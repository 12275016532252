import React, { FormEventHandler, useEffect, useState } from 'react';
import { Translate, translate } from 'app/component/jhipster';
import { Button, Input, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { TableBody, TableCell, TableContainer, TableHead, TableRow, Table } from '@mui/material';
import { SERVER_URL } from 'app/config/constants';
import { IQueryParams } from 'app/shared/reducers/reducer.utils';
import CustomPagination from 'app/component/CustomPagination';
import { initialSquare } from 'app/shared/style/style';
import { getEntities as getRefKatilimciTotalEntities } from 'app/entities/ref-katilimci/ref-katilimci.reducer';
import { IRefKatilimci } from 'app/shared/model/ref-katilimci.model';

interface IPersonelSearchProps {
  isKatilimciModal: boolean;
  handleCloseRefKatilimciForm: FormEventHandler;
  dataToCompare?: any;
  bildirimModal: (isBildirim: boolean, katilimci: IRefKatilimci) => void;
}

const RefKatilimciSearch: React.FC<IPersonelSearchProps> = ({
  isKatilimciModal,
  handleCloseRefKatilimciForm,
  bildirimModal,
  dataToCompare,
}) => {
  const dispatch = useAppDispatch();

  const refTotalKatilimcis = useAppSelector(state => state.refKatilimci.entities);
  const totalItem = useAppSelector(state => state.refKatilimci.totalItems);
  const loading = useAppSelector(state => state.refKatilimci.loading);
  const [pageObject, setPageObject] = useState<IQueryParams>(useAppSelector(state => state.paginate.pageObject));
  const [search, setSearch] = useState('');

  useEffect(() => {
    dispatch(getRefKatilimciTotalEntities(pageObject));
  }, [pageObject.page, pageObject.query, pageObject.size, pageObject.sort]);

  useEffect(() => {
    if (search.length > 2) {
      setPageObject({ ...pageObject, page: 0, query: `globalFilter=${search}` });
    } else if (search.length === 0) {
      setPageObject({ ...pageObject, page: 0, query: '' });
    }
  }, [search]);

  const handlePageChange = (pageNumber, newItemsPerPage = pageObject.size) => {
    if (newItemsPerPage !== pageObject.size) {
      setPageObject({ ...pageObject, page: pageNumber - 1, size: newItemsPerPage });
    } else {
      setPageObject({ ...pageObject, page: pageNumber - 1 });
    }
  };

  return (
    <Modal isOpen={isKatilimciModal} toggle={handleCloseRefKatilimciForm} className="modal-style-lg" centered>
      <ModalHeader toggle={handleCloseRefKatilimciForm}>
        <Translate contentKey="tk24AdysApp.krizKatilimci.home.title" />
      </ModalHeader>
      <ModalBody>
        <div className="search-frame">
          <div className="seachiconposition">
            <img className="seachicon" width={24} height={24} src={`${SERVER_URL}/content/images/icon/faSearch.svg`} />
            <Input
              value={search}
              onChange={e => setSearch(e.target.value)}
              placeholder={translate('entity.action.search')}
              className="search-input"
            />
          </div>
        </div>

        <div className="table-responsive" style={{ marginTop: 5 }}>
          <TableContainer className="content-table response-modal-height">
            {refTotalKatilimcis && refTotalKatilimcis.length > 0 ? (
              <Table aria-label="a dense table" stickyHeader>
                <TableHead className="content-table-title-area">
                  <TableRow className="table-head-row">
                    <TableCell className="table-title-cell-kriz-create hand" align="left">
                      <Translate contentKey="tk24AdysApp.krizKatilimci.sicil">Adi</Translate>
                    </TableCell>
                    <TableCell className="table-title-cell-kriz-create hand" align="left">
                      <Translate contentKey="tk24AdysApp.krizKatilimci.img">img</Translate>&nbsp;
                    </TableCell>
                    <TableCell className="table-title-cell-kriz-create hand" align="left">
                      <Translate contentKey="tk24AdysApp.krizKatilimci.adi">Adi</Translate>
                    </TableCell>
                    <TableCell className="table-title-cell-kriz-create hand" align="left">
                      <Translate contentKey="tk24AdysApp.krizKatilimci.soyadi">Soyadi</Translate>
                    </TableCell>
                    <TableCell className="table-title-cell-kriz-create hand" align="left">
                      <Translate contentKey="tk24AdysApp.krizKatilimci.unvanAdi">Unvan</Translate>
                    </TableCell>
                    <TableCell className="table-title-cell-kriz-create"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {refTotalKatilimcis.map(katilimci => (
                    <TableRow key={katilimci.id} className="table-cell-row">
                      <TableCell className="table-content-cell" component="th" scope="row">
                        {katilimci.sicil}
                      </TableCell>
                      <TableCell className="table-content-cell" component="th" scope="row">
                        {katilimci?.personel ? (
                          katilimci?.personel?.thumbnail ? (
                            <div className="hoverable-image-container">
                              <img
                                className="hoverable-image"
                                style={{
                                  ...initialSquare,
                                  transition: 'width 0.3s, height 0.3s',
                                }}
                                src={`data:${katilimci?.personel?.thumbnail.contentType};base64,${katilimci?.personel?.thumbnail.base64}`}
                                alt={''}
                              />
                            </div>
                          ) : (
                            <div style={initialSquare}>
                              <span style={{ fontSize: '14px' }}>
                                {katilimci.soyadi ? (
                                  <>
                                    {katilimci.adi.charAt(0)}
                                    {katilimci.soyadi.charAt(0)}
                                  </>
                                ) : (
                                  katilimci.adi.charAt(0)
                                )}
                              </span>
                            </div>
                          )
                        ) : (
                          <div style={initialSquare}>
                            <span style={{ fontSize: '14px' }}>
                              {katilimci.soyadi ? (
                                <>
                                  {katilimci.adi.charAt(0)}
                                  {katilimci.soyadi.charAt(0)}
                                </>
                              ) : (
                                katilimci.adi.charAt(0)
                              )}
                            </span>
                          </div>
                        )}
                      </TableCell>
                      <TableCell className="table-content-cell" component="th" scope="row">
                        {katilimci.adi}
                      </TableCell>
                      <TableCell className="table-content-cell" component="th" scope="row">
                        {katilimci.soyadi}
                      </TableCell>
                      <TableCell className="table-content-cell" component="th" scope="row">
                        {katilimci.unvanAdi ? katilimci.unvanAdi : '-'}
                      </TableCell>
                      <TableCell className="table-content-cell" align="right">
                        <div className="btn-group flex-btn-group-container">
                          {dataToCompare.some(item => item.sicil.toString() === katilimci.sicil.toString()) ? (
                            <Button
                              className="savedButton"
                              id="start-entity"
                              data-cy="entityStartButton"
                              onClick={() => bildirimModal(true, katilimci)}
                              disabled={true}
                            >
                              <img width={14} height={14} src={`${SERVER_URL}/content/images/icon/onayIcon.svg`} />
                              <span className="d-none d-md-inline" style={{ color: '#39AB59' }}>
                                {translate('entity.action.saved')}
                              </span>
                            </Button>
                          ) : (
                            <Button
                              className="saveButton"
                              id="start-entity"
                              data-cy="entityStartButton"
                              onClick={() => bildirimModal(true, katilimci)}
                            >
                              <img width={14} height={14} src={`${SERVER_URL}/content/images/icon/faPlusLtBlue.svg`} />
                              <span className="d-none d-md-inline">{translate('entity.action.save')}</span>
                            </Button>
                          )}
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            ) : (
              !loading && (
                <div className="alert alert-warning">
                  <Translate contentKey="tk24AdysApp.krizKatilimci.home.notFound">No krizKatilimci found</Translate>
                </div>
              )
            )}
          </TableContainer>
        </div>
      </ModalBody>
      <div style={{ padding: '20px' }}>
        <CustomPagination
          currentPage={pageObject.page + 1}
          currentPageSize={pageObject.size}
          totalItems={totalItem}
          handlePageChange={handlePageChange}
        />
      </div>
    </Modal>
  );
};

export default RefKatilimciSearch;
