import React, { useState } from 'react';
import { Button, UncontrolledTooltip, Form, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { TextFormat, Translate, translate } from 'app/component/jhipster';

import { updateEntity, createEntity } from './uygulama-ayarlari.reducer';
import { IUygulamaAyarlari } from 'app/shared/model/uygulama-ayarlari.model';
import { useAppDispatch } from 'app/config/store';
import { CustomSaveEntityConfirm, getHeaderContentKey } from 'app/shared/util/confirm-utils';
import { FormItem } from 'app/component/FormItem';
import { IQueryParams } from 'app/shared/reducers/reducer.utils';
import { APP_DATE_FORMAT } from 'app/config/constants';
import { convertDateTimeFromServer } from 'app/shared/util/date-utils';

interface UygulamaAyarlariUpdateProp {
  handleCloseForm: () => void;
  formValues?: IUygulamaAyarlari;
  isOpen: boolean;
  pageObject?: IQueryParams;
  isViewing: boolean;
}

export const UygulamaAyarlariUpdate: React.FC<UygulamaAyarlariUpdateProp> = ({
  handleCloseForm,
  formValues,
  isOpen,
  pageObject,
  isViewing,
}) => {
  const dispatch = useAppDispatch();
  const [customIsOpen, setCustomIsOpen] = useState(isOpen);
  const [modalPromiseResolve, setModalPromiseResolve] = useState(null);
  const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);

  const showSaveConfirmModal = () => {
    setIsSaveModalOpen(true);
    return new Promise(resolve => {
      setModalPromiseResolve(() => resolve);
    });
  };

  const handleConfirm = () => {
    if (modalPromiseResolve) {
      modalPromiseResolve(true);
    }
    setIsSaveModalOpen(false);
  };

  const handleCancel = () => {
    if (modalPromiseResolve) {
      modalPromiseResolve(false);
    }
    setIsSaveModalOpen(false);
  };

  const saveEntity = async e => {
    e.preventDefault();
    setCustomIsOpen(false);
    const isSave = await showSaveConfirmModal();
    if (isSave) {
      const formDataObject = {};
      for (const field of e.target.elements) {
        if (field.name) {
          formDataObject[field.name] = field.value;
        }
      }

      const uygulamaAyarlariEntity = {
        ...formValues,
        ...formDataObject,
      };

      if (formValues !== null) {
        await dispatch(updateEntity({ ...pageObject, entity: uygulamaAyarlariEntity }));
      } else {
        await dispatch(createEntity({ ...pageObject, entity: uygulamaAyarlariEntity }));
      }

      handleCloseForm();
    } else {
      setCustomIsOpen(true);
    }
  };

  return (
    <>
      <CustomSaveEntityConfirm entity={formValues} isOpen={isSaveModalOpen} onConfirm={handleConfirm} onCancel={handleCancel} />
      <Modal className="modal-style" isOpen={customIsOpen} toggle={handleCloseForm} centered>
        <ModalHeader data-cy="uygulamaAyarlariCreateUpdateHeading" toggle={handleCloseForm}>
          {formValues && (
            <p className="modal-title-info">
              {formValues?.adiTr} - {formValues?.adiEn}
            </p>
          )}
          <Translate contentKey={getHeaderContentKey(isViewing, formValues, 'uygulamaAyarlari')} />
        </ModalHeader>

        <Form onSubmit={saveEntity}>
          <ModalBody style={{ maxHeight: '600px', overflow: 'auto' }}>
            <FormItem
              label={translate('global.languageGlobal.adiTr')}
              id="ref-adt-form-bolum-adiTr"
              name="adiTr"
              defaultValue={formValues && formValues['adiTr']}
              disabled={formValues || isViewing ? true : false}
              type="text"
              validation={{
                required: { value: true, message: translate('entity.validation.required') },
                maxLength: { value: 100, message: translate('entity.validation.maxlength', { max: 100 }) },
              }}
            />
            <UncontrolledTooltip target="adiTrLabel">
              <Translate contentKey="global.languageGlobal.adiTr" />
            </UncontrolledTooltip>

            <FormItem
              label={translate('global.languageGlobal.adiEn')}
              id="ref-adt-form-bolum-adiEn"
              name="adiEn"
              defaultValue={formValues && formValues['adiEn']}
              type="text"
              disabled={formValues || isViewing ? true : false}
              validation={{
                required: { value: true, message: translate('entity.validation.required') },
                maxLength: { value: 100, message: translate('entity.validation.maxlength', { max: 100 }) },
              }}
            />
            <UncontrolledTooltip target="adiEnLabel">
              <Translate contentKey="global.languageGlobal.adiEn" />
            </UncontrolledTooltip>

            <FormItem
              label={translate('tk24AdysApp.uygulamaAyarlari.aciklama')}
              id="aciklama"
              name="aciklama"
              defaultValue={formValues && formValues['aciklama']}
              disabled={isViewing}
              type="textarea"
              validation={{
                required: { value: true, message: translate('entity.validation.required') },
                maxLength: { value: 1500, message: translate('entity.validation.maxlength', { max: 1500 }) },
              }}
            />
            <FormItem
              label={translate('tk24AdysApp.uygulamaAyarlari.deger')}
              id="sistem-parametre-deger"
              name="deger"
              defaultValue={formValues && formValues['deger']}
              disabled={isViewing}
              type="text"
              validation={{
                required: { value: true, message: translate('entity.validation.required') },
                maxLength: { value: 100, message: translate('entity.validation.maxlength', { max: 100 }) },
              }}
            />
            <FormItem
              label={translate('global.languageGlobal.note')}
              id="note"
              name="note"
              defaultValue={formValues && formValues['note']}
              disabled={isViewing}
              type="textarea"
              validation={{
                maxLength: { value: 3000, message: translate('entity.validation.maxlength', { max: 3000 }) },
              }}
            />

            {isViewing && (
              <>
                <FormItem
                  label={translate('global.languageGlobal.sonGuncelleyenKullanici')}
                  id="sistem-parametre-sonGuncelleyenKullanici"
                  name="sonGuncelleyenKullanici"
                  defaultValue={
                    formValues &&
                    formValues.sonGuncelleyenKullanici?.sicilNo +
                      ' / ' +
                      formValues.sonGuncelleyenKullanici?.adi +
                      ' ' +
                      formValues.sonGuncelleyenKullanici?.soyadi
                  }
                  disabled={isViewing}
                  type="text"
                />

                <FormItem
                  label={translate('global.languageGlobal.mDate')}
                  id="sistem-parametre-mDate"
                  name="mDate"
                  defaultValue={formValues && convertDateTimeFromServer(formValues.mDate)}
                  disabled={isViewing}
                  type="text"
                />
              </>
            )}
          </ModalBody>
          {!isViewing && (
            <ModalFooter className="modal-footer">
              <Button className="cancel-button" onClick={handleCloseForm}>
                <Translate contentKey="entity.action.cancel">Cancel</Translate>
              </Button>
              &nbsp;
              <Button className="save-button" id="jhi-confirm-delete-refAdtForm" type="submit" data-cy="entityConfirmButton">
                <Translate contentKey="entity.action.save">Kaydet</Translate>
              </Button>
            </ModalFooter>
          )}
        </Form>
      </Modal>
    </>
  );
};

export default UygulamaAyarlariUpdate;
