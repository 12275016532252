import React, { useState, useEffect } from 'react';
import { Link, RouteComponentProps, useHistory } from 'react-router-dom';
import { Table } from 'reactstrap';
import { Translate } from 'app/component/jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { getEntities } from './kriz-katilimci.reducer';
import { ASC, DESC } from 'app/shared/util/pagination.constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { LoadingSpinner } from 'app/component/LoadingSpinner';
import { faArrowDown, faArrowLeft, faArrowUp, faHome } from '@fortawesome/free-solid-svg-icons';
import { TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import CustomPagination from 'app/component/CustomPagination';
import { IQueryParams } from 'app/shared/reducers/reducer.utils';
import { SERVER_URL } from 'app/config/constants';

export const KrizKatilimci = (props: RouteComponentProps<{ url: string }>) => {
  const dispatch = useAppDispatch();

  const krizKatilimciList = useAppSelector(state => state.krizKatilimci.entities);
  const loading = useAppSelector(state => state.krizKatilimci.loading);
  const totalItems = useAppSelector(state => state.krizKatilimci.totalItems);

  const [pageObject, setPageObject] = useState<IQueryParams>(useAppSelector(state => state.paginate.pageObject));

  const getAllEntities = () => {
    dispatch(getEntities(pageObject));
  };

  useEffect(() => {
    getAllEntities();
  }, [pageObject.page, pageObject.query, pageObject.size, pageObject.sort]);

  const sort = p => () => {
    const orderBy = pageObject.sort.split(',')[1];
    setPageObject({ ...pageObject, sort: `${p},${orderBy === ASC ? DESC : ASC}` });
  };

  const handlePageChange = (pageNumber, newItemsPerPage = pageObject.size) => {
    if (newItemsPerPage !== pageObject.size) {
      setPageObject({ ...pageObject, page: pageNumber - 1, size: newItemsPerPage });
    } else {
      setPageObject({ ...pageObject, page: pageNumber - 1 });
    }
  };

  return (
    <div>
      {loading && <LoadingSpinner />}
      <div style={{ display: 'flex', gap: 8, textAlign: 'center' }}>
        <p className="icon-text">
          <a className="link-a-text" href={`${SERVER_URL}`}>
            <FontAwesomeIcon width={13} height={13} icon={faHome} />
          </a>
        </p>
        <p className="separator"> / </p>
        <p className="link-text">
          {' '}
          <Translate contentKey="tk24AdysApp.krizKatilimci.home.title" />{' '}
        </p>
      </div>

      <h2 id="ref-yakinlik-durumu-heading" data-cy="RefYakinlikDurumuHeading" className="title-frame">
        <a className="link-a-text" onClick={() => props.history.goBack()}>
          <FontAwesomeIcon width={27.5} height={23} icon={faArrowLeft} />{' '}
        </a>
        <p className="title-text">
          <Translate contentKey="tk24AdysApp.krizKatilimci.home.title" />
        </p>
      </h2>

      <div className="content-area">
        <TableContainer className="content-table">
          {krizKatilimciList && krizKatilimciList.length > 0 ? (
            <Table aria-label="a dense table">
              <TableHead className="content-table-title-area">
                <TableRow className="table-head-row">
                  <TableCell style={{ width: 570 }} align="left" className="table-title-cell hand" onClick={sort('sicil')}>
                    <Translate contentKey="tk24AdysApp.krizKatilimci.sicilTitle">Sicil</Translate>
                    {pageObject.sort === `sicil,${ASC}` ? <FontAwesomeIcon icon={faArrowDown} /> : <FontAwesomeIcon icon={faArrowUp} />}
                  </TableCell>
                  <TableCell style={{ width: 570 }} align="left" className="table-title-cell hand" onClick={sort('adi')}>
                    <Translate contentKey="tk24AdysApp.krizKatilimci.adi">Adi</Translate>
                    {pageObject.sort === `adi,${ASC}` ? <FontAwesomeIcon icon={faArrowDown} /> : <FontAwesomeIcon icon={faArrowUp} />}
                  </TableCell>
                  <TableCell style={{ width: 570 }} align="left" className="table-title-cell hand" onClick={sort('soyadi')}>
                    <Translate contentKey="tk24AdysApp.krizKatilimci.soyadi">Soyadi</Translate>
                    {pageObject.sort === `soyadi,${ASC}` ? <FontAwesomeIcon icon={faArrowDown} /> : <FontAwesomeIcon icon={faArrowUp} />}
                  </TableCell>
                  <TableCell style={{ width: 570 }} align="left" className="table-title-cell hand" onClick={sort('unvanAdi')}>
                    <Translate contentKey="tk24AdysApp.krizKatilimci.unvanAdiTitle">Unvan</Translate>
                    {pageObject.sort === `unvanAdi,${ASC}` ? <FontAwesomeIcon icon={faArrowDown} /> : <FontAwesomeIcon icon={faArrowUp} />}
                  </TableCell>
                  <TableCell style={{ width: 570 }} align="left" className="table-title-cell hand" onClick={sort('orgAdiTr')}>
                    <Translate contentKey="tk24AdysApp.krizKatilimci.orgAdiTrTitle">Org Adi Tr</Translate>
                    {pageObject.sort === `orgAdiTr,${ASC}` ? <FontAwesomeIcon icon={faArrowDown} /> : <FontAwesomeIcon icon={faArrowUp} />}
                  </TableCell>
                  <TableCell style={{ width: 570 }} align="left" className="table-title-cell hand" onClick={sort('orgAdiEn')}>
                    <Translate contentKey="tk24AdysApp.krizKatilimci.orgAdiEnTitle">Org Adi En</Translate>
                    {pageObject.sort === `orgAdiEn,${ASC}` ? <FontAwesomeIcon icon={faArrowDown} /> : <FontAwesomeIcon icon={faArrowUp} />}
                  </TableCell>
                  <TableCell style={{ width: 570 }} align="left" className="table-title-cell hand" onClick={sort('eposta')}>
                    <Translate contentKey="tk24AdysApp.krizKatilimci.epostaTitle">Eposta</Translate>
                    {pageObject.sort === `eposta,${ASC}` ? <FontAwesomeIcon icon={faArrowDown} /> : <FontAwesomeIcon icon={faArrowUp} />}
                  </TableCell>
                  <TableCell style={{ width: 570 }} align="left" className="table-title-cell hand" onClick={sort('gsmNo')}>
                    <Translate contentKey="tk24AdysApp.krizKatilimci.gsmNo">Gsm No</Translate>
                    {pageObject.sort === `gsmNo,${ASC}` ? <FontAwesomeIcon icon={faArrowDown} /> : <FontAwesomeIcon icon={faArrowUp} />}
                  </TableCell>
                  <TableCell style={{ width: 570 }} align="left" className="table-title-cell hand">
                    <Translate contentKey="tk24AdysApp.krizKatilimci.krizTitle">Kriz</Translate>
                  </TableCell>
                  <TableCell style={{ width: 570 }} align="left" className="table-title-cell hand">
                    <Translate contentKey="tk24AdysApp.krizKatilimci.vekilKatilimciTitle">Vekil Katilimci</Translate>{' '}
                  </TableCell>
                  <TableCell style={{ width: 570 }} align="left" className="table-title-cell hand">
                    <Translate contentKey="tk24AdysApp.krizKatilimci.toplantidaMiTitle">Toplantida Mi</Translate>
                  </TableCell>
                  <TableCell style={{ width: 570 }} align="left" className="table-title-cell hand">
                    <Translate contentKey="tk24AdysApp.krizKatilimci.katilimciRoluTitle">Katilimci Rolu</Translate>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {krizKatilimciList.map(krizKatilimci => (
                  <TableRow key={krizKatilimci.id} className="table-cell-row">
                    <TableCell component="th" scope="row" className="table-content-cell">
                      {krizKatilimci.sicil}{' '}
                    </TableCell>
                    <TableCell component="th" scope="row" className="table-content-cell">
                      {krizKatilimci.adi}{' '}
                    </TableCell>
                    <TableCell component="th" scope="row" className="table-content-cell">
                      {krizKatilimci.soyadi}{' '}
                    </TableCell>
                    <TableCell component="th" scope="row" className="table-content-cell">
                      {krizKatilimci.unvanAdi}{' '}
                    </TableCell>
                    <TableCell component="th" scope="row" className="table-content-cell">
                      {krizKatilimci.org?.adiTr}{' '}
                    </TableCell>
                    <TableCell component="th" scope="row" className="table-content-cell">
                      {krizKatilimci.org?.adiEn}{' '}
                    </TableCell>
                    <TableCell component="th" scope="row" className="table-content-cell">
                      <a href={`mailto:${krizKatilimci.eposta}`} className="epostaText">
                        {krizKatilimci.eposta}
                      </a>
                    </TableCell>
                    <TableCell component="th" scope="row" className="table-content-cell">
                      {krizKatilimci.gsmNo}{' '}
                    </TableCell>
                    <TableCell component="th" scope="row" className="table-content-cell">
                      {krizKatilimci.kriz ? <>{krizKatilimci.kriz.realId}</> : ''}{' '}
                    </TableCell>
                    <TableCell component="th" scope="row" className="table-content-cell">
                      {krizKatilimci.vekilKatilimci ? <>{krizKatilimci.vekilKatilimci.adi}</> : ''}{' '}
                    </TableCell>
                    <TableCell component="th" scope="row" className="table-content-cell">
                      {krizKatilimci.toplantidaMi ? <>{krizKatilimci.toplantidaMi.adi}</> : ''}{' '}
                    </TableCell>
                    <TableCell component="th" scope="row" className="table-content-cell">
                      {krizKatilimci.katilimciRolu ? <>{krizKatilimci.katilimciRolu.adi}</> : ''}{' '}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : (
            !loading && (
              <div className="alert alert-warning">
                <Translate contentKey="tk24AdysApp.krizKatilimci.home.notFound">No Kriz Katilimcis found</Translate>
              </div>
            )
          )}
        </TableContainer>

        <CustomPagination
          currentPage={pageObject.page + 1}
          currentPageSize={pageObject.size}
          totalItems={totalItems}
          handlePageChange={handlePageChange}
        />
      </div>
    </div>
  );
};

export default KrizKatilimci;
