import { IKriz } from 'app/shared/model/kriz.model';
import { IReferans } from 'app/shared/model/referans.model';
import { IKisi } from './kisi.model';
import { IRefKisiAltTipi } from './ref-kisi-alt-tipi.model';
import { IQueryParams } from 'app/shared/reducers/reducer.utils';
import { IRefUlke } from './ref-ulke.model';

export interface IKrizEkKisi {
  id?: number;
  realId?: number;
  guid?: string;
  cDate?: string;
  cUser?: number;
  mDate?: string;
  mUser?: number;
  adi?: string;
  soyadi?: string;
  ePosta?: string;
  gsmNo?: string;
  aciklama?: string;
  sonGuncelleZamani?: string;
  refUlke?: IRefUlke;
  sonGuncelleyenKullanici?: {
    adi?: string | null;
    id?: number;
    realId?: number;
    sicilNo?: string | null;
    soyadi?: string | null;
  };
  kriz?: IKriz;
  durum?: IReferans;
  kisi?: IKisi;
  kisiAltTipi?: IRefKisiAltTipi;
  kisiTipi?: IReferans;
  milliyet?: string;
  hasHatirlatma?: boolean;
}

export interface IKrizEkKisi extends IQueryParams {
  krizId?: number | string;
}

export const defaultValue: Readonly<IKrizEkKisi> = {};
