import React, { useState, useEffect } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col, FormText, UncontrolledTooltip } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'app/component/jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IKullanici } from 'app/shared/model/kullanici.model';
import { getEntities as getKullanicis } from 'app/entities/kullanici/kullanici.reducer';
import { IRol } from 'app/shared/model/rol.model';
import { getEntities as getRols } from 'app/entities/rol/rol.reducer';
import { IReferans } from 'app/shared/model/referans.model';
import { getEntities as getReferans } from 'app/entities/referans/referans.reducer';
import { getEntity, updateEntity, createEntity, reset } from './kullanici-rol.reducer';
import { IKullaniciRol } from 'app/shared/model/kullanici-rol.model';
import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

export const KullaniciRolUpdate = (props: RouteComponentProps<{ id: string }>) => {
  const dispatch = useAppDispatch();

  const [isNew] = useState(!props.match.params || !props.match.params.id);

  const kullanicis = useAppSelector(state => state.kullanici.entities);
  const rols = useAppSelector(state => state.rol.entities);
  const referans = useAppSelector(state => state.referans.entities);
  const kullaniciRolEntity = useAppSelector(state => state.kullaniciRol.entity);
  const loading = useAppSelector(state => state.kullaniciRol.loading);
  const updating = useAppSelector(state => state.kullaniciRol.updating);
  const updateSuccess = useAppSelector(state => state.kullaniciRol.updateSuccess);

  const handleClose = () => {
    props.history.push('/kullanici-rol' + props.location.search);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(props.match.params.id));
    }

    dispatch(getKullanicis({}));
    dispatch(getRols({}));
    dispatch(getReferans({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    values.cDate = convertDateTimeToServer(values.cDate);
    values.mDate = convertDateTimeToServer(values.mDate);

    const entity = {
      ...kullaniciRolEntity,
      ...values,
      kullanici: kullanicis.find(it => it.id.toString() ===  values.kullaniciId.toString()),
      rol: rols.find(it => it.id.toString() ===  values.rolId.toString()),
      durum: referans.find(it => it.id.toString() ===  values.durumId.toString()),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {
          cDate: displayDefaultDateTime(),
          mDate: displayDefaultDateTime(),
        }
      : {
          ...kullaniciRolEntity,
          cDate: convertDateTimeFromServer(kullaniciRolEntity.cDate),
          mDate: convertDateTimeFromServer(kullaniciRolEntity.mDate),
          kullaniciId: kullaniciRolEntity?.kullanici?.id,
          rolId: kullaniciRolEntity?.rol?.id,
          durumId: kullaniciRolEntity?.durum?.id,
          kullaniciAdi: kullaniciRolEntity?.kullanici?.kullaniciAdi,
          soyadi: kullaniciRolEntity?.kullanici?.soyadi,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="tk24AdysApp.kullaniciRol.home.createOrEditLabel" data-cy="KullaniciRolCreateUpdateHeading">
            <Translate contentKey="tk24AdysApp.kullaniciRol.home.createOrEditLabel">Create or edit a KullaniciRol</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="kullanici-rol-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                id="kullanici-rol-kullanici"
                name="kullaniciId"
                data-cy="kullanici"
                label={translate('tk24AdysApp.kullaniciRol.kullanici')}
                type="text"
                required
              ></ValidatedField>
              <ValidatedField
                label={translate('tk24AdysApp.kullaniciRol.adi')}
                id="kullaniciAdi"
                name="kullaniciAdi"
                data-cy="kullaniciAdi"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  maxLength: { value: 4000, message: translate('entity.validation.maxlength', { max: 4000 }) },
                }}
              />
              <ValidatedField
                label={translate('tk24AdysApp.kullaniciRol.soyadi')}
                id="soyadi"
                name="soyadi"
                data-cy="soyadi"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  maxLength: { value: 4000, message: translate('entity.validation.maxlength', { max: 4000 }) },
                }}
              />
              <ValidatedField
                id="kullanici-rol-rol"
                name="rolId"
                data-cy="rol"
                label={translate('tk24AdysApp.kullaniciRol.rol')}
                type="select"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              >
                <option value="" key="0" />
                {rols
                  ? rols.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.adi}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/kullanici-rol" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default KullaniciRolUpdate;
