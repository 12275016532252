import React, { useState, useEffect, useCallback } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Button, ModalBody, ModalHeader, Form, Modal, Input, ModalFooter, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { Translate, TextFormat, translate } from 'app/component/jhipster';
import { deleteEntity, getEntities, getTotalEntities } from './ref-katilimci.reducer';
import { IRefKatilimci } from 'app/shared/model/ref-katilimci.model';
import { APP_DATE_FORMAT, SERVER_URL } from 'app/config/constants';
import { ASC, DESC } from 'app/shared/util/pagination.constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { ReferansEnum } from 'app/constants/ReferansEnum';
import { getEntities as getReferans } from 'app/entities/referans/referans.reducer';
import { CustomDeleteEntityConfirm, CustomSaveEntityConfirm, isNullOrEmpty } from 'app/shared/util/confirm-utils';
import RefKatilimciUpdate from './ref-katilimci-update';
import {
  Box,
  Collapse,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material';
import { LoadingSpinner } from 'app/component/LoadingSpinner';
import { FormItem } from 'app/component/FormItem';
import PersonelSearch from '../personel/personel-search';
import GrantedButton from 'app/component/GrantedButton';
import { reset as resetPersonelEntities } from 'app/entities/personel/personel.reducer';
import CustomPagination from 'app/component/CustomPagination';
import { IQueryParams } from 'app/shared/reducers/reducer.utils';
import { KeyboardArrowDownOutlined, KeyboardArrowRight } from '@mui/icons-material';
import { localeLanguage, upperOnlyFirstLetter } from 'app/shared/util/util';
import RefKatilimciIletisim from '../ref-katilimci-iletisim/ref-katilimci-iletisim';
import RefKatilimciVekil from '../ref-katilimci-vekil/ref-katilimci-vekil';
import RefKatilimciDdmsDokuman from '../ref-katilimci-ddms-dokuman/ref-katilimci-ddms-dokuman';
import ExcelDownloadButton from 'app/component/ExcelDownload';
import SortIcon from 'app/component/SortIcon';
import { MenuProps } from 'app/shared/util/filter.constants';
import { initialSquare } from 'app/shared/style/style';
import classnames from 'classnames';
import axios from 'axios';
import { toast } from 'react-toastify';

export const RefKatilimci = (props: RouteComponentProps<{ url: string }>) => {
  const dispatch = useAppDispatch();
  const totalItems = useAppSelector(state => state.refKatilimci.totalItems);
  const refKatilimciList = useAppSelector(state => state.refKatilimci.entities);
  const loading = useAppSelector(state => state.refKatilimci.loading);
  const updating = useAppSelector(state => state.refKatilimci.updating);
  const refLoading = useAppSelector(state => state.referans.loading);
  const referans = useAppSelector(state => state.referans.entities);
  const [saveLoading, setSaveLoading] = useState(false);
  const katilimciRoluList = referans.filter(item => item.tipi === 'KATILIMCI_ROLU');
  const [isRefKatilimciCreateModal, setIsRefKatilimciCreateModal] = useState(false);
  const [selectedRefKatilimci, setSelectedRefKatilimci] = useState(null);
  const [isRefKatilimciUpdateModal, setIsRefKatilimciUpdateModal] = useState(false);
  const [isKatilimciRoluModal, setKatilimciRoluModal] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [selectedPersonel, setSelectedPersonel] = useState(null);
  const [modalPromiseResolve, setModalPromiseResolve] = useState(null);
  const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);
  const [openItemId, setOpenItemId] = useState<number | null>(null);
  const [search, setSearch] = useState('');
  const [pageObject, setPageObject] = useState<IQueryParams>(useAppSelector(state => state.paginate.pageObject));

  const ExcelSampleData = [
    { key: 'adi', titleContentKey: 'tk24AdysApp.refKatilimci.adi' },
    { key: 'soyadi', titleContentKey: 'tk24AdysApp.refKatilimci.soyadi' },
    { key: 'unvanAdi', titleContentKey: 'tk24AdysApp.refKatilimci.unvanAdi' },
    { key: 'org.adi', titleContentKey: 'tk24AdysApp.refKatilimci.unite' },
    { key: 'katilimciRolu.adi', titleContentKey: 'tk24AdysApp.refKatilimci.katilimciRolu.adi' },
    {
      key: 'bilgiSonGuncellenmeZamani',
      format: 'date',
      titleContentKey: 'tk24AdysApp.refKatilimci.bilgiSonGuncellenmeZamani',
    },
    { key: 'org.sirket.adi', titleContentKey: 'tk24AdysApp.refKatilimci.sirket' },
  ];

  const getAllEntities = () => {
    dispatch(getEntities(pageObject));
  };

  useEffect(() => {
    dispatch(getReferans({ size: 999, page: 0, sort: 'id,asc' }));
  }, []);
  useEffect(() => {
    getAllEntities();
  }, [pageObject.page, pageObject.query, pageObject.size, pageObject.sort]);

  useEffect(() => {
    if (search.length > 2) {
      setPageObject(prevPageObject => ({
        ...prevPageObject,
        page: 0,
        query: prevPageObject.query + `&globalFilter=${search}`,
      }));
    } else if (search.length === 0 && !isNullOrEmpty(pageObject.query)) {
      const keyToDelete = 'globalFilter';
      const updatedParams = pageObject.query
        .split('&')
        .filter(param => {
          const [key, _] = param.split('=');
          return key !== keyToDelete;
        })
        .join('&');

      setPageObject({ ...pageObject, page: 0, query: updatedParams });
    }
  }, [search]);

  const fetchTotalRefKatilimciData = async (): Promise<IRefKatilimci[]> => {
    try {
      const response = (await dispatch(getTotalEntities({ ...pageObject, size: totalItems }))) as {
        payload: { data: IRefKatilimci[] };
      };

      return response.payload.data || [];
    } catch (error) {
      console.error('Error fetching data:', error);
      return [];
    }
  };

  const handlePageChange = (pageNumber, newItemsPerPage = pageObject.size) => {
    if (newItemsPerPage !== pageObject.size) {
      setPageObject({ ...pageObject, page: pageNumber - 1, size: newItemsPerPage });
    } else {
      setPageObject({ ...pageObject, page: pageNumber - 1 });
    }
  };

  const confirmRefKatilimciDelete = refKatilimci => {
    setSelectedRefKatilimci(refKatilimci);
    setIsDeleteModalOpen(true);
  };

  const handleDeleteConfirm = useCallback(async () => {
    if (selectedRefKatilimci) {
      const entity = { ...pageObject, entity: selectedRefKatilimci };

      if (refKatilimciList.length === 1) {
        entity.page = pageObject.page - 1;
        setPageObject({ ...pageObject, page: pageObject.page - 1 });
      }

      await dispatch(deleteEntity(entity));
      handleDeleteCancel();
    }
  }, [selectedRefKatilimci, dispatch]);

  const handleDeleteCancel = () => {
    setSelectedRefKatilimci(null);
    setIsDeleteModalOpen(false);
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  const handleCloseUpdateForm = () => {
    if (isRefKatilimciUpdateModal) {
      setIsRefKatilimciUpdateModal(false);
    }
    setSelectedRefKatilimci(null);
  };

  const handleCloseCreateKatilimciModal = () => {
    if (isRefKatilimciCreateModal) {
      setSelectedPersonel(null);
      setIsRefKatilimciCreateModal(false);
    }

    dispatch(resetPersonelEntities());
  };

  const handleCloseCreateKatilimciRoluModal = () => {
    if (isKatilimciRoluModal) {
      setKatilimciRoluModal(false);
    }
    setIsRefKatilimciCreateModal(true);
  };

  const savePersonelKatilimciIslem = personel => {
    setSelectedPersonel(personel);
    setIsRefKatilimciCreateModal(false);
    setKatilimciRoluModal(true);
  };

  const showSaveConfirmModal = () => {
    setIsSaveModalOpen(true);
    return new Promise(resolve => {
      setModalPromiseResolve(() => resolve);
    });
  };

  const handleConfirm = () => {
    if (modalPromiseResolve) {
      modalPromiseResolve(true);
    }
    setIsSaveModalOpen(false);
  };

  const handleCancel = () => {
    if (modalPromiseResolve) {
      modalPromiseResolve(false);
    }
    setIsSaveModalOpen(false);
  };

  const saveEntityKatilimci = async e => {
    e.preventDefault();
    setKatilimciRoluModal(false);
    const isSave = await showSaveConfirmModal();
    if (isSave) {
      setSaveLoading(true);
      const formDataObject = {};

      for (const field of e.target.elements) {
        if (field.name) {
          formDataObject[field.name] = field.value;
        }
      }

      await axios
        .post(
          `api/ref-katilimcis/personel?katilimciRoluId=${formDataObject['katilimciRoluId'].toString()}&personelGuid=${selectedPersonel?.id}`
        )
        .then(resp => {
          getAllEntities();
        })
        .catch(error => {
          toast.error(translate(`${error.response?.data?.message || 'error.title'}`));
        });

      setSaveLoading(false);
    } else {
      setKatilimciRoluModal(true);
    }
    setSelectedPersonel(null);
  };

  const RefEntityUpdateModal = () => {
    return (
      <RefKatilimciUpdate
        handleCloseForm={handleCloseUpdateForm}
        formValues={selectedRefKatilimci}
        isOpen={isRefKatilimciUpdateModal}
        pageObject={pageObject}
      ></RefKatilimciUpdate>
    );
  };

  const katilimciRoluModal = () => {
    return (
      <Modal className="modal-style" isOpen={isKatilimciRoluModal} toggle={handleCloseCreateKatilimciRoluModal} centered>
        <ModalHeader data-cy="refAdtFormUpdateDialogHeading" toggle={handleCloseCreateKatilimciRoluModal}>
          <p className="modal-title-info">
            {selectedPersonel?.sicil}
            {' / '}
            {selectedPersonel?.adi} {selectedPersonel?.soyadi}
          </p>
          <Translate contentKey="tk24AdysApp.refKatilimci.home.createRolLabel">Create a refKatilimciRol</Translate>
        </ModalHeader>

        <Form onSubmit={saveEntityKatilimci}>
          <ModalBody>
            <FormItem
              label={translate('tk24AdysApp.refKatilimci.katilimciRolu.adi')}
              id="ref-katilimci-katilimciRolu-id"
              name="katilimciRoluId"
              defaultValue={
                selectedRefKatilimci &&
                katilimciRoluList.length > 0 &&
                selectedRefKatilimci['katilimciRolu'] &&
                selectedRefKatilimci.katilimciRolu.id
              }
              type="select"
              validation={{
                required: { value: true, message: translate('entity.validation.required') },
              }}
            >
              <option value="" key="0" />
              {katilimciRoluList.length > 0
                ? katilimciRoluList
                    .filter(item => item.id === ReferansEnum.KatilimciRolu.AdymBaskani || item.id === ReferansEnum.KatilimciRolu.AdymUyesi)
                    .map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.adi}
                      </option>
                    ))
                : null}
            </FormItem>
          </ModalBody>

          <ModalFooter className="modal-footer">
            <Button className="cancel-button" onClick={handleCloseCreateKatilimciRoluModal}>
              <Translate contentKey="entity.action.cancel">Cancel</Translate>
            </Button>
            &nbsp;
            <Button className="save-button" id="jhi-confirm-delete-refAdtForm" type="submit" data-cy="entityConfirmButton">
              <Translate contentKey="entity.action.save">Kaydet</Translate>
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    );
  };

  function RefKatilimciTable({ refKatilimci, isOpen, openTable }) {
    return (
      <>
        <TableRow className="accordion-content-row" sx={{ '& > *': { borderBottom: 'unset' } }} onClick={openTable}>
          <TableCell className="accordion-content-cell" style={{ width: 48 }}>
            <IconButton aria-label="expand row" size="small" className="accordion-icon">
              {isOpen ? <KeyboardArrowDownOutlined /> : <KeyboardArrowRight />}
            </IconButton>
          </TableCell>
          <TableCell className="accordion-content-cell" component="th" scope="row">
            {refKatilimci.sicil}
          </TableCell>

          <TableCell className="accordion-content-cell" component="th" scope="row">
            {refKatilimci.personel?.thumbnail ? (
              <div className="hoverable-image-container">
                <img
                  className="hoverable-image"
                  style={{
                    ...initialSquare,
                    transition: 'width 0.3s, height 0.3s',
                  }}
                  src={`data:${refKatilimci.personel?.thumbnail?.contentType};base64,${refKatilimci.personel?.thumbnail?.base64}`}
                  alt={''}
                />
              </div>
            ) : (
              <div style={initialSquare}>
                <span style={{ fontSize: '14px' }}>
                  {refKatilimci.soyadi ? (
                    <>
                      {refKatilimci.adi.charAt(0)}
                      {refKatilimci.soyadi.charAt(0)}
                    </>
                  ) : (
                    refKatilimci.adi.charAt(0)
                  )}
                </span>
              </div>
            )}
          </TableCell>

          <TableCell className="accordion-content-cell" component="th" scope="row">
            {refKatilimci.adi}
          </TableCell>
          <TableCell className="accordion-content-cell" component="th" scope="row">
            {refKatilimci.soyadi}
          </TableCell>
          <TableCell className="accordion-content-cell" component="th" scope="row">
            {refKatilimci.personel ? refKatilimci.personel.unvanAdi : '-'}
          </TableCell>
          <TableCell className="accordion-content-cell" component="th" scope="row">
            {refKatilimci.org?.adi}
          </TableCell>
          <TableCell className="accordion-content-cell" component="th" scope="row">
            {refKatilimci.katilimciRolu?.adi}
          </TableCell>
          <TableCell className="accordion-content-cell" component="th" scope="row">
            {refKatilimci.bilgiSonGuncellenmeZamani ? (
              <TextFormat type="date" value={refKatilimci.bilgiSonGuncellenmeZamani} format={APP_DATE_FORMAT} />
            ) : null}
          </TableCell>
          <TableCell className="accordion-content-cell" component="th" scope="row">
            {refKatilimci.org?.sirket?.adi}
          </TableCell>

          <TableCell align="right" className="sticky-right">
            <div className="btn-group flex-btn-group-container">
              <GrantedButton
                grantedAuthorites={['ref_katilimci_edit']}
                className="edit-button"
                style={{ marginLeft: 10 }}
                onClick={event => {
                  event.stopPropagation();
                  setSelectedRefKatilimci(refKatilimci);
                  setOpenItemId(null);
                  setIsRefKatilimciUpdateModal(true);
                }}
                size="sm"
                data-cy="entityEditButton"
              >
                <Tooltip title={<Translate contentKey="entity.action.edit">Edit</Translate>}>
                  <img width={14} height={14} src={`${SERVER_URL}/content/images/icon/faPencil.svg`} />
                </Tooltip>
                <span className="show-on-lg">
                  <Translate contentKey="entity.action.edit">Edit</Translate>
                </span>
              </GrantedButton>
              <GrantedButton
                grantedAuthorites={['ref_katilimci_delete']}
                onClick={event => {
                  event.stopPropagation();
                  setOpenItemId(null);
                  confirmRefKatilimciDelete(refKatilimci);
                }}
                style={{ marginLeft: 10 }}
                className="delete-button"
                size="sm"
                data-cy="entityDeleteButton"
              >
                <Tooltip title={<Translate contentKey="entity.action.delete">Delete</Translate>}>
                  <img width={11.2} height={12.8} src={`${SERVER_URL}/content/images/icon/faTrashCan.svg`} />
                </Tooltip>
                <span className="show-on-lg">
                  <Translate contentKey="entity.action.delete">Delete</Translate>
                </span>
              </GrantedButton>
            </div>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell className="accordion-content-area" colSpan={12}>
            <Collapse in={isOpen} unmountOnExit>
              <div>
                <Nav className="custom-nav-tabs mt-3" tabs>
                  <NavItem>
                    <NavLink
                      className={classnames('custom-nav-link', { active: activeTab === 1 })}
                      onClick={() => {
                        setActiveTab(1);
                      }}
                    >
                      <Translate contentKey="tk24AdysApp.refKatilimciIletisim.home.title" />
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames('custom-nav-link', { active: activeTab === 2 })}
                      onClick={() => {
                        setActiveTab(2);
                      }}
                    >
                      <Translate contentKey="tk24AdysApp.refKatilimciVekil.home.title" />
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames('custom-nav-link', { active: activeTab === 3 })}
                      onClick={() => {
                        setActiveTab(3);
                      }}
                    >
                      <Translate contentKey="tk24AdysApp.refKatilimciDdmsDokuman.home.title" />
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent activeTab={activeTab}>
                  <TabPane tabId={1}>
                    <RefKatilimciIletisim refKatilimci={selectedRefKatilimci} />
                  </TabPane>
                  <TabPane tabId={2}>
                    <RefKatilimciVekil refKatilimci={selectedRefKatilimci} />
                  </TabPane>
                  <TabPane tabId={3}>
                    <RefKatilimciDdmsDokuman refKatilimci={selectedRefKatilimci} />
                  </TabPane>
                </TabContent>
              </div>
            </Collapse>
          </TableCell>
        </TableRow>
      </>
    );
  }

  const sort = p => () => {
    setOpenItemId(null);
    const orderBy = pageObject.sort.split(',')[1];
    setPageObject({ ...pageObject, sort: `${p},${orderBy === ASC ? DESC : ASC}` });
  };

  const getKatilimciFormTable = (refKatilimci: IRefKatilimci) => {
    if (openItemId === refKatilimci?.id) {
      setOpenItemId(null);
      setSelectedRefKatilimci(null);
    } else {
      setOpenItemId(refKatilimci?.id);
      setSelectedRefKatilimci(refKatilimci);
      setActiveTab(1);
    }
  };

  const handleSearchChange = (elem, event) => {
    if (!isNullOrEmpty(event.target.value)) {
      setPageObject(prevPageObject => ({
        ...prevPageObject,
        page: 0,
        query: prevPageObject.query + `&${elem}.equals=${event.target.value}`,
      }));
    } else {
      const keyToDelete = `${elem}.equals`;
      const updatedParams = pageObject.query
        .split('&')
        .filter(param => {
          const [key, _] = param.split('=');
          return key !== keyToDelete;
        })
        .join('&');
      setPageObject({ ...pageObject, page: 0, query: updatedParams });
    }
  };

  return (
    <div>
      {(loading || updating || refLoading || saveLoading) && <LoadingSpinner />}
      <CustomSaveEntityConfirm entity={selectedRefKatilimci} isOpen={isSaveModalOpen} onConfirm={handleConfirm} onCancel={handleCancel} />
      {selectedRefKatilimci && (
        <CustomDeleteEntityConfirm
          entity={selectedRefKatilimci}
          entityName="refKatilimci"
          isOpen={isDeleteModalOpen}
          onConfirm={handleDeleteConfirm}
          onCancel={handleDeleteCancel}
        />
      )}
      {isRefKatilimciCreateModal && (
        <PersonelSearch
          isPersonelModal={isRefKatilimciCreateModal}
          titleContentKey="tk24AdysApp.refKatilimci.home.createLabel"
          handleClosePersonelForm={handleCloseCreateKatilimciModal}
          saveEntityPersonel={savePersonelKatilimciIslem}
          havalimaniFilter={true}
          dataToCompare={[]}
        />
      )}
      {isRefKatilimciUpdateModal && RefEntityUpdateModal()}
      {isKatilimciRoluModal && katilimciRoluModal()}

      <div style={{ display: 'flex', gap: 8, textAlign: 'center' }}>
        <p className="icon-text">
          <a className="link-a-text" href={`${SERVER_URL}`}>
            <img width={13} height={13} src={`${SERVER_URL}/content/images/icon/faHouse.svg`} />
          </a>
        </p>
        <p className="separator"> / </p>
        <p className="link-text">
          {' '}
          <Translate contentKey="tk24AdysApp.refKatilimci.home.title" />{' '}
        </p>
      </div>

      <h2 id="ref-adt-ekip-heading" data-cy="RefAdtEkipDokumanHeading" className="title-frame">
        <a className="link-a-text" onClick={() => props.history.goBack()}>
          <img width={27.5} height={22.5} src={`${SERVER_URL}/content/images/icon/faArrowLeft.svg`} />{' '}
        </a>
        <p className="title-text">
          <Translate contentKey="tk24AdysApp.refKatilimci.home.title" />
        </p>
      </h2>

      <div className="content-area">
        <div className="search-frame">
          <div className="seachiconposition">
            <img className="seachicon" width={24} height={24} src={`${SERVER_URL}/content/images/icon/faSearch.svg`} />
            <Input
              value={search}
              onChange={e => setSearch(e.target.value)}
              placeholder={translate('entity.action.search')}
              className="search-input"
            />
          </div>
          <div className="create-area">
            <Box sx={{ minWidth: 220, maxWidth: 220 }}>
              <FormControl fullWidth>
                <InputLabel id="katilimci-rolu-label" className="select-input-label">
                  <Translate contentKey="tk24AdysApp.refKatilimci.katilimciRolu.adi" />
                </InputLabel>
                <Select
                  labelId="katilimci-rolu-label"
                  id="katilimci-rolu"
                  label={translate('tk24AdysApp.refKatilimci.katilimciRolu.adi')}
                  onChange={event => handleSearchChange('katilimciRoluId', event)}
                  MenuProps={MenuProps}
                  className="select-input"
                >
                  <MenuItem value="">
                    <Translate contentKey="entity.action.all" />
                  </MenuItem>
                  {katilimciRoluList &&
                    katilimciRoluList.length > 0 &&
                    katilimciRoluList
                      .filter(
                        item => item.id === ReferansEnum.KatilimciRolu.AdymBaskani || item.id === ReferansEnum.KatilimciRolu.AdymUyesi
                      )
                      .map(item => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.adi}
                        </MenuItem>
                      ))}
                </Select>
              </FormControl>
            </Box>
            <ExcelDownloadButton
              excelSampleData={ExcelSampleData}
              grantedAuthorites={['ref_katilimci_export']}
              fetchData={fetchTotalRefKatilimciData}
              className={'download-button'}
              exportName={translate('tk24AdysApp.refKatilimci.home.title')}
            />

            <GrantedButton
              grantedAuthorites={['ref_katilimci_edit']}
              onClick={() => {
                setOpenItemId(null);
                setIsRefKatilimciCreateModal(true);
              }}
              className="create-button"
              size="sm"
              data-cy="entityCreateButton"
            >
              <Tooltip title={<Translate contentKey="tk24AdysApp.refKatilimci.home.createLabel" />}>
                <img width={14} height={14} src={`${SERVER_URL}/content/images/icon/faPlus.svg`} />
              </Tooltip>
              <span className="show-on-lg">
                <Translate contentKey="tk24AdysApp.refKatilimci.home.createLabel" />
              </span>
            </GrantedButton>
          </div>
        </div>
        <div className="table-responsive" style={{ marginTop: 5 }}>
          <TableContainer className="content-table">
            <Table aria-label="collapsible table">
              <TableHead>
                <TableRow className="accordion-title-row">
                  <TableCell className="accordion-title-cell" style={{ width: 48 }} />
                  <TableCell className="accordion-title-cell hand" align="left" onClick={sort('sicil')}>
                    <div className="tableHeaderContainer">
                      <Translate contentKey="tk24AdysApp.refKatilimci.sicilTitle">sicil</Translate>&nbsp;
                      <SortIcon column="sicil" activeSortColumn={pageObject.sort} />
                    </div>
                  </TableCell>
                  <TableCell className="table-title-cell hand" align="left">
                    <Translate contentKey="tk24AdysApp.krizKatilimci.img">img</Translate>&nbsp;
                  </TableCell>
                  <TableCell className="accordion-title-cell hand" align="left" onClick={sort('adi')}>
                    <div className="tableHeaderContainer">
                      <Translate contentKey="tk24AdysApp.refKatilimci.adi">Adi</Translate>&nbsp;
                      <SortIcon column="adi" activeSortColumn={pageObject.sort} />
                    </div>
                  </TableCell>
                  <TableCell className="accordion-title-cell hand" align="left" onClick={sort('soyadi')}>
                    <div className="tableHeaderContainer">
                      <Translate contentKey="tk24AdysApp.refKatilimci.soyadi">Soyadi</Translate>&nbsp;
                      <SortIcon column="soyadi" activeSortColumn={pageObject.sort} />
                    </div>
                  </TableCell>
                  <TableCell className="accordion-title-cell hand" align="left" onClick={sort('unvanAdi')}>
                    <div className="tableHeaderContainer">
                      <Translate contentKey="tk24AdysApp.refKatilimci.unvanAdiTitle">Unvan Adi</Translate>&nbsp;
                      <SortIcon column="unvanAdi" activeSortColumn={pageObject.sort} />
                    </div>
                  </TableCell>
                  <TableCell
                    className="accordion-title-cell hand"
                    align="left"
                    onClick={sort(`orgAdi${upperOnlyFirstLetter(localeLanguage)}`)}
                  >
                    <div className="tableHeaderContainer">
                      <Translate contentKey="tk24AdysApp.refKatilimci.uniteTitle">uniteTitle</Translate>&nbsp;
                      <SortIcon column={`orgAdi${upperOnlyFirstLetter(localeLanguage)}`} activeSortColumn={pageObject.sort} />
                    </div>
                  </TableCell>

                  <TableCell
                    className="accordion-title-cell hand"
                    align="left"
                    onClick={sort(`katilimciRolu.adi${upperOnlyFirstLetter(localeLanguage)}`)}
                  >
                    <div className="tableHeaderContainer">
                      <Translate contentKey="tk24AdysApp.refKatilimci.katilimciRolu.adiTitle">Katilimci Rolu Adi</Translate>&nbsp;
                      <SortIcon column={`katilimciRolu.adi${upperOnlyFirstLetter(localeLanguage)}`} activeSortColumn={pageObject.sort} />
                    </div>
                  </TableCell>

                  <TableCell className="accordion-title-cell hand" align="left" onClick={sort('bilgiSonGuncellenmeZamani')}>
                    <div className="tableHeaderContainer">
                      <Translate contentKey="tk24AdysApp.refKatilimci.bilgiSonGuncellenmeZamaniTitle">
                        Bilgi Son Guncellenme Zamani
                      </Translate>
                      &nbsp;
                      <SortIcon column="bilgiSonGuncellenmeZamani" activeSortColumn={pageObject.sort} />
                    </div>
                  </TableCell>

                  <TableCell
                    className="accordion-title-cell hand"
                    align="left"
                    onClick={sort(`org.sirket.adi${upperOnlyFirstLetter(localeLanguage)}`)}
                  >
                    <div className="tableHeaderContainer">
                      <Translate contentKey="tk24AdysApp.refKatilimci.sirket">sirket</Translate>&nbsp;
                      <SortIcon column={`org.sirket.adi${upperOnlyFirstLetter(localeLanguage)}`} activeSortColumn={pageObject.sort} />
                    </div>
                  </TableCell>
                  <TableCell className="sticky-right-header-accordion" />
                </TableRow>
              </TableHead>
              <TableBody>
                {refKatilimciList &&
                  refKatilimciList?.map(refKatilimci => (
                    <RefKatilimciTable
                      key={refKatilimci?.id}
                      refKatilimci={refKatilimci}
                      isOpen={openItemId === refKatilimci?.id}
                      openTable={() => getKatilimciFormTable(refKatilimci)}
                    />
                  ))}
              </TableBody>
            </Table>
            {!loading && refKatilimciList.length === 0 && (
              <div className="alert alert-warning">
                <Translate contentKey="tk24AdysApp.refKatilimci.home.notFound">Ref Katilimci.</Translate>
              </div>
            )}
          </TableContainer>
        </div>
        <CustomPagination
          currentPage={pageObject.page + 1}
          currentPageSize={pageObject.size}
          totalItems={totalItems}
          handlePageChange={handlePageChange}
        />
      </div>
    </div>
  );
};

export default RefKatilimci;
