import React from 'react';
import * as ExcelJS from 'exceljs';
import './style.scss';
import { Translate } from 'app/component/jhipster';
import { SERVER_URL } from 'app/config/constants';
import GrantedButton from 'app/component/GrantedButton';

const CustomDownloadExcelButton = ({ fetchData, exportName, className = null, grantedAuthorites }) => {
  const handleDownload = async () => {
    const sampleData = await fetchData();

    if (!sampleData || sampleData.length === 0) {
      console.error('No data available for download');
      return;
    }

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Sheet1');

    const hiddenColumns = ['id', 'guid', 'realId', 'uygulamaId', 'cDate', 'cUser', 'mDate', 'mUser', 'durum'];
    const columns = Object.keys(sampleData[0]).filter(column => !hiddenColumns.includes(column));
    worksheet.addRow(columns);

    sampleData.forEach(row => {
      const filteredRow = columns.map(column => {
        const cellValue = row[column];
        if (cellValue !== null && typeof cellValue === 'object') {
          return cellValue.adi !== undefined ? cellValue.adi : JSON.stringify(cellValue);
        } else {
          return cellValue;
        }
      });
      worksheet.addRow(filteredRow);
    });

    const excelBlob = await workbook.xlsx.writeBuffer();
    const data = new Blob([excelBlob], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(data);
    link.download = `${exportName}.xlsx`;
    link.click();
  };

  return (
    <div style={{ marginLeft: 5 }}>
      <GrantedButton
        grantedAuthorites={grantedAuthorites}
        className={className ? className : 'searchButtonBox'}
        type="button"
        onClick={handleDownload}
      >
        <img src={`${SERVER_URL}/content/images/icon/faArrowDownToLine.svg`} />
        <span className="d-none d-md-inline">
          <Translate contentKey="entity.action.export" />
        </span>
      </GrantedButton>
    </div>
  );
};

export default CustomDownloadExcelButton;
