import { translate } from 'app/component/jhipster';
import { SERVER_URL } from 'app/config/constants';
import { hasAnyAuthority } from 'app/config/store';
import React from 'react';

const DashboardComponent = ({ component: Component, yetki }) => {
  const hasPermission = hasAnyAuthority([yetki]);

  return (
    <>
      {hasPermission ? (
        <>
          <Component />
        </>
      ) : (
        <div className="noTasksMessageYetki">
          <div>
            <img src={`${SERVER_URL}/content/images/icon/gorevError.svg`} className="mb-3" style={{ width: '190px' }} alt="Error" />
            <h3>{translate(`entity.confirm.yetkiYok`)}</h3>
          </div>
        </div>
      )}
    </>
  );
};

export default DashboardComponent;
