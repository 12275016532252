import React, { useState } from 'react';
import { Button, UncontrolledTooltip, Form, ModalFooter, ModalBody } from 'reactstrap';
import { Translate, translate } from 'app/component/jhipster';
import { updateEntity, createEntity } from './ref-adt-form-bolum.reducer';
import { useAppDispatch } from 'app/config/store';
import { IRefAdtFormBolum } from 'app/shared/model/ref-adt-form-bolum.model';
import { CustomSaveEntityConfirm, isNullOrEmpty, saveEntityConfirm } from 'app/shared/util/confirm-utils';
import { FormItem } from 'app/component/FormItem';
import { IRefAdtForm } from 'app/shared/model/ref-adt-form.model';
import axios from 'axios';
import { toast } from 'react-toastify';

interface RefAdtFormBolumUpdateProp {
  handleCloseForm: () => void;
  formValues?: IRefAdtFormBolum;
  form: IRefAdtForm;
  totalItems: number;
}

export const RefAdtFormUpdate: React.FC<RefAdtFormBolumUpdateProp> = ({ handleCloseForm, formValues, form, totalItems }) => {
  const dispatch = useAppDispatch();

  const checkFieldExistence = async (fieldValue, fieldName) => {
    try {
      const response = await axios.get(`api/ref-adt-form-bolums?formId.equals=${form.id}&${fieldName}.contains=${fieldValue}`);
      return response.data;
    } catch (error) {
      console.error(`Alan varlık kontrolünde bir hata oluştu: ${fieldName}`, error);
      return null;
    }
  };
  const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);
  const [modalPromiseResolve, setModalPromiseResolve] = useState(null);
  const showToast = message => {
    toast.error(message);
  };

  const showSaveConfirmModal = () => {
    setIsSaveModalOpen(true);
    return new Promise(resolve => {
      setModalPromiseResolve(() => resolve);
    });
  };

  const handleConfirm = () => {
    if (modalPromiseResolve) {
      modalPromiseResolve(true);
    }
    setIsSaveModalOpen(false);
  };

  const handleCancel = () => {
    if (modalPromiseResolve) {
      modalPromiseResolve(false);
    }
    setIsSaveModalOpen(false);
  };

  const saveEntity = async e => {
    e.preventDefault();
    const isSave = await showSaveConfirmModal();
    if (isSave) {
      const formDataObject = {};
      for (const field of e.target.elements) {
        if (field.name) {
          formDataObject[field.name] = field.value;
        }
      }

      const adiTrValue = formDataObject['adiTr'];
      const adiEnValue = formDataObject['adiEn'];
      const isAdiTrExist = await checkFieldExistence(adiTrValue, 'adiTr');
      const isAdiEnExist = await checkFieldExistence(adiEnValue, 'adiEn');
      if (
        formValues === null &&
        ((isAdiTrExist !== null && isAdiTrExist.length > 0) || (isAdiEnExist !== null && isAdiEnExist.length > 0))
      ) {
        showToast(translate('entity.confirm.alanlarVar'));
      } else {
        if (formValues !== null) {
          const entity = {
            ...formValues,
            ...formDataObject,
            form,
          };
          await dispatch(updateEntity(entity));
        } else {
          const entity = {
            ...formValues,
            ...formDataObject,
            sortIndex: totalItems + 1,
            form,
          };
          await dispatch(createEntity(entity));
        }
        handleCloseForm();
      }
    }
  };

  return (
    <>
      <CustomSaveEntityConfirm entity={formValues} isOpen={isSaveModalOpen} onConfirm={handleConfirm} onCancel={handleCancel} />

      <Form onSubmit={saveEntity}>
        <ModalBody>
          <FormItem
            label={translate('global.languageGlobal.adiTr')}
            id="ref-adt-form-bolum-adiTr"
            name="adiTr"
            defaultValue={formValues && formValues['adiTr']}
            type="text"
            validation={{
              required: { value: true, message: translate('entity.validation.required') },
              maxLength: { value: 100, message: translate('entity.validation.maxlength', { max: 100 }) },
            }}
          />
          <UncontrolledTooltip target="adiTrLabel">
            <Translate contentKey="global.languageGlobal.adiTr" />
          </UncontrolledTooltip>

          <FormItem
            label={translate('global.languageGlobal.adiEn')}
            id="ref-adt-form-bolum-adiEn"
            name="adiEn"
            defaultValue={formValues && formValues['adiEn']}
            type="text"
            validation={{
              required: { value: true, message: translate('entity.validation.required') },
              maxLength: { value: 100, message: translate('entity.validation.maxlength', { max: 100 }) },
            }}
          />
          <UncontrolledTooltip target="adiEnLabel">
            <Translate contentKey="global.languageGlobal.adiEn" />
          </UncontrolledTooltip>

          <FormItem
            label={translate('tk24AdysApp.refAdtFormBolum.aciklamaTr')}
            id="aciklama-tr"
            name="aciklamaTr"
            defaultValue={formValues && formValues['aciklamaTr']}
            type="textarea"
            validation={{
              required: { value: true, message: translate('entity.validation.required') },
              maxLength: { value: 1500, message: translate('entity.validation.maxlength', { max: 1500 }) },
            }}
          />

          <FormItem
            label={translate('tk24AdysApp.refAdtFormBolum.aciklamaEn')}
            id="aciklama-en"
            name="aciklamaEn"
            defaultValue={formValues && formValues['aciklamaEn']}
            type="textarea"
            validation={{
              required: { value: true, message: translate('entity.validation.required') },
              maxLength: { value: 1500, message: translate('entity.validation.maxlength', { max: 1500 }) },
            }}
          />
        </ModalBody>
        <ModalFooter>
          <Button className="cancel-button" onClick={handleCloseForm}>
            <Translate contentKey="entity.action.cancel">Cancel</Translate>
          </Button>
          &nbsp;
          <Button id="jhi-confirm-delete-refAdtForm" type="submit" data-cy="entityConfirmButton" className="save-button">
            <Translate contentKey="entity.action.save">Kaydet</Translate>
          </Button>
        </ModalFooter>
      </Form>
    </>
  );
};

export default RefAdtFormUpdate;
