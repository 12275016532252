import React, { FormEventHandler, useEffect, useRef, useState } from 'react';
import { Button, UncontrolledTooltip, Form, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Translate, translate } from 'app/component/jhipster';
import { FormItem } from 'app/component/FormItem';
import { IRefSaglikDurumu } from 'app/shared/model/ref-saglik-durumu.model';
import { SketchPicker } from 'react-color';

interface RefSaglikDurumuUpdateProp {
  saveNewRefEntity: FormEventHandler;
  handleCloseForm: FormEventHandler;
  formValues?: IRefSaglikDurumu;
  isOpen: boolean;
}

export const RefSaglikDurumuUpdate: React.FC<RefSaglikDurumuUpdateProp> = ({ saveNewRefEntity, handleCloseForm, formValues, isOpen }) => {
  const [fontColorValue, setFontColorValue] = useState((formValues && formValues['cssBilgisiFontColor']) || '#ffffff');
  const [bgColorValue, setBgColorValue] = useState((formValues && formValues['cssBilgisiBgColor']) || '#ffffff');
  const [borderColorValue, setBorderColorValue] = useState((formValues && formValues['cssBilgisiBorderColor']) || '#ffffff');
  const [showFontColorPicker, setShowFontColorPicker] = useState(false);
  const [showBgColorPicker, setShowBgColorPicker] = useState(false);
  const [showBorderColorPicker, setShowBorderColorPicker] = useState(false);
  const fontColorPickerRef = useRef(null);
  const bgColorPickerRef = useRef(null);
  const borderColorPickerRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = event => {
      if (fontColorPickerRef.current && !fontColorPickerRef.current.contains(event.target)) {
        setShowFontColorPicker(false);
      }
      if (bgColorPickerRef.current && !bgColorPickerRef.current.contains(event.target)) {
        setShowBgColorPicker(false);
      }
      if (borderColorPickerRef.current && !borderColorPickerRef.current.contains(event.target)) {
        setShowBorderColorPicker(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleFontColorClick = () => {
    setShowFontColorPicker(true);
  };

  const handleBgColorClick = () => {
    setShowBgColorPicker(true);
  };

  const handleBorderColorClick = () => {
    setShowBorderColorPicker(true);
  };

  const handleFontColorPickerClose = () => {
    setShowFontColorPicker(false);
  };

  const handleBgColorPickerClose = () => {
    setShowBgColorPicker(false);
  };

  const handleBorderColorPickerClose = () => {
    setShowBorderColorPicker(false);
  };

  return (
    <Modal className="modal-style" isOpen={isOpen} toggle={handleCloseForm} centered>
      <ModalHeader data-cy="RefKrizSaglikDurumuCreateUpdateHeading" toggle={handleCloseForm}>
        {formValues && (
          <p className="modal-title-info">
            {formValues?.adiTr}-{formValues?.adiEn}
          </p>
        )}
        {formValues ? (
          <Translate contentKey="tk24AdysApp.refSaglikDurumu.home.editLabel">Update a Ref Saglik Durumu</Translate>
        ) : (
          <Translate contentKey="tk24AdysApp.refSaglikDurumu.home.createLabel">Create a Ref Saglik Durumu</Translate>
        )}
      </ModalHeader>
      <Form onSubmit={saveNewRefEntity}>
        <ModalBody>
          <FormItem
            label={translate('global.languageGlobal.adiTr')}
            id="ref-saglik-durumu-adiTr"
            name="adiTr"
            defaultValue={formValues && formValues['adiTr']}
            type="text"
            validation={{
              required: { value: true, message: translate('entity.validation.required') },
              maxLength: { value: 100, message: translate('entity.validation.maxlength', { max: 100 }) },
            }}
          />
          <UncontrolledTooltip target="adiTrLabel">
            <Translate contentKey="global.languageGlobal.adiTr" />
          </UncontrolledTooltip>

          <FormItem
            label={translate('global.languageGlobal.adiEn')}
            id="ref-saglik-durumu-adiEn"
            name="adiEn"
            defaultValue={formValues && formValues['adiEn']}
            type="text"
            validation={{
              required: { value: true, message: translate('entity.validation.required') },
              maxLength: { value: 100, message: translate('entity.validation.maxlength', { max: 100 }) },
            }}
          />
          <UncontrolledTooltip target="adiEnLabel">
            <Translate contentKey="global.languageGlobal.adiEn" />
          </UncontrolledTooltip>

          <FormItem
            label={translate('tk24AdysApp.refSaglikDurumu.cssBilgisiFontColor')}
            id="ref-saglik-durumu-cssBilgisiFontColor"
            name="cssBilgisiFontColor"
            value={fontColorValue}
            type="text"
            onClick={handleFontColorClick}
            validation={{
              maxLength: { value: 7, message: translate('entity.validation.maxlength', { max: 7 }) },
            }}
          />
          {showFontColorPicker && (
            <div ref={fontColorPickerRef} style={{ position: 'absolute', zIndex: '2' }}>
              <div className="sketchpicker-card">
                <div className="sketchpicker-head">
                  <div className="sketchpicker-close-button" onClick={handleFontColorPickerClose}>
                    <span className="sketchpicker-close-button-content">&times;</span>
                  </div>
                </div>
                <div>
                  <SketchPicker
                    className="sketchpicker"
                    color={fontColorValue || formValues['cssBilgisiFontColor']}
                    onChange={color => setFontColorValue(color?.hex)}
                    width="300px"
                  />
                </div>
              </div>
            </div>
          )}

          <FormItem
            label={translate('tk24AdysApp.refSaglikDurumu.cssBilgisiBgColor')}
            id="ref-saglik-durumu-cssBilgisiBgColor"
            name="cssBilgisiBgColor"
            value={bgColorValue}
            type="text"
            onClick={handleBgColorClick}
            validation={{
              maxLength: { value: 7, message: translate('entity.validation.maxlength', { max: 7 }) },
            }}
          />
          {showBgColorPicker && (
            <div ref={bgColorPickerRef} style={{ position: 'absolute', zIndex: '2' }}>
              <div className="sketchpicker-card">
                <div className="sketchpicker-head">
                  <div className="sketchpicker-close-button" onClick={handleBgColorPickerClose}>
                    <span className="sketchpicker-close-button-content">&times;</span>
                  </div>
                </div>
                <div>
                  <SketchPicker
                    className="sketchpicker"
                    color={bgColorValue || formValues['cssBilgisiBgColor']}
                    onChange={color => setBgColorValue(color.hex)}
                    width="300px"
                  />
                </div>
              </div>
            </div>
          )}

          <FormItem
            label={translate('tk24AdysApp.refSaglikDurumu.cssBilgisiBorderColor')}
            id="ref-saglik-durumu-cssBilgisiBorderColor"
            name="cssBilgisiBorderColor"
            value={borderColorValue}
            type="text"
            onClick={handleBorderColorClick}
            validation={{
              maxLength: { value: 7, message: translate('entity.validation.maxlength', { max: 7 }) },
            }}
          />

          {showBorderColorPicker && (
            <div ref={borderColorPickerRef} style={{ position: 'absolute', zIndex: '2' }}>
              <div className="sketchpicker-card">
                <div className="sketchpicker-head">
                  <div className="sketchpicker-close-button" onClick={handleBorderColorPickerClose}>
                    <span className="sketchpicker-close-button-content">&times;</span>
                  </div>
                </div>
                <div>
                  <SketchPicker
                    className="sketchpicker"
                    color={borderColorValue || formValues['cssBilgisiBorderColor']}
                    onChange={color => setBorderColorValue(color.hex)}
                    width="300px"
                  />
                </div>
              </div>
            </div>
          )}
        </ModalBody>
        <ModalFooter className="modal-footer">
          <Button className="cancel-button" onClick={handleCloseForm}>
            <Translate contentKey="entity.action.cancel">Cancel</Translate>
          </Button>
          &nbsp;
          <Button className="save-button" id="jhi-confirm-delete-refAdtForm" type="submit" data-cy="entityConfirmButton">
            <Translate contentKey="entity.action.save">Kaydet</Translate>
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default RefSaglikDurumuUpdate;
