import { FormItem } from 'app/component/FormItem';
import { IKrizKatilimci } from 'app/shared/model/kriz-katilimci.model';
import React, { useEffect, useState } from 'react';
import { Translate, translate } from 'app/component/jhipster';
import { Button, Col, Form, Modal, ModalBody, ModalFooter, ModalHeader, UncontrolledTooltip } from 'reactstrap';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import GrantedButton from 'app/component/GrantedButton';
import { SERVER_URL } from 'app/config/constants';
import { CustomSaveEntityConfirm, deleteEntityConfirm, saveEntityConfirm } from 'app/shared/util/confirm-utils';
import { ReferansEnum } from 'app/constants/ReferansEnum';
import { IQueryParams } from 'app/shared/reducers/reducer.utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import {
  createKymEntity as createKatilimciGorevEntity,
  updateKymEntity as updateKatilimciGorevEntity,
} from 'app/entities/kriz-katilimci-gorev/kriz-katilimci-gorev.reducer';
import { IReferans } from 'app/shared/model/referans.model';
import { IKriz } from 'app/shared/model/kriz.model';
import { IKrizKatilimciGorev } from 'app/shared/model/kriz-katilimci-gorev.model';
import {
  createEntity as createSorBirimEntity,
  getEntities as getSorBirimEntity,
  deleteEntity as deleteSorBirimEntity,
} from '../kriz-katilimci-gorev-sorumlu-birim/kriz-katilimci-gorev-sorumlu-birim.reducer';
import { LoadingSpinner } from 'app/component/LoadingSpinner';
import RefOrganizasyonUpdate from '../ref-organizasyon/ref-organizasyon-update';
import { IKrizKatilimciGorevSorBirim } from 'app/shared/model/IKrizKatilimciGorevSorBirim.models';

interface GorevUpdateProp {
  referans?: readonly IReferans[];
  kriz?: IKriz;
  krizKatilimciEntity?: IKrizKatilimci;
  pageObject?: IQueryParams;
  handleCloseForm: () => void;
  isOpen: boolean;
  isOwnerUpdate?: boolean;
  krizKatilimci?: IKrizKatilimci[];
  formValues: IKrizKatilimciGorev;
}

export const GorevUpdate: React.FC<GorevUpdateProp> = ({
  referans,
  kriz,
  krizKatilimciEntity,
  pageObject,
  handleCloseForm,
  isOpen,
  isOwnerUpdate,
  krizKatilimci,
  formValues,
}) => {
  const dispatch = useAppDispatch();
  const [localFormValues, setLocalFormValues] = React.useState({
    ...formValues,
    sorumluBirims: formValues?.sorumluBirims || [],
  });
  const [isGorevSorBirimModal, setIsGorevSorBirimModal] = useState(false);
  const gorevSorBirimList = useAppSelector(state => state.krizKatilimciGorevSorumluBirim.entities);
  const gorevSorBirimLoading = useAppSelector(state => state.krizKatilimciGorevSorumluBirim.loading);
  const gorevSorBirimUpdating = useAppSelector(state => state.krizKatilimciGorevSorumluBirim.updating);
  const [loading, setLoading] = useState(false);
  const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);
  const [modalPromiseResolve, setModalPromiseResolve] = useState(null);
  const [sorumluBirimPageObejct, setSorumluBirimPageObejct] = useState<IQueryParams>({
    ...useAppSelector(state => state.paginate.pageObject),
    size: 200,
    query: `krizKatilimciGorevId.equals=${formValues?.id}`,
  });

  useEffect(() => {
    onLoadSorBirim();
  }, []);

  const onLoadSorBirim = () => {
    if (formValues) {
      dispatch(getSorBirimEntity(sorumluBirimPageObejct));
    }
  };

  const handleCloseUpdateForm = () => {
    setIsGorevSorBirimModal(false);
  };

  const removeSorBirim = sorumluUniteId => {
    const updatedSorumluBirims = localFormValues?.sorumluBirims?.filter(item => item?.org?.orgId !== sorumluUniteId);
    setLocalFormValues(prevValues => ({
      ...prevValues,
      sorumluBirims: updatedSorumluBirims,
    }));
  };

  const confirmSorBirimDelete = async (gorev: IKrizKatilimciGorevSorBirim) => {
    const isDel = await deleteEntityConfirm(gorev.id, 'refGorevSorBirim');
    if (isDel) {
      const params = {
        ...sorumluBirimPageObejct,
        entity: { ...gorev },
      };
      await dispatch(deleteSorBirimEntity(params));
    }
  };

  function createSorumluBirim(selectedUnite) {
    const alreadyExists = localFormValues?.sorumluBirims?.some(item => item?.org?.orgId === selectedUnite?.orgId);
    if (!alreadyExists) {
      const newUnite = {
        org: { ...selectedUnite },
      };
      setLocalFormValues(prevValues => ({
        ...prevValues,
        sorumluBirims: [...prevValues?.sorumluBirims, newUnite],
      }));
    }
  }

  const updateSorumluBirim = async unite => {
    const isSave = await saveEntityConfirm();
    if (isSave) {
      const params = {
        ...sorumluBirimPageObejct,
        entity: {
          krizKatilimciGorevId: formValues?.realId,
          org: { ...unite },
        },
      };
      await dispatch(createSorBirimEntity(params));
    }
  };

  const showSaveConfirmModal = () => {
    setIsSaveModalOpen(true);
    return new Promise(resolve => {
      setModalPromiseResolve(() => resolve);
    });
  };

  const handleConfirm = () => {
    if (modalPromiseResolve) {
      modalPromiseResolve(true);
    }
    setIsSaveModalOpen(false);
  };

  const handleCancel = () => {
    if (modalPromiseResolve) {
      modalPromiseResolve(false);
    }
    setIsSaveModalOpen(false);
  };

  const saveNewTask = async e => {
    e.preventDefault();
    const isSave = await showSaveConfirmModal();

    if (isSave) {
      const formDataObject = {};
      for (const field of e.target.elements) {
        if (field.name) {
          formDataObject[field.name] = field.value;
        }
      }
      const gorevKatilimci =
        formDataObject['gorevKatilimciId'] === null || formDataObject['gorevKatilimciId'] === undefined
          ? krizKatilimciEntity
          : krizKatilimci?.find(item => item?.id?.toString() === formDataObject['gorevKatilimciId'].toString());

      const kEntity = {
        ...formValues,
        ...formDataObject,
        krizKatilimci: gorevKatilimci,
        gorevTuru: referans.find(it => it?.id === ReferansEnum.GorevTuru.AdymEkGorev),
        gorevSahibi: referans.find(it => it?.id === ReferansEnum.GorevSahibi.ADYM),
        kriz,
        sorumluBirims: localFormValues?.sorumluBirims,
      };

      if (formValues !== null) {
        const katilimciGorevEntity = {
          ...pageObject,
          entity: kEntity,
        };
        await dispatch(updateKatilimciGorevEntity(katilimciGorevEntity));
      } else {
        const katilimciGorevEntity = {
          ...pageObject,
          entity: kEntity,
        };
        await dispatch(createKatilimciGorevEntity(katilimciGorevEntity));
      }
      handleCloseForm();
    }
  };

  const GorevSorBirimUpdateModal = () => {
    return (
      <>
        <Modal isOpen={isGorevSorBirimModal} toggle={handleCloseUpdateForm} className="modal-style-lg" centered>
          <ModalHeader data-cy="refAdtFormUpdateDialogHeading" toggle={handleCloseUpdateForm}>
            <Translate contentKey="tk24AdysApp.refGorevSorBirim.home.createLabel" />
          </ModalHeader>
          <RefOrganizasyonUpdate
            formValues={formValues}
            createSorumluBirim={createSorumluBirim}
            updateSorumluBirim={updateSorumluBirim}
            localFormValues={localFormValues}
          />
        </Modal>
      </>
    );
  };

  const GorevSorBirimTable = ({ sorumluBirimList, isFormNull }) => {
    return (
      <Col md={12} style={{ padding: '0px' }}>
        <TableContainer className="content-table">
          <Table aria-label="a dense table">
            <TableHead className="content-table-title-area">
              <TableRow className="table-head-row">
                <TableCell className="table-title-cell hand" align="left">
                  <Translate contentKey="tk24AdysApp.refGorevSorBirim.sorumluUniteAdiTitle">Sorumlu Unite Adi</Translate>
                </TableCell>
                <TableCell className="table-title-cell"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sorumluBirimList.map(gorevSorBirim => (
                <TableRow key={gorevSorBirim.id} className="table-cell-row">
                  <TableCell className="table-content-cell" component="th" scope="row">
                    {gorevSorBirim.org?.adi}
                  </TableCell>
                  <TableCell className="table-content-cell" align="right">
                    <div className="btn-group flex-btn-group-container">
                      <GrantedButton
                        grantedAuthorites={['ref_gorev_sor_birim_delete']}
                        onClick={() => (isFormNull ? removeSorBirim(gorevSorBirim.org?.orgId) : confirmSorBirimDelete(gorevSorBirim))}
                        className="delete-button"
                        size="sm"
                        data-cy="entityDeleteButton"
                      >
                        <img width={11.2} height={12.8} src={`${SERVER_URL}/content/images/icon/faTrashCan.svg`} />
                        <span>
                          <Translate contentKey="entity.action.delete">Delete</Translate>
                        </span>
                      </GrantedButton>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Col>
    );
  };

  return (
    <>
      {(loading || gorevSorBirimLoading || gorevSorBirimUpdating) && <LoadingSpinner />}
      {isGorevSorBirimModal && GorevSorBirimUpdateModal()}
      <CustomSaveEntityConfirm entity={formValues} isOpen={isSaveModalOpen} onConfirm={handleConfirm} onCancel={handleCancel} />

      <Modal isOpen={isOpen} toggle={handleCloseForm} className="modal-style" centered>
        <ModalHeader data-cy="refAdtFormUpdateDialogHeading" toggle={handleCloseForm}>
          {formValues ? (
            <Translate contentKey="tk24AdysApp.refGorev.home.updateLabel">Update a Gorev</Translate>
          ) : (
            <Translate contentKey="tk24AdysApp.refGorev.home.createLabel">Create a Gorev</Translate>
          )}
        </ModalHeader>
        {formValues && (
          <div id="gorevDurumuName" className="gorevDurumuName">
            <p className="m-0">{formValues.adi}</p>
          </div>
        )}

        <Form onSubmit={saveNewTask}>
          <ModalBody className="modal-body">
            {isOwnerUpdate ? (
              <>
                <FormItem
                  type="select"
                  label={translate('tk24AdysApp.kriz.krizTuru')}
                  id="gorev-krizKatilimciId"
                  name="gorevKatilimciId"
                  defaultValue={formValues['krizKatilimci'] && formValues['krizKatilimci']['id']}
                  validation={{
                    required: { value: true, message: translate('entity.validation.required') },
                  }}
                >
                  {krizKatilimci
                    ? krizKatilimci.map(otherEntity => (
                        <option value={otherEntity.id} key={otherEntity.id}>
                          {otherEntity.adi} {otherEntity.soyadi} - {otherEntity.unvanAdi}
                        </option>
                      ))
                    : null}
                </FormItem>
              </>
            ) : (
              <>
                <p className="gorev-modal-title">
                  <Translate contentKey="global.languageGlobal.turkce" />
                </p>
                <FormItem
                  label={translate('tk24AdysApp.refGorev.placeholder.adi')}
                  id="ref-gorev-adiTr"
                  name="adiTr"
                  defaultValue={formValues && formValues['adiTr']}
                  type="textarea"
                  placeholder={translate('tk24AdysApp.refGorev.placeholder.gorevAdi')}
                  validation={{
                    required: { value: true, message: translate('entity.validation.required') },
                    maxLength: { value: 4000, message: translate('entity.validation.maxlength', { max: 4000 }) },
                  }}
                />
                <UncontrolledTooltip target="adiTrLabel">
                  <Translate contentKey="global.languageGlobal.adi" />
                </UncontrolledTooltip>

                <FormItem
                  label={translate('global.languageGlobal.aciklama')}
                  id="ref-gorev-aciklamaTr"
                  name="aciklamaTr"
                  defaultValue={formValues && formValues['aciklamaTr']}
                  type="textarea"
                  placeholder={translate('tk24AdysApp.refGorev.placeholder.gorevAciklama')}
                  validation={{
                    maxLength: { value: 4000, message: translate('entity.validation.maxlength', { max: 4000 }) },
                  }}
                />
                <UncontrolledTooltip target="aciklamaTrLabel">
                  <Translate contentKey="global.languageGlobal.aciklama" />
                </UncontrolledTooltip>

                <FormItem
                  label={translate('global.languageGlobal.gorevOzeti')}
                  id="ref-gorev-kisaAdiTr"
                  name="kisaAdiTr"
                  defaultValue={formValues && formValues['kisaAdiTr']}
                  type="text"
                  placeholder={translate('tk24AdysApp.refGorev.placeholder.kisaAdi')}
                  validation={{
                    maxLength: { value: 4000, message: translate('entity.validation.maxlength', { max: 4000 }) },
                  }}
                />

                <p className="gorev-modal-title">
                  <Translate contentKey="global.languageGlobal.ingilizce" />
                </p>
                <FormItem
                  label={translate('tk24AdysApp.refGorev.placeholder.adi')}
                  id="ref-gorev-adiEn"
                  name="adiEn"
                  defaultValue={formValues && formValues['adiEn']}
                  type="textarea"
                  placeholder={translate('tk24AdysApp.refGorev.placeholder.gorevAdi')}
                  validation={{
                    maxLength: { value: 4000, message: translate('entity.validation.maxlength', { max: 4000 }) },
                  }}
                />
                <UncontrolledTooltip target="adiEnLabel">
                  <Translate contentKey="global.languageGlobal.adi" />
                </UncontrolledTooltip>

                <FormItem
                  label={translate('global.languageGlobal.aciklama')}
                  id="ref-gorev-aciklamaEn"
                  name="aciklamaEn"
                  defaultValue={formValues && formValues['aciklamaEn']}
                  type="textarea"
                  placeholder={translate('tk24AdysApp.refGorev.placeholder.gorevAciklama')}
                  validation={{
                    maxLength: { value: 4000, message: translate('entity.validation.maxlength', { max: 4000 }) },
                  }}
                />
                <UncontrolledTooltip target="aciklamaEnLabel">
                  <Translate contentKey="global.languageGlobal.aciklama" />
                </UncontrolledTooltip>

                <FormItem
                  label={translate('global.languageGlobal.gorevOzeti')}
                  id="ref-gorev-kisaAdiEn"
                  name="kisaAdiEn"
                  defaultValue={formValues && formValues['kisaAdiEn']}
                  type="text"
                  placeholder={translate('tk24AdysApp.refGorev.placeholder.kisaAdi')}
                  validation={{
                    maxLength: { value: 4000, message: translate('entity.validation.maxlength', { max: 4000 }) },
                  }}
                />

                {formValues === null ? (
                  <>
                    {localFormValues.sorumluBirims.length > 0 && (
                      <GorevSorBirimTable sorumluBirimList={localFormValues.sorumluBirims} isFormNull={true} />
                    )}
                  </>
                ) : (
                  <>{gorevSorBirimList?.length > 0 && <GorevSorBirimTable sorumluBirimList={gorevSorBirimList} isFormNull={false} />}</>
                )}
              </>
            )}
          </ModalBody>
          <ModalFooter className="modal-footer">
            <Button className="cancel-button" onClick={handleCloseForm}>
              <Translate contentKey="entity.action.cancel">Cancel</Translate>
            </Button>
            <div style={{ display: 'flex' }}>
              &nbsp;
              {!isOwnerUpdate && (
                <div>
                  <GrantedButton
                    grantedAuthorites={['ref_gorev_sor_birim_edit']}
                    onClick={() => setIsGorevSorBirimModal(true)}
                    className="ilgili-unite-button"
                    size="sm"
                    data-cy="entityCreateButton"
                  >
                    <img width={20} height={20} style={{ marginBottom: '5px' }} src={`${SERVER_URL}/content/images/icon/additem.svg`} />{' '}
                    <span className="d-none d-md-inline">
                      <Translate contentKey="tk24AdysApp.refGorevSorBirim.ilgiliUniteEkleTitle" />
                    </span>
                  </GrantedButton>
                </div>
              )}
              &nbsp;
              <Button className="save-button" id="jhi-confirm-delete-refAdtForm" type="submit" data-cy="entityConfirmButton">
                <Translate contentKey="entity.action.save">Kaydet</Translate>
              </Button>
            </div>
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
};

export default GorevUpdate;
