import { SERVER_URL } from 'app/config/constants';
import React from 'react';

const ErrorPage = ({ children }) => {
  return (
    <div className="error-page">
      <img src={`${SERVER_URL}/content/images/icon/errorPage.svg`} className="error-image" />
      <div className="error-content">{children}</div>
    </div>
  );
};

export default ErrorPage;
