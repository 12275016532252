import axios from 'axios';

export const customFilterValues = async (apiName, queryString = null) => {
  try {
    const response = await axios.get(`/api/custom-filter-values/${apiName}${queryString ? `?${queryString}` : ''}`);
    return response.data;
  } catch (error) {
    return null;
  }
};

export const customFilterKeyValues = async (apiName, queryString = null) => {
  try {
    const response = await axios.get(`/api/custom-filter-key_values/${apiName}${queryString ? `?${queryString}` : ''}`);
    return response.data;
  } catch (error) {
    return null;
  }
};
