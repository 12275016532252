import React, { useState } from 'react';
import { Button, UncontrolledTooltip, Form, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Translate, translate } from 'app/component/jhipster';

import { useAppDispatch } from 'app/config/store';
import { CustomSaveEntityConfirm } from 'app/shared/util/confirm-utils';
import { FormItem } from 'app/component/FormItem';
import { IQueryParams } from 'app/shared/reducers/reducer.utils';
import { createEntity, updateEntity } from './ref-sablon-parametre.reducer';
import { IRefSablonParametre } from 'app/shared/model/ref-sablon-parametre.model';
import { ReferansEnum } from 'app/constants/ReferansEnum';

interface RefSablonParametreUpdateProp {
  handleCloseForm: () => void;
  formValues?: IRefSablonParametre;
  isOpen: boolean;
  pageObject?: IQueryParams;
}

export const RefSablonParametreUpdate: React.FC<RefSablonParametreUpdateProp> = ({ handleCloseForm, formValues, isOpen, pageObject }) => {
  const dispatch = useAppDispatch();
  const [customIsOpen, setCustomIsOpen] = useState(isOpen);
  const [modalPromiseResolve, setModalPromiseResolve] = useState(null);
  const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);

  const showSaveConfirmModal = () => {
    setIsSaveModalOpen(true);
    return new Promise(resolve => {
      setModalPromiseResolve(() => resolve);
    });
  };

  const handleConfirm = () => {
    if (modalPromiseResolve) {
      modalPromiseResolve(true);
    }
    setIsSaveModalOpen(false);
  };

  const handleCancel = () => {
    if (modalPromiseResolve) {
      modalPromiseResolve(false);
    }
    setIsSaveModalOpen(false);
  };

  const saveEntity = async e => {
    e.preventDefault();
    setCustomIsOpen(false);
    const isSave = await showSaveConfirmModal();
    if (isSave) {
      const formDataObject = {};
      for (const field of e.target.elements) {
        if (field.name) {
          formDataObject[field.name] = field.value;
        }
      }

      const refSablonParametreEntity = {
        ...formValues,
        ...formDataObject,
      };

      if (formValues !== null) {
        await dispatch(updateEntity({ ...pageObject, entity: refSablonParametreEntity }));
      } else {
        await dispatch(createEntity({ ...pageObject, entity: refSablonParametreEntity }));
      }

      handleCloseForm();
    } else {
      setCustomIsOpen(true);
    }
  };

  return (
    <>
      <CustomSaveEntityConfirm entity={formValues} isOpen={isSaveModalOpen} onConfirm={handleConfirm} onCancel={handleCancel} />

      <Modal className="modal-style-lg" isOpen={customIsOpen} toggle={handleCloseForm} centered>
        <ModalHeader data-cy="RefSablonParametreCreateUpdateHeading" toggle={handleCloseForm}>
          {formValues && (
            <p className="modal-title-info">
              {formValues?.adiTr} - {formValues?.adiEn}
            </p>
          )}
          {formValues ? (
            <Translate contentKey="tk24AdysApp.refSablonParametre.home.updateLabel">Update a refSablonParametre</Translate>
          ) : (
            <Translate contentKey="tk24AdysApp.refSablonParametre.home.createLabel">Create a refSablonParametre</Translate>
          )}
        </ModalHeader>

        <Form onSubmit={saveEntity}>
          <ModalBody>
            <FormItem
              label={translate('global.languageGlobal.adiTr')}
              id="ref-sablon-parametre-adiTr"
              name="adiTr"
              defaultValue={formValues && formValues['adiTr']}
              type={formValues && formValues['adiTr'] && formValues['adiTr'].length > 100 ? 'textarea' : 'text'}
              validation={{
                required: { value: true, message: translate('entity.validation.required') },
                maxLength: { value: 100, message: translate('entity.validation.maxlength', { max: 100 }) },
              }}
            />
            <UncontrolledTooltip target="adiTrLabel">
              <Translate contentKey="global.languageGlobal.adiTr" />
            </UncontrolledTooltip>

            <FormItem
              label={translate('global.languageGlobal.adiEn')}
              id="ref-sablon-parametre-adiEn"
              name="adiEn"
              defaultValue={formValues && formValues['adiEn']}
              type={formValues && formValues['adiEn'] && formValues['adiEn'].length > 100 ? 'textarea' : 'text'}
              validation={{
                maxLength: { value: 100, message: translate('entity.validation.maxlength', { max: 100 }) },
              }}
            />
            <UncontrolledTooltip target="adiEnLabel">
              <Translate contentKey="global.languageGlobal.adiEn" />
            </UncontrolledTooltip>

            <FormItem
              label={translate('tk24AdysApp.refSablonParametre.aciklamaTr')}
              id="ref-sablon-parametre-aciklamaTr"
              name="aciklamaTr"
              defaultValue={formValues && formValues['aciklamaTr']}
              type="textarea"
              validation={{
                required: { value: true, message: translate('entity.validation.required') },
                maxLength: { value: 4000, message: translate('entity.validation.maxlength', { max: 4000 }) },
              }}
            />

            <FormItem
              label={translate('tk24AdysApp.refSablonParametre.aciklamaEn')}
              id="ref-sablon-parametre-aciklamaEn"
              name="aciklamaEn"
              defaultValue={formValues && formValues['aciklamaEn']}
              type="textarea"
              validation={{
                maxLength: { value: 4000, message: translate('entity.validation.maxlength', { max: 4000 }) },
              }}
            />

            {formValues?.statikMi?.id === ReferansEnum.EvetHayir.Evet && (
              <FormItem
                label={translate('tk24AdysApp.refSablonParametre.statikIcerik')}
                id="ref-sablon-parametre-statikIcerik"
                name="statikIcerik"
                defaultValue={formValues && formValues['statikIcerik']}
                type="textarea"
                validation={{
                  maxLength: { value: 4000, message: translate('entity.validation.maxlength', { max: 4000 }) },
                }}
              />
            )}
          </ModalBody>
          <ModalFooter className="modal-footer">
            <Button className="cancel-button" onClick={handleCloseForm}>
              <Translate contentKey="entity.action.cancel">Cancel</Translate>
            </Button>
            &nbsp;
            <Button className="save-button" id="jhi-confirm-delete-refAdtForm" type="submit" data-cy="entityConfirmButton">
              <Translate contentKey="entity.action.save">Kaydet</Translate>
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
};

export default RefSablonParametreUpdate;
