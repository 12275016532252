import { IRefKatilimci } from 'app/shared/model/ref-katilimci.model';
import { IReferans } from 'app/shared/model/referans.model';
import { IKrizKatilimci } from 'app/shared/model/kriz-katilimci.model';
import { IQueryParams } from '../reducers/reducer.utils';
import { IRefOrganisazyon } from './ref-organizasyon.model';
import { IPersonel } from './personel.model';
import { IRefKatilimciIletisim } from './ref-katilimci-iletisim.model';

export interface IRefKatilimciVekil {
  id?: number;
  guid?: string;
  unvanAdi?: string;
  unvanId?: string;
  sicil?: string | null;
  adi?: string | null;
  soyadi?: string | null;
  eposta?: string | null;
  gsmNo?: string | null;
  bilgiSonGuncellenmeZamani?: string;
  cDate?: string;
  cUser?: number;
  mDate?: string;
  mUser?: number;
  katilimci?: IRefKatilimci;
  durum?: IReferans;
  krizKatilimcis?: IKrizKatilimci[] | null;
  sortIndex?: number | null;
  org?: IRefOrganisazyon;
  personel?: IPersonel;
  refKatilimciIletisimList?: IRefKatilimciIletisim[];
}

export interface IRefKatilimciVekilParams extends IQueryParams {
  entity?: IRefKatilimciVekil;
  katilimciId?: string;
}

export const defaultValue: Readonly<IRefKatilimciVekil> = {};
