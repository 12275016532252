import { IReferans } from 'app/shared/model/referans.model';
import { IRefKrizTuru } from 'app/shared/model/ref-kriz-turu.model';
import { IRefKrizSeviye } from 'app/shared/model/ref-kriz-seviye.model';
import { IRefHavalimani } from 'app/shared/model/ref-havalimani.model';
import { IKrizDuyuru } from 'app/shared/model/kriz-duyuru.model';
import { IKrizCeride } from 'app/shared/model/kriz-ceride.model';
import { IKrizKatilimci } from 'app/shared/model/kriz-katilimci.model';
import { IKrizUcak } from 'app/shared/model/kriz-ucak.model';
import { IKrizUcus } from 'app/shared/model/kriz-ucus.model';
import { IMesaj } from 'app/shared/model/mesaj.model';
import { IKrizHavalimani } from './kriz-havalimani.model';
import { IBaseModel } from './base.model';

export interface IKriz extends IBaseModel {
  realId?: number;
  krizOlusmaZamani?: string;
  krizYeriAciklama?: string | null;
  krizYeriKonumX?: number | null;
  krizYeriKonumY?: number | null;
  aciklama?: string | null;
  kapanisAciklama?: string | null;
  teamsDavetiKacDkSonra?: number | null;
  krizinBildirilmeZamani?: string;
  kriziBildiren?: string;
  acilDurumTuru?: IReferans;
  krizTuru?: IRefKrizTuru;
  krizSeviye?: IRefKrizSeviye;
  toplantiTipi?: IReferans;
  ucusVarMi?: IReferans;
  krizYeriHavalimani?: IRefHavalimani | null;
  krizDurumu?: IReferans;
  duyurus?: IKrizDuyuru[] | null;
  krizCerides?: IKrizCeride[] | null;
  krizKatilimcis?: IKrizKatilimci[] | null;
  krizUcaks?: IKrizUcak[] | null;
  krizUcuses?: IKrizUcus[] | null;
  mesajs?: IMesaj[] | null;
  kymKrizDurumu?: IReferans;
  adtKrizDurumu?: IReferans;
  krizKapanmaTipi?: IReferans;
  istasyonKrizDurumuId?: IReferans;
  krizYeriId?: IReferans;
  krizYeriSaatDilimi?: string;
  krizKapanmaZamani?: string;
}

export interface IKrizUpdate {
  kriz?: IKriz;
  krizKatilimciList?: IKrizKatilimci;
  krizUcusList?: IKrizUcus;
  krizUcakList?: IKrizUcak;
  krizHavalimaniList?: IKrizHavalimani;
}

export interface IKrizKartList {
  id?: number;
  krizSeviyeId?: number;
  krizSeviyeCssBorderColor?: string;
  krizSeviyeCssFontColor?: string;
  acilDurumTuruAdi?: string;
  krizTuruAdi?: string;
  havalimaniAdiList?: string[];
  krizOlusmaZamani?: string;
  ucusList?: IKrizKartUcus[];
  acilDurumTuru?: IReferans;
  krizTuru?: IRefKrizTuru;
  krizSeviye?: IRefKrizSeviye;
}

export interface IKrizKartDetail {
  krizId?: number;
  krizDurumuAdi?: string;
  kymDurumuAdi?: string;
  adtDurumuAdi?: string;
  istasyonDurumuAdi?: string;
  krizYeriAdi?: string;
  krizKapanmaTipiAdi?: string;
  kriziBildiren?: string;
  krizinBildirilmeZamani?: string;
  krizSeviyeAdi?: string;
  toplantiTipiAdi?: string;
  krizYeriAciklama?: string;
  aciklama?: string;
  ucusList?: IKrizKartUcus[];
}

export interface IKrizKartUcus {
  ucusKodu?: string;
  kalkisZamani?: string;
  planlananKalkisZamani?: string;
  varisZamani?: string;
  ucakKuyrukKodu?: string;
  kalkisHavalimaniIata?: string;
  varisHavalimaniIata?: string;
}

export interface ICrisisEntryCheck {
  canAccess: boolean;
  isAdmin: boolean;
  isKrizKatilimci: boolean;
  authorities?: string[];
}

export const defaultValue: Readonly<IKriz> = {};
export const defaultKartListValue: Readonly<IKrizKartList> = {};
