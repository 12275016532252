import { IReferans } from 'app/shared/model/referans.model';
import { IRefUcak } from './ref-ucak.model';
import { IRefHavalimani } from './ref-havalimani.model';
import { IQueryParams } from '../reducers/reducer.utils';

export interface IRefUcus {
  id?: number;
  realId?: number;
  guid?: string;
  tasiyiciHavayolu?: string;
  ucusNumarasi?: string;
  planlananKalkisZamani?: string | null;
  planlananVarisZamani?: string | null;
  bilgiSonGuncellenmeZamani?: string;
  durum?: IReferans;
  cDate?: string;
  cUser?: number;
  mDate?: string;
  mUser?: number;
  ucak?: IRefUcak;
  planlananKalkisHavalimani?: IRefHavalimani;
  planlananVarisHavalimani?: IRefHavalimani;
  codeShareHavayolu?: string | null;
  codeShareUcusuMu?: IReferans;
  tehlikeliMaddeVarMi?: IReferans | null;
  tehlikeliMaddeTuru?: string | null;
  filoTipi?: string;
  gerceklesenKalkisHavalimani?: IRefHavalimani;
  gerceklesenVarisHavalimani?: IRefHavalimani;
  gerceklesenKalkisZamani?: string;
  tahminiKalkisZamani?: string;
  tahminiVarisZamani?: string;
  gerceklesenVarisZamani?: string;
  legNo?: string;
  legUpdateNo?: string;
  tekerKesmeZamani?: string;
  tekerVurmaZamani?: string;
  ucusSuffix?: string;
  ucusTuru?: string;
  legUpdateRawJson?: any;
  ekipSayisi?: number;
  yolcuSayisi?: number;
}

export interface IRefUcusParams extends IQueryParams {
  entity?: IRefUcus;
}

export const defaultValue: Readonly<IRefUcus> = {};
