import React, { useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Input } from 'reactstrap';
import { TextFormat, Translate, translate } from 'app/component/jhipster';
import { getEntities, getTotalEntities } from './sistem-parametre.reducer';
import { ASC, DESC } from 'app/shared/util/pagination.constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { LoadingSpinner } from 'app/component/LoadingSpinner';
import SistemParametreUpdate from './sistem-parametre-update';
import CustomPagination from 'app/component/CustomPagination';
import GrantedButton from 'app/component/GrantedButton';
import { APP_DATE_FORMAT, SERVER_URL } from 'app/config/constants';
import { IQueryParams } from 'app/shared/reducers/reducer.utils';
import { ISistemParametre } from 'app/shared/model/sistem-parametre.model';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@mui/material';
import ExcelDownloadButton from 'app/component/ExcelDownload';
import SortIcon from 'app/component/SortIcon';

export const SistemParametre = (props: RouteComponentProps<{ url: string }>) => {
  const dispatch = useAppDispatch();

  const sistemParametreList = useAppSelector(state => state.sistemParametre.entities);
  const loading = useAppSelector(state => state.sistemParametre.loading);
  const updating = useAppSelector(state => state.sistemParametre.updating);
  const totalItem = useAppSelector(state => state.sistemParametre.totalItems);

  const [isSistemParametreUpdateModal, setIsSistemParametreUpdateModal] = useState(false);
  const [isViewing, setIsViewing] = useState(null);

  const [selectedSistemParametre, setSelectedSistemParametre] = useState(null);

  const [search, setSearch] = useState('');

  const [pageObject, setPageObject] = useState<IQueryParams>(useAppSelector(state => state.paginate.pageObject));

  const ExcelSampleData = [
    { key: 'adiTr', titleContentKey: 'global.languageGlobal.adiTr' },
    { key: 'adiEn', titleContentKey: 'global.languageGlobal.adiEn' },
    { key: 'aciklama', titleContentKey: 'tk24AdysApp.sistemParametre.aciklama' },
    { key: 'deger', titleContentKey: 'tk24AdysApp.sistemParametre.deger' },
    { key: 'note', titleContentKey: 'global.languageGlobal.note' },
    { key: 'mDate', format: 'date', titleContentKey: 'global.languageGlobal.mDate' },
  ];
  const getAllEntities = () => {
    dispatch(getEntities(pageObject));
  };

  useEffect(() => {
    getAllEntities();
  }, [pageObject.page, pageObject.query, pageObject.size, pageObject.sort]);

  useEffect(() => {
    let timeoutId;
    const handleSearch = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        if (search.length > 2) {
          setPageObject({ ...pageObject, page: 0, query: `globalFilter=${search}` });
        } else if (search.length === 0) {
          setPageObject({ ...pageObject, page: 0, query: '' });
        }
      }, 300);
    };

    handleSearch();

    return () => {
      clearTimeout(timeoutId);
    };
  }, [search]);

  const sort = p => () => {
    const orderBy = pageObject.sort.split(',')[1];
    setPageObject({ ...pageObject, sort: `${p},${orderBy === ASC ? DESC : ASC}` });
  };

  const handlePageChange = (pageNumber, newItemsPerPage = pageObject.size) => {
    if (newItemsPerPage !== pageObject.size) {
      setPageObject({ ...pageObject, page: pageNumber - 1, size: newItemsPerPage });
    } else {
      setPageObject({ ...pageObject, page: pageNumber - 1 });
    }
  };

  const handleCloseUpdateForm = () => {
    if (isSistemParametreUpdateModal) {
      setIsSistemParametreUpdateModal(false);
    }
    setSelectedSistemParametre(null);
  };

  const fetchTotalSistemParametreData = async (): Promise<ISistemParametre[]> => {
    try {
      const response = (await dispatch(getTotalEntities({ ...pageObject, size: totalItem }))) as {
        payload: { data: ISistemParametre[] };
      };

      return response.payload.data || [];
    } catch (error) {
      console.error('Error fetching data:', error);
      return [];
    }
  };

  const SistemParametreUpdateModal = () => {
    return (
      <SistemParametreUpdate
        handleCloseForm={handleCloseUpdateForm}
        formValues={selectedSistemParametre}
        isOpen={isSistemParametreUpdateModal}
        pageObject={pageObject}
        isViewing={isViewing}
      ></SistemParametreUpdate>
    );
  };

  return (
    <div>
      {(loading || updating) && <LoadingSpinner />}
      {isSistemParametreUpdateModal && SistemParametreUpdateModal()}

      <div style={{ display: 'flex', gap: 8, textAlign: 'center' }}>
        <p className="icon-text">
          <a className="link-a-text" href={`${SERVER_URL}`}>
            <img width={13} height={13} src={`${SERVER_URL}/content/images/icon/faHouse.svg`} />
          </a>
        </p>
        <p className="separator"> / </p>
        <p className="link-text">
          {' '}
          <Translate contentKey="tk24AdysApp.sistemParametre.home.title" />{' '}
        </p>
      </div>

      <h2 id="ref-duyuru-tipi-heading" data-cy="SistemParametreHeading" className="title-frame">
        <a className="link-a-text" onClick={() => props.history.goBack()}>
          <img width={27.5} height={22.5} src={`${SERVER_URL}/content/images/icon/faArrowLeft.svg`} />{' '}
        </a>
        <p className="title-text">
          <Translate contentKey="tk24AdysApp.sistemParametre.home.title" />
        </p>
      </h2>

      <div className="content-area">
        <div className="search-frame">
          <div className="seachiconposition">
            <img className="seachicon" width={24} height={24} src={`${SERVER_URL}/content/images/icon/faSearch.svg`} />
            <Input
              value={search}
              onChange={e => setSearch(e.target.value)}
              placeholder={translate('entity.action.search')}
              className="search-input"
            />
          </div>
          <div className="create-area">
            <ExcelDownloadButton
              excelSampleData={ExcelSampleData}
              grantedAuthorites={['sistem_parametre_export']}
              fetchData={fetchTotalSistemParametreData}
              className={'download-button'}
              exportName={translate('tk24AdysApp.sistemParametre.home.title')}
            />
          </div>
        </div>
        <div className="table-responsive" style={{ marginTop: 5 }}>
          <TableContainer className="content-table">
            {sistemParametreList && sistemParametreList.length > 0 ? (
              <Table aria-label="a dense table">
                <TableHead className="content-table-title-area">
                  <TableRow className="table-head-row">
                    <TableCell className="table-title-cell hand" align="left" onClick={sort('adiTr')}>
                      <div className="tableHeaderContainer">
                        <Translate contentKey="global.languageGlobal.adiTrTitle">Adi Tr</Translate>&nbsp;
                        <SortIcon column="adiTr" activeSortColumn={pageObject.sort} />
                      </div>
                    </TableCell>
                    <TableCell className="table-title-cell hand" align="left" onClick={sort('adiEn')}>
                      <div className="tableHeaderContainer">
                        <Translate contentKey="global.languageGlobal.adiEnTitle">Adi En</Translate>&nbsp;
                        <SortIcon column="adiEn" activeSortColumn={pageObject.sort} />
                      </div>
                    </TableCell>
                    <TableCell className="table-title-cell hand" align="left" onClick={sort('aciklama')}>
                      <div className="tableHeaderContainer">
                        <Translate contentKey="tk24AdysApp.sistemParametre.aciklamaTitle">aciklama</Translate>&nbsp;
                        <SortIcon column="aciklama" activeSortColumn={pageObject.sort} />
                      </div>
                    </TableCell>
                    <TableCell className="table-title-cell hand" align="left" onClick={sort('deger')}>
                      <div className="tableHeaderContainer">
                        <Translate contentKey="tk24AdysApp.sistemParametre.deger">deger</Translate>&nbsp;
                        <SortIcon column="deger" activeSortColumn={pageObject.sort} />
                      </div>
                    </TableCell>
                    <TableCell align="left" className="table-title-cell hand" onClick={sort('mDate')}>
                      <div className="tableHeaderContainer">
                        <Translate contentKey="global.languageGlobal.mDateTitle">mDate</Translate>&nbsp;
                        <SortIcon column="mDate" activeSortColumn={pageObject.sort} />
                      </div>
                    </TableCell>
                    <TableCell className="sticky-right-header"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sistemParametreList.map(sistemParametre => (
                    <TableRow key={sistemParametre.id} className="table-cell-row">
                      <TableCell className="table-content-cell" component="th" scope="row">
                        {sistemParametre?.adiTr}
                      </TableCell>
                      <TableCell className="table-content-cell" component="th" scope="row">
                        {sistemParametre?.adiEn}
                      </TableCell>
                      <TableCell className="table-content-cell" component="th" scope="row">
                        {sistemParametre?.aciklama}
                      </TableCell>
                      <TableCell className="table-content-cell" component="th" scope="row">
                        {sistemParametre?.deger}
                      </TableCell>
                      <TableCell component="th" scope="row" className="table-content-cell">
                        {sistemParametre.mDate && <TextFormat value={sistemParametre.mDate} type="date" format={APP_DATE_FORMAT} />}
                      </TableCell>
                      <TableCell className="sticky-right" align="right">
                        <div className="btn-group flex-btn-group-container">
                          <GrantedButton
                            grantedAuthorites={['sistem_parametre_edit']}
                            className="edit-button"
                            style={{ marginLeft: 2 }}
                            onClick={() => {
                              setSelectedSistemParametre(sistemParametre);
                              setIsViewing(false);
                              setIsSistemParametreUpdateModal(true);
                            }}
                            size="sm"
                            data-cy="entityEditButton"
                          >
                            <Tooltip title={<Translate contentKey="entity.action.edit">Edit</Translate>}>
                              <img width={14} height={14} src={`${SERVER_URL}/content/images/icon/faPencil.svg`} />
                            </Tooltip>
                            <span className="show-on-lg">
                              <Translate contentKey="entity.action.edit">Edit</Translate>
                            </span>
                          </GrantedButton>
                          <GrantedButton
                            grantedAuthorites={['sistem_parametre_read']}
                            className="view-button"
                            onClick={() => {
                              setSelectedSistemParametre(sistemParametre);
                              setIsViewing(true);
                              setIsSistemParametreUpdateModal(true);
                            }}
                            style={{ marginLeft: 10 }}
                            size="sm"
                            data-cy="entityDetailsButton"
                          >
                            <Tooltip title={<Translate contentKey="entity.action.view">View</Translate>}>
                              <img width={15.2} height={11.2} src={`${SERVER_URL}/content/images/icon/faEye.svg`} />
                            </Tooltip>
                            <span className="show-on-lg">
                              <Translate contentKey="entity.action.view">View</Translate>
                            </span>
                          </GrantedButton>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            ) : (
              !loading &&
              !updating && (
                <div className="alert alert-warning">
                  <Translate contentKey="tk24AdysApp.sistemParametre.home.notFound">No Sistem Parametres found</Translate>
                </div>
              )
            )}
          </TableContainer>
        </div>
        <CustomPagination
          currentPage={pageObject.page + 1}
          currentPageSize={pageObject.size}
          totalItems={totalItem}
          handlePageChange={handlePageChange}
        />
      </div>
    </div>
  );
};

export default SistemParametre;
