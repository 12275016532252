import { IKriz } from 'app/shared/model/kriz.model';
import { IReferans } from 'app/shared/model/referans.model';
import { IKrizKatilimci } from './kriz-katilimci.model';
import { IQueryParams } from '../reducers/reducer.utils';

export interface IKrizTalimat {
  id?: number;
  guid?: string;
  realId?: number;
  cDate?: string;
  cUser?: number;
  mDate?: string;
  mUser?: number;
  durum?: IReferans;
  kriz?: IKriz;
  adiTr?: string;
  adiEn?: string;
  adi?: string;
  aciklama?: string;
  talimatVerilmeZamani?: string;
  talimatDeadline?: string;
  talimatiVerenKrizKatilimci?: IKrizKatilimci;
  talimatiVerenDiger?: string;
  sorumluDiger?: string;
  krizTalimatSorumlus?: IKrizTalimatSorumlu[];
  talimatDurumu?: IReferans;
}

export interface IKrizTalimatParams extends IQueryParams {
  entity?: IKrizTalimat;
  krizId?: string | number;
}
export const defaultValue: Readonly<IKrizTalimat> = {};

export interface IKrizTalimatSorumlu {
  id?: number;
  guid?: string;
  cDate?: string;
  cUser?: number;
  mDate?: string;
  mUser?: number;
  durum?: IReferans;
  krizKatilimci?: IKrizKatilimci;
}

export interface KrizTalimatUpdateModal {
  krizId: string;
  handleClose: () => void;
  talimat?: IKrizTalimat;
  pageObject?: IQueryParams;
}
