import { useAppDispatch, useAppSelector } from 'app/config/store';
import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Col, Row, Button, Modal, ModalHeader, ModalBody, ModalFooter, Input, Nav, NavItem, NavLink } from 'reactstrap';
import { Search } from '@mui/icons-material';
import { Translate, translate, Storage } from 'app/component/jhipster';
import { Box, FormControl, InputLabel, LinearProgress, MenuItem, Select } from '@mui/material';
import { IQueryParams } from 'app/shared/reducers/reducer.utils';
import { ASC } from 'app/shared/util/pagination.constants';
import { calculateElapsedTime, calculateRemainingTime, isNullOrEmpty } from 'app/shared/util/confirm-utils';
import { KatilimciRolMap, ReferansEnum } from 'app/constants/ReferansEnum';
import ExcelDownloadButton from 'app/component/ExcelDownload';
import { IKrizKatilimciGorev } from 'app/shared/model/kriz-katilimci-gorev.model';
import { getEntities as getReferans } from 'app/entities/referans/referans.reducer';
import { getEntities as getKatilimciEntities } from 'app/entities/kriz-katilimci/kriz-katilimci.reducer';
import {
  getAkisEntities as getKrizGorevEntities,
  reset as gorevReset,
  updateKrizAkisEntity,
} from 'app/entities/kriz-katilimci-gorev/kriz-katilimci-gorev.reducer';
import { LoadingSpinner } from 'app/component/LoadingSpinner';
import GrantedCheckbox from 'app/component/GrantedCheckbox';
import { SERVER_URL } from 'app/config/constants';
import { initialCircleNew } from 'app/shared/style/style';
import axios from 'axios';
import GorevDetail from '../kriz-adym-gorevler/gorev-detail';
import TableNameEnum from 'app/constants/TableNameEnum';
import { getFilteredEntities as getFilterGorevEkDosyaEntities } from 'app/entities/ek-dosya/ek-dosya.reducer';

import classnames from 'classnames';

export const KrizAkisListe = (props: RouteComponentProps<{ id: string }>) => {
  const { match } = props;
  const krizId = Storage.session.get('kriz');
  const dispatch = useAppDispatch();

  const [search, setSearch] = useState('');

  const referans = useAppSelector(state => state.referans.entities);
  const referansLoading = useAppSelector(state => state.referans.loading);
  const kriz = useAppSelector(state => state.kriz.entity);

  const krizKatilimciGorevs = useAppSelector(state => state.krizKatilimciGorev.entities);
  const krizKatilimciGorevLoading = useAppSelector(state => state.krizKatilimciGorev.loading);
  const krizKatilimciGorevUpdating = useAppSelector(state => state.krizKatilimciGorev.updating);

  const krizKatilimci = useAppSelector(state => state.krizKatilimci.entities);
  const krizKatilimciLoading = useAppSelector(state => state.krizKatilimci.loading);
  const krizKymKatilimci = krizKatilimci && krizKatilimci.filter(item => KatilimciRolMap.KYM.includes(item.katilimciRolu?.id));

  const [selectedKatilimci, setSelectedKatilimci] = useState(null);
  const [selectedGorevTipi, setSelectedGorevTipi] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);

  const [selectedTaskNewStatus, setSelectedTaskNewStatus] = useState(null);

  const [selectedTask, setSelectedTask] = useState<IKrizKatilimciGorev>(null);
  const [isChangeStatusOpen, setIsChangeStatusOpen] = useState(false);

  const [countLoading, setCountLoading] = useState(false);
  const [dataChart, setDataChart] = useState([]);
  const [tamamlananGorevCount, setTamamlananGorevCount] = useState(0);

  const totalValue: number = dataChart.reduce((sum, [, , value]) => sum + value, 0);
  const percentageDone = totalValue > 0 ? Math.round((100 / totalValue) * tamamlananGorevCount * 100) / 100 : 0;

  const gorevDurumuColors = {
    [ReferansEnum.GorevDurumu.Tamamlandi]: '#83E59F',
    [ReferansEnum.GorevDurumu.IptalEdildi]: '#ED6068',
    [ReferansEnum.GorevDurumu.DevamEdiyor]: '#5FB6FF',
  };

  const [isTaskDetailModal, setIsTaskDetailModal] = useState(false);

  const krizGorevEkDosyaList = useAppSelector(state => state.ekDosya.entities);

  const [pageObject, setPageObject] = useState<IQueryParams>({
    ...useAppSelector(state => state.paginate.pageObject),
    sort: `id,${ASC}`,
    query: `krizId.equals=${krizId}`,
  });
  const [dynamicTime, setDynamicTime] = useState({});
  const ExcelSampleData = [
    { key: 'krizKatilimciAdi', key2: 'krizKatilimciSoyadi', titleContentKey: 'tk24AdysApp.krizKatilimci.katilimci' },
    { key: 'adi', titleContentKey: 'tk24AdysApp.krizKatilimciGorev.gorevAdi' },
    { key: 'aciklama', titleContentKey: 'tk24AdysApp.krizKatilimciGorev.gorevAciklama' },
    { key: 'note', titleContentKey: 'tk24AdysApp.krizKatilimciGorev.note' },
    { key: 'gorevTuru', titleContentKey: 'tk24AdysApp.krizKatilimciGorev.gorevTuru' },
    { key: 'gorevDurumu', titleContentKey: 'tk24AdysApp.krizKatilimciGorev.gorevDurumu' },
    { key: 'gorevSahibi', titleContentKey: 'tk24AdysApp.krizKatilimciGorev.gorevSahibi' },
    { key: 'gorevAtanmaZamani', format: 'date', titleContentKey: 'tk24AdysApp.krizKatilimciGorev.gorevAtanmaZamani' },
    { key: 'gorevKapanmaZamani', format: 'date', titleContentKey: 'tk24AdysApp.krizKatilimciGorev.gorevKapanmaZamani' },
  ];

  const [showFilter, setShowFilter] = useState(false);
  const [sureFilter, setSureFilter] = useState('');

  useEffect(() => {
    dispatch(gorevReset());
    dispatch(getReferans({ page: 0, size: 999, sort: 'asc' }));
    dispatch(getKatilimciEntities({ size: 100, page: 0, sort: 'adi,asc', query: `krizId.equals=${krizId}` }));
  }, []);

  useEffect(() => {
    getCountAkisList();
  }, []);

  useEffect(() => {
    if (totalValue > 0) {
      if (sureFilter === '30') {
        dispatch(getKrizGorevEntities({ ...pageObject, size: totalValue, query2: 'sure.greaterThan=0&sure.lessThanOrEqual=30' }));
      } else if (sureFilter === '60') {
        dispatch(getKrizGorevEntities({ ...pageObject, size: totalValue, query2: 'sure.greaterThan=30&sure.lessThanOrEqual=60' }));
      } else if (sureFilter === '60+') {
        dispatch(getKrizGorevEntities({ ...pageObject, size: totalValue, query2: 'sure.greaterThan=60' }));
      } else if (sureFilter === 'suresiz') {
        dispatch(getKrizGorevEntities({ ...pageObject, size: totalValue, query2: 'sure.specified=false' }));
      } else if (sureFilter === '') {
        dispatch(getKrizGorevEntities({ ...pageObject, size: totalValue }));
      }
    }
  }, [totalValue, pageObject.query, sureFilter]);

  useEffect(() => {
    const interval = setInterval(() => {
      krizKatilimciGorevs.forEach(task => {
        const remainingTime = calculateRemainingTime(task.gorevAtanmaZamani, task.sure);
        setDynamicTime(prevState => ({
          ...prevState,
          [task.id]: remainingTime,
        }));
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [krizKatilimciGorevs]);

  const [timeElapsed, setTimeElapsed] = useState('');

  useEffect(() => {
    const interval = setInterval(() => {
      const elapsedTime = calculateElapsedTime(kriz?.krizOlusmaZamani);
      setTimeElapsed(elapsedTime);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const getCountAkisList = async () => {
    const taskChartUrl = 'api/kriz-katilimci-gorevs/count-by-kym-akis-list-gorev-durum?page=0&size=2000&krizId.equals=' + match.params.id;
    setCountLoading(true);
    await axios.get(taskChartUrl).then(response => {
      const gorev = [];
      const colors = [];
      const dataTasks = response.data;
      dataTasks.map(data => {
        colors.push(gorevDurumuColors[data.gorevDurumuId]);
        gorev.push([data.gorevDurumuId, data.gorevDurumuAdi, data.count]);
      });
      setDataChart(gorev);
      setTamamlananGorevCount(dataTasks.find(item => item.gorevDurumuId === ReferansEnum.GorevDurumu.Tamamlandi)?.count);
    });

    setCountLoading(false);
  };

  const handleSearchChange = (elem, event) => {
    const value = event.target.value;
    if (elem === 'krizKatilimciId') {
      setSelectedKatilimci(value);
    } else if (elem === 'gorevTuruId') {
      setSelectedGorevTipi(value);
    } else if (elem === 'gorevDurumuId') {
      setSelectedStatus(value);
    }

    if (!isNullOrEmpty(value)) {
      const newQuery = pageObject.query
        .split('&')
        .filter(param => !param.startsWith(`${elem}.equals`))
        .concat(`${elem}.equals=${value}`)
        .join('&');
      setPageObject(prevPageObject => ({
        ...prevPageObject,
        page: 0,
        query: newQuery,
      }));
    } else {
      const keyToDelete = `${elem}.equals`;
      const updatedParams = pageObject.query
        .split('&')
        .filter(param => {
          const [key, _] = param.split('=');
          return key !== keyToDelete;
        })
        .join('&');
      setPageObject({ ...pageObject, page: 0, query: updatedParams });
    }
  };

  useEffect(() => {
    if (search.length > 2) {
      setPageObject(prevPageObject => ({
        ...prevPageObject,
        page: 0,
        query: prevPageObject.query + `&globalFilter=${search}`,
      }));
    } else if (search.length === 0) {
      const keyToDelete = `globalFilter`;
      const updatedParams = pageObject.query
        .split('&')
        .filter(param => {
          const [key, _] = param.split('=');
          return key !== keyToDelete;
        })
        .join('&');
      setPageObject({ ...pageObject, page: 0, query: updatedParams });
    }
  }, [search]);

  const fetchTotalData = () => {
    return krizKatilimciGorevs.map(task => ({
      adi: task.adi,
      aciklama: task.aciklama,
      note: task.note,
      gorevTuru: task.gorevTuru.adi,
      gorevDurumu: task.gorevDurumu.adi,
      gorevSahibi: task.gorevSahibi.adi,
      gorevAtanmaZamani: task.gorevAtanmaZamani,
      gorevKapanmaZamani: task.gorevKapanmaZamani,
      krizKatilimciAdi: task.krizKatilimci?.adi,
      krizKatilimciSoyadi: task.krizKatilimci?.soyadi,
    }));
  };

  const changeTaskStatus = (task, newStatusId) => {
    setSelectedTaskNewStatus(task.gorevDurumu);
    const gorevDurumuId = newStatusId !== null ? newStatusId : ReferansEnum.GorevDurumu.IptalEdildi;

    const gorevDurumu = referans.find(it => it.id.toString() === gorevDurumuId.toString());
    setSelectedTask(prevState => ({
      ...prevState,
      ...task,
      gorevDurumu,
    }));

    setIsChangeStatusOpen(true);
  };

  const handleChangeStatusCloseDialog = () => {
    setSelectedTask(null);
    setSelectedTaskNewStatus(null);
    setIsChangeStatusOpen(false);
  };

  const krizKatilimciGorevUpdated = async () => {
    if (selectedTask && selectedTask !== null) {
      try {
        await dispatch(updateKrizAkisEntity({ ...pageObject, entity: selectedTask }));
        getCountAkisList();
        handleChangeStatusCloseDialog();
      } catch (error) {
        window.console.error('Error sending POST request:', error);
      }
    }
  };

  const changeTaskStatusModal = () => {
    return (
      <Modal className="modal-style" isOpen={isChangeStatusOpen} toggle={handleChangeStatusCloseDialog} centered>
        <ModalHeader data-cy="refDuyuruTipiDetailsHeading" toggle={handleChangeStatusCloseDialog}>
          <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            <img src={`${SERVER_URL}/content/images/icon/WarningRed.svg`} style={{ height: '52px' }} />
            <Translate contentKey="tk24AdysApp.krizKatilimciGorev.durumDegistir" />
          </div>
        </ModalHeader>
        {selectedTask && (
          <div id="gorevDurumuName" className="gorevDurumuName">
            <p className="m-0">{selectedTask.adi}</p>
          </div>
        )}
        <ModalBody style={{ maxHeight: '450px', overflowY: 'auto' }}>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', margin: '10px 0px 30px' }}>
            <span
              className="modal-gorev-durumu-badge"
              style={{
                color: getTextColor(selectedTaskNewStatus),
                backgroundColor: getBadgeBackgroundColor(selectedTaskNewStatus),
              }}
            >
              {selectedTaskNewStatus ? selectedTaskNewStatus?.adi : null}
            </span>
            <img src={`${SERVER_URL}/content/images/icon/gorevRightArrow.svg`} />
            {selectedTaskNewStatus?.id === ReferansEnum.GorevDurumu.Tamamlandi ? (
              <span
                className="modal-gorev-durumu-badge"
                style={{
                  color: '#004279',
                  backgroundColor: '#E6F3FF',
                }}
              >
                <Translate contentKey="tk24AdysApp.krizKatilimciGorev.devamEdiyor" />
              </span>
            ) : (
              <span
                className="modal-gorev-durumu-badge"
                style={{
                  color: '#146F2E',
                  backgroundColor: '#E8FEEF',
                }}
              >
                <Translate contentKey="tk24AdysApp.krizKatilimciGorev.tamamlandi" />
              </span>
            )}
          </div>
          <div className="model-title-info gorevDurumu ">
            <p className="m-0">
              {selectedTask && selectedTask.gorevDurumu !== null && (
                <>{translate('tk24AdysApp.krizKatilimciGorev.update.question', { durum: selectedTask?.gorevDurumu?.adi })}</>
              )}
            </p>
          </div>
        </ModalBody>
        <ModalFooter className="modal-footer">
          <Button className="cancel-button" onClick={handleChangeStatusCloseDialog}>
            <Translate contentKey="entity.action.cancel">Cancel</Translate>
          </Button>
          &nbsp;
          <Button
            className="save-button"
            onClick={krizKatilimciGorevUpdated}
            id="jhi-confirm-delete-refAdtForm"
            type="submit"
            data-cy="entityConfirmButton"
          >
            <Translate contentKey="entity.action.save">Kaydet</Translate>
          </Button>
        </ModalFooter>
      </Modal>
    );
  };

  const getTextColor = gorevDurumu => {
    if (gorevDurumu) {
      switch (gorevDurumu.id) {
        case ReferansEnum.GorevDurumu.DevamEdiyor:
          return '#004279';
        case ReferansEnum.GorevDurumu.IptalEdildi:
          return '#AA050E';
        case ReferansEnum.GorevDurumu.Tamamlandi:
          return '#146F2E';
        default:
          return '';
      }
    }
    return '';
  };

  const getBadgeBackgroundColor = gorevDurumu => {
    if (gorevDurumu) {
      switch (gorevDurumu.id) {
        case ReferansEnum.GorevDurumu.DevamEdiyor:
          return '#E6F3FF';
        case ReferansEnum.GorevDurumu.IptalEdildi:
          return '#FCE6E7';
        case ReferansEnum.GorevDurumu.Tamamlandi:
          return '#E8FEEF';
        default:
          return '';
      }
    }
    return '';
  };
  function getName(name) {
    const names = name
      .trim()
      .split(' ')
      .filter(word => word);
    const initials = names.map(word => word[0].toUpperCase()).join('');
    return initials;
  }
  const NameCircle = ({ name }) => {
    if (!isNullOrEmpty(name)) {
      const initials = getName(name);
      return (
        <div
          style={{
            height: '24px',
            width: '24px',
            backgroundColor: '#79889A',
            borderRadius: '50%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: 'white',
            fontSize: '12px',
            marginRight: '10px',
          }}
        >
          {initials}
        </div>
      );
    }

    return <></>;
  };

  const handleCloseUpdateForm = () => {
    setSelectedTask(null);
    setIsTaskDetailModal(false);
  };

  const getEkDosya = taskId => {
    dispatch(
      getFilterGorevEkDosyaEntities({
        ilgiliTabloKayitId: taskId,
        ilgiliTablo: TableNameEnum.KrizKatilimciGorev,
      })
    );
  };

  const taskDetailModal = () => {
    return (
      selectedTask && (
        <Modal className="modal-style-lg" isOpen={isTaskDetailModal} toggle={handleCloseUpdateForm} centered>
          <ModalHeader data-cy="refAdtFormUpdateDialogHeading" toggle={handleCloseUpdateForm}>
            <Translate contentKey="tk24AdysApp.krizKatilimciGorev.detail.title">Mission Detail</Translate>
          </ModalHeader>
          {selectedTask && (
            <div id="gorevDurumuName" className="gorevDurumuName">
              <p className="m-0">{selectedTask.adi}</p>
            </div>
          )}
          <ModalBody style={{ maxHeight: '550px', overflowY: 'auto' }}>
            <GorevDetail
              isAkisListe={true}
              selectedTask={selectedTask}
              ekDosya={krizGorevEkDosyaList}
              update={getEkDosya}
              kriz={kriz}
            ></GorevDetail>
          </ModalBody>
        </Modal>
      )
    );
  };

  return (
    <div>
      {(referansLoading || krizKatilimciLoading || krizKatilimciGorevLoading || krizKatilimciGorevUpdating || countLoading) && (
        <LoadingSpinner />
      )}
      {changeTaskStatusModal()}
      {taskDetailModal()}

      <div className="search-frame">
        <div className="seachiconposition-text" onClick={() => setShowFilter(!showFilter)}>
          {!showFilter ? (
            <div className="akis-liste-filter">
              <img src={`${SERVER_URL}/content/images/icon/filterLines.svg`} />
              <p className="akis-liste-filter-text">
                <Translate contentKey="entity.action.filtre" />
              </p>
            </div>
          ) : (
            <>
              <p className="akis-liste-filter-text">
                <Translate contentKey="entity.action.filtreGizle" />{' '}
              </p>
            </>
          )}
        </div>
        <div className="seachiconposition-akis-filter">
          {showFilter && (
            <>
              <Search className="seachicon" style={{ color: 'grey' }} />
              <Input
                style={{ height: '48px' }}
                value={search}
                onChange={e => setSearch(e.target.value)}
                placeholder={translate('entity.action.search')}
                className="search-input"
                type="text"
                name="categorySelect"
                id="categorySelect"
              />

              <Box sx={{ width: '100%', backgroundColor: '#FFFFFF' }}>
                <FormControl fullWidth size={window.innerWidth < 1600 ? 'small' : 'medium'}>
                  <InputLabel id="default-katilimci-label" className="select-input">
                    <Translate contentKey="tk24AdysApp.krizKatilimci.katilimci" />
                  </InputLabel>
                  <Select
                    style={{ maxWidth: '300px' }}
                    labelId="katilimciId-label"
                    id="katilimciId"
                    name="katilimciId"
                    value={selectedKatilimci}
                    label={translate('tk24AdysApp.krizKatilimci.katilimci')}
                    onChange={event => handleSearchChange('krizKatilimciId', event)}
                    className="select-input"
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 200,
                          overflowY: 'auto',
                        },
                      },
                    }}
                  >
                    <MenuItem value={null}>
                      <Translate contentKey="entity.action.all">Tümü</Translate>
                    </MenuItem>
                    {krizKymKatilimci &&
                      krizKymKatilimci.length > 0 &&
                      krizKymKatilimci.map(katilimci => (
                        <MenuItem key={katilimci.id} value={katilimci.id}>
                          {katilimci.adi} {katilimci.soyadi} {katilimci.unvanAdi ? '- ' + katilimci.unvanAdi : ''}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Box>

              <Box sx={{ width: '100%', backgroundColor: '#FFFFFF' }}>
                <FormControl fullWidth size={window.innerWidth < 1600 ? 'small' : 'medium'}>
                  <InputLabel id="default-gorevTuruId-label" className="select-input">
                    <Translate contentKey="tk24AdysApp.krizKatilimciGorev.gorevTipi" />
                  </InputLabel>
                  <Select
                    style={{ maxWidth: '300px' }}
                    labelId="gorevTuruId-label"
                    id="gorevTuruId"
                    name="gorevTuruId"
                    value={selectedGorevTipi}
                    label={translate('tk24AdysApp.krizKatilimciGorev.gorevTipi')}
                    onChange={event => handleSearchChange('gorevTuruId', event)}
                    className="select-input"
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 200,
                          overflowY: 'auto',
                        },
                      },
                    }}
                  >
                    <MenuItem value={null}>
                      <Translate contentKey="entity.action.all">Tümü</Translate>
                    </MenuItem>
                    {referans
                      .filter(
                        item =>
                          item.id === ReferansEnum.GorevTuru.AdymEkGorev ||
                          item.id === ReferansEnum.GorevTuru.AdymTanimliGorev ||
                          item.id === ReferansEnum.GorevTuru.AdymTalimatGorev
                      )
                      .map(item => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.adi}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Box>

              <Box sx={{ width: '100%', backgroundColor: '#FFFFFF' }}>
                <FormControl fullWidth size={window.innerWidth < 1600 ? 'small' : 'medium'}>
                  <InputLabel id="gorevDurumuId-label" className="select-input">
                    <Translate contentKey="tk24AdysApp.krizKatilimciGorev.gorevDurumu" />
                  </InputLabel>
                  <Select
                    style={{ maxWidth: '300px' }}
                    labelId="gorevDurumuId-label"
                    id="gorevDurumuId"
                    name="gorevDurumuId"
                    className="select-input"
                    value={selectedStatus}
                    label={translate('tk24AdysApp.krizKatilimciGorev.gorevDurumu')}
                    onChange={event => handleSearchChange('gorevDurumuId', event)}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 200,
                          overflowY: 'auto',
                        },
                      },
                    }}
                  >
                    <MenuItem value={null}>
                      <Translate contentKey="entity.action.all" />
                    </MenuItem>
                    {referans
                      .filter(item => item.tipi === 'GOREV_DURUMU' && item.id !== ReferansEnum.GorevDurumu.IptalEdildi)
                      .map(item => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.adi}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Box>
              <ExcelDownloadButton
                excelSampleData={ExcelSampleData}
                grantedAuthorites={['ref_yolcu_export']}
                fetchData={fetchTotalData}
                exportName={translate('dashboard.krizAkisListe')}
                className={'download-button'}
              />
            </>
          )}
        </div>
      </div>
      <div className="akisListeSure">
        <Row className="align-items-center row">
          <Col md={4}>
            <div>
              <Nav tabs className={'tab-kriz-akis'}>
                <NavItem>
                  <NavLink
                    className={classnames({ active: sureFilter === '' })}
                    id="all"
                    name="all"
                    onClick={event => {
                      setSureFilter('');
                    }}
                  >
                    <Translate contentKey="entity.action.all" />
                  </NavLink>
                </NavItem>

                <NavItem>
                  <NavLink
                    className={classnames({ active: sureFilter === '30' })}
                    id="30"
                    name="30"
                    onClick={event => {
                      setSureFilter('30');
                    }}
                  >
                    30 <Translate contentKey="entity.action.dakika" />
                  </NavLink>
                </NavItem>

                <NavItem>
                  <NavLink
                    className={classnames({ active: sureFilter === '60' })}
                    id="60"
                    name="60"
                    onClick={event => {
                      setSureFilter('60');
                    }}
                  >
                    60 <Translate contentKey="entity.action.dakika" />
                  </NavLink>
                </NavItem>

                <NavItem>
                  <NavLink
                    className={classnames({ active: sureFilter === '60+' })}
                    id="60+"
                    name="60+"
                    onClick={event => {
                      setSureFilter('60+');
                    }}
                  >
                    60+ <Translate contentKey="entity.action.dakika" />
                  </NavLink>
                </NavItem>

                <NavItem>
                  <NavLink
                    className={classnames({ active: sureFilter === 'suresiz' })}
                    id="suresiz"
                    name="suresiz"
                    onClick={event => {
                      setSureFilter('suresiz');
                    }}
                  >
                    <Translate contentKey="entity.action.suresiz" />
                  </NavLink>
                </NavItem>
              </Nav>
            </div>
          </Col>
          <Col md={4}>
            <div style={{ width: '100%', textAlign: 'center' }}>
              <div
                style={{
                  display: 'inline-flex',
                  gap: '8px',
                  background: '#FFFFFF',
                  border: '1px solid  #E2E4E9',
                  padding: '8px',
                  borderRadius: '4px',
                  alignItems: 'center',
                  marginBottom: '5px',
                }}
              >
                <img width={16} height={16} src={`${SERVER_URL}/content/images/icon/accessTimeFilled.svg`} />
                <p style={{ fontWeight: '500', margin: '0px', fontSize: '14px' }}> {timeElapsed} </p>
                <p style={{ fontWeight: '200', margin: '0px', fontSize: '14px' }}>
                  <Translate contentKey="dashboard.krizGecenSure" />
                </p>
              </div>
            </div>
          </Col>
          <Col md={4}>
            <div style={{ display: 'flex', gap: 6, fontSize: '10px', alignItems: 'center' }}>
              <div className="krizAkisTamamlananGorevSayisi">
                <Translate contentKey="tk24AdysApp.krizKatilimciGorev.tamamlananGorev">Tamamlanan Gorev</Translate>:
                <b>
                  {tamamlananGorevCount}/{totalValue}
                </b>
              </div>
              <div className="gorevlerAkisInfoBoxRight">
                <LinearProgress
                  variant="determinate"
                  value={percentageDone}
                  sx={{
                    height: '32px',
                    width: '100%',
                    borderRadius: '8px',
                    backgroundColor: '#dadee3',
                    '& .MuiLinearProgress-bar': {
                      backgroundColor: '#53c172',
                      borderRadius: '8px',
                    },
                  }}
                />
              </div>
            </div>
          </Col>
        </Row>
      </div>
      {krizKatilimciGorevs && krizKatilimciGorevs.length > 0 ? (
        <>
          <Row>
            {krizKatilimciGorevs.map((tasks, index) => (
              <Col key={index} md={4}>
                <div
                  className="gorev-akis-card"
                  style={{ cursor: 'pointer', display: 'flex', flexDirection: 'row' }}
                  onClick={() => {
                    setSelectedTask(tasks);
                    getEkDosya(tasks.realId);
                    setIsTaskDetailModal(true);
                  }}
                >
                  <div
                    style={{
                      textAlign: 'center',
                    }}
                  >
                    <GrantedCheckbox
                      id={`update-button-${tasks.id}`}
                      grantedAuthorites={['kriz_katilimci_gorev_edit']}
                      sx={{
                        width: '35px',
                        height: '35px',
                        zIndex: 500,
                        '& .MuiIconButton-root': {
                          color: tasks.gorevDurumu?.id === ReferansEnum.GorevDurumu.Tamamlandi ? '#39ab59' : 'transparent',
                        },
                        '& .MuiSvgIcon-root': {
                          width: '35px',
                          height: '35px',
                          color: tasks.gorevDurumu?.id === ReferansEnum.GorevDurumu.Tamamlandi ? '#39ab59' : '#A9B3BE',
                        },
                        '&.Mui-disabled': {
                          opacity: 0.5,
                        },
                      }}
                      checked={tasks.gorevDurumu?.id === ReferansEnum.GorevDurumu.Tamamlandi}
                      onClick={e => e.stopPropagation()}
                      onChange={() => {
                        if (tasks.gorevDurumu?.id === ReferansEnum.GorevDurumu.Tamamlandi) {
                          changeTaskStatus(tasks, ReferansEnum.GorevDurumu.DevamEdiyor);
                        } else {
                          changeTaskStatus(tasks, ReferansEnum.GorevDurumu.Tamamlandi);
                        }
                      }}
                    />
                  </div>
                  <div
                    style={{
                      paddingLeft: 10,
                      width: '100%',
                    }}
                  >
                    <span
                      className="gorev-durumu-badge"
                      style={{
                        color: getTextColor(tasks.gorevDurumu),
                        backgroundColor: getBadgeBackgroundColor(tasks.gorevDurumu),
                      }}
                    >
                      {tasks.gorevDurumu ? tasks.gorevDurumu.adi : null}
                    </span>

                    {tasks?.ekDosyaSayisi > 0 && (
                      <div style={{ position: 'absolute', right: '5%', top: '0px' }}>
                        <img width={40} height={40} src={`${SERVER_URL}/content/images/icon/uploadFile.svg`} />
                      </div>
                    )}

                    <div>
                      <p className="gorev-akis-text">{tasks?.adi}</p>
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
                      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', flexBasis: '50%' }}>
                        <div>
                          {tasks?.krizKatilimci?.personel?.thumbnail ? (
                            <div className="hoverable-image-container">
                              <img
                                style={{
                                  ...initialCircleNew,
                                  transition: 'width 0.3s, height 0.3s',
                                }}
                                className="hoverable-image"
                                src={`data:${tasks?.krizKatilimci?.personel?.thumbnail.contentType};base64,${tasks?.krizKatilimci?.personel?.thumbnail.base64}`}
                                alt={''}
                              />
                            </div>
                          ) : (
                            <div style={initialCircleNew}>
                              <span style={{ fontSize: '14px' }}>
                                {tasks?.krizKatilimci?.soyadi ? (
                                  <>
                                    {tasks?.krizKatilimci?.adi.charAt(0)}
                                    {tasks?.krizKatilimci?.soyadi.charAt(0)}
                                  </>
                                ) : (
                                  tasks?.krizKatilimci?.adi.charAt(0)
                                )}
                              </span>
                            </div>
                          )}
                        </div>
                        <div>
                          <p className="krizAkisKatilimci">
                            {' '}
                            {tasks?.krizKatilimci?.adi} {tasks?.krizKatilimci?.soyadi ?? ''}{' '}
                          </p>
                          <p className="krizAkisKatilimciUnvan"> {tasks?.krizKatilimci?.unvanAdi} </p>
                        </div>
                      </div>
                      <div style={{ flexBasis: '50%' }}>
                        <div className="gorev-tipi-akis-badge">
                          <Translate contentKey="tk24AdysApp.krizKatilimciGorev.gorevTipi">Görev Tipi</Translate> :{' '}
                          <span style={{ color: '#788088' }}>{tasks.gorevTuru ? tasks.gorevTuru.adi : null}</span>
                        </div>
                        {!isNullOrEmpty(tasks.sure) && (
                          <div className="gorev-tipi-akis-badge">
                            <Translate contentKey="tk24AdysApp.krizKatilimciGorev.kalanSure">Kalan Sure</Translate> :{' '}
                            <span style={{ color: '#788088' }}>{tasks && tasks.sure ? dynamicTime[tasks.id] : ''}</span>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </>
      ) : (
        !krizKatilimciGorevLoading && (
          <div className="alert alert-warning">
            <Translate contentKey="tk24AdysApp.krizKatilimciGorev.home.notFound">No Gorev found</Translate>
          </div>
        )
      )}
    </div>
  );
};

export default KrizAkisListe;
