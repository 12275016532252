import React, { useState, useEffect } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Table } from 'reactstrap';
import { Translate, TextFormat } from 'app/component/jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { getEntities } from './kriz-katilimci-gorev.reducer';
import { APP_DATE_FORMAT, SERVER_URL } from 'app/config/constants';
import { ASC, DESC } from 'app/shared/util/pagination.constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { LoadingSpinner } from 'app/component/LoadingSpinner';
import { faArrowDown, faArrowLeft, faArrowUp, faHome } from '@fortawesome/free-solid-svg-icons';
import { TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import CustomPagination from 'app/component/CustomPagination';
import { IQueryParams } from 'app/shared/reducers/reducer.utils';

export const KrizKatilimciGorev = (props: RouteComponentProps<{ url: string }>) => {
  const dispatch = useAppDispatch();
  const krizKatilimciGorevList = useAppSelector(state => state.krizKatilimciGorev.entities);
  const loading = useAppSelector(state => state.krizKatilimciGorev.loading);
  const totalItems = useAppSelector(state => state.krizKatilimciGorev.totalItems);

  const [pageObject, setPageObject] = useState<IQueryParams>(useAppSelector(state => state.paginate.pageObject));

  const getAllEntities = () => {
    dispatch(getEntities(pageObject));
  };

  useEffect(() => {
    getAllEntities();
  }, [pageObject.page, pageObject.query, pageObject.size, pageObject.sort]);

  const sort = p => () => {
    const orderBy = pageObject.sort.split(',')[1];
    setPageObject({ ...pageObject, sort: `${p},${orderBy === ASC ? DESC : ASC}` });
  };

  const handlePageChange = (pageNumber, newItemsPerPage = pageObject.size) => {
    if (newItemsPerPage !== pageObject.size) {
      setPageObject({ ...pageObject, page: pageNumber - 1, size: newItemsPerPage });
    } else {
      setPageObject({ ...pageObject, page: pageNumber - 1 });
    }
  };

  return (
    <div>
      {loading && <LoadingSpinner />}

      <div style={{ display: 'flex', gap: 8, textAlign: 'center' }}>
        <p className="icon-text">
          <a className="link-a-text" href={`${SERVER_URL}`}>
            <FontAwesomeIcon width={13} height={13} icon={faHome} />
          </a>
        </p>
        <p className="separator"> / </p>
        <p className="link-text">
          {' '}
          <Translate contentKey="tk24AdysApp.krizKatilimciGorev.home.title">Kriz Katilimci Gorevs</Translate>
        </p>
      </div>

      <h2 id="ref-yakinlik-durumu-heading" data-cy="RefYakinlikDurumuHeading" className="title-frame">
        <a className="link-a-text" onClick={() => props.history.goBack()}>
          <FontAwesomeIcon width={27.5} height={23} icon={faArrowLeft} />{' '}
        </a>
        <p className="title-text">
          <Translate contentKey="tk24AdysApp.krizKatilimciGorev.home.title">Kriz Katilimci Gorevs</Translate>
        </p>
      </h2>
      <div className="content-area">
        <TableContainer className="content-table">
          {krizKatilimciGorevList && krizKatilimciGorevList.length > 0 ? (
            <Table aria-label="a dense table">
              <TableHead className="content-table-title-area">
                <TableRow className="table-head-row">
                  <TableCell style={{ width: 570 }} align="left" className="table-title-cell hand" onClick={sort('gorevAtanmaZamani')}>
                    <Translate contentKey="tk24AdysApp.krizKatilimciGorev.gorevAtanmaZamaniTitle">Gorev Atanma Zamani</Translate>{' '}
                    {pageObject.sort === `gorevAtanmaZamani,${ASC}` ? (
                      <FontAwesomeIcon icon={faArrowDown} />
                    ) : (
                      <FontAwesomeIcon icon={faArrowUp} />
                    )}
                  </TableCell>
                  <TableCell style={{ width: 570 }} align="left" className="table-title-cell hand" onClick={sort('gorevKapanmaZamani')}>
                    <Translate contentKey="tk24AdysApp.krizKatilimciGorev.gorevKapanmaZamaniTitle">Gorev Kapanma Zamani</Translate>{' '}
                    {pageObject.sort === `gorevKapanmaZamani,${ASC}` ? (
                      <FontAwesomeIcon icon={faArrowDown} />
                    ) : (
                      <FontAwesomeIcon icon={faArrowUp} />
                    )}
                  </TableCell>
                  <TableCell style={{ width: 570 }} align="left" className="table-title-cell hand" onClick={sort('note')}>
                    <Translate contentKey="tk24AdysApp.krizKatilimciGorev.note">Note</Translate>
                    {pageObject.sort === `note,${ASC}` ? <FontAwesomeIcon icon={faArrowDown} /> : <FontAwesomeIcon icon={faArrowUp} />}
                  </TableCell>
                  <TableCell style={{ width: 570 }} align="left" className="table-title-cell hand" onClick={sort('adiTr')}>
                    <Translate contentKey="global.languageGlobal.adiTrTitle">Adi Tr</Translate>{' '}
                    {pageObject.sort === `adiTr,${ASC}` ? <FontAwesomeIcon icon={faArrowDown} /> : <FontAwesomeIcon icon={faArrowUp} />}
                  </TableCell>
                  <TableCell style={{ width: 570 }} align="left" className="table-title-cell hand" onClick={sort('adiEn')}>
                    <Translate contentKey="global.languageGlobal.adiEnTitle">Adi En</Translate>{' '}
                    {pageObject.sort === `adiEn,${ASC}` ? <FontAwesomeIcon icon={faArrowDown} /> : <FontAwesomeIcon icon={faArrowUp} />}
                  </TableCell>
                  <TableCell style={{ width: 570 }} align="left" className="table-title-cell hand" onClick={sort('aciklamaTr')}>
                    <Translate contentKey="global.languageGlobal.aciklamaTrTitle">Aciklama Tr</Translate>{' '}
                    {pageObject.sort === `aciklamaTr,${ASC}` ? (
                      <FontAwesomeIcon icon={faArrowDown} />
                    ) : (
                      <FontAwesomeIcon icon={faArrowUp} />
                    )}
                  </TableCell>
                  <TableCell style={{ width: 570 }} align="left" className="table-title-cell hand" onClick={sort('aciklamaEn')}>
                    <Translate contentKey="global.languageGlobal..aciklamaEnTitle">Aciklama En</Translate>
                    {pageObject.sort === `aciklamaEn,${ASC}` ? (
                      <FontAwesomeIcon icon={faArrowDown} />
                    ) : (
                      <FontAwesomeIcon icon={faArrowUp} />
                    )}
                  </TableCell>
                  <TableCell style={{ width: 570 }} align="left" className="table-title-cell hand" onClick={sort('sorumluUniteAdi')}>
                    <Translate contentKey="tk24AdysApp.krizKatilimciGorev.sorumluUniteAdiTitle">Sorumlu Unite Adi</Translate>{' '}
                    {pageObject.sort === `sorumluUniteAdi,${ASC}` ? (
                      <FontAwesomeIcon icon={faArrowDown} />
                    ) : (
                      <FontAwesomeIcon icon={faArrowUp} />
                    )}
                  </TableCell>
                  <TableCell style={{ width: 570 }} align="left" className="table-title-cell hand" onClick={sort('sure')}>
                    <Translate contentKey="tk24AdysApp.krizKatilimciGorev.sureTitle">Sure</Translate>
                    {pageObject.sort === `sure,${ASC}` ? <FontAwesomeIcon icon={faArrowDown} /> : <FontAwesomeIcon icon={faArrowUp} />}
                  </TableCell>
                  <TableCell style={{ width: 570 }} align="left" className="table-title-cell hand">
                    <Translate contentKey="tk24AdysApp.krizKatilimciGorev.krizKatilimciTitle">Kriz Katilimci</Translate>{' '}
                  </TableCell>
                  <TableCell style={{ width: 570 }} align="left" className="table-title-cell hand">
                    <Translate contentKey="tk24AdysApp.krizKatilimciGorev.gorevTitle">Gorev</Translate>
                  </TableCell>
                  <TableCell style={{ width: 570 }} align="left" className="table-title-cell hand">
                    <Translate contentKey="tk24AdysApp.krizKatilimciGorev.gorevTuruTitle">Gorev Turu</Translate>
                  </TableCell>
                  <TableCell style={{ width: 570 }} align="left" className="table-title-cell hand">
                    <Translate contentKey="tk24AdysApp.krizKatilimciGorev.gorevDurumuTitle">Gorev Durumu</Translate>{' '}
                  </TableCell>

                  <TableCell style={{ width: 570 }} align="left" className="table-title-cell hand">
                    <Translate contentKey="tk24AdysApp.krizKatilimciGorev.adtEkip">ADT Timi</Translate>
                  </TableCell>
                  <TableCell style={{ width: 570 }} align="left" className="table-title-cell hand">
                    <Translate contentKey="tk24AdysApp.krizKatilimciGorev.gorevSahibiTitle">Görev Sahibi</Translate>{' '}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {krizKatilimciGorevList.map(krizKatilimciGorev => (
                  <TableRow key={krizKatilimciGorev.id} className="table-cell-row">
                    <TableCell component="th" scope="row" className="table-content-cell">
                      {krizKatilimciGorev?.gorevAtanmaZamani ? (
                        <TextFormat type="date" value={krizKatilimciGorev?.gorevAtanmaZamani} format={APP_DATE_FORMAT} />
                      ) : null}
                    </TableCell>
                    <TableCell component="th" scope="row" className="table-content-cell">
                      {krizKatilimciGorev?.gorevKapanmaZamani ? (
                        <TextFormat type="date" value={krizKatilimciGorev?.gorevKapanmaZamani} format={APP_DATE_FORMAT} />
                      ) : null}
                    </TableCell>
                    <TableCell component="th" scope="row" className="table-content-cell">
                      {krizKatilimciGorev?.note}
                    </TableCell>
                    <TableCell component="th" scope="row" className="table-content-cell">
                      {krizKatilimciGorev?.adiTr}
                    </TableCell>
                    <TableCell component="th" scope="row" className="table-content-cell">
                      {krizKatilimciGorev?.adiEn}
                    </TableCell>
                    <TableCell component="th" scope="row" className="table-content-cell">
                      {krizKatilimciGorev?.aciklamaTr}
                    </TableCell>
                    <TableCell component="th" scope="row" className="table-content-cell">
                      {krizKatilimciGorev?.aciklamaEn}
                    </TableCell>
                    <TableCell component="th" scope="row" className="table-content-cell">
                      {krizKatilimciGorev?.sorumluUniteAdi}
                    </TableCell>
                    <TableCell component="th" scope="row" className="table-content-cell">
                      {krizKatilimciGorev?.sure}
                    </TableCell>
                    <TableCell component="th" scope="row" className="table-content-cell">
                      {krizKatilimciGorev?.krizKatilimci ? <>{krizKatilimciGorev?.krizKatilimci.adi}</> : ''}
                    </TableCell>
                    <TableCell component="th" scope="row" className="table-content-cell">
                      {krizKatilimciGorev?.gorev ? <>{krizKatilimciGorev?.gorev.adi}</> : ''}
                    </TableCell>
                    <TableCell component="th" scope="row" className="table-content-cell">
                      {krizKatilimciGorev?.gorevTuru ? <>{krizKatilimciGorev?.gorevTuru.adi}</> : ''}
                    </TableCell>
                    <TableCell component="th" scope="row" className="table-content-cell">
                      {krizKatilimciGorev?.gorevDurumu ? <>{krizKatilimciGorev?.gorevDurumu.adi}</> : ''}
                    </TableCell>
                    <TableCell component="th" scope="row" className="table-content-cell">
                      {krizKatilimciGorev?.adtEkip ? <>{krizKatilimciGorev?.adtEkip}</> : ''}
                    </TableCell>
                    <TableCell component="th" scope="row" className="table-content-cell">
                      {krizKatilimciGorev?.gorevSahibi ? <>{krizKatilimciGorev?.gorevSahibi.adi}</> : ''}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : (
            !loading && (
              <div className="alert alert-warning">
                <Translate contentKey="tk24AdysApp.kriz.home.notFound">No Krizs found</Translate>
              </div>
            )
          )}
        </TableContainer>

        <CustomPagination
          currentPage={pageObject.page + 1}
          currentPageSize={pageObject.size}
          totalItems={totalItems}
          handlePageChange={handlePageChange}
        />
      </div>
    </div>
  );
};

export default KrizKatilimciGorev;
