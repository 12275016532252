import React, { useEffect, useState } from 'react';
import { TextFormat, Translate } from 'app/component/jhipster';
import { Container } from 'reactstrap';
import { IRefMurettebat } from 'app/shared/model/ref-murettebat.model';
import axios from 'axios';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { LoadingSpinner } from 'app/component/LoadingSpinner';
import { IKabinEgitimBilgileri } from 'app/shared/model/kabin-egitim-bilgileri.model';

interface EtkilenenKisiMurettebatEgitimTabProps {
  refMurettebat: IRefMurettebat;
  tab: number;
}

const EtkilenenKisiMurettebatEgitimTab: React.FC<EtkilenenKisiMurettebatEgitimTabProps> = ({ refMurettebat, tab }) => {
  const [egitimLoading, setEgitimLoading] = useState(false);
  const [kabinEgitimBilgileri, setKabinEgitimBilgileri] = useState<IKabinEgitimBilgileri[]>(null);

  useEffect(() => {
    if (tab === 5) {
      getKabinEgitimBilgileri(refMurettebat);
    }
  }, [tab]);

  const getKabinEgitimBilgileri = murettebat => {
    setEgitimLoading(true);
    const murettebatIdUrl = `api/kabin-egitims?refMurettebatId.equals=${murettebat?.id}&size=2000`;
    axios.get(murettebatIdUrl).then(response => {
      setKabinEgitimBilgileri(response.data);
      setEgitimLoading(false);
    });
  };

  const mapEtkinlikBasariDurumu = (value: number) => {
    switch (value) {
      case 0:
        return 'Başarısız';
      case 1:
        return 'Başarılı';
      case 2:
        return 'Belirsiz';
      default:
        return '';
    }
  };

  const mapEtkinlikTamamlamaDurumu = (value: number) => {
    switch (value) {
      case 0:
        return 'Tamamlamamış';
      case 1:
        return 'Tamamlamış';
      case 2:
        return 'Belirsiz';
      default:
        return '';
    }
  };

  const mapBirimTuru = (value: string) => {
    switch (value) {
      case '0':
        return 'Sınıf İçi Eğitim';
      case '1':
        return 'Sınav';
      case '2':
        return 'İçerik Eğitimi';
      case '4':
        return 'Görev';
      default:
        return '';
    }
  };

  const mapEgitimBirimiDersDurumu = (value: number) => {
    switch (value) {
      case 0:
        return 'Kapalı';
      case 1:
        return 'Açık';
      case 2:
        return 'Serbest';
      default:
        return '';
    }
  };

  const mapEgitimBirimiErisimDurumu = (value: number) => {
    switch (value) {
      case 1:
        return 'Bitmiş';
      case 5:
        return 'Başlamamış';
      case 6:
        return 'Devam Ediyor';
      case 7:
        return 'Sınavsa ve Açıp Kapatılmışsa';
      default:
        return '';
    }
  };

  const mapEgitimBirimiBasariDurumu = (value: number) => {
    switch (value) {
      case 0:
        return 'Başarısız';
      case 1:
        return 'Başarılı';
      case 2:
        return 'Belirsiz';
      default:
        return '';
    }
  };

  return (
    <>
      {egitimLoading && <LoadingSpinner />}
      {!egitimLoading ? (
        <Container>
          <>
            <Typography className="accordion-typography mt-3" gutterBottom component="div">
              <Translate contentKey="tk24AdysApp.refMurettebat.detayTab.egitimBilgileri"> </Translate>
            </Typography>
            <TableContainer className="content-table mb-4">
              {kabinEgitimBilgileri && kabinEgitimBilgileri !== undefined && kabinEgitimBilgileri.length > 0 ? (
                <Table className="accordion-table">
                  <TableHead>
                    <TableRow className="accordion-table-title-row">
                      <TableCell className="accordion-table-title-cell" align="left">
                        <Translate contentKey="tk24AdysApp.refMurettebat.detayTab.etkinlikAdi"></Translate>
                      </TableCell>
                      <TableCell className="accordion-table-title-cell" align="left">
                        <Translate contentKey="tk24AdysApp.refMurettebat.detayTab.etkinlikDurum"></Translate>
                      </TableCell>
                      <TableCell className="accordion-table-title-cell" align="left">
                        <Translate contentKey="tk24AdysApp.refMurettebat.detayTab.etkinlikBasariDurumu"></Translate>
                      </TableCell>
                      <TableCell className="accordion-table-title-cell" align="left">
                        <Translate contentKey="tk24AdysApp.refMurettebat.detayTab.etkinlikPuani"></Translate>
                      </TableCell>
                      <TableCell className="accordion-table-title-cell" align="left">
                        <Translate contentKey="tk24AdysApp.refMurettebat.detayTab.etkinlikTamamlamaDurumu"></Translate>
                      </TableCell>
                      <TableCell className="accordion-table-title-cell" align="left">
                        <Translate contentKey="tk24AdysApp.refMurettebat.detayTab.etkinlikTamamlamaTarihi"></Translate>
                      </TableCell>
                      <TableCell className="accordion-table-title-cell" align="left">
                        <Translate contentKey="tk24AdysApp.refMurettebat.detayTab.birimArayuzKodu"></Translate>
                      </TableCell>
                      <TableCell className="accordion-table-title-cell" align="left">
                        <Translate contentKey="tk24AdysApp.refMurettebat.detayTab.birimAdi"></Translate>
                      </TableCell>
                      <TableCell className="accordion-table-title-cell" align="left">
                        <Translate contentKey="tk24AdysApp.refMurettebat.detayTab.birimTuru"></Translate>
                      </TableCell>
                      <TableCell className="accordion-table-title-cell" align="left">
                        <Translate contentKey="tk24AdysApp.refMurettebat.detayTab.egitimBirimiDersDurumu"></Translate>
                      </TableCell>
                      <TableCell className="accordion-table-title-cell" align="left">
                        <Translate contentKey="tk24AdysApp.refMurettebat.detayTab.egitimBirimiErisimDurumu"></Translate>
                      </TableCell>
                      <TableCell className="accordion-table-title-cell" align="left">
                        <Translate contentKey="tk24AdysApp.refMurettebat.detayTab.egitimBirimiBasariDurumu"></Translate>
                      </TableCell>
                      <TableCell className="accordion-table-title-cell" align="left">
                        <Translate contentKey="tk24AdysApp.refMurettebat.detayTab.egitimBirimiPuan"></Translate>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {kabinEgitimBilgileri?.map(kabinEgitim => (
                      <TableRow key={kabinEgitim.id} className="accordion-table-content-row">
                        <TableCell className="accordion-table-content-cell" component="th" scope="row">
                          {kabinEgitim.etkinlikAdi}
                        </TableCell>
                        <TableCell className="accordion-table-content-cell" align="left">
                          {kabinEgitim.etkinlikDurum?.adi}
                        </TableCell>
                        <TableCell className="accordion-table-content-cell" align="left">
                          {mapEtkinlikBasariDurumu(kabinEgitim.etkinlikBasariDurumu)}
                        </TableCell>
                        <TableCell className="accordion-table-content-cell" align="left">
                          {kabinEgitim.etkinlikPuani}
                        </TableCell>
                        <TableCell className="accordion-table-content-cell" align="left">
                          {mapEtkinlikTamamlamaDurumu(kabinEgitim.etkinlikTamamlamaDurumu)}
                        </TableCell>
                        <TableCell className="accordion-table-content-cell" align="left">
                          <TextFormat type="date" value={kabinEgitim.etkinlikTamamlanmaTarihi} format={APP_LOCAL_DATE_FORMAT} />
                        </TableCell>
                        <TableCell className="accordion-table-content-cell" align="left">
                          {kabinEgitim.birimArayuzKodu}
                        </TableCell>
                        <TableCell className="accordion-table-content-cell" align="left">
                          {kabinEgitim.birimAdi}
                        </TableCell>
                        <TableCell className="accordion-table-content-cell" align="left">
                          {mapBirimTuru(kabinEgitim.birimTuru)}
                        </TableCell>
                        <TableCell className="accordion-table-content-cell" align="left">
                          {mapEgitimBirimiDersDurumu(kabinEgitim.egitimBirimiDersDurumu)}
                        </TableCell>
                        <TableCell className="accordion-table-content-cell" align="left">
                          {mapEgitimBirimiErisimDurumu(kabinEgitim.egitimBirimiErisimDurumu)}
                        </TableCell>
                        <TableCell className="accordion-table-content-cell" align="left">
                          {mapEgitimBirimiBasariDurumu(kabinEgitim.egitimBirimiBasariDurumu)}
                        </TableCell>
                        <TableCell className="accordion-table-content-cell" align="left">
                          {kabinEgitim.egitimBirimiPuan}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              ) : (
                !egitimLoading &&
                kabinEgitimBilgileri?.length === 0 && (
                  <div className="alert alert-warning m-2">
                    <Translate contentKey="tk24AdysApp.refMurettebat.home.kabinEgitimNotFound">kabinEgitimNotFound</Translate>
                  </div>
                )
              )}
            </TableContainer>
          </>
        </Container>
      ) : (
        <></>
      )}
    </>
  );
};

export default EtkilenenKisiMurettebatEgitimTab;
