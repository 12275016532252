import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import KrizKatilimciBildirim from './kriz-katilimci-bildirim';
import KrizKatilimciBildirimDetail from './kriz-katilimci-bildirim-detail';
import KrizKatilimciBildirimUpdate from './kriz-katilimci-bildirim-update';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={KrizKatilimciBildirimUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={KrizKatilimciBildirimUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={KrizKatilimciBildirimDetail} />
      <ErrorBoundaryRoute path={match.url} component={KrizKatilimciBildirim} />
    </Switch>
  </>
);

export default Routes;
