import React, { useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Col, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { Translate, TextFormat, translate } from 'app/component/jhipster';
import { getKrizBildirimByKrizId, getTotalEntities, reset } from './kriz-katilimci-bildirim.reducer';
import { getEntities as getReferans } from 'app/entities/referans/referans.reducer';
import { APP_DATE_FORMAT, SERVER_URL } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { LoadingSpinner } from 'app/component/LoadingSpinner';
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { IQueryParams } from 'app/shared/reducers/reducer.utils';
import { ASC, DESC } from 'app/shared/util/pagination.constants';
import { isNullOrEmpty } from 'app/shared/util/confirm-utils';
import CustomPagination from 'app/component/CustomPagination';
import { IKrizKatilimciBildirim } from 'app/shared/model/kriz-katilimci-bildirim.model';
import { KrizMain } from 'app/component/KrizMain';
import InfoTable from '../infoTable';
import ExcelDownloadButton from 'app/component/ExcelDownload';
import SortIcon from 'app/component/SortIcon';
import { MenuProps } from 'app/shared/util/filter.constants';
import { localeLanguage, upperOnlyFirstLetter } from 'app/shared/util/util';
import { ReferansEnum } from 'app/constants/ReferansEnum';
import { getEntities as getIslemKoduEntities } from '../ref-islem-kodu/ref-islem-kodu.reducer';

export const KrizKatilimciBildirimDetail = (props: RouteComponentProps<{ id: string }>) => {
  const dispatch = useAppDispatch();
  const isDrawer = useAppSelector(state => state.krizChat.isDrawer);

  const referans = useAppSelector(state => state.referans.entities);
  const krizKatilimciBildirimList = useAppSelector(state => state.krizKatilimciBildirim.entities);
  const loading = useAppSelector(state => state.krizKatilimciBildirim.loading);
  const totalItem = useAppSelector(state => state.krizKatilimciBildirim.totalItems);
  const [selectedBildirim, setSelectedBildirim] = useState(null);
  const [isBildirimDetailModal, setIsBildirimDetailModal] = useState(false);

  const gonderimDurumu = referans.filter(res => res.tipi === 'GONDERIM_DURUMU');
  const bildirimTuru = referans.filter(
    res => res.tipi === 'BILDIRIM_TURU' && res.id !== ReferansEnum.BildirimTuru.Ceride && res.id !== ReferansEnum.BildirimTuru.Crysis
  );
  const [search, setSearch] = useState('');
  const islemKoduEntities = useAppSelector(state => state.refIslemKodu.entities);
  const islemKoduLoading = useAppSelector(state => state.refIslemKodu.loading);

  const [pageObject, setPageObject] = useState<IQueryParams>(useAppSelector(state => state.paginate.pageObject));

  const ExcelSampleData = [
    { key: 'iletisimBilgisi', titleContentKey: 'tk24AdysApp.krizKatilimciBildirim.iletisimBilgisi' },
    {
      key: 'planlananGonderimZamani',
      format: 'date',
      titleContentKey: 'tk24AdysApp.krizKatilimciBildirim.planlananGonderimZamani',
    },
    { key: 'gonderimZamani', format: 'date', titleContentKey: 'tk24AdysApp.krizKatilimciBildirim.gonderimZamani' },
    { key: 'krizKatilimci.adi', titleContentKey: 'tk24AdysApp.krizKatilimciBildirim.adi' },
    { key: 'krizKatilimci.soyadi', titleContentKey: 'tk24AdysApp.krizKatilimciBildirim.soyadi' },
    { key: 'konu', titleContentKey: 'tk24AdysApp.krizKatilimciBildirim.konu' },
    { key: 'icerik', titleContentKey: 'tk24AdysApp.krizKatilimciBildirim.icerik' },
    { key: 'bildirimTuru.adi', titleContentKey: 'tk24AdysApp.krizKatilimciBildirim.bildirimTuru' },
    { key: 'islemKodu.adi', titleContentKey: 'tk24AdysApp.krizKatilimciBildirim.islemKodu' },
    { key: 'gonderimDurumu.adi', titleContentKey: 'tk24AdysApp.krizKatilimciBildirim.gonderimDurumu' },
    { key: 'denemeSayisi', titleContentKey: 'tk24AdysApp.krizKatilimciBildirim.denemeSayisi' },
    { key: 'hataMesaji', titleContentKey: 'tk24AdysApp.krizKatilimciBildirim.hataMesaji' },
  ];

  useEffect(() => {
    dispatch(getReferans({ size: 2000, page: 0, sort: `adi${upperOnlyFirstLetter(localeLanguage)},asc` }));
    dispatch(getIslemKoduEntities({ size: 999, page: 0, sort: `adi${upperOnlyFirstLetter(localeLanguage)},asc` }));
    dispatch(reset());
  }, []);

  const getAllEntities = () => {
    dispatch(
      getKrizBildirimByKrizId({
        ...pageObject,
        krizId: `${props.match.params.id}`,
      })
    );
  };

  useEffect(() => {
    getAllEntities();
  }, [pageObject.page, pageObject.query, pageObject.size, pageObject.sort]);

  useEffect(() => {
    if (search.length > 2) {
      setPageObject(prevPageObject => ({
        ...prevPageObject,
        page: 0,
        query: prevPageObject.query + `&globalFilter=${search}`,
      }));
    } else if (search.length === 0) {
      const keyToDelete = `globalFilter`;
      const updatedParams = pageObject.query
        .split('&')
        .filter(param => {
          const [key, _] = param.split('=');
          return key !== keyToDelete;
        })
        .join('&');
      setPageObject({ ...pageObject, page: 0, query: updatedParams });
    }
  }, [search]);

  const sort = p => () => {
    const orderBy = pageObject.sort.split(',')[1];
    setPageObject({ ...pageObject, sort: `${p},${orderBy === ASC ? DESC : ASC}` });
  };

  const handlePageChange = (pageNumber, newItemsPerPage = pageObject.size) => {
    if (newItemsPerPage !== pageObject.size) {
      setPageObject({ ...pageObject, page: pageNumber - 1, size: newItemsPerPage });
    } else {
      setPageObject({ ...pageObject, page: pageNumber - 1 });
    }
  };

  const handleBildirimTuruChange = event => {
    if (!isNullOrEmpty(event.target.value)) {
      setPageObject(prevPageObject => ({
        ...prevPageObject,
        page: 0,
        query: prevPageObject.query + `&bildirimTuruId.equals=${event.target.value}`,
      }));
    } else {
      const keyToDelete = 'bildirimTuruId.equals';
      const updatedParams = pageObject.query
        .split('&')
        .filter(param => {
          const [key, _] = param.split('=');
          return key !== keyToDelete;
        })
        .join('&');

      setPageObject({ ...pageObject, page: 0, query: updatedParams });
    }
  };

  const handleGonderimDurumuChange = event => {
    if (!isNullOrEmpty(event.target.value)) {
      setPageObject(prevPageObject => ({
        ...prevPageObject,
        page: 0,
        query: prevPageObject.query + `&gonderimDurumuId.equals=${event.target.value}`,
      }));
    } else {
      const keyToDelete = 'gonderimDurumuId.equals';
      const updatedParams = pageObject.query
        .split('&')
        .filter(param => {
          const [key, _] = param.split('=');
          return key !== keyToDelete;
        })
        .join('&');

      setPageObject({ ...pageObject, page: 0, query: updatedParams });
    }
  };

  const handleSearchChange = (elem, event) => {
    if (!isNullOrEmpty(event.target.value)) {
      setPageObject(prevPageObject => ({
        ...prevPageObject,
        page: 0,
        query: prevPageObject.query + `&${elem}.equals=${event.target.value}`,
      }));
    } else {
      const keyToDelete = `${elem}.equals`;
      const updatedParams = pageObject.query
        .split('&')
        .filter(param => {
          const [key, _] = param.split('=');
          return key !== keyToDelete;
        })
        .join('&');
      setPageObject({ ...pageObject, page: 0, query: updatedParams });
    }
  };

  const fetchTotalBildirimData = async (): Promise<IKrizKatilimciBildirim[]> => {
    try {
      const response = (await dispatch(
        getTotalEntities({
          ...pageObject,
          size: totalItem,
          krizId: props.match.params.id,
        })
      )) as {
        payload: { data: IKrizKatilimciBildirim[] };
      };

      return response.payload.data || [];
    } catch (error) {
      console.error('Error fetching data:', error);
      return [];
    }
  };

  const handleCloseForm = () => {
    if (isBildirimDetailModal) {
      setIsBildirimDetailModal(false);
    }
    setSelectedBildirim(null);
  };

  const BildirimDetailModal = () => {
    return (
      <Modal className="modal-style" isOpen={isBildirimDetailModal} toggle={handleCloseForm} centered>
        <ModalHeader data-cy="krizKatilimciBildirimDetailsHeading" toggle={handleCloseForm}>
          <p className="modal-title-info">
            {selectedBildirim?.krizKatilimci?.adi} {selectedBildirim?.krizKatilimci?.soyadi}
            {' / '}
            {selectedBildirim?.bildirimTuru?.adi}
          </p>
          <Translate contentKey="tk24AdysApp.krizDetay.krizBildirim" />
        </ModalHeader>
        <ModalBody style={{ maxHeight: '500px', overflowY: 'auto' }}>
          <Row className="modal-body-row">
            <Col lg="12" style={{ marginTop: '10px' }}>
              <Row className="modal-content-title">
                <Translate contentKey="tk24AdysApp.krizKatilimciBildirim.icerik">icerik</Translate>
              </Row>
              <Row className="modal-content-text">{selectedBildirim?.icerik ? selectedBildirim?.icerik : '-'}</Row>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter className="modal-footer"></ModalFooter>
      </Modal>
    );
  };

  return (
    <KrizMain isDrawer={isDrawer}>
      {(loading || islemKoduLoading) && <LoadingSpinner />}
      {isBildirimDetailModal && BildirimDetailModal()}

      <div style={{ marginBottom: 5 }}>
        <InfoTable id={props.match.params.id} titleContentKey={'tk24AdysApp.krizDetay.krizBildirim'} />
      </div>

      <div className="content-area">
        <div className="search-frame">
          <div className="seachiconposition">
            <img className="seachicon" width={24} height={24} src={`${SERVER_URL}/content/images/icon/faSearch.svg`} />
            <Input
              value={search}
              onChange={e => setSearch(e.target.value)}
              placeholder={translate('entity.action.search')}
              className="search-input"
            />
          </div>
          <div className="create-area">
            <Box sx={{ minWidth: 200, maxWidth: 200 }}>
              <FormControl fullWidth>
                <InputLabel id="default-bildirim-turu-label" className="select-input-label">
                  <Translate contentKey="tk24AdysApp.krizKatilimciBildirim.bildirimTuru" />
                </InputLabel>
                <Select
                  className="select-input"
                  labelId="default-bildirim-turu-label"
                  id="default-bildirim-turu"
                  label={translate('tk24AdysApp.krizKatilimciBildirim.bildirimTuru')}
                  onChange={handleBildirimTuruChange}
                >
                  <MenuItem value="">
                    <Translate contentKey="entity.action.all" />
                  </MenuItem>
                  {bildirimTuru &&
                    bildirimTuru.length > 0 &&
                    bildirimTuru.map(item => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.adi}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Box>

            <Box sx={{ minWidth: 200, maxWidth: 200 }}>
              <FormControl fullWidth>
                <InputLabel id="default-gonderim-durumu-label" className="select-input-label">
                  <Translate contentKey="tk24AdysApp.krizKatilimciBildirim.gonderimDurumu" />
                </InputLabel>
                <Select
                  className="select-input"
                  labelId="default-gonderim-durumu-label"
                  id="default-gonderim-durumu"
                  label={translate('tk24AdysApp.krizKatilimciBildirim.gonderimDurumu')}
                  onChange={handleGonderimDurumuChange}
                >
                  <MenuItem value="">
                    <Translate contentKey="entity.action.all" />
                  </MenuItem>
                  {gonderimDurumu &&
                    gonderimDurumu.length > 0 &&
                    gonderimDurumu.map(item => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.adi}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Box>

            <Box sx={{ minWidth: 200, maxWidth: 200 }}>
              <FormControl fullWidth>
                <InputLabel id="islemKodu-label" className="select-input-label">
                  <Translate contentKey="tk24AdysApp.krizKatilimciBildirim.islemKodu" />
                </InputLabel>
                <Select
                  className="select-input"
                  labelId="islemKodu-label"
                  id="islemKodu"
                  label={translate('tk24AdysApp.krizKatilimciBildirim.islemKodu')}
                  onChange={event => handleSearchChange('islemKoduId', event)}
                >
                  <MenuItem value="">
                    <Translate contentKey="entity.action.all" />
                  </MenuItem>
                  {islemKoduEntities &&
                    islemKoduEntities.length > 0 &&
                    islemKoduEntities.map(item => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.adi}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Box>

            <ExcelDownloadButton
              excelSampleData={ExcelSampleData}
              grantedAuthorites={['kriz_katilimci_bildirim_export']}
              fetchData={fetchTotalBildirimData}
              className={'download-button'}
              exportName={translate('tk24AdysApp.krizKatilimciBildirim.home.title')}
            />
          </div>
        </div>

        <div className="table-responsive" style={{ marginTop: 5 }}>
          <TableContainer className="content-table">
            {krizKatilimciBildirimList && krizKatilimciBildirimList.length > 0 ? (
              <Table aria-label="a dense table">
                <TableHead className="content-table-title-area">
                  <TableRow className="table-head-row">
                    <TableCell align="left" className="table-title-cell hand" onClick={sort('iletisimBilgisi')}>
                      <div className="tableHeaderContainer">
                        <Translate contentKey="tk24AdysApp.krizKatilimciBildirim.iletisimBilgisiTitle">Iletisim Bilgisi</Translate>&nbsp;
                        <SortIcon column="iletisimBilgisi" activeSortColumn={pageObject.sort} />
                      </div>
                    </TableCell>
                    <TableCell align="left" className="table-title-cell hand" onClick={sort('planlananGonderimZamani')}>
                      <div className="tableHeaderContainer">
                        <Translate contentKey="tk24AdysApp.krizKatilimciBildirim.planlananGonderimZamaniTitle">
                          Planlanan Gonderim Zamani
                        </Translate>{' '}
                        &nbsp;
                        <SortIcon column="planlananGonderimZamani" activeSortColumn={pageObject.sort} />
                      </div>
                    </TableCell>
                    <TableCell align="left" className="table-title-cell hand" onClick={sort('gonderimZamani')}>
                      <div className="tableHeaderContainer">
                        <Translate contentKey="tk24AdysApp.krizKatilimciBildirim.gonderimZamaniTitle">Gonderim Zamani</Translate> &nbsp;
                        <SortIcon column="gonderimZamani" activeSortColumn={pageObject.sort} />
                      </div>
                    </TableCell>
                    <TableCell align="left" className="table-title-cell hand" onClick={sort('krizKatilimci.adi')}>
                      <div className="tableHeaderContainer">
                        <Translate contentKey="tk24AdysApp.krizKatilimciBildirim.adi">Adı</Translate> &nbsp;
                        <SortIcon column="krizKatilimci.adi" activeSortColumn={pageObject.sort} />
                      </div>
                    </TableCell>
                    <TableCell align="left" className="table-title-cell hand" onClick={sort('krizKatilimci.soyadi')}>
                      <div className="tableHeaderContainer">
                        <Translate contentKey="tk24AdysApp.krizKatilimciBildirim.soyadi">Soyadı</Translate> &nbsp;
                        <SortIcon column="krizKatilimci.soyadi" activeSortColumn={pageObject.sort} />
                      </div>
                    </TableCell>
                    <TableCell align="left" className="table-title-cell hand" onClick={sort(`konu${upperOnlyFirstLetter(localeLanguage)}`)}>
                      <div className="tableHeaderContainer">
                        <Translate contentKey="tk24AdysApp.krizKatilimciBildirim.konu">konu</Translate> &nbsp;
                        <SortIcon column={`konu${upperOnlyFirstLetter(localeLanguage)}`} activeSortColumn={pageObject.sort} />
                      </div>
                    </TableCell>
                    <TableCell align="left" className="table-title-cell hand" onClick={sort('bildirimTuru')}>
                      <div className="tableHeaderContainer">
                        <Translate contentKey="tk24AdysApp.krizKatilimciBildirim.bildirimTuruTitle">Bildirim Turu</Translate> &nbsp;
                        <SortIcon column="bildirimTuru" activeSortColumn={pageObject.sort} />
                      </div>
                    </TableCell>
                    <TableCell align="left" className="table-title-cell hand" onClick={sort('gonderimDurumu')}>
                      <div className="tableHeaderContainer">
                        <Translate contentKey="tk24AdysApp.krizKatilimciBildirim.gonderimDurumu">Gonderim Durumu</Translate> &nbsp;
                        <SortIcon column="gonderimDurumu" activeSortColumn={pageObject.sort} />
                      </div>
                    </TableCell>
                    <TableCell align="left" className="table-title-cell hand" onClick={sort('islemKodu')}>
                      <div className="tableHeaderContainer">
                        <Translate contentKey="tk24AdysApp.krizKatilimciBildirim.islemKoduTitle">İşlem Kodu</Translate> &nbsp;
                        <SortIcon column="islemKodu" activeSortColumn={pageObject.sort} />
                      </div>
                    </TableCell>
                    <TableCell align="left" className="table-title-cell hand" onClick={sort('denemeSayisi')}>
                      <div className="tableHeaderContainer">
                        <Translate contentKey="tk24AdysApp.krizKatilimciBildirim.denemeSayisi">Deneme Sayisi</Translate> &nbsp;
                        <SortIcon column="denemeSayisi" activeSortColumn={pageObject.sort} />
                      </div>
                    </TableCell>
                    <TableCell align="left" className="table-title-cell hand" onClick={sort('hataMesaji')}>
                      <div className="tableHeaderContainer">
                        <Translate contentKey="tk24AdysApp.krizKatilimciBildirim.hataMesaji">Hata Mesaji</Translate> &nbsp;
                        <SortIcon column="hataMesaji" activeSortColumn={pageObject.sort} />
                      </div>
                    </TableCell>
                    <TableCell className="sticky-right-header"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {krizKatilimciBildirimList.map(bildirim => (
                    <TableRow key={bildirim.id} className="table-cell-row">
                      <TableCell className="table-content-cell" component="th" scope="row">
                        <a href={`mailto:${bildirim?.iletisimBilgisi}`} className="epostaText">
                          {bildirim?.iletisimBilgisi}
                        </a>
                      </TableCell>
                      <TableCell className="table-content-cell" component="th" scope="row">
                        {bildirim?.planlananGonderimZamani ? (
                          <TextFormat type="date" value={bildirim?.planlananGonderimZamani} format={APP_DATE_FORMAT} />
                        ) : null}
                      </TableCell>
                      <TableCell className="table-content-cell" component="th" scope="row">
                        {bildirim?.gonderimZamani ? (
                          <TextFormat type="date" value={bildirim?.gonderimZamani} format={APP_DATE_FORMAT} />
                        ) : null}
                      </TableCell>
                      <TableCell className="table-content-cell" component="th" scope="row">
                        {bildirim?.krizKatilimci?.adi}
                      </TableCell>
                      <TableCell className="table-content-cell" component="th" scope="row">
                        {bildirim?.krizKatilimci?.soyadi}
                      </TableCell>
                      <TableCell className="table-content-cell" component="th" scope="row">
                        {bildirim?.konu}
                      </TableCell>
                      <TableCell className="table-content-cell" component="th" scope="row">
                        {bildirim?.bildirimTuru?.adi}
                      </TableCell>
                      <TableCell className="table-content-cell" component="th" scope="row">
                        {bildirim?.gonderimDurumu?.adi}
                      </TableCell>
                      <TableCell className="table-content-cell" component="th" scope="row">
                        {bildirim?.islemKodu?.adi}
                      </TableCell>
                      <TableCell className="table-content-cell" component="th" scope="row">
                        {bildirim?.denemeSayisi}
                      </TableCell>
                      <TableCell className="table-content-cell" component="th" scope="row">
                        {bildirim?.hataMesaji}
                      </TableCell>
                      <TableCell className="sticky-right" align="right">
                        <div className="btn-group flex-btn-group-container">
                          <Button
                            className="view-button"
                            onClick={() => {
                              setSelectedBildirim(bildirim);
                              setIsBildirimDetailModal(true);
                            }}
                            size="sm"
                            data-cy="entityDetailsButton"
                          >
                            <img width={15.2} height={11.2} src={`${SERVER_URL}/content/images/icon/faEye.svg`} />
                            <span className="d-none d-md-inline">
                              <Translate contentKey="entity.action.view">Detay</Translate>
                            </span>
                          </Button>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            ) : (
              !loading && (
                <div className="alert alert-warning m-3">
                  <Translate contentKey="tk24AdysApp.krizKatilimciBildirim.home.notFound">No Bildirim found</Translate>
                </div>
              )
            )}
          </TableContainer>
        </div>

        <CustomPagination
          currentPage={pageObject.page + 1}
          currentPageSize={pageObject.size}
          totalItems={totalItem}
          handlePageChange={handlePageChange}
        />
      </div>
    </KrizMain>
  );
};

export default KrizKatilimciBildirimDetail;
