import React, { useEffect } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, UncontrolledTooltip, Row, Col } from 'reactstrap';
import { Translate, TextFormat } from 'app/component/jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { getEntity } from './ref-gorev-sor-birim.reducer';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

export const RefGorevSorBirimDetail = (props: RouteComponentProps<{ id: string }>) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getEntity(props.match.params.id));
  }, []);

  const refGorevSorBirimEntity = useAppSelector(state => state.refGorevSorBirim.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="refGorevSorBirimDetailsHeading">
          <Translate contentKey="tk24AdysApp.refGorevSorBirim.detail.title">RefGorevSorBirim</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="sorumluUniteAdi">
              <Translate contentKey="tk24AdysApp.refGorevSorBirim.sorumluUniteAdi">Sorumlu Unite Adi</Translate>
            </span>
            <UncontrolledTooltip target="sorumluUniteAdi">
              <Translate contentKey="tk24AdysApp.refGorevSorBirim.help.sorumluUniteAdi" />
            </UncontrolledTooltip>
          </dt>
          <dd>{refGorevSorBirimEntity.org?.adi}</dd>
          <dt>
            <Translate contentKey="tk24AdysApp.refGorevSorBirim.gorev">Gorev</Translate>
          </dt>
          <dd>{refGorevSorBirimEntity.gorev ? refGorevSorBirimEntity.gorev.adi : ''}</dd>
        </dl>
        <Button tag={Link} to="/ref-gorev-sor-birim" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/ref-gorev-sor-birim/${refGorevSorBirimEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default RefGorevSorBirimDetail;
