import { IReferans } from 'app/shared/model/referans.model';
import { IQueryParams } from '../reducers/reducer.utils';
import { IKullanici } from './kullanici.model';

export interface ISistemParametre {
  id?: number;
  guid?: string;
  adiTr?: string;
  adiEn?: string;
  aciklama?: string | null;
  deger?: string;
  cDate?: string;
  cUser?: number;
  mDate?: string;
  mUser?: number;
  durum?: IReferans;
  uygulama?: IReferans;
  note?: string;
  sonGuncelleyenKullanici?: IKullanici;
}

export interface ISistemParametreParams extends IQueryParams {
  entity?: ISistemParametre;
}

export const defaultValue: Readonly<ISistemParametre> = {};
