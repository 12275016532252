import React from 'react';
import { hasAnyAuthority } from 'app/config/store';
import { Box, Tab, Tabs } from '@mui/material';
import { CustomTabPanel } from 'app/component/CustomTabPanel';
import styled from 'styled-components';

const StyledTab = styled(Tab)`
  &:hover {
    box-shadow: 0px -2px 4px 0px #0000000f;
  }
`;

const GrantedTab = ({ headerStyle, headerClassName, tabsClassName, tabStyle, tabClassName, tabs }) => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const filteredTabs = tabs.filter(tab => hasAnyAuthority([tab.authority]));

  return (
    <>
      <Box sx={headerStyle} className={headerClassName}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" className={tabsClassName}>
          {filteredTabs.map((tab, index) => (
            <StyledTab key={index} label={tab.label} className={tab.className} />
          ))}
        </Tabs>
      </Box>
      <Box sx={tabStyle} className={tabClassName}>
        {filteredTabs.map((tab, index) => (
          <CustomTabPanel key={index} value={value} index={index}>
            {tab.component}
          </CustomTabPanel>
        ))}
      </Box>
    </>
  );
};

export default GrantedTab;
