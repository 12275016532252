import { IReferans } from 'app/shared/model/referans.model';
import { IQueryParams } from '../reducers/reducer.utils';

export interface IRefHavalimaniIrtibatTipi {
  id?: number;
  guid?: string;
  cDate?: string;
  cUser?: number;
  mDate?: string;
  mUser?: number;
  durum?: IReferans;
  adi?: string;
  adiTr?: string;
  adiEn?: string;
}

export interface IRefHavalimaniIrtibatTipiParams extends IQueryParams {
  entity?: IRefHavalimaniIrtibatTipi;
}

export const defaultValue: Readonly<IRefHavalimaniIrtibatTipi> = {};
