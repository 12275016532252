import { IRefDDMSDokuman } from 'app/shared/model/ref-ddms-dokuman.model';
import React, { useEffect, useState } from 'react';
import { Button, Input } from 'reactstrap';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getSearchEntities } from '../ref-ddms-dokuman/ref-ddms-dokuman.reducer';
import { Translate, translate } from 'app/component/jhipster';
import { LoadingSpinner } from 'app/component/LoadingSpinner';
import { IQueryParams } from 'app/shared/reducers/reducer.utils';
import CustomPagination from 'app/component/CustomPagination';
import GrantedButton from 'app/component/GrantedButton';
import { isNullOrEmpty } from 'app/shared/util/confirm-utils';
import { MenuProps } from 'app/shared/util/filter.constants';
import { SERVER_URL } from 'app/config/constants';
import { getTotalEntities as getTotalKategoriEntities } from 'app/entities/ref-ddms-dokuman-kategori/ref-ddms-dokuman-kategori.reducer';
import { localeLanguage, upperOnlyFirstLetter } from 'app/shared/util/util';
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';

interface RefKatilimciDetailProp {
  addDdmsDokumanList: (ddmsDokuman: IRefDDMSDokuman) => void;
}

const RefKatilimciDdmsDokumanUpdate: React.FC<RefKatilimciDetailProp> = ({ addDdmsDokumanList }) => {
  const dispatch = useAppDispatch();
  const katilimciDdmsDokumanList = useAppSelector(state => state.refKatilimciDdmsDokuman.entities);
  const refDdmsDokumanList = useAppSelector(state => state.refDdmsDokuman.entities);
  const loading = useAppSelector(state => state.refDdmsDokuman.loading);
  const totalItem = useAppSelector(state => state.refDdmsDokuman.totalItems);

  const [pageObject, setPageObject] = useState<IQueryParams>(useAppSelector(state => state.paginate.pageObject));

  const [search, setSearch] = useState('');

  const kategoriList = useAppSelector(state => state.refDdmsDokumanKategori.totalEntities);
  const kategoriLoading = useAppSelector(state => state.refDdmsDokumanKategori.loading);

  useEffect(() => {
    dispatch(getTotalKategoriEntities({ size: 999, page: 0, sort: `adi${upperOnlyFirstLetter(localeLanguage)},asc` }));
  }, []);

  const sortEntities = () => {
    dispatch(getSearchEntities(pageObject));
  };

  useEffect(() => {
    sortEntities();
  }, [pageObject.page, pageObject.query, pageObject.size, pageObject.sort]);

  useEffect(() => {
    if (search.length > 2) {
      setPageObject(prevPageObject => ({
        ...prevPageObject,
        page: 0,
        query: prevPageObject.query + `&globalFilter=${search}`,
      }));
    } else if (search.length === 0 && !isNullOrEmpty(pageObject.query)) {
      const keyToDelete = 'globalFilter';
      const updatedParams = pageObject.query
        .split('&')
        .filter(param => {
          const [key, _] = param.split('=');
          return key !== keyToDelete;
        })
        .join('&');

      setPageObject({ ...pageObject, page: 0, query: updatedParams });
    }
  }, [search]);

  const handlePageChange = (pageNumber, newItemsPerPage = pageObject.size) => {
    if (newItemsPerPage !== pageObject.size) {
      setPageObject({ ...pageObject, page: pageNumber - 1, size: newItemsPerPage });
    } else {
      setPageObject({ ...pageObject, page: pageNumber - 1 });
    }
  };

  const handleSearchChange = (elem, event) => {
    if (!isNullOrEmpty(event.target.value)) {
      setPageObject(prevPageObject => ({
        ...prevPageObject,
        page: 0,
        query: prevPageObject.query + `&${elem}.equals=${event.target.value}`,
      }));
    } else {
      const keyToDelete = `${elem}.equals`;
      const updatedParams = pageObject.query
        .split('&')
        .filter(param => {
          const [key, _] = param.split('=');
          return key !== keyToDelete;
        })
        .join('&');
      setPageObject({ ...pageObject, page: 0, query: updatedParams });
    }
  };

  return (
    <>
      <div className="search-frame">
        <div className="seachiconposition">
          <img className="seachicon" width={24} height={24} src={`${SERVER_URL}/content/images/icon/faSearch.svg`} />
          <Input
            value={search}
            onChange={e => setSearch(e.target.value)}
            placeholder={translate('entity.action.search')}
            className="search-input"
          />

          <Box sx={{ minWidth: 220, maxWidth: 220, marginLeft: 1 }}>
            <FormControl style={{ minHeight: 48, maxHeight: 48 }} fullWidth>
              <InputLabel id="dokuman-turu-label">
                <Translate contentKey="tk24AdysApp.refDdmsDokuman.kategori" />
              </InputLabel>
              <Select
                style={{ minHeight: 48, maxHeight: 48, borderRadius: '6px' }}
                labelId="dokuman-turu-label"
                id="dokuman-turu"
                label={translate('tk24AdysApp.refDdmsDokuman.kategori')}
                onChange={event => handleSearchChange('refDdmsDokumanCategoryId', event)}
                MenuProps={MenuProps}
              >
                <MenuItem value="">
                  <Translate contentKey="entity.action.all" />
                </MenuItem>
                {kategoriList &&
                  kategoriList.map(item => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.adi}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Box>
        </div>
      </div>

      {loading || kategoriLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <div className="table-responsive">
            <TableContainer className="content-table response-modal-height">
              {refDdmsDokumanList && refDdmsDokumanList.length > 0 ? (
                <Table aria-label="a dense table" stickyHeader>
                  <TableHead className="content-table-title-area">
                    <TableRow className="table-head-row">
                      <TableCell className="table-title-cell-kriz-create hand" align="left">
                        <Translate contentKey="tk24AdysApp.refDdmsDokuman.kategori">Kategori</Translate>
                      </TableCell>
                      <TableCell className="table-title-cell-kriz-create hand" align="left">
                        <Translate contentKey="tk24AdysApp.refDdmsDokuman.dokumanNo">Dokuman No</Translate>
                      </TableCell>
                      <TableCell className="table-title-cell-kriz-create hand" align="left">
                        <Translate contentKey="tk24AdysApp.refDdmsDokuman.revizeNo">Revize No</Translate>
                      </TableCell>
                      <TableCell className="table-title-cell-kriz-create hand" align="left">
                        <Translate contentKey="global.languageGlobal.adi">Adi</Translate>
                      </TableCell>
                      <TableCell className="table-title-cell-kriz-create" style={{ minWidth: '270px' }}></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <>
                      {refDdmsDokumanList.map(refDdmsDokuman => (
                        <TableRow key={refDdmsDokuman.id} className="table-cell-row">
                          <TableCell className="table-content-cell" component="th" scope="row">
                            {refDdmsDokuman.refDdmsDokumanKategori?.adi}
                          </TableCell>
                          <TableCell className="table-content-cell" component="th" scope="row">
                            {refDdmsDokuman.dokumanNo}
                          </TableCell>
                          <TableCell className="table-content-cell" component="th" scope="row">
                            {refDdmsDokuman.revizeNo}
                          </TableCell>
                          <TableCell className="table-content-cell" component="th" scope="row">
                            {refDdmsDokuman.adi}
                          </TableCell>
                          <TableCell className="table-content-cell" align="right">
                            <div className="btn-group flex-btn-group-container">
                              {katilimciDdmsDokumanList.some(item => item.ddmsDokuman && item.ddmsDokuman.id === refDdmsDokuman.id) ? (
                                <>
                                  <Button color="primary" id="start-entity" data-cy="entityStartButton" className="savedButton">
                                    <img width={14} height={14} src={`${SERVER_URL}/content/images/icon/onayIcon.svg`} />
                                    <span className="d-none d-md-inline">
                                      {translate('tk24AdysApp.refKatilimciDdmsDokuman.dokumanEklendi')}
                                    </span>
                                  </Button>
                                </>
                              ) : (
                                <>
                                  <GrantedButton
                                    grantedAuthorites={['ref_katilimci_ddms_dokuman_edit']}
                                    id="start-entity"
                                    data-cy="entityStartButton"
                                    className="saveButton"
                                    onClick={() => addDdmsDokumanList(refDdmsDokuman)}
                                    disabled={katilimciDdmsDokumanList.some(
                                      item => item.ddmsDokuman && item.ddmsDokuman.id === refDdmsDokuman.id
                                    )}
                                  >
                                    <img width={14} height={14} src={`${SERVER_URL}/content/images/icon/faPlusLtBlue.svg`} />
                                    <span className="d-none d-md-inline">
                                      {translate('tk24AdysApp.refKatilimciDdmsDokuman.home.createLabel')}
                                    </span>
                                  </GrantedButton>
                                </>
                              )}
                            </div>
                          </TableCell>
                        </TableRow>
                      ))}
                    </>
                  </TableBody>
                </Table>
              ) : (
                <div className="alert alert-warning">
                  <Translate contentKey="tk24AdysApp.refDdmsDokuman.home.notFound">No Kriz Cerides found</Translate>
                </div>
              )}
            </TableContainer>
          </div>
          <>
            {totalItem ? (
              <div className={refDdmsDokumanList && refDdmsDokumanList.length > 0 ? '' : 'd-none'}>
                <CustomPagination
                  currentPage={pageObject.page + 1}
                  currentPageSize={pageObject.size}
                  totalItems={totalItem}
                  handlePageChange={handlePageChange}
                />
              </div>
            ) : (
              ''
            )}
          </>
        </>
      )}
    </>
  );
};

export default RefKatilimciDdmsDokumanUpdate;
