import React, { useCallback, useEffect, useRef, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import InfoTable from 'app/entities/infoTable';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { KrizMain } from 'app/component/KrizMain';
import { Button, Col, Form, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { Translate, translate } from 'app/component/jhipster';
import { Box, FormControl, InputLabel, LinearProgress, MenuItem, Select, Autocomplete, TextField } from '@mui/material';
import { IQueryParams } from 'app/shared/reducers/reducer.utils';
import { ASC } from 'app/shared/util/pagination.constants';
import {
  createEntity as createKatilimciGorevEntity,
  deleteEntity,
  getEntities,
  reset,
  updateEntity as updateKatilimciGorevEntity,
} from 'app/entities/kriz-katilimci-gorev/kriz-katilimci-gorev.reducer';
import axios from 'axios';
import { ReferansEnum } from 'app/constants/ReferansEnum';
import { IKrizIstasyon } from 'app/shared/model/kriz-istasyon.model';
import { MenuProps } from 'app/shared/util/filter.constants';
import {
  CustomDeleteEntityConfirm,
  CustomSaveEntityConfirm,
  isNullOrEmpty,
  isObjectEmpty,
  saveEntityConfirm,
} from 'app/shared/util/confirm-utils';
import { Cached, Search } from '@mui/icons-material';
import { getEntities as getReferans } from 'app/entities/referans/referans.reducer';
import GrantedButton from 'app/component/GrantedButton';
import { SERVER_URL } from 'app/config/constants';
import { LoadingSpinner } from 'app/component/LoadingSpinner';
import { IKrizKatilimciGorev } from 'app/shared/model/kriz-katilimci-gorev.model';
import TableNameEnum from 'app/constants/TableNameEnum';
import {
  uploadEntity as uploadEkDosyaEntity,
  getFilteredEntities as getFilterGorevEkDosyaEntities,
} from 'app/entities/ek-dosya/ek-dosya.reducer';
import GorevDetail from './gorev-detail';
import { getEntities as getRefEkDosyaTipis } from 'app/entities/ref-ek-dosya-tipi/ref-ek-dosya-tipi.reducer';
import { toast } from 'react-toastify';
import FormDialog from 'app/component/FormDialog';
import GorevUpdate from './gorev-update';
import { getEntity as getKrizEntitiy } from '../kriz/kriz.reducer';
import { GetBase64PromiseString } from 'app/shared/util/util';
import { RefEkDosyaTipiCodeEnum } from 'app/constants/RefEkDosyaTipiCodeEnum';
import GrantedCheckbox from 'app/component/GrantedCheckbox';
import CustomPagination from 'app/component/CustomPagination';
import { FormItem } from 'app/component/FormItem';
import ExcelDownloadButton from 'app/component/ExcelDownload';
import { IRefHavalimani } from 'app/shared/model/ref-havalimani.model';
import { validationKrizIstasyonEkip } from 'app/shared/util/get-custom-service';
import { getEntities as getEntitiesRefIstasyonGrubu } from '../ref-istasyon-gorev-grubu/ref-istasyon-gorev-grubu.reducer';
interface GorevComponentProp extends RouteComponentProps<{ id: string }> {
  sicilId?: string;
}

const KrizIstasyonGorev = (props: GorevComponentProp) => {
  const { match } = props;
  const { sicilId } = props;
  const dispatch = useAppDispatch();
  const isDrawer = useAppSelector(state => state.krizChat.isDrawer);
  const [tamamlananGorevCount, setTamamlananGorevCount] = useState(0);
  const [dataChart, setDataChart] = useState([]);

  const totalValue: number = dataChart.reduce((sum, [, , value]) => sum + value, 0);
  const percentageDone = totalValue > 0 ? Math.round((100 / totalValue) * tamamlananGorevCount * 100) / 100 : 0;
  const pageGorev = useAppSelector(state => state.paginate.pageObject);
  const krizKatilimciGorevLoading = useAppSelector(state => state.krizKatilimciGorev.loading);
  const krizIstasyonUpdating = useAppSelector(state => state.krizKatilimciGorev.updating);
  const krizKatilimciGorevs = useAppSelector(state => state.krizKatilimciGorev.entities);
  const totalItem = useAppSelector(state => state.krizKatilimciGorev.totalItems);
  const [krizIstasyonGorevLoading, setKrizIstasyonGorevLoading] = useState(false);
  const [krizIstasyonLoading, setKrizIstasyonLoading] = useState(false);
  const [pageObject, setPageObject] = useState<IQueryParams>({ ...pageGorev, sort: `id,${ASC}` });
  const gorevDurumuColors = {
    [ReferansEnum.GorevDurumu.Tamamlandi]: '#83E59F',
    [ReferansEnum.GorevDurumu.IptalEdildi]: '#ED6068',
    [ReferansEnum.GorevDurumu.DevamEdiyor]: '#5FB6FF',
  };
  const [search, setSearch] = useState('');

  const [krizIstasyonList, setKrizIstasyonList] = useState<IKrizIstasyon[]>([]);
  const [krizHavalimani, setKrizHavalimani] = useState(null);
  const referans = useAppSelector(state => state.referans.entities);
  const [selectedTask, setSelectedTask] = useState<IKrizKatilimciGorev>(null);
  const [selectedTaskStatus, setSelectedTaskStatus] = useState(null);
  const [isTaskDetailModal, setIsTaskDetailModal] = useState(false);
  const krizGorevEkDosyaList = useAppSelector(state => state.ekDosya.entities);
  const krizGorevEkDosyaListLoading = useAppSelector(state => state.ekDosya.loading);
  const krizGorevEkDosyaListUpdating = useAppSelector(state => state.ekDosya.updating);
  const [isChangeStatusOpen, setIsChangeStatusOpen] = useState(false);
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [selectedTaskNote, setSelectedTaskNote] = useState(null);
  const [isOpenTaskModal, setIsOpenTaskModal] = useState(false);
  const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);
  const [modalPromiseResolve, setModalPromiseResolve] = useState(null);
  const kriz = useAppSelector(state => state.kriz.entity);
  const [selectedMoreTask, setSelectedMoreTask] = useState(null);
  const [openOwner, setOpenOwner] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isGorevEkDosyaModal, setIsGorevEkDosyaModal] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const fileInputRef = useRef(null);
  const [ekDosyaLoading, setEkDosyaLoading] = useState(false);
  const refEkDosyaTipis = useAppSelector(state => state.refEkDosyaTipi.entities);
  const [selectedGorevTuru, setSelectedGorevTuru] = useState('');
  const [selectedGorevDurumu, setSelectedGorevDurumu] = useState('');
  const [selectedIstasyonGorevGrubu, setSelectedIstasyonGorevGrubu] = useState('');
  const [fetchTotalGorev, setFetchTotalGorev] = useState(false);
  const [fileNames, setFileNames] = useState({});
  const [ekDosyaAciklama, setEkDosyaAciklama] = useState('');
  const refIstasyonGorevGrubuList = useAppSelector(state => state.refIstasyonGorevGrubu.entities);

  const ExcelSampleData = [
    { key: 'adi', titleContentKey: 'tk24AdysApp.krizKatilimciGorev.gorevAdi' },
    { key: 'aciklama', titleContentKey: 'tk24AdysApp.krizKatilimciGorev.gorevAciklama' },
    { key: 'note', titleContentKey: 'tk24AdysApp.krizKatilimciGorev.note' },
    { key: 'gorevTuru', titleContentKey: 'tk24AdysApp.krizKatilimciGorev.gorevTuru' },
    { key: 'gorevDurumu', titleContentKey: 'tk24AdysApp.krizKatilimciGorev.gorevDurumu' },
    { key: 'gorevSahibi', titleContentKey: 'tk24AdysApp.krizKatilimciGorev.gorevSahibi' },
    { key: 'istasyonGorevGrubu', titleContentKey: 'tk24AdysApp.refIstasyonGorevGrubu.home.title' },
    { key: 'gorevAtanmaZamani', format: 'date', titleContentKey: 'tk24AdysApp.krizKatilimciGorev.gorevAtanmaZamani' },
    { key: 'gorevKapanmaZamani', format: 'date', titleContentKey: 'tk24AdysApp.krizKatilimciGorev.gorevKapanmaZamani' },
  ];

  useEffect(() => {
    dispatch(getReferans({ page: 0, size: 500, sort: 'asc' }));
    dispatch(getRefEkDosyaTipis({ page: 0, size: 1000, sort: 'id,asc', hasParent: true }));
    dispatch(getKrizEntitiy(match.params.id));
    dispatch(getEntitiesRefIstasyonGrubu({ page: 0, size: 1000, sort: 'asc' }));
    getKrizIstasyon();
  }, []);

  const getKrizIstasyon = async () => {
    setKrizIstasyonLoading(true);

    const krizIstasyon = await validationKrizIstasyonEkip(match.params.id);
    setKrizIstasyonList(krizIstasyon);

    setKrizIstasyonLoading(false);
  };

  const getAllTaskList = () => {
    const taskChartUrl =
      'api/kriz-katilimci-gorevs/count-by-gorev-durum?page=0&gorevSahibiId.equals=152&size=2000&krizId.equals=' + match.params.id;
    axios.get(taskChartUrl).then(response => {
      const gorev = [];
      const colors = [];
      const dataTasks = response.data;
      dataTasks.map(data => {
        colors.push(gorevDurumuColors[data.gorevDurumuId]);
        gorev.push([data.gorevDurumuId, data.gorevDurumuAdi, data.count]);
      });
      setDataChart(gorev);
      setTamamlananGorevCount(gorev[1][2]);
    });
  };

  const getTaskList = krizHavalimanis => {
    setKrizIstasyonGorevLoading(true);
    const havalimanis = krizIstasyonList.find(it => it.havalimani?.id === krizHavalimanis.id);
    setKrizHavalimani(havalimanis?.havalimani);
    dispatch(reset());
    dispatch(
      getEntities({
        ...pageObject,
        krizId: match.params.id,
        refHavalimaniIstasyonId: krizHavalimanis.id,
      })
    );
    setKrizIstasyonGorevLoading(false);

    if (fetchTotalGorev === true) {
      const taskChartUrl =
        'api/kriz-katilimci-gorevs/count-by-gorev-durum?gorevSahibiId.equals=152&refHavalimaniIstasyonId.equals=' +
        krizHavalimanis.id +
        '&krizId.equals=' +
        match.params.id +
        '&size=100';
      setDataChart([]);
      axios.get(taskChartUrl).then(response => {
        const gorev = [];
        const colors = [];
        const dataTasks = response.data;
        dataTasks.map(data => {
          colors.push(gorevDurumuColors[data.gorevDurumuId]);
          gorev.push([data.gorevDurumuId, data.gorevDurumuAdi, data.count]);
        });
        setTamamlananGorevCount(gorev[1][2]);
        setDataChart(gorev);
      });
    }
  };

  const fetchTotalIstasyonGorevData = () => {
    return krizKatilimciGorevs.map(task => ({
      adi: task.adi,
      aciklama: task.aciklama,
      note: task.note,
      gorevTuru: task.gorevTuru.adi,
      gorevDurumu: task.gorevDurumu.adi,
      gorevSahibi: task.gorevSahibi.adi,
      istasyonGorevGrubu: task.istasyonGorevGrubu.adi,
      gorevAtanmaZamani: task.gorevAtanmaZamani,
      gorevKapanmaZamani: task.gorevKapanmaZamani,
    }));
  };

  useEffect(() => {
    if (krizHavalimani) {
      getTaskList(krizHavalimani);
    } else if (!sicilId) {
      getAllTaskList();
    }
  }, [krizHavalimani, pageObject.page, pageObject.query, pageObject.size, pageObject.sort, sicilId, fetchTotalGorev]);

  useEffect(() => {
    if (search.length > 2) {
      if (krizHavalimani && krizHavalimani.id) {
        setPageObject({ ...pageObject, page: 0, query: `globalFilter=${search}&refHavalimaniIstasyonId.equals=${krizHavalimani.id}` });
      }
    } else if (search.length === 0) {
      if (krizHavalimani && krizHavalimani.id) {
        setPageObject({ ...pageObject, page: 0, query: `refHavalimaniIstasyonId.equals=${krizHavalimani.id}` });
      }
    }
  }, [search]);

  const getEkDosya = taskId => {
    dispatch(
      getFilterGorevEkDosyaEntities({
        ilgiliTabloKayitId: taskId,
        ilgiliTablo: TableNameEnum.KrizKatilimciGorev,
      })
    );
  };

  const krizIstasyonListChange = (elem, event) => {
    if (!isNullOrEmpty(event.target.value)) {
      setPageObject(prevPageObject => ({
        ...prevPageObject,
        page: 0,
        query: `${elem}.equals=${event.target.value}`,
      }));
    } else {
      const keyToDelete = `${elem}.equals`;
      const updatedParams = pageObject.query
        .split('&')
        .filter(param => {
          const [key, _] = param.split('=');
          return key !== keyToDelete;
        })
        .join('&');
      setPageObject({ ...pageObject, page: 0, query: updatedParams });
    }
    if (event.target.value === null) {
      setKrizHavalimani(null);
    } else {
      setFetchTotalGorev(true);
      const havalimani = krizIstasyonList.find(it => it.havalimani?.id.toString() === event.target.value.toString());
      setKrizHavalimani(havalimani?.havalimani);
    }

    setSelectedGorevTuru('');
    setSelectedGorevDurumu('');
  };

  const getTextColor = gorevDurumu => {
    if (gorevDurumu) {
      switch (gorevDurumu.id) {
        case ReferansEnum.GorevDurumu.DevamEdiyor:
          return '#004279';
        case ReferansEnum.GorevDurumu.IptalEdildi:
          return '#AA050E';
        case ReferansEnum.GorevDurumu.Tamamlandi:
          return '#146F2E';
        default:
          return '';
      }
    }
    return '';
  };

  const getBadgeBackgroundColor = gorevDurumu => {
    if (gorevDurumu) {
      switch (gorevDurumu.id) {
        case ReferansEnum.GorevDurumu.DevamEdiyor:
          return '#E6F3FF';
        case ReferansEnum.GorevDurumu.IptalEdildi:
          return '#FCE6E7';
        case ReferansEnum.GorevDurumu.Tamamlandi:
          return '#E8FEEF';
        default:
          return '';
      }
    }
    return '';
  };

  const handleSearchChange = (elem, event) => {
    setFetchTotalGorev(false);
    const value = event.target.value;
    if (elem === 'gorevTuruId') {
      setSelectedGorevTuru(value);
    } else if (elem === 'gorevDurumuId') {
      setSelectedGorevDurumu(value);
    } else if (elem === 'istasyonGorevGrubuId') {
      setSelectedIstasyonGorevGrubu(value);
    }

    if (!isNullOrEmpty(value)) {
      const newQuery = pageObject.query
        .split('&')
        .filter(param => !param.startsWith(`${elem}.equals`))
        .concat(`${elem}.equals=${value}`)
        .join('&');
      setPageObject(prevPageObject => ({
        ...prevPageObject,
        page: 0,
        query: newQuery,
      }));
    } else {
      const keyToDelete = `${elem}.equals`;
      const updatedParams = pageObject.query
        .split('&')
        .filter(param => {
          const [key, _] = param.split('=');
          return key !== keyToDelete;
        })
        .join('&');
      setPageObject({ ...pageObject, page: 0, query: updatedParams });
    }
  };

  const krizIstasyonGorevUpdated = async () => {
    setKrizIstasyonGorevLoading(true);
    if (selectedTask && selectedTask !== null) {
      const krizIstasyonGorev = selectedTask;
      const gorevUrl = 'api/kriz-katilimci-gorevs/' + selectedTask.id;
      try {
        const response = await axios.put(gorevUrl, krizIstasyonGorev);
        setSelectedTask(null);
        toast.success(translate('tk24AdysApp.krizKatilimciGorev.updated', { param: response.data.id }));
        setIsChangeStatusOpen(false);
        getTaskList(krizHavalimani);
        setFetchTotalGorev(true);
        setKrizIstasyonGorevLoading(false);
      } catch (error) {
        window.console.error('Error sending POST request:', error);
      }
    }
  };
  const handleChangeStatusCloseDialog = () => {
    setSelectedTask(null);
    setIsChangeStatusOpen(false);
  };

  const handleCloseUpdateForm = () => {
    setIsOpenTaskModal(false);
    setSelectedTask(null);
    setIsTaskDetailModal(false);
    setSelectedMoreTask(null);
  };

  const showSaveConfirmModal = () => {
    setIsSaveModalOpen(true);
    return new Promise(resolve => {
      setModalPromiseResolve(() => resolve);
    });
  };

  const handleConfirm = () => {
    if (modalPromiseResolve) {
      modalPromiseResolve(true);
    }
    setIsSaveModalOpen(false);
  };

  const handleCancel = () => {
    if (modalPromiseResolve) {
      modalPromiseResolve(false);
    }
    setIsSaveModalOpen(false);
  };

  const saveNote = async note => {
    setKrizIstasyonGorevLoading(true);
    const saveUrl = 'api/kriz-katilimci-gorevs/' + selectedTaskNote.id;
    try {
      const updatedData = { ...selectedTaskNote, note };
      await axios.put(saveUrl, updatedData);
      handleCloseDialog();
      getTaskList(krizHavalimani);
      setKrizIstasyonGorevLoading(false);
    } catch (error) {
      console.error('PUT isteği gönderirken hata oluştu:', error);
    }
  };

  const NoteAddModal = () => {
    return <FormDialog open={isDialogOpen} onClose={handleCloseDialog} saveNote={saveNote} data={selectedTaskNote} />;
  };

  const noteChange = event => {
    setSelectedTask(prevState => ({
      ...prevState,
      note: event.target.value,
    }));
  };

  const changeTaskStatusModal = () => {
    return (
      <Modal className="modal-style" isOpen={isChangeStatusOpen} toggle={handleChangeStatusCloseDialog} centered>
        <ModalHeader data-cy="refDuyuruTipiDetailsHeading" toggle={handleChangeStatusCloseDialog}>
          <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            <img src={`${SERVER_URL}/content/images/icon/WarningRed.svg`} style={{ height: '52px' }} />
            <Translate contentKey="tk24AdysApp.krizKatilimciGorev.durumDegistir" />
          </div>
        </ModalHeader>
        {selectedTask && (
          <div id="gorevDurumuName" className="gorevDurumuName">
            <p className="m-0">{selectedTask.adi}</p>
          </div>
        )}
        <ModalBody style={{ maxHeight: '450px', overflowY: 'auto' }}>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', margin: '10px 0px 30px' }}>
            <span
              className="modal-gorev-durumu-badge"
              style={{
                color: getTextColor(selectedTaskStatus),
                backgroundColor: getBadgeBackgroundColor(selectedTaskStatus),
              }}
            >
              {selectedTaskStatus ? selectedTaskStatus?.adi : null}
            </span>
            <img src={`${SERVER_URL}/content/images/icon/gorevRightArrow.svg`} />
            {selectedTaskStatus?.id === ReferansEnum.GorevDurumu.Tamamlandi ? (
              <span
                className="modal-gorev-durumu-badge"
                style={{
                  color: '#004279',
                  backgroundColor: '#E6F3FF',
                }}
              >
                <Translate contentKey="tk24AdysApp.krizKatilimciGorev.devamEdiyor" />
              </span>
            ) : (
              <span
                className="modal-gorev-durumu-badge"
                style={{
                  color: '#146F2E',
                  backgroundColor: '#E8FEEF',
                }}
              >
                <Translate contentKey="tk24AdysApp.krizKatilimciGorev.tamamlandi" />
              </span>
            )}
          </div>
          <div className="model-title-info gorevDurumu ">
            <p className="m-0">
              {selectedTask && selectedTask.gorevDurumu !== null && (
                <>{translate('tk24AdysApp.krizKatilimciGorev.update.question', { durum: selectedTask.gorevDurumu.adi })}</>
              )}
            </p>
          </div>

          <FormItem
            label={translate('global.messages.not')}
            id="gorev-not"
            name="gorevNot"
            defaultValue={selectedTask?.note}
            type="textarea"
            placeholder={translate('tk24AdysApp.refGorev.placeholder.not')}
            onChange={noteChange}
            validation={{
              maxLength: { value: 4000, message: translate('entity.validation.maxlength', { max: 4000 }) },
            }}
          />
        </ModalBody>
        <ModalFooter className="modal-footer">
          <Button className="cancel-button" onClick={handleChangeStatusCloseDialog}>
            <Translate contentKey="entity.action.cancel">Cancel</Translate>
          </Button>
          &nbsp;
          <Button
            className="save-button"
            onClick={krizIstasyonGorevUpdated}
            id="jhi-confirm-delete-refAdtForm"
            type="submit"
            data-cy="entityConfirmButton"
          >
            <Translate contentKey="entity.action.save">Kaydet</Translate>
          </Button>
        </ModalFooter>
      </Modal>
    );
  };

  const taskDetailModal = () => {
    return (
      selectedTask && (
        <Modal className="modal-style-lg" isOpen={isTaskDetailModal} toggle={handleCloseUpdateForm} centered>
          <ModalHeader data-cy="refAdtFormUpdateDialogHeading" toggle={handleCloseUpdateForm}>
            <Translate contentKey="tk24AdysApp.krizKatilimciGorev.detail.title">Mission Detail</Translate>
          </ModalHeader>
          {selectedTask && (
            <div id="gorevDurumuName" className="gorevDurumuName">
              <p className="m-0">{selectedTask.adi}</p>
            </div>
          )}
          <ModalBody style={{ maxHeight: '550px', overflowY: 'auto' }}>
            <GorevDetail
              selectedTask={selectedTask}
              ekDosya={krizGorevEkDosyaList}
              update={getEkDosya}
              reload={() => getTaskList(krizHavalimani)}
              kriz={kriz}
            ></GorevDetail>
          </ModalBody>
        </Modal>
      )
    );
  };

  const changeTaskStatus = (task, newStatusId) => {
    setSelectedTaskStatus(task.gorevDurumu);
    const gorevDurumuId = newStatusId !== null ? newStatusId : ReferansEnum.GorevDurumu.IptalEdildi;

    const gorevDurumu = referans.find(it => it.id.toString() === gorevDurumuId.toString());
    setSelectedTask(prevState => ({
      ...prevState,
      ...task,
      gorevDurumu,
    }));

    setIsChangeStatusOpen(true);
  };

  const saveNewTask = async e => {
    e.preventDefault();
    const isSave = await showSaveConfirmModal();
    if (isSave) {
      const formDataObject = {};
      for (const field of e.target.elements) {
        if (field.name) {
          formDataObject[field.name] = field.value;
        }
      }

      const kEntity = {
        ...selectedTask,
        ...formDataObject,
        refHavalimaniIstasyon: krizHavalimani,
        gorevTuru: referans.find(it => it.id === ReferansEnum.GorevTuru.IstasyonEkGorev),
        gorevSahibi: referans.find(it => it.id === ReferansEnum.GorevSahibi.Istasyon),
        kriz,
      };

      if (selectedTask !== null) {
        const katilimciGorevEntity = {
          ...pageObject,
          krizId: `${match.params.id}`,
          refHavalimaniIstasyonId: krizHavalimani && krizHavalimani.id,
          entity: kEntity,
        };
        await dispatch(updateKatilimciGorevEntity(katilimciGorevEntity));
      } else {
        const katilimciGorevEntity = {
          ...pageObject,
          krizId: `${match.params.id}`,
          refHavalimaniIstasyonId: krizHavalimani && krizHavalimani.id,
          entity: kEntity,
        };
        await dispatch(createKatilimciGorevEntity(katilimciGorevEntity));
      }
      handleCloseUpdateForm();
      getTaskList(krizHavalimani);
    }
  };

  const newTaskModal = () => {
    return (
      <GorevUpdate
        saveNewTask={saveNewTask}
        handleCloseForm={handleCloseUpdateForm}
        formValues={selectedTask}
        isOpen={isOpenTaskModal}
      ></GorevUpdate>
    );
  };

  const handleCloseEkDosyaModal = () => {
    setSelectedFiles([]);
    setFileNames({});
    setEkDosyaAciklama('');
    setIsGorevEkDosyaModal(false);
    setSelectedTask(null);
  };

  const handleOpenEkDosyaModal = () => {
    setIsGorevEkDosyaModal(true);
  };

  const handleDrop = event => {
    event.preventDefault();
    const files = Array.from(event.dataTransfer.files);
    setSelectedFiles([...selectedFiles, ...files]);
  };

  const getFileNameAndExtension = filename => {
    const dotIndex = filename.lastIndexOf('.');
    if (dotIndex === -1) {
      return { name: filename, extension: '' };
    }
    return { name: filename.substring(0, dotIndex), extension: filename.substring(dotIndex) };
  };

  const handleFileNameChange = (index, newName) => {
    setFileNames(prevNames => ({
      ...prevNames,
      [index]: newName,
    }));

    const updatedFiles = selectedFiles.map((file, fileIndex) => {
      if (fileIndex === index) {
        const { extension } = getFileNameAndExtension(file.name);
        return new File([file], `${newName}${extension}`, { type: file.type });
      }
      return file;
    });

    setSelectedFiles(updatedFiles);
  };

  const handleEkDosyaAciklamaChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEkDosyaAciklama(event.target.value);
  };

  const fileUpload = async dosya => {
    const fileInfoArray = await GetBase64PromiseString(selectedFiles);
    const entity = {
      ...dosya,
      fileList: fileInfoArray.map((file, index) => {
        const { extension } = getFileNameAndExtension(file.fileName);
        const updatedName = fileNames[index] || getFileNameAndExtension(file.fileName).name;
        return {
          ...file,
          fileName: `${updatedName}${extension}`,
          aciklama: ekDosyaAciklama,
        };
      }),
    };
    await dispatch(uploadEkDosyaEntity(entity));
    getTaskList(krizHavalimani);
  };

  const handleFileChange = e => {
    const files = e.target.files;
    setSelectedFiles([...selectedFiles, ...files]);
  };

  const handleRemoveFile = index => {
    const newFiles = [...selectedFiles];
    newFiles.splice(index, 1);
    setSelectedFiles(newFiles);
  };

  const saveEntity = async values => {
    values.preventDefault();

    const isSave = await saveEntityConfirm();
    setEkDosyaLoading(true);

    if (isSave) {
      const ekDosyaSabit = {
        ilgiliTabloKayitId: selectedTask?.realId,
        ilgiliTablo: TableNameEnum.KrizKatilimciGorev,
        onaylandiMi: ReferansEnum.EvetHayir.Hayir,
        ekDosyaTipi: refEkDosyaTipis.find(res => res.code === RefEkDosyaTipiCodeEnum.KrizGorevDefaultDokuman),
      };

      await fileUpload(ekDosyaSabit);
    }
    getEkDosya(selectedTask?.realId);
    handleCloseEkDosyaModal();
    setEkDosyaLoading(false);
  };

  const handlePageChange = (pageNumber, newItemsPerPage = pageObject.size) => {
    if (newItemsPerPage !== pageObject.size) {
      setPageObject({ ...pageObject, page: pageNumber - 1, size: newItemsPerPage });
    } else {
      setPageObject({ ...pageObject, page: pageNumber - 1 });
    }
  };

  const uploadSelectedGorevEkDosya = () => {
    return (
      <Modal className="modal-style modal-gorevler" isOpen={isGorevEkDosyaModal} toggle={handleCloseEkDosyaModal} centered>
        <ModalHeader toggle={handleCloseEkDosyaModal}>
          <Translate contentKey="tk24AdysApp.krizKatilimciGorev.dosyaEkle" />
        </ModalHeader>
        {selectedTask && (
          <div id="gorevDurumuName" className="gorevDurumuName">
            <p className="m-0">{selectedTask.adi}</p>
          </div>
        )}

        <Form onSubmit={saveEntity}>
          <ModalBody className="modal-body">
            <FormItem
              label={translate('tk24AdysApp.ekDosya.ekDosyaAciklama')}
              id="ek-dosya-aciklama"
              name="aciklama"
              defaultValue={ekDosyaAciklama}
              onChange={handleEkDosyaAciklamaChange}
              type="textarea"
              validation={{
                maxLength: { value: 1000, message: translate('entity.validation.maxlength', { max: 1000 }) },
              }}
            />

            <div className="modal-file-area">
              <div className="modal-dashed-file-area" onDrop={handleDrop} onDragOver={e => e.preventDefault()}>
                <img width={16} height={20} style={{ marginBottom: '20px' }} src={`${SERVER_URL}/content/images/icon/downloadIcon.svg`} />
                <span className="modal-file-text">
                  <span className="modal-upload-file-text" onClick={() => fileInputRef.current.click()}>
                    <Translate contentKey="tk24AdysApp.ekDosya.yukle" />
                  </span>
                  <span style={{ color: 'rgba(73, 88, 106, 1)' }}>
                    {' '}
                    <Translate contentKey="tk24AdysApp.ekDosya.surukle" />
                  </span>
                  <input type="file" ref={fileInputRef} style={{ display: 'none' }} onChange={handleFileChange} multiple />
                </span>
              </div>
              <div className="modal-file-list-area">
                {selectedFiles.length > 0 &&
                  selectedFiles.map((file, index) => {
                    const { name, extension } = getFileNameAndExtension(file.name);
                    return (
                      <div key={file.id} className="modal-file-list-row">
                        <div style={{ display: 'flex', justifyContent: 'start', alignItems: 'center', padding: '0px', width: '50px' }}>
                          <img width={16} height={20} src={`${SERVER_URL}/content/images/icon/downloadIcon.svg`} />
                        </div>
                        <div style={{ width: '100%' }}>
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'start',
                              justifyContent: 'center',
                              margin: 'auto',
                            }}
                          >
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              <input
                                type="textarea"
                                value={fileNames[index] || name}
                                onChange={e => handleFileNameChange(index, e.target.value)}
                                className="form-control"
                                style={{ width: '300px' }}
                                maxLength={950}
                              />
                              <span className="ml-2">{extension}</span>
                            </div>
                            <span className="modal-file-size">{`${(file.size / 1024).toFixed(2)}kb`}</span>
                          </div>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'end', alignItems: 'center', padding: '0px', width: '50px' }}>
                          <Button className="delete-file-button" onClick={() => handleRemoveFile(index)}>
                            <img width={12} height={15} src={`${SERVER_URL}/content/images/icon/deleteFileIcon.svg`} />
                          </Button>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </ModalBody>

          <ModalFooter className="modal-footer">
            <Button className="cancel-button" onClick={handleCloseEkDosyaModal} replace>
              <Translate contentKey="entity.action.cancel">Cancel</Translate>
            </Button>
            &nbsp;
            <Button
              className="save-button"
              disabled={selectedFiles.length < 1}
              id="jhi-confirm-delete-refAdtForm"
              type="submit"
              data-cy="entityConfirmButton"
            >
              <Translate contentKey="entity.action.save">Kaydet</Translate>
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    );
  };
  const handleOpenDialog = () => {
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const confirmTasksDelete = task => {
    setSelectedTask(task);
    setIsDeleteModalOpen(true);
  };

  const handleDeleteConfirm = useCallback(async () => {
    if (selectedTask) {
      const entity = {
        ...pageObject,
        krizId: `${match.params.id}`,
        refHavalimaniIstasyonId: krizHavalimani && krizHavalimani.id,
        entity: selectedTask,
      };

      if (krizKatilimciGorevs.length === 1) {
        entity.page = pageObject.page - 1;
        setPageObject({ ...pageObject, page: pageObject.page - 1 });
      }

      await dispatch(deleteEntity(entity));
      getTaskList(krizHavalimani);
      handleDeleteCancel();
    }
  }, [selectedTask, dispatch]);

  const handleDeleteCancel = () => {
    setIsDeleteModalOpen(false);
    setSelectedTask(null);
  };
  const getSize = () => (window.innerWidth < 1600 ? 'small' : 'medium');
  return (
    !isObjectEmpty(kriz) && (
      <KrizMain isDrawer={isDrawer}>
        {(krizIstasyonLoading ||
          krizKatilimciGorevLoading ||
          krizIstasyonUpdating ||
          krizIstasyonGorevLoading ||
          krizGorevEkDosyaListLoading ||
          krizGorevEkDosyaListUpdating ||
          ekDosyaLoading) && <LoadingSpinner />}
        {taskDetailModal()}
        {changeTaskStatusModal()}
        {NoteAddModal()}
        {uploadSelectedGorevEkDosya()}
        {newTaskModal()}
        {selectedTask && (
          <CustomDeleteEntityConfirm
            entity={selectedTask}
            entityName="krizKatilimciGorev"
            isOpen={isDeleteModalOpen}
            onConfirm={handleDeleteConfirm}
            onCancel={handleDeleteCancel}
          />
        )}
        <CustomSaveEntityConfirm entity={selectedTask} isOpen={isSaveModalOpen} onConfirm={handleConfirm} onCancel={handleCancel} />
        <div style={{ marginBottom: 5 }}>
          <InfoTable id={match.params.id} titleContentKey={'tk24AdysApp.krizDetay.istasyonGorev'} />
        </div>

        <Row>
          <Col md={12}>
            <div className="d-flex align-items-center">
              <Box sx={{ minWidth: 640, backgroundColor: '#FFFFFF' }} className="mt-3 mb-3">
                <FormControl fullWidth size={getSize()}>
                  <InputLabel id="default-havalimaniId-label" className="select-input">
                    İstasyon
                  </InputLabel>
                  <Select
                    labelId="havalimaniId-label"
                    id="refHavalimaniIstasyonId"
                    label="İstasyon"
                    MenuProps={MenuProps}
                    onChange={event => krizIstasyonListChange('refHavalimaniIstasyonId', event)}
                    className="select-input"
                  >
                    <MenuItem key={'all'} value={null} onClick={() => setKrizHavalimani(null)}>
                      <Translate contentKey="entity.action.istasyonSec">Istasyon Sec</Translate>
                    </MenuItem>
                    {krizIstasyonList &&
                      krizIstasyonList.length > 0 &&
                      krizIstasyonList.map((istasyon, i) => (
                        <MenuItem key={istasyon.havalimani?.id} value={istasyon.havalimani?.id}>
                          {istasyon.havalimani?.adi}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Box>
            </div>
            <div className="gorevlerGorevInfoBox">
              {krizHavalimani && krizHavalimani !== null ? (
                <div className="gorev-katilimci-info">
                  <div className="gorevlerInfoBoxLeft ml-3">
                    <span className="gorevlerInfoBoxNameSurname">{krizHavalimani.adi}</span>
                  </div>
                </div>
              ) : (
                <div className="gorev-katilimci-info">
                  <div className="gorevlerInfoBoxLeft ml-3">
                    <span className="gorevlerInfoBoxNameSurname">
                      <Translate contentKey="tk24AdysApp.refGorev.home.istasyon" />
                    </span>
                    <br />
                    <span className="gorevlerInfoBoxTitle">
                      <Translate contentKey="tk24AdysApp.krizKatilimciGorev.gorevIlerlemeDurum" />
                    </span>
                  </div>
                </div>
              )}
              <div style={{ display: 'flex', flexDirection: 'row', flexBasis: '60%', justifyContent: 'flex-end', alignItems: 'center' }}>
                <div className="gorev-tamamlanan-info">
                  <span className="gorevlerTamamlananCountText">
                    <Translate contentKey="tk24AdysApp.krizKatilimciGorev.tamamlanmaOrani">Tamamlanma Oranı</Translate>:{' '}
                    {isNaN(percentageDone) || percentageDone === Infinity ? <b>% 0</b> : <b>{`% ${percentageDone}`}</b>}
                  </span>
                  <hr className="gorevlerTamamlananHr" />
                  <span className="gorevlerTamamlananCountText">
                    <Translate contentKey="tk24AdysApp.krizKatilimciGorev.tamamlananGorev"> Tamamlanan Görev Sayısı</Translate>:{' '}
                    <b>
                      {tamamlananGorevCount && totalValue > 0 ? tamamlananGorevCount.toString() : 0}/{`${totalValue}`}
                    </b>
                  </span>
                </div>

                <div className="gorevlerInfoBoxRight">
                  <div className="progressGorevler">
                    <LinearProgress
                      variant="determinate"
                      value={percentageDone}
                      sx={{
                        height: '32px !important',
                        borderRadius: '8px !important',
                        backgroundColor: '#dadee3',
                        '& .MuiLinearProgress-bar': {
                          backgroundColor: '#53c172',
                          borderRadius: '8px !important',
                        },
                        '&.MuiLinearProgress-root': {
                          borderRadius: '8px !important',
                        },
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>

            {krizHavalimani && (
              <>
                <div className="gorevCard">
                  <div className="gorevIstasyonCard">
                    <div className="mb-3">
                      <div className="seachiconposition">
                        <Search className="seachicon" style={{ color: 'grey' }} />
                        <Input
                          value={search}
                          onChange={e => setSearch(e.target.value)}
                          placeholder={translate('entity.action.search')}
                          className="search-input"
                          type="text"
                          name="categorySelect"
                          id="categorySelect"
                        />
                      </div>
                    </div>
                    <div
                      style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', flexWrap: 'wrap', gap: '8px' }}
                      className="mb-3"
                    >
                      <Box sx={{ minWidth: 200, maxWidth: 200 }}>
                        <FormControl fullWidth size={getSize()}>
                          <InputLabel id="default-istasyonGorevGrubu-label" className="select-input">
                            <Translate contentKey="tk24AdysApp.refIstasyonGorevGrubu.home.title" />
                          </InputLabel>
                          <Select
                            labelId="istasyonGorevGrubu-label"
                            id="istasyonGorevGrubu"
                            label={translate('tk24AdysApp.refIstasyonGorevGrubu.home.title')}
                            value={selectedIstasyonGorevGrubu}
                            onChange={event => handleSearchChange('istasyonGorevGrubuId', event)}
                            className="select-input"
                          >
                            <MenuItem value="">
                              <Translate contentKey="entity.action.all">All</Translate>
                            </MenuItem>
                            {refIstasyonGorevGrubuList.map(item => (
                              <MenuItem value={item.id} key={item.id}>
                                {item.adi}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Box>
                      <Box sx={{ minWidth: 200, maxWidth: 200 }}>
                        <FormControl fullWidth size={window.innerWidth < 1600 ? 'small' : 'medium'}>
                          <InputLabel id="default-gorevDurumu-label" className="select-input">
                            <Translate contentKey="tk24AdysApp.krizKatilimciGorev.gorevDurumu" />
                          </InputLabel>
                          <Select
                            labelId="gorevDurumu-label"
                            id="gorevDurumu"
                            label="Görev Durumu"
                            value={selectedGorevDurumu}
                            onChange={event => handleSearchChange('gorevDurumuId', event)}
                            className="select-input"
                          >
                            <MenuItem value="">
                              <Translate contentKey="entity.action.all">All</Translate>
                            </MenuItem>
                            {referans
                              .filter(item => item.tipi === 'GOREV_DURUMU' && item.id !== ReferansEnum.GorevDurumu.IptalEdildi)
                              .map(item => (
                                <MenuItem value={item.id} key={item.id}>
                                  {item.adi}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                      </Box>

                      <Box sx={{ minWidth: 200, maxWidth: 200 }}>
                        <FormControl fullWidth size={window.innerWidth < 1600 ? 'small' : 'medium'}>
                          <InputLabel id="default-gorev-tipi-label" className="select-input">
                            <Translate contentKey="tk24AdysApp.krizKatilimciGorev.gorevTipi" />
                          </InputLabel>
                          <Select
                            labelId="categorySelect-label"
                            id="categorySelect"
                            name="categorySelect"
                            label="Görev Tipi"
                            value={selectedGorevTuru}
                            onChange={event => handleSearchChange('gorevTuruId', event)}
                            className="select-input"
                          >
                            <MenuItem value=""> Tümü</MenuItem>
                            {referans
                              .filter(
                                item =>
                                  item.id === ReferansEnum.GorevTuru.IstasyonEkGorev ||
                                  item.id === ReferansEnum.GorevTuru.IstasyonTanimliGorev
                              )
                              .map(item => (
                                <MenuItem key={item.id} value={item.id}>
                                  {item.adi}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                      </Box>

                      <ExcelDownloadButton
                        excelSampleData={ExcelSampleData}
                        grantedAuthorites={['ref_gorev_export']}
                        fetchData={fetchTotalIstasyonGorevData}
                        className={'download-button'}
                        exportName={translate('tk24AdysApp.refGorev.home.istasyonTitle')}
                      />

                      <GrantedButton
                        id={`create-button`}
                        grantedAuthorites={['kriz_istasyon_gorev_read']}
                        onClick={() => setIsOpenTaskModal(true)}
                        comparison={[
                          {
                            value: kriz.krizDurumu?.id,
                            checkValue: ReferansEnum.KrizDurumu.Aktif,
                            contentKey: 'error.comparison.crisisIsPassive',
                          },
                        ]}
                        className="create-button"
                        size="sm"
                        data-cy="entityCreateButton"
                      >
                        <img width={14} height={14} src={`${SERVER_URL}/content/images/icon/faPlus.svg`} />
                        <span className="d-none d-md-inline">
                          <Translate contentKey="tk24AdysApp.krizKatilimciGorev.yeniGorevEkle"> Yeni Görev Ekle</Translate>
                        </span>
                      </GrantedButton>
                    </div>
                  </div>

                  {krizKatilimciGorevs && krizKatilimciGorevs.length > 0
                    ? krizKatilimciGorevs.map((tasks, index) => (
                        <Row key={tasks.id}>
                          <Col md={12}>
                            <div
                              className="gorev-card"
                              style={{ cursor: 'pointer', display: 'flex', flexDirection: 'row' }}
                              onClick={() => {
                                setSelectedTask(tasks);
                                getEkDosya(tasks.realId);
                                setIsTaskDetailModal(true);
                              }}
                            >
                              <div
                                style={{
                                  width: 70,
                                  textAlign: 'center',
                                  alignSelf: 'flex-end',
                                }}
                              >
                                <GrantedCheckbox
                                  id={`update-button-${tasks.id}`}
                                  grantedAuthorites={['kriz_katilimci_gorev_edit']}
                                  sx={{
                                    width: '48px',
                                    height: '48px',
                                    zIndex: 500,
                                    '& .MuiIconButton-root': {
                                      color: tasks.gorevDurumu?.id === ReferansEnum.GorevDurumu.Tamamlandi ? '#39ab59' : 'transparent',
                                    },
                                    '& .MuiSvgIcon-root': {
                                      width: '48px',
                                      height: '48px',
                                      color: tasks.gorevDurumu?.id === ReferansEnum.GorevDurumu.Tamamlandi ? '#39ab59' : '#607083',
                                    },
                                    '&.Mui-disabled': {
                                      opacity: 0.5,
                                    },
                                  }}
                                  checked={tasks.gorevDurumu?.id === ReferansEnum.GorevDurumu.Tamamlandi}
                                  onClick={e => e.stopPropagation()}
                                  onChange={() => {
                                    if (tasks.gorevDurumu?.id === ReferansEnum.GorevDurumu.Tamamlandi) {
                                      changeTaskStatus(tasks, ReferansEnum.GorevDurumu.DevamEdiyor);
                                    } else {
                                      changeTaskStatus(tasks, ReferansEnum.GorevDurumu.Tamamlandi);
                                    }
                                  }}
                                  comparison={[
                                    {
                                      value: kriz.krizDurumu?.id,
                                      checkValue: ReferansEnum.KrizDurumu.Aktif,
                                      contentKey: 'error.comparison.crisisIsPassive',
                                    },
                                  ]}
                                />

                                <hr />

                                <div style={{ height: 42 }}>
                                  <span style={{ textAlign: 'center', color: '#49586A' }}>
                                    {' '}
                                    {index + 1 + pageObject.page * pageObject.size} / {totalItem}{' '}
                                  </span>
                                </div>
                              </div>
                              <div
                                style={{
                                  marginLeft: 10,
                                  borderLeft: '1px solid #d5dae0',
                                  paddingLeft: 10,
                                  width: 'calc(100% - 70px)',
                                }}
                              >
                                <div style={{ justifyContent: 'space-between', display: 'flex', alignItems: 'center' }}>
                                  <span
                                    className="gorev-durumu-badge"
                                    style={{
                                      color: getTextColor(tasks.gorevDurumu),
                                      backgroundColor: getBadgeBackgroundColor(tasks.gorevDurumu),
                                    }}
                                  >
                                    {tasks.gorevDurumu ? tasks.gorevDurumu.adi : null}
                                  </span>

                                  {tasks?.ekDosyaSayisi > 0 && (
                                    <img width={40} height={40} src={`${SERVER_URL}/content/images/icon/uploadFile.svg`} />
                                  )}
                                </div>

                                <div style={{ height: 48 }}>
                                  <p className="gorev-text">{tasks?.adi}</p>
                                </div>
                                <hr />
                                <div className="gorev-badge-row">
                                  <div style={{ width: '50%', display: 'flex', flexDirection: 'row' }}>
                                    <div className="gorev-tipi-badge">
                                      <Translate contentKey="tk24AdysApp.krizKatilimciGorev.gorevTipi">Görev Tipi</Translate> :{' '}
                                      <span style={{ color: '#788088' }}>{tasks.gorevTuru ? tasks.gorevTuru.adi : null}</span>
                                    </div>
                                    <div className="gorev-tipi-badge">
                                      <Translate contentKey="tk24AdysApp.krizKatilimciGorev.gorevID">Görev ID</Translate> :{' '}
                                      <span style={{ color: '#788088' }}>{tasks.realId ? tasks.realId : null}</span>
                                    </div>
                                    {tasks.istasyonGorevGrubu && (
                                      <div className="gorev-tipi-badge  ml-4">
                                        <Translate contentKey="tk24AdysApp.refIstasyonGorevGrubu.home.title">
                                          İstasyon Görev Grubu
                                        </Translate>{' '}
                                        : <span style={{ color: '#788088' }}>{tasks.istasyonGorevGrubu?.adi}</span>
                                      </div>
                                    )}
                                  </div>

                                  <div
                                    style={{
                                      width: '50%',
                                      display: 'flex',
                                      flexDirection: 'row',
                                      justifyContent: 'flex-end',
                                    }}
                                  >
                                    {tasks?.gorevTuru?.id === ReferansEnum.GorevTuru.IstasyonEkGorev && (
                                      <GrantedButton
                                        id={`update-button-${tasks.id}`}
                                        style={{ cursor: 'pointer', position: 'relative', marginRight: 12 }}
                                        grantedAuthorites={['kriz_katilimci_gorev_edit']}
                                        className={'edit-button'}
                                        onClick={e => {
                                          e.stopPropagation();
                                          setSelectedMoreTask(tasks);
                                          setOpenOwner(!openOwner);
                                        }}
                                      >
                                        <img width={14} height={14} src={`${SERVER_URL}/content/images/icon/faPencil.svg`} />
                                        <Translate contentKey="tk24AdysApp.krizKatilimciGorev.duzenle">Düzenle</Translate>

                                        {openOwner &&
                                          selectedMoreTask &&
                                          selectedMoreTask.id === tasks.id &&
                                          selectedMoreTask.gorevDurumu.id !== ReferansEnum.GorevDurumu.IptalEdildi && (
                                            <div className={'moreActionBtnContainer'}>
                                              {selectedMoreTask.gorevTuru?.id === ReferansEnum.GorevTuru.IstasyonEkGorev && (
                                                <>
                                                  <div
                                                    style={{ cursor: 'pointer' }}
                                                    className={'edit-button'}
                                                    onClick={e => {
                                                      e.stopPropagation();
                                                      setSelectedTask(selectedMoreTask);
                                                      setIsOpenTaskModal(true);
                                                    }}
                                                  >
                                                    <Cached />
                                                    <Translate contentKey="global.messages.update">Update</Translate>
                                                  </div>
                                                </>
                                              )}
                                            </div>
                                          )}
                                      </GrantedButton>
                                    )}
                                    <GrantedButton
                                      id={`update-button-${tasks.id}`}
                                      grantedAuthorites={['kriz_katilimci_gorev_edit']}
                                      style={{ cursor: 'pointer', marginRight: 12 }}
                                      onClick={e => {
                                        e.stopPropagation();
                                        setSelectedTaskNote(tasks);
                                        handleOpenDialog();
                                      }}
                                      comparison={[
                                        {
                                          value: kriz.krizDurumu?.id,
                                          checkValue: ReferansEnum.KrizDurumu.Aktif,
                                          contentKey: 'error.comparison.crisisIsPassive',
                                        },
                                      ]}
                                      className={'edit-button'}
                                      size="sm"
                                      data-cy="entityCreateButton"
                                    >
                                      <img width={11.2} height={11.2} src={`${SERVER_URL}/content/images/icon/faPlusGray.svg`} />
                                      <span className="d-none d-md-inline">
                                        <Translate contentKey="tk24AdysApp.krizKatilimciGorev.notGir">Not Gir</Translate>
                                      </span>
                                    </GrantedButton>

                                    <GrantedButton
                                      id={`update-button-${tasks.id}`}
                                      grantedAuthorites={['ref_ek_dosya_tipi_edit']}
                                      style={{ cursor: 'pointer', marginRight: 12 }}
                                      onClick={e => {
                                        e.stopPropagation();
                                        setSelectedTask(tasks);
                                        handleOpenEkDosyaModal();
                                      }}
                                      comparison={[
                                        {
                                          value: kriz.krizDurumu?.id,
                                          checkValue: ReferansEnum.KrizDurumu.Aktif,
                                          contentKey: 'error.comparison.crisisIsPassive',
                                        },
                                      ]}
                                      className={'edit-button'}
                                      size="sm"
                                      data-cy="entityCreateButton"
                                    >
                                      <img width={13} height={11} src={`${SERVER_URL}/content/images/icon/addFile.svg`} />
                                      <span className="d-none d-md-inline">
                                        <Translate contentKey="tk24AdysApp.krizKatilimciGorev.dosyaEkle">Dosya Ekle</Translate>
                                      </span>
                                    </GrantedButton>

                                    {tasks.gorevTuru?.id === ReferansEnum.GorevTuru.IstasyonEkGorev && (
                                      <GrantedButton
                                        id={`delete-button-${tasks.id}`}
                                        grantedAuthorites={['kriz_katilimci_gorev_delete']}
                                        className="delete-button"
                                        size="sm"
                                        data-cy="entityDeleteButton"
                                        onClick={e => {
                                          e.stopPropagation();
                                          confirmTasksDelete(tasks);
                                        }}
                                        comparison={[
                                          {
                                            value: kriz.krizDurumu?.id,
                                            checkValue: ReferansEnum.KrizDurumu.Aktif,
                                            contentKey: 'error.comparison.crisisIsPassive',
                                          },
                                        ]}
                                      >
                                        <img width={11.2} height={12.8} src={`${SERVER_URL}/content/images/icon/faTrashCan.svg`} />
                                        <span>
                                          <Translate contentKey="entity.action.delete">Delete</Translate>
                                        </span>
                                      </GrantedButton>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      ))
                    : !krizKatilimciGorevLoading && (
                        <div className="alert alert-warning">
                          <Translate contentKey="tk24AdysApp.krizKatilimciGorev.home.notFound">No Gorev found</Translate>
                        </div>
                      )}
                  <CustomPagination
                    currentPage={pageObject.page + 1}
                    currentPageSize={pageObject.size}
                    totalItems={totalItem}
                    handlePageChange={handlePageChange}
                  />
                </div>
              </>
            )}
          </Col>
        </Row>
      </KrizMain>
    )
  );
};

export default KrizIstasyonGorev;
