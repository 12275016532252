import { IKriz } from 'app/shared/model/kriz.model';
import { IKrizKatilimci } from 'app/shared/model/kriz-katilimci.model';
import { IKrizKatilimciGorev } from 'app/shared/model/kriz-katilimci-gorev.model';
import { IReferans } from 'app/shared/model/referans.model';
import { IQueryParams } from '../reducers/reducer.utils';
import { IKrizCerideEkleyen } from './ref-ceride-ekleyen.model';
import { IKrizAdtEkip } from './kriz-adt-ekip.model';
import { IKrizIstasyon } from './kriz-istasyon.model';

export interface IKrizCeride {
  id?: number;
  guid?: string;
  islemKodu?: IReferans;
  icerik?: string;
  baslik?: string;
  cDate?: string;
  cUser?: number;
  mDate?: string;
  mUser?: number;
  kriz?: IKriz;
  krizKatilimci?: IKrizKatilimci | null;
  katilimciGorev?: IKrizKatilimciGorev | null;
  islemZamani?: string;
  durum?: IReferans;
  cerideEkleyen?: IKrizCerideEkleyen;
  cerideEkleyenDiger?: string;
  cerideEkleyenKrizAdtEkip?: IKrizAdtEkip;
  rapordaYerAlacakMi?: IReferans;
  cerideEkleyenKrizIstasyon?: IKrizIstasyon;
}

export interface IKrizCerideParams extends IQueryParams {
  entity?: IKrizCeride;
}

export const defaultValue: Readonly<IKrizCeride> = {};
