import React, { useState } from 'react';
import { Button, UncontrolledTooltip, Form, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { TextFormat, Translate, translate } from 'app/component/jhipster';
import { FormItem } from 'app/component/FormItem';
import { IRefDDMSDokuman } from 'app/shared/model/ref-ddms-dokuman.model';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { CustomSaveEntityConfirm, getHeaderContentKey } from 'app/shared/util/confirm-utils';
import { createEntity as createDdmsDokumanEntity, updateEntity as updateDdmsDokumanEntity } from './ref-ddms-dokuman.reducer';
import { IQueryParams } from 'app/shared/reducers/reducer.utils';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { APP_DATE_FORMAT, SERVER_URL } from 'app/config/constants';
import axios from 'axios';

interface RefDdmsDokumanUpdateProp {
  handleCloseForm: () => void;
  formValues?: IRefDDMSDokuman;
  isOpen: boolean;
  pageObject?: IQueryParams;
  isViewing: boolean;
}

export const RefDdmsDokumanUpdate: React.FC<RefDdmsDokumanUpdateProp> = ({
  handleCloseForm,
  formValues,
  isOpen,
  pageObject,
  isViewing,
}) => {
  const dispatch = useAppDispatch();
  const [customIsOpen, setCustomIsOpen] = useState(isOpen);
  const [modalPromiseResolve, setModalPromiseResolve] = useState(null);
  const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);
  const kategoriList = useAppSelector(state => state.refDdmsDokumanKategori.totalEntities);
  const ddmsDokumanDosyaList = useAppSelector(state => state.ekDosya.entities);

  const showSaveConfirmModal = () => {
    setIsSaveModalOpen(true);
    return new Promise(resolve => {
      setModalPromiseResolve(() => resolve);
    });
  };

  const handleConfirm = () => {
    if (modalPromiseResolve) {
      modalPromiseResolve(true);
    }
    setIsSaveModalOpen(false);
  };

  const handleCancel = () => {
    if (modalPromiseResolve) {
      modalPromiseResolve(false);
    }
    setIsSaveModalOpen(false);
  };

  const handleDownloadSavedFile = async file => {
    try {
      const response = await axios.get(`api/ek-dosyas/download/${file.id}`, {
        responseType: 'blob',
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${file.dosyaAdi}`);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error('Dosya indirme hatası:', error);
    }
  };

  const saveEntity = async e => {
    e.preventDefault();
    setCustomIsOpen(false);

    const isSave = await showSaveConfirmModal();

    if (isSave) {
      const formDataObject = {};
      for (const field of e.target.elements) {
        if (field.name) {
          formDataObject[field.name] = field.value;
        }
      }

      const refDdmsDokumanEntity = {
        ...formValues,
        ...formDataObject,
        refDdmsDokumanKategori: kategoriList.find(it => it.id.toString() === formDataObject['refDdmsDokumanKategoriId'].toString()),
      };

      if (!formValues) {
        await dispatch(createDdmsDokumanEntity({ ...pageObject, entity: refDdmsDokumanEntity }));
      } else {
        await dispatch(updateDdmsDokumanEntity({ ...pageObject, entity: refDdmsDokumanEntity }));
      }

      // update();
      handleCloseForm();
    } else {
      setCustomIsOpen(true);
    }
  };

  return (
    <>
      <CustomSaveEntityConfirm entity={formValues} isOpen={isSaveModalOpen} onConfirm={handleConfirm} onCancel={handleCancel} />

      <Modal className="modal-style-lg" isOpen={customIsOpen} toggle={handleCloseForm} centered>
        <ModalHeader data-cy="RefDdmsDokumanCreateUpdateHeading" toggle={handleCloseForm}>
          {formValues && (
            <p className="modal-title-info">
              {formValues?.adiTr} - {formValues?.adiEn}
            </p>
          )}
          <Translate contentKey={getHeaderContentKey(isViewing, formValues, 'refDdmsDokuman')} />
        </ModalHeader>

        <Form onSubmit={saveEntity}>
          <ModalBody style={{ maxHeight: '600px', overflowY: 'auto' }}>
            <FormItem
              label={translate('tk24AdysApp.refDdmsDokuman.kategori')}
              id="ref-ddms-dokuman-kategori-id"
              name="refDdmsDokumanKategoriId"
              defaultValue={formValues && formValues.refDdmsDokumanKategori?.id}
              disabled={isViewing}
              type="select"
              validation={{
                required: { value: true, message: translate('entity.validation.required') },
              }}
            >
              <option value="" key="0" />
              {kategoriList.length > 0 &&
                kategoriList.map(otherEntity => (
                  <option value={otherEntity.id} key={otherEntity.id}>
                    {otherEntity.adi}
                  </option>
                ))}
            </FormItem>

            <FormItem
              label={translate('tk24AdysApp.refDdmsDokuman.dokumanNo')}
              id="ref-ddms-dokuman-dokumanNo"
              name="dokumanNo"
              defaultValue={formValues && formValues['dokumanNo']}
              disabled={isViewing}
              type="text"
              validation={{
                required: { value: true, message: translate('entity.validation.required') },
                maxLength: { value: 100, message: translate('entity.validation.maxlength', { max: 100 }) },
              }}
            />
            {isViewing && (
              <>
                <FormItem
                  label={translate('tk24AdysApp.refDdmsDokuman.revizeNo')}
                  id="ref-ddms-dokuman-revizeNo"
                  name="revizeNo"
                  defaultValue={formValues && formValues['revizeNo']}
                  disabled={isViewing}
                  type="text"
                />
                <UncontrolledTooltip target="revizeNoLabel">
                  <Translate contentKey="tk24AdysApp.refDdmsDokuman.revizeNo" />
                </UncontrolledTooltip>
              </>
            )}
            <FormItem
              label={translate('global.languageGlobal.adiTr')}
              id="ref-ddms-dokuman-adiTr"
              name="adiTr"
              defaultValue={formValues && formValues['adiTr']}
              disabled={isViewing}
              type="text"
              validation={{
                required: { value: true, message: translate('entity.validation.required') },
                maxLength: { value: 100, message: translate('entity.validation.maxlength', { max: 100 }) },
              }}
            />
            <UncontrolledTooltip target="adiTrLabel">
              <Translate contentKey="global.languageGlobal.adiTr" />
            </UncontrolledTooltip>

            <FormItem
              label={translate('global.languageGlobal.adiEn')}
              id="ref-ddms-dokuman-adiEn"
              name="adiEn"
              defaultValue={formValues && formValues['adiEn']}
              disabled={isViewing}
              type="text"
              validation={{
                maxLength: { value: 100, message: translate('entity.validation.maxlength', { max: 100 }) },
              }}
            />
            <UncontrolledTooltip target="adiEnLabel">
              <Translate contentKey="global.languageGlobal.adiEn" />
            </UncontrolledTooltip>
            {isViewing && (
              <>
                <TableContainer className="content-table">
                  {ddmsDokumanDosyaList && ddmsDokumanDosyaList.length > 0 ? (
                    <Table aria-label="a dense table">
                      <TableHead className="content-table-title-area">
                        <TableRow className="table-head-row">
                          <TableCell className="table-title-cell hand" align="left">
                            <Translate contentKey="tk24AdysApp.ekDosya.dosyaAdi">dosyaAdi</Translate>
                          </TableCell>
                          <TableCell className="table-title-cell hand" align="left">
                            <Translate contentKey="tk24AdysApp.ekDosya.aciklamaTitle">aciklama</Translate>
                          </TableCell>
                          <TableCell className="table-title-cell hand" align="left">
                            <Translate contentKey="tk24AdysApp.ekDosya.fileLanguageTitle">dil</Translate>
                          </TableCell>
                          <TableCell className="table-title-cell hand" align="left">
                            <Translate contentKey="tk24AdysApp.ekDosya.fileExtentionTitle">fileExtention</Translate>
                          </TableCell>
                          <TableCell className="table-title-cell hand" align="left">
                            <Translate contentKey="tk24AdysApp.ekDosya.ekleyenKullanici">ekleyenKullanici</Translate>
                          </TableCell>
                          <TableCell className="table-title-cell hand" align="left">
                            <Translate contentKey="tk24AdysApp.ekDosya.cDateTitle">CDate</Translate>
                          </TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {ddmsDokumanDosyaList.map(ekDosya => (
                          <TableRow key={ekDosya.id} className="table-cell-row">
                            <TableCell className="table-content-cell" component="th" scope="row">
                              {ekDosya?.dosyaAdi}
                            </TableCell>
                            <TableCell className="table-content-cell" component="th" scope="row">
                              {ekDosya?.aciklama}
                            </TableCell>
                            <TableCell className="table-content-cell" component="th" scope="row">
                              {ekDosya?.dil?.adi}
                            </TableCell>
                            <TableCell className="table-content-cell" component="th" scope="row">
                              {ekDosya?.fileExtention}
                            </TableCell>
                            <TableCell className="table-content-cell" component="th" scope="row">
                              {ekDosya?.ekleyenKullanici?.adi} {ekDosya?.ekleyenKullanici?.soyadi}
                            </TableCell>
                            <TableCell className="table-content-cell" component="th" scope="row">
                              <TextFormat type="date" value={ekDosya.cDate} format={APP_DATE_FORMAT} />
                            </TableCell>
                            <TableCell className="table-content-cell" align="right">
                              <div className="btn-group flex-btn-group-container">
                                <Button
                                  className="download-file-button"
                                  style={{ marginLeft: 5 }}
                                  onClick={() => handleDownloadSavedFile(ekDosya)}
                                >
                                  <img src={`${SERVER_URL}/content/images/icon/faArrowDownToLine.svg`} />
                                  <Translate contentKey="entity.action.download">İndir</Translate>
                                </Button>
                              </div>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  ) : (
                    <div className="alert alert-warning m-2">
                      <Translate contentKey="tk24AdysApp.ekDosya.home.notFound">No Ek Dosya found</Translate>
                    </div>
                  )}
                </TableContainer>
              </>
            )}
          </ModalBody>
          {!isViewing && (
            <ModalFooter className="modal-footer">
              <Button className="cancel-button" onClick={handleCloseForm}>
                <Translate contentKey="entity.action.cancel">Cancel</Translate>
              </Button>
              <Button className="save-button" id="jhi-confirm-delete-refAdtForm" type="submit" data-cy="entityConfirmButton">
                <Translate contentKey="entity.action.save">Kaydet</Translate>
              </Button>
            </ModalFooter>
          )}
        </Form>
      </Modal>
    </>
  );
};

export default RefDdmsDokumanUpdate;
