export const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 900,
  maxHeight: 830,
  height: 830,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  borderRadius: 3,
  boxShadow: 24,
  p: 2,
};

export const modalSortStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 700,
  height: 500,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  borderRadius: 3,
  boxShadow: 24,
  p: 2,
};

export const circleStyle = {
  width: '32px',
  height: '32px',
  borderRadius: '50%',
  backgroundColor: '#FCE6E7',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: '#72030A',
  marginLeft: 15,
};
