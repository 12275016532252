import { useAppDispatch, useAppSelector } from 'app/config/store';
import { IRefKatilimci } from 'app/shared/model/ref-katilimci.model';
import React, { useCallback, useEffect, useState } from 'react';
import { deleteEntity, getEntities, updateEntity } from './ref-katilimci-iletisim.reducer';
import { LoadingSpinner } from 'app/component/LoadingSpinner';
import { Box, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, Typography } from '@mui/material';
import { HasAuthComponent } from 'app/component/HasAuthComponent';
import { Translate, translate } from 'app/component/jhipster';
import GrantedButton from 'app/component/GrantedButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReferansEnum } from 'app/constants/ReferansEnum';
import { SERVER_URL } from 'app/config/constants';
import { CustomDeleteEntityConfirm } from 'app/shared/util/confirm-utils';
import { IQueryParams } from 'app/shared/reducers/reducer.utils';
import RefKatilimciIletisimUpdate from './ref-katilimci-iletisim-update';
import { IRefKatilimciVekil } from 'app/shared/model/ref-katilimci-vekil.model';
import ExcelDownloadButton from 'app/component/ExcelDownload';
import SortIcon from 'app/component/SortIcon';
import { ASC, DESC } from 'app/shared/util/pagination.constants';
import { localeLanguage, upperOnlyFirstLetter } from 'app/shared/util/util';

interface RefKatilimciIletisimProp {
  refKatilimci: IRefKatilimci;
  refKatilimciVekil?: IRefKatilimciVekil;
}

const RefKatilimciIletisim: React.FC<RefKatilimciIletisimProp> = ({ refKatilimci, refKatilimciVekil }) => {
  const dispatch = useAppDispatch();
  const referans = useAppSelector(state => state.referans.entities);
  const refKatilimciIletisimList = useAppSelector(state => state.refKatilimciIletisim.entities);

  const totalItems = useAppSelector(state => state.refKatilimciIletisim.totalItems);
  const refKatilimciIletisimLoading = useAppSelector(state => state.refKatilimciIletisim.loading);
  const refKatilimciIletisimUpdating = useAppSelector(state => state.refKatilimciIletisim.updating);

  const [selectedIletisim, setSelectedIletisim] = useState(null);
  const [isIletisimModal, setIsIletisimModal] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const [pageObject, setPageObject] = useState<IQueryParams>(useAppSelector(state => state.paginate.pageObject));

  const ExcelSampleData = [
    { key: 'iletisimTipi.adi', titleContentKey: 'tk24AdysApp.refKatilimciIletisim.iletisimTipi' },
    { key: 'iletisimBilgisi', titleContentKey: 'tk24AdysApp.refKatilimciIletisim.iletisimBilgisi' },
    { key: 'entMiGeldi.adi', titleContentKey: 'tk24AdysApp.refKatilimciIletisim.entMiGeldi' },
    { key: 'varsayilanMi.adi', titleContentKey: 'tk24AdysApp.refKatilimciIletisim.varsayilanmi' },
  ];

  const getAllEntities = () => {
    if (refKatilimci !== null || refKatilimciVekil !== null) {
      dispatch(
        getEntities({
          ...pageObject,
          katilimciId: refKatilimci?.id.toString(),
          katilimciVekilId: refKatilimciVekil?.id.toString(),
          size: 1000,
        })
      );
    }
  };

  const sort = p => () => {
    const orderBy = pageObject.sort.split(',')[1];
    setPageObject({ ...pageObject, sort: `${p},${orderBy === ASC ? DESC : ASC}`, size: 1000 });
  };

  useEffect(() => {
    getAllEntities();
  }, [refKatilimci, refKatilimciVekil, pageObject]);

  const varsayilanButtonClick = form => {
    const params = {
      ...pageObject,
      entity: {
        ...form,
        varsayilanMi: referans.find(item => item.id.toString() === ReferansEnum.EvetHayir.Evet.toString()),
      },
    };
    dispatch(updateEntity(params));
  };

  const confirmRefKatilimciIletisimDelete = refKatilimciIletisim => {
    setSelectedIletisim(refKatilimciIletisim);
    setIsDeleteModalOpen(true);
  };

  const handleDeleteConfirm = useCallback(async () => {
    if (selectedIletisim) {
      const entity = { ...pageObject, entity: selectedIletisim };
      await dispatch(deleteEntity(entity));
      getAllEntities();
      handleDeleteCancel();
    }
  }, [selectedIletisim, dispatch]);

  const handleDeleteCancel = () => {
    setSelectedIletisim(null);
    setIsDeleteModalOpen(false);
  };

  const handlCloseIletisimModal = () => {
    setIsIletisimModal(false);
    setSelectedIletisim(null);
  };

  const fetchTotalKatilimciIletisimData = (): readonly any[] => {
    try {
      return refKatilimciIletisimList || [];
    } catch (error) {
      console.error('Error fetching data:', error);
      return [];
    }
  };

  const fetchTotalKatilimciVekilIletisimData = (): readonly any[] => {
    try {
      return refKatilimciIletisimList || [];
    } catch (error) {
      console.error('Error fetching data:', error);
      return [];
    }
  };

  const IletisimModal = () => {
    return (
      <RefKatilimciIletisimUpdate
        handleIletisimCloseModal={handlCloseIletisimModal}
        formValues={selectedIletisim}
        katilimci={refKatilimci}
        vekil={refKatilimciVekil}
        pageObject={pageObject}
        isOpen={isIletisimModal}
      />
    );
  };

  return (
    <>
      {(refKatilimciIletisimLoading || refKatilimciIletisimUpdating) && <LoadingSpinner />}
      {isIletisimModal && IletisimModal()}
      {selectedIletisim && (
        <CustomDeleteEntityConfirm
          entity={selectedIletisim}
          entityName="refKatilimciIletisim"
          isOpen={isDeleteModalOpen}
          onConfirm={handleDeleteConfirm}
          onCancel={handleDeleteCancel}
        />
      )}
      <Box className="accordion-box">
        <HasAuthComponent grantedAuthorites={['ref_katilimci_iletisim_read']}>
          {totalItems > 0 ? (
            <>
              <Typography className="item-total-count" gutterBottom component="div">
                <span className="totalListItemText">
                  <Translate
                    contentKey="global.item-total-count"
                    interpolate={{
                      total: totalItems,
                    }}
                  />
                </span>
                {refKatilimciIletisimList.length > 0 ? (
                  <ExcelDownloadButton
                    excelSampleData={ExcelSampleData}
                    grantedAuthorites={['ref_katilimci_iletisim_export']}
                    fetchData={fetchTotalKatilimciIletisimData}
                    className={'download-button'}
                    exportName={`${refKatilimci?.adi + ' ' + refKatilimci?.soyadi} ${translate(
                      'tk24AdysApp.refKatilimciIletisim.detail.title'
                    )}`}
                  />
                ) : (
                  <ExcelDownloadButton
                    excelSampleData={ExcelSampleData}
                    grantedAuthorites={['ref_katilimci_iletisim_export']}
                    fetchData={fetchTotalKatilimciVekilIletisimData}
                    className={'download-button'}
                    exportName={`${refKatilimciVekil?.adi + ' ' + refKatilimciVekil?.soyadi} ${translate(
                      'tk24AdysApp.refKatilimciIletisim.detail.title'
                    )}`}
                  />
                )}
              </Typography>
              <div className="table-responsive" style={{ marginTop: 5 }}>
                <Table className="accordion-table">
                  <TableHead>
                    <TableRow className="accordion-table-title-row">
                      <TableCell
                        className="accordion-table-title-cell"
                        style={{ width: '18%' }}
                        align="left"
                        onClick={sort('iletisimTipiId')}
                      >
                        <div className="tableHeaderContainer">
                          <Translate contentKey="tk24AdysApp.refKatilimciIletisim.iletisimTipi">Contact Type</Translate>
                          <SortIcon column="iletisimTipiId" activeSortColumn={pageObject.sort} />
                        </div>
                      </TableCell>
                      <TableCell
                        className="accordion-table-title-cell"
                        style={{ width: '18%' }}
                        align="left"
                        onClick={sort('iletisimBilgisi')}
                      >
                        <div className="tableHeaderContainer">
                          <Translate contentKey="tk24AdysApp.refKatilimciIletisim.iletisimBilgisiTitle">Contact information</Translate>
                          <SortIcon column="iletisimBilgisi" activeSortColumn={pageObject.sort} />
                        </div>
                      </TableCell>
                      <TableCell
                        className="accordion-table-title-cell"
                        style={{ width: '18%' }}
                        align="left"
                        onClick={sort(`entMiGeldi.adi${upperOnlyFirstLetter(localeLanguage)}`)}
                      >
                        <div className="tableHeaderContainer">
                          <Translate contentKey="tk24AdysApp.refKatilimciIletisim.entMiGeldiTitle">Default information</Translate>
                          <SortIcon column={`entMiGeldi.adi${upperOnlyFirstLetter(localeLanguage)}`} activeSortColumn={pageObject.sort} />
                        </div>
                      </TableCell>
                      <TableCell
                        className="accordion-table-title-cell"
                        style={{ width: '18%' }}
                        align="left"
                        onClick={sort(`varsayilanMi.adi${upperOnlyFirstLetter(localeLanguage)}`)}
                      >
                        <div className="tableHeaderContainer">
                          <Translate contentKey="tk24AdysApp.refKatilimciIletisim.varsayilanmiTitle">Default</Translate>
                          <SortIcon column={`varsayilanMi.adi${upperOnlyFirstLetter(localeLanguage)}`} activeSortColumn={pageObject.sort} />
                        </div>
                      </TableCell>
                      <TableCell className="sticky-right-header-accordion-alt"></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {refKatilimciIletisimList?.map(refKatilimciIletisim => (
                      <TableRow key={refKatilimciIletisim.id} className="accordion-table-content-row">
                        <TableCell className="accordion-table-content-cell" component="th" scope="row">
                          {refKatilimciIletisim.iletisimTipi?.adi}
                        </TableCell>
                        <TableCell className="accordion-table-content-cell" component="th" scope="row">
                          {refKatilimciIletisim.iletisimBilgisi}
                        </TableCell>
                        <TableCell className="accordion-table-content-cell" component="th" scope="row">
                          {refKatilimciIletisim.entMiGeldi?.adi}
                        </TableCell>
                        <TableCell className="accordion-table-content-cell" component="th" scope="row">
                          {refKatilimciIletisim.varsayilanMi?.adi}
                        </TableCell>
                        <TableCell className="sticky-right" component="th" scope="row" align="right">
                          <div className="btn-group flex-btn-group-container">
                            <GrantedButton
                              grantedAuthorites={['ref_katilimci_iletisim_edit']}
                              className="edit-button"
                              disabled={refKatilimciIletisim.varsayilanMi?.id === ReferansEnum.EvetHayir.Evet}
                              onClick={() => varsayilanButtonClick(refKatilimciIletisim)}
                              size="sm"
                              data-cy="entityEditButton"
                            >
                              <Tooltip
                                title={<Translate contentKey="tk24AdysApp.refKatilimciIletisim.varsayilanyap">Make Default</Translate>}
                              >
                                <FontAwesomeIcon icon="list" />
                              </Tooltip>
                              <span className="show-on-lg">
                                <Translate contentKey="tk24AdysApp.refKatilimciIletisim.varsayilanyap">Make Default</Translate>
                              </span>
                            </GrantedButton>
                            <GrantedButton
                              grantedAuthorites={['ref_katilimci_iletisim_edit']}
                              className="edit-button"
                              style={{ marginLeft: 10 }}
                              onClick={event => {
                                event.stopPropagation();
                                setSelectedIletisim(refKatilimciIletisim);
                                setIsIletisimModal(true);
                              }}
                              size="sm"
                              data-cy="entityEditButton"
                            >
                              <Tooltip title={<Translate contentKey="entity.action.edit">Edit</Translate>}>
                                <img width={14} height={14} src={`${SERVER_URL}/content/images/icon/faPencil.svg`} />
                              </Tooltip>
                              <span className="show-on-lg">
                                <Translate contentKey="entity.action.edit">Edit</Translate>
                              </span>
                            </GrantedButton>
                            <GrantedButton
                              grantedAuthorites={['ref_katilimci_iletisim_delete']}
                              onClick={event => {
                                event.stopPropagation();
                                confirmRefKatilimciIletisimDelete(refKatilimciIletisim);
                              }}
                              style={{ marginLeft: 10 }}
                              className="delete-button"
                              size="sm"
                              data-cy="entityDeleteButton"
                            >
                              <Tooltip title={<Translate contentKey="entity.action.delete">Delete</Translate>}>
                                <img width={11.2} height={12.8} src={`${SERVER_URL}/content/images/icon/faTrashCan.svg`} />
                              </Tooltip>
                              <span className="show-on-lg">
                                <Translate contentKey="entity.action.delete">Delete</Translate>
                              </span>
                            </GrantedButton>
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>
            </>
          ) : (
            !refKatilimciIletisimLoading && (
              <div className="alert alert-warning">
                <Translate contentKey="tk24AdysApp.refKatilimciIletisim.home.notFound">No refKatilimciIletisim found</Translate>
              </div>
            )
          )}
          <div className="flex-start-frame">
            <GrantedButton
              grantedAuthorites={['ref_katilimci_iletisim_edit']}
              onClick={event => {
                event.stopPropagation();
                setIsIletisimModal(true);
              }}
              className="accordion-create-button"
              size="sm"
              data-cy="entityCreateButton"
            >
              <img width={14} height={14} src={`${SERVER_URL}/content/images/icon/faPlusBlue.svg`} />
              <span className="d-none d-md-inline">
                <Translate contentKey="tk24AdysApp.refKatilimciIletisim.home.createLabel" />
              </span>
            </GrantedButton>
          </div>
        </HasAuthComponent>
      </Box>
    </>
  );
};

export default RefKatilimciIletisim;
