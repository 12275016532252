import { FormItem } from 'app/component/FormItem';
import { IRefGorev } from 'app/shared/model/ref-gorev.model';
import React, { useEffect, useState } from 'react';
import { Translate, translate } from 'app/component/jhipster';
import { Button, Col, Form, Modal, ModalBody, ModalFooter, ModalHeader, UncontrolledTooltip } from 'reactstrap';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { CustomSaveEntityConfirm, deleteEntityConfirm, getHeaderContentKey, saveEntityConfirm } from 'app/shared/util/confirm-utils';
import { IRefKatilimci } from 'app/shared/model/ref-katilimci.model';
import { createEntity, updateEntity } from './ref-gorev.reducer';
import { IReferans } from 'app/shared/model/referans.model';
import { IRefAdtEkip } from 'app/shared/model/ref-adt-ekip.model';
import { IRefIstasyonGorevGrubu } from 'app/shared/model/ref-istasyon-gorev-grubu.model';
import { ReferansEnum } from 'app/constants/ReferansEnum';
import GrantedButton from 'app/component/GrantedButton';
import { SERVER_URL } from 'app/config/constants';
import { IQueryParams } from 'app/shared/reducers/reducer.utils';
import {
  createEntity as createSorBirimEntity,
  getEntities as getSorBirimEntity,
  deleteEntity as deleteSorBirimEntity,
  getEntities,
} from '../ref-gorev-sor-birim/ref-gorev-sor-birim.reducer';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { IRefGorevSorBirim } from 'app/shared/model/ref-gorev-sor-birim.model';
import { LoadingSpinner } from 'app/component/LoadingSpinner';
import RefGorevSorBirimUpdate from '../ref-gorev-sor-birim/ref-gorev-sor-birim-update';

interface RefGorevUpdateProp {
  handleCloseForm: () => void;
  loadRefGorevForm: () => void;
  formValues: IRefGorev;
  refKatilimci?: IRefKatilimci;
  refAdtEkip?: IRefAdtEkip;
  refIstasyonGrubu?: IRefIstasyonGorevGrubu;
  gorevSahibiRef: IReferans;
  isOpen: boolean;
  isViewing: boolean;
}
export const RefGorevUpdate: React.FC<RefGorevUpdateProp> = ({
  handleCloseForm,
  loadRefGorevForm,
  formValues,
  refKatilimci,
  refAdtEkip,
  refIstasyonGrubu,
  gorevSahibiRef,
  isOpen,
  isViewing,
}) => {
  const dispatch = useAppDispatch();
  const [localFormValues, setLocalFormValues] = React.useState({
    ...formValues,
    sorumluBirims: formValues?.sorumluBirims || [],
  });
  const [customIsOpen, setCustomIsOpen] = useState(isOpen);
  const [modalPromiseResolve, setModalPromiseResolve] = useState(null);
  const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);
  const [isGorevSorBirimModal, setIsGorevSorBirimModal] = useState(false);
  const gorevSorBirimList = useAppSelector(state => state.refGorevSorBirim.entities);
  const loading = useAppSelector(state => state.refGorevSorBirim.loading);
  const updating = useAppSelector(state => state.refGorevSorBirim.updating);
  const [sorumluBirimPageObejct, setSorumluBirimPageObejct] = useState<IQueryParams>({
    ...useAppSelector(state => state.paginate.pageObject),
    size: 200,
    query: `gorevId.equals=${formValues?.id}`,
  });

  useEffect(() => {
    onLoadSorBirim();
  }, []);

  const onLoadSorBirim = () => {
    dispatch(getEntities(sorumluBirimPageObejct));
  };

  const showSaveConfirmModal = () => {
    setIsSaveModalOpen(true);
    return new Promise(resolve => {
      setModalPromiseResolve(() => resolve);
    });
  };

  const handleConfirm = () => {
    if (modalPromiseResolve) {
      modalPromiseResolve(true);
    }
    setIsSaveModalOpen(false);
  };

  const handleCancel = () => {
    if (modalPromiseResolve) {
      modalPromiseResolve(false);
    }
    setIsSaveModalOpen(false);
  };

  const handleCloseUpdateForm = () => {
    setIsGorevSorBirimModal(false);
  };

  const saveEntity = async e => {
    e.preventDefault();
    setCustomIsOpen(false);
    const isSave = await showSaveConfirmModal();
    if (isSave) {
      const formDataObject = {};
      for (const field of e.target.elements) {
        if (field.name) {
          formDataObject[field.name] = field.value;
        }
      }

      const refGorevEntity = {
        ...formValues,
        ...formDataObject,
        gorevSahibi: gorevSahibiRef,
        katilimci: refKatilimci ? refKatilimci : null,
        adtEkip: refAdtEkip ? refAdtEkip : null,
        istasyonGorevGrubu: refIstasyonGrubu ? refIstasyonGrubu : null,
        sorumluBirims: localFormValues?.sorumluBirims?.map(item => ({
          id: item.org.id,
          realId: item.org.realId,
          guid: item.org.guid,
          mDate: item.org.mDate,
        })),
      };

      if (formValues !== null) {
        await dispatch(updateEntity({ entity: refGorevEntity }));
        loadRefGorevForm();
      } else {
        await dispatch(createEntity({ entity: refGorevEntity }));
        loadRefGorevForm();
      }

      handleCloseForm();
    } else {
      setCustomIsOpen(true);
    }
  };

  function createSorumluBirim(selectedUnite) {
    const alreadyExists = localFormValues?.sorumluBirims?.some(item => item?.org?.orgId === selectedUnite?.orgId);
    if (!alreadyExists) {
      const newUnite = {
        org: { ...selectedUnite },
      };
      setLocalFormValues(prevValues => ({
        ...prevValues,
        sorumluBirims: [...prevValues?.sorumluBirims, newUnite],
      }));
    }
  }

  const updateSorumluBirim = async unite => {
    const isSave = await saveEntityConfirm();
    if (isSave) {
      const params = {
        ...sorumluBirimPageObejct,
        entity: {
          gorev: {
            id: formValues.id,
            realId: formValues.realId,
            guid: formValues.guid,
            mDate: formValues.mDate,
          },
          org: { ...unite },
        },
      };
      await dispatch(createSorBirimEntity(params));
    }
  };

  const GorevSorBirimUpdateModal = () => {
    return (
      <>
        <Modal isOpen={isGorevSorBirimModal} toggle={handleCloseUpdateForm} style={{ minWidth: '1200px' }} centered>
          <ModalHeader data-cy="refAdtFormUpdateDialogHeading" toggle={handleCloseUpdateForm}>
            <Translate contentKey="tk24AdysApp.refGorevSorBirim.home.createLabel" />
          </ModalHeader>
          <ModalBody style={{ maxHeight: '500px' }}>
            <RefGorevSorBirimUpdate
              formValues={formValues}
              createSorumluBirim={createSorumluBirim}
              updateSorumluBirim={updateSorumluBirim}
              localFormValues={localFormValues}
            />
          </ModalBody>
          <ModalFooter className="modal-footer"></ModalFooter>
        </Modal>
      </>
    );
  };

  const removeSorBirim = sorumluUniteId => {
    const updatedSorumluBirims = localFormValues?.sorumluBirims?.filter(item => item?.org?.orgId !== sorumluUniteId);
    setLocalFormValues(prevValues => ({
      ...prevValues,
      sorumluBirims: updatedSorumluBirims,
    }));
  };

  const confirmSorBirimDelete = async (gorev: IRefGorevSorBirim) => {
    const isDel = await deleteEntityConfirm(gorev.id, 'refGorevSorBirim');
    if (isDel) {
      const params = {
        ...sorumluBirimPageObejct,
        entity: { ...gorev },
      };
      await dispatch(deleteSorBirimEntity(params));
    }
  };

  const GorevSorBirimTable = ({ sorumluBirimList, isFormNull }) => {
    return (
      <TableContainer className="content-table" style={{ width: 'auto', maxHeight: '350px', overflowY: 'auto' }}>
        <Table aria-label="a dense table">
          <TableHead className="content-table-title-area">
            <TableRow className="table-head-row">
              <TableCell className="table-title-cell hand" align="left">
                <Translate contentKey="tk24AdysApp.refGorevSorBirim.sorumluUniteAdiTitle">Sorumlu Unite Adi</Translate>
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sorumluBirimList.map(gorevSorBirim => (
              <TableRow key={gorevSorBirim.id} className="table-cell-row">
                <TableCell className="table-content-cell" component="th" scope="row">
                  {gorevSorBirim.org?.adi}
                </TableCell>
                <TableCell className="table-content-cell" align="right">
                  {!isViewing && (
                    <div className="btn-group flex-btn-group-container">
                      <GrantedButton
                        grantedAuthorites={['ref_gorev_sor_birim_delete']}
                        onClick={() => (isFormNull ? removeSorBirim(gorevSorBirim.org?.orgId) : confirmSorBirimDelete(gorevSorBirim))}
                        className="delete-button"
                        size="sm"
                        data-cy="entityDeleteButton"
                      >
                        <img width={11.2} height={12.8} src={`${SERVER_URL}/content/images/icon/faTrashCan.svg`} />
                        <span>
                          <Translate contentKey="entity.action.delete">Delete</Translate>
                        </span>
                      </GrantedButton>
                    </div>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  return (
    <>
      {(loading || updating) && <LoadingSpinner />}
      <CustomSaveEntityConfirm entity={formValues} isOpen={isSaveModalOpen} onConfirm={handleConfirm} onCancel={handleCancel} />
      {isGorevSorBirimModal && GorevSorBirimUpdateModal()}
      <Modal style={{ maxWidth: '1200px' }} isOpen={customIsOpen} toggle={handleCloseForm} centered>
        <ModalHeader data-cy="RefDdmsDokumanCreateUpdateHeading" toggle={handleCloseForm}>
          {formValues && (
            <p className="modal-title-info">
              {formValues?.adiTr} - {formValues?.adiEn}
            </p>
          )}
          <Translate contentKey={getHeaderContentKey(isViewing, formValues, 'refGorev')} />
        </ModalHeader>

        <Form onSubmit={saveEntity}>
          <ModalBody style={{ maxHeight: '500px', overflowY: 'auto' }} className={formValues ? 'refGorevText' : 'refGorevTextCreate'}>
            <FormItem
              label={translate('global.languageGlobal.adiTr')}
              id="ref-gorev-adiTr"
              name="adiTr"
              defaultValue={formValues && formValues['adiTr']}
              disabled={isViewing}
              type="textarea"
              validation={{
                required: { value: true, message: translate('entity.validation.required') },
                maxLength: { value: 4000, message: translate('entity.validation.maxlength', { max: 4000 }) },
              }}
            />
            <UncontrolledTooltip target="adiTrLabel">
              <Translate contentKey="global.languageGlobal.adiTr" />
            </UncontrolledTooltip>

            <FormItem
              label={translate('global.languageGlobal.adiEn')}
              id="ref-gorev-adiEn"
              name="adiEn"
              defaultValue={formValues && formValues['adiEn']}
              disabled={isViewing}
              type="textarea"
              validation={{
                required: { value: true, message: translate('entity.validation.required') },
                maxLength: { value: 4000, message: translate('entity.validation.maxlength', { max: 4000 }) },
              }}
            />
            <UncontrolledTooltip target="adiEnLabel">
              <Translate contentKey="global.languageGlobal.adiEn" />
            </UncontrolledTooltip>

            <FormItem
              label={translate('global.languageGlobal.aciklamaTr')}
              id="ref-gorev-aciklamaTr"
              name="aciklamaTr"
              defaultValue={formValues && formValues['aciklamaTr']}
              disabled={isViewing}
              type="textarea"
              validation={{
                maxLength: { value: 4000, message: translate('entity.validation.maxlength', { max: 4000 }) },
              }}
            />
            <UncontrolledTooltip target="aciklamaTrLabel">
              <Translate contentKey="global.languageGlobal.aciklamaTr" />
            </UncontrolledTooltip>
            <FormItem
              label={translate('global.languageGlobal.aciklamaEn')}
              id="ref-gorev-aciklamaEn"
              name="aciklamaEn"
              defaultValue={formValues && formValues['aciklamaEn']}
              disabled={isViewing}
              type="textarea"
              validation={{
                maxLength: { value: 4000, message: translate('entity.validation.maxlength', { max: 4000 }) },
              }}
            />
            <UncontrolledTooltip target="aciklamaEnLabel">
              <Translate contentKey="global.languageGlobal.aciklamaEn" />
            </UncontrolledTooltip>

            <FormItem
              label={translate('global.languageGlobal.gorevOzetiTr')}
              id="ref-gorev-kisaAdiTr"
              name="kisaAdiTr"
              defaultValue={formValues && formValues['kisaAdiTr']}
              disabled={isViewing}
              type="text"
              validation={{
                maxLength: { value: 4000, message: translate('entity.validation.maxlength', { max: 4000 }) },
              }}
            />

            <FormItem
              label={translate('global.languageGlobal.gorevOzetiEn')}
              id="ref-gorev-kisaAdiEn"
              name="kisaAdiEn"
              defaultValue={formValues && formValues['kisaAdiEn']}
              disabled={isViewing}
              type="text"
              validation={{
                maxLength: { value: 4000, message: translate('entity.validation.maxlength', { max: 4000 }) },
              }}
            />

            {gorevSahibiRef.id === ReferansEnum.GorevSahibi.ADYM && (
              <FormItem
                label={translate('tk24AdysApp.refGorev.sure')}
                id="ref-gorev-sure"
                name="sure"
                defaultValue={formValues && formValues['sure']}
                disabled={isViewing}
                type="number"
                validation={{
                  min: { value: 0, message: translate('entity.validation.min', { min: 0 }) },
                }}
              />
            )}

            {formValues === null ? (
              <>
                {localFormValues.sorumluBirims.length > 0 && (
                  <GorevSorBirimTable sorumluBirimList={localFormValues.sorumluBirims} isFormNull={true} />
                )}
              </>
            ) : (
              <>{gorevSorBirimList?.length > 0 && <GorevSorBirimTable sorumluBirimList={gorevSorBirimList} isFormNull={false} />}</>
            )}
          </ModalBody>
          {!isViewing && (
            <ModalFooter className="modal-footer">
              <Button className="cancel-button" onClick={handleCloseForm}>
                <Translate contentKey="entity.action.cancel">Cancel</Translate>
              </Button>
              <div style={{ display: 'flex' }}>
                &nbsp;
                {gorevSahibiRef.id === ReferansEnum.GorevSahibi.ADYM && (
                  <div>
                    <GrantedButton
                      grantedAuthorites={['ref_gorev_sor_birim_edit']}
                      onClick={() => setIsGorevSorBirimModal(true)}
                      className="ilgili-unite-button"
                      size="sm"
                      data-cy="entityCreateButton"
                    >
                      <img width={20} height={20} style={{ marginBottom: '5px' }} src={`${SERVER_URL}/content/images/icon/additem.svg`} />{' '}
                      <span className="d-none d-md-inline">
                        <Translate contentKey="tk24AdysApp.refGorevSorBirim.ilgiliUniteEkle" />
                      </span>
                    </GrantedButton>
                  </div>
                )}
                &nbsp;
                <Button className="save-button" id="jhi-confirm-delete-refAdtForm" type="submit" data-cy="entityConfirmButton">
                  <Translate contentKey="entity.action.save">Kaydet</Translate>
                </Button>
              </div>
            </ModalFooter>
          )}
        </Form>
      </Modal>
    </>
  );
};

export default RefGorevUpdate;
