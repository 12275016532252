import { IQueryParams } from '../reducers/reducer.utils';
import { IBaseModel } from './base.model';
import { IPersonel } from './personel.model';
import { IRefHavalimani } from './ref-havalimani.model';
import { IRefIstasyonGorevGrubu } from './ref-istasyon-gorev-grubu.model';

export interface IRefIstasyonGorevGrubuPersonel extends IBaseModel {
  realId?: number;
  havalimani?: IRefHavalimani;
  istasyonGorevGrubu?: IRefIstasyonGorevGrubu;
  personel?: IPersonel;
}

export interface IRefIstasyonGorevGrubuPersonelParams extends IQueryParams {
  entity?: IRefIstasyonGorevGrubuPersonel;
}

export const defaultValue: Readonly<IRefIstasyonGorevGrubuPersonel> = {};
