import { translate } from 'app/component/jhipster';
import { useAppSelector } from 'app/config/store';
import { getCurrentDateTimeForHeader, isNullOrEmpty } from 'app/shared/util/confirm-utils';
import React, { useState, useEffect } from 'react';

const DateMenu = () => {
  const [utcTime, setUtcTime] = useState('');
  const [istanbulTime, setIstanbulTime] = useState('');
  const [krizUtcTime, setKrizUtcTime] = useState('');

  const kriz = useAppSelector(state => state.kriz.entity);

  useEffect(() => {
    const timer = setInterval(() => {
      setUtcTime(getCurrentDateTimeForHeader(0)); // UTC+0
      setIstanbulTime(getCurrentDateTimeForHeader(3)); // UTC+3

      if (!isNullOrEmpty(kriz?.krizYeriSaatDilimi)) {
        const krizYeriSaatDilimi = kriz.krizYeriSaatDilimi.replace(/\s/g, '').replace('UTC', '');
        setKrizUtcTime(getCurrentDateTimeForHeader(parseInt(krizYeriSaatDilimi, 10)));
      } else {
        setKrizUtcTime(null);
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [kriz]);

  return (
    <div className="timezone-selector leftDate">
      <h4>{translate('entity.action.tarihSaatBilgisi')}</h4>
      <div className="date-text">
        {utcTime} <span>UTC</span>
      </div>
      <div className="date-text">
        {istanbulTime} <span>İstanbul (+03:00)</span>
      </div>
      {!isNullOrEmpty(krizUtcTime) && !isNullOrEmpty(kriz?.krizYeriSaatDilimi) && (
        <div className="date-text">
          {krizUtcTime} <span> {kriz?.krizYeriSaatDilimi} </span>
        </div>
      )}
    </div>
  );
};

export default DateMenu;
