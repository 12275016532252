import React, { useState, useEffect } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col, FormText, UncontrolledTooltip } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'app/component/jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IReferans } from 'app/shared/model/referans.model';
import { getEntities as getReferans } from 'app/entities/referans/referans.reducer';
import { getEntity, updateEntity, createEntity, reset } from './ref-havalimani.reducer';
import { IRefHavalimani } from 'app/shared/model/ref-havalimani.model';
import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

export const RefHavalimaniUpdate = (props: RouteComponentProps<{ id: string }>) => {
  const dispatch = useAppDispatch();

  const [isNew] = useState(!props.match.params || !props.match.params.id);

  const referans = useAppSelector(state => state.referans.entities);
  const refHavalimaniEntity = useAppSelector(state => state.refHavalimani.entity);
  const loading = useAppSelector(state => state.refHavalimani.loading);
  const updating = useAppSelector(state => state.refHavalimani.updating);
  const updateSuccess = useAppSelector(state => state.refHavalimani.updateSuccess);

  const handleClose = () => {
    props.history.push('/ref-havalimani' + props.location.search);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(props.match.params.id));
    }

    dispatch(getReferans({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    values.bilgiSonGuncellenmeZamani = convertDateTimeToServer(values.bilgiSonGuncellenmeZamani);
    values.cDate = convertDateTimeToServer(values.cDate);
    values.mDate = convertDateTimeToServer(values.mDate);

    const entity = {
      ...refHavalimaniEntity,
      ...values,
      durum: referans.find(it => it.id.toString() ===  values.durumId.toString()),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {
          bilgiSonGuncellenmeZamani: displayDefaultDateTime(),
          cDate: displayDefaultDateTime(),
          mDate: displayDefaultDateTime(),
        }
      : {
          ...refHavalimaniEntity,
          bilgiSonGuncellenmeZamani: convertDateTimeFromServer(refHavalimaniEntity.bilgiSonGuncellenmeZamani),
          cDate: convertDateTimeFromServer(refHavalimaniEntity.cDate),
          mDate: convertDateTimeFromServer(refHavalimaniEntity.mDate),
          durumId: refHavalimaniEntity?.durum?.id,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="tk24AdysApp.refHavalimani.home.createOrEditLabel" data-cy="RefHavalimaniCreateUpdateHeading">
            <Translate contentKey="tk24AdysApp.refHavalimani.home.createOrEditLabel">Create or edit a RefHavalimani</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="ref-havalimani-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('tk24AdysApp.refHavalimani.koduIata')}
                id="ref-havalimani-koduIata"
                name="koduIata"
                data-cy="koduIata"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  maxLength: { value: 3, message: translate('entity.validation.maxlength', { max: 3 }) },
                }}
              />
              <ValidatedField
                label={translate('tk24AdysApp.refHavalimani.koduIcoa')}
                id="ref-havalimani-koduIcoa"
                name="koduIcoa"
                data-cy="koduIcoa"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  maxLength: { value: 3, message: translate('entity.validation.maxlength', { max: 3 }) },
                }}
              />
              <ValidatedField
                label={translate('tk24AdysApp.refHavalimani.adi')}
                id="ref-havalimani-adi"
                name="adi"
                data-cy="adi"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  maxLength: { value: 255, message: translate('entity.validation.maxlength', { max: 255 }) },
                }}
              />
              <UncontrolledTooltip target="adiLabel">
                <Translate contentKey="tk24AdysApp.refHavalimani.help.adi" />
              </UncontrolledTooltip>
              <ValidatedField
                label={translate('tk24AdysApp.refHavalimani.digerBilgiler')}
                id="ref-havalimani-digerBilgiler"
                name="digerBilgiler"
                data-cy="digerBilgiler"
                type="text"
                validate={{
                  maxLength: { value: 255, message: translate('entity.validation.maxlength', { max: 255 }) },
                }}
              />
              <ValidatedField
                label={translate('tk24AdysApp.refHavalimani.bilgiSonGuncellenmeZamani')}
                id="ref-havalimani-bilgiSonGuncellenmeZamani"
                name="bilgiSonGuncellenmeZamani"
                data-cy="bilgiSonGuncellenmeZamani"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <UncontrolledTooltip target="bilgiSonGuncellenmeZamaniLabel">
                <Translate contentKey="tk24AdysApp.refHavalimani.help.bilgiSonGuncellenmeZamani" />
              </UncontrolledTooltip>
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/ref-havalimani" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default RefHavalimaniUpdate;
