import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import 'app/entities/style.scss';
import { RouteComponentProps } from 'react-router-dom';
import { Modal, ModalBody, ModalHeader, Nav, NavItem, NavLink, TabContent, TabPane, Col, Row } from 'reactstrap';
import { getEntities as getReferans } from 'app/entities/referans/referans.reducer';
import { Translate } from 'app/component/jhipster';
import InfoTable from 'app/entities/infoTable';
import axios from 'axios';
import classnames from 'classnames';
import GorevAdtTim from './gorev-adt-tim';
import GorevAdtKatilimci from './gorev-adt-katlimci';
import GorevEkle from './gorev-ekle';
import { KrizMain } from 'app/component/KrizMain';
import { Box, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { MenuProps } from 'app/shared/util/filter.constants';
import { getEntities as getRefEkDosyaTipis } from 'app/entities/ref-ek-dosya-tipi/ref-ek-dosya-tipi.reducer';
import { getEntity as getKrizEntitiy } from '../kriz/kriz.reducer';
import { LoadingSpinner } from 'app/component/LoadingSpinner';
import { validationKrizAdtEkip } from 'app/shared/util/get-custom-service';
import { IKrizAdtEkip } from 'app/shared/model/kriz-adt-ekip.model';

interface GorevComponentProp extends RouteComponentProps<{ id: string }> {
  sicilId?: string;
}

const Gorevler = (props: GorevComponentProp) => {
  const { match } = props;
  const { sicilId } = props;

  const dispatch = useAppDispatch();
  const isDrawer = useAppSelector(state => state.krizChat.isDrawer);

  const [activeTab, setActiveTab] = useState(1);
  const [selectedAdtEkip, setSelectedAdtEkip] = useState(null);
  const [adtEkipList, setAdtEkipList] = useState<IKrizAdtEkip[]>(null);

  const [adtEkipLoading, setAdtEkipLoading] = useState(false);

  const [isOpenTaskModal, setIsOpenTaskModal] = useState(false);

  useEffect(() => {
    dispatch(getReferans({ page: 0, size: 10000, sort: 'asc' }));
    dispatch(getRefEkDosyaTipis({ page: 0, size: 1000, sort: 'asc', hasParent: true }));
    dispatch(getKrizEntitiy(match.params.id));
    getAdtEkipList();
  }, []);

  const getAdtEkipList = async () => {
    setAdtEkipLoading(true);

    const krizEkip = await validationKrizAdtEkip(props.match.params.id);
    setAdtEkipList(krizEkip);

    setAdtEkipLoading(false);
  };

  const handleCloseUpdateForm = () => {
    setIsOpenTaskModal(false);
  };

  const newTaskModal = () => {
    return (
      <Modal isOpen={isOpenTaskModal} toggle={handleCloseUpdateForm} style={{ maxWidth: '1200px' }} size="lg" centered>
        <ModalHeader data-cy="refAdtFormUpdateDialogHeading" toggle={handleCloseUpdateForm}>
          <Translate contentKey="entity.action.newTask">New Task</Translate>
        </ModalHeader>
        <ModalBody className=" gorevDurumlari">
          <GorevEkle adtEkipList={adtEkipList} handleCloseForm={handleCloseUpdateForm} krizId={props.match.params.id} />
        </ModalBody>
      </Modal>
    );
  };

  const titleTask = sicilId ? 'tk24AdysApp.krizDetay.adtGorevlerim' : 'tk24AdysApp.krizDetay.adtTumGorevler';
  const getSize = () => (window.innerWidth < 1600 ? 'small' : 'medium');
  return (
    <KrizMain isDrawer={isDrawer}>
      {newTaskModal()}

      {adtEkipLoading && <LoadingSpinner />}

      <div style={{ marginBottom: 5 }}>
        <InfoTable id={match.params.id} titleContentKey={titleTask} />
      </div>

      <Row>
        <Col md={12}>
          <div className="d-flex align-items-center">
            <Box sx={{ minWidth: 640, backgroundColor: '#FFFFFF' }} className="mt-3 mb-3">
              <FormControl fullWidth size={getSize()}>
                <InputLabel id="default-krizKatilimci-label" className="select-input-label">
                  {' '}
                  <Translate contentKey="tk24AdysApp.refAdtEkip.home.title" />
                </InputLabel>
                <Select
                  labelId="krizAdtEkip-label"
                  id="krizAdtEkip"
                  label="krizAdtEkip Listesi"
                  MenuProps={MenuProps}
                  className="select-input"
                >
                  <MenuItem
                    key={'all'}
                    value={null}
                    onClick={() => {
                      setActiveTab(1);
                      setSelectedAdtEkip(null);
                    }}
                  >
                    <Translate contentKey="entity.action.timSec">Tim Sec</Translate>
                  </MenuItem>
                  {adtEkipList &&
                    adtEkipList.map(ekip => (
                      <MenuItem key={ekip.adtEkip.id} value={ekip.adtEkip.id} onClick={() => setSelectedAdtEkip(ekip)}>
                        {ekip.adtEkip.adi}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Box>
          </div>
        </Col>
      </Row>

      <div>
        <Nav tabs className="tabAdtGorev">
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === 1 })}
              onClick={() => {
                setActiveTab(1);
              }}
            >
              <Translate contentKey="tk24AdysApp.krizAdtEkip.timGorevleri" />
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              disabled={selectedAdtEkip === null}
              className={classnames({ active: activeTab === 2 })}
              onClick={() => {
                setActiveTab(2);
              }}
            >
              <Translate contentKey="tk24AdysApp.krizAdtEkip.timUyesiGorevleri" />
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane key={1} tabId={1}>
            <GorevAdtTim selectedAdtEkip={selectedAdtEkip} activeTab={activeTab} {...props} />
          </TabPane>
          <TabPane key={2} tabId={2} disabled>
            <GorevAdtKatilimci selectedAdtEkip={selectedAdtEkip} activeTab={activeTab} {...props} />
          </TabPane>
        </TabContent>
      </div>
    </KrizMain>
  );
};

export default Gorevler;
