import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import TalimatTakip from 'app/entities/talimat-takip/talimat-takip';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={TalimatTakip} />
    </Switch>
  </>
);

export default Routes;
