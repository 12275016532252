import React, { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import InfoTable from 'app/entities/infoTable';
import IrtibatHavalimani from './irtibat-havalimanı';
import IrtibatDiger from './irtibat-diger';
import { KrizMain } from 'app/component/KrizMain';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { Box, Tab, Tabs, Typography } from '@mui/material';
import { getEntity } from '../kriz/kriz.reducer';
import { isObjectEmpty } from 'app/shared/util/confirm-utils';
import { LoadingSpinner } from 'app/component/LoadingSpinner';
import styled from 'styled-components';
import { translate } from 'app/component/jhipster';
import { HasAuthComponent } from 'app/component/HasAuthComponent';
import TumHavalimani from './tum-havalimanlari';
import Personel from '../personel/personel';

const StyledTab = styled(Tab)`
  &:hover {
    box-shadow: 0px -2px 4px 0px #0000000f;
  }
`;

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const Irtibat = (props: RouteComponentProps<{ id: string }>) => {
  const dispatch = useAppDispatch();
  const [value, setValue] = React.useState(0);
  const kriz = useAppSelector(state => state.kriz.entity);
  const loading = useAppSelector(state => state.kriz.loading);
  const isDrawer = useAppSelector(state => state.krizChat.isDrawer);

  useEffect(() => {
    dispatch(getEntity(props.match.params.id));
  }, []);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <KrizMain isDrawer={isDrawer}>
      <div style={{ marginBottom: 5 }}>
        <InfoTable id={props.match.params.id} titleContentKey={'tk24AdysApp.krizDetay.irtibatBilgileri'} />
      </div>
      {loading && <LoadingSpinner />}
      {!isObjectEmpty(kriz) && (
        <>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }} className="krizAnaCerides">
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" className="tabKrizCerideAnaKategori">
              <StyledTab label={translate('tk24AdysApp.refHavalimani.home.title')} {...a11yProps(0)} className="tabKrizCerideTitle" />
              <StyledTab label={translate('tk24AdysApp.refHavalimani.diger')} {...a11yProps(1)} className="tabKrizCerideTitle" />
              <StyledTab label={translate('tk24AdysApp.refHavalimani.allHavalimani')} {...a11yProps(2)} className="tabKrizCerideTitle" />
              <StyledTab label={translate('tk24AdysApp.personel.thyRehber')} {...a11yProps(3)} className="tabKrizCerideTitle" />
            </Tabs>
          </Box>
          <Box sx={{ width: '100%' }} className="krizCeride">
            <CustomTabPanel value={value} index={0}>
              {value === 0 && <IrtibatHavalimani krizInfo={kriz} />}
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              {value === 1 && <IrtibatDiger krizInfo={kriz} irtibatProps={props} />}
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
              <HasAuthComponent grantedAuthorites={['ref_havalimani_read']}>
                {value === 2 && <TumHavalimani history={undefined} location={undefined} match={undefined} />}
              </HasAuthComponent>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={3}>
              {value === 3 && <Personel history={undefined} location={undefined} match={undefined} />}
            </CustomTabPanel>
          </Box>
        </>
      )}
    </KrizMain>
  );
};

export default Irtibat;
