import { IQueryParams } from '../reducers/reducer.utils';
import { IReferans } from './referans.model';

export interface IRefIslemKodu {
  id?: number;
  realId?: number;
  guid?: string;
  adi?: string;
  adiTr?: string;
  adiEn?: string;
  cDate?: string;
  cUser?: number;
  mDate?: string;
  mUser?: number;
  bildirimTuru?: IReferans;
}

export interface IRefIslemKoduParams extends IQueryParams {
  entity?: IRefIslemKodu;
}

export const defaultValue: Readonly<IRefIslemKodu> = {};
