import { IReferans } from 'app/shared/model/referans.model';
import { IKriz } from 'app/shared/model/kriz.model';
import { IRefKrizTuru } from 'app/shared/model/ref-kriz-turu.model';
import { IQueryParams } from '../reducers/reducer.utils';

export interface IRefKrizSeviye {
  id?: number;
  guid?: string;
  adi?: string;
  adiTr?: string;
  adiEn?: string;
  cDate?: string;
  cUser?: number;
  mDate?: string;
  mUser?: number;
  defaultKrizSeviye?: string;
  defaultTplntTipi?: IReferans;
  durum?: IReferans;
  krizs?: IKriz[] | null;
  refKrizTurus?: IRefKrizTuru[] | null;
  cssBorderColor?: string;
  cssFontColor?: string;
}

export interface IRefKrizSeviyeParams extends IQueryParams {
  entity?: IRefKrizSeviye;
}

export const defaultValue: Readonly<IRefKrizSeviye> = {};
