export const RefMurettebatDetayEnum = {
  CategoryQualification: 'categoryqualification',
  AircraftQualification: 'aircraftqualification',
  TechnicalQualification: 'technicalqualification',
  Recruitment: 'recruitment',
  CockpitDateInfo: 'cockpitdateinfo',
  Education: 'education',
  FlightAcademy: 'flightacademy',
  BaseFleet: 'basefleet',
  BaseDuty: 'baseduty',
  Rank: 'rank',
  Base: 'base',
  Seniority: 'seniority',
  ExtraDuty: 'extraduty',
  InstructorCaptain: 'instructorcaptain',
  CockpitLicense: 'cockpitlicense',
  Passport: 'passport',
  Aproncard: 'aproncard',
  RecurrentCheck: 'recurrentcheck',
  PersonelManagementAwardInfo: 'personelmanagementawardinfo',
  AccidentIncident: 'accidentincident',
  OperationalSafetyCompetenceAsse: 'operationalsafetycompetenceassessmentboards',
  FailInformation: 'failinformation',
};

export const BilgiFieldsMapping = {
  [RefMurettebatDetayEnum.CategoryQualification]: ['aircraftTypeValue', 'categoryValue', 'startDate', 'endDate'],
  [RefMurettebatDetayEnum.AircraftQualification]: ['aircraftTypeValue', 'startDate', 'endDate'],
  [RefMurettebatDetayEnum.TechnicalQualification]: ['aircraftTypeValue', 'technicalQualificationTypeValue', 'startDate', 'endDate'],
  [RefMurettebatDetayEnum.Recruitment]: ['startDateAsPersonnel', 'startDateAsCrew'],
  [RefMurettebatDetayEnum.CockpitDateInfo]: ['pilotDate', 'captainDate'],
  [RefMurettebatDetayEnum.Education]: [
    'educationTypeValue',
    'educationInstitutionName',
    'department',
    'facultyInstitute',
    'startDate',
    'endDate',
  ],
  [RefMurettebatDetayEnum.FlightAcademy]: [
    'flightSchoolValue',
    'flightSchoolFleet',
    'trainingStartDate',
    'trainingEstimatedEndDate',
    'trainingEndDate',
    'executiveBoardDate',
    'executiveBoardNumber',
    'note',
  ],
  [RefMurettebatDetayEnum.BaseFleet]: ['fleetValue', 'startDate', 'endDate'],
  [RefMurettebatDetayEnum.BaseDuty]: ['companyValue', 'carrierValue', 'baseDutyValue', 'startDate', 'endDate'],
  [RefMurettebatDetayEnum.Rank]: [
    'crewTypeValue',
    'baseDutyValue',
    'baseClassValue',
    'baseFleetValue',
    'crewSourceValue',
    'crewDefinitionValue',
    'companyValue',
    'subCompany',
  ],
  [RefMurettebatDetayEnum.Base]: ['baseValue', 'useBaseShuttle', 'useTransferShuttle', 'bridge', 'startDate', 'endDate'],
  [RefMurettebatDetayEnum.Seniority]: ['seniority', 'seniorityVersion', 'startDate', 'endDate'],
  [RefMurettebatDetayEnum.ExtraDuty]: ['extraDutyPositionValue', 'startDate', 'endDate'],
  [RefMurettebatDetayEnum.InstructorCaptain]: ['aircraftTypeValue', 'instructorCaptainTypeValue', 'startDate', 'endDate'],
  [RefMurettebatDetayEnum.CockpitLicense]: [
    'licenseTypeValue',
    'licenseNumber',
    'issuerCountryValue',
    'startDate',
    'endDate',
    'licenseAuthorityValue',
  ],
  [RefMurettebatDetayEnum.Passport]: [
    'passportTypeValue',
    'codeOfIssueStateValue',
    'passportNumber',
    'passportName',
    'passportSurname',
    'startDate',
    'endDate',
  ],
  [RefMurettebatDetayEnum.Aproncard]: ['documentNo', 'startDate', 'endDate', 'description'],
  [RefMurettebatDetayEnum.RecurrentCheck]: ['startDate', 'endDate'],
  [RefMurettebatDetayEnum.PersonelManagementAwardInfo]: [
    'awardGroupValue',
    'awardTypeValue',
    'awardProposalDate',
    'awardDecisionDate',
    'awardDecisionByValue',
  ],
  [RefMurettebatDetayEnum.AccidentIncident]: ['accidentIncidentDate', 'note', 'isAccidentIncident'],
  [RefMurettebatDetayEnum.OperationalSafetyCompetenceAsse]: [
    'boardId',
    'boardTypeValue',
    'boardDate',
    'caseDate',
    'baseFleetOnCaseDateValue',
    'statusOnCaseDateValue',
    'originCodeValue',
    'originAirport',
    'originCity',
    'destinationCodeValue',
    'destinationAirport',
    'destinationCity',
    'tkNo',
    'summaryOfTheCase',
    'assize1',
    'assize2',
    'assize3',
    'assize4',
    'assize5',
    'concludedCorrectiveMeasures',
    'concludedPreventiveMeasures',
  ],
  [RefMurettebatDetayEnum.FailInformation]: [
    'trainingCheckingTypeValue',
    'trainingCheckValue',
    'simTypeValue',
    'failDate',
    'failTypeValue',
    'note',
  ],
};

export const filterBilgiFields = (bilgi, bilgiTipi) => {
  const fields = BilgiFieldsMapping[bilgiTipi];
  if (!fields) return bilgi;

  return Object.keys(bilgi)
    .filter(key => fields.includes(key))
    .reduce((acc, key) => {
      acc[key] = bilgi[key];
      return acc;
    }, {});
};

export default { RefMurettebatDetayEnum, BilgiFieldsMapping, filterBilgiFields };
