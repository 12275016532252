import React, { useState, useEffect } from 'react';
import { LoadingSpinner } from 'app/component/LoadingSpinner';
import { Paper, TableBody, TableCell, TableContainer, TableHead, TableRow, Table } from '@mui/material';
import { IPersonel } from 'app/shared/model/personel.model';
import axios from 'axios';
import { IRefIstasyonGorevGrubuPersonel } from 'app/shared/model/ref-ist-gorev-grubu-personel.model';
import { IRefHavalimani } from 'app/shared/model/ref-havalimani.model';
import { translate, Translate } from 'app/component/jhipster';
import ExcelDownloadButton from 'app/component/ExcelDownload';
import ExcelOganizasyonSemaDownload from 'app/component/ExcelOganizasyonSemaDownload';

interface RefIstGorevGrubuPersonelParams {
  havalimani: IRefHavalimani;
}

export const RefIstGorevGrubuPersonel: React.FC<RefIstGorevGrubuPersonelParams> = ({ havalimani }) => {
  const [loading, setLoading] = useState(false);

  const [header, setHeader] = useState(null);
  const [dataList, setDataList] = useState([]);

  const loadHavalimaniPersonel = async () => {
    try {
      setLoading(true);
      const gorevGrubuPersonels: IRefIstasyonGorevGrubuPersonel[] = (
        await axios.get(`api/ref-ist-gorev-grubu-personels?page=0&size=200&sort=id,desc&havalimaniId.equals=${havalimani.id}`)
      ).data;
      const uniqueGroups = [...new Map(gorevGrubuPersonels.map(item => [item.istasyonGorevGrubu.id, item.istasyonGorevGrubu])).values()];
      const newGroups = uniqueGroups
        .map(group => ({
          id: group.id,
          adi: group.adi,
          sortIndex: group.sortIndex,
        }))
        .sort((a, b) => a.sortIndex - b.sortIndex);

      setHeader(newGroups);
      const result = {};
      gorevGrubuPersonels.forEach(item => {
        const { istasyonGorevGrubu, personel } = item;
        if (!result[istasyonGorevGrubu.id]) {
          result[istasyonGorevGrubu.id] = [];
        }
        result[istasyonGorevGrubu.id].push(personel);
      });
      const maxRecordCount = Math.max(...Object.values(result).map((arr: IPersonel[]) => arr.length));
      for (let i = 0; i < maxRecordCount; i++) {
        const record = [];
        newGroups.forEach(item => {
          record[`row-${item.id}`] = result[item.id][i] ? result[item.id][i] : {};
        });
        setDataList(prevData => [...prevData, record]);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadHavalimaniPersonel();
  }, []);

  return (
    <div>
      {loading && <LoadingSpinner />}
      {header && header.length > 0 ? (
        <>
          <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '3px' }}>
            <ExcelOganizasyonSemaDownload
              className={'download-button'}
              exportName={translate('tk24AdysApp.refIstasyonGorevGrubu.detail.titleHavalimani', {
                params: `${havalimani.adi.toUpperCase()} ${havalimani.koduIata ? `(${havalimani.koduIata})` : ''}`,
              })}
              grantedAuthorites={['ref_ist_gorev_grubu_personel_read']}
              header={header}
              dataList={dataList}
            />
          </div>

          <TableContainer component={Paper}>
            <Table className="gorevIstasyonGrubu">
              <TableHead>
                <TableRow>
                  <TableCell align="center" style={{ border: '1px solid #ddd', fontWeight: 'bold' }} rowSpan={2}>
                    SNO
                  </TableCell>
                  <TableCell align="center" style={{ border: '1px solid #ddd', fontWeight: 'bold' }} colSpan={header.length * 2}>
                    <Translate
                      contentKey="tk24AdysApp.refIstasyonGorevGrubu.detail.titleHavalimani"
                      interpolate={{ params: `${havalimani.adi.toUpperCase()} ${havalimani.koduIata ? `(${havalimani.koduIata})` : ''}` }}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  {header.map(item => (
                    <TableCell key={item.id} align="center" style={{ border: '1px solid #ddd', fontWeight: 'bold' }} colSpan={2}>
                      {item.adi}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {dataList &&
                  dataList.map((data, index) => (
                    <TableRow key={index}>
                      <TableCell align="center">{index + 1}</TableCell>
                      {header &&
                        header.map(item => (
                          <>
                            <TableCell align="center" style={{ border: '1px solid #ddd' }}>
                              {data[`row-${item.id}`]?.adi} {data[`row-${item.id}`]?.soyadi}
                            </TableCell>
                            <TableCell align="center" style={{ border: '1px solid #ddd' }}>
                              {data[`row-${item.id}`]?.unvanAdi}
                            </TableCell>
                          </>
                        ))}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      ) : (
        !loading && (
          <div style={{ minWidth: '98.5%', backgroundColor: '#ffe8a4', padding: '10px' }}>
            <Translate contentKey="tk24AdysApp.refIstasyonGorevGrubu.home.notFoundPersonel">Bulunamadı</Translate>
          </div>
        )
      )}
    </div>
  );
};

export default RefIstGorevGrubuPersonel;
