import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import RefHavalimani from './ref-havalimani';
import RefHavalimaniDetail from './ref-havalimani-detail';
import RefHavalimaniUpdate from './ref-havalimani-update';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={RefHavalimaniUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={RefHavalimaniUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={RefHavalimaniDetail} />
      <ErrorBoundaryRoute path={match.url} component={RefHavalimani} />
    </Switch>
  </>
);

export default Routes;
