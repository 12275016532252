import { APP_DATE_FORMAT, SERVER_URL } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import {
  uploadAndGetEntity as uploadEkDosyaEntity,
  updateEntity,
  deleteEntity as deleteEkDosyaEntity,
  getFilteredEntities as getFilterMurettebatEkDosyaEntities,
} from 'app/entities/ek-dosya/ek-dosya.reducer';
import { CustomSaveEntityConfirm, deleteEntityConfirm, isObjectEmpty, saveEntityConfirm } from 'app/shared/util/confirm-utils';
import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { TextFormat, translate, Translate } from 'app/component/jhipster';
import { Button, Container, Form, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import TableNameEnum from 'app/constants/TableNameEnum';
import GrantedButton from 'app/component/GrantedButton';
import { IKriz } from 'app/shared/model/kriz.model';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { IRefMurettebat } from 'app/shared/model/ref-murettebat.model';
import { LoadingSpinner } from 'app/component/LoadingSpinner';
import { ReferansEnum } from 'app/constants/ReferansEnum';
import { GetBase64PromiseString } from 'app/shared/util/util';
import { FormItem } from 'app/component/FormItem';
import { RefEkDosyaTipiCodeEnum } from 'app/constants/RefEkDosyaTipiCodeEnum';

interface EtkilenenKisiMurettebatEkDosyaTabProps {
  refMurettebat: IRefMurettebat;
  kriz: IKriz;
  tab: number;
}

const EtkilenenKisiMurettebatEkDosyaTab: React.FC<EtkilenenKisiMurettebatEkDosyaTabProps> = ({ refMurettebat, kriz, tab }) => {
  const dispatch = useAppDispatch();
  const krizMurettebatEkDosyaList = useAppSelector(state => state.ekDosya.entities);
  const krizMurettebatEkDosyaLoading = useAppSelector(state => state.ekDosya.loading);
  const krizMurettebatEkDosyaUpdating = useAppSelector(state => state.ekDosya.updating);
  const refEkDosyaTipis = useAppSelector(state => state.refEkDosyaTipi.entities);
  const [isMurettebatEkDosyaModal, setIsMurettebatEkDosyaModal] = useState(false);
  const fileInputRef = useRef(null);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [fileNames, setFileNames] = useState({});
  const [ekDosyaAciklama, setEkDosyaAciklama] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [isEkDosyaUpdateModal, setIsEkDosyaUpdateModal] = useState(false);
  const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);
  const [modalPromiseResolve, setModalPromiseResolve] = useState(null);

  useEffect(() => {
    if (tab === 7) {
      getEkDosya();
    }
  }, [tab]);

  const getEkDosya = () => {
    dispatch(
      getFilterMurettebatEkDosyaEntities({
        ilgiliTabloKayitId: refMurettebat?.kisi?.realId,
        ilgiliTablo: TableNameEnum.Kisi,
        page: 0,
        size: 100,
        sort: 'id,asc',
      })
    );
  };

  const handleDownloadSavedFile = async file => {
    try {
      const response = await axios.get(`api/ek-dosyas/download/${file.id}`, {
        responseType: 'blob',
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${file.dosyaAdi}`);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error('Dosya indirme hatası:', error);
    }
  };

  const handleRemoveSavedFile = async file => {
    const isDel = await deleteEntityConfirm(file.id, 'ekDosya');

    if (isDel) {
      await dispatch(deleteEkDosyaEntity(file.id));
      getEkDosya();
    }
  };

  const handleOpenEkDosyaModal = () => {
    setIsMurettebatEkDosyaModal(true);
  };

  const handleCloseEkDosyaModal = () => {
    setSelectedFiles([]);
    setFileNames({});
    setEkDosyaAciklama('');
    setIsMurettebatEkDosyaModal(false);
  };

  const handleDrop = event => {
    event.preventDefault();
    const files = Array.from(event.dataTransfer.files);
    setSelectedFiles([...selectedFiles, ...files]);
  };

  const getFileNameAndExtension = filename => {
    const dotIndex = filename.lastIndexOf('.');
    if (dotIndex === -1) {
      return { name: filename, extension: '' };
    }
    return { name: filename.substring(0, dotIndex), extension: filename.substring(dotIndex) };
  };

  const handleFileNameChange = (index, newName) => {
    setFileNames(prevNames => ({
      ...prevNames,
      [index]: newName,
    }));

    const updatedFiles = selectedFiles.map((file, fileIndex) => {
      if (fileIndex === index) {
        const { extension } = getFileNameAndExtension(file.name);
        return new File([file], `${newName}${extension}`, { type: file.type });
      }
      return file;
    });

    setSelectedFiles(updatedFiles);
  };

  const handleEkDosyaAciklamaChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEkDosyaAciklama(event.target.value);
  };

  const fileUpload = async dosya => {
    const fileInfoArray = await GetBase64PromiseString(selectedFiles);
    const ekDosyaEntity = {
      ...dosya,
      fileList: fileInfoArray.map((file, index) => {
        const { extension } = getFileNameAndExtension(file.fileName);
        const updatedName = fileNames[index] || getFileNameAndExtension(file.fileName).name;
        return {
          ...file,
          fileName: `${updatedName}${extension}`,
          aciklama: ekDosyaAciklama,
        };
      }),
    };
    dispatch(
      uploadEkDosyaEntity({
        entity: ekDosyaEntity,
        ilgiliTabloKayitId: refMurettebat?.kisi?.realId,
        ilgiliTablo: TableNameEnum.Kisi,
        page: 0,
        size: 100,
        sort: 'id,asc',
      })
    );
  };

  const handleFileChange = e => {
    const files = e.target.files;
    setSelectedFiles([...selectedFiles, ...files]);
  };

  const handleRemoveFile = index => {
    const newFiles = [...selectedFiles];
    newFiles.splice(index, 1);
    setSelectedFiles(newFiles);
  };

  const formConvertDataObject = formEvent => {
    const formDataObject = {};
    for (const field of formEvent.target.elements) {
      if (field.name) {
        formDataObject[field.name] = field.value;
      }
    }
    return formDataObject;
  };

  const saveEkDosyaEntity = async values => {
    values.preventDefault();
    const isSave = await saveEntityConfirm();

    if (isSave) {
      const formDataObject = formConvertDataObject(values);

      const ekDosyaSabit = {
        ilgiliTabloKayitId: refMurettebat?.kisi?.realId,
        ilgiliTablo: TableNameEnum.Kisi,
        onaylandiMi: ReferansEnum.EvetHayir.Hayir,
        ekDosyaTipi: refEkDosyaTipis.find(it => it.id.toString() === formDataObject['ekDosyaTipi'].toString()),
      };

      await fileUpload(ekDosyaSabit);
    }
    handleCloseEkDosyaModal();
  };

  const handleCloseUpdateForm = () => {
    if (isEkDosyaUpdateModal) {
      setIsEkDosyaUpdateModal(false);
    }
    setSelectedFile(null);
  };

  const showSaveConfirmModal = () => {
    setIsSaveModalOpen(true);
    return new Promise(resolve => {
      setModalPromiseResolve(() => resolve);
    });
  };

  const handleConfirm = () => {
    if (modalPromiseResolve) {
      modalPromiseResolve(true);
    }
    setIsSaveModalOpen(false);
  };

  const handleCancel = () => {
    if (modalPromiseResolve) {
      modalPromiseResolve(false);
    }
    setIsSaveModalOpen(false);
  };

  const saveNewEkDosya = async e => {
    e.preventDefault();

    setIsEkDosyaUpdateModal(false);

    const isSave = await showSaveConfirmModal();
    if (isSave) {
      const formDataObject = {};
      for (const field of e.target.elements) {
        if (field.name) {
          formDataObject[field.name] = field.value;
        }
      }

      const ekDosyaEntity = {
        ...selectedFile,
        ...formDataObject,
        ekDosyaTipi: refEkDosyaTipis.find(it => it?.id?.toString() === formDataObject['ekDosyaTipi'].toString()),
      };

      if (selectedFile !== null) {
        await dispatch(
          updateEntity({
            entity: ekDosyaEntity,
            ilgiliTabloKayitId: refMurettebat?.kisi?.realId,
            ilgiliTablo: TableNameEnum.Kisi,
          })
        );
      }

      handleCloseUpdateForm();
    } else {
      setIsEkDosyaUpdateModal(true);
    }
  };

  const EkDosyaUpdateModal = () => {
    return (
      <Modal className="modal-style" isOpen={isEkDosyaUpdateModal} toggle={handleCloseUpdateForm} centered>
        <ModalHeader data-cy="EkDosyaCreateUpdateHeading" toggle={handleCloseUpdateForm}>
          {selectedFile && (
            <p className="modal-title-info">
              {selectedFile?.ekDosyaTipi?.adi}-{selectedFile?.dosyaAdi}
            </p>
          )}
          <Translate contentKey="tk24AdysApp.ekDosya.home.updateLabel">Update a ekDosya</Translate>
        </ModalHeader>
        <Form onSubmit={saveNewEkDosya}>
          <ModalBody>
            <FormItem
              label={translate('tk24AdysApp.ekDosya.ekDosyaTipi')}
              id="ref-ek-dosya-tipi-id"
              name="ekDosyaTipi"
              defaultValue={
                selectedFile && refEkDosyaTipis.length > 0 && selectedFile['ekDosyaTipi'] !== null && selectedFile.ekDosyaTipi.id
              }
              type="select"
              validation={{
                required: { value: true, message: translate('entity.validation.required') },
              }}
            >
              <option value="" key="0" />
              {refEkDosyaTipis
                .filter(res => res.parent && res.parent.code === RefEkDosyaTipiCodeEnum.KrizEtkilenenKisiDokuman)
                .map(otherEntity => (
                  <option value={otherEntity.id} key={otherEntity.id}>
                    {otherEntity.adi}
                  </option>
                ))}
            </FormItem>

            <FormItem
              label={translate('tk24AdysApp.ekDosya.dosyaAdi')}
              id="dosyaAdiId"
              name="dosyaAdi"
              defaultValue={selectedFile && selectedFile['dosyaAdi']}
              type="text"
              validation={{
                required: { value: true, message: translate('entity.validation.required') },
                maxLength: { value: 1000, message: translate('entity.validation.maxlength', { max: 100 }) },
              }}
            />

            <FormItem
              label={translate('tk24AdysApp.ekDosya.aciklama')}
              id="aciklamaId"
              name="aciklama"
              defaultValue={selectedFile && selectedFile['aciklama']}
              type="textarea"
              validation={{
                maxLength: { value: 1000, message: translate('entity.validation.maxlength', { max: 100 }) },
              }}
            />
          </ModalBody>
          <ModalFooter className="modal-footer">
            <Button className="cancel-button" onClick={handleCloseUpdateForm}>
              <Translate contentKey="entity.action.cancel">Cancel</Translate>
            </Button>
            &nbsp;
            <Button className="save-button" id="jhi-confirm-delete-refAdtForm" type="submit" data-cy="entityConfirmButton">
              <Translate contentKey="entity.action.save">Kaydet</Translate>
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    );
  };

  const UploadSelectedMurettebatEkDosya = () => {
    return (
      <Modal className="modal-style" isOpen={isMurettebatEkDosyaModal} toggle={handleCloseEkDosyaModal} centered>
        <ModalHeader data-cy="RefEtkilenenKisiMurettebatCreateUpdateHeading" toggle={handleCloseEkDosyaModal}>
          {refMurettebat && (
            <p className="modal-title-info">
              {refMurettebat?.refUcus?.tasiyiciHavayolu +
                refMurettebat?.refUcus?.ucusNumarasi +
                ' - ' +
                refMurettebat?.adi +
                ' ' +
                refMurettebat?.soyadi +
                ' - ' +
                refMurettebat?.kisiAltTipi?.adi}
            </p>
          )}
          <Translate contentKey="tk24AdysApp.refMurettebat.dosyaYukle" />
        </ModalHeader>

        <Form onSubmit={saveEkDosyaEntity}>
          <ModalBody style={{ maxHeight: '500px', overflowY: 'auto' }}>
            <FormItem
              label={translate('tk24AdysApp.ekDosya.ekDosyaTipi')}
              id="ref-ek-dosya-tipi-id"
              name="ekDosyaTipi"
              type="select"
              validation={{
                required: { value: true, message: translate('entity.validation.required') },
              }}
            >
              <option value="" key="0" />
              {refEkDosyaTipis
                .filter(res => res.parent && res.parent.code === RefEkDosyaTipiCodeEnum.KrizEtkilenenKisiDokuman)
                .map(otherEntity => (
                  <option value={otherEntity.id} key={otherEntity.id}>
                    {otherEntity.adi}
                  </option>
                ))}
            </FormItem>

            <FormItem
              label={translate('tk24AdysApp.ekDosya.ekDosyaAciklama')}
              id="ek-dosya-aciklama"
              name="aciklama"
              defaultValue={ekDosyaAciklama}
              onChange={handleEkDosyaAciklamaChange}
              type="textarea"
              validation={{
                maxLength: { value: 1000, message: translate('entity.validation.maxlength', { max: 1000 }) },
              }}
            />

            <div className="modal-file-area">
              <div className="modal-dashed-file-area" onDrop={handleDrop} onDragOver={e => e.preventDefault()}>
                <img width={16} height={20} style={{ marginBottom: '20px' }} src={`${SERVER_URL}/content/images/icon/downloadIcon.svg`} />
                <span className="modal-file-text">
                  <span className="modal-upload-file-text" onClick={() => fileInputRef.current.click()}>
                    <Translate contentKey="tk24AdysApp.ekDosya.yukle" />
                  </span>
                  <span style={{ color: 'rgba(73, 88, 106, 1)' }}>
                    {' '}
                    <Translate contentKey="tk24AdysApp.ekDosya.surukle" />
                  </span>
                  <input type="file" ref={fileInputRef} style={{ display: 'none' }} onChange={handleFileChange} multiple />
                </span>
              </div>
              <div className="modal-file-list-area">
                {selectedFiles.length > 0 && (
                  <>
                    {selectedFiles.length > 0 &&
                      selectedFiles.map((file, index) => {
                        const { name, extension } = getFileNameAndExtension(file.name);
                        return (
                          <div key={file.id} className="modal-file-list-row">
                            <div style={{ display: 'flex', justifyContent: 'start', alignItems: 'center', padding: '0px', width: '50px' }}>
                              <img width={16} height={20} src={`${SERVER_URL}/content/images/icon/downloadIcon.svg`} />
                            </div>
                            <div style={{ width: '100%' }}>
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  alignItems: 'start',
                                  justifyContent: 'center',
                                  margin: 'auto',
                                }}
                              >
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                  <input
                                    type="text"
                                    value={fileNames[index] || name}
                                    onChange={e => handleFileNameChange(index, e.target.value)}
                                    className="form-control"
                                    style={{ width: '300px' }}
                                    maxLength={950}
                                  />
                                  <span className="ml-2">{extension}</span>
                                </div>
                                <span className="modal-file-size">{`${(file.size / 1024).toFixed(2)}kb`}</span>
                              </div>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'end', alignItems: 'center', padding: '0px', width: '50px' }}>
                              <Button className="delete-file-button" onClick={() => handleRemoveFile(index)}>
                                <img width={12} height={15} src={`${SERVER_URL}/content/images/icon/deleteFileIcon.svg`} />
                              </Button>
                            </div>
                          </div>
                        );
                      })}
                  </>
                )}
              </div>
            </div>
          </ModalBody>

          <ModalFooter className="modal-footer">
            <Button className="cancel-button" onClick={handleCloseEkDosyaModal}>
              <Translate contentKey="entity.action.cancel">Cancel</Translate>
            </Button>
            &nbsp;
            <Button
              className="save-button"
              disabled={selectedFiles.length < 1}
              id="jhi-confirm-delete-refAdtForm"
              type="submit"
              data-cy="entityConfirmButton"
            >
              <Translate contentKey="entity.action.save">Kaydet</Translate>
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    );
  };

  return (
    <>
      {(krizMurettebatEkDosyaLoading || krizMurettebatEkDosyaUpdating) && <LoadingSpinner />}
      {!krizMurettebatEkDosyaLoading || !krizMurettebatEkDosyaUpdating ? (
        <>
          {isMurettebatEkDosyaModal && UploadSelectedMurettebatEkDosya()}
          {isEkDosyaUpdateModal && EkDosyaUpdateModal()}
          <CustomSaveEntityConfirm entity={selectedFiles} isOpen={isSaveModalOpen} onConfirm={handleConfirm} onCancel={handleCancel} />
          {!isObjectEmpty(kriz) && (
            <Container>
              {krizMurettebatEkDosyaList && krizMurettebatEkDosyaList.length > 0 ? (
                <Table className="accordion-table mb-4">
                  <TableHead>
                    <TableRow className="accordion-table-title-row">
                      <TableCell className="accordion-table-title-cell" align="left">
                        <Translate contentKey="tk24AdysApp.ekDosya.dosyaAdi">dosyaAdi</Translate>
                      </TableCell>
                      <TableCell className="accordion-table-title-cell" align="left">
                        <Translate contentKey="tk24AdysApp.ekDosya.ekDosyaTipiTitle">ekDosyaTipi</Translate>
                      </TableCell>
                      <TableCell className="accordion-table-title-cell" align="left">
                        <Translate contentKey="tk24AdysApp.ekDosya.aciklamaTitle">aciklama</Translate>
                      </TableCell>
                      <TableCell className="accordion-table-title-cell" align="left">
                        <Translate contentKey="tk24AdysApp.ekDosya.fileLanguageTitle">dil</Translate>
                      </TableCell>
                      <TableCell className="accordion-table-title-cell" align="left">
                        <Translate contentKey="tk24AdysApp.ekDosya.fileExtentionTitle">fileExtention</Translate>
                      </TableCell>
                      <TableCell className="accordion-table-title-cell" align="left">
                        <Translate contentKey="tk24AdysApp.ekDosya.ekleyenKullanici">ekleyenKullanici</Translate>
                      </TableCell>
                      <TableCell className="accordion-table-title-cell" align="left">
                        <Translate contentKey="tk24AdysApp.ekDosya.cDateTitle">CDate</Translate>
                      </TableCell>
                      <TableCell className="accordion-table-title-cell" align="right"></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {krizMurettebatEkDosyaList.map(ekDosya => (
                      <TableRow key={ekDosya.id} className="accordion-table-content-row">
                        <TableCell className="accordion-table-content-cell" component="th" scope="row">
                          {ekDosya?.dosyaAdi}
                        </TableCell>
                        <TableCell className="accordion-table-content-cell" component="th" scope="row">
                          {ekDosya?.ekDosyaTipi?.adi}
                        </TableCell>
                        <TableCell className="accordion-table-content-cell" component="th" scope="row">
                          {ekDosya?.aciklama}
                        </TableCell>
                        <TableCell className="accordion-table-content-cell" component="th" scope="row">
                          {ekDosya?.dil?.adi}
                        </TableCell>
                        <TableCell className="accordion-table-content-cell" component="th" scope="row">
                          {ekDosya?.fileExtention}
                        </TableCell>
                        <TableCell className="accordion-table-content-cell" component="th" scope="row">
                          {ekDosya?.ekleyenKullanici?.adi} {ekDosya?.ekleyenKullanici?.soyadi}
                        </TableCell>
                        <TableCell className="accordion-table-content-cell" component="th" scope="row">
                          <TextFormat type="date" value={ekDosya.cDate} format={APP_DATE_FORMAT} />
                        </TableCell>
                        <TableCell className="accordion-table-content-cell" component="th" scope="row" align="right">
                          <div className="btn-group flex-btn-group-container">
                            <GrantedButton
                              grantedAuthorites={['kriz_etkilenen_kisi_dosya_edit']}
                              className="edit-button"
                              onClick={() => {
                                setSelectedFile(ekDosya);
                                setIsEkDosyaUpdateModal(true);
                              }}
                              size="sm"
                              data-cy="entityEditButton"
                            >
                              <img width={14} height={14} src={`${SERVER_URL}/content/images/icon/faPencil.svg`} />
                              <span className="d-none d-md-inline">
                                <Translate contentKey="entity.action.edit">Edit</Translate>
                              </span>
                            </GrantedButton>
                            <GrantedButton
                              grantedAuthorites={['ek_dosya_read']}
                              onClick={() => handleDownloadSavedFile(ekDosya)}
                              className="download-file-button"
                              style={{ marginLeft: 10 }}
                            >
                              <img src={`${SERVER_URL}/content/images/icon/faArrowDownToLine.svg`} />
                              <span>
                                <Translate contentKey="entity.action.export" />
                              </span>
                            </GrantedButton>
                            <GrantedButton
                              id={`delete-button-${ekDosya.id}`}
                              grantedAuthorites={['kriz_etkilenen_kisi_dosya_edit']}
                              style={{ marginLeft: 10 }}
                              onClick={() => handleRemoveSavedFile(ekDosya)}
                              comparison={[
                                {
                                  value: kriz.krizDurumu.id,
                                  checkValue: ReferansEnum.KrizDurumu.Aktif,
                                  contentKey: 'error.comparison.crisisIsPassive',
                                },
                              ]}
                              className="delete-button"
                              size="sm"
                            >
                              <img width={11.2} height={12.8} src={`${SERVER_URL}/content/images/icon/faTrashCan.svg`} />
                              <span>
                                <Translate contentKey="entity.action.delete">Sil</Translate>
                              </span>
                            </GrantedButton>
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              ) : (
                !krizMurettebatEkDosyaLoading && (
                  <div className="alert alert-warning mt-3">
                    <Translate contentKey="tk24AdysApp.ekDosya.home.notFound">No Ek Dosya found</Translate>
                  </div>
                )
              )}

              <GrantedButton
                id={`create-button`}
                grantedAuthorites={['kriz_etkilenen_kisi_dosya_edit']}
                comparison={[
                  {
                    value: kriz.krizDurumu?.id,
                    checkValue: ReferansEnum.KrizDurumu.Aktif,
                    contentKey: 'error.comparison.crisisIsPassive',
                  },
                ]}
                onClick={() => {
                  handleOpenEkDosyaModal();
                }}
                className="accordion-create-button mb-3"
                size="sm"
                data-cy="entityCreateButton"
              >
                <img width={14} height={14} src={`${SERVER_URL}/content/images/icon/faPlusBlue.svg`} />
                <span className="d-none d-md-inline">
                  <Translate contentKey="tk24AdysApp.ekDosya.home.createLabel">ekDosya ekle</Translate>
                </span>
              </GrantedButton>
            </Container>
          )}
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default EtkilenenKisiMurettebatEkDosyaTab;
