import { IQueryParams } from '../reducers/reducer.utils';
import { IKriz } from './kriz.model';
import { IRefHavalimani } from './ref-havalimani.model';

export interface IKrizHavalimani {
  id?: number;
  guid?: string;
  cDate?: string;
  cUser?: number;
  mDate?: string;
  mUser?: number;
  kriz?: IKriz | null;
  havalimani?: IRefHavalimani;
}

export interface IKrizHavalimaniParams extends IQueryParams {
  entity?: IKrizHavalimani;
  krizId?: string;
}

export const defaultValue: Readonly<IKrizHavalimani> = {};
