import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { Input } from 'reactstrap';
import { Translate, TextFormat, translate } from 'app/component/jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getEntities, getTotalEntities } from './kullanici.reducer';
import { APP_DATE_FORMAT, SERVER_URL } from 'app/config/constants';
import { ASC, DESC } from 'app/shared/util/pagination.constants';
import axios from 'axios';
import { Box, Collapse, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { modalStyle } from 'app/shared/util/style-utils';
import MatModal from '@mui/material/Modal';
import { toast } from 'react-toastify';
import TransferList from 'app/component/TransferList';
import KullaniciUpdate from './kullanici-update';
import { LoadingSpinner } from 'app/component/LoadingSpinner';
import { hasAnyAuthority } from 'app/shared/util/grant-util';
import CustomPagination from 'app/component/CustomPagination';
import GrantedButton from 'app/component/GrantedButton';
import { IQueryParams } from 'app/shared/reducers/reducer.utils';
import { KeyboardArrowRight, KeyboardArrowDownOutlined } from '@mui/icons-material';
import { IKullanici } from 'app/shared/model/kullanici.model';
import { HasAuthComponent } from 'app/component/HasAuthComponent';
import ExcelDownloadButton from 'app/component/ExcelDownload';
import SortIcon from 'app/component/SortIcon';
import { IRol } from 'app/shared/model/rol.model';

export const Kullanici = (props: RouteComponentProps<{ url: string }>) => {
  const dispatch = useAppDispatch();

  const kullaniciList = useAppSelector(state => state.kullanici.entities);
  const loading = useAppSelector(state => state.kullanici.loading);
  const updating = useAppSelector(state => state.kullanici.updating);
  const totalItem = useAppSelector(state => state.kullanici.totalItems);
  const [kullaniciLoading, setKullaniciLoading] = useState(false);

  const formsLoading = useAppSelector(state => state.yetki.loading);

  const [kullaniciRols, setKullaniciRols] = useState(null);
  const [onlyForms, setOnlyForms] = useState(null);
  const [forms, setForms] = useState(null);

  const [kullaniciRol, setKullaniciRol] = useState(null);

  const [isKullaniciUpdateModal, setIsKullaniciUpdateModal] = useState(false);
  const [selectedKullanici, setSelectedKullanici] = useState(null);

  const [search, setSearch] = useState('');

  const [pageObject, setPageObject] = useState<IQueryParams>(useAppSelector(state => state.paginate.pageObject));
  const [pageObjectRol, setPageObjectRol] = useState<IQueryParams>(useAppSelector(state => state.paginate.pageObject));
  const [sortField, setSortField] = useState('rol.adiTr');
  const [sortOrder, setSortOrder] = useState('asc');
  const [open, setOpen] = React.useState(false);
  const [openItemId, setOpenItemId] = useState<number | null>(null);
  const sicilNo = useAppSelector(state => state.authentication.account.sicilNo);
  const ExcelSampleData = [
    { key: 'adi', titleContentKey: 'tk24AdysApp.kullanici.adi' },
    { key: 'soyadi', titleContentKey: 'tk24AdysApp.kullanici.soyadi' },
    { key: 'unvanAdi', titleContentKey: 'tk24AdysApp.kullanici.unvanAdi' },
    { key: 'kullaniciAdi', titleContentKey: 'tk24AdysApp.kullanici.kullaniciAdi' },
    { key: 'eposta', titleContentKey: 'tk24AdysApp.kullanici.eposta' },
    { key: 'sonLoginZamani', format: 'date', titleContentKey: 'tk24AdysApp.kullanici.sonLoginZamani' },
  ];

  const AccordionExcelSampleData = [
    { key: 'adiTr', titleContentKey: 'global.languageGlobal.adiTr' },
    { key: 'adiEn', titleContentKey: 'global.languageGlobal.adiEn' },
    { key: 'aciklamaTr', titleContentKey: 'global.languageGlobal.aciklamaTr' },
    { key: 'aciklamaEn', titleContentKey: 'global.languageGlobal.aciklamaEn' },
  ];

  const getAllEntities = () => {
    dispatch(getEntities(pageObject));
  };

  useEffect(() => {
    getAllEntities();
  }, [pageObject.page, pageObject.query, pageObject.size, pageObject.sort]);

  useEffect(() => {
    if (search.length > 2) {
      setPageObject({ ...pageObject, page: 0, query: `globalFilter=${search}` });
    } else if (search.length === 0) {
      setPageObject({ ...pageObject, page: 0, query: '' });
    }
  }, [search]);

  useEffect(() => {
    if (selectedKullanici) {
      getKullaniciList(selectedKullanici.id);
    }
  }, [pageObjectRol, sortField, sortOrder]);

  const sort = p => () => {
    const orderBy = pageObject.sort.split(',')[1];
    setPageObject({ ...pageObject, sort: `${p},${orderBy === ASC ? DESC : ASC}` });
  };

  const handlePageChange = (pageNumber, newItemsPerPage = pageObject.size) => {
    if (newItemsPerPage !== pageObject.size) {
      setPageObject({ ...pageObject, page: pageNumber - 1, size: newItemsPerPage });
    } else {
      setPageObject({ ...pageObject, page: pageNumber - 1 });
    }
  };

  const handleCloseUpdateForm = () => {
    if (isKullaniciUpdateModal) {
      setIsKullaniciUpdateModal(false);
    }
    setSelectedKullanici(null);
  };

  const handleOpen = async kullanici => {
    setKullaniciLoading(true);
    setSelectedKullanici(kullanici);
    const dataForm = [];
    const formsUrl = `api/kullanici-rols?sicilNo.equals=${sicilNo}&size=999`;
    await axios.get(formsUrl).then(response => {
      response.data.map(item => dataForm.push(item.rol));
      setForms(dataForm);
    });

    const ekipForm = [];
    const ekipFormsUrl = `api/kullanici-rols?kullaniciId.equals=${kullanici.id}&size=999`;
    await axios.get(ekipFormsUrl).then(response => {
      response.data.map(item => ekipForm.push(item.rol));
      setOnlyForms(ekipForm);
      setOpen(true);
      setKullaniciLoading(false);
    });

    const kullaniciDataRol = kullaniciList.filter(team => team.id === kullanici.id);
    setKullaniciRol(kullaniciDataRol);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const saveTransfer = async (right: any) => {
    const data = {
      kullanici: kullaniciRol[0],
      rolList: right,
    };

    setKullaniciLoading(true);
    const refKullanicisFormUrl = `api/kullanici-rols/add`;
    await axios
      .post(refKullanicisFormUrl, data)
      .then(response => {
        toast.success(translate('tk24AdysApp.kullaniciRol.updated', { param: data.kullanici.adi + ' ' + data.kullanici.soyadi }));
      })
      .catch(function (error) {
        toast.error(translate(`error.http.${error.response.status}`));
      });

    await getKullaniciList(selectedKullanici?.id);
    setKullaniciLoading(false);
    handleClose();
  };

  const fetchTotalKullaniciData = async (): Promise<IKullanici[]> => {
    try {
      const response = (await dispatch(getTotalEntities({ ...pageObject, size: totalItem }))) as {
        payload: { data: IKullanici[] };
      };

      return response.payload.data || [];
    } catch (error) {
      console.error('Error fetching data:', error);
      return [];
    }
  };

  const fetchTotalKullaniciRolData = (): IRol[] => {
    try {
      if (selectedKullanici) {
        return kullaniciRols.map(res => res.rol) || [];
      } else {
        return [];
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      return [];
    }
  };

  const getKullaniciTable = async (kullanici: IKullanici) => {
    setKullaniciRols(null);
    if (openItemId === kullanici?.id) {
      setOpenItemId(null);
    } else {
      setOpenItemId(kullanici?.id);
      setSelectedKullanici(kullanici);
      await getKullaniciList(kullanici?.id);
    }
  };

  const sortAcordion = p => () => {
    const orderBy = pageObjectRol.sort.split(',')[1];
    const newSortOrder = orderBy === ASC ? DESC : ASC;
    setPageObjectRol({ ...pageObjectRol, sort: `${p},${newSortOrder}` });
    setSortField(p);
    setSortOrder(newSortOrder);
  };

  const getKullaniciList = async (kullaniciId: number) => {
    setKullaniciLoading(true);
    const kalitimciDdmsUrl = `api/kullanici-rols?kullaniciId.equals=${kullaniciId}&sort=${sortField},${sortOrder}&size=999`;

    await axios.get(kalitimciDdmsUrl).then(response => {
      if (Array.isArray(response?.data)) {
        setKullaniciRols(response?.data);
      }
    });
    setKullaniciLoading(false);
  };

  const KullaniciUpdateModal = () => {
    return (
      <KullaniciUpdate
        handleCloseForm={handleCloseUpdateForm}
        formValues={selectedKullanici}
        isOpen={isKullaniciUpdateModal}
        pageObject={pageObject}
      ></KullaniciUpdate>
    );
  };

  const KullaniciRolUpdateModal = () => {
    return (
      <MatModal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={modalStyle} style={{ minWidth: 1200, height: 'auto' }} className="transferBox">
          {selectedKullanici && forms && forms.length > 0 && onlyForms && (
            <TransferList
              formData={forms}
              onlyForms={onlyForms}
              saveTransfer={saveTransfer}
              closeModal={handleClose}
              isSort={false}
              header={`${selectedKullanici.adi}
                      ${selectedKullanici.soyadi ? selectedKullanici.soyadi : ''}
                      ${selectedKullanici.unvanAdi ? `- ${selectedKullanici.unvanAdi}` : ''}`}
              title={translate('tk24AdysApp.kullanici.home.title')}
              transactionTitle={translate('tk24AdysApp.rol.detail.title')}
            />
          )}
        </Box>
      </MatModal>
    );
  };

  function KullaniciTable({ kullanici, isOpen, openTable }) {
    return (
      <>
        <TableRow className="accordion-content-row" sx={{ '& > *': { borderBottom: 'unset' } }} onClick={openTable}>
          <TableCell className="accordion-content-cell" style={{ width: 48 }}>
            <IconButton aria-label="expand row" size="small" className="accordion-icon">
              {isOpen ? <KeyboardArrowDownOutlined /> : <KeyboardArrowRight />}
            </IconButton>
          </TableCell>
          <TableCell className="accordion-content-cell" component="th" scope="row">
            {kullanici?.adi}
          </TableCell>
          <TableCell className="accordion-content-cell" component="th" scope="row">
            {kullanici?.soyadi}
          </TableCell>
          <TableCell className="accordion-content-cell" component="th" scope="row">
            {kullanici?.unvanAdi}
          </TableCell>
          <TableCell className="accordion-content-cell" component="th" scope="row">
            {kullanici?.kullaniciAdi}
          </TableCell>
          <TableCell className="accordion-content-cell" component="th" scope="row">
            <a href={`mailto:${kullanici?.eposta}`} className="epostaText">
              {kullanici?.eposta}
            </a>
          </TableCell>
          <TableCell className="accordion-content-cell" component="th" scope="row">
            {' '}
            {kullanici.sonLoginZamani ? <TextFormat type="date" value={kullanici.sonLoginZamani} format={APP_DATE_FORMAT} /> : null}
          </TableCell>
          <TableCell align="right" className="accordion-content-cell">
            {/* <div className="btn-group flex-btn-group-container">
              {hasAnyAuthority(['AYAR0']) ? (
                <GrantedButton
                  grantedAuthorites={['kullanici_edit']}
                  className="edit-button"
                  style={{ marginLeft: 10 }}
                  onClick={event => {
                    event.stopPropagation();
                    setSelectedKullanici(kullanici);
                    setIsKullaniciUpdateModal(true);
                  }}
                  size="sm"
                  data-cy="entityEditButton"
                >
                  <img width={14} height={14} src={`${SERVER_URL}/content/images/icon/faPencil.svg`} />
                  <span className="d-none d-md-inline">
                    <Translate contentKey="entity.action.edit">Edit</Translate>
                  </span>
                </GrantedButton>
              ) : (
                <></>
              )}
            </div> */}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell className="accordion-content-area" colSpan={12}>
            <Collapse in={isOpen} unmountOnExit>
              {kullaniciRols?.length > 0 ? (
                <Box className="accordion-box">
                  <HasAuthComponent grantedAuthorites={['kullanici_rol_read']}>
                    <Typography className="accordion-typography mt-3" gutterBottom component="div">
                      <Translate contentKey="tk24AdysApp.kullaniciRol.home.title">Kullanıcı Rol</Translate>
                    </Typography>
                    <Typography className="item-total-count" gutterBottom component="div">
                      <span className="totalListItemText">
                        <Translate contentKey="global.item-total-count" interpolate={{ total: kullaniciRols?.length }} />
                      </span>
                      <ExcelDownloadButton
                        excelSampleData={AccordionExcelSampleData}
                        grantedAuthorites={['kullanici_rol_export']}
                        fetchData={fetchTotalKullaniciRolData}
                        className={'download-button'}
                        exportName={translate('tk24AdysApp.kullaniciRol.home.title')}
                      />
                    </Typography>
                    <Table className="accordion-table">
                      <TableHead>
                        <TableRow className="accordion-table-title-row">
                          <TableCell className="accordion-table-title-cell" align="left" onClick={sortAcordion('rol.adiTr')}>
                            <div className="tableHeaderContainer">
                              <Translate contentKey="global.languageGlobal.adiTrTitle">adiTr</Translate>&nbsp;
                              <SortIcon column="rol.adiTr" activeSortColumn={pageObjectRol.sort} />
                            </div>
                          </TableCell>
                          <TableCell className="accordion-table-title-cell" align="left" onClick={sortAcordion('rol.adiEn')}>
                            <div className="tableHeaderContainer">
                              <Translate contentKey="global.languageGlobal.adiEnTitle">adiEn</Translate>&nbsp;
                              <SortIcon column="rol.adiEn" activeSortColumn={pageObjectRol.sort} />
                            </div>
                          </TableCell>
                          <TableCell className="accordion-table-title-cell" align="left" onClick={sortAcordion('rol.aciklamaTr')}>
                            <div className="tableHeaderContainer">
                              <Translate contentKey="global.languageGlobal.aciklamaTrTitle">aciklamaTr</Translate>&nbsp;
                              <SortIcon column="rol.aciklamaTr" activeSortColumn={pageObjectRol.sort} />
                            </div>
                          </TableCell>
                          <TableCell className="accordion-table-title-cell" align="left" onClick={sortAcordion('rol.aciklamaEn')}>
                            <div className="tableHeaderContainer">
                              <Translate contentKey="global.languageGlobal.aciklamaEnTitle">aciklamaEn</Translate>&nbsp;
                              <SortIcon column="rol.aciklamaEn" activeSortColumn={pageObjectRol.sort} />
                            </div>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {kullaniciRols.map(item => (
                          <TableRow key={item?.id} className="accordion-table-content-row">
                            <TableCell className="accordion-table-content-cell" component="th" scope="row">
                              {item?.rol?.adiTr}
                            </TableCell>
                            <TableCell className="accordion-table-content-cell" component="th" scope="row">
                              {item?.rol?.adiEn}
                            </TableCell>
                            <TableCell className="accordion-table-content-cell" component="th" scope="row">
                              {item?.rol?.aciklamaTr}
                            </TableCell>
                            <TableCell className="accordion-table-content-cell" component="th" scope="row">
                              {item?.rol?.aciklamaEn}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </HasAuthComponent>
                </Box>
              ) : (
                !kullaniciLoading && (
                  <div className="alert alert-warning mt-3">
                    <Translate contentKey="tk24AdysApp.rol.home.notFound">No KullaniciRol found</Translate>
                  </div>
                )
              )}
              <div className="flex-start-frame">
                <GrantedButton
                  grantedAuthorites={['kullanici_rol_read']}
                  onClick={event => {
                    event.stopPropagation();
                    handleOpen(kullanici);
                  }}
                  className="accordion-create-button"
                  size="sm"
                  data-cy="entityCreateButton"
                >
                  <FontAwesomeIcon icon="list" />
                  <Translate contentKey="tk24AdysApp.kullanici.kullaniciRolIslem">kullaniciRolIslem</Translate>
                </GrantedButton>
              </div>
            </Collapse>
          </TableCell>
        </TableRow>
      </>
    );
  }

  return (
    <div>
      {(loading || updating || kullaniciLoading || formsLoading) && <LoadingSpinner />}
      {open && KullaniciRolUpdateModal()}
      {isKullaniciUpdateModal && KullaniciUpdateModal()}

      <div style={{ display: 'flex', gap: 8, textAlign: 'center' }}>
        <p className="icon-text">
          <a className="link-a-text" href={`${SERVER_URL}`}>
            <img width={13} height={13} src={`${SERVER_URL}/content/images/icon/faHouse.svg`} />
          </a>
        </p>
        <p className="separator"> / </p>
        <p className="link-text">
          {' '}
          <Translate contentKey="tk24AdysApp.kullanici.home.title" />{' '}
        </p>
      </div>

      <h2 id="rol-heading" data-cy="RolDokumanHeading" className="title-frame">
        <a className="link-a-text" onClick={() => props.history.goBack()}>
          <img width={27.5} height={22.5} src={`${SERVER_URL}/content/images/icon/faArrowLeft.svg`} />{' '}
        </a>
        <p className="title-text">
          <Translate contentKey="tk24AdysApp.kullanici.home.title" />
        </p>
      </h2>

      <div className="content-area">
        <div className="search-frame">
          <div className="seachiconposition">
            <img className="seachicon" width={24} height={24} src={`${SERVER_URL}/content/images/icon/faSearch.svg`} />
            <Input
              value={search}
              onChange={e => setSearch(e.target.value)}
              placeholder={translate('entity.action.search')}
              className="search-input"
            />
          </div>
          <div className="create-area">
            <ExcelDownloadButton
              excelSampleData={ExcelSampleData}
              grantedAuthorites={['kullanici_export']}
              fetchData={fetchTotalKullaniciData}
              className={'download-button'}
              exportName={translate('tk24AdysApp.kullanici.home.title')}
            />
          </div>
        </div>
        <div className="table-responsive" style={{ marginTop: 5 }}>
          <TableContainer className="content-table">
            <Table aria-label="collapsible table">
              <TableHead>
                <TableRow className="accordion-title-row">
                  <TableCell className="accordion-title-cell hand" style={{ width: 48 }} />
                  <TableCell className="accordion-title-cell hand" align="left" onClick={sort('adi')}>
                    <div className="tableHeaderContainer">
                      <Translate contentKey="tk24AdysApp.kullanici.adi">Adi</Translate>
                      <SortIcon column="adi" activeSortColumn={pageObject.sort} />
                    </div>
                  </TableCell>
                  <TableCell className="accordion-title-cell hand" align="left" onClick={sort('soyadi')}>
                    <div className="tableHeaderContainer">
                      <Translate contentKey="tk24AdysApp.kullanici.soyadi">soyadi</Translate>
                      <SortIcon column="soyadi" activeSortColumn={pageObject.sort} />
                    </div>
                  </TableCell>
                  <TableCell className="accordion-title-cell hand" align="left" onClick={sort('unvanAdi')}>
                    <div className="tableHeaderContainer">
                      <Translate contentKey="tk24AdysApp.kullanici.unvanAdiTitle">unvan</Translate>
                      <SortIcon column="unvanAdi" activeSortColumn={pageObject.sort} />
                    </div>
                  </TableCell>
                  <TableCell className="accordion-title-cell hand" align="left" onClick={sort('kullaniciAdi')}>
                    <div className="tableHeaderContainer">
                      <Translate contentKey="tk24AdysApp.kullanici.kullaniciAdi">kullaniciAdi</Translate>
                      <SortIcon column="kullaniciAdi" activeSortColumn={pageObject.sort} />
                    </div>
                  </TableCell>
                  <TableCell className="accordion-title-cell hand" align="left" onClick={sort('eposta')}>
                    <div className="tableHeaderContainer">
                      <Translate contentKey="tk24AdysApp.kullanici.epostaTitle">eposta</Translate>
                      <SortIcon column="eposta" activeSortColumn={pageObject.sort} />
                    </div>
                  </TableCell>
                  <TableCell className="accordion-title-cell hand" align="left" onClick={sort('sonLoginZamani')}>
                    <div className="tableHeaderContainer">
                      <Translate contentKey="tk24AdysApp.kullanici.sonLoginZamaniTitle">sonLoginZamaniTitle</Translate>
                      <SortIcon column="sonLoginZamani" activeSortColumn={pageObject.sort} />
                    </div>
                  </TableCell>
                  <TableCell className="accordion-title-cell" />
                </TableRow>
              </TableHead>
              <TableBody>
                {kullaniciList?.map(kullanici => (
                  <KullaniciTable
                    key={kullanici?.id}
                    kullanici={kullanici}
                    isOpen={openItemId === kullanici?.id}
                    openTable={() => getKullaniciTable(kullanici)}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <CustomPagination
          currentPage={pageObject.page + 1}
          currentPageSize={pageObject.size}
          totalItems={totalItem}
          handlePageChange={handlePageChange}
        />
      </div>
    </div>
  );
};

export default Kullanici;
