import React from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import ErrorPage from '../error-page';
import { Translate, translate } from 'app/component/jhipster';
import GrantedButton from 'app/component/GrantedButton';

export const Kriz = (props: RouteComponentProps<{ url: string }>) => {
  return (
    <ErrorPage>
      <div className="error-header">
        <Translate contentKey="tk24AdysApp.kriz.home.notFound" />
      </div>
      <div className="error-content">
        <Translate contentKey="tk24AdysApp.kriz.home.notFoundDetail" />
      </div>
    </ErrorPage>
  );
};

export default Kriz;
