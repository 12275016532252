import axios from 'axios';
import { createAsyncThunk, isFulfilled } from '@reduxjs/toolkit';
import { createEntitySlice, EntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { IRefAdtAltEkipUye, IRefAdtAltEkipUyeParams, defaultValue } from 'app/shared/model/ref-adt-alt-ekip-uye.model';

const initialState: EntityState<IRefAdtAltEkipUye> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

const apiUrl = 'api/ref-adt-alt-ekip-uyes';

export const getEntities = createAsyncThunk(
  'refAdtAltEkipUye/fetch_entity_list',
  async ({ uyeId, page, size, sort, query, query2 }: IRefAdtAltEkipUyeParams) => {
    const queryParams = new URLSearchParams();
    if (sort) {
      queryParams.set('page', page.toString());
      queryParams.set('size', size.toString());
      queryParams.set('sort', sort);
    }

    if (uyeId) {
      queryParams.set('altEkipId.equals', uyeId.toString());
    }
    if (query) {
      const parsedQueryParams = new URLSearchParams(query);
      parsedQueryParams.forEach((value, key) => {
        queryParams.set(key, value);
      });
    }
    if (query2) {
      const parsedQueryParams = new URLSearchParams(query2);
      parsedQueryParams.forEach((value, key) => {
        queryParams.set(key, value);
      });
    }
    queryParams.set('cacheBuster', new Date().getTime().toString());
    const queryString = queryParams.toString();
    const requestUrl = `${apiUrl}${queryString ? `?${queryString}` : ''}`;

    try {
      const response = await axios.get<IRefAdtAltEkipUye[]>(requestUrl);
      return {
        data: response.data,
        headers: response.headers,
      };
    } catch (error) {
      throw serializeAxiosError(error);
    }
  }
);

export const getEntity = createAsyncThunk('refAdtAltEkipUye/fetch_entity', async (id: string | number) => {
  const requestUrl = `${apiUrl}/${id}`;
  try {
    const response = await axios.get<IRefAdtAltEkipUye>(requestUrl);
    return response.data;
  } catch (error) {
    throw serializeAxiosError(error);
  }
});

export const RefAdtAltEkipSlice = createEntitySlice({
  name: 'refAdtAltEkip',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getEntities.pending, state => {
        state.loading = true;
      })
      .addCase(getEntities.fulfilled, (state, action) => {
        state.loading = false;
        state.entities = action.payload.data;
        state.totalItems = parseInt(action.payload.headers['x-total-count'], 10);
      })
      .addCase(getEntities.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = action.error.message;
      })
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload;
        state.entities = [action.payload];
      });
  },
});

export const { reset } = RefAdtAltEkipSlice.actions;

export default RefAdtAltEkipSlice.reducer;
