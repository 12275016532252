import React, { useState, useEffect } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col, FormText, UncontrolledTooltip } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'app/component/jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { getEntities as getKrizs } from 'app/entities/kriz/kriz.reducer';
import { getEntities as getReferans } from 'app/entities/referans/referans.reducer';
import { getEntity, updateEntity, createEntity, reset } from './mesaj.reducer';
import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

export const MesajUpdate = (props: RouteComponentProps<{ id: string }>) => {
  const dispatch = useAppDispatch();

  const [isNew] = useState(!props.match.params || !props.match.params.id);

  const krizs = useAppSelector(state => state.kriz.entities);
  const referans = useAppSelector(state => state.referans.entities);
  const mesajEntity = useAppSelector(state => state.mesaj.entity);
  const loading = useAppSelector(state => state.mesaj.loading);
  const updating = useAppSelector(state => state.mesaj.updating);
  const updateSuccess = useAppSelector(state => state.mesaj.updateSuccess);

  const handleClose = () => {
    props.history.push('/mesaj' + props.location.search);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(props.match.params.id));
    }

    dispatch(getKrizs({}));
    dispatch(getReferans({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    values.cDate = convertDateTimeToServer(values.cDate);
    values.mDate = convertDateTimeToServer(values.mDate);

    const entity = {
      ...mesajEntity,
      ...values,
      kriz: krizs.find(it => it.id.toString() === values.krizId.toString()),
      durum: referans.find(it => it.id.toString() === values.durumId.toString()),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {
          cDate: displayDefaultDateTime(),
          mDate: displayDefaultDateTime(),
        }
      : {
          ...mesajEntity,
          cDate: convertDateTimeFromServer(mesajEntity.cDate),
          mDate: convertDateTimeFromServer(mesajEntity.mDate),
          krizId: mesajEntity?.kriz?.id,
          durumId: mesajEntity?.durum?.id,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="tk24AdysApp.mesaj.home.createOrEditLabel" data-cy="MesajCreateUpdateHeading">
            <Translate contentKey="tk24AdysApp.mesaj.home.createOrEditLabel">Create or edit a Mesaj</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="mesaj-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('tk24AdysApp.mesaj.icerik')}
                id="mesaj-icerik"
                name="icerik"
                data-cy="icerik"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  maxLength: { value: 4000, message: translate('entity.validation.maxlength', { max: 4000 }) },
                }}
              />
              <UncontrolledTooltip target="icerikLabel">
                <Translate contentKey="tk24AdysApp.mesaj.help.icerik" />
              </UncontrolledTooltip>
              <ValidatedField
                label={translate('tk24AdysApp.mesaj.gonderenKulId')}
                id="mesaj-gonderenKulId"
                name="gonderenKulId"
                data-cy="gonderenKulId"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  validate: v => isNumber(v) || translate('entity.validation.number'),
                }}
              />
              <ValidatedField
                label={translate('tk24AdysApp.mesaj.aliciKulId')}
                id="mesaj-aliciKulId"
                name="aliciKulId"
                data-cy="aliciKulId"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  validate: v => isNumber(v) || translate('entity.validation.number'),
                }}
              />
              <ValidatedField
                id="mesaj-kriz"
                name="krizId"
                data-cy="kriz"
                label={translate('tk24AdysApp.mesaj.kriz')}
                type="select"
                required
              >
                <option value="" key="0" />
                {krizs
                  ? krizs.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <FormText>
                <Translate contentKey="entity.validation.required">This field is required.</Translate>
              </FormText>
              <FormText>
                <Translate contentKey="entity.validation.required">This field is required.</Translate>
              </FormText>
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/mesaj" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default MesajUpdate;
