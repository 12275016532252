import { IRefHavalimani } from 'app/shared/model/ref-havalimani.model';
import { IReferans } from 'app/shared/model/referans.model';
import { IRefHavalimaniIrtibatTipi } from './ref-havalimani-irtibat-tipi.model';

export interface IRefHavalimaniIrtibat {
  id?: number;
  guid?: string;
  cDate?: string;
  cUser?: number;
  mDate?: string;
  mUser?: number;
  durum?: IReferans;
  havalimani?: IRefHavalimani;
  havalimaniIrtibatTipi?: IRefHavalimaniIrtibatTipi;
  adiSoyadiUnvani?: string;
  telefonNo1?: string;
  telefonNo2?: string;
  eposta?: string;
  aciklama?: string;
}

export const defaultValue: Readonly<IRefHavalimaniIrtibat> = {};
