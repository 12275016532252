import { LoadingSpinner } from 'app/component/LoadingSpinner';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { ASC, DESC } from 'app/shared/util/pagination.constants';
import React, { useEffect, useState } from 'react';
import { TextFormat, Translate, translate } from 'app/component/jhipster';
import { RouteComponentProps } from 'react-router-dom';
import { Input } from 'reactstrap';
import { getEntities, getTotalEntities } from '../ref-dis-dokuman/ref-dis-dokuman.reducer';
import axios from 'axios';
import { getFilteredEntities } from 'app/entities/ek-dosya/ek-dosya.reducer';
import TableNameEnum from 'app/constants/TableNameEnum';
import { IQueryParams } from 'app/shared/reducers/reducer.utils';
import CustomPagination from 'app/component/CustomPagination';
import { Box, Collapse, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { APP_DATE_FORMAT, SERVER_URL } from 'app/config/constants';
import { IRefDisDokuman } from 'app/shared/model/ref-dis-dokuman.model';
import GrantedButton from 'app/component/GrantedButton';
import { localeLanguage, upperOnlyFirstLetter } from 'app/shared/util/util';
import { KeyboardArrowDownOutlined, KeyboardArrowRight } from '@mui/icons-material';
import SortIcon from 'app/component/SortIcon';
import ExcelDownloadButton from 'app/component/ExcelDownload';
import { IEkDosya } from 'app/shared/model/ek-dosya.model';

interface KrizMedyaDisDokumanProps extends RouteComponentProps<{ id: string }> {
  krizId: string;
}

const KrizMedyaDisDokuman = (props: KrizMedyaDisDokumanProps) => {
  const dispatch = useAppDispatch();

  const [search, setSearch] = useState('');
  const [pageObject, setPageObject] = useState<IQueryParams>(useAppSelector(state => state.paginate.pageObject));

  const refDisDokumanList = useAppSelector(state => state.refDisDokuman.entities);
  const disDokumanDosyaList = useAppSelector(state => state.ekDosya.entities);
  const ekDosyaLoading = useAppSelector(state => state.ekDosya.loading);
  const loading = useAppSelector(state => state.refDisDokuman.loading);
  const updating = useAppSelector(state => state.refDisDokuman.updating);
  const totalItem = useAppSelector(state => state.refDisDokuman.totalItems);
  const [ekDosyaDownloadLoading, setEkDosyaDonwloadLoading] = useState(false);

  const [pageObjectEkDosya, setPageObjectEkDosya] = useState<IQueryParams>({
    ...useAppSelector(state => state.paginate.pageObject),
    size: 999,
  });

  const [openItemId, setOpenItemId] = useState<number | null>(null);

  const ExcelSampleData = [{ key: 'adi', titleContentKey: 'global.languageGlobal.adi' }];
  const [refDisDokumans, setRefDisDokumans] = useState(null);
  const [isSorting, setIsSorting] = useState(false);

  const AccordionExcelSampleData = [
    { key: 'dosyaAdi', titleContentKey: 'tk24AdysApp.ekDosya.dosyaAdi' },
    { key: 'aciklama', titleContentKey: 'tk24AdysApp.ekDosya.aciklama' },
    { key: 'dil.adi', titleContentKey: 'tk24AdysApp.ekDosya.fileLanguage' },
    { key: 'fileExtention', titleContentKey: 'tk24AdysApp.ekDosya.fileExtention' },
    { key: 'ekleyenKullanici.adi', titleContentKey: 'tk24AdysApp.ekDosya.ekleyenKullaniciAdi' },
    { key: 'ekleyenKullanici.soyadi', titleContentKey: 'tk24AdysApp.ekDosya.ekleyenKullaniciSoyadi' },
    { key: 'cDate', format: 'date', titleContentKey: 'tk24AdysApp.ekDosya.cDate' },
  ];

  const getAllEntities = () => {
    dispatch(getEntities(pageObject));
  };

  useEffect(() => {
    getAllEntities();
  }, [pageObject.page, pageObject.query, pageObject.size, pageObject.sort]);

  useEffect(() => {
    if (search.length > 2) {
      setPageObject({ ...pageObject, page: 0, query: `globalFilter=${search}` });
    } else if (search.length === 0) {
      setPageObject({ ...pageObject, page: 0, query: '' });
    }
  }, [search]);

  const sort = p => () => {
    const orderBy = pageObject.sort.split(',')[1];
    setPageObject({ ...pageObject, sort: `${p},${orderBy === ASC ? DESC : ASC}` });
  };

  const handlePageChange = (pageNumber, newItemsPerPage = pageObject.size) => {
    if (newItemsPerPage !== pageObject.size) {
      setPageObject({ ...pageObject, page: pageNumber - 1, size: newItemsPerPage });
    } else {
      setPageObject({ ...pageObject, page: pageNumber - 1 });
    }
  };

  const handleDownloadSavedFile = async refEkDosya => {
    setEkDosyaDonwloadLoading(true);

    try {
      const response = await axios.get(`api/ek-dosyas/download/${refEkDosya.id}`, {
        responseType: 'blob',
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${refEkDosya.dosyaAdi}`);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error('Dosya indirme hatası:', error);
    }
    setEkDosyaDonwloadLoading(false);
  };

  const fetchTotalKrizMedyaDisDokumanData = async (): Promise<IRefDisDokuman[]> => {
    try {
      const response = (await dispatch(getTotalEntities({ ...pageObject, size: totalItem }))) as {
        payload: { data: IRefDisDokuman[] };
      };

      return response.payload.data || [];
    } catch (error) {
      console.error('Error fetching data:', error);
      return [];
    }
  };

  const fetchTotalDisDokumanEkDosyaData = (): readonly IEkDosya[] => {
    try {
      return disDokumanDosyaList || [];
    } catch (error) {
      console.error('Error fetching data:', error);
      return [];
    }
  };

  useEffect(() => {
    if (refDisDokumans) {
      getDisDokumanTable(refDisDokumans);
    }
  }, [pageObjectEkDosya.page, pageObjectEkDosya.query, pageObjectEkDosya.size, pageObjectEkDosya.sort]);

  const sortAcordion = p => () => {
    const orderBy = pageObjectEkDosya.sort.split(',')[1];
    const newSortOrder = orderBy === ASC ? DESC : ASC;
    setPageObjectEkDosya({ ...pageObjectEkDosya, sort: `${p},${newSortOrder}` });
    setIsSorting(true);
  };

  const getDisDokumanTable = (refDisDokuman: IRefDisDokuman) => {
    if (isSorting) {
      setRefDisDokumans(refDisDokuman);
      dispatch(
        getFilteredEntities({
          ...pageObjectEkDosya,
          ilgiliTablo: TableNameEnum.RefDisDokuman,
          ilgiliTabloKayitId: refDisDokuman.realId,
        })
      );
      setIsSorting(false);
    } else {
      if (openItemId === refDisDokuman?.id) {
        setOpenItemId(null);
        setRefDisDokumans(null);
      } else {
        setOpenItemId(refDisDokuman.id);
        setRefDisDokumans(refDisDokuman);
        dispatch(
          getFilteredEntities({
            ...pageObjectEkDosya,
            ilgiliTablo: TableNameEnum.RefDisDokuman,
            ilgiliTabloKayitId: refDisDokuman.realId,
          })
        );
      }
    }
  };

  function DisDokumanTable({ refDisDokuman, isOpen, openTable }) {
    return (
      <>
        <TableRow className="accordion-content-row" sx={{ '& > *': { borderBottom: 'unset' } }} onClick={openTable}>
          <TableCell className="accordion-content-cell" style={{ width: 48 }}>
            <IconButton aria-label="expand row" size="small" className="accordion-icon">
              {isOpen ? <KeyboardArrowDownOutlined /> : <KeyboardArrowRight />}
            </IconButton>
          </TableCell>
          <TableCell className="accordion-content-cell" component="th" scope="row">
            {refDisDokuman.adi}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell className="accordion-content-area" colSpan={12}>
            <Collapse in={isOpen} unmountOnExit>
              <Box className="accordion-box">
                {disDokumanDosyaList?.length > 0 ? (
                  <>
                    <Typography className="accordion-typography mt-3" gutterBottom component="div">
                      <Translate contentKey="tk24AdysApp.ekDosya.home.title">ekDosya</Translate>
                    </Typography>
                    <Typography className="item-total-count" gutterBottom component="div">
                      <span className="totalListItemText">
                        <Translate
                          contentKey="global.item-total-count"
                          interpolate={{
                            total: disDokumanDosyaList?.filter(item => item?.ilgiliTabloKayitId === refDisDokuman?.realId).length,
                          }}
                        />
                      </span>
                      <ExcelDownloadButton
                        excelSampleData={AccordionExcelSampleData}
                        grantedAuthorites={['ek_dosya_export']}
                        fetchData={fetchTotalDisDokumanEkDosyaData}
                        className={'download-button'}
                        exportName={translate('tk24AdysApp.krizMedya.dokumanlarDisDokumanEkDosya')}
                      />
                    </Typography>
                    <Table className="accordion-table">
                      <TableHead>
                        <TableRow className="accordion-table-title-row">
                          <TableCell className="accordion-table-title-cell" align="left" onClick={sortAcordion('dosyaAdi')}>
                            <div className="tableHeaderContainer">
                              <Translate contentKey="tk24AdysApp.ekDosya.dosyaAdi">dosyaAdi</Translate>&nbsp;
                              <SortIcon column="dosyaAdi" activeSortColumn={pageObjectEkDosya.sort} />
                            </div>
                          </TableCell>
                          <TableCell className="accordion-table-title-cell" align="left" onClick={sortAcordion('aciklama')}>
                            <div className="tableHeaderContainer">
                              <Translate contentKey="tk24AdysApp.ekDosya.aciklamaTitle">aciklama</Translate>&nbsp;
                              <SortIcon column="aciklama" activeSortColumn={pageObjectEkDosya.sort} />
                            </div>
                          </TableCell>
                          <TableCell className="accordion-table-title-cell" align="left" onClick={sortAcordion('dilId')}>
                            <div className="tableHeaderContainer">
                              <Translate contentKey="tk24AdysApp.ekDosya.fileLanguageTitle">dosya dili</Translate>&nbsp;
                              <SortIcon column="dilId" activeSortColumn={pageObjectEkDosya.sort} />
                            </div>
                          </TableCell>
                          <TableCell className="accordion-table-title-cell" align="left" onClick={sortAcordion('fileExtention')}>
                            <div className="tableHeaderContainer">
                              <Translate contentKey="tk24AdysApp.ekDosya.fileExtentionTitle">fileExtention</Translate>&nbsp;
                              <SortIcon column="fileExtention" activeSortColumn={pageObjectEkDosya.sort} />
                            </div>
                          </TableCell>
                          <TableCell className="accordion-table-title-cell" align="left" onClick={sortAcordion('ekleyenKullanici.adi')}>
                            <div className="tableHeaderContainer">
                              <Translate contentKey="tk24AdysApp.ekDosya.ekleyenKullanici">Ekleyen Kullanıcı</Translate>
                              <SortIcon column="ekleyenKullanici.adi" activeSortColumn={pageObjectEkDosya.sort} />
                            </div>
                          </TableCell>
                          <TableCell className="accordion-table-title-cell" align="left" onClick={sortAcordion('cDate')}>
                            <div className="tableHeaderContainer">
                              <Translate contentKey="tk24AdysApp.ekDosya.cDateTitle">CDate</Translate>
                              <SortIcon column="cDate" activeSortColumn={pageObjectEkDosya.sort} />
                            </div>
                          </TableCell>
                          <TableCell className="accordion-table-title-cell" />
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {disDokumanDosyaList
                          ?.filter(item => item?.ilgiliTabloKayitId === refDisDokuman?.realId)
                          .map(item => (
                            <TableRow key={item?.id} className="accordion-table-content-row">
                              <TableCell className="accordion-table-content-cell" component="th" scope="row">
                                {item?.dosyaAdi}
                              </TableCell>
                              <TableCell className="accordion-table-content-cell" component="th" scope="row">
                                {item?.aciklama}
                              </TableCell>
                              <TableCell className="accordion-table-content-cell" component="th" scope="row">
                                {item?.dil?.adi}
                              </TableCell>
                              <TableCell className="accordion-table-content-cell" component="th" scope="row">
                                {item?.fileExtention}
                              </TableCell>
                              <TableCell className="accordion-table-content-cell" component="th" scope="row">
                                {item.ekleyenKullanici?.adi} {item.ekleyenKullanici?.soyadi}
                              </TableCell>
                              <TableCell className="accordion-table-content-cell" component="th" scope="row">
                                <TextFormat type="date" value={item.cDate} format={APP_DATE_FORMAT} />
                              </TableCell>
                              <TableCell align="right" className="accordion-table-content-cell">
                                <div className="btn-group flex-btn-group-container">
                                  <GrantedButton
                                    grantedAuthorites={['ref_ek_dosya_tipi_read']}
                                    style={{ marginLeft: 2 }}
                                    onClick={() => handleDownloadSavedFile(item)}
                                    className="edit-button"
                                    size="sm"
                                  >
                                    <img src={`${SERVER_URL}/content/images/icon/faArrowDownToLine.svg`} />
                                    <span className="d-none d-md-inline">
                                      <Translate contentKey="entity.action.download" />
                                    </span>
                                  </GrantedButton>
                                </div>
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </>
                ) : (
                  <div>
                    {!ekDosyaLoading && (
                      <div className="alert alert-warning mt-3">
                        <Translate contentKey="tk24AdysApp.ekDosya.home.notFound">No ekDosya found</Translate>
                      </div>
                    )}
                  </div>
                )}
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </>
    );
  }

  return (
    <div>
      {(loading || updating || ekDosyaLoading || ekDosyaDownloadLoading) && <LoadingSpinner />}
      <div className="search-frame">
        <div className="seachiconposition">
          <img className="seachicon" width={24} height={24} src={`${SERVER_URL}/content/images/icon/faSearch.svg`} />
          <Input
            value={search}
            onChange={e => setSearch(e.target.value)}
            placeholder={translate('entity.action.search')}
            className="search-input"
          />
        </div>
        <ExcelDownloadButton
          excelSampleData={ExcelSampleData}
          grantedAuthorites={['ref_dis_dokuman_export']}
          fetchData={fetchTotalKrizMedyaDisDokumanData}
          className={'download-button'}
          exportName={translate('tk24AdysApp.krizMedya.header.disDokuman')}
        />
      </div>
      <TableContainer className="content-table">
        {refDisDokumanList && refDisDokumanList.length > 0 ? (
          <Table aria-label="a dense table">
            <TableHead>
              <TableRow className="accordion-title-row">
                <TableCell style={{ width: 48 }} />
                <TableCell className="accordion-title-cell hand" align="left" onClick={sort(`adi${upperOnlyFirstLetter(localeLanguage)}`)}>
                  <div className="tableHeaderContainer">
                    <Translate contentKey="global.languageGlobal.adi">Adi</Translate>&nbsp;
                    <SortIcon column={`adi${upperOnlyFirstLetter(localeLanguage)}`} activeSortColumn={pageObject.sort} />
                  </div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {refDisDokumanList?.map(refDisDokuman => (
                <DisDokumanTable
                  key={refDisDokuman?.id}
                  refDisDokuman={refDisDokuman}
                  isOpen={openItemId === refDisDokuman?.id}
                  openTable={() => getDisDokumanTable(refDisDokuman)}
                />
              ))}
            </TableBody>
          </Table>
        ) : (
          !loading && (
            <div className="alert alert-warning">
              <Translate contentKey="tk24AdysApp.refDisDokuman.home.notFound">No Ref Dis Dokuman found</Translate>
            </div>
          )
        )}
      </TableContainer>
      {totalItem ? (
        <div className={refDisDokumanList && refDisDokumanList.length > 0 ? '' : 'd-none'}>
          <CustomPagination
            currentPage={pageObject.page + 1}
            currentPageSize={pageObject.size}
            totalItems={totalItem}
            handlePageChange={handlePageChange}
          />
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default KrizMedyaDisDokuman;
