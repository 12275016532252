import React from 'react';
import { Row, Col } from 'reactstrap';
import { Translate } from 'app/component/jhipster';
import { IRefAdtEkip } from 'app/shared/model/ref-adt-ekip.model';

interface RefAdtEkipDetailProp {
  adtEkip: IRefAdtEkip;
}

export const RefAdtEkipDetail: React.FC<RefAdtEkipDetailProp> = ({ adtEkip }) => {
  return (
    <Row className="modal-body-row">
      <Col lg="6" style={{ marginTop: '10px' }}>
        <Row className="modal-content-title">
          <Translate contentKey="global.languageGlobal.adiTr">adiTr</Translate>
        </Row>
        <Row className="modal-content-text">{adtEkip.adiTr ? adtEkip.adiTr : '-'}</Row>
      </Col>
      <Col lg="6" style={{ marginTop: '10px' }}>
        <Row className="modal-content-title">
          <Translate contentKey="global.languageGlobal.adiEn">adiEn</Translate>
        </Row>
        <Row className="modal-content-text">{adtEkip.adiEn ? adtEkip.adiEn : '-'}</Row>
      </Col>
    </Row>
  );
};

export default RefAdtEkipDetail;
