import React, { useState } from 'react';
import { Row, Col } from 'reactstrap';
import { TextFormat, translate, Translate } from 'app/component/jhipster';
import { APP_DATE_FORMAT, SERVER_URL } from 'app/config/constants';
import { IKrizUcus } from 'app/shared/model/kriz-ucus.model';
import DetailModalContentRow from 'app/component/DetailModalContentRow';
import GrantedButton from 'app/component/GrantedButton';
import axios from 'axios';
import { toast } from 'react-toastify';
import { LoadingSpinner } from 'app/component/LoadingSpinner';

interface KrizUcusDetailModalProp {
  krizUcus: IKrizUcus;
}

export const KrizUcusDetailModal: React.FC<KrizUcusDetailModalProp> = ({ krizUcus }) => {
  const ucus = krizUcus?.ucus;
  const [filterLoading, setFilterLoading] = useState(false);

  const handleRaporuClick = async () => {
    setFilterLoading(true);
    try {
      const response = await axios.get(`api/kriz-ucuses/report/${krizUcus.id}`);

      if (response.data.status === true) {
        const base64String = response.data.zipFile;
        const binaryString = atob(base64String);

        const len = binaryString.length;
        const bytes = new Uint8Array(len);
        for (let i = 0; i < len; i++) {
          bytes[i] = binaryString.charCodeAt(i);
        }
        const blob = new Blob([bytes], { type: 'application/pdf' });
        const blobUrl = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = blobUrl;
        link.download = `${ucus.tasiyiciHavayolu + ucus.ucusNumarasi}-${translate('entity.action.seferRaporu')}.pdf`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(blobUrl);
        toast.success(
          <Translate contentKey="entity.rapor.generateReport" interpolate={{ title: translate('entity.action.seferRaporu') }} />
        );
      } else {
        toast.error(
          <Translate contentKey="entity.rapor.error.generateReport" interpolate={{ title: translate('entity.action.seferRaporu') }} />
        );
      }
    } catch (error) {
      console.error('Dosya indirme hatası:', error);
      toast.error(
        <Translate contentKey="entity.rapor.error.generateReport" interpolate={{ title: translate('entity.action.seferRaporu') }} />
      );
    } finally {
      setFilterLoading(false);
    }
  };

  return (
    <>
      {filterLoading && <LoadingSpinner />}
      <div>
        <GrantedButton
          id="create-button"
          grantedAuthorites={['kriz_ucus_read']}
          className="view-button"
          size="sm"
          data-cy="entityCreateButton"
          style={{ marginRight: '8px', float: 'right' }}
          onClick={event => {
            event.stopPropagation();
            handleRaporuClick();
          }}
        >
          <img src={`${SERVER_URL}/content/images/icon/pdf.svg`} />
          <Translate contentKey="entity.action.rapor"></Translate>
        </GrantedButton>
      </div>
      <Row className="modal-detail-body-row">
        {ucus && (
          <>
            <Col md="6" style={{ marginTop: '10px' }}>
              <DetailModalContentRow
                titleKey="tk24AdysApp.krizUcus.tasiyiciHavayolu"
                content={ucus.tasiyiciHavayolu ? ucus.tasiyiciHavayolu : '-'}
              />
              <DetailModalContentRow
                titleKey="tk24AdysApp.krizUcus.gerceklesenKalkisHavalimani"
                content={ucus.gerceklesenKalkisHavalimani ? ucus.gerceklesenKalkisHavalimani.koduIata : '-'}
              />
              <DetailModalContentRow
                titleKey="tk24AdysApp.krizUcus.gerceklesenKalkisZamani"
                content={
                  ucus.gerceklesenKalkisZamani ? (
                    <TextFormat value={ucus.gerceklesenKalkisZamani} type="date" format={APP_DATE_FORMAT} />
                  ) : (
                    '-'
                  )
                }
              />
              <DetailModalContentRow
                titleKey="tk24AdysApp.krizUcus.gerceklesenVarisHavalimani"
                content={ucus.gerceklesenVarisHavalimani ? ucus.gerceklesenVarisHavalimani.koduIata : '-'}
              />
              <DetailModalContentRow
                titleKey="tk24AdysApp.krizUcus.gerceklesenVarisZamani"
                content={
                  ucus.gerceklesenVarisZamani ? (
                    <TextFormat value={ucus.gerceklesenVarisZamani} type="date" format={APP_DATE_FORMAT} />
                  ) : (
                    '-'
                  )
                }
              />
              <DetailModalContentRow titleKey="tk24AdysApp.krizUcus.ucusSuffix" content={ucus.ucusSuffix ? ucus.ucusSuffix : '-'} />
              <DetailModalContentRow titleKey="tk24AdysApp.krizUcus.ucusTuru" content={ucus.ucusTuru ? ucus.ucusTuru : '-'} />
              <DetailModalContentRow
                titleKey="tk24AdysApp.krizUcak.kuyrukKodu"
                content={ucus?.ucak?.kuyrukKodu ? ucus?.ucak?.kuyrukKodu : '-'}
              />
              <DetailModalContentRow titleKey="tk24AdysApp.krizUcus.filoTipi" content={ucus.filoTipi ? ucus.filoTipi : '-'} />
            </Col>

            <Col md="6" style={{ marginTop: '10px' }}>
              <DetailModalContentRow
                titleKey="tk24AdysApp.krizUcus.tahminiKalkisZamani"
                content={
                  ucus.tahminiKalkisZamani ? <TextFormat value={ucus.tahminiKalkisZamani} type="date" format={APP_DATE_FORMAT} /> : '-'
                }
              />
              <DetailModalContentRow
                titleKey="tk24AdysApp.krizUcus.tahminiVarisZamani"
                content={
                  ucus.tahminiVarisZamani ? <TextFormat value={ucus.tahminiVarisZamani} type="date" format={APP_DATE_FORMAT} /> : '-'
                }
              />
              <DetailModalContentRow
                titleKey="tk24AdysApp.krizUcus.tekerKesmeZamani"
                content={ucus.tekerKesmeZamani ? <TextFormat value={ucus.tekerKesmeZamani} type="date" format={APP_DATE_FORMAT} /> : '-'}
              />
              <DetailModalContentRow
                titleKey="tk24AdysApp.krizUcus.tekerVurmaZamani"
                content={ucus.tekerVurmaZamani ? <TextFormat value={ucus.tekerVurmaZamani} type="date" format={APP_DATE_FORMAT} /> : '-'}
              />
              <DetailModalContentRow
                titleKey="tk24AdysApp.krizUcus.tehlikeliMaddeTuru"
                content={ucus.tehlikeliMaddeTuru ? ucus.tehlikeliMaddeTuru : '-'}
              />
              <DetailModalContentRow
                titleKey="tk24AdysApp.krizUcus.codeShareHavayolu"
                content={ucus.codeShareHavayolu ? ucus.codeShareHavayolu : '-'}
              />
              <DetailModalContentRow
                titleKey="tk24AdysApp.krizUcus.planlananKalkisHavalimani"
                content={
                  ucus.planlananKalkisHavalimani?.adi
                    ? ucus.planlananKalkisHavalimani?.adi + ucus.planlananKalkisHavalimani?.koduIata
                    : ucus.planlananKalkisHavalimani?.koduIata
                    ? ucus.planlananKalkisHavalimani?.koduIata
                    : '-'
                }
              />
              <DetailModalContentRow
                titleKey="tk24AdysApp.krizUcus.planlananKalkisZamani"
                content={
                  ucus.planlananKalkisZamani ? <TextFormat value={ucus.planlananKalkisZamani} type="date" format={APP_DATE_FORMAT} /> : '-'
                }
              />
              <DetailModalContentRow
                titleKey="tk24AdysApp.krizUcus.planlananVarisHavalimani"
                content={
                  ucus.planlananVarisHavalimani?.adi
                    ? ucus.planlananVarisHavalimani?.adi + ucus.planlananVarisHavalimani?.koduIata
                    : ucus.planlananVarisHavalimani?.koduIata
                    ? ucus.planlananVarisHavalimani?.koduIata
                    : '-'
                }
              />
            </Col>
          </>
        )}
      </Row>
    </>
  );
};

export default KrizUcusDetailModal;
