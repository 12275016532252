import React from 'react';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { KeyboardArrowDownOutlined } from '@mui/icons-material';
import { Translate, translate } from 'app/component/jhipster';
import { initialCircle } from 'app/shared/style/style';

const circleStyle = {
  width: '40px',
  height: '40px',
  borderRadius: '50%',
  backgroundColor: '#49586A',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: 'white',
  marginRight: '10px',
};

export const NavDropdown = props => (
  <UncontrolledDropdown nav inNavbar id={props.id} data-cy={props['data-cy']}>
    <DropdownToggle nav className="d-flex align-items-center">
      {props.isAuthenticated ? (
        <>
          {props?.imageUser?.thumbnailBase64 ? (
            <div className="hoverable-image-container">
              <img
                style={{
                  ...initialCircle,
                  transition: 'width 0.3s, height 0.3s',
                }}
                src={`data:image/png;base64,${props?.imageUser?.thumbnailBase64}`}
                alt={''}
              />
            </div>
          ) : (
            <div style={circleStyle}>
              <span style={{ fontSize: '14px' }}>
                {props.lastName ? (
                  <>
                    {props.firstName.charAt(0)}
                    {props.lastName.charAt(0)}
                  </>
                ) : (
                  props.firstName.charAt(0)
                )}
              </span>
            </div>
          )}
          <span style={{ fontSize: '14px' }}>{props.lastName ? `${props.firstName} ${props.lastName}` : props.firstName}</span>
        </>
      ) : (
        <>
          <div style={circleStyle}>
            <span style={{ fontSize: '14px' }}>
              {' '}
              <Translate contentKey="global.menu.account.mainHarf"></Translate>
            </span>
          </div>
          <span>
            <Translate contentKey="global.menu.account.main">Hesap</Translate>
          </span>
        </>
      )}

      <KeyboardArrowDownOutlined />
    </DropdownToggle>
    <DropdownMenu right style={props.style}>
      {props.children}
    </DropdownMenu>
  </UncontrolledDropdown>
);
