import { IQueryParams } from '../reducers/reducer.utils';
import { IRefHavalimani } from './ref-havalimani.model';

export interface IOccRehber {
  id?: number;
  realId?: number;
  guid?: string;
  mDate?: string;
  havalimani?: IRefHavalimani;
  dahiliDisi1?: string;
  dahiliDisi2?: string;
  dahiliDisi3?: string;
  dahililer?: string;
  kisi?: string;
  koduIata?: string;
  numara?: string;
  numaraTipi?: string;
  occRehberId?: number;
  unvan?: string;
}
export interface IOccRehberParams extends IQueryParams {
  entity?: IOccRehber;
  havalimaniId?: string;
}

export const defaultValue: Readonly<IOccRehber> = {};
