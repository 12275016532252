import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending } from '@reduxjs/toolkit';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { IQueryParams, createEntitySlice, EntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { IKrizOzetRaporu, defaultValue } from 'app/shared/model/kriz-ozet-raporu.model';

const initialState: EntityState<IKrizOzetRaporu> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

interface KrizOzetRaporParams extends IQueryParams {
  krizId: number | string;
}

const apiUrl = 'api/kriz-ozet-raporus';

// Actions

export const getEntities = createAsyncThunk(
  'krizOzetRaporu/fetch_entity_list',
  async ({ krizId, page, size, sort }: KrizOzetRaporParams) => {
    const filter = `${sort ? `&page=${page}&size=${size}&sort=${sort}&` : '&'}cacheBuster=${new Date().getTime()}`;
    const requestUrl = `${apiUrl}?krizId.equals=${krizId}${filter}`;
    return axios.get<IKrizOzetRaporu[]>(requestUrl);
  }
);

export const getEntity = createAsyncThunk(
  'krizOzetRaporu/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<IKrizOzetRaporu>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const createEntity = createAsyncThunk(
  'krizOzetRaporu/create_entity',
  async (entity: IKrizOzetRaporu, thunkAPI) => {
    const result = await axios.post<IKrizOzetRaporu>(apiUrl, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({ krizId: entity.kriz.id, sort: 'id,desc', page: 0, size: 999 }));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
  'krizOzetRaporu/update_entity',
  async (entity: IKrizOzetRaporu, thunkAPI) => {
    const result = await axios.put<IKrizOzetRaporu>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({ krizId: entity.kriz.id, sort: 'id,desc', page: 0, size: 999 }));

    return result;
  },
  { serializeError: serializeAxiosError }
);

export const partialUpdateEntity = createAsyncThunk(
  'krizOzetRaporu/partial_update_entity',
  async (entity: IKrizOzetRaporu, thunkAPI) => {
    const result = await axios.patch<IKrizOzetRaporu>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const deleteEntity = createAsyncThunk(
  'krizOzetRaporu/delete_entity',
  async (id: string | number, thunkAPI) => {
    const requestUrl = `${apiUrl}/${id}`;
    const result = await axios.delete<IKrizOzetRaporu>(requestUrl);
    return result;
  },
  { serializeError: serializeAxiosError }
);

// slice

export const KrizOzetRaporSlice = createEntitySlice({
  name: 'krizOzetRaporu',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(deleteEntity.fulfilled, state => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = {};
      })
      .addMatcher(isFulfilled(getEntities), (state, action) => {
        return {
          ...state,
          loading: false,
          entities: action.payload.data,
          totalItems: parseInt(action.payload.headers['x-total-count'], 10),
        };
      })
      .addMatcher(isFulfilled(createEntity, updateEntity, partialUpdateEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isPending(getEntities, getEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(createEntity, updateEntity, partialUpdateEntity, deleteEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      });
  },
});

export const { reset } = KrizOzetRaporSlice.actions;

// Reducer
export default KrizOzetRaporSlice.reducer;
