import { IRefAdtFormBolumSoru } from './ref-adt-form-bolum-soru.model';

export interface IRefAdtFormBolumSoruSecenek {
  id?: number;
  realId?: number;
  guid?: string;
  secenek?: string;
  secenekTr?: string;
  secenekEn?: string;
  cDate?: string;
  cUser?: number;
  mDate?: string;
  mUser?: number;
  sortIndex?: number;
  formSoru?: IRefAdtFormBolumSoru;
  aciklamaTr?: string;
  aciklamaEn?: string;
  aciklama?: string;
}

export const defaultValue: Readonly<IRefAdtFormBolumSoruSecenek> = {};
