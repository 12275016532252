import { IReferans } from 'app/shared/model/referans.model';
import { IQueryParams } from '../reducers/reducer.utils';

export interface IRefDuyuruTipi {
  id?: number;
  guid?: string;
  adi?: string;
  adiTr?: string;
  adiEn?: string;
  cDate?: string;
  cUser?: number;
  mDate?: string;
  mUser?: number;
  durum?: IReferans;
}
export interface IRefDuyuruTipiParams extends IQueryParams {
  entity?: IRefDuyuruTipi;
}

export const defaultValue: Readonly<IRefDuyuruTipi> = {};
