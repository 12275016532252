import React, { useEffect, useRef, useState } from 'react';
import L, { LeafletMouseEvent } from 'leaflet';

import 'leaflet/dist/leaflet.css';
import '@geoman-io/leaflet-geoman-free';
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css';
import CustomControl from 'app/modules/viewer/Map/CustomControl';
import './map.scss';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getLayerBBOX, retrieveAddresses } from 'app/modules/viewer/viewer.reducer';

import { Translate, translate } from 'app/component/jhipster';

L.Icon.Default.imagePath = 'content/images/leaflet/';
import 'leaflet.markercluster';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';

import 'leaflet-contextmenu';
import 'leaflet-contextmenu/dist/leaflet.contextmenu.css';
import { createMap } from 'app/modules/viewer/Map/leafletContextMenu';
import { Button, FormGroup, Input, Table } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { SERVER_URL } from 'app/config/constants';

export interface IMapProps {
  handleSelection: (lat, lng) => void;
  initialLocation: any;
}

export const MapComponent = (props: IMapProps) => {
  const mapMaxZoom = 22;
  const addresses = useAppSelector(state => state.viewer.addresses);

  const [focusLayerGroup, setFocusLayerGroup] = useState(new L.LayerGroup());
  const [map, setMap] = useState(null);
  const [queryVal, setQueryVal] = useState(null);
  const [lastMarker, setLastMarker] = useState(null);

  const featureGroupSorgu = useRef(null as L.FeatureGroup);

  const currentMap = useRef(null);

  const dispatch = useAppDispatch();

  const [showResults, setShowResults] = useState(false);

  let addedGeoJsonLayer = null;

  const zoomIn = e => {
    currentMap.current && currentMap.current.zoomIn();
  };

  const selectLocation = e => {
    const customIcon = L.icon({
      iconUrl: SERVER_URL + '/content/images/leaflet/marker-icon.png',
      iconSize: [32, 32],
    });

    if (currentMap.current) {
      const latlng = e.latlng;
      const lat = latlng.lat.toFixed(6);
      const lng = latlng.lng.toFixed(6);
      props.handleSelection(lat, lng);

      currentMap.current.eachLayer(layer => {
        if (layer instanceof L.Marker) {
          currentMap.current.removeLayer(layer);
        }
      });

      const newMarker = L.marker(latlng, { icon: customIcon }).addTo(currentMap.current);
      setLastMarker(newMarker);
    }
  };

  const zoomOut = e => {
    currentMap.current && currentMap.current.zoomOut();
  };

  const contextmenuItems = [
    {
      text: translate('tk24AdysApp.kriz.map.selectLocation'),
      icon: SERVER_URL + '/content/images/leaflet/selectLocation.png',
      callback: selectLocation,
    },
    {
      text: translate('tk24AdysApp.kriz.map.zoomIn'),
      icon: SERVER_URL + '/content/images/leaflet/zoom-in.png',
      callback: zoomIn,
    },
    {
      text: translate('tk24AdysApp.kriz.map.zoomOut'),
      icon: SERVER_URL + '/content/images/leaflet/zoom-out.png',
      callback: zoomOut,
    },
  ];

  const styleMap = featurel => {
    return {
      weight: 5,
      opacity: 1,
      color: '#eb46e7',
      dashArray: '3',
      fillOpacity: 0.7,
      fillColor: '#FFEDA0',
    };
  };

  const onShowLayerClicked = layer => {
    if (layer) {
      dispatch(getLayerBBOX(layer.id));
    }
    // dispatch(showClickedLayer(null));
  };

  function generateHtml4Tooltip(json) {
    let content = '<table><tbody>';
    for (const data of json) {
      content +=
        '<tr><td style="min-width: 100px;"><strong style="text-transform: capitalize">' +
        data.tag +
        '</strong></td><td style="padding: 0 16px;">' +
        data.value +
        '</td></tr>';
    }
    content += '</tbody></table>';

    return content;
  }

  const droneIcon = L.icon({
    iconUrl: SERVER_URL + '/content/images/leaflet/drone_iot.png',
    iconSize: [64, 64],
    className: 'rotatedMarker',
  });

  /**
   * Harita Nesnesi ilk olusuyor
   */
  useEffect(() => {
    const centerLoc = [39.266244, 34.16748];
    if (props.initialLocation != null && props.initialLocation) {
      centerLoc[0] = props.initialLocation.split(',')[0];
      centerLoc[1] = props.initialLocation.split(',')[1];
    }
    const newMap = createMap(mapMaxZoom, centerLoc, contextmenuItems);

    const defaults = {
      maxNativeZoom: 19,
      maxZoom: mapMaxZoom,
      attribution: '',
      type: 'AerialWithLabels',
    };

    const customIcon = L.icon({
      iconUrl: SERVER_URL + '/content/images/leaflet/marker-icon.png',
      iconSize: [32, 32],
    });
    const marker = L.marker({ lat: centerLoc[0], lng: centerLoc[1] }, { icon: customIcon }).addTo(newMap);
    L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
      maxNativeZoom: 19,
      maxZoom: mapMaxZoom,
    }).addTo(newMap);
    currentMap.current = newMap;
    focusLayerGroup.addTo(newMap);
    newMap.addLayer(focusLayerGroup);
    setMap(newMap);
  }, []);

  const olcekDegerleriniHesapla = function (e: LeafletMouseEvent) {
    if (e.type === 'zoomend') {
      document.getElementById('mapZoom').innerHTML = 'Z(' + map.getZoom() + ') ';
    } else {
      const latlng = e.latlng;
      const olcekLat = latlng.lat.toFixed(6);
      const olcekLong = latlng.lng.toFixed(6);
      document.getElementById('mapZoom').innerHTML = 'Z(' + map.getZoom() + ') ';
      document.getElementById('olcekLat').innerHTML = olcekLat + ',' + olcekLong;
    }
  };

  const queryAddress = () => {
    dispatch(retrieveAddresses(queryVal));
    setShowResults(true);
  };
  const geojsonMarkerOptions = {
    radius: 8,
    fillColor: '#ff7800',
    color: '#000',
    weight: 1,
    opacity: 1,
    fillOpacity: 0.8,
  };

  const flyTo = index => {
    setShowResults(false);
    const corner1 = L.latLng(addresses[index].boundingbox[0], addresses[index].boundingbox[2]),
      corner2 = L.latLng(addresses[index].boundingbox[1], addresses[index].boundingbox[3]),
      bounds = L.latLngBounds(corner1, corner2);

    if (addedGeoJsonLayer !== null) map.removeLayer(addedGeoJsonLayer);

    addedGeoJsonLayer = L.geoJSON(addresses[index].geojson, {
      pointToLayer(f, latlng) {
        return L.circleMarker(latlng, geojsonMarkerOptions);
      },
    });
    addedGeoJsonLayer.addTo(map);
    map.flyToBounds(bounds);
  };

  useEffect(() => {
    if (map) {
      const newControl = new CustomControl();

      map.on('mousemove', olcekDegerleriniHesapla);
      map.on('zoomend', olcekDegerleriniHesapla);

      // map.on('pm:create', pmCreateFinished);
      map.pm.setLang('tr');

      featureGroupSorgu.current = new L.FeatureGroup([], { pane: 'featureGroupSorgu' });
      featureGroupSorgu.current.setZIndex(400);
      map.addLayer(featureGroupSorgu.current);
    }
  }, [map]);

  return (
    <div>
      <div id="map" className={'mapContainer' + ' ' + 'infoCursor'} />
      <div className={'olcekPanel'}>
        <div className={'row'} style={{ marginLeft: 5 }}>
          <div id="mapZoom" style={{ fontSize: '12px', display: 'contents' }} className={'col-8'} />
          <div id="olcekLat" style={{ fontSize: '12px', display: 'contents' }} className={'col-8'} />
        </div>
      </div>
      <div className={'queryPanel'}>
        {/* Search Box */}
        <div className={'row'}>
          <div className="seachiconposition mr-2">
            <img className="seachicon" width={24} height={24} src={`${SERVER_URL}/content/images/icon/faSearch.svg`} />
            <Input
              type="text"
              name="query"
              id="query"
              placeholder="Ara.."
              className="search-input"
              onChange={e => setQueryVal(e.target.value)}
            />
          </div>
          <Button
            className="map-create-button"
            id="start-entity"
            data-cy="entitySearchButton"
            onClick={queryAddress}
            style={{ gap: '5px' }}
          >
            <FontAwesomeIcon icon="search" />
            &nbsp;
            <Translate contentKey="entity.action.search">search</Translate>
          </Button>
        </div>

        {/* Search Results */}
        {showResults ? (
          <div className={'row'} style={{ marginLeft: 5 }}>
            {addresses && addresses.length > 0 ? (
              <Table responsive className={'searchResults'}>
                <thead>
                  <tr></tr>
                </thead>
                <tbody>
                  {addresses.map((address, i) => (
                    <tr key={address.osm_id} data-cy="entityTable">
                      <td>{address.name}</td>
                      <td>{address.display_name}</td>
                      <td>
                        <Button color="info" id="start-entity" data-cy="entitySearchButton" onClick={() => flyTo(i)}>
                          <FontAwesomeIcon icon="map" />
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : (
              <div className="alert alert-warning">
                <Translate contentKey="tk24AdysApp.kriz.map.notFound">No Ref Kriz Turus found</Translate>
              </div>
            )}
          </div>
        ) : null}
      </div>
      <div className={'secimText'}>Sağ tık ile seçim yapınız.</div>
    </div>
  );
};

export default MapComponent;
