import { IQueryParams } from '../reducers/reducer.utils';

export interface IRefSaglikDurumu {
  id?: number;
  guid?: string;
  adi?: string;
  adiTr?: string;
  adiEn?: string;
  cDate?: string;
  cUser?: number;
  mDate?: string;
  mUser?: number;
  cssBilgisiBgColor?: string;
  cssBilgisiBorderColor?: string;
  cssBilgisiFontColor?: string;
}

export interface IRefSaglikDurumuParams extends IQueryParams {
  entity?: IRefSaglikDurumu;
}

export const defaultValue: Readonly<IRefSaglikDurumu> = {};
