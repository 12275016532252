import { IEtkilenenKisi } from './etkilenen-kisi.model';
import { IKisi } from './kisi.model';
import { IKrizAdtEkip } from './kriz-adt-ekip.model';
import { IKrizKatilimci } from './kriz-katilimci.model';
import { IReferans } from './referans.model';

export interface IKrizEtkilenenKisiAdtAtama {
  id?: number;
  guid?: string;
  cDate?: string;
  cUser?: number;
  mDate?: string;
  mUser?: number;
  durum?: IReferans;
  aciklama?: string;
  krizId?: number | string;
  krizKatilimci?: IKrizKatilimci;
  kisi?: IKisi;
  adtEkip?: IKrizAdtEkip;
}

export const defaultValue: Readonly<IKrizEtkilenenKisiAdtAtama> = {};
