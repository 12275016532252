import { IReferans } from 'app/shared/model/referans.model';
import { IEkDosya } from 'app/shared/model/ek-dosya.model';
import { IQueryParams } from '../reducers/reducer.utils';

export interface IRefEkDosyaTipi {
  id?: number;
  guid?: string;
  realId?: number;
  adiTr?: string;
  adiEn?: string;
  adi?: string;
  aciklamaTr?: string | null;
  aciklamaEn?: string | null;
  parent?: IRefEkDosyaTipi | null;
  cDate?: string;
  cUser?: number;
  mDate?: string;
  mUser?: number;
  durum?: IReferans;
  ekDosyas?: IEkDosya[] | null;
  code?: string | null;
}

export interface IRefEkDosyaTipiParams extends IQueryParams {
  entity?: IRefEkDosyaTipi;
  hasParent?: boolean;
}

export const defaultValue: Readonly<IRefEkDosyaTipi> = {};
