import React, { useState, useEffect, useRef } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import {
  Button,
  Row,
  Col,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Label,
  Form,
  Input,
  Alert,
  ModalFooter,
} from 'reactstrap';
import { TextFormat, Translate, translate, Storage } from 'app/component/jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import 'bootstrap/dist/css/bootstrap.min.css';
import { getEntities as getReferans } from 'app/entities/referans/referans.reducer';
import { getEntities as getRefKrizTurus } from 'app/entities/ref-kriz-turu/ref-kriz-turu.reducer';
import { getEntities as getRefKrizSeviyes } from 'app/entities/ref-kriz-seviye/ref-kriz-seviye.reducer';
import { getEntity, reset } from './kriz.reducer';
import {
  Box,
  Breadcrumbs,
  Modal as MuiModal,
  Stepper,
  Step,
  StepButton,
  Typography,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  StepLabel,
  Table,
} from '@mui/material';
import { convertDateTimeToServer, convertDateTimeZoneAppDate, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import axios from 'axios';
import { KrizSaveEntityConfirm, deleteEntityConfirm, isNullOrEmpty, renderTimezones } from 'app/shared/util/confirm-utils';
import { toast } from 'react-toastify';
import { FormItem } from 'app/component/FormItem';
import { modalStyle } from 'app/shared/util/style-utils';
import TransferList from 'app/component/TransferList';
import { ReferansEnum } from 'app/constants/ReferansEnum';
import MapComponent from 'app/modules/viewer/Map/map';
import RefUcusSearch from 'app/entities/ref-ucus/ref-ucus-search';
import RefUcakSearch from '../ref-ucak/ref-ucak-search';
import { Link } from 'react-router-dom';
import { ArrowRightAlt, ArrowBack } from '@mui/icons-material';
import { LoadingSpinner } from 'app/component/LoadingSpinner';
import GrantedButton from 'app/component/GrantedButton';
import { APP_DATETIME_FORMAT, SERVER_URL } from 'app/config/constants';
import PersonelSearch from '../personel/personel-search';
import { IReferans } from 'app/shared/model/referans.model';
import { reset as resetPersonelEntities } from 'app/entities/personel/personel.reducer';
import { IRefKatilimci } from 'app/shared/model/ref-katilimci.model';
import { IRefOrganisazyon } from 'app/shared/model/ref-organizasyon.model';
import { localeLanguage, upperOnlyFirstLetter } from 'app/shared/util/util';
import { initialSquare } from 'app/shared/style/style';
import { IPersonel } from 'app/shared/model/personel.model';
import KrizHavalimaniSearch from '../kriz-havalimani/kriz-havalimani-search';
import { getDashboardEntities as getKartEntities } from 'app/entities/kriz/kriz-kart-list.reducer';
import { IKriz } from 'app/shared/model/kriz.model';

interface KatilimcisModel {
  id?: number | string;
  refKatilimci: IRefKatilimci;
  org?: IRefOrganisazyon;
  unvanAdi: any | string;
  sicil: any | string;
  adi: any | string;
  soyadi: any | string;
  eposta: any | string;
  gsmNo: any | string;
  bilgiSonGuncellenmeZamani: any | string;
  bildirimGonderilsinMi?: IReferans;
  katilimciRolu?: any;
  cerideTutulacakMi: any;
  personel?: IPersonel;
  thumbnail?: any;
}

export const KrizUpdate = (props: RouteComponentProps<{ id: string }>) => {
  const dispatch = useAppDispatch();
  const [selectedAcilDurumTuru, setSelectedAcilDurumTuru] = useState(null);
  const [isNew] = useState(!props.match.params || !props.match.params.id);

  const [katilimciForms, setKatilimciForms] = useState(null);
  const [isKatilimciModal, setIsKatilimciModal] = useState(false);
  const [katilimciOnlyForms, setKatilimciOnlyForms] = useState(null);
  const [isEkKatilimciModal, setIsEkKatilimciModal] = useState(false);

  const [kriziBildiren, setKriziBildiren] = useState(null);

  const [alertMessage, setAlertMessage] = useState({
    krizOlusmaZamani: { visible: false, message: '' },
    krizinBildirilmeZamani: { visible: false, message: '' },
  });

  const [selectedPersonel, setSelectedPersonel] = useState(null);
  const [isEkKatilimciBilgiModal, setIsEkKatilimciBilgiModal] = useState(false);
  const [isPersonelSearchModal, setIsPersonelSearchModal] = useState(false);

  const onAlertMessageDismiss = field => {
    setAlertMessage(prev => ({
      ...prev,
      [field]: { visible: false, message: '' },
    }));
  };

  const [formValues, setFormValues] = useState<IKriz>(
    isNew
      ? {
          cDate: displayDefaultDateTime(),
          mDate: displayDefaultDateTime(),
          teamsDavetiKacDkSonra: null,
        }
      : null
  );
  const referans = useAppSelector(state => state.referans.entities);

  const [katilimcis, setKatilimcis] = useState<KatilimcisModel[]>([]);
  const [selectedKrizTuru, setSelectedKrizTuru] = useState(null);
  const [selectedSaatDilimi, setSelectedSaatDilimi] = useState(null);
  const refKrizTurus = useAppSelector(state => state.refKrizTuru.entities);
  const refKrizTuruLoading = useAppSelector(state => state.refKrizTuru.loading);

  const refKrizSeviyes = useAppSelector(state => state.refKrizSeviye.entities);
  const refKrizSeviyeLoading = useAppSelector(state => state.refKrizSeviye.loading);

  const refToplantiTipis = referans.filter(res => res.tipi === 'TOPLANTI_TIPI');
  const refKrizYeris = referans.filter(res => res.tipi === 'KRIZ_YERI');
  const acilDurumTuruList = referans.filter(res => res.tipi === 'ACIL_DURUM_TURU').sort((a, b) => a.sortIndex - b.sortIndex);
  const [krizUcakList, setKrizUcakList] = useState([]);
  const [ucakList, setUcakList] = useState([]);
  const [mergedUcakList, setMergedUcakList] = useState([]);
  const [krizHavalimaniList, setKrizHavalimaniList] = useState([]);

  const updating = useAppSelector(state => state.kriz.updating);
  const updateSuccess = useAppSelector(state => state.kriz.updateSuccess);
  const [krizSeviyesiValue, setKrizSeviyesiValue] = useState(0);
  const [krizToplantiTipiValue, setKrizToplantiTipiValue] = useState(0);
  const [krizToplantiTipiOnline, setKrizToplantiTipiOnline] = useState(false);

  const [bildirimTuruList, setBildirimTuruList] = useState([]);
  const [checkBildirimTuruList, setCheckBildirimTuruList] = useState([]);
  const [selectedKrizYeri, setSelectedKrizYeri] = useState(null);
  const [showUcakModal, setShowUcakModal] = useState(false);
  const [showHavalimaniModal, setShowHavalimaniModal] = useState(false);
  const [showMapModal, setShowMapModal] = useState(false);
  const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);
  const [isSaveSecurityModalOpen, setIsSaveSecurityModalOpen] = useState(false);
  const [modalPromiseResolve, setModalPromiseResolve] = useState(null);

  const [showUcusModal, setShowUcusModal] = useState(false);
  const [errorPassword, setErrorPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);

  const [krizUcusList, setKrizUcusList] = useState([]);
  const [transferredKatilimcis, setTransferredKatilimcis] = useState<KatilimcisModel[]>([]);
  const [savedKatilimcis, setSavedKatilimcis] = useState<KatilimcisModel[]>([]);

  const handleClose = () => {
    props.history.push('/kriz/new' + props.location.search);
  };

  useEffect(() => {
    const sortedBildirimTuruList = referans
      .filter(
        ref =>
          ref.tipi === 'BILDIRIM_TURU' &&
          [
            ReferansEnum.BildirimTuru.Crysis,
            ReferansEnum.BildirimTuru.EPosta,
            ReferansEnum.BildirimTuru.Sms,
            ReferansEnum.BildirimTuru.PushNotification,
          ].includes(ref.id)
      )
      .sort((a, b) => {
        const order = [
          ReferansEnum.BildirimTuru.Crysis,
          ReferansEnum.BildirimTuru.EPosta,
          ReferansEnum.BildirimTuru.Sms,
          ReferansEnum.BildirimTuru.PushNotification,
        ];
        return order.indexOf(a.id) - order.indexOf(b.id);
      });

    setBildirimTuruList(sortedBildirimTuruList);
    setCheckBildirimTuruList(sortedBildirimTuruList);
  }, [referans]);

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(props.match.params.id));
    }

    dispatch(getReferans({ page: 0, size: 999, sort: `adi${upperOnlyFirstLetter(localeLanguage)},asc` }));
    dispatch(getRefKrizTurus({ page: 0, size: 100, sort: `adi${upperOnlyFirstLetter(localeLanguage)},asc` }));
    dispatch(getRefKrizSeviyes({ page: 0, size: 100, sort: `adi${upperOnlyFirstLetter(localeLanguage)},asc` }));

    const refKatilimciUrl = `api/ref-katilimcis?page=0&size=2000&sort=adi,asc`;
    axios.get(refKatilimciUrl).then(response => {
      updatedKatilimciForms(response.data);
    });
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  useEffect(() => {
    const combinedList = [...ucakList, ...krizUcakList];
    setMergedUcakList(combinedList);
  }, [krizUcakList, ucakList]);

  const [code, setCode] = useState('');
  const [selectedSecurityCode, setSelectedSecurityCode] = useState(null);
  const [timer, setTimer] = useState({ minutes: 3, seconds: 0 });
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [showSmsInput, setShowSmsInput] = useState(true);
  const [isSmsCodeVerified, setIsSmsCodeVerified] = useState(false);

  const [loading, setLoading] = useState(false);
  const [otpValues, setOtpValues] = useState(Array(6).fill(''));
  const [activeIndex, setActiveIndex] = useState(0);

  const inputs = useRef([]);

  const handleInputChange = (index, event) => {
    const value = event.target.value;
    const newOtpValues = [...otpValues];
    newOtpValues[index] = value;
    setOtpValues(newOtpValues);

    if (value && index < 5) {
      setActiveIndex(index + 1);
      inputs.current[index + 1].focus();
    }
  };

  const handleKeyDown = (index, event) => {
    if (event.key === 'Backspace') {
      const newOtpValues = [...otpValues];
      if (newOtpValues[index] === '') {
        if (index > 0) {
          setActiveIndex(index - 1);
          inputs.current[index - 1].focus();
        }
      } else {
        newOtpValues[index] = '';
        setOtpValues(newOtpValues);
      }
    }
  };

  const showSaveConfirmModal = () => {
    setIsSaveModalOpen(true);
    return new Promise(resolve => {
      setModalPromiseResolve(() => resolve);
    });
  };

  const handleConfirm = () => {
    if (modalPromiseResolve) {
      modalPromiseResolve(true);
    }
    setIsSaveModalOpen(false);
    setIsSaveSecurityModalOpen(true);
    setShowSmsInput(true);
    setTimer({ minutes: 3, seconds: 0 });
  };

  const handleCancel = () => {
    if (modalPromiseResolve) {
      modalPromiseResolve(false);
    }
    setSelectedSecurityCode(null);
    setOtpValues(Array(6).fill(''));
    setCode(null);
    setIsSaveModalOpen(false);
    setIsSaveSecurityModalOpen(false);
    setShowSmsInput(true);
    setTimer({ minutes: 3, seconds: 0 });
    setIsButtonDisabled(false);
    setErrorPassword(false);
    setErrorMessage(false);
  };

  const handleCodeChange = e => {
    const value = e.target.value;
    setCode(value);
    setIsSmsCodeVerified(true);
  };

  const handleSecurityCodeChange = e => {
    const value = e.target.value;
    setSelectedSecurityCode(value);
    setIsSmsCodeVerified(true);
    setErrorPassword(false);
    setErrorMessage(false);
  };

  const handleGüvenlikKoduDoğrula = () => {
    setShowSmsInput(false);
    setCode(null);
    setOtpValues(Array(6).fill(''));
    setErrorPassword(false);
    setErrorMessage(false);
  };

  const handleSmsDoğrula = () => {
    setShowSmsInput(true);
    setSelectedSecurityCode(null);
    setOtpValues(Array(6).fill(''));
    setErrorPassword(false);
    setErrorMessage(false);
  };

  useEffect(() => {}, [showSmsInput]);

  useEffect(() => {
    const interval = setInterval(() => {
      setTimer(prevTimer => {
        if (prevTimer.minutes === 0 && prevTimer.seconds === 0) {
          setIsButtonDisabled(true);
          clearInterval(interval);
          return prevTimer;
        } else if (prevTimer.seconds === 0) {
          return { minutes: prevTimer.minutes - 1, seconds: 59 };
        } else {
          return { minutes: prevTimer.minutes, seconds: prevTimer.seconds - 1 };
        }
      });
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [timer]);

  const securityAuthenticationModal = () => {
    return (
      <Modal isOpen={isSaveSecurityModalOpen} size="md" style={{ maxWidth: '650px' }} centered>
        {loading ? (
          <LoadingSpinner />
        ) : (
          <Form onSubmit={saveEntity}>
            <ModalHeader toggle={handleCancel} style={{ backgroundColor: '#FBFBFB' }}>
              <Translate contentKey="entity.action.guvenlikDogrulama">GÜVENLİK DOĞRULAMA</Translate>
            </ModalHeader>
            <ModalBody style={{ color: '#9795B5', maxHeight: '300px', height: '250px' }}>
              <div className="ml-2">
                {showSmsInput ? (
                  <>
                    <div className="input-container mb-3">
                      {[...Array(6)].map((_, index) => (
                        <div key={index} className={`input-area ${otpValues[index] ? 'active' : ''}`}>
                          <input
                            ref={el => (inputs.current[index] = el)}
                            type="text"
                            maxLength={1}
                            onChange={e => handleInputChange(index, e)}
                            onKeyDown={e => handleKeyDown(index, e)}
                            className="otp-input"
                          />
                        </div>
                      ))}
                    </div>
                  </>
                ) : (
                  <>
                    <FormItem
                      label={translate('entity.action.guvenlikKoduGiriniz')}
                      name="guvenlikKodu"
                      id="guvenlikKodu"
                      type="text"
                      defaultValue={selectedSecurityCode}
                      onChange={e => handleSecurityCodeChange(e)}
                      validation={{
                        maxLength: { value: 12, message: translate('entity.validation.maxlength', { max: 12 }) },
                      }}
                    />
                  </>
                )}

                {errorPassword && <p className="errorPassword">{translate('entity.validation.yanlisKod')}</p>}
                {errorMessage && <p className="errorPassword">{translate('entity.validation.required')}</p>}
                <div>
                  {showSmsInput ? (
                    <div className="mt-3">
                      {timer.minutes > 0 && (
                        <div className="timerSms">
                          <Translate
                            contentKey={`entity.action.smsKoduSure`}
                            interpolate={{
                              dakika: (
                                <span className="sure-bold">
                                  {timer.minutes} {translate('entity.action.dakika')}
                                </span>
                              ),
                              saniye: (
                                <span className="sure-bold">
                                  {timer.seconds} {translate('entity.action.saniye')}
                                </span>
                              ),
                            }}
                          />
                        </div>
                      )}
                      {timer.minutes === 0 && timer.seconds > 0 && (
                        <div className="timerSms">
                          <Translate contentKey={`entity.action.smsKoduSaniye`} interpolate={{ saniye: <span>{timer.seconds}</span> }} />
                        </div>
                      )}

                      <Button className="kodGonder mt-3" style={{ cursor: 'pointer' }} onClick={kodGonderKriz}>
                        <Translate contentKey="entity.action.kodGonder"></Translate>
                      </Button>
                      <div className="button-dogrulama mt-3" style={{ cursor: 'pointer' }} onClick={handleGüvenlikKoduDoğrula}>
                        <Translate contentKey="entity.action.guvenlikKoduDogrulama">
                          Güvenlik Kodu Doğrulama İle Giriş Sağlamak İstiyorum
                        </Translate>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <div className="button-dogrulama mt-3" style={{ cursor: 'pointer' }} onClick={handleSmsDoğrula}>
                        <Translate contentKey="entity.action.smsKoduDogrulama">Sms Doğrulama İle Giriş Sağlamak İstiyorum</Translate>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </ModalBody>

            <ModalFooter className="modal-footer">
              <Button className="modal-cancel-button m-0" onClick={handleCancel}>
                <Translate contentKey="entity.action.cancel">Cancel</Translate>
              </Button>
              {showSmsInput ? (
                <>
                  <div className="modalDogrula m-0">
                    <Button
                      className="modal-save-button securityButton m-0"
                      data-cy="smsButton"
                      id="smsButton"
                      type="submit"
                      disabled={isButtonDisabled}
                    >
                      {translate('entity.action.smsKodu')}
                    </Button>
                  </div>
                </>
              ) : (
                <>
                  <div className="modalDogrula m-0">
                    <Button className="modal-save-button securityButton  m-0" data-cy="securityButton" id="securityButton" type="submit">
                      {translate('entity.action.guvenlikKodu')}
                    </Button>
                  </div>
                </>
              )}
            </ModalFooter>
          </Form>
        )}
      </Modal>
    );
  };

  const kodGonderKriz = val => {
    val.preventDefault();
    if (katilimcis.length === 0) {
      toast.error(translate(`error.katilimci`));
      return;
    }
    const krizsOtpUrl = `api/kriz-katilimci-bildirims/create-kriz-otp`;
    axios
      .post(krizsOtpUrl)
      .then(response => {
        // toast.success(translate('tk24AdysApp.kriz.created', { param: response.data.id }));
      })
      .catch(function (error) {
        if (error.response.data.message) {
          toast.error(translate(`${error.response.data.message}`));
        } else {
          toast.error(translate(`error.http.${error.response.status}`));
        }
      });
    setTimer({ minutes: 3, seconds: 0 });
    setIsButtonDisabled(false);
    securityAuthenticationModal();
  };

  const securityControlSaveEntity = async val => {
    val.preventDefault();

    if (katilimcis.length === 0) {
      toast.error(translate(`error.katilimci`));
      return;
    }

    const saveSecurity = await showSaveConfirmModal();

    if (saveSecurity) {
      const krizsOtpUrl = `api/kriz-katilimci-bildirims/create-kriz-otp`;
      axios
        .post(krizsOtpUrl)
        .then(response => {
          // toast.success(translate('tk24AdysApp.kriz.created', { param: response.data.id }));
        })
        .catch(function (error) {
          if (error.response.data.message) {
            toast.error(translate(`${error.response.data.message}`));
          } else {
            toast.error(translate(`error.http.${error.response.status}`));
          }
        });
      securityAuthenticationModal();
    }
  };

  const saveEntity = e => {
    e.preventDefault();
    setLoading(true);
    const isOtpValuesEmpty = otpValues.every(value => value === '');

    if (!selectedSecurityCode && isOtpValuesEmpty) {
      setErrorMessage(true);
      setErrorPassword(false);
      setLoading(false);
      return;
    }
    const entitiyFormValues = {
      cDate: convertDateTimeToServer(formValues['cDate']).toISOString(),
      mDate: convertDateTimeToServer(formValues['mDate']).toISOString(),
    };

    const kEntity = {
      ...formValues,
      ...entitiyFormValues,
      acilDurumTuru: referans.find(it => it.id.toString() === selectedAcilDurumTuru.toString()),
      krizDurumu: referans.find(it => it.id === ReferansEnum.KrizDurumu.Aktif),
      krizTuru: refKrizTurus.find(it => it.id.toString() === selectedKrizTuru.toString()),
      krizSeviye: refKrizSeviyes.find(it => it.id.toString() === formValues['krizSeviyeId'].toString()),
      toplantiTipi: refToplantiTipis.find(it => it.id.toString() === formValues['toplantiTipiId'].toString()),
      krizYeriId: refKrizYeris.find(it => it.id.toString() === selectedKrizYeri.toString()),
      krizYeriSaatDilimi: selectedSaatDilimi,
      krizBildirimSms: checkBildirimTuruList.find(item => item.id === ReferansEnum.BildirimTuru.Sms)
        ? ReferansEnum.EvetHayir.Evet
        : ReferansEnum.EvetHayir.Hayir,
      krizBildirimEposta: checkBildirimTuruList.find(item => item.id === ReferansEnum.BildirimTuru.EPosta)
        ? ReferansEnum.EvetHayir.Evet
        : ReferansEnum.EvetHayir.Hayir,
      krizBildirimPush: checkBildirimTuruList.find(item => item.id === ReferansEnum.BildirimTuru.PushNotification)
        ? ReferansEnum.EvetHayir.Evet
        : ReferansEnum.EvetHayir.Hayir,
      krizBildirimIvr: checkBildirimTuruList.find(item => item.id === ReferansEnum.BildirimTuru.Crysis)
        ? ReferansEnum.EvetHayir.Evet
        : ReferansEnum.EvetHayir.Hayir,
    };

    const entity = {
      kriz: kEntity,
      krizKatilimciList: katilimcis,
      krizUcusList,
      krizUcakList,
      krizHavalimaniList,
      superKey: selectedSecurityCode || null,
      onayKodu: code || null,
    };

    const urlMappings = {
      [ReferansEnum.AcilDurumTuru.Kriz]: 'api/krizs/add',
      [ReferansEnum.AcilDurumTuru.Tatbikat]: 'api/krizs/add-tatbikat',
      default: 'api/krizs/add-diger',
    };

    const krizsFormUrl = urlMappings[selectedAcilDurumTuru] || urlMappings.default;
    axios
      .post(krizsFormUrl, entity)
      .then(response => {
        if (response.data.status && response.data.status !== 200) {
          toast.error(translate(`${response.data.message}`));
          setLoading(false);
        } else {
          toast.success(translate('tk24AdysApp.kriz.created', { param: response.data.id }));
          Storage.session.set('kriz', response.data.id);
          dispatch(getKartEntities({ query: `krizDurumuId.equals=${ReferansEnum.KrizDurumu.Aktif}&size=50&sort=krizOlusmaZamani,desc` }));
          props.history.push(`/dashboard/${response.data.id}`);
          setLoading(false);
        }
      })
      .catch(function (error) {
        if (error.response.data.message === 'error.invalidOnayKoduOrSuperKey') {
          setErrorPassword(true);
          setOtpValues(Array(6).fill(''));
          setErrorMessage(false);
        } else {
          toast.error(translate(`error.http.${error.response.status}`));
        }
        setLoading(false);
      });
  };

  useEffect(() => {
    const translatedSteps = [
      translate('tk24AdysApp.kriz.krizBilgileri'),
      translate('tk24AdysApp.kriz.katilimciListesi'),
      translate('entity.action.declare'),
    ];
    setSteps(translatedSteps);
  }, []);

  const [currentStep, setCurrentStep] = useState(1);

  const updatedKatilimciForms = updatedParticipants => {
    const data = [];
    if (updatedParticipants !== null) {
      updatedParticipants.map(katilimci => {
        data.push({
          ...katilimci,
          transferAdi: katilimci.adi + ' ' + (katilimci.soyadi !== null ? ` ${katilimci.soyadi}` : ''),
          transferDetay: katilimci.unvanAdi !== null ? ` (${katilimci.unvanAdi}) ` : '',
        });
      });
    }
    setKatilimciForms(data);
  };

  const updatedKatilimciOnlyForms = updatedParticipants => {
    const data = [];
    if (updatedParticipants !== null) {
      updatedParticipants.map(katilimci => {
        data.push({
          ...katilimci.refKatilimci,
          transferAdi:
            katilimci.refKatilimci.adi + ' ' + (katilimci.refKatilimci.soyadi !== null ? `${katilimci.refKatilimci.soyadi}` : ''),
          transferDetay: katilimci.refKatilimci.unvanAdi !== null ? ` (${katilimci.refKatilimci.unvanAdi}) ` : '',
        });
      });
    }
    setKatilimciOnlyForms(data);
  };

  const confirmKatilimcisDelete = async (krizKatilimci: KatilimcisModel) => {
    const deletedKatilimci = krizKatilimci.refKatilimci ? krizKatilimci.refKatilimci : krizKatilimci;
    const isDel = await deleteEntityConfirm(
      `${deletedKatilimci.adi} ${deletedKatilimci.soyadi} / ${deletedKatilimci.unvanAdi}`,
      'refKrizTuruKatilimci'
    );
    if (isDel) {
      if (krizKatilimci.refKatilimci) {
        const updatedTransferred = transferredKatilimcis.filter(participant => participant.refKatilimci.id !== deletedKatilimci.id);
        const updatedOnlyForms = katilimciOnlyForms.filter(item => item.id !== deletedKatilimci.id);

        setTransferredKatilimcis(updatedTransferred);
        setKatilimciOnlyForms([...updatedOnlyForms]);
        updateKatilimcis(updatedTransferred, savedKatilimcis);
      } else {
        const updatedSaved = savedKatilimcis.filter(participant => participant.sicil !== deletedKatilimci.sicil);
        setSavedKatilimcis(updatedSaved);
        updateKatilimcis(transferredKatilimcis, updatedSaved);
      }
    }
  };

  const addKrizUcusList = (ucus: any) => {
    const krizUcus = [...krizUcusList, ucus];
    setKrizUcusList(krizUcus);

    const updatedUcakList = krizUcus.map(res => res.ucak);
    setUcakList(updatedUcakList);
  };

  const deleteKrizUcusList = (ucus: any) => {
    const updatedKrizUcusList = krizUcusList.filter(item => item.id !== ucus.id);
    setKrizUcusList(updatedKrizUcusList);

    const updatedUcakList = updatedKrizUcusList.map(res => res.ucak);
    setUcakList(updatedUcakList);
  };

  const addKrizUcakList = (ucak: any) => {
    const krizUcak = [...krizUcakList];
    krizUcak.push(ucak);

    setKrizUcakList(krizUcak);
  };

  const deleteKrizUcakList = (ucakId: number) => {
    const updatedDataList = krizUcakList.filter(item => item.id !== ucakId);
    setKrizUcakList(updatedDataList);
  };

  const addKrizHavalimaniList = (havalimani: any) => {
    const krizHavalimani = [...krizHavalimaniList];
    krizHavalimani.push(havalimani);

    setKrizHavalimaniList(krizHavalimani);
  };

  const deleteKrizHavalimaniList = (havalimaniId: number) => {
    const updatedDataList = krizHavalimaniList.filter(item => item.id !== havalimaniId);
    setKrizHavalimaniList(updatedDataList);
  };

  const handleHavalimaniModalClose = () => {
    setShowHavalimaniModal(false);
  };

  const handleMapModalClose = () => {
    setShowMapModal(false);
  };

  const saveTransfer = (right: any) => {
    const katilimci = transferredKatilimcis.filter(k => k.refKatilimci === null);

    const newKatilimcis = right.map(ref => ({
      refKatilimci: ref,
      org: ref.org,
      cerideTutulacakMi: referans.find(it => it.id === ReferansEnum.EvetHayir.Evet),
      unvanAdi: null,
      sicil: null,
      adi: null,
      soyadi: null,
      eposta: null,
      gsmNo: null,
      bilgiSonGuncellenmeZamani: null,
    }));

    const updatedTransferredKatilimcis = [...katilimci, ...newKatilimcis];
    setTransferredKatilimcis(updatedTransferredKatilimcis);
    updateKatilimcis(updatedTransferredKatilimcis, savedKatilimcis);

    setKatilimciOnlyForms(right);
    setIsKatilimciModal(false);
  };

  const havalimaniModal = () => {
    return (
      <KrizHavalimaniSearch
        addKrizHavalimaniList={addKrizHavalimaniList}
        krizHavalimaniList={krizHavalimaniList}
        handleClose={handleHavalimaniModalClose}
        isOpen={showHavalimaniModal}
      />
    );
  };
  const varsayilanKatilimciModal = () => {
    return (
      <MuiModal
        open={isKatilimciModal}
        onClose={() => setIsKatilimciModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle} style={{ minWidth: 1200, height: 'auto' }} className="transferBox">
          {katilimciForms &&
            katilimciForms.length > 0 &&
            (katilimcis !== null ? (
              <TransferList
                formData={katilimciForms}
                onlyForms={katilimciOnlyForms}
                saveTransfer={saveTransfer}
                closeModal={() => setIsKatilimciModal(false)}
                isSort={true}
                title={translate('tk24AdysApp.refKatilimci.home.createDefaultLabel')}
                transactionTitle={translate('global.form.varsayilanKatilimci')}
                showInitials={true}
              />
            ) : (
              <FontAwesomeIcon icon="sync" spin={true} />
            ))}
        </Box>
      </MuiModal>
    );
  };

  const ucakModal = () => {
    return (
      <Modal isOpen={showUcakModal} toggle={() => setShowUcakModal(false)} className="modal-style-lg" centered>
        <ModalHeader toggle={() => setShowUcakModal(false)} style={{ backgroundColor: '#FBFBFB' }}>
          <Translate contentKey="tk24AdysApp.krizDetay.ucakBilgileri">Aircraft Information</Translate>
        </ModalHeader>
        <RefUcakSearch krizUcakList={mergedUcakList} addKrizUcakList={addKrizUcakList} />
      </Modal>
    );
  };

  const ucusModal = () => {
    return (
      <Modal isOpen={showUcusModal} toggle={() => setShowUcusModal(false)} className="modal-style-lg" centered>
        <ModalHeader toggle={() => setShowUcusModal(false)} style={{ background: '#FBFBFB' }}>
          <Translate contentKey="tk24AdysApp.krizDetay.ucusBilgileri">Flight Information</Translate>
        </ModalHeader>
        <RefUcusSearch krizUcusList={krizUcusList} addKrizUcusList={addKrizUcusList} />
      </Modal>
    );
  };

  function krizDatetimeKontrol(krizOlusmaZamani, krizinBildirilmeZamani) {
    const currentTime = new Date(new Date().toISOString());

    if (krizinBildirilmeZamani !== null) {
      if (new Date(krizOlusmaZamani) > new Date(krizinBildirilmeZamani)) {
        return { valid: false, reason: 'olusmaBildirme' };
      } else if (new Date(krizOlusmaZamani) > currentTime) {
        return { valid: false, reason: 'olusmaFuture' };
      } else if (new Date(krizinBildirilmeZamani) > currentTime) {
        return { valid: false, reason: 'bildirmeFuture' };
      } else {
        return { valid: true };
      }
    } else {
      return { valid: true };
    }
  }

  const nextStep = e => {
    e.preventDefault();

    const addKrizyeriKonumValidationResult = krizYeriKonumValidation();

    // Eğer "Add Airport" doğrulama başarısızsa, diğer işlemleri gerçekleştirme
    if (!addKrizyeriKonumValidationResult) {
      return;
    }

    const formDataObject = {};
    for (const field of e.currentTarget.elements) {
      if (field.name) {
        if (field.value !== null && field.value !== '') {
          switch (field.name) {
            case 'krizOlusmaZamani':
            case 'krizinBildirilmeZamani':
              formDataObject[field.name] = convertDateTimeZoneAppDate(field.value);
              break;
            default:
              formDataObject[field.name] = field.value;
              break;
          }
        }
      }
    }

    const datetimeCheck = krizDatetimeKontrol(formDataObject['krizOlusmaZamani'], formDataObject['krizinBildirilmeZamani']);

    const value = { ...formValues, ...formDataObject };

    setFormValues(value);

    if (datetimeCheck.valid) {
      setCurrentStep(currentStep + 1);
      onAlertMessageDismiss('krizOlusmaZamani');
      onAlertMessageDismiss('krizinBildirilmeZamani');
      handleNext();
    } else {
      setAlertMessage(prev => ({
        krizOlusmaZamani: { visible: false, message: '' },
        krizinBildirilmeZamani: { visible: false, message: '' },
      }));

      let errorDateMessage;
      switch (datetimeCheck.reason) {
        case 'olusmaBildirme':
          errorDateMessage = translate('entity.validation.lessThanField', { field: translate('tk24AdysApp.kriz.krizOlusmaZamani') });
          setAlertMessage(prev => ({
            ...prev,
            krizinBildirilmeZamani: { visible: true, message: errorDateMessage },
          }));
          break;
        case 'olusmaFuture':
          errorDateMessage = translate('entity.validation.futureDate', { field: translate('tk24AdysApp.kriz.krizOlusmaZamani') });
          setAlertMessage(prev => ({
            ...prev,
            krizOlusmaZamani: { visible: true, message: errorDateMessage },
          }));
          break;
        case 'bildirmeFuture':
          errorDateMessage = translate('entity.validation.futureDate', { field: translate('tk24AdysApp.kriz.krizinBildirilmeZamani') });
          setAlertMessage(prev => ({
            ...prev,
            krizinBildirilmeZamani: { visible: true, message: errorDateMessage },
          }));
          break;
        default:
          errorDateMessage = translate('entity.validation.error');
          break;
      }
    }
  };

  const acilDurumTuruChange = event => {
    setSelectedAcilDurumTuru(event.target.value);
  };

  useEffect(() => {
    if (!isNullOrEmpty(selectedAcilDurumTuru) && !isNullOrEmpty(selectedKrizTuru)) {
      const refKrizTuruKatilimciUrl = `api/ref-kriz-turu-katilimcis?acilDurumTuruId.equals=${selectedAcilDurumTuru}&krizTuruId.equals=${selectedKrizTuru}&sort=katilimci.adi,asc`;
      axios.get(refKrizTuruKatilimciUrl).then(response => {
        const refKatilimci = [];
        response.data.map(ref => {
          const entitiy: KatilimcisModel = {
            refKatilimci: ref.katilimci,
            org: ref.katilimci.org,
            cerideTutulacakMi: referans.find(it => it.id.toString() === ReferansEnum.EvetHayir.Evet.toString()),
            unvanAdi: null,
            sicil: null,
            adi: null,
            soyadi: null,
            eposta: null,
            gsmNo: null,
            bilgiSonGuncellenmeZamani: null,
          };
          refKatilimci.push(entitiy);
        });

        setTransferredKatilimcis(refKatilimci);
        setKatilimcis(refKatilimci);
        updatedKatilimciOnlyForms(refKatilimci);
      });
    }
  }, [selectedAcilDurumTuru, selectedKrizTuru]);

  const krizTuruChange = event => {
    setKrizToplantiTipiOnline(false);
    setSelectedKrizTuru(event.target.value);
    if (event.target.value) {
      const krizTuru = refKrizTurus.find(item => item.id === event.target.value);
      setKrizSeviyesiValue(krizTuru.defaultKrizSeviye.id);
      setKrizToplantiTipiValue(krizTuru.defaultKrizSeviye.defaultTplntTipi.id);
      if (krizTuru.defaultKrizSeviye.defaultTplntTipi.id === ReferansEnum.ToplantiTipi.OnlineToplanti) {
        setKrizToplantiTipiOnline(true);
      }
    }
  };

  const krizSeviyeIdChange = event => {
    setKrizToplantiTipiOnline(false);
    setKrizSeviyesiValue(event.target.value);
    const toplantiTipi = refKrizSeviyes.find(x => x.id.toString() === event.target.value.toString());
    if (toplantiTipi) {
      setKrizToplantiTipiValue(toplantiTipi.defaultTplntTipi.id);
      if (toplantiTipi.defaultTplntTipi.id === ReferansEnum.ToplantiTipi.OnlineToplanti) {
        setKrizToplantiTipiOnline(true);
      } else {
        setKrizToplantiTipiOnline(false);
      }
    } else {
      setKrizToplantiTipiValue(0);
    }
  };

  const krizToplantiTipiChange = event => {
    setKrizToplantiTipiOnline(false);
    if (event.target.value === ReferansEnum.ToplantiTipi.OnlineToplanti.toString()) {
      setKrizToplantiTipiOnline(true);
    }
    setKrizToplantiTipiValue(event.target.value);
  };

  const saatDilimiChange = event => {
    if (event.target.value) {
      setSelectedSaatDilimi(event.target.value);
    } else {
      setSelectedSaatDilimi(null);
    }
  };

  const handleCloseEkKatilimciModal = () => {
    setIsEkKatilimciModal(false);
    dispatch(resetPersonelEntities());
  };

  const [krizYeriKonumError, setKrizYeriKonumError] = useState({ visible: false, message: '' });
  const krizYeriKonumValidation = () => {
    if (isNullOrEmpty(selectedKrizYeri)) {
      setKrizYeriKonumError({ visible: true, message: translate('tk24AdysApp.kriz.confirm.krizKonum') });
      return false;
    } else if (selectedKrizYeri === ReferansEnum.KrizYeri.Konum) {
      if (formValues['krizYeriKonum'] === null) {
        setKrizYeriKonumError({ visible: true, message: translate('tk24AdysApp.kriz.confirm.krizKonumXY') });
        return false;
      }
    } else if (selectedKrizYeri === ReferansEnum.KrizYeri.Havalimani) {
      if (!krizHavalimaniList || krizHavalimaniList.length === 0) {
        setKrizYeriKonumError({ visible: true, message: translate('tk24AdysApp.kriz.confirm.krizHavalimani') });
        return false;
      }
    }
    setKrizYeriKonumError(null);
    return true;
  };

  const handleKriziBildirenChange = e => {
    setKriziBildiren(e.target.value);
  };

  const firstStep = () => {
    return (
      <Form onSubmit={nextStep}>
        <Row className="krizGenelBilgi">
          <Col md={12} style={{ marginBottom: '15px' }}>
            <span className="kriz-create-title">
              <Translate contentKey="global.languageGlobal.genelBilgiler">Genel Bilgiler</Translate>
            </span>
            {!isNew ? <FormItem type="text" label={translate('global.field.id')} name="id" id="kriz-id" disabled={true} /> : null}
          </Col>
          <Col md={12}>
            <FormItem
              type="select"
              label={translate('tk24AdysApp.kriz.olayTuru')}
              id="kriz-acilDurumTuru"
              name="acilDurumTuruId"
              value={selectedAcilDurumTuru}
              onChange={acilDurumTuruChange}
              validation={{
                required: { value: true, message: translate('entity.validation.required') },
              }}
            >
              <option value="" key="0" />
              {acilDurumTuruList
                ? acilDurumTuruList.map(otherEntity => (
                    <option value={otherEntity.id} key={otherEntity.id}>
                      {otherEntity.adi}
                    </option>
                  ))
                : null}
            </FormItem>
          </Col>
          <Col md={6}>
            <FormItem
              type="datetime"
              label={translate('tk24AdysApp.kriz.krizOlusmaZamani')}
              name="krizOlusmaZamani"
              id="kriz-krizOlusmaZamani"
              value={formValues['krizOlusmaZamani']}
              disableFuture={true}
              disablePast={false}
              validation={{
                required: { value: true, message: translate('entity.validation.required') },
              }}
            />
            {alertMessage.krizOlusmaZamani.visible && (
              <Alert color="danger" isOpen={alertMessage.krizOlusmaZamani.visible} toggle={() => onAlertMessageDismiss('krizOlusmaZamani')}>
                {alertMessage.krizOlusmaZamani.message}
              </Alert>
            )}

            <FormItem
              label={translate('tk24AdysApp.kriz.krizinBildirilmeZamani')}
              id="kriz-krizinBildirilmeZamani"
              name="krizinBildirilmeZamani"
              type="datetime"
              disableFuture={true}
              disablePast={false}
              defaultValue={formValues['krizinBildirilmeZamani']}
              validation={{
                required: { value: true, message: translate('entity.validation.required') },
              }}
            />
            {alertMessage.krizinBildirilmeZamani.visible && (
              <Alert
                color="danger"
                isOpen={alertMessage.krizinBildirilmeZamani.visible}
                toggle={() => onAlertMessageDismiss('krizinBildirilmeZamani')}
              >
                {alertMessage.krizinBildirilmeZamani.message}
              </Alert>
            )}
            <FormItem
              type="text"
              label={translate('tk24AdysApp.kriz.kriziBildiren')}
              name="kriziBildiren"
              id="kriz-kriziBildiren"
              value={kriziBildiren}
              onChange={handleKriziBildirenChange}
              placeholder={translate('entity.action.bildirenKisi')}
            />

            <Button
              color="primary"
              id="show-havalimani-modal"
              className="kriz-create-add-button mt-2 mb-5"
              data-cy="entityShowHavalimaniModal"
              onClick={() => {
                setIsPersonelSearchModal(true);
              }}
              style={{ width: '50%' }}
            >
              <img width={14} height={14} src={`${SERVER_URL}/content/images/icon/faSearch.svg`} />
              <span className="d-none d-md-inline">
                <Translate contentKey="entity.action.personelSearch">Search</Translate>
              </span>
            </Button>
          </Col>

          <Col md={6}>
            <FormItem
              type="select"
              label={translate('tk24AdysApp.kriz.krizTuru')}
              id="kriz-krizTuru"
              name="krizTuruId"
              value={selectedKrizTuru}
              onChange={krizTuruChange}
              validation={{
                required: { value: true, message: translate('entity.validation.required') },
              }}
            >
              <option value="" key="0" />
              {refKrizTurus
                ? refKrizTurus.map(otherEntity => (
                    <option value={otherEntity.id} key={otherEntity.id}>
                      {otherEntity.adi}
                    </option>
                  ))
                : null}
            </FormItem>
            <FormItem
              type="select"
              label={translate('tk24AdysApp.kriz.krizSeviye')}
              id="kriz-krizSeviye"
              name="krizSeviyeId"
              value={krizSeviyesiValue}
              onChange={krizSeviyeIdChange}
              validation={{
                required: { value: true, message: translate('entity.validation.required') },
              }}
            >
              <option value="" key="0" />
              {refKrizSeviyes
                ? refKrizSeviyes.map(otherEntity => (
                    <option value={otherEntity.id} key={otherEntity.id}>
                      {otherEntity.adi}
                    </option>
                  ))
                : null}
            </FormItem>
            <FormItem
              id="kriz-toplantiTipi"
              name="toplantiTipiId"
              label={translate('tk24AdysApp.kriz.toplantiTipi')}
              type="select"
              value={krizToplantiTipiValue}
              onChange={krizToplantiTipiChange}
              validation={{
                required: { value: true, message: translate('entity.validation.required') },
              }}
            >
              <option value="" key="0" />
              {refToplantiTipis
                ? refToplantiTipis.map(otherEntity => (
                    <option value={otherEntity.id} key={otherEntity.id}>
                      {otherEntity.adi}
                    </option>
                  ))
                : null}
            </FormItem>

            {krizToplantiTipiOnline && (
              <>
                <FormItem
                  id="kriz-teamsDavetiKacDkSonra"
                  name="teamsDavetiKacDkSonra"
                  label={translate('tk24AdysApp.kriz.teamsDavetiKacDkSonra')}
                  type="number"
                  defaultValue={formValues['teamsDavetiKacDkSonra']}
                  validation={{
                    required: { value: true, message: translate('entity.validation.required') },
                  }}
                  placeholder={translate('tk24AdysApp.kriz.surePlaceHolder')}
                />
                <UncontrolledTooltip target="teamsDavetiKacDkSonraLabel">
                  <Translate contentKey="tk24AdysApp.kriz.help.teamsDavetiKacDkSonra" />
                </UncontrolledTooltip>
              </>
            )}
          </Col>
        </Row>

        <Row className="krizGenelBilgi">
          <Col md={12} style={{ marginBottom: '15px' }}>
            <span className="kriz-create-title">
              <Translate contentKey="tk24AdysApp.kriz.konumBilgileri">Detay Bilgileri</Translate>
            </span>
          </Col>
          <Col md={12}>
            <FormGroup tag="fieldset" style={{ display: 'flex', margin: '24px 0px', gap: 20 }}>
              {refKrizYeris
                .sort((a, b) => a.sortIndex - b.sortIndex)
                .map(item => (
                  <FormGroup
                    key={item.id}
                    check
                    className="flexKonumKriz"
                    onClick={() => {
                      setSelectedKrizYeri(item.id);
                      setKrizYeriKonumError(null);
                      setKrizHavalimaniList([]);
                      removeSelectLocation();
                    }}
                  >
                    <Input
                      type="radio"
                      id={`radio-krizYeriKonum-${item.id}`}
                      name={`krizYeriKonum-${item.id}`}
                      checked={selectedKrizYeri === item.id}
                      style={{ marginTop: '0px', width: '20px', height: '20px' }}
                      size={18}
                      onChange={() => {
                        setSelectedKrizYeri(item.id);
                        setKrizYeriKonumError(null);
                        setKrizHavalimaniList([]);
                        removeSelectLocation();
                      }}
                    />
                    <Label check for="radio-krizYeriKonum" className="krizKonumChecked">
                      {item.adi}
                    </Label>
                  </FormGroup>
                ))}
            </FormGroup>
            <Col md={12} style={{ padding: '0px' }}>
              {selectedKrizYeri === ReferansEnum.KrizYeri.Havalimani && (
                <>
                  {krizHavalimaniList && krizHavalimaniList.length > 0 && (
                    <TableContainer className="content-table">
                      <Table aria-label="a dense table">
                        <TableHead className="content-table-title-area">
                          <TableRow className="table-head-row">
                            <TableCell className="table-title-cell hand" align="left">
                              <Translate contentKey="tk24AdysApp.refHavalimani.koduIata">koduIata</Translate>&nbsp;
                            </TableCell>
                            <TableCell className="table-title-cell hand" align="left">
                              <Translate contentKey="tk24AdysApp.refHavalimani.koduIcoa">koduIcoa</Translate>&nbsp;
                            </TableCell>
                            <TableCell className="table-title-cell hand" align="left">
                              <Translate contentKey="tk24AdysApp.refHavalimani.ulke">koduIcoa</Translate>&nbsp;
                            </TableCell>
                            <TableCell className="table-title-cell hand" align="left">
                              <Translate contentKey="tk24AdysApp.refHavalimani.sehirTitle">koduIcoa</Translate>&nbsp;
                            </TableCell>
                            <TableCell className="table-title-cell hand" align="left">
                              <Translate contentKey="tk24AdysApp.refHavalimani.adi">koduIcoa</Translate>&nbsp;
                            </TableCell>
                            <TableCell className="table-title-cell" align="right"></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {krizHavalimaniList.map(havalimani => (
                            <TableRow key={havalimani.id} className="table-cell-row">
                              <TableCell className="table-content-cell" component="th" scope="row">
                                {havalimani.koduIata}
                              </TableCell>
                              <TableCell className="table-content-cell" component="th" scope="row">
                                {havalimani.koduIcoa}
                              </TableCell>
                              <TableCell className="table-content-cell" component="th" scope="row">
                                {havalimani.refUlke?.adi}
                              </TableCell>
                              <TableCell className="table-content-cell" component="th" scope="row">
                                {havalimani.sehir}
                              </TableCell>
                              <TableCell className="table-content-cell" component="th" scope="row">
                                {havalimani.adi}
                              </TableCell>
                              <TableCell className="table-content-cell" align="right">
                                <div className="btn-group flex-btn-group-container">
                                  <Button
                                    onClick={() => deleteKrizHavalimaniList(havalimani.id)}
                                    style={{ marginLeft: 10 }}
                                    className="delete-button"
                                    size="sm"
                                    data-cy="entityDeleteButton"
                                  >
                                    <img width={11.2} height={12.8} src={`${SERVER_URL}/content/images/icon/faTrashCan.svg`} />
                                    <span>
                                      <Translate contentKey="entity.action.delete">Delete</Translate>
                                    </span>
                                  </Button>
                                </div>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  )}
                  <GrantedButton
                    grantedAuthorites={['ref_havalimani_read']}
                    color="primary"
                    id="show-havalimani-modal"
                    className="kriz-create-add-button mt-2 mb-5"
                    data-cy="entityShowHavalimaniModal"
                    onClick={() => {
                      setShowHavalimaniModal(true);
                      setKrizYeriKonumError(null);
                    }}
                    style={krizHavalimaniList.length > 0 ? { width: '200px' } : { width: '50%' }}
                  >
                    <img width={14} height={14} src={`${SERVER_URL}/content/images/icon/faPlusBlue.svg`} />
                    <span className="d-none d-md-inline">
                      <Translate contentKey="entity.action.addAirport">Add Airport</Translate>
                    </span>
                  </GrantedButton>
                </>
              )}
            </Col>

            <Col md={6} style={{ padding: '0px' }}>
              {selectedKrizYeri === ReferansEnum.KrizYeri.Konum && (
                <>
                  <Button
                    color="info"
                    size="sm"
                    id="show-map-modal"
                    className="show-map-modal mt-2 mb-5"
                    onClick={() => {
                      setShowMapModal(true);
                      setKrizYeriKonumError(null);
                    }}
                  >
                    <FontAwesomeIcon icon="map-pin" />{' '}
                    <span className="d-none d-md-inline">
                      <Translate contentKey="entity.action.addMap">Open Map</Translate>
                    </span>
                  </Button>
                  {formValues['krizYeriKonum'] ? (
                    <Label check for="radio-krizYeriHavalimani" className="input-label">
                      {translate('tk24AdysApp.kriz.krizYeriKonum')}: <span className="input-text">{formValues['krizYeriKonum']}</span>
                    </Label>
                  ) : null}
                </>
              )}

              {selectedKrizYeri && (
                <>
                  <div className="mt-3"></div>
                  <FormItem
                    label={translate('tk24AdysApp.kriz.krizYeriAciklama')}
                    id="kriz-krizYeriAciklama"
                    name="krizYeriAciklama"
                    defaultValue={formValues['krizYeriAciklama']}
                    type="textarea"
                    validation={{
                      required: {
                        value: selectedKrizYeri === ReferansEnum.KrizYeri.Konum || selectedKrizYeri === ReferansEnum.KrizYeri.KonumYok,
                        message: translate('entity.validation.required'),
                      },
                    }}
                    placeholder={translate('tk24AdysApp.kriz.aciklamaPlaceHolder')}
                  />
                  <UncontrolledTooltip target="krizYeriAciklamaLabel">
                    <Translate contentKey="tk24AdysApp.kriz.help.krizYeriAciklama" />
                  </UncontrolledTooltip>

                  <FormItem
                    type="select"
                    label={translate('tk24AdysApp.kriz.krizYeriSaatDilimi')}
                    id="kriz-krizYeriSaatDilimi"
                    name="krizYeriSaatDilimi"
                    value={selectedSaatDilimi}
                    onChange={saatDilimiChange}
                  >
                    <option value="" key="0" />
                    {renderTimezones()}
                  </FormItem>
                </>
              )}

              {krizYeriKonumError && (
                <Alert color="danger" isOpen={krizYeriKonumError.visible} toggle={onAlertMessageDismiss}>
                  {krizYeriKonumError.message}
                </Alert>
              )}
            </Col>
          </Col>
        </Row>

        <Row className="krizDetayBilgi">
          <Col md={12} style={{ marginBottom: '15px' }}>
            <span className="kriz-create-title">
              <Translate contentKey="global.languageGlobal.detayBilgileri">Detay Bilgileri</Translate>
            </span>
          </Col>
          <Col md={6}>
            <FormGroup style={{ display: 'flex', flexDirection: 'column' }}>
              <span className="input-label mb-2">
                <Translate contentKey="tk24AdysApp.krizDetay.ucusBilgileri">Flight Information</Translate>
              </span>
              <span className="input-text">
                <Translate contentKey="tk24AdysApp.krizDetay.ucusSeciniz">Ucus Seciniz</Translate>
              </span>
              {krizUcusList && krizUcusList.length > 0 ? (
                <div className="table-responsive divDashed my-3">
                  <Table style={{ marginBottom: '0px' }}>
                    <tbody>
                      {krizUcusList.map(ucus => (
                        <tr key={ucus.id} data-cy="entityTable">
                          <td>
                            <span className="havalimaniText">{ucus.ucusNumarasi && ucus.tasiyiciHavayolu + ucus.ucusNumarasi}</span>
                          </td>
                          <td>
                            <span className="havalimaniText">
                              {ucus.planlananKalkisHavalimani && ucus.planlananKalkisHavalimani.koduIata}
                            </span>
                          </td>
                          <td style={{ textAlign: 'left' }}>
                            <ArrowRightAlt className="rightArrowBlue" style={{ color: '#1175D2' }} />
                          </td>
                          <td>
                            <span className="havalimaniText">
                              {ucus.planlananVarisHavalimani && ucus.planlananVarisHavalimani.koduIata}
                            </span>
                          </td>
                          <td>
                            <span className="havalimaniTextBold">{ucus.ucak && ucus.ucak.anaTipi}</span>
                          </td>
                          <td>
                            <span className="havalimaniTextBlue">{ucus.ucak && ucus.ucak.kuyrukKodu}</span>
                          </td>
                          <td style={{ minWidth: '20%' }}>
                            <span className="havalimaniTarihText">
                              {ucus.planlananVarisZamani ? (
                                <TextFormat type="date" value={ucus.planlananVarisZamani} format={APP_DATETIME_FORMAT} />
                              ) : null}
                            </span>
                          </td>
                          <td style={{ textAlign: 'left' }}>
                            <div className="btn-group flex-btn-group-container">
                              <Button
                                type="button"
                                className="deleteUcusUcakButton"
                                size="sm"
                                data-cy="entityDeleteButton"
                                onClick={() => {
                                  deleteKrizUcusList(ucus);
                                }}
                              >
                                <FontAwesomeIcon icon="trash" />
                              </Button>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  <GrantedButton
                    grantedAuthorites={['ref_ucus_read']}
                    color="primary"
                    id="show-ucus-modal"
                    className="kriz-create-add-button mb-2"
                    data-cy="entityShowUcusModal"
                    type="button"
                    onClick={() => {
                      setShowUcusModal(true);
                    }}
                  >
                    <img width={14} height={14} src={`${SERVER_URL}/content/images/icon/faPlusBlue.svg`} />
                    <span className="d-none d-md-inline">
                      <Translate contentKey="entity.action.ucusEkle">Add Ucus</Translate>
                    </span>
                  </GrantedButton>
                </div>
              ) : (
                <GrantedButton
                  grantedAuthorites={['ref_ucus_read']}
                  color="primary"
                  id="show-ucus-modal"
                  className="kriz-create-add-button mt-4"
                  data-cy="entityShowUcusModal"
                  type="button"
                  onClick={() => {
                    setShowUcusModal(true);
                  }}
                >
                  <img width={14} height={14} src={`${SERVER_URL}/content/images/icon/faPlusBlue.svg`} />
                  <span className="d-none d-md-inline">
                    <Translate contentKey="entity.action.ucusEkle">Add Ucus</Translate>
                  </span>
                </GrantedButton>
              )}
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup style={{ display: 'flex', flexDirection: 'column' }}>
              <span className="input-label mb-2">
                <Translate contentKey="tk24AdysApp.krizDetay.ucakBilgileri">Flight Information</Translate>
              </span>
              <span className="input-text">
                <Translate contentKey="tk24AdysApp.krizDetay.ucakSeciniz">Ucus Seciniz</Translate>
              </span>
              {mergedUcakList && mergedUcakList?.length > 0 ? (
                <div className="table-responsive divDashed my-3">
                  <Table style={{ marginBottom: '0px' }}>
                    <tbody>
                      {mergedUcakList.map(ucak => (
                        <tr key={ucak?.id} data-cy="entityTable">
                          <td style={{ minWidth: '60%' }}>
                            <span className="havalimaniText">
                              {ucak?.uretici} {ucak?.detayTipi} {ucak?.govdeTipi}
                            </span>
                          </td>
                          <td style={{ minWidth: '25%' }}>
                            <span className="havalimaniTextBold">{ucak?.kuyrukKodu}</span>
                          </td>
                          <td style={{ minWidth: '15%', textAlign: 'left' }}>
                            <div className="btn-group flex-btn-group-container">
                              <Button
                                type="button"
                                color="danger"
                                size="sm"
                                className="deleteUcusUcakButton"
                                data-cy="entityDeleteButton"
                                onClick={() => deleteKrizUcakList(ucak?.id)}
                                hidden={ucakList.includes(ucak)}
                              >
                                <FontAwesomeIcon icon="trash" />
                              </Button>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  <GrantedButton
                    grantedAuthorites={['ref_ucak_read']}
                    color="primary"
                    id="show-ucak-modal"
                    className="kriz-create-add-button mb-2"
                    data-cy="entityShowUcakModal"
                    onClick={() => {
                      setShowUcakModal(true);
                    }}
                  >
                    <img width={14} height={14} src={`${SERVER_URL}/content/images/icon/faPlusBlue.svg`} />
                    <span className="d-none d-md-inline">
                      <Translate contentKey="entity.action.ucakEkle">Add Ucak</Translate>
                    </span>
                  </GrantedButton>
                </div>
              ) : (
                <GrantedButton
                  grantedAuthorites={['ref_ucak_read']}
                  color="primary"
                  id="show-ucak-modal"
                  className="kriz-create-add-button mt-4"
                  data-cy="entityShowUcakModal"
                  onClick={() => {
                    setShowUcakModal(true);
                  }}
                >
                  <img width={14} height={14} src={`${SERVER_URL}/content/images/icon/faPlusBlue.svg`} />
                  <span className="d-none d-md-inline">
                    <Translate contentKey="entity.action.ucakEkle">Add Ucak</Translate>
                  </span>
                </GrantedButton>
              )}
            </FormGroup>
          </Col>
        </Row>
        <Row className="justify-content-center aciklamaSend">
          <Col md={12}>
            <FormItem
              label={translate('tk24AdysApp.kriz.aciklama')}
              id="kriz-aciklama"
              name="aciklama"
              type="textarea"
              defaultValue={formValues['aciklama']}
              placeholder={translate('entity.action.aciklamaPlaceholder')}
            />
          </Col>
          <Col md={12} style={{ textAlign: 'right', display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
            <Button id="next-entity" data-cy="entityNextButton" className="saveKrizUpdateButton" type="submit" disabled={updating}>
              <Translate contentKey="entity.action.next">Next</Translate>
            </Button>
          </Col>
        </Row>
      </Form>
    );
  };

  const handleChangeBildirimTuru = bildirim => {
    const isCheck = checkBildirimTuruList.some(item => JSON.stringify(item) === JSON.stringify(bildirim));

    if (isCheck) {
      const updatedDataList = checkBildirimTuruList.filter(item => item.id !== bildirim.id);
      setCheckBildirimTuruList(updatedDataList);
    } else {
      const bildirimList = [...checkBildirimTuruList];
      bildirimList.push(bildirim);

      setCheckBildirimTuruList(bildirimList);
    }
  };

  const secondStep = () => {
    return (
      <>
        <div>
          <Form onSubmit={securityControlSaveEntity}>
            <Row style={{ marginBottom: '1rem' }}>
              <Col md="12">
                <div className="krizChecked">
                  <h4>
                    <Translate contentKey="tk24AdysApp.kriz.katilimciBildirimTipi">Katilimci Bildirim Tipi Seçiniz</Translate>
                  </h4>
                  <div style={{ display: 'flex', gap: '40px' }}>
                    {bildirimTuruList &&
                      bildirimTuruList.map(bildirim => (
                        <FormGroup key={bildirim.id} check style={{ display: 'flex' }}>
                          <Input
                            checked={checkBildirimTuruList.some(item => JSON.stringify(item) === JSON.stringify(bildirim))}
                            onChange={() => handleChangeBildirimTuru(bildirim)}
                            name={bildirim.id}
                            id={bildirim.id}
                            type="checkbox"
                            style={{ height: '18px', width: '18px', marginTop: '0px' }}
                          />
                          <Label
                            check
                            onClick={() => handleChangeBildirimTuru(bildirim)}
                            style={{
                              fontSize: '15px',
                              pointerEvents: 'auto',
                              marginLeft: '5px',
                            }}
                          >
                            {bildirim.adi}
                          </Label>
                        </FormGroup>
                      ))}
                  </div>
                </div>
              </Col>
            </Row>

            <div className="krizChecked">
              <div className="d-flex justify-end krizCreateButton">
                <Button
                  style={{ marginLeft: 15 }}
                  onClick={() => setIsEkKatilimciModal(true)}
                  className="accordion-create-button"
                  size="sm"
                  data-cy="entityCreateButton"
                >
                  <img width={14} height={14} src={`${SERVER_URL}/content/images/icon/faPlusBlue.svg`} />
                  <span className="d-none d-md-inline">
                    <Translate contentKey="tk24AdysApp.refKatilimci.home.createExtraLabel" />
                  </span>
                </Button>
                <Button
                  style={{ marginLeft: 15 }}
                  onClick={() => setIsKatilimciModal(true)}
                  className="create-button"
                  size="sm"
                  data-cy="entityCreateButton"
                >
                  <img width={14} height={14} src={`${SERVER_URL}/content/images/icon/faPlus.svg`} />
                  <span className="d-none d-md-inline">
                    <Translate contentKey="tk24AdysApp.refKatilimci.home.createDefaultLabel">Create a new Default Participant</Translate>
                  </span>
                </Button>
              </div>
              <TableContainer className="content-table">
                {katilimcis && katilimcis.length > 0 ? (
                  <Table aria-label="a dense table">
                    <TableHead className="content-table-title-area">
                      <TableRow className="table-head-row">
                        <TableCell className="table-title-cell-kriz-create-katilimci" align="left">
                          &nbsp;
                        </TableCell>
                        <TableCell align="left" className="table-title-cell-kriz-create-katilimci">
                          <Translate contentKey="tk24AdysApp.krizKatilimci.sicilTitle">Sicil</Translate>
                        </TableCell>
                        <TableCell align="left" className="table-title-cell-kriz-create-katilimci">
                          <Translate contentKey="tk24AdysApp.krizKatilimci.adi">Adi</Translate>
                        </TableCell>
                        <TableCell align="left" className="table-title-cell-kriz-create-katilimci">
                          <Translate contentKey="tk24AdysApp.krizKatilimci.soyadi">Soyadi</Translate>
                        </TableCell>
                        <TableCell align="left" className="table-title-cell-kriz-create-katilimci">
                          <Translate contentKey="tk24AdysApp.krizKatilimci.unvanAdiTitle">Unvan</Translate>
                        </TableCell>
                        <TableCell align="left" className="table-title-cell-kriz-create-katilimci">
                          <Translate contentKey="tk24AdysApp.krizKatilimci.katilimciRolu.adiTitle">Katilimci Rolü</Translate>
                        </TableCell>

                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {katilimcis.map(krizKatilimci => (
                        <TableRow key={krizKatilimci.id} className="table-cell-row">
                          <TableCell className="table-content-cell" component="th" scope="row">
                            {krizKatilimci.refKatilimci ? (
                              krizKatilimci?.refKatilimci?.personel?.thumbnail ? (
                                <div className="hoverable-image-container">
                                  <img
                                    className="hoverable-image"
                                    style={{
                                      ...initialSquare,
                                      transition: 'width 0.3s, height 0.3s',
                                    }}
                                    src={`data:${krizKatilimci?.refKatilimci?.personel.thumbnail.contentType};base64,${krizKatilimci?.refKatilimci?.personel.thumbnail.base64}`}
                                    alt={''}
                                  />
                                </div>
                              ) : (
                                <div style={initialSquare}>
                                  <span style={{ fontSize: '14px' }}>
                                    {krizKatilimci?.refKatilimci?.soyadi ? (
                                      <>
                                        {krizKatilimci?.refKatilimci?.adi.charAt(0)}
                                        {krizKatilimci?.refKatilimci?.soyadi.charAt(0)}
                                      </>
                                    ) : (
                                      krizKatilimci?.refKatilimci?.adi.charAt(0)
                                    )}
                                  </span>
                                </div>
                              )
                            ) : krizKatilimci?.thumbnail ? (
                              <div className="hoverable-image-container">
                                <img
                                  className="hoverable-image"
                                  style={{
                                    ...initialSquare,
                                    transition: 'width 0.3s, height 0.3s',
                                  }}
                                  src={`data:${krizKatilimci?.thumbnail.contentType};base64,${krizKatilimci?.thumbnail.base64}`}
                                  alt={''}
                                />
                              </div>
                            ) : (
                              <div style={initialSquare}>
                                <span style={{ fontSize: '14px' }}>
                                  {krizKatilimci?.soyadi ? (
                                    <>
                                      {krizKatilimci?.adi.charAt(0)}
                                      {krizKatilimci?.soyadi.charAt(0)}
                                    </>
                                  ) : (
                                    krizKatilimci?.adi.charAt(0)
                                  )}
                                </span>
                              </div>
                            )}
                          </TableCell>
                          <TableCell component="th" scope="row" className="table-content-cell">
                            <span className="sicilKrizCreate">
                              {krizKatilimci.refKatilimci ? krizKatilimci.refKatilimci.sicil : krizKatilimci.sicil}
                            </span>
                          </TableCell>
                          <TableCell component="th" scope="row" className="table-content-cell">
                            {krizKatilimci.refKatilimci ? krizKatilimci.refKatilimci.adi : krizKatilimci.adi}
                          </TableCell>
                          <TableCell component="th" scope="row" className="table-content-cell">
                            {krizKatilimci.refKatilimci ? krizKatilimci.refKatilimci.soyadi : krizKatilimci.soyadi}
                          </TableCell>
                          <TableCell component="th" scope="row" className="table-content-cell">
                            {krizKatilimci.refKatilimci ? krizKatilimci.refKatilimci.unvanAdi : krizKatilimci.unvanAdi}
                          </TableCell>
                          <TableCell component="th" scope="row" className="table-content-cell">
                            {krizKatilimci.refKatilimci ? krizKatilimci.refKatilimci?.katilimciRolu?.adi : krizKatilimci.katilimciRolu?.adi}
                          </TableCell>

                          <TableCell align="right" className="table-content-cell">
                            <div className="btn-group flex-btn-group-container">
                              <Button
                                onClick={() => confirmKatilimcisDelete(krizKatilimci)}
                                style={{ marginLeft: 10 }}
                                className="delete-button"
                                size="sm"
                                data-cy="entityDeleteButton"
                              >
                                <img width={11.2} height={12.8} src={`${SERVER_URL}/content/images/icon/faTrashCan.svg`} />
                                <span>
                                  <Translate contentKey="entity.action.delete">Delete</Translate>
                                </span>
                              </Button>
                            </div>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                ) : (
                  !updating && (
                    <div className="alert alert-warning">
                      <Translate contentKey="tk24AdysApp.krizKatilimci.home.notFound">No Kriz Katılımcı found</Translate>
                    </div>
                  )
                )}
              </TableContainer>
              {katilimcis.length > 0 && (
                <h4 className="toplamKrizKatilimci">
                  <Translate contentKey="global.item-total-count" interpolate={{ total: katilimcis.length }} />
                </h4>
              )}
              <Row className="justify-content-center mt-3">
                <Col md={12} style={{ textAlign: 'right', display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
                  <Button
                    id="cancel-save"
                    data-cy="entityCreateCancelButton"
                    className="cancel-button"
                    onClick={handleBack}
                    replace
                    color="info"
                  >
                    <span className="d-none d-md-inline">
                      <Translate contentKey="entity.action.back">Back</Translate>
                    </span>
                  </Button>
                  &nbsp;
                  <Button
                    color="primary"
                    className="saveKrizUpdateButton"
                    id="start-entity"
                    data-cy="entityStartButton"
                    type="submit"
                    disabled={updating}
                  >
                    &nbsp;
                    <Translate contentKey="entity.action.declare">Save</Translate>
                  </Button>
                </Col>
              </Row>
            </div>
          </Form>
        </div>
      </>
    );
  };

  const handleSelectLocation = (lat, lng) => {
    const value = { ...formValues, krizYeriKonum: lat + ',' + lng, krizYeriKonumX: lat, krizYeriKonumY: lng };
    setFormValues(value);
  };

  const removeSelectLocation = () => {
    const value = { ...formValues, krizYeriKonum: null, krizYeriKonumX: null, krizYeriKonumY: null };
    setFormValues(value);
  };

  const mapModal = () => {
    return (
      <Modal isOpen={showMapModal} toggle={handleMapModalClose} style={{ maxWidth: '1100px' }}>
        <ModalHeader toggle={handleMapModalClose}>
          <Translate contentKey="tk24AdysApp.krizDetay.krizYeriKonum">Crisis Location</Translate>
        </ModalHeader>
        <ModalBody style={{ height: '750px', width: '100%', position: 'relative' }}>
          <MapComponent handleSelection={handleSelectLocation} initialLocation={formValues['krizYeriKonum']}></MapComponent>
        </ModalBody>
      </Modal>
    );
  };

  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState<{
    [k: number]: boolean;
  }>({});
  const [steps, setSteps] = useState([]);

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleStep = (step: number) => () => {
    setActiveStep(step);
  };

  const saveEkKatilimciIslem = personel => {
    if (katilimcis.some(item => item?.sicil?.toString() === personel?.sicil?.toString())) {
      toast.error(translate(`error.mevcutKayit`));
    } else {
      setSelectedPersonel(personel);
      setIsEkKatilimciBilgiModal(true);
    }
  };

  const EvetHayirReferansOption = () => {
    return (
      <>
        <option value="" key="0" />
        {referans
          ? referans
              .filter(item => item.id === ReferansEnum.EvetHayir.Evet || item.id === ReferansEnum.EvetHayir.Hayir)
              .map(otherEntity => (
                <option value={otherEntity.id} key={otherEntity.id}>
                  {otherEntity.adi}
                </option>
              ))
          : null}
      </>
    );
  };

  const handleCloseEkKatilimciBilgi = () => {
    setIsEkKatilimciBilgiModal(false);
    setSelectedPersonel(null);
  };

  const saveEntityEkPersonel = e => {
    e.preventDefault();
    const formDataObject = {};
    for (const field of e.target.elements) {
      if (field.name) {
        formDataObject[field.name] = field.value;
      }
    }
    const data: KatilimcisModel = {
      refKatilimci: null,
      cerideTutulacakMi: referans.find(it => it.id.toString() === formDataObject['cerideTutulacakMiId'].toString()),
      bildirimGonderilsinMi: referans.find(it => it.id.toString() === formDataObject['bildirimGonderilsinMiId'].toString()),
      org: selectedPersonel.org,
      unvanAdi: selectedPersonel.unvanAdi,
      sicil: selectedPersonel.sicil,
      adi: selectedPersonel.adi,
      soyadi: selectedPersonel.soyadi,
      eposta: selectedPersonel.eposta,
      gsmNo: selectedPersonel.telefonNo,
      katilimciRolu: referans.find(it => it.id === ReferansEnum.KatilimciRolu.AdymEkKatilimci),
      bilgiSonGuncellenmeZamani: null,
      thumbnail: selectedPersonel.thumbnail,
    };
    const updatedSavedKatilimcis = [...savedKatilimcis, data];
    updatedSavedKatilimcis.sort((a, b) => {
      if (a.adi && b.adi) {
        return a.adi.localeCompare(b.adi, 'tr');
      }
      return 0;
    });
    setSavedKatilimcis(updatedSavedKatilimcis);
    updateKatilimcis(transferredKatilimcis, updatedSavedKatilimcis);
    handleCloseEkKatilimciBilgi();
  };

  const updateKatilimcis = (transferred, saved) => {
    setKatilimcis([...transferred, ...saved]);
  };

  const ekKatilimciBilgiModal = () => {
    return (
      <Modal isOpen={isEkKatilimciBilgiModal} toggle={handleCloseEkKatilimciBilgi} size="lg" className="modal-dialog-centered">
        <ModalHeader data-cy="refAdtFormUpdateDialogHeading" toggle={handleCloseEkKatilimciBilgi}>
          {selectedPersonel?.adi} {selectedPersonel?.soyadi} / {selectedPersonel?.unvanAdi}
        </ModalHeader>
        <Form onSubmit={saveEntityEkPersonel}>
          <ModalBody>
            <FormItem
              type="select"
              label={translate('tk24AdysApp.krizKatilimci.cerideTutulacakMi')}
              id="krizKatilimci-cerideTutulacakMi"
              name="cerideTutulacakMiId"
              validation={{
                required: { value: true, message: translate('entity.validation.required') },
              }}
            >
              {EvetHayirReferansOption()}
            </FormItem>
            <FormItem
              type="select"
              label={translate('tk24AdysApp.krizKatilimci.bildirimGonderilsinMi')}
              id="krizKatilimci-bildirimGonderilsinMi"
              name="bildirimGonderilsinMiId"
              validation={{
                required: { value: true, message: translate('entity.validation.required') },
              }}
            >
              {EvetHayirReferansOption()}
            </FormItem>
          </ModalBody>
          <ModalFooter className="modal-footer">
            <Button className="cancel-button" onClick={handleCloseEkKatilimciBilgi}>
              <Translate contentKey="entity.action.cancel">Cancel</Translate>
            </Button>
            &nbsp;
            <Button className="save-button" id="jhi-confirm-delete-refAdtForm" type="submit" data-cy="entityConfirmButton">
              <Translate contentKey="entity.action.save">Kaydet</Translate>
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    );
  };

  const searchPersonel = (personel: IPersonel) => {
    setKriziBildiren(`${personel.adi ?? ''} ${personel.soyadi ?? ''} ${personel.unvanAdi ? ` / ${personel.unvanAdi}` : ''}`);
    setIsPersonelSearchModal(false);
  };

  return (
    <>
      {(refKrizSeviyeLoading || refKrizTuruLoading) && <LoadingSpinner />}
      {isPersonelSearchModal && (
        <PersonelSearch
          isPersonelModal={isPersonelSearchModal}
          titleContentKey="entity.action.personelSearch"
          handleClosePersonelForm={() => {
            setIsPersonelSearchModal(false);
          }}
          saveEntityPersonel={searchPersonel}
          havalimaniFilter={true}
        />
      )}
      <div>
        <Breadcrumbs aria-label="breadcrumb">
          <a className="link-a-text" href={`${SERVER_URL}`}>
            <img width={13} height={13} src={`${SERVER_URL}/content/images/icon/faHouse.svg`} />
          </a>
          <Typography>
            <Translate contentKey="tk24AdysApp.kriz.home.createLabel">Kriz Oluştur</Translate>
          </Typography>
        </Breadcrumbs>
      </div>
      <div className="d-flex align-items-center mt-3 mb-3">
        <>
          <Button color="primary" className="goBackBtn goBackInfoTable" tag={Link} to={`/`}>
            <ArrowBack />
          </Button>
          <h4 className="contentKeyTitle">
            <Translate contentKey="tk24AdysApp.kriz.home.createLabel">Kriz Oluştur</Translate>
          </h4>
        </>
      </div>
      <Row>
        <Col md="12">
          <div className="kriz-create-step-area">
            <Stepper nonLinear activeStep={activeStep} className="mb-4">
              {steps.map((label, index) => (
                <Step key={label} completed={completed[index]} className="stepKrizCreate">
                  <StepButton color="inherit">{label}</StepButton>
                </Step>
              ))}
            </Stepper>
          </div>
          <div>
            {activeStep === 0 && (
              <Step key={steps[activeStep]} completed={completed[activeStep]}>
                <StepLabel>{firstStep()}</StepLabel>
              </Step>
            )}

            {activeStep === 1 && (
              <Step key={steps[activeStep]} completed={completed[activeStep]}>
                <StepLabel>{secondStep()}</StepLabel>
              </Step>
            )}
          </div>
        </Col>
      </Row>
      {ucakModal()}
      {mapModal()}
      {ucusModal()}
      {havalimaniModal()}

      {isEkKatilimciModal && (
        <PersonelSearch
          isPersonelModal={isEkKatilimciModal}
          titleContentKey="tk24AdysApp.refKatilimci.home.createExtraLabel"
          handleClosePersonelForm={handleCloseEkKatilimciModal}
          saveEntityPersonel={saveEkKatilimciIslem}
          dataToCompare={katilimcis}
          havalimaniFilter={false}
        />
      )}
      {selectedPersonel && isEkKatilimciBilgiModal && ekKatilimciBilgiModal()}

      {varsayilanKatilimciModal()}
      <KrizSaveEntityConfirm isOpen={isSaveModalOpen} onConfirm={handleConfirm} onCancel={handleCancel} />
      {securityAuthenticationModal()}
    </>
  );
};

export default KrizUpdate;
