import React from 'react';
import { hasAnyAuthority } from 'app/config/store';

import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';

import classnames from 'classnames';

const GrantedNavTab = ({ headerClassName, tabs }) => {
  const [value, setValue] = React.useState(0);

  const handleChange = newValue => {
    setValue(newValue);
  };

  const filteredTabs = tabs.filter(tab => hasAnyAuthority([tab.authority]));

  return (
    <>
      <Nav tabs className={headerClassName}>
        {filteredTabs.map((tab, index) => (
          <NavItem key={index}>
            <NavLink
              className={classnames({ active: value === index })}
              onClick={() => {
                handleChange(index);
              }}
            >
              {tab.label}
            </NavLink>
          </NavItem>
        ))}
      </Nav>
      <TabContent activeTab={value}>
        {filteredTabs.map((tab, index) => (
          <TabPane key={index} tabId={index}>
            {value === index && tab.component}
          </TabPane>
        ))}
      </TabContent>
    </>
  );
};

export default GrantedNavTab;
