import React from 'react';
import { Translate, translate } from 'app/component/jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import confirm from 'reactstrap-confirm';
import { Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import './style.scss';
import { SERVER_URL } from 'app/config/constants';
import { useAppSelector } from 'app/config/store';

export const CustomSaveDynamicEntityConfirm = ({ totalTask, entityName, infoText, isOpen, onConfirm, onCancel }) => {
  return (
    <Modal isOpen={isOpen} toggle={onCancel} centered>
      <ModalHeader toggle={onCancel}>
        <Translate contentKey="entity.confirm.title">Confirm operation</Translate>
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col sm={1} style={{ display: 'flex', alignItems: 'center', margin: 'auto' }}>
            <img src={`${SERVER_URL}/content/images/icon/Warning.svg`} style={{ height: '30px' }} />
          </Col>
          <Col sm={11} style={{ display: 'flex', alignItems: 'center', margin: 'auto' }}>
            {totalTask ? translate(`tk24AdysApp.${entityName}.confirm.totalTaskMessage`, { param: totalTask }) : ''}
            {translate(`tk24AdysApp.${entityName}.confirm.message`, { param: infoText })}
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button className="modal-cancel-button" onClick={onCancel}>
          <Translate contentKey="entity.action.cancel">Cancel</Translate>
        </Button>
        <Button className="modal-save-button" onClick={onConfirm}>
          <Translate contentKey="entity.action.save">Kaydet</Translate>
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export const CustomDeleteEntityConfirm = ({ entity, entityName, isOpen, onConfirm, onCancel, titleName = null }) => {
  return (
    <Modal isOpen={isOpen} toggle={onCancel} centered>
      <ModalHeader toggle={onCancel}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img src={`${SERVER_URL}/content/images/icon/WarningRed.svg`} style={{ height: '52px' }} />
          <Translate contentKey="entity.delete.title" />
        </div>
      </ModalHeader>
      <ModalBody style={{ display: 'flex' }}>
        <Row style={{ margin: '0px' }}>
          <Col sm={12} style={{ padding: '0px' }}>
            {titleName ? (
              <p className="modal-title-info">
                <span title={titleName}>{titleName}</span>
              </p>
            ) : (
              <>
                {entity?.adiTr || entity?.adiEn ? (
                  <p className="modal-title-info">
                    <span title={entity?.adiTr}>
                      {entity?.adiTr?.slice(0, 50)}
                      {entity?.adiTr?.length > 50 ? '...' : ''}
                    </span>
                    {' - '}
                    <span title={entity?.adiEn}>
                      {entity?.adiEn?.slice(0, 50)}
                      {entity?.adiEn?.length > 50 ? '...' : ''}
                    </span>
                  </p>
                ) : (
                  entity?.adi && (
                    <p className="modal-title-info">
                      <span title={entity?.adi}>{entity.adi}</span>
                    </p>
                  )
                )}
              </>
            )}

            {entity?.dosyaAdi && (
              <p className="modal-title-info">
                <span title={entity?.dosyaAdi}>
                  {entity?.dosyaAdi?.slice(0, 50)}
                  {entity?.dosyaAdi?.length > 50 ? '...' : ''}
                </span>
              </p>
            )}
          </Col>
          <Col sm={12} style={{ display: 'flex', alignItems: 'center', margin: 'auto', padding: '0px' }}>
            {translate(`entity.confirm.confirmDelete`)}
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button className="modal-cancel-button" onClick={onCancel}>
          <Translate contentKey="entity.action.cancel">Cancel</Translate>
        </Button>
        <Button className="modal-delete-button" onClick={onConfirm}>
          <Translate contentKey="entity.action.delete">Delete</Translate>
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export const CustomSaveEntityConfirm = ({ entity = null, isOpen, onConfirm, onCancel, titleName = null, confirmText = null }) => {
  return (
    <Modal isOpen={isOpen} toggle={onCancel} centered className="Modal-style">
      <ModalHeader toggle={onCancel}>
        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
          <img src={`${SERVER_URL}/content/images/icon/WarningRed.svg`} style={{ height: '52px' }} />
          <Translate contentKey="entity.confirm.title" />
        </div>
      </ModalHeader>
      <ModalBody>
        <Row style={{ margin: '0px' }}>
          <Col sm={12} style={{ padding: '0px' }}>
            {titleName ? (
              <p className="modal-title-info">
                <span title={titleName}>{titleName}</span>
              </p>
            ) : (
              <>
                {' '}
                {entity?.adiTr || entity?.adiEn ? (
                  <p className="modal-title-info">
                    <span title={entity?.adiTr}>
                      {entity?.adiTr?.slice(0, 100)}
                      {entity?.adiTr?.length > 100 ? '...' : ''}
                    </span>{' '}
                    -{' '}
                    <span title={entity?.adiEn}>
                      {entity?.adiEn?.slice(0, 100)}
                      {entity?.adiEn?.length > 100 ? '...' : ''}
                    </span>
                  </p>
                ) : (
                  entity?.adi && (
                    <p className="modal-title-info">
                      <span title={entity?.adi}>{entity.adi}</span>{' '}
                      {entity?.soyadi ? <span title={entity?.soyadi}>{entity.soyadi}</span> : ''}
                    </p>
                  )
                )}
              </>
            )}

            {entity?.dosyaAdi && (
              <p className="modal-title-info">
                <span title={entity?.dosyaAdi}>
                  {entity?.dosyaAdi?.slice(0, 100)}
                  {entity?.dosyaAdi?.length > 100 ? '...' : ''}
                </span>
              </p>
            )}
          </Col>

          {isNullOrEmpty(confirmText) ? (
            <Col sm={12} style={{ display: 'flex', alignItems: 'center', margin: 'auto', padding: '0px' }}>
              <Translate contentKey="entity.confirm.question" interpolate={entity?.id} />
            </Col>
          ) : (
            <Col sm={12} style={{ display: 'flex', alignItems: 'center', margin: 'auto', padding: '0px' }}>
              {confirmText}
            </Col>
          )}
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button className="modal-cancel-button" onClick={onCancel}>
          <Translate contentKey="entity.action.cancel">Cancel</Translate>
        </Button>
        <Button className="modal-save-button" onClick={onConfirm}>
          <Translate contentKey="entity.action.save">Kaydet</Translate>
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export const KrizSaveEntityConfirm = ({ isOpen, onConfirm, onCancel }) => {
  return (
    <Modal isOpen={isOpen} toggle={onCancel} centered className="modal-style">
      <ModalHeader toggle={onCancel}>
        <div className="d-flex align-items-center" style={{ gap: '5px' }}>
          <img src={`${SERVER_URL}/content/images/icon/WarningRed.svg`} style={{ height: '52px', opacity: '0.8' }} />
          <Translate contentKey="entity.confirm.krizTitle" />
        </div>
      </ModalHeader>
      <ModalBody className="kriz-save-modal-body">
        <div>
          <span className="krizYayinla">
            <Translate contentKey="entity.confirm.krizQuestion" />
          </span>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button className="modal-cancel-button m-0" onClick={onCancel}>
          <Translate contentKey="entity.action.cancel">Cancel</Translate>
        </Button>
        <Button className="modal-save-button m-0" onClick={onConfirm}>
          <Translate contentKey="entity.action.declare">Kaydet</Translate>
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export const deleteEntityConfirm = (id: number | string, entityName: string) => {
  return confirm({
    title: (
      <>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img src={`${SERVER_URL}/content/images/icon/WarningRed.svg`} style={{ height: '52px' }} />
          <Translate contentKey="entity.delete.title"></Translate>
        </div>
      </>
    ),
    message: <Translate contentKey={`entity.confirm.confirmDelete`} />,
    confirmText: (
      <>
        <span style={{ textTransform: 'uppercase' }}>
          <Translate contentKey="entity.action.delete">Delete</Translate>
        </span>
      </>
    ),
    cancelText: (
      <>
        <span style={{ textTransform: 'uppercase' }}>
          <Translate contentKey="entity.action.cancel">Cancel</Translate>
        </span>
      </>
    ),
    confirmColor: 'modal-delete-button',
    cancelColor: 'modal-cancel-button',
    style: {
      zIndex: 99999,
    },
    className: 'modal-dialog-centered',
  });
};

export const saveEntityConfirm = (id?: number | any) => {
  return confirm({
    title: (
      <>
        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
          <img src={`${SERVER_URL}/content/images/icon/Warning.svg`} style={{ height: '42px' }} />
          <Translate contentKey="entity.confirm.title" />
        </div>
      </>
    ),
    message: <Translate contentKey="entity.confirm.question" interpolate={{ id }} />,

    confirmText: (
      <>
        <span style={{ textTransform: 'uppercase' }}>
          <Translate contentKey="entity.action.save">Kaydet</Translate>
        </span>
      </>
    ),
    cancelText: (
      <>
        <span style={{ textTransform: 'uppercase' }}>
          <Translate contentKey="entity.action.cancel">Cancel</Translate>
        </span>
      </>
    ),
    confirmColor: 'modal-save-button',
    cancelColor: 'modal-cancel-button',
    style: {
      zIndex: 99999,
    },
    className: 'modal-dialog-centered',
  });
};

export const saveDynamicEntityConfirm = (entityName: string, param: string, id?: number | any) => {
  return confirm({
    title: <Translate contentKey="entity.confirm.title">Confirm operation</Translate>,
    message: `${translate(`tk24AdysApp.${entityName}.confirm.message`, { param })}`,
    confirmText: <Translate contentKey="entity.action.save">Kaydet</Translate>,
    cancelText: (
      <>
        <FontAwesomeIcon icon="ban" />
        &nbsp;
        <Translate contentKey="entity.action.cancel">Cancel</Translate>
      </>
    ),
    confirmColor: 'success',
    cancelColor: 'secondary',
  });
};

export const isNullOrEmpty = (value?: number | string) => {
  return value === null || value === undefined || value === '';
};

export const isObjectEmpty = obj => {
  return obj === null || obj === undefined || Object.keys(obj).length === 0;
};

export const calculateRemainingTime = (startTime, durationInMinutes) => {
  const currentTime = new Date();
  const endTime = new Date(startTime);
  endTime.setMinutes(endTime.getMinutes() + durationInMinutes);
  const remainingTimeInMilliseconds = Number(endTime) - Number(currentTime);

  if (remainingTimeInMilliseconds <= 0) {
    return translate('global.languageGlobal.bitti');
  }

  const hours = Math.floor(remainingTimeInMilliseconds / (1000 * 60 * 60))
    .toString()
    .padStart(2, '0');
  const minutes = Math.floor((remainingTimeInMilliseconds / (1000 * 60)) % 60)
    .toString()
    .padStart(2, '0');
  const seconds = Math.floor((remainingTimeInMilliseconds / 1000) % 60)
    .toString()
    .padStart(2, '0');

  return `${hours}:${minutes}:${seconds}`;
};

export const calculateRemainingTimeMinutes = (startTime, durationInMinutes) => {
  const currentTime = new Date();
  const endTime = new Date(startTime);
  endTime.setMinutes(endTime.getMinutes() + durationInMinutes);
  const remainingTimeInMilliseconds = Number(endTime) - Number(currentTime);

  return remainingTimeInMilliseconds / 1000 / 60;
};

export function sliceLabelField(label: string, length: number): string {
  if (label.length > 25) {
    return label.slice(0, 25) + '...';
  } else {
    return label;
  }
}

export function getCurrentDateTime() {
  const now = new Date();

  const day = String(now.getDate()).padStart(2, '0');
  const month = String(now.getMonth() + 1).padStart(2, '0');
  const year = now.getFullYear();
  const hours = String(now.getHours()).padStart(2, '0');
  const minutes = String(now.getMinutes()).padStart(2, '0');

  return `${day}_${month}_${year}_${hours}_${minutes}`;
}

export function getCurrentDateTimeForHeader(timezoneOffset: number) {
  // Calculate hours and minutes separately
  const hours = Math.floor(timezoneOffset);
  const minutes = (timezoneOffset - hours) * 60;

  const now = new Date();
  const utcNow = new Date(now.getTime() + now.getTimezoneOffset() * 60000);
  utcNow.setMinutes(utcNow.getMinutes() + hours * 60 + minutes);

  const day = String(utcNow.getDate()).padStart(2, '0');
  const month = String(utcNow.getMonth() + 1).padStart(2, '0');
  const year = utcNow.getFullYear();
  const hoursFormatted = String(utcNow.getHours()).padStart(2, '0');
  const minutesFormatted = String(utcNow.getMinutes()).padStart(2, '0');
  const secondsFormatted = String(utcNow.getSeconds()).padStart(2, '0');

  return `${year}-${month}-${day} ${hoursFormatted}:${minutesFormatted}:${secondsFormatted}`;
}

export function getHeaderContentKey(isViewing, formValues, entityName) {
  if (isViewing) {
    return `tk24AdysApp.${entityName}.detail.title`;
  }
  return formValues ? `tk24AdysApp.${entityName}.home.updateLabel` : `tk24AdysApp.${entityName}.home.createLabel`;
}

export function getTextInitials(text) {
  return text
    .split(' ') // Boşluklara göre kelimelere ayır
    .map(word => word.charAt(0)) // Her kelimenin ilk harfini al
    .join('');
}

export function renderTimezones() {
  const timezones = [];
  for (let i = -12; i <= 12; i += 0.5) {
    const offset = i;
    const hours = Math.floor(Math.abs(offset));
    const minutes = Math.abs(offset) % 1 === 0.5 ? 30 : 0;
    const sign = offset >= 0 ? '+' : '-';
    const formattedHours = hours.toString();
    const formattedMinutes = minutes.toString();
    const value = `UTC${sign}${formattedHours}${minutes > 0 ? `:${formattedMinutes}` : ''}`;
    timezones.push(
      <option value={value} key={value}>
        {value}
      </option>
    );
  }
  return timezones;
}

export const CustomSaveKatilimciEntityConfirm = ({ infoText, isOpen, onConfirm, onCancel }) => {
  return (
    <Modal isOpen={isOpen} toggle={onCancel} centered>
      <ModalHeader toggle={onCancel}>
        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
          <img src={`${SERVER_URL}/content/images/icon/WarningRed.svg`} style={{ height: '52px' }} />
          <Translate contentKey="entity.confirm.title" />
        </div>
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col sm={12} style={{ display: 'flex', alignItems: 'center', margin: 'auto' }}>
            {infoText}
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button className="modal-cancel-button" onClick={onCancel}>
          <Translate contentKey="entity.action.cancel">Cancel</Translate>
        </Button>
        <Button className="modal-save-button" onClick={onConfirm}>
          <Translate contentKey="entity.action.save">Kaydet</Translate>
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export function calculateElapsedTime(startDate) {
  if (!isNullOrEmpty(startDate)) {
    const now = new Date();
    const diff = now.getTime() - new Date(startDate).getTime();

    const totalSeconds = Math.floor(diff / 1000);
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    const formattedTime = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;

    return formattedTime;
  } else {
    return '';
  }
}

export const messageReplace = text => {
  const krizKatilimciEntities = useAppSelector(state => state.krizKatilimci.entities);

  if (!text) {
    return '';
  } else {
    const mentionRegex = /\${(\d+?)}/g;
    return text.split(mentionRegex).map((part, index) => {
      if (index % 2 === 1) {
        const number = part.match(/\d+/);
        const mentionKatilimci = krizKatilimciEntities && krizKatilimciEntities.find(item => item.sicil.toString() === number.toString());
        if (mentionKatilimci) {
          return `${mentionKatilimci.adi} 
          ${isNullOrEmpty(mentionKatilimci.soyadi) ? '' : mentionKatilimci.soyadi}`;
        } else {
          return '';
        }
      } else {
        return part;
      }
    });
  }
};
export const getKatilimciAdSoyadUnvanAdi = katilimci => {
  return `${katilimci.adi} 
      ${isNullOrEmpty(katilimci.soyadi) ? '' : katilimci.soyadi} 
      ${isNullOrEmpty(katilimci.unvanAdi) ? '' : ` / ${katilimci.unvanAdi}`} `;
};

export const getKatilimciAdSoyadUnvan = katilimci => {
  return `${katilimci.adi} 
      ${isNullOrEmpty(katilimci.soyadi) ? '' : katilimci.soyadi} 
      ${isNullOrEmpty(katilimci.unvan) ? '' : ` / ${katilimci.unvan}`} `;
};

export const getKatilimciAdSoyad = katilimci => {
  return `${katilimci.adi} 
     ${isNullOrEmpty(katilimci.soyadi) ? '' : katilimci.soyadi}`;
};
