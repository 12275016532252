import { APP_DATE_FORMAT, SERVER_URL } from 'app/config/constants';
import { GorevDetailProp } from 'app/shared/model/kriz-katilimci-gorev.model';
import { deleteEntityConfirm, isObjectEmpty } from 'app/shared/util/confirm-utils';
import React from 'react';
import { TextFormat, Translate } from 'app/component/jhipster';
import { Col, Row } from 'reactstrap';
import axios from 'axios';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { deleteEntity as deleteEkDosyaEntity } from 'app/entities/ek-dosya/ek-dosya.reducer';
import { ReferansEnum } from 'app/constants/ReferansEnum';
import GrantedButton from 'app/component/GrantedButton';

const GorevDetail: React.FC<GorevDetailProp> = ({ selectedTask, ekDosya, update, kriz, reload }) => {
  const dispatch = useAppDispatch();
  const krizGorevEkDosyaListLoading = useAppSelector(state => state.ekDosya.loading);

  const handleDownloadSavedFile = async file => {
    try {
      const response = await axios.get(`api/ek-dosyas/download/${file.id}`, {
        responseType: 'blob',
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${file.dosyaAdi}`);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error('Dosya indirme hatası:', error);
    }
  };

  const handleRemoveSavedFile = async file => {
    const isDel = await deleteEntityConfirm(file.id, 'ekDosya');

    if (isDel) {
      await dispatch(deleteEkDosyaEntity(file.id));
      update(file.ilgiliTabloKayitId);
      reload();
    }
  };

  const getTextColor = gorevDurumu => {
    if (gorevDurumu) {
      switch (gorevDurumu.id) {
        case ReferansEnum.GorevDurumu.DevamEdiyor:
          return '#005BA6';
        case ReferansEnum.GorevDurumu.Tamamlandi:
          return '#248F42';
        default:
          return '';
      }
    }
    return '';
  };

  const getBadgeBackgroundColor = gorevDurumu => {
    if (gorevDurumu) {
      switch (gorevDurumu.id) {
        case ReferansEnum.GorevDurumu.DevamEdiyor:
          return '#E6F3FF';
        case ReferansEnum.GorevDurumu.Tamamlandi:
          return '#E8FEEF';
        default:
          return '';
      }
    }
    return '';
  };

  return (
    !isObjectEmpty(kriz) && (
      <div>
        <Row style={{ padding: '0px 12px 16px' }}>
          <span
            className="modal-gorev-durumu-badge"
            style={{
              color: getTextColor(selectedTask?.gorevDurumu),
              backgroundColor: getBadgeBackgroundColor(selectedTask?.gorevDurumu),
            }}
          >
            {selectedTask?.gorevDurumu ? selectedTask?.gorevDurumu?.adi : null}
          </span>
        </Row>
        <div className="gorevDetayNotAciklama mt-2 mb-4">
          <p className="gorevDetayBaslik">
            {' '}
            <Translate contentKey="tk24AdysApp.krizKatilimciGorev.gorevAciklamaTable" />:{' '}
          </p>
          <p className="notlarAciklama">{selectedTask?.aciklama}</p>
        </div>
        <Row>
          <Col md={2}>
            <p className="gorevDetayBaslik">
              <Translate contentKey="tk24AdysApp.krizKatilimciGorev.gorevTuru" />:
            </p>
          </Col>
          <Col md={10}>
            <p className="gorevDetayAciklama">{selectedTask?.gorevTuru ? selectedTask?.gorevTuru?.adi : '-'}</p>
          </Col>
        </Row>
        <Row>
          <Col md={2}>
            <p className="gorevDetayBaslik">
              {' '}
              <Translate contentKey="global.languageGlobal.adi" />:
            </p>
          </Col>
          <Col md={10}>
            <p className="gorevDetayAciklama">{selectedTask?.adi ? selectedTask?.adi : '-'}</p>
          </Col>
        </Row>
        <Row>
          <Col md={2}>
            <p className="gorevDetayBaslik">
              {' '}
              <Translate contentKey="global.languageGlobal.gorevOzeti" />:
            </p>
          </Col>
          <Col md={10}>
            <p className="gorevDetayAciklama">{selectedTask?.kisaAdi || '-'}</p>
          </Col>
        </Row>
        <Row>
          <Col md={2}>
            <p className="gorevDetayBaslik">
              {' '}
              <Translate contentKey="tk24AdysApp.krizKatilimciGorev.gorevAtanmaZamaniTable" />:
            </p>
          </Col>
          <Col md={10}>
            <p className="gorevDetayAciklama">
              {selectedTask.gorevAtanmaZamani ? (
                <TextFormat value={selectedTask.gorevAtanmaZamani} type="date" format={APP_DATE_FORMAT} />
              ) : (
                '-'
              )}
            </p>
          </Col>
        </Row>
        <div className="gorevDetayNotAciklama my-4">
          <p className="gorevDetayBaslik">
            {' '}
            <Translate contentKey="tk24AdysApp.krizKatilimciGorev.note" />:{' '}
          </p>
          <p className="notlarAciklama">{selectedTask?.note ? selectedTask?.note : '-'}</p>
        </div>

        <p className="gorevDetayBaslik">
          {' '}
          <Translate contentKey="tk24AdysApp.krizKatilimciGorev.ekDosya" />:
        </p>
        <div className="modal-file-area">
          {ekDosya.length > 0 ? (
            <>
              {ekDosya.map(file => (
                <div key={file.id} className="modal-file-list-row">
                  <div style={{ display: 'flex', justifyContent: 'start', alignItems: 'center', padding: '0px', width: '50px' }}>
                    <img width={16} height={20} src={`${SERVER_URL}/content/images/icon/downloadIcon.svg`} />
                  </div>
                  <div style={{ width: '100%' }}>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'start',
                        justifyContent: 'center',
                        margin: 'auto',
                      }}
                    >
                      <span className="modal-file-name">{file.dosyaAdi}</span>
                      <span className="modal-file-size">{`${(file.fileSize / 1024).toFixed(2)}kb`}</span>
                    </div>
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'end', alignItems: 'center', padding: '0px', width: '100px' }}>
                    <GrantedButton
                      grantedAuthorites={['ref_ek_dosya_tipi_read']}
                      className="delete-file-button"
                      id={`update-button-${file.id}`}
                      onClick={() => handleDownloadSavedFile(file)}
                      comparison={[
                        {
                          value: kriz.krizDurumu?.id,
                          checkValue: ReferansEnum.KrizDurumu.Aktif,
                          contentKey: 'error.comparison.crisisIsPassive',
                        },
                      ]}
                    >
                      <img width={15} height={15} src={`${SERVER_URL}/content/images/icon/downloadFileIcon.svg`} />
                    </GrantedButton>

                    <GrantedButton
                      grantedAuthorites={['ref_ek_dosya_tipi_delete']}
                      id={`delete-button-${file.id}`}
                      comparison={[
                        {
                          value: kriz.krizDurumu?.id,
                          checkValue: ReferansEnum.KrizDurumu.Aktif,
                          contentKey: 'error.comparison.crisisIsPassive',
                        },
                      ]}
                      onClick={() => handleRemoveSavedFile(file)}
                      className="delete-file-button"
                    >
                      <img width={12} height={15} src={`${SERVER_URL}/content/images/icon/deleteFileIcon.svg`} />
                    </GrantedButton>
                  </div>
                </div>
              ))}
            </>
          ) : (
            !krizGorevEkDosyaListLoading && (
              <div className="alert alert-warning w-100 my-2">
                <Translate contentKey="tk24AdysApp.ekDosya.home.notFound">No ekDosya found</Translate>
              </div>
            )
          )}
        </div>
      </div>
    )
  );
};

export default GorevDetail;
