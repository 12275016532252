import { TextFormat } from 'app/component/jhipster';
import { APP_DATETIME_FORMAT, SERVER_URL } from 'app/config/constants';
import { ReferansEnum } from 'app/constants/ReferansEnum';
import { isNullOrEmpty, messageReplace } from 'app/shared/util/confirm-utils';
import React from 'react';
import { Card, CardText } from 'reactstrap';

const circleStyle = {
  width: '32px',
  height: '32px',
  borderRadius: '50%',
  backgroundColor: '#C1C8D1',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: 'white',
};

export const highlightMention = (text, krizKatilimciEntities, sicilNo) => {
  if (!text) {
    return '';
  } else {
    const mentionRegex = /\${(\d+?)}/g;
    return text.split(mentionRegex).map((part, index) => {
      if (index % 2 === 1) {
        const number = part.match(/\d+/);
        const color = number[0].toString() === sicilNo ? '#FF0000' : '#0078D4 ';
        const mentionKatilimci = krizKatilimciEntities && krizKatilimciEntities.find(item => item.sicil.toString() === number.toString());
        return (
          <span style={{ color, fontWeight: '500' }}>
            {mentionKatilimci
              ? `${mentionKatilimci.adi} 
              ${isNullOrEmpty(mentionKatilimci.soyadi) ? '' : mentionKatilimci.soyadi}`
              : '-'}
          </span>
        );
      } else {
        return <React.Fragment>{part}</React.Fragment>;
      }
    });
  }
};

export const KYMDmMessageComponent = ({ krizKatilimciEntities, message, key, chatDetail }) => {
  return (
    <Card key={key} body className="cardChat" style={{ backgroundColor: '#F2F3F580' }}>
      <div>
        <div style={circleStyle}>
          <span style={{ fontSize: '14px' }}>
            {message.adi && message.adi.charAt(0)}
            {message.soyadi && message.soyadi.charAt(0)}
          </span>
        </div>
      </div>

      <CardText
        style={{ display: 'flex', flexDirection: 'column', width: '100%' }}
        onClick={() => chatDetail(ReferansEnum.MesajlasmaTipi.Kym, ReferansEnum.MesajAliciTipi.Dm, message.sicil, message)}
      >
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: '10px' }}>
          <span className="gonderenKatilimciStyle">
            {`${message.adi}`}
            {`${!isNullOrEmpty(message.soyadi) ? ` ${message.soyadi} ` : ''}`}
            {`${!isNullOrEmpty(message.unvan) ? ` / ${message.unvan} ` : ''}`}
          </span>
          <span style={{ fontSize: '14px', color: '#919DAC', fontWeight: '400' }}>
            <TextFormat value={message.cDate} type="date" format={APP_DATETIME_FORMAT} />
          </span>
        </div>
        <span style={{ color: '#919DAC', fontSize: '15px' }}> {messageReplace(krizKatilimciEntities, message.icerik)} </span>
      </CardText>
    </Card>
  );
};

export const ADTDmMessageComponent = ({ krizKatilimciEntities, message, key, chatDetail }) => {
  return (
    <Card key={key} body className="cardChat" style={{ backgroundColor: '#F2F3F580' }}>
      <div>
        <div style={circleStyle}>
          <span style={{ fontSize: '14px' }}>
            {message.adi && message.adi.charAt(0)}
            {message.soyadi && message.soyadi.charAt(0)}
          </span>
        </div>
      </div>

      <CardText
        style={{ display: 'flex', flexDirection: 'column', width: '100%' }}
        onClick={() => chatDetail(ReferansEnum.MesajlasmaTipi.Adt, message, ReferansEnum.MesajAliciTipi.Dm, null, message.sicil)}
      >
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: '10px' }}>
          <span className="gonderenKatilimciStyle">
            {`${message.adi}`}
            {`${!isNullOrEmpty(message.soyadi) ? ` ${message.soyadi} ` : ''}`}
            {`${!isNullOrEmpty(message.unvan) ? ` / ${message.unvan} ` : ''}`}
          </span>
          <span style={{ fontSize: '14px', color: '#919DAC', fontWeight: '400' }}>
            <TextFormat value={message.cDate} type="date" format={APP_DATETIME_FORMAT} />
          </span>
        </div>
        <span style={{ color: '#919DAC', fontSize: '15px' }}> {messageReplace(krizKatilimciEntities, message.icerik)} </span>
      </CardText>
    </Card>
  );
};

export const YonetimDmMessageComponent = ({ krizKatilimciEntities, message, key, chatDetail }) => {
  return (
    <Card key={key} body className="cardChat" style={{ backgroundColor: '#F2F3F580' }}>
      <div>
        <div style={circleStyle}>
          <span style={{ fontSize: '14px' }}>
            {message.adi && message.adi.charAt(0)}
            {message.soyadi && message.soyadi.charAt(0)}
          </span>
        </div>
      </div>

      <CardText
        style={{ display: 'flex', flexDirection: 'column', width: '100%' }}
        onClick={() => chatDetail(message, ReferansEnum.MesajAliciTipi.Dm, null, message.sicil)}
      >
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: '10px' }}>
          <span className="gonderenKatilimciStyle">
            {`${message.adi}`}
            {`${!isNullOrEmpty(message.soyadi) ? ` ${message.soyadi} ` : ''}`}
            {`${!isNullOrEmpty(message.unvan) ? ` / ${message.unvan} ` : ''}`}
          </span>
          <span style={{ fontSize: '14px', color: '#919DAC', fontWeight: '400' }}>
            <TextFormat value={message.cDate} type="date" format={APP_DATETIME_FORMAT} />
          </span>
        </div>
        <span style={{ color: '#919DAC', fontSize: '15px' }}> {messageReplace(krizKatilimciEntities, message.icerik)} </span>
      </CardText>
    </Card>
  );
};

export const KrizADTTimComponent = ({ krizKatilimciEntities, item, krizAdtMessage, chatDetail }) => {
  return (
    <Card
      key={item.id}
      body
      className="cardChat"
      onClick={() => chatDetail(ReferansEnum.MesajlasmaTipi.Adt, null, ReferansEnum.MesajAliciTipi.BirAdtTimi, item, null)}
      style={{
        backgroundColor: '#F2F3F580',
      }}
    >
      <div className="d-flex justify-content-center align-items-center">
        <img width={32} height={32} src={`${SERVER_URL}/content/images/icon/faUserOvers.svg`} />
      </div>
      <CardText style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: '10px' }}>
          <span style={{ fontSize: '20px', color: '#34404F', fontWeight: '500' }}>{item.adtEkip?.adi}</span>
          <span style={{ fontSize: '14px', color: '#919DAC', fontWeight: '400' }}>
            {krizAdtMessage && <TextFormat value={krizAdtMessage.cDate} type="date" format={APP_DATETIME_FORMAT} />}
          </span>
        </div>

        <span style={{ color: '#919DAC', fontSize: '15px' }}>
          {krizAdtMessage && messageReplace(krizKatilimciEntities, krizAdtMessage.icerik)}
        </span>
      </CardText>
    </Card>
  );
};

export const KrizYonetimADTTimComponent = ({ krizKatilimciEntities, item, krizAdtMessage, chatDetail }) => {
  return (
    <Card
      key={item.id}
      body
      className="cardChat"
      onClick={() => chatDetail(null, ReferansEnum.MesajAliciTipi.BirAdtTimi, item, null)}
      style={{
        backgroundColor: '#F2F3F580',
      }}
    >
      <div className="d-flex justify-content-center align-items-center">
        <img width={32} height={32} src={`${SERVER_URL}/content/images/icon/faUserOvers.svg`} />
      </div>
      <CardText style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: '10px' }}>
          <span style={{ fontSize: '20px', color: '#34404F', fontWeight: '500' }}>{item.adtEkip?.adi}</span>
          <span style={{ fontSize: '14px', color: '#919DAC', fontWeight: '400' }}>
            {krizAdtMessage && <TextFormat value={krizAdtMessage.cDate} type="date" format={APP_DATETIME_FORMAT} />}
          </span>
        </div>

        <span style={{ color: '#919DAC', fontSize: '15px' }}>
          {krizAdtMessage && messageReplace(krizKatilimciEntities, krizAdtMessage.icerik)}
        </span>
      </CardText>
    </Card>
  );
};
