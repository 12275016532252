import React, { useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Row, Table } from 'reactstrap';
import { Translate, getPaginationState, JhiItemCount } from 'app/component/jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { getEntities } from './mesaj.reducer';
import { ASC, DESC, SORT } from 'app/shared/util/pagination.constants';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { RDCPagination } from 'app/shared/layout/pagination/pagination-component';
import { LoadingSpinner } from 'app/component/LoadingSpinner';

export const Mesaj = (props: RouteComponentProps<{ url: string }>) => {
  const dispatch = useAppDispatch();
  const currentPageSize = useAppSelector(state => state.paginate.currentPageSize);

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getPaginationState(props.location, currentPageSize, 'id'), props.location.search)
  );

  const mesajList = useAppSelector(state => state.mesaj.entities);
  const loading = useAppSelector(state => state.mesaj.loading);
  const totalItems = useAppSelector(state => state.mesaj.totalItems);

  const getAllEntities = () => {
    dispatch(
      getEntities({
        page: paginationState.activePage - 1,
        size: currentPageSize,
        sort: `${paginationState.sort},${paginationState.order}`,
      })
    );
  };

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`;
    if (props.location.search !== endURL) {
      props.history.push(`${props.location.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort]);

  useEffect(() => {
    const params = new URLSearchParams(props.location.search);
    const page = params.get('page');
    const sort = params.get(SORT);
    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [props.location.search]);

  const sort = p => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === ASC ? DESC : ASC,
      sort: p,
    });
  };

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const handleSyncList = () => {
    sortEntities();
  };

  return (
    <div>
      {loading && <LoadingSpinner />}
      <h2 id="mesaj-heading" data-cy="MesajHeading">
        <Translate contentKey="tk24AdysApp.mesaj.home.title">Mesajs</Translate>
        <div className="d-flex justify-content-end">
          <Button className="mr-2" color="info" onClick={handleSyncList} disabled={loading}>
            <FontAwesomeIcon icon="sync" spin={loading} />{' '}
            <Translate contentKey="tk24AdysApp.mesaj.home.refreshListLabel">Refresh List</Translate>
          </Button>
        </div>
      </h2>
      <div className="table-responsive">
        {mesajList && mesajList.length > 0 ? (
          <Table responsive>
            <thead>
              <tr>
                <th className="hand" onClick={sort('icerik')}>
                  <Translate contentKey="tk24AdysApp.mesaj.icerik">Icerik</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('gonderenKulId')}>
                  <Translate contentKey="tk24AdysApp.mesaj.gonderenKulId">Gonderen Kul Id</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('aliciKulId')}>
                  <Translate contentKey="tk24AdysApp.mesaj.aliciKulId">Alici Kul Id</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  <Translate contentKey="tk24AdysApp.mesaj.kriz">Kriz</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              {mesajList.map(mesaj => (
                <tr key={mesaj.id} data-cy="entityTable">
                  <td>{mesaj.icerik}</td>
                  <td>{mesaj.gonderenKulId}</td>
                  <td>{mesaj.aliciKulId}</td>
                  <td>{mesaj.kriz ? <>{mesaj.kriz.id}</> : ''}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          !loading && (
            <div className="alert alert-warning">
              <Translate contentKey="tk24AdysApp.mesaj.home.notFound">No Mesajs found</Translate>
            </div>
          )
        )}
      </div>
      {totalItems ? (
        <div className={mesajList && mesajList.length > 0 ? '' : 'd-none'}>
          <Row className="justify-content-center">
            <JhiItemCount page={paginationState.activePage} total={totalItems} itemsPerPage={currentPageSize} i18nEnabled />
          </Row>
          <Row className="justify-content-center">
            <RDCPagination
              activePage={paginationState.activePage}
              onSelect={handlePagination}
              maxButtons={5}
              itemsPerPage={currentPageSize}
              totalItems={totalItems}
              syncPage={handleSyncList}
            />
          </Row>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default Mesaj;
