import React from 'react';
import { NavLink } from 'react-router-dom';
import { DropdownItem } from 'reactstrap';

export interface IMenuItem {
  to: string;
  id?: string;
  'data-cy'?: string;
}

export default class MenuItem extends React.Component<IMenuItem> {
  render() {
    const { to, id, children } = this.props;

    return (
      <div className="nav-link-container">
        <NavLink to={to} className="nav-link" activeClassName="active">
          <DropdownItem id={id} data-cy={this.props['data-cy']}>
            <span>{children}</span>
          </DropdownItem>
        </NavLink>
      </div>
    );
  }
}
