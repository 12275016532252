import React from 'react';
import { Row, Col } from 'reactstrap';
import { Translate } from 'app/component/jhipster';
import { IRefYakinlik } from 'app/shared/model/ref-yakinlik.model';

interface RefYakinlikDetailProp {
  refYakinlik: IRefYakinlik;
}

export const RefYakinlikDetail: React.FC<RefYakinlikDetailProp> = ({ refYakinlik }) => {
  return (
    <Row className="modal-body-row">
      <Col lg="6" style={{ marginTop: '10px' }}>
        <Row className="modal-content-title">
          <Translate contentKey="global.languageGlobal.adiTr">Adi Tr</Translate>
        </Row>
        <Row className="modal-content-text">{refYakinlik.adiTr}</Row>
      </Col>
      <Col lg="6" style={{ marginTop: '10px' }}>
        <Row className="modal-content-title">
          <Translate contentKey="global.languageGlobal.adiEn">Adi En</Translate>
        </Row>
        <Row className="modal-content-text">{refYakinlik.adiEn}</Row>
      </Col>
    </Row>
  );
};

export default RefYakinlikDetail;
