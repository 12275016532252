import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending } from '@reduxjs/toolkit';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { IQueryParams, createEntitySlice, EntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { IEkDosya, IEkDosyaParams, IEkDosyaUpload, defaultValue } from 'app/shared/model/ek-dosya.model';

const initialState: EntityState<IEkDosya> = {
  loading: false,
  errorMessage: null,
  entities: [],
  totalEntities: [],
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

interface IEkDosyaQueryParams extends IQueryParams {
  ilgiliTabloKayitId?: number;
  ilgiliTablo?: string;
  ekDosyaTipis?: number[];
  query?: string;
}

interface IEkDosyaIlgiliTabloQueryParams extends IQueryParams {
  ilgiliTablo?: string;
}

const apiUrl = 'api/ek-dosyas';

// Actions

export const getEntities = createAsyncThunk('ekDosya/fetch_entity_list', async ({ page, size, sort, query }: IQueryParams) => {
  const queryParams = new URLSearchParams();
  if (sort) {
    queryParams.set('page', page.toString());
    queryParams.set('size', size.toString());
    queryParams.set('sort', sort);
  }

  if (query) {
    const parsedQueryParams = new URLSearchParams(query);
    parsedQueryParams.forEach((value, key) => {
      queryParams.set(key, value);
    });
  }
  queryParams.set('cacheBuster', new Date().getTime().toString());
  const queryString = queryParams.toString();
  const requestUrl = `${apiUrl}${queryString ? `?${queryString}` : ''}`;

  return axios.get<IEkDosya[]>(requestUrl);
});

export const getFilteredEntities = createAsyncThunk(
  'ekDosya/fetch_entity_filtered_list',
  async ({ ilgiliTabloKayitId, ilgiliTablo, ekDosyaTipis, page, size, sort, query }: IEkDosyaQueryParams) => {
    const queryParams = new URLSearchParams();
    if (ekDosyaTipis) {
      queryParams.set('ekDosyaTipiId.in', ekDosyaTipis.toString());
    }
    if (ilgiliTabloKayitId) {
      queryParams.set('ilgiliTabloKayitId.equals', ilgiliTabloKayitId.toString());
    }
    if (ilgiliTablo) {
      queryParams.set('ilgiliTablo.equals', ilgiliTablo.toString());
    }
    if (sort) {
      queryParams.set('page', page.toString());
      queryParams.set('size', size.toString());
      queryParams.set('sort', sort);
    }
    if (query) {
      const parsedQueryParams = new URLSearchParams(query);
      parsedQueryParams.forEach((value, key) => {
        queryParams.set(key, value);
      });
    }

    queryParams.set('cacheBuster', new Date().getTime().toString());
    const queryString = queryParams.toString();
    const requestUrl = `${apiUrl}${queryString ? `?${queryString}` : ''}`;
    return axios.get<IEkDosya[]>(requestUrl);
  }
);

export const getTotalFilteredEntities = createAsyncThunk(
  'ekDosya/fetch_entity_total_filtered_list',
  async ({ ilgiliTabloKayitId, ilgiliTablo, ekDosyaTipis, size, sort, query }: IEkDosyaQueryParams) => {
    const queryParams = new URLSearchParams();
    if (ekDosyaTipis) {
      queryParams.set('ekDosyaTipiId.in', ekDosyaTipis.toString());
    }
    if (ilgiliTabloKayitId) {
      queryParams.set('ilgiliTabloKayitId.equals', ilgiliTabloKayitId.toString());
    }
    if (ilgiliTablo) {
      queryParams.set('ilgiliTablo.equals', ilgiliTablo.toString());
    }
    if (sort) {
      queryParams.set('size', size.toString());
      queryParams.set('sort', sort);
    }
    if (query) {
      const parsedQueryParams = new URLSearchParams(query);
      parsedQueryParams.forEach((value, key) => {
        queryParams.set(key, value);
      });
    }

    queryParams.set('cacheBuster', new Date().getTime().toString());
    const queryString = queryParams.toString();
    const requestUrl = `${apiUrl}${queryString ? `?${queryString}` : ''}`;
    return axios.get<IEkDosya[]>(requestUrl);
  }
);

export const getFilteredIlgiliTabloEntities = createAsyncThunk(
  'ekDosya/fetch_entity_filtered_list',
  async ({ ilgiliTablo, page, size, sort }: IEkDosyaIlgiliTabloQueryParams) => {
    const requestUrl = `${apiUrl}?ilgiliTablo.equals=${ilgiliTablo}${sort ? `&page=${page}&size=${size}&sort=${sort}` : ''}`;
    return axios.get<IEkDosya[]>(requestUrl);
  }
);

export const getEntity = createAsyncThunk(
  'ekDosya/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<IEkDosya>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const createEntity = createAsyncThunk(
  'ekDosya/create_entity',
  async (entity: IEkDosya, thunkAPI) => {
    const result = await axios.post<IEkDosya>(apiUrl, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
  'ekDosya/update_entity',
  async (params: IEkDosyaParams, thunkAPI) => {
    const result = await axios.put<IEkDosya>(`${apiUrl}/${params.entity.id}`, cleanEntity(params.entity));
    thunkAPI.dispatch(getFilteredEntities(params));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const uploadEntity = createAsyncThunk(
  'ekDosya/upload_entity',
  async (entity: IEkDosyaUpload) => {
    const result = await axios.post<IEkDosyaUpload>(`${apiUrl}/upload`, cleanEntity(entity));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const uploadAndGetEntity = createAsyncThunk(
  'ekDosya/upload_and_get_entity',
  async (params: IEkDosyaParams, thunkAPI) => {
    const result = await axios.post<IEkDosya>(`${apiUrl}/upload`, cleanEntity(params.entity));
    thunkAPI.dispatch(getFilteredEntities(params));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const partialUpdateEntity = createAsyncThunk(
  'ekDosya/partial_update_entity',
  async (entity: IEkDosya, thunkAPI) => {
    const result = await axios.patch<IEkDosya>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const deleteEntity = createAsyncThunk(
  'ekDosya/delete_entity',
  async (id: string | number, thunkAPI) => {
    const requestUrl = `${apiUrl}/${id}`;
    const result = await axios.delete<IEkDosya>(requestUrl);
    return result;
  },
  { serializeError: serializeAxiosError }
);

// slice

export const EkDosyaSlice = createEntitySlice({
  name: 'ekDosya',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(deleteEntity.fulfilled, state => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = {};
      })
      .addMatcher(isFulfilled(getEntities, getTotalFilteredEntities, getFilteredIlgiliTabloEntities), (state, action) => {
        return {
          ...state,
          loading: false,
          entities: action.payload.data,
          totalItems: parseInt(action.payload.headers['x-total-count'], 10),
        };
      })
      .addMatcher(isFulfilled(getTotalFilteredEntities), (state, action) => {
        return {
          ...state,
          loading: false,
          totalEntities: action.payload.data,
          totalItems: parseInt(action.payload.headers['x-total-count'], 10),
        };
      })
      .addMatcher(isFulfilled(createEntity, updateEntity, uploadEntity, uploadAndGetEntity, partialUpdateEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(
        isPending(getEntities, getFilteredEntities, getTotalFilteredEntities, getFilteredIlgiliTabloEntities, getEntity),
        state => {
          state.errorMessage = null;
          state.updateSuccess = false;
          state.loading = true;
        }
      )
      .addMatcher(isPending(createEntity, updateEntity, uploadEntity, uploadAndGetEntity, partialUpdateEntity, deleteEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      });
  },
});

export const { reset } = EkDosyaSlice.actions;

// Reducer
export default EkDosyaSlice.reducer;
