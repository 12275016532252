import React, { useState, useEffect } from 'react';
import { Translate, translate } from 'app/component/jhipster';
import { updateEntity, createEntity } from './kriz-katilimci-iletisim.reducer';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { ReferansEnum } from 'app/constants/ReferansEnum';
import { IRefKatilimci } from 'app/shared/model/ref-katilimci.model';
import { CustomSaveEntityConfirm } from 'app/shared/util/confirm-utils';
import { IKrizKatilimciIletisim } from 'app/shared/model/kriz-katilimci-iletisim.model';
import { Button, Form, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { FormItem } from 'app/component/FormItem';

interface KrizKatilimciIletisimProps {
  handleIletisimCloseModal: () => void;
  formValues?: IKrizKatilimciIletisim;
  katilimci?: IRefKatilimci;
  isOpen: boolean;
}

const KrizKatilimciIletisimUpdate: React.FC<KrizKatilimciIletisimProps> = ({ handleIletisimCloseModal, katilimci, formValues, isOpen }) => {
  const dispatch = useAppDispatch();
  const [customIsOpen, setCustomIsOpen] = useState(isOpen);
  const refIletisimTipi = useAppSelector(state => state.referans.entities);

  const [modalPromiseResolve, setModalPromiseResolve] = useState(null);
  const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);

  useEffect(() => {
    if (isOpen === true) {
      setCustomIsOpen(true);
    } else {
      setCustomIsOpen(false);
    }
  }, [isOpen]);

  const showSaveConfirmModal = () => {
    setIsSaveModalOpen(true);
    return new Promise(resolve => {
      setModalPromiseResolve(() => resolve);
    });
  };

  const handleConfirm = () => {
    if (modalPromiseResolve) {
      modalPromiseResolve(true);
    }
    setIsSaveModalOpen(false);
  };

  const handleCancel = () => {
    if (modalPromiseResolve) {
      modalPromiseResolve(false);
    }
    setIsSaveModalOpen(false);
  };

  const saveEntity = async e => {
    e.preventDefault();
    setCustomIsOpen(false);
    const isSave = await showSaveConfirmModal();
    if (isSave) {
      const formDataObject = {};
      for (const field of e.target.elements) {
        if (field.name) {
          formDataObject[field.name] = field.value;
        }
      }

      let params = {};

      if (formValues) {
        params = {
          entity: {
            ...formValues,
            ...formDataObject,
            iletisimTipiId: formValues.iletisimTipi.id,
          },
        };
      } else {
        params = {
          entity: {
            ...formDataObject,
            iletisimTipi: refIletisimTipi.find(ref => ref.id.toString() === formDataObject['iletisimTipiId'].toString()),
            krizKatilimci: katilimci,
            entMiGeldi: refIletisimTipi.find(item => item.id.toString() === ReferansEnum.EvetHayir.Hayir.toString()),
            varsayilanMi: refIletisimTipi.find(item => item.id.toString() === ReferansEnum.EvetHayir.Hayir.toString()),
          },
        };
      }

      if (formValues === null) {
        await dispatch(createEntity(params));
      } else {
        await dispatch(updateEntity(params));
      }

      handleIletisimCloseModal();
    } else {
      setCustomIsOpen(true);
    }
  };

  return (
    <>
      <CustomSaveEntityConfirm entity={formValues} isOpen={isSaveModalOpen} onConfirm={handleConfirm} onCancel={handleCancel} />

      <Modal className="modal-style" isOpen={customIsOpen} toggle={handleIletisimCloseModal} centered>
        <ModalHeader data-cy="RefKatilimciIletisimCreateUpdateHeading" toggle={handleIletisimCloseModal}>
          {formValues && <p className="modal-title-info">{formValues?.iletisimTipi?.adi}</p>}
          {formValues ? (
            <Translate contentKey="tk24AdysApp.refKatilimciIletisim.home.updateLabel">Update a refKatilimciIletisim</Translate>
          ) : (
            <Translate contentKey="tk24AdysApp.refKatilimciIletisim.home.createLabel">Create a refKatilimciIletisim</Translate>
          )}
        </ModalHeader>

        <Form onSubmit={saveEntity}>
          <ModalBody>
            <FormItem
              label={translate('tk24AdysApp.refKatilimciIletisim.iletisimTipi')}
              id="iletisimTipiId"
              name="iletisimTipiId"
              defaultValue={formValues && refIletisimTipi.length > 0 && formValues['iletisimTipi'] && formValues.iletisimTipi.id}
              type="select"
              validation={{
                required: { value: true, message: translate('entity.validation.required') },
              }}
            >
              <option value="" key="0" />
              {refIletisimTipi.length > 0
                ? refIletisimTipi
                    .filter(item => item.tipi === 'ILETISIM_TIPI')
                    .map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.adi}
                      </option>
                    ))
                : null}
            </FormItem>

            <FormItem
              label={translate('tk24AdysApp.refKatilimciIletisim.iletisimBilgisi')}
              id="katilimci-iletisimBilgisi"
              name="iletisimBilgisi"
              defaultValue={formValues && formValues?.iletisimBilgisi}
              type="text"
              validation={{
                required: { value: true, message: translate('entity.validation.required') },
                maxLength: { value: 200, message: translate('entity.validation.maxlength', { max: 200 }) },
              }}
            />
          </ModalBody>

          <ModalFooter className="modal-footer">
            <Button className="cancel-button" onClick={handleIletisimCloseModal}>
              <Translate contentKey="entity.action.cancel">Cancel</Translate>
            </Button>
            &nbsp;
            <Button className="save-button" id="jhi-confirm-delete-refAdtForm" type="submit" data-cy="entityConfirmButton">
              <Translate contentKey="entity.action.save">Kaydet</Translate>
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
};

export default KrizKatilimciIletisimUpdate;
