import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending } from '@reduxjs/toolkit';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { createEntitySlice, EntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { IRefAdtForm, IRefAdtFormParams, defaultValue } from 'app/shared/model/ref-adt-form.model';

const initialState: EntityState<IRefAdtForm> = {
  loading: false,
  errorMessage: null,
  entities: [],
  totalEntities: [],
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

const apiUrl = 'api/ref-adt-forms';

// Actions

export const getEntities = createAsyncThunk(
  'refAdtForm/fetch_entity_list',
  async ({ page, size, sort, query, formTipiId }: IRefAdtFormParams) => {
    const queryParams = new URLSearchParams();
    if (sort) {
      queryParams.set('page', page.toString());
      queryParams.set('size', size.toString());
      queryParams.set('sort', sort);
    }
    if (formTipiId) {
      queryParams.set('formTipiId.equals', formTipiId.toString());
    }

    if (query) {
      const parsedQueryParams = new URLSearchParams(query);
      parsedQueryParams.forEach((value, key) => {
        queryParams.set(key, value);
      });
    }
    queryParams.set('cacheBuster', new Date().getTime().toString());
    const queryString = queryParams.toString();
    const requestUrl = `${apiUrl}${queryString ? `?${queryString}` : ''}`;

    return axios.get<IRefAdtForm[]>(requestUrl);
  }
);

export const getTotalEntities = createAsyncThunk('refAdtForm/fetch_entity_total_list', async ({ size, sort, query }: IRefAdtFormParams) => {
  const queryParams = new URLSearchParams();
  if (sort) {
    queryParams.set('size', size.toString());
    queryParams.set('sort', sort);
  }
  if (query) {
    const parsedQueryParams = new URLSearchParams(query);
    parsedQueryParams.forEach((value, key) => {
      queryParams.set(key, value);
    });
  }
  queryParams.set('cacheBuster', new Date().getTime().toString());
  const queryString = queryParams.toString();
  const requestUrl = `${apiUrl}${queryString ? `?${queryString}` : ''}`;

  return axios.get<IRefAdtForm[]>(requestUrl);
});

export const getEntity = createAsyncThunk(
  'refAdtForm/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<IRefAdtForm>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const createEntity = createAsyncThunk(
  'refAdtForm/create_entity',
  async (params: IRefAdtFormParams, thunkAPI) => {
    const result = await axios.post<IRefAdtForm>(apiUrl, cleanEntity(params.entity));
    thunkAPI.dispatch(getEntities(params));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
  'refAdtForm/update_entity',
  async (params: IRefAdtFormParams, thunkAPI) => {
    const result = await axios.put<IRefAdtForm>(`${apiUrl}/${params.entity.id}`, cleanEntity(params.entity));
    thunkAPI.dispatch(getEntities(params));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const partialUpdateEntity = createAsyncThunk(
  'refAdtForm/partial_update_entity',
  async (params: IRefAdtFormParams, thunkAPI) => {
    const result = await axios.patch<IRefAdtForm>(`${apiUrl}/${params.entity.id}`, cleanEntity(params.entity));
    thunkAPI.dispatch(getEntities(params));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const deleteEntity = createAsyncThunk(
  'refAdtForm/delete_entity',
  async (params: IRefAdtFormParams, thunkAPI) => {
    const requestUrl = `${apiUrl}/${params.entity.id}`;
    const result = await axios.delete<IRefAdtForm>(requestUrl);
    thunkAPI.dispatch(getEntities(params));
    return result;
  },
  { serializeError: serializeAxiosError }
);

// slice

export const RefAdtFormSlice = createEntitySlice({
  name: 'refAdtForm',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(deleteEntity.fulfilled, state => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = {};
      })
      .addMatcher(isFulfilled(getEntities), (state, action) => {
        return {
          ...state,
          loading: false,
          entities: action.payload.data,
          totalItems: parseInt(action.payload.headers['x-total-count'], 10),
        };
      })
      .addMatcher(isFulfilled(getTotalEntities), (state, action) => {
        return {
          ...state,
          loading: false,
          totalEntities: action.payload.data,
          totalItems: parseInt(action.payload.headers['x-total-count'], 10),
        };
      })
      .addMatcher(isFulfilled(createEntity, updateEntity, partialUpdateEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isPending(getEntities, getTotalEntities, getEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(createEntity, updateEntity, partialUpdateEntity, deleteEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      });
  },
});

export const { reset } = RefAdtFormSlice.actions;

// Reducer
export default RefAdtFormSlice.reducer;
