import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { LoadingSpinner } from 'app/component/LoadingSpinner';
import { TextFormat, Translate, translate } from 'app/component/jhipster';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { MenuProps } from 'app/shared/util/filter.constants';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import { reset as resetPersonelEntities } from '../personel/personel.reducer';
import { IKrizIstasyon } from 'app/shared/model/kriz-istasyon.model';
import { APP_DATE_FORMAT } from 'app/config/constants';
import { ReferansEnum } from 'app/constants/ReferansEnum';
import { updateKatilimciEntity, getEntities as getKatilimciEntities } from '../kriz-katilimci/kriz-katilimci.reducer';
import { isObjectEmpty } from 'app/shared/util/confirm-utils';
import GrantedButton from 'app/component/GrantedButton';
import { IQueryParams } from 'app/shared/reducers/reducer.utils';
import CustomPagination from 'app/component/CustomPagination';
import { ASC, DESC } from 'app/shared/util/pagination.constants';
import SortIcon from 'app/component/SortIcon';
import { localeLanguage, upperOnlyFirstLetter } from 'app/shared/util/util';
import { validationKrizIstasyonEkip } from 'app/shared/util/get-custom-service';

interface KrizIstasyonListProps {
  krizId: string;
}

const KrizIstasyonList: React.FC<KrizIstasyonListProps> = ({ krizId }) => {
  const dispatch = useAppDispatch();
  const kriz = useAppSelector(state => state.kriz.entity);
  const [krizIstasyons, setKrizIstasyons] = useState<IKrizIstasyon[]>(null);
  const [krizIstasyonLoading, setKrizIstasyonLoading] = useState(false);
  const [selectedIstasyon, setSelectedIstasyon] = useState(null);
  const [loading, setLoading] = useState(false);

  const krizKatilimciList = useAppSelector(state => state.krizKatilimci.entities);
  const krizKatilimciTotalItem = useAppSelector(state => state.krizKatilimci.totalItems);
  const krizKatilimciLoading = useAppSelector(state => state.krizKatilimci.loading);
  const [krizKatilimciPageObject, setKrizKatilimciPageObject] = useState<IQueryParams>(useAppSelector(state => state.paginate.pageObject));

  const referans = useAppSelector(state => state.referans.entities);

  const pageObject = {
    size: 100,
    page: 0,
    sort: 'id,desc',
    query: `krizId.equals=${krizId}`,
  };

  useEffect(() => {
    getKrizIstasyon();
  }, []);

  const getKrizIstasyon = async () => {
    setKrizIstasyonLoading(true);

    const krizIstasyon = await validationKrizIstasyonEkip(krizId);
    setKrizIstasyons(krizIstasyon);

    setKrizIstasyonLoading(false);
  };

  const getKatilimciIstasyon = (istasyon: IKrizIstasyon) => {
    dispatch(
      getKatilimciEntities({
        ...krizKatilimciPageObject,
        query: `krizId.equals=${krizId}&istasyonId.equals=${istasyon.havalimani?.id}`,
      })
    );
  };

  useEffect(() => {
    if (selectedIstasyon) {
      getKatilimciIstasyon(selectedIstasyon);
    }
  }, [selectedIstasyon, krizKatilimciPageObject.page, krizKatilimciPageObject.size, krizKatilimciPageObject.sort]);

  const sort = p => () => {
    const orderBy = krizKatilimciPageObject.sort.split(',')[1];
    const newSort = `${p},${orderBy === ASC ? DESC : ASC}`;
    setKrizKatilimciPageObject({ ...krizKatilimciPageObject, sort: newSort });
  };

  const adtLiderOnayUpdate = async (katilimci, onayStatus) => {
    setLoading(true);
    const params = {
      ...pageObject,
      entity: {
        ...katilimci,
        kriz,
        adtLiderOnayiYapildiMi: referans.find(item => item.id.toString() === onayStatus.toString()),
      },
    };

    await dispatch(updateKatilimciEntity(params));
    getKatilimciIstasyon(selectedIstasyon);
    setLoading(false);
  };

  function AdtLiderOnayButton(values) {
    const { katilimci } = values;
    const adtLiderOnayiYapildiMi = katilimci.adtLiderOnayiYapildiMi?.id;

    const handleButtonClick = newValue => {
      adtLiderOnayUpdate(katilimci, newValue);
    };

    return (
      !isObjectEmpty(kriz) && (
        <>
          <GrantedButton
            id={`adt-${adtLiderOnayiYapildiMi === ReferansEnum.EvetHayir.Evet ? 'onay-kaldir' : 'onayla '}-${katilimci.id}`}
            grantedAuthorites={['kriz_istasyon_uye_onay_edit']}
            className={`istasyonOnayBtn ${adtLiderOnayiYapildiMi === ReferansEnum.EvetHayir.Evet ? 'delete-button' : 'view-button'}`}
            data-cy="entityStartButton"
            onClick={() =>
              handleButtonClick(
                adtLiderOnayiYapildiMi === ReferansEnum.EvetHayir.Evet ? ReferansEnum.EvetHayir.Hayir : ReferansEnum.EvetHayir.Evet
              )
            }
            comparison={[
              {
                value: kriz.krizDurumu?.id,
                checkValue: ReferansEnum.KrizDurumu.Aktif,
                contentKey: 'error.comparison.crisisIsPassive',
              },
            ]}
          >
            {translate(`tk24AdysApp.krizAdtEkip.adt${adtLiderOnayiYapildiMi === ReferansEnum.EvetHayir.Evet ? 'OnayKaldir' : 'Onayla'}`)}
          </GrantedButton>
        </>
      )
    );
  }

  const handlePageKatilimciChange = (pageNumber, newItemsPerPage = krizKatilimciPageObject.size) => {
    if (newItemsPerPage !== krizKatilimciPageObject.size) {
      setKrizKatilimciPageObject({ ...krizKatilimciPageObject, page: pageNumber - 1, size: newItemsPerPage });
    } else {
      setKrizKatilimciPageObject({ ...krizKatilimciPageObject, page: pageNumber - 1 });
    }
  };

  return (
    !isObjectEmpty(kriz) && (
      <>
        {(krizIstasyonLoading || loading || krizKatilimciLoading) && <LoadingSpinner />}
        <div className="content-area">
          <Row>
            <Col md={12}>
              <div className="d-flex align-items-center">
                <Box sx={{ minWidth: 260, maxWidth: 260 }}>
                  <FormControl fullWidth size={window.innerWidth < 1600 ? 'small' : 'medium'}>
                    <InputLabel id="default-label" className="select-input">
                      <Translate contentKey="tk24AdysApp.refGorev.home.istasyon" />
                    </InputLabel>
                    <Select
                      className="select-input"
                      labelId="krizAdtEkip-label"
                      id="krizAdtEkip"
                      label={translate('tk24AdysApp.refGorev.home.istasyon')}
                    >
                      {krizIstasyons &&
                        krizIstasyons.map(istasyon => (
                          <MenuItem
                            key={istasyon?.havalimani?.id}
                            value={istasyon?.havalimani.id}
                            onClick={() => {
                              dispatch(resetPersonelEntities());
                              setSelectedIstasyon(istasyon);
                            }}
                          >
                            {istasyon?.havalimani?.koduIata} - {istasyon?.havalimani?.adi}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Box>
                {selectedIstasyon && (
                  <span className="kriz-katilimci-name" style={{ marginLeft: 15 }}>
                    {selectedIstasyon.havalimani?.koduIata} - {selectedIstasyon.havalimani?.adi}
                  </span>
                )}
              </div>
            </Col>
          </Row>
          <Typography className="accordion-typography" gutterBottom component="div">
            {selectedIstasyon && <span style={{ marginLeft: 15 }}>{selectedIstasyon?.aciklama}</span>}
          </Typography>

          {selectedIstasyon !== null ? (
            <>
              <div className="table-responsive" style={{ marginTop: 5 }}>
                <TableContainer className="content-table">
                  {krizKatilimciList && krizKatilimciList.length > 0 ? (
                    <Table aria-label="a dense table">
                      <TableHead className="content-table-title-area">
                        <TableRow className="table-head-row">
                          <TableCell className="table-title-cell hand" align="left" onClick={sort(`katilimciRoluId`)}>
                            <div className="tableHeaderContainer">
                              <Translate contentKey="tk24AdysApp.refAdtEkip.katilimciRoluTitle" />
                              <SortIcon column="katilimciRoluId" activeSortColumn={krizKatilimciPageObject.sort} />
                            </div>
                          </TableCell>
                          <TableCell className="table-title-cell hand" align="left" onClick={sort(`unvanAdi`)}>
                            <div className="tableHeaderContainer">
                              <Translate contentKey="tk24AdysApp.refAdtEkip.unvanAdiTitle" />
                              <SortIcon column="unvanAdi" activeSortColumn={krizKatilimciPageObject.sort} />
                            </div>
                          </TableCell>
                          <TableCell className="table-title-cell hand" align="left" onClick={sort(`adi`)}>
                            <div className="tableHeaderContainer">
                              <Translate contentKey="tk24AdysApp.refAdtEkip.Adi" />
                              <SortIcon column="adi" activeSortColumn={krizKatilimciPageObject.sort} />
                            </div>
                          </TableCell>
                          <TableCell className="table-title-cell hand" align="left" onClick={sort(`soyadi`)}>
                            <div className="tableHeaderContainer">
                              <Translate contentKey="tk24AdysApp.refAdtEkip.Soyadi" />
                              <SortIcon column="soyadi" activeSortColumn={krizKatilimciPageObject.sort} />
                            </div>
                          </TableCell>
                          <TableCell className="table-title-cell hand" align="left" onClick={sort(`personel.eposta`)}>
                            <div className="tableHeaderContainer">
                              <Translate contentKey="tk24AdysApp.refAdtEkip.epostaTitle" />
                              <SortIcon column="personel.eposta" activeSortColumn={krizKatilimciPageObject.sort} />
                            </div>
                          </TableCell>
                          <TableCell className="table-title-cell hand" align="left" onClick={sort(`personel.telefonNo`)}>
                            <div className="tableHeaderContainer">
                              <Translate contentKey="tk24AdysApp.refAdtEkip.Telefon" />
                              <SortIcon column="personel.telefonNo" activeSortColumn={krizKatilimciPageObject.sort} />
                            </div>
                          </TableCell>
                          <TableCell
                            className="table-title-cell hand"
                            align="left"
                            onClick={sort(`adtLiderOnayiYapildiMi.adi${upperOnlyFirstLetter(localeLanguage)}`)}
                          >
                            <div className="tableHeaderContainer">
                              <Translate contentKey="tk24AdysApp.krizKatilimci.liderOnayDurum" />
                              <SortIcon
                                column={`adtLiderOnayiYapildiMi.adi${upperOnlyFirstLetter(localeLanguage)}`}
                                activeSortColumn={krizKatilimciPageObject.sort}
                              />
                            </div>
                          </TableCell>
                          <TableCell className="table-title-cell hand" align="left">
                            <Translate contentKey="tk24AdysApp.krizKatilimci.ivrBildirimAramaZamaniTitle" />
                          </TableCell>
                          <TableCell className="table-title-cell hand" align="left">
                            <Translate contentKey="tk24AdysApp.krizKatilimci.ivrBildirimCevapKacDkSonraTitle" />
                          </TableCell>
                          <TableCell className="table-title-cell hand" align="left">
                            <Translate contentKey="tk24AdysApp.krizKatilimci.ivrBildirimCevapKatilimDurumuTitle" />
                          </TableCell>
                          <TableCell className="sticky-right-header"></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {krizKatilimciList.map(katilimci => (
                          <TableRow key={katilimci.id} className="table-cell-row">
                            <TableCell className="table-content-cell" component="th" scope="row">
                              {katilimci.katilimciRolu?.adi}
                            </TableCell>
                            <TableCell className="table-content-cell" component="th" scope="row">
                              {katilimci.unvanAdi}
                            </TableCell>
                            <TableCell className="table-content-cell" component="th" scope="row">
                              {katilimci.adi}
                            </TableCell>
                            <TableCell className="table-content-cell" component="th" scope="row">
                              {katilimci.soyadi}
                            </TableCell>
                            <TableCell component="th" scope="row" className="table-content-cell">
                              <a href={`mailto:${katilimci.personel?.eposta}`} className="epostaText">
                                {katilimci.personel?.eposta}
                              </a>
                            </TableCell>
                            <TableCell className="table-content-cell" component="th" scope="row">
                              {katilimci.personel?.telefonNo}
                            </TableCell>
                            <TableCell className="table-content-cell" component="th" scope="row">
                              {katilimci.adtLiderOnayiYapildiMi?.adi}
                            </TableCell>
                            <TableCell className="table-content-cell" component="th" scope="row">
                              {katilimci.ivrBildirim?.aramaZamani && (
                                <TextFormat value={katilimci.ivrBildirim?.aramaZamani} type="date" format={APP_DATE_FORMAT} />
                              )}
                            </TableCell>
                            <TableCell className="table-content-cell" component="th" scope="row">
                              {katilimci.ivrBildirim?.ivrCevapKacDkSonra}
                            </TableCell>
                            <TableCell className="table-content-cell" component="th" scope="row">
                              {katilimci.ivrBildirim?.ivrCevapKatilimDurumu?.adi}
                            </TableCell>

                            <TableCell className="sticky-right" align="right">
                              <div className="btn-group flex-btn-group-container">
                                <AdtLiderOnayButton katilimci={katilimci} />
                              </div>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  ) : (
                    !krizKatilimciLoading && (
                      <div className="alert alert-warning my-3">
                        <Translate contentKey="tk24AdysApp.krizIstasyon.home.notFoundPersonel">No AdtPersonel found</Translate>
                      </div>
                    )
                  )}
                </TableContainer>
              </div>
              <CustomPagination
                currentPage={krizKatilimciPageObject.page + 1}
                currentPageSize={krizKatilimciPageObject.size}
                totalItems={krizKatilimciTotalItem}
                handlePageChange={handlePageKatilimciChange}
              />
            </>
          ) : (
            <></>
          )}
        </div>
      </>
    )
  );
};

export default KrizIstasyonList;
