import { IQueryParams } from '../reducers/reducer.utils';

export interface IRefAdtEkip {
  realId?: any;
  id?: number;
  guid?: string;
  adi?: string;
  adiTr?: string;
  adiEn?: string;
  soyadi?: string;
  cDate?: string;
  cUser?: number;
  mDate?: string;
  mUser?: number;
}

export interface IRefAdtEkipParams extends IQueryParams {
  entity?: IRefAdtEkip;
}

export const defaultValue: Readonly<IRefAdtEkip> = {};
