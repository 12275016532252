import React, { useEffect } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { Storage, Translate, translate } from 'app/component/jhipster';
import { SERVER_URL } from 'app/config/constants';
import InfoTable from '../infoTable';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { KrizMain } from 'app/component/KrizMain';
import GrantedButton from 'app/component/GrantedButton';
import { getEntity as getKrizEntity } from 'app/entities/kriz/kriz.reducer';
import { getEntityBySicilId } from '../kriz-katilimci/kriz-katilimci.reducer';
import KrizYonetimMerkezi from './kriz-yonetim-merkezi';
import AileDestekTimi from './aile-destek-timi';
import KrizIstasyon from './kriz-istasyon';
import { getEntities as getReferans } from 'app/entities/referans/referans.reducer';
import styled from 'styled-components';

const StyledTab = styled(Tab)`
  &:hover {
    box-shadow: 0px -2px 4px 0px #0000000f;
  }
`;

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const KrizDetay = (props: RouteComponentProps<{ id: string }>) => {
  const dispatch = useAppDispatch();
  const isDrawer = useAppSelector(state => state.krizChat.isDrawer);

  const [value, setValue] = React.useState(0);
  const sicilNo = useAppSelector(state => state.authentication.account.sicilNo);
  const krizKatilimciEntity = useAppSelector(state => state.krizKatilimci.entity);
  const kriz = useAppSelector(state => state.kriz.entity);

  useEffect(() => {
    dispatch(getKrizEntity(props.match.params.id));
    dispatch(getEntityBySicilId({ krizId: props.match.params.id, sicilId: sicilNo }));
    dispatch(getReferans({ sort: 'asc', page: 0, size: 2000 }));
    // getKartDetail();
    if (value === 1) {
      getKatilimciCeride();
    }

    Storage.session.set('kriz', props.match.params.id);
  }, [value]);

  const getKatilimciCeride = () => {
    // TO DO
  };

  // const getKartDetail = async () => {
  //   await axios.get(`api/krizs/kart-detay/${props.match.params.id}`).then(resp => {
  //     dispatch(setKrizKart(resp.data));
  //   });
  // };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <KrizMain isDrawer={isDrawer}>
      <div style={{ marginBottom: 5 }}>
        <InfoTable id={props.match.params.id} />
      </div>
      <div className="mt-4 krizDetayBtn">
        <Row>
          <Col md={3} xl={2} className="conditional-col">
            <GrantedButton
              grantedAuthorites={['view_tum_gorevler']}
              className="krizDetayButtons"
              tag={Link}
              to={`/kym-gorevler/${props.match.params.id}`}
            >
              <div className="krizdetay-image">
                <img src={`${SERVER_URL}/content/images/kriz-detay/kymGorevlerim.svg`} />
              </div>
              <p>
                <Translate contentKey="tk24AdysApp.krizDetay.adymTumGorevler">ADYM Tüm Görevler</Translate>
              </p>
            </GrantedButton>
          </Col>
          <Col md={3} xl={2} className="conditional-col">
            <GrantedButton
              grantedAuthorites={['view_gorevlerim']}
              className="krizDetayButtons"
              tag={Link}
              to={`/kym-gorev/${props.match.params.id}`}
            >
              <div className="krizdetay-image">
                <img src={`${SERVER_URL}/content/images/kriz-detay/kymGorevler.svg`} />
              </div>
              <p>
                <Translate contentKey="tk24AdysApp.krizDetay.adymGorevlerim">ADYM Görevlerim</Translate>
              </p>
            </GrantedButton>
          </Col>
          <Col md={3} xl={2} className="conditional-col">
            <GrantedButton
              grantedAuthorites={['view_ceride_liste']}
              className="krizDetayButtons"
              tag={Link}
              to={`/kriz-ceride/${props.match.params.id}`}
            >
              <div className="krizdetay-image">
                <img src={`${SERVER_URL}/content/images/kriz-detay/cerideListesi.svg`} />
              </div>
              <p>
                <Translate contentKey="tk24AdysApp.krizDetay.cerideListe" />
              </p>
            </GrantedButton>
          </Col>
          <Col md={3} xl={2} className="conditional-col">
            <GrantedButton
              grantedAuthorites={['view_kriz_detay']}
              className="krizDetayButtons"
              tag={Link}
              to={`/kriz-edit/${props.match.params.id}`}
            >
              <div className="krizdetay-image">
                <img src={`${SERVER_URL}/content/images/kriz-detay/krizDetayicn.svg`} />
              </div>
              <p>
                <Translate contentKey="tk24AdysApp.krizDetay.krizDetay">Kriz Detay</Translate>
              </p>
            </GrantedButton>
          </Col>
          <Col md={3} xl={2} className="conditional-col">
            <GrantedButton
              grantedAuthorites={['view_kriz_ozet_raporu']}
              className="krizDetayButtons"
              tag={Link}
              to={`/kriz-ozet-raporu/${props.match.params.id}`}
            >
              <div className="krizdetay-image">
                <img src={`${SERVER_URL}/content/images/kriz-detay/krizOzetRaporu.svg`} />
              </div>
              <p>
                {' '}
                <Translate contentKey="tk24AdysApp.krizDetay.acilDurumOzet"> Kriz Özet Raporu</Translate>
              </p>
            </GrantedButton>
          </Col>
          <Col md={3} xl={2} className="conditional-col">
            <GrantedButton
              grantedAuthorites={['view_sefer_bilgileri']}
              className="krizDetayButtons"
              tag={Link}
              to={`/kriz-ucus/${props.match.params.id}`}
            >
              <div className="krizdetay-image">
                <img src={`${SERVER_URL}/content/images/kriz-detay/seferBilgileri.svg`} />
              </div>
              <p>
                <Translate contentKey="tk24AdysApp.krizDetay.ucusBilgileri">Uçuş Bilgileri</Translate>
              </p>
            </GrantedButton>
          </Col>
          <Col md={3} xl={2} className="conditional-col">
            <GrantedButton
              grantedAuthorites={['view_ucak_bilgileri']}
              className="krizDetayButtons"
              tag={Link}
              to={`/kriz-ucak/${props.match.params.id}`}
            >
              <div className="krizdetay-image">
                <img src={`${SERVER_URL}/content/images/kriz-detay/ucakBilgileri.svg`} />
              </div>
              <p>
                <Translate contentKey="tk24AdysApp.krizDetay.ucakBilgileri">Uçak Bilgileri</Translate>
              </p>
            </GrantedButton>
          </Col>
          <Col md={3} xl={2} className="conditional-col">
            <GrantedButton
              grantedAuthorites={['view_etkilenen_kisi']}
              className="krizDetayButtons"
              tag={Link}
              to={`/etkilenen-kisi/${props.match.params.id}`}
            >
              <div className="krizdetay-image">
                <img src={`${SERVER_URL}/content/images/kriz-detay/etkilenenKisiler.svg`} />
              </div>
              <p>
                <Translate contentKey="tk24AdysApp.krizDetay.kisiBilgileri">Etkilenen Kişi Bilgileri</Translate>
              </p>
            </GrantedButton>
          </Col>

          <Col md={3} xl={2} className="conditional-col">
            <GrantedButton
              grantedAuthorites={['view_iletisim']}
              className="krizDetayButtons"
              tag={Link}
              to={`/irtibat/${props.match.params.id}`}
            >
              <div className="krizdetay-image">
                <img src={`${SERVER_URL}/content/images/kriz-detay/iletisim.svg`} />
              </div>
              <p>
                <Translate contentKey="tk24AdysApp.krizDetay.irtibatBilgileri">İrtibat</Translate>
              </p>
            </GrantedButton>
          </Col>

          <Col md={3} xl={2} className="conditional-col">
            <GrantedButton
              grantedAuthorites={['view_talimat_yonetimi']}
              className="krizDetayButtons"
              tag={Link}
              to={`/kriz-talimat/${props.match.params.id}`}
            >
              <div className="krizdetay-image">
                <img src={`${SERVER_URL}/content/images/kriz-detay/talimatYonetimi.svg`} />
              </div>
              <p>
                <Translate contentKey="tk24AdysApp.krizDetay.talimat">Talimat Yönetimi</Translate>
              </p>
            </GrantedButton>
          </Col>
          <Col md={3} xl={2} className="conditional-col">
            <GrantedButton
              grantedAuthorites={['view_talimat_ekrani']}
              className="krizDetayButtons"
              tag={Link}
              to={`/kriz-talimat-takip/${props.match.params.id}`}
            >
              <div className="krizdetay-image">
                <img src={`${SERVER_URL}/content/images/kriz-detay/talimatEkrani.svg`} />
              </div>
              <p>
                <Translate contentKey="tk24AdysApp.krizDetay.talimatTakipListesi">Talimat Takip Listesi</Translate>
              </p>
            </GrantedButton>
          </Col>
          <Col md={3} xl={2} className="conditional-col">
            <GrantedButton
              grantedAuthorites={['view_dokumanlar']}
              className="krizDetayButtons"
              tag={Link}
              to={`/kriz-medya/${props.match.params.id}`}
            >
              <div className="krizdetay-image">
                <img src={`${SERVER_URL}/content/images/kriz-detay/dokumanlar.svg`} />
              </div>
              <p>
                <Translate contentKey="tk24AdysApp.krizDetay.medya">Medya & Doküman & Form & DDMS</Translate>
              </p>
            </GrantedButton>
          </Col>
          <Col md={3} xl={2} className="conditional-col">
            <GrantedButton
              grantedAuthorites={['view_vekalet_yonetimi']}
              className="krizDetayButtons"
              tag={Link}
              to={`/vekalet/${props.match.params.id}`}
            >
              <div className="krizdetay-image">
                <img src={`${SERVER_URL}/content/images/kriz-detay/vekaletYonetimi.svg`} />
              </div>
              <p>
                <Translate contentKey="tk24AdysApp.krizDetay.vekalet">Vekalet</Translate>
              </p>
            </GrantedButton>
          </Col>
          <Col md={3} xl={2} className="conditional-col">
            <GrantedButton
              grantedAuthorites={['view_raporlar']}
              className="krizDetayButtons"
              tag={Link}
              to={`/kriz-rapor/${props.match.params.id}`}
            >
              <div className="krizdetay-image">
                <img src={`${SERVER_URL}/content/images/kriz-detay/raporlar.svg`} />
              </div>
              <p>
                <Translate contentKey="tk24AdysApp.krizDetay.raporlar">Raporlar</Translate>
              </p>
            </GrantedButton>
          </Col>
          <Col md={3} xl={2} className="conditional-col">
            <GrantedButton
              grantedAuthorites={['view_duyurular']}
              className="krizDetayButtons"
              tag={Link}
              to={`/kym-duyuru/${props.match.params.id}`}
            >
              <div className="krizdetay-image">
                <img src={`${SERVER_URL}/content/images/kriz-detay/duyurular.svg`} />
              </div>
              <p>
                <Translate contentKey="tk24AdysApp.krizDetay.adymMesajDuyuru">ADYM Duyuru</Translate>
              </p>
            </GrantedButton>
          </Col>
          <Col md={3} xl={2} className="conditional-col">
            <GrantedButton
              grantedAuthorites={['view_adt_duyurular']}
              className="krizDetayButtons"
              tag={Link}
              to={`/adt-duyuru/${props.match.params.id}`}
            >
              <div className="krizdetay-image">
                <img src={`${SERVER_URL}/content/images/kriz-detay/adtDuyurular.svg`} />
              </div>
              <p>
                <Translate contentKey="tk24AdysApp.krizDetay.adtMesajDuyuru">ADT Duyuru</Translate>
              </p>
            </GrantedButton>
          </Col>
          <Col md={3} xl={2} className="conditional-col">
            <GrantedButton
              grantedAuthorites={['view_adt_gorev_cagir']}
              className="krizDetayButtons"
              tag={Link}
              to={`/kriz-adt-ekip/${props.match.params.id}`}
            >
              <div className="krizdetay-image">
                <img src={`${SERVER_URL}/content/images/kriz-detay/goreveCagirma.svg`} />
              </div>
              <p>
                <Translate contentKey="tk24AdysApp.krizDetay.adt">ADT Göreve Çağırma</Translate>
              </p>
            </GrantedButton>
          </Col>
          <Col md={3} xl={2} className="conditional-col">
            <GrantedButton
              grantedAuthorites={['view_adt_gorevler']}
              className="krizDetayButtons"
              tag={Link}
              to={`/adt-gorevler/${props.match.params.id}`}
            >
              <div className="krizdetay-image">
                <img src={`${SERVER_URL}/content/images/kriz-detay/adtGorevlerim.svg`} />
              </div>
              <p>
                <Translate contentKey="tk24AdysApp.krizDetay.adtTumGorevler">ADT Tüm Görevler</Translate>
              </p>
            </GrantedButton>
          </Col>
          <Col md={3} xl={2} className="conditional-col">
            <GrantedButton
              grantedAuthorites={['view_adt_gorevlerim']}
              className="krizDetayButtons"
              tag={Link}
              to={`/adt-gorev/${props.match.params.id}`}
            >
              <div className="krizdetay-image">
                <img src={`${SERVER_URL}/content/images/kriz-detay/adtTumGorevler.svg`} />
              </div>
              <p>
                <Translate contentKey="tk24AdysApp.krizDetay.adtGorevlerim">ADT Görevlerim</Translate>
              </p>
            </GrantedButton>
          </Col>
          <Col md={3} xl={2} className="conditional-col">
            <GrantedButton
              grantedAuthorites={['view_katilimci_yonetimi']}
              className="krizDetayButtons"
              tag={Link}
              to={`/kym-katilimci/${props.match.params.id}`}
            >
              <div className="krizdetay-image">
                <img src={`${SERVER_URL}/content/images/kriz-detay/katilimciYonetimi.svg`} />
              </div>
              <p>
                <Translate contentKey="tk24AdysApp.krizDetay.adymKatilimcilar">ADYM Katılımcılar</Translate>
              </p>
            </GrantedButton>
          </Col>
          <Col md={3} xl={2} className="conditional-col">
            <GrantedButton
              grantedAuthorites={['view_crisis']}
              className="krizDetayButtons"
              tag={Link}
              to={`/kriz-katilimci-bildirim/${props.match.params.id}`}
            >
              <div className="krizdetay-image">
                <img src={`${SERVER_URL}/content/images/kriz-detay/kriz.svg`} />
              </div>
              <p>
                <Translate contentKey="tk24AdysApp.krizDetay.krizBildirim"> Kriz Bildirim</Translate>
              </p>
            </GrantedButton>
          </Col>
          <Col md={3} xl={2} className="conditional-col">
            <GrantedButton
              grantedAuthorites={['view_istasyon_gorev_cagirma']}
              className="krizDetayButtons"
              tag={Link}
              to={`/kriz-istasyon-personel-cagir/${props.match.params.id}`}
            >
              <div className="krizdetay-image">
                <img src={`${SERVER_URL}/content/images/kriz-detay/istasyonGoreveCagirma.svg`} />
              </div>
              <p>
                <Translate contentKey="tk24AdysApp.krizDetay.istasyonPersonelCagir" />
              </p>
            </GrantedButton>
          </Col>
          <Col md={3} xl={2} className="conditional-col">
            <GrantedButton
              grantedAuthorites={['view_istasyon_gorevleri']}
              className="krizDetayButtons"
              tag={Link}
              to={`/kriz-istasyon-gorev/${props.match.params.id}`}
            >
              <div className="krizdetay-image">
                <img src={`${SERVER_URL}/content/images/kriz-detay/istasyonGorevleri.svg`} />
              </div>
              <p>
                <Translate contentKey="tk24AdysApp.krizDetay.istasyonGorevleri" />
              </p>
            </GrantedButton>
          </Col>
        </Row>
      </div>

      <>
        <Row className="align-items-center">
          <Col md={2} className="mb-3">
            <h4 className="krizCerideTitle">
              {' '}
              <Translate contentKey={`tk24AdysApp.krizDetay.krizCeride`} interpolate={{ olayTuru: kriz?.acilDurumTuru?.adi }}>
                {' '}
                Kriz Ceride
              </Translate>
            </h4>
          </Col>
          <Col md={10} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }} className="mb-3"></Col>
        </Row>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }} className="krizAnaCerides">
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" className="tabKrizCerideAnaKategori">
            <StyledTab label={translate('tk24AdysApp.krizCeride.krizYonetimMerkezi')} className="tabKrizCerideTitle" />
            <StyledTab label={translate('tk24AdysApp.krizCeride.aileDestekTimi')} className="tabKrizCerideTitle" />
            <StyledTab label={translate('tk24AdysApp.krizCeride.istasyon')} className="tabKrizCerideTitle" />
          </Tabs>
        </Box>
        <Box sx={{ width: '100%' }} className="krizCeride">
          <CustomTabPanel value={value} index={0}>
            <KrizYonetimMerkezi {...props} krizKatilimci={krizKatilimciEntity} />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <AileDestekTimi {...props} krizKatilimci={krizKatilimciEntity} />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={2}>
            <KrizIstasyon {...props} krizKatilimci={krizKatilimciEntity} />
          </CustomTabPanel>
        </Box>
      </>
    </KrizMain>
  );
};

export default KrizDetay;
