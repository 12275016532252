import { createSlice } from '@reduxjs/toolkit';

import { AppThunk } from 'app/config/store';

const initialState = {
  currentPageSize: 20,
  pageObject: {
    page: 0,
    size: 20,
    sort: `id,desc`,
    query: '',
  },
};

export type PaginationState = Readonly<typeof initialState>;

export const setPageSize: (pageSize: number) => AppThunk = pageSize => dispatch => {
  dispatch(updatePageSize(pageSize));
};

export const PaginationSlice = createSlice({
  name: 'pagination',
  initialState: initialState as PaginationState,
  reducers: {
    updatePageSize(state, action) {
      const currentPageSize = action.payload;
      if (state.currentPageSize !== currentPageSize) {
        state.currentPageSize = currentPageSize;
      }
    },
  },
});

export const { updatePageSize } = PaginationSlice.actions;

// Reducer
export default PaginationSlice.reducer;
