import { SERVER_URL } from 'app/config/constants';
import { Storage, translate } from 'app/component/jhipster';
import React, { useEffect, useState } from 'react';
import { hasAnyAuthority, useAppDispatch, useAppSelector } from 'app/config/store';
import { useHistory } from 'react-router-dom';
import { Popover } from '@mui/material';
import Bildirimler from 'app/entities/kriz-dashboard/bildirimler';
import { toast } from 'react-toastify';
import axios from 'axios';
import { isObjectEmpty } from 'app/shared/util/confirm-utils';
import { closeDrawer as closeMesajDrawer, toggleDrawer as toggleMesajDrawer } from 'app/entities/kriz-chat/kriz-chat-slice.reducer';
import KrizChatNew from 'app/entities/kriz-chat/kriz-chat-new';

const ButtonHeader = () => {
  const history = useHistory();
  const krizId = Storage.session.get('kriz');

  const unauthorizedUser = useAppSelector(state => state.krizAuthority.unauthorizedUser);
  const loginConfirmation = useAppSelector(state => state.krizAuthority.loginConfirmation);
  const confirmationText = useAppSelector(state => state.krizAuthority.confirmationText);

  const isDisableButtons = unauthorizedUser || loginConfirmation || confirmationText;

  const handleDuyuruClick = () => {
    history.push(`/kriz-duyurular/${krizId}`);
  };

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
    setNewNotification(false);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const krizKatilimciEntity = useAppSelector(state => state.krizKatilimci.entity);

  const dispatch = useAppDispatch();
  const [bildirimCount, setBildirimCount] = useState({ krizId: Storage.session.get('kriz'), count: 0 });
  const isDrawerOpen = useAppSelector(state => state.krizChatSlice.isDrawerOpen);

  const bildirimReadAuth = hasAnyAuthority(['kriz_katilimci_bildirim_read']);
  const [newNotification, setNewNotification] = useState(false);

  useEffect(() => {
    if (bildirimReadAuth && !isObjectEmpty(krizKatilimciEntity)) {
      const interval = setInterval(() => {
        const fetchData = async () => {
          const bildirimCountUrl = `api/kriz-katilimci-bildirims/count?gonderimDurumuId.in=21,22&bildirimTuruId.equals=64`;
          const count = (await axios.get(bildirimCountUrl)).data;

          if (Storage.session.get('kriz') !== bildirimCount.krizId) {
            setBildirimCount({ krizId: Storage.session.get('kriz'), count });
          } else if (count !== bildirimCount.count) {
            if (bildirimCount.count === 0) {
              setBildirimCount({ krizId: Storage.session.get('kriz'), count });
            } else {
              setBildirimCount({ krizId: Storage.session.get('kriz'), count });
              setNewNotification(true);
              toast.success(translate('tk24AdysApp.krizKatilimciBildirim.createdBildirim'), {
                position: toast.POSITION.TOP_RIGHT,
              });
            }

            if (open) {
              const previousAnchorEl = anchorEl;
              setAnchorEl(null);

              setAnchorEl(previousAnchorEl);
            }
          }
        };
        fetchData();
      }, 10000);

      return () => clearInterval(interval);
    }
  }, [krizKatilimciEntity, bildirimCount.count, bildirimCount.krizId]);

  const open = Boolean(anchorEl);
  const id = open ? 'notification-popover' : undefined;

  const handleDrawerMesajOpen = () => {
    dispatch(toggleMesajDrawer());
  };

  const handleDrawerMesajClose = () => {
    dispatch(closeMesajDrawer());
  };

  return (
    <div className="text-right hiddenInfo d-flex" style={{ gap: '10px' }}>
      {hasAnyAuthority(['kriz_katilimci_bildirim_read']) && (
        <>
          <div className="buttonsHeader" style={{ pointerEvents: isDisableButtons ? 'none' : 'auto' }}>
            <img
              src={`${SERVER_URL}/content/images/icon/bildirimHeader.svg`}
              onClick={handleClick}
              style={{ cursor: 'pointer' }}
              alt="Notification Icon"
            />
            {newNotification && <span className="notification-dot" />}
          </div>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <Bildirimler />
          </Popover>
        </>
      )}
      {hasAnyAuthority(['kriz_duyuru_read']) && (
        <div onClick={handleDuyuruClick} className="buttonsHeader" style={{ pointerEvents: isDisableButtons ? 'none' : 'auto' }}>
          <img src={`${SERVER_URL}/content/images/icon/duyuruHeader.svg`} />
        </div>
      )}
      {hasAnyAuthority(['dashboard_mesaj']) && (
        <>
          <div onClick={handleDrawerMesajOpen} className="buttonsHeader" style={{ pointerEvents: isDisableButtons ? 'none' : 'auto' }}>
            <img src={`${SERVER_URL}/content/images/icon/message.svg`} />
          </div>

          {isDrawerOpen && <KrizChatNew krizId={krizId} open={isDrawerOpen} onClose={handleDrawerMesajClose} />}
        </>
      )}
    </div>
  );
};

export default ButtonHeader;
