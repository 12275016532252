export const RefEkDosyaTipiCodeEnum = {
  KrizDokuman: 'KRIZ_DOKUMAN',
  KrizMedyaDokuman: 'KRIZ_MEDYA_DOKUMAN',
  KrizEtkilenenKisiDokuman: 'KRIZ_ETKILENEN_KISI_DOKUMAN',
  KrizOzetRaporDokuman: 'KRIZ_OZET_RAPOR_DOKUMAN',
  KrizChatDokuman: 'KRIZ_CHAT_DOKUMAN',
  KrizDuyuruDokuman: 'KRIZ_DUYURU_DOKUMAN',
  KrizGorevDokuman: 'KRIZ_KATILIMCI_GOREV',
  KrizOzetDefaultRaporu: 'KRIZ_OZET_DEFAULT_DOKUMAN',
  KrizOzetHariciRaporu: 'KRIZ_OZET_HARICI_DOKUMAN',
  KrizChatDefaultDokuman: 'KRIZ_CHAT_DEFAULT_DOKUMAN',
  KrizDuyuruDefaultDokuman: 'KRIZ_DUYURU_DEFAULT_DOKUMAN',
  KrizGorevDefaultDokuman: 'KRIZ_GOREV_DEFAULT_DOKUMAN',
  KrizTalimatDefaultDokuman: 'KRIZ_TALIMAT_DOKUMAN',
  krizRefMurettebat: 'MURETTEBAT_SERTIFIKALARI',
  DisDefaultDokuman: 'DIS_DEFAULT_DOKUMAN',
  DdmsDokuman: 'DDMS_DOKUMAN',
  YolcuUcusLoadsheet: 'YOLCU_UCUS_LOADSHEET',
  KargoLoadsheet: 'KARGO_LOADSHEET',
  HazirPdfDokuman: 'HAZIR_PDF_DOKUMAN',
  BriefingProduct: 'BRIEFING_PRODUCT',
  BriefingXMLProduct: 'BRIEFING_XMLPRODUCT',
  BriefingNotam: 'BRIEFING_NOTAM',
  HazirForm: 'HAZIR_FORM',
  KrizTalimatRaporu: 'KRIZ_TALIMAT_RAPOR',
};
