import { APP_DATETIME_FORMAT, SERVER_URL } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntities, getSearchEntities } from 'app/entities/ref-ucus/ref-ucus.reducer';
import { IRefUcus } from 'app/shared/model/ref-ucus.model';
import React, { useEffect, useState } from 'react';
import { TextFormat, Translate, translate } from 'app/component/jhipster';
import { Button, Col, Form, ModalBody, Row } from 'reactstrap';
import CustomPagination from 'app/component/CustomPagination';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@mui/material';
import { FormItem } from 'app/component/FormItem';
import { convertDateTimeForUcus } from 'app/shared/util/date-utils';
import { IQueryParams } from 'app/shared/reducers/reducer.utils';
import { ASC, DESC } from 'app/shared/util/pagination.constants';
import SortIcon from 'app/component/SortIcon';
import { LoadingSpinner } from 'app/component/LoadingSpinner';

interface RefUcusProps {
  addKrizUcusList: (ucus: IRefUcus) => void;
  krizUcusList: readonly IRefUcus[];
}

export const RefUcusSearch: React.FC<RefUcusProps> = ({ addKrizUcusList, krizUcusList }) => {
  const dispatch = useAppDispatch();
  const loading = useAppSelector(state => state.refUcus.loading);
  const updating = useAppSelector(state => state.refUcus.updating);

  const refUcusList = useAppSelector(state => state.refUcus.entities);
  const totalItem = useAppSelector(state => state.refUcus.totalItems);

  const [pageObject, setPageObject] = useState<IQueryParams>(useAppSelector(state => state.paginate.pageObject));

  const [searchUcusKodu, setSearchUcusKodu] = useState('');
  const [searchUcusKuyrukKodu, setSearchUcusKuyrukKodu] = useState('');
  const [searchDep, setSearchDep] = useState('');
  const [searchArr, setSearchArr] = useState('');

  const [searchPlanlananKalkisZamani, setSearchPlanlananKalkisZamani] = useState(null);
  const [searchPlanlananVarisZamani, setSearchPlanlananVarisZamani] = useState(null);
  const [searchGerceklesenKalkisZamani, setSearchGerceklesenKalkisZamani] = useState(null);
  const [searchGerceklesenVarisZamani, setSearchGerceklesenVarisZamani] = useState(null);

  useEffect(() => {
    sortEntities();
  }, [pageObject.page, pageObject.query, pageObject.size, pageObject.sort]);

  const sortEntities = () => {
    dispatch(
      getSearchEntities({
        ucusKodu: searchUcusKodu,
        ucakKuyrukKodu: searchUcusKuyrukKodu,
        departure: searchDep,
        arrival: searchArr,
        planlananKalkisZamani: convertDateTimeForUcus(searchPlanlananKalkisZamani)?.toString(),
        planlananVarisZamani: convertDateTimeForUcus(searchPlanlananVarisZamani)?.toString(),
        gerceklesenKalkisZamani: convertDateTimeForUcus(searchGerceklesenKalkisZamani)?.toString(),
        gerceklesenVarisZamani: convertDateTimeForUcus(searchGerceklesenVarisZamani)?.toString(),
        size: pageObject.size,
        sort: pageObject.sort,
        page: pageObject.page,
      })
    );
  };

  const searchUcus = () => {
    if (pageObject.page === 0) {
      sortEntities();
    } else {
      setPageObject(prevPageObject => ({
        ...prevPageObject,
        page: 0,
      }));
    }
  };

  const cleanFilter = () => {
    setSearchUcusKodu('');
    setSearchUcusKuyrukKodu('');
    setSearchDep('');
    setSearchArr('');
    setSearchPlanlananKalkisZamani(null);
    setSearchPlanlananVarisZamani(null);
    setSearchGerceklesenKalkisZamani(null);
    setSearchGerceklesenVarisZamani(null);
    if (pageObject.page === 0) {
      dispatch(getEntities(pageObject));
    } else {
      setPageObject(prevPageObject => ({
        ...prevPageObject,
        page: 0,
      }));
    }
  };

  const changePlanlananKalkisZamani = event => {
    setSearchPlanlananKalkisZamani(event.target.value);
  };

  const changePlanlananVarisZamani = event => {
    setSearchPlanlananVarisZamani(event.target.value);
  };

  const changeGerceklesenKalkisZamani = event => {
    setSearchGerceklesenKalkisZamani(event.target.value);
  };

  const changeGerceklesenVarisZamani = event => {
    setSearchGerceklesenVarisZamani(event.target.value);
  };

  const handlePageChange = (pageNumber, newItemsPerPage = pageObject.size) => {
    if (newItemsPerPage !== pageObject.size) {
      setPageObject({ ...pageObject, page: pageNumber - 1, size: newItemsPerPage });
    } else {
      setPageObject({ ...pageObject, page: pageNumber - 1 });
    }
  };

  const sort = p => () => {
    const orderBy = pageObject.sort.split(',')[1];
    setPageObject({ ...pageObject, sort: `${p},${orderBy === ASC ? DESC : ASC}` });
  };

  return (
    <>
      <ModalBody style={{ color: '#9795B5' }}>
        <Form
          onSubmit={e => {
            e.preventDefault();
            searchUcus();
          }}
        >
          <Row>
            <Col md={6}>
              <FormItem
                id="planlanan-kalkis-zamani-start"
                name="ucus-planlanan-kalkis-zamani-start"
                label={null}
                customLabel={translate('tk24AdysApp.krizUcus.planlananKalkisTarihi')}
                type="date"
                value={searchPlanlananKalkisZamani}
                onChange={changePlanlananKalkisZamani}
              />
            </Col>
            <Col md={6}>
              <FormItem
                id="planlanan-varis-zamani-start"
                name="ucus-planlanan-varis-zamani-start"
                label={null}
                customLabel={translate('tk24AdysApp.krizUcus.gerceklesenKalkisTarihi')}
                type="date"
                value={searchGerceklesenKalkisZamani}
                onChange={changeGerceklesenKalkisZamani}
              />
            </Col>

            <Col md={6}>
              <FormItem
                id="planlanan-kalkis-zamani-end"
                name="ucus-planlanan-kalkis-zamani-end"
                label={null}
                customLabel={translate('tk24AdysApp.krizUcus.planlananVarisTarihi')}
                type="date"
                value={searchPlanlananVarisZamani}
                onChange={changePlanlananVarisZamani}
              />
            </Col>
            <Col md={6}>
              <FormItem
                id="planlanan-varis-zamani-end"
                name="ucus-planlanan-varis-zamani-end"
                label={null}
                customLabel={translate('tk24AdysApp.krizUcus.gerceklesenVarisTarihi')}
                type="date"
                value={searchGerceklesenVarisZamani}
                onChange={changeGerceklesenVarisZamani}
              />
            </Col>

            <Col md={6}>
              <TextField
                id="dep-id"
                label={translate('tk24AdysApp.krizUcus.kisaltmaPlanlananKalkisHavalimani')}
                fullWidth
                size="small"
                value={searchDep}
                onChange={e => setSearchDep(e.target.value)}
                className="modal-search-input"
              />
            </Col>
            <Col md={6}>
              <TextField
                id="arr-id"
                label={translate('tk24AdysApp.krizUcus.kisaltmaPlanlananVarisHavalimani')}
                fullWidth
                size="small"
                value={searchArr}
                onChange={e => setSearchArr(e.target.value)}
                className="modal-search-input"
              />
            </Col>

            <Col md={6}>
              <TextField
                id="ucusKodu-id"
                label={translate('tk24AdysApp.krizUcus.ucusNumarasi')}
                fullWidth
                size="small"
                value={searchUcusKodu}
                onChange={e => setSearchUcusKodu(e.target.value)}
                className="modal-search-input"
              />
            </Col>
            <Col md={6}>
              <TextField
                id="ucak-id"
                label={translate('tk24AdysApp.krizUcak.kuyrukKodu')}
                fullWidth
                size="small"
                value={searchUcusKuyrukKodu}
                onChange={e => setSearchUcusKuyrukKodu(e.target.value)}
                className="modal-search-input"
              />
            </Col>

            <div className="container d-flex justify-content-between mb-3">
              <span className="modal-clear" onClick={cleanFilter}>
                <Translate contentKey="entity.action.tumFiltreTemizle">search</Translate>
              </span>

              <Button color="primary" id="start-entity" data-cy="entitySearchButton" className="entitySearchButton" type="submit">
                <img width={15.5} height={15.5} src={`${SERVER_URL}/content/images/icon/faSearchWhite.svg`} />
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.search">search</Translate>
                </span>
              </Button>
            </div>
          </Row>
        </Form>
        <div className="table-responsive">
          <TableContainer className="content-table response-modal-height">
            {loading || updating ? (
              <div className="text-center">
                <LoadingSpinner />
              </div>
            ) : (
              <>
                {refUcusList && refUcusList.length > 0 ? (
                  <Table aria-label="a dense table" stickyHeader>
                    <TableHead className="content-table-title-area">
                      <TableRow className="table-head-row">
                        <TableCell align="left" className="table-title-cell-kriz-create hand" onClick={sort('planlananKalkisZamani')}>
                          <div className="tableHeaderContainer">
                            <Translate contentKey="tk24AdysApp.krizUcus.kisaltmaPlanlananKalkis">planlananKalkisZamani</Translate>&nbsp;
                            <SortIcon column="planlananKalkisZamani" activeSortColumn={pageObject.sort} />
                          </div>
                        </TableCell>
                        <TableCell align="left" className="table-title-cell-kriz-create hand" onClick={sort('planlananVarisZamani')}>
                          <div className="tableHeaderContainer">
                            <Translate contentKey="tk24AdysApp.krizUcus.kisaltmaPlanlananVaris">planlananKalkisZamani</Translate>&nbsp;
                            <SortIcon column="planlananVarisZamani" activeSortColumn={pageObject.sort} />
                          </div>
                        </TableCell>
                        <TableCell align="left" className="table-title-cell-kriz-create hand" onClick={sort('gerceklesenKalkisZamani')}>
                          <div className="tableHeaderContainer">
                            <Translate contentKey="tk24AdysApp.krizUcus.kisaltmaGerceklesenKalkis">planlananKalkisZamani</Translate>&nbsp;
                            <SortIcon column="gerceklesenKalkisZamani" activeSortColumn={pageObject.sort} />
                          </div>
                        </TableCell>
                        <TableCell align="left" className="table-title-cell-kriz-create hand" onClick={sort('gerceklesenVarisZamani')}>
                          <div className="tableHeaderContainer">
                            <Translate contentKey="tk24AdysApp.krizUcus.kisaltmaGerceklesenVaris">planlananKalkisZamani</Translate>&nbsp;
                            <SortIcon column="gerceklesenVarisZamani" activeSortColumn={pageObject.sort} />
                          </div>
                        </TableCell>
                        <TableCell align="left" className="table-title-cell-kriz-create hand" onClick={sort('ucusNumarasi')}>
                          <div className="tableHeaderContainer">
                            <Translate contentKey="tk24AdysApp.krizUcus.ucusNumarasiTitle">Ucus Numarasi</Translate>&nbsp;
                            <SortIcon column="ucusNumarasi" activeSortColumn={pageObject.sort} />
                          </div>
                        </TableCell>
                        <TableCell align="left" className="table-title-cell-kriz-create hand" onClick={sort('kalkisHavalimani')}>
                          <div className="tableHeaderContainer">
                            <Translate contentKey="tk24AdysApp.krizUcus.kisaltmaPlanlananKalkisHavalimani">kalkisHavalimani</Translate>
                            &nbsp;
                            <SortIcon column="kalkisHavalimani" activeSortColumn={pageObject.sort} />
                          </div>
                        </TableCell>
                        <TableCell align="left" className="table-title-cell-kriz-create hand" onClick={sort('varisHavalimani')}>
                          <div className="tableHeaderContainer">
                            <Translate contentKey="tk24AdysApp.krizUcus.kisaltmaPlanlananVarisHavalimani">varisHavalimani</Translate>&nbsp;
                            <SortIcon column="varisHavalimani" activeSortColumn={pageObject.sort} />
                          </div>
                        </TableCell>
                        <TableCell align="left" className="table-title-cell-kriz-create hand" onClick={sort('ucak')}>
                          <div className="tableHeaderContainer">
                            <Translate contentKey="tk24AdysApp.krizUcak.kuyrukKodu">Kuyruk Kodu</Translate>&nbsp;
                            <SortIcon column="ucak" activeSortColumn={pageObject.sort} />
                          </div>
                        </TableCell>
                        <TableCell className="table-title-cell-kriz-create" style={{ minWidth: '190px' }}></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {refUcusList.map(refUcus => (
                        <TableRow key={refUcus.id} className="table-cell-row">
                          <TableCell component="th" scope="row" className="table-content-cell">
                            {refUcus.planlananKalkisZamani ? (
                              <TextFormat type="date" value={refUcus.planlananKalkisZamani} format={APP_DATETIME_FORMAT} />
                            ) : null}
                          </TableCell>
                          <TableCell component="th" scope="row" className="table-content-cell">
                            {refUcus.planlananVarisZamani ? (
                              <TextFormat type="date" value={refUcus.planlananVarisZamani} format={APP_DATETIME_FORMAT} />
                            ) : null}
                          </TableCell>
                          <TableCell component="th" scope="row" className="table-content-cell">
                            {refUcus.gerceklesenKalkisZamani ? (
                              <TextFormat type="date" value={refUcus.gerceklesenKalkisZamani} format={APP_DATETIME_FORMAT} />
                            ) : null}
                          </TableCell>
                          <TableCell component="th" scope="row" className="table-content-cell">
                            {refUcus.gerceklesenVarisZamani ? (
                              <TextFormat type="date" value={refUcus.gerceklesenVarisZamani} format={APP_DATETIME_FORMAT} />
                            ) : null}
                          </TableCell>
                          <TableCell component="th" scope="row" className="table-content-cell">
                            {refUcus.tasiyiciHavayolu + ' ' + refUcus.ucusNumarasi}
                          </TableCell>
                          <TableCell component="th" scope="row" className="table-content-cell">
                            {refUcus.planlananKalkisHavalimani ? refUcus.planlananKalkisHavalimani.koduIata : ''}{' '}
                          </TableCell>
                          <TableCell component="th" scope="row" className="table-content-cell">
                            {refUcus.planlananVarisHavalimani ? refUcus.planlananVarisHavalimani.koduIata : ''}{' '}
                          </TableCell>
                          <TableCell component="th" scope="row" className="table-content-cell">
                            {refUcus.ucak ? refUcus.ucak.kuyrukKodu : ''}{' '}
                          </TableCell>

                          <TableCell align="right" className="table-content-cell">
                            <div className="btn-group flex-btn-group-container">
                              {krizUcusList.some(item => item?.id === refUcus?.id) ? (
                                <>
                                  <Button color="primary" id="start-entity" data-cy="entityStartButton" className="savedButton">
                                    <img width={14} height={14} src={`${SERVER_URL}/content/images/icon/onayIcon.svg`} />
                                    <span className="d-none d-md-inline">{translate('entity.action.ucusEkleSaved')}</span>
                                  </Button>
                                </>
                              ) : (
                                <>
                                  <Button
                                    color="primary"
                                    id="start-entity"
                                    data-cy="entityStartButton"
                                    className="saveButton"
                                    onClick={() => addKrizUcusList(refUcus)}
                                  >
                                    <img width={14} height={14} src={`${SERVER_URL}/content/images/icon/faPlusLtBlue.svg`} />
                                    <span className="d-none d-md-inline">{translate('entity.action.ucusEkle')}</span>
                                  </Button>
                                </>
                              )}
                            </div>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                ) : (
                  !loading && (
                    <div className="alert alert-warning mt-1">
                      <Translate contentKey="tk24AdysApp.krizUcus.home.notFound">No krizUcus found</Translate>
                    </div>
                  )
                )}
              </>
            )}
          </TableContainer>
        </div>
      </ModalBody>
      <div className="sayfalamapd">
        <CustomPagination
          currentPage={pageObject.page + 1}
          currentPageSize={pageObject.size}
          totalItems={totalItem}
          handlePageChange={handlePageChange}
        />
      </div>
    </>
  );
};

export default RefUcusSearch;
