import React from 'react';
import { Row, Col } from 'reactstrap';
import { Translate } from 'app/component/jhipster';
import { IRefKrizSeviye } from 'app/shared/model/ref-kriz-seviye.model';

interface RefKrizSeviyeDetailProp {
  refKrizSeviye: IRefKrizSeviye;
}

export const RefKrizSeviyeDetail: React.FC<RefKrizSeviyeDetailProp> = ({ refKrizSeviye }) => {
  return (
    <>
      <Row className="modal-body-row">
        <Col lg="6" style={{ marginTop: '10px' }}>
          <Row className="modal-content-title">
            <Translate contentKey="global.languageGlobal.adiTr">Adi Tr</Translate>
          </Row>
          <Row className="modal-content-text">{refKrizSeviye.adiTr}</Row>
        </Col>
        <Col lg="6" style={{ marginTop: '10px' }}>
          <Row className="modal-content-title">
            <Translate contentKey="global.languageGlobal.adiEn">Adi En</Translate>
          </Row>
          <Row className="modal-content-text">{refKrizSeviye.adiEn}</Row>
        </Col>
        <Col lg="6" style={{ marginTop: '10px' }}>
          <Row className="modal-content-title">
            <Translate contentKey="tk24AdysApp.refKrizSeviye.defaultTplntTipi"></Translate>
          </Row>
          <Row className="modal-content-text">{refKrizSeviye.defaultTplntTipi?.adi}</Row>
        </Col>
        <Col lg="6" style={{ marginTop: '10px' }}>
          <Row className="modal-content-title">
            <Translate contentKey="tk24AdysApp.refKrizSeviye.cssBilgisiBorderColor">Css Bilgisi BorderColor</Translate>
          </Row>
          <Row className="modal-content-text">
            {' '}
            <div
              style={{
                height: '20px',
                width: '100px',
                borderRadius: '10px',
                backgroundColor: refKrizSeviye.cssBorderColor,
              }}
            ></div>
          </Row>
        </Col>
        <Col lg="6" style={{ marginTop: '10px' }}>
          <Row className="modal-content-title">
            <Translate contentKey="tk24AdysApp.refKrizSeviye.cssBilgisiFontColor">Css Bilgisi FontColor</Translate>
          </Row>
          <Row className="modal-content-text">
            {' '}
            <div
              style={{
                height: '20px',
                width: '100px',
                borderRadius: '10px',
                backgroundColor: refKrizSeviye.cssFontColor,
              }}
            ></div>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default RefKrizSeviyeDetail;
