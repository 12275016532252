import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending } from '@reduxjs/toolkit';

import { createEntitySlice, EntityState, IQueryParams } from 'app/shared/reducers/reducer.utils';
import { defaultKartListValue, IKrizKartList } from 'app/shared/model/kriz.model';

const initialState: EntityState<IKrizKartList> = {
  loading: false,
  errorMessage: null,
  entities: [],
  totalEntities: [],
  entity: defaultKartListValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

const apiUrl = 'api/krizs/kart-list';
const apiDashboardUrl = 'api/krizs/active-krizs';
export const getEntities = createAsyncThunk('krizKartList/fetch_entity_list', async ({ page, size, sort, query }: IQueryParams) => {
  const queryParams = new URLSearchParams();
  if (sort) {
    queryParams.set('page', page.toString());
    queryParams.set('size', size.toString());
    queryParams.set('sort', sort);
  }

  if (query) {
    const parsedQueryParams = new URLSearchParams(query);
    parsedQueryParams.forEach((value, key) => {
      queryParams.set(key, value);
    });
  }
  queryParams.set('cacheBuster', new Date().getTime().toString());
  const queryString = queryParams.toString();
  const requestUrl = `${apiUrl}${queryString ? `?${queryString}` : ''}`;

  return axios.get<IKrizKartList[]>(requestUrl);
});

export const getDashboardEntities = createAsyncThunk(
  'krizKartList/fetch_entity_list',
  async ({ page, size, sort, query }: IQueryParams) => {
    const queryParams = new URLSearchParams();
    if (sort) {
      queryParams.set('page', page.toString());
      queryParams.set('size', size.toString());
      queryParams.set('sort', sort);
    }

    if (query) {
      const parsedQueryParams = new URLSearchParams(query);
      parsedQueryParams.forEach((value, key) => {
        queryParams.set(key, value);
      });
    }
    queryParams.set('cacheBuster', new Date().getTime().toString());
    const queryString = queryParams.toString();
    const requestUrl = `${apiDashboardUrl}${queryString ? `?${queryString}` : ''}`;

    return axios.get<IKrizKartList[]>(requestUrl);
  }
);

export const getTotalEntities = createAsyncThunk('krizKartList/fetch_entity_total_list', async ({ size, sort, query }: IQueryParams) => {
  const queryParams = new URLSearchParams();
  if (sort) {
    queryParams.set('size', size.toString());
    queryParams.set('sort', sort);
  }
  if (query) {
    const parsedQueryParams = new URLSearchParams(query);
    parsedQueryParams.forEach((value, key) => {
      queryParams.set(key, value);
    });
  }
  queryParams.set('cacheBuster', new Date().getTime().toString());
  const queryString = queryParams.toString();
  const requestUrl = `${apiUrl}${queryString ? `?${queryString}` : ''}`;

  return axios.get<IKrizKartList[]>(requestUrl);
});

// slice

export const KrizKartListSlice = createEntitySlice({
  name: 'krizKartList',
  initialState,
  extraReducers(builder) {
    builder
      .addMatcher(isFulfilled(getEntities, getDashboardEntities), (state, action) => {
        return {
          ...state,
          loading: false,
          entities: action.payload.data,
          totalItems: parseInt(action.payload.headers['x-total-count'], 10),
        };
      })
      .addMatcher(isFulfilled(getTotalEntities), (state, action) => {
        return {
          ...state,
          loading: false,
          totalEntities: action.payload.data,
          totalItems: parseInt(action.payload.headers['x-total-count'], 10),
        };
      })
      .addMatcher(isPending(getEntities, getTotalEntities, getDashboardEntities), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      });
  },
});

export const { reset } = KrizKartListSlice.actions;

// Reducer
export default KrizKartListSlice.reducer;
