import React, { useEffect, useState } from 'react';
import MenuItem from 'app/shared/layout/menus/menu-item';
import MenuItemHeader from 'app/shared/layout/menus/menu-item-header';
import { Translate } from 'app/component/jhipster';
import { Collapse } from 'reactstrap';
import './menu.scss';
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';

export const ParametersMenu = ({ menuOpen, toggleMenu }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [activeMenu, setActiveMenu] = useState(null);
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    setIsOpen(menuOpen);
    setExpanded(menuOpen);
  }, [menuOpen]);

  const toggle = () => setIsOpen(!isOpen);

  const handleMenuClick = menuId => {
    setActiveMenu(menuId === activeMenu ? null : menuId);
    setExpanded(!expanded);
    toggleMenu();
  };

  const handleMenuItemClick = () => {
    if (menuOpen) {
      toggleMenu();
    }
  };

  return (
    <div>
      <div onClick={toggle}>
        <MenuItemHeader to="/" className={activeMenu === 'parameters' ? 'activeHeader' : ''} onClick={() => handleMenuClick('parameters')}>
          {expanded && (
            <>
              <TuneOutlinedIcon style={{ color: '#34404F' }} />
              <span className="headerText">
                <Translate contentKey="global.menu.parameters.main" />
              </span>
            </>
          )}
        </MenuItemHeader>
      </div>
      <Collapse isOpen={isOpen}>
        <MenuItem to="/ref-adt-ekip" grantedAuthorites={['ref_adt_ekip_read']} onClick={handleMenuItemClick}>
          <Translate contentKey="global.menu.parameters.refAdtEkip" />
        </MenuItem>
        <MenuItem to="/ref-adt-form" grantedAuthorites={['ref_adt_form_read']} onClick={handleMenuItemClick}>
          <Translate contentKey="global.menu.parameters.refAdtForm" />
        </MenuItem>
        <MenuItem to="/bildirim-sablonu" grantedAuthorites={['bildirim_sablonu_read']} onClick={handleMenuItemClick}>
          <Translate contentKey="global.menu.parameters.bildirimSablonu" />
        </MenuItem>
        <MenuItem to="/ref-ddms-dokuman" grantedAuthorites={['ref_ddms_dokuman_read']} onClick={handleMenuItemClick}>
          <Translate contentKey="global.menu.parameters.refDdmsDokuman" />
        </MenuItem>
        <MenuItem to="/ref-ddms-dokuman-kategori" grantedAuthorites={['ref_ddms_dokuman_kategori_read']} onClick={handleMenuItemClick}>
          <Translate contentKey="global.menu.parameters.refDdmsDokumanKategori" />
        </MenuItem>
        <MenuItem to="/ref-dis-dokuman" grantedAuthorites={['ref_dis_dokuman_read']} onClick={handleMenuItemClick}>
          <Translate contentKey="global.menu.parameters.refDisDokuman" />
        </MenuItem>
        <MenuItem to="/ref-duyuru-tipi" grantedAuthorites={['ref_duyuru_tipi_read']} onClick={handleMenuItemClick}>
          <Translate contentKey="global.menu.parameters.refDuyuruTipi" />
        </MenuItem>
        <MenuItem to="/ref-ek-dosya-tipi" grantedAuthorites={['ref_ek_dosya_tipi_read']} onClick={handleMenuItemClick}>
          <Translate contentKey="global.menu.parameters.refEkDosyaTipi" />
        </MenuItem>
        <MenuItem to="/ref-gorev" grantedAuthorites={['ref_gorev_read']} onClick={handleMenuItemClick}>
          <Translate contentKey="global.menu.parameters.refGorev" />
        </MenuItem>
        <MenuItem to="/ref-havalimani" grantedAuthorites={['ref_havalimani_read']} onClick={handleMenuItemClick}>
          <Translate contentKey="global.menu.parameters.refHavalimani" />
        </MenuItem>
        <MenuItem to="/ref-istasyon-gorev-grubu" grantedAuthorites={['ref_istasyon_gorev_grubu_read']} onClick={handleMenuItemClick}>
          <Translate contentKey="global.menu.parameters.refIstasyonGorevGrubu" />
        </MenuItem>
        <MenuItem to="/ref-islem-kodu" grantedAuthorites={['ref_islem_kodu_read']} onClick={handleMenuItemClick}>
          <Translate contentKey="global.menu.parameters.islemKodu" />
        </MenuItem>
        <MenuItem to="/ref-katilimci" grantedAuthorites={['ref_katilimci_read']} onClick={handleMenuItemClick}>
          <Translate contentKey="global.menu.parameters.refKatilimci" />
        </MenuItem>
        <MenuItem to="/ref-kisi-alt-tipi" grantedAuthorites={['ref_kisi_alt_tipi_read']} onClick={handleMenuItemClick}>
          <Translate contentKey="global.menu.parameters.refKisiAltTipi" />
        </MenuItem>
        <MenuItem to="/ref-kriz-seviye" grantedAuthorites={['ref_kriz_seviye_read']} onClick={handleMenuItemClick}>
          <Translate contentKey="global.menu.parameters.refKrizSeviye" />
        </MenuItem>
        <MenuItem to="/ref-kriz-turu" grantedAuthorites={['ref_kriz_turu_read']} onClick={handleMenuItemClick}>
          <Translate contentKey="global.menu.parameters.refKrizTuru" />
        </MenuItem>
        <MenuItem to="/ref-kriz-turu-katilimci" grantedAuthorites={['ref_kriz_turu_katilimci_read']} onClick={handleMenuItemClick}>
          <Translate contentKey="global.menu.parameters.refKrizTuruKatilimci" />
        </MenuItem>
        <MenuItem
          to="/ref-kriz-turu-katilimci-gorev"
          grantedAuthorites={['ref_kriz_turu_katilimci_gorev_read']}
          onClick={handleMenuItemClick}
        >
          <Translate contentKey="global.menu.parameters.refKrizTuruKatilimciGorev" />
        </MenuItem>
        <MenuItem to="/kullanici" grantedAuthorites={['kullanici_read']} onClick={handleMenuItemClick}>
          <Translate contentKey="global.menu.entities.kullanici" />
        </MenuItem>
        <MenuItem to="/referans" grantedAuthorites={['referans_read']} onClick={handleMenuItemClick}>
          <Translate contentKey="global.menu.parameters.referans" />
        </MenuItem>
        <MenuItem to="/rol" grantedAuthorites={['rol_read']} onClick={handleMenuItemClick}>
          <Translate contentKey="global.menu.entities.rol" />
        </MenuItem>
        <MenuItem to="/ref-saglik-durumu" grantedAuthorites={['ref_saglik_durumu_read']} onClick={handleMenuItemClick}>
          <Translate contentKey="global.menu.parameters.refSaglikDurumu" />
        </MenuItem>
        <MenuItem to="/sistem-parametre" grantedAuthorites={['sistem_parametre_read']} onClick={handleMenuItemClick}>
          <Translate contentKey="global.menu.entities.sistemParametre" />
        </MenuItem>
        <MenuItem to="/ref-sablon-parametre" grantedAuthorites={['ref_sablon_parametre_read']} onClick={handleMenuItemClick}>
          <Translate contentKey="global.menu.parameters.refSablonParametre" />
        </MenuItem>
        <MenuItem to="/ref-toplanti-tipi" grantedAuthorites={['ref_toplanti_tipi_read']} onClick={handleMenuItemClick}>
          <Translate contentKey="global.menu.parameters.refToplantiTipi" />
        </MenuItem>
        <MenuItem to="/ref-ucak" grantedAuthorites={['ref_ucak_read']} onClick={handleMenuItemClick}>
          <Translate contentKey="global.menu.parameters.refUcak" />
        </MenuItem>
        <MenuItem to="/uygulama-ayarlari" grantedAuthorites={['uygulama_ayarlari_read']} onClick={handleMenuItemClick}>
          <Translate contentKey="global.menu.entities.uygulamaAyarlari" />
        </MenuItem>
        <MenuItem to="/ref-yakinlik" grantedAuthorites={['ref_yakinlik_read']} onClick={handleMenuItemClick}>
          <Translate contentKey="global.menu.parameters.refYakinlik" />
        </MenuItem>
        <MenuItem to="/yetki" grantedAuthorites={['yetki_read']} onClick={handleMenuItemClick}>
          <Translate contentKey="global.menu.entities.yetki" />
        </MenuItem>
      </Collapse>
    </div>
  );
};

export default ParametersMenu;
