import React, { useState, useEffect } from 'react';
import { Button, Form, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { Translate, translate } from 'app/component/jhipster';
import { getEntities as getReferans } from 'app/entities/referans/referans.reducer';
import { updateEntity, createEntity } from './ref-katilimci-iletisim.reducer';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { IRefKatilimciIletisim } from 'app/shared/model/ref-katilimci-iletisim.model';
import { ReferansEnum } from 'app/constants/ReferansEnum';
import { IRefKatilimci } from 'app/shared/model/ref-katilimci.model';
import { CustomSaveEntityConfirm } from 'app/shared/util/confirm-utils';
import { IRefKatilimciVekil } from 'app/shared/model/ref-katilimci-vekil.model';
import { IQueryParams } from 'app/shared/reducers/reducer.utils';
import { FormItem } from 'app/component/FormItem';

interface RefKatilimciIletisimProps {
  handleIletisimCloseModal: () => void;
  formValues?: IRefKatilimciIletisim;
  katilimci?: IRefKatilimci;
  vekil?: IRefKatilimciVekil;
  pageObject: IQueryParams;
  isOpen: boolean;
}

export const RefKatilimciIletisimUpdate: React.FC<RefKatilimciIletisimProps> = ({
  handleIletisimCloseModal,
  formValues,
  katilimci,
  vekil,
  pageObject,
  isOpen,
}) => {
  const dispatch = useAppDispatch();
  const [customIsOpen, setCustomIsOpen] = useState(isOpen);

  const referans = useAppSelector(state => state.referans.entities);

  const refIletisimTipi = referans.filter(res => res.tipi === 'ILETISIM_TIPI');
  const [modalPromiseResolve, setModalPromiseResolve] = useState(null);
  const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);
  const refKatilimciIletisimList = useAppSelector(state => state.refKatilimciIletisim.entities);

  const hasDefaultEPosta = refKatilimciIletisimList.some(
    item => item.iletisimTipi?.id === ReferansEnum.IletisimTipi.EPosta && item.varsayilanMi?.id === ReferansEnum.EvetHayir.Evet
  );
  const hasDefaultGsm = refKatilimciIletisimList.some(
    item => item.iletisimTipi?.id === ReferansEnum.IletisimTipi.GsmNo && item.varsayilanMi?.id === ReferansEnum.EvetHayir.Evet
  );

  useEffect(() => {
    getReferans({ size: 2000, page: 0, sort: 'asc' });
  }, []);

  useEffect(() => {
    if (isOpen === true) {
      setCustomIsOpen(true);
    } else {
      setCustomIsOpen(false);
    }
  }, [isOpen]);

  const showSaveConfirmModal = () => {
    setIsSaveModalOpen(true);
    return new Promise(resolve => {
      setModalPromiseResolve(() => resolve);
    });
  };

  const handleConfirm = () => {
    if (modalPromiseResolve) {
      modalPromiseResolve(true);
    }
    setIsSaveModalOpen(false);
  };

  const handleCancel = () => {
    if (modalPromiseResolve) {
      modalPromiseResolve(false);
    }
    setIsSaveModalOpen(false);
  };

  const saveEntity = async e => {
    e.preventDefault();
    setCustomIsOpen(false);
    const isSave = await showSaveConfirmModal();
    if (isSave) {
      const formDataObject = {};
      for (const field of e.target.elements) {
        if (field.name) {
          formDataObject[field.name] = field.value;
        }
      }

      let entity = {};

      const formIletisimTipiId = formDataObject['iletisimTipiId'].toString();
      const evetReferans = referans.find(item => item.id.toString() === ReferansEnum.EvetHayir.Evet.toString());
      const hayirReferans = referans.find(item => item.id.toString() === ReferansEnum.EvetHayir.Hayir.toString());

      if (formValues) {
        entity = {
          ...formValues,
          ...formDataObject,
          iletisimTipi: refIletisimTipi.find(ref => ref.id.toString() === formIletisimTipiId),
        };
      } else {
        entity = {
          ...formDataObject,
          iletisimTipi: refIletisimTipi.find(ref => ref.id.toString() === formIletisimTipiId),
          katilimci,
          katilimciVekil: vekil,
          entMiGeldi: hayirReferans,
          varsayilanMi: hayirReferans,
        };
        if (formIletisimTipiId === ReferansEnum.IletisimTipi.EPosta.toString() && !hasDefaultEPosta) {
          entity['varsayilanMi'] = evetReferans;
        } else if (formIletisimTipiId === ReferansEnum.IletisimTipi.GsmNo.toString() && !hasDefaultGsm) {
          entity['varsayilanMi'] = evetReferans;
        }
      }

      if (formValues !== null) {
        await dispatch(updateEntity({ ...pageObject, entity }));
      } else {
        await dispatch(createEntity({ ...pageObject, entity }));
      }

      handleIletisimCloseModal();
    } else {
      setCustomIsOpen(true);
    }
  };

  return (
    <>
      <CustomSaveEntityConfirm entity={formValues} isOpen={isSaveModalOpen} onConfirm={handleConfirm} onCancel={handleCancel} />

      <Modal className="modal-style" isOpen={customIsOpen} toggle={handleIletisimCloseModal} centered>
        <ModalHeader data-cy="RefKatilimciIletisimCreateUpdateHeading" toggle={handleIletisimCloseModal}>
          <p className="modal-title-info">
            {vekil ? (
              <>
                {vekil?.sicil}
                {' / '}
                {vekil?.adi} {vekil?.soyadi}
              </>
            ) : (
              <>
                {katilimci?.sicil}
                {' / '}
                {katilimci?.adi} {katilimci?.soyadi}
              </>
            )}
          </p>
          {formValues ? (
            <Translate contentKey="tk24AdysApp.refKatilimciIletisim.home.updateLabel">Update a refKatilimciIletisim</Translate>
          ) : (
            <Translate contentKey="tk24AdysApp.refKatilimciIletisim.home.createLabel">Create a refKatilimciIletisim</Translate>
          )}
        </ModalHeader>

        <Form onSubmit={saveEntity}>
          <ModalBody>
            <FormItem
              label={translate('tk24AdysApp.refKatilimciIletisim.iletisimTipi')}
              id="iletisimTipiId"
              name="iletisimTipiId"
              defaultValue={formValues && refIletisimTipi.length > 0 && formValues['iletisimTipi'] && formValues.iletisimTipi.id}
              type="select"
              validation={{
                required: { value: true, message: translate('entity.validation.required') },
              }}
            >
              <option value="" key="0" />
              {refIletisimTipi.length > 0
                ? refIletisimTipi
                    .filter(item => item.tipi === 'ILETISIM_TIPI')
                    .map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.adi}
                      </option>
                    ))
                : null}
            </FormItem>

            <FormItem
              label={translate('tk24AdysApp.refKatilimciIletisim.iletisimBilgisi')}
              id="katilimci-iletisimBilgisi"
              name="iletisimBilgisi"
              defaultValue={formValues && formValues?.iletisimBilgisi}
              type="text"
              validation={{
                required: { value: true, message: translate('entity.validation.required') },
                maxLength: { value: 200, message: translate('entity.validation.maxlength', { max: 200 }) },
              }}
            />
          </ModalBody>

          <ModalFooter className="modal-footer">
            <Button className="cancel-button" onClick={handleIletisimCloseModal}>
              <Translate contentKey="entity.action.cancel">Cancel</Translate>
            </Button>
            &nbsp;
            <Button className="save-button" id="jhi-confirm-delete-refAdtForm" type="submit" data-cy="entityConfirmButton">
              <Translate contentKey="entity.action.save">Kaydet</Translate>
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
};

export default RefKatilimciIletisimUpdate;
