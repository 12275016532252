import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import axios from 'axios';
import { TextFormat, Translate, translate } from 'app/component/jhipster';
import { Button, Col, Form, Modal, ModalBody, ModalFooter, ModalHeader, Row, UncontrolledTooltip } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRefAdtEkip } from 'app/shared/model/ref-adt-ekip.model';
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Modal as MuiModal,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { modalStyle } from 'app/shared/util/style-utils';
import TransferList from 'app/component/TransferList';
import { FormItem } from 'app/component/FormItem';
import {
  updateEntity as updateKatilimciEntity,
  updateParamsEntity as updateKatilimciParamEntity,
} from '../kriz-katilimci/kriz-katilimci.reducer';
import { ReferansEnum } from 'app/constants/ReferansEnum';
import { isObjectEmpty, saveEntityConfirm } from 'app/shared/util/confirm-utils';
import { toast } from 'react-toastify';
import { LoadingSpinner } from 'app/component/LoadingSpinner';
import { APP_DATE_FORMAT } from 'app/config/constants';
import GrantedButton from 'app/component/GrantedButton';
import { initialSquare } from 'app/shared/style/style';
import CustomPagination from 'app/component/CustomPagination';
import { IQueryParams } from 'app/shared/reducers/reducer.utils';
import { validationKrizAdtEkip } from 'app/shared/util/get-custom-service';
import { IKrizAdtEkip } from 'app/shared/model/kriz-adt-ekip.model';

interface KrizAdtListProps {
  krizId: string;
}

const KrizAdtList: React.FC<KrizAdtListProps> = ({ krizId }) => {
  const dispatch = useAppDispatch();
  const refAdtEkipList = useAppSelector(state => state.refAdtEkip.entities);

  const [ekipKatilimci, setEkipKatilimci] = useState(null);
  const kriz = useAppSelector(state => state.kriz.entity);

  const [selectedAdtEkip, setSelectedAdtEkip] = useState(null);
  const [selectedEkipKatilimci, setSelectedEkipKatilimci] = useState(null);
  const [selectedKatilimciEkip, setSelectedKatilimciEkip] = useState(null);

  const [isTimRoluEdit, setIsTimRoluEdit] = useState(false);
  const [isManagerEdit, setIsManagerEdit] = useState(false);

  const referans = useAppSelector(state => state.referans.entities);
  const katilimciRolu = referans.filter(item => item.tipi === 'KATILIMCI_ROLU');

  const adtKrizKatilimciLoading = useAppSelector(state => state.krizKatilimci.loading);
  const adtKrizKatilimciUpdating = useAppSelector(state => state.krizKatilimci.updating);

  const refLoading = useAppSelector(state => state.referans.loading);
  const [loading, setLoading] = useState(false);

  const [pageObject, setPageObject] = useState<IQueryParams>(useAppSelector(state => state.paginate.pageObject));
  const [selectedTotalEkipKatilimci, setSelectedTotalEkipKatilimci] = useState(null);

  const [adtEkipLoading, setAdtEkipLoading] = useState(false);
  const [adtEkipList, setAdtEkipList] = useState<IKrizAdtEkip[]>(null);

  const adtTeamMemberParams = {
    size: 100,
    page: 0,
    sort: 'id,desc',
    query: `krizId.equals=${krizId}`,
  };

  const getAdtEkipList = async () => {
    setAdtEkipLoading(true);

    const krizEkip = await validationKrizAdtEkip(krizId);
    setAdtEkipList(krizEkip);

    setAdtEkipLoading(false);
  };

  useEffect(() => {
    getAdtEkipList();
  }, []);

  useEffect(() => {
    if (selectedAdtEkip) {
      getKatilimciAdtEkipRols(selectedAdtEkip?.adtEkip);
    }
  }, [selectedAdtEkip]);

  const adtLiderOnayUpdate = async (katilimci, onayStatus) => {
    const params = {
      ...adtTeamMemberParams,
      entity: {
        ...katilimci,
        kriz,
        adtLiderOnayiYapildiMi: referans.find(item => item.id.toString() === onayStatus.toString()),
      },
    };

    await dispatch(updateKatilimciParamEntity(params));
    getKatilimciAdtEkipRols(selectedAdtEkip?.adtEkip);
  };

  const getKatilimciAdtEkipRols = (ekip: IRefAdtEkip) => {
    setLoading(true);
    const ekipUrl = `api/kriz-katilimci-adt-ekip-rols?adtEkipId.equals=${ekip?.id}&krizId.equals=${krizId}`;
    axios.get(ekipUrl).then(response => {
      setEkipKatilimci(response.data);
      setLoading(false);

      if (Array.isArray(response.data)) {
        setSelectedTotalEkipKatilimci(response.data.length);
      } else {
        setSelectedTotalEkipKatilimci(0);
      }
    });
  };

  const handleCloseModal = () => {
    setSelectedEkipKatilimci(null);
    setSelectedKatilimciEkip(null);
    setIsManagerEdit(false);
    setIsTimRoluEdit(false);
  };

  const handlePageChange = (pageNumber, newItemsPerPage = pageObject.size) => {
    if (newItemsPerPage !== pageObject.size) {
      setPageObject({ ...pageObject, page: pageNumber - 1, size: newItemsPerPage });
    } else {
      setPageObject({ ...pageObject, page: pageNumber - 1 });
    }
  };

  const savetimRoluTransfer = async right => {
    if (right.length > 0) {
      setLoading(true);
      const entity = {
        krizKatilimci: selectedEkipKatilimci,
        adtEkipList: right,
        kriz,
      };

      const krizKatilimciAdtEkipFormUrl = `api/kriz-katilimci-adt-ekip-rols/add`;
      await axios
        .post(krizKatilimciAdtEkipFormUrl, entity)
        .then(response => {
          toast.success(
            translate('tk24AdysApp.refAdtEkipForm.updated', {
              param: selectedEkipKatilimci?.adi + '- ' + selectedEkipKatilimci?.soyadi,
            })
          );
        })
        .catch(function (error) {
          toast.error(translate(`error.http.${error.response.status}`));
        });

      setLoading(false);
      handleCloseModal();
      getKatilimciAdtEkipRols(selectedAdtEkip?.adtEkip);
    } else {
      toast.error(translate('tk24AdysApp.krizAdtEkip.timKontrol'));
    }
  };

  const openTimModal = katilimci => {
    setLoading(true);
    setSelectedEkipKatilimci(katilimci);
    const ekipUrl = `api/kriz-katilimci-adt-ekip-rols?krizKatilimciId.equals=${katilimci.id}&krizId.equals=${krizId}`;
    axios.get(ekipUrl).then(response => {
      setSelectedKatilimciEkip(response.data);
      setLoading(false);
    });
    setIsTimRoluEdit(true);
  };

  const openManagerModal = katilimci => {
    setSelectedEkipKatilimci(katilimci);
    setIsManagerEdit(true);
  };

  const timRoluEditModal = () => {
    return (
      selectedKatilimciEkip && (
        <MuiModal
          open={isTimRoluEdit}
          onClose={handleCloseModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={modalStyle} style={{ minWidth: 1200, height: 'auto' }} className="transferBox">
            {adtEkipList &&
              adtEkipList.length > 0 &&
              (ekipKatilimci !== null ? (
                <TransferList
                  formData={refAdtEkipList}
                  onlyForms={selectedKatilimciEkip.map(item => item.adtEkip)}
                  saveTransfer={savetimRoluTransfer}
                  closeModal={handleCloseModal}
                  isSort={false}
                  header={`${selectedEkipKatilimci?.adi} ${selectedEkipKatilimci?.soyadi || ''}`}
                  title={translate('tk24AdysApp.refAdtEkip.home.title')}
                  transactionTitle={translate('tk24AdysApp.refAdtEkip.home.title')}
                />
              ) : (
                <FontAwesomeIcon icon="sync" spin={true} />
              ))}
          </Box>
        </MuiModal>
      )
    );
  };

  const updateManagerEdit = async event => {
    event.preventDefault();
    const isSave = await saveEntityConfirm();
    if (isSave) {
      const formDataObject = {};
      for (const field of event.target.elements) {
        if (field.name) {
          formDataObject[field.name] = field.value;
        }
      }

      const entitiy = {
        ...selectedEkipKatilimci,
        kriz,
        katilimciRolu: katilimciRolu.find(item => item.id.toString() === formDataObject['katilimciRoluId'].toString()),
      };

      await dispatch(updateKatilimciEntity(entitiy));
      handleCloseModal();
      getKatilimciAdtEkipRols(selectedAdtEkip?.adtEkip); // ??
    }
  };

  const managerEditModal = () => {
    return (
      selectedEkipKatilimci !== null && (
        <Modal isOpen={isManagerEdit} toggle={handleCloseModal} centered className="modal-style">
          <ModalHeader data-cy="refAdtFormUpdateDialogHeading" toggle={handleCloseModal}>
            <Translate contentKey="entity.action.managerEdit">Manager Edit</Translate>
          </ModalHeader>
          <Form onSubmit={updateManagerEdit}>
            <ModalBody>
              <FormItem
                type="select"
                label={translate('tk24AdysApp.refKatilimci.katilimciRolu.adi')}
                id="ref-katilimci-katilimciRolu.id"
                name="katilimciRoluId"
                defaultValue={selectedEkipKatilimci?.katilimciRolu && selectedEkipKatilimci?.katilimciRolu?.id}
                validation={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              >
                {katilimciRolu
                  ? katilimciRolu
                      .filter(
                        item =>
                          item.id === ReferansEnum.KatilimciRolu.AdtEkipLideri ||
                          item.id === ReferansEnum.KatilimciRolu.AdtEkipUyesi ||
                          item.id === ReferansEnum.KatilimciRolu.AdtEkipLideriYardimcisi
                      )
                      .map(otherEntity => (
                        <option value={otherEntity.id} key={otherEntity.id}>
                          {otherEntity.adi}
                        </option>
                      ))
                  : null}
              </FormItem>
            </ModalBody>
            <ModalFooter>
              <Button className="cancel-button" onClick={handleCloseModal}>
                <Translate contentKey="entity.action.cancel">Cancel</Translate>
              </Button>
              &nbsp;
              <Button className="save-button" id="jhi-confirm-delete-refAdtForm" type="submit" data-cy="entityConfirmButton">
                <Translate contentKey="entity.action.save">Kaydet</Translate>
              </Button>
            </ModalFooter>
          </Form>
        </Modal>
      )
    );
  };

  function AdtLiderOnayButton(values) {
    const { katilimci } = values;
    const { krizKatilimci } = katilimci;
    const adtLiderOnayiYapildiMi = krizKatilimci?.adtLiderOnayiYapildiMi?.id;

    const handleButtonClick = newValue => {
      adtLiderOnayUpdate(krizKatilimci, newValue);
    };

    return (
      !isObjectEmpty(kriz) && (
        <>
          <GrantedButton
            id={`adt-${adtLiderOnayiYapildiMi === ReferansEnum.EvetHayir.Evet ? 'onay-kaldir' : 'onayla '}-${katilimci.id}`}
            grantedAuthorites={['kriz_adt_uye_onay_edit']}
            className={adtLiderOnayiYapildiMi === ReferansEnum.EvetHayir.Evet ? 'delete-button' : 'view-button'}
            data-cy="entityStartButton"
            onClick={() =>
              handleButtonClick(
                adtLiderOnayiYapildiMi === ReferansEnum.EvetHayir.Evet ? ReferansEnum.EvetHayir.Hayir : ReferansEnum.EvetHayir.Evet
              )
            }
            comparison={[
              {
                value: kriz.krizDurumu?.id,
                checkValue: ReferansEnum.KrizDurumu.Aktif,
                contentKey: 'error.comparison.crisisIsPassive',
              },
            ]}
            style={{ marginLeft: 10 }}
          >
            {translate(`tk24AdysApp.krizAdtEkip.adt${adtLiderOnayiYapildiMi === ReferansEnum.EvetHayir.Evet ? 'OnayKaldir' : 'Onayla'}`)}
          </GrantedButton>
        </>
      )
    );
  }

  return (
    !isObjectEmpty(kriz) && (
      <>
        {(loading || refLoading || adtKrizKatilimciLoading || adtKrizKatilimciUpdating || adtEkipLoading) && <LoadingSpinner />}

        {isTimRoluEdit && timRoluEditModal()}
        {isManagerEdit && managerEditModal()}
        <div className="content-area">
          <Row>
            <Col md={12}>
              <div className="d-flex align-items-center mt-3">
                <Box sx={{ minWidth: 260, maxWidth: 260 }}>
                  <FormControl fullWidth>
                    <InputLabel id="default-krizKatilimci-label" className="select-input-label">
                      {' '}
                      <Translate contentKey="tk24AdysApp.refAdtEkip.home.title" />
                    </InputLabel>
                    <Select
                      className="select-input"
                      labelId="krizAdtEkip-label"
                      id="krizAdtEkip"
                      label={translate('tk24AdysApp.refAdtEkip.home.title')}
                    >
                      {adtEkipList &&
                        adtEkipList.map(ekip => (
                          <MenuItem key={ekip?.adtEkip?.id} value={ekip?.adtEkip?.id} onClick={() => setSelectedAdtEkip(ekip)}>
                            {ekip.adtEkip?.adi}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Box>
                {selectedAdtEkip && (
                  <span className="kriz-katilimci-name" style={{ marginLeft: 15 }}>
                    {selectedAdtEkip && selectedAdtEkip.adtEkip.adi}
                  </span>
                )}
              </div>
            </Col>
          </Row>

          <Typography className="accordion-typography" gutterBottom component="div">
            {selectedAdtEkip && <span style={{ marginLeft: 15 }}>{selectedAdtEkip && selectedAdtEkip?.aciklama}</span>}
          </Typography>

          {selectedAdtEkip !== null ? (
            <div className="table-responsive" style={{ marginTop: 5 }}>
              <TableContainer className="content-table">
                {ekipKatilimci && ekipKatilimci.length > 0 ? (
                  <Table aria-label="a dense table">
                    <TableHead className="content-table-title-area">
                      <TableRow className="table-head-row">
                        <TableCell className="table-title-cell hand" align="left">
                          <Translate contentKey="tk24AdysApp.refAdtEkip.katilimciRoluTitle" />
                        </TableCell>
                        <TableCell className="table-title-cell hand" align="left">
                          <Translate contentKey="tk24AdysApp.refAdtEkip.unvanAdiTitle" />
                        </TableCell>
                        <TableCell className="table-title-cell hand" align="left">
                          <Translate contentKey="tk24AdysApp.krizKatilimci.img">img</Translate>&nbsp;
                        </TableCell>
                        <TableCell className="table-title-cell hand" align="left">
                          <Translate contentKey="tk24AdysApp.krizKatilimci.sicil" />
                        </TableCell>
                        <TableCell className="table-title-cell hand" align="left">
                          <Translate contentKey="tk24AdysApp.refAdtEkip.Adi" />
                        </TableCell>
                        <TableCell className="table-title-cell hand" align="left">
                          <Translate contentKey="tk24AdysApp.refAdtEkip.Soyadi" />
                        </TableCell>
                        <TableCell className="table-title-cell hand" align="left">
                          <Translate contentKey="tk24AdysApp.refAdtEkip.epostaTitle" />
                        </TableCell>
                        <TableCell className="table-title-cell hand" align="left">
                          <Translate contentKey="tk24AdysApp.refAdtEkip.Telefon" />
                        </TableCell>
                        <TableCell className="table-title-cell hand" align="left">
                          <Translate contentKey="tk24AdysApp.refAdtEkip.altAdtEkip" />
                        </TableCell>
                        <TableCell className="table-title-cell hand" align="left">
                          <Translate contentKey="tk24AdysApp.refAdtEkip.vardiyaTitle" />
                        </TableCell>
                        <TableCell className="table-title-cell hand" align="left">
                          <Translate contentKey="tk24AdysApp.krizKatilimci.liderOnayDurum" />
                        </TableCell>
                        <TableCell className="table-title-cell hand" align="left">
                          <Translate contentKey="tk24AdysApp.krizKatilimci.ivrBildirimAramaZamaniTitle" />
                        </TableCell>
                        <TableCell className="table-title-cell hand" align="left">
                          <Translate contentKey="tk24AdysApp.krizKatilimci.ivrBildirimCevapKacDkSonraTitle" />
                        </TableCell>
                        <TableCell className="table-title-cell hand" align="left">
                          <Translate contentKey="tk24AdysApp.krizKatilimci.ivrBildirimCevapKatilimDurumuTitle" />
                        </TableCell>
                        <TableCell className="sticky-right-header"></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {ekipKatilimci.map(katilimci => (
                        <TableRow key={katilimci?.id} className="table-cell-row">
                          <TableCell className="table-content-cell" component="th" scope="row">
                            {katilimci?.krizKatilimci?.katilimciRolu?.adi}
                          </TableCell>
                          <TableCell className="table-content-cell" component="th" scope="row">
                            {katilimci?.krizKatilimci?.unvanAdi}
                          </TableCell>
                          <TableCell className="table-content-cell" component="th" scope="row">
                            {katilimci.krizKatilimci ? (
                              katilimci.krizKatilimci?.personel?.thumbnail ? (
                                <div className="hoverable-image-container">
                                  <img
                                    className="hoverable-image"
                                    style={{
                                      ...initialSquare,
                                      transition: 'width 0.3s, height 0.3s',
                                    }}
                                    src={`data:${katilimci.krizKatilimci.personel.thumbnail.contentType};base64,${katilimci.krizKatilimci.personel.thumbnail.base64}`}
                                    alt={''}
                                  />
                                </div>
                              ) : (
                                <div style={initialSquare}>
                                  <span style={{ fontSize: '14px' }}>
                                    {katilimci.krizKatilimci.soyadi ? (
                                      <>
                                        {katilimci.krizKatilimci.adi.charAt(0)}
                                        {katilimci.krizKatilimci.soyadi.charAt(0)}
                                      </>
                                    ) : (
                                      katilimci.krizKatilimci.adi.charAt(0)
                                    )}
                                  </span>
                                </div>
                              )
                            ) : (
                              <div style={initialSquare}>
                                <span style={{ fontSize: '14px' }}>
                                  {katilimci.krizKatilimci.soyadi ? (
                                    <>
                                      {katilimci.krizKatilimci.adi.charAt(0)}
                                      {katilimci.krizKatilimci.soyadi.charAt(0)}
                                    </>
                                  ) : (
                                    katilimci.krizKatilimci.adi.charAt(0)
                                  )}
                                </span>
                              </div>
                            )}
                          </TableCell>
                          <TableCell className="table-content-cell" component="th" scope="row">
                            {katilimci?.krizKatilimci?.sicil}
                          </TableCell>
                          <TableCell className="table-content-cell" component="th" scope="row">
                            {katilimci?.krizKatilimci?.adi}
                          </TableCell>
                          <TableCell className="table-content-cell" component="th" scope="row">
                            {katilimci?.krizKatilimci?.soyadi}
                          </TableCell>
                          <TableCell className="table-content-cell" component="th" scope="row">
                            <a href={`mailto:${katilimci?.krizKatilimci?.personel?.eposta}`} className="epostaText">
                              {katilimci?.krizKatilimci?.personel?.eposta}
                            </a>
                          </TableCell>
                          <TableCell className="table-content-cell" component="th" scope="row">
                            {katilimci?.krizKatilimci?.personel?.telefonNo}
                          </TableCell>
                          <TableCell className="table-content-cell" component="th" scope="row">
                            {katilimci?.krizKatilimci?.adtAltEkip?.adi}
                          </TableCell>
                          <TableCell className="table-content-cell" component="th" scope="row">
                            {katilimci?.krizKatilimci?.adtEkipCalismaDuzeni?.adi}
                          </TableCell>
                          <TableCell className="table-content-cell" component="th" scope="row">
                            {katilimci?.krizKatilimci?.adtLiderOnayiYapildiMi?.adi}
                          </TableCell>
                          <TableCell className="table-content-cell" component="th" scope="row">
                            {katilimci?.krizKatilimci?.ivrBildirim?.aramaZamani && (
                              <TextFormat value={katilimci?.krizKatilimci?.ivrBildirim?.aramaZamani} type="date" format={APP_DATE_FORMAT} />
                            )}
                          </TableCell>
                          <TableCell className="table-content-cell" component="th" scope="row">
                            {katilimci?.krizKatilimci?.ivrBildirim?.ivrCevapKacDkSonra}
                          </TableCell>
                          <TableCell className="table-content-cell" component="th" scope="row">
                            {katilimci?.krizKatilimci?.ivrBildirim?.ivrCevapKatilimDurumu?.adi}
                          </TableCell>

                          <TableCell className="sticky-right" align="right">
                            <div className="btn-group flex-btn-group-container">
                              <AdtLiderOnayButton className="" katilimci={katilimci} />
                              <GrantedButton
                                id={`update-button-${katilimci?.krizKatilimci?.id}`}
                                grantedAuthorites={['kriz_katilimci_adt_ekip_rol_edit']}
                                className="view-button "
                                onClick={() => openTimModal(katilimci.krizKatilimci)}
                                comparison={[
                                  {
                                    value: kriz.krizDurumu?.id,
                                    checkValue: ReferansEnum.KrizDurumu.Aktif,
                                    contentKey: 'error.comparison.crisisIsPassive',
                                  },
                                ]}
                                style={{ marginLeft: 10 }}
                              >
                                <Translate contentKey="entity.action.timRolüEdit" />
                              </GrantedButton>

                              <GrantedButton
                                id={`update-button-${katilimci?.krizKatilimci?.id}`}
                                grantedAuthorites={['kriz_katilimci_edit']}
                                className="view-button "
                                onClick={() => openManagerModal(katilimci.krizKatilimci)}
                                comparison={[
                                  {
                                    value: kriz.krizDurumu?.id,
                                    checkValue: ReferansEnum.KrizDurumu.Aktif,
                                    contentKey: 'error.comparison.crisisIsPassive',
                                  },
                                ]}
                                style={{ marginLeft: 10 }}
                              >
                                <Translate contentKey="entity.action.managerEdit" />
                              </GrantedButton>
                            </div>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                ) : (
                  !loading && (
                    <div className="alert alert-warning my-3">
                      <Translate contentKey="tk24AdysApp.krizAdtEkip.home.notFoundAdtTimUye">No Adt Tim Üyesi found</Translate>
                    </div>
                  )
                )}
              </TableContainer>

              <CustomPagination
                currentPage={pageObject.page + 1}
                currentPageSize={pageObject.size}
                totalItems={selectedTotalEkipKatilimci && selectedTotalEkipKatilimci !== null ? selectedTotalEkipKatilimci : 0}
                handlePageChange={handlePageChange}
              />
            </div>
          ) : (
            <></>
          )}
        </div>
      </>
    )
  );
};

export default KrizAdtList;
