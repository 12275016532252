import React, { useState, useEffect, useCallback } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Modal, ModalBody, ModalHeader, Label, FormGroup, Input } from 'reactstrap';
import { Translate, getPaginationState, translate } from 'app/component/jhipster';
import { SORT } from 'app/shared/util/pagination.constants';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { getEntity as getFormBolumEntity } from '../ref-adt-form-bolum/ref-adt-form-bolum.reducer';
import {
  getFilterBolumSoruEntities as getKFilterBolumSoruEntities,
  deleteEntity,
  updateEntities as updateListEntities,
} from './ref-adt-form-bolum-soru.reducer';
import RefAdtFormBolumSoruUpdate from './ref-adt-form-bolum-soru-update';
import { CustomDeleteEntityConfirm, deleteEntityConfirm } from 'app/shared/util/confirm-utils';
import axios from 'axios';
import { getEntities as getReferans } from 'app/entities/referans/referans.reducer';
import { ReferansEnum } from 'app/constants/ReferansEnum';
import { FormItem } from 'app/component/FormItem';
import { debounce } from 'lodash';
import { Tooltip } from '@mui/material';
import { SwapVert as SwapVertIcon } from '@mui/icons-material';
import { SERVER_URL } from 'app/config/constants';
import GrantedButton from 'app/component/GrantedButton';
import { LoadingSpinner } from 'app/component/LoadingSpinner';
import { toast } from 'react-toastify';
import { updateEntities as updateListSecenekEntities } from '../ref-adt-form-bolum-soru-secenek/ref-adt-form-bolum-soru-secenek.reducer';

const RefAdtFormBolumSoru = (props: RouteComponentProps<{ url: string; id: string }>) => {
  const dispatch = useAppDispatch();
  const currentPageSize = useAppSelector(state => state.paginate.currentPageSize);
  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getPaginationState(props.location, currentPageSize, 'id'), props.location.search)
  );

  const refAdtFormBolumSoruList = useAppSelector(state => state.refAdtFormBolumSoru.entities);
  const loading = useAppSelector(state => state.refAdtFormBolumSoru.loading);
  const updating = useAppSelector(state => state.refAdtFormBolumSoru.updating);
  const totalItems = useAppSelector(state => state.refAdtFormBolumSoru.totalItems);
  const totalItemsSecenek = useAppSelector(state => state.refAdtFormBolumSoruSecenek.totalItems);
  const refAdtFormBolum = useAppSelector(state => state.refAdtFormBolum.entity);
  const refAdtForm = useAppSelector(state => state.refAdtForm.entity);
  const formId = refAdtFormBolum && refAdtFormBolum.form ? refAdtFormBolum.form.id : null;

  useEffect(() => {
    if (props.match.params.id) {
      sortEntities();
      dispatch(getFormBolumEntity(props.match.params.id));
      dispatch(getReferans({ size: 2000, page: 0, sort: 'asc' }));
    }
  }, [props.match.params.id]);

  const getAllEntities = () => {
    dispatch(getKFilterBolumSoruEntities({ bolumId: props.match.params.id }));
  };

  const sortEntities = () => {
    getAllEntities();
  };

  useEffect(() => {
    const params = new URLSearchParams(props.location.search);
    const page = params.get('page');
    const sort = params.get(SORT);

    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [props.location.search]);

  const [isAdtFormBolumSoruUpdateModal, setIsAdtFormBolumSoruUpdateModal] = useState(false);
  const [selectedAdtFormBolumSoru, setSelectedAdtFormBolumSoru] = useState(null);
  const [selectedAdtFormBolum, setSelectedAdtFormBolum] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const handleCloseUpdateForm = () => {
    setIsAdtFormBolumSoruUpdateModal(false);
    setSelectedAdtFormBolumSoru(null);
  };

  const confirmAdtFormBolumSoruDelete = (AdtFormBolumSoru, bolum) => {
    setSelectedAdtFormBolumSoru(AdtFormBolumSoru);
    setSelectedAdtFormBolum(bolum);
    setIsDeleteModalOpen(true);
  };

  const handleDeleteConfirm = useCallback(async () => {
    if (selectedAdtFormBolumSoru) {
      await dispatch(deleteEntity({ AdtFormBolumSoruId: selectedAdtFormBolumSoru.id, bolumId: selectedAdtFormBolum.id }));
      handleDeleteCancel();
    }
  }, [selectedAdtFormBolumSoru]);

  const handleDeleteCancel = () => {
    setIsDeleteModalOpen(false);
    setSelectedAdtFormBolumSoru(null);
    setSelectedAdtFormBolum(null);
  };

  const handleDragEnd = debounce(result => {
    if (!result.destination) {
      return;
    }
    const { source, destination } = result;
    const startIndex = source.index;
    const endIndex = destination.index;

    const updatedList = [...refAdtFormBolumSoruList];
    const [removed] = updatedList.splice(startIndex, 1);
    updatedList.splice(endIndex, 0, removed);

    const updatedListWithIndexSort = updatedList.map((item, index) => ({
      ...item,
      sortIndex: index + 1,
    }));

    try {
      dispatch(updateListEntities(updatedListWithIndexSort));
    } catch (error) {
      console.error('Toplu güncelleme başarısız oldu:', error);
    }
  }, 500);

  const RefAdtFormBolumSoruUpdateModal = () => {
    return (
      <Modal isOpen={isAdtFormBolumSoruUpdateModal} toggle={handleCloseUpdateForm} size="xl" centered className="modal-style-lg">
        <ModalHeader data-cy="RefAdtFormCreateUpdateHeading" toggle={handleCloseUpdateForm}>
          <Translate contentKey="tk24AdysApp.refAdtFormSoru.home.createOrEditLabel">Create or edit a RefAdtForm</Translate>
        </ModalHeader>
        <RefAdtFormBolumSoruUpdate
          handleCloseForm={handleCloseUpdateForm}
          bolum={refAdtFormBolum}
          totalItems={totalItems}
          totalItemsSecenek={totalItemsSecenek}
          formValues={selectedAdtFormBolumSoru}
        ></RefAdtFormBolumSoruUpdate>
      </Modal>
    );
  };

  const AccordionCard = ({ soru, dragHandleProps }) => {
    const SoruSecenek = ({ soruId }) => {
      const [bolumSoruSecenek, setBolumSoruSecenek] = useState([]);
      const getAllSecenekEntities = () => {
        const soruSecenekUrl = `api/ref-adt-form-soru-seceneks?formSoruId.equals=${soruId}&sort=sortIndex,asc`;
        axios.get(soruSecenekUrl).then(response => {
          setBolumSoruSecenek(response.data);
        });
      };
      useEffect(() => {
        getAllSecenekEntities();
      }, [soruId]);

      const handleOptionDragEnd = debounce(async result => {
        if (!result.destination) {
          return;
        }

        const { source, destination } = result;
        const startIndex = source.index;
        const endIndex = destination.index;

        const updatedOptions = [...bolumSoruSecenek];
        const [removed] = updatedOptions.splice(startIndex, 1);
        updatedOptions.splice(endIndex, 0, removed);

        const updatedListWithIndexSort = updatedOptions.map((item, index) => ({
          ...item,
          sortIndex: index + 1,
        }));

        try {
          await dispatch(updateListSecenekEntities(updatedListWithIndexSort));
          sortEntities();
          getAllSecenekEntities();
          toast.success(translate('tk24AdysApp.refAdtFormSoruSecenek.secenekSiralandi'));
        } catch (error) {
          console.error('Toplu güncelleme başarısız oldu:', error);
        }
      }, 500);

      return (
        <div>
          {soru.soruTipi.id === ReferansEnum.SoruTipi.SerbestSayi && (
            <FormItem
              name={soru.soruTr}
              id={soru.soruTr}
              label={soru.soruTr && soru.soruEn ? `${soru.soruTr} / ${soru.soruEn}` : soru.soruTr || soru.soruEn}
              type="number"
              validation={{
                max: { value: 9999, message: translate('entity.validation.max', { max: 9999 }) },
                min: { value: 0, message: translate('entity.validation.min', { min: 0 }) },
              }}
            />
          )}
          {soru.soruTipi.id === ReferansEnum.SoruTipi.SerbestMetin && (
            <FormItem
              type="text"
              name={soru.soruTr}
              id={soru.soruTr}
              label={soru.soruTr && soru.soruEn ? `${soru.soruTr} / ${soru.soruEn}` : soru.soruTr || soru.soruEn}
            />
          )}
          {soru.soruTipi.id === ReferansEnum.SoruTipi.SerbestTarih && (
            <div>
              <FormItem
                name={soru.soruTr}
                id={soru.soruTr}
                label={soru.soruTr && soru.soruEn ? `${soru.soruTr} / ${soru.soruEn}` : soru.soruTr || soru.soruEn}
                type="date"
              />
            </div>
          )}
          {soru.soruTipi.id === ReferansEnum.SoruTipi.SerbestSaat && (
            <div>
              <FormItem
                name={soru.soruTr}
                id={soru.soruTr}
                label={soru.soruTr && soru.soruEn ? `${soru.soruTr} / ${soru.soruEn}` : soru.soruTr || soru.soruEn}
                type="time"
              />
            </div>
          )}
          {soru.soruTipi.id === ReferansEnum.SoruTipi.CoktanSecmeliTekCevap && (
            <div>
              <Label id={`${soru.soruTr}Label`}>
                {soru.soruTr && soru.soruEn ? `${soru.soruTr} / ${soru.soruEn}` : soru.soruTr || soru.soruEn}
              </Label>
              <DragDropContext onDragEnd={handleOptionDragEnd}>
                <Droppable droppableId={`optionList-${soru.id}`}>
                  {provided => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      {bolumSoruSecenek.map((option, optionIndex) => (
                        <Draggable key={option.id} draggableId={option.id.toString()} index={optionIndex}>
                          {innerProvided => (
                            <div
                              ref={innerProvided.innerRef}
                              {...innerProvided.draggableProps}
                              style={{
                                ...innerProvided.draggableProps.style,
                                marginBottom: '10px',
                                display: 'flex',
                                alignItems: 'start',
                              }}
                            >
                              <div className="mr-2">
                                <div {...innerProvided.dragHandleProps}>
                                  <Tooltip title="Sırala">
                                    <SwapVertIcon />
                                  </Tooltip>
                                </div>
                              </div>
                              <FormGroup check>
                                <Input name={`radio${soru.id}`} type="radio" style={{ width: '20px', height: '20px' }} />
                                <Label check className="ml-2">
                                  {option.secenekTr && option.secenekEn ? (
                                    <>
                                      <div>{`${option.secenekTr} / ${option.secenekEn}`}</div>
                                      <small>{option.aciklama}</small>
                                    </>
                                  ) : (
                                    <>
                                      <div>{option.secenekTr || option.secenekEn}</div>
                                      <small>{option.aciklama}</small>
                                    </>
                                  )}
                                </Label>
                              </FormGroup>
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </div>
          )}
          {soru.soruTipi.id === ReferansEnum.SoruTipi.CoktanSecmeliCokluCevap && (
            <div>
              <Label id={`${soru.soruTr}Label`}>
                {soru.soruTr && soru.soruEn ? `${soru.soruTr} / ${soru.soruEn}` : soru.soruTr || soru.soruEn}
              </Label>
              <DragDropContext onDragEnd={handleOptionDragEnd}>
                <Droppable droppableId={`optionList-${soru.id}`}>
                  {provided => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      {bolumSoruSecenek.map((option, optionIndex) => (
                        <Draggable key={option.id} draggableId={option.id.toString()} index={optionIndex}>
                          {innerProvided => (
                            <div
                              ref={innerProvided.innerRef}
                              {...innerProvided.draggableProps}
                              style={{
                                ...innerProvided.draggableProps.style,
                                marginBottom: '10px',
                                display: 'flex',
                                alignItems: 'start',
                              }}
                            >
                              <div className="mr-2">
                                <div {...innerProvided.dragHandleProps}>
                                  <Tooltip title="Sırala">
                                    <SwapVertIcon />
                                  </Tooltip>
                                </div>
                              </div>
                              <FormGroup check>
                                <Input name={`checkbox${soru.id}`} type="checkbox" style={{ width: '20px', height: '20px' }} />
                                <Label check className="ml-2">
                                  {option.secenekTr && option.secenekEn ? (
                                    <>
                                      <div>{`${option.secenekTr} / ${option.secenekEn}`}</div>
                                      <small>{option.aciklama}</small>
                                    </>
                                  ) : (
                                    <>
                                      <div>{option.secenekTr || option.secenekEn}</div>
                                      <small>{option.aciklama}</small>
                                    </>
                                  )}
                                </Label>
                              </FormGroup>
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </div>
          )}
          <div className="mt-3">
            <FormGroup check>
              <Input name="radio2" type="checkbox" defaultChecked={soru.cevaplanmasiZorunluMu.adi === 'Evet'} />{' '}
              <Label check> {translate('tk24AdysApp.refAdtFormSoru.cevaplanmasiZorunluMu')}</Label>
            </FormGroup>
            {soru.soruTipi.id === 164 && (
              <div>
                <FormGroup check>
                  <Input name="radio2" type="checkbox" defaultChecked={soru.digerSecenegiVarMi.adi === 'Evet'} />{' '}
                  <Label check>{translate('tk24AdysApp.refAdtFormSoru.digerSecenegiVarMi')}</Label>
                </FormGroup>
                <FormGroup check>
                  <Input name="radio2" type="checkbox" defaultChecked={soru.bilgiAlaniVarMi.adi === 'Evet'} />{' '}
                  <Label check>{translate('tk24AdysApp.refAdtFormSoru.bilgiAlaniVarMi')}</Label>
                </FormGroup>
              </div>
            )}
            {soru.soruTipi.id === 165 && (
              <div>
                <FormGroup check>
                  <Input name="radio2" type="checkbox" defaultChecked={soru.digerSecenegiVarMi.adi === 'Evet'} />{' '}
                  <Label check>{translate('tk24AdysApp.refAdtFormSoru.digerSecenegiVarMi')}</Label>
                </FormGroup>
                <FormGroup check>
                  <Input name="radio2" type="checkbox" defaultChecked={soru.bilgiAlaniVarMi.adi === 'Evet'} />{' '}
                  <Label check>{translate('tk24AdysApp.refAdtFormSoru.bilgiAlaniVarMi')}</Label>
                </FormGroup>
              </div>
            )}
          </div>
        </div>
      );
    };

    return (
      <div className={`card mb-3`}>
        <div {...dragHandleProps}>
          <Tooltip title="Sırala">
            <SwapVertIcon />
          </Tooltip>
        </div>
        <div className="card-header" style={{ backgroundColor: 'transparent' }}>
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <div className="d-flex align-items-center">
                <h6 className="card-title m-0 mr-2">Soru Tipi: </h6>
                <p className="card-text m-0"> {soru.soruTipi.adi}</p>
              </div>
              {soru.aciklama && (
                <div className="d-flex align-items-center">
                  <h6 className="card-title m-0 mr-2">
                    {' '}
                    <Translate contentKey="tk24AdysApp.refAdtFormSoru.aciklama">Aciklama</Translate>
                  </h6>
                  <p className="card-text m-0"> {soru.aciklama}</p>
                </div>
              )}
            </div>
            <div className="btn-group flex-btn-group-container">
              <GrantedButton
                grantedAuthorites={['ref_adt_form_soru_edit']}
                className="edit-button"
                onClick={() => {
                  setSelectedAdtFormBolumSoru(soru);
                  setIsAdtFormBolumSoruUpdateModal(true);
                }}
                size="sm"
                data-cy="entityEditButton"
              >
                <img width={14} height={14} src={`${SERVER_URL}/content/images/icon/faPencil.svg`} />
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.edit">Edit</Translate>
                </span>
              </GrantedButton>
              <GrantedButton
                grantedAuthorites={['ref_adt_form_soru_delete']}
                onClick={() => confirmAdtFormBolumSoruDelete(soru, soru.bolum)}
                style={{ marginLeft: 5 }}
                className="delete-button"
                size="sm"
                data-cy="entityDeleteButton"
              >
                <img width={11.2} height={12.8} src={`${SERVER_URL}/content/images/icon/faTrashCan.svg`} />
                <span>
                  <Translate contentKey="entity.action.delete">Delete</Translate>
                </span>
              </GrantedButton>
            </div>
          </div>
        </div>
        <div className="card-body">
          <SoruSecenek soruId={soru.id} />
        </div>
      </div>
    );
  };

  return (
    <>
      {(loading || updating) && <LoadingSpinner />}
      {selectedAdtFormBolumSoru && (
        <CustomDeleteEntityConfirm
          entity={selectedAdtFormBolumSoru}
          entityName="refAdtFormSoru"
          isOpen={isDeleteModalOpen}
          onConfirm={handleDeleteConfirm}
          onCancel={handleDeleteCancel}
        />
      )}
      <div>
        <div className="d-flex justify-content-between align-items-center mb-4">
          <div>
            <h2 id="ref-adt-form-heading" data-cy="RefAdtFormHeading">
              <Translate contentKey="tk24AdysApp.refAdtFormSoru.home.title">Ref Adt Form Unit Questions</Translate>
            </h2>
            {refAdtFormBolumSoruList.slice(0, 1).map((refAdtFormBolumSoru, i) => (
              <div key={refAdtFormBolumSoru.id} className="d-flex align-items-center mt-4">
                <p className="mr-3">
                  <span className="font-weight-bold">
                    <Translate contentKey="tk24AdysApp.refAdtFormBolum.formAdiTr" />:
                  </span>{' '}
                  {refAdtForm.adiTr}
                </p>
                <p className="mr-3">
                  <span className="font-weight-bold">
                    <Translate contentKey="tk24AdysApp.refAdtFormBolum.formAdiEn" />:
                  </span>{' '}
                  {refAdtForm.adiEn}
                </p>
              </div>
            ))}
            {refAdtFormBolumSoruList.slice(0, 1).map((refAdtFormBolumSoru, i) => (
              <div key={refAdtFormBolumSoru.id} className="d-flex align-items-center">
                <p className="mr-3">
                  <span className="font-weight-bold">{refAdtFormBolum.adiTr} Formuna Ait Bölüm Adı(Türkçe):</span> {refAdtFormBolum.adiTr}
                </p>
                <p className="mr-3">
                  <span className="font-weight-bold">{refAdtFormBolum.adiEn} Formuna Ait Bölüm Adı(İngilizce):</span>{' '}
                  {refAdtFormBolum.adiEn}
                </p>
              </div>
            ))}
          </div>
          <div className="d-flex">
            <Button className="download-button" tag={Link} to={`/ref-adt-form-bolum/${formId}`}>
              <Tooltip title={<Translate contentKey="entity.action.back">Geri Dön </Translate>}>
                <ArrowBackIcon />
              </Tooltip>
              <span className="show-on-lg">
                <Translate contentKey="entity.action.back">Geri Dön </Translate>
              </span>
            </Button>
            <GrantedButton
              grantedAuthorites={['ref_adt_form_soru_edit']}
              style={{ marginLeft: 15 }}
              onClick={() => {
                setIsAdtFormBolumSoruUpdateModal(true);
              }}
              className="create-button"
              size="sm"
              data-cy="entityCreateButton"
            >
              <Tooltip title={<Translate contentKey="tk24AdysApp.refAdtFormSoru.home.createLabel" />}>
                <img width={14} height={14} src={`${SERVER_URL}/content/images/icon/faPlus.svg`} />
              </Tooltip>
              <span className="show-on-lg">
                <Translate contentKey="tk24AdysApp.refAdtFormSoru.home.createLabel" />
              </span>
            </GrantedButton>
          </div>
        </div>
        <RefAdtFormBolumSoruUpdateModal />
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="refAdtFormBolumList">
            {provided => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {refAdtFormBolumSoruList && refAdtFormBolumSoruList.length > 0 ? (
                  refAdtFormBolumSoruList.map((refAdtFormBolumSoru, index) => (
                    <Draggable key={refAdtFormBolumSoru.id} draggableId={refAdtFormBolumSoru.id.toString()} index={index}>
                      {innerProvided => (
                        <div ref={innerProvided.innerRef} {...innerProvided.draggableProps}>
                          <AccordionCard soru={refAdtFormBolumSoru} dragHandleProps={innerProvided.dragHandleProps} />
                        </div>
                      )}
                    </Draggable>
                  ))
                ) : !loading ? (
                  <div className="alert alert-warning">
                    <Translate contentKey="tk24AdysApp.refAdtFormSoru.home.notFound">No Ref Adt Forms found</Translate>
                  </div>
                ) : null}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </>
  );
};

export default RefAdtFormBolumSoru;
