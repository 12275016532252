import React, { useEffect } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, UncontrolledTooltip, Row, Col } from 'reactstrap';
import { Translate } from 'app/component/jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { getEntity } from './kriz-katilimci.reducer';
import { useAppDispatch, useAppSelector } from 'app/config/store';

export const KrizKatilimciDetail = (props: RouteComponentProps<{ id: string }>) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getEntity(props.match.params.id));
  }, []);

  const krizKatilimciEntity = useAppSelector(state => state.krizKatilimci.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="krizKatilimciDetailsHeading">
          <Translate contentKey="tk24AdysApp.krizKatilimci.detail.title">KrizKatilimci</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="orgId">
              <Translate contentKey="tk24AdysApp.krizKatilimci.orgId">Org Id</Translate>
            </span>
            <UncontrolledTooltip target="orgId">
              <Translate contentKey="tk24AdysApp.krizKatilimci.help.orgId" />
            </UncontrolledTooltip>
          </dt>
          <dd>{krizKatilimciEntity.org?.orgId}</dd>

          <dt>
            <span id="sicil">
              <Translate contentKey="tk24AdysApp.krizKatilimci.sicil">Sicil</Translate>
            </span>
          </dt>
          <dd>{krizKatilimciEntity.sicil}</dd>

          <dt>
            <span id="adi">
              <Translate contentKey="tk24AdysApp.krizKatilimci.adi">Adi</Translate>
            </span>
            <UncontrolledTooltip target="adi">
              <Translate contentKey="tk24AdysApp.krizKatilimci.help.adi" />
            </UncontrolledTooltip>
          </dt>
          <dd>{krizKatilimciEntity.adi}</dd>

          <dt>
            <span id="soyadi">
              <Translate contentKey="tk24AdysApp.krizKatilimci.soyadi">Soyadi</Translate>
            </span>
          </dt>
          <dd>{krizKatilimciEntity.soyadi}</dd>

          <dt>
            <span id="soyadi">
              <Translate contentKey="tk24AdysApp.krizKatilimci.unvanAdi">Unvan</Translate>
            </span>
          </dt>
          <dd>{krizKatilimciEntity.unvanAdi}</dd>

          <dt>
            <span id="orgAdiTr">
              <Translate contentKey="tk24AdysApp.krizKatilimci.orgAdiTr">Org Adi Tr</Translate>
            </span>
            <UncontrolledTooltip target="orgAdiTr">
              <Translate contentKey="tk24AdysApp.krizKatilimci.help.orgAdiTr" />
            </UncontrolledTooltip>
          </dt>
          <dd>{krizKatilimciEntity.org?.adiTr}</dd>

          <dt>
            <span id="orgAdiEn">
              <Translate contentKey="tk24AdysApp.krizKatilimci.orgAdiEn">Org Adi En</Translate>
            </span>
            <UncontrolledTooltip target="orgAdiEn">
              <Translate contentKey="tk24AdysApp.krizKatilimci.help.orgAdiEn" />
            </UncontrolledTooltip>
          </dt>
          <dd>{krizKatilimciEntity.org?.adiEn}</dd>

          <dt>
            <span id="eposta">
              <Translate contentKey="tk24AdysApp.krizKatilimci.eposta">Eposta</Translate>
            </span>
            <UncontrolledTooltip target="eposta">
              <Translate contentKey="tk24AdysApp.krizKatilimci.help.eposta" />
            </UncontrolledTooltip>
          </dt>
          <dd>
            <a href={`mailto:${krizKatilimciEntity.eposta}`} className="epostaText">
              {krizKatilimciEntity.eposta}
            </a>
          </dd>
          <dt>
            <span id="gsmNo">
              <Translate contentKey="tk24AdysApp.krizKatilimci.gsmNo">Gsm No</Translate>
            </span>
            <UncontrolledTooltip target="gsmNo">
              <Translate contentKey="tk24AdysApp.krizKatilimci.help.gsmNo" />
            </UncontrolledTooltip>
          </dt>
          <dd>{krizKatilimciEntity.gsmNo}</dd>

          <dt>
            <Translate contentKey="tk24AdysApp.krizKatilimci.kriz">Kriz</Translate>
          </dt>
          <dd>{krizKatilimciEntity.kriz ? krizKatilimciEntity.kriz.id : ''}</dd>

          <dt>
            <Translate contentKey="tk24AdysApp.krizKatilimci.adi">Katilimci Adi</Translate>
          </dt>
          <dd>{krizKatilimciEntity.katilimci ? krizKatilimciEntity.katilimci.adi : ''}</dd>

          <dt>
            <Translate contentKey="tk24AdysApp.krizKatilimci.soyadi">Katilimci Soyadi</Translate>
          </dt>
          <dd>{krizKatilimciEntity.katilimci ? krizKatilimciEntity.katilimci.soyadi : ''}</dd>

          <dt>
            <Translate contentKey="tk24AdysApp.krizKatilimci.vekilKatilimci">Vekil Katilimci</Translate>
          </dt>
          <dd>{krizKatilimciEntity.vekilKatilimci ? krizKatilimciEntity.vekilKatilimci.id : ''}</dd>

          <dt>
            <Translate contentKey="tk24AdysApp.krizKatilimci.toplantidaMi">Toplantida Mi</Translate>
          </dt>
          <dd>{krizKatilimciEntity.toplantidaMi ? krizKatilimciEntity.toplantidaMi.adi : ''}</dd>

          <dt>
            <Translate contentKey="tk24AdysApp.krizKatilimci.katilimciRolu">Katilimci Rolu</Translate>
          </dt>
          <dd>{krizKatilimciEntity.katilimciRolu ? krizKatilimciEntity.katilimciRolu.adi : ''}</dd>
        </dl>
        <Button tag={Link} to="/kriz-katilimci" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/kriz-katilimci/${krizKatilimciEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default KrizKatilimciDetail;
