import React, { useCallback, useEffect, useRef, useState } from 'react';
import { TextFormat, Translate, translate } from 'app/component/jhipster';
import { Button, Col, Form, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntities as getRefEkDosyaTipis } from 'app/entities/ref-ek-dosya-tipi/ref-ek-dosya-tipi.reducer';
import {
  uploadEntity,
  getFilteredEntities as getFilterKrizMedyaEkDosyaEntities,
  deleteEntity as deleteEkDosyaEntity,
  getTotalFilteredEntities,
  updateEntity,
  createEntity,
} from 'app/entities/ek-dosya/ek-dosya.reducer';
import axios from 'axios';
import TableNameEnum from 'app/constants/TableNameEnum';
import { ReferansEnum } from 'app/constants/ReferansEnum';
import { CustomDeleteEntityConfirm, CustomSaveEntityConfirm, isNullOrEmpty, isObjectEmpty } from 'app/shared/util/confirm-utils';
import { LoadingSpinner } from 'app/component/LoadingSpinner';
import { RefEkDosyaTipiCodeEnum } from 'app/constants/RefEkDosyaTipiCodeEnum';
import { GetBase64PromiseString, getFileNameAndExtension, localeLanguage, upperOnlyFirstLetter } from 'app/shared/util/util';
import { IEkDosya } from 'app/shared/model/ek-dosya.model';
import { FormItem } from 'app/component/FormItem';
import { APP_DATE_FORMAT, SERVER_URL } from 'app/config/constants';
import { IQueryParams } from 'app/shared/reducers/reducer.utils';
import CustomPagination from 'app/component/CustomPagination';
import {
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Table,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import GrantedButton from 'app/component/GrantedButton';
import ExcelDownloadButton from 'app/component/ExcelDownload';
import { ASC, DESC } from 'app/shared/util/pagination.constants';
import SortIcon from 'app/component/SortIcon';
import { MenuProps } from 'app/shared/util/filter.constants';

const KrizMedyaAcilDurum = () => {
  const dispatch = useAppDispatch();
  const kriz = useAppSelector(state => state.kriz.entity);

  const [isMedyaEkDosyaModal, setIsMedyaEkDosyaModal] = useState(false);
  const refEkDosyaTipis = useAppSelector(state => state.refEkDosyaTipi.entities);
  const krizMedyaEkDosyaList = useAppSelector(state => state.ekDosya.entities);
  const totalItem = useAppSelector(state => state.ekDosya.totalItems);
  const loading = useAppSelector(state => state.ekDosya.loading);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const fileInputRef = useRef(null);
  const [ekDosyaLoading, setEkDosyaLoading] = useState(false);
  const [fileNames, setFileNames] = useState({});

  const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);
  const [modalPromiseResolve, setModalPromiseResolve] = useState(null);

  const [search, setSearch] = useState('');
  const [selectedEkDosyaTipi, setSelectedEkDosyaTipi] = useState('');
  const [isEkDosyaUpdateModal, setIsEkDosyaUpdateModal] = useState(false);

  const [pageObject, setPageObject] = useState<IQueryParams>(useAppSelector(state => state.paginate.pageObject));
  const [selectedFile, setSelectedFile] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [ekDosyaAciklama, setEkDosyaAciklama] = useState('');

  const krizEkDosyaTipis = refEkDosyaTipis
    .filter(res => res.parent && res.parent.code === RefEkDosyaTipiCodeEnum.KrizDokuman)
    .map(ekDosya => ekDosya.id);

  const ExcelSampleData = [
    { key: 'dosyaAdi', titleContentKey: 'tk24AdysApp.ekDosya.dosyaAdi' },
    { key: 'aciklama', titleContentKey: 'tk24AdysApp.ekDosya.aciklama' },
    { key: 'ekDosyaTipi.adi', titleContentKey: 'tk24AdysApp.ekDosya.ekDosyaTipi' },
    { key: 'fileExtention', titleContentKey: 'tk24AdysApp.ekDosya.fileExtention' },
    { key: 'ekleyenKullanici.adi', titleContentKey: 'tk24AdysApp.ekDosya.ekleyenKullaniciAdi' },
    { key: 'ekleyenKullanici.soyadi', titleContentKey: 'tk24AdysApp.ekDosya.ekleyenKullaniciSoyadi' },
    { key: 'cDate', format: 'date', titleContentKey: 'tk24AdysApp.ekDosya.cDate' },
  ];

  useEffect(() => {
    dispatch(getRefEkDosyaTipis({ size: 2000, page: 0, sort: 'id,asc', hasParent: true }));
  }, []);

  useEffect(() => {
    dispatch(
      getFilterKrizMedyaEkDosyaEntities({
        ...pageObject,
        ilgiliTabloKayitId: kriz.realId,
        ilgiliTablo: TableNameEnum.Kriz,
        ekDosyaTipis: krizEkDosyaTipis,
      })
    );
  }, [pageObject.page, pageObject.query, pageObject.size, pageObject.sort]);

  useEffect(() => {
    if (search.length > 2) {
      setPageObject({ ...pageObject, page: 0, query: `globalFilter=${search}` });
    } else if (search.length === 0) {
      setPageObject({ ...pageObject, page: 0, query: '' });
    }
  }, [search]);

  const sort = p => () => {
    const orderBy = pageObject.sort.split(',')[1];
    const newSort = `${p},${orderBy === ASC ? DESC : ASC}`;
    setPageObject({ ...pageObject, sort: newSort });
  };

  const handlePageChange = (pageNumber, newItemsPerPage = pageObject.size) => {
    if (newItemsPerPage !== pageObject.size) {
      setPageObject({ ...pageObject, page: pageNumber - 1, size: newItemsPerPage });
    } else {
      setPageObject({ ...pageObject, page: pageNumber - 1 });
    }
  };

  const handleCloseEkDosyaModal = () => {
    setSelectedFiles([]);
    setFileNames({});
    setEkDosyaAciklama('');
    setIsMedyaEkDosyaModal(false);
  };

  const handleOpenEkDosyaModal = () => {
    setIsMedyaEkDosyaModal(true);
  };

  const handleDrop = event => {
    event.preventDefault();
    const files = Array.from(event.dataTransfer.files);
    setSelectedFiles([...selectedFiles, ...files]);
  };

  const handleFileNameChange = (index, newName) => {
    setFileNames(prevNames => ({
      ...prevNames,
      [index]: newName,
    }));

    const updatedFiles = selectedFiles.map((file, fileIndex) => {
      if (fileIndex === index) {
        const { extension } = getFileNameAndExtension(file.name);
        return new File([file], `${newName}${extension}`, { type: file.type });
      }
      return file;
    });

    setSelectedFiles(updatedFiles);
  };

  const handleFileChange = e => {
    const files = e.target.files;
    setSelectedFiles([...selectedFiles, ...files]);
  };

  const showSaveConfirmModal = () => {
    setIsSaveModalOpen(true);
    return new Promise(resolve => {
      setModalPromiseResolve(() => resolve);
    });
  };

  const handleConfirm = () => {
    if (modalPromiseResolve) {
      modalPromiseResolve(true);
    }
    setIsSaveModalOpen(false);
  };

  const handleCancel = () => {
    if (modalPromiseResolve) {
      modalPromiseResolve(false);
    }
    setIsSaveModalOpen(false);
  };

  const handleRemoveFile = index => {
    const newFiles = [...selectedFiles];
    newFiles.splice(index, 1);
    setSelectedFiles(newFiles);
  };

  const handleDownloadSavedFile = async file => {
    setEkDosyaLoading(true);
    try {
      const response = await axios.get(`api/ek-dosyas/download/${file.id}`, {
        responseType: 'blob',
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${file.dosyaAdi}`);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error('Dosya indirme hatası:', error);
    }
    setEkDosyaLoading(false);
  };

  const handleRemoveSavedFile = file => {
    setSelectedFile(file);
    setIsDeleteModalOpen(true);
  };

  const handleDeleteConfirm = useCallback(async () => {
    if (selectedFile) {
      setEkDosyaLoading(true);
      await dispatch(deleteEkDosyaEntity(selectedFile.id));
      dispatch(
        getFilterKrizMedyaEkDosyaEntities({
          ...pageObject,
          ilgiliTabloKayitId: kriz.realId,
          ilgiliTablo: TableNameEnum.Kriz,
          ekDosyaTipis: krizEkDosyaTipis,
        })
      );
      setEkDosyaLoading(false);
      handleDeleteCancel();
    }
  }, [selectedFile, dispatch]);

  const handleDeleteCancel = () => {
    setIsDeleteModalOpen(false);
    setSelectedFile(null);
  };

  const handleEkDosyaAciklamaChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEkDosyaAciklama(event.target.value);
  };

  const saveEntity = async values => {
    values.preventDefault();

    const isSave = await showSaveConfirmModal();
    setEkDosyaLoading(true);

    if (isSave) {
      const formDataObject = {};
      for (const field of values.target.elements) {
        if (field.name) {
          formDataObject[field.name] = field.value;
        }
      }

      const ekDosya = {
        ilgiliTablo: TableNameEnum.Kriz,
        ilgiliTabloKayitId: kriz.realId,
        onaylandiMi: ReferansEnum.EvetHayir.Hayir,
        ekDosyaTipi: refEkDosyaTipis.find(it => it.id.toString() === formDataObject['ekDosyaTipi'].toString()),
      };

      const fileInfoArray = await GetBase64PromiseString(selectedFiles);

      const entity = {
        ...ekDosya,
        fileList: fileInfoArray.map((file, index) => {
          const { extension } = getFileNameAndExtension(file.fileName);
          const updatedName = fileNames[index] || getFileNameAndExtension(file.fileName).name;
          return {
            ...file,
            fileName: `${updatedName}${extension}`,
            aciklama: ekDosyaAciklama,
          };
        }),
      };

      await dispatch(uploadEntity(entity));

      dispatch(
        getFilterKrizMedyaEkDosyaEntities({
          ...pageObject,
          ilgiliTabloKayitId: kriz.realId,
          ilgiliTablo: TableNameEnum.Kriz,
          ekDosyaTipis: krizEkDosyaTipis,
        })
      );
    }

    handleCloseEkDosyaModal();
    setEkDosyaLoading(false);
  };

  const handleEkDosyaTipiChange = (elem, event) => {
    if (!isNullOrEmpty(event.target.value)) {
      setPageObject(prevPageObject => ({
        ...prevPageObject,
        page: 0,
        query: prevPageObject.query + `&${elem}.equals=${event.target.value}`,
      }));
    } else {
      const keyToDelete = `${elem}.equals`;
      const updatedParams = pageObject.query
        .split('&')
        .filter(param => {
          const [key, _] = param.split('=');
          return key !== keyToDelete;
        })
        .join('&');
      setPageObject({ ...pageObject, page: 0, query: updatedParams });
    }
  };

  const fetchTotalKrizEkDosyaData = async (): Promise<IEkDosya[]> => {
    try {
      const response = (await dispatch(
        getTotalFilteredEntities({
          ...pageObject,
          size: totalItem,
          ilgiliTabloKayitId: kriz.realId,
          ilgiliTablo: TableNameEnum.Kriz,
        })
      )) as { payload: { data: IEkDosya[] } };

      return response.payload.data || [];
    } catch (error) {
      console.error('Error fetching data:', error);
      return [];
    }
  };

  const handleCloseUpdateForm = () => {
    if (isEkDosyaUpdateModal) {
      setIsEkDosyaUpdateModal(false);
    }
    setSelectedFile(null);
  };

  const saveNewEkDosya = async e => {
    e.preventDefault();

    setIsEkDosyaUpdateModal(false);

    const isSave = await showSaveConfirmModal();
    if (isSave) {
      const formDataObject = {};
      for (const field of e.target.elements) {
        if (field.name) {
          formDataObject[field.name] = field.value;
        }
      }

      const ekDosyaEntity = {
        ...selectedFile,
        ...formDataObject,
        ekDosyaTipi: refEkDosyaTipis.find(it => it?.id?.toString() === formDataObject['ekDosyaTipi'].toString()),
      };

      if (selectedFile !== null) {
        await dispatch(
          updateEntity({
            ...pageObject,
            entity: ekDosyaEntity,
            ilgiliTabloKayitId: kriz.realId,
            ilgiliTablo: TableNameEnum.Kriz,
            ekDosyaTipis: krizEkDosyaTipis,
          })
        );
      }

      handleCloseUpdateForm();
    } else {
      setIsEkDosyaUpdateModal(true);
    }
  };

  const EkDosyaUpdateModal = () => {
    return (
      <Modal className="modal-style" isOpen={isEkDosyaUpdateModal} toggle={handleCloseUpdateForm} centered>
        <ModalHeader data-cy="EkDosyaCreateUpdateHeading" toggle={handleCloseUpdateForm}>
          {selectedFile && (
            <p className="modal-title-info">
              {selectedFile?.ekDosyaTipi?.adi}-{selectedFile?.dosyaAdi}
            </p>
          )}
          <Translate contentKey="tk24AdysApp.ekDosya.home.updateLabel">Update a ekDosya</Translate>
        </ModalHeader>
        <Form onSubmit={saveNewEkDosya}>
          <ModalBody>
            <FormItem
              label={translate('tk24AdysApp.ekDosya.ekDosyaTipi')}
              id="ref-ek-dosya-tipi-id"
              name="ekDosyaTipi"
              defaultValue={
                selectedFile && refEkDosyaTipis.length > 0 && selectedFile['ekDosyaTipi'] !== null && selectedFile.ekDosyaTipi.id
              }
              type="select"
              validation={{
                required: { value: true, message: translate('entity.validation.required') },
              }}
            >
              <option value="" key="0" />
              {refEkDosyaTipis
                .filter(res => res.parent && res.parent.code === RefEkDosyaTipiCodeEnum.KrizDokuman)
                .map(otherEntity => (
                  <option value={otherEntity.id} key={otherEntity.id}>
                    {otherEntity.adi}
                  </option>
                ))}
            </FormItem>

            <FormItem
              label={translate('tk24AdysApp.ekDosya.dosyaAdi')}
              id="dosyaAdiId"
              name="dosyaAdi"
              defaultValue={selectedFile && selectedFile['dosyaAdi']}
              type="text"
              validation={{
                required: { value: true, message: translate('entity.validation.required') },
                maxLength: { value: 1000, message: translate('entity.validation.maxlength', { max: 100 }) },
              }}
            />

            <FormItem
              label={translate('tk24AdysApp.ekDosya.aciklama')}
              id="aciklamaId"
              name="aciklama"
              defaultValue={selectedFile && selectedFile['aciklama']}
              type="textarea"
              validation={{
                maxLength: { value: 1000, message: translate('entity.validation.maxlength', { max: 100 }) },
              }}
            />
          </ModalBody>
          <ModalFooter className="modal-footer">
            <Button className="cancel-button" onClick={handleCloseUpdateForm}>
              <Translate contentKey="entity.action.cancel">Cancel</Translate>
            </Button>
            &nbsp;
            <Button className="save-button" id="jhi-confirm-delete-refAdtForm" type="submit" data-cy="entityConfirmButton">
              <Translate contentKey="entity.action.save">Kaydet</Translate>
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    );
  };

  const uploadKrizMedyaEkDosya = () => {
    return (
      <Modal isOpen={isMedyaEkDosyaModal} toggle={handleCloseEkDosyaModal} className="modal-style" centered>
        <ModalHeader toggle={handleCloseEkDosyaModal}>
          <Translate contentKey="tk24AdysApp.krizMedya.dosyaYukle" />
        </ModalHeader>
        <Form onSubmit={saveEntity}>
          <ModalBody style={{ maxHeight: '550px', overflowY: 'auto' }}>
            <FormItem
              label={translate('tk24AdysApp.ekDosya.ekDosyaTipi')}
              id="ref-ek-dosya-tipi-id"
              name="ekDosyaTipi"
              type="select"
              validation={{
                required: { value: true, message: translate('entity.validation.required') },
              }}
            >
              <option value="" key="0" />
              {refEkDosyaTipis
                .filter(res => res.parent && res.parent.code === RefEkDosyaTipiCodeEnum.KrizDokuman)
                .map(otherEntity => (
                  <option value={otherEntity.id} key={otherEntity.id}>
                    {otherEntity.adi}
                  </option>
                ))}
            </FormItem>
            <FormItem
              label={translate('tk24AdysApp.ekDosya.ekDosyaAciklama')}
              id="ek-dosya-aciklama"
              name="aciklama"
              defaultValue={ekDosyaAciklama}
              onChange={handleEkDosyaAciklamaChange}
              type="textarea"
              validation={{
                maxLength: { value: 1000, message: translate('entity.validation.maxlength', { max: 1000 }) },
              }}
            />

            <div className="modal-file-area">
              <div className="modal-dashed-file-area" onDrop={handleDrop} onDragOver={e => e.preventDefault()}>
                <img width={16} height={20} style={{ marginBottom: '20px' }} src={`${SERVER_URL}/content/images/icon/downloadIcon.svg`} />
                <span className="modal-file-text">
                  <span className="modal-upload-file-text" onClick={() => fileInputRef.current.click()}>
                    <Translate contentKey="tk24AdysApp.ekDosya.yukle" />
                  </span>
                  <span style={{ color: 'rgba(73, 88, 106, 1)' }}>
                    {' '}
                    <Translate contentKey="tk24AdysApp.ekDosya.surukle" />
                  </span>
                  <input type="file" ref={fileInputRef} style={{ display: 'none' }} onChange={handleFileChange} multiple />
                </span>
              </div>
              <div className="modal-file-list-area">
                {selectedFiles.length > 0 && (
                  <>
                    {selectedFiles.length > 0 &&
                      selectedFiles.map((file, index) => {
                        const { name, extension } = getFileNameAndExtension(file.name);
                        return (
                          <div key={file.id} className="modal-file-list-row">
                            <div style={{ display: 'flex', justifyContent: 'start', alignItems: 'center', padding: '0px', width: '50px' }}>
                              <img width={16} height={20} src={`${SERVER_URL}/content/images/icon/downloadIcon.svg`} />
                            </div>
                            <div style={{ width: '100%' }}>
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  alignItems: 'start',
                                  justifyContent: 'center',
                                  margin: 'auto',
                                }}
                              >
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                  <input
                                    type="text"
                                    value={fileNames[index] || name}
                                    onChange={e => handleFileNameChange(index, e.target.value)}
                                    className="form-control"
                                    style={{ width: '300px' }}
                                    maxLength={950}
                                  />
                                  <span className="ml-2">{extension}</span>
                                </div>
                                <span className="modal-file-size">{`${(file.size / 1024).toFixed(2)}kb`}</span>
                              </div>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'end', alignItems: 'center', padding: '0px', width: '50px' }}>
                              <Button className="delete-file-button" onClick={() => handleRemoveFile(index)}>
                                <img width={12} height={15} src={`${SERVER_URL}/content/images/icon/deleteFileIcon.svg`} />
                              </Button>
                            </div>
                          </div>
                        );
                      })}
                  </>
                )}
              </div>
            </div>
          </ModalBody>

          <ModalFooter className="modal-footer">
            <Button className="cancel-button" onClick={handleCloseEkDosyaModal} replace>
              <Translate contentKey="entity.action.cancel">Cancel</Translate>
            </Button>
            &nbsp;
            <Button
              className="save-button"
              disabled={selectedFiles.length < 1}
              id="jhi-confirm-delete-refAdtForm"
              type="submit"
              data-cy="entityConfirmButton"
            >
              <Translate contentKey="entity.action.save">Kaydet</Translate>
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    );
  };

  return (
    !isObjectEmpty(kriz) && (
      <div style={{ margin: 'auto' }}>
        {(loading || ekDosyaLoading) && <LoadingSpinner />}
        {selectedFile && (
          <CustomDeleteEntityConfirm
            entity={selectedFile}
            entityName="ekDosya"
            isOpen={isDeleteModalOpen}
            onConfirm={handleDeleteConfirm}
            onCancel={handleDeleteCancel}
          />
        )}
        <CustomSaveEntityConfirm entity={selectedFiles} isOpen={isSaveModalOpen} onConfirm={handleConfirm} onCancel={handleCancel} />

        {isEkDosyaUpdateModal && EkDosyaUpdateModal()}

        {isMedyaEkDosyaModal && uploadKrizMedyaEkDosya()}
        <Row style={{ margin: 'auto', minWidth: '100%' }}>
          <div className="search-frame w-100">
            <div className="seachiconposition">
              <img className="seachicon" width={24} height={24} src={`${SERVER_URL}/content/images/icon/faSearch.svg`} />
              <Input
                value={search}
                onChange={e => setSearch(e.target.value)}
                placeholder={translate('entity.action.search')}
                className="search-input"
              />
            </div>
            <div className="d-flex justify-end">
              <Box sx={{ minWidth: 220, maxWidth: 220, marginRight: '15px' }}>
                <FormControl fullWidth size={window.innerWidth < 1600 ? 'small' : 'medium'}>
                  <InputLabel id="ek-dosya-tipi-label" className="select-input">
                    <Translate contentKey="tk24AdysApp.ekDosya.ekDosyaTipi" />
                  </InputLabel>
                  <Select
                    value={selectedEkDosyaTipi}
                    labelId="ek-dosya-tipi-label"
                    id="ek-dosya-tipi"
                    label={translate('tk24AdysApp.ekDosya.ekDosyaTipi')}
                    className="select-input"
                    onChange={event => {
                      setSelectedEkDosyaTipi(event.target.value);
                      handleEkDosyaTipiChange('ekDosyaTipiId', event);
                    }}
                    MenuProps={MenuProps}
                  >
                    <MenuItem value="">
                      <Translate contentKey="entity.action.all" />
                    </MenuItem>
                    {refEkDosyaTipis &&
                      refEkDosyaTipis
                        .filter(res => res.parent && res.parent.code === RefEkDosyaTipiCodeEnum.KrizDokuman)
                        .map(item => (
                          <MenuItem key={item.id} value={item.id}>
                            {item.adi}
                          </MenuItem>
                        ))}
                  </Select>
                </FormControl>
              </Box>
              <ExcelDownloadButton
                excelSampleData={ExcelSampleData}
                grantedAuthorites={['ek_dosya_export']}
                fetchData={fetchTotalKrizEkDosyaData}
                className={'download-button'}
                exportName={`${translate('tk24AdysApp.krizDetay.medya')} ${translate('tk24AdysApp.krizMedya.header.kriz')}`}
              />
              <GrantedButton
                id={`create-button`}
                grantedAuthorites={['ref_ek_dosya_tipi_edit']}
                style={{ marginLeft: 15 }}
                onClick={handleOpenEkDosyaModal}
                comparison={[
                  {
                    value: kriz.krizDurumu.id,
                    checkValue: ReferansEnum.KrizDurumu.Aktif,
                    contentKey: 'error.comparison.crisisIsPassive',
                  },
                ]}
                className="create-button"
                size="sm"
                data-cy="entityCreateButton"
              >
                <img width={14} height={14} src={`${SERVER_URL}/content/images/icon/faPlus.svg`} />
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.newRecord" />
                </span>
              </GrantedButton>
            </div>
          </div>
        </Row>

        <Row style={{ minWidth: '100%' }}>
          <Col>
            <TableContainer className="content-table">
              {krizMedyaEkDosyaList && krizMedyaEkDosyaList.length > 0 ? (
                <Table aria-label="a dense table">
                  <TableHead className="content-table-title-area">
                    <TableRow className="table-head-row">
                      <TableCell className="table-title-cell hand" align="left" onClick={sort('dosyaAdi')}>
                        <div className="tableHeaderContainer">
                          <Translate contentKey="tk24AdysApp.ekDosya.dosyaAdi">dosyaAdi</Translate>
                          <SortIcon column="dosyaAdi" activeSortColumn={pageObject.sort} />
                        </div>
                      </TableCell>
                      <TableCell className="table-title-cell hand" align="left" onClick={sort('aciklama')}>
                        <div className="tableHeaderContainer">
                          <Translate contentKey="tk24AdysApp.ekDosya.aciklamaTitle">aciklama</Translate>
                          <SortIcon column="aciklama" activeSortColumn={pageObject.sort} />
                        </div>
                      </TableCell>
                      <TableCell
                        className="table-title-cell hand"
                        align="left"
                        onClick={sort(`ekDosyaTipi.adi${upperOnlyFirstLetter(localeLanguage)}`)}
                      >
                        <div className="tableHeaderContainer">
                          <Translate contentKey="tk24AdysApp.ekDosya.ekDosyaTipiTitle">ekDosyaTipi</Translate>
                          <SortIcon column={`ekDosyaTipi.adi${upperOnlyFirstLetter(localeLanguage)}`} activeSortColumn={pageObject.sort} />
                        </div>
                      </TableCell>
                      <TableCell className="table-title-cell hand" align="left" onClick={sort('fileExtention')}>
                        <div className="tableHeaderContainer">
                          <Translate contentKey="tk24AdysApp.ekDosya.fileExtentionTitle">fileExtention</Translate>
                          <SortIcon column="fileExtention" activeSortColumn={pageObject.sort} />
                        </div>
                      </TableCell>
                      <TableCell className="table-title-cell hand" align="left" onClick={sort('ekleyenKullanici.adi')}>
                        <div className="tableHeaderContainer">
                          <Translate contentKey="tk24AdysApp.ekDosya.ekleyenKullanici">Kullanici</Translate>
                          <SortIcon column={`ekleyenKullanici.adi`} activeSortColumn={pageObject.sort} />
                        </div>
                      </TableCell>
                      <TableCell className="table-title-cell hand" align="left" onClick={sort('cDate')}>
                        <div className="tableHeaderContainer">
                          <Translate contentKey="tk24AdysApp.ekDosya.cDateTitle">CDate</Translate>
                          <SortIcon column={`cDate`} activeSortColumn={pageObject.sort} />
                        </div>
                      </TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {krizMedyaEkDosyaList.map(file => (
                      <TableRow key={file.id} className="table-cell-row">
                        <TableCell className="table-content-cell" component="th" scope="row">
                          {file.dosyaAdi}
                        </TableCell>
                        <TableCell className="table-content-cell" component="th" scope="row">
                          {file.aciklama}
                        </TableCell>
                        <TableCell className="table-content-cell" component="th" scope="row">
                          {file.ekDosyaTipi.adi}
                        </TableCell>
                        <TableCell className="table-content-cell" component="th" scope="row">
                          {file.fileExtention}
                        </TableCell>
                        <TableCell className="table-content-cell" component="th" scope="row">
                          {file.ekleyenKullanici?.adi} {file.ekleyenKullanici?.soyadi}
                        </TableCell>
                        <TableCell className="table-content-cell" component="th" scope="row">
                          <TextFormat type="date" value={file.cDate} format={APP_DATE_FORMAT} />
                        </TableCell>
                        <TableCell className="table-content-cell" align="right">
                          <div className="btn-group flex-btn-group-container">
                            <GrantedButton
                              grantedAuthorites={['ek_dosya_edit']}
                              className="edit-button"
                              onClick={() => {
                                setSelectedFile(file);
                                setIsEkDosyaUpdateModal(true);
                              }}
                              size="sm"
                              data-cy="entityEditButton"
                            >
                              <img width={14} height={14} src={`${SERVER_URL}/content/images/icon/faPencil.svg`} />
                              <span className="d-none d-md-inline">
                                <Translate contentKey="entity.action.edit">Edit</Translate>
                              </span>
                            </GrantedButton>
                            <GrantedButton
                              grantedAuthorites={['ek_dosya_read']}
                              style={{ marginLeft: 10 }}
                              onClick={() => handleDownloadSavedFile(file)}
                              className="edit-button"
                              size="sm"
                            >
                              <img src={`${SERVER_URL}/content/images/icon/faArrowDownToLine.svg`} />
                              <span>
                                <Translate contentKey="entity.action.export" />
                              </span>
                            </GrantedButton>
                            <GrantedButton
                              id={`delete-button-${file.id}`}
                              grantedAuthorites={['ek_dosya_delete']}
                              style={{ marginLeft: 10 }}
                              onClick={() => handleRemoveSavedFile(file)}
                              comparison={[
                                {
                                  value: kriz.krizDurumu.id,
                                  checkValue: ReferansEnum.KrizDurumu.Aktif,
                                  contentKey: 'error.comparison.crisisIsPassive',
                                },
                              ]}
                              className="delete-button"
                              size="sm"
                            >
                              <img width={11.2} height={12.8} src={`${SERVER_URL}/content/images/icon/faTrashCan.svg`} />
                              <span>
                                <Translate contentKey="entity.action.delete">Sil</Translate>
                              </span>
                            </GrantedButton>
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              ) : (
                !loading && (
                  <div className="alert alert-warning">
                    <Translate contentKey="tk24AdysApp.ekDosya.home.notFound">No Ek Dosyas found</Translate>
                  </div>
                )
              )}
            </TableContainer>

            {totalItem ? (
              <div className={krizMedyaEkDosyaList && krizMedyaEkDosyaList.length > 0 ? '' : 'd-none'}>
                <CustomPagination
                  currentPage={pageObject.page + 1}
                  currentPageSize={pageObject.size}
                  totalItems={totalItem}
                  handlePageChange={handlePageChange}
                />
              </div>
            ) : (
              ''
            )}
          </Col>
        </Row>
      </div>
    )
  );
};

export default KrizMedyaAcilDurum;
