import { IKrizDoldurulanFormlar } from 'app/shared/model/kriz-doldurulan-formlar.model';
import React, { useEffect, useState } from 'react';
import { LoadingSpinner } from './LoadingSpinner';
import { Button, Card, CardBody, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { Translate, translate } from './jhipster';
import axios from 'axios';
import { FormItem } from './FormItem';
import { ReferansEnum } from 'app/constants/ReferansEnum';
import { Accordion, AccordionDetails, AccordionSummary, Input as MuiInput, Typography } from '@mui/material';
import { IRefAdtFormDoldurulan } from 'app/shared/model/ref-adt-form-doldurulan.model';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { saveEntityConfirm } from 'app/shared/util/confirm-utils';
import { toast } from 'react-toastify';

interface AdtFormPreviewProp {
  doldurulanForm: IKrizDoldurulanFormlar;
  isFormModal: boolean;
  isUpdatable: boolean;
  handleCloseFormModal: () => void;
}

const AdtFormPreview: React.FC<AdtFormPreviewProp> = ({ doldurulanForm, isUpdatable, isFormModal, handleCloseFormModal }) => {
  const [formLoading, setFormLoading] = useState(false);
  const [formFields, setFormFields] = useState<IRefAdtFormDoldurulan>(null);
  const [expanded, setExpanded] = useState(0);

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const anketPreview = () => {
    setFormLoading(true);
    const formDoldurulanUrl = `api/ref-adt-form-doldurulans/${doldurulanForm?.guid}`;
    axios
      .get(formDoldurulanUrl)
      .then(response => {
        setFormFields(response.data);
        setFormLoading(false);
      })
      .catch(error => {
        setFormLoading(false);
      });
  };

  useEffect(() => {
    anketPreview();
  }, []);

  const changeSoruCevapValue = (etiket, newValue, bolumId, soruId) => {
    formFields.doldurulanBolumList
      .filter(bolum => bolum.id === bolumId)
      .forEach(bolum => {
        bolum.doldurulanSoruList.forEach(soru => {
          if (soru.id === soruId) {
            soru[etiket] = newValue;
          }
        });
      });
    setFormFields(Object.assign({}, formFields));
  };

  const saveFormEntity = async values => {
    values.preventDefault();
    const isSave = await saveEntityConfirm();
    if (isSave) {
      setFormLoading(true);
      axios
        .put(`api/ref-adt-form-doldurulans/${formFields.id}`, formFields)
        .then(res => {
          toast.success(translate('tk24AdysApp.refAdtForm.doldurulanUpdated', { params: formFields?.orjForm?.adi }));
          setFormLoading(false);
          handleCloseFormModal();
        })
        .catch(error => {
          toast.error(error.message);
          setFormLoading(false);
        });
    }
  };

  function handleRadioSecenekChange(secilenSoru, secilenSecenek, newValue) {
    formFields.doldurulanBolumList.forEach(bolum => {
      const soru = bolum.doldurulanSoruList.find(sorus => sorus.id === secilenSoru.id);
      if (soru) {
        soru.doldurulanSoruSecenekList.forEach(secenek => {
          if (secenek.id === secilenSecenek.id) {
            secenek['checked'] = newValue;
          } else {
            secenek['checked'] = false;
          }
        });
      }
    });
    setFormFields({ ...formFields });
  }

  function handleSecenekChange(secilenSoru, secilenSecenek, newValue) {
    formFields.doldurulanBolumList.forEach(bolum => {
      const soru = bolum.doldurulanSoruList.find(sorus => sorus.id === secilenSoru.id);
      if (soru) {
        if (soru.doldurulanSoruSecenekList) {
          soru.doldurulanSoruSecenekList.forEach(secenek => {
            if (secenek.id === secilenSecenek.id) {
              secenek['checked'] = newValue;
            }
          });
        }
      }
    });
    setFormFields({ ...formFields });
  }

  const handleBilgiInputChange = (e, secilenSoru) => {
    formFields.doldurulanBolumList.forEach(bolum => {
      bolum.doldurulanSoruList.forEach(soru => {
        if (soru.id === secilenSoru.id) {
          soru['cevapBilgiAlani'] = e.target.value;
        }
      });
    });
    setFormFields(Object.assign({}, formFields));
  };

  const handleDigerRadioSecenekChange = (e, secilenSoru) => {
    const checkboxValue = e.target.checked;

    formFields.doldurulanBolumList.forEach(bolum => {
      bolum.doldurulanSoruList.forEach(soru => {
        if (soru.id === secilenSoru.id) {
          soru['digerChecked'] = checkboxValue;
          if (!checkboxValue) {
            soru['digerChecked'] = false;
          } else {
            soru.doldurulanSoruSecenekList.forEach(secenek => {
              secenek['checked'] = false;
            });
          }
        }
      });
    });
    setFormFields(Object.assign({}, formFields));
  };

  const handleDigerChange = (e, secilenSoru) => {
    const checkboxValue = e.target.checked;

    formFields.doldurulanBolumList.forEach(bolum => {
      bolum.doldurulanSoruList.forEach(soru => {
        if (soru.id === secilenSoru.id) {
          soru['digerChecked'] = checkboxValue;
          if (!checkboxValue) {
            soru['diger'] = null;
          }
        }
      });
    });
    setFormFields(Object.assign({}, formFields));
  };

  const handleDigerInputChange = (e, secilenSoru) => {
    formFields.doldurulanBolumList.forEach(bolum => {
      bolum.doldurulanSoruList.forEach(soru => {
        if (soru.id === secilenSoru.id) {
          soru['cevapDigerIse'] = e.target.value;
        }
      });
    });
    setFormFields(Object.assign({}, formFields));
  };
  return (
    <Modal isOpen={isFormModal} toggle={handleCloseFormModal} className="modal-style-lg" centered>
      {formLoading && <LoadingSpinner />}
      <ModalHeader toggle={handleCloseFormModal}>
        <div className="anketTitle">{formFields?.orjForm?.adi} </div>
        <div className="anketAciklama">{formFields?.orjForm?.aciklama}</div>
      </ModalHeader>
      <Form onSubmit={saveFormEntity}>
        <ModalBody style={{ maxHeight: '500px', height: '500px', overflowY: 'auto' }}>
          <Card className="anketCard">
            <CardBody style={{ display: 'flex', alignItems: 'center' }}>
              <div className="anketKullanici">
                {' '}
                <Translate contentKey="global.languageGlobal.formDoldurulanKisi" />{' '}
              </div>
              <div className="anketKullaniciAdi">
                {' '}
                : {doldurulanForm?.kisiDetay?.adi} {doldurulanForm?.kisiDetay?.soyadi}{' '}
              </div>
            </CardBody>
          </Card>

          {formFields &&
            formFields['doldurulanBolumList'] &&
            formFields['doldurulanBolumList']
              .sort((a, b) => a.sortIndex - b.sortIndex)
              .map((bolum, index) => (
                <Accordion expanded={expanded === index} onChange={handleChange(index)} className="acordionHead" key={bolum.id}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                    style={{ backgroundColor: '#F2F3F5', borderTopLeftRadius: '8px', borderTopRightRadius: '8px' }}
                  >
                    <div>
                      <Typography className="anketBolumAdi">{bolum.adi}</Typography>
                      <Typography className="anketAciklama">{bolum.aciklama}</Typography>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography className="anketLabelInput">
                      {bolum['doldurulanSoruList'] &&
                        bolum['doldurulanSoruList']
                          .sort((a, b) => a.sortIndex - b.sortIndex)
                          .map(soru => (
                            <div key={soru.id} style={{ marginBottom: '10px' }}>
                              {soru.soruTipi && soru.soruTipi.id === ReferansEnum.SoruTipi.SerbestMetin && (
                                <FormItem
                                  type="text"
                                  name={`${soru.id}`}
                                  id={`${soru.id}`}
                                  label={`${soru.soru} ${soru.aciklama ? `(${soru.aciklama})` : ''}`}
                                  value={soru['cevapSerbestMetin']}
                                  onChange={event => changeSoruCevapValue('cevapSerbestMetin', event.target['value'], bolum.id, soru.id)}
                                  disabled={!isUpdatable}
                                />
                              )}
                              {soru.soruTipi && soru.soruTipi.id === ReferansEnum.SoruTipi.SerbestSayi && (
                                <FormItem
                                  name={`${soru.id}`}
                                  id={`${soru.id}`}
                                  label={`${soru.soru} ${soru.aciklama ? `(${soru.aciklama})` : ''}`}
                                  type="number"
                                  value={soru['cevapSerbestSayi']}
                                  onChange={event => changeSoruCevapValue('cevapSerbestSayi', event.target['value'], bolum.id, soru.id)}
                                  disabled={!isUpdatable}
                                />
                              )}
                              {soru.soruTipi && soru.soruTipi.id === ReferansEnum.SoruTipi.SerbestTarih && (
                                <div>
                                  <FormItem
                                    type="date"
                                    name={`${soru.id}`}
                                    id={`${soru.id}`}
                                    label={`${soru.soru} ${soru.aciklama ? `(${soru.aciklama})` : ''}`}
                                    //defaultValue={soru['cevapSerbestTarih']}
                                    value={soru['cevapSerbestTarih']}
                                    onChange={event => changeSoruCevapValue('cevapSerbestTarih', event.target['value'], bolum.id, soru.id)}
                                    disabled={!isUpdatable}
                                  />
                                </div>
                              )}
                              {soru.soruTipi && soru.soruTipi.id === ReferansEnum.SoruTipi.SerbestSaat && (
                                <div>
                                  <FormItem
                                    name={`${soru.id}`}
                                    id={`${soru.id}`}
                                    label={`${soru.soru} ${soru.aciklama ? `(${soru.aciklama})` : ''}`}
                                    type="time"
                                    //defaultValue={soru['cevapSerbestSaat']}
                                    value={soru['cevapSerbestSaat']}
                                    onChange={event => {
                                      changeSoruCevapValue('cevapSerbestSaat', event.target['value'], bolum.id, soru.id);
                                    }}
                                    disabled={!isUpdatable}
                                  />
                                </div>
                              )}
                              {soru.soruTipi && soru.soruTipi.id === ReferansEnum.SoruTipi.CoktanSecmeliTekCevap && (
                                <div className="mb-3">
                                  <Label id={`radio${soru.realId}`} className="soruCoktanSecmeli">
                                    {soru.soru} {soru.aciklama ? `(${soru.aciklama})` : ''}
                                  </Label>
                                  {soru['doldurulanSoruSecenekList'] &&
                                    soru['doldurulanSoruSecenekList']
                                      .sort((a, b) => a.sortIndex - b.sortIndex)
                                      .map(secenek => (
                                        <FormGroup key={secenek.realId} check className="secenekSoru">
                                          <Input
                                            name={`radio${soru.realId}`}
                                            type="radio"
                                            checked={secenek['checked'] !== undefined && secenek['checked']}
                                            onChange={elem => handleRadioSecenekChange(soru, secenek, elem.target.checked)}
                                            disabled={!isUpdatable}
                                          />{' '}
                                          <Label check>
                                            {secenek.secenek ? (
                                              <>
                                                <div>{`${secenek.secenek}`}</div>
                                                {secenek.aciklama && <small className="secenekAciklama">({secenek.aciklama})</small>}
                                              </>
                                            ) : (
                                              <>
                                                <div>{secenek.secenek}</div>
                                                {secenek.aciklama && <small className="secenekAciklama">({secenek.aciklama})</small>}
                                              </>
                                            )}
                                          </Label>
                                        </FormGroup>
                                      ))}
                                  {soru.digerSecenegiVarMi && soru.digerSecenegiVarMi.id === ReferansEnum.EvetHayir.Evet && (
                                    <FormGroup check key={soru.realId} className="secenekSoru">
                                      <Input
                                        name={`radio${soru.realId}`}
                                        value={soru.realId}
                                        type="radio"
                                        checked={soru['cevapDigerIse'] && soru['cevapDigerIse'] !== undefined}
                                        onChange={e => handleDigerRadioSecenekChange(e, soru)}
                                        disabled={!isUpdatable}
                                      />
                                      <MuiInput
                                        placeholder={translate('home.diger')}
                                        inputProps={{ 'aria-label': 'Diger Input' }}
                                        value={soru['cevapDigerIse']}
                                        disabled={!isUpdatable}
                                      />
                                    </FormGroup>
                                  )}
                                </div>
                              )}

                              {soru.soruTipi && soru.soruTipi.id === ReferansEnum.SoruTipi.CoktanSecmeliCokluCevap && (
                                <div className="mb-3">
                                  <Label id={`${`${soru.realId}`}Label`} className="soruCoktanSecmeli">
                                    {soru.soru} {soru.aciklama ? `(${soru.aciklama})` : ''}
                                  </Label>

                                  {soru['doldurulanSoruSecenekList'] &&
                                    soru['doldurulanSoruSecenekList'].map(secenek => (
                                      <FormGroup key={secenek.realId} check className="secenekSoru">
                                        <Input
                                          name={`checkbox${soru.realId}`}
                                          type="checkbox"
                                          checked={secenek['checked']}
                                          onChange={e => handleSecenekChange(soru, secenek, e.target.checked)}
                                          disabled={!isUpdatable}
                                        />{' '}
                                        <Label check>
                                          {secenek.secenek ? (
                                            <>
                                              <div>{`${secenek.secenek}`}</div>
                                              <small>{secenek.aciklama}</small>
                                            </>
                                          ) : (
                                            <>
                                              <div>{secenek.secenek}</div>
                                              <small>{secenek.aciklama}</small>
                                            </>
                                          )}
                                        </Label>
                                      </FormGroup>
                                    ))}

                                  {soru.digerSecenegiVarMi && soru.digerSecenegiVarMi.id === ReferansEnum.EvetHayir.Evet && (
                                    <FormGroup key={0} check className="secenekSoru">
                                      <Input
                                        name={`checkbox${soru.realId}`}
                                        value={0}
                                        type="checkbox"
                                        checked={soru['cevapDigerIse'] && soru['cevapDigerIse'] !== undefined}
                                        onChange={e => handleDigerChange(e, soru)}
                                        disabled={!isUpdatable}
                                      />
                                      <MuiInput
                                        placeholder={translate('home.diger')}
                                        inputProps={{ 'aria-label': 'Diger Input' }}
                                        value={soru['cevapDigerIse']}
                                        onChange={e => handleDigerInputChange(e, soru)}
                                        disabled={!isUpdatable}
                                      />
                                    </FormGroup>
                                  )}
                                  {soru.bilgiAlaniVarMi && soru.bilgiAlaniVarMi.id === ReferansEnum.EvetHayir.Evet && (
                                    <MuiInput
                                      style={{ marginBottom: '5px' }}
                                      fullWidth
                                      placeholder={translate('home.bilgiAlani')}
                                      value={soru['cevapBilgiAlani']}
                                      onChange={e => handleBilgiInputChange(e, soru)}
                                      disabled={!isUpdatable}
                                    />
                                  )}
                                </div>
                              )}

                              <hr />
                            </div>
                          ))}
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              ))}
        </ModalBody>
        <ModalFooter className="modal-footer">
          <Button className="cancel-button" onClick={handleCloseFormModal}>
            <Translate contentKey="entity.action.cancel">Cancel</Translate>
          </Button>
          &nbsp;
          {isUpdatable && (
            <Button className="save-button" id="jhi-confirm-delete-refAdtForm" type="submit" data-cy="entityConfirmButton">
              <Translate contentKey="entity.action.update">Update</Translate>
            </Button>
          )}
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default AdtFormPreview;
