import React from 'react';
import { UncontrolledTooltip, Row, Col } from 'reactstrap';
import { Translate } from 'app/component/jhipster';
import { IRefKisiAltTipi } from 'app/shared/model/ref-kisi-alt-tipi.model';

interface RefKisiAltTipiDetailProp {
  refKisiAltTipi: IRefKisiAltTipi;
}

export const refKisiAltTipiEntity: React.FC<RefKisiAltTipiDetailProp> = ({ refKisiAltTipi }) => {
  return (
    <>
      <Row className="modal-body-row">
        <Col lg="6" style={{ marginTop: '10px' }}>
          <Row className="modal-content-title">
            <Translate contentKey="global.languageGlobal.kisiTipi">Kisi Tipi</Translate>
          </Row>
          <Row className="modal-content-text">{refKisiAltTipi.kisiTipi && refKisiAltTipi.kisiTipi.adi}</Row>
        </Col>
        <Col lg="6" style={{ marginTop: '10px' }}>
          <Row className="modal-content-title">
            <Translate contentKey="global.languageGlobal.kisaAdi">Kisa Adi</Translate>
          </Row>
          <Row className="modal-content-text">{refKisiAltTipi.kisaAdi}</Row>
        </Col>
      </Row>
      <Row className="modal-body-row">
        <Col lg="6" style={{ marginTop: '10px' }}>
          <Row className="modal-content-title">
            <Translate contentKey="global.languageGlobal.adiTr">Adi Tr</Translate>
          </Row>
          <Row className="modal-content-text">{refKisiAltTipi.adiTr}</Row>
        </Col>
        <Col lg="6" style={{ marginTop: '10px' }}>
          <Row className="modal-content-title">
            <Translate contentKey="global.languageGlobal.adiEn">Adi En</Translate>
          </Row>
          <Row className="modal-content-text">{refKisiAltTipi.adiEn}</Row>
        </Col>
      </Row>
    </>
  );
};

export default refKisiAltTipiEntity;
