import { styled } from '@mui/material/styles';

export const chatDrawerWidth = '32%';

export const KrizMain = styled('main', { shouldForwardProp: prop => prop !== 'open' })<{
  isDrawer?: boolean;
}>(({ theme, isDrawer }) => ({
  flexGrow: 1,
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(isDrawer && {
    marginRight: chatDrawerWidth,
  }),
  position: 'relative',
}));
