import React, { FormEventHandler } from 'react';
import { Button, Form, ModalFooter, ModalBody, Modal, ModalHeader } from 'reactstrap';
import { Translate, translate } from 'app/component/jhipster';
import { FormItem } from 'app/component/FormItem';
import { useAppSelector } from 'app/config/store';
import { IRefBildirimSablonuKisiTipi } from 'app/shared/model/bildirim-sablonu-kisi-tipi.model';

interface BildirimSablonuKisiTipiUpdateProp {
  formValues?: IRefBildirimSablonuKisiTipi;
  saveNewBildirimSablonKisiTipi: FormEventHandler;
  handleCloseForm: FormEventHandler;
  isOpen: boolean;
}

export const BildirimSablonKisiTipiUpdate: React.FC<BildirimSablonuKisiTipiUpdateProp> = ({
  handleCloseForm,
  formValues,
  isOpen,
  saveNewBildirimSablonKisiTipi,
}) => {
  const bildirimKisiTipiList = useAppSelector(state => state.referans.entities);
  const bildirimSablonuList = useAppSelector(state => state.bildirimSablonu.entities);

  return (
    <Modal className="modal-style" isOpen={isOpen} toggle={handleCloseForm} centered>
      <ModalHeader data-cy="RefDuyuruTipiCreateUpdateHeading" toggle={handleCloseForm}>
        {formValues && (
          <p className="modal-title-info">
            {formValues?.bildirimSablon.konu}-{formValues?.bildirimKisiTipi.adi}
          </p>
        )}
        {formValues ? (
          <Translate contentKey="tk24AdysApp.bildirimSablonKisiTipi.home.updateLabel">Update a RefDuyuruTipi</Translate>
        ) : (
          <Translate contentKey="tk24AdysApp.bildirimSablonKisiTipi.home.createLabel">Create a RefDuyuruTipi</Translate>
        )}
      </ModalHeader>
      <Form onSubmit={saveNewBildirimSablonKisiTipi}>
        <ModalBody>
          <FormItem
            label={translate('tk24AdysApp.bildirimSablonKisiTipi.bildirimSablonu')}
            id="bildirim-sablonu"
            name="bildirimSablonId"
            type="select"
            validation={{
              required: { value: true, message: translate('entity.validation.required') },
            }}
          >
            <option value="" key="0" />
            {bildirimSablonuList
              ? bildirimSablonuList.map(otherEntity => (
                  <option
                    key={otherEntity.id}
                    value={otherEntity.id}
                    selected={formValues && formValues.bildirimSablon ? otherEntity.id === formValues.bildirimSablon.id : false}
                  >
                    {otherEntity.konu}
                  </option>
                ))
              : null}
          </FormItem>

          <FormItem
            label={translate('tk24AdysApp.bildirimSablonKisiTipi.bildirimKisiTipi')}
            id="islem-kisi-tipi-id"
            name="bildirimKisiTipiId"
            type="select"
            validation={{
              required: { value: true, message: translate('entity.validation.required') },
            }}
          >
            <option value="" key="0" />
            {bildirimKisiTipiList
              ? bildirimKisiTipiList.map(otherEntity => (
                  <option
                    key={otherEntity.id}
                    value={otherEntity.id}
                    selected={formValues && formValues.bildirimKisiTipi ? otherEntity.id === formValues.bildirimKisiTipi.id : false}
                  >
                    {otherEntity.adi}
                  </option>
                ))
              : null}
          </FormItem>
        </ModalBody>
        <ModalFooter className="modal-footer">
          <Button className="cancel-button" onClick={handleCloseForm}>
            <Translate contentKey="entity.action.cancel">Cancel</Translate>
          </Button>
          &nbsp;
          <Button className="save-button" id="jhi-confirm-delete-refAdtForm" type="submit" data-cy="entityConfirmButton">
            <Translate contentKey="entity.action.save">Kaydet</Translate>
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default BildirimSablonKisiTipiUpdate;
