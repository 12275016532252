import React, { useEffect, useState } from 'react';
import { Link, RouteComponentProps, useHistory } from 'react-router-dom';
import ErrorPage from '../error-page';
import { Storage, Translate, translate } from 'app/component/jhipster';
import GrantedButton from 'app/component/GrantedButton';
import { useAppSelector } from 'app/config/store';
import { LoadingSpinner } from 'app/component/LoadingSpinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';

export const Kriz = (props: RouteComponentProps<{ url: string }>) => {
  const history = useHistory();

  const laodingAccount = useAppSelector(state => state.authentication.loading);
  const laodingKriz = useAppSelector(state => state.krizKartList.loading);
  const [notFound, setNotFound] = useState(false);
  const [unauthorizedUser, setUnauthorizedUser] = useState(false);
  const [loginConfirmation, setLoginConfirmation] = useState(false);
  const [confirmationText, setConfirmationText] = useState(false);

  return (
    <div>
      {laodingAccount || laodingKriz ? (
        <LoadingSpinner />
      ) : (
        <>
          <div>
            {notFound && (
              <ErrorPage>
                <div className="error-header">
                  <Translate contentKey="tk24AdysApp.kriz.home.notFound" />
                </div>
                <div className="error-content">
                  <Translate contentKey="tk24AdysApp.kriz.home.notFoundDetail" />
                </div>
                <div>
                  <GrantedButton
                    grantedAuthorites={['gecmis_kriz_read']}
                    tag={Link}
                    to={`/gecmis-kriz`}
                    className="btn btn-secondary jh-create-entity br16 gecmisKrizDashboard"
                    id="jh-create-entity"
                    data-cy="entityCreateButton"
                  >
                    <FontAwesomeIcon icon={faBars} style={{ fontSize: '14px', marginRight: '15' }} />
                    <Translate contentKey="dashboard.gecmisKrizgoruntule" />
                  </GrantedButton>
                </div>
              </ErrorPage>
            )}
            {unauthorizedUser && (
              <ErrorPage>
                <div className="error-header">
                  <Translate contentKey="tk24AdysApp.kriz.home.unauthorizedUser" />
                </div>
                <div className="error-content">
                  <Translate contentKey="tk24AdysApp.kriz.home.unauthorizedUserDetail" />
                </div>
              </ErrorPage>
            )}
            {loginConfirmation && (
              <ErrorPage>
                <div className="error-header">
                  <Translate contentKey="tk24AdysApp.kriz.home.loginConfirmation" />
                </div>
                <div className="error-content">
                  <Translate contentKey="tk24AdysApp.kriz.home.loginConfirmationDetail" />
                </div>
              </ErrorPage>
            )}
            {confirmationText && (
              <ErrorPage>
                <div className="error-header">
                  <Translate contentKey="tk24AdysApp.kriz.home.confirmationText" />
                </div>
                <div className="error-content">
                  <Translate contentKey="tk24AdysApp.kriz.home.confirmationTextDetail" />
                </div>
              </ErrorPage>
            )}
          </div>
        </>
      )}
    </div>
    //   <div style={{ marginTop: 20 }}>
    //   {laodingAccount ? (
    //     <LoadingSpinner />
    //   ) :   <>
    //         {account && account.login ? ( ) }}
    //   {/* // <ErrorPage>
    //   //   <div className="error-header">
    //   //     <Translate contentKey="tk24AdysApp.kriz.home.notFound" />
    //   //   </div>
    //   //   <div className="error-content">
    //   //     <Translate contentKey="tk24AdysApp.kriz.home.notFoundDetail" />
    //   //   </div>
    //   //   <div></div>
    //   // </ErrorPage> */}

    // </div>
  );
};

export default Kriz;
