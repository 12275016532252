import React from 'react';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { KeyboardArrowDownOutlined } from '@mui/icons-material';
import { Translate, translate } from 'app/component/jhipster';
import { initialCircle } from 'app/shared/style/style';
import { SERVER_URL } from 'app/config/constants';

const circleStyle = {
  width: '40px',
  height: '40px',
  borderRadius: '50%',
  backgroundColor: '#49586A',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: 'white',
  marginRight: '10px',
};

export const ParameterNavDropdown = props => (
  <UncontrolledDropdown nav inNavbar id={props.id} data-cy={props['data-cy']}>
    <DropdownToggle nav caret className="d-flex align-items-center menu-parameter-toggle">
      <img src={`${SERVER_URL}/content/images/icon/settings.svg`} style={{ height: '20px' }} />{' '}
      <span className="menu-parameter-header-title">{props.name}</span>
    </DropdownToggle>
    <DropdownMenu right style={props.style} className="sistemYoneticisi">
      {props.children}
    </DropdownMenu>
  </UncontrolledDropdown>
);
