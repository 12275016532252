import { IReferans } from 'app/shared/model/referans.model';
import { IRefHavalimani } from './ref-havalimani.model';
import { IQueryParams } from '../reducers/reducer.utils';

export interface IKrizIstasyon {
  id?: number;
  guid?: string;
  cDate?: string;
  cUser?: number;
  mDate?: string;
  mUser?: number;
  aciklama?: string;
  aciklamaTr?: string;
  aciklamaEn?: string;
  realId?: number;
  durum?: IReferans;
  havalimani?: IRefHavalimani;
}

export interface IKrizIstasyonParams extends IQueryParams {
  entity?: IKrizIstasyon;
}

export const defaultValue: Readonly<IKrizIstasyon> = {};
