import { useAppDispatch, useAppSelector } from 'app/config/store';
import React, { useEffect, useState } from 'react';
import { getEntities } from '../personel/personel.reducer';
import { LoadingSpinner } from 'app/component/LoadingSpinner';
import { Translate, translate } from 'app/component/jhipster';
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { SERVER_URL } from 'app/config/constants';
import { Button, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import CustomPagination from 'app/component/CustomPagination';
import { IQueryParams } from 'app/shared/reducers/reducer.utils';
import { FormItem } from 'app/component/FormItem';
import { ReferansEnum } from 'app/constants/ReferansEnum';
import axios from 'axios';
import { toast } from 'react-toastify';
import GrantedButton from 'app/component/GrantedButton';
import { isNullOrEmpty, isObjectEmpty } from 'app/shared/util/confirm-utils';
import GrantedCheckbox from 'app/component/GrantedCheckbox';
import { initialSquare } from 'app/shared/style/style';
import { ASC, DESC } from 'app/shared/util/pagination.constants';
import { localeLanguage, upperOnlyFirstLetter } from 'app/shared/util/util';
import SortIcon from 'app/component/SortIcon';

import { getEntities as getHavalimani } from 'app/entities/ref-havalimani/ref-havalimani.reducer';

interface KrizAdtUyePersonelProps {
  krizId: string;
}

const KrizAdtPersonelCagir: React.FC<KrizAdtUyePersonelProps> = ({ krizId }) => {
  const dispatch = useAppDispatch();

  const kriz = useAppSelector(state => state.kriz.entity);
  const adtPersonelList = useAppSelector(state => state.personel.entities);
  const loading = useAppSelector(state => state.personel.loading);
  const totalItem = useAppSelector(state => state.personel.totalItems);
  const refAdtEkipEntities = useAppSelector(state => state.refAdtEkip.entities);
  const [saveLoading, setSaveLoading] = useState(false);
  const [checkBildirimTuruList, setCheckBildirimTuruList] = useState([]);
  const [isAcilDrmOzetRprGndrlckMi, setIsAcilDrmOzetRprGndrlckMi] = useState(false);
  const [pageObject, setPageObject] = useState<IQueryParams>(useAppSelector(state => state.paginate.pageObject));
  const [search, setSearch] = useState('');
  const [checkedPersonel, setCheckedPersonel] = useState([]);
  const [isAdtPersonelCagirModal, setIsAdtPersonelCagirModal] = useState(false);
  const referans = useAppSelector(state => state.referans.entities);
  const evetReferans = referans.find(item => item.id === ReferansEnum.EvetHayir.Evet);
  const hayirReferans = referans.find(item => item.id === ReferansEnum.EvetHayir.Hayir);
  const bildirimTuruList = referans.filter(ref => ref.tipi === 'BILDIRIM_TURU' && [61, 62, 63, 65].includes(ref.id));

  const [isInfoModal, setIsInfoModal] = useState(false);
  const [isInfoMessage, setIsInfoMessage] = useState(null);

  const refHavalimaniList = useAppSelector(state => state.refHavalimani.entities);
  const lokasyonDurumuList = [
    { id: 'yurtici', adi: `${translate('tk24AdysApp.krizAdtEkip.yurtici')}` },
    { id: 'yurtdisi', adi: `${translate('tk24AdysApp.krizAdtEkip.yurtdisi')}` },
  ];

  useEffect(() => {
    dispatch(getHavalimani({ size: 999, page: 0, sort: `adi,asc` }));
  }, []);

  const getAllEntities = () => {
    dispatch(getEntities(pageObject));
  };

  useEffect(() => {
    getAllEntities();
  }, [pageObject.page, pageObject.query, pageObject.query2, pageObject.size, pageObject.sort]);

  useEffect(() => {
    let timeoutId;
    const handleSearch = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        if (search.length > 2) {
          setCheckedPersonel([]);
          setPageObject(prevPageObject => ({
            ...prevPageObject,
            page: 0,
            query: prevPageObject.query + `&globalFilter=${search}`,
          }));
        } else if (search.length === 0) {
          setCheckedPersonel([]);
          const keyToDelete = `globalFilter`;
          const updatedParams = pageObject.query
            .split('&')
            .filter(param => {
              const [key, _] = param.split('=');
              return key !== keyToDelete;
            })
            .join('&');
          setPageObject({ ...pageObject, page: 0, query: updatedParams });
        }
      }, 300);
    };

    handleSearch();

    return () => {
      clearTimeout(timeoutId);
    };
  }, [search]);

  const sort = p => () => {
    const orderBy = pageObject.sort.split(',')[1];
    const newSort = `${p},${orderBy === ASC ? DESC : ASC}`;
    setPageObject({ ...pageObject, sort: newSort });
  };

  const handlePageChange = (pageNumber, newItemsPerPage = pageObject.size) => {
    if (newItemsPerPage !== pageObject.size) {
      setPageObject({ ...pageObject, page: pageNumber - 1, size: newItemsPerPage });
    } else {
      setPageObject({ ...pageObject, page: pageNumber - 1 });
    }
  };

  const handleClick = personel => {
    if (kriz.krizDurumu?.id === ReferansEnum.KrizDurumu.Aktif) {
      const selectedIndex = checkedPersonel.findIndex(item => item.id === personel.id);
      let newSelected = [];

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(checkedPersonel, personel);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(checkedPersonel.slice(1));
      } else if (selectedIndex === checkedPersonel.length - 1) {
        newSelected = newSelected.concat(checkedPersonel.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(checkedPersonel.slice(0, selectedIndex), checkedPersonel.slice(selectedIndex + 1));
      }
      setCheckedPersonel(newSelected);
    }
  };

  const handleAllClick = event => {
    if (kriz.krizDurumu?.id === ReferansEnum.KrizDurumu.Aktif) {
      if (event.target.checked) {
        const combinedArray = [...adtPersonelList, ...checkedPersonel].filter(
          (item, index, self) => index === self.findIndex(i => i.id === item.id)
        );
        setCheckedPersonel(combinedArray);
      } else {
        setCheckedPersonel(checkedPersonel.filter(item => !adtPersonelList.includes(item)));
      }
    }
  };

  const isSelected = personel => {
    return checkedPersonel?.some(item => item.id === personel.id);
  };

  const closePersonelCagirModal = () => {
    setIsAdtPersonelCagirModal(false);
    setCheckBildirimTuruList([]);
    setCheckedPersonel([]);
    setIsAcilDrmOzetRprGndrlckMi(false);
  };

  const handleSearchChange = (elem, event) => {
    setCheckedPersonel([]);
    if (!isNullOrEmpty(event.target.value)) {
      setPageObject(prevPageObject => ({
        ...prevPageObject,
        page: 0,
        query: prevPageObject.query + `&${elem}.equals=${event.target.value}`,
      }));
    } else {
      const keyToDelete = `${elem}.equals`;
      const updatedParams = pageObject.query
        .split('&')
        .filter(param => {
          const [key, _] = param.split('=');
          return key !== keyToDelete;
        })
        .join('&');
      setPageObject({ ...pageObject, page: 0, query: updatedParams });
    }
  };

  const handleUlkeSearchChange = event => {
    setCheckedPersonel([]);
    if (!isNullOrEmpty(event.target.value)) {
      const query2 = event.target.value === 'yurtici' ? `havalimaniListUlkeKodu.equals=TR` : `havalimaniListUlkeKodu.notEquals=TR`;
      setPageObject(prevPageObject => ({
        ...prevPageObject,
        page: 0,
        query2,
      }));
    } else {
      setPageObject({ ...pageObject, page: 0, query2: '' });
    }
  };

  const adtPersonelCagir = event => {
    event.preventDefault();
    setSaveLoading(true);
    const formDataObject = {};
    for (const field of event.target.elements) {
      if (field.name) {
        formDataObject[field.name] = field.value;
      }
    }

    const entity = {
      kriz,
      personelList: checkedPersonel,
      krizBildirimSms: checkBildirimTuruList.find(item => item.id === ReferansEnum.BildirimTuru.Sms) ? evetReferans : hayirReferans,
      krizBildirimEposta: checkBildirimTuruList.find(item => item.id === ReferansEnum.BildirimTuru.EPosta) ? evetReferans : hayirReferans,
      krizBildirimPush: checkBildirimTuruList.find(item => item.id === ReferansEnum.BildirimTuru.PushNotification)
        ? evetReferans
        : hayirReferans,
      krizBildirimIvr: checkBildirimTuruList.find(item => item.id === ReferansEnum.BildirimTuru.Crysis) ? evetReferans : hayirReferans,
      acilDrmOzetRprGndrlckMi: isAcilDrmOzetRprGndrlckMi ? evetReferans : hayirReferans,
      adtEkip: refAdtEkipEntities.find(item => item.id === formDataObject['adtEkipId']),
      aciklama: formDataObject['aciklama'],
      grupSayisi: 1,
    };

    axios
      .post('api/kriz-adt-ekips/add-personel', entity)
      .then(resp => {
        setSaveLoading(false);
        closePersonelCagirModal();
        getAllEntities();
        setIsInfoMessage(resp.data);
        setIsInfoModal(true);
      })
      .catch(() => {
        toast.error(translate('tk24AdysApp.krizAdtEkip.home.notCreatePersonelGorevCagir'));
        closePersonelCagirModal();
        setSaveLoading(false);
      });
  };

  const handleChangeBildirimTuru = bildirim => {
    const isCheck = checkBildirimTuruList.some(item => JSON.stringify(item) === JSON.stringify(bildirim));

    if (isCheck) {
      const updatedDataList = checkBildirimTuruList.filter(item => item.id !== bildirim.id);
      setCheckBildirimTuruList(updatedDataList);
    } else {
      const bildirimList = [...checkBildirimTuruList];
      bildirimList.push(bildirim);

      setCheckBildirimTuruList(bildirimList);
    }
  };

  const closeInfoModal = () => {
    setIsInfoMessage('');
    setIsInfoModal(false);
  };

  const infoModal = () => {
    return (
      <Modal isOpen={isInfoModal} toggle={closeInfoModal} centered>
        <ModalHeader data-cy="refAdtFormUpdateDialogHeading" toggle={closeInfoModal}>
          <Translate contentKey="tk24AdysApp.krizAdtEkip.personelGoreveCagirma" />
        </ModalHeader>
        <Form onSubmit={adtPersonelCagir}>
          <ModalBody className="ql-editor">
            <span dangerouslySetInnerHTML={{ __html: isInfoMessage }} />
          </ModalBody>
        </Form>
      </Modal>
    );
  };

  const adtPersonelCagirModal = () => {
    return (
      <Modal isOpen={isAdtPersonelCagirModal} toggle={closePersonelCagirModal} className="modal-style-lg" centered>
        <ModalHeader data-cy="refAdtFormUpdateDialogHeading" toggle={closePersonelCagirModal}>
          <Translate contentKey="tk24AdysApp.krizAdtEkip.adtPersonelGoreveCagir" interpolate={{ count: `${checkedPersonel.length}` }} />
        </ModalHeader>
        <Form onSubmit={adtPersonelCagir}>
          <ModalBody style={{ maxHeight: '500px', overflowY: 'auto' }}>
            {bildirimTuruList &&
              bildirimTuruList.map(bildirim => (
                <FormGroup key={bildirim.id} check>
                  <Input
                    checked={checkBildirimTuruList.some(item => JSON.stringify(item) === JSON.stringify(bildirim))}
                    onChange={() => handleChangeBildirimTuru(bildirim)}
                    name={bildirim.id.toString()}
                    id={bildirim.id.toString()}
                    type="checkbox"
                  />
                  <Label
                    check
                    onClick={() => handleChangeBildirimTuru(bildirim)}
                    style={{
                      fontSize: '15px',
                      pointerEvents: 'auto',
                    }}
                  >
                    {bildirim.adi}
                  </Label>
                </FormGroup>
              ))}

            <FormGroup check>
              <Input
                checked={isAcilDrmOzetRprGndrlckMi}
                onChange={() => setIsAcilDrmOzetRprGndrlckMi(!isAcilDrmOzetRprGndrlckMi)}
                name="acilDrmOzetRprGndrlckMi"
                type="checkbox"
              />
              <Label check onClick={() => setIsAcilDrmOzetRprGndrlckMi(!isAcilDrmOzetRprGndrlckMi)} style={{ fontSize: '15px' }}>
                <Translate contentKey="tk24AdysApp.krizAdtEkip.acilDrmOzetRprGndrlckMi" />
              </Label>
            </FormGroup>

            <FormItem
              label={translate('tk24AdysApp.krizKatilimci.adtEkip')}
              id="adtEkip"
              name="adtEkipId"
              type="select"
              validation={{
                required: { value: true, message: translate('entity.validation.required') },
              }}
            >
              <option value="" key="0" />
              {refAdtEkipEntities
                ? refAdtEkipEntities.map(otherEntity => (
                    <option value={otherEntity.id} key={otherEntity.id}>
                      {otherEntity.adi}
                    </option>
                  ))
                : null}
            </FormItem>

            <FormItem
              label={translate('tk24AdysApp.krizAdtEkip.aciklama')}
              id="aciklama"
              name="aciklama"
              type="textarea"
              validation={{
                required: { value: true, message: translate('entity.validation.required') },
              }}
            />
          </ModalBody>

          <ModalFooter className="modal-footer">
            <Button className="cancel-button" onClick={closePersonelCagirModal}>
              <Translate contentKey="entity.action.cancel">Cancel</Translate>
            </Button>
            &nbsp;
            <Button id="jhi-confirm-delete-refAdtForm" type="submit" data-cy="entityConfirmButton" className="save-button">
              <Translate contentKey="entity.action.save">Kaydet</Translate>
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    );
  };

  return (
    !isObjectEmpty(kriz) && (
      <>
        {(loading || saveLoading) && <LoadingSpinner />}
        {isAdtPersonelCagirModal && adtPersonelCagirModal()}
        {infoModal()}
        <div className="content-area">
          <div className="search-frame">
            <div className="seachiconposition">
              <img className="seachicon" width={24} height={24} src={`${SERVER_URL}/content/images/icon/faSearch.svg`} />
              <Input
                value={search}
                onChange={e => setSearch(e.target.value)}
                placeholder={translate('entity.action.search')}
                className="search-input"
              />
            </div>

            <div className="create-area">
              <Box sx={{ minWidth: 220, maxWidth: 220 }}>
                <FormControl fullWidth>
                  <InputLabel id="havalimani-label" className="select-input-label">
                    <Translate contentKey="tk24AdysApp.krizKatilimci.havalimani" />
                  </InputLabel>
                  <Select
                    className="select-input"
                    labelId="havalimani-label"
                    id="havalimani"
                    label={translate('tk24AdysApp.krizKatilimci.havalimani')}
                    onChange={event => handleSearchChange('havalimaniId', event)}
                  >
                    <MenuItem value="">
                      <Translate contentKey="entity.action.all" />
                    </MenuItem>
                    {refHavalimaniList &&
                      refHavalimaniList.length > 0 &&
                      refHavalimaniList.map(item => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.adi}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Box>

              <Box sx={{ minWidth: 220, maxWidth: 220 }}>
                <FormControl fullWidth>
                  <InputLabel id="lokasyon-label" className="select-input-label">
                    <Translate contentKey="tk24AdysApp.krizAdtEkip.lokasyonDurum" />
                  </InputLabel>
                  <Select
                    className="select-input"
                    labelId="lokasyon-label"
                    id="lokasyon"
                    label={translate('tk24AdysApp.krizAdtEkip.lokasyonDurum')}
                    onChange={handleUlkeSearchChange}
                  >
                    <MenuItem value="">
                      <Translate contentKey="entity.action.all" />
                    </MenuItem>

                    {lokasyonDurumuList &&
                      lokasyonDurumuList.length > 0 &&
                      lokasyonDurumuList.map(item => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.adi}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Box>
              {adtPersonelList && adtPersonelList.length > 0 ? (
                <GrantedButton
                  id={`update-button`}
                  grantedAuthorites={['kriz_adt_ekip_edit']}
                  className="create-button"
                  onClick={event => {
                    event.stopPropagation();
                    setCheckBildirimTuruList(referans.filter(ref => ref.tipi === 'BILDIRIM_TURU' && [65].includes(ref.id)));
                    setIsAdtPersonelCagirModal(true);
                  }}
                  comparison={[
                    {
                      value: kriz.krizDurumu?.id,
                      checkValue: ReferansEnum.KrizDurumu.Aktif,
                      contentKey: 'error.comparison.crisisIsPassive',
                    },
                  ]}
                  disabled={checkedPersonel?.length === 0}
                  size="sm"
                  data-cy="entityEditButton"
                >
                  <Translate
                    contentKey="tk24AdysApp.krizAdtEkip.adtPersonelGoreveCagir"
                    interpolate={{ count: `${checkedPersonel.length}` }}
                  />
                </GrantedButton>
              ) : (
                <></>
              )}
            </div>
          </div>

          <div className="table-responsive" style={{ marginTop: 5 }}>
            <TableContainer className="content-table">
              {adtPersonelList && adtPersonelList.length > 0 ? (
                <Table aria-label="a dense table">
                  <TableHead className="content-table-title-area">
                    <TableRow className="table-head-row">
                      <TableCell padding="checkbox">
                        <GrantedCheckbox
                          id={`update-button`}
                          grantedAuthorites={['kriz_katilimci_gorev_edit']}
                          color="primary"
                          sx={{
                            '&.Mui-disabled': {
                              opacity: 0.5,
                            },
                          }}
                          checked={adtPersonelList.every(item => checkedPersonel.includes(item))}
                          indeterminate={checkedPersonel && !adtPersonelList.every(item => checkedPersonel.includes(item))}
                          onClick={handleAllClick}
                          comparison={[
                            {
                              value: kriz.krizDurumu?.id,
                              checkValue: ReferansEnum.KrizDurumu.Aktif,
                              contentKey: 'error.comparison.crisisIsPassive',
                            },
                          ]}
                        />
                      </TableCell>
                      <TableCell align="left" className="table-title-cell hand" onClick={sort(`sicil`)}>
                        <div className="tableHeaderContainer">
                          <Translate contentKey="tk24AdysApp.krizKatilimci.sicilTitle">Sicil</Translate>
                          <SortIcon column="sicil" activeSortColumn={pageObject.sort} />
                        </div>
                      </TableCell>
                      <TableCell className="table-title-cell hand" align="left">
                        <Translate contentKey="tk24AdysApp.krizKatilimci.img">img</Translate>&nbsp;
                      </TableCell>
                      <TableCell align="left" className="table-title-cell hand" onClick={sort(`adi`)}>
                        <div className="tableHeaderContainer">
                          <Translate contentKey="tk24AdysApp.krizKatilimci.adi">Adi</Translate>
                          <SortIcon column="adi" activeSortColumn={pageObject.sort} />
                        </div>
                      </TableCell>
                      <TableCell align="left" className="table-title-cell hand" onClick={sort(`soyadi`)}>
                        <div className="tableHeaderContainer">
                          <Translate contentKey="tk24AdysApp.krizKatilimci.soyadi">Soyadi</Translate>
                          <SortIcon column="soyadi" activeSortColumn={pageObject.sort} />
                        </div>
                      </TableCell>
                      <TableCell align="left" className="table-title-cell hand" onClick={sort(`telefonNo`)}>
                        <div className="tableHeaderContainer">
                          <Translate contentKey="tk24AdysApp.krizKatilimci.gsmNo">gsmNo</Translate>
                          <SortIcon column="telefonNo" activeSortColumn={pageObject.sort} />
                        </div>
                      </TableCell>
                      <TableCell align="left" className="table-title-cell hand" onClick={sort(`eposta`)}>
                        <div className="tableHeaderContainer">
                          <Translate contentKey="tk24AdysApp.krizKatilimci.epostaTitle">eposta</Translate>
                          <SortIcon column="eposta" activeSortColumn={pageObject.sort} />
                        </div>
                      </TableCell>

                      <TableCell align="left" className="table-title-cell hand" onClick={sort(`unvanAdi`)}>
                        <div className="tableHeaderContainer">
                          <Translate contentKey="tk24AdysApp.krizKatilimci.unvanAdiTitle">UnvanAdi</Translate>
                          <SortIcon column="unvanAdi" activeSortColumn={pageObject.sort} />
                        </div>
                      </TableCell>
                      <TableCell
                        align="left"
                        className="table-title-cell hand"
                        onClick={sort(`org.adi${upperOnlyFirstLetter(localeLanguage)}`)}
                      >
                        <div className="tableHeaderContainer">
                          <Translate contentKey="tk24AdysApp.krizKatilimci.orgAdi">OrgAdi</Translate>
                          <SortIcon column={`org.adi${upperOnlyFirstLetter(localeLanguage)}`} activeSortColumn={pageObject.sort} />
                        </div>
                      </TableCell>
                      <TableCell align="left" className="table-title-cell">
                        <div className="tableHeaderContainer">
                          <Translate contentKey="tk24AdysApp.refHavalimani.koduIata">Iata</Translate>
                        </div>
                      </TableCell>
                      <TableCell align="left" className="table-title-cell">
                        <div className="tableHeaderContainer">
                          <Translate contentKey="tk24AdysApp.krizKatilimci.havalimaniTitle">havalimani</Translate>
                        </div>
                      </TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {adtPersonelList.map(personel => (
                      <TableRow onClick={() => handleClick(personel)} key={personel.id} className="table-cell-row">
                        <TableCell padding="checkbox">
                          <GrantedCheckbox
                            id={`update-button-${personel.id}`}
                            grantedAuthorites={['kriz_katilimci_gorev_edit']}
                            color="primary"
                            sx={{
                              '&.Mui-disabled': {
                                opacity: 0.5,
                              },
                            }}
                            checked={isSelected(personel)}
                            onClick={() => handleClick(personel)}
                            comparison={[
                              {
                                value: kriz.krizDurumu?.id,
                                checkValue: ReferansEnum.KrizDurumu.Aktif,
                                contentKey: 'error.comparison.crisisIsPassive',
                              },
                            ]}
                          />
                        </TableCell>
                        <TableCell component="th" scope="row" className="table-content-cell">
                          {personel.sicil}
                        </TableCell>
                        <TableCell className="table-content-cell" component="th" scope="row">
                          {personel ? (
                            personel?.thumbnail ? (
                              <div className="hoverable-image-container">
                                <img
                                  className="hoverable-image"
                                  style={{
                                    ...initialSquare,
                                    transition: 'width 0.3s, height 0.3s',
                                  }}
                                  src={`data:${personel.thumbnail.contentType};base64,${personel.thumbnail.base64}`}
                                  alt={''}
                                />
                              </div>
                            ) : (
                              <div style={initialSquare}>
                                <span style={{ fontSize: '14px' }}>
                                  {personel.soyadi ? (
                                    <>
                                      {personel.adi.charAt(0)}
                                      {personel.soyadi.charAt(0)}
                                    </>
                                  ) : (
                                    personel.adi.charAt(0)
                                  )}
                                </span>
                              </div>
                            )
                          ) : (
                            <div style={initialSquare}>
                              <span style={{ fontSize: '14px' }}>
                                {personel.soyadi ? (
                                  <>
                                    {personel.adi.charAt(0)}
                                    {personel.soyadi.charAt(0)}
                                  </>
                                ) : (
                                  personel.adi.charAt(0)
                                )}
                              </span>
                            </div>
                          )}
                        </TableCell>
                        <TableCell component="th" scope="row" className="table-content-cell">
                          {personel.adi}
                        </TableCell>
                        <TableCell component="th" scope="row" className="table-content-cell">
                          {personel.soyadi}
                        </TableCell>
                        <TableCell component="th" scope="row" className="table-content-cell">
                          {personel.telefonNo}
                        </TableCell>
                        <TableCell component="th" scope="row" className="table-content-cell">
                          <a href={`mailto:${personel.eposta}`} className="epostaText">
                            {personel.eposta}
                          </a>
                        </TableCell>
                        <TableCell component="th" scope="row" className="table-content-cell">
                          {personel.unvanAdi}
                        </TableCell>
                        <TableCell component="th" scope="row" className="table-content-cell">
                          {personel.org?.adi}
                        </TableCell>
                        <TableCell component="th" scope="row" className="table-content-cell">
                          {personel?.havalimaniList?.map(item => item.koduIata).join(', ')}
                        </TableCell>
                        <TableCell component="th" scope="row" className="table-content-cell">
                          {personel?.havalimaniList?.map(item => item.adi).join(', ')}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              ) : (
                !loading && (
                  <div className="alert alert-warning m-3">
                    <Translate contentKey="tk24AdysApp.krizAdtEkip.home.notFoundAdtPersonel">No AdtPersonel found</Translate>
                  </div>
                )
              )}
            </TableContainer>
          </div>

          <CustomPagination
            currentPage={pageObject.page + 1}
            currentPageSize={pageObject.size}
            totalItems={totalItem}
            handlePageChange={handlePageChange}
          />
        </div>
      </>
    )
  );
};

export default KrizAdtPersonelCagir;
