import { IKriz } from 'app/shared/model/kriz.model';
import { IReferans } from 'app/shared/model/referans.model';

export interface IKrizOzetRaporu {
  id?: number;
  guid?: string;
  cDate?: string;
  cUser?: number;
  mDate?: string;
  mUser?: number;
  durum?: IReferans;
  kriz?: IKriz;
  hasarBilgisiTr?: string;
  hasarBilgisiEn?: string;
  hasarBilgisi?: string;
  depIletisimAdSoyad?: string;
  depIletisimTelNo?: string;
  arrIletisimAdSoyad?: string;
  arrIletisimTelNo?: string;
  enYakinIstIletisimAdSoyad?: string;
  enYakinIstIletisimTelNo?: string;
}

export const defaultValue: Readonly<IKrizOzetRaporu> = {};
