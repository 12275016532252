import React, { useCallback, useState, useEffect } from 'react';
import { Translate, translate } from 'app/component/jhipster';
import { Button, Form, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { IRefAdtEkip } from 'app/shared/model/ref-adt-ekip.model';
import { CustomDeleteEntityConfirm, CustomSaveEntityConfirm } from 'app/shared/util/confirm-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { createEntity, deleteEntity, updateEntity } from './ref-adt-alt-ekip.reducer';
import { getEntities as getAdtAltEkipUyeEntities } from 'app/entities/ref-adt-ekip/ref-adt-alt-ekip-uye.reducer';
import { FormItem } from 'app/component/FormItem';
import { IRefAdtAltEkip } from 'app/shared/model/ref-adt-alt-ekip.model';
import { LoadingSpinner } from 'app/component/LoadingSpinner';
import GrantedButton from 'app/component/GrantedButton';
import { SERVER_URL } from 'app/config/constants';
import { Box, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, Typography } from '@mui/material';
import ExcelDownloadButton from 'app/component/ExcelDownload';
import { IRefAdtAltEkipUye } from 'app/shared/model/ref-adt-alt-ekip-uye.model';
import { getEntitiesByEkipId } from './ref-adt-alt-ekip.reducer';
import SortIcon from 'app/component/SortIcon';
import { IQueryParams } from 'app/shared/reducers/reducer.utils';
import { ASC, DESC } from 'app/shared/util/pagination.constants';

interface RefAdtAltEkipProp {
  ekip: IRefAdtEkip;
  activeSummaryTab: number;
}

const RefAdtAltEkip: React.FC<RefAdtAltEkipProp> = ({ ekip, activeSummaryTab }) => {
  const dispatch = useAppDispatch();
  const altEkipEntities = useAppSelector(state => state.refAdtAltEkip.entities);
  const altEkipUyeEntities = useAppSelector(state => state.refAdtAltEkipUye.entities);
  const [altEkipUyeData, setAltEkipUyeData] = useState<IRefAdtAltEkipUye[]>([]);

  const loading = useAppSelector(state => state.refAdtAltEkip.loading);
  const updating = useAppSelector(state => state.refAdtAltEkip.updating);
  const [selectedAltEkip, setSelectedAltEkip] = useState<IRefAdtAltEkip>(null);
  const [selectedAltEkipUye, setSelectedAltEkipUye] = useState<IRefAdtAltEkipUye>(null);
  const [isUpdateModal, setIsUpdateModal] = useState<boolean>(false);
  const [isPersonModel, setIsPersonModel] = useState<boolean>(false);

  const [modalPromiseResolve, setModalPromiseResolve] = useState(null);
  const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [pageObject, setPageObject] = useState<IQueryParams>(useAppSelector(state => state.paginate.pageObject));

  const AccordionExcelSampleData = [
    { key: 'adiTr', titleContentKey: 'global.languageGlobal.adiTr' },
    { key: 'adiEn', titleContentKey: 'global.languageGlobal.adiEn' },
  ];

  useEffect(() => {
    const fetchData = async () => {
      const action = await dispatch(getAdtAltEkipUyeEntities({ uyeId: selectedAltEkip.id }));
      if (getAdtAltEkipUyeEntities.fulfilled.match(action)) {
        setAltEkipUyeData(action.payload.data);
      }
    };

    if (selectedAltEkip) {
      fetchData();
    }
  }, [dispatch, selectedAltEkip]);

  useEffect(() => {
    if (activeSummaryTab === 1) {
      getAltEkipForms(ekip?.id);
    }
  }, [activeSummaryTab, pageObject.sort]);

  const getAltEkipForms = (ekipId: number) => {
    dispatch(getEntitiesByEkipId({ ...pageObject, adtEkipId: ekipId, size: 1000, page: 0 }));
  };

  const sortAcordion = p => () => {
    const orderBy = pageObject.sort.split(',')[1];
    setPageObject({ ...pageObject, sort: `${p},${orderBy === ASC ? DESC : ASC}`, size: 1000, page: 0 });
  };

  const handleCloseForm = () => {
    if (isUpdateModal) {
      setIsUpdateModal(false);
    }
    if (isPersonModel) {
      setIsPersonModel(false);
    }
    setSelectedAltEkip(null);
    setSelectedAltEkipUye(null);
  };

  const showSaveConfirmModal = () => {
    setIsSaveModalOpen(true);
    return new Promise(resolve => {
      setModalPromiseResolve(() => resolve);
    });
  };

  const handleConfirm = () => {
    if (modalPromiseResolve) {
      modalPromiseResolve(true);
    }
    setIsSaveModalOpen(false);
  };

  const handleCancel = () => {
    if (modalPromiseResolve) {
      modalPromiseResolve(false);
    }
    setIsSaveModalOpen(false);
  };

  const saveEntity = async event => {
    event.preventDefault();
    setIsUpdateModal(false);
    const isSave = await showSaveConfirmModal();
    if (isSave) {
      const formDataObject = {};
      for (const field of event.target.elements) {
        if (field.name) {
          formDataObject[field.name] = field.value;
        }
      }

      const entity: IRefAdtAltEkip = {
        ...selectedAltEkip,
        ...formDataObject,
        ekip,
      };

      if (selectedAltEkip != null) {
        await dispatch(updateEntity(entity));
      } else {
        await dispatch(createEntity(entity));
      }

      handleCloseForm();
    } else {
      setIsUpdateModal(true);
    }
  };

  const confirmAltEkipDelete = altEkip => {
    setSelectedAltEkip(altEkip);
    setIsDeleteModalOpen(true);
  };

  const handleDeleteConfirm = useCallback(async () => {
    if (selectedAltEkip) {
      await dispatch(deleteEntity(selectedAltEkip));
      handleDeleteCancel();
    }
  }, [selectedAltEkip, dispatch]);

  const handleDeleteCancel = () => {
    setSelectedAltEkip(null);
    setIsDeleteModalOpen(false);
  };

  const fetchTotalAdtGrupData = (): readonly IRefAdtAltEkip[] => {
    try {
      return altEkipEntities || [];
    } catch (error) {
      console.error('Error fetching data:', error);
      return [];
    }
  };

  const PersonModal = () => {
    return (
      <Modal className="modal-style" isOpen={isPersonModel} toggle={handleCloseForm} centered>
        <ModalHeader data-cy="RefYakinlikCreateUpdateHeading" toggle={handleCloseForm}>
          {altEkipUyeData && altEkipUyeData.length > 0 && (
            <p className="modal-title-info">{altEkipUyeData && altEkipUyeData.length > 0 ? altEkipUyeData[0].altEkip.adi : null}</p>
          )}
          <Translate contentKey="tk24AdysApp.refAdtAltEkip.home.title" />
        </ModalHeader>
        <ModalBody>
          <Row className="modal-body-row">
            <div style={{ flexDirection: 'column', display: 'flex' }}>
              <p>
                <b> Adı Soyadı </b>
              </p>
              {altEkipUyeData.map((uye, index) => (
                <div key={index}>
                  <p>
                    {uye.adtUye.adi} {uye.adtUye.soyadi}
                  </p>
                </div>
              ))}
            </div>
          </Row>
        </ModalBody>
      </Modal>
    );
  };

  const UpdateModal = () => {
    return (
      <Modal className="modal-style" isOpen={isUpdateModal} toggle={handleCloseForm} centered>
        <ModalHeader data-cy="RefAdtAltEkipCreateUpdateHeading" toggle={handleCloseForm}>
          {selectedAltEkip && (
            <p className="modal-title-info">
              {selectedAltEkip?.adiTr} - {selectedAltEkip?.adiEn}
            </p>
          )}
          {selectedAltEkip ? (
            <Translate contentKey="tk24AdysApp.refAdtAltEkip.home.updateLabel">Update a refAdtAltEkip</Translate>
          ) : (
            <Translate contentKey="tk24AdysApp.refAdtAltEkip.home.createLabel">Create a refAdtAltEkip</Translate>
          )}
        </ModalHeader>

        <Form onSubmit={saveEntity}>
          <ModalBody>
            <FormItem
              label={translate('global.languageGlobal.adiTr')}
              id="ref-adt-alt-ekip-adiTr"
              name="adiTr"
              defaultValue={selectedAltEkip && selectedAltEkip.adiTr}
              type="text"
              validation={{
                required: { value: true, message: translate('entity.validation.required') },
                maxLength: { value: 100, message: translate('entity.validation.maxlength', { max: 100 }) },
              }}
            />
            <FormItem
              label={translate('global.languageGlobal.adiEn')}
              id="ref-adt-alt-ekip-adiEn"
              name="adiEn"
              defaultValue={selectedAltEkip && selectedAltEkip.adiEn}
              type="text"
              validation={{
                required: { value: true, message: translate('entity.validation.required') },
                maxLength: { value: 100, message: translate('entity.validation.maxlength', { max: 100 }) },
              }}
            />
          </ModalBody>

          <ModalFooter className="modal-footer">
            <Button className="cancel-button" onClick={handleCloseForm}>
              <Translate contentKey="entity.action.cancel">Cancel</Translate>
            </Button>
            &nbsp;
            <Button className="save-button" id="jhi-confirm-delete-refAdtForm" type="submit" data-cy="entityConfirmButton">
              <Translate contentKey="entity.action.save">Kaydet</Translate>
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    );
  };

  return (
    <div className="table-responsive">
      {(loading || updating) && <LoadingSpinner />}
      {UpdateModal()}
      {selectedAltEkipUye && PersonModal()}

      <CustomSaveEntityConfirm entity={selectedAltEkip} isOpen={isSaveModalOpen} onConfirm={handleConfirm} onCancel={handleCancel} />

      {selectedAltEkip && (
        <CustomDeleteEntityConfirm
          entity={selectedAltEkip}
          entityName="refAdtAltEkip"
          isOpen={isDeleteModalOpen}
          onConfirm={handleDeleteConfirm}
          onCancel={handleDeleteCancel}
        />
      )}

      <Box className="accordion-box">
        {altEkipEntities && altEkipEntities?.length > 0 ? (
          <>
            <Typography className="item-total-count" gutterBottom component="div">
              <span className="totalListItemText">
                <Translate contentKey="global.item-total-count" interpolate={{ total: altEkipEntities?.length }} />
              </span>
              <ExcelDownloadButton
                excelSampleData={AccordionExcelSampleData}
                grantedAuthorites={['ref_adt_alt_ekip_export']}
                fetchData={fetchTotalAdtGrupData}
                className={'download-button'}
                exportName={translate('tk24AdysApp.refAdtAltEkip.home.title')}
              />
            </Typography>
            <div className="table-responsive" style={{ marginTop: 5 }}>
              <Table className="accordion-table">
                <TableHead>
                  <TableRow className="accordion-table-title-row">
                    <TableCell className="accordion-table-title-cell" align="left" onClick={sortAcordion('adiTr')}>
                      <div className="tableHeaderContainer">
                        <Translate contentKey="global.languageGlobal.adiTrTitle">Adi Tr</Translate>&nbsp;
                        <SortIcon column="adiTr" activeSortColumn={pageObject.sort} />
                      </div>
                    </TableCell>
                    <TableCell className="accordion-table-title-cell" align="left" onClick={sortAcordion('adiEn')}>
                      <div className="tableHeaderContainer">
                        <Translate contentKey="global.languageGlobal.adiEnTitle">Adi En</Translate>&nbsp;
                        <SortIcon column="adiEn" activeSortColumn={pageObject.sort} />
                      </div>
                    </TableCell>
                    <TableCell className="sticky-right-header-accordion-alt" align="right"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {altEkipEntities.map(altEkip => (
                    <TableRow key={altEkip?.id}>
                      <TableCell className="accordion-table-content-cell" component="th" scope="row">
                        {altEkip?.adiTr}
                      </TableCell>
                      <TableCell className="accordion-table-content-cell" component="th" scope="row">
                        {altEkip?.adiEn}
                      </TableCell>
                      <TableCell className="sticky-right" component="th" scope="row" align="right">
                        <div className="btn-group flex-btn-group-container">
                          <GrantedButton
                            grantedAuthorites={['ref_adt_alt_ekip_read']}
                            className="edit-button kisilerButton"
                            style={{ marginRight: 10 }}
                            onClick={() => {
                              setSelectedAltEkip(altEkip);
                              setSelectedAltEkipUye(altEkip);
                              dispatch(getAdtAltEkipUyeEntities({ uyeId: altEkip.id }));
                              setIsPersonModel(true);
                            }}
                            size="sm"
                            data-cy="entityEditButton"
                          >
                            <Tooltip title={<Translate contentKey="tk24AdysApp.refAdtAltEkip.persons">Kişiler</Translate>}>
                              <img width={14} height={14} src={`${SERVER_URL}/content/images/icon/faUserOvers.svg`} />
                            </Tooltip>
                            <span className="show-on-lg">
                              <Translate contentKey="tk24AdysApp.refAdtAltEkip.persons">Kişiler</Translate>
                            </span>
                          </GrantedButton>

                          <GrantedButton
                            grantedAuthorites={['ref_adt_alt_ekip_edit']}
                            className="edit-button"
                            onClick={() => {
                              setSelectedAltEkip(altEkip);
                              setIsUpdateModal(true);
                            }}
                            size="sm"
                            data-cy="entityEditButton"
                          >
                            <Tooltip title={<Translate contentKey="entity.action.edit">Edit</Translate>}>
                              <img width={14} height={14} src={`${SERVER_URL}/content/images/icon/faPencil.svg`} />
                            </Tooltip>
                            <span className="show-on-lg">
                              <Translate contentKey="entity.action.edit">Edit</Translate>
                            </span>
                          </GrantedButton>
                          <GrantedButton
                            grantedAuthorites={['ref_adt_alt_ekip_delete']}
                            onClick={() => confirmAltEkipDelete(altEkip)}
                            style={{ marginLeft: 10 }}
                            className="delete-button"
                            size="sm"
                            data-cy="entityDeleteButton"
                          >
                            <Tooltip title={<Translate contentKey="entity.action.delete">Delete</Translate>}>
                              <img width={11.2} height={12.8} src={`${SERVER_URL}/content/images/icon/faTrashCan.svg`} />
                            </Tooltip>
                            <span className="show-on-lg">
                              <Translate contentKey="entity.action.delete">Delete</Translate>
                            </span>
                          </GrantedButton>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </>
        ) : (
          !loading &&
          !updating && (
            <div className="alert alert-warning">
              <Translate contentKey="tk24AdysApp.refAdtAltEkip.home.notFound">No refAdtAltEkip found</Translate>
            </div>
          )
        )}
        <div className="flex-start-frame">
          <GrantedButton
            grantedAuthorites={['ref_adt_alt_ekip_edit']}
            onClick={() => {
              setIsUpdateModal(true);
            }}
            className="accordion-create-button"
            size="sm"
            data-cy="entityCreateButton"
          >
            <Tooltip title={<Translate contentKey="entity.action.newRecord">Yeni Kayıt</Translate>}>
              <img width={14} height={14} src={`${SERVER_URL}/content/images/icon/faPlusBlue.svg`} />
            </Tooltip>
            <span className="show-on-lg">
              <Translate contentKey="entity.action.newRecord">Yeni Kayıt</Translate>
            </span>
          </GrantedButton>
        </div>
      </Box>
    </div>
  );
};

export default RefAdtAltEkip;
