import React, { useState, useEffect, useCallback } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Input } from 'reactstrap';
import { Translate, translate } from 'app/component/jhipster';
import { deleteEntity, getEntities, getTotalEntities } from './ref-dis-dokuman.reducer';
import { ASC, DESC } from 'app/shared/util/pagination.constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { CustomDeleteEntityConfirm } from 'app/shared/util/confirm-utils';
import { RefDisDokumanUpdate } from './ref-dis-dokuman-update';
import { LoadingSpinner } from 'app/component/LoadingSpinner';
import { getEntities as getReferans } from 'app/entities/referans/referans.reducer';
import { getEntities as getRefEkDosyaTipis } from 'app/entities/ref-ek-dosya-tipi/ref-ek-dosya-tipi.reducer';
import { getFilteredEntities as getFilterDisDokumanEkDosyaEntities } from 'app/entities/ek-dosya/ek-dosya.reducer';
import TableNameEnum from 'app/constants/TableNameEnum';
import CustomPagination from 'app/component/CustomPagination';
import { IRefDisDokuman } from 'app/shared/model/ref-dis-dokuman.model';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@mui/material';
import GrantedButton from 'app/component/GrantedButton';
import { SERVER_URL } from 'app/config/constants';
import { IQueryParams } from 'app/shared/reducers/reducer.utils';
import ExcelDownloadButton from 'app/component/ExcelDownload';
import SortIcon from 'app/component/SortIcon';

export const RefDisDokuman = (props: RouteComponentProps<{ url: string }>) => {
  const dispatch = useAppDispatch();

  const refDisDokumanList = useAppSelector(state => state.refDisDokuman.entities);
  const [isDisDokumanUpdateModal, setIsDisDokumanUpdateModal] = useState(false);
  const [selectedDisDokuman, setSelectedDisDokuman] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const loading = useAppSelector(state => state.refDisDokuman.loading);
  const updating = useAppSelector(state => state.refDisDokuman.updating);
  const totalItem = useAppSelector(state => state.refDisDokuman.totalItems);
  const [disDokumanLoading, setDisDokumanLoading] = useState(false);
  const ekDosyaLoading = useAppSelector(state => state.ekDosya.loading);
  const ekDosyaUpdating = useAppSelector(state => state.ekDosya.updating);
  const [isViewing, setIsViewing] = useState(null);

  const [search, setSearch] = useState('');

  const [pageObject, setPageObject] = useState<IQueryParams>(useAppSelector(state => state.paginate.pageObject));
  const ExcelSampleData = [
    { key: 'adiTr', titleContentKey: 'global.languageGlobal.adiTr' },
    { key: 'adiEn', titleContentKey: 'global.languageGlobal.adiEn' },
  ];
  const getAllEntities = () => {
    dispatch(getEntities(pageObject));
  };

  useEffect(() => {
    getAllEntities();
  }, [pageObject.page, pageObject.query, pageObject.size, pageObject.sort]);

  useEffect(() => {
    if (search.length > 2) {
      setPageObject({ ...pageObject, page: 0, query: `globalFilter=${search}` });
    } else if (search.length === 0) {
      setPageObject({ ...pageObject, page: 0, query: '' });
    }
  }, [search]);

  const getDisDokumanEkDosya = disDokuman => {
    filteredDisDokumanEkDosyaEntities(disDokuman);
  };

  const filteredDisDokumanEkDosyaEntities = disDokuman => {
    dispatch(
      getFilterDisDokumanEkDosyaEntities({
        ilgiliTabloKayitId: disDokuman.realId,
        ilgiliTablo: TableNameEnum.RefDisDokuman,
      })
    );
  };

  const getEkDosyaDependencies = () => {
    dispatch(getReferans({ size: 2000, page: 0, sort: 'asc' }));
    dispatch(getRefEkDosyaTipis({ sort: 'asc', size: 9999, page: 0 }));
  };

  const sort = p => () => {
    const orderBy = pageObject.sort.split(',')[1];
    setPageObject({ ...pageObject, sort: `${p},${orderBy === ASC ? DESC : ASC}` });
  };

  const handlePageChange = (pageNumber, newItemsPerPage = pageObject.size) => {
    if (newItemsPerPage !== pageObject.size) {
      setPageObject({ ...pageObject, page: pageNumber - 1, size: newItemsPerPage });
    } else {
      setPageObject({ ...pageObject, page: pageNumber - 1 });
    }
  };

  const handleCloseUpdateForm = () => {
    if (isDisDokumanUpdateModal) {
      setIsDisDokumanUpdateModal(false);
    }
    setSelectedDisDokuman(null);
  };

  const confirmDisDokumanDelete = disDokuman => {
    setSelectedDisDokuman(disDokuman);
    setIsDeleteModalOpen(true);
  };

  const handleDeleteConfirm = useCallback(async () => {
    if (selectedDisDokuman) {
      const entity = { ...pageObject, entity: selectedDisDokuman };

      if (refDisDokumanList.length === 1) {
        entity.page = pageObject.page - 1;
        setPageObject({ ...pageObject, page: pageObject.page - 1 });
      }

      await dispatch(deleteEntity(entity));
      handleDeleteCancel();
    }
  }, [selectedDisDokuman, dispatch]);

  const handleDeleteCancel = () => {
    setIsDeleteModalOpen(false);
    setSelectedDisDokuman(null);
  };

  const fetchTotalDisDokumanData = async (): Promise<IRefDisDokuman[]> => {
    try {
      const response = (await dispatch(getTotalEntities({ ...pageObject, size: totalItem }))) as {
        payload: { data: IRefDisDokuman[] };
      };

      return response.payload.data || [];
    } catch (error) {
      console.error('Error fetching data:', error);
      return [];
    }
  };

  const RefDisDokumanUpdateModal = () => {
    return (
      <RefDisDokumanUpdate
        handleCloseForm={handleCloseUpdateForm}
        formValues={selectedDisDokuman}
        isOpen={isDisDokumanUpdateModal}
        page={pageObject}
        setIsLoading={setDisDokumanLoading}
        isViewing={isViewing}
      ></RefDisDokumanUpdate>
    );
  };

  return (
    <div>
      {(loading || updating || disDokumanLoading || ekDosyaLoading || ekDosyaUpdating) && <LoadingSpinner />}
      {selectedDisDokuman && (
        <CustomDeleteEntityConfirm
          entity={selectedDisDokuman}
          entityName="refDisDokuman"
          isOpen={isDeleteModalOpen}
          onConfirm={handleDeleteConfirm}
          onCancel={handleDeleteCancel}
        />
      )}

      {isDisDokumanUpdateModal && RefDisDokumanUpdateModal()}

      <div style={{ display: 'flex', gap: 8, textAlign: 'center' }}>
        <p className="icon-text">
          <a className="link-a-text" href={`${SERVER_URL}`}>
            <img width={13} height={13} src={`${SERVER_URL}/content/images/icon/faHouse.svg`} />
          </a>
        </p>
        <p className="separator"> / </p>
        <p className="link-text">
          {' '}
          <Translate contentKey="tk24AdysApp.refDisDokuman.home.title" />{' '}
        </p>
      </div>

      <h2 id="ref-dis-dokuman-heading" data-cy="RefDisDokumanHeading" className="title-frame">
        <a className="link-a-text" onClick={() => props.history.goBack()}>
          <img width={27.5} height={22.5} src={`${SERVER_URL}/content/images/icon/faArrowLeft.svg`} />{' '}
        </a>
        <p className="title-text">
          <Translate contentKey="tk24AdysApp.refDisDokuman.home.mainTitle" />
        </p>
      </h2>

      <div className="content-area">
        <div className="search-frame">
          <div className="seachiconposition">
            <img className="seachicon" width={24} height={24} src={`${SERVER_URL}/content/images/icon/faSearch.svg`} />
            <Input
              value={search}
              onChange={e => setSearch(e.target.value)}
              placeholder={translate('entity.action.search')}
              className="search-input"
            />
          </div>
          <div className="create-area">
            <ExcelDownloadButton
              excelSampleData={ExcelSampleData}
              fetchData={fetchTotalDisDokumanData}
              className={'download-button'}
              exportName={translate('tk24AdysApp.refDisDokuman.home.title')}
              grantedAuthorites={['ref_dis_dokuman_export']}
            />
            <GrantedButton
              grantedAuthorites={['ref_dis_dokuman_edit']}
              style={{ marginLeft: 15 }}
              onClick={() => {
                setIsDisDokumanUpdateModal(true);
                setIsViewing(false);
                getEkDosyaDependencies();
              }}
              className="create-button"
              size="sm"
              data-cy="entityCreateButton"
            >
              <Tooltip title={<Translate contentKey="tk24AdysApp.refDisDokuman.home.createLabel" />}>
                <img width={14} height={14} src={`${SERVER_URL}/content/images/icon/faPlus.svg`} />
              </Tooltip>
              <span className="show-on-lg">
                <Translate contentKey="tk24AdysApp.refDisDokuman.home.createLabel" />
              </span>
            </GrantedButton>
          </div>
        </div>
        <div className="table-responsive" style={{ marginTop: 5 }}>
          <TableContainer className="content-table">
            {refDisDokumanList && refDisDokumanList.length > 0 ? (
              <Table aria-label="a dense table">
                <TableHead className="content-table-title-area">
                  <TableRow className="table-head-row">
                    <TableCell align="left" className="table-title-cell hand" onClick={sort('adiTr')}>
                      <div className="tableHeaderContainer">
                        <Translate contentKey="global.languageGlobal.adiTrTitle">Adi Tr</Translate>&nbsp;
                        <SortIcon column="adiTr" activeSortColumn={pageObject.sort} />
                      </div>
                    </TableCell>
                    <TableCell align="left" className="table-title-cell hand" onClick={sort('adiEn')}>
                      <div className="tableHeaderContainer">
                        <Translate contentKey="global.languageGlobal.adiEnTitle">Adi En</Translate>&nbsp;
                        <SortIcon column="adiEn" activeSortColumn={pageObject.sort} />
                      </div>
                    </TableCell>
                    <TableCell align="right" className="sticky-right-header"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {refDisDokumanList.map(refDisDokuman => (
                    <TableRow key={refDisDokuman.id} className="table-cell-row">
                      <TableCell component="th" scope="row" className="table-content-cell">
                        {refDisDokuman.adiTr}
                      </TableCell>
                      <TableCell component="th" scope="row" className="table-content-cell">
                        {refDisDokuman.adiEn}
                      </TableCell>
                      <TableCell align="right" className="sticky-right">
                        <div className="btn-group flex-btn-group-container">
                          <GrantedButton
                            grantedAuthorites={['ref_dis_dokuman_edit']}
                            className="edit-button"
                            style={{ marginLeft: 2 }}
                            onClick={() => {
                              setSelectedDisDokuman(refDisDokuman);
                              setIsViewing(false);
                              getDisDokumanEkDosya(refDisDokuman);
                              getEkDosyaDependencies();
                              setIsDisDokumanUpdateModal(true);
                            }}
                            size="sm"
                            data-cy="entityEditButton"
                          >
                            <Tooltip title={<Translate contentKey="entity.action.edit">Edit</Translate>}>
                              <img width={14} height={14} src={`${SERVER_URL}/content/images/icon/faPencil.svg`} />
                            </Tooltip>
                            <span className="show-on-lg">
                              <Translate contentKey="entity.action.edit">Edit</Translate>
                            </span>
                          </GrantedButton>
                          <GrantedButton
                            grantedAuthorites={['ref_dis_dokuman_read']}
                            className="view-button"
                            onClick={() => {
                              setSelectedDisDokuman(refDisDokuman);
                              setIsViewing(true);
                              getDisDokumanEkDosya(refDisDokuman);
                              getEkDosyaDependencies();
                              setIsDisDokumanUpdateModal(true);
                            }}
                            style={{ marginLeft: 10 }}
                            size="sm"
                            data-cy="entityDetailsButton"
                          >
                            <Tooltip title={<Translate contentKey="entity.action.view">View</Translate>}>
                              <img width={15.2} height={11.2} src={`${SERVER_URL}/content/images/icon/faEye.svg`} />
                            </Tooltip>
                            <span className="show-on-lg">
                              <Translate contentKey="entity.action.view">View</Translate>
                            </span>
                          </GrantedButton>
                          <GrantedButton
                            grantedAuthorites={['ref_dis_dokuman_delete']}
                            onClick={() => confirmDisDokumanDelete(refDisDokuman)}
                            style={{ marginLeft: 10 }}
                            className="delete-button"
                            size="sm"
                            data-cy="entityDeleteButton"
                          >
                            <Tooltip title={<Translate contentKey="entity.action.delete">Delete</Translate>}>
                              <img width={11.2} height={12.8} src={`${SERVER_URL}/content/images/icon/faTrashCan.svg`} />
                            </Tooltip>
                            <span className="show-on-lg">
                              <Translate contentKey="entity.action.delete">Delete</Translate>
                            </span>
                          </GrantedButton>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            ) : (
              !loading &&
              !updating && (
                <div className="alert alert-warning">
                  <Translate contentKey="tk24AdysApp.refDisDokuman.home.notFound">No refDisDokuman Tipis found</Translate>
                </div>
              )
            )}
          </TableContainer>
        </div>
        <CustomPagination
          currentPage={pageObject.page + 1}
          currentPageSize={pageObject.size}
          totalItems={totalItem}
          handlePageChange={handlePageChange}
        />
      </div>
    </div>
  );
};

export default RefDisDokuman;
