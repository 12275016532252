import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending } from '@reduxjs/toolkit';
import { cleanEntity } from 'app/shared/util/entity-utils';
import { IQueryParams, createEntitySlice, EntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { IKrizEtkilenenKisiAdtAtama, defaultValue } from 'app/shared/model/kriz-etkilenen-kisi-adt-atama.model';

const initialState: EntityState<IKrizEtkilenenKisiAdtAtama> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

interface KrizEtkilenenKisiAtamaProp extends IQueryParams {
  krizId?: number | string;
}

const apiUrl = 'api/kriz-etkilenen-kisi-adt-atamas';

// Actions

export const getEntities = createAsyncThunk(
  'krizEtkilenenKisiAdtAtama/fetch_entity_list',
  async ({ page, size, sort, krizId }: KrizEtkilenenKisiAtamaProp) => {
    const filter = `${sort ? `&page=${page}&size=${size}&sort=${sort}&` : '&'}cacheBuster=${new Date().getTime()}`;
    const requestUrl = `${apiUrl}?krizId.equals=${krizId}${filter}`;
    return axios.get<IKrizEtkilenenKisiAdtAtama[]>(requestUrl);
  }
);

export const getEntity = createAsyncThunk(
  'krizEtkilenenKisiAdtAtama/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<IKrizEtkilenenKisiAdtAtama>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const createEntity = createAsyncThunk(
  'krizEtkilenenKisiAdtAtama/create_entity',
  async (entity: IKrizEtkilenenKisiAdtAtama, thunkAPI) => {
    const result = await axios.post<IKrizEtkilenenKisiAdtAtama>(apiUrl, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({ krizId: entity.krizId }));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
  'krizEtkilenenKisiAdtAtama/update_entity',
  async (entity: IKrizEtkilenenKisiAdtAtama, thunkAPI) => {
    const result = await axios.put<IKrizEtkilenenKisiAdtAtama>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const partialUpdateEntity = createAsyncThunk(
  'krizEtkilenenKisiAdtAtama/partial_update_entity',
  async (entity: IKrizEtkilenenKisiAdtAtama, thunkAPI) => {
    const result = await axios.patch<IKrizEtkilenenKisiAdtAtama>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const deleteEntity = createAsyncThunk(
  'krizEtkilenenKisiAdtAtama/delete_entity',
  async (entity: IKrizEtkilenenKisiAdtAtama, thunkAPI) => {
    const requestUrl = `${apiUrl}/${entity.id}`;
    const result = await axios.delete<IKrizEtkilenenKisiAdtAtama>(requestUrl);
    thunkAPI.dispatch(getEntities({ krizId: entity.krizId }));
    return result;
  },
  { serializeError: serializeAxiosError }
);

// slice

export const KrizEtkilenenKisiAdtAtamaSlice = createEntitySlice({
  name: 'krizEtkilenenKisiAdtAtama',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(deleteEntity.fulfilled, state => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = {};
      })
      .addMatcher(isFulfilled(getEntities), (state, action) => {
        return {
          ...state,
          loading: false,
          entities: action.payload.data,
          totalItems: parseInt(action.payload.headers['x-total-count'], 10),
        };
      })
      .addMatcher(isFulfilled(createEntity, updateEntity, partialUpdateEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isPending(getEntities, getEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(createEntity, updateEntity, partialUpdateEntity, deleteEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      });
  },
});

export const { reset } = KrizEtkilenenKisiAdtAtamaSlice.actions;

// Reducer
export default KrizEtkilenenKisiAdtAtamaSlice.reducer;
