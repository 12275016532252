import { IEtkilenenKisi } from './etkilenen-kisi.model';
import { IKisi } from './kisi.model';
import { IKrizKatilimci } from './kriz-katilimci.model';
import { IReferans } from './referans.model';

export interface IKrizEtkilenenKisiFavori {
  id?: number;
  guid?: string;
  cDate?: string;
  cUser?: number;
  mDate?: string;
  mUser?: number;
  durum?: IReferans;
  krizKatilimci?: IKrizKatilimci;
  kisi?: IKisi;
}

export const defaultValue: Readonly<IKrizEtkilenenKisiFavori> = {};
