import { IReferans } from 'app/shared/model/referans.model';
import { IRefKatilimci } from './ref-katilimci.model';
import { IQueryParams } from '../reducers/reducer.utils';
import { IRefKatilimciVekil } from './ref-katilimci-vekil.model';

export interface IRefKatilimciIletisim {
  id?: number;
  guid?: string;
  katilimci?: IRefKatilimci;
  katilimciVekil?: IRefKatilimciVekil;
  iletisimTipi?: IReferans;
  iletisimBilgisi?: string;
  entMiGeldi?: IReferans;
  varsayilanMi?: IReferans;
  cDate?: string;
  cUser?: number;
  mDate?: string;
  mUser?: number;
  durum?: IReferans;
}

export interface IRefKatilimciIletisimParams extends IQueryParams {
  entity?: IRefKatilimciIletisim;
  katilimciId?: string;
  katilimciVekilId?: string;
}

export const defaultValue: Readonly<IRefKatilimciIletisim> = {};
