import React, { useState } from 'react';
import { Row, Col, Modal, ModalHeader, ModalBody, Nav, NavItem, NavLink, TabContent, TabPane, ModalFooter, Container } from 'reactstrap';
import { Translate } from 'app/component/jhipster';
import { IRefHavalimani } from 'app/shared/model/ref-havalimani.model';
import { useAppSelector } from 'app/config/store';

interface KrizHavalimaniDetailProp {
  handleCloseForm: () => void;
  isOpen: boolean;
}

export const RefHavalimaniDetail: React.FC<KrizHavalimaniDetailProp> = ({ handleCloseForm, isOpen }) => {
  const [activeTab, setActiveTab] = useState(1);
  const refHavalimani = useAppSelector(state => state.refHavalimani.entity);
  const getActiveTabStyle = controlledTabId => {
    if (controlledTabId === activeTab) {
      return { color: '#1175D2', borderBottom: '2px solid  #34404f' };
    }
    return { color: '#607083' };
  };

  const Asu = () => {
    return (
      <Container className="modal-body">
        {refHavalimani?.asu ? (
          Object.entries(refHavalimani?.asu).map(([key, value]) => (
            <Row key={key}>
              <Col sm={4}>
                <p className="detailKeyStyle">{key}</p>
              </Col>
              <Col sm={8}>
                <p className="detailValStyle">{value}</p>
              </Col>
            </Row>
          ))
        ) : (
          <div className="alert alert-warning">
            <Translate contentKey="tk24AdysApp.krizHavalimani.home.notAsuFound" />
          </div>
        )}
      </Container>
    );
  };

  const BuzOnlemeTemizleme = () => {
    return (
      <Container className="modal-body">
        {refHavalimani?.buzOnlemeTemizleme ? (
          Object.entries(refHavalimani?.buzOnlemeTemizleme).map(([key, value]) => (
            <Row key={key}>
              <Col sm={4}>
                <p className="detailKeyStyle">{key}</p>
              </Col>
              <Col sm={8}>
                <p className="detailValStyle">{value}</p>
              </Col>
            </Row>
          ))
        ) : (
          <div className="alert alert-warning">
            <Translate contentKey="tk24AdysApp.krizHavalimani.home.notBuzOnlemeTemizlemeFound" />
          </div>
        )}
      </Container>
    );
  };

  const UcakTekerPompasi = () => {
    return (
      <Container className="modal-body">
        {refHavalimani?.ucakTekerPompasi ? (
          Object.entries(refHavalimani?.ucakTekerPompasi).map(([key, value]) => (
            <Row key={key}>
              <Col sm={4}>
                <p className="detailKeyStyle">{key}</p>
              </Col>
              <Col sm={8}>
                <p className="detailValStyle">{value}</p>
              </Col>
            </Row>
          ))
        ) : (
          <div className="alert alert-warning">
            <Translate contentKey="tk24AdysApp.krizHavalimani.home.notUcakTekerPompasiFound" />
          </div>
        )}
      </Container>
    );
  };

  const UcakKrikosu = () => {
    return (
      <Container className="modal-body">
        {refHavalimani?.ucakKrikosu ? (
          Object.entries(refHavalimani?.ucakKrikosu).map(([key, value]) => (
            <Row key={key}>
              <Col sm={4}>
                <p className="detailKeyStyle">{key}</p>
              </Col>
              <Col sm={8}>
                <p className="detailValStyle">{value}</p>
              </Col>
            </Row>
          ))
        ) : (
          <div className="alert alert-warning">
            <Translate contentKey="tk24AdysApp.krizHavalimani.home.notUcakKrikosuFound" />
          </div>
        )}
      </Container>
    );
  };

  const Klima = () => {
    return (
      <Container className="modal-body">
        {refHavalimani?.klima ? (
          Object.entries(refHavalimani?.klima).map(([key, value]) => (
            <Row key={key}>
              <Col sm={4}>
                <p className="detailKeyStyle">{key}</p>
              </Col>
              <Col sm={8}>
                <p className="detailValStyle">{value}</p>
              </Col>
            </Row>
          ))
        ) : (
          <div className="alert alert-warning">
            <Translate contentKey="tk24AdysApp.krizHavalimani.home.notKlimaFound" />
          </div>
        )}
      </Container>
    );
  };

  const TasimaBandi = () => {
    return (
      <Container className="modal-body">
        {refHavalimani?.tasimaBandi ? (
          Object.entries(refHavalimani?.tasimaBandi).map(([key, value]) => (
            <Row key={key}>
              <Col sm={4}>
                <p className="detailKeyStyle">{key}</p>
              </Col>
              <Col sm={8}>
                <p className="detailValStyle">{value}</p>
              </Col>
            </Row>
          ))
        ) : (
          <div className="alert alert-warning">
            <Translate contentKey="tk24AdysApp.krizHavalimani.home.notTasimaBandiFound" />
          </div>
        )}
      </Container>
    );
  };

  const EngelliAsansoru = () => {
    return (
      <Container className="modal-body">
        {refHavalimani?.engelliAsansoru ? (
          Object.entries(refHavalimani?.engelliAsansoru).map(([key, value]) => (
            <Row key={key}>
              <Col sm={4}>
                <p className="detailKeyStyle">{key}</p>
              </Col>
              <Col sm={8}>
                <p className="detailValStyle">{value}</p>
              </Col>
            </Row>
          ))
        ) : (
          <div className="alert alert-warning">
            <Translate contentKey="tk24AdysApp.krizHavalimani.home.notEngelliAsansoruFound" />
          </div>
        )}
      </Container>
    );
  };

  const KlimaUnitesi = () => {
    return (
      <Container className="modal-body">
        {refHavalimani?.klimaUnitesi ? (
          Object.entries(refHavalimani?.klimaUnitesi).map(([key, value]) => (
            <Row key={key}>
              <Col sm={4}>
                <p className="detailKeyStyle">{key}</p>
              </Col>
              <Col sm={8}>
                <p className="detailValStyle">{value}</p>
              </Col>
            </Row>
          ))
        ) : (
          <div className="alert alert-warning">
            <Translate contentKey="tk24AdysApp.krizHavalimani.home.notKlimaUnitesiFound" />
          </div>
        )}
      </Container>
    );
  };

  const GenelBilgiler = () => {
    return (
      <Container className="modal-body">
        {refHavalimani?.genelBilgiler ? (
          Object.entries(refHavalimani?.genelBilgiler).map(([key, value]) => (
            <Row key={key}>
              <Col sm={4}>
                <p className="detailKeyStyle">{key}</p>
              </Col>
              <Col sm={8}>
                <p className="detailValStyle">{value}</p>
              </Col>
            </Row>
          ))
        ) : (
          <div className="alert alert-warning">
            <Translate contentKey="tk24AdysApp.krizHavalimani.home.notGenelBilgilerFound" />
          </div>
        )}
      </Container>
    );
  };

  return (
    <Modal isOpen={isOpen} toggle={handleCloseForm} className="modal-style-lg" size="lg" centered>
      <ModalHeader data-cy="refEntityDetailsHeading" toggle={handleCloseForm}>
        <p className="modal-title-info">{refHavalimani.koduIata + ' / ' + refHavalimani.adi}</p>
        <Translate contentKey="tk24AdysApp.krizHavalimani.detail.title" />
      </ModalHeader>
      <ModalBody style={{ maxHeight: '500px', height: '500px' }}>
        <div>
          <Nav tabs>
            <NavItem>
              <NavLink
                style={getActiveTabStyle(1)}
                onClick={() => {
                  setActiveTab(1);
                }}
              >
                <Translate contentKey="tk24AdysApp.krizHavalimani.asu"> asu </Translate>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                style={getActiveTabStyle(2)}
                onClick={() => {
                  setActiveTab(2);
                }}
              >
                <Translate contentKey="tk24AdysApp.krizHavalimani.buzOnlemeTemizleme">buzOnlemeTemizleme</Translate>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                style={getActiveTabStyle(3)}
                onClick={() => {
                  setActiveTab(3);
                }}
              >
                <Translate contentKey="tk24AdysApp.krizHavalimani.ucakTekerPompasi">ucakTekerPompasi</Translate>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                style={getActiveTabStyle(4)}
                onClick={() => {
                  setActiveTab(4);
                }}
              >
                <Translate contentKey="tk24AdysApp.krizHavalimani.ucakKrikosu">ucakKrikosu</Translate>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                style={getActiveTabStyle(5)}
                onClick={() => {
                  setActiveTab(5);
                }}
              >
                <Translate contentKey="tk24AdysApp.krizHavalimani.klima">klima</Translate>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                style={getActiveTabStyle(6)}
                onClick={() => {
                  setActiveTab(6);
                }}
              >
                <Translate contentKey="tk24AdysApp.krizHavalimani.tasimaBandi">tasimaBandi</Translate>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                style={getActiveTabStyle(7)}
                onClick={() => {
                  setActiveTab(7);
                }}
              >
                <Translate contentKey="tk24AdysApp.krizHavalimani.engelliAsansoru">engelliAsansoru</Translate>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                style={getActiveTabStyle(8)}
                onClick={() => {
                  setActiveTab(8);
                }}
              >
                <Translate contentKey="tk24AdysApp.krizHavalimani.klimaUnitesi">klimaUnitesi</Translate>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                style={getActiveTabStyle(9)}
                onClick={() => {
                  setActiveTab(9);
                }}
              >
                <Translate contentKey="tk24AdysApp.krizHavalimani.genelBilgiler">genelBilgiler</Translate>
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={activeTab}>
            <TabPane key={1} tabId={1}>
              <Asu />
            </TabPane>
            <TabPane key={2} tabId={2}>
              <BuzOnlemeTemizleme />
            </TabPane>
            <TabPane key={3} tabId={3}>
              <UcakTekerPompasi />
            </TabPane>
            <TabPane key={4} tabId={4}>
              <UcakKrikosu />
            </TabPane>
            <TabPane key={5} tabId={5}>
              <Klima />
            </TabPane>
            <TabPane key={6} tabId={6}>
              <TasimaBandi />
            </TabPane>
            <TabPane key={7} tabId={7}>
              <EngelliAsansoru />
            </TabPane>
            <TabPane key={8} tabId={8}>
              <KlimaUnitesi />
            </TabPane>
            <TabPane key={9} tabId={9}>
              <GenelBilgiler />
            </TabPane>
          </TabContent>
        </div>
      </ModalBody>
      <ModalFooter className="modal-footer"></ModalFooter>
    </Modal>
  );
};

export default RefHavalimaniDetail;
