import React, { useState, useEffect, useCallback } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Modal, ModalBody, ModalHeader, Spinner, Card, Form, ModalFooter, FormGroup, Input, Label, CardBody } from 'reactstrap';
import { Translate, getPaginationState, translate } from 'app/component/jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SORT } from 'app/shared/util/pagination.constants';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import RefAdtFormBolumUpdate from './ref-adt-form-bolum-update';
import {
  deleteEntity,
  getFilterBolumEntities as getKFilterBolumEntities,
  updateEntities as updateListEntities,
} from './ref-adt-form-bolum.reducer';
import { CustomDeleteEntityConfirm, deleteEntityConfirm } from 'app/shared/util/confirm-utils';
import { getEntity as getFormEntity } from '../ref-adt-form/ref-adt-form.reducer';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import axios from 'axios';
import { ReferansEnum } from 'app/constants/ReferansEnum';
import { FormItem } from 'app/component/FormItem';
import { debounce } from 'lodash';
import { Input as MuiInput, Tooltip } from '@mui/material';
import { SwapVert as SwapVertIcon } from '@mui/icons-material';
import { IRefAdtFormBolumSoru } from 'app/shared/model/ref-adt-form-bolum-soru.model';
import { SERVER_URL } from 'app/config/constants';
import GrantedButton from 'app/component/GrantedButton';
import { LoadingSpinner, ModalLoadingSpinner } from 'app/component/LoadingSpinner';
import { IRefAdtAnket } from 'app/shared/model/ref-adt-anket';

const RefAdtFormBolum = (props: RouteComponentProps<{ url: string; id: string }>) => {
  const dispatch = useAppDispatch();
  const currentPageSize = useAppSelector(state => state.paginate.currentPageSize);

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getPaginationState(props.location, currentPageSize, 'id'), props.location.search)
  );

  const refAdtFormBolumList = useAppSelector(state => state.refAdtFormBolum.entities);
  const loading = useAppSelector(state => state.refAdtFormBolum.loading);
  const updating = useAppSelector(state => state.refAdtFormBolum.updating);

  const totalItems = useAppSelector(state => state.refAdtFormBolum.totalItems);
  const refAdtForm = useAppSelector(state => state.refAdtForm.entity);
  const [formLoading, setFormLoading] = useState(false);
  const [selectedForm, setSelectedForm] = useState(null);
  const [isFormModal, setIsFormModal] = useState(false);
  const [idBolum, setIdBolum] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  useEffect(() => {
    if (props.match.params.id) {
      sortEntities();
      dispatch(getFormEntity(props.match.params.id));
    }
  }, [props.match.params.id]);

  const getAllEntities = () => {
    dispatch(getKFilterBolumEntities({ formId: props.match.params.id }));
  };

  const sortEntities = () => {
    getAllEntities();
  };

  useEffect(() => {
    const params = new URLSearchParams(props.location.search);
    const page = params.get('page');
    const sort = params.get(SORT);

    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [props.location.search]);

  const [isAdtFormBolumUpdateModal, setIsAdtFormBolumUpdateModal] = useState(false);
  const [selectedAdtFormBolum, setSelectedAdtFormBolum] = useState(null);
  const [selectedAdtForm, setSelectedAdtForm] = useState(null);

  const handleCloseUpdateForm = () => {
    setIsAdtFormBolumUpdateModal(false);
    setSelectedAdtFormBolum(null);
  };

  const handleCloseFormModal = () => {
    setIsFormModal(false);
    setSelectedForm(null);
  };

  const [formFields, setFormFields] = useState<IRefAdtAnket>(null);

  const FormModal = () => {
    const [digerCheckboxValues, setDigerCheckboxValues] = useState({});
    const [digerInputValues, setDigerInputValues] = useState({});
    const [selectedRadio, setSelectedRadio] = useState('');
    const [muiInputValue, setMuiInputValue] = useState({});

    const radioOnChange = (elem, soru) => {
      setSelectedRadio('');
      setMuiInputValue('');
    };

    const handleInputChange = (e, soru) => {
      const inputValue = e.target.value;

      setDigerInputValues(prevValues => ({
        ...prevValues,
        [soru.id]: inputValue,
      }));

      if (inputValue) {
        setDigerCheckboxValues(prevValues => ({
          ...prevValues,
          [soru.id]: true,
        }));
      }
    };

    const handleRadioChange = (e, soru) => {
      const checkboxValue = e.target.checked;

      setDigerCheckboxValues(prevValues => ({
        ...prevValues,
        [soru.id]: checkboxValue,
      }));

      if (!checkboxValue) {
        setDigerInputValues(prevValues => ({
          ...prevValues,
          [soru.id]: '',
        }));
      }
    };

    const handleRadioxChange = (e, soru) => {
      const radioValue = e.target.value;

      setMuiInputValue(prevValues => ({
        ...prevValues,
        [soru.id]: prevValues[soru.id] === radioValue ? '' : prevValues[soru.id],
      }));

      setSelectedRadio(prevRadio => (prevRadio === radioValue ? '' : radioValue));
    };

    const handleMuiInputChange = (e, soru) => {
      const inputValue = e.target.value;

      setMuiInputValue(prevValues => ({
        ...prevValues,
        [soru.id]: inputValue,
      }));
    };

    return (
      <>
        {formLoading ? (
          <div className="text-center">
            <ModalLoadingSpinner />
          </div>
        ) : (
          <Modal isOpen={isFormModal} toggle={handleCloseFormModal} size="xl" fullscreen>
            <ModalHeader toggle={handleCloseFormModal}></ModalHeader>
            <ModalBody style={{ maxHeight: '700px', overflowY: 'auto' }}>
              <Card>
                <CardBody>
                  <h6>
                    {' '}
                    <Translate contentKey="tk24AdysApp.refAdtForm.adtFormAdi" /> : {refAdtForm?.adiTr}{' '}
                  </h6>
                  <p>
                    {' '}
                    <Translate contentKey="tk24AdysApp.refAdtForm.aciklama" /> : {refAdtForm?.aciklamaTr}{' '}
                  </p>
                </CardBody>
              </Card>
              <Form>
                {selectedAdtFormBolum &&
                  formFields &&
                  formFields.bolumList &&
                  formFields.bolumList
                    .filter(bolum => bolum.id === selectedAdtFormBolum.id)
                    .sort((a, b) => a.sortIndex - b.sortIndex)
                    .map(bolum => (
                      <Card key={bolum.id} className="mt-3">
                        <CardBody>
                          <h6>
                            {bolum.adi}(<small>{bolum.aciklama}</small>)
                          </h6>
                          {bolum.soruList &&
                            bolum.soruList
                              .sort((a, b) => a.sortIndex - b.sortIndex)
                              .map(soru => (
                                <div key={soru.id}>
                                  {soru.soruTipi && soru.soruTipi.id === ReferansEnum.SoruTipi.SerbestMetin && (
                                    <FormItem
                                      type="text"
                                      name={`${soru.id}`}
                                      id={`${soru.id}`}
                                      label={`${soru.soru} ${soru.aciklama ? `(${soru.aciklama})` : ''}`}
                                      validation={{
                                        required: {
                                          value: soru.cevaplanmasiZorunluMu.id === ReferansEnum.EvetHayir.Evet,
                                          message: translate('entity.validation.required'),
                                        },
                                      }}
                                    />
                                  )}
                                  {soru.soruTipi && soru.soruTipi.id === ReferansEnum.SoruTipi.SerbestSayi && (
                                    <FormItem
                                      name={`${soru.id}`}
                                      id={`${soru.id}`}
                                      label={`${soru.soru} ${soru.aciklama ? `(${soru.aciklama})` : ''}`}
                                      type="number"
                                      validation={{
                                        max: { value: 9999, message: translate('entity.validation.max', { max: 9999 }) },
                                        min: { value: 0, message: translate('entity.validation.min', { min: 0 }) },
                                      }}
                                    />
                                  )}
                                  {soru.soruTipi && soru.soruTipi.id === ReferansEnum.SoruTipi.SerbestTarih && (
                                    <div>
                                      <FormItem
                                        name={`${soru.id}`}
                                        id={`${soru.id}`}
                                        label={`${soru.soru} ${soru.aciklama ? `(${soru.aciklama})` : ''}`}
                                        type="date"
                                      />
                                    </div>
                                  )}
                                  {soru.soruTipi && soru.soruTipi.id === ReferansEnum.SoruTipi.SerbestSaat && (
                                    <div>
                                      <FormItem
                                        name={`${soru.id}`}
                                        id={`${soru.id}`}
                                        label={`${soru.soru} ${soru.aciklama ? `(${soru.aciklama})` : ''}`}
                                        type="time"
                                      />
                                    </div>
                                  )}
                                  {soru.soruTipi && soru.soruTipi.id === ReferansEnum.SoruTipi.CoktanSecmeliTekCevap && (
                                    <div className="mb-3">
                                      <Label id={`${soru.id}`}>{soru.soru}</Label>
                                      {soru.soruSecenekList &&
                                        soru.soruSecenekList
                                          .sort((a, b) => a.sortIndex - b.sortIndex)
                                          .map(secenek => (
                                            <FormGroup key={secenek.id} check>
                                              <Input
                                                name={`radio${soru.id}`}
                                                value={secenek.id}
                                                type="radio"
                                                onChange={elem => radioOnChange(elem, soru)}
                                              />{' '}
                                              <Label check>
                                                {secenek.secenek ? (
                                                  <>
                                                    <div>{`${secenek.secenek}`}</div>
                                                    <small>{secenek.aciklama}</small>
                                                  </>
                                                ) : (
                                                  <>
                                                    <div>{secenek.secenek}</div>
                                                    <small>{secenek.aciklama}</small>
                                                  </>
                                                )}
                                              </Label>
                                            </FormGroup>
                                          ))}
                                      {soru.digerSecenegiVarMi && soru.digerSecenegiVarMi.id === ReferansEnum.EvetHayir.Evet && (
                                        <FormGroup check key={soru.id}>
                                          <Input
                                            name={`radio${soru.id}`}
                                            value={soru.id}
                                            type="radio"
                                            checked={selectedRadio === String(soru.id)}
                                            onChange={e => handleRadioxChange(e, soru)}
                                          />
                                          <MuiInput
                                            placeholder={translate('home.diger')}
                                            inputProps={{ 'aria-label': 'Diger Input' }}
                                            onChange={e => handleMuiInputChange(e, soru)}
                                            value={muiInputValue[soru.id] || ''}
                                            disabled={selectedRadio !== String(soru.id)}
                                          />
                                        </FormGroup>
                                      )}
                                    </div>
                                  )}

                                  {soru.soruTipi && soru.soruTipi.id === ReferansEnum.SoruTipi.CoktanSecmeliCokluCevap && (
                                    <div className="mb-3">
                                      <Label id={`${`${soru.id}`}Label`}>{soru.soru}</Label>

                                      {soru.soruSecenekList &&
                                        soru.soruSecenekList.map(secenek => (
                                          <FormGroup key={secenek.id} check>
                                            <Input name={`radio${soru.id}`} type="checkbox" />{' '}
                                            <Label check>
                                              {secenek.secenek ? (
                                                <>
                                                  <div>{`${secenek.secenek}`}</div>
                                                  <small>{secenek.aciklama}</small>
                                                </>
                                              ) : (
                                                <>
                                                  <div>{secenek.secenek}</div>
                                                  <small>{secenek.aciklama}</small>
                                                </>
                                              )}
                                            </Label>
                                          </FormGroup>
                                        ))}

                                      {soru.digerSecenegiVarMi && soru.digerSecenegiVarMi.id === ReferansEnum.EvetHayir.Evet && (
                                        <FormGroup key={0} check>
                                          <Input
                                            name={`radio${soru.id}`}
                                            value={0}
                                            type="checkbox"
                                            checked={!!digerCheckboxValues[soru.id]}
                                            onChange={e => handleRadioChange(e, soru)}
                                          />
                                          <MuiInput
                                            placeholder={translate('home.diger')}
                                            inputProps={{ 'aria-label': 'Diger Input' }}
                                            onChange={e => handleInputChange(e, soru)}
                                            value={digerInputValues[soru.id] || ''}
                                          />
                                        </FormGroup>
                                      )}
                                    </div>
                                  )}
                                </div>
                              ))}
                        </CardBody>
                      </Card>
                    ))}
              </Form>
            </ModalBody>
            <ModalFooter>
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                  className="eventArea"
                  id="cancel-save"
                  data-cy="entityCreateCancelButton"
                  onClick={handleCloseFormModal}
                  color="secondary"
                >
                  <span style={{ display: 'inline-block', width: '100%', textAlign: 'center' }}>
                    <Translate contentKey="entity.action.cancel">İptal</Translate>
                  </span>
                </Button>
              </div>
            </ModalFooter>
          </Modal>
        )}
      </>
    );
  };

  const loadFormFields = async refAdtFormBolum => {
    setFormLoading(true);
    const formBolumSoruUrl = `api/ref-adt-forms/anket/${refAdtFormBolum.form.id}`;
    await axios.get(formBolumSoruUrl).then(response => {
      setFormFields(response.data);
    });

    setFormLoading(false);
    setSelectedAdtFormBolum(refAdtFormBolum);
    setSelectedForm(refAdtFormBolum.form);
    setIsFormModal(true);
  };

  const confirmAdtFormBolumDelete = (AdtFormBolum, form) => {
    setSelectedAdtFormBolum(AdtFormBolum);
    setSelectedAdtForm(form);
    setIsDeleteModalOpen(true);
  };

  const handleDeleteConfirm = useCallback(async () => {
    if (selectedAdtFormBolum) {
      await dispatch(deleteEntity({ AdtFormBolumId: selectedAdtFormBolum.id, formId: selectedAdtForm.id }));
      handleDeleteCancel();
    }
  }, [selectedAdtFormBolum, dispatch]);

  const handleDeleteCancel = () => {
    setIsDeleteModalOpen(false);
    setSelectedAdtFormBolum(null);
    setSelectedAdtForm(null);
  };

  const handleDragEnd = debounce(result => {
    if (!result.destination) {
      return;
    }
    const { source, destination } = result;
    const startIndex = source.index;
    const endIndex = destination.index;

    const updatedList = [...refAdtFormBolumList];
    const [removed] = updatedList.splice(startIndex, 1);
    updatedList.splice(endIndex, 0, removed);

    const updatedListWithIndexSort = updatedList.map((item, index) => ({
      ...item,
      sortIndex: index + 1,
    }));

    try {
      dispatch(updateListEntities(updatedListWithIndexSort));
    } catch (error) {
      console.error('Toplu güncelleme başarısız oldu:', error);
    }
  }, 500);

  const RefAdtFormBolumUpdateModal = () => {
    return (
      <Modal isOpen={isAdtFormBolumUpdateModal} toggle={handleCloseUpdateForm} className="modal-style modal-dialog-centered">
        <ModalHeader data-cy="RefAdtFormCreateUpdateHeading" toggle={handleCloseUpdateForm}>
          <Translate contentKey="tk24AdysApp.refAdtFormBolum.home.createOrEditLabel">Create or edit a RefAdtForm</Translate>
        </ModalHeader>
        <RefAdtFormBolumUpdate
          form={refAdtForm}
          totalItems={totalItems}
          handleCloseForm={handleCloseUpdateForm}
          formValues={selectedAdtFormBolum}
        ></RefAdtFormBolumUpdate>
      </Modal>
    );
  };

  return (
    <>
      {(loading || updating) && <LoadingSpinner />}
      {selectedAdtFormBolum && (
        <CustomDeleteEntityConfirm
          entity={selectedAdtFormBolum}
          entityName="refAdtFormBolum"
          isOpen={isDeleteModalOpen}
          onConfirm={handleDeleteConfirm}
          onCancel={handleDeleteCancel}
        />
      )}
      <div>
        <div className="d-flex justify-content-between align-items-center mb-4">
          <div>
            <h2 id="ref-adt-form-heading" data-cy="RefAdtFormHeading">
              <Translate contentKey="tk24AdysApp.refAdtFormBolum.home.title">Ref Adt Form Units</Translate>
            </h2>

            <div className="d-flex align-items-center mt-2">
              <p className="mr-3">
                <span className="font-weight-bold">
                  <Translate contentKey="tk24AdysApp.refAdtFormBolum.formAdiTr" />:
                </span>{' '}
                {refAdtForm.adiTr}
              </p>
              <p className="mr-3">
                <span className="font-weight-bold">
                  <Translate contentKey="tk24AdysApp.refAdtFormBolum.formAdiEn" />:
                </span>{' '}
                {refAdtForm.adiEn}
              </p>
            </div>
          </div>
          <div className="create-area">
            <Button className="download-button" tag={Link} to={`/ref-adt-form`}>
              <Tooltip title={<Translate contentKey="entity.action.back">Geri Dön </Translate>}>
                <ArrowBackIcon />
              </Tooltip>
              <span className="show-on-lg">
                <Translate contentKey="entity.action.back">Geri Dön </Translate>
              </span>
            </Button>
            <GrantedButton
              grantedAuthorites={['ref_adt_form_bolum_edit']}
              style={{ marginLeft: 15 }}
              onClick={() => {
                setIsAdtFormBolumUpdateModal(true);
              }}
              className="create-button"
              size="sm"
              data-cy="entityCreateButton"
            >
              <Tooltip title={<Translate contentKey="tk24AdysApp.refAdtFormBolum.home.createLabel" />}>
                <img width={14} height={14} src={`${SERVER_URL}/content/images/icon/faPlus.svg`} />
              </Tooltip>
              <span className="show-on-lg">
                <Translate contentKey="tk24AdysApp.refAdtFormBolum.home.createLabel" />
              </span>
            </GrantedButton>
          </div>
        </div>
        <RefAdtFormBolumUpdateModal />
        <FormModal />
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="refAdtFormBolumList">
            {provided => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                <div className="table-responsive" style={{ marginTop: 5 }}>
                  {refAdtFormBolumList && refAdtFormBolumList.length > 0
                    ? refAdtFormBolumList.map((refAdtFormBolum, index) => (
                        <Draggable key={refAdtFormBolum.id} draggableId={refAdtFormBolum.id.toString()} index={index}>
                          {innerProvided => (
                            <div
                              ref={innerProvided.innerRef}
                              {...innerProvided.draggableProps}
                              key={refAdtFormBolum.id}
                              className="card mb-3"
                            >
                              <div className="card-body d-flex justify-content-between">
                                <div className="d-flex align-items-center">
                                  <div className="mr-4">
                                    <Tooltip title="Sırala">
                                      <div {...innerProvided.dragHandleProps}>
                                        <SwapVertIcon />
                                      </div>
                                    </Tooltip>
                                  </div>
                                  <div className="mr-4">
                                    <h6 className="card-title m-0">
                                      <Translate contentKey="tk24AdysApp.refAdtFormBolum.adiTr" />:{' '}
                                    </h6>
                                    <p className="card-text m-0"> {refAdtFormBolum.adiTr}</p>
                                  </div>
                                  <div className="mr-4">
                                    <h6 className="card-title m-0">
                                      <Translate contentKey="tk24AdysApp.refAdtFormBolum.adiEn" />:{' '}
                                    </h6>
                                    <p className="card-text m-0"> {refAdtFormBolum.adiEn}</p>
                                  </div>
                                  {refAdtFormBolum.aciklama && (
                                    <div className="mr-4">
                                      <h6 className="card-title m-0">
                                        <Translate contentKey="tk24AdysApp.refAdtFormBolum.aciklama" />:
                                      </h6>
                                      <p className="card-text m-0"> {refAdtFormBolum.aciklama}</p>
                                    </div>
                                  )}
                                </div>
                                <div className="sticky-right">
                                  <div className="btn-group flex-btn-group-container">
                                    <GrantedButton
                                      grantedAuthorites={['ref_adt_form_soru_read']}
                                      className="edit-button"
                                      style={{ marginLeft: 5 }}
                                      tag={Link}
                                      to={`/ref-adt-form-bolum-soru/${refAdtFormBolum.id}`}
                                      size="sm"
                                      data-cy="entityEditButton"
                                    >
                                      <Tooltip title={<Translate contentKey="tk24AdysApp.refAdtFormBolum.questions">Questions</Translate>}>
                                        <FontAwesomeIcon icon="list" />
                                      </Tooltip>
                                      <span className="show-on-lg">
                                        <Translate contentKey="tk24AdysApp.refAdtFormBolum.questions">Questions</Translate>
                                      </span>
                                    </GrantedButton>

                                    <GrantedButton
                                      grantedAuthorites={['ref_adt_form_bolum_edit']}
                                      className="edit-button"
                                      style={{ marginLeft: 5 }}
                                      onClick={() => {
                                        setSelectedAdtFormBolum(refAdtFormBolum);
                                        setIsAdtFormBolumUpdateModal(true);
                                      }}
                                      size="sm"
                                      data-cy="entityEditButton"
                                    >
                                      <Tooltip title={<Translate contentKey="entity.action.edit">Edit</Translate>}>
                                        <img width={14} height={14} src={`${SERVER_URL}/content/images/icon/faPencil.svg`} />
                                      </Tooltip>
                                      <span className="show-on-lg">
                                        <Translate contentKey="entity.action.edit">Edit</Translate>
                                      </span>
                                    </GrantedButton>
                                    <GrantedButton
                                      grantedAuthorites={['ref_adt_form_bolum_delete']}
                                      onClick={() => confirmAdtFormBolumDelete(refAdtFormBolum, refAdtFormBolum.form)}
                                      style={{ marginLeft: 5 }}
                                      className="delete-button"
                                      size="sm"
                                      data-cy="entityDeleteButton"
                                    >
                                      <Tooltip title={<Translate contentKey="entity.action.delete">Delete</Translate>}>
                                        <img width={11.2} height={12.8} src={`${SERVER_URL}/content/images/icon/faTrashCan.svg`} />
                                      </Tooltip>
                                      <span className="show-on-lg">
                                        <Translate contentKey="entity.action.delete">Delete</Translate>
                                      </span>
                                    </GrantedButton>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </Draggable>
                      ))
                    : !loading && (
                        <div className="alert alert-warning">
                          <Translate contentKey="tk24AdysApp.refAdtFormBolum.home.notFound">No Ref Adt Forms found</Translate>
                        </div>
                      )}
                </div>
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </>
  );
};

export default RefAdtFormBolum;
