import React, { useState, useEffect, useCallback } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Input } from 'reactstrap';
import { Translate, translate } from 'app/component/jhipster';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import axios from 'axios';
import { IRefKatilimci } from 'app/shared/model/ref-katilimci.model';
import { CustomDeleteEntityConfirm, isNullOrEmpty } from 'app/shared/util/confirm-utils';
import { deleteEntity } from './ref-gorev.reducer';
import RefGorevUpdate from './ref-gorev-update';
import { getEntities as getEkipEntities, getTotalEntities as getTotalAdtEkipEntities } from '../ref-adt-ekip/ref-adt-ekip.reducer';
import { LoadingSpinner } from 'app/component/LoadingSpinner';
import { ASC, DESC } from 'app/shared/util/pagination.constants';
import CustomPagination from 'app/component/CustomPagination';
import { SERVER_URL } from 'app/config/constants';
import GrantedButton from 'app/component/GrantedButton';
import { IQueryParams } from 'app/shared/reducers/reducer.utils';
import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { KeyboardArrowRight, KeyboardArrowDownOutlined } from '@mui/icons-material';
import { HasAuthComponent } from 'app/component/HasAuthComponent';
import { ReferansEnum } from 'app/constants/ReferansEnum';
import SortIcon from 'app/component/SortIcon';
import ExcelDownloadButton from 'app/component/ExcelDownload';
import { IRefAdtEkip } from 'app/shared/model/ref-adt-ekip.model';
import { IRefGorev } from 'app/shared/model/ref-gorev.model';

export const RefAdtGorev = (props: RouteComponentProps<{ url: string }>) => {
  const dispatch = useAppDispatch();

  const refAdtEkip = useAppSelector(state => state.refAdtEkip.entities);
  const referans = useAppSelector(state => state.referans.entities);
  const gorevSahibiRef = referans.find(item => item.id === ReferansEnum.GorevSahibi.ADT);
  const [isViewing, setIsViewing] = useState(null);

  const loading = useAppSelector(state => state.refAdtEkip.loading);
  const updating = useAppSelector(state => state.refAdtEkip.updating);
  const totalItem = useAppSelector(state => state.refAdtEkip.totalItems);
  const refLoading = useAppSelector(state => state.referans.loading);
  const [adtLoading, setAdtLoading] = useState(false);

  const [refAdtGorevList, setRefAdtGorevList] = useState(null);

  const [isGorevUpdateModal, setIsGorevUpdateModal] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const [selectedTask, setSelectedTask] = useState(null);
  const [selectedAdtEkip, setSelectedAdtEkip] = useState(null);

  const [openItemId, setOpenItemId] = useState<number | null>(null);
  const [search, setSearch] = useState('');
  const [accordionSearch, setAccordionSearch] = useState('');

  const [pageObject, setPageObject] = useState<IQueryParams>(useAppSelector(state => state.paginate.pageObject));
  const [pageObjectRefGorev, setPageObjectRefGorev] = useState<IQueryParams>(useAppSelector(state => state.paginate.pageObject));
  const [sortField, setSortField] = useState('id');
  const [sortOrder, setSortOrder] = useState('asc');

  const ExcelSampleData = [
    { key: 'adiTr', titleContentKey: 'global.languageGlobal.adiTr' },
    { key: 'adiEn', titleContentKey: 'global.languageGlobal.adiEn' },
  ];

  const AccordionExcelSampleData = [
    { key: 'adiTr', titleContentKey: 'global.languageGlobal.adiTr' },
    { key: 'adiEn', titleContentKey: 'global.languageGlobal.adiEn' },
    { key: 'aciklamaTr', titleContentKey: 'global.languageGlobal.aciklamaTr' },
    { key: 'aciklamaEn', titleContentKey: 'global.languageGlobal.aciklamaEn' },
  ];

  const getAllEntities = () => {
    dispatch(getEkipEntities(pageObject));
  };

  useEffect(() => {
    getAllEntities();
  }, [pageObject.page, pageObject.query, pageObject.size, pageObject.sort]);

  useEffect(() => {
    if (search.length > 2) {
      setPageObject(prevPageObject => ({
        ...prevPageObject,
        page: 0,
        query: prevPageObject.query + `&globalFilter=${search}`,
      }));
    } else if (search.length === 0 && !isNullOrEmpty(pageObject.query)) {
      const keyToDelete = 'globalFilter';
      const updatedParams = pageObject.query
        .split('&')
        .filter(param => {
          const [key, _] = param.split('=');
          return key !== keyToDelete;
        })
        .join('&');

      setPageObject({ ...pageObject, page: 0, query: updatedParams });
    }
  }, [search]);

  useEffect(() => {
    if (openItemId) {
      if (accordionSearch.length > 2) {
        loadEkipGorev(selectedAdtEkip.id, accordionSearch);
      } else if (accordionSearch.length === 0) {
        loadEkipGorev(selectedAdtEkip.id);
      }
    }
  }, [accordionSearch, openItemId, sortField, sortOrder]);

  const sort = p => () => {
    const orderBy = pageObject.sort.split(',')[1];
    setPageObject({ ...pageObject, sort: `${p},${orderBy === ASC ? DESC : ASC}` });
  };

  const handlePageChange = (pageNumber, newItemsPerPage = pageObject.size) => {
    if (newItemsPerPage !== pageObject.size) {
      setPageObject({ ...pageObject, page: pageNumber - 1, size: newItemsPerPage });
    } else {
      setPageObject({ ...pageObject, page: pageNumber - 1 });
    }
  };

  const handleCloseUpdateForm = () => {
    if (isGorevUpdateModal) {
      setIsGorevUpdateModal(false);
    }
    setSelectedTask(null);
    setIsViewing(null);
  };

  const confirmGorevDelete = gorev => {
    setSelectedTask(gorev);
    setIsDeleteModalOpen(true);
  };

  const handleDeleteConfirm = useCallback(async () => {
    if (selectedTask) {
      const entity = { ...pageObject, entity: selectedTask };

      if (refAdtGorevList.length === 1) {
        entity.page = pageObject.page - 1;
        setPageObject({ ...pageObject, page: pageObject.page - 1 });
      }

      await dispatch(deleteEntity(entity));
      loadEkipGorev(selectedAdtEkip?.id);
      handleDeleteCancel();
    }
  }, [selectedTask, dispatch]);

  const handleDeleteCancel = () => {
    setIsDeleteModalOpen(false);
    setSelectedTask(null);
  };

  const sortAcordion = p => () => {
    const orderBy = pageObjectRefGorev.sort.split(',')[1];
    const newSortOrder = orderBy === ASC ? DESC : ASC;
    setPageObjectRefGorev({ ...pageObjectRefGorev, sort: `${p},${newSortOrder}` });
    setSortField(p);
    setSortOrder(newSortOrder);
  };

  const getGorevTable = (katilimci: IRefKatilimci) => {
    if (openItemId === katilimci?.id) {
      setOpenItemId(null);
      setAccordionSearch('');
      setRefAdtGorevList(null);
    } else {
      setOpenItemId(katilimci?.id);
      setAccordionSearch('');
      setSelectedAdtEkip(katilimci);
    }
  };

  const loadEkipGorev = async (adtEkipId: number, searchInput?: string) => {
    if (!adtEkipId) return;
    setRefAdtGorevList([]);
    setAdtLoading(true);
    let gorevSorBirimUrl = `api/ref-gorevs?adtEkipId.equals=${adtEkipId}&gorevSahibiId.equals=${ReferansEnum.GorevSahibi.ADT}&sort=${sortField},${sortOrder}&size=2000`;

    if (searchInput) {
      gorevSorBirimUrl += `&globalFilter=${searchInput}`;
    }

    await axios.get(gorevSorBirimUrl).then(response => {
      setRefAdtGorevList(response.data);
    });
    setAdtLoading(false);
  };

  const fetchTotalAdtEkipData = async (): Promise<IRefAdtEkip[]> => {
    try {
      const response = (await dispatch(getTotalAdtEkipEntities({ ...pageObject, size: totalItem }))) as {
        payload: { data: IRefAdtEkip[] };
      };

      return response.payload.data || [];
    } catch (error) {
      console.error('Error fetching data:', error);
      return [];
    }
  };

  const fetchTotalAdtGorevData = (): IRefGorev[] => {
    try {
      if (selectedAdtEkip) {
        return refAdtGorevList || [];
      } else {
        return [];
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      return [];
    }
  };

  const RefGorevUpdateModal = () => {
    return (
      <RefGorevUpdate
        handleCloseForm={handleCloseUpdateForm}
        formValues={selectedTask}
        refKatilimci={null}
        refAdtEkip={selectedAdtEkip}
        gorevSahibiRef={gorevSahibiRef}
        isOpen={isGorevUpdateModal}
        loadRefGorevForm={() => loadEkipGorev(selectedAdtEkip?.id)}
        isViewing={isViewing}
      ></RefGorevUpdate>
    );
  };

  return (
    <div>
      {(loading || updating || refLoading || adtLoading) && <LoadingSpinner />}
      {isGorevUpdateModal && RefGorevUpdateModal()}
      {selectedTask && (
        <CustomDeleteEntityConfirm
          entity={selectedTask}
          entityName="refGorev"
          isOpen={isDeleteModalOpen}
          onConfirm={handleDeleteConfirm}
          onCancel={handleDeleteCancel}
        />
      )}

      <div className="content-area">
        <div className="search-frame">
          <div className="seachiconposition">
            <img className="seachicon" width={24} height={24} src={`${SERVER_URL}/content/images/icon/faSearch.svg`} />
            <Input
              value={search}
              onChange={e => setSearch(e.target.value)}
              placeholder={translate('entity.action.search')}
              className="search-input"
            />
          </div>
          <div className="d-flex justify-end">
            <ExcelDownloadButton
              excelSampleData={ExcelSampleData}
              grantedAuthorites={['ref_adt_ekip_export']}
              fetchData={fetchTotalAdtEkipData}
              className={'download-button'}
              exportName={translate('tk24AdysApp.refAdtEkip.home.title')}
            />
          </div>
        </div>
        <div className="table-responsive" style={{ marginTop: 5 }}>
          <TableContainer className="content-table">
            <Table aria-label="collapsible table">
              <TableHead>
                <TableRow className="accordion-title-row">
                  <TableCell className="accordion-title-cell" />

                  <TableCell className="accordion-title-cell hand" style={{ width: '40%' }} align="left" onClick={sort('adiTr')}>
                    <div className="tableHeaderContainer">
                      <Translate contentKey="global.languageGlobal.adiTrTitle">Adi Tr</Translate>{' '}
                      <SortIcon column="adiTr" activeSortColumn={pageObject.sort} />
                    </div>
                  </TableCell>

                  <TableCell className="accordion-title-cell hand" style={{ width: '40%' }} align="left" onClick={sort('adiEn')}>
                    <div className="tableHeaderContainer">
                      <Translate contentKey="global.languageGlobal.adiEnTitle">Adi En</Translate>{' '}
                      <SortIcon column="adiEn" activeSortColumn={pageObject.sort} />
                    </div>
                  </TableCell>

                  <TableCell className="accordion-title-cell" />
                </TableRow>
              </TableHead>
              <TableBody>
                {refAdtEkip?.map(ekip => (
                  <>
                    <TableRow
                      className="accordion-content-row"
                      sx={{ '& > *': { borderBottom: 'unset' } }}
                      onClick={() => getGorevTable(ekip)}
                    >
                      <TableCell className="accordion-content-cell" style={{ width: 48 }}>
                        <IconButton aria-label="expand row" size="small" className="accordion-icon">
                          {openItemId === ekip.id ? <KeyboardArrowDownOutlined /> : <KeyboardArrowRight />}
                        </IconButton>
                      </TableCell>
                      <TableCell className="accordion-content-cell" component="th" scope="row">
                        {ekip?.adiTr}
                      </TableCell>
                      <TableCell className="accordion-content-cell" component="th" scope="row">
                        {ekip?.adiEn}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className="accordion-content-area" colSpan={12}>
                        <Collapse in={openItemId === ekip.id} unmountOnExit>
                          <Box className="accordion-box accordion-box-bottom-zero">
                            {selectedAdtEkip && (
                              <HasAuthComponent grantedAuthorites={['ref_gorev_read']}>
                                <Typography className="accordion-typography" gutterBottom component="div" style={{ marginTop: 12 }}>
                                  <span>
                                    <Translate contentKey="tk24AdysApp.refKrizTuruKatilimciGorev.adtGorevler">adtGorevler</Translate>
                                  </span>
                                  <br />
                                  <span className="totalListItemText">
                                    <Translate contentKey="global.item-total-count" interpolate={{ total: refAdtGorevList?.length }} />
                                  </span>
                                  <div className="search-frame">
                                    <div className="seachiconposition">
                                      <img
                                        className="seachicon"
                                        width={24}
                                        height={24}
                                        src={`${SERVER_URL}/content/images/icon/faSearch.svg`}
                                      />
                                      <Input
                                        value={accordionSearch}
                                        onChange={e => setAccordionSearch(e.target.value)}
                                        placeholder={translate('entity.action.search')}
                                        className="search-input"
                                      />
                                    </div>
                                    <div className="create-area">
                                      <ExcelDownloadButton
                                        excelSampleData={AccordionExcelSampleData}
                                        grantedAuthorites={['ref_gorev_export']}
                                        fetchData={fetchTotalAdtGorevData}
                                        className={'download-button'}
                                        exportName={`${selectedAdtEkip.adi} ${translate('tk24AdysApp.refGorev.detail.exportHeader')}`}
                                      />
                                    </div>
                                  </div>
                                </Typography>
                                {refAdtGorevList?.length > 0 ? (
                                  <>
                                    <Table className="accordion-table">
                                      <TableHead>
                                        <TableRow className="accordion-table-title-row">
                                          <TableCell className="accordion-table-title-cell" align="left" onClick={sortAcordion('adiTr')}>
                                            <div className="tableHeaderContainer">
                                              <Translate contentKey="global.languageGlobal.adiTrTitle">adiTr</Translate>&nbsp;
                                              <SortIcon column="adiTr" activeSortColumn={pageObjectRefGorev.sort} />
                                            </div>
                                          </TableCell>
                                          <TableCell className="accordion-table-title-cell" align="left" onClick={sortAcordion('adiEn')}>
                                            <div className="tableHeaderContainer">
                                              <Translate contentKey="global.languageGlobal.adiEnTitle">adiEn</Translate>&nbsp;
                                              <SortIcon column="adiEn" activeSortColumn={pageObjectRefGorev.sort} />
                                            </div>
                                          </TableCell>
                                          <TableCell className="accordion-table-title-cell" align="left" onClick={sortAcordion('gorevId')}>
                                            <div className="tableHeaderContainer">
                                              <Translate contentKey="tk24AdysApp.krizKatilimciGorev.gorevID">Görev ID</Translate>
                                              <SortIcon column="gorevId" activeSortColumn={pageObjectRefGorev.sort} />
                                            </div>
                                          </TableCell>
                                          <TableCell className="sticky-right-header-accordion-alt"> </TableCell>
                                        </TableRow>
                                      </TableHead>
                                      <TableBody>
                                        {refAdtGorevList?.map(gorev => (
                                          <TableRow key={gorev.id} className="accordion-table-content-row">
                                            <TableCell className="accordion-table-content-cell" component="th" scope="row">
                                              {gorev?.adiTr}
                                            </TableCell>
                                            <TableCell className="accordion-table-content-cell" component="th" scope="row">
                                              {gorev?.adiEn}
                                            </TableCell>
                                            <TableCell className="accordion-table-content-cell" component="th" scope="row">
                                              {gorev?.realId}
                                            </TableCell>
                                            <TableCell className="sticky-right" component="th" scope="row" align="right">
                                              <div className="btn-group flex-btn-group-container">
                                                <GrantedButton
                                                  grantedAuthorites={['ref_gorev_edit']}
                                                  className="edit-button"
                                                  onClick={() => {
                                                    setSelectedTask(gorev);
                                                    setIsViewing(false);
                                                    setIsGorevUpdateModal(true);
                                                  }}
                                                  size="sm"
                                                  data-cy="entityEditButton"
                                                >
                                                  <Tooltip title={<Translate contentKey="entity.action.edit">Edit</Translate>}>
                                                    <img width={14} height={14} src={`${SERVER_URL}/content/images/icon/faPencil.svg`} />
                                                  </Tooltip>
                                                  <span className="show-on-lg">
                                                    <Translate contentKey="entity.action.edit">Edit</Translate>
                                                  </span>
                                                </GrantedButton>
                                                <GrantedButton
                                                  grantedAuthorites={['ref_gorev_read']}
                                                  className="view-button"
                                                  onClick={() => {
                                                    setSelectedTask(gorev);
                                                    setIsViewing(true);
                                                    setIsGorevUpdateModal(true);
                                                  }}
                                                  style={{ marginLeft: 10 }}
                                                  size="sm"
                                                  data-cy="entityDetailsButton"
                                                >
                                                  <Tooltip title={<Translate contentKey="entity.action.view">View</Translate>}>
                                                    <img width={15.2} height={11.2} src={`${SERVER_URL}/content/images/icon/faEye.svg`} />
                                                  </Tooltip>
                                                  <span className="show-on-lg">
                                                    <Translate contentKey="entity.action.view">View</Translate>
                                                  </span>
                                                </GrantedButton>
                                                <GrantedButton
                                                  grantedAuthorites={['ref_gorev_delete']}
                                                  onClick={() => confirmGorevDelete(gorev)}
                                                  style={{ marginLeft: 10 }}
                                                  className="delete-button"
                                                  size="sm"
                                                  data-cy="entityDeleteButton"
                                                >
                                                  <Tooltip title={<Translate contentKey="entity.action.delete">Delete</Translate>}>
                                                    <img
                                                      width={11.2}
                                                      height={12.8}
                                                      src={`${SERVER_URL}/content/images/icon/faTrashCan.svg`}
                                                    />
                                                  </Tooltip>
                                                  <span className="show-on-lg">
                                                    <Translate contentKey="entity.action.delete">Delete</Translate>
                                                  </span>
                                                </GrantedButton>
                                              </div>
                                            </TableCell>
                                          </TableRow>
                                        ))}
                                      </TableBody>
                                    </Table>
                                  </>
                                ) : (
                                  !adtLoading && (
                                    <div className="alert alert-warning">
                                      <Translate contentKey="tk24AdysApp.refGorev.home.notFound">No refGorev found</Translate>
                                    </div>
                                  )
                                )}
                                <div className="flex-start-frame">
                                  <GrantedButton
                                    grantedAuthorites={['ref_gorev_edit']}
                                    onClick={() => {
                                      setIsGorevUpdateModal(true);
                                    }}
                                    className="accordion-create-button"
                                    size="sm"
                                    data-cy="entityCreateButton"
                                  >
                                    <img width={14} height={14} src={`${SERVER_URL}/content/images/icon/faPlusBlue.svg`} />
                                    <span className="d-none d-md-inline">
                                      <Translate contentKey="tk24AdysApp.refGorev.home.createLabel" />
                                    </span>
                                  </GrantedButton>
                                </div>
                              </HasAuthComponent>
                            )}
                          </Box>
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  </>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <CustomPagination
          currentPage={pageObject.page + 1}
          currentPageSize={pageObject.size}
          totalItems={totalItem}
          handlePageChange={handlePageChange}
        />
      </div>
    </div>
  );
};

export default RefAdtGorev;
