import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isDrawerOpen: false,
};

export type krizChatSliceState = Readonly<typeof initialState>;

export const krizChatSlice = createSlice({
  name: 'krizChat',
  initialState: initialState as krizChatSliceState,
  reducers: {
    toggleDrawer(state) {
      state.isDrawerOpen = !state.isDrawerOpen;
    },
    closeDrawer(state) {
      state.isDrawerOpen = false;
    },
    openDrawer(state) {
      state.isDrawerOpen = true;
    },
  },
});

export const { toggleDrawer, closeDrawer, openDrawer } = krizChatSlice.actions;

export default krizChatSlice.reducer;
