import { Storage } from 'app/component/jhipster';
import { IFileList } from '../model/ek-dosya.model';
import { useRef } from 'react';
import axios from 'axios';
import L from "leaflet";
import {SERVER_URL} from "app/config/constants";

export const localeLanguage = Storage.local.get('locale') || Storage.session.get('locale', 'tr');

export function upperOnlyFirstLetter(text) {
  return text.charAt(0).toUpperCase() + text.slice(1);
}

export function GetBase64PromiseString(uploadedFiles): Promise<IFileList[]> {
  return Promise.all(
    uploadedFiles.map(async item => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
          const result = reader.result;
          if (typeof result === 'string') {
            const file = result.split(',')[1];
            resolve({
              file,
              fileName: item.name,
              mimeType: item.type,
            });
          } else {
            reject(new Error('Dosya okunurken hata oluştu'));
          }
        };
        reader.onerror = error => {
          reject(error);
        };
        reader.readAsDataURL(item);
      });
    })
  );
}

export async function handleDownloadSavedFile(file) {
  try {
    const response = await axios.get(`api/ek-dosyas/download/${file.id}`, {
      responseType: 'blob',
    });

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${file.dosyaAdi}`);
    document.body.appendChild(link);
    link.click();
  } catch (error) {
    console.error('Dosya indirme hatası:', error);
  }
}

export function arrayUnique(array) {
  const a = array.concat();
  for(let i=0; i<a.length; ++i) {
    for(let j=i+1; j<a.length; ++j) {
      if(a[i] === a[j])
        a.splice(j--, 1);
    }
  }

  return a;
}

export const customIcon = L.icon({
  iconUrl: SERVER_URL + '/content/images/leaflet/marker-icon.png',
  iconSize: [32, 32],
});

export const airportIcon = L.icon({
  iconUrl: SERVER_URL + '/content/images/leaflet/airport-pin.svg',
  iconSize: [32, 32],
});

export function base64ToArrayBuffer(base64) {
  const binaryString = atob(base64);
  const bytes = new Uint8Array(binaryString.length);
  for (let i = 0; i < binaryString.length; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes.buffer;
}

export const getFileNameAndExtension = filename => {
  const dotIndex = filename.lastIndexOf('.');
  if (dotIndex === -1) {
    return { name: filename, extension: '' };
  }
  return { name: filename.substring(0, dotIndex), extension: filename.substring(dotIndex) };
};
