import React, { FormEventHandler } from 'react';
import { Button, Row, Col, ModalHeader, ModalBody, ModalFooter, Modal, Form } from 'reactstrap';
import { Translate, translate } from 'app/component/jhipster';
import { IReferans } from 'app/shared/model/referans.model';
import { FormItem } from 'app/component/FormItem';

interface ReferansUpdateProp {
  saveNewRefEntity: FormEventHandler;
  handleCloseForm: FormEventHandler;
  formValues?: IReferans;
  isOpen: boolean;
  isViewing: boolean;
}

export const ReferansUpdate: React.FC<ReferansUpdateProp> = ({ saveNewRefEntity, handleCloseForm, formValues, isOpen, isViewing }) => {
  return (
    <Modal className="modal-style" isOpen={isOpen} toggle={handleCloseForm} centered>
      <ModalHeader data-cy="RefKisiAltTipiCreateUpdateHeading" toggle={handleCloseForm}>
        {formValues && (
          <p className="modal-title-info">
            {formValues?.adiTr}-{formValues?.adiEn}
          </p>
        )}
        {formValues ? (
          <Translate contentKey="tk24AdysApp.referans.home.editLabel">Update a Referans</Translate>
        ) : (
          <Translate contentKey="tk24AdysApp.referans.home.createLabel">Create a Referans</Translate>
        )}
      </ModalHeader>
      <Form onSubmit={saveNewRefEntity}>
        <ModalBody>
          <Row>
            <Col md={12}>
              <FormItem
                label={translate('tk24AdysApp.referans.tipi')}
                id="referans-tipi"
                name="tipi"
                defaultValue={formValues && formValues['tipi']}
                type="text"
                disabled={true}
                validation={{
                  required: { value: true, message: translate('entity.validation.required') },
                  maxLength: { value: 100, message: translate('entity.validation.maxlength', { max: 100 }) },
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <FormItem
                label={translate('global.languageGlobal.adiTr')}
                id="referans-adiTr"
                name="adiTr"
                defaultValue={formValues && formValues['adiTr']}
                disabled={isViewing}
                type="text"
                validation={{
                  required: { value: true, message: translate('entity.validation.required') },
                  maxLength: { value: 200, message: translate('entity.validation.maxlength', { max: 200 }) },
                }}
              />
              <FormItem
                label={translate('global.languageGlobal.adiEn')}
                id="referans-adiEn"
                name="adiEn"
                defaultValue={formValues && formValues['adiEn']}
                disabled={isViewing}
                type="text"
                validation={{
                  required: { value: true, message: translate('entity.validation.required') },
                  maxLength: { value: 200, message: translate('entity.validation.maxlength', { max: 200 }) },
                }}
              />
            </Col>
            <Col md={12}>
              <FormItem
                label={translate('global.languageGlobal.aciklamaTr')}
                id="referans-aciklamaTr"
                name="aciklamaTr"
                defaultValue={formValues && formValues['aciklamaTr']}
                disabled={isViewing}
                type="textarea"
                validation={{
                  maxLength: { value: 1000, message: translate('entity.validation.maxlength', { max: 1000 }) },
                }}
              />
              <FormItem
                label={translate('global.languageGlobal.aciklamaEn')}
                id="referans-aciklamaEn"
                name="aciklamaEn"
                defaultValue={formValues && formValues['aciklamaEn']}
                disabled={isViewing}
                type="textarea"
                validation={{
                  maxLength: { value: 1000, message: translate('entity.validation.maxlength', { max: 1000 }) },
                }}
              />
            </Col>
          </Row>
        </ModalBody>
        {!isViewing && (
          <ModalFooter className="modal-footer">
            <Button className="cancel-button" onClick={handleCloseForm}>
              <Translate contentKey="entity.action.cancel">Cancel</Translate>
            </Button>
            &nbsp;
            <Button className="save-button" id="jhi-confirm-delete-refAdtForm" type="submit" data-cy="entityConfirmButton">
              <Translate contentKey="entity.action.save">Kaydet</Translate>
            </Button>
          </ModalFooter>
        )}
      </Form>
    </Modal>
  );
};

export default ReferansUpdate;
