import { IReferans } from 'app/shared/model/referans.model';
import { IKullaniciRol } from 'app/shared/model/kullanici-rol.model';
import { IQueryParams } from '../reducers/reducer.utils';
import { IPersonel } from './personel.model';

export interface IKullanici {
  id?: number;
  guid?: string;
  adi?: string;
  soyadi?: string | null;
  kullaniciAdi?: string;
  unvanAdi?: string;
  eposta?: string | null;
  gsmNo?: string | null;
  tckn?: string | null;
  sonLoginZamani?: string | null;
  loginDenemeSayisi?: number;
  cDate?: string;
  cUser?: number;
  mDate?: string;
  mUser?: number;
  durum?: IReferans;
  kullaniciRols?: IKullaniciRol[] | null;
  airport?: string | null;
  baskanlik?: string | null;
  company?: string | null;
  langKey?: string | null;
  lokasyon?: string | null;
  mudurluk?: string | null;
  realId?: string | number | null;
  rols?: string | null;
  sehir?: string | null;
  sicilNo?: string | null;
  personel?: IPersonel;
}

export interface IKullaniciParams extends IQueryParams {
  entity?: IKullanici;
}

export const defaultValue: Readonly<IKullanici> = {};
