import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { Translate, translate } from 'app/component/jhipster';
import { hasAnyAuthority, useAppDispatch, useAppSelector } from 'app/config/store';
import { CustomSaveEntityConfirm } from 'app/shared/util/confirm-utils';
import { updateEntity as updateEtkilenenKisi } from './reducer/etkilenen-kisi.reducer';
import { Col, Form, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { IKisi } from 'app/shared/model/kisi.model';
import { FormItem } from 'app/component/FormItem';
import { LoadingSpinner } from 'app/component/LoadingSpinner';
import GrantedButton from 'app/component/GrantedButton';

interface EtkilenenKisiSaglikDialogProps {
  open: boolean;
  onClose: () => void;
  saveOnClose: () => void;
  selectedYolcu: IKisi;
  headingName?: string;
  krizId: string;
}

const EtkilenenKisiSaglikDialog: React.FC<EtkilenenKisiSaglikDialogProps> = ({
  open,
  onClose,
  saveOnClose,
  selectedYolcu,
  krizId,
  headingName,
}) => {
  const refSaglikDurums = useAppSelector(state => state.refSaglikDurumu.entities);

  const dispatch = useAppDispatch();
  const [openModal, setOpenModal] = useState(open);
  const saglikDurumEditYetki = hasAnyAuthority(['etkilenen_kisi_saglik_durumu_edit']);

  const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);
  const [modalPromiseResolve, setModalPromiseResolve] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setOpenModal(open);
  }, [open]);

  const showSaveConfirmModal = () => {
    setIsSaveModalOpen(true);
    return new Promise(resolve => {
      setModalPromiseResolve(() => resolve);
    });
  };

  const handleConfirm = () => {
    if (modalPromiseResolve) {
      modalPromiseResolve(true);
    }
    setIsSaveModalOpen(false);
  };

  const handleCancel = () => {
    if (modalPromiseResolve) {
      modalPromiseResolve(false);
    }
    setIsSaveModalOpen(false);
  };

  const saveEntityNewRecord = async values => {
    values.preventDefault();
    setLoading(true);

    const isSave = await showSaveConfirmModal();

    if (isSave) {
      const formDataObject = {};
      for (const field of values.target.elements) {
        if (field.name) {
          formDataObject[field.name] = field.value;
        }
      }

      const entity = {
        ...selectedYolcu,
        krizId,
        saglikDurumu: refSaglikDurums.find(it => it.id.toString() === formDataObject['saglikDurumu'].toString()),
      };
      try {
        await dispatch(updateEtkilenenKisi(entity));
        setLoading(false);
        saveOnClose();
      } catch (error) {
        console.error('Error saving entity:', error);
        setLoading(false);
      }
    } else {
      setLoading(false);
      handleClose();
    }
  };

  const handleClose = () => {
    setOpenModal(false);
    onClose();
  };

  return (
    <>
      <CustomSaveEntityConfirm entity={selectedYolcu} isOpen={isSaveModalOpen} onConfirm={handleConfirm} onCancel={handleCancel} />

      <Modal className="modal-style" isOpen={openModal} onClose={onClose} toggle={onClose} centered>
        <ModalHeader data-cy="RefEtkilenenKisiYolcuCreateUpdateHeading" toggle={onClose}>
          {headingName && <p className="modal-title-info">{headingName}</p>}
          {selectedYolcu && <Translate contentKey="tk24AdysApp.saglikDurum.detail.title" />}
        </ModalHeader>
        <Form onSubmit={saveEntityNewRecord}>
          <ModalBody>
            {loading && <LoadingSpinner />}
            <Row>
              <Col md={12}>
                <FormItem
                  id="etkilenen-kisi-saglik-durumu"
                  name="saglikDurumu"
                  data-cy="saglikDurumu"
                  label="Sağlık Durumu Güncelle"
                  type="select"
                  disabled={!saglikDurumEditYetki}
                  defaultValue={selectedYolcu !== null && selectedYolcu['saglikDurumu'] !== null && selectedYolcu['saglikDurumu'].id}
                  validation={{
                    required: { value: true, message: translate('entity.validation.required') },
                  }}
                >
                  <option value="" key="0" />
                  {refSaglikDurums
                    ? refSaglikDurums.map(otherEntity => (
                        <option value={otherEntity.id} key={otherEntity.id}>
                          {otherEntity.adi}
                        </option>
                      ))
                    : null}
                </FormItem>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter className="modal-footer">
            <GrantedButton grantedAuthorites={['etkilenen_kisi_saglik_durumu_edit']} className="cancel-button" onClick={handleClose}>
              <Translate contentKey="entity.action.cancel">Cancel</Translate>
            </GrantedButton>
            &nbsp;
            <GrantedButton
              grantedAuthorites={['etkilenen_kisi_saglik_durumu_edit']}
              className="save-button"
              id="jhi-confirm-delete-refAdtForm"
              type="submit"
              data-cy="entityConfirmButton"
            >
              <Translate contentKey="entity.action.save">Kaydet</Translate>
            </GrantedButton>
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
};

export default EtkilenenKisiSaglikDialog;
