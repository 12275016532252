import { IQueryParams } from '../reducers/reducer.utils';
import { IKriz } from './kriz.model';

export interface IKrizIrtibat {
  id?: number;
  guid?: string;
  adi?: string;
  soyadi?: string;
  eposta?: string;
  gsmNo?: string;
  gorevi?: string;
  cDate?: string;
  cUser?: number;
  mDate?: string;
  mUser?: number;
  kriz?: IKriz | null;
  fax?: string | null;
  adres?: string | null;
  hesapNo?: string | null;
  telefonFax?: string | null;
  telefonNo?: string | null;
  webPage?: string | null;
  adiSoyadiUnvani?: string | null;
  telefonNo1?: string | null;
  telefonNo2?: string | null;
  aciklama?: string | null;
}

export interface IKrizIrtibatParams extends IQueryParams {
  entity?: IKrizIrtibat;
  krizId?: string;
}

export const defaultValue: Readonly<IKrizIrtibat> = {};
