import React, { useEffect } from 'react';
import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import {
  getDrawerEntityBySicilId,
  getEntityBySicilId,
  getEntities as getKatilimciEntities,
} from '../kriz-katilimci/kriz-katilimci.reducer';
import { KatilimciRolMap, ReferansEnum } from 'app/constants/ReferansEnum';
import KrizChatKym from './kriz-chat-kym/kriz-chat-kym';
import { getEntity as getKrizEntity } from 'app/entities/kriz/kriz.reducer';
import { getEntities as getKrizAdtEkip } from 'app/entities/kriz-adt-ekip/kriz-adt-ekip.reducer';
import KrizChatAdt from './kriz-chat-adt/kriz-chat-adt';
import KrizChatYonetim from './kriz-chat-yonetim/kriz-chat-yonetim';
import { KartLoadingSpinner } from 'app/component/LoadingSpinner';
import { isObjectEmpty } from 'app/shared/util/confirm-utils';
import { localeLanguage, upperOnlyFirstLetter } from 'app/shared/util/util';
import { IconButton } from '@mui/material';
import Close from '@mui/icons-material/Close';
import { Translate } from 'app/component/jhipster';
import { getEntities as getReferans } from '../referans/referans.reducer';

const KrizChatNew = ({ krizId, open, onClose }) => {
  const dispatch = useAppDispatch();

  const kriz = useAppSelector(state => state.kriz.entity);

  const krizKatilimci = useAppSelector(state => state.krizKatilimci.entity);

  const krizAdtEkipLoading = useAppSelector(state => state.krizAdtEkip.loading);

  const kymVarMi = krizKatilimci?.katilimciRolu && KatilimciRolMap.KYM.includes(krizKatilimci.katilimciRolu?.id);
  const adtVarMi = krizKatilimci?.katilimciRolu && KatilimciRolMap.ADT.includes(krizKatilimci.katilimciRolu?.id);
  const istasyonMi = krizKatilimci?.katilimciRolu && KatilimciRolMap.ISTASYON.includes(krizKatilimci.katilimciRolu?.id);

  useEffect(() => {
    if (isObjectEmpty(kriz)) {
      dispatch(getKrizEntity(krizId));
    }

    dispatch(getReferans({ page: 0, size: 999, sort: 'id,desc' }));

    if (!kymVarMi) {
      dispatch(
        getKrizAdtEkip({
          query: `krizId.equals=${krizId}`,
          size: 999,
          page: 0,
          sort: `adtEkipAdi${upperOnlyFirstLetter(localeLanguage)},asc`,
        })
      );
    }
  }, []);

  return (
    <Drawer anchor="right" open={open} onClose={onClose} className="chatRightDrawer2" onFocus={e => e.stopPropagation()}>
      <Box sx={{ width: 610 }} className="chatRightDrawer" role="presentation">
        <div style={{ height: '100%' }}>
          {krizAdtEkipLoading && <KartLoadingSpinner />}
          <div>
            <div className="chatCloseNew">
              <div className="chatNewTitle">
                <Translate contentKey="tk24AdysApp.krizChat.detail.title" />
              </div>
              <IconButton onClick={onClose} aria-label="close">
                <Close />
              </IconButton>
            </div>
          </div>
          {!istasyonMi && (
            <>
              {kymVarMi && <KrizChatKym krizId={krizId} />}
              {adtVarMi && !krizAdtEkipLoading && <KrizChatAdt krizId={krizId} />}
              {!kymVarMi && !adtVarMi && !krizAdtEkipLoading && <KrizChatYonetim krizId={krizId} />}
            </>
          )}
        </div>
      </Box>
    </Drawer>
  );
};

export default KrizChatNew;
