import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Card, CardText, Col, Container, Input, Row } from 'reactstrap';
import { TextFormat, Translate, translate } from 'app/component/jhipster';
import { APP_DATE_FORMAT, APP_DATETIME_FORMAT, APP_LOCAL_TIME_FORMAT, SERVER_URL } from 'app/config/constants';
import { ReferansEnum } from 'app/constants/ReferansEnum';
import { useAppSelector } from 'app/config/store';
import { Search } from '@mui/icons-material';
import KrizChatDetail from './kriz-chat-detail';
import { isNullOrEmpty, isObjectEmpty, messageReplace } from 'app/shared/util/confirm-utils';
import GrantedButton from 'app/component/GrantedButton';
import { DrawerLoadingSpinner } from 'app/component/LoadingSpinner';
import axios from 'axios';

const KrizChatYonetim = ({ krizId }) => {
  const kriz = useAppSelector(state => state.kriz.entity);
  const krizKatilimciEntities = useAppSelector(state => state.krizKatilimci.entities);

  const [messageList, setMessageList] = useState([]);

  const [loading, setLoading] = useState(false);
  const krizAdtEkipEntities = useAppSelector(state => state.krizAdtEkip.entities);
  const [selectedMessageKullanici, setSelectedMessageKullanici] = useState(null);

  const [isOpenChatDetail, setIsOpenChatDetail] = useState(false);
  const [isOpenNewChat, setIsOpenNewChat] = useState(false);
  const [selectedAliciTipi, setSelectedAliciTipi] = useState(null);
  const [selectedGonderenSicil, setSelectedGonderenSicil] = useState(null);
  const [selectedGonderenTim, setSelectedGonderenTim] = useState(null);

  const [tumAdtMessage, setTumAdtMessage] = useState(null);
  const [tumKymMessage, setTumKymMessage] = useState(null);
  const [yonetimMessage, setYonetimMessage] = useState(null);
  const [adtTimMessage, setAdtTimMessage] = useState([]);

  useEffect(() => {
    getMessageList();
    getMessageCustomList();
  }, [kriz.realId]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (!isOpenChatDetail) {
        getMessageListReload();
        getMessageCustomListReload();
      }
    }, 5000);
    return () => clearInterval(interval);
  }, [isOpenChatDetail]);

  const getMessageList = async () => {
    try {
      setLoading(true);
      const krizChatUrl = `api/kriz-chats/get-messages-dm?krizGuid=${kriz.id}`;
      await axios.get(krizChatUrl).then(response => {
        setMessageList(response.data);
        setLoading(false);
      });
    } catch {
      setLoading(false);
    }
  };

  const getMessageCustomList = async () => {
    try {
      setLoading(true);
      const krizChatUrl = `api/kriz-chats/get-messages-custom?krizId.equals=${kriz.id}`;

      await axios
        .get(
          `${krizChatUrl}&mesajlasmaTipiId.equals=${ReferansEnum.MesajlasmaTipi.Yonetim}&mesajAliciTipiId.equals=${ReferansEnum.MesajAliciTipi.TumAdt}`
        )
        .then(response => {
          setTumAdtMessage(response.data);
        });

      await axios
        .get(
          `${krizChatUrl}&mesajlasmaTipiId.equals=${ReferansEnum.MesajlasmaTipi.Yonetim}&mesajAliciTipiId.equals=${ReferansEnum.MesajAliciTipi.TumKym}`
        )
        .then(response => {
          setTumKymMessage(response.data);
        });

      for (const krizAdtEkip of krizAdtEkipEntities) {
        axios
          .get(
            `${krizChatUrl}&mesajlasmaTipiId.equals=${ReferansEnum.MesajlasmaTipi.Yonetim}&mesajAliciTipiId.equals=${ReferansEnum.MesajAliciTipi.BirAdtTimi}&aliciKrizAdtEkipId.equals=${krizAdtEkip.id}`
          )
          .then(response => {
            if (response.data) {
              const newData = { ...response.data, krizAdtEkipId: krizAdtEkip.id };
              setAdtTimMessage(prevData => {
                const mevcutIndex = prevData.findIndex(item => item.krizAdtEkipId === newData.krizAdtEkipId);

                if (mevcutIndex !== -1) {
                  const yeniArray = [...prevData];
                  yeniArray[mevcutIndex] = { ...yeniArray[mevcutIndex], ...newData };
                  return yeniArray;
                } else {
                  return [...prevData, newData];
                }
              });
            }
          });
      }

      await axios
        .get(
          `${krizChatUrl}&mesajlasmaTipiId.equals=${ReferansEnum.MesajlasmaTipi.Yonetim}&mesajAliciTipiId.equals=${ReferansEnum.MesajAliciTipi.Yonetim}`
        )
        .then(response => {
          setYonetimMessage(response.data);
        });
      setLoading(false);
    } catch {
      setLoading(false);
    }
  };

  const getMessageListReload = () => {
    const krizChatUrl = `api/kriz-chats/get-messages-dm?krizGuid=${kriz.id}`;
    axios.get(krizChatUrl).then(response => {
      setMessageList(response.data);
    });
  };

  const getMessageCustomListReload = () => {
    const krizChatUrl = `api/kriz-chats/get-messages-custom?krizId.equals=${kriz.id}`;

    axios
      .get(
        `${krizChatUrl}&mesajlasmaTipiId.equals=${ReferansEnum.MesajlasmaTipi.Yonetim}&mesajAliciTipiId.equals=${ReferansEnum.MesajAliciTipi.TumAdt}`
      )
      .then(response => {
        setTumAdtMessage(response.data);
      });

    axios
      .get(
        `${krizChatUrl}&mesajlasmaTipiId.equals=${ReferansEnum.MesajlasmaTipi.Yonetim}&mesajAliciTipiId.equals=${ReferansEnum.MesajAliciTipi.TumKym}`
      )
      .then(response => {
        setTumKymMessage(response.data);
      });

    for (const krizAdtEkip of krizAdtEkipEntities) {
      axios
        .get(
          `${krizChatUrl}&mesajlasmaTipiId.equals=${ReferansEnum.MesajlasmaTipi.Yonetim}&mesajAliciTipiId.equals=${ReferansEnum.MesajAliciTipi.BirAdtTimi}&aliciKrizAdtEkipId.equals=${krizAdtEkip.id}`
        )
        .then(response => {
          if (response.data) {
            const newData = { ...response.data, krizAdtEkipId: krizAdtEkip.id };
            setAdtTimMessage(prevData => {
              const mevcutIndex = prevData.findIndex(item => item.krizAdtEkipId === newData.krizAdtEkipId);

              if (mevcutIndex !== -1) {
                const yeniArray = [...prevData];
                yeniArray[mevcutIndex] = { ...yeniArray[mevcutIndex], ...newData };
                return yeniArray;
              } else {
                return [...prevData, newData];
              }
            });
          }
        });
    }

    axios
      .get(
        `${krizChatUrl}&mesajlasmaTipiId.equals=${ReferansEnum.MesajlasmaTipi.Yonetim}&mesajAliciTipiId.equals=${ReferansEnum.MesajAliciTipi.Yonetim}`
      )
      .then(response => {
        setYonetimMessage(response.data);
      });
  };

  const chatDetail = (messages, mesajAliciTipi, gonderenAdtTim, gonderenSicil) => {
    setSelectedMessageKullanici(messages);
    setSelectedAliciTipi(mesajAliciTipi);
    setSelectedGonderenSicil(gonderenSicil);
    setSelectedGonderenTim(gonderenAdtTim);
    setIsOpenChatDetail(true);
    setIsOpenNewChat(false);
  };

  const chatDetailNew = () => {
    setIsOpenChatDetail(true);
    setIsOpenNewChat(true);
    setSelectedAliciTipi(ReferansEnum.MesajAliciTipi.Dm);
  };

  const closeDetailModal = () => {
    setIsOpenChatDetail(false);
  };

  const circleStyle = {
    width: '32px',
    height: '32px',
    borderRadius: '50%',
    backgroundColor: '#C1C8D1',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
  };

  const TumKYMChat = () => {
    return (
      <Card
        body
        className="cardChat"
        onClick={() => chatDetail(null, ReferansEnum.MesajAliciTipi.TumKym, null, null)}
        style={{
          backgroundColor: '#F2F3F580',
        }}
      >
        <div>
          <img width={32} height={32} src={`${SERVER_URL}/content/images/icon/faUserOvers.svg`} />
        </div>
        <CardText style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <span style={{ fontSize: '20px', color: '#34404F', fontWeight: '500' }}>
              <Translate contentKey="tk24AdysApp.krizChat.tumKYM" />
            </span>
            <span style={{ fontSize: '14px', color: '#919DAC', fontWeight: '400' }}>
              {tumKymMessage && <TextFormat value={tumKymMessage.cDate} type="date" format={APP_DATETIME_FORMAT} />}
            </span>
          </div>

          <span style={{ color: '#919DAC', fontSize: '15px' }}>{tumKymMessage && messageReplace(tumKymMessage.icerik)}</span>
        </CardText>
      </Card>
    );
  };

  const TumADT = () => {
    return (
      <Card
        body
        className="cardChat"
        onClick={() => chatDetail(null, ReferansEnum.MesajAliciTipi.TumAdt, null, null)}
        style={{
          backgroundColor: '#F2F3F580',
        }}
      >
        <div>
          <img width={32} height={32} src={`${SERVER_URL}/content/images/icon/faUserOvers.svg`} />
        </div>
        <CardText style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <span style={{ fontSize: '20px', color: '#34404F', fontWeight: '500' }}>
              <Translate contentKey="tk24AdysApp.krizChat.tumADT" />
            </span>
            <span style={{ fontSize: '14px', color: '#919DAC', fontWeight: '400' }}>
              {tumAdtMessage && <TextFormat value={tumAdtMessage.cDate} type="date" format={APP_DATETIME_FORMAT} />}
            </span>
          </div>

          <span style={{ color: '#919DAC', fontSize: '15px' }}>{tumAdtMessage && messageReplace(tumAdtMessage.icerik)}</span>
        </CardText>
      </Card>
    );
  };

  const YonetimChat = () => {
    return (
      <Card
        body
        className="cardChat"
        style={{
          backgroundColor: '#F2F3F580',
        }}
      >
        <div>
          <img width={32} height={32} src={`${SERVER_URL}/content/images/icon/faUserOvers.svg`} />
        </div>
        <CardText
          style={{ display: 'flex', flexDirection: 'column' }}
          onClick={() => chatDetail(null, ReferansEnum.MesajAliciTipi.Yonetim, null, null)}
        >
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <span style={{ fontSize: '20px', color: '#34404F', fontWeight: '500' }}>
              <Translate contentKey="tk24AdysApp.krizChat.yonetim" />
            </span>
            <span style={{ fontSize: '14px', color: '#919DAC', fontWeight: '400' }}>
              {yonetimMessage && <TextFormat value={yonetimMessage.cDate} type="date" format={APP_DATETIME_FORMAT} />}
            </span>
          </div>

          <span style={{ color: '#919DAC', fontSize: '15px' }}>{yonetimMessage && messageReplace(yonetimMessage.icerik)}</span>
        </CardText>
      </Card>
    );
  };

  const KrizADTTim = () => {
    return (
      <>
        {krizAdtEkipEntities &&
          krizAdtEkipEntities.map(item => {
            const krizAdtMessage = adtTimMessage.find(v => v.krizAdtEkipId === item.id);
            return (
              <Card
                key={item.id}
                body
                className="cardChat"
                onClick={() => chatDetail(null, ReferansEnum.MesajAliciTipi.BirAdtTimi, item, null)}
                style={{
                  backgroundColor: '#F2F3F580',
                }}
              >
                <div className="d-flex justify-content-center align-items-center">
                  <img width={32} height={32} src={`${SERVER_URL}/content/images/icon/faUserOvers.svg`} />
                </div>
                <CardText style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                  <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: '10px' }}>
                    <span style={{ fontSize: '20px', color: '#34404F', fontWeight: '500' }}>{item.adtEkip?.adi}</span>
                    <span style={{ fontSize: '14px', color: '#919DAC', fontWeight: '400' }}>
                      {krizAdtMessage && <TextFormat value={krizAdtMessage.cDate} type="date" format={APP_DATETIME_FORMAT} />}
                    </span>
                  </div>

                  <span style={{ color: '#919DAC', fontSize: '15px' }}>{krizAdtMessage && messageReplace(krizAdtMessage.icerik)}</span>
                </CardText>
              </Card>
            );
          })}
      </>
    );
  };

  const DMChat = () => {
    return (
      <>
        {!loading &&
          messageList.length > 0 &&
          messageList.map((message, index) => {
            return (
              <Card key={index} body className="cardChat" style={{ backgroundColor: '#F2F3F580' }}>
                <div>
                  <div style={circleStyle}>
                    <span style={{ fontSize: '14px' }}>
                      {message.adi && message.adi.charAt(0)}
                      {message.soyadi && message.soyadi.charAt(0)}
                    </span>
                  </div>
                </div>

                <CardText
                  style={{ display: 'flex', flexDirection: 'column', width: '100%' }}
                  onClick={() => chatDetail(message, ReferansEnum.MesajAliciTipi.Dm, null, message.sicil)}
                >
                  <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: '10px' }}>
                    <span className="gonderenKatilimciStyle">
                      {`${message.adi}`}
                      {`${!isNullOrEmpty(message.soyadi) ? ` ${message.soyadi} ` : ''}`}
                      {`${!isNullOrEmpty(message.unvan) ? ` / ${message.unvan} ` : ''}`}
                    </span>
                    <span style={{ fontSize: '14px', color: '#919DAC', fontWeight: '400' }}>
                      <TextFormat value={message.cDate} type="date" format={APP_DATETIME_FORMAT} />
                    </span>
                  </div>
                  <span style={{ color: '#919DAC', fontSize: '15px' }}> {messageReplace(message.icerik)} </span>
                </CardText>
              </Card>
            );
          })}
      </>
    );
  };
  return (
    !isObjectEmpty(kriz) && (
      <Container
        className="container-chat-area "
        style={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: '#FFFFFF',
          height: 'calc(100% - 70px)',
        }}
      >
        {loading ? (
          <DrawerLoadingSpinner />
        ) : (
          <>
            {!isOpenChatDetail ? (
              <>
                <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }} className="mt-4 mb-4">
                  <div className="seachiconposition" style={{ width: '73%' }}>
                    <Search className="seachicon" style={{ color: 'grey' }} />
                    <Input placeholder={translate('entity.action.search')} className="seachinput" style={{ height: 48 }} />
                  </div>
                  <div className="text-right chatNewButton">
                    <GrantedButton
                      id={`create-button`}
                      grantedAuthorites={['kriz_chat_edit']}
                      style={{ marginLeft: 2 }}
                      onClick={chatDetailNew}
                      comparison={[
                        {
                          value: kriz.krizDurumu?.id,
                          checkValue: ReferansEnum.KrizDurumu.Aktif,
                          contentKey: 'error.comparison.crisisIsPassive',
                        },
                      ]}
                      className="create-button"
                      size="sm"
                      data-cy="entityCreateButton"
                    >
                      <FontAwesomeIcon icon="plus" />{' '}
                      <span className="d-none d-md-inline newChatAdd">
                        <Translate contentKey="tk24AdysApp.krizChat.home.createLabel" />
                      </span>
                    </GrantedButton>
                  </div>
                </div>

                <TumKYMChat />
                <TumADT />
                <KrizADTTim />
                <YonetimChat />
                <DMChat />
              </>
            ) : (
              <KrizChatDetail
                selectedMessageKullanici={selectedMessageKullanici}
                aliciTipi={selectedAliciTipi}
                gonderenSicil={selectedGonderenSicil}
                gonderenTim={selectedGonderenTim}
                isOpenNewChat={isOpenNewChat}
                onClose={closeDetailModal}
              />
            )}
          </>
        )}
      </Container>
    )
  );
};

export default KrizChatYonetim;
