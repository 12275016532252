import React, { useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import InfoTable from 'app/entities/infoTable';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { KrizMain } from 'app/component/KrizMain';
import KrizAdtList from './kriz-adt-list';
import KrizAdtGorevCagir from './kriz-adt-gorev-cagir';
import { LoadingSpinner } from 'app/component/LoadingSpinner';
import { getEntities as getRefAdtEkipEntities } from '../ref-adt-ekip/ref-adt-ekip.reducer';
import KrizAdtPersonelCagir from './kriz-adt-personel-cagir';
import KrizAdtUyeCagir from './kriz-adt-uye-cagir';
import { getEntities as getReferans } from '../referans/referans.reducer';
import { translate, Translate } from 'app/component/jhipster';
import { getEntity as getEntityByKriz } from '../kriz/kriz.reducer';
import { CustomTabPanel } from 'app/component/CustomTabPanel';
import { Box, Tab, Tabs } from '@mui/material';

import styled from 'styled-components';
const StyledTab = styled(Tab)`
  &:hover {
    box-shadow: 0px -2px 4px 0px #0000000f;
  }
`;

const KrizAdtEkip = (props: RouteComponentProps<{ id: string }>) => {
  const dispatch = useAppDispatch();
  const isDrawer = useAppSelector(state => state.krizChat.isDrawer);
  const [selectedButton, setSelectedButton] = useState('adt-list');
  // const loading = useAppSelector(state => state.krizAdtEkip.loading);
  const refAdtEkipLoading = useAppSelector(state => state.refAdtEkip.loading);
  const referandLoading = useAppSelector(state => state.referans.loading);
  const krizLoading = useAppSelector(state => state.kriz.loading);
  const [value, setValue] = React.useState(0);

  useEffect(() => {
    dispatch(getReferans({ size: 2000, sort: 'id,desc', page: 0 }));
    // dispatch(getEntities({ size: 100, page: 0, sort: 'id,desc', query: `krizId.equals=${props.match.params.id}` }));
    dispatch(getRefAdtEkipEntities({ size: 100, page: 0, sort: 'id,desc' }));
    dispatch(getEntityByKriz(props.match.params.id));
  }, []);

  const getStyles = useMemo(() => {
    return (buttonName: string) => {
      const backgroundColor = selectedButton === buttonName ? '#919DAC' : '#FFFFFF';
      const color = selectedButton === buttonName ? '#FFFFFF' : '#34404F';

      return {
        backgroundColor,
        color,
      };
    };
  }, [selectedButton]);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <KrizMain isDrawer={isDrawer}>
      {refAdtEkipLoading || referandLoading || krizLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <br />
          <div style={{ marginBottom: 5 }}>
            <InfoTable id={props.match.params.id} titleContentKey={'tk24AdysApp.krizDetay.adt'} />
          </div>

          <Box sx={{ borderBottom: 1, borderColor: 'divider' }} className="krizAnaCerides">
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" className="tabKrizCerideAnaKategori">
              <StyledTab label={translate('tk24AdysApp.krizAdtEkip.home.adtList')} className="tabKrizCerideTitle" />
              <StyledTab label={translate('tk24AdysApp.krizAdtEkip.home.adtGorevCagir')} className="tabKrizCerideTitle" />
              <StyledTab label={translate('tk24AdysApp.krizAdtEkip.home.adtUyeCagir')} className="tabKrizCerideTitle" />
              <StyledTab label={translate('tk24AdysApp.krizAdtEkip.home.adtPersonelCagir')} className="tabKrizCerideTitle" />
            </Tabs>
          </Box>
          <Box sx={{ width: '100%' }} className="krizCeride">
            <CustomTabPanel value={value} index={0}>
              <KrizAdtList krizId={props.match.params.id} />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <KrizAdtGorevCagir krizId={props.match.params.id} />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
              <KrizAdtUyeCagir krizId={props.match.params.id} />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={3}>
              <KrizAdtPersonelCagir krizId={props.match.params.id} />
            </CustomTabPanel>
          </Box>
        </>
      )}
    </KrizMain>
  );
};

export default KrizAdtEkip;
