import React from 'react';
import { Row, Col } from 'reactstrap';
import { Translate } from 'app/component/jhipster';
import { IRefHavalimaniIrtibatTipi } from 'app/shared/model/ref-havalimani-irtibat-tipi.model';

interface RefHavalimaniIrtibatTipiDetailProp {
  refHavalimaniIrtibatTipi: IRefHavalimaniIrtibatTipi;
}

export const RefHavalimaniIrtibatTipiDetail: React.FC<RefHavalimaniIrtibatTipiDetailProp> = ({ refHavalimaniIrtibatTipi }) => {
  return (
    <Row className="modal-body-row">
      <Col lg="6" style={{ marginTop: '10px' }}>
        <Row className="modal-content-title">
          <Translate contentKey="global.languageGlobal.adiTr">Adi Tr</Translate>
        </Row>
        <Row className="modal-content-text">{refHavalimaniIrtibatTipi.adiTr}</Row>
      </Col>
      <Col lg="6" style={{ marginTop: '10px' }}>
        <Row className="modal-content-title">
          <Translate contentKey="global.languageGlobal.adiEn">Adi En</Translate>
        </Row>
        <Row className="modal-content-text">{refHavalimaniIrtibatTipi.adiEn}</Row>
      </Col>
    </Row>
  );
};

export default RefHavalimaniIrtibatTipiDetail;
