import React, { useState, useEffect, useCallback } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Input } from 'reactstrap';
import { Translate, translate } from 'app/component/jhipster';
import { createEntity, deleteEntity, getEntities, getTotalEntities, updateEntity } from './ref-kriz-turu.reducer';
import { ASC, DESC } from 'app/shared/util/pagination.constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { CustomDeleteEntityConfirm, CustomSaveEntityConfirm, isNullOrEmpty } from 'app/shared/util/confirm-utils';
import { getEntities as getRefKrizSeviyes } from 'app/entities/ref-kriz-seviye/ref-kriz-seviye.reducer';
import RefKrizTuruUpdate from './ref-kriz-turu-update';
import { LoadingSpinner } from 'app/component/LoadingSpinner';
import { IRefKrizTuru } from 'app/shared/model/ref-kriz-turu.model';
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material';
import CustomPagination from 'app/component/CustomPagination';
import GrantedButton from 'app/component/GrantedButton';
import { SERVER_URL } from 'app/config/constants';
import { IQueryParams } from 'app/shared/reducers/reducer.utils';
import ExcelDownloadButton from 'app/component/ExcelDownload';
import SortIcon from 'app/component/SortIcon';

export const RefKrizTuru = (props: RouteComponentProps<{ url: string }>) => {
  const dispatch = useAppDispatch();

  const refKrizTuruList = useAppSelector(state => state.refKrizTuru.entities);
  const loading = useAppSelector(state => state.refKrizTuru.loading);
  const updating = useAppSelector(state => state.refKrizTuru.updating);
  const totalItem = useAppSelector(state => state.refKrizTuru.totalItems);

  const refKrizSeviyeList = useAppSelector(state => state.refKrizSeviye.entities);

  const [isEntityUpdateModal, setIsEntityUpdateModal] = useState(false);
  const [selectedEntity, setSelectedEntity] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);
  const [modalPromiseResolve, setModalPromiseResolve] = useState(null);

  const [search, setSearch] = useState('');

  const [pageObject, setPageObject] = useState<IQueryParams>(useAppSelector(state => state.paginate.pageObject));
  const ExcelSampleData = [
    { key: 'adiTr', titleContentKey: 'global.languageGlobal.adiTr' },
    { key: 'adiEn', titleContentKey: 'global.languageGlobal.adiEn' },
    { key: 'defaultKrizSeviye.adi', titleContentKey: 'tk24AdysApp.refKrizTuru.defaultKrizSeviye' },
  ];
  const getAllEntities = () => {
    dispatch(getEntities(pageObject));
  };

  useEffect(() => {
    dispatch(getRefKrizSeviyes({}));
  }, []);

  useEffect(() => {
    getAllEntities();
  }, [pageObject.page, pageObject.query, pageObject.size, pageObject.sort]);

  useEffect(() => {
    if (search.length > 2) {
      setPageObject(prevPageObject => ({
        ...prevPageObject,
        page: 0,
        query: prevPageObject.query + `&globalFilter=${search}`,
      }));
    } else if (search.length === 0 && !isNullOrEmpty(pageObject.query)) {
      const keyToDelete = 'globalFilter';
      const updatedParams = pageObject.query
        .split('&')
        .filter(param => {
          const [key, _] = param.split('=');
          return key !== keyToDelete;
        })
        .join('&');

      setPageObject({ ...pageObject, page: 0, query: updatedParams });
    }
  }, [search]);

  const sort = p => () => {
    const orderBy = pageObject.sort.split(',')[1];
    setPageObject({ ...pageObject, sort: `${p},${orderBy === ASC ? DESC : ASC}` });
  };

  const handlePageChange = (pageNumber, newItemsPerPage = pageObject.size) => {
    if (newItemsPerPage !== pageObject.size) {
      setPageObject({ ...pageObject, page: pageNumber - 1, size: newItemsPerPage });
    } else {
      setPageObject({ ...pageObject, page: pageNumber - 1 });
    }
  };

  const handleCloseUpdateForm = () => {
    if (isEntityUpdateModal) {
      setIsEntityUpdateModal(false);
    }
    setSelectedEntity(null);
  };

  const showSaveConfirmModal = () => {
    setIsSaveModalOpen(true);
    return new Promise(resolve => {
      setModalPromiseResolve(() => resolve);
    });
  };

  const handleConfirm = () => {
    if (modalPromiseResolve) {
      modalPromiseResolve(true);
    }
    setIsSaveModalOpen(false);
  };

  const handleCancel = () => {
    if (modalPromiseResolve) {
      modalPromiseResolve(false);
    }
    setIsSaveModalOpen(false);
  };

  const saveNewRefEntity = async e => {
    e.preventDefault();

    setIsEntityUpdateModal(false);

    const isSave = await showSaveConfirmModal();
    if (isSave) {
      const formDataObject = {};
      for (const field of e.target.elements) {
        if (field.name) {
          formDataObject[field.name] = field.value;
        }
      }

      const entity = {
        ...selectedEntity,
        ...formDataObject,
        defaultKrizSeviye: refKrizSeviyeList.find(item => item.id.toString() === formDataObject['defaultKrizSeviyeId'].toString()),
      };

      if (selectedEntity !== null) {
        await dispatch(updateEntity({ ...pageObject, entity }));
      } else {
        await dispatch(createEntity({ ...pageObject, entity }));
      }

      handleCloseUpdateForm();
    } else {
      setIsEntityUpdateModal(true);
    }
  };

  const confirmEntityDelete = entity => {
    setSelectedEntity(entity);
    setIsDeleteModalOpen(true);
  };

  const handleDeleteConfirm = useCallback(async () => {
    if (selectedEntity) {
      const entity = { ...pageObject, entity: selectedEntity };

      if (refKrizTuruList.length === 1) {
        entity.page = pageObject.page - 1;
        setPageObject({ ...pageObject, page: pageObject.page - 1 });
      }

      await dispatch(deleteEntity(entity));
      handleDeleteCancel();
    }
  }, [selectedEntity, dispatch]);

  const handleDeleteCancel = () => {
    setIsDeleteModalOpen(false);
    setSelectedEntity(null);
  };

  const fetchTotalData = async (): Promise<IRefKrizTuru[]> => {
    try {
      const response = (await dispatch(getTotalEntities({ ...pageObject, size: totalItem }))) as {
        payload: { data: IRefKrizTuru[] };
      };

      return response.payload.data || [];
    } catch (error) {
      console.error('Error fetching data:', error);
      return [];
    }
  };

  const RefEntityUpdateModal = () => {
    return (
      <RefKrizTuruUpdate
        saveNewRefEntity={saveNewRefEntity}
        handleCloseForm={handleCloseUpdateForm}
        formValues={selectedEntity}
        isOpen={isEntityUpdateModal}
      ></RefKrizTuruUpdate>
    );
  };

  const handleTplntTipiChange = event => {
    if (!isNullOrEmpty(event.target.value)) {
      setPageObject(prevPageObject => ({
        ...prevPageObject,
        page: 0,
        query: prevPageObject.query + `&defaultKrizSeviyeId.equals=${event.target.value}`,
      }));
    } else {
      const keyToDelete = 'defaultKrizSeviyeId.equals';
      const updatedParams = pageObject.query
        .split('&')
        .filter(param => {
          const [key, _] = param.split('=');
          return key !== keyToDelete;
        })
        .join('&');

      setPageObject({ ...pageObject, page: 0, query: updatedParams });
    }
  };

  return (
    <div>
      {(loading || updating) && <LoadingSpinner />}
      {selectedEntity && (
        <CustomDeleteEntityConfirm
          entity={selectedEntity}
          entityName="refKrizTuru"
          isOpen={isDeleteModalOpen}
          onConfirm={handleDeleteConfirm}
          onCancel={handleDeleteCancel}
        />
      )}
      <CustomSaveEntityConfirm entity={selectedEntity} isOpen={isSaveModalOpen} onConfirm={handleConfirm} onCancel={handleCancel} />
      {isEntityUpdateModal && RefEntityUpdateModal()}

      <div style={{ display: 'flex', gap: 8, textAlign: 'center' }}>
        <p className="icon-text">
          <a className="link-a-text" href={`${SERVER_URL}`}>
            <img width={13} height={13} src={`${SERVER_URL}/content/images/icon/faHouse.svg`} />
          </a>
        </p>
        <p className="separator"> / </p>
        <p className="link-text">
          {' '}
          <Translate contentKey="tk24AdysApp.refKrizTuru.home.title" />{' '}
        </p>
      </div>

      <h2 id="ref-kriz-seviye-tipi-heading" data-cy="RefKrizSeviyeTipiHeading" className="title-frame">
        <a className="link-a-text" onClick={() => props.history.goBack()}>
          <img width={27.5} height={22.5} src={`${SERVER_URL}/content/images/icon/faArrowLeft.svg`} />{' '}
        </a>
        <p className="title-text">
          <Translate contentKey="tk24AdysApp.refKrizTuru.home.title" />
        </p>
      </h2>

      <div className="content-area">
        <div className="search-frame">
          <div className="seachiconposition">
            <img className="seachicon" width={24} height={24} src={`${SERVER_URL}/content/images/icon/faSearch.svg`} />
            <Input
              value={search}
              onChange={e => setSearch(e.target.value)}
              placeholder={translate('entity.action.search')}
              className="search-input"
            />
          </div>

          <div className="create-area">
            <Box sx={{ minWidth: 220, maxWidth: 220 }}>
              <FormControl fullWidth size={window.innerWidth < 1600 ? 'small' : 'medium'}>
                <InputLabel id="default-toplanti-tipi-label" className="select-input">
                  <Translate contentKey="tk24AdysApp.refKrizTuru.defaultKrizSeviye" />
                </InputLabel>
                <Select
                  labelId="default-toplanti-tipi-label"
                  id="default-toplanti-tipi"
                  label={translate('tk24AdysApp.refKrizTuru.defaultKrizSeviye')}
                  onChange={handleTplntTipiChange}
                  className="select-input"
                >
                  <MenuItem value="">
                    <Translate contentKey="entity.action.all" />
                  </MenuItem>
                  {refKrizSeviyeList &&
                    refKrizSeviyeList.length > 0 &&
                    refKrizSeviyeList.map(item => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.adi}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Box>
            <ExcelDownloadButton
              excelSampleData={ExcelSampleData}
              fetchData={fetchTotalData}
              className={'download-button'}
              exportName={translate('tk24AdysApp.refKrizTuru.home.title')}
              grantedAuthorites={['ref_kriz_turu_export']}
            />
            <GrantedButton
              grantedAuthorites={['ref_kriz_turu_edit']}
              onClick={() => {
                setIsEntityUpdateModal(true);
              }}
              className="create-button"
              size="sm"
              data-cy="entityCreateButton"
            >
              <Tooltip title={<Translate contentKey="tk24AdysApp.refKrizTuru.home.createLabel" />}>
                <img width={14} height={14} src={`${SERVER_URL}/content/images/icon/faPlus.svg`} />
              </Tooltip>
              <span className="show-on-lg">
                <Translate contentKey="tk24AdysApp.refKrizTuru.home.createLabel" />
              </span>
            </GrantedButton>
          </div>
        </div>
        <div className="table-responsive" style={{ marginTop: 5 }}>
          <TableContainer className="content-table">
            {refKrizTuruList && refKrizTuruList.length > 0 ? (
              <Table aria-label="a dense table">
                <TableHead className="content-table-title-area">
                  <TableRow className="table-head-row">
                    <TableCell align="left" className="table-title-cell hand" onClick={sort('adiTr')}>
                      <div className="tableHeaderContainer">
                        <Translate contentKey="global.languageGlobal.adiTrTitle">Adi Tr</Translate>&nbsp;
                        <SortIcon column="adiTr" activeSortColumn={pageObject.sort} />
                      </div>
                    </TableCell>
                    <TableCell align="left" className="table-title-cell hand" onClick={sort('adiEn')}>
                      <div className="tableHeaderContainer">
                        <Translate contentKey="global.languageGlobal.adiEnTitle">Adi En</Translate>&nbsp;
                        <SortIcon column="adiEn" activeSortColumn={pageObject.sort} />
                      </div>
                    </TableCell>
                    <TableCell align="left" className="table-title-cell hand" onClick={sort('defaultKrizSeviye')}>
                      <div className="tableHeaderContainer">
                        <Translate contentKey="tk24AdysApp.refKrizTuru.defaultKrizSeviyeTitle">Default Kriz Seviye</Translate>&nbsp;
                        <SortIcon column="defaultKrizSeviye" activeSortColumn={pageObject.sort} />
                      </div>
                    </TableCell>
                    <TableCell className="sticky-right-header"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {refKrizTuruList.map(refKrizTuru => (
                    <TableRow key={refKrizTuru.id} className="table-cell-row">
                      <TableCell component="th" scope="row" className="table-content-cell">
                        {refKrizTuru.adiTr}
                      </TableCell>
                      <TableCell component="th" scope="row" className="table-content-cell">
                        {refKrizTuru.adiEn}
                      </TableCell>
                      <TableCell component="th" scope="row" className="table-content-cell">
                        {refKrizTuru.defaultKrizSeviye && refKrizTuru.defaultKrizSeviye.adi}
                      </TableCell>
                      <TableCell align="right" className="sticky-right">
                        <div className="btn-group flex-btn-group-container">
                          <GrantedButton
                            grantedAuthorites={['ref_kriz_turu_edit']}
                            className="edit-button"
                            onClick={() => {
                              setSelectedEntity(refKrizTuru);
                              setIsEntityUpdateModal(true);
                            }}
                            size="sm"
                            data-cy="entityEditButton"
                          >
                            <Tooltip title={<Translate contentKey="entity.action.edit">Edit</Translate>}>
                              <img width={14} height={14} src={`${SERVER_URL}/content/images/icon/faPencil.svg`} />
                            </Tooltip>
                            <span className="show-on-lg">
                              <Translate contentKey="entity.action.edit">Edit</Translate>
                            </span>
                          </GrantedButton>
                          <GrantedButton
                            grantedAuthorites={['ref_kriz_turu_delete']}
                            onClick={() => confirmEntityDelete(refKrizTuru)}
                            style={{ marginLeft: 10 }}
                            className="delete-button"
                            size="sm"
                            data-cy="entityDeleteButton"
                          >
                            <Tooltip title={<Translate contentKey="entity.action.delete">Delete</Translate>}>
                              <img width={11.2} height={12.8} src={`${SERVER_URL}/content/images/icon/faTrashCan.svg`} />
                            </Tooltip>
                            <span className="show-on-lg">
                              <Translate contentKey="entity.action.delete">Delete</Translate>
                            </span>
                          </GrantedButton>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            ) : (
              !loading &&
              !updating && (
                <div className="alert alert-warning">
                  <Translate contentKey="tk24AdysApp.refKrizTuru.home.notFound">No Ref Type Tipis found</Translate>
                </div>
              )
            )}
          </TableContainer>
        </div>
        <CustomPagination
          currentPage={pageObject.page + 1}
          currentPageSize={pageObject.size}
          totalItems={totalItem}
          handlePageChange={handlePageChange}
        />
      </div>
    </div>
  );
};

export default RefKrizTuru;
