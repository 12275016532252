import React, { useEffect, useState } from 'react';
import { Translate } from 'app/component/jhipster';
import { NavItem, NavLink, NavbarBrand } from 'reactstrap';
import { NavLink as Link } from 'react-router-dom';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import './menu.scss';

export const Home = props => {
  const [clicked, setClicked] = useState(false);

  useEffect(() => {
    setClicked(props.menuOpen);
  }, [props.menuOpen]);

  const handleClick = () => {
    setClicked(!clicked);
    props.toggleMenu();
  };

  return (
    <NavItem>
      <NavLink
        tag={Link}
        to="/"
        className="d-flex align-items-center dropdown-item-home"
        style={{ padding: 32, border: '1px solid #c1c8d1' }}
        onClick={handleClick}
      >
        {clicked && (
          <>
            <HomeOutlinedIcon style={{ color: '#34404F' }} />
            <span className="headerText">
              <Translate contentKey="global.menu.home">Home</Translate>
            </span>
          </>
        )}
      </NavLink>
    </NavItem>
  );
};
