import { IRol } from 'app/shared/model/rol.model';
import { IYetki } from 'app/shared/model/yetki.model';
import { IReferans } from 'app/shared/model/referans.model';

export interface IRolYetki {
  id?: number;
  guid?: string;
  cDate?: string;
  cUser?: number;
  mDate?: string;
  mUser?: number;
  rol?: IRol;
  yetki?: IYetki;
  durum?: IReferans;
}

export const defaultValue: Readonly<IRolYetki> = {};
