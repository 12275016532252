import { IReferans } from 'app/shared/model/referans.model';
import { IRefKrizTuru } from 'app/shared/model/ref-kriz-turu.model';
import { IRefKatilimci } from 'app/shared/model/ref-katilimci.model';
import { IQueryParams } from '../reducers/reducer.utils';

export interface IRefKrizTuruKatilimci {
  id?: number;
  guid?: string;
  sortIndex?: number;
  cDate?: string;
  cUser?: number;
  mDate?: string;
  mUser?: number;
  acilDurumTuru?: IReferans;
  krizTuru?: IRefKrizTuru;
  katilimci?: IRefKatilimci;
  durum?: IReferans;
}
export interface IRefKrizTuruKatilimciParams extends IQueryParams {
  entity?: IRefKrizTuruKatilimci;
}

export const defaultValue: Readonly<IRefKrizTuruKatilimci> = {};
