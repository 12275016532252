import React, { FormEventHandler } from 'react';
import { Button, UncontrolledTooltip, Form, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Translate, translate } from 'app/component/jhipster';
import { useAppSelector } from 'app/config/store';
import { IRefKrizTuru } from 'app/shared/model/ref-kriz-turu.model';
import { FormItem } from 'app/component/FormItem';

interface RefKrizTuruUpdateProp {
  handleCloseForm: FormEventHandler;
  saveNewRefEntity: FormEventHandler;
  formValues?: IRefKrizTuru;
  isOpen: boolean;
}

export const RefKrizTuruUpdate: React.FC<RefKrizTuruUpdateProp> = ({ handleCloseForm, saveNewRefEntity, formValues, isOpen }) => {
  const refKrizSeviyes = useAppSelector(state => state.refKrizSeviye.entities);

  return (
    <Modal className="modal-style" isOpen={isOpen} toggle={handleCloseForm} centered>
      <ModalHeader data-cy="RefKrizTuruCreateUpdateHeading" toggle={handleCloseForm}>
        {formValues && (
          <p className="modal-title-info">
            {formValues?.adiTr}-{formValues?.adiEn}
          </p>
        )}
        {formValues ? (
          <Translate contentKey="tk24AdysApp.refKrizTuru.home.editLabel">Update a RefKrizSeviye</Translate>
        ) : (
          <Translate contentKey="tk24AdysApp.refKrizTuru.home.createLabel">Create a RefKrizSeviye</Translate>
        )}
      </ModalHeader>
      <Form onSubmit={saveNewRefEntity}>
        <ModalBody>
          <FormItem
            label={translate('global.languageGlobal.adiTr')}
            id="ref-kriz-turu-adiTr"
            name="adiTr"
            defaultValue={formValues && formValues['adiTr']}
            type="text"
            validation={{
              required: { value: true, message: translate('entity.validation.required') },
              maxLength: { value: 100, message: translate('entity.validation.maxlength', { max: 100 }) },
            }}
          />
          <UncontrolledTooltip target="adiTrLabel">
            <Translate contentKey="global.languageGlobal.adiTr" />
          </UncontrolledTooltip>

          <FormItem
            label={translate('global.languageGlobal.adiEn')}
            id="ref-kriz-turu-adiEn"
            name="adiEn"
            defaultValue={formValues && formValues['adiEn']}
            type="text"
            validation={{
              required: { value: true, message: translate('entity.validation.required') },
              maxLength: { value: 100, message: translate('entity.validation.maxlength', { max: 100 }) },
            }}
          />
          <UncontrolledTooltip target="adiEnLabel">
            <Translate contentKey="global.languageGlobal.adiEn" />
          </UncontrolledTooltip>

          <FormItem
            label={translate('tk24AdysApp.refKrizTuru.defaultKrizSeviye')}
            id="ref-kriz-turu-defaultKrizSeviye"
            name="defaultKrizSeviyeId"
            defaultValue={
              formValues && refKrizSeviyes.length > 0 && formValues['defaultKrizSeviye'] !== null && formValues.defaultKrizSeviye.id
            }
            type="select"
            validation={{
              required: { value: true, message: translate('entity.validation.required') },
            }}
          >
            <option value="" key="0" />
            {refKrizSeviyes.length > 0 &&
              refKrizSeviyes.map(otherEntity => (
                <option value={otherEntity.id} key={otherEntity.id}>
                  {otherEntity.adi}
                </option>
              ))}
          </FormItem>
        </ModalBody>
        <ModalFooter className="modal-footer">
          <Button className="cancel-button" onClick={handleCloseForm}>
            <Translate contentKey="entity.action.cancel">Cancel</Translate>
          </Button>
          &nbsp;
          <Button className="save-button" id="jhi-confirm-delete-refAdtForm" type="submit" data-cy="entityConfirmButton">
            <Translate contentKey="entity.action.save">Kaydet</Translate>
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default RefKrizTuruUpdate;
