import React, { useEffect, useState } from 'react';
import GrantedButton from 'app/component/GrantedButton';
import { Storage, TextFormat, Translate, translate } from 'app/component/jhipster';
import { useAppSelector } from 'app/config/store';
import axios from 'axios';
import { APP_DATE_FORMAT, SERVER_URL } from 'app/config/constants';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { KartLoadingSpinner } from 'app/component/LoadingSpinner';
import { ReferansEnum } from 'app/constants/ReferansEnum';
import { Link } from 'react-router-dom';
import { MapContainer, TileLayer, Marker, useMapEvents, Tooltip } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { toast } from 'react-toastify';
import { airportIcon, customIcon } from 'app/shared/util/util';
import { calculateElapsedTime, isNullOrEmpty } from 'app/shared/util/confirm-utils';
import { NearestList } from 'app/shared/util/get-custom-service';

const DetailModal = ({ isOpen, toggle, header, details }) => (
  <Modal isOpen={isOpen} toggle={toggle} className="modal-style" centered>
    <ModalHeader toggle={toggle}>{header}</ModalHeader>
    <ModalBody>
      {details.map((detail, index) => (
        <div key={index}>{detail}</div>
      ))}
    </ModalBody>
    <ModalFooter className="modal-footer" style={{ justifyContent: 'end !important' }}>
      <Button className="cancel-button" onClick={toggle}>
        <Translate contentKey="entity.action.cancel">Cancel</Translate>
      </Button>
    </ModalFooter>
  </Modal>
);

const KrizDetaylari = () => {
  const krizEntity = useAppSelector(state => state.kriz.entity);

  const [krizDetayList, setKrizDetayList] = useState(null);

  const [nearHavalimanis, setNearHavalimanis] = useState([]);
  const [BBox, setBBox] = useState([]);

  const [loading, setLoading] = useState(false);
  const [nearHavalimaniLoading, setNearHavalimaniLoading] = useState(false);

  const krizId = Storage.session.get('kriz');

  const [openUcak, setOpenUcak] = useState(false);
  const [openSefer, setOpenSefer] = useState(false);
  const [openHavalimani, setOpenHavalimani] = useState(false);
  const [openSeferSaat, setOpenSeferSaat] = useState(false);

  const handleToggleUcak = () => setOpenUcak(!openUcak);
  const handleToggleSefer = () => setOpenSefer(!openSefer);
  const handleToggleSeferSaat = () => setOpenSeferSaat(!openSeferSaat);
  const handleToggleHavalimani = () => setOpenHavalimani(!openHavalimani);

  const ucakList = krizDetayList?.ucakList ?? [];
  const seferList = krizDetayList?.ucusList ?? [];
  const havalimaniList = krizDetayList?.havalimaniList ?? [];
  const havalimaniKonumList = krizDetayList?.havalimaniList
    ? krizDetayList?.havalimaniList.filter(item => !isNullOrEmpty(item.konumX) || !isNullOrEmpty(item.konumY))
    : [];

  useEffect(() => {
    const fetchKrizDetay = async () => {
      setLoading(true);
      if (krizId) {
        try {
          const krizDetayUrl = `api/krizs/kriz-detay/${krizId}`;
          const detayResponse = await axios.get(krizDetayUrl);
          setKrizDetayList(detayResponse.data);
          setLoading(false);
        } catch (error) {
          console.error('Error fetching ADT ekip data:', error);
        }
      }
    };
    fetchKrizDetay();
  }, [krizId]);

  const getNearHavalimanis = async () => {
    const nearHavalimaniList = [];
    const bBoxList = [];

    const existingKeys = new Set(havalimaniKonumList.map(item => JSON.stringify({ konumX: item.konumX, konumY: item.konumY })));

    for (const havalimani of havalimaniKonumList) {
      setNearHavalimaniLoading(true);
      const nearList = await NearestList(havalimani.konumX, havalimani.konumY);
      setNearHavalimaniLoading(false);
      if (nearList[0]) {
        const filteredNewItems = nearList[0].filter(item => {
          const key = JSON.stringify({ konumX: item.konumX, konumY: item.konumY });
          return !existingKeys.has(key);
        });

        if (filteredNewItems) {
          nearHavalimaniList.push(...filteredNewItems);
        }
      }

      if (nearList[1]) {
        bBoxList.push(nearList[1]);
      }
    }

    setBBox(bBoxList);
    setNearHavalimanis(nearHavalimaniList);
  };

  const getNearKonumHavalimanis = async () => {
    setNearHavalimaniLoading(true);
    const nearList = await NearestList(krizDetayList.krizYeriKonumX, krizDetayList.krizYeriKonumY);
    setNearHavalimaniLoading(false);
    if (nearList[0]) {
      setNearHavalimanis(nearList[0]);
    }
    if (nearList[1]) {
      setBBox(nearList[1]);
    }
  };

  useEffect(() => {
    if (krizDetayList && krizDetayList.krizYeri.id === ReferansEnum.KrizYeri.Konum) {
      setLoading(true);
      getNearKonumHavalimanis();
      setLoading(false);
    } else if (krizDetayList && krizDetayList.krizYeri.id === ReferansEnum.KrizYeri.Havalimani) {
      setLoading(true);
      getNearHavalimanis();
      setLoading(false);
    }
  }, [krizDetayList]);

  const Konum = ({ konumX, konumY }) => {
    const [mousePosition, setMousePosition] = useState<[number, number]>([konumX, konumY]);
    const [zoomLevel, setZoomLevel] = useState<number>(10);

    useMapEvents({
      mousemove(e) {
        const lat = parseFloat(e.latlng.lat.toFixed(5));
        const lng = parseFloat(e.latlng.lng.toFixed(5));
        setMousePosition([lat, lng]);
      },
      zoom(e) {
        setZoomLevel(e.target.getZoom());
      },
    });

    return (
      <div style={{ position: 'absolute', bottom: '10px', right: '10px', backgroundColor: 'white', padding: '5px', zIndex: 400 }}>
        Z({zoomLevel}) - {mousePosition[1]}, {mousePosition[0]}
      </div>
    );
  };

  const [timeElapsed, setTimeElapsed] = useState('');

  useEffect(() => {
    const interval = setInterval(() => {
      const elapsedTime = calculateElapsedTime(krizEntity?.krizOlusmaZamani);
      setTimeElapsed(elapsedTime);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="taskChartSlider">
      <>
        <div className="chartTaskSlideHeader">
          <div className="krizDetaylariTitle">
            <h3 style={{ margin: 0 }}>
              {krizEntity?.acilDurumTuru?.adi} {translate('dashboard.detaylari')}
            </h3>

            {krizEntity?.krizDurumu?.id === ReferansEnum.KrizDurumu.Aktif && (
              <div
                style={{
                  backgroundColor: '#F2F3F5',
                  display: 'flex',
                  gap: '8px',
                  borderRadius: '6px',
                  padding: '10px 8px',
                  alignItems: 'center',
                  height: '24px',
                }}
              >
                <img width={16} height={16} src={`${SERVER_URL}/content/images/icon/accessTimeFilled.svg`} />
                <p style={{ fontWeight: '500', margin: '0px', fontSize: '14px' }}> {timeElapsed} </p>
                <p style={{ fontWeight: '200', margin: '0px', fontSize: '12px' }}>
                  <Translate contentKey="dashboard.krizGecenSure" />
                </p>
              </div>
            )}
          </div>

          <GrantedButton grantedAuthorites={['view_kriz_detay']} className="chart-button" tag={Link} to={`/kriz-edit/${krizId}`}>
            {translate('dashboard.detaylariGoruntule')}
          </GrantedButton>
        </div>
        {(loading || nearHavalimaniLoading) && <KartLoadingSpinner />}
        <div className="row m-0" style={{ padding: '25px 0 0px' }}>
          <div className={`col-lg-${krizDetayList?.krizYeri.id !== ReferansEnum.KrizYeri.KonumYok ? '8' : '12'} p-0`}>
            <p className="tatbikatDetayValue">
              <span className="tatbikatDetayKey">{translate('tk24AdysApp.kriz.krizDurumu')}: </span>
              <span className={krizDetayList?.krizDurumu?.id === ReferansEnum.KrizDurumu.Aktif && 'krizDurumDashboard'}>
                {krizDetayList?.krizDurumu?.adi}
              </span>
            </p>
            <p className="tatbikatDetayValue">
              <span className="tatbikatDetayKey">{translate('dashboard.krizTuru')}: </span> {krizDetayList?.krizTuru?.adi}
            </p>
            <p className="tatbikatDetayValue">
              <span className="tatbikatDetayKey">{translate('dashboard.krizSeviyesi')}: </span>
              <span
                className="krizSeviyeDashboard"
                style={{ backgroundColor: krizDetayList?.krizSeviye?.cssBorderColor, color: krizDetayList?.krizSeviye?.cssFontColor }}
              >
                {krizDetayList?.krizSeviye?.adi}
              </span>
            </p>
            <p className="tatbikatDetayValue">
              <span className="tatbikatDetayKey">{translate('dashboard.gerceklesmeZamani')}: </span>
              {krizDetayList?.krizOlusmaZamani && (
                <TextFormat type="date" value={krizDetayList?.krizOlusmaZamani} format={APP_DATE_FORMAT} />
              )}
            </p>

            {krizDetayList?.krizYeri.id === ReferansEnum.KrizYeri.Havalimani && (
              <p className="tatbikatDetayValue">
                <span className="tatbikatDetayKey">{translate('dashboard.gerceklesmeYeri')}: </span>
                <>
                  {[havalimaniList[0]?.koduIata, havalimaniList[0]?.sehir, havalimaniList[0]?.adi].filter(value => value).join(' - ')}

                  {havalimaniList.length > 1 && (
                    <>
                      <div onClick={handleToggleHavalimani} className="artiBilgi">
                        +{havalimaniList.length - 1}
                      </div>
                      <DetailModal
                        isOpen={openHavalimani}
                        toggle={handleToggleHavalimani}
                        header={translate('dashboard.havalimaniBilgileri')}
                        details={havalimaniList.slice(1).map(havalimani => {
                          const koduIata = havalimani?.koduIata || '';
                          const sehir = havalimani?.sehir || '';
                          const adi = havalimani?.adi || '';
                          const values = [koduIata, sehir, adi].filter(value => value);
                          return values.join(' - ');
                        })}
                      />
                    </>
                  )}
                </>
              </p>
            )}

            {(krizDetayList?.krizYeri.id === ReferansEnum.KrizYeri.Konum ||
              krizDetayList?.krizYeri.id === ReferansEnum.KrizYeri.KonumYok) && (
              <p className="tatbikatDetayAciklama">
                <span className="tatbikatDetayKey">{translate('tk24AdysApp.kriz.krizYeriAciklama')}: </span>
                {krizDetayList?.krizYeriAciklama}
              </p>
            )}

            <p className="tatbikatDetayValue">
              <span className="tatbikatDetayKey">{translate('dashboard.seferBilgileri')}: </span>
              {seferList.length > 0 && (
                <>
                  {`${seferList[0]?.ucusKodu} / ${seferList[0]?.kalkisHavalimaniIata} - ${seferList[0]?.varisHavalimaniIata}`}
                  {seferList.length > 1 && (
                    <>
                      <div onClick={handleToggleSefer} className="artiBilgi">
                        +{seferList.length - 1}
                      </div>
                      <DetailModal
                        isOpen={openSefer}
                        toggle={handleToggleSefer}
                        header={translate('dashboard.seferBilgileri')}
                        details={seferList.map(
                          sefer => `${sefer?.ucusKodu} / ${sefer?.kalkisHavalimaniIata} - ${sefer?.varisHavalimaniIata}`
                        )}
                      />
                    </>
                  )}
                </>
              )}
            </p>
            <p className="tatbikatDetayValue">
              <span className="tatbikatDetayKey">{translate('dashboard.gerceklesenSeferSaati')}: </span>
              {seferList.length > 0 && (
                <>
                  <TextFormat value={seferList[0]?.planlananKalkisZamani} type="date" format={APP_DATE_FORMAT} />
                  {seferList.length > 1 && (
                    <>
                      <div onClick={handleToggleSeferSaat} className="artiBilgi">
                        +{seferList.length - 1}
                      </div>
                      <DetailModal
                        isOpen={openSeferSaat}
                        toggle={handleToggleSeferSaat}
                        header={translate('dashboard.seferBilgileri')}
                        details={[
                          ...seferList.map(sefer => (
                            <TextFormat key={sefer?.id} value={sefer?.planlananKalkisZamani} type="date" format={APP_DATE_FORMAT} />
                          )),
                        ]}
                      />
                    </>
                  )}
                </>
              )}
            </p>
            <p className="tatbikatDetayValue">
              <span className="tatbikatDetayKey">{translate('dashboard.ucakBilgileri')}: </span>
              {ucakList.length > 0 && (
                <>
                  {ucakList[0]?.kuyrukKodu && `${ucakList[0]?.kuyrukKodu} / ${ucakList[0]?.detayTipi ? ucakList[0]?.detayTipi : ''}`}
                  {ucakList.length > 1 && (
                    <>
                      <div onClick={handleToggleUcak} className="artiBilgi">
                        +{ucakList.length - 1}
                      </div>
                      <DetailModal
                        isOpen={openUcak}
                        toggle={handleToggleUcak}
                        header={translate('dashboard.ucakBilgileri')}
                        details={ucakList.map(ucak => `${ucak?.kuyrukKodu} ${ucak?.detayTipi ? ` / ${ucak?.detayTipi}` : ''}`)}
                      />
                    </>
                  )}
                </>
              )}
            </p>
          </div>
          {!nearHavalimaniLoading ? (
            <>
              <div className={`col-lg-${krizDetayList?.krizYeri.id !== ReferansEnum.KrizYeri.KonumYok ? '4' : '12'} p-0`}>
                {krizDetayList?.krizYeri?.id === ReferansEnum.KrizYeri.Konum && (
                  <MapContainer
                    center={[krizDetayList.krizYeriKonumX, krizDetayList.krizYeriKonumY]}
                    zoom={5}
                    style={{ width: '100%', height: '100%', borderRadius: '8px' }}
                    attributionControl={false}
                    bounds={BBox}
                  >
                    <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                    <Marker position={[krizDetayList.krizYeriKonumX, krizDetayList.krizYeriKonumY]} icon={customIcon}>
                      {' '}
                    </Marker>
                    {nearHavalimanis &&
                      nearHavalimanis.length > 0 &&
                      nearHavalimanis.map((nearHavalimani, index) => (
                        <Marker key={index} position={[nearHavalimani.konumX, nearHavalimani.konumY]} icon={airportIcon}>
                          <Tooltip sticky>{nearHavalimani.adi}</Tooltip>
                        </Marker>
                      ))}
                    <Konum konumX={krizDetayList.krizYeriKonumX} konumY={krizDetayList.krizYeriKonumY} />
                  </MapContainer>
                )}

                {krizDetayList?.krizYeri?.id === ReferansEnum.KrizYeri.Havalimani && havalimaniKonumList && havalimaniKonumList.length > 0 && (
                  <MapContainer
                    center={[havalimaniKonumList[0]?.konumX, havalimaniKonumList[0]?.konumY]}
                    zoom={5}
                    style={{ width: '100%', height: '100%', borderRadius: '8px' }}
                    attributionControl={false}
                    bounds={BBox}
                  >
                    <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />

                    {havalimaniKonumList.map(item => (
                      <Marker key={item.adi} position={[item.konumX, item.konumY]} icon={customIcon}>
                        <Tooltip sticky>{item.adi}</Tooltip>
                      </Marker>
                    ))}

                    {nearHavalimanis &&
                      nearHavalimanis.length > 0 &&
                      nearHavalimanis
                        .filter(item => !isNullOrEmpty(item.konumX) && !isNullOrEmpty(item.konumY))
                        .map((nearHavalimani, index) => (
                          <Marker key={index} position={[nearHavalimani.konumX, nearHavalimani.konumY]} icon={airportIcon}>
                            <Tooltip sticky>{nearHavalimani.adi}</Tooltip>
                          </Marker>
                        ))}

                    <Konum konumX={havalimaniKonumList[0].konumX} konumY={havalimaniKonumList[0].konumY} />
                  </MapContainer>
                )}
              </div>
            </>
          ) : (
            ''
          )}
        </div>
      </>
    </div>
  );
};

export default KrizDetaylari;
