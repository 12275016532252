import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending } from '@reduxjs/toolkit';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { IQueryParams, createEntitySlice, EntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { IRefUcus, IRefUcusParams, defaultValue } from 'app/shared/model/ref-ucus.model';
import { isNullOrEmpty } from 'app/shared/util/confirm-utils';

const initialState: EntityState<IRefUcus> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

interface RefUcusSearchParams extends IQueryParams {
  ucusKodu?: string;
  ucakKuyrukKodu?: string;
  departure?: string;
  arrival?: string;
  planlananKalkisZamani?: string;
  planlananVarisZamani?: string;
  gerceklesenKalkisZamani?: string;
  gerceklesenVarisZamani?: string;
}

const apiUrl = 'api/ref-ucuses';

// Actions

export const getEntities = createAsyncThunk('refUcuseses/fetch_entity_list', async ({ page, size, sort, query }: IRefUcusParams) => {
  const queryParams = new URLSearchParams();
  if (sort) {
    queryParams.set('page', page.toString());
    queryParams.set('size', size.toString());
    queryParams.set('sort', sort);
  }

  if (query) {
    const parsedQueryParams = new URLSearchParams(query);
    parsedQueryParams.forEach((value, key) => {
      queryParams.set(key, value);
    });
  }
  queryParams.set('cacheBuster', new Date().getTime().toString());
  const queryString = queryParams.toString();
  const requestUrl = `${apiUrl}${queryString ? `?${queryString}` : ''}`;
  return axios.get<IRefUcus[]>(requestUrl);
});

export const getSearchEntities = createAsyncThunk(
  'refUcuseses/fetch_entity_list',
  async ({
    ucusKodu,
    ucakKuyrukKodu,
    departure,
    arrival,
    planlananKalkisZamani,
    planlananVarisZamani,
    gerceklesenKalkisZamani,
    gerceklesenVarisZamani,
    page,
    size,
    sort,
  }: RefUcusSearchParams) => {
    let seachParam = `${sort ? `?page=${page}&size=${size}&sort=${sort}&` : '?'}cacheBuster=${new Date().getTime()}`;
    if (!isNullOrEmpty(ucusKodu)) {
      seachParam += `&ucusKodu.contains=${ucusKodu}`;
    }
    if (!isNullOrEmpty(ucakKuyrukKodu)) {
      seachParam += `&ucakKuyrukKodu.contains=${ucakKuyrukKodu}`;
    }
    if (!isNullOrEmpty(departure)) {
      seachParam += `&globalDepFilter=${departure}`;
    }
    if (!isNullOrEmpty(arrival)) {
      seachParam += `&globalArFilter=${arrival}`;
    }
    if (!isNullOrEmpty(planlananKalkisZamani)) {
      seachParam += `&planlananKalkisTarihi.equals=${planlananKalkisZamani}`;
    }
    if (!isNullOrEmpty(planlananVarisZamani)) {
      seachParam += `&planlananVarisTarihi.equals=${planlananVarisZamani}`;
    }
    if (!isNullOrEmpty(gerceklesenKalkisZamani)) {
      seachParam += `&gerceklesenKalkisTarihi.equals=${gerceklesenKalkisZamani}`;
    }
    if (!isNullOrEmpty(gerceklesenVarisZamani)) {
      seachParam += `&gerceklesenVarisTarihi.equals=${gerceklesenVarisZamani}`;
    }

    const requestUrl = `${apiUrl}${seachParam}`;
    return axios.get<IRefUcus[]>(requestUrl);
  }
);

export const getEntity = createAsyncThunk(
  'refUcuses/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<IRefUcus>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const createEntity = createAsyncThunk(
  'refUcuses/create_entity',
  async (entity: IRefUcus, thunkAPI) => {
    const result = await axios.post<IRefUcus>(apiUrl, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
  'refUcuses/update_entity',
  async (entity: IRefUcus, thunkAPI) => {
    const result = await axios.put<IRefUcus>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const partialUpdateEntity = createAsyncThunk(
  'refUcuses/partial_update_entity',
  async (entity: IRefUcus, thunkAPI) => {
    const result = await axios.patch<IRefUcus>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const deleteEntity = createAsyncThunk(
  'refUcuses/delete_entity',
  async (id: string | number, thunkAPI) => {
    const requestUrl = `${apiUrl}/${id}`;
    const result = await axios.delete<IRefUcus>(requestUrl);
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

// slice

export const RefUcusSlice = createEntitySlice({
  name: 'refUcuses',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(deleteEntity.fulfilled, state => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = {};
      })
      .addMatcher(isFulfilled(getEntities, getSearchEntities), (state, action) => {
        return {
          ...state,
          loading: false,
          entities: action.payload.data,
          totalItems: parseInt(action.payload.headers['x-total-count'], 10),
        };
      })
      .addMatcher(isFulfilled(createEntity, updateEntity, partialUpdateEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isPending(getEntities, getSearchEntities, getEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(createEntity, updateEntity, partialUpdateEntity, deleteEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      });
  },
});

export const { reset } = RefUcusSlice.actions;

// Reducer
export default RefUcusSlice.reducer;
