import React, { useState, useEffect } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col, UncontrolledTooltip } from 'reactstrap';
import { Translate, translate, ValidatedField, ValidatedForm } from 'app/component/jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getEntities as getKrizs } from 'app/entities/kriz/kriz.reducer';
import { getEntities as getRefDuyuruTipis } from 'app/entities/ref-duyuru-tipi/ref-duyuru-tipi.reducer';
import { getEntity, updateEntity, createEntity, reset } from './adym-duyuru.reducer';
import { convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { getReferansByTip } from 'app/entities/referans/referans.reducer';
import { useAppDispatch, useAppSelector } from 'app/config/store';

export const AdymDuyuruUpdate = (props: RouteComponentProps<{ id: string }>) => {
  const dispatch = useAppDispatch();

  const [isNew] = useState(!props.match.params || !props.match.params.id);

  const refDuyuruTipis = useAppSelector(state => state.refDuyuruTipi.entities);
  const referans = useAppSelector(state => state.referans.entities);
  const duyuruEntity = useAppSelector(state => state.adymDuyuru.entity);
  const loading = useAppSelector(state => state.adymDuyuru.loading);
  const updating = useAppSelector(state => state.adymDuyuru.updating);
  const updateSuccess = useAppSelector(state => state.adymDuyuru.updateSuccess);
  const hedefKitles = useAppSelector(state => state.referans.entities);
  const duyuruDurumu = useAppSelector(state => state.referans.entities);

  const handleClose = () => {
    props.history.push('/duyuru' + props.location.search);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(props.match.params.id));
    }

    dispatch(getKrizs({}));
    dispatch(getRefDuyuruTipis({}));
    dispatch(getReferansByTip('DUYURU_DURUMU'));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    values.cDate = convertDateTimeToServer(values.cDate);
    values.mDate = convertDateTimeToServer(values.mDate);

    const entity = {
      ...duyuruEntity,
      ...values,
      kriz: { id: values.krizId.toString() },
      duyuruTipi: refDuyuruTipis.find(it => it.id.toString() === values.duyuruTipiId.toString()),
      duyuruDurumu: referans.find(it => it.id.toString() === values.duyuruDurumuId.toString()),
      hedefKitle: hedefKitles.find(it => it.id.toString() === values.hedefKitleId.toString()),
      durum: referans.find(it => it.id.toString() === values.durumId.toString()),
      adtEkip: referans.find(it => it.id.toString() === values.adtEkipId.toString()),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {
          cDate: displayDefaultDateTime(),
          mDate: displayDefaultDateTime(),
        }
      : {
          ...duyuruEntity,
          krizId: duyuruEntity?.kriz?.id,
          duyuruTipiId: duyuruEntity?.duyuruTipi?.id,
          duyuruDurumuId: duyuruEntity?.duyuruDurumu?.id,
          durumId: duyuruEntity?.durum?.id,
          hedefKitleId: duyuruEntity?.hedefKitle?.id,
          adtEkipId: duyuruEntity?.adtEkip?.id,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="tk24AdysApp.duyuru.home.createOrEditLabel" data-cy="DuyuruCreateUpdateHeading">
            <Translate contentKey="tk24AdysApp.duyuru.home.createOrEditLabel">Create or edit a Duyuru</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="duyuru-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('tk24AdysApp.duyuru.baslik')}
                id="duyuru-baslik"
                name="baslik"
                data-cy="baslik"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  maxLength: { value: 1000, message: translate('entity.validation.maxlength', { max: 1000 }) },
                }}
              />
              <UncontrolledTooltip target="baslikLabel">
                <Translate contentKey="tk24AdysApp.duyuru.help.baslik" />
              </UncontrolledTooltip>
              <ValidatedField
                label={translate('tk24AdysApp.duyuru.icerik')}
                id="duyuru-icerik"
                name="icerik"
                data-cy="icerik"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  maxLength: { value: 4000, message: translate('entity.validation.maxlength', { max: 4000 }) },
                }}
              />
              <UncontrolledTooltip target="icerikLabel">
                <Translate contentKey="tk24AdysApp.duyuru.help.icerik" />
              </UncontrolledTooltip>
              <ValidatedField
                id="duyuru-duyuruTipi"
                name="duyuruTipiId"
                data-cy="duyuruTipi"
                label={translate('tk24AdysApp.duyuru.duyuruTipi')}
                type="select"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              >
                <option value="" key="0" />
                {refDuyuruTipis
                  ? refDuyuruTipis.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.adi}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="duyuru-duyuruDurumu"
                name="duyuruDurumuId"
                data-cy="duyuruDurumu"
                label={translate('tk24AdysApp.duyuru.duyuruDurumu')}
                type="select"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              >
                <option value="" key="0" />
                {duyuruDurumu
                  ? duyuruDurumu.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.adi}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="duyuru-duyuruDurumu"
                name="adtEkip"
                data-cy="adtEkip"
                label={translate('tk24AdysApp.duyuru.adtEkip')}
                type="select"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              >
                <option value="" key="0" />
                {referans
                  ? referans.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.adi}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="duyuru-duyuruDurumu"
                name="hedefKitlesiId"
                data-cy="hedefKitlesiId"
                label={'Hedef Kitle'}
                type="select"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              >
                <option value="" key="0" />
                {hedefKitles
                  ? hedefKitles.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.adi}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/duyuru" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default AdymDuyuruUpdate;
