import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Translate, translate } from 'app/component/jhipster';
import { FormItem } from './FormItem';
interface FormDialogProps {
  open: boolean;
  onClose: () => void;
  saveNote: (note: string) => void;
  data: any;
}

const FormDialog: React.FC<FormDialogProps> = ({ open, saveNote, onClose, data }) => {
  const [note, setNote] = useState('');

  const handleNoteChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNote(event.target.value);
  };
  return (
    <Modal className="modal-style" isOpen={open} toggle={onClose} centered>
      <ModalHeader data-cy="notEkleHeading" toggle={onClose}>
        {data && data.krizKatilimci && data.krizKatilimci.adi && (
          <span className="yakinEkleInfo"> {data.krizKatilimci.adi + ' ' + data.krizKatilimci.soyadi}</span>
        )}
        <span className="yakinEkleText">
          <Translate contentKey="tk24AdysApp.krizKatilimciGorev.notGir" />
        </span>
      </ModalHeader>
      {data && (
        <div className="gorevDurumuName">
          <p className="m-0">{data.adi}</p>
        </div>
      )}
      <ModalBody style={{ maxHeight: '450px', overflowY: 'auto' }}>
        <FormItem
          label={translate('global.messages.not')}
          id="gorev-not"
          name="gorevNot"
          defaultValue={data?.talimatiVerenKrizKatilimci ? data?.aciklama : data?.note}
          type="textarea"
          placeholder={translate('tk24AdysApp.refGorev.placeholder.not')}
          onChange={handleNoteChange}
          validation={{
            maxLength: { value: 4000, message: translate('entity.validation.maxlength', { max: 4000 }) },
          }}
        />
      </ModalBody>
      <ModalFooter className="modal-footer">
        <Button className="cancel-button" onClick={onClose}>
          <Translate contentKey="entity.action.cancel">Cancel</Translate>
        </Button>
        &nbsp;
        <Button
          className="save-button"
          onClick={() => saveNote(note)}
          id="jhi-confirm-delete-refAdtForm"
          type="submit"
          data-cy="entityConfirmButton"
        >
          <Translate contentKey="entity.action.save">Kaydet</Translate>
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default FormDialog;
