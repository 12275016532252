import React from 'react';
import { Button } from 'reactstrap';
import { languages } from 'app/config/translation';
import './menu.scss';

export const LocaleMenu = ({ currentLocale, onClick }: { currentLocale: string; onClick: (event: any) => void }) => (
  <div className="d-flex align-items-center">
    {Object.keys(languages).map(locale => (
      <Button
        key={locale}
        color={currentLocale === locale ? 'white' : 'link'}
        className={'localeLink'}
        onClick={() => onClick({ target: { value: locale } })}
      >
        {languages[locale].name}
      </Button>
    ))}
  </div>
);
