import React, { useState, useEffect } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col, FormText, UncontrolledTooltip } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'app/component/jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IKrizKatilimci } from 'app/shared/model/kriz-katilimci.model';
import { getEntities as getKrizKatilimcis } from 'app/entities/kriz-katilimci/kriz-katilimci.reducer';
import { IRefGorev } from 'app/shared/model/ref-gorev.model';
import { getEntities as getRefGorevs } from 'app/entities/ref-gorev/ref-gorev.reducer';
import { IReferans } from 'app/shared/model/referans.model';
import { getEntities as getReferans, getReferansByTip } from 'app/entities/referans/referans.reducer';
import { getEntity, updateEntity, createEntity, reset } from './kriz-katilimci-gorev.reducer';
import { IKrizKatilimciGorev } from 'app/shared/model/kriz-katilimci-gorev.model';
import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import axios from 'axios';

export const KrizKatilimciGorevUpdate = (props: RouteComponentProps<{ id: string }>) => {
  const dispatch = useAppDispatch();

  const [isNew] = useState(!props.match.params || !props.match.params.id);

  const krizKatilimcis = useAppSelector(state => state.krizKatilimci.entities);
  const refGorevs = useAppSelector(state => state.refGorev.entities);
  const referans = useAppSelector(state => state.referans.entities);
  const krizKatilimciGorevEntity = useAppSelector(state => state.krizKatilimciGorev.entity);
  const loading = useAppSelector(state => state.krizKatilimciGorev.loading);
  const updating = useAppSelector(state => state.krizKatilimciGorev.updating);
  const updateSuccess = useAppSelector(state => state.krizKatilimciGorev.updateSuccess);
  const gorevSahibi = useAppSelector(state => state.referans.entities);

  const handleClose = () => {
    props.history.push('/kriz-katilimci-gorev' + props.location.search);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(props.match.params.id));
    }
    dispatch(getReferansByTip('GOREV_SAHIBI'));
    dispatch(getKrizKatilimcis({}));
    dispatch(getRefGorevs({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    values.gorevAtanmaZamani = convertDateTimeToServer(values.gorevAtanmaZamani);
    values.gorevKapanmaZamani = convertDateTimeToServer(values.gorevKapanmaZamani);
    values.cDate = convertDateTimeToServer(values.cDate);
    values.mDate = convertDateTimeToServer(values.mDate);

    const entity = {
      ...krizKatilimciGorevEntity,
      ...values,
      krizKatilimci: krizKatilimcis.find(it => it.id.toString() ===  values.krizKatilimciId.toString()),
      gorev: refGorevs.find(it => it.id.toString() ===  values.gorevId.toString()),
      gorevTuru: referans.find(it => it.id.toString() ===  values.gorevTuruId.toString()),
      gorevDurumu: referans.find(it => it.id.toString() ===  values.gorevDurumuId.toString()),
      durum: referans.find(it => it.id.toString() ===  values.durumId.toString()),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {
          gorevAtanmaZamani: displayDefaultDateTime(),
          gorevKapanmaZamani: displayDefaultDateTime(),
          cDate: displayDefaultDateTime(),
          mDate: displayDefaultDateTime(),
        }
      : {
          ...krizKatilimciGorevEntity,
          gorevAtanmaZamani: convertDateTimeFromServer(krizKatilimciGorevEntity.gorevAtanmaZamani),
          gorevKapanmaZamani: convertDateTimeFromServer(krizKatilimciGorevEntity.gorevKapanmaZamani),
          cDate: convertDateTimeFromServer(krizKatilimciGorevEntity.cDate),
          mDate: convertDateTimeFromServer(krizKatilimciGorevEntity.mDate),
          krizKatilimciId: krizKatilimciGorevEntity?.krizKatilimci?.id,
          gorevId: krizKatilimciGorevEntity?.gorev?.id,
          gorevTuruId: krizKatilimciGorevEntity?.gorevTuru?.id,
          gorevDurumuId: krizKatilimciGorevEntity?.gorevDurumu?.id,
          durumId: krizKatilimciGorevEntity?.durum?.id,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="tk24AdysApp.krizKatilimciGorev.home.createOrEditLabel" data-cy="KrizKatilimciGorevCreateUpdateHeading">
            <Translate contentKey="tk24AdysApp.krizKatilimciGorev.home.createOrEditLabel">Create or edit a KrizKatilimciGorev</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="kriz-katilimci-gorev-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('tk24AdysApp.krizKatilimciGorev.gorevAtanmaZamani')}
                id="kriz-katilimci-gorev-gorevAtanmaZamani"
                name="gorevAtanmaZamani"
                data-cy="gorevAtanmaZamani"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <UncontrolledTooltip target="gorevAtanmaZamaniLabel">
                <Translate contentKey="tk24AdysApp.krizKatilimciGorev.help.gorevAtanmaZamani" />
              </UncontrolledTooltip>
              <ValidatedField
                label={translate('tk24AdysApp.krizKatilimciGorev.gorevKapanmaZamani')}
                id="kriz-katilimci-gorev-gorevKapanmaZamani"
                name="gorevKapanmaZamani"
                data-cy="gorevKapanmaZamani"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <UncontrolledTooltip target="gorevKapanmaZamaniLabel">
                <Translate contentKey="tk24AdysApp.krizKatilimciGorev.help.gorevKapanmaZamani" />
              </UncontrolledTooltip>
              <ValidatedField
                label={translate('tk24AdysApp.krizKatilimciGorev.note')}
                id="kriz-katilimci-gorev-note"
                name="note"
                data-cy="note"
                type="text"
                validate={{
                  maxLength: { value: 4000, message: translate('entity.validation.maxlength', { max: 4000 }) },
                }}
              />
              <UncontrolledTooltip target="noteLabel">
                <Translate contentKey="tk24AdysApp.krizKatilimciGorev.help.note" />
              </UncontrolledTooltip>
              <ValidatedField
                label={translate('tk24AdysApp.krizKatilimciGorev.sortIndex')}
                id="kriz-katilimci-gorev-sortIndex"
                name="sortIndex"
                data-cy="sortIndex"
                type="text"
              />
              <UncontrolledTooltip target="sortIndexLabel">
                <Translate contentKey="tk24AdysApp.krizKatilimciGorev.help.sortIndex" />
              </UncontrolledTooltip>
              <ValidatedField
                label={translate('global.languageGlobal.adiTr')}
                id="kriz-katilimci-gorev-adiTr"
                name="adiTr"
                data-cy="adiTr"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  maxLength: { value: 4000, message: translate('entity.validation.maxlength', { max: 4000 }) },
                }}
              />
              <UncontrolledTooltip target="adiTrLabel">
                <Translate contentKey="global.languageGlobal.adiTr" />
              </UncontrolledTooltip>
              <ValidatedField
                label={translate('global.languageGlobal.adiEn')}
                id="kriz-katilimci-gorev-adiEn"
                name="adiEn"
                data-cy="adiEn"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  maxLength: { value: 4000, message: translate('entity.validation.maxlength', { max: 4000 }) },
                }}
              />
              <UncontrolledTooltip target="adiEnLabel">
                <Translate contentKey="global.languageGlobal.adiEn" />
              </UncontrolledTooltip>
              <ValidatedField
                label={translate('global.languageGlobal.aciklamaTr')}
                id="kriz-katilimci-gorev-aciklamaTr"
                name="aciklamaTr"
                data-cy="aciklamaTr"
                type="text"
                validate={{
                  maxLength: { value: 4000, message: translate('entity.validation.maxlength', { max: 4000 }) },
                }}
              />
              <UncontrolledTooltip target="aciklamaTrLabel">
                <Translate contentKey="global.languageGlobal.aciklamaTr" />
              </UncontrolledTooltip>
              <ValidatedField
                label={translate('global.languageGlobal.aciklamaEn')}
                id="kriz-katilimci-gorev-aciklamaEn"
                name="aciklamaEn"
                data-cy="aciklamaEn"
                type="text"
                validate={{
                  maxLength: { value: 4000, message: translate('entity.validation.maxlength', { max: 4000 }) },
                }}
              />
              <UncontrolledTooltip target="aciklamaEnLabel">
                <Translate contentKey="global.languageGlobal.aciklamaEn" />
              </UncontrolledTooltip>
              <ValidatedField
                label={translate('tk24AdysApp.krizKatilimciGorev.sorumluUniteId')}
                id="kriz-katilimci-gorev-sorumluUniteId"
                name="sorumluUniteId"
                data-cy="sorumluUniteId"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  maxLength: { value: 20, message: translate('entity.validation.maxlength', { max: 20 }) },
                }}
              />
              <UncontrolledTooltip target="sorumluUniteIdLabel">
                <Translate contentKey="tk24AdysApp.krizKatilimciGorev.help.sorumluUniteId" />
              </UncontrolledTooltip>
              <ValidatedField
                label={translate('tk24AdysApp.krizKatilimciGorev.sorumluUniteAdi')}
                id="kriz-katilimci-gorev-sorumluUniteAdi"
                name="sorumluUniteAdi"
                data-cy="sorumluUniteAdi"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  maxLength: { value: 1000, message: translate('entity.validation.maxlength', { max: 1000 }) },
                }}
              />
              <UncontrolledTooltip target="sorumluUniteAdiLabel">
                <Translate contentKey="tk24AdysApp.krizKatilimciGorev.help.sorumluUniteAdi" />
              </UncontrolledTooltip>
              <ValidatedField
                label={translate('tk24AdysApp.krizKatilimciGorev.sure')}
                id="kriz-katilimci-gorev-sure"
                name="sure"
                data-cy="sure"
                type="text"
              />
              <UncontrolledTooltip target="sureLabel">
                <Translate contentKey="tk24AdysApp.krizKatilimciGorev.help.sure" />
              </UncontrolledTooltip>
              <ValidatedField
                id="kriz-katilimci-gorev-krizKatilimci"
                name="krizKatilimciId"
                data-cy="krizKatilimci"
                label={translate('tk24AdysApp.krizKatilimciGorev.krizKatilimci')}
                type="select"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              >
                <option value="" key="0" />
                {krizKatilimcis
                  ? krizKatilimcis.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="kriz-katilimci-gorev-gorev"
                name="gorevId"
                data-cy="gorev"
                label={translate('tk24AdysApp.krizKatilimciGorev.gorev')}
                type="select"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              >
                <option value="" key="0" />
                {refGorevs
                  ? refGorevs.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.adi}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="kriz-katilimci-gorev-gorevTuru"
                name="gorevTuruId"
                data-cy="gorevTuru"
                label={translate('tk24AdysApp.krizKatilimciGorev.gorevTuru')}
                type="select"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              >
                <option value="" key="0" />
                {referans
                  ? referans.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="kriz-katilimci-gorev-gorevDurumu"
                name="gorevDurumuId"
                data-cy="gorevDurumu"
                label={translate('tk24AdysApp.krizKatilimciGorev.gorevDurumu')}
                type="select"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              >
                <option value="" key="0" />
                {referans
                  ? referans.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="gorevSahibi"
                name="gorevSahibi"
                data-cy="gorevSahibi"
                label={'Gorev Sahibi'}
                type="select"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              >
                <option value="" key="0" />
                {gorevSahibi
                  ? gorevSahibi.map(otherEntity => (
                      <option value={otherEntity.adi} key={otherEntity.id}>
                        {otherEntity.adi}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                label={translate('tk24AdysApp.krizKatilimciGorev.adtEkip')}
                id="kriz-katilimci-gorev-adtEkip"
                name="adtEkip"
                data-cy="adtEkip"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  validate: v => isNumber(v) || translate('entity.validation.number'),
                }}
              />
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/kriz-katilimci-gorev" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default KrizKatilimciGorevUpdate;
