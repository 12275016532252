import React from 'react';
import { Row, Col } from 'reactstrap';
import { Translate } from 'app/component/jhipster';
import { IYetki } from 'app/shared/model/yetki.model';

interface YetkiDetailProp {
  yetki: IYetki;
}

export const YetkiDetail: React.FC<YetkiDetailProp> = ({ yetki }) => {
  return (
    <Row className="modal-body-row">
      <Col lg="6" style={{ marginTop: '10px' }}>
        <Row className="modal-content-title">
          <Translate contentKey="tk24AdysApp.yetki.code">Code</Translate>
        </Row>
        <Row className="modal-content-text">{yetki.code}</Row>
      </Col>
      <Col lg="6" style={{ marginTop: '10px' }}>
        <Row className="modal-content-title">
          <Translate contentKey="tk24AdysApp.yetki.adi">Adi</Translate>
        </Row>
        <Row className="modal-content-text">{yetki.adi}</Row>
      </Col>
      <Col lg="6" style={{ marginTop: '10px' }}>
        <Row className="modal-content-title">
          <Translate contentKey="tk24AdysApp.yetki.nesne">Nesne</Translate>
        </Row>
        <Row className="modal-content-text">{yetki.nesne}</Row>
      </Col>
    </Row>
  );
};

export default YetkiDetail;
