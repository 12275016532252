import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending } from '@reduxjs/toolkit';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { createEntitySlice, EntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { defaultValue } from 'app/shared/model/ref-duyuru-tipi.model';
import { IRefHavalimaniDestek, IRefHavalimaniDestekParams } from 'app/shared/model/ref-havalimani-destek.model';

const initialState: EntityState<IRefHavalimaniDestek> = {
  loading: false,
  errorMessage: null,
  entities: [],
  totalEntities: [],
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

const apiUrl = 'api/ref-havalimani-desteks';

export const getEntities = createAsyncThunk(
  'refHavalimaniDestek/fetch_entity_list',
  async ({ page, size, sort, query, havalimaniId, havalimaniIds }: IRefHavalimaniDestekParams) => {
    const queryParams = new URLSearchParams();
    if (sort) {
      queryParams.set('page', page.toString());
      queryParams.set('size', size.toString());
      queryParams.set('sort', sort);
    }

    if (havalimaniId) {
      queryParams.set('havalimaniId.equals', havalimaniId.toString());
    }

    if (havalimaniIds) {
      queryParams.set('havalimaniId.in', havalimaniIds.toString());
    }

    if (query) {
      const parsedQueryParams = new URLSearchParams(query);
      parsedQueryParams.forEach((value, key) => {
        queryParams.set(key, value);
      });
    }
    queryParams.set('cacheBuster', new Date().getTime().toString());
    const queryString = queryParams.toString();
    const requestUrl = `${apiUrl}${queryString ? `?${queryString}` : ''}`;

    return axios.get<IRefHavalimaniDestek[]>(requestUrl);
  }
);

export const getTotalEntities = createAsyncThunk(
  'refHavalimaniDestek/fetch_entity_total_list',
  async ({ size, sort, query }: IRefHavalimaniDestekParams) => {
    const queryParams = new URLSearchParams();
    if (sort) {
      queryParams.set('size', size.toString());
      queryParams.set('sort', sort);
    }
    if (query) {
      const parsedQueryParams = new URLSearchParams(query);
      parsedQueryParams.forEach((value, key) => {
        queryParams.set(key, value);
      });
    }
    queryParams.set('cacheBuster', new Date().getTime().toString());
    const queryString = queryParams.toString();
    const requestUrl = `${apiUrl}${queryString ? `?${queryString}` : ''}`;

    return axios.get<IRefHavalimaniDestek[]>(requestUrl);
  }
);

export const getEntity = createAsyncThunk(
  'refHavalimaniDestek/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<IRefHavalimaniDestek>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const createEntity = createAsyncThunk(
  'refHavalimaniDestek/create_entity',
  async (params: IRefHavalimaniDestekParams, thunkAPI) => {
    const result = await axios.post<IRefHavalimaniDestek>(apiUrl, cleanEntity(params.entity));
    thunkAPI.dispatch(getEntities({ havalimaniId: params.entity.havalimani.id.toString() }));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
  'refHavalimaniDestek/update_entity',
  async (params: IRefHavalimaniDestekParams, thunkAPI) => {
    const result = await axios.put<IRefHavalimaniDestek>(`${apiUrl}/${params.entity.id}`, cleanEntity(params.entity));
    thunkAPI.dispatch(getEntities(params));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const partialUpdateEntity = createAsyncThunk(
  'refHavalimaniDestek/partial_update_entity',
  async (params: IRefHavalimaniDestekParams, thunkAPI) => {
    const result = await axios.patch<IRefHavalimaniDestek>(`${apiUrl}/${params.entity.id}`, cleanEntity(params.entity));
    thunkAPI.dispatch(getEntities(params));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const deleteEntity = createAsyncThunk(
  'refHavalimaniDestek/delete_entity',
  async (params: IRefHavalimaniDestekParams, thunkAPI) => {
    const requestUrl = `${apiUrl}/${params.entity.id}`;
    const result = await axios.delete<IRefHavalimaniDestek>(requestUrl);
    thunkAPI.dispatch(getEntities({ havalimaniId: params.entity.havalimani.id.toString() }));
    return result;
  },
  { serializeError: serializeAxiosError }
);

// slice

export const RefHavalimaniDestekSlice = createEntitySlice({
  name: 'refHavalimaniDestek',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(deleteEntity.fulfilled, state => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = {};
      })
      .addMatcher(isFulfilled(getEntities), (state, action) => {
        return {
          ...state,
          loading: false,
          entities: action.payload.data,
          totalItems: parseInt(action.payload.headers['x-total-count'], 10),
        };
      })
      .addMatcher(isFulfilled(getTotalEntities), (state, action) => {
        return {
          ...state,
          loading: false,
          totalEntities: action.payload.data,
          totalItems: parseInt(action.payload.headers['x-total-count'], 10),
        };
      })
      .addMatcher(isFulfilled(createEntity, updateEntity, partialUpdateEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isPending(getEntities, getTotalEntities, getEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(createEntity, updateEntity, partialUpdateEntity, deleteEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      });
  },
});

export const { reset } = RefHavalimaniDestekSlice.actions;

// Reducer
export default RefHavalimaniDestekSlice.reducer;
