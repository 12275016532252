import { IKriz } from 'app/shared/model/kriz.model';
import { IRefUcak } from 'app/shared/model/ref-ucak.model';
import { IReferans } from 'app/shared/model/referans.model';
import { IQueryParams } from '../reducers/reducer.utils';

export interface IKrizUcak {
  id?: number;
  guid?: string;
  cDate?: string;
  cUser?: number;
  mDate?: string;
  mUser?: number;
  kriz?: IKriz;
  ucak?: IRefUcak;
  durum?: IReferans;
}

export interface IKrizUcakParams extends IQueryParams {
  entity?: IKrizUcak;
  krizId?: string;
}

export const defaultValue: Readonly<IKrizUcak> = {};
