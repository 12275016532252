import React, { CSSProperties } from 'react';
import { hasAnyAuthority } from 'app/config/store';
import { NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import { translate } from 'app/component/jhipster';

export interface INavItem {
  to?: string;
  id?: string;
  'data-cy'?: string;
  grantedAuthorites?: string[];
  className?: string;
  style?: CSSProperties;
  onClick?: () => void; // onClick prop ekleyin
}

export interface INavItemSearch extends INavItem {
  metaDataKey: string;
  search?: string;
}

export class NavItemAccount extends React.Component<INavItem> {
  handleClick = () => {
    const { onClick } = this.props;
    if (onClick) {
      onClick(); // Eğer onClick prop'u varsa çağır
    }
  };

  render() {
    const { to, id, grantedAuthorites, children, className, style } = this.props;
    if (grantedAuthorites && hasAnyAuthority(grantedAuthorites))
      return (
        <NavLink to={to} tag={Link} className={`${className || 'childTitle'} `} style={style}>
          {children}
        </NavLink>
      );
    else return null;
  }
}

export class NavChildItemAccount extends React.Component<INavItem> {
  handleClick = () => {
    const { onClick } = this.props;
    if (onClick) {
      onClick(); // Eğer onClick prop'u varsa çağır
    }
  };

  render() {
    const { to, id, grantedAuthorites, children, className } = this.props;
    if (grantedAuthorites && hasAnyAuthority(grantedAuthorites))
      return (
        <div className="childTitle">
          <NavLink to={to} tag={Link} className={`${className || 'childTitle'} `}>
            {children}
          </NavLink>
        </div>
      );
    else return null;
  }
}

export class NavItemSearchAccount extends React.Component<INavItemSearch> {
  handleClick = () => {
    const { onClick } = this.props;
    if (onClick) {
      onClick(); // Eğer onClick prop'u varsa çağır
    }
  };

  render() {
    const { to, id, grantedAuthorites, children, className, style, search, metaDataKey } = this.props;

    function metaDataBySearch(): boolean {
      if (search.length > 2) {
        const metaData: string[] = Object.values(translate(`tk24AdysApp.metaData.${metaDataKey}`));
        const searchTerms = search.toLocaleLowerCase('tr').split(' ');
        return searchTerms.every(term => metaData.some(str => str.toLocaleLowerCase('tr').includes(term.toLocaleLowerCase('tr'))));
      } else return true;
    }

    if (grantedAuthorites && hasAnyAuthority(grantedAuthorites) && metaDataBySearch()) {
      return (
        <NavLink to={to} tag={Link} className={`${className || 'childTitle'} `} style={style}>
          {children}
        </NavLink>
      );
    } else {
      return null;
    }
  }
}

export class NavChildItemSearchAccount extends React.Component<INavItemSearch> {
  handleClick = () => {
    const { onClick } = this.props;
    if (onClick) {
      onClick(); // Eğer onClick prop'u varsa çağır
    }
  };

  render() {
    const { to, id, grantedAuthorites, children, className, search, metaDataKey } = this.props;

    function metaDataBySearch(): boolean {
      if (search.length > 2) {
        const metaData: string[] = Object.values(translate(`tk24AdysApp.metaData.${metaDataKey}`));

        const searchTerms = search.toLocaleLowerCase('tr').split(' ');
        return searchTerms.every(term => metaData.some(str => str.toLocaleLowerCase('tr').includes(term.toLocaleLowerCase('tr'))));
      } else return true;
    }

    if (grantedAuthorites && hasAnyAuthority(grantedAuthorites) && metaDataBySearch())
      return (
        <div className="childTitle">
          <NavLink to={to} tag={Link} className={`${className || 'childTitle'} `}>
            {children}
          </NavLink>
        </div>
      );
    else return null;
  }
}

export class NavItemSearch extends React.Component<INavItemSearch> {
  render() {
    const { children, className, search, metaDataKey } = this.props;

    function metaDataBySearch(): boolean {
      if (search.length > 2) {
        const metaDataObject = translate(`tk24AdysApp.metaData.${metaDataKey}`);
        const valDegerleri = Object.keys(metaDataObject).reduce((acc, key) => {
          const values = Object.values(metaDataObject[key]);
          return acc.concat(values);
        }, []);
        const searchTerms = search.toLocaleLowerCase('tr').split(' ');
        return searchTerms.every(term => valDegerleri.some(str => str.toLocaleLowerCase('tr').includes(term.toLocaleLowerCase('tr'))));
      } else return true;
    }

    if (metaDataBySearch()) return <NavItem className={`${className || 'childTitle'} `}>{children}</NavItem>;
    else return null;
  }
}
