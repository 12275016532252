import React from 'react';
import { DropdownItem } from 'reactstrap';
import { NavLink as Link } from 'react-router-dom';
import './menu.scss';

interface IMenuItemHeaderProps {
  to: string;
  id?: string;
  'data-cy'?: string;
  className?: string;
  styles?: React.CSSProperties;
  onClick?: () => void;
}

const MenuItemHeader: React.FC<IMenuItemHeaderProps> = ({ to, id, 'data-cy': dataCy, className, styles, onClick, children }) => (
  <DropdownItem
    tag={Link}
    to={to}
    id={id}
    data-cy={dataCy}
    className={className}
    onClick={onClick}
    style={{ background: 'transparent', padding: 32, border: '1px solid #c1c8d1', ...styles }}
  >
    {children}
  </DropdownItem>
);

export default MenuItemHeader;
