import { IQueryParams } from '../reducers/reducer.utils';
import { IBaseModel } from './base.model';

export interface IRefIstasyonGorevGrubu extends IBaseModel {
  realId?: number;
  sortIndex?: number;
  id?: number;
  guid?: string;
  adi?: string;
  mDate?: string;
}

export interface IRefIstasyonGorevGrubuParams extends IQueryParams {
  entity?: IRefIstasyonGorevGrubu;
}

export interface IRefIstasyonGorevGrubuListParams extends IQueryParams {
  entity?: IRefIstasyonGorevGrubu[];
}

export const defaultValue: Readonly<IRefIstasyonGorevGrubu> = {};
