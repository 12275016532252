import { IEtkilenenKisi } from './etkilenen-kisi.model';

export interface IKrizEtkilenenKisiNote {
  id?: number;
  guid?: string;
  cDate?: string;
  cUser?: number;
  mDate?: string;
  mUser?: number;
  note?: string;
  krikrizEtkilenenKisiz?: IEtkilenenKisi | null;
}

export const defaultValue: Readonly<IKrizEtkilenenKisiNote> = {};
