import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending } from '@reduxjs/toolkit';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { IQueryParams, createEntitySlice, EntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { IRefHavalimani, IRefHavalimaniParams, defaultValue } from 'app/shared/model/ref-havalimani.model';
import { isNullOrEmpty } from 'app/shared/util/confirm-utils';

const initialState: EntityState<IRefHavalimani> = {
  loading: false,
  errorMessage: null,
  entities: [],
  totalEntities: [],
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};
interface RefHavalimaniSearchParams extends IQueryParams {
  iataKodu?: string;
  icoaKodu?: string;
  havalimaniAdi?: string;
}

const apiUrl = 'api/ref-havalimanis';

// Actions
export const getSearchEntities = createAsyncThunk(
  'refhavalimanis/fetch_entity_list',
  async ({ iataKodu, icoaKodu, havalimaniAdi, page, size, sort }: RefHavalimaniSearchParams) => {
    let seachParam = `${sort ? `?page=${page}&size=${size}&sort=${sort}&` : '?'}cacheBuster=${new Date().getTime()}`;
    if (!isNullOrEmpty(iataKodu)) {
      seachParam += `&koduIata.contains=${iataKodu}`;
    }
    if (!isNullOrEmpty(icoaKodu)) {
      seachParam += `&koduIcoa.contains=${icoaKodu}`;
    }
    if (!isNullOrEmpty(havalimaniAdi)) {
      seachParam += `&adi.contains=${havalimaniAdi}`;
    }

    const requestUrl = `api/ref-havalimanis-kriz/${seachParam}`;
    return axios.get<IRefHavalimani[]>(requestUrl);
  }
);

export const getEntities = createAsyncThunk(
  'refhavalimanis/fetch_entity_list',
  async ({ page, size, sort, query }: IRefHavalimaniParams) => {
    const queryParams = new URLSearchParams();
    if (sort) {
      queryParams.set('page', page.toString());
      queryParams.set('size', size.toString());
      queryParams.set('sort', sort);
    }

    if (query) {
      const parsedQueryParams = new URLSearchParams(query);
      parsedQueryParams.forEach((value, key) => {
        queryParams.set(key, value);
      });
    }
    queryParams.set('cacheBuster', new Date().getTime().toString());
    const queryString = queryParams.toString();
    const requestUrl = `${apiUrl}-kriz/${queryString ? `?${queryString}` : ''}`;

    return axios.get<IRefHavalimani[]>(requestUrl);
  }
);

export const getTotalEntities = createAsyncThunk(
  'refhavalimanis/fetch_entity_total_list',
  async ({ size, sort, query }: IRefHavalimaniParams) => {
    const queryParams = new URLSearchParams();
    if (sort) {
      queryParams.set('size', size.toString());
      queryParams.set('sort', sort);
    }
    if (query) {
      const parsedQueryParams = new URLSearchParams(query);
      parsedQueryParams.forEach((value, key) => {
        queryParams.set(key, value);
      });
    }
    queryParams.set('cacheBuster', new Date().getTime().toString());
    const queryString = queryParams.toString();
    const requestUrl = `${apiUrl}-kriz/${queryString ? `?${queryString}` : ''}`;

    return axios.get<IRefHavalimani[]>(requestUrl);
  }
);

export const getEntity = createAsyncThunk(
  'refHavalimani/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<IRefHavalimani>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const createEntity = createAsyncThunk(
  'refHavalimani/create_entity',
  async (entity: IRefHavalimani, thunkAPI) => {
    const result = await axios.post<IRefHavalimani>(apiUrl, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
  'refHavalimani/update_entity',
  async (entity: IRefHavalimani, thunkAPI) => {
    const result = await axios.put<IRefHavalimani>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const partialUpdateEntity = createAsyncThunk(
  'refHavalimani/partial_update_entity',
  async (entity: IRefHavalimani, thunkAPI) => {
    const result = await axios.patch<IRefHavalimani>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const deleteEntity = createAsyncThunk(
  'refHavalimani/delete_entity',
  async (id: string | number, thunkAPI) => {
    const requestUrl = `${apiUrl}/${id}`;
    const result = await axios.delete<IRefHavalimani>(requestUrl);
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

// slice

export const RefHavalimaniSlice = createEntitySlice({
  name: 'refHavalimani',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(deleteEntity.fulfilled, state => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = {};
      })
      .addMatcher(isFulfilled(getEntities, getSearchEntities), (state, action) => {
        return {
          ...state,
          loading: false,
          entities: action.payload.data,
          totalItems: parseInt(action.payload.headers['x-total-count'], 10),
        };
      })
      .addMatcher(isFulfilled(getTotalEntities), (state, action) => {
        return {
          ...state,
          loading: false,
          totalEntities: action.payload.data,
          totalItems: parseInt(action.payload.headers['x-total-count'], 10),
        };
      })
      .addMatcher(isFulfilled(createEntity, updateEntity, partialUpdateEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isPending(getEntities, getSearchEntities, getTotalEntities, getEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(createEntity, updateEntity, partialUpdateEntity, deleteEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      });
  },
});

export const { reset } = RefHavalimaniSlice.actions;

// Reducer
export default RefHavalimaniSlice.reducer;
