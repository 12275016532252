import dayjs from 'dayjs';

import {
  APP_DATETIME_FORMAT,
  APP_LOCAL_DATETIME_FORMAT,
  APP_LOCAL_DATETIMEZONE_FORMAT,
  APP_LOCAL_DATE_FORMAT_FOR_UCUS,
} from 'app/config/constants';

export const convertDateTimeFromServer = date => (date ? dayjs.tz(date).format(APP_LOCAL_DATETIME_FORMAT) : null);

export const convertDateTimeToServer = date => (date ? dayjs.tz(date).toDate() : null);

export const displayDefaultDateTime = () => dayjs().startOf('day').format(APP_LOCAL_DATETIME_FORMAT);

export const convertDateTimeAppDate = date => (date ? dayjs(date, APP_DATETIME_FORMAT).format(APP_LOCAL_DATETIME_FORMAT) : null);

export const convertDateTimeZoneAppDate = date => (date ? dayjs(date, APP_DATETIME_FORMAT).format(APP_LOCAL_DATETIMEZONE_FORMAT) : null);

export const convertDateTimeForUcus = date => (date ? dayjs(date).format(APP_LOCAL_DATE_FORMAT_FOR_UCUS) : null);
