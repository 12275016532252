import { IRefAdtEkip } from './ref-adt-ekip.model';
import { IQueryParams } from 'app/shared/reducers/reducer.utils';
import { IKrizKatilimciIletisim } from 'app/shared/model/kriz-katilimci-iletisim.model';

export interface IRefAdtAltEkip {
  id?: number;
  guid?: string;
  adt_ekip_id?: number;
  ekip?: IRefAdtEkip;
  adi?: string;
  adiTr?: string;
  adiEn?: string;
  cDate?: string;
  cUser?: number;
  mDate?: string;
  mUser?: number;
  realId?: any;
}
export const defaultValue: Readonly<IRefAdtAltEkip> = {};
