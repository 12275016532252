import React, { useCallback, useEffect, useState } from 'react';
import { Button, Form, ModalFooter, ModalBody, Modal, ModalHeader, Col, FormGroup, Input, Label, Row } from 'reactstrap';
import { Translate, translate } from 'app/component/jhipster';
import { FormItem } from 'app/component/FormItem';
import '../style.scss';
import KrizHavalimaniSearch from './kriz-havalimani-search';
import axios from 'axios';
import MapComponent from 'app/modules/viewer/Map/map';
import { IKriz } from 'app/shared/model/kriz.model';
import { SERVER_URL } from 'app/config/constants';
import { toast } from 'react-toastify';
import { LoadingSpinner } from 'app/component/LoadingSpinner';
import { ReferansEnum } from 'app/constants/ReferansEnum';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { CustomDeleteEntityConfirm, isNullOrEmpty, renderTimezones } from 'app/shared/util/confirm-utils';
import { deleteEntity as deleteKrizHavalimaniEntity } from './kriz-havalimani.reducer';

interface KrizHavalimaniUpdateProp {
  kriz: IKriz;
  krizHavalimaniList: any[];
  getAllEntities: () => void;
  handleCloseForm: () => void;
  isUpdateOpen: boolean;
  krizUpdating: boolean;
  setKrizUpdating: (updating: boolean) => void;
}

export const KrizHavalimaniUpdate: React.FC<KrizHavalimaniUpdateProp> = ({
  kriz,
  krizHavalimaniList,
  getAllEntities,
  handleCloseForm,
  isUpdateOpen,
  krizUpdating,
  setKrizUpdating,
}) => {
  const dispatch = useAppDispatch();
  const [selectedRadio, setSelectedRadio] = useState(kriz.krizYeriId?.id);
  const [formValues, setFormValues] = useState<IKriz>();
  const [updatedHavalimaniList, setUpdatedHavalimaniList] = useState(null);

  const [showSearchHavalimaniModal, setShowSearchHavalimaniModal] = useState(false);

  const [isVerifyModalOpen, setIsVerifyModalOpen] = useState(false);
  const [modalPromiseResolve, setModalPromiseResolve] = useState(null);
  const [krizLoading, setKrizLoading] = useState(false);

  const referans = useAppSelector(state => state.referans.entities);
  const refKrizYeris = referans.filter(res => res.tipi === 'KRIZ_YERI');

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedHavalimani, setSelectedHavalimani] = useState(null);

  const handleHavalimaniModalClose = () => {
    setShowSearchHavalimaniModal(false);
  };

  const showVerifyConfirmModal = () => {
    if (selectedRadio === ReferansEnum.KrizYeri.Havalimani && (!updatedHavalimaniList || updatedHavalimaniList.length === 0)) {
      toast.error('Lütfen havalimanı seçimiz.');
    } else {
      setIsVerifyModalOpen(true);
      return new Promise(resolve => {
        setModalPromiseResolve(() => resolve);
      });
    }
  };

  const handleVerify = () => {
    if (modalPromiseResolve) {
      modalPromiseResolve(true);
    }
    setIsVerifyModalOpen(false);
  };

  const handleCancel = () => {
    if (modalPromiseResolve) {
      modalPromiseResolve(false);
    }
    setIsVerifyModalOpen(false);
  };

  useEffect(() => {
    if (!updatedHavalimaniList) {
      setUpdatedHavalimaniList(krizHavalimaniList.map(res => res));
    }
  }, [krizHavalimaniList]);

  const addKrizHavalimaniList = havalimani => {
    setUpdatedHavalimaniList(prevList => [...prevList, havalimani]);
  };

  const deleteKrizHavalimaniList = havalimaniId => {
    setUpdatedHavalimaniList(prevList => prevList.filter(item => item.id !== havalimaniId));
  };

  const confirmEntityDelete = havalimani => {
    setSelectedHavalimani(havalimani);
    setIsDeleteModalOpen(true);
  };

  const handleDeleteConfirm = useCallback(async () => {
    if (selectedHavalimani) {
      const entity = { page: 0, size: 20, sort: 'id,desc', entity: selectedHavalimani, krizId: kriz.id.toString() };
      await dispatch(deleteKrizHavalimaniEntity(entity));
      deleteKrizHavalimaniList(selectedHavalimani?.id);
      handleDeleteCancel();
    }
  }, [selectedHavalimani, dispatch]);

  const handleDeleteCancel = () => {
    setIsDeleteModalOpen(false);
    setSelectedHavalimani(null);
  };

  const handleSelectLocation = (lat, lng) => {
    const value = { krizYeriKonum: lat + ',' + lng, krizYeriKonumX: lat, krizYeriKonumY: lng };
    setFormValues(prevValues => ({
      ...prevValues,
      ...value,
    }));
  };

  const handleSelectDefaultLocation = () => {
    const value = { krizYeriKonum: 39.266244 + ',' + 34.16748, krizYeriKonumX: 39.266244, krizYeriKonumY: 34.16748 };
    setFormValues(prevValues => ({
      ...prevValues,
      ...value,
    }));
  };

  const handleInputChange = e => {
    const { name, value } = e.target;
    setFormValues(prevValues => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const updateKrizKonumHavalimani = async (url, entity) => {
    try {
      setKrizLoading(true);
      setKrizUpdating(true);
      const response = await axios.post(url, entity);
      getAllEntities();
      setKrizLoading(false);
      setKrizUpdating(false);
    } catch (error) {
      toast.error(error.message);
      setKrizLoading(false);
      setKrizUpdating(false);
    }
  };

  const handleSave = async values => {
    values.preventDefault();

    const isSave = await showVerifyConfirmModal();
    if (isSave) {
      setKrizLoading(true);

      if (selectedRadio === ReferansEnum.KrizYeri.Havalimani) {
        // havalimanı
        const newEntity = {
          kriz: { ...kriz, ...formValues, krizYeriId: refKrizYeris.find(it => it.id.toString() === selectedRadio.toString()) },
          krizYeriKonumX: null,
          krizYeriKonumY: null,
          krizHavalimaniList: updatedHavalimaniList.map(res => (res.havalimani ? res.havalimani : res)),
          krizKatilimciList: [],
        };

        await updateKrizKonumHavalimani('api/krizs/update/krizYeriHavalimani', newEntity);
      } else if (selectedRadio === ReferansEnum.KrizYeri.Konum) {
        // konum
        const newEntity = {
          kriz: {
            ...kriz,
            ...formValues,
            krizYeriId: refKrizYeris.find(it => it.id.toString() === selectedRadio.toString()),
          },
          krizKatilimciList: [],
        };

        await updateKrizKonumHavalimani('api/krizs/update/krizYeriKonum', newEntity);
      } else if (selectedRadio === ReferansEnum.KrizYeri.KonumYok) {
        // konum yok
        const newEntity = {
          kriz: {
            ...kriz,
            ...formValues,
            krizYeriId: refKrizYeris.find(it => it.id.toString() === selectedRadio.toString()),
          },
          krizKatilimciList: [],
        };

        await updateKrizKonumHavalimani('api/krizs/update/krizYeriKonumYok', newEntity);
      }
      setKrizLoading(false);
    }
    handleCloseForm();
  };

  const searchHavalimaniModal = () => {
    return (
      <KrizHavalimaniSearch
        addKrizHavalimaniList={addKrizHavalimaniList}
        krizHavalimaniList={updatedHavalimaniList}
        handleClose={handleHavalimaniModalClose}
        isOpen={showSearchHavalimaniModal}
      />
    );
  };

  const verifyModal = () => {
    return (
      <Modal isOpen={isVerifyModalOpen} toggle={handleCancel} centered>
        <ModalHeader toggle={handleCancel}>
          <Translate contentKey="entity.confirm.title" />
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col sm={1} style={{ display: 'flex', alignItems: 'center', margin: 'auto' }}>
              <img src={`${SERVER_URL}/content/images/icon/Warning.svg`} style={{ height: '30px' }} />
            </Col>
            <Col sm={11} style={{ display: 'flex', alignItems: 'center', margin: 'auto' }}>
              {selectedRadio === kriz.krizYeriId?.id && (
                <>
                  {selectedRadio === ReferansEnum.KrizYeri.Havalimani && (
                    <div>
                      <Translate contentKey="tk24AdysApp.krizHavalimani.confirm.havalimaniUpdate" /> <br />
                      <Translate contentKey="tk24AdysApp.krizHavalimani.confirm.eminMisiniz" />
                    </div>
                  )}
                  {selectedRadio === ReferansEnum.KrizYeri.Konum && (
                    <div>
                      <Translate contentKey="tk24AdysApp.krizHavalimani.confirm.konumUpdate" /> <br />
                      <Translate contentKey="tk24AdysApp.krizHavalimani.confirm.eminMisiniz" />
                    </div>
                  )}

                  {selectedRadio === ReferansEnum.KrizYeri.KonumYok && (
                    <div>
                      <Translate contentKey="tk24AdysApp.krizHavalimani.confirm.konumUpdate" /> <br />
                      <Translate contentKey="tk24AdysApp.krizHavalimani.confirm.eminMisiniz" />
                    </div>
                  )}
                </>
              )}

              {selectedRadio !== kriz.krizYeriId?.id && (
                <>
                  {' '}
                  {kriz.krizYeriId?.id === ReferansEnum.KrizYeri.Havalimani && (
                    <div>
                      <Translate
                        contentKey="tk24AdysApp.krizHavalimani.confirm.countByHavalimani"
                        interpolate={{ count: krizHavalimaniList.length }}
                      />
                      {' ( '}
                      {krizHavalimaniList.map((item, index) => (
                        <span key={item?.havalimani?.id}>
                          <strong>
                            {item?.havalimani?.koduIata} {index !== krizHavalimaniList.length - 1 && ' / '}{' '}
                          </strong>
                        </span>
                      ))}
                      {' ) '} <br />
                      <Translate contentKey="tk24AdysApp.krizHavalimani.confirm.havalimaniChangeByKonum" /> <br />
                      <Translate contentKey="tk24AdysApp.krizHavalimani.confirm.eminMisiniz" />
                    </div>
                  )}
                  {kriz.krizYeriId?.id === ReferansEnum.KrizYeri.Konum && (
                    <>
                      {selectedRadio === ReferansEnum.KrizYeri.Havalimani && (
                        <div>
                          <Translate contentKey="tk24AdysApp.krizHavalimani.confirm.konumChangeByHavalimani" /> <br />
                          <Translate contentKey="tk24AdysApp.krizHavalimani.confirm.eminMisiniz" />
                        </div>
                      )}

                      {selectedRadio === ReferansEnum.KrizYeri.KonumYok && (
                        <div>
                          <Translate contentKey="tk24AdysApp.krizHavalimani.confirm.konumUpdate" /> <br />
                          <Translate contentKey="tk24AdysApp.krizHavalimani.confirm.eminMisiniz" />
                        </div>
                      )}
                    </>
                  )}
                  {kriz.krizYeriId?.id === ReferansEnum.KrizYeri.KonumYok && (
                    <>
                      {selectedRadio === ReferansEnum.KrizYeri.Havalimani && (
                        <div>
                          <Translate contentKey="tk24AdysApp.krizHavalimani.confirm.havalimaniUpdate" /> <br />
                          <Translate contentKey="tk24AdysApp.krizHavalimani.confirm.eminMisiniz" />
                        </div>
                      )}

                      {selectedRadio === ReferansEnum.KrizYeri.Konum && (
                        <div>
                          <Translate contentKey="tk24AdysApp.krizHavalimani.confirm.konumUpdate" /> <br />
                          <Translate contentKey="tk24AdysApp.krizHavalimani.confirm.eminMisiniz" />
                        </div>
                      )}
                    </>
                  )}
                </>
              )}
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button className="modal-cancel-button" onClick={handleCancel}>
            <Translate contentKey="entity.action.cancel">Cancel</Translate>
          </Button>
          <Button className="modal-save-button" onClick={handleVerify}>
            <Translate contentKey="entity.action.save">Kaydet</Translate>
          </Button>
        </ModalFooter>
      </Modal>
    );
  };

  const saatDilimiChange = event => {
    if (event.target.value) {
      setFormValues(prevValues => ({
        ...prevValues,
        krizYeriSaatDilimi: event.target.value,
      }));
    } else {
      setFormValues(prevValues => ({
        ...prevValues,
        krizYeriSaatDilimi: null,
      }));
    }
  };

  const handleChangeRadio = konumYeriId => {
    if (
      konumYeriId === ReferansEnum.KrizYeri.Konum &&
      isNullOrEmpty(kriz?.krizYeriKonumX) &&
      isNullOrEmpty(kriz?.krizYeriKonumY) &&
      isNullOrEmpty(formValues?.krizYeriKonumX) &&
      isNullOrEmpty(formValues?.krizYeriKonumY)
    ) {
      handleSelectDefaultLocation();
    }

    setSelectedRadio(konumYeriId);
  };

  return (
    <Modal isOpen={isUpdateOpen} toggle={handleCloseForm} style={{ maxWidth: '1200px' }} size="lg" centered>
      {(krizLoading || krizUpdating) && <LoadingSpinner />}
      {selectedHavalimani && (
        <CustomDeleteEntityConfirm
          entity={selectedHavalimani}
          entityName="krizHavalimani"
          isOpen={isDeleteModalOpen}
          onConfirm={handleDeleteConfirm}
          onCancel={handleDeleteCancel}
        />
      )}

      <ModalHeader data-cy="RefDuyuruTipiCreateUpdateHeading" toggle={handleCloseForm}>
        <Translate contentKey="tk24AdysApp.kriz.konumBilgisiUpdate" />
      </ModalHeader>
      <Form onSubmit={handleSave}>
        <ModalBody style={{ maxHeight: '520px', height: '520px', overflowY: 'auto' }}>
          {searchHavalimaniModal()}
          {verifyModal()}

          <div style={{ top: 0, backgroundColor: 'white' }}>
            <FormGroup tag="fieldset" style={{ display: 'flex', margin: 5, gap: 20 }}>
              {refKrizYeris
                .sort((a, b) => a.sortIndex - b.sortIndex)
                .map(item => (
                  <FormGroup
                    key={item.id}
                    check
                    className="flexKonumKriz"
                    onClick={() => {
                      handleChangeRadio(item.id);
                    }}
                  >
                    <Input
                      type="radio"
                      id={`radio-krizYeriKonum-${item.id}`}
                      name={`krizYeriKonum-${item.id}`}
                      checked={selectedRadio === item.id}
                      style={{ marginTop: '0px', width: '20px', height: '20px' }}
                      onChange={() => {
                        handleChangeRadio(item.id);
                      }}
                    />
                    <Label check for="radio-krizYeriKonum" className="krizKonumChecked">
                      {item.adi}
                    </Label>
                  </FormGroup>
                ))}
            </FormGroup>
          </div>

          <hr style={{ marginBottom: '5px' }} />

          <Col md={12}>
            {selectedRadio === ReferansEnum.KrizYeri.Havalimani && (
              <>
                <FormGroup>
                  <Row>
                    <>
                      <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <h5>
                          <Translate contentKey="tk24AdysApp.krizDetay.havalimaniBilgileri">Airport Information</Translate>
                        </h5>
                        <Button
                          className="create-button my-3"
                          id="show-havalimani-modal"
                          data-cy="entityShowHavalimaniModal"
                          onClick={() => {
                            setShowSearchHavalimaniModal(true);
                          }}
                        >
                          <img width={14} height={14} src={`${SERVER_URL}/content/images/icon/faPlus.svg`} />
                          <span className="d-none d-md-inline">
                            <Translate contentKey="entity.action.addAirport">Add Airport</Translate>
                          </span>
                        </Button>
                      </div>

                      <div className="table-responsive">
                        <TableContainer className="content-table">
                          {updatedHavalimaniList && updatedHavalimaniList.length > 0 ? (
                            <Table aria-label="a dense table">
                              <TableHead className="content-table-title-area">
                                <TableRow className="table-head-row">
                                  <TableCell align="left" className="table-title-cell">
                                    <Translate contentKey="tk24AdysApp.krizHavalimani.koduIata">IATA Kodu</Translate>
                                  </TableCell>
                                  <TableCell align="left" className="table-title-cell">
                                    <Translate contentKey="tk24AdysApp.krizHavalimani.koduIcoa">ICOA Kodu</Translate>
                                  </TableCell>
                                  <TableCell align="left" className="table-title-cell">
                                    <Translate contentKey="tk24AdysApp.krizHavalimani.ulke">Ulke</Translate>
                                  </TableCell>
                                  <TableCell align="left" className="table-title-cell">
                                    <Translate contentKey="tk24AdysApp.krizHavalimani.sehir">Sehir</Translate>
                                  </TableCell>
                                  <TableCell align="left" className="table-title-cell">
                                    <Translate contentKey="tk24AdysApp.krizHavalimani.adi">Adi</Translate>
                                  </TableCell>
                                  <TableCell className="table-title-cell" align="right"></TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {updatedHavalimaniList.map(krizHavalimani => (
                                  <TableRow key={krizHavalimani.id} className="table-cell-row">
                                    <TableCell component="th" scope="row" className="table-content-cell">
                                      {krizHavalimani?.havalimani ? krizHavalimani?.havalimani?.koduIata : krizHavalimani?.koduIata}
                                    </TableCell>
                                    <TableCell component="th" scope="row" className="table-content-cell">
                                      {krizHavalimani?.havalimani ? krizHavalimani?.havalimani?.koduIcoa : krizHavalimani?.koduIcoa}
                                    </TableCell>
                                    <TableCell component="th" scope="row" className="table-content-cell">
                                      {krizHavalimani?.havalimani ? krizHavalimani?.havalimani?.refUlke?.adi : krizHavalimani?.refUlke?.adi}
                                    </TableCell>
                                    <TableCell component="th" scope="row" className="table-content-cell">
                                      {krizHavalimani?.havalimani ? krizHavalimani?.havalimani?.sehir : krizHavalimani?.sehir}
                                    </TableCell>
                                    <TableCell component="th" scope="row" className="table-content-cell">
                                      {krizHavalimani?.havalimani ? krizHavalimani?.havalimani?.adi : krizHavalimani?.adi}
                                    </TableCell>
                                    <TableCell align="right" className="table-content-cell">
                                      <div className="btn-group flex-btn-group-container">
                                        <Button
                                          type="button"
                                          className="delete-button"
                                          size="sm"
                                          data-cy="entityDeleteButton"
                                          onClick={() => {
                                            krizHavalimani?.havalimani
                                              ? confirmEntityDelete(krizHavalimani)
                                              : deleteKrizHavalimaniList(krizHavalimani.id);
                                          }}
                                          disabled={krizHavalimani?.havalimani && krizHavalimaniList?.length === 1 ? true : false}
                                        >
                                          <img width={11.2} height={12.8} src={`${SERVER_URL}/content/images/icon/faTrashCan.svg`} />
                                          <span className="d-none d-md-inline">
                                            <Translate contentKey="entity.action.delete">Delete</Translate>
                                          </span>
                                        </Button>
                                      </div>
                                    </TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          ) : (
                            <div className="alert alert-warning mt-3">
                              <Translate contentKey="tk24AdysApp.krizHavalimani.home.selectedHavalimaniNotFound">
                                No krizHavalimani found
                              </Translate>
                            </div>
                          )}
                        </TableContainer>
                      </div>
                    </>
                  </Row>
                </FormGroup>
              </>
            )}

            {selectedRadio === ReferansEnum.KrizYeri.Konum && (
              <>
                <div style={{ height: '280px', width: '100%' }}>
                  <MapComponent
                    handleSelection={handleSelectLocation}
                    initialLocation={kriz.krizYeriKonumX && kriz.krizYeriKonumY ? `${kriz.krizYeriKonumX},${kriz.krizYeriKonumY}` : null}
                  ></MapComponent>
                </div>
              </>
            )}
          </Col>
          <div style={{ position: 'sticky', top: 5, backgroundColor: 'white', marginTop: '20px' }}>
            <FormItem
              type="textarea"
              label={translate('tk24AdysApp.kriz.krizYeriAciklama')}
              name="krizYeriAciklama"
              id="krizYeriAciklama"
              defaultValue={kriz['krizYeriAciklama']}
              onChange={handleInputChange}
              validation={{ required: { value: selectedRadio === ReferansEnum.KrizYeri.Konum, message: 'Kriz yeri açıklama zorunludur.' } }}
            />
            <FormItem
              type="select"
              label={translate('tk24AdysApp.kriz.krizYeriSaatDilimi')}
              id="kriz-krizYeriSaatDilimi"
              name="krizYeriSaatDilimi"
              defaultValue={kriz['krizYeriSaatDilimi']}
              onChange={saatDilimiChange}
            >
              <option value="" key="0" />
              {renderTimezones()}
            </FormItem>
          </div>
        </ModalBody>
        <ModalFooter className="modal-footer">
          <Button className="cancel-button" onClick={handleCloseForm}>
            <Translate contentKey="entity.action.cancel">Cancel</Translate>
          </Button>
          &nbsp;
          <Button className="save-button" id="jhi-confirm-delete-refAdtForm" type="submit" data-cy="entityConfirmButton">
            <Translate contentKey="entity.action.save">Kaydet</Translate>
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default KrizHavalimaniUpdate;
