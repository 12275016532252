import React, { useState, useEffect } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col, UncontrolledTooltip } from 'reactstrap';
import { Translate, translate, ValidatedField, ValidatedForm } from 'app/component/jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getReferansByTip } from 'app/entities/referans/referans.reducer';

import { getEntities as getRefHavalimanis } from 'app/entities/ref-havalimani/ref-havalimani.reducer';
import { getEntity, updateEntity, createEntity, reset } from './ref-havalimani-irtibat.reducer';
import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

export const RefHavalimaniIrtibatUpdate = (props: RouteComponentProps<{ id: string }>) => {
  const dispatch = useAppDispatch();

  const [isNew] = useState(!props.match.params || !props.match.params.id);

  const refHavalimanis = useAppSelector(state => state.refHavalimani.entities);
  const referans = useAppSelector(state => state.referans.entities);
  const refHavalimaniIrtibatEntity = useAppSelector(state => state.refHavalimaniIrtibat.entity);
  const loading = useAppSelector(state => state.refHavalimaniIrtibat.loading);
  const updating = useAppSelector(state => state.refHavalimaniIrtibat.updating);
  const updateSuccess = useAppSelector(state => state.refHavalimaniIrtibat.updateSuccess);
  const havalimaniIrtibatTipi = useAppSelector(state => state.referans.entities);

  const handleClose = () => {
    props.history.push('/ref-havalimani-irtibat' + props.location.search);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(props.match.params.id));
    }
    dispatch(getReferansByTip('HAVALIMANI_IRTIBAT_TIPI'));
    dispatch(getRefHavalimanis({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    values.bilgiSonGuncellenmeZamani = convertDateTimeToServer(values.bilgiSonGuncellenmeZamani);
    values.cDate = convertDateTimeToServer(values.cDate);
    values.mDate = convertDateTimeToServer(values.mDate);

    const entity = {
      ...refHavalimaniIrtibatEntity,
      ...values,
      havalimani: refHavalimanis.find(it => it.id.toString() === values.havalimaniId.toString()),
      durum: referans.find(it => it.id.toString() === values.durumId.toString()),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {
          bilgiSonGuncellenmeZamani: displayDefaultDateTime(),
          cDate: displayDefaultDateTime(),
          mDate: displayDefaultDateTime(),
        }
      : {
          ...refHavalimaniIrtibatEntity,
          cDate: convertDateTimeFromServer(refHavalimaniIrtibatEntity.cDate),
          mDate: convertDateTimeFromServer(refHavalimaniIrtibatEntity.mDate),
          havalimaniId: refHavalimaniIrtibatEntity?.havalimani?.id,
          durumId: refHavalimaniIrtibatEntity?.durum?.id,
          havalimaniIrtibatTipi: refHavalimaniIrtibatEntity?.havalimaniIrtibatTipi?.adi,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="tk24AdysApp.refHavalimaniIrtibat.home.createOrEditLabel" data-cy="RefHavalimaniIrtibatCreateUpdateHeading">
            <Translate contentKey="tk24AdysApp.refHavalimaniIrtibat.home.createOrEditLabel">
              Create or edit a RefHavalimaniIrtibat
            </Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="ref-havalimani-irtibat-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <UncontrolledTooltip target="adiLabel">
                <Translate contentKey="tk24AdysApp.refHavalimaniIrtibat.help.havalimaniIrtibatTipi" />
              </UncontrolledTooltip>
              <ValidatedField
                label={translate('tk24AdysApp.refHavalimaniIrtibat.adi')}
                id="ref-havalimani-irtibat-adi"
                name="adi"
                data-cy="adi"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  maxLength: { value: 255, message: translate('entity.validation.maxlength', { max: 255 }) },
                }}
              />
              <UncontrolledTooltip target="adiLabel">
                <Translate contentKey="tk24AdysApp.refHavalimaniIrtibat.help.havalimaniIrtibatTipi" />
              </UncontrolledTooltip>
              <ValidatedField
                id="havalimaniIrtibatTipi"
                name="havalimaniIrtibatTipi"
                data-cy="havalimaniIrtibatTipi.adi"
                label={translate('tk24AdysApp.refHavalimaniIrtibat.havalimaniTipiIrtibatAdi')}
                type="select"
                required
              >
                <option value="" key="0" />
                {havalimaniIrtibatTipi
                  ? havalimaniIrtibatTipi.map(otherEntity => (
                      <option value={otherEntity.adi} key={otherEntity.id}>
                        {otherEntity.adi}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <UncontrolledTooltip target="adiLabel">
                <Translate contentKey="tk24AdysApp.refHavalimaniIrtibat.help.adi" />
              </UncontrolledTooltip>
              <ValidatedField
                label={translate('tk24AdysApp.refHavalimaniIrtibat.soyadi')}
                id="ref-havalimani-irtibat-soyadi"
                name="soyadi"
                data-cy="soyadi"
                type="text"
                validate={{
                  maxLength: { value: 255, message: translate('entity.validation.maxlength', { max: 255 }) },
                }}
              />
              <ValidatedField
                label={translate('tk24AdysApp.refHavalimaniIrtibat.eposta')}
                id="ref-havalimani-irtibat-eposta"
                name="eposta"
                data-cy="eposta"
                type="text"
                validate={{
                  maxLength: { value: 255, message: translate('entity.validation.maxlength', { max: 255 }) },
                }}
              />
              <ValidatedField
                label={translate('tk24AdysApp.refHavalimaniIrtibat.telefon')}
                id="ref-havalimani-irtibat-telefon"
                name="telefon"
                data-cy="telefon"
                type="text"
                validate={{
                  maxLength: { value: 255, message: translate('entity.validation.maxlength', { max: 255 }) },
                }}
              />
              <ValidatedField
                label={translate('tk24AdysApp.refHavalimaniIrtibat.bilgiSonGuncellenmeZamani')}
                id="ref-havalimani-irtibat-bilgiSonGuncellenmeZamani"
                name="bilgiSonGuncellenmeZamani"
                data-cy="bilgiSonGuncellenmeZamani"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <UncontrolledTooltip target="bilgiSonGuncellenmeZamaniLabel">
                <Translate contentKey="tk24AdysApp.refHavalimaniIrtibat.help.bilgiSonGuncellenmeZamani" />
              </UncontrolledTooltip>
              <ValidatedField
                id="ref-havalimani-irtibat-havalimani"
                name="havalimaniId"
                data-cy="havalimani"
                label={translate('tk24AdysApp.refHavalimaniIrtibat.havalimani')}
                type="select"
                required
              >
                <option value="" key="0" />
                {refHavalimanis
                  ? refHavalimanis.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.adi}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/ref-havalimani-irtibat" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default RefHavalimaniIrtibatUpdate;
