import React, { useEffect, useState } from 'react';
import { Button } from 'reactstrap';
import { TextFormat, Translate } from 'app/component/jhipster';
import { APP_DATE_FORMAT, SERVER_URL } from 'app/config/constants';
import { IKrizUcus } from 'app/shared/model/kriz-ucus.model';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import axios from 'axios';
import { RefEkDosyaTipiCodeEnum } from 'app/constants/RefEkDosyaTipiCodeEnum';
import { getEntities as getRefEkDosyaTipis } from 'app/entities/ref-ek-dosya-tipi/ref-ek-dosya-tipi.reducer';
import { getFilteredEntities as getFilterKrizUcusEkDosyaEntities, reset } from 'app/entities/ek-dosya/ek-dosya.reducer';
import TableNameEnum from 'app/constants/TableNameEnum';

interface KrizUcusLoadsheetModalProp {
  krizUcus: IKrizUcus;
  krizUcusEkDosyaLoading?: boolean;
  selectedTab: number;
}

export const KrizUcusLoadsheet: React.FC<KrizUcusLoadsheetModalProp> = ({ krizUcus, krizUcusEkDosyaLoading, selectedTab }) => {
  const ucus = krizUcus?.ucus;
  const dispatch = useAppDispatch();

  const refEkDosyaTipis = useAppSelector(state => state.refEkDosyaTipi.entities);
  const krizUcusEkDosyaList = useAppSelector(state => state.ekDosya.entities);
  const loading = useAppSelector(state => state.ekDosya.loading);
  const [ekDosyaTipiList, setEkDosyaTipiList] = useState([]);

  useEffect(() => {
    dispatch(reset());
    dispatch(getRefEkDosyaTipis({ sort: 'asc', page: 0, size: 2000 }));
  }, []);

  useEffect(() => {
    const filteredItems = refEkDosyaTipis.filter(
      res => res && (res.code === RefEkDosyaTipiCodeEnum.YolcuUcusLoadsheet || res.code === RefEkDosyaTipiCodeEnum.KargoLoadsheet)
    );
    const filteredItemIds = filteredItems.map(item => item.id);
    setEkDosyaTipiList(filteredItemIds);
  }, [refEkDosyaTipis]);

  useEffect(() => {
    if (selectedTab === 2) {
      dispatch(
        getFilterKrizUcusEkDosyaEntities({
          ilgiliTabloKayitId: ucus?.realId,
          ilgiliTablo: TableNameEnum.RefUcus,
          ekDosyaTipis: ekDosyaTipiList,
        })
      );
    }
  }, [selectedTab]);

  const handleDownloadSavedFile = async file => {
    try {
      const response = await axios.get(`api/ek-dosyas/download/${file.id}`, {
        responseType: 'blob',
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${file.dosyaAdi}`);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error('Dosya indirme hatası:', error);
    }
  };

  return (
    <>
      <div className="table-responsive" style={{ marginTop: 5 }}>
        <TableContainer className="content-table">
          {krizUcusEkDosyaList && krizUcusEkDosyaList.length > 0 ? (
            <Table className="accordion-table" aria-label="collapsible table">
              <TableHead>
                <TableRow className="accordion-table-title-row">
                  <TableCell className="accordion-table-title-cell" align="left">
                    <Translate contentKey="tk24AdysApp.ekDosya.dosyaAdi">dosyaAdi</Translate>
                  </TableCell>
                  <TableCell className="accordion-table-title-cell" align="left">
                    <Translate contentKey="tk24AdysApp.ekDosya.ekDosyaTipiTitle">ekDosyaTipi</Translate>
                  </TableCell>
                  <TableCell className="accordion-table-title-cell" align="left">
                    <Translate contentKey="tk24AdysApp.ekDosya.aciklamaTitle">aciklama</Translate>
                  </TableCell>
                  <TableCell className="accordion-table-title-cell" align="left">
                    <Translate contentKey="tk24AdysApp.ekDosya.fileLanguageTitle">dil</Translate>
                  </TableCell>
                  <TableCell className="accordion-table-title-cell" align="left">
                    <Translate contentKey="tk24AdysApp.ekDosya.fileExtentionTitle">fileExtention</Translate>
                  </TableCell>
                  <TableCell className="accordion-table-title-cell" align="left">
                    <Translate contentKey="tk24AdysApp.ekDosya.ekleyenKullanici">ekleyenKullanici</Translate>
                  </TableCell>
                  <TableCell className="accordion-table-title-cell" align="left">
                    <Translate contentKey="tk24AdysApp.ekDosya.cDateTitle">CDate</Translate>
                  </TableCell>
                  <TableCell className="accordion-table-title-cell" align="right"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {krizUcusEkDosyaList.map(ekDosya => (
                  <TableRow key={ekDosya.id} className="table-cell-row">
                    <TableCell className="accordion-table-content-cell" component="th" scope="row">
                      {ekDosya?.dosyaAdi}
                    </TableCell>
                    <TableCell className="accordion-table-content-cell" component="th" scope="row">
                      {ekDosya?.ekDosyaTipi?.adi}
                    </TableCell>
                    <TableCell className="accordion-table-content-cell" component="th" scope="row">
                      {ekDosya?.aciklama}
                    </TableCell>
                    <TableCell className="accordion-table-content-cell" component="th" scope="row">
                      {ekDosya?.dil?.adi}
                    </TableCell>
                    <TableCell className="accordion-table-content-cell" component="th" scope="row">
                      {ekDosya?.fileExtention}
                    </TableCell>
                    <TableCell className="accordion-table-content-cell" component="th" scope="row">
                      {ekDosya?.ekleyenKullanici?.adi} {ekDosya?.ekleyenKullanici?.soyadi}
                    </TableCell>
                    <TableCell className="accordion-table-content-cell" component="th" scope="row">
                      <TextFormat type="date" value={ekDosya.cDate} format={APP_DATE_FORMAT} />
                    </TableCell>
                    <TableCell className="accordion-table-content-cell" align="right">
                      <div className="btn-group flex-btn-group-container">
                        <Button className="download-file-button" style={{ marginLeft: 5 }} onClick={() => handleDownloadSavedFile(ekDosya)}>
                          <img src={`${SERVER_URL}/content/images/icon/faArrowDownToLine.svg`} />
                          <Translate contentKey="entity.action.download">İndir</Translate>
                        </Button>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : (
            !krizUcusEkDosyaLoading &&
            !loading && (
              <div className="alert alert-warning m-2">
                <Translate contentKey="tk24AdysApp.ekDosya.home.notFound">No Ek Dosya found</Translate>
              </div>
            )
          )}
        </TableContainer>
      </div>
    </>
  );
};

export default KrizUcusLoadsheet;
