import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import KrizUcak from './kriz-ucak';
import KrizUcakDetail from './kriz-ucak-detail';
import KrizUcakUpdate from './kriz-ucak-update';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={KrizUcakUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={KrizUcakUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={KrizUcakDetail} />
      <ErrorBoundaryRoute path={match.url} component={KrizUcak} />
    </Switch>
  </>
);

export default Routes;
