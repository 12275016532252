import { hasAnyAuthority, useAppDispatch, useAppSelector } from 'app/config/store';
import React, { useEffect, useState } from 'react';
import { TextFormat, Translate, translate } from 'app/component/jhipster';
import { Col, FormGroup, Input, Label, Row } from 'reactstrap';
import { getEntities as getDDMSDokumanEntities } from '../ref-ddms-dokuman/ref-ddms-dokuman.reducer';
import { RouteComponentProps } from 'react-router-dom';
import { ASC, DESC } from 'app/shared/util/pagination.constants';
import { getEntities as getKatilimciDDMSDokumanEntities } from '../ref-katilimci-ddms-dokuman/ref-katilimci-ddms-dokuman.reducer';
import { LoadingSpinner } from 'app/component/LoadingSpinner';
import axios from 'axios';
import { IQueryParams } from 'app/shared/reducers/reducer.utils';
import CustomPagination from 'app/component/CustomPagination';
import {
  Box,
  Collapse,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { APP_DATE_FORMAT, SERVER_URL } from 'app/config/constants';
import GrantedButton from 'app/component/GrantedButton';
import { localeLanguage, upperOnlyFirstLetter } from 'app/shared/util/util';
import { KeyboardArrowDownOutlined, KeyboardArrowRight } from '@mui/icons-material';
import { IRefDDMSDokuman } from 'app/shared/model/ref-ddms-dokuman.model';
import { getFilteredEntities, reset } from '../ek-dosya/ek-dosya.reducer';
import TableNameEnum from 'app/constants/TableNameEnum';
import SortIcon from 'app/component/SortIcon';
import ExcelDownloadButton from 'app/component/ExcelDownload';
import { IEkDosya } from 'app/shared/model/ek-dosya.model';
import { IRefKatilimciDdmsDokuman } from 'app/shared/model/ref-katilimci-ddms-dokuman.model';
import { customFilterValues } from 'app/shared/util/get-custom-filter';
import { MenuProps } from 'app/shared/util/filter.constants';
import { isNullOrEmpty } from 'app/shared/util/confirm-utils';
import { getTotalEntities as getTotalKategoriEntities } from 'app/entities/ref-ddms-dokuman-kategori/ref-ddms-dokuman-kategori.reducer';

interface KrizMedyaDDMSProps extends RouteComponentProps<{ id: string }> {
  krizId: string;
}

const KrizMedyaDDMS = (props: KrizMedyaDDMSProps) => {
  const dispatch = useAppDispatch();

  const hasAuthDdmsDokumanlarimRead = hasAnyAuthority(['ddms_dokumanlarim_read']);

  const refKatilimciListLoading = useAppSelector(state => state.refKatilimci.loading);
  const [selectedOption, setSelectedOption] = useState(hasAuthDdmsDokumanlarimRead ? 'benim' : 'selectAll');
  const refDdmsDokumanList = useAppSelector(state => state.refDdmsDokuman.entities);
  const refDdmsDokumanListLoading = useAppSelector(state => state.refDdmsDokuman.loading);
  const refKatilimciDdmsDokumanList = useAppSelector(state => state.refKatilimciDdmsDokuman.entities);
  const refKatilimciDdmsDokumanListLoading = useAppSelector(state => state.refKatilimciDdmsDokuman.loading);
  const refDdmsDokumanLisTotalItems = useAppSelector(state => state.refDdmsDokuman.totalItems);
  const refKatilimciDdmsDokumanListTotalItems = useAppSelector(state => state.refKatilimciDdmsDokuman.totalItems);
  const krizKatilimci = useAppSelector(state => state.krizKatilimci.entity);
  const totalItem = selectedOption === 'selectAll' ? refDdmsDokumanLisTotalItems : refKatilimciDdmsDokumanListTotalItems;
  const katilimciEkDosyaLoading = useAppSelector(state => state.ekDosya.loading);
  const [ekDosyaLoading, setEkDosyaLoading] = useState(false);

  const kategoriList = useAppSelector(state => state.refDdmsDokumanKategori.totalEntities);
  const kategoriLoading = useAppSelector(state => state.refDdmsDokumanKategori.loading);

  const [searchMyDdms, setSearchMyDdms] = useState('');
  const [searchAllDdms, setSearchAllDdms] = useState('');
  const [selectedMyDdmsCategory, setSelectedMyDdmsCategory] = useState('');
  const [selectedAllDdmsCategory, setSelectedAllDdmsCategory] = useState('');

  const [openItemId, setOpenItemId] = useState<number | null>(null);
  const ddmsDokumanDosyaList = useAppSelector(state => state.ekDosya.entities);

  const pageObject = useAppSelector(state => state.paginate.pageObject);

  const [pageMyDdmsObject, setPageMyDdmsObject] = useState<IQueryParams>({
    ...pageObject,
    query: `katilimciId.equals=${krizKatilimci?.katilimci?.id}`,
  });
  const [pageAllDdmsObject, setPageAllDdmsObject] = useState<IQueryParams>(useAppSelector(state => state.paginate.pageObject));

  const [pageObjectRefEkDosya, setPageObjectRefEkDosya] = useState<IQueryParams>({
    ...useAppSelector(state => state.paginate.pageObject),
    size: 999,
  });
  const [refDdmsDokumans, setRefDdmsDokumans] = useState(null);
  const [refAllDdmsDokumans, setAllRefDdmsDokumans] = useState(null);
  const [isSorting, setIsSorting] = useState(false);

  const ExcelSampleMyDocumentsData = [
    { key: 'ddmsDokuman.refDdmsDokumanKategori.adi', titleContentKey: 'tk24AdysApp.refDdmsDokuman.kategori' },
    { key: 'ddmsDokuman.dokumanNo', titleContentKey: 'tk24AdysApp.refDdmsDokuman.dokumanNo' },
    { key: 'ddmsDokuman.revizeNo', titleContentKey: 'tk24AdysApp.refDdmsDokuman.revizeNo' },
    { key: 'ddmsDokuman.adi', titleContentKey: 'global.languageGlobal.adi' },
  ];

  const ExcelSampleAllDocumentsData = [
    { key: 'refDdmsDokumanKategori.adi', titleContentKey: 'tk24AdysApp.refDdmsDokuman.kategori' },
    { key: 'dokumanNo', titleContentKey: 'tk24AdysApp.refDdmsDokuman.dokumanNo' },
    { key: 'revizeNo', titleContentKey: 'tk24AdysApp.refDdmsDokuman.revizeNo' },
    { key: 'adi', titleContentKey: 'global.languageGlobal.adi' },
  ];

  const AccordionExcelSampleData = [
    { key: 'dosyaAdi', titleContentKey: 'tk24AdysApp.ekDosya.dosyaAdi' },
    { key: 'aciklama', titleContentKey: 'tk24AdysApp.ekDosya.aciklama' },
    { key: 'fileExtention', titleContentKey: 'tk24AdysApp.ekDosya.fileExtention' },
    { key: 'dil.adi', titleContentKey: 'tk24AdysApp.ekDosya.dil' },
    { key: 'ekleyenKullanici.adi', titleContentKey: 'tk24AdysApp.ekDosya.ekleyenKullaniciAdi' },
    { key: 'ekleyenKullanici.soyadi', titleContentKey: 'tk24AdysApp.ekDosya.ekleyenKullaniciSoyadi' },
    { key: 'cDate', format: 'date', titleContentKey: 'tk24AdysApp.ekDosya.cDate' },
  ];

  useEffect(() => {
    dispatch(getTotalKategoriEntities({ size: 999, page: 0, sort: `adi${upperOnlyFirstLetter(localeLanguage)},asc` }));
  }, []);

  const getMyDdmsDoc = () => {
    if (krizKatilimci) {
      dispatch(getKatilimciDDMSDokumanEntities({ ...pageMyDdmsObject }));
    }
  };

  const getAllDdmsDoc = () => {
    dispatch(getDDMSDokumanEntities({ ...pageAllDdmsObject }));
  };

  useEffect(() => {
    if (selectedOption === 'benim') {
      getMyDdmsDoc();
    }
  }, [selectedOption, pageMyDdmsObject.page, pageMyDdmsObject.query, pageMyDdmsObject.size, pageMyDdmsObject.sort]);

  useEffect(() => {
    if (selectedOption === 'selectAll') {
      getAllDdmsDoc();
    }
  }, [selectedOption, pageAllDdmsObject.page, pageAllDdmsObject.query, pageAllDdmsObject.size, pageAllDdmsObject.sort]);

  useEffect(() => {
    if (refDdmsDokumans) {
      getMyDdmsDokumanTable(refDdmsDokumans);
    } else if (refAllDdmsDokumans) {
      getAllDdmsDokumanTable(refAllDdmsDokumans);
    }
  }, [pageObjectRefEkDosya.page, pageObjectRefEkDosya.query, pageObjectRefEkDosya.size, pageObjectRefEkDosya.sort]);

  useEffect(() => {
    if (searchMyDdms.length > 2) {
      setPageMyDdmsObject(prevPageObject => ({
        ...prevPageObject,
        page: 0,
        query: prevPageObject.query + `&globalFilter=${searchMyDdms}`,
      }));
    } else if (searchMyDdms.length === 0) {
      const keyToDelete = `globalFilter`;
      const updatedParams = pageMyDdmsObject.query
        .split('&')
        .filter(param => {
          const [key, _] = param.split('=');
          return key !== keyToDelete;
        })
        .join('&');
      setPageMyDdmsObject({ ...pageMyDdmsObject, page: 0, query: updatedParams });
    }
  }, [searchMyDdms]);

  useEffect(() => {
    if (searchAllDdms.length > 2) {
      setPageAllDdmsObject(prevPageObject => ({
        ...prevPageObject,
        page: 0,
        query: prevPageObject.query + `&globalFilter=${searchAllDdms}`,
      }));
    } else if (searchAllDdms.length === 0) {
      const keyToDelete = `globalFilter`;
      const updatedParams = pageAllDdmsObject.query
        .split('&')
        .filter(param => {
          const [key, _] = param.split('=');
          return key !== keyToDelete;
        })
        .join('&');
      setPageAllDdmsObject({ ...pageAllDdmsObject, page: 0, query: updatedParams });
    }
  }, [searchAllDdms]);

  const sort = p => () => {
    if (selectedOption === 'benim') {
      const orderBy = pageMyDdmsObject.sort.split(',')[1];
      setPageMyDdmsObject({ ...pageMyDdmsObject, sort: `${p},${orderBy === ASC ? DESC : ASC}` });
    } else if (selectedOption === 'selectAll') {
      const orderBy = pageAllDdmsObject.sort.split(',')[1];
      setPageAllDdmsObject({ ...pageAllDdmsObject, sort: `${p},${orderBy === ASC ? DESC : ASC}` });
    }
  };

  const handlePageMyDdmsChange = (pageNumber, newItemsPerPage = pageMyDdmsObject.size) => {
    if (newItemsPerPage !== pageMyDdmsObject.size) {
      setPageMyDdmsObject({ ...pageMyDdmsObject, page: pageNumber - 1, size: newItemsPerPage });
    } else {
      setPageMyDdmsObject({ ...pageMyDdmsObject, page: pageNumber - 1 });
    }
  };

  const handlePageAllDdmsChange = (pageNumber, newItemsPerPage = pageAllDdmsObject.size) => {
    if (newItemsPerPage !== pageAllDdmsObject.size) {
      setPageAllDdmsObject({ ...pageAllDdmsObject, page: pageNumber - 1, size: newItemsPerPage });
    } else {
      setPageAllDdmsObject({ ...pageAllDdmsObject, page: pageNumber - 1 });
    }
  };

  const handleOptionChange = event => {
    setSelectedOption(event.target.value);
    setOpenItemId(null);
    dispatch(reset());
    setSearchMyDdms('');
    setSearchAllDdms('');
    setSelectedMyDdmsCategory('');
    setSelectedAllDdmsCategory('');
    setPageMyDdmsObject(prevPageObject => ({
      ...prevPageObject,
      query: `katilimciId.equals=${krizKatilimci?.katilimci?.id}`,
      page: 0,
    }));
    setPageAllDdmsObject(pageObject);
  };

  const handleDownloadSavedFile = async refEkDosya => {
    setEkDosyaLoading(true);

    try {
      const response = await axios.get(`api/ek-dosyas/download/${refEkDosya.id}`, {
        responseType: 'blob',
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${refEkDosya.dosyaAdi}`);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error('Dosya indirme hatası:', error);
    }
    setEkDosyaLoading(false);
  };

  const fetchTotalMyDdmsDocData = async (): Promise<IRefDDMSDokuman[]> => {
    try {
      if (krizKatilimci) {
        const response = (await dispatch(
          getKatilimciDDMSDokumanEntities({
            ...pageMyDdmsObject,
            query: `katilimciId.equals=${krizKatilimci?.katilimci?.id}`,
            size: totalItem,
          })
        )) as { payload: { data: IRefDDMSDokuman[] } };

        return response.payload.data || [];
      }
      return [];
    } catch (error) {
      console.error('Error fetching data:', error);
      return [];
    }
  };

  const fetchTotalAllDdmsDocData = async (): Promise<IRefDDMSDokuman[]> => {
    try {
      const response = (await dispatch(getDDMSDokumanEntities({ ...pageAllDdmsObject, size: totalItem }))) as {
        payload: { data: IRefDDMSDokuman[] };
      };

      return response.payload.data || [];
    } catch (error) {
      console.error('Error fetching data:', error);
      return [];
    }
  };

  const fetchTotalDdmsDokumanData = (): readonly IEkDosya[] => {
    try {
      return ddmsDokumanDosyaList || [];
    } catch (error) {
      console.error('Error fetching data:', error);
      return [];
    }
  };

  const handleMyDdmsSearchChange = (elem, event) => {
    if (!isNullOrEmpty(event.target.value)) {
      setPageMyDdmsObject(prevPageObject => ({
        ...prevPageObject,
        page: 0,
        query: prevPageObject.query + `&${elem}.equals=${event.target.value}`,
      }));
    } else {
      const keyToDelete = `${elem}.equals`;
      const updatedParams = pageMyDdmsObject.query
        .split('&')
        .filter(param => {
          const [key, _] = param.split('=');
          return key !== keyToDelete;
        })
        .join('&');
      setPageMyDdmsObject({ ...pageMyDdmsObject, page: 0, query: updatedParams });
    }
  };

  const handleAllDdmsSearchChange = (elem, event) => {
    if (!isNullOrEmpty(event.target.value)) {
      setPageAllDdmsObject(prevPageObject => ({
        ...prevPageObject,
        page: 0,
        query: prevPageObject.query + `&${elem}.equals=${event.target.value}`,
      }));
    } else {
      const keyToDelete = `${elem}.equals`;
      const updatedParams = pageAllDdmsObject.query
        .split('&')
        .filter(param => {
          const [key, _] = param.split('=');
          return key !== keyToDelete;
        })
        .join('&');
      setPageAllDdmsObject({ ...pageAllDdmsObject, page: 0, query: updatedParams });
    }
  };

  const Filter = () => {
    return (
      <Row style={{ marginLeft: '0px' }}>
        <div style={{ display: 'flex', gap: '18px' }} className="mb-2">
          {hasAuthDdmsDokumanlarimRead && (
            <div style={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
              <FormGroup check className="inputArea ml-2">
                <Label check>
                  <Input type="radio" name="benim" value="benim" checked={selectedOption === 'benim'} onChange={handleOptionChange} />{' '}
                  <span>
                    <Translate contentKey="tk24AdysApp.krizMedya.buttons.benim" />
                  </span>
                </Label>
              </FormGroup>
            </div>
          )}

          <div style={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
            <FormGroup check className="inputArea">
              <Label check>
                <Input
                  type="radio"
                  name="Select All"
                  value="selectAll"
                  checked={selectedOption === 'selectAll'}
                  onChange={handleOptionChange}
                />{' '}
                <span>
                  <Translate contentKey="tk24AdysApp.krizMedya.buttons.selectAll" />
                </span>
              </Label>
            </FormGroup>
          </div>
          <hr />
        </div>
      </Row>
    );
  };

  const sortAcordion = p => () => {
    const orderBy = pageObjectRefEkDosya.sort.split(',')[1];
    const newSortOrder = orderBy === ASC ? DESC : ASC;
    setPageObjectRefEkDosya({ ...pageObjectRefEkDosya, sort: `${p},${newSortOrder}` });
    setIsSorting(true);
  };

  const getMyDdmsDokumanTable = (refDdmsDokuman: IRefKatilimciDdmsDokuman) => {
    dispatch(reset());
    if (isSorting) {
      setRefDdmsDokumans(refDdmsDokuman);
      dispatch(
        getFilteredEntities({
          ...pageObjectRefEkDosya,
          ilgiliTablo: TableNameEnum.RefDdmsDokuman,
          ilgiliTabloKayitId: refDdmsDokuman.ddmsDokuman.realId,
        })
      );
      setIsSorting(false);
    } else {
      if (openItemId === refDdmsDokuman?.id) {
        setOpenItemId(null);
        setRefDdmsDokumans(null);
      } else {
        setOpenItemId(refDdmsDokuman.id);
        setRefDdmsDokumans(refDdmsDokuman);
        dispatch(
          getFilteredEntities({
            ...pageObjectRefEkDosya,
            ilgiliTablo: TableNameEnum.RefDdmsDokuman,
            ilgiliTabloKayitId: refDdmsDokuman.ddmsDokuman.realId,
          })
        );
      }
    }
  };

  function MyDdmsDokumanTable({ refDdmsDokuman, isOpen, openTable }) {
    return (
      <>
        <TableRow className="accordion-content-row" sx={{ '& > *': { borderBottom: 'unset' } }} onClick={openTable}>
          <TableCell style={{ width: 48 }}>
            <IconButton aria-label="expand row" size="small" className="accordion-icon">
              {isOpen ? <KeyboardArrowDownOutlined /> : <KeyboardArrowRight />}
            </IconButton>
          </TableCell>
          <TableCell className="table-content-cell" component="th" scope="row">
            {refDdmsDokuman.ddmsDokuman?.refDdmsDokumanKategori?.adi}
          </TableCell>
          <TableCell className="table-content-cell" component="th" scope="row">
            {refDdmsDokuman.ddmsDokuman.dokumanNo}
          </TableCell>
          <TableCell className="table-content-cell" component="th" scope="row">
            {refDdmsDokuman.ddmsDokuman.revizeNo}
          </TableCell>
          <TableCell className="table-content-cell" component="th" scope="row">
            {refDdmsDokuman.ddmsDokuman.adi}
          </TableCell>
        </TableRow>
        <TableRow style={{ backgroundColor: '#F2F3F5' }}>
          <TableCell className="accordion-content-area" colSpan={12}>
            <Collapse in={isOpen} unmountOnExit>
              <Box className="accordion-box">
                <Typography className="accordion-typography mt-3" gutterBottom component="div">
                  <Translate contentKey="tk24AdysApp.ekDosya.home.title">ekDosya</Translate>
                </Typography>
                {ddmsDokumanDosyaList?.length > 0 ? (
                  <>
                    <Typography className="item-total-count" gutterBottom component="div">
                      <span className="totalListItemText">
                        <Translate
                          contentKey="global.item-total-count"
                          interpolate={{
                            total: ddmsDokumanDosyaList?.length,
                          }}
                        />
                      </span>
                      <ExcelDownloadButton
                        excelSampleData={AccordionExcelSampleData}
                        grantedAuthorites={['ek_dosya_export']}
                        fetchData={fetchTotalDdmsDokumanData}
                        className={'download-button'}
                        exportName={translate('tk24AdysApp.krizMedya.dokumanlarDdmsEkDosya')}
                      />
                    </Typography>
                    <Table className="accordion-table">
                      <TableHead>
                        <TableRow className="accordion-table-title-row">
                          <TableCell className="accordion-table-title-cell" align="left" onClick={sortAcordion('dosyaAdi')}>
                            <div className="tableHeaderContainer">
                              <Translate contentKey="tk24AdysApp.ekDosya.dosyaAdi">dosyaAdi</Translate>&nbsp;
                              <SortIcon column="dosyaAdi" activeSortColumn={pageObjectRefEkDosya.sort} />
                            </div>
                          </TableCell>
                          <TableCell className="accordion-table-title-cell" align="left" onClick={sortAcordion('aciklama')}>
                            <div className="tableHeaderContainer">
                              <Translate contentKey="tk24AdysApp.ekDosya.aciklamaTitle">aciklama</Translate>&nbsp;
                              <SortIcon column="aciklama" activeSortColumn={pageObjectRefEkDosya.sort} />
                            </div>
                          </TableCell>
                          <TableCell className="accordion-table-title-cell" align="left" onClick={sortAcordion('fileExtention')}>
                            <div className="tableHeaderContainer">
                              <Translate contentKey="tk24AdysApp.ekDosya.fileExtentionTitle">fileExtention</Translate>&nbsp;
                              <SortIcon column="fileExtention" activeSortColumn={pageObjectRefEkDosya.sort} />
                            </div>
                          </TableCell>
                          <TableCell
                            className="accordion-table-title-cell"
                            align="left"
                            onClick={sortAcordion(`dil.adi${upperOnlyFirstLetter(localeLanguage)}`)}
                          >
                            <div className="tableHeaderContainer">
                              <Translate contentKey="tk24AdysApp.ekDosya.dil">dil</Translate>&nbsp;
                              <SortIcon
                                column={`dil.adi${upperOnlyFirstLetter(localeLanguage)}`}
                                activeSortColumn={pageObjectRefEkDosya.sort}
                              />
                            </div>
                          </TableCell>
                          <TableCell className="accordion-table-title-cell" align="left" onClick={sortAcordion(`ekleyenKullanici.adi`)}>
                            <div className="tableHeaderContainer">
                              <Translate contentKey="tk24AdysApp.ekDosya.ekleyenKullanici">Kullanici</Translate>&nbsp;
                              <SortIcon column={`ekleyenKullanici.adi`} activeSortColumn={pageObjectRefEkDosya.sort} />
                            </div>
                          </TableCell>
                          <TableCell className="accordion-table-title-cell" align="left" onClick={sortAcordion(`cDate`)}>
                            <div className="tableHeaderContainer">
                              <Translate contentKey="tk24AdysApp.ekDosya.cDateTitle">cDate</Translate>&nbsp;
                              <SortIcon column={`cDate`} activeSortColumn={pageObjectRefEkDosya.sort} />
                            </div>
                          </TableCell>
                          <TableCell className="accordion-table-title-cell" />
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {ddmsDokumanDosyaList.map(item => (
                          <TableRow key={item?.id} className="accordion-table-content-row">
                            <TableCell className="accordion-table-content-cell" component="th" scope="row">
                              {item?.dosyaAdi}
                            </TableCell>
                            <TableCell className="accordion-table-content-cell" component="th" scope="row">
                              {item?.aciklama}
                            </TableCell>
                            <TableCell className="accordion-table-content-cell" component="th" scope="row">
                              {item?.fileExtention}
                            </TableCell>
                            <TableCell className="accordion-table-content-cell" component="th" scope="row">
                              {item?.dil?.adi}
                            </TableCell>
                            <TableCell className="accordion-table-content-cell" component="th" scope="row">
                              {item?.ekleyenKullanici?.adi} {item?.ekleyenKullanici?.soyadi}
                            </TableCell>
                            <TableCell className="accordion-table-content-cell" component="th" scope="row">
                              <TextFormat type="date" value={item.cDate} format={APP_DATE_FORMAT} />
                            </TableCell>
                            <TableCell align="right" className="accordion-table-content-cell">
                              <div className="btn-group flex-btn-group-container">
                                <GrantedButton
                                  grantedAuthorites={['ref_ek_dosya_tipi_read']}
                                  style={{ marginLeft: 2 }}
                                  onClick={() => handleDownloadSavedFile(item)}
                                  className="edit-button"
                                  size="sm"
                                >
                                  <img src={`${SERVER_URL}/content/images/icon/faArrowDownToLine.svg`} />
                                  <span className="d-none d-md-inline">
                                    <Translate contentKey="entity.action.download" />
                                  </span>
                                </GrantedButton>
                              </div>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </>
                ) : (
                  <div>
                    {!ekDosyaLoading && !katilimciEkDosyaLoading && (
                      <div className="alert alert-warning">
                        <Translate contentKey="tk24AdysApp.ekDosya.home.notFound">No ekDosya found</Translate>
                      </div>
                    )}
                  </div>
                )}
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </>
    );
  }

  const getAllDdmsDokumanTable = (refDdmsDokuman: IRefDDMSDokuman) => {
    dispatch(reset());
    if (isSorting) {
      setAllRefDdmsDokumans(refDdmsDokuman);
      dispatch(
        getFilteredEntities({
          ...pageObjectRefEkDosya,
          ilgiliTablo: TableNameEnum.RefDdmsDokuman,
          ilgiliTabloKayitId: refDdmsDokuman.realId,
        })
      );
      setIsSorting(false);
    } else {
      if (openItemId === refDdmsDokuman?.id) {
        setOpenItemId(null);
        setAllRefDdmsDokumans(null);
      } else {
        setOpenItemId(refDdmsDokuman.id);
        setAllRefDdmsDokumans(refDdmsDokuman);
        dispatch(
          getFilteredEntities({
            ...pageObjectRefEkDosya,
            ilgiliTablo: TableNameEnum.RefDdmsDokuman,
            ilgiliTabloKayitId: refDdmsDokuman.realId,
          })
        );
      }
    }
  };

  function AllDdmsDokumanTable({ refDdmsDokuman, isOpen, openTable }) {
    return (
      <>
        <TableRow className="accordion-content-row" sx={{ '& > *': { borderBottom: 'unset' } }} onClick={openTable}>
          <TableCell style={{ width: 48 }}>
            <IconButton aria-label="expand row" size="small" className="accordion-icon">
              {isOpen ? <KeyboardArrowDownOutlined /> : <KeyboardArrowRight />}
            </IconButton>
          </TableCell>
          <TableCell className="table-content-cell" component="th" scope="row">
            {refDdmsDokuman.refDdmsDokumanKategori?.adi}
          </TableCell>
          <TableCell className="table-content-cell" component="th" scope="row">
            {refDdmsDokuman.dokumanNo}
          </TableCell>
          <TableCell className="table-content-cell" component="th" scope="row">
            {refDdmsDokuman.revizeNo}
          </TableCell>
          <TableCell className="table-content-cell" component="th" scope="row">
            {refDdmsDokuman.adi}
          </TableCell>
        </TableRow>
        <TableRow style={{ backgroundColor: '#F2F3F5' }}>
          <TableCell className="accordion-content-area" colSpan={12}>
            <Collapse in={isOpen} unmountOnExit>
              <Box className="accordion-box">
                <Typography className="accordion-typography mt-3" gutterBottom component="div">
                  <Translate contentKey="tk24AdysApp.ekDosya.home.title">ekDosya</Translate>
                </Typography>
                {ddmsDokumanDosyaList?.length > 0 ? (
                  <>
                    <Typography className="item-total-count" gutterBottom component="div">
                      <span className="totalListItemText">
                        <Translate
                          contentKey="global.item-total-count"
                          interpolate={{
                            total: ddmsDokumanDosyaList?.length,
                          }}
                        />
                      </span>
                      <ExcelDownloadButton
                        excelSampleData={AccordionExcelSampleData}
                        grantedAuthorites={['ek_dosya_export']}
                        fetchData={fetchTotalDdmsDokumanData}
                        className={'download-button'}
                        exportName={translate('tk24AdysApp.krizMedya.dokumanlarDdmsEkDosya')}
                      />
                    </Typography>
                    <Table className="accordion-table">
                      <TableHead>
                        <TableRow className="accordion-table-title-row">
                          <TableCell className="accordion-table-title-cell" align="left" onClick={sortAcordion('dosyaAdi')}>
                            <div className="tableHeaderContainer">
                              <Translate contentKey="tk24AdysApp.ekDosya.dosyaAdi">dosyaAdi</Translate>&nbsp;
                              <SortIcon column="dosyaAdi" activeSortColumn={pageObjectRefEkDosya.sort} />
                            </div>
                          </TableCell>
                          <TableCell className="accordion-table-title-cell" align="left" onClick={sortAcordion('aciklama')}>
                            <div className="tableHeaderContainer">
                              <Translate contentKey="tk24AdysApp.ekDosya.aciklamaTitle">aciklama</Translate>&nbsp;
                              <SortIcon column="aciklama" activeSortColumn={pageObjectRefEkDosya.sort} />
                            </div>
                          </TableCell>
                          <TableCell className="accordion-table-title-cell" align="left" onClick={sortAcordion('fileExtention')}>
                            <div className="tableHeaderContainer">
                              <Translate contentKey="tk24AdysApp.ekDosya.fileExtentionTitle">fileExtention</Translate>&nbsp;
                              <SortIcon column="fileExtention" activeSortColumn={pageObjectRefEkDosya.sort} />
                            </div>
                          </TableCell>
                          <TableCell
                            className="accordion-table-title-cell"
                            align="left"
                            onClick={sortAcordion(`dil.adi${upperOnlyFirstLetter(localeLanguage)}`)}
                          >
                            <div className="tableHeaderContainer">
                              <Translate contentKey="tk24AdysApp.ekDosya.dil">dosya dili</Translate>&nbsp;
                              <SortIcon
                                column={`dil.adi${upperOnlyFirstLetter(localeLanguage)}`}
                                activeSortColumn={pageObjectRefEkDosya.sort}
                              />
                            </div>
                          </TableCell>
                          <TableCell className="accordion-table-title-cell" align="left" onClick={sortAcordion(`ekleyenKullanici.adi`)}>
                            <div className="tableHeaderContainer">
                              <Translate contentKey="tk24AdysApp.ekDosya.ekleyenKullanici">Kullanici</Translate>&nbsp;
                              <SortIcon column={`ekleyenKullanici.adi`} activeSortColumn={pageObjectRefEkDosya.sort} />
                            </div>
                          </TableCell>
                          <TableCell className="accordion-table-title-cell" align="left" onClick={sortAcordion(`cDate`)}>
                            <div className="tableHeaderContainer">
                              <Translate contentKey="tk24AdysApp.ekDosya.cDateTitle">cDate</Translate>&nbsp;
                              <SortIcon column={`cDate`} activeSortColumn={pageObjectRefEkDosya.sort} />
                            </div>
                          </TableCell>
                          <TableCell className="accordion-table-title-cell" />
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {ddmsDokumanDosyaList?.map(item => (
                          <TableRow key={item?.id} className="accordion-table-content-row">
                            <TableCell className="accordion-table-content-cell" component="th" scope="row">
                              {item?.dosyaAdi}
                            </TableCell>
                            <TableCell className="accordion-table-content-cell" component="th" scope="row">
                              {item?.aciklama}
                            </TableCell>
                            <TableCell className="accordion-table-content-cell" component="th" scope="row">
                              {item?.fileExtention}
                            </TableCell>
                            <TableCell className="accordion-table-content-cell" component="th" scope="row">
                              {item?.dil?.adi}
                            </TableCell>
                            <TableCell className="accordion-table-content-cell" component="th" scope="row">
                              {item?.ekleyenKullanici?.adi} {item?.ekleyenKullanici?.soyadi}
                            </TableCell>
                            <TableCell className="accordion-table-content-cell" component="th" scope="row">
                              <TextFormat type="date" value={item.cDate} format={APP_DATE_FORMAT} />
                            </TableCell>
                            <TableCell align="right" className="accordion-table-content-cell">
                              <div className="btn-group flex-btn-group-container">
                                <GrantedButton
                                  grantedAuthorites={['ref_ek_dosya_tipi_read']}
                                  style={{ marginLeft: 2 }}
                                  onClick={() => handleDownloadSavedFile(item)}
                                  className="edit-button"
                                  size="sm"
                                >
                                  <img src={`${SERVER_URL}/content/images/icon/faArrowDownToLine.svg`} />
                                  <span className="d-none d-md-inline">
                                    <Translate contentKey="entity.action.download" />
                                  </span>
                                </GrantedButton>
                              </div>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </>
                ) : (
                  <div>
                    {!ekDosyaLoading && !katilimciEkDosyaLoading && (
                      <div className="alert alert-warning">
                        <Translate contentKey="tk24AdysApp.ekDosya.home.notFound">No ekDosya found</Translate>
                      </div>
                    )}
                  </div>
                )}
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </>
    );
  }

  return (
    <Row style={{ margin: 'auto', minWidth: '100%' }}>
      <Col style={{ margin: 'auto', minWidth: '100%' }}>
        {(refKatilimciListLoading ||
          refDdmsDokumanListLoading ||
          refKatilimciDdmsDokumanListLoading ||
          ekDosyaLoading ||
          katilimciEkDosyaLoading ||
          kategoriLoading) && <LoadingSpinner />}
        <Row>
          <Filter />
        </Row>
        <Row>
          {selectedOption === 'benim' ? (
            <>
              <div className="search-frame" style={{ width: '100%' }}>
                <div className="seachiconposition">
                  <img className="seachicon" width={24} height={24} src={`${SERVER_URL}/content/images/icon/faSearch.svg`} />
                  <Input
                    value={searchMyDdms}
                    onChange={e => setSearchMyDdms(e.target.value)}
                    placeholder={translate('entity.action.search')}
                    className="search-input"
                  />
                </div>
                <div className="d-flex justify-end">
                  <Box sx={{ minWidth: 220, maxWidth: 220, marginRight: '15px' }}>
                    <FormControl size={window.innerWidth < 1600 ? 'small' : 'medium'} fullWidth>
                      <InputLabel id="dokuman-turu-label" className="select-input">
                        <Translate contentKey="tk24AdysApp.refDdmsDokuman.kategori" />
                      </InputLabel>
                      <Select
                        value={selectedMyDdmsCategory}
                        className="select-input"
                        labelId="dokuman-turu-label"
                        id="dokuman-turu"
                        label={translate('tk24AdysApp.refDdmsDokuman.kategori')}
                        onChange={event => {
                          setSelectedMyDdmsCategory(event.target.value);
                          handleMyDdmsSearchChange('refDdmsDokumanCategoryId', event);
                        }}
                        MenuProps={MenuProps}
                      >
                        <MenuItem value="">
                          <Translate contentKey="entity.action.all" />
                        </MenuItem>
                        {kategoriList &&
                          kategoriList.map(item => (
                            <MenuItem key={item.id} value={item.id}>
                              {item.adi}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Box>
                  <ExcelDownloadButton
                    excelSampleData={ExcelSampleMyDocumentsData}
                    grantedAuthorites={['ref_ddms_dokuman_export']}
                    fetchData={fetchTotalMyDdmsDocData}
                    className={'download-button'}
                    exportName={`${translate('tk24AdysApp.krizMedya.header.ddms')} ${translate('tk24AdysApp.krizMedya.buttons.benim')}`}
                  />
                </div>
              </div>
              <TableContainer className="content-table">
                {refKatilimciDdmsDokumanList && refKatilimciDdmsDokumanList.length > 0 ? (
                  <Table aria-label="a dense table">
                    <TableHead>
                      <TableRow className="accordion-title-row">
                        <TableCell style={{ width: 48 }} />
                        <TableCell
                          className="accordion-title-cell hand"
                          align="left"
                          onClick={sort(`ddmsDokuman.refDdmsDokumanKategori.adi${upperOnlyFirstLetter(localeLanguage)}`)}
                        >
                          <div className="tableHeaderContainer">
                            <Translate contentKey="tk24AdysApp.refDdmsDokuman.kategori">Kategori</Translate>{' '}
                            <SortIcon
                              column={`ddmsDokuman.refDdmsDokumanKategori.adi${upperOnlyFirstLetter(localeLanguage)}`}
                              activeSortColumn={pageMyDdmsObject.sort}
                            />
                          </div>
                        </TableCell>
                        <TableCell className="accordion-title-cell hand" align="left" onClick={sort('ddmsDokuman.dokumanNo')}>
                          <div className="tableHeaderContainer">
                            <Translate contentKey="tk24AdysApp.refDdmsDokuman.dokumanNo">Dokuman No</Translate>{' '}
                            <SortIcon column="ddmsDokuman.dokumanNo" activeSortColumn={pageMyDdmsObject.sort} />
                          </div>
                        </TableCell>
                        <TableCell className="accordion-title-cell hand" align="left" onClick={sort('ddmsDokuman.revizeNo')}>
                          <div className="tableHeaderContainer">
                            <Translate contentKey="tk24AdysApp.refDdmsDokuman.revizeNoTitle">Revize No</Translate>{' '}
                            <SortIcon column="ddmsDokuman.revizeNo" activeSortColumn={pageMyDdmsObject.sort} />
                          </div>
                        </TableCell>
                        <TableCell
                          className="accordion-title-cell hand"
                          align="left"
                          onClick={sort(`ddmsDokuman.adi${upperOnlyFirstLetter(localeLanguage)}`)}
                        >
                          <div className="tableHeaderContainer">
                            <Translate contentKey="global.languageGlobal.adi">Adi Tr</Translate>{' '}
                            <SortIcon
                              column={`ddmsDokuman.adi${upperOnlyFirstLetter(localeLanguage)}`}
                              activeSortColumn={pageMyDdmsObject.sort}
                            />
                          </div>
                        </TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {refKatilimciDdmsDokumanList?.map(refDdmsDokuman => (
                        <MyDdmsDokumanTable
                          key={refDdmsDokuman?.id}
                          refDdmsDokuman={refDdmsDokuman}
                          isOpen={openItemId === refDdmsDokuman?.id}
                          openTable={() => getMyDdmsDokumanTable(refDdmsDokuman)}
                        />
                      ))}
                    </TableBody>
                  </Table>
                ) : (
                  !refKatilimciDdmsDokumanListLoading && (
                    <div className="alert alert-warning">
                      <Translate contentKey="tk24AdysApp.refKatilimciDdmsDokuman.home.notFound">
                        No Ref Katilimci DDMS Dokuman Tipis found
                      </Translate>
                    </div>
                  )
                )}
              </TableContainer>
            </>
          ) : (
            <>
              <div className="search-frame" style={{ width: '100%' }}>
                <div className="seachiconposition">
                  <img className="seachicon" width={24} height={24} src={`${SERVER_URL}/content/images/icon/faSearch.svg`} />
                  <Input
                    value={searchAllDdms}
                    onChange={e => setSearchAllDdms(e.target.value)}
                    placeholder={translate('entity.action.search')}
                    className="search-input"
                  />
                </div>
                <div className="d-flex justify-end">
                  <Box sx={{ minWidth: 220, maxWidth: 220, marginRight: '15px' }}>
                    <FormControl style={{ minHeight: 48, maxHeight: 48 }} fullWidth>
                      <InputLabel id="dokuman-turu-label">
                        <Translate contentKey="tk24AdysApp.refDdmsDokuman.kategori" />
                      </InputLabel>
                      <Select
                        value={selectedAllDdmsCategory}
                        style={{ minHeight: 48, maxHeight: 48, borderRadius: '6px' }}
                        labelId="dokuman-turu-label"
                        id="dokuman-turu"
                        label={translate('tk24AdysApp.refDdmsDokuman.kategori')}
                        onChange={event => {
                          setSelectedAllDdmsCategory(event.target.value);
                          handleAllDdmsSearchChange('refDdmsDokumanCategoryId', event);
                        }}
                        MenuProps={MenuProps}
                      >
                        <MenuItem value="">
                          <Translate contentKey="entity.action.all" />
                        </MenuItem>
                        {kategoriList &&
                          kategoriList.map(item => (
                            <MenuItem key={item.id} value={item.id}>
                              {item.adi}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Box>
                  <ExcelDownloadButton
                    excelSampleData={ExcelSampleAllDocumentsData}
                    grantedAuthorites={['ref_ddms_dokuman_export']}
                    fetchData={fetchTotalAllDdmsDocData}
                    className={'download-button'}
                    exportName={`${translate('tk24AdysApp.krizMedya.header.ddms')} ${translate('tk24AdysApp.krizMedya.buttons.selectAll')}`}
                  />
                </div>
              </div>
              <TableContainer className="content-table">
                {refDdmsDokumanList && refDdmsDokumanList.length > 0 ? (
                  <Table aria-label="a dense table">
                    <TableHead>
                      <TableRow className="accordion-title-row">
                        <TableCell style={{ width: 48 }} />
                        <TableCell
                          className="accordion-title-cell hand"
                          align="left"
                          onClick={sort(`refDdmsDokumanKategori.adi${upperOnlyFirstLetter(localeLanguage)}`)}
                        >
                          <div className="tableHeaderContainer">
                            <Translate contentKey="tk24AdysApp.refDdmsDokuman.kategori">Kategori</Translate>{' '}
                            <SortIcon
                              column={`refDdmsDokumanKategori.adi${upperOnlyFirstLetter(localeLanguage)}`}
                              activeSortColumn={pageAllDdmsObject.sort}
                            />
                          </div>
                        </TableCell>
                        <TableCell className="accordion-title-cell hand" align="left" onClick={sort('dokumanNo')}>
                          <div className="tableHeaderContainer">
                            <Translate contentKey="tk24AdysApp.refDdmsDokuman.dokumanNo">Dokuman No</Translate>{' '}
                            <SortIcon column="dokumanNo" activeSortColumn={pageAllDdmsObject.sort} />
                          </div>
                        </TableCell>
                        <TableCell className="accordion-title-cell hand" align="left" onClick={sort('revizeNo')}>
                          <div className="tableHeaderContainer">
                            <Translate contentKey="tk24AdysApp.refDdmsDokuman.revizeNoTitle">Revize No</Translate>{' '}
                            <SortIcon column="revizeNo" activeSortColumn={pageAllDdmsObject.sort} />
                          </div>
                        </TableCell>
                        <TableCell
                          className="accordion-title-cell hand"
                          align="left"
                          onClick={sort(`adi${upperOnlyFirstLetter(localeLanguage)}`)}
                        >
                          <div className="tableHeaderContainer">
                            <Translate contentKey="global.languageGlobal.adi">Adi</Translate>{' '}
                            <SortIcon column={`adi${upperOnlyFirstLetter(localeLanguage)}`} activeSortColumn={pageAllDdmsObject.sort} />
                          </div>
                        </TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {refDdmsDokumanList?.map(refDdmsDokuman => (
                        <AllDdmsDokumanTable
                          key={refDdmsDokuman?.id}
                          refDdmsDokuman={refDdmsDokuman}
                          isOpen={openItemId === refDdmsDokuman?.id}
                          openTable={() => getAllDdmsDokumanTable(refDdmsDokuman)}
                        />
                      ))}
                    </TableBody>
                  </Table>
                ) : (
                  !refDdmsDokumanListLoading && (
                    <div className="alert alert-warning">
                      <Translate contentKey="tk24AdysApp.refDdmsDokuman.home.notFound">No Ref DDMS Dokuman Tipis found</Translate>
                    </div>
                  )
                )}
              </TableContainer>
            </>
          )}
        </Row>

        {selectedOption === 'benim' && (
          <CustomPagination
            currentPage={pageMyDdmsObject.page + 1}
            currentPageSize={pageMyDdmsObject.size}
            totalItems={totalItem}
            handlePageChange={handlePageMyDdmsChange}
          />
        )}

        {selectedOption === 'selectAll' && (
          <CustomPagination
            currentPage={pageAllDdmsObject.page + 1}
            currentPageSize={pageAllDdmsObject.size}
            totalItems={totalItem}
            handlePageChange={handlePageAllDdmsChange}
          />
        )}
      </Col>
    </Row>
  );
};

export default KrizMedyaDDMS;
