import { IReferans } from 'app/shared/model/referans.model';
import { IQueryParams } from '../reducers/reducer.utils';
import { IBaseModel } from './base.model';

export interface IRefDisDokuman extends IBaseModel {
  realId?: number;
  cDate?: string;
  cUser?: number;
  mDate?: string;
  mUser?: number;
  durum?: IReferans;
}

export interface IRefDisDokumanParams extends IQueryParams {
  entity?: IRefDisDokuman;
}

export const defaultValue: Readonly<IRefDisDokuman> = {};
