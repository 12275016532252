import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending } from '@reduxjs/toolkit';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { IQueryParams, createEntitySlice, EntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { IRefDDMSDokuman, IRefDdmsDokumanParams, defaultValue } from 'app/shared/model/ref-ddms-dokuman.model';

const initialState: EntityState<IRefDDMSDokuman> = {
  loading: false,
  errorMessage: null,
  entities: [],
  totalEntities: [],
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

interface RefKatilimciDdmsDokumanParams extends IQueryParams {
  dokumanNo?: string;
}

const apiUrl = 'api/ref-ddms-dokumans';

// Actions

export const getEntities = createAsyncThunk(
  'refDdmsDokuman/fetch_entity_list',
  async ({ page, size, sort, query }: IRefDdmsDokumanParams) => {
    const queryParams = new URLSearchParams();
    if (sort) {
      queryParams.set('page', page.toString());
      queryParams.set('size', size.toString());
      queryParams.set('sort', sort);
    }

    if (query) {
      const parsedQueryParams = new URLSearchParams(query);
      parsedQueryParams.forEach((value, key) => {
        queryParams.set(key, value);
      });
    }
    queryParams.set('cacheBuster', new Date().getTime().toString());
    const queryString = queryParams.toString();
    const requestUrl = `${apiUrl}${queryString ? `?${queryString}` : ''}`;

    return axios.get<IRefDDMSDokuman[]>(requestUrl);
  }
);

export const getSearchEntities = createAsyncThunk(
  'refUcuseses/fetch_entity_list',
  async ({ dokumanNo, page, size, sort, query }: RefKatilimciDdmsDokumanParams) => {
    const queryParams = new URLSearchParams();
    if (sort) {
      queryParams.set('page', page.toString());
      queryParams.set('size', size.toString());
      queryParams.set('sort', sort);
    }
    if (dokumanNo) {
      queryParams.set('dokumanNo.contains', dokumanNo.toString());
    }
    if (query) {
      const parsedQueryParams = new URLSearchParams(query);
      parsedQueryParams.forEach((value, key) => {
        queryParams.set(key, value);
      });
    }
    queryParams.set('cacheBuster', new Date().getTime().toString());
    const queryString = queryParams.toString();
    const requestUrl = `${apiUrl}${queryString ? `?${queryString}` : ''}`;

    return axios.get<IRefDDMSDokuman[]>(requestUrl);
  }
);

export const getTotalEntities = createAsyncThunk(
  'refDdmsDokuman/fetch_entity_total_list',
  async ({ size, sort, query }: IRefDdmsDokumanParams) => {
    const queryParams = new URLSearchParams();
    if (sort) {
      queryParams.set('size', size.toString());
      queryParams.set('sort', sort);
    }
    if (query) {
      const parsedQueryParams = new URLSearchParams(query);
      parsedQueryParams.forEach((value, key) => {
        queryParams.set(key, value);
      });
    }
    queryParams.set('cacheBuster', new Date().getTime().toString());
    const queryString = queryParams.toString();
    const requestUrl = `${apiUrl}${queryString ? `?${queryString}` : ''}`;

    return axios.get<IRefDDMSDokuman[]>(requestUrl);
  }
);

export const getEntity = createAsyncThunk(
  'refDdmsDokuman/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<IRefDDMSDokuman>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const createEntity = createAsyncThunk(
  'refDdmsDokuman/create_entity',
  async (params: IRefDdmsDokumanParams, thunkAPI) => {
    const result = await axios.post<IRefDDMSDokuman>(apiUrl, cleanEntity(params.entity));
    thunkAPI.dispatch(getEntities(params));

    const entityWithId = {
      ...result.data,
      id: result.data.id,
    };

    return entityWithId;
  },
  { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
  'refDdmsDokuman/update_entity',
  async (params: IRefDdmsDokumanParams, thunkAPI) => {
    const result = await axios.put<IRefDDMSDokuman>(`${apiUrl}/${params.entity.id}`, cleanEntity(params.entity));
    thunkAPI.dispatch(getEntities(params));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const partialUpdateEntity = createAsyncThunk(
  'refDdmsDokuman/partial_update_entity',
  async (params: IRefDdmsDokumanParams, thunkAPI) => {
    const result = await axios.patch<IRefDDMSDokuman>(`${apiUrl}/${params.entity.id}`, cleanEntity(params.entity));
    thunkAPI.dispatch(getEntities(params));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const deleteEntity = createAsyncThunk(
  'refDdmsDokuman/delete_entity',
  async (params: IRefDdmsDokumanParams, thunkAPI) => {
    const requestUrl = `${apiUrl}/${params.entity.id}`;
    const result = await axios.delete<IRefDDMSDokuman>(requestUrl);
    thunkAPI.dispatch(getEntities(params));
    return result;
  },
  { serializeError: serializeAxiosError }
);

// slice

export const RefDdmsDokumanSlice = createEntitySlice({
  name: 'refDdmsDokuman',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(deleteEntity.fulfilled, state => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = {};
      })
      .addMatcher(isFulfilled(getEntities, getSearchEntities), (state, action) => {
        return {
          ...state,
          loading: false,
          entities: action.payload.data,
          totalItems: parseInt(action.payload.headers['x-total-count'], 10),
        };
      })
      .addMatcher(isFulfilled(getTotalEntities), (state, action) => {
        return {
          ...state,
          loading: false,
          totalEntities: action.payload.data,
          totalItems: parseInt(action.payload.headers['x-total-count'], 10),
        };
      })
      .addMatcher(isFulfilled(createEntity, updateEntity, partialUpdateEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;

        if ('data' in action.payload) {
          state.entity = action.payload.data;
          state.updateSuccess = true;
        } else {
          state.entity = action.payload;
        }
      })
      .addMatcher(isPending(getEntities, getTotalEntities, getSearchEntities, getEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(createEntity, updateEntity, partialUpdateEntity, deleteEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      });
  },
});

export const { reset } = RefDdmsDokumanSlice.actions;

// Reducer
export default RefDdmsDokumanSlice.reducer;
