import { IKriz } from 'app/shared/model/kriz.model';
import { IRefKatilimci } from 'app/shared/model/ref-katilimci.model';
import { IRefKatilimciVekil } from 'app/shared/model/ref-katilimci-vekil.model';
import { IReferans } from 'app/shared/model/referans.model';
import { IKrizCeride } from 'app/shared/model/kriz-ceride.model';
import { IKrizKatilimciBildirim } from 'app/shared/model/kriz-katilimci-bildirim.model';
import { IKrizKatilimciGorev } from 'app/shared/model/kriz-katilimci-gorev.model';
import { IQueryParams } from '../reducers/reducer.utils';
import { IRefOrganisazyon } from './ref-organizasyon.model';
import { IPersonel } from './personel.model';
import { IRefHavalimani } from './ref-havalimani.model';

export interface IKrizKatilimci {
  id?: number;
  realId?: number;
  guid?: string;
  unvanAdi?: string;
  unvanId?: string;
  sicil?: string | null;
  adi?: string | null;
  soyadi?: string | null;
  eposta?: string | null;
  gsmNo?: string | null;
  cDate?: string;
  cUser?: number;
  mDate?: string;
  mUser?: number;
  kriz?: IKriz;
  katilimci?: IRefKatilimci | null;
  vekilKatilimci?: IRefKatilimciVekil | null;
  toplantidaMi?: IReferans;
  katilimciRolu?: IReferans;
  cerideTutulacakMi?: IReferans;
  durum?: IReferans;
  krizCerides?: IKrizCeride[] | null;
  krizKatilimciBildirims?: IKrizKatilimciBildirim[] | null;
  krizKatilimciGorevs?: IKrizKatilimciGorev[] | null;
  bildirimGonderilsinMi?: IReferans;
  acikRizaOnayiYapildiMi?: IReferans;
  adtLiderOnayiYapildiMi?: IReferans;
  org?: IRefOrganisazyon;
  personel?: IPersonel;
  ivrBildirim?: any;
  sonLoginZamani?: string;
  istasyon?: IRefHavalimani;
}

export interface IKrizKatilimciParams extends IQueryParams {
  entity?: IKrizKatilimci;
  krizId?: number | string;
  katilimciRoluIds?: number[];
}

export interface IKrizKatilimcVekiliParams extends IQueryParams {
  entity?: IKrizKatilimci;
  krizId?: number | string;
  katilimciId?: number | string;
}

export const defaultValue: Readonly<IKrizKatilimci> = {};
