import { IReferans } from 'app/shared/model/referans.model';
import { IQueryParams } from '../reducers/reducer.utils';
import { IBaseModel } from './base.model';
import { IRefOrganisazyon } from './ref-organizasyon.model';
import { IPersonel } from './personel.model';
import { IRefKatilimciIletisim } from './ref-katilimci-iletisim.model';

export interface IRefKatilimci extends IBaseModel {
  adi?: string;
  bilgiSonGuncellenmeZamani?: string;
  katilimciRolu?: IReferans;
  org?: IRefOrganisazyon;
  sicil?: string;
  soyadi?: string;
  unvanAdi?: string;
  unvanId?: string;
  personel?: IPersonel;
  refKatilimciIletisimList?: IRefKatilimciIletisim[];
}

export interface IRefKatilimciParams extends IQueryParams {
  entity?: IRefKatilimci;
}

export const defaultValue: Readonly<IRefKatilimci> = {};
