import React, { useState, useEffect, useCallback } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Translate, translate } from 'app/component/jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAppDispatch, useAppSelector } from 'app/config/store';
import axios from 'axios';
import { CustomDeleteEntityConfirm, isNullOrEmpty } from 'app/shared/util/confirm-utils';
import { getEntities as getRefAdtEkips } from 'app/entities/ref-adt-ekip/ref-adt-ekip.reducer';

import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownOutlined from '@mui/icons-material/KeyboardArrowDownOutlined';
import {
  Box,
  Collapse,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Modal as MuiModal,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Tooltip,
} from '@mui/material';
import { modalStyle } from 'app/shared/util/style-utils';
import TransferList from 'app/component/TransferList';
import { toast } from 'react-toastify';
import { IReferans } from 'app/shared/model/referans.model';
import { IRefAdtEkip } from 'app/shared/model/ref-adt-ekip.model';
import { LoadingSpinner } from 'app/component/LoadingSpinner';
import GrantedButton from 'app/component/GrantedButton';
import { SERVER_URL } from 'app/config/constants';
import CustomPagination from 'app/component/CustomPagination';
import { IRefKrizTuruKatilimciGorev } from 'app/shared/model/ref-kriz-turu-katilimci-gorev.model';
import { IRefKrizTuru } from 'app/shared/model/ref-kriz-turu.model';
import { HasAuthComponent } from 'app/component/HasAuthComponent';
import { Button, Col, Form, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import RefKrizTuruKatilimciGorevDetail from './ref-kriz-turu-katilimci-gorev-detail';
import ExcelDownloadButton from 'app/component/ExcelDownload';
import { MenuProps } from 'app/shared/util/filter.constants';
import { localeLanguage, upperOnlyFirstLetter } from 'app/shared/util/util';

const RefKrizTuruKatilimciGorevAdt = (props: RouteComponentProps<{ url: string }>) => {
  const dispatch = useAppDispatch();

  const referans = useAppSelector(state => state.referans.entities);
  const acilDurumTuru = referans.filter(item => item.tipi === 'ACIL_DURUM_TURU');
  const refKrizTurus = useAppSelector(state => state.refKrizTuru.entities);
  const refAdtEkips = useAppSelector(state => state.refAdtEkip.entities);

  const [loading, setLoading] = useState(false);
  const refAdtEkipsLoading = useAppSelector(state => state.refAdtEkip.loading);
  const refAdtEkipsUpdating = useAppSelector(state => state.refAdtEkip.updating);
  const [isViewing, setIsViewing] = useState(null);

  const [krizTuruAdtEkipGorevs, setKrizTuruAdtEkipGorevs] = useState<IRefKrizTuruKatilimciGorev[]>([]);

  const [selectedAcilDurumTuru, setSelectedAcilDurumTuru] = useState<IReferans>({});
  const [selectedRefKrizTuru, setSelectedRefKrizTuru] = useState<IReferans>({});
  const [selectedAdtEkips, setSelectedAdtEkips] = useState<IRefAdtEkip>();
  const [selectedKrizTuruKatilimciGorev, setSelectedKrizTuruKatilimciGorev] = useState<IRefKrizTuruKatilimciGorev>();

  const [matrixData, setMatrixData] = useState([]);

  const [filterAcilDurumTuru, setFilterAcilDurumTuru] = useState<string>();
  const [filterKrizTuru, setFilterKrizTuru] = useState<string>();
  const [filterAdtTim, setFilterAdtTim] = useState<string>();

  const [isRefKrizTuruKatilimciGorevDetailModal, setIsRefKrizTuruKatilimciGorevDetailModal] = useState(false);
  const [isTransferListModal, setIsTransferListModal] = useState(false);
  const [adtEkipRefGorevs, setAdtEkipRefGorevs] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = matrixData.slice(indexOfFirstItem, indexOfLastItem);

  const [openItemId, setOpenItemId] = useState<string | null>(null);
  const [search, setSearch] = useState('');
  const [isCopyGorevModal, setIsCopyGorevModal] = useState(false);
  const [selectedAcilDurumTuruForCopy, setSelectedAcilDurumTuruForCopy] = useState<IReferans>();
  const [selectedKrizTuruForCopy, setSelectedKrizTuruForCopy] = useState<IRefKrizTuru>();
  const [selectedAdtEkipsForCopy, setSelectedAdtEkipsForCopy] = useState<IRefAdtEkip>();
  const [krizTuruAdtEkipGorevsForCopy, setKrizTuruAdtEkipGorevsForCopy] = useState<IRefKrizTuruKatilimciGorev[]>([]);

  const pageObject = {
    page: currentPage - 1,
    size: itemsPerPage,
    sort: `adi${upperOnlyFirstLetter(localeLanguage)},asc`,
    query: '',
  };

  const ExcelSampleData = [
    { key: 'acilDurumTuru.adi', titleContentKey: 'tk24AdysApp.refKrizTuruKatilimciGorev.acilDurumTuru' },
    { key: 'refKrizTurus.adi', titleContentKey: 'tk24AdysApp.refKrizTuruKatilimciGorev.krizTuru' },
    { key: 'refAdtEkips.adi', titleContentKey: 'tk24AdysApp.refKrizTuruKatilimci.adtTim' },
  ];

  const AccordionExcelSampleData = [
    { key: 'gorev.adiTr', titleContentKey: 'global.languageGlobal.adiTr' },
    { key: 'gorev.adiEn', titleContentKey: 'global.languageGlobal.adiEn' },
    { key: 'gorev.aciklamaTr', titleContentKey: 'global.languageGlobal.aciklamaTr' },
    { key: 'gorev.aciklamaEn', titleContentKey: 'global.languageGlobal.aciklamaEn' },
  ];

  const getAllEntities = () => {
    dispatch(getRefAdtEkips(pageObject));
  };

  useEffect(() => {
    getAllEntities();
  }, []);

  useEffect(() => {
    if (matrixData) {
      let acilDurumTuruByFilter = [...acilDurumTuru];
      let refKrizTurusByFilter = [...refKrizTurus];
      let refAdtEkipsByFilter = [...refAdtEkips];

      if (!isNullOrEmpty(filterAcilDurumTuru) && filterAcilDurumTuru !== '0') {
        acilDurumTuruByFilter = acilDurumTuruByFilter.filter(item => item.id.toString() === filterAcilDurumTuru);
      }

      if (!isNullOrEmpty(filterKrizTuru) && filterKrizTuru !== '0') {
        refKrizTurusByFilter = refKrizTurusByFilter.filter(item => item.id.toString() === filterKrizTuru);
      }

      if (!isNullOrEmpty(filterAdtTim) && filterAdtTim !== '0') {
        refAdtEkipsByFilter = refAdtEkipsByFilter.filter(item => item.id.toString() === filterAdtTim);
      }

      const mData = [];
      acilDurumTuruByFilter.forEach(item1 =>
        refKrizTurusByFilter.forEach(item2 =>
          refAdtEkipsByFilter.forEach(item3 => {
            const uniqueId = `item1-${item1.id}-item2-${item2.id}-item3-${item3.id}`;
            mData.push({
              id: uniqueId,
              acilDurumTuru: item1,
              refKrizTurus: item2,
              refAdtEkips: item3,
            });
          })
        )
      );
      setCurrentPage(1);
      setMatrixData(mData);
    }
  }, [filterAcilDurumTuru, filterKrizTuru, filterAdtTim, referans, refKrizTurus, refAdtEkips]);

  useEffect(() => {
    if (openItemId) {
      if (search.length > 2) {
        getKrizTuruAdtEkipGorevs(selectedAcilDurumTuru.id, selectedRefKrizTuru.id, selectedAdtEkips.id, search);
      } else if (search.length === 0) {
        getKrizTuruAdtEkipGorevs(selectedAcilDurumTuru.id, selectedRefKrizTuru.id, selectedAdtEkips.id);
      }
    }
  }, [search, openItemId]);

  const handlePageChange = (pageNumber, newItemsPerPage = itemsPerPage) => {
    setOpenItemId(null);
    setCurrentPage(pageNumber);
    if (newItemsPerPage !== itemsPerPage) {
      setItemsPerPage(newItemsPerPage);
    }
  };

  const handleCloseDetailModal = () => {
    if (isRefKrizTuruKatilimciGorevDetailModal) {
      setIsRefKrizTuruKatilimciGorevDetailModal(false);
    }
    setSelectedKrizTuruKatilimciGorev(null);
  };

  const handleTransferListClose = () => {
    setIsTransferListModal(false);
  };

  const handleTransferListOpen = async (event, item) => {
    event.stopPropagation();
    setIsTransferListModal(true);
    setSelectedAcilDurumTuru(item.acilDurumTuru);
    setSelectedRefKrizTuru(item.refKrizTurus);
    setSelectedAdtEkips(item.refAdtEkips);

    await getKrizTuruAdtEkipGorevs(item.acilDurumTuru.id, item.refKrizTurus.id, item.refAdtEkips.id);
    setLoading(true);
    const refKatilimciUrl = `api/ref-gorevs?adtEkipId.equals=${item.refAdtEkips.id}&gorevSahibiId.equals=151&size=2000`;
    await axios.get(refKatilimciUrl).then(response => {
      setAdtEkipRefGorevs(response.data);
    });

    setLoading(false);
  };

  const saveTransfer = async (right: any) => {
    const entity = {
      acilDurumTuru: selectedAcilDurumTuru,
      krizTuru: selectedRefKrizTuru,
      katilimci: null,
      adtEkip: selectedAdtEkips,
      gorevList: right,
    };

    setLoading(true);
    const refKrizTuruKatilimciGorevFormUrl = `api/ref-kriz-turu-katilimci-gorevs/add`;
    await axios
      .post(refKrizTuruKatilimciGorevFormUrl, entity)
      .then(response => {
        toast.success(
          translate('tk24AdysApp.refKrizTuruKatilimciGorev.updated', {
            param:
              selectedAcilDurumTuru.adi + '- ' + selectedRefKrizTuru.adi + ' - ' + selectedAdtEkips.adiTr + ' ' + selectedAdtEkips.adiEn,
          })
        );
      })
      .catch(function (error) {
        toast.error(translate(`error.http.${error.response.status}`));
      });

    await getKrizTuruAdtEkipGorevs(selectedAcilDurumTuru.id, selectedRefKrizTuru.id, selectedAdtEkips.id);

    handleTransferListClose();
    setLoading(false);
  };

  const saveTransferForCopy = async () => {
    const acilDurumTuruData = acilDurumTuru.filter(durum => durum.id === selectedAcilDurumTuru?.id);
    const refKrizTuruData = refKrizTurus.filter(kriz => kriz.id === selectedRefKrizTuru?.id);
    const adtEkipData = refAdtEkips.filter(adtEkips => adtEkips.id === selectedAdtEkips?.id);

    const updatedKrizTuruKatilimciGorevsForCopy = krizTuruAdtEkipGorevsForCopy.map(form => ({
      ...form,
      adtEkip: adtEkipData[0],
    }));

    const currentGorevList = krizTuruAdtEkipGorevs.map(res => res?.gorev);
    const currentGorevListForCopy = updatedKrizTuruKatilimciGorevsForCopy.map(res => res?.gorev);

    const combinedForms = [...currentGorevList, ...currentGorevListForCopy];
    const uniqueForms = Array.from(new Set(combinedForms.map(form => form.id))).map(id => {
      return combinedForms.find(form => form.id === id);
    });

    const entityForCopy = {
      acilDurumTuru: acilDurumTuruData[0],
      krizTuru: refKrizTuruData[0],
      katilimci: null,
      adtEkip: adtEkipData[0],
      gorevList: uniqueForms,
    };

    setLoading(true);
    const refKrizTuruKatilimciGorevFormUrl = `api/ref-kriz-turu-katilimci-gorevs/add`;
    await axios
      .post(refKrizTuruKatilimciGorevFormUrl, entityForCopy)
      .then(response => {
        toast.success(
          translate('tk24AdysApp.refKrizTuruKatilimciGorev.updated', {
            param:
              selectedAcilDurumTuru.adi + '- ' + selectedRefKrizTuru.adi + ' - ' + selectedAdtEkips.adiTr + ' ' + selectedAdtEkips.adiEn,
          })
        );
      })
      .catch(function (error) {
        toast.error(translate(`error.http.${error.response.status}`));
      });

    await getKrizTuruAdtEkipGorevs(selectedAcilDurumTuru.id, selectedRefKrizTuru.id, selectedAdtEkips.id);

    handleCopyClose();
    setLoading(false);
  };

  const confirmKrizTuruKatilimciGorevDelete = gorev => {
    setSelectedKrizTuruKatilimciGorev(gorev);
    setIsDeleteModalOpen(true);
  };

  const handleDeleteConfirm = useCallback(async () => {
    if (selectedKrizTuruKatilimciGorev) {
      const updatedGorevs = krizTuruAdtEkipGorevs.filter(item => item?.gorev?.id !== selectedKrizTuruKatilimciGorev?.id);
      const gorevList = updatedGorevs.map(res => res?.gorev);

      await saveTransfer(gorevList);
      handleDeleteCancel();

      if (updatedGorevs.length === 0) {
        if (currentPage > 1) {
          setCurrentPage(currentPage - 1);
        }
      }

      setKrizTuruAdtEkipGorevs(updatedGorevs);
    }
  }, [selectedKrizTuruKatilimciGorev, krizTuruAdtEkipGorevs, currentPage]);

  const handleDeleteCancel = () => {
    setIsDeleteModalOpen(false);
    setSelectedKrizTuruKatilimciGorev(null);
  };

  const handleCopyOpen = async (acilDTuru, refKTurus, refAdtEkip) => {
    await getKrizTuruAdtEkipGorevs(acilDTuru?.id, refKTurus?.id, refAdtEkip?.id);
    setLoading(true);
    const refAdtEkipUrl = `api/ref-gorevs?adtEkipId.equals=${refAdtEkip?.id}&gorevSahibiId.equals=150&size=2000`;
    await axios.get(refAdtEkipUrl).then(response => {
      setSelectedKrizTuruKatilimciGorev(response.data);
      setLoading(false);
    });
    setIsCopyGorevModal(true);
  };

  const handleCopyClose = () => {
    setSelectedAcilDurumTuruForCopy(null);
    setSelectedKrizTuruForCopy(null);
    setSelectedAdtEkipsForCopy(null);
    setSelectedKrizTuruKatilimciGorev(null);
    setKrizTuruAdtEkipGorevsForCopy(null);
    setIsCopyGorevModal(false);
  };

  useEffect(() => {
    if (
      selectedAcilDurumTuruForCopy !== undefined &&
      selectedKrizTuruForCopy !== undefined &&
      selectedAcilDurumTuruForCopy !== null &&
      selectedKrizTuruForCopy !== null &&
      selectedAdtEkipsForCopy !== undefined &&
      selectedAdtEkipsForCopy !== null
    ) {
      getKrizTuruAdtEkipGorevsForCopy(selectedAcilDurumTuruForCopy, selectedKrizTuruForCopy, selectedAdtEkipsForCopy);
    }
  }, [selectedAcilDurumTuruForCopy, selectedKrizTuruForCopy, selectedAdtEkipsForCopy]);

  const TransferListModal = () => {
    return (
      <MuiModal
        open={isTransferListModal}
        onClose={handleTransferListClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle} style={{ minWidth: 1200, height: 'auto' }} className="transferBox">
          {adtEkipRefGorevs &&
            (krizTuruAdtEkipGorevs !== null ? (
              <TransferList
                formData={adtEkipRefGorevs}
                onlyForms={krizTuruAdtEkipGorevs.map(res => res.gorev)}
                saveTransfer={saveTransfer}
                closeModal={handleTransferListClose}
                isSort={true}
                transactionTitle={translate('tk24AdysApp.refGorev.detail.header')}
                title={`${translate('tk24AdysApp.refGorev.home.adt')} ${translate('tk24AdysApp.refKrizTuruKatilimciGorev.home.title')}`}
                header={`${selectedAcilDurumTuru.adi} - ${selectedRefKrizTuru.adi} / ${selectedAdtEkips.adi}`}
                showGorevList={true}
              />
            ) : (
              <FontAwesomeIcon icon="sync" spin={true} />
            ))}
        </Box>
      </MuiModal>
    );
  };

  const getKrizTuruAdtEkipGorevs = async (acilDurumTuruId: number, refKrizTurusId: number, refAdtEkipId: number, searchInput?: string) => {
    if (acilDurumTuruId !== null && refKrizTurusId !== null) {
      setLoading(true);
      let refKrizTuruKatilimciGorevUrl = `api/ref-kriz-turu-katilimci-gorevs?acilDurumTuruId.equals=${acilDurumTuruId}&krizTuruId.equals=${refKrizTurusId}&adtEkipId.equals=${refAdtEkipId}&size=2000`;

      if (searchInput) {
        refKrizTuruKatilimciGorevUrl += `&globalFilter=${searchInput}`;
      }

      await axios.get(refKrizTuruKatilimciGorevUrl).then(response => {
        setKrizTuruAdtEkipGorevs(response.data);
        setLoading(false);
      });
    }
  };

  const getKrizTuruAdtEkipGorevsForCopy = async (acilDurumTuruId: any, refKrizTurusId: any, refAdtEkipId: any) => {
    if (acilDurumTuruId !== null && refKrizTurusId !== null) {
      setLoading(true);
      const refKrizTuruKatilimciGorevUrl = `api/ref-kriz-turu-katilimci-gorevs?acilDurumTuruId.equals=${acilDurumTuruId}&krizTuruId.equals=${refKrizTurusId}&adtEkipId.equals=${refAdtEkipId}&size=2000`;

      await axios.get(refKrizTuruKatilimciGorevUrl).then(response => {
        setKrizTuruAdtEkipGorevsForCopy(response.data);
        setLoading(false);
      });
    }
  };

  const getGorevTable = (itemId: string, acilDTuru: IReferans, refKTurus: IRefKrizTuru, refAdtEkip: IRefAdtEkip) => {
    if (openItemId === itemId) {
      setOpenItemId(null);
      setSearch('');
      setKrizTuruAdtEkipGorevs([]);
    } else {
      setOpenItemId(itemId);
      setSearch('');
      setKrizTuruAdtEkipGorevs([]);
      if (acilDTuru !== null && refKTurus !== null) {
        getKrizTuruAdtEkipGorevs(acilDTuru?.id, refKTurus?.id, refAdtEkip?.id);
        setSelectedAcilDurumTuru(acilDTuru);
        setSelectedRefKrizTuru(refKTurus);
        setSelectedAdtEkips(refAdtEkip);
      }
    }
  };

  const fetchTotalKrizTuruAdtEkipData = (): IRefKrizTuruKatilimciGorev[] => {
    try {
      return matrixData || [];
    } catch (error) {
      console.error('Error fetching data:', error);
      return [];
    }
  };

  const fetchTotalKrizTuruAdtEkipGorevData = (): any[] => {
    try {
      if (selectedAdtEkips) {
        return krizTuruAdtEkipGorevs || [];
      } else {
        return [];
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      return [];
    }
  };

  const RefKrizTuruKatilimciGorevDetailModal = () => {
    return (
      <Modal isOpen={isRefKrizTuruKatilimciGorevDetailModal} toggle={handleCloseDetailModal} style={{ maxWidth: '1200px' }} centered>
        <ModalHeader data-cy="refAdtFormUpdateDialogHeading" toggle={handleCloseDetailModal} className="mr-3">
          <p className="modal-title-info">
            {selectedKrizTuruKatilimciGorev?.adiTr} - {selectedKrizTuruKatilimciGorev?.adiEn}
          </p>
          <Translate contentKey="tk24AdysApp.refKrizTuruKatilimciGorev.detail.title" />
        </ModalHeader>
        <ModalBody>
          <RefKrizTuruKatilimciGorevDetail refKrizTuruKatilimciGorev={selectedKrizTuruKatilimciGorev} isViewing={isViewing}>
            {' '}
          </RefKrizTuruKatilimciGorevDetail>
        </ModalBody>
        <ModalFooter className="modal-footer"></ModalFooter>
      </Modal>
    );
  };

  const CopyGorevModal = () => {
    return (
      <>
        <Modal className="modal-style-lg" isOpen={isCopyGorevModal} toggle={handleCopyClose} centered>
          <ModalHeader data-cy="RefKisiAltTipiCreateUpdateHeading" toggle={handleCopyClose}>
            <Translate contentKey="tk24AdysApp.refKrizTuruKatilimciGorev.gorevKopyala" />
          </ModalHeader>
          <Form onSubmit={saveTransferForCopy}>
            <ModalBody style={{ maxHeight: '500px', height: '500px', overflow: 'auto' }}>
              <Row className="my-2">
                <Col md={6} className="mb-3">
                  <span className="accordion-typography mb-3">
                    <Translate contentKey="tk24AdysApp.refKrizTuruKatilimci.katilimcilariBurayaKopyala" />
                  </span>
                </Col>
              </Row>
              <Row className="my-2">
                <Col md={6}>
                  <Translate contentKey="tk24AdysApp.refKrizTuruKatilimciGorev.seciliAcilDurumTuru" />
                  {': '}
                  <b>{selectedAcilDurumTuru?.adi}</b>
                </Col>
                <Col md={6}>
                  <Translate contentKey="tk24AdysApp.refKrizTuruKatilimciGorev.seciliKrizTuru" />
                  {': '}
                  <b>{selectedRefKrizTuru?.adi}</b>
                </Col>
                <Col md={6}>
                  <Translate contentKey="tk24AdysApp.refKrizTuruKatilimci.adtTim" />
                  {': '}
                  <b>{selectedAdtEkips?.adi}</b>
                </Col>
                <Col md={12}>
                  <TableContainer className="content-table my-3">
                    {krizTuruAdtEkipGorevs && krizTuruAdtEkipGorevs.length > 0 ? (
                      <Table aria-label="a dense table">
                        <TableHead className="content-table-title-area">
                          <TableRow className="table-head-row">
                            <TableCell className="accordion-table-title-cell" align="left">
                              <Translate contentKey="global.languageGlobal.adiTrTitle">adiTr</Translate>&nbsp;
                            </TableCell>
                            <TableCell className="accordion-table-title-cell" align="left">
                              <Translate contentKey="global.languageGlobal.adiEnTitle">adiEn</Translate>&nbsp;
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {krizTuruAdtEkipGorevs.map(krizTuruGorev => (
                            <TableRow key={krizTuruGorev.id} className="table-cell-row">
                              <TableCell className="accordion-table-content-cell" component="th" scope="row">
                                {krizTuruGorev.gorev?.adiTr}
                              </TableCell>
                              <TableCell className="accordion-table-content-cell" component="th" scope="row">
                                {krizTuruGorev.gorev?.adiEn}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    ) : (
                      !loading && (
                        <div className="alert alert-warning m-4">
                          <Translate contentKey="tk24AdysApp.refGorev.home.notFound">No refGorev found</Translate>
                        </div>
                      )
                    )}
                  </TableContainer>
                </Col>
              </Row>
              <hr className="my-4" />
              <Row className="my-2">
                <Col md={6} className="mb-3">
                  <span className="accordion-typography">
                    <Translate contentKey="tk24AdysApp.refKrizTuruKatilimci.kopyalanacakKatilimci" />
                  </span>
                </Col>
              </Row>
              <Row className="my-2">
                <Col md={6}>
                  <FormControl style={{ minHeight: 48, maxHeight: 48 }} fullWidth>
                    <InputLabel id="default-acil-durum-turu-label" className="select-input">
                      <Translate contentKey="tk24AdysApp.refKrizTuruKatilimci.acilDurumTuru" />
                    </InputLabel>
                    <Select
                      style={{ minHeight: 48, maxHeight: 48, borderRadius: '6px' }}
                      labelId="default-acil-durum-turu-label"
                      id="default-acil-durum-turu"
                      label={translate('tk24AdysApp.refKrizTuruKatilimci.acilDurumTuru')}
                      onChange={elem => {
                        setSelectedAcilDurumTuruForCopy(elem.target.value);
                      }}
                      className="select-input"
                    >
                      <MenuItem value="">
                        <Translate contentKey="entity.action.all" />
                      </MenuItem>
                      {acilDurumTuru &&
                        acilDurumTuru.length > 0 &&
                        acilDurumTuru.map(item => (
                          <MenuItem key={item.id} value={item.id}>
                            {item.adi}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Col>
                <Col md={6}>
                  <FormControl style={{ minHeight: 48, maxHeight: 48 }} fullWidth>
                    <InputLabel id="default-kriz-turu-label" className="select-input">
                      <Translate contentKey="tk24AdysApp.refKrizTuruKatilimci.krizTuru" />
                    </InputLabel>
                    <Select
                      style={{ minHeight: 48, maxHeight: 48, borderRadius: '6px' }}
                      labelId="default-kriz-turu-label"
                      id="default-kriz-turu"
                      label={translate('tk24AdysApp.refKrizTuruKatilimci.krizTuru')}
                      onChange={elem => {
                        setSelectedKrizTuruForCopy(elem.target.value);
                      }}
                      MenuProps={MenuProps}
                      className="select-input"
                    >
                      <MenuItem value="">
                        <Translate contentKey="entity.action.all" />
                      </MenuItem>
                      {refKrizTurus &&
                        refKrizTurus.length > 0 &&
                        refKrizTurus.map(item => (
                          <MenuItem key={item.id} value={item.id}>
                            {item.adi}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Col>
                <Col md={12} className="mt-4">
                  <FormControl style={{ minHeight: 48, maxHeight: 48 }} fullWidth>
                    <InputLabel id="default-adt-ekip-label" className="select-input">
                      <Translate contentKey="tk24AdysApp.refKrizTuruKatilimciGorev.adtEkip" />
                    </InputLabel>
                    <Select
                      style={{ minHeight: 48, maxHeight: 48, borderRadius: '6px' }}
                      labelId="default-adt-ekip-label"
                      id="default-adt-ekip"
                      label={translate('tk24AdysApp.refKrizTuruKatilimciGorev.adtEkip')}
                      onChange={elem => {
                        setSelectedAdtEkipsForCopy(elem.target.value);
                      }}
                      MenuProps={MenuProps}
                      className="select-input"
                    >
                      <MenuItem value="">
                        <Translate contentKey="entity.action.all" />
                      </MenuItem>
                      {refAdtEkips &&
                        refAdtEkips.length > 0 &&
                        refAdtEkips.map(item => (
                          <MenuItem key={item.id} value={item.id}>
                            {item.adi}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Col>
                <Col md={12}>
                  <TableContainer className="content-table my-3">
                    {krizTuruAdtEkipGorevsForCopy && krizTuruAdtEkipGorevsForCopy.length > 0 ? (
                      <Table aria-label="a dense table">
                        <TableHead className="content-table-title-area">
                          <TableRow className="table-head-row">
                            <TableCell className="accordion-table-title-cell" align="left">
                              <Translate contentKey="global.languageGlobal.adiTrTitle">adiTr</Translate>&nbsp;
                            </TableCell>
                            <TableCell className="accordion-table-title-cell" align="left">
                              <Translate contentKey="global.languageGlobal.adiEnTitle">adiEn</Translate>&nbsp;
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {krizTuruAdtEkipGorevsForCopy.map(krizTuruGorev => (
                            <TableRow key={krizTuruGorev.id} className="table-cell-row">
                              <TableCell className="accordion-table-content-cell" component="th" scope="row">
                                {krizTuruGorev.gorev?.adiTr}
                              </TableCell>
                              <TableCell className="accordion-table-content-cell" component="th" scope="row">
                                {krizTuruGorev.gorev?.adiEn}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    ) : (
                      !loading && (
                        <div className="alert alert-warning m-4">
                          <Translate contentKey="tk24AdysApp.refGorev.home.notFound">No refGorev found</Translate>
                        </div>
                      )
                    )}
                  </TableContainer>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter className="modal-footer">
              <Button className="cancel-button" onClick={handleCopyClose}>
                <Translate contentKey="entity.action.cancel">Cancel</Translate>
              </Button>
              &nbsp;
              {krizTuruAdtEkipGorevsForCopy?.length > 0 && (
                <Button className="save-button" id="jhi-confirm-delete-refAdtForm" data-cy="entityConfirmButton">
                  <Translate contentKey="tk24AdysApp.refKrizTuruKatilimci.kopyala">kopyala</Translate>
                </Button>
              )}
            </ModalFooter>
          </Form>
        </Modal>
      </>
    );
  };

  return (
    <div>
      {(loading || refAdtEkipsLoading || refAdtEkipsUpdating) && <LoadingSpinner />}
      {isTransferListModal && TransferListModal()}
      {isRefKrizTuruKatilimciGorevDetailModal && RefKrizTuruKatilimciGorevDetailModal()}
      {isCopyGorevModal && CopyGorevModal()}
      {selectedKrizTuruKatilimciGorev && (
        <CustomDeleteEntityConfirm
          entity={selectedKrizTuruKatilimciGorev}
          entityName="refKrizTuruKatilimciGorev"
          isOpen={isDeleteModalOpen}
          onConfirm={handleDeleteConfirm}
          onCancel={handleDeleteCancel}
        />
      )}

      <h2 id="rol-heading" data-cy="RolDokumanHeading" className="title-frame">
        <div className="excel-export-frame">
          <p className="sub-title-text">
            <Translate contentKey="tk24AdysApp.refGorev.home.adtTitle">ADT Mission</Translate>
          </p>
        </div>
      </h2>

      <div className="content-area">
        <div className="flex-end-frame">
          <Box sx={{ minWidth: 200, maxWidth: 200, marginRight: '10px' }}>
            <FormControl className="accordion-content-cell" fullWidth>
              <InputLabel id="default-acil-durum-turu-label" className="select-input">
                <Translate contentKey="tk24AdysApp.refKrizTuruKatilimci.acilDurumTuru" />
              </InputLabel>
              <Select
                labelId="default-acil-durum-turu-label"
                id="default-acil-durum-turu"
                label={translate('tk24AdysApp.refKrizTuruKatilimci.acilDurumTuru')}
                onChange={elem => {
                  setOpenItemId(null);
                  setFilterAcilDurumTuru(elem.target.value.toString());
                }}
                className="select-input"
              >
                <MenuItem value="">
                  <Translate contentKey="entity.action.all" />
                </MenuItem>
                {acilDurumTuru &&
                  acilDurumTuru.length > 0 &&
                  acilDurumTuru.map(item => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.adi}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Box>
          <Box sx={{ minWidth: 200, maxWidth: 200, marginRight: '10px' }}>
            <FormControl className="accordion-content-cell" fullWidth>
              <InputLabel id="default-kriz-turu-label" className="select-input">
                <Translate contentKey="tk24AdysApp.refKrizTuruKatilimci.krizTuru" />
              </InputLabel>
              <Select
                labelId="default-kriz-turu-label"
                id="default-kriz-turu"
                label={translate('tk24AdysApp.refKrizTuruKatilimci.krizTuru')}
                onChange={elem => {
                  setOpenItemId(null);
                  setFilterKrizTuru(elem.target.value.toString());
                }}
                MenuProps={MenuProps}
                className="select-input"
              >
                <MenuItem value="">
                  <Translate contentKey="entity.action.all" />
                </MenuItem>
                {refKrizTurus &&
                  refKrizTurus.length > 0 &&
                  refKrizTurus.map(item => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.adi}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Box>
          <Box sx={{ minWidth: 200, maxWidth: 200, marginRight: '10px' }}>
            <FormControl className="accordion-content-cell" fullWidth>
              <InputLabel id="adtTim-label" className="select-input">
                <Translate contentKey="tk24AdysApp.refKrizTuruKatilimci.adtTim" />
              </InputLabel>
              <Select
                labelId="adtTim-label"
                id="adtTim"
                label={translate('tk24AdysApp.refKrizTuruKatilimci.adtTim')}
                onChange={elem => {
                  setOpenItemId(null);
                  setFilterAdtTim(elem.target.value.toString());
                }}
                className="select-input"
              >
                <MenuItem value="">
                  <Translate contentKey="entity.action.all" />
                </MenuItem>
                {refAdtEkips &&
                  refAdtEkips.length > 0 &&
                  refAdtEkips.map(item => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.adi}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Box>
          <ExcelDownloadButton
            excelSampleData={ExcelSampleData}
            grantedAuthorites={['ref_kriz_turu_katilimci_gorev_export']}
            fetchData={fetchTotalKrizTuruAdtEkipData}
            className={'download-button'}
            exportName={translate('tk24AdysApp.refGorev.home.adt')}
          />
        </div>
        <div className="table-responsive" style={{ marginTop: 5 }}>
          <TableContainer className="content-table">
            <Table aria-label="collapsible table">
              <TableHead>
                <TableRow className="accordion-title-row">
                  <TableCell className="accordion-title-cell" style={{ width: 48 }} />
                  <TableCell className="accordion-title-cell" align="left">
                    <Translate contentKey="tk24AdysApp.refKrizTuruKatilimciGorev.acilDurumTuru">Kriz Turu</Translate>&nbsp;
                  </TableCell>
                  <TableCell className="accordion-title-cell" align="left">
                    <Translate contentKey="tk24AdysApp.refKrizTuruKatilimciGorev.krizTuruTitle">Kriz Turu</Translate>&nbsp;
                  </TableCell>
                  <TableCell className="accordion-title-cell" align="left">
                    <Translate contentKey="tk24AdysApp.refKrizTuruKatilimci.adtTim">Tim</Translate>&nbsp;
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {currentItems?.map(item => (
                  <>
                    <TableRow
                      className="accordion-content-row"
                      sx={{ '& > *': { borderBottom: 'unset' } }}
                      onClick={() => getGorevTable(item.id, item.acilDurumTuru, item.refKrizTurus, item.refAdtEkips)}
                    >
                      <TableCell className="accordion-content-cell" style={{ width: 48 }}>
                        <IconButton aria-label="expand row" size="small" className="accordion-icon">
                          {openItemId === item.id ? <KeyboardArrowDownOutlined /> : <KeyboardArrowRight />}
                        </IconButton>
                      </TableCell>
                      <TableCell className="accordion-content-cell" component="th" scope="row">
                        {item.acilDurumTuru.adi}
                      </TableCell>
                      <TableCell className="accordion-content-cell" component="th" scope="row">
                        {item.refKrizTurus.adi}
                      </TableCell>
                      <TableCell className="accordion-content-cell" component="th" scope="row">
                        {item?.refAdtEkips?.adi}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className="accordion-content-area" colSpan={12}>
                        <Collapse in={openItemId === item.id} unmountOnExit>
                          <Box className="accordion-box accordion-box-bottom-zero">
                            <HasAuthComponent grantedAuthorites={['ref_kriz_turu_katilimci_gorev_read']}>
                              <Typography className="accordion-typography mt-3" gutterBottom component="div">
                                <Translate contentKey="tk24AdysApp.refKrizTuruKatilimciGorev.adtGorevler">adtGorevler</Translate>
                              </Typography>
                              <Typography className="item-total-count" gutterBottom component="div">
                                <span className="totalListItemText">
                                  <Translate contentKey="global.item-total-count" interpolate={{ total: krizTuruAdtEkipGorevs?.length }} />
                                </span>
                              </Typography>
                              <div className="search-frame">
                                <div className="seachiconposition">
                                  <img
                                    className="seachicon"
                                    width={24}
                                    height={24}
                                    src={`${SERVER_URL}/content/images/icon/faSearch.svg`}
                                  />
                                  <Input
                                    value={search}
                                    onChange={e => setSearch(e.target.value)}
                                    placeholder={translate('entity.action.search')}
                                    className="search-input"
                                  />
                                </div>
                                <div className="d-flex justify-end">
                                  <ExcelDownloadButton
                                    excelSampleData={AccordionExcelSampleData}
                                    grantedAuthorites={['ref_kriz_turu_katilimci_gorev_export']}
                                    fetchData={fetchTotalKrizTuruAdtEkipGorevData}
                                    className={'download-button'}
                                    exportName={translate('tk24AdysApp.refGorev.home.adtTitle')}
                                  />
                                </div>
                              </div>
                              {krizTuruAdtEkipGorevs?.length > 0 ? (
                                <Table className="accordion-table">
                                  <TableHead>
                                    <TableRow className="accordion-table-title-row">
                                      <TableCell className="accordion-table-title-cell" align="left">
                                        <Translate contentKey="global.languageGlobal.adiTrTitle">adiTr</Translate>&nbsp;
                                      </TableCell>
                                      <TableCell className="accordion-table-title-cell" align="left">
                                        <Translate contentKey="global.languageGlobal.adiEnTitle">adiEn</Translate>&nbsp;
                                      </TableCell>
                                      <TableCell className="accordion-table-title-cell"></TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {krizTuruAdtEkipGorevs?.map(krizTuruAdtEkipGorev => (
                                      <TableRow key={krizTuruAdtEkipGorev.id} className="accordion-table-content-row">
                                        <TableCell className="accordion-table-content-cell" component="th" scope="row">
                                          {krizTuruAdtEkipGorev.gorev?.adiTr}
                                        </TableCell>
                                        <TableCell className="accordion-table-content-cell" component="th" scope="row">
                                          {krizTuruAdtEkipGorev.gorev?.adiEn}
                                        </TableCell>
                                        <TableCell className="accordion-table-content-cell" component="th" scope="row" align="right">
                                          <div className="btn-group flex-btn-group-container">
                                            <GrantedButton
                                              grantedAuthorites={['ref_kriz_turu_katilimci_gorev_read']}
                                              className="view-button"
                                              onClick={() => {
                                                setSelectedKrizTuruKatilimciGorev(krizTuruAdtEkipGorev);
                                                setIsViewing(true);
                                                setIsRefKrizTuruKatilimciGorevDetailModal(true);
                                              }}
                                              size="sm"
                                              data-cy="entityDetailsButton"
                                            >
                                              <Tooltip title={<Translate contentKey="entity.action.delete">Delete</Translate>}>
                                                <img width={15.2} height={11.2} src={`${SERVER_URL}/content/images/icon/faEye.svg`} />
                                              </Tooltip>
                                              <span className="show-on-lg">
                                                <Translate contentKey="entity.action.view">View</Translate>
                                              </span>
                                            </GrantedButton>
                                            <GrantedButton
                                              grantedAuthorites={['ref_kriz_turu_katilimci_gorev_delete']}
                                              onClick={() => confirmKrizTuruKatilimciGorevDelete(krizTuruAdtEkipGorev.gorev)}
                                              className="delete-button"
                                              style={{ marginLeft: 10 }}
                                              size="sm"
                                              data-cy="entityDeleteButton"
                                            >
                                              <Tooltip title={<Translate contentKey="entity.action.delete">Delete</Translate>}>
                                                <img width={11.2} height={12.8} src={`${SERVER_URL}/content/images/icon/faTrashCan.svg`} />
                                              </Tooltip>
                                              <span className="show-on-lg">
                                                <Translate contentKey="entity.action.delete">Delete</Translate>
                                              </span>
                                            </GrantedButton>
                                          </div>
                                        </TableCell>
                                      </TableRow>
                                    ))}
                                  </TableBody>
                                </Table>
                              ) : (
                                !loading && (
                                  <div className="alert alert-warning mt-3">
                                    <Translate contentKey="tk24AdysApp.refGorev.home.notFound">No refGorev found</Translate>
                                  </div>
                                )
                              )}
                              <div className="flex-start-frame">
                                <GrantedButton
                                  grantedAuthorites={['ref_kriz_turu_katilimci_gorev_edit']}
                                  className="accordion-create-button"
                                  onClick={event => handleTransferListOpen(event, item)}
                                  size="sm"
                                  data-cy="entityCreateButton"
                                >
                                  <FontAwesomeIcon icon="list" />
                                  <span className="d-none d-md-inline">
                                    <Translate contentKey="tk24AdysApp.refKrizTuruKatilimciGorev.home.editLabel">Mission Manage</Translate>
                                  </span>
                                </GrantedButton>
                                <GrantedButton
                                  grantedAuthorites={['ref_kriz_turu_katilimci_gorev_edit']}
                                  className="accordion-create-button"
                                  id="jh-create-entity"
                                  data-cy="entityCreateButton"
                                  onClick={event => {
                                    event.stopPropagation();
                                    handleCopyOpen(item.acilDurumTuru, item.refKrizTurus, item.refAdtEkips);
                                  }}
                                  style={{ marginLeft: '10px' }}
                                >
                                  <img width={20} height={20} src={`${SERVER_URL}/content/images/icon/faCopySimpleBlue.svg`} />
                                  <Translate contentKey="tk24AdysApp.refKrizTuruKatilimciGorev.gorevKopyala">gorevKopyala</Translate>
                                </GrantedButton>
                              </div>
                            </HasAuthComponent>
                          </Box>
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  </>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <CustomPagination
          currentPage={currentPage}
          currentPageSize={itemsPerPage}
          totalItems={matrixData.length}
          handlePageChange={handlePageChange}
        />
      </div>
    </div>
  );
};

export default RefKrizTuruKatilimciGorevAdt;
