export const ReferansEnum = {
  Durum: {
    Pasif: 0,
    Aktif: 1,
    Silinmis: 2,
  },
  EvetHayir: {
    Hayir: 10,
    Evet: 11,
  },
  GonderimDurumu: {
    Gonderilecek: 21,
    Gonderildi: 22,
    Gonderilmeyecek: 23,
    IptalEdildi: 29,
  },
  GorevTuru: {
    AdymTanimliGorev: 41,
    AdymEkGorev: 42,
    AdymTalimatGorev: 45,
    AdtEkibiTanimliGorev: 43,
    AdtEkibiEkGorev: 44,
    IstasyonEkGorev: 47,
    IstasyonTanimliGorev: 46,
  },
  GorevDurumu: {
    DevamEdiyor: 31,
    Tamamlandi: 33,
    IptalEdildi: 39,
  },
  KatilimciRolu: {
    AdymBaskani: 51,
    AdymUyesi: 52,
    AdymEkKatilimci: 53,
    AdymTanimliVekil: 54,
    AdymEkVekil: 55,
    AdtEkipLideri: 56,
    AdtEkipUyesi: 57,
    AdtEkipLideriYardimcisi: 58,
    AdtEkKatilimci: 59,
    IstasyonMudur: 600,
    IstasyonSef: 601,
    IstasyonEkKatilimci: 602,
    IstasyonKatilimci: 603,
  },
  FormTipi: {
    DijitalForm: 120,
    HazirForm: 121,
  },
  BildirimTuru: {
    Sms: 61,
    EPosta: 62,
    PushNotification: 63,
    WebPush: 64,
    Crysis: 65,
    MSTeamsToplantisi: 66,
    Duyuru: 67,
    Ceride: 68,
  },
  AcilDurumTuru: {
    Kriz: 71,
    Tatbikat: 72,
    Test: 73,
    StarAlliance: 74,
    CodeShare: 75,
    Wetlease: 76,
  },

  KrizDurumu: {
    Aktif: 81,
    Kapandi: 82,
    IptalEdildi: 83,
  },
  GorevSahibi: {
    ADYM: 150,
    ADT: 151,
    Istasyon: 152,
  },
  IletisimTipi: {
    GsmNo: 170,
    EPosta: 171,
  },
  HatirlatmaDurumu: {
    Aktif: 190,
    Ertelendi: 191,
    Kapatildi: 192,
  },
  KisiTipi: {
    Murettebat: 300,
    Yolcu: 301,
    EkKisi: 302,
  },
  TalimatDurumu: {
    Baslamadi: 221,
    DevamEdiyor: 222,
    Tamamlandi: 223,
    IptalEdildi: 229,
  },
  Duyuru: {
    hedefKitle: 111,
  },
  DuyuruDurumu: {
    taslak: 100,
    yayinlandi: 101,
    pasif: 102,
  },
  Uygulama: {
    TK24: 1000,
    KRIZ_THY_COM: 2000,
  },
  SoruTipi: {
    SerbestMetin: 160,
    SerbestSayi: 161,
    SerbestTarih: 162,
    SerbestSaat: 163,
    CoktanSecmeliTekCevap: 164,
    CoktanSecmeliCokluCevap: 165,
  },
  EkDosyaTipi: {
    duyuru: 361,
    krizOzetRapor: 442,
    Medya: 441,
  },
  Dil: {
    Turkce: 91,
    Ingilizce: 92,
  },
  MesajlasmaTipi: {
    Kym: 230,
    Adt: 231,
    Yonetim: 232,
  },
  MesajAliciTipi: {
    Dm: 240,
    TumKym: 241,
    TumAdt: 242,
    BirAdtTimi: 243,
    Yonetim: 244,
  },
  ToplantiTipi: {
    FizikiToplanti: 291,
    OnlineToplanti: 292,
  },
  IvrCevap: {
    iletildiSonucBekleniyor: 328,
    diger: 329,
    telefonuActiSecimYapmadı: 330,
    telefonuActiKatilacak: 331,
    telefonuActiKatilamayacak: 332,
    telefonuActiVekilAransin: 333,
  },
  KrizYeri: {
    Havalimani: 450,
    Konum: 451,
    KonumYok: 452,
  },
  SaglikDurumu: {
    Belirsiz: 1,
    Saglikli: 2,
    HafifYarali: 3,
    HafifYaraliHastanede: 4,
    AgirYarali: 5,
    AgirYaraliHastanede: 6,
    Olu: 7,
    Kayip: 8,
  },
};

export const KatilimciRolMap = {
  KYM: [
    ReferansEnum.KatilimciRolu.AdymBaskani,
    ReferansEnum.KatilimciRolu.AdymUyesi,
    ReferansEnum.KatilimciRolu.AdymEkKatilimci,
    ReferansEnum.KatilimciRolu.AdymTanimliVekil,
    ReferansEnum.KatilimciRolu.AdymEkVekil,
  ],
  ADT: [
    ReferansEnum.KatilimciRolu.AdtEkipLideri,
    ReferansEnum.KatilimciRolu.AdtEkipUyesi,
    ReferansEnum.KatilimciRolu.AdtEkipLideriYardimcisi,
    ReferansEnum.KatilimciRolu.AdtEkKatilimci,
  ],
  ISTASYON: [
    ReferansEnum.KatilimciRolu.IstasyonEkKatilimci,
    ReferansEnum.KatilimciRolu.IstasyonKatilimci,
    ReferansEnum.KatilimciRolu.IstasyonMudur,
    ReferansEnum.KatilimciRolu.IstasyonSef,
  ],
};
