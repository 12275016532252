import React, { useState, useEffect } from 'react';
import 'app/entities/style.scss';
import { RouteComponentProps } from 'react-router-dom';
import { Modal, ModalBody, ModalHeader, Table } from 'reactstrap';
import { Translate } from 'app/component/jhipster';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntities as getKrizHavalimaniEntity } from './kriz-havalimani.reducer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isObjectEmpty } from 'app/shared/util/confirm-utils';
import { LoadingSpinner } from 'app/component/LoadingSpinner';
import { MapContainer, Marker, TileLayer, Tooltip, useMapEvents } from 'react-leaflet';
import L from 'leaflet';
import { SERVER_URL } from 'app/config/constants';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import { TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import CustomPagination from 'app/component/CustomPagination';
import KrizHavalimaniUpdate from './kriz-havalimani-update';
import GrantedButton from 'app/component/GrantedButton';
import { ReferansEnum } from 'app/constants/ReferansEnum';
import { getEntity as getKrizEntity } from '../kriz/kriz.reducer';
import { getEntity as getHavalimaniEntity } from '../ref-havalimani/ref-havalimani.reducer';
import RefHavalimaniDetail from '../ref-havalimani/ref-havalimani-detail';
import { IQueryParams } from 'app/shared/reducers/reducer.utils';
import { ASC, DESC } from 'app/shared/util/pagination.constants';
import SortIcon from 'app/component/SortIcon';
import axios from 'axios';
import { toast } from 'react-toastify';
import { airportIcon, customIcon } from 'app/shared/util/util';
import RefIstGorevGrubuPersonel from '../ref-ist-gorev-grubu-personel/ref-ist-gorev-grubu-personel';

const KrizHavalimani = (props: RouteComponentProps<{ id: string }>) => {
  const { match } = props;
  const dispatch = useAppDispatch();

  const kriz = useAppSelector(state => state.kriz.entity);
  const totalItems = useAppSelector(state => state.krizHavalimani.totalItems);
  const krizHavalimaniList = useAppSelector(state => state.krizHavalimani.entities);
  const refHavalimaniLoading = useAppSelector(state => state.refHavalimani.loading);

  const krizLoading = useAppSelector(state => state.kriz.loading);
  const krizHavalimaniLoading = useAppSelector(state => state.krizHavalimani.loading);
  const krizHavalimaniUpdating = useAppSelector(state => state.krizHavalimani.updating);

  const [nearHavalimanis, setNearHavalimanis] = useState([]);
  const [BBox, setBBox] = useState(null);

  const [showUpdateHavalimaniModal, setShowUpdateHavalimaniModal] = useState(false);
  const [showHavalimaniDetailModal, setShowHavalimaniDetailModal] = useState(false);
  const [selectedHavalimani, setSelectedHavalimani] = useState(null);
  const [isPersonelGrubuModal, setIsPersonelGrubuModal] = useState(false);
  const [krizUpdate, setKrizUpdate] = useState(false);

  const [pageObject, setPageObject] = useState<IQueryParams>({
    ...useAppSelector(state => state.paginate.pageObject),
    query: `krizId.equals=${match.params.id}`,
  });

  useEffect(() => {
    getAllEntities();
  }, [pageObject.page, pageObject.size, pageObject.query, pageObject.sort]);

  const sort = p => () => {
    const orderBy = pageObject.sort.split(',')[1];
    setPageObject({ ...pageObject, sort: `${p},${orderBy === ASC ? DESC : ASC}` });
  };

  const handlePageChange = (pageNumber, newItemsPerPage = pageObject.size) => {
    if (newItemsPerPage !== pageObject.size) {
      setPageObject({ ...pageObject, page: pageNumber - 1, size: newItemsPerPage });
    } else {
      setPageObject({ ...pageObject, page: pageNumber - 1 });
    }
  };

  const getAllEntities = () => {
    dispatch(getKrizEntity(match.params.id));
    dispatch(getKrizHavalimaniEntity(pageObject));
  };

  useEffect(() => {
    if (kriz && kriz.krizYeriId?.id === ReferansEnum.KrizYeri.Konum) {
      const yakinHavalimaniUrl = `api/ref-havalimanis/nearest-list`;
      axios
        .post(yakinHavalimaniUrl, { lat: kriz.krizYeriKonumX, lon: kriz.krizYeriKonumY })
        .then(function (response) {
          const havalimanis = response.data;
          if (havalimanis.length > 0) {
            const lats = [];
            const lngs = [];
            lngs.push(kriz.krizYeriKonumX);
            lats.push(kriz.krizYeriKonumY);
            for (let i = 0; i < havalimanis.length; i++) {
              lngs.push(havalimanis[i].konumX);
              lats.push(havalimanis[i].konumY);
            }

            const minlat = Math.min.apply(null, lats),
              maxlat = Math.max.apply(null, lats);
            const minlng = Math.min.apply(null, lngs),
              maxlng = Math.max.apply(null, lngs);

            // create a bounding rectangle that can be used in leaflet
            const bbox = [
              [minlat, minlng],
              [maxlat, maxlng],
            ];
            setBBox(bbox);
            setNearHavalimanis(havalimanis);
          }
        })
        .catch(function (error) {
          toast.error(error);
        });
    }
  }, [kriz]);

  const handleHavalimaniModalClose = () => {
    setShowUpdateHavalimaniModal(false);
  };

  const Konum = () => {
    const [mousePosition, setMousePosition] = useState<[number, number]>([kriz.krizYeriKonumX, kriz.krizYeriKonumY]);
    const [zoomLevel, setZoomLevel] = useState<number>(10);

    useMapEvents({
      mousemove(e) {
        const lat = parseFloat(e?.latlng?.lat?.toFixed(5));
        const lng = parseFloat(e?.latlng?.lng?.toFixed(5));
        setMousePosition([lat, lng]);
      },
      zoom(e) {
        setZoomLevel(e.target.getZoom());
      },
    });

    return (
      <div
        style={{
          position: 'absolute',
          bottom: '10px',
          right: '10px',
          backgroundColor: 'white',
          padding: '5px',
          zIndex: 400,
        }}
      >
        Z({zoomLevel}) - {mousePosition[1]}, {mousePosition[0]}
      </div>
    );
  };

  const krizHavalimaniDetail = async krizHavalimani => {
    await dispatch(getHavalimaniEntity(krizHavalimani.id));
    setShowHavalimaniDetailModal(true);
  };

  const handleCloseDetailForm = () => {
    setShowHavalimaniDetailModal(false);
  };

  const handleClosePersonelGrubuModal = () => {
    setIsPersonelGrubuModal(false);
    setSelectedHavalimani(null);
  };

  const PersonelGrubuModal = () => {
    return (
      <Modal isOpen={isPersonelGrubuModal} toggle={handleClosePersonelGrubuModal} className="modal-style-xlg">
        <ModalHeader data-cy="refEntityDetailsHeading" toggle={handleClosePersonelGrubuModal}>
          <p className="modal-title-info">{selectedHavalimani?.adi}</p>
          <Translate contentKey="tk24AdysApp.refIstasyonGorevGrubu.detail.titlePersonel" />
        </ModalHeader>
        <ModalBody style={{ height: '100%' }}>
          <RefIstGorevGrubuPersonel havalimani={selectedHavalimani} />
        </ModalBody>
      </Modal>
    );
  };

  const HavalimaniDetailModal = () => {
    return <RefHavalimaniDetail handleCloseForm={handleCloseDetailForm} isOpen={showHavalimaniDetailModal} />;
  };

  const KonumBilgileri = () => {
    return (
      <MapContainer
        center={kriz.krizYeriKonumX && kriz.krizYeriKonumY ? [kriz.krizYeriKonumX, kriz.krizYeriKonumY] : [39.266244, 34.16748]}
        zoom={5}
        style={{ width: '100%', height: '400px' }}
        attributionControl={false}
        bounds={BBox}
      >
        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
        <Marker position={[kriz.krizYeriKonumX, kriz.krizYeriKonumY]} icon={customIcon}>
          {' '}
        </Marker>
        {nearHavalimanis &&
          nearHavalimanis.length > 0 &&
          nearHavalimanis.map((nearHavalimani, index) => (
            <Marker key={index} position={[nearHavalimani.konumX, nearHavalimani.konumY]} icon={airportIcon}>
              <Tooltip sticky>{nearHavalimani.adi}</Tooltip>
            </Marker>
          ))}
        <Konum />
      </MapContainer>
    );
  };

  const HavalimaniBilgileri = () => {
    return (
      <>
        {showHavalimaniDetailModal && <HavalimaniDetailModal />}
        <TableContainer className="content-table">
          {krizHavalimaniList && krizHavalimaniList.length > 0 ? (
            <Table aria-label="a dense table">
              <TableHead className="content-table-title-area">
                <TableRow className="table-head-row">
                  <TableCell align="left" className="table-title-cell hand" onClick={sort('havalimani.koduIata')}>
                    <div className="tableHeaderContainer">
                      <Translate contentKey="tk24AdysApp.krizHavalimani.koduIata">Kodu Iata</Translate>
                      <SortIcon column="havalimani.koduIata" activeSortColumn={pageObject.sort} />
                    </div>
                  </TableCell>
                  <TableCell align="left" className="table-title-cell hand" onClick={sort('havalimani.koduIcoa')}>
                    <div className="tableHeaderContainer">
                      <Translate contentKey="tk24AdysApp.krizHavalimani.koduIcoa">ICOA Kodu</Translate>
                      <SortIcon column="havalimani.koduIcoa" activeSortColumn={pageObject.sort} />
                    </div>
                  </TableCell>
                  <TableCell align="left" className="table-title-cell hand" onClick={sort('havalimani.ulke')}>
                    <div className="tableHeaderContainer">
                      <Translate contentKey="tk24AdysApp.krizHavalimani.ulke">Ulke</Translate>
                      <SortIcon column="havalimani.ulke" activeSortColumn={pageObject.sort} />
                    </div>
                  </TableCell>
                  <TableCell align="left" className="table-title-cell hand" onClick={sort('havalimani.sehir')}>
                    <div className="tableHeaderContainer">
                      <Translate contentKey="tk24AdysApp.krizHavalimani.sehir">Sehir</Translate>
                      <SortIcon column="havalimani.sehir" activeSortColumn={pageObject.sort} />
                    </div>
                  </TableCell>
                  <TableCell align="left" className="table-title-cell hand" onClick={sort('havalimani.adi')}>
                    <div className="tableHeaderContainer">
                      <Translate contentKey="tk24AdysApp.krizHavalimani.adi">Adi</Translate>
                      <SortIcon column="havalimani.adi" activeSortColumn={pageObject.sort} />
                    </div>
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {krizHavalimaniList.map(krizHavalimani => (
                  <TableRow key={krizHavalimani.id} className="table-cell-row">
                    <TableCell component="th" scope="row" className="table-content-cell">
                      {krizHavalimani.havalimani.koduIata}
                    </TableCell>
                    <TableCell component="th" scope="row" className="table-content-cell">
                      {krizHavalimani.havalimani.koduIcoa}
                    </TableCell>

                    <TableCell component="th" scope="row" className="table-content-cell">
                      {' '}
                      {krizHavalimani.havalimani?.refUlke?.adi}{' '}
                    </TableCell>
                    <TableCell component="th" scope="row" className="table-content-cell">
                      {' '}
                      {krizHavalimani.havalimani.sehir}{' '}
                    </TableCell>
                    <TableCell component="th" scope="row" className="table-content-cell">
                      {' '}
                      {krizHavalimani.havalimani.adi}{' '}
                    </TableCell>
                    <TableCell align="right" className="table-content-cell">
                      <div className="btn-group flex-btn-group-container">
                        <GrantedButton
                          grantedAuthorites={['ref_ist_gorev_grubu_personel_read']}
                          className="view-button"
                          onClick={event => {
                            event.stopPropagation();
                            setSelectedHavalimani(krizHavalimani?.havalimani);
                            setIsPersonelGrubuModal(true);
                          }}
                          size="sm"
                          data-cy="entityEditButton"
                        >
                          <img width={15.2} height={11.2} src={`${SERVER_URL}/content/images/icon/faEye.svg`} />
                          <span className="d-none d-md-inline">
                            <Translate contentKey="entity.action.organizasyonSema" />
                          </span>
                        </GrantedButton>
                        <GrantedButton
                          grantedAuthorites={['kriz_havalimani_read']}
                          className="view-button"
                          onClick={() => {
                            krizHavalimaniDetail(krizHavalimani?.havalimani);
                          }}
                          style={{ marginLeft: 8 }}
                          size="sm"
                          data-cy="entityDetailsButton"
                        >
                          <img width={15.2} height={11.2} src={`${SERVER_URL}/content/images/icon/faEye.svg`} />
                          <span className="d-none d-md-inline">
                            <Translate contentKey="entity.action.detail">Detay</Translate>
                          </span>
                        </GrantedButton>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : (
            !krizHavalimaniLoading && (
              <div className="alert alert-warning">
                <Translate contentKey="tk24AdysApp.krizHavalimani.home.notFound">No Kriz Havalimani found</Translate>
              </div>
            )
          )}
        </TableContainer>

        <CustomPagination
          currentPage={pageObject.page + 1}
          currentPageSize={pageObject.size}
          totalItems={totalItems}
          handlePageChange={handlePageChange}
        />
      </>
    );
  };

  return (
    <div>
      {(krizLoading || krizUpdate || krizHavalimaniLoading || refHavalimaniLoading || krizHavalimaniUpdating) && <LoadingSpinner />}
      {!isObjectEmpty(kriz) && (
        <>
          {isPersonelGrubuModal && PersonelGrubuModal()}
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <div className="my-3" style={{ display: 'flex', flexDirection: 'column' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <p className="homeCardKey">
                  <Translate contentKey="tk24AdysApp.kriz.konumBilgileri" />:
                </p>
                <p className="homeCardValue" style={{ marginLeft: 2 }}>
                  {kriz.krizYeriId?.adi}
                </p>
              </div>

              <div style={{ display: 'flex', alignItems: 'center' }}>
                <p className="homeCardKey">
                  <Translate contentKey="tk24AdysApp.kriz.krizYeriAciklama" />:
                </p>
                <p className="homeCardValue" style={{ marginLeft: 2 }}>
                  {kriz.krizYeriAciklama}
                </p>
              </div>
            </div>

            <GrantedButton
              id={`edit-button`}
              grantedAuthorites={['kriz_havalimani_edit']}
              comparison={[
                {
                  value: kriz.krizDurumu.id,
                  checkValue: ReferansEnum.KrizDurumu.Aktif,
                  contentKey: 'error.comparison.crisisIsPassive',
                },
              ]}
              onClick={() => setShowUpdateHavalimaniModal(true)}
              className="kriz-edit-button"
              data-cy="entityEditButton"
            >
              <FontAwesomeIcon icon={faPen} />
              <span className="d-none d-md-inline">
                <Translate contentKey="tk24AdysApp.kriz.konumBilgisiUpdate" />
              </span>
            </GrantedButton>
          </div>

          {showUpdateHavalimaniModal && (
            <KrizHavalimaniUpdate
              kriz={kriz}
              krizHavalimaniList={krizHavalimaniList.map(res => res)} // todo
              getAllEntities={getAllEntities}
              handleCloseForm={handleHavalimaniModalClose}
              isUpdateOpen={showUpdateHavalimaniModal}
              krizUpdating={krizUpdate}
              setKrizUpdating={setKrizUpdate}
            ></KrizHavalimaniUpdate>
          )}
          {!krizLoading && !krizHavalimaniLoading && (
            <>{krizHavalimaniList.length > 0 ? <HavalimaniBilgileri /> : <>{kriz?.krizYeriKonumX !== null && <KonumBilgileri />}</>}</>
          )}
        </>
      )}
    </div>
  );
};

export default KrizHavalimani;
