import React, { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import InfoTable from 'app/entities/infoTable';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { KrizMain } from 'app/component/KrizMain';
import EtkilenenKisiDoldurulanFormlar from 'app/entities/etkilenen-kisi/etkilenen-kisi-doldurulan-formlar';
import { getEntity as getKrizEntity } from '../kriz/kriz.reducer';
import { LoadingSpinner } from 'app/component/LoadingSpinner';

const KrizDoldurulanFormlar = (props: RouteComponentProps<{ id: string }>) => {
  const isDrawer = useAppSelector(state => state.krizChat.isDrawer);
  const krizLoading = useAppSelector(state => state.kriz.loading);
  const dispatch = useAppDispatch();
  const { match } = props;

  useEffect(() => {
    dispatch(getKrizEntity(match.params.id));
  }, []);

  return (
    <KrizMain isDrawer={isDrawer}>
      <div style={{ marginBottom: 5 }}>
        <InfoTable id={match.params.id} titleContentKey={'tk24AdysApp.krizDoldurulanForm.home.title'} urlName="kriz-rapor" />
      </div>
      {krizLoading ? <LoadingSpinner /> : <EtkilenenKisiDoldurulanFormlar kisi={null} kisiAltTipi={null} krizId={match.params.id} />}
    </KrizMain>
  );
};

export default KrizDoldurulanFormlar;
