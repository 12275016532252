import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import KrizMedyaDetail from 'app/entities/kriz-medya/kriz-medya-detail';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={KrizMedyaDetail} />
    </Switch>
  </>
);

export default Routes;
