import React from 'react';
import { Translate, Storage } from 'app/component/jhipster';
import { NavItem, NavLink } from 'reactstrap';
import { NavLink as Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { SERVER_URL } from 'app/config/constants';
import { isNullOrEmpty } from 'app/shared/util/confirm-utils';

export const BrandIcon = props => (
  <div {...props} className="brand-icon">
    <img src={SERVER_URL + `/content/images/thyLogo.svg`} alt="Logo" />
  </div>
);

export const Brand = props => (
  <NavLink
    tag={Link}
    to={!isNullOrEmpty(Storage.session.get('kriz')) ? `/kriz-detay/${Storage.session.get('kriz')}` : '/'}
    className="brand-logo p-0"
  >
    <BrandIcon />
  </NavLink>
);

export const Home = props => (
  <NavItem>
    <NavLink tag={Link} to="/" className="d-flex align-items-center">
      <FontAwesomeIcon icon="home" />
      <span>
        <Translate contentKey="global.menu.home">Home</Translate>
      </span>
    </NavLink>
  </NavItem>
);
