export interface IUser {
  id?: any;
  login?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  activated?: boolean;
  langKey?: string;
  authorities?: any[];
  cUser?: string;
  cDate?: Date | null;
  mUser?: string;
  mDate?: Date | null;
}

export const defaultValue: Readonly<IUser> = {
  id: '',
  login: '',
  firstName: '',
  lastName: '',
  email: '',
  activated: true,
  langKey: '',
  authorities: [],
  cUser: '',
  cDate: null,
  mUser: '',
  mDate: null,
};
