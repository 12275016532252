import { IKriz } from 'app/shared/model/kriz.model';
import { IReferans } from 'app/shared/model/referans.model';

export interface IMesaj {
  id?: number;
  guid?: string;
  icerik?: string;
  gonderenKulId?: number;
  aliciKulId?: number;
  cDate?: string;
  cUser?: number;
  mDate?: string;
  mUser?: number;
  kriz?: IKriz;
  durum?: IReferans;
}

export const defaultValue: Readonly<IMesaj> = {};
