import { IQueryParams } from 'app/shared/reducers/reducer.utils';
import { IBaseModel } from './base.model';
import { IAdtUye } from './adt-uye.model';
import { IRefAdtAltEkip } from './ref-adt-alt-ekip.model';

export interface IRefAdtAltEkipUye extends IBaseModel {
  adtUye?: IAdtUye;
  altEkip?: IRefAdtAltEkip;
}

export interface IRefAdtAltEkipUyeParams extends IQueryParams {
  entity?: IRefAdtAltEkipUye;
  uyeId?: string | number;
}

export const defaultValue: Readonly<IRefAdtAltEkipUye> = {};
