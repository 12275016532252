import React from 'react';
import { Row, Col } from 'reactstrap';
import { Translate } from 'app/component/jhipster';
import { IRol } from 'app/shared/model/rol.model';

interface RolDetailProp {
  rol: IRol;
}

export const RolDetail: React.FC<RolDetailProp> = ({ rol }) => {
  return (
    <Row className="modal-body-row">
      <Col lg="6" style={{ marginTop: '10px' }}>
        <Row className="modal-content-title">
          <Translate contentKey="global.languageGlobal.adiTr">dokumanNo</Translate>
        </Row>
        <Row className="modal-content-text">{rol.adiTr ? rol.adiTr : '-'}</Row>
      </Col>
      <Col lg="6" style={{ marginTop: '10px' }}>
        <Row className="modal-content-title">
          <Translate contentKey="global.languageGlobal.adiEn">Adi Tr</Translate>
        </Row>
        <Row className="modal-content-text">{rol.adiEn ? rol.adiEn : '-'}</Row>
      </Col>
      <Col lg="6" style={{ marginTop: '10px' }}>
        <Row className="modal-content-title">
          <Translate contentKey="global.languageGlobal.aciklamaTr">Adi En</Translate>
        </Row>
        <Row className="modal-content-text">{rol.aciklamaTr ? rol.aciklamaTr : '-'}</Row>
      </Col>
      <Col lg="6" style={{ marginTop: '10px' }}>
        <Row className="modal-content-title">
          <Translate contentKey="global.languageGlobal.aciklamaEn">kategori</Translate>
        </Row>
        <Row className="modal-content-text">{rol.aciklamaEn ? rol.aciklamaEn : '-'}</Row>
      </Col>
    </Row>
  );
};

export default RolDetail;
