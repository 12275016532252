import { IKriz } from 'app/shared/model/kriz.model';
import { IReferans } from 'app/shared/model/referans.model';
import { IRefAdtEkip } from './ref-adt-ekip.model';

export interface IKrizAdtEkip {
  id?: number;
  guid?: string;
  adtEkip?: IRefAdtEkip;
  cDate?: string;
  cUser?: number;
  mDate?: string;
  mUser?: number;
  kriz?: IKriz;
  durum?: IReferans;
  aciklamaTr?: string;
  aciklamaEn?: string;
  aciklama?: string;
}

export const defaultValue: Readonly<IKrizAdtEkip> = {};
