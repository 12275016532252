import React, { useState, useEffect } from 'react';
import 'app/entities/style.scss';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import InfoTable from 'app/entities/infoTable';
import axios from 'axios';
import { RouteComponentProps } from 'react-router-dom';
import EtkilenenKisiYolcu from './etkilenen-kisi-yolcu';
import EtkilenenKisiMurettebat from './etkilenen-kisi-murettebat';
import EtkilenenKisiEkKisi from './etkilenen-kisi-ek-kisi';
import { CustomTabPanel } from 'app/component/CustomTabPanel';
import { hasAnyAuthority, useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntities as getFavoriEntities } from './reducer/etkilenen-kisi-favoris.reducer';
import { getEntities as getAdtAtamaEntities } from './reducer/etkilenen-kisi-adt-atama.reducer';
import { getEntities as getRefKisiAltTipis } from 'app/entities/ref-kisi-alt-tipi/ref-kisi-alt-tipi.reducer';
import { getEntityBySicilId } from 'app/entities/kriz-katilimci/kriz-katilimci.reducer';
import { KrizMain } from 'app/component/KrizMain';
import { isObjectEmpty } from 'app/shared/util/confirm-utils';
import { getEntities as getReferans } from 'app/entities/referans/referans.reducer';
import { getEntity as getKrizEntity } from '../kriz/kriz.reducer';
import { getEntities as getRefEkDosyaTipis } from 'app/entities/ref-ek-dosya-tipi/ref-ek-dosya-tipi.reducer';
import { getEntities as getRefSaglikDurums } from 'app/entities/ref-saglik-durumu/ref-saglik-durumu.reducer';
import { translate } from 'app/component/jhipster';
import { localeLanguage, upperOnlyFirstLetter } from 'app/shared/util/util';
import { IKrizUcus } from 'app/shared/model/kriz-ucus.model';
import { LoadingSpinner } from 'app/component/LoadingSpinner';
import styled from 'styled-components';

const StyledTab = styled(Tab)`
  &:hover {
    box-shadow: 0px -2px 4px 0px #0000000f;
  }
`;

const EtkilenenKisi = (props: RouteComponentProps<{ id: string }>) => {
  const dispatch = useAppDispatch();
  const isDrawer = useAppSelector(state => state.krizChat.isDrawer);

  const [value, setValue] = React.useState(0);
  const [ucus, setUcus] = useState<IKrizUcus[]>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const krizKatilimci = useAppSelector(state => state.krizKatilimci.entity);

  const sicilNo = useAppSelector(state => state.authentication.account.sicilNo);

  const loadKrizUcus = async () => {
    const krizUcusUrl = `api/kriz-ucuses?krizId.equals=${props.match.params.id}&size=100&sort=cDate,asc`;
    await axios
      .get(krizUcusUrl)
      .then(response => {
        setUcus(response.data);
      })
      .catch(ex => {
        setLoading(false);
      });
    setLoading(false);
  };
  useEffect(() => {
    setLoading(true);
    dispatch(getEntityBySicilId({ krizId: props.match.params.id, sicilId: sicilNo }));
    dispatch(getRefKisiAltTipis({ size: 999, sort: `adi${upperOnlyFirstLetter(localeLanguage)},asc`, page: 0 }));
    dispatch(getReferans({ size: 999, sort: `adi${upperOnlyFirstLetter(localeLanguage)},asc`, page: 0 }));
    dispatch(getKrizEntity(props.match.params.id));
    dispatch(getRefEkDosyaTipis({ size: 999, page: 0, sort: `adi${upperOnlyFirstLetter(localeLanguage)},asc`, hasParent: true }));
    dispatch(getRefSaglikDurums({ size: 999, page: 0, sort: `adi${upperOnlyFirstLetter(localeLanguage)},asc` }));
    loadKrizUcus();
  }, []);

  useEffect(() => {
    if (!isObjectEmpty(krizKatilimci)) {
      if (hasAnyAuthority(['kriz_etkilenen_kisi_favori_read'])) {
        dispatch(getFavoriEntities({ krizKatilimciId: krizKatilimci.id }));
      }
    }
    if (hasAnyAuthority(['kriz_etkilenen_kisi_adt_atama_read'])) {
      dispatch(getAdtAtamaEntities({ krizId: props.match.params.id }));
    }
  }, []);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <KrizMain isDrawer={isDrawer}>
      <div style={{ marginBottom: 5 }}>
        <InfoTable id={props.match.params.id} titleContentKey={'tk24AdysApp.krizDetay.kisiBilgileri'} />
      </div>

      <Box sx={{ width: '100%' }} className="bgEtkilenenKisi">
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }} className="boxBorder">
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" className="tabEtkilenenKisi">
            <StyledTab label={translate('tk24AdysApp.etkilenenKisi.yolcular')} className="tabEtkilenenKisiTitle" />
            <StyledTab label={translate('tk24AdysApp.etkilenenKisi.mürettebat')} className="tabEtkilenenKisiTitle" />
            <StyledTab label={translate('tk24AdysApp.etkilenenKisi.kisi')} className="tabEtkilenenKisiTitle" />
          </Tabs>
        </Box>
        {loading ? (
          <LoadingSpinner />
        ) : (
          <>
            <CustomTabPanel value={value} index={0}>
              <EtkilenenKisiYolcu ucus={ucus} krizId={props.match.params.id} />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <EtkilenenKisiMurettebat ucus={ucus} krizId={props.match.params.id} />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
              <EtkilenenKisiEkKisi ucus={ucus} krizId={props.match.params.id} />
            </CustomTabPanel>
          </>
        )}
      </Box>
    </KrizMain>
  );
};

export default EtkilenenKisi;
