import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import RefAdtFormBolum from './ref-adt-form-bolum';
import RefAdtFormBolumUpdate from './ref-adt-form-bolum-update';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={RefAdtFormBolumUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={RefAdtFormBolumUpdate} />
      <ErrorBoundaryRoute path={`${match.url}/:id`} component={RefAdtFormBolum} />
    </Switch>
  </>
);

export default Routes;
