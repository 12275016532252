import { IQueryParams } from '../reducers/reducer.utils';
import { IRefIslemKodu } from './ref-islem-kodu.model';
import { IReferans } from './referans.model';

export interface IRefBildirimSablonu {
  id?: number;
  guid?: string;
  konu?: string;
  konuTr?: string;
  konuEn?: string;
  icerik?: string;
  icerikTr?: string;
  icerikEn?: string;
  cDate?: string;
  cUser?: number;
  mDate?: string;
  mUser?: number;
  bildirimTuru?: IReferans;
  islemKodu?: IRefIslemKodu;
  durum?: IReferans;
  kykCeride?: IReferans;
  adtCeride?: IReferans;
  istasyonCeride?: IReferans;
  cc?: string;
  bcc?: string;
}

export interface IRefBildirimSablonuParams extends IQueryParams {
  entity?: IRefBildirimSablonu;
}

export const defaultValue: Readonly<IRefBildirimSablonu> = {};
