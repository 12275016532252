import React, { useState, useEffect } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col, UncontrolledTooltip } from 'reactstrap';
import { Translate, translate, ValidatedField, ValidatedForm } from 'app/component/jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { getEntities as getKrizs } from 'app/entities/kriz/kriz.reducer';
import { getEntities as getRefKatilimcis } from 'app/entities/ref-katilimci/ref-katilimci.reducer';
import { getEntities as getRefKatilimciVekils } from 'app/entities/ref-katilimci-vekil/ref-katilimci-vekil.reducer';
import { getEntities as getReferans } from 'app/entities/referans/referans.reducer';
import { getEntity, updateEntity, createEntity, reset } from './kriz-katilimci.reducer';
import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

export const KrizKatilimciUpdate = (props: RouteComponentProps<{ id: string }>) => {
  const dispatch = useAppDispatch();

  const [isNew] = useState(!props.match.params || !props.match.params.id);

  const krizs = useAppSelector(state => state.kriz.entities);
  const refKatilimcis = useAppSelector(state => state.refKatilimci.entities);
  const refKatilimciVekils = useAppSelector(state => state.refKatilimciVekil.entities);
  const referans = useAppSelector(state => state.referans.entities);
  const krizKatilimciEntity = useAppSelector(state => state.krizKatilimci.entity);
  const loading = useAppSelector(state => state.krizKatilimci.loading);
  const updating = useAppSelector(state => state.krizKatilimci.updating);
  const updateSuccess = useAppSelector(state => state.krizKatilimci.updateSuccess);

  const handleClose = () => {
    props.history.push('/kriz-katilimci' + props.location.search);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(props.match.params.id));
    }

    dispatch(getKrizs({}));
    dispatch(getRefKatilimcis({}));
    dispatch(getRefKatilimciVekils({}));
    dispatch(getReferans({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    values.cDate = convertDateTimeToServer(values.cDate);
    values.mDate = convertDateTimeToServer(values.mDate);

    const entity = {
      ...krizKatilimciEntity,
      ...values,
      kriz: krizs.find(it => it.id.toString() === values.krizId.toString()),
      katilimci: refKatilimcis.find(it => it.id.toString() === values.katilimciId.toString()),
      vekilKatilimci: refKatilimciVekils.find(it => it.id.toString() === values.vekilKatilimciId.toString()),
      toplantidaMi: referans.find(it => it.id.toString() === values.toplantidaMiId.toString()),
      katilimciRolu: referans.find(it => it.id.toString() === values.katilimciRoluId.toString()),
      durum: referans.find(it => it.id.toString() === values.durumId.toString()),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {
          cDate: displayDefaultDateTime(),
          mDate: displayDefaultDateTime(),
        }
      : {
          ...krizKatilimciEntity,
          cDate: convertDateTimeFromServer(krizKatilimciEntity.cDate),
          mDate: convertDateTimeFromServer(krizKatilimciEntity.mDate),
          krizId: krizKatilimciEntity?.kriz?.id,
          katilimciId: krizKatilimciEntity?.katilimci?.id,
          vekilKatilimciId: krizKatilimciEntity?.vekilKatilimci?.id,
          toplantidaMiId: krizKatilimciEntity?.toplantidaMi?.id,
          katilimciRoluId: krizKatilimciEntity?.katilimciRolu?.id,
          durumId: krizKatilimciEntity?.durum?.id,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="tk24AdysApp.krizKatilimci.home.createOrEditLabel" data-cy="KrizKatilimciCreateUpdateHeading">
            <Translate contentKey="tk24AdysApp.krizKatilimci.home.createOrEditLabel">Create or edit a KrizKatilimci</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="kriz-katilimci-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('tk24AdysApp.krizKatilimci.orgId')}
                id="kriz-katilimci-orgId"
                name="orgId"
                data-cy="orgId"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  maxLength: { value: 20, message: translate('entity.validation.maxlength', { max: 20 }) },
                }}
              />
              <UncontrolledTooltip target="orgIdLabel">
                <Translate contentKey="tk24AdysApp.krizKatilimci.help.orgId" />
              </UncontrolledTooltip>
              <ValidatedField
                label={translate('tk24AdysApp.krizKatilimci.sicil')}
                id="kriz-katilimci-sicil"
                name="sicil"
                data-cy="sicil"
                type="text"
                validate={{
                  maxLength: { value: 6, message: translate('entity.validation.maxlength', { max: 6 }) },
                }}
              />
              <ValidatedField
                label={translate('tk24AdysApp.krizKatilimci.adi')}
                id="kriz-katilimci-adi"
                name="adi"
                data-cy="adi"
                type="text"
                validate={{
                  maxLength: { value: 100, message: translate('entity.validation.maxlength', { max: 100 }) },
                }}
              />
              <UncontrolledTooltip target="adiLabel">
                <Translate contentKey="tk24AdysApp.krizKatilimci.help.adi" />
              </UncontrolledTooltip>
              <ValidatedField
                label={translate('tk24AdysApp.krizKatilimci.soyadi')}
                id="kriz-katilimci-soyadi"
                name="soyadi"
                data-cy="soyadi"
                type="text"
                validate={{
                  maxLength: { value: 255, message: translate('entity.validation.maxlength', { max: 255 }) },
                }}
              />
              <ValidatedField
                label={translate('tk24AdysApp.krizKatilimci.unvanAdi')}
                id="kriz-katilimci-unvanAdi"
                name="unvanAdi"
                data-cy="unvanAdi"
                type="text"
                validate={{
                  maxLength: { value: 255, message: translate('entity.validation.maxlength', { max: 255 }) },
                }}
              />
              <ValidatedField
                label={translate('tk24AdysApp.krizKatilimci.orgAdiTr')}
                id="kriz-katilimci-orgAdiTr"
                name="orgAdiTr"
                data-cy="orgAdiTr"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  maxLength: { value: 100, message: translate('entity.validation.maxlength', { max: 100 }) },
                }}
              />
              <UncontrolledTooltip target="orgAdiTrLabel">
                <Translate contentKey="tk24AdysApp.krizKatilimci.help.orgAdiTr" />
              </UncontrolledTooltip>
              <ValidatedField
                label={translate('tk24AdysApp.krizKatilimci.orgAdiEn')}
                id="kriz-katilimci-orgAdiEn"
                name="orgAdiEn"
                data-cy="orgAdiEn"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  maxLength: { value: 100, message: translate('entity.validation.maxlength', { max: 100 }) },
                }}
              />
              <UncontrolledTooltip target="orgAdiEnLabel">
                <Translate contentKey="tk24AdysApp.krizKatilimci.help.orgAdiEn" />
              </UncontrolledTooltip>
              <ValidatedField
                label={translate('tk24AdysApp.krizKatilimci.eposta')}
                id="kriz-katilimci-eposta"
                name="eposta"
                data-cy="eposta"
                type="text"
                validate={{
                  maxLength: { value: 100, message: translate('entity.validation.maxlength', { max: 100 }) },
                }}
              />
              <UncontrolledTooltip target="epostaLabel">
                <Translate contentKey="tk24AdysApp.krizKatilimci.help.eposta" />
              </UncontrolledTooltip>
              <ValidatedField
                label={translate('tk24AdysApp.krizKatilimci.gsmNo')}
                id="kriz-katilimci-gsmNo"
                name="gsmNo"
                data-cy="gsmNo"
                type="text"
                validate={{
                  maxLength: { value: 100, message: translate('entity.validation.maxlength', { max: 100 }) },
                }}
              />
              <UncontrolledTooltip target="gsmNoLabel">
                <Translate contentKey="tk24AdysApp.krizKatilimci.help.gsmNo" />
              </UncontrolledTooltip>
              <ValidatedField
                id="kriz-katilimci-kriz"
                name="krizId"
                data-cy="kriz"
                label={translate('tk24AdysApp.krizKatilimci.kriz')}
                type="select"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              >
                <option value="" key="0" />
                {krizs
                  ? krizs.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="kriz-katilimci-katilimci"
                name="katilimciId"
                data-cy="katilimci"
                label={translate('tk24AdysApp.krizKatilimci.katilimci')}
                type="select"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              >
                <option value="" key="0" />
                {refKatilimcis
                  ? refKatilimcis.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="kriz-katilimci-vekilKatilimci"
                name="vekilKatilimciId"
                data-cy="vekilKatilimci"
                label={translate('tk24AdysApp.krizKatilimci.vekilKatilimci')}
                type="select"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              >
                <option value="" key="0" />
                {refKatilimciVekils
                  ? refKatilimciVekils.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="kriz-katilimci-toplantidaMi"
                name="toplantidaMiId"
                data-cy="toplantidaMi"
                label={translate('tk24AdysApp.krizKatilimci.toplantidaMi')}
                type="select"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              >
                <option value="" key="0" />
                {referans
                  ? referans.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="kriz-katilimci-katilimciRolu"
                name="katilimciRoluId"
                data-cy="katilimciRolu"
                label={translate('tk24AdysApp.krizKatilimci.katilimciRolu')}
                type="select"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              >
                <option value="" key="0" />
                {referans
                  ? referans.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/kriz-katilimci" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default KrizKatilimciUpdate;
