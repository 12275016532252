import React, { useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Input, Modal, ModalBody, ModalHeader, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { Translate, TextFormat, translate } from 'app/component/jhipster';
import { getEntities, getTotalEntities, getEntity } from './ref-havalimani.reducer';
import { ASC, DESC } from 'app/shared/util/pagination.constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import axios from 'axios';
import { LoadingSpinner } from 'app/component/LoadingSpinner';
import { APP_DATE_FORMAT, SERVER_URL } from 'app/config/constants';
import { toast } from 'react-toastify';
import {
  Box,
  Checkbox,
  Collapse,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Autocomplete,
  TextField,
  Chip,
  Tooltip,
} from '@mui/material';
import { IQueryParams } from 'app/shared/reducers/reducer.utils';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownOutlined from '@mui/icons-material/KeyboardArrowDownOutlined';
import { IRefHavalimani } from 'app/shared/model/ref-havalimani.model';
import CustomPagination from 'app/component/CustomPagination';
import ExcelDownloadButton from 'app/component/ExcelDownload';
import GrantedButton from 'app/component/GrantedButton';
import RefIstGorevGrubuPersonel from '../ref-ist-gorev-grubu-personel/ref-ist-gorev-grubu-personel';
import SortIcon from 'app/component/SortIcon';
import RefHavalimaniDetail from './ref-havalimani-detail';
import { MenuProps } from 'app/shared/util/filter.constants';
import { isNullOrEmpty } from 'app/shared/util/confirm-utils';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CloseIcon from '@mui/icons-material/Close';
import { localeLanguage, upperOnlyFirstLetter } from 'app/shared/util/util';
import classnames from 'classnames';
import {
  getEntities as getOccRehberEntities,
  reset as resetOccRehber,
  getTotalEntities as getTotalOccRehberEntities,
} from '../occ-rehber/occ-rehber.reducer';
import { IOccRehber } from 'app/shared/model/occ-rehber.model';
import { HasAuthComponent } from 'app/component/HasAuthComponent';
import { hasAnyAuthority } from 'app/config/store';

export const RefHavalimani = (props: RouteComponentProps<{ url: string }>) => {
  const dispatch = useAppDispatch();

  const [refHavalimaniIrtibatTipi, setRefHavalimaniIrtibatTipi] = useState([]);
  const refHavalimaniList = useAppSelector(state => state.refHavalimani.entities);
  const loading = useAppSelector(state => state.refHavalimani.loading);
  const [irtibatLoading, setIrtibatLoading] = useState(false);
  const totalItems = useAppSelector(state => state.refHavalimani.totalItems);
  const [search, setSearch] = useState('');
  const [openItemId, setOpenItemId] = useState<number | null>(null);
  const [checked, setChecked] = React.useState(false);
  const [pageObject, setPageObject] = useState<IQueryParams>(useAppSelector(state => state.paginate.pageObject));
  const [selectedHavalimani, setSelectedHavalimani] = useState(null);
  const [isPersonelGrubuModal, setIsPersonelGrubuModal] = useState(false);
  const [isDetailModal, setIsDetailModal] = useState(false);
  const [ulkeList, setUlkeList] = useState(null);
  const [filterLoading, setFilterLoading] = useState(false);
  const [selectedIrtibatTipleri, setSelectedIrtibatTipleri] = useState([]);
  const [selectedIrtibat, setSelectedIrtibat] = useState([]);
  const [activeSummaryTab, setActiveSummaryTab] = useState(1);
  const occRehberList = useAppSelector(state => state.occRehber.entities);
  const occRehberTotalItem = useAppSelector(state => state.occRehber.totalItems);
  const occRehberLoading = useAppSelector(state => state.occRehber.loading);
  const [searchOccRehber, setSearchOccRehber] = useState('');
  const [pageObjectOccRehber, setPageObjectOccRehber] = useState<IQueryParams>(useAppSelector(state => state.paginate.pageObject));

  const [pageObjectIrtibat, setPageObjectIrtibat] = useState<IQueryParams>({
    ...useAppSelector(state => state.paginate.pageObject),
    sort: `havalimaniIrtibatTipi.adi${upperOnlyFirstLetter(localeLanguage)},asc`,
  });
  const [totalItem, setTotalItem] = useState(0);

  const ExcelSampleData = [
    { key: 'koduIata', titleContentKey: 'tk24AdysApp.refHavalimani.koduIata' },
    { key: 'koduIcoa', titleContentKey: 'tk24AdysApp.refHavalimani.koduIcoa' },
    { key: 'refUlke.adi', titleContentKey: 'tk24AdysApp.refHavalimani.ulke' },
    { key: 'sehir', titleContentKey: 'tk24AdysApp.refHavalimani.sehir' },
    { key: 'adi', titleContentKey: 'tk24AdysApp.refHavalimani.adi' },
    {
      key: 'bilgiSonGuncellenmeZamani',
      format: 'date',
      titleContentKey: 'tk24AdysApp.refHavalimani.bilgiSonGuncellenmeZamani',
    },
  ];

  const AccordionExcelSampleData = [
    { key: 'havalimaniIrtibatTipi.adi', titleContentKey: 'tk24AdysApp.refHavalimaniIrtibat.irtibatTipi' },
    { key: 'adiSoyadiUnvani', titleContentKey: 'tk24AdysApp.refHavalimaniIrtibat.adiSoyadiUnvani' },
    { key: 'telefonNo1', titleContentKey: 'tk24AdysApp.refHavalimaniIrtibat.telefonNo1' },
    { key: 'telefonNo2', titleContentKey: 'tk24AdysApp.refHavalimaniIrtibat.telefonNo2' },
    { key: 'eposta', titleContentKey: 'tk24AdysApp.refHavalimaniIrtibat.eposta' },
    { key: 'aciklama', titleContentKey: 'tk24AdysApp.refHavalimaniIrtibat.aciklama' },
  ];

  const ExcelSampleDataOccRehber = [
    { key: 'numaraTipi', titleContentKey: 'tk24AdysApp.refHavalimani.numaraTipi' },
    { key: 'numara', titleContentKey: 'tk24AdysApp.refHavalimani.numara' },
    { key: 'unvan', titleContentKey: 'tk24AdysApp.refHavalimani.unvan' },
    { key: 'kisi', titleContentKey: 'tk24AdysApp.refHavalimani.kisi' },
    { key: 'dahiliDisi1', titleContentKey: 'tk24AdysApp.refHavalimani.dahiliDisi1' },
    { key: 'dahiliDisi2', titleContentKey: 'tk24AdysApp.refHavalimani.dahiliDisi2' },
    { key: 'dahiliDisi3', titleContentKey: 'tk24AdysApp.refHavalimani.dahiliDisi3' },
    { key: 'dahililer', titleContentKey: 'tk24AdysApp.refHavalimani.dahililer' },
  ];

  const getFilterData = async () => {
    setFilterLoading(false);
    const refUlkeUrl = `api/ref-ulkes?size=9999&sort=adi${upperOnlyFirstLetter(localeLanguage)},asc`;
    await axios.get(refUlkeUrl).then(response => {
      setUlkeList(response.data);
    });
    setFilterLoading(false);
  };

  useEffect(() => {
    getFilterData();
  }, []);

  const getAllEntities = () => {
    dispatch(getEntities(pageObject));
  };

  useEffect(() => {
    getAllEntities();
    setOpenItemId(null);
  }, [pageObject.page, pageObject.query, pageObject.size, pageObject.sort]);

  useEffect(() => {
    if (search.length > 2) {
      setPageObject(prevPageObject => ({
        ...prevPageObject,
        page: 0,
        query: prevPageObject.query + `&globalFilter=${search}`,
      }));
    } else if (search.length === 0) {
      const keyToDelete = `globalFilter`;
      const updatedParams = pageObject.query
        .split('&')
        .filter(param => {
          const [key, _] = param.split('=');
          return key !== keyToDelete;
        })
        .join('&');
      setPageObject({ ...pageObject, page: 0, query: updatedParams });
    }
  }, [search]);

  useEffect(() => {
    if (selectedIrtibatTipleri.length > 0) {
      loadRefHavalimaniIrtibat(
        selectedHavalimani.id,
        selectedIrtibatTipleri.map(tip => tip.id),
        pageObjectIrtibat.page,
        pageObjectIrtibat.size,
        pageObjectIrtibat.sort
      );
    } else if (selectedIrtibatTipleri.length === 0) {
      setSelectedIrtibat([]);
      setChecked(false);
    }
  }, [selectedIrtibatTipleri, pageObjectIrtibat.page, pageObjectIrtibat.size, pageObjectIrtibat.sort]);

  useEffect(() => {
    setSelectedIrtibatTipleri([]);
    dispatch(resetOccRehber());
  }, [openItemId]);

  useEffect(() => {
    if (totalItem) {
      setPageObjectIrtibat({ ...pageObjectIrtibat, page: 0, size: pageObjectIrtibat.size });
    }
  }, [totalItem]);

  useEffect(() => {
    if (occRehberList.length > 0) {
      getOccRehber();
    }
  }, [pageObjectOccRehber.page, pageObjectOccRehber.size, pageObjectOccRehber.sort]);

  useEffect(() => {
    if (selectedHavalimani) {
      if (searchOccRehber.length > 2) {
        dispatch(
          getOccRehberEntities({
            ...pageObjectOccRehber,
            havalimaniId: selectedHavalimani?.id,
            page: 0,
            query: `globalFilter=${searchOccRehber}`,
          })
        );
      } else if (searchOccRehber.length === 0) {
        dispatch(getOccRehberEntities({ ...pageObjectOccRehber, havalimaniId: selectedHavalimani?.id, page: 0, query: '' }));
      }
    }
  }, [searchOccRehber]);

  const getOccRehber = () => {
    if (hasAnyAuthority(['occ_rehber_read'])) {
      setSearchOccRehber('');
      dispatch(getOccRehberEntities({ ...pageObjectOccRehber, havalimaniId: selectedHavalimani?.id }));
    }
  };

  const handlePageChangeForOccRehber = (pageNumber, newItemsPerPage = pageObjectOccRehber.size) => {
    if (newItemsPerPage !== pageObjectOccRehber.size) {
      setPageObjectOccRehber({ ...pageObjectOccRehber, page: pageNumber - 1, size: newItemsPerPage });
    } else {
      setPageObjectOccRehber({ ...pageObjectOccRehber, page: pageNumber - 1 });
    }
  };

  const loadRefHavalimaniIrtibatTipi = async (havalimaniIdforIrtibatTipi: number) => {
    setIrtibatLoading(true);
    const kalitimciIletisimUrl = `api/ref-havalimani-irtibat-tipis?havalimaniId.equals=${havalimaniIdforIrtibatTipi}&size=100&sort=adi${upperOnlyFirstLetter(
      localeLanguage
    )}`;

    await axios.get(kalitimciIletisimUrl).then(response => {
      if (response.data && response.data.length > 0) {
        setRefHavalimaniIrtibatTipi(response.data);
        setIrtibatLoading(false);
      }
    });
  };

  const loadRefHavalimaniIrtibat = async (
    havalimaniIdforIrtibat: number,
    selectedTabIndexes: number[],
    page: number,
    size: number,
    sort: string
  ) => {
    setIrtibatLoading(true);

    const irtibatUrl = `api/ref-havalimani-irtibats?havalimaniId.equals=${havalimaniIdforIrtibat}&havalimaniIrtibatTipiId.in=${selectedTabIndexes.join(
      ','
    )}&page=${page}&size=${size}&sort=${sort}`;

    await axios.get(irtibatUrl).then(response => {
      setTotalItem(response.headers['x-total-count']);
      if (response.data.status === 403) {
        toast.error(translate(`error.http.${response.data.status}`));
        setIrtibatLoading(false);
        return;
      } else {
        response.data.forEach(item => {
          if (item.havalimani.id === selectedHavalimani.id) {
            if (selectedIrtibatTipleri.some(res => res.id === item.havalimaniIrtibatTipi.id)) {
              setSelectedIrtibat(response.data);
            }
          }
        });
      }
      setIrtibatLoading(false);
    });
  };

  const handlePageChangeForIrtibat = (pageNumber, newItemsPerPage = pageObjectIrtibat.size) => {
    if (newItemsPerPage !== pageObjectIrtibat.size) {
      setPageObjectIrtibat({ ...pageObjectIrtibat, page: pageNumber - 1, size: newItemsPerPage });
    } else {
      setPageObjectIrtibat({ ...pageObjectIrtibat, page: pageNumber - 1 });
    }
  };

  const sort = p => () => {
    const orderBy = pageObject.sort.split(',')[1];
    setPageObject({ ...pageObject, sort: `${p},${orderBy === ASC ? DESC : ASC}` });
  };

  const sortOccRehber = p => () => {
    const orderBy = pageObjectOccRehber.sort.split(',')[1];
    setPageObjectOccRehber({ ...pageObjectOccRehber, sort: `${p},${orderBy === ASC ? DESC : ASC}` });
  };

  const handlePageChange = (pageNumber, newItemsPerPage = pageObject.size) => {
    if (newItemsPerPage !== pageObject.size) {
      setPageObject({ ...pageObject, page: pageNumber - 1, size: newItemsPerPage });
    } else {
      setPageObject({ ...pageObject, page: pageNumber - 1 });
    }
  };

  const handleCheckboxChange = isChecked => {
    setChecked(isChecked);
    if (isChecked) {
      setSelectedIrtibatTipleri(refHavalimaniIrtibatTipi);
    } else {
      setSelectedIrtibatTipleri([]);
    }
  };

  const getHavalimaniIrtibatTable = async (refHavalimani: IRefHavalimani) => {
    setChecked(false);
    if (openItemId === refHavalimani.id) {
      setOpenItemId(null);
      setActiveSummaryTab(1);
      setSelectedHavalimani(null);
      setSelectedIrtibatTipleri([]);
    } else {
      setOpenItemId(refHavalimani.id);
      setActiveSummaryTab(1);
      setSelectedHavalimani(refHavalimani);
      await loadRefHavalimaniIrtibatTipi(refHavalimani.id);
    }
  };

  const fetchTotalHavalimaniData = async (): Promise<IRefHavalimani[]> => {
    try {
      const response = (await dispatch(getTotalEntities({ ...pageObject, size: totalItems }))) as {
        payload: { data: IRefHavalimani[] };
      };

      return response.payload.data || [];
    } catch (error) {
      console.error('Error fetching data:', error);
      return [];
    }
  };

  const fetchAllIrtibatData = async havalimaniId => {
    try {
      if (selectedIrtibat.length > 0) {
        const irtibatUrl = `api/ref-havalimani-irtibats?havalimaniId.equals=${havalimaniId}&havalimaniIrtibatTipiId.in=${selectedIrtibatTipleri
          .map(res => res.id)
          .join(',')}&size=${totalItem}&sort=${pageObjectIrtibat.sort}`;
        const response = await axios.get(irtibatUrl);
        if (response.data) {
          return response.data;
        } else {
          console.error('No data returned from API');
          return [];
        }
      }
    } catch (error) {
      console.error('Error fetching all irtibat data:', error);
      return [];
    }
  };

  const handleClosePersonelGrubuModal = () => {
    setIsPersonelGrubuModal(false);
    setSelectedHavalimani(null);
  };

  const PersonelGrubuModal = () => {
    return (
      <Modal isOpen={isPersonelGrubuModal} toggle={handleClosePersonelGrubuModal} className="modal-style-xlg">
        <ModalHeader data-cy="refEntityDetailsHeading" toggle={handleClosePersonelGrubuModal}>
          <p className="modal-title-info">{selectedHavalimani?.adi}</p>
          <Translate contentKey="tk24AdysApp.refIstasyonGorevGrubu.detail.titlePersonel" />
        </ModalHeader>
        <ModalBody style={{ height: '100%' }}>
          <RefIstGorevGrubuPersonel havalimani={selectedHavalimani} />
        </ModalBody>
      </Modal>
    );
  };

  const havalimaniDetail = async havalimani => {
    await dispatch(getEntity(havalimani.id));
    setIsDetailModal(true);
  };

  const handleCloseDetailForm = () => {
    setIsDetailModal(false);
  };

  const HavalimaniDetailModal = () => {
    return <RefHavalimaniDetail handleCloseForm={handleCloseDetailForm} isOpen={isDetailModal} />;
  };

  const fetchTotalOccRehberData = async (): Promise<IOccRehber[]> => {
    try {
      const response = (await dispatch(
        getTotalOccRehberEntities({
          ...pageObjectOccRehber,
          size: occRehberTotalItem,
          havalimaniId: selectedHavalimani?.id,
          query: searchOccRehber ? `globalFilter=${searchOccRehber}` : '',
        })
      )) as { payload: { data: IOccRehber[] } };

      return response.payload.data || [];
    } catch (error) {
      console.error('Error fetching data:', error);
      return [];
    }
  };

  const handleSearchChange = event => {
    if (!isNullOrEmpty(event.target.value)) {
      setPageObject(prevPageObject => ({
        ...prevPageObject,
        page: 0,
        query: prevPageObject.query + `&ulkeKodu.equals=${event.target.value}`,
      }));
    } else {
      const keyToDelete = 'ulkeKodu.equals';
      const updatedParams = pageObject.query
        .split('&')
        .filter(param => {
          const [key, _] = param.split('=');
          return key !== keyToDelete;
        })
        .join('&');

      setPageObject({ ...pageObject, page: 0, query: updatedParams });
    }
  };

  const handleAcilDurumRaporuClick = async havalimani => {
    setFilterLoading(true);
    try {
      const responseData = await axios.post(`api/report/acil-durum-organizasyon-raporu/${havalimani.id}`);
      try {
        const response = await axios.get(`api/ek-dosyas/download/${responseData.data.id}`, {
          responseType: 'blob',
        });
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${responseData.data.dosyaAdi}`);
        document.body.appendChild(link);
        link.click();
      } catch (error) {
        console.error('Dosya indirme hatası:', error);
      }
      toast.success(
        <Translate contentKey="entity.rapor.generateReport" interpolate={{ title: translate('entity.action.acilDurumRaporu') }} />
      );
    } catch (error) {
      console.error('Dosya indirme hatası:', error);
      toast.error(
        <Translate contentKey="entity.rapor.error.generateReport" interpolate={{ title: translate('entity.action.acilDurumRaporu') }} />
      );
    } finally {
      setFilterLoading(false);
    }
  };

  return (
    <div>
      {(loading || irtibatLoading || filterLoading || occRehberLoading) && <LoadingSpinner />}
      {isPersonelGrubuModal && PersonelGrubuModal()}
      {isDetailModal && <HavalimaniDetailModal />}
      <div style={{ display: 'flex', gap: 8, textAlign: 'center' }}>
        <p className="icon-text">
          <a className="link-a-text" href={SERVER_URL}>
            <img width={13} height={13} src={`${SERVER_URL}/content/images/icon/faHouse.svg`} />
          </a>
        </p>
        <p className="separator"> / </p>
        <p className="link-text">
          {' '}
          <Translate contentKey="tk24AdysApp.refHavalimani.home.title" />{' '}
        </p>
      </div>

      <h2 id="ref-ek-dosya-tipi-heading" data-cy="RefEkDosyaTipiHeading" className="title-frame">
        <a className="link-a-text" onClick={() => props.history.goBack()}>
          <img width={27.5} height={22.5} src={`${SERVER_URL}/content/images/icon/faArrowLeft.svg`} />{' '}
        </a>
        <p className="title-text">
          <Translate contentKey="tk24AdysApp.refHavalimani.home.title" />
        </p>
      </h2>

      <div className="content-area">
        <div className="search-frame">
          <div className="seachiconposition">
            <img className="seachicon" width={24} height={24} src={`${SERVER_URL}/content/images/icon/faSearch.svg`} />
            <Input
              value={search}
              onChange={e => setSearch(e.target.value)}
              placeholder={translate('entity.action.search')}
              className="search-input"
            />
          </div>
          <div className="d-flex justify-end">
            <Box sx={{ minWidth: 220, maxWidth: 220, marginRight: '15px' }}>
              <FormControl style={{ minHeight: 48, maxHeight: 48 }} fullWidth>
                <InputLabel id="ulke-label" className="select-input-label">
                  <Translate contentKey="tk24AdysApp.refHavalimani.ulke" />
                </InputLabel>
                <Select
                  labelId="ulke-label"
                  id="ulke"
                  label={translate('tk24AdysApp.refHavalimani.ulke')}
                  onChange={handleSearchChange}
                  MenuProps={MenuProps}
                  className="select-input"
                >
                  <MenuItem value="">
                    <Translate contentKey="entity.action.all" />
                  </MenuItem>
                  {ulkeList &&
                    ulkeList.map(item => (
                      <MenuItem key={item.id} value={item.kodu2Harf}>
                        {item.adi}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Box>
            <ExcelDownloadButton
              excelSampleData={ExcelSampleData}
              grantedAuthorites={['ref_havalimani_export']}
              fetchData={fetchTotalHavalimaniData}
              className={'download-button'}
              exportName={translate('tk24AdysApp.refHavalimani.home.title')}
            />
          </div>
        </div>
        <div className="table-responsive" style={{ marginTop: 5 }}>
          <TableContainer className="content-table">
            {refHavalimaniList && refHavalimaniList.length > 0 ? (
              <Table aria-label="collapsible table">
                <TableHead>
                  <TableRow className="accordion-title-row">
                    <TableCell className="accordion-title-cell" style={{ width: 48 }} />
                    <TableCell className="accordion-title-cell hand" align="left" onClick={sort('koduIata')}>
                      <div className="tableHeaderContainer">
                        <Translate contentKey="tk24AdysApp.refHavalimani.koduIata">IATA Kodu</Translate>&nbsp;
                        <SortIcon column="koduIata" activeSortColumn={pageObject.sort} />
                      </div>
                    </TableCell>
                    <TableCell className="accordion-title-cell hand" align="left" onClick={sort('koduIcoa')}>
                      <div className="tableHeaderContainer">
                        <Translate contentKey="tk24AdysApp.refHavalimani.koduIcoa">ICOA Kodu</Translate>&nbsp;
                        <SortIcon column="koduIcoa" activeSortColumn={pageObject.sort} />
                      </div>
                    </TableCell>
                    <TableCell className="accordion-title-cell hand" align="left" onClick={sort('ulke')}>
                      <div className="tableHeaderContainer">
                        <Translate contentKey="tk24AdysApp.refHavalimani.ulke">Ulke</Translate>&nbsp;
                        <SortIcon column="ulke" activeSortColumn={pageObject.sort} />
                      </div>
                    </TableCell>
                    <TableCell className="accordion-title-cell hand" align="left" onClick={sort('sehir')}>
                      <div className="tableHeaderContainer">
                        <Translate contentKey="tk24AdysApp.refHavalimani.sehirTitle">Sehir</Translate>&nbsp;
                        <SortIcon column="sehir" activeSortColumn={pageObject.sort} />
                      </div>
                    </TableCell>
                    <TableCell className="accordion-title-cell hand" align="left" onClick={sort('adi')}>
                      <div className="tableHeaderContainer">
                        <Translate contentKey="tk24AdysApp.refHavalimani.adi">Adi</Translate>&nbsp;
                        <SortIcon column="adi" activeSortColumn={pageObject.sort} />
                      </div>
                    </TableCell>
                    <TableCell className="accordion-title-cell hand" align="left" onClick={sort('bilgiSonGuncellenmeZamani')}>
                      <div className="tableHeaderContainer">
                        <Translate contentKey="tk24AdysApp.refHavalimani.bilgiSonGuncellenmeZamaniTitle">
                          Bilgi Son Guncellenme Zamani
                        </Translate>
                        &nbsp;
                        <SortIcon column="bilgiSonGuncellenmeZamani" activeSortColumn={pageObject.sort} />
                      </div>
                    </TableCell>
                    <TableCell className="sticky-right-header-accordion hand" align="left"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {refHavalimaniList.map(havalimani => (
                    <>
                      <TableRow
                        className="accordion-content-row"
                        sx={{ '& > *': { borderBottom: 'unset' } }}
                        onClick={() => getHavalimaniIrtibatTable(havalimani)}
                      >
                        <TableCell className="accordion-content-cell" component="th" scope="row" style={{ width: 48 }}>
                          <IconButton aria-label="expand row" size="small" className="accordion-icon">
                            {openItemId === havalimani.id ? <KeyboardArrowDownOutlined /> : <KeyboardArrowRight />}
                          </IconButton>
                        </TableCell>
                        <TableCell className="accordion-content-cell" component="th" scope="row">
                          {havalimani.koduIata}
                        </TableCell>
                        <TableCell className="accordion-content-cell" component="th" scope="row">
                          {havalimani.koduIcoa}
                        </TableCell>
                        <TableCell className="accordion-content-cell" component="th" scope="row">
                          {havalimani.refUlke?.adi}
                        </TableCell>
                        <TableCell className="accordion-content-cell" component="th" scope="row">
                          {havalimani.sehir}
                        </TableCell>
                        <TableCell className="accordion-content-cell" component="th" scope="row">
                          {havalimani.adi}
                        </TableCell>
                        <TableCell className="accordion-content-cell" component="th" scope="row">
                          {' '}
                          <TextFormat value={havalimani.mDate} type="date" format={APP_DATE_FORMAT} />
                        </TableCell>
                        <TableCell className="sticky-right" align="right">
                          <div className="btn-group flex-btn-group-container">
                            <GrantedButton
                              id="create-button"
                              grantedAuthorites={['kriz_havalimani_edit']}
                              className="view-button"
                              size="sm"
                              data-cy="entityCreateButton"
                              style={{ marginRight: '8px' }}
                              onClick={event => {
                                event.stopPropagation();
                                handleAcilDurumRaporuClick(havalimani);
                              }}
                            >
                              <Tooltip title={<Translate contentKey="entity.action.rapor"></Translate>}>
                                <img width={15} height={15} src={`${SERVER_URL}/content/images/icon/reportIcon.svg`} />
                              </Tooltip>
                              <span className="show-on-lg">
                                <Translate contentKey="entity.action.rapor"></Translate>
                              </span>
                            </GrantedButton>
                            <GrantedButton
                              grantedAuthorites={['ref_ist_gorev_grubu_personel_read']}
                              className="view-button"
                              onClick={event => {
                                event.stopPropagation();
                                setSelectedHavalimani(havalimani);
                                setIsPersonelGrubuModal(true);
                              }}
                              size="sm"
                              data-cy="entityEditButton"
                            >
                              <Tooltip title={<Translate contentKey="entity.action.organizasyonSema" />}>
                                <img width={15.2} height={11.2} src={`${SERVER_URL}/content/images/icon/faEye.svg`} />
                              </Tooltip>
                              <span className="show-on-lg">
                                <Translate contentKey="entity.action.organizasyonSema" />
                              </span>
                            </GrantedButton>
                            <GrantedButton
                              grantedAuthorites={['kriz_havalimani_read']}
                              className="view-button"
                              onClick={event => {
                                event.stopPropagation();
                                havalimaniDetail(havalimani);
                              }}
                              style={{ marginLeft: 8 }}
                              size="sm"
                              data-cy="entityDetailsButton"
                            >
                              <Tooltip title={<Translate contentKey="entity.action.detail">Detay</Translate>}>
                                <img width={15.2} height={11.2} src={`${SERVER_URL}/content/images/icon/faEye.svg`} />
                              </Tooltip>
                              <span className="show-on-lg">
                                <Translate contentKey="entity.action.detail">Detay</Translate>
                              </span>
                            </GrantedButton>
                          </div>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className="accordion-content-area" colSpan={12}>
                          <Collapse in={openItemId === havalimani.id} unmountOnExit>
                            <Nav className="custom-nav-tabs mt-3" tabs>
                              <NavItem>
                                <NavLink
                                  className={classnames('custom-nav-link', { active: activeSummaryTab === 1 })}
                                  onClick={() => {
                                    setActiveSummaryTab(1);
                                  }}
                                >
                                  <Translate contentKey="tk24AdysApp.refHavalimani.acilDurumIrtibatBilgileri" />
                                </NavLink>
                              </NavItem>
                              <NavItem>
                                <NavLink
                                  className={classnames('custom-nav-link', { active: activeSummaryTab === 2 })}
                                  onClick={() => {
                                    setActiveSummaryTab(2);
                                    getOccRehber();
                                  }}
                                >
                                  <Translate contentKey="tk24AdysApp.refHavalimani.occRehber" />
                                </NavLink>
                              </NavItem>
                            </Nav>
                            <TabContent activeTab={activeSummaryTab}>
                              <TabPane tabId={1}>
                                <Box className="accordion-box">
                                  <div
                                    style={{
                                      padding: '20px 10px 10px 10px',
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'space-between',
                                      width: '100%',
                                    }}
                                  >
                                    <div style={{ display: 'flex' }}>
                                      <Checkbox
                                        checked={checked}
                                        onChange={e => handleCheckboxChange(e.target.checked)}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                      />
                                      <div className="havalimani-checkbox-text" onClick={() => handleCheckboxChange(!checked)}>
                                        {checked ? (
                                          <Translate contentKey="entity.action.deselectAll">Seçimleri Kaldır</Translate>
                                        ) : (
                                          <Translate contentKey="entity.action.selectAll">Tümünü Seç</Translate>
                                        )}
                                      </div>
                                    </div>
                                    <ExcelDownloadButton
                                      excelSampleData={AccordionExcelSampleData}
                                      grantedAuthorites={['kriz_irtibat_export']}
                                      fetchData={() => fetchAllIrtibatData(selectedHavalimani.id)}
                                      className={'download-button'}
                                      exportName={translate('tk24AdysApp.refHavalimaniIrtibat.home.title')}
                                    />
                                  </div>

                                  <div style={{ margin: '10px' }}>
                                    <Autocomplete
                                      multiple
                                      id="irtibat-tipleri-autocomplete"
                                      options={refHavalimaniIrtibatTipi}
                                      disableCloseOnSelect
                                      getOptionLabel={option => option.adi}
                                      value={selectedIrtibatTipleri}
                                      className="custom-autocomplete"
                                      onChange={(event, newValue) => {
                                        setSelectedIrtibatTipleri(newValue);
                                      }}
                                      renderOption={(propsAutocomplete, option, { selected }) => (
                                        <li {...propsAutocomplete}>
                                          <Checkbox
                                            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                            checkedIcon={<CheckBoxIcon fontSize="small" />}
                                            checked={selected}
                                          />
                                          {option.adi}
                                        </li>
                                      )}
                                      renderTags={(value, getTagProps) =>
                                        value.map((option, index) => (
                                          <Chip
                                            key={option.id}
                                            label={option.adi}
                                            {...getTagProps({ index })}
                                            deleteIcon={<CloseIcon />}
                                            className="custom-chip"
                                          />
                                        ))
                                      }
                                      renderInput={params => (
                                        <TextField
                                          {...params}
                                          label={translate('tk24AdysApp.refHavalimaniIrtibat.irtibatTipi')}
                                          placeholder={translate('entity.action.search')}
                                        />
                                      )}
                                      noOptionsText={translate('entity.action.notFound')}
                                    />
                                  </div>

                                  {selectedIrtibatTipleri.length > 0 ? (
                                    <>
                                      <hr style={{ margin: '20px 10px 10px 10px' }} />
                                      <Box style={{ width: '100%', padding: '10px' }}>
                                        {selectedIrtibat.length > 0 ? (
                                          <>
                                            <Table className="accordion-table" style={{ marginBottom: '0px' }}>
                                              <TableHead>
                                                <TableRow className="accordion-table-title-row">
                                                  <TableCell
                                                    className="accordion-table-title-cell"
                                                    align="left"
                                                    onClick={sort(`havalimaniIrtibatTipi.adi${upperOnlyFirstLetter(localeLanguage)}`)}
                                                  >
                                                    <div className="tableHeaderContainer">
                                                      <Translate contentKey="tk24AdysApp.refHavalimaniIrtibat.irtibatTipi">
                                                        İrtibat Tipi
                                                      </Translate>
                                                      <SortIcon
                                                        column={`havalimaniIrtibatTipi.adi${upperOnlyFirstLetter(localeLanguage)}`}
                                                        activeSortColumn={pageObjectIrtibat.sort}
                                                      />
                                                    </div>
                                                  </TableCell>
                                                  <TableCell
                                                    className="accordion-table-title-cell"
                                                    align="left"
                                                    onClick={sort('adiSoyadiUnvani')}
                                                  >
                                                    <div className="tableHeaderContainer">
                                                      <Translate contentKey="tk24AdysApp.refHavalimaniIrtibat.adiSoyadiUnvaniTitle">
                                                        Ad/Soyad/Unvan
                                                      </Translate>
                                                      <SortIcon column="adiSoyadiUnvani" activeSortColumn={pageObjectIrtibat.sort} />
                                                    </div>
                                                  </TableCell>
                                                  <TableCell
                                                    className="accordion-table-title-cell"
                                                    align="left"
                                                    onClick={sort('telefonNo1')}
                                                  >
                                                    <div className="tableHeaderContainer">
                                                      <Translate contentKey="tk24AdysApp.refHavalimaniIrtibat.telefonNo1">
                                                        Tel No 1
                                                      </Translate>
                                                      <SortIcon column="telefonNo1" activeSortColumn={pageObjectIrtibat.sort} />
                                                    </div>
                                                  </TableCell>
                                                  <TableCell
                                                    className="accordion-table-title-cell"
                                                    align="left"
                                                    onClick={sort('telefonNo2')}
                                                  >
                                                    <div className="tableHeaderContainer">
                                                      <Translate contentKey="tk24AdysApp.refHavalimaniIrtibat.telefonNo2">
                                                        Tel No 2
                                                      </Translate>
                                                      <SortIcon column="telefonNo2" activeSortColumn={pageObjectIrtibat.sort} />
                                                    </div>
                                                  </TableCell>
                                                  <TableCell className="accordion-table-title-cell" align="left" onClick={sort('eposta')}>
                                                    <div className="tableHeaderContainer">
                                                      <Translate contentKey="tk24AdysApp.refHavalimaniIrtibat.epostaTitle">
                                                        E-Posta
                                                      </Translate>
                                                      <SortIcon column="eposta" activeSortColumn={pageObjectIrtibat.sort} />
                                                    </div>
                                                  </TableCell>
                                                  <TableCell className="accordion-table-title-cell" align="left" onClick={sort('aciklama')}>
                                                    <div className="tableHeaderContainer">
                                                      <Translate contentKey="tk24AdysApp.refHavalimaniIrtibat.aciklamaTitle">
                                                        Açıklama
                                                      </Translate>
                                                      <SortIcon column="aciklama" activeSortColumn={pageObjectIrtibat.sort} />
                                                    </div>
                                                  </TableCell>
                                                </TableRow>
                                              </TableHead>
                                              {selectedIrtibat.map(item => (
                                                <>
                                                  <TableBody style={{ backgroundColor: '#fff' }}>
                                                    <TableRow key={item.id} className="accordion-table-content-row">
                                                      <TableCell className="accordion-table-content-cell" component="th" scope="row">
                                                        <b>{item?.havalimaniIrtibatTipi?.adi}</b>
                                                      </TableCell>
                                                      <TableCell className="accordion-table-content-cell" component="th" scope="row">
                                                        {item.adiSoyadiUnvani}
                                                      </TableCell>
                                                      <TableCell className="accordion-table-content-cell" component="th" scope="row">
                                                        {item.telefonNo1}
                                                      </TableCell>
                                                      <TableCell className="accordion-table-content-cell" component="th" scope="row">
                                                        {item.telefonNo2}
                                                      </TableCell>
                                                      <TableCell className="accordion-table-content-cell" component="th" scope="row">
                                                        <a href={`mailto:${item.eposta}`} className="yakinEpostaText">
                                                          {item.eposta}
                                                        </a>
                                                      </TableCell>
                                                      <TableCell className="accordion-table-content-cell" component="th" scope="row">
                                                        {item.aciklama}
                                                      </TableCell>
                                                    </TableRow>
                                                  </TableBody>
                                                </>
                                              ))}
                                            </Table>
                                            <CustomPagination
                                              currentPage={pageObjectIrtibat.page + 1}
                                              currentPageSize={pageObjectIrtibat.size}
                                              totalItems={totalItem}
                                              handlePageChange={handlePageChangeForIrtibat}
                                            />
                                          </>
                                        ) : (
                                          !irtibatLoading && (
                                            <div className="alert alert-warning">
                                              <Translate contentKey="tk24AdysApp.refHavalimaniIrtibat.home.notFound">Bulunamadı</Translate>
                                            </div>
                                          )
                                        )}
                                      </Box>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </Box>
                              </TabPane>
                              <TabPane tabId={2}>
                                <HasAuthComponent grantedAuthorites={['occ_rehber_read']}>
                                  <div className="search-frame">
                                    <div className="seachiconposition">
                                      <img
                                        className="seachicon"
                                        width={24}
                                        height={24}
                                        src={`${SERVER_URL}/content/images/icon/faSearch.svg`}
                                      />
                                      <Input
                                        value={searchOccRehber}
                                        onChange={e => setSearchOccRehber(e.target.value)}
                                        placeholder={translate('entity.action.search')}
                                        className="search-input"
                                      />
                                    </div>
                                    <div className="d-flex justify-end">
                                      <ExcelDownloadButton
                                        excelSampleData={ExcelSampleDataOccRehber}
                                        grantedAuthorites={['kriz_havalimani_export']}
                                        fetchData={fetchTotalOccRehberData}
                                        className={'download-button'}
                                        exportName={translate('tk24AdysApp.refHavalimani.occRehber')}
                                      />
                                    </div>
                                  </div>

                                  <Box className="accordion-box">
                                    {occRehberList && occRehberList.length > 0 ? (
                                      <>
                                        <Table className="accordion-table">
                                          <TableHead>
                                            <TableRow className="accordion-table-title-row">
                                              <TableCell
                                                className="accordion-table-title-cell hand"
                                                align="left"
                                                onClick={sortOccRehber('numaraTipi')}
                                              >
                                                <div className="tableHeaderContainer">
                                                  <Translate contentKey="tk24AdysApp.refHavalimani.numaraTipiTitle">Numara Tipi</Translate>
                                                  &nbsp;
                                                  <SortIcon column="numaraTipi" activeSortColumn={pageObjectOccRehber.sort} />
                                                </div>
                                              </TableCell>
                                              <TableCell
                                                className="accordion-table-title-cell hand"
                                                align="left"
                                                onClick={sortOccRehber('numara')}
                                              >
                                                <div className="tableHeaderContainer">
                                                  <Translate contentKey="tk24AdysApp.refHavalimani.numaraTitle">Numara</Translate>&nbsp;
                                                  <SortIcon column="numara" activeSortColumn={pageObjectOccRehber.sort} />
                                                </div>
                                              </TableCell>
                                              <TableCell
                                                className="accordion-table-title-cell hand"
                                                align="left"
                                                onClick={sortOccRehber('unvan')}
                                              >
                                                <div className="tableHeaderContainer">
                                                  <Translate contentKey="tk24AdysApp.refHavalimani.unvanTitle">Ünvan</Translate>&nbsp;
                                                  <SortIcon column="unvan" activeSortColumn={pageObjectOccRehber.sort} />
                                                </div>
                                              </TableCell>
                                              <TableCell
                                                className="accordion-table-title-cell hand"
                                                align="left"
                                                onClick={sortOccRehber('kisi')}
                                              >
                                                <div className="tableHeaderContainer">
                                                  <Translate contentKey="tk24AdysApp.refHavalimani.kisiTitle">Kişi</Translate>&nbsp;
                                                  <SortIcon column="kisi" activeSortColumn={pageObjectOccRehber.sort} />
                                                </div>
                                              </TableCell>
                                              <TableCell
                                                className="accordion-table-title-cell hand"
                                                align="left"
                                                onClick={sortOccRehber('dahiliDisi1')}
                                              >
                                                <div className="tableHeaderContainer">
                                                  <Translate contentKey="tk24AdysApp.refHavalimani.dahiliDisi1Title">
                                                    Dahili Dışı1
                                                  </Translate>
                                                  &nbsp;
                                                  <SortIcon column="dahiliDisi1" activeSortColumn={pageObjectOccRehber.sort} />
                                                </div>
                                              </TableCell>
                                              <TableCell
                                                className="accordion-table-title-cell hand"
                                                align="left"
                                                onClick={sortOccRehber('dahiliDisi2')}
                                              >
                                                <div className="tableHeaderContainer">
                                                  <Translate contentKey="tk24AdysApp.refHavalimani.dahiliDisi2Title">
                                                    Dahili Dışı2
                                                  </Translate>
                                                  &nbsp;
                                                  <SortIcon column="dahiliDisi2" activeSortColumn={pageObjectOccRehber.sort} />
                                                </div>
                                              </TableCell>
                                              <TableCell
                                                className="accordion-table-title-cell hand"
                                                align="left"
                                                onClick={sortOccRehber('dahiliDisi3')}
                                              >
                                                <div className="tableHeaderContainer">
                                                  <Translate contentKey="tk24AdysApp.refHavalimani.dahiliDisi3Title">
                                                    Dahili Dışı3
                                                  </Translate>
                                                  &nbsp;
                                                  <SortIcon column="dahiliDisi3" activeSortColumn={pageObjectOccRehber.sort} />
                                                </div>
                                              </TableCell>
                                              <TableCell
                                                className="accordion-table-title-cell hand"
                                                align="left"
                                                onClick={sortOccRehber('dahililer')}
                                              >
                                                <div className="tableHeaderContainer">
                                                  <Translate contentKey="tk24AdysApp.refHavalimani.dahililerTitle">Dahililer</Translate>
                                                  &nbsp;
                                                  <SortIcon column="dahililer" activeSortColumn={pageObjectOccRehber.sort} />
                                                </div>
                                              </TableCell>
                                            </TableRow>
                                          </TableHead>
                                          <TableBody style={{ backgroundColor: '#fff' }}>
                                            {occRehberList.map(rehber => (
                                              <TableRow key={rehber.id} className="accordion-table-cell-row">
                                                <TableCell className="accordion-table-content-cell" component="th" scope="row">
                                                  {rehber?.numaraTipi}
                                                </TableCell>
                                                <TableCell className="accordion-table-content-cell" component="th" scope="row">
                                                  {rehber?.numara}
                                                </TableCell>
                                                <TableCell className="accordion-table-content-cell" component="th" scope="row">
                                                  {rehber?.unvan}
                                                </TableCell>
                                                <TableCell className="accordion-table-content-cell" component="th" scope="row">
                                                  {rehber?.kisi}
                                                </TableCell>
                                                <TableCell className="accordion-table-content-cell" component="th" scope="row">
                                                  {rehber?.dahiliDisi1}
                                                </TableCell>
                                                <TableCell className="accordion-table-content-cell" component="th" scope="row">
                                                  {rehber?.dahiliDisi2}
                                                </TableCell>
                                                <TableCell className="accordion-table-content-cell" component="th" scope="row">
                                                  {rehber?.dahiliDisi3}
                                                </TableCell>
                                                <TableCell className="accordion-table-content-cell" component="th" scope="row">
                                                  {rehber?.dahililer}
                                                </TableCell>
                                              </TableRow>
                                            ))}
                                          </TableBody>
                                        </Table>
                                        <CustomPagination
                                          currentPage={pageObjectOccRehber.page + 1}
                                          currentPageSize={pageObjectOccRehber.size}
                                          totalItems={occRehberTotalItem}
                                          handlePageChange={handlePageChangeForOccRehber}
                                        />
                                      </>
                                    ) : (
                                      !occRehberLoading && (
                                        <div className="alert alert-warning mt-3">
                                          <Translate contentKey="tk24AdysApp.refHavalimani.home.notOccRehberFound">
                                            No OccRehber found
                                          </Translate>
                                        </div>
                                      )
                                    )}
                                  </Box>
                                </HasAuthComponent>
                              </TabPane>
                            </TabContent>
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    </>
                  ))}
                </TableBody>
              </Table>
            ) : (
              !loading && (
                <div className="alert alert-warning mt-3">
                  <Translate contentKey="tk24AdysApp.refHavalimani.home.notFound">No refHavalimani found</Translate>
                </div>
              )
            )}
          </TableContainer>
        </div>
        <CustomPagination
          currentPage={pageObject.page + 1}
          currentPageSize={pageObject.size}
          totalItems={totalItems}
          handlePageChange={handlePageChange}
        />
      </div>
    </div>
  );
};

export default RefHavalimani;
