import React, { useState, useEffect, useCallback } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Table } from 'reactstrap';
import { Translate, translate } from 'app/component/jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { createEntity, deleteEntity, getEntities, updateEntity, getTotalEntities } from './bildirim-sablonu-kisi-tipi.reducer';
import { ASC, DESC } from 'app/shared/util/pagination.constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { LoadingSpinner } from 'app/component/LoadingSpinner';
import { faArrowUp, faArrowDown, faHome } from '@fortawesome/free-solid-svg-icons';
import { Box, FormControl, InputLabel, MenuItem, Select, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import CustomPagination from 'app/component/CustomPagination';
import { BildirimSablonKisiTipiUpdate } from './bildirim-sablon-kisi-tipi-update';
import { CustomDeleteEntityConfirm, CustomSaveEntityConfirm, isNullOrEmpty } from 'app/shared/util/confirm-utils';
import { getReferansByTip } from 'app/entities/referans/referans.reducer';
import { getEntities as getBildirimSablonu } from 'app/entities/bildirim-sablonu/bildirim-sablonu.reducer';
import CustomDownloadExcelButton from '../customDownloadExcel';
import { IRefBildirimSablonuKisiTipi } from 'app/shared/model/bildirim-sablonu-kisi-tipi.model';
import GrantedButton from 'app/component/GrantedButton';
import { SERVER_URL } from 'app/config/constants';
import { IQueryParams } from 'app/shared/reducers/reducer.utils';

export const BildirimSablonuKisiTipi = (props: RouteComponentProps<{ url: string }>) => {
  const dispatch = useAppDispatch();

  const bildirimSablonuKisiTipiList = useAppSelector(state => state.bildirimSablonuKisiTipi.entities);
  const loading = useAppSelector(state => state.bildirimSablonuKisiTipi.loading);
  const updating = useAppSelector(state => state.bildirimSablonuKisiTipi.updating);
  const totalItem = useAppSelector(state => state.bildirimSablonuKisiTipi.totalItems);

  const [isBildirimSablonKisiTipiUpdateModal, setIsBildirimSablonKisiTipiUpdateModal] = useState(false);
  const [selectedBildirimSablonKisiTipi, setSelectedBildirimSablonKisiTipi] = useState(null);
  const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);
  const [modalPromiseResolve, setModalPromiseResolve] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const bildirimKisiTipiList = useAppSelector(state => state.referans.entities);
  const bildirimSablonuList = useAppSelector(state => state.bildirimSablonu.entities);

  const [pageObject, setPageObject] = useState<IQueryParams>(useAppSelector(state => state.paginate.pageObject));

  const getAllEntities = () => {
    dispatch(getEntities(pageObject));
  };

  useEffect(() => {
    dispatch(getReferansByTip('BILDIRIM_KISI_TIPI'));
    dispatch(getBildirimSablonu({ size: 2000, page: 0, sort: 'asc' }));
  }, []);

  useEffect(() => {
    getAllEntities();
  }, [pageObject.page, pageObject.query, pageObject.size, pageObject.sort]);

  const sort = p => () => {
    const orderBy = pageObject.sort.split(',')[1];
    setPageObject({ ...pageObject, sort: `${p},${orderBy === ASC ? DESC : ASC}` });
  };

  const handlePageChange = (pageNumber, newItemsPerPage = pageObject.size) => {
    if (newItemsPerPage !== pageObject.size) {
      setPageObject({ ...pageObject, page: pageNumber - 1, size: newItemsPerPage });
    } else {
      setPageObject({ ...pageObject, page: pageNumber - 1 });
    }
  };

  const handleCloseUpdateForm = () => {
    if (isBildirimSablonKisiTipiUpdateModal) {
      setIsBildirimSablonKisiTipiUpdateModal(false);
    }
    setSelectedBildirimSablonKisiTipi(null);
  };

  const showSaveConfirmModal = () => {
    setIsSaveModalOpen(true);
    return new Promise(resolve => {
      setModalPromiseResolve(() => resolve);
    });
  };

  const handleConfirm = () => {
    if (modalPromiseResolve) {
      modalPromiseResolve(true);
    }
    setIsSaveModalOpen(false);
  };

  const handleCancel = () => {
    if (modalPromiseResolve) {
      modalPromiseResolve(false);
    }
    setIsSaveModalOpen(false);
  };

  const saveNewBildirimSablonKisiTipi = async e => {
    e.preventDefault();

    setIsBildirimSablonKisiTipiUpdateModal(false);

    const isSave = await showSaveConfirmModal();
    if (isSave) {
      const formDataObject = {};
      for (const field of e.target.elements) {
        if (field.name) {
          formDataObject[field.name] = field.value;
        }
      }
      const bildirimKisiTipiEleman = bildirimKisiTipiList.find(it => it.id.toString() === formDataObject['bildirimKisiTipiId'].toString());
      const bildirimSablonEleman = bildirimSablonuList.find(it => it.id.toString() === formDataObject['bildirimSablonId'].toString());

      const refBildirimSablonKisiTipiEntity = {
        ...selectedBildirimSablonKisiTipi,
        ...formDataObject,
        bildirimKisiTipi: bildirimKisiTipiEleman ? bildirimKisiTipiEleman : null,
        bildirimSablon: bildirimSablonEleman ? bildirimSablonEleman : null,
      };

      if (selectedBildirimSablonKisiTipi !== null) {
        await dispatch(updateEntity({ ...pageObject, entity: refBildirimSablonKisiTipiEntity }));
      } else {
        await dispatch(createEntity({ ...pageObject, entity: refBildirimSablonKisiTipiEntity }));
      }

      handleCloseUpdateForm();
    } else {
      setIsBildirimSablonKisiTipiUpdateModal(true);
    }
  };

  const confirmBildirimSablonKisiTipiDelete = bildirimSablonKisiTipi => {
    setSelectedBildirimSablonKisiTipi(bildirimSablonKisiTipi);
    setIsDeleteModalOpen(true);
  };

  const handleDeleteConfirm = useCallback(async () => {
    if (selectedBildirimSablonKisiTipi) {
      const entity = { ...pageObject, entity: selectedBildirimSablonKisiTipi };

      if (bildirimSablonuList.length === 1) {
        entity.page = pageObject.page - 1;
        setPageObject({ ...pageObject, page: pageObject.page - 1 });
      }

      await dispatch(deleteEntity(entity));
      handleDeleteCancel();
    }
  }, [selectedBildirimSablonKisiTipi, dispatch]);

  const handleDeleteCancel = () => {
    setIsDeleteModalOpen(false);
    setSelectedBildirimSablonKisiTipi(null);
  };

  const fetchTotalBildirimSablonuKisiTipiData = async (): Promise<IRefBildirimSablonuKisiTipi[]> => {
    try {
      const response = (await dispatch(getTotalEntities({ ...pageObject, size: totalItem }))) as {
        payload: { data: IRefBildirimSablonuKisiTipi[] };
      };

      return response.payload.data || [];
    } catch (error) {
      console.error('Error fetching data:', error);
      return [];
    }
  };

  const BildirimSablonKisiTipiUpdateModal = () => {
    return (
      <BildirimSablonKisiTipiUpdate
        saveNewBildirimSablonKisiTipi={saveNewBildirimSablonKisiTipi}
        handleCloseForm={handleCloseUpdateForm}
        formValues={selectedBildirimSablonKisiTipi}
        isOpen={isBildirimSablonKisiTipiUpdateModal}
      ></BildirimSablonKisiTipiUpdate>
    );
  };

  const handleSearchChange = (elem, event) => {
    if (!isNullOrEmpty(event.target.value)) {
      setPageObject(prevPageObject => ({
        ...prevPageObject,
        page: 0,
        query: prevPageObject.query + `&${elem}.equals=${event.target.value}`,
      }));
    } else {
      const keyToDelete = `${elem}.equals`;
      const updatedParams = pageObject.query
        .split('&')
        .filter(param => {
          const [key, _] = param.split('=');
          return key !== keyToDelete;
        })
        .join('&');
      setPageObject({ ...pageObject, page: 0, query: updatedParams });
    }
  };

  return (
    <div>
      {(loading || updating) && <LoadingSpinner />}
      {selectedBildirimSablonKisiTipi && (
        <CustomDeleteEntityConfirm
          entity={selectedBildirimSablonKisiTipi}
          entityName="bildirimSablonKisiTipi"
          isOpen={isDeleteModalOpen}
          onConfirm={handleDeleteConfirm}
          onCancel={handleDeleteCancel}
        />
      )}
      <CustomSaveEntityConfirm
        entity={selectedBildirimSablonKisiTipi}
        isOpen={isSaveModalOpen}
        onConfirm={handleConfirm}
        onCancel={handleCancel}
      />
      <div style={{ display: 'flex', gap: 8, textAlign: 'center' }}>
        <p className="icon-text">
          <a className="link-a-text" href={`${SERVER_URL}`}>
            <img width={13} height={13} src={`${SERVER_URL}/content/images/icon/faHouse.svg`} />
          </a>
        </p>
        <p className="separator"> / </p>
        <p className="link-text">
          {' '}
          <Translate contentKey="tk24AdysApp.bildirimSablonKisiTipi.home.title" />{' '}
        </p>
      </div>

      <h2 id="ref-bildirim-sablon-kisi-tipi-heading" data-cy="RefBildirimSablonKisiTipiHeading" className="title-frame">
        <a className="link-a-text" onClick={() => props.history.goBack()}>
          <img width={27.5} height={22.5} src={`${SERVER_URL}/content/images/icon/faArrowLeft.svg`} />{' '}
        </a>
        <p className="title-text">
          <Translate contentKey="tk24AdysApp.bildirimSablonKisiTipi.home.mainTitle" />
        </p>
      </h2>

      <div className="content-area">
        <div className="search-frame">
          <div className="seachiconposition">
            <Box sx={{ minWidth: 200, maxWidth: 200, marginLeft: 1 }}>
              <FormControl style={{ minHeight: 48, maxHeight: 48 }} fullWidth>
                <InputLabel id="default-bildirimSablonu-label">
                  <Translate contentKey="tk24AdysApp.bildirimSablonKisiTipi.bildirimSablonu" />
                </InputLabel>
                <Select
                  style={{ minHeight: 48, maxHeight: 48, borderRadius: '6px' }}
                  labelId="bildirimSablonu-label"
                  id="bildirimSablonu"
                  label={translate('tk24AdysApp.bildirimSablonKisiTipi.bildirimSablonu')}
                  onChange={event => handleSearchChange('bildirimSablonId', event)}
                >
                  <MenuItem value="">
                    <Translate contentKey="entity.action.all" />
                  </MenuItem>
                  {bildirimSablonuList &&
                    bildirimSablonuList.length > 0 &&
                    bildirimSablonuList.map(item => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.konu}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Box>

            <Box sx={{ minWidth: 200, maxWidth: 200, marginLeft: 1 }}>
              <FormControl style={{ minHeight: 48, maxHeight: 48 }} fullWidth>
                <InputLabel id="default-toplanti-tipi-label">
                  <Translate contentKey="tk24AdysApp.bildirimSablonKisiTipi.bildirimKisiTipi" />
                </InputLabel>
                <Select
                  style={{ minHeight: 48, maxHeight: 48, borderRadius: '6px' }}
                  labelId="bildirimKisiTipi-label"
                  id="bildirimKisiTipi"
                  label={translate('tk24AdysApp.bildirimSablonKisiTipi.bildirimKisiTipi')}
                  onChange={event => handleSearchChange('bildirimKisiTipiId', event)}
                >
                  <MenuItem value="">
                    <Translate contentKey="entity.action.all" />
                  </MenuItem>
                  {bildirimKisiTipiList &&
                    bildirimKisiTipiList.length > 0 &&
                    bildirimKisiTipiList.map(item => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.adi}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Box>
          </div>
          <div className="d-flex justify-end">
            <CustomDownloadExcelButton
              fetchData={fetchTotalBildirimSablonuKisiTipiData}
              className={'download-button'}
              exportName={translate('tk24AdysApp.bildirimSablonKisiTipi.home.title')}
              grantedAuthorites={['bildirim_sablonu_kisi_tipi_export']}
            />
            <GrantedButton
              grantedAuthorites={['bildirim_sablonu_kisi_tipi_edit']}
              style={{ marginLeft: 15 }}
              className="create-button"
              size="sm"
              data-cy="entityCreateButton"
              onClick={() => {
                setIsBildirimSablonKisiTipiUpdateModal(true);
              }}
            >
              <img width={14} height={14} src={`${SERVER_URL}/content/images/icon/faPlus.svg`} />
              <span className="d-none d-md-inline">
                <Translate contentKey="tk24AdysApp.bildirimSablonKisiTipi.home.createLabel" />
              </span>
            </GrantedButton>
          </div>
        </div>
        {isBildirimSablonKisiTipiUpdateModal && BildirimSablonKisiTipiUpdateModal()}
        <TableContainer className="content-table">
          {bildirimSablonuKisiTipiList && bildirimSablonuKisiTipiList.length > 0 ? (
            <Table aria-label="a dense table">
              <TableHead className="content-table-title-area">
                <TableRow className="table-head-row">
                  <TableCell className="table-title-cell hand" style={{ width: 570 }} align="left" onClick={sort('bildirimSablonId')}>
                    <Translate contentKey="tk24AdysApp.bildirimSablonKisiTipi.bildirimSablonu">Bildirim Şablonu</Translate>&nbsp;
                    {pageObject.sort === `bildirimSablonId,${ASC}` ? (
                      <FontAwesomeIcon icon={faArrowDown} />
                    ) : (
                      <FontAwesomeIcon icon={faArrowUp} />
                    )}
                  </TableCell>
                  <TableCell className="table-title-cell hand" style={{ width: 570 }} align="left" onClick={sort('bildirimKisiTipiId')}>
                    <Translate contentKey="tk24AdysApp.bildirimSablonKisiTipi.bildirimKisiTipi">Bildirim Kişi Tipi</Translate>&nbsp;
                    {pageObject.sort === `bildirimKisiTipiId,${ASC}` ? (
                      <FontAwesomeIcon icon={faArrowDown} />
                    ) : (
                      <FontAwesomeIcon icon={faArrowUp} />
                    )}
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {bildirimSablonuKisiTipiList.map(bildirimSablonuKisiTipiItem => (
                  <TableRow key={bildirimSablonuKisiTipiItem.id} className="table-cell-row">
                    <TableCell className="table-content-cell" component="th" scope="row">
                      {bildirimSablonuKisiTipiItem.bildirimSablon.konu}
                    </TableCell>
                    <TableCell className="table-content-cell" component="th" scope="row">
                      {bildirimSablonuKisiTipiItem.bildirimKisiTipi.adi}
                    </TableCell>
                    <TableCell className="table-content-cell" align="right">
                      <div className="btn-group flex-btn-group-container">
                        <GrantedButton
                          grantedAuthorites={['bildirim_sablonu_kisi_tipi_edit']}
                          className="edit-button"
                          style={{ marginLeft: 2 }}
                          size="sm"
                          data-cy="entityEditButton"
                          onClick={() => {
                            setSelectedBildirimSablonKisiTipi(bildirimSablonuKisiTipiItem);
                            setIsBildirimSablonKisiTipiUpdateModal(true);
                          }}
                        >
                          <img width={14} height={14} src={`${SERVER_URL}/content/images/icon/faPencil.svg`} />
                          <span className="d-none d-md-inline">
                            <Translate contentKey="entity.action.edit">Edit</Translate>
                          </span>
                        </GrantedButton>
                        <GrantedButton
                          grantedAuthorites={['bildirim_sablonu_kisi_tipi_delete']}
                          style={{ marginLeft: 10 }}
                          className="delete-button"
                          size="sm"
                          data-cy="entityDeleteButton"
                          onClick={() => confirmBildirimSablonKisiTipiDelete(bildirimSablonuKisiTipiItem)}
                        >
                          <img width={11.2} height={12.8} src={`${SERVER_URL}/content/images/icon/faTrashCan.svg`} />
                          <span>
                            <Translate contentKey="entity.action.delete">Delete</Translate>
                          </span>
                        </GrantedButton>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : (
            !loading &&
            !updating && (
              <div className="alert alert-warning">
                <Translate contentKey="tk24AdysApp.bildirimSablonKisiTipi.home.notFound">No Ref Duyuru Tipis found</Translate>
              </div>
            )
          )}
        </TableContainer>

        <CustomPagination
          currentPage={pageObject.page + 1}
          currentPageSize={pageObject.size}
          totalItems={totalItem}
          handlePageChange={handlePageChange}
        />
      </div>
    </div>
  );
};

export default BildirimSablonuKisiTipi;
