import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending } from '@reduxjs/toolkit';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { IQueryParams, createEntitySlice, EntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { IEtkilenenKisiHatirlatma, defaultValue } from 'app/shared/model/etkilenen-kisi-hatirlatma.model';

const initialState: EntityState<IEtkilenenKisiHatirlatma> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

interface KrizEtkilenenKisiHatirlatmaParams extends IQueryParams {
  kisiId: number;
}

const apiUrl = 'api/kriz-etkilenen-kisi-hatirlatmas';

// Actions

export const getEntities = createAsyncThunk(
  'krizEtkilenenKisiHatirlatma/fetch_entity_list',
  async ({ kisiId, page, size, sort }: KrizEtkilenenKisiHatirlatmaParams) => {
    const filter = `${sort ? `&page=${page}&size=${size}&sort=${sort}&` : '?'}cacheBuster=${new Date().getTime()}`;
    const requestUrl = `${apiUrl}?kisiId.equals=${kisiId}${filter}`;
    return axios.get<IEtkilenenKisiHatirlatma[]>(requestUrl);
  }
);

export const getEntity = createAsyncThunk(
  'krizEtkilenenKisiHatirlatma/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<IEtkilenenKisiHatirlatma>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const createEntity = createAsyncThunk(
  'krizEtkilenenKisiHatirlatma/create_entity',
  async (entity: IEtkilenenKisiHatirlatma, thunkAPI) => {
    const result = await axios.post<IEtkilenenKisiHatirlatma>(apiUrl, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({ kisiId: entity.kisi.id, sort: 'id,desc', page: 0, size: 999 }));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
  'krizEtkilenenKisiHatirlatma/update_entity',
  async (entity: IEtkilenenKisiHatirlatma, thunkAPI) => {
    const result = await axios.put<IEtkilenenKisiHatirlatma>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({ kisiId: entity.kisi.id, sort: 'id,desc', page: 0, size: 999 }));

    return result;
  },
  { serializeError: serializeAxiosError }
);

export const partialUpdateEntity = createAsyncThunk(
  'krizEtkilenenKisiHatirlatma/partial_update_entity',
  async (entity: IEtkilenenKisiHatirlatma, thunkAPI) => {
    const result = await axios.patch<IEtkilenenKisiHatirlatma>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const deleteEntity = createAsyncThunk(
  'krizEtkilenenKisiHatirlatma/delete_entity',
  async (id: string | number, thunkAPI) => {
    const requestUrl = `${apiUrl}/${id}`;
    const result = await axios.delete<IEtkilenenKisiHatirlatma>(requestUrl);
    return result;
  },
  { serializeError: serializeAxiosError }
);

// slice

export const KrizEtkilenenKisiHatirlatmaSlice = createEntitySlice({
  name: 'krizEtkilenenKisiHatirlatma',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(deleteEntity.fulfilled, state => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = {};
      })
      .addMatcher(isFulfilled(getEntities), (state, action) => {
        return {
          ...state,
          loading: false,
          entities: action.payload.data,
          totalItems: parseInt(action.payload.headers['x-total-count'], 10),
        };
      })
      .addMatcher(isFulfilled(createEntity, updateEntity, partialUpdateEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isPending(getEntities, getEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(createEntity, updateEntity, partialUpdateEntity, deleteEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      });
  },
});

export const { reset } = KrizEtkilenenKisiHatirlatmaSlice.actions;

// Reducer
export default KrizEtkilenenKisiHatirlatmaSlice.reducer;
