import React, { FormEventHandler, useEffect } from 'react';
import { Button, UncontrolledTooltip, Form, ModalFooter, ModalBody, Modal, ModalHeader } from 'reactstrap';
import { Translate, translate } from 'app/component/jhipster';

import { FormItem } from 'app/component/FormItem';
import { IRefIstasyonGorevGrubu } from 'app/shared/model/ref-istasyon-gorev-grubu.model';

interface RefIstasyonGorevGrubuUpdateProp {
  saveNewIstasyonGorevGrubu: FormEventHandler;
  handleCloseForm: FormEventHandler;
  formValues?: IRefIstasyonGorevGrubu;
  isOpen: boolean;
}

export const RefIstasyonGorevGrubuUpdate: React.FC<RefIstasyonGorevGrubuUpdateProp> = ({
  saveNewIstasyonGorevGrubu,
  handleCloseForm,
  formValues,
  isOpen,
}) => {
  return (
    <Modal className="modal-style" isOpen={isOpen} toggle={handleCloseForm} centered>
      <ModalHeader data-cy="RefIstasyonGorevGrubuCreateUpdateHeading" toggle={handleCloseForm}>
        {formValues && (
          <p className="modal-title-info">
            {formValues?.adiTr}-{formValues?.adiEn}
          </p>
        )}
        {formValues ? (
          <Translate contentKey="tk24AdysApp.refIstasyonGorevGrubu.home.editLabel">Update a RefIstasyonGorevGrubu</Translate>
        ) : (
          <Translate contentKey="tk24AdysApp.refIstasyonGorevGrubu.home.createLabel">Create a RefIstasyonGorevGrubu</Translate>
        )}
      </ModalHeader>
      <Form onSubmit={saveNewIstasyonGorevGrubu}>
        <ModalBody>
          <FormItem
            label={translate('global.languageGlobal.adiTr')}
            id="ref-istasyon-grubu-adiTr"
            name="adiTr"
            defaultValue={formValues && formValues['adiTr']}
            type="text"
            validation={{
              required: { value: true, message: translate('entity.validation.required') },
              maxLength: { value: 100, message: translate('entity.validation.maxlength', { max: 100 }) },
            }}
          />
          <UncontrolledTooltip target="adiTrLabel">
            <Translate contentKey="global.languageGlobal.adiTr" />
          </UncontrolledTooltip>

          <FormItem
            label={translate('global.languageGlobal.adiEn')}
            id="ref-istasyon-grubu-adiEn"
            name="adiEn"
            defaultValue={formValues && formValues['adiEn']}
            type="text"
            validation={{
              required: { value: true, message: translate('entity.validation.required') },
              maxLength: { value: 100, message: translate('entity.validation.maxlength', { max: 100 }) },
            }}
          />
          <UncontrolledTooltip target="adiEnLabel">
            <Translate contentKey="global.languageGlobal.adiEn" />
          </UncontrolledTooltip>
        </ModalBody>
        <ModalFooter className="modal-footer">
          <Button className="cancel-button" onClick={handleCloseForm}>
            <Translate contentKey="entity.action.cancel">Cancel</Translate>
          </Button>
          &nbsp;
          <Button className="save-button" id="jhi-confirm-delete-refAdtForm" type="submit" data-cy="entityConfirmButton">
            <Translate contentKey="entity.action.save">Kaydet</Translate>
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default RefIstasyonGorevGrubuUpdate;
