import React, { useState, useEffect } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col, FormText, UncontrolledTooltip } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'app/component/jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IKrizUcus } from 'app/shared/model/kriz-ucus.model';
import { getEntities as getKrizUcuses } from 'app/entities/kriz-ucus/kriz-ucus.reducer';
import { IReferans } from 'app/shared/model/referans.model';
import { getEntities as getReferans } from 'app/entities/referans/referans.reducer';
import { getEntity, updateEntity, createEntity, reset } from './ucus-kisi-listesi.reducer';
import { IUcusKisiListesi } from 'app/shared/model/ucus-kisi-listesi.model';
import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

export const UcusKisiListesiUpdate = (props: RouteComponentProps<{ id: string }>) => {
  const dispatch = useAppDispatch();

  const [isNew] = useState(!props.match.params || !props.match.params.id);

  const krizUcuses = useAppSelector(state => state.krizUcus.entities);
  const referans = useAppSelector(state => state.referans.entities);
  const ucusKisiListesiEntity = useAppSelector(state => state.ucusKisiListesi.entity);
  const loading = useAppSelector(state => state.ucusKisiListesi.loading);
  const updating = useAppSelector(state => state.ucusKisiListesi.updating);
  const updateSuccess = useAppSelector(state => state.ucusKisiListesi.updateSuccess);

  const handleClose = () => {
    props.history.push('/ucus-kisi-listesi' + props.location.search);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(props.match.params.id));
    }

    dispatch(getKrizUcuses({}));
    dispatch(getReferans({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    values.cDate = convertDateTimeToServer(values.cDate);
    values.mDate = convertDateTimeToServer(values.mDate);

    const entity = {
      ...ucusKisiListesiEntity,
      ...values,
      ucus: krizUcuses.find(it => it.id.toString() ===  values.ucusId.toString()),
      yolcuTipi: referans.find(it => it.id.toString() ===  values.yolcuTipiId.toString()),
      durum: referans.find(it => it.id.toString() ===  values.durumId.toString()),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {
          cDate: displayDefaultDateTime(),
          mDate: displayDefaultDateTime(),
        }
      : {
          ...ucusKisiListesiEntity,
          cDate: convertDateTimeFromServer(ucusKisiListesiEntity.cDate),
          mDate: convertDateTimeFromServer(ucusKisiListesiEntity.mDate),
          ucusId: ucusKisiListesiEntity?.ucus?.id,
          yolcuTipiId: ucusKisiListesiEntity?.yolcuTipi?.id,
          durumId: ucusKisiListesiEntity?.durum?.id,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="tk24AdysApp.ucusKisiListesi.home.createOrEditLabel" data-cy="UcusKisiListesiCreateUpdateHeading">
            <Translate contentKey="tk24AdysApp.ucusKisiListesi.home.createOrEditLabel">Create or edit a UcusKisiListesi</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="ucus-kisi-listesi-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('tk24AdysApp.ucusKisiListesi.adi')}
                id="ucus-kisi-listesi-adi"
                name="adi"
                data-cy="adi"
                type="text"
                validate={{
                  maxLength: { value: 255, message: translate('entity.validation.maxlength', { max: 255 }) },
                }}
              />
              <UncontrolledTooltip target="adiLabel">
                <Translate contentKey="tk24AdysApp.ucusKisiListesi.help.adi" />
              </UncontrolledTooltip>
              <ValidatedField
                label={translate('tk24AdysApp.ucusKisiListesi.soyadi')}
                id="ucus-kisi-listesi-soyadi"
                name="soyadi"
                data-cy="soyadi"
                type="text"
                validate={{
                  maxLength: { value: 255, message: translate('entity.validation.maxlength', { max: 255 }) },
                }}
              />
              <ValidatedField
                label={translate('tk24AdysApp.ucusKisiListesi.eposta')}
                id="ucus-kisi-listesi-eposta"
                name="eposta"
                data-cy="eposta"
                type="text"
                validate={{
                  maxLength: { value: 255, message: translate('entity.validation.maxlength', { max: 255 }) },
                }}
              />
              <ValidatedField
                label={translate('tk24AdysApp.ucusKisiListesi.telefon')}
                id="ucus-kisi-listesi-telefon"
                name="telefon"
                data-cy="telefon"
                type="text"
                validate={{
                  maxLength: { value: 255, message: translate('entity.validation.maxlength', { max: 255 }) },
                }}
              />
              <ValidatedField
                label={translate('tk24AdysApp.ucusKisiListesi.murettebatGoreviVb')}
                id="ucus-kisi-listesi-murettebatGoreviVb"
                name="murettebatGoreviVb"
                data-cy="murettebatGoreviVb"
                type="text"
                validate={{
                  maxLength: { value: 255, message: translate('entity.validation.maxlength', { max: 255 }) },
                }}
              />
              <ValidatedField
                label={translate('tk24AdysApp.ucusKisiListesi.murettabatSinifiVb')}
                id="ucus-kisi-listesi-murettabatSinifiVb"
                name="murettabatSinifiVb"
                data-cy="murettabatSinifiVb"
                type="text"
                validate={{
                  maxLength: { value: 255, message: translate('entity.validation.maxlength', { max: 255 }) },
                }}
              />
              <ValidatedField
                id="ucus-kisi-listesi-ucus"
                name="ucusId"
                data-cy="ucus"
                label={translate('tk24AdysApp.ucusKisiListesi.ucus')}
                type="select"
              >
                <option value="" key="0" />
                {krizUcuses
                  ? krizUcuses.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="ucus-kisi-listesi-yolcuTipi"
                name="yolcuTipiId"
                data-cy="yolcuTipi"
                label={translate('tk24AdysApp.ucusKisiListesi.yolcuTipi')}
                type="select"
              >
                <option value="" key="0" />
                {referans
                  ? referans.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/ucus-kisi-listesi" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default UcusKisiListesiUpdate;
