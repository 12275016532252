export const initialSquare = {
  width: '40px',
  height: '40px',
  borderRadius: '6px',
  backgroundColor: '#79889A',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: 'white',
  marginRight: '10px',
};

export const initialCircle = {
  width: '40px',
  height: '40px',
  borderRadius: '50%',
  backgroundColor: '#79889A',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: 'white',
  marginRight: '10px',
};

export const initialCircleNew = {
  width: '32px',
  height: '32px',
  borderRadius: '50%',
  backgroundColor: '#79889A',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: 'white',
  marginRight: '10px',
};

export const gorevCircle = {
  minWidth: '56px',
  height: '56px',
  borderRadius: '50%',
  backgroundColor: '#79889A',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: 'white',
  marginRight: '5px',
};
