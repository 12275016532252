import React, { useState, useEffect, useCallback } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Input } from 'reactstrap';
import { Translate, translate } from 'app/component/jhipster';
import { deleteEntity, getEntities, getTotalEntities, updateEntity } from './bildirim-sablonu.reducer';
import { ASC, DESC } from 'app/shared/util/pagination.constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { CustomDeleteEntityConfirm, CustomSaveEntityConfirm, isNullOrEmpty } from 'app/shared/util/confirm-utils';
import { LoadingSpinner } from 'app/component/LoadingSpinner';
import { BildirimSablonUpdate } from './bildirim-sablon-update';
import CustomPagination from 'app/component/CustomPagination';
import { SERVER_URL } from 'app/config/constants';
import GrantedButton from 'app/component/GrantedButton';
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material';
import { IRefBildirimSablonu } from 'app/shared/model/bildirim-sablonu.model';
import { IQueryParams } from 'app/shared/reducers/reducer.utils';
import { getEntities as getIslemKoduEntities } from '../ref-islem-kodu/ref-islem-kodu.reducer';
import { getEntities as getReferans } from 'app/entities/referans/referans.reducer';
import { MenuProps } from 'app/shared/util/filter.constants';
import { ReferansEnum } from 'app/constants/ReferansEnum';
import ExcelDownloadButton from 'app/component/ExcelDownload';
import SortIcon from 'app/component/SortIcon';
import { localeLanguage, upperOnlyFirstLetter } from 'app/shared/util/util';

export const BildirimSablonu = (props: RouteComponentProps<{ url: string }>) => {
  const dispatch = useAppDispatch();
  const bildirimSablonuList = useAppSelector(state => state.bildirimSablonu.entities);
  const loading = useAppSelector(state => state.bildirimSablonu.loading);
  const updating = useAppSelector(state => state.bildirimSablonu.updating);
  const totalItem = useAppSelector(state => state.bildirimSablonu.totalItems);

  const [isBildirimSablonuUpdateModal, setIsBildirimSablonuUpdateModal] = useState(false);
  const [selectedBildirimSablonu, setSelectedBildirimSablonu] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const [search, setSearch] = useState('');
  const referans = useAppSelector(state => state.referans.entities);
  const bildirimTuruList = referans.filter(res => res.tipi === 'BILDIRIM_TURU');
  const islemKoduEntities = useAppSelector(state => state.refIslemKodu.entities);
  const durumList = referans.filter(res => res.tipi === 'DURUM' && res.id !== ReferansEnum.Durum.Silinmis);

  const islemKoduLoading = useAppSelector(state => state.refIslemKodu.loading);
  const bildirimTuruLoading = useAppSelector(state => state.referans.loading);

  const [modalPromiseResolve, setModalPromiseResolve] = useState(null);
  const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);

  const [pageObject, setPageObject] = useState<IQueryParams>(useAppSelector(state => state.paginate.pageObject));
  const ExcelSampleData = [
    { key: 'islemKodu.adi', titleContentKey: 'tk24AdysApp.bildirimSablon.islemKodu' },
    { key: 'bildirimTuru.adi', titleContentKey: 'tk24AdysApp.bildirimSablon.bildirimTuru' },
    { key: 'konuTr', titleContentKey: 'tk24AdysApp.bildirimSablon.baslikKonuTr' },
    { key: 'konuEn', titleContentKey: 'tk24AdysApp.bildirimSablon.baslikKonuEn' },
    { key: 'kykCeride.adi', titleContentKey: 'tk24AdysApp.bildirimSablon.kykCeride' },
    { key: 'adtCeride.adi', titleContentKey: 'tk24AdysApp.bildirimSablon.adtCeride' },
    { key: 'istasyonCeride.adi', titleContentKey: 'tk24AdysApp.bildirimSablon.istasyonCeride' },
    { key: 'durum.adi', titleContentKey: 'tk24AdysApp.bildirimSablon.durum' },
  ];
  const showSaveConfirmModal = () => {
    setIsSaveModalOpen(true);
    return new Promise(resolve => {
      setModalPromiseResolve(() => resolve);
    });
  };

  const handleConfirm = () => {
    if (modalPromiseResolve) {
      modalPromiseResolve(true);
    }
    setIsSaveModalOpen(false);
  };

  const handleCancel = () => {
    if (modalPromiseResolve) {
      modalPromiseResolve(false);
    }
    setIsSaveModalOpen(false);
  };

  const getAllEntities = () => {
    dispatch(getEntities(pageObject));
  };

  useEffect(() => {
    dispatch(getIslemKoduEntities({ size: 999, page: 0, sort: `adi${upperOnlyFirstLetter(localeLanguage)},asc` }));
    dispatch(getReferans({ size: 2000, page: 0, sort: `adi${upperOnlyFirstLetter(localeLanguage)},asc` }));
  }, []);

  useEffect(() => {
    getAllEntities();
  }, [pageObject.page, pageObject.query, pageObject.size, pageObject.sort]);

  useEffect(() => {
    if (search.length > 2) {
      setPageObject(prevPageObject => ({
        ...prevPageObject,
        page: 0,
        query: prevPageObject.query + `&globalFilter=${search}`,
      }));
    } else if (search.length === 0) {
      const keyToDelete = `globalFilter`;
      const updatedParams = pageObject.query
        .split('&')
        .filter(param => {
          const [key, _] = param.split('=');
          return key !== keyToDelete;
        })
        .join('&');
      setPageObject({ ...pageObject, page: 0, query: updatedParams });
    }
  }, [search]);

  const sort = p => () => {
    const orderBy = pageObject.sort.split(',')[1];
    setPageObject({
      ...pageObject,
      sort: `${p},${orderBy === ASC ? DESC : ASC}`,
    });
  };

  const handlePageChange = (pageNumber, newItemsPerPage = pageObject.size) => {
    if (newItemsPerPage !== pageObject.size) {
      setPageObject({ ...pageObject, page: pageNumber - 1, size: newItemsPerPage });
    } else {
      setPageObject({ ...pageObject, page: pageNumber - 1 });
    }
  };

  const handleCloseUpdateForm = () => {
    if (isBildirimSablonuUpdateModal) {
      setIsBildirimSablonuUpdateModal(false);
    }
    setSelectedBildirimSablonu(null);
  };

  const confirmBildirimSablonuDelete = bildirimSablonu => {
    setSelectedBildirimSablonu(bildirimSablonu);
    setIsDeleteModalOpen(true);
  };

  const handleDeleteConfirm = useCallback(async () => {
    if (selectedBildirimSablonu) {
      const entity = { ...pageObject, entity: selectedBildirimSablonu };

      if (bildirimSablonuList.length === 1) {
        entity.page = pageObject.page - 1;
        setPageObject({ ...pageObject, page: pageObject.page - 1 });
      }

      await dispatch(deleteEntity(entity));
      handleDeleteCancel();
    }
  }, [selectedBildirimSablonu, dispatch]);

  const handleDeleteCancel = () => {
    setIsDeleteModalOpen(false);
    setSelectedBildirimSablonu(null);
  };

  const fetchTotalBildirimSablonuData = async (): Promise<IRefBildirimSablonu[]> => {
    try {
      const response = (await dispatch(getTotalEntities({ ...pageObject, size: totalItem }))) as {
        payload: { data: IRefBildirimSablonu[] };
      };

      return response.payload.data || [];
    } catch (error) {
      console.error('Error fetching data:', error);
      return [];
    }
  };

  const BildirimSablonuUpdateModal = () => {
    return (
      <BildirimSablonUpdate
        handleCloseForm={handleCloseUpdateForm}
        formValues={selectedBildirimSablonu}
        isOpen={isBildirimSablonuUpdateModal}
        pageObject={pageObject}
      ></BildirimSablonUpdate>
    );
  };

  const handleSearchChange = (elem, event) => {
    if (!isNullOrEmpty(event.target.value)) {
      setPageObject(prevPageObject => ({
        ...prevPageObject,
        page: 0,
        query: prevPageObject.query + `&${elem}.equals=${event.target.value}`,
      }));
    } else {
      const keyToDelete = `${elem}.equals`;
      const updatedParams = pageObject.query
        .split('&')
        .filter(param => {
          const [key, _] = param.split('=');
          return key !== keyToDelete;
        })
        .join('&');
      setPageObject({ ...pageObject, page: 0, query: updatedParams });
    }
  };

  const updateDurum = async (bildSablon: IRefBildirimSablonu) => {
    const isSave = await showSaveConfirmModal();
    if (isSave) {
      const bildirimSablonuEntity = {
        ...bildSablon,
        durum:
          bildSablon.durum.id.toString() === ReferansEnum.Durum.Aktif.toString()
            ? referans.find(it => it.id.toString() === ReferansEnum.Durum.Pasif.toString())
            : referans.find(it => it.id.toString() === ReferansEnum.Durum.Aktif.toString()),
      };

      dispatch(updateEntity({ ...pageObject, entity: bildirimSablonuEntity }));
    }
  };

  return (
    <div>
      {(loading || updating || islemKoduLoading || bildirimTuruLoading) && <LoadingSpinner />}
      <CustomSaveEntityConfirm
        entity={selectedBildirimSablonu}
        isOpen={isSaveModalOpen}
        onConfirm={handleConfirm}
        onCancel={handleCancel}
      />
      {isBildirimSablonuUpdateModal && BildirimSablonuUpdateModal()}
      {selectedBildirimSablonu && (
        <CustomDeleteEntityConfirm
          entity={selectedBildirimSablonu}
          entityName="bildirimSablon"
          isOpen={isDeleteModalOpen}
          onConfirm={handleDeleteConfirm}
          onCancel={handleDeleteCancel}
        />
      )}

      <div style={{ display: 'flex', gap: 8, textAlign: 'center' }}>
        <p className="icon-text">
          <a className="link-a-text" href={`${SERVER_URL}`}>
            <img width={13} height={13} src={`${SERVER_URL}/content/images/icon/faHouse.svg`} />
          </a>
        </p>
        <p className="separator"> / </p>
        <p className="link-text">
          {' '}
          <Translate contentKey="tk24AdysApp.bildirimSablon.home.title" />{' '}
        </p>
      </div>

      <h2 id="bildirim-sablon-heading" data-cy="BildirimSablonHeading" className="title-frame">
        <a className="link-a-text" onClick={() => props.history.goBack()}>
          <img width={27.5} height={22.5} src={`${SERVER_URL}/content/images/icon/faArrowLeft.svg`} />{' '}
        </a>
        <p className="title-text">
          <Translate contentKey="tk24AdysApp.bildirimSablon.home.title" />
        </p>
      </h2>

      <div className="content-area">
        <div className="search-frame">
          <div className="seachiconposition">
            <img className="seachicon" width={24} height={24} src={`${SERVER_URL}/content/images/icon/faSearch.svg`} />
            <Input
              value={search}
              onChange={e => setSearch(e.target.value)}
              placeholder={translate('entity.action.search')}
              className="search-input"
            />
          </div>
          <div className="create-area">
            <Box sx={{ minWidth: 220, maxWidth: 220 }}>
              <FormControl fullWidth>
                <InputLabel id="islemKodu-label" className="select-input-label">
                  <Translate contentKey="tk24AdysApp.bildirimSablon.islemKodu" />
                </InputLabel>
                <Select
                  labelId="islemKodu-label"
                  id="islemKodu"
                  label={translate('tk24AdysApp.bildirimSablon.islemKodu')}
                  onChange={event => handleSearchChange('islemKoduId', event)}
                  MenuProps={MenuProps}
                  className="select-input"
                >
                  <MenuItem value="">
                    <Translate contentKey="entity.action.all" />
                  </MenuItem>
                  {islemKoduEntities &&
                    islemKoduEntities.length > 0 &&
                    islemKoduEntities.map(item => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.adi}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Box>

            <Box sx={{ minWidth: 220, maxWidth: 220 }}>
              <FormControl fullWidth>
                <InputLabel id="bildirimTuru-label" className="select-input-label">
                  <Translate contentKey="tk24AdysApp.bildirimSablon.bildirimTuru" />
                </InputLabel>
                <Select
                  labelId="bildirimTuru-label"
                  id="bildirimTuru"
                  label={translate('tk24AdysApp.bildirimSablon.bildirimTuru')}
                  onChange={event => handleSearchChange('bildirimTuruId', event)}
                  MenuProps={MenuProps}
                  className="select-input"
                >
                  <MenuItem value="">
                    <Translate contentKey="entity.action.all" />
                  </MenuItem>
                  {bildirimTuruList &&
                    bildirimTuruList.length > 0 &&
                    bildirimTuruList.map(item => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.adi}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Box>

            <Box sx={{ minWidth: 220, maxWidth: 220 }}>
              <FormControl fullWidth>
                <InputLabel id="islemKodu-label" className="select-input-label">
                  <Translate contentKey="tk24AdysApp.bildirimSablon.durum" />
                </InputLabel>
                <Select
                  labelId="islemKodu-label"
                  id="islemKodu"
                  label={translate('tk24AdysApp.bildirimSablon.durum')}
                  onChange={event => handleSearchChange('durumId', event)}
                  MenuProps={MenuProps}
                  className="select-input"
                >
                  <MenuItem value="">
                    <Translate contentKey="entity.action.all" />
                  </MenuItem>
                  {durumList &&
                    durumList.length > 0 &&
                    durumList.map(item => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.adi}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Box>
            <ExcelDownloadButton
              excelSampleData={ExcelSampleData}
              grantedAuthorites={['bildirim_sablonu_export']}
              fetchData={fetchTotalBildirimSablonuData}
              className={'download-button'}
              exportName={translate('tk24AdysApp.bildirimSablon.home.title')}
            />
            <GrantedButton
              grantedAuthorites={['bildirim_sablonu_edit']}
              onClick={() => {
                setIsBildirimSablonuUpdateModal(true);
              }}
              className="create-button"
              size="sm"
              data-cy="entityCreateButton"
            >
              <Tooltip title={<Translate contentKey="tk24AdysApp.bildirimSablon.home.createLabel" />}>
                <img width={14} height={14} src={`${SERVER_URL}/content/images/icon/faPlus.svg`} />
              </Tooltip>
              <span className="show-on-lg">
                <Translate contentKey="tk24AdysApp.bildirimSablon.home.createLabel" />
              </span>
            </GrantedButton>
          </div>
        </div>
        <div className="table-responsive" style={{ marginTop: 5 }}>
          <TableContainer className="content-table">
            {bildirimSablonuList && bildirimSablonuList.length > 0 ? (
              <Table aria-label="a dense table">
                <TableHead className="content-table-title-area">
                  <TableRow className="table-head-row">
                    <TableCell
                      className="table-title-cell hand"
                      align="left"
                      onClick={sort(`islemKodu.adi${upperOnlyFirstLetter(localeLanguage)}`)}
                    >
                      <div className="tableHeaderContainer">
                        <Translate contentKey="tk24AdysApp.bildirimSablon.islemKoduTitle" /> &nbsp;
                        <SortIcon column={`islemKodu.adi${upperOnlyFirstLetter(localeLanguage)}`} activeSortColumn={pageObject.sort} />
                      </div>
                    </TableCell>
                    <TableCell
                      className="table-title-cell hand"
                      align="left"
                      onClick={sort(`bildirimTuru.adi${upperOnlyFirstLetter(localeLanguage)}`)}
                    >
                      <div className="tableHeaderContainer">
                        <Translate contentKey="tk24AdysApp.bildirimSablon.bildirimTuruTitle" /> &nbsp;
                        <SortIcon column={`bildirimTuru.adi${upperOnlyFirstLetter(localeLanguage)}`} activeSortColumn={pageObject.sort} />
                      </div>
                    </TableCell>
                    <TableCell className="table-title-cell hand" align="left" onClick={sort('konuTr')}>
                      <div className="tableHeaderContainer">
                        <Translate contentKey="tk24AdysApp.bildirimSablon.baslikKonuTrTitle" /> &nbsp;
                        <SortIcon column="konuTr" activeSortColumn={pageObject.sort} />
                      </div>
                    </TableCell>
                    <TableCell className="table-title-cell hand" align="left" onClick={sort('konuEn')}>
                      <div className="tableHeaderContainer">
                        <Translate contentKey="tk24AdysApp.bildirimSablon.baslikKonuEnTitle" /> &nbsp;
                        <SortIcon column="konuEn" activeSortColumn={pageObject.sort} />
                      </div>
                    </TableCell>
                    <TableCell
                      className="table-title-cell hand"
                      align="left"
                      onClick={sort(`kykCeride.adi${upperOnlyFirstLetter(localeLanguage)}`)}
                    >
                      <div className="tableHeaderContainer">
                        <Translate contentKey="tk24AdysApp.bildirimSablon.kykCerideTitle" /> &nbsp;
                        <SortIcon column={`kykCeride.adi${upperOnlyFirstLetter(localeLanguage)}`} activeSortColumn={pageObject.sort} />
                      </div>
                    </TableCell>
                    <TableCell
                      className="table-title-cell hand"
                      align="left"
                      onClick={sort(`adtCeride.adi${upperOnlyFirstLetter(localeLanguage)}`)}
                    >
                      <div className="tableHeaderContainer">
                        <Translate contentKey="tk24AdysApp.bildirimSablon.adtCerideTitle" /> &nbsp;
                        <SortIcon column={`adtCeride.adi${upperOnlyFirstLetter(localeLanguage)}`} activeSortColumn={pageObject.sort} />
                      </div>
                    </TableCell>
                    <TableCell
                      className="table-title-cell hand"
                      align="left"
                      onClick={sort(`istasyonCeride.adi${upperOnlyFirstLetter(localeLanguage)}`)}
                    >
                      <div className="tableHeaderContainer">
                        <Translate contentKey="tk24AdysApp.bildirimSablon.istasyonCerideTitle" /> &nbsp;
                        <SortIcon column={`istasyonCeride.adi${upperOnlyFirstLetter(localeLanguage)}`} activeSortColumn={pageObject.sort} />
                      </div>
                    </TableCell>
                    <TableCell
                      className="table-title-cell hand"
                      style={{ width: '150px' }}
                      align="left"
                      onClick={sort(`durum.adi${upperOnlyFirstLetter(localeLanguage)}`)}
                    >
                      <div className="tableHeaderContainer">
                        <Translate contentKey="tk24AdysApp.bildirimSablon.durum" /> &nbsp;
                        <SortIcon column={`durum.adi${upperOnlyFirstLetter(localeLanguage)}`} activeSortColumn={pageObject.sort} />
                      </div>
                    </TableCell>
                    <TableCell className="sticky-right-header"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {bildirimSablonuList.map(bildirimSablonu => (
                    <TableRow key={bildirimSablonu?.id} className="table-cell-row">
                      <TableCell className="table-content-cell" component="th" scope="row">
                        {bildirimSablonu?.islemKodu?.adi}
                      </TableCell>
                      <TableCell className="table-content-cell" component="th" scope="row">
                        {bildirimSablonu?.bildirimTuru?.adi}
                      </TableCell>
                      <TableCell className="table-content-cell" component="th" scope="row">
                        {bildirimSablonu?.konuTr}
                      </TableCell>
                      <TableCell className="table-content-cell" component="th" scope="row">
                        {bildirimSablonu?.konuEn}
                      </TableCell>
                      <TableCell className="table-content-cell" component="th" scope="row">
                        {bildirimSablonu?.kykCeride?.adi}
                      </TableCell>
                      <TableCell className="table-content-cell" component="th" scope="row">
                        {bildirimSablonu?.adtCeride?.adi}
                      </TableCell>
                      <TableCell className="table-content-cell" component="th" scope="row">
                        {bildirimSablonu?.istasyonCeride?.adi}
                      </TableCell>
                      <TableCell className="table-content-cell" component="th" scope="row">
                        {bildirimSablonu?.durum?.adi}{' '}
                        <Switch
                          className="sablon-switch-button"
                          checked={bildirimSablonu.durum.id === ReferansEnum.Durum.Aktif}
                          onChange={() => updateDurum(bildirimSablonu)}
                        />
                      </TableCell>
                      <TableCell className="sticky-right" align="right">
                        <div className="btn-group flex-btn-group-container">
                          <GrantedButton
                            grantedAuthorites={['bildirim_sablonu_edit']}
                            className="edit-button"
                            style={{ marginLeft: 10 }}
                            onClick={() => {
                              setSelectedBildirimSablonu(bildirimSablonu);
                              setIsBildirimSablonuUpdateModal(true);
                            }}
                            size="sm"
                            data-cy="entityEditButton"
                          >
                            <Tooltip title={<Translate contentKey="entity.action.edit">Edit</Translate>}>
                              <img width={14} height={14} src={`${SERVER_URL}/content/images/icon/faPencil.svg`} />
                            </Tooltip>
                            <span className="show-on-lg">
                              <Translate contentKey="entity.action.edit">Edit</Translate>
                            </span>
                          </GrantedButton>
                          <GrantedButton
                            grantedAuthorites={['bildirim_sablonu_delete']}
                            onClick={() => confirmBildirimSablonuDelete(bildirimSablonu)}
                            style={{ marginLeft: 10 }}
                            className="delete-button"
                            size="sm"
                            data-cy="entityDeleteButton"
                          >
                            <Tooltip title={<Translate contentKey="entity.action.delete">Delete</Translate>}>
                              <img width={11.2} height={12.8} src={`${SERVER_URL}/content/images/icon/faTrashCan.svg`} />
                            </Tooltip>
                            <span className="show-on-lg">
                              <Translate contentKey="entity.action.delete">Delete</Translate>
                            </span>
                          </GrantedButton>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            ) : (
              !loading &&
              !updating && (
                <div className="alert alert-warning">
                  <Translate contentKey="tk24AdysApp.bildirimSablon.home.notFound">No Bildirim Sablonu found</Translate>
                </div>
              )
            )}
          </TableContainer>
        </div>
        <CustomPagination
          currentPage={pageObject.page + 1}
          currentPageSize={pageObject.size}
          totalItems={totalItem}
          handlePageChange={handlePageChange}
        />
      </div>
    </div>
  );
};

export default BildirimSablonu;
