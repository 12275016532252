import { IReferans } from 'app/shared/model/referans.model';
import { IQueryParams } from '../reducers/reducer.utils';

export interface IYetki {
  id?: number;
  guid?: string;
  realId?: number;
  adi?: string;
  adiTr?: string;
  adiEn?: string;
  nesneTr?: string;
  nesneEn?: string;
  code?: string;
  nesne?: string;
  cDate?: string;
  cUser?: number;
  mDate?: string;
  mUser?: number;
  durum?: IReferans;
}

export interface IYetkiParams extends IQueryParams {
  entity?: IYetki;
}

export const defaultValue: Readonly<IYetki> = {};
