import { TextFormat, translate, Translate } from 'app/component/jhipster';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { getEntities, getTotalEntities } from '../manuel-bildirim/manuel-bildirim.reducer';
import {
  getEntities as getBildirimEntities,
  getTotalEntities as getTotalBildirimEntities,
  reset as resetBildirimEntities,
} from '../kriz-katilimci-bildirim/kriz-katilimci-bildirim.reducer';
import { IQueryParams } from 'app/shared/reducers/reducer.utils';
import CustomPagination from 'app/component/CustomPagination';
import {
  getEntities as getEntitiesManuelIvr,
  getTotalEntities as getTotalEntitiesManuelIvr,
} from '../manuel-ivr-bildirim/manuel-ivr-bildirim.reducer';
import {
  Box,
  Collapse,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { Button, Col, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import ExcelDownloadButton from 'app/component/ExcelDownload';
import { APP_DATE_FORMAT, SERVER_URL } from 'app/config/constants';
import { IManuelBildirim } from 'app/shared/model/manuel-bildirim.model';
import { isNullOrEmpty } from 'app/shared/util/confirm-utils';
import SortIcon from 'app/component/SortIcon';
import { ASC, DESC } from 'app/shared/util/pagination.constants';
import { localeLanguage, upperOnlyFirstLetter } from 'app/shared/util/util';
import { KeyboardArrowDownOutlined, KeyboardArrowRight, Search } from '@mui/icons-material';
import { LoadingSpinner } from 'app/component/LoadingSpinner';
import { ReferansEnum } from 'app/constants/ReferansEnum';
import { HasAuthComponent } from 'app/component/HasAuthComponent';
import { IKrizKatilimciBildirim } from 'app/shared/model/kriz-katilimci-bildirim.model';
import { IManuelIvrBildirim } from 'app/shared/model/manuel-ivr-bildirim.model';

const BildirimYonetimiRapor = (props: RouteComponentProps<{ url: string }>) => {
  const dispatch = useAppDispatch();
  const [pageObject, setPageObject] = useState<IQueryParams>(useAppSelector(state => state.paginate.pageObject));
  const [pageObjectBildirim, setPageObjectBildirim] = useState<IQueryParams>(useAppSelector(state => state.paginate.pageObject));

  const manuelBildirimList = useAppSelector(state => state.manuelBildirim.entities);
  const manuelBildirimListLoading = useAppSelector(state => state.manuelBildirim.loading);
  const manuelBildirimListUpdating = useAppSelector(state => state.manuelBildirim.updating);
  const totalItem = useAppSelector(state => state.manuelBildirim.totalItems);
  const [openItemId, setOpenItemId] = useState<number | null>(null);
  const [selectManuelBildirim, setSelectManuelBildirim] = useState<IManuelBildirim>(null);

  const krizKatilimciBildirimList = useAppSelector(state => state.krizKatilimciBildirim.entities);
  const krizKatilimciBildirimTotal = useAppSelector(state => state.krizKatilimciBildirim.totalItems);
  const krizKatilimciBildirimLoading = useAppSelector(state => state.krizKatilimciBildirim.loading);

  const referans = useAppSelector(state => state.referans.entities);
  const bildirimTuruList = referans.filter(
    res =>
      res.id === ReferansEnum.BildirimTuru.EPosta || res.id === ReferansEnum.BildirimTuru.Sms || res.id === ReferansEnum.BildirimTuru.Crysis
  );

  const manuelIvrBildirimList = useAppSelector(state => state.manuelIvrBildirim.entities);
  const loadingManuelIvr = useAppSelector(state => state.manuelIvrBildirim.loading);

  const queryParams = useAppSelector(state => state.paginate.pageObject);

  const [pageObjectManuelIvr, setPageObjectManuelIvr] = useState<IQueryParams>(() => {
    const newPageObject = { ...queryParams };
    delete newPageObject.sort;
    return newPageObject;
  });

  const totalItemManuelIvr = useAppSelector(state => state.manuelIvrBildirim.totalItems);

  const [search, setSearch] = useState('');

  const aramaSebebiList = referans.filter(res => res.tipi === 'IVR_ARAMA_SEBEBI');
  const gonderimDurumuList = referans.filter(res => res.tipi === 'GONDERIM_DURUMU');

  const [searchManuelIvr, setSearchManuelIvr] = useState('');

  const getAsilMiVekilMi = data => {
    if (data.asilMi?.id.toString() === ReferansEnum.EvetHayir.Evet.toString()) {
      return translate('tk24AdysApp.manuelIvrBildirim.asil');
    }
    return translate('tk24AdysApp.manuelIvrBildirim.vekil');
  };

  const getAsilBilgi = data => {
    if (data.asilMi?.id.toString() === ReferansEnum.EvetHayir.Hayir.toString()) {
      return `${data.refKatilimci?.adi} ${data.refKatilimci?.soyadi || ''}`;
    }
    return '-';
  };

  const ExcelSampleData = [
    { key: 'bildirimTuru.adi', titleContentKey: 'tk24AdysApp.bildirimSablon.bildirimTuru' },
    { key: 'manuelIvrBildirimTipi.adi', titleContentKey: 'tk24AdysApp.manuelIvrBildirim.manuelIvrBildirimTipi' },
    { key: 'aciklama', titleContentKey: 'global.languageGlobal.aciklama' },
    { key: 'cDate', format: 'date', titleContentKey: 'tk24AdysApp.bildirimYonetimi.cDate' },
  ];

  const ExcelSampleDataManuelIvr = [
    { customFunction: getAsilMiVekilMi, format: 'function', titleContentKey: 'tk24AdysApp.manuelIvrBildirim.asilMi' },
    { key: 'iletisimBilgisi', titleContentKey: 'tk24AdysApp.krizKatilimciBildirim.iletisimBilgisi' },
    { key: 'adi', titleContentKey: 'tk24AdysApp.krizKatilimciBildirim.adi' },
    { key: 'soyadi', titleContentKey: 'tk24AdysApp.krizKatilimciBildirim.soyadi' },
    { customFunction: getAsilBilgi, format: 'function', titleContentKey: 'tk24AdysApp.manuelIvrBildirim.asil' },
    { key: 'ivrTalepId', titleContentKey: 'tk24AdysApp.manuelIvrBildirim.ivrTalep' },
    { key: 'ivrCevapKodu', titleContentKey: 'tk24AdysApp.manuelIvrBildirim.ivrCevap' },
    { key: 'ivrSecilenSecenek', titleContentKey: 'tk24AdysApp.manuelIvrBildirim.ivrSecilenSecenek' },
    { key: 'aramaZamani', format: 'date', titleContentKey: 'tk24AdysApp.manuelIvrBildirim.aramaZamani' },
    { key: 'gonderimZamani', format: 'date', titleContentKey: 'tk24AdysApp.krizKatilimciBildirim.gonderimZamani' },
    { key: 'denemeSayisi', titleContentKey: 'tk24AdysApp.krizKatilimciBildirim.denemeSayisi' },
    { key: 'icerik', titleContentKey: 'tk24AdysApp.manuelIvrBildirim.icerik' },
    { key: 'ivrPrompText', titleContentKey: 'tk24AdysApp.manuelIvrBildirim.secenek' },
    { key: 'ivrPrompTextVekil', titleContentKey: 'tk24AdysApp.manuelIvrBildirim.secenekVekil' },
    { key: 'ivrPrompText2', titleContentKey: 'tk24AdysApp.manuelIvrBildirim.ivrPrompText2' },
    { key: 'hataMesaji', titleContentKey: 'tk24AdysApp.krizKatilimciBildirim.hataMesaji' },
  ];

  const AccordionExcelSampleData = [
    { key: 'iletisimBilgisi', titleContentKey: 'tk24AdysApp.krizKatilimciBildirim.iletisimBilgisi' },
    {
      key: 'planlananGonderimZamani',
      format: 'date',
      titleContentKey: 'tk24AdysApp.krizKatilimciBildirim.planlananGonderimZamani',
    },
    { key: 'gonderimZamani', format: 'date', titleContentKey: 'tk24AdysApp.krizKatilimciBildirim.gonderimZamani' },
    { key: 'krizKatilimci.adi', titleContentKey: 'tk24AdysApp.krizKatilimciBildirim.adi' },
    { key: 'krizKatilimci.soyadi', titleContentKey: 'tk24AdysApp.krizKatilimciBildirim.soyadi' },
    { key: 'konu', titleContentKey: 'tk24AdysApp.krizKatilimciBildirim.konu' },
    { key: 'icerik', titleContentKey: 'tk24AdysApp.krizKatilimciBildirim.icerik' },
    { key: 'bildirimTuru.adi', titleContentKey: 'tk24AdysApp.krizKatilimciBildirim.bildirimTuru' },
    { key: 'gonderimDurumu.adi', titleContentKey: 'tk24AdysApp.krizKatilimciBildirim.gonderimDurumu' },
    { key: 'denemeSayisi', titleContentKey: 'tk24AdysApp.krizKatilimciBildirim.denemeSayisi' },
    { key: 'hataMesaji', titleContentKey: 'tk24AdysApp.krizKatilimciBildirim.hataMesaji' },
  ];

  useEffect(() => {
    dispatch(getEntities(pageObject));
  }, [pageObject.page, pageObject.query, pageObject.size, pageObject.sort]);

  useEffect(() => {
    if (openItemId) {
      if (selectManuelBildirim?.bildirimTuru?.id === ReferansEnum.BildirimTuru.Crysis) {
        dispatch(
          getEntitiesManuelIvr({
            ...pageObjectManuelIvr,
            query2: `manuelBildirimId.equals=${openItemId}`,
          })
        );
      } else {
        dispatch(getBildirimEntities({ ...pageObjectBildirim, query2: `manuelBildirimId.equals=${openItemId}` }));
      }
    }
  }, [
    openItemId,
    pageObjectBildirim.page,
    pageObjectBildirim.query,
    pageObjectBildirim.size,
    pageObjectBildirim.sort,
    pageObjectManuelIvr.page,
    pageObjectManuelIvr.size,
    pageObjectManuelIvr.sort,
    pageObjectManuelIvr.query,
  ]);

  useEffect(() => {
    if (search.length > 2) {
      setPageObject(prevPageObject => ({
        ...prevPageObject,
        page: 0,
        query: prevPageObject.query + `&globalFilter=${search}`,
      }));
    } else if (search.length === 0 && !isNullOrEmpty(pageObject.query)) {
      const keyToDelete = 'globalFilter';
      const updatedParams = pageObject.query
        .split('&')
        .filter(param => {
          const [key, _] = param.split('=');
          return key !== keyToDelete;
        })
        .join('&');

      setPageObject({ ...pageObject, page: 0, query: updatedParams });
    }
  }, [search]);

  useEffect(() => {
    if (searchManuelIvr.length > 2) {
      setPageObjectManuelIvr(prevPageObject => ({
        ...prevPageObject,
        page: 0,
        query: prevPageObject.query + `&globalFilter=${searchManuelIvr}`,
      }));
    } else if (searchManuelIvr.length === 0 && !isNullOrEmpty(pageObjectManuelIvr.query)) {
      const keyToDelete = 'globalFilter';
      const updatedParams = pageObjectManuelIvr.query
        .split('&')
        .filter(param => {
          const [key, _] = param.split('=');
          return key !== keyToDelete;
        })
        .join('&');

      setPageObjectManuelIvr({ ...pageObjectManuelIvr, page: 0, query: updatedParams });
    }
  }, [searchManuelIvr]);

  const handleSearchChange = (elem, event) => {
    if (!isNullOrEmpty(event.target.value)) {
      setPageObjectManuelIvr(prevPageObject => ({
        ...prevPageObject,
        page: 0,
        query: prevPageObject.query + `&${elem}.equals=${event.target.value}`,
      }));
    } else {
      const keyToDelete = `${elem}.equals`;
      const updatedParams = pageObjectManuelIvr.query
        .split('&')
        .filter(param => {
          const [key, _] = param.split('=');
          return key !== keyToDelete;
        })
        .join('&');

      setPageObjectManuelIvr({ ...pageObjectManuelIvr, page: 0, query: updatedParams });
    }
  };

  const handlePageChange = (pageNumber, newItemsPerPage = pageObject.size) => {
    if (newItemsPerPage !== pageObject.size) {
      setPageObject({ ...pageObject, page: pageNumber - 1, size: newItemsPerPage });
    } else {
      setPageObject({ ...pageObject, page: pageNumber - 1 });
    }
  };

  const handlePageBildirimChange = (pageNumber, newItemsPerPage = pageObjectBildirim.size) => {
    if (newItemsPerPage !== pageObjectBildirim.size) {
      setPageObjectBildirim({ ...pageObjectBildirim, page: pageNumber - 1, size: newItemsPerPage });
    } else {
      setPageObjectBildirim({ ...pageObjectBildirim, page: pageNumber - 1 });
    }
  };

  const handlePageKrizIvrChange = (pageNumber, newItemsPerPage = pageObjectManuelIvr.size) => {
    if (newItemsPerPage !== pageObjectManuelIvr.size) {
      setPageObjectManuelIvr({ ...pageObjectManuelIvr, page: pageNumber - 1, size: newItemsPerPage });
    } else {
      setPageObjectManuelIvr({ ...pageObjectManuelIvr, page: pageNumber - 1 });
    }
  };

  const fetchTotalRaporData = async (): Promise<IKrizKatilimciBildirim[]> => {
    try {
      const response = (await dispatch(
        getTotalBildirimEntities({ ...pageObject, query2: `manuelBildirimId.equals=${openItemId}`, size: krizKatilimciBildirimTotal })
      )) as {
        payload: { data: IKrizKatilimciBildirim[] };
      };

      return response.payload.data || [];
    } catch (error) {
      console.error('Error fetching data:', error);
      return [];
    }
  };

  const fetchTotalData = async (): Promise<IManuelBildirim[]> => {
    try {
      const response = (await dispatch(getTotalEntities({ ...pageObject, size: totalItem }))) as { payload: { data: IManuelBildirim[] } };

      return response.payload.data || [];
    } catch (error) {
      console.error('Error fetching data:', error);
      return [];
    }
  };

  const fetchTotalManuelIvrBildirimData = async (): Promise<IManuelIvrBildirim[]> => {
    try {
      const response = (await dispatch(
        getTotalEntitiesManuelIvr({
          ...pageObjectManuelIvr,
          size: totalItemManuelIvr,
          query2: `manuelBildirimId.equals=${openItemId}`,
        })
      )) as {
        payload: { data: IManuelIvrBildirim[] };
      };

      return response.payload.data || [];
    } catch (error) {
      console.error('Error fetching data:', error);
      return [];
    }
  };

  const sort = p => () => {
    const orderBy = pageObject.sort.split(',')[1];
    setPageObject({ ...pageObject, sort: `${p},${orderBy === ASC ? DESC : ASC}` });
  };

  const sortAcordion = p => () => {
    const orderBy = pageObjectBildirim.sort.split(',')[1];
    setPageObjectBildirim({ ...pageObjectBildirim, sort: `${p},${orderBy === ASC ? DESC : ASC}` });
  };

  const sortManuelIvr = p => () => {
    const orderBy = pageObjectManuelIvr.sort.split(',')[1];
    setPageObjectManuelIvr({ ...pageObjectManuelIvr, sort: `${p},${orderBy === ASC ? DESC : ASC}` });
  };

  const [selectedBildirim, setSelectedBildirim] = useState(null);
  const [isBildirimDetailModal, setIsBildirimDetailModal] = useState(false);

  const handleCloseForm = () => {
    if (isBildirimDetailModal) {
      setIsBildirimDetailModal(false);
    }
    setSelectedBildirim(null);
  };

  const BildirimDetailModal = () => {
    return (
      <Modal className="modal-style" isOpen={isBildirimDetailModal} toggle={handleCloseForm} centered>
        <ModalHeader data-cy="krizKatilimciBildirimDetailsHeading" toggle={handleCloseForm}>
          <p className="modal-title-info">
            {selectManuelBildirim?.bildirimTuru?.id === ReferansEnum.BildirimTuru.Crysis ? (
              <>
                {selectedBildirim?.adi} {selectedBildirim?.soyadi}
                {' / '}
                {selectManuelBildirim?.bildirimTuru?.adi}
              </>
            ) : (
              <>
                {selectedBildirim?.krizKatilimci?.adi} {selectedBildirim?.krizKatilimci?.soyadi}
                {' / '}
                {selectManuelBildirim?.bildirimTuru?.adi}
              </>
            )}
          </p>
          <Translate contentKey="tk24AdysApp.krizDetay.krizBildirim" />
        </ModalHeader>
        <ModalBody style={{ maxHeight: '500px', overflowY: 'auto' }} className="ql-editor">
          <Row className="modal-body-row">
            {selectManuelBildirim?.bildirimTuru?.id === ReferansEnum.BildirimTuru.EPosta && (
              <Col lg="12" style={{ marginTop: '10px' }}>
                <Row className="modal-content-title">
                  <Translate contentKey="tk24AdysApp.bildirimSablon.baslikKonu">konu</Translate>
                </Row>
                <Row className="modal-content-text">{selectedBildirim?.konu}</Row>
              </Col>
            )}

            <Col lg="12" style={{ marginTop: '10px' }}>
              <Row className="modal-content-title">
                {selectManuelBildirim?.bildirimTuru?.id === ReferansEnum.BildirimTuru.Crysis ? (
                  <Translate contentKey="tk24AdysApp.manuelIvrBildirim.icerik">icerik</Translate>
                ) : (
                  <Translate contentKey="tk24AdysApp.krizKatilimciBildirim.icerik">icerik</Translate>
                )}
              </Row>
              <Row className="modal-content-text">
                {selectedBildirim?.icerik ? <span dangerouslySetInnerHTML={{ __html: selectedBildirim?.icerik }} /> : '-'}
              </Row>
            </Col>

            {selectManuelBildirim?.bildirimTuru?.id === ReferansEnum.BildirimTuru.Crysis && (
              <>
                <Col lg="12" style={{ marginTop: '10px' }}>
                  <Row className="modal-content-title">
                    <Translate contentKey="tk24AdysApp.manuelIvrBildirim.secenek" />
                  </Row>
                  <Row className="modal-content-text">{selectedBildirim?.ivrPrompText}</Row>
                </Col>

                {selectedBildirim?.manuelIvrBildirimTipi?.id === ReferansEnum.ManuelIvrBildirimTipi.BildirimTatbikati && (
                  <Col lg="12" style={{ marginTop: '10px' }}>
                    <Row className="modal-content-title">
                      <Translate contentKey="tk24AdysApp.manuelIvrBildirim.secenekVekil" />
                    </Row>
                    <Row className="modal-content-text">{selectedBildirim?.ivrPrompTextVekil}</Row>
                  </Col>
                )}

                <Col lg="12" style={{ marginTop: '10px' }}>
                  <Row className="modal-content-title">
                    <Translate contentKey="tk24AdysApp.manuelIvrBildirim.ivrPrompText2" />
                  </Row>
                  <Row className="modal-content-text">{selectedBildirim?.ivrPrompText2}</Row>
                </Col>

                <Col lg="12" style={{ marginTop: '10px' }}>
                  <Row className="modal-content-title">
                    <Translate contentKey="tk24AdysApp.krizKatilimciBildirim.hataMesaji" />
                  </Row>
                  <Row className="modal-content-text">{selectedBildirim?.hataMesaji}</Row>
                </Col>
              </>
            )}
          </Row>
        </ModalBody>
        <ModalFooter className="modal-footer"></ModalFooter>
      </Modal>
    );
  };

  const IvrAccordionDetails = () => {
    return (
      <div className="mt-4 mb-4">
        <div className="search-frame">
          <div className="seachiconposition">
            <Search className="seachicon" style={{ color: 'grey' }} />
            <Input
              id={'searchManuelIvr'}
              value={searchManuelIvr}
              onChange={e => setSearchManuelIvr(e.target.value)}
              placeholder={translate('entity.action.search')}
              className="search-input"
            />
          </div>
          <div className="d-flex justify-end">
            <ExcelDownloadButton
              excelSampleData={ExcelSampleDataManuelIvr}
              grantedAuthorites={['manuel_ivr_bildirim_export']}
              fetchData={fetchTotalManuelIvrBildirimData}
              className={'download-button'}
              exportName={translate('tk24AdysApp.krizIvrBildirim.home.title')}
            />
          </div>
        </div>
        <TableContainer className="content-table">
          {manuelIvrBildirimList && manuelIvrBildirimList.length > 0 ? (
            <Table aria-label="a dense table">
              <TableHead className="content-table-title-area">
                <TableRow className="table-head-row">
                  <TableCell
                    align="left"
                    className="table-title-cell hand"
                    onClick={sortManuelIvr(`asilMi.adi${upperOnlyFirstLetter(localeLanguage)}`)}
                  >
                    <div className="tableHeaderContainer">
                      <Translate contentKey="tk24AdysApp.manuelIvrBildirim.asilMi" />
                      &nbsp;
                      <SortIcon column={`asilMi.adi${upperOnlyFirstLetter(localeLanguage)}`} activeSortColumn={pageObjectManuelIvr.sort} />
                    </div>
                  </TableCell>

                  <TableCell align="left" className="table-title-cell hand" onClick={sortManuelIvr('iletisimBilgisi')}>
                    <div className="tableHeaderContainer">
                      <Translate contentKey="tk24AdysApp.krizKatilimciBildirim.iletisimBilgisiTitle">İleitisim Bilgisi</Translate>&nbsp;
                      <SortIcon column="iletisimBilgisi" activeSortColumn={pageObjectManuelIvr.sort} />
                    </div>
                  </TableCell>

                  <TableCell className="accordion-table-title-cell" align="left" onClick={sortManuelIvr('adi')}>
                    <div className="tableHeaderContainer">
                      <Translate contentKey="tk24AdysApp.krizKatilimciBildirim.adi">Adı</Translate> &nbsp;
                      <SortIcon column="adi" activeSortColumn={pageObjectManuelIvr.sort} />
                    </div>
                  </TableCell>

                  <TableCell className="accordion-table-title-cell" align="left" onClick={sortManuelIvr('soyadi')}>
                    <div className="tableHeaderContainer">
                      <Translate contentKey="tk24AdysApp.krizKatilimciBildirim.soyadi">Soyadı</Translate> &nbsp;
                      <SortIcon column="soyadi" activeSortColumn={pageObjectManuelIvr.sort} />
                    </div>
                  </TableCell>

                  <TableCell className="accordion-table-title-cell" align="left" onClick={sortManuelIvr('refKatilimci.adi')}>
                    <div className="tableHeaderContainer">
                      <Translate contentKey="tk24AdysApp.manuelIvrBildirim.asilTitle" /> &nbsp;
                      <SortIcon column="refKatilimci.adi" activeSortColumn={pageObjectManuelIvr.sort} />
                    </div>
                  </TableCell>

                  <TableCell className="accordion-table-title-cell" align="left" onClick={sortManuelIvr(`ivrTalepId`)}>
                    <div className="tableHeaderContainer">
                      <Translate contentKey="tk24AdysApp.manuelIvrBildirim.ivrTalepTitle" /> &nbsp;
                      <SortIcon column={`ivrTalepId`} activeSortColumn={pageObjectManuelIvr.sort} />
                    </div>
                  </TableCell>

                  <TableCell className="accordion-table-title-cell" align="left" onClick={sortManuelIvr(`ivrCevapKodu`)}>
                    <div className="tableHeaderContainer">
                      <Translate contentKey="tk24AdysApp.manuelIvrBildirim.ivrCevapTitle" /> &nbsp;
                      <SortIcon column={`ivrCevapKodu`} activeSortColumn={pageObjectManuelIvr.sort} />
                    </div>
                  </TableCell>

                  <TableCell className="accordion-table-title-cell" align="left" onClick={sortManuelIvr(`ivrSecilenSecenek`)}>
                    <div className="tableHeaderContainer">
                      <Translate contentKey="tk24AdysApp.manuelIvrBildirim.ivrSecilenSecenekTitle" /> &nbsp;
                      <SortIcon column={`ivrSecilenSecenek`} activeSortColumn={pageObjectManuelIvr.sort} />
                    </div>
                  </TableCell>

                  <TableCell className="accordion-table-title-cell" align="left" onClick={sortManuelIvr(`aramaZamani`)}>
                    <div className="tableHeaderContainer">
                      <Translate contentKey="tk24AdysApp.manuelIvrBildirim.aramaZamaniTitle" /> &nbsp;
                      <SortIcon column={`aramaZamani`} activeSortColumn={pageObjectManuelIvr.sort} />
                    </div>
                  </TableCell>

                  <TableCell className="accordion-table-title-cell" align="left" onClick={sortManuelIvr('gonderimZamani')}>
                    <div className="tableHeaderContainer">
                      <Translate contentKey="tk24AdysApp.krizKatilimciBildirim.gonderimZamaniTitle">Gonderim Zamani</Translate> &nbsp;
                      <SortIcon column="gonderimZamani" activeSortColumn={pageObjectManuelIvr.sort} />
                    </div>
                  </TableCell>

                  <TableCell className="accordion-table-title-cell" align="left" onClick={sortManuelIvr('denemeSayisi')}>
                    <div className="tableHeaderContainer">
                      <Translate contentKey="tk24AdysApp.krizKatilimciBildirim.denemeSayisi">Deneme Sayisi</Translate> &nbsp;
                      <SortIcon column="denemeSayisi" activeSortColumn={pageObjectManuelIvr.sort} />
                    </div>
                  </TableCell>

                  <TableCell className="accordion-table-title-cell"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {manuelIvrBildirimList.map(manuelIvrBildirim => (
                  <TableRow key={manuelIvrBildirim.id} className="table-cell-row">
                    <TableCell component="th" scope="row" className="accordion-table-content-cell">
                      {getAsilMiVekilMi(manuelIvrBildirim)}
                    </TableCell>

                    <TableCell component="th" scope="row" className="accordion-table-content-cell">
                      {manuelIvrBildirim.iletisimBilgisi}
                    </TableCell>

                    <TableCell component="th" scope="row" className="accordion-table-content-cell">
                      {manuelIvrBildirim.adi}
                    </TableCell>

                    <TableCell component="th" scope="row" className="accordion-table-content-cell">
                      {manuelIvrBildirim.soyadi}
                    </TableCell>

                    <TableCell component="th" scope="row" className="accordion-table-content-cell">
                      {getAsilBilgi(manuelIvrBildirim)}
                    </TableCell>

                    <TableCell component="th" scope="row" className="accordion-table-content-cell">
                      {manuelIvrBildirim.ivrTalepId}
                    </TableCell>
                    <TableCell component="th" scope="row" className="accordion-table-content-cell">
                      {manuelIvrBildirim.ivrCevapKodu}
                    </TableCell>
                    <TableCell component="th" scope="row" className="accordion-table-content-cell">
                      {manuelIvrBildirim.ivrSecilenSecenek}
                    </TableCell>

                    <TableCell component="th" scope="row" className="accordion-table-content-cell">
                      {manuelIvrBildirim?.aramaZamani ? (
                        <TextFormat type="date" value={manuelIvrBildirim?.aramaZamani} format={APP_DATE_FORMAT} />
                      ) : null}
                    </TableCell>

                    <TableCell component="th" scope="row" className="accordion-table-content-cell">
                      {manuelIvrBildirim?.gonderimZamani ? (
                        <TextFormat type="date" value={manuelIvrBildirim?.gonderimZamani} format={APP_DATE_FORMAT} />
                      ) : null}
                    </TableCell>

                    <TableCell component="th" scope="row" className="accordion-table-content-cell">
                      {manuelIvrBildirim.denemeSayisi}
                    </TableCell>

                    <TableCell className="sticky-right" align="right">
                      <div className="btn-group flex-btn-group-container">
                        <Button
                          className="view-button"
                          onClick={() => {
                            setSelectedBildirim(manuelIvrBildirim);
                            setIsBildirimDetailModal(true);
                          }}
                          size="sm"
                          data-cy="entityDetailsButton"
                        >
                          <img width={15.2} height={11.2} src={`${SERVER_URL}/content/images/icon/faEye.svg`} />
                          <span className="d-none d-md-inline">
                            <Translate contentKey="entity.action.view">Detay</Translate>
                          </span>
                        </Button>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : (
            !loadingManuelIvr && (
              <div className="alert alert-warning">
                <Translate contentKey="tk24AdysApp.krizIvrBildirim.home.notFound">No Ref IVR Cevap Katilim Durumu found</Translate>
              </div>
            )
          )}
        </TableContainer>
        {totalItemManuelIvr ? (
          <div className={manuelIvrBildirimList && manuelIvrBildirimList.length > 0 ? '' : 'd-none'}>
            <CustomPagination
              currentPage={pageObjectManuelIvr.page + 1}
              currentPageSize={pageObjectManuelIvr.size}
              totalItems={totalItemManuelIvr}
              handlePageChange={handlePageKrizIvrChange}
            />
          </div>
        ) : (
          ''
        )}
      </div>
    );
  };
  const RaporAccordionDetails = () => {
    return (
      <div>
        {krizKatilimciBildirimList?.length > 0 ? (
          <Box className="accordion-box">
            <HasAuthComponent grantedAuthorites={['kriz_katilimci_bildirim_read']}>
              <div className="d-flex align-items-center justify-content-between mt-2 mb-2">
                <Typography className="item-total-count  m-0" gutterBottom component="div">
                  <ExcelDownloadButton
                    excelSampleData={AccordionExcelSampleData}
                    grantedAuthorites={['kriz_katilimci_bildirim_export']}
                    fetchData={fetchTotalRaporData}
                    className={'download-button'}
                    exportName={translate('tk24AdysApp.krizKatilimciBildirim.home.title')}
                  />
                </Typography>
              </div>
              <div className="table-responsive" style={{ marginTop: 5 }}>
                {' '}
                <Table className="accordion-table">
                  <TableHead>
                    <TableRow className="accordion-table-title-row">
                      <TableCell className="accordion-table-title-cell" align="left" onClick={sortAcordion('iletisimBilgisi')}>
                        <div className="tableHeaderContainer">
                          <Translate contentKey="tk24AdysApp.krizKatilimciBildirim.iletisimBilgisiTitle">Iletisim Bilgisi</Translate>&nbsp;
                          <SortIcon column="iletisimBilgisi" activeSortColumn={pageObject.sort} />
                        </div>
                      </TableCell>
                      <TableCell className="accordion-table-title-cell" align="left" onClick={sortAcordion('planlananGonderimZamani')}>
                        <div className="tableHeaderContainer">
                          <Translate contentKey="tk24AdysApp.krizKatilimciBildirim.planlananGonderimZamaniTitle">
                            Planlanan Gonderim Zamani
                          </Translate>{' '}
                          &nbsp;
                          <SortIcon column="planlananGonderimZamani" activeSortColumn={pageObject.sort} />
                        </div>
                      </TableCell>
                      <TableCell className="accordion-table-title-cell" align="left" onClick={sortAcordion('gonderimZamani')}>
                        <div className="tableHeaderContainer">
                          <Translate contentKey="tk24AdysApp.krizKatilimciBildirim.gonderimZamaniTitle">Gonderim Zamani</Translate> &nbsp;
                          <SortIcon column="gonderimZamani" activeSortColumn={pageObject.sort} />
                        </div>
                      </TableCell>
                      <TableCell className="accordion-table-title-cell" align="left" onClick={sortAcordion('personel.adi')}>
                        <div className="tableHeaderContainer">
                          <Translate contentKey="tk24AdysApp.krizKatilimciBildirim.adi">Adı</Translate> &nbsp;
                          <SortIcon column="personel.adi" activeSortColumn={pageObject.sort} />
                        </div>
                      </TableCell>

                      <TableCell className="accordion-table-title-cell" align="left" onClick={sortAcordion('personel.soyadi')}>
                        <div className="tableHeaderContainer">
                          <Translate contentKey="tk24AdysApp.krizKatilimciBildirim.soyadi">Soyadı</Translate> &nbsp;
                          <SortIcon column="personel.soyadi" activeSortColumn={pageObject.sort} />
                        </div>
                      </TableCell>

                      <TableCell className="accordion-table-title-cell" align="left" onClick={sortAcordion('gonderimDurumu')}>
                        <div className="tableHeaderContainer">
                          <Translate contentKey="tk24AdysApp.krizKatilimciBildirim.gonderimDurumu">Gonderim Durumu</Translate> &nbsp;
                          <SortIcon column="gonderimDurumu" activeSortColumn={pageObject.sort} />
                        </div>
                      </TableCell>

                      <TableCell className="accordion-table-title-cell" align="left" onClick={sortAcordion('denemeSayisi')}>
                        <div className="tableHeaderContainer">
                          <Translate contentKey="tk24AdysApp.krizKatilimciBildirim.denemeSayisi">Deneme Sayisi</Translate> &nbsp;
                          <SortIcon column="denemeSayisi" activeSortColumn={pageObject.sort} />
                        </div>
                      </TableCell>

                      <TableCell className="accordion-table-title-cell" align="left" onClick={sortAcordion('hataMesaji')}>
                        <div className="tableHeaderContainer">
                          <Translate contentKey="tk24AdysApp.krizKatilimciBildirim.hataMesaji">Hata Mesaji</Translate> &nbsp;
                          <SortIcon column="hataMesaji" activeSortColumn={pageObject.sort} />
                        </div>
                      </TableCell>

                      <TableCell className="accordion-table-title-cell"></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {krizKatilimciBildirimList.map(bildirim => (
                      <TableRow key={bildirim?.id} className="accordion-table-content-row">
                        <TableCell className="accordion-table-content-cell" component="th" scope="row">
                          {bildirim?.bildirimTuru?.id === ReferansEnum.BildirimTuru.EPosta ? (
                            <a href={`mailto:${bildirim?.iletisimBilgisi}`} className="epostaText">
                              {bildirim?.iletisimBilgisi}
                            </a>
                          ) : (
                            bildirim?.iletisimBilgisi
                          )}
                        </TableCell>
                        <TableCell className="accordion-table-content-cell" component="th" scope="row">
                          {bildirim?.planlananGonderimZamani ? (
                            <TextFormat type="date" value={bildirim?.planlananGonderimZamani} format={APP_DATE_FORMAT} />
                          ) : null}
                        </TableCell>
                        <TableCell className="accordion-table-content-cell" component="th" scope="row">
                          {bildirim?.gonderimZamani ? (
                            <TextFormat type="date" value={bildirim?.gonderimZamani} format={APP_DATE_FORMAT} />
                          ) : null}
                        </TableCell>
                        <TableCell className="accordion-table-content-cell" component="th" scope="row">
                          {bildirim?.personel?.adi}
                        </TableCell>
                        <TableCell className="accordion-table-content-cell" component="th" scope="row">
                          {bildirim?.personel?.soyadi}
                        </TableCell>
                        <TableCell className="accordion-table-content-cell" component="th" scope="row">
                          {bildirim?.gonderimDurumu?.adi}
                        </TableCell>
                        <TableCell className="accordion-table-content-cell" component="th" scope="row">
                          {bildirim?.denemeSayisi}
                        </TableCell>
                        <TableCell className="accordion-table-content-cell" component="th" scope="row">
                          {bildirim?.hataMesaji}
                        </TableCell>
                        <TableCell className="sticky-right" align="right">
                          <div className="btn-group flex-btn-group-container">
                            <Button
                              className="view-button"
                              onClick={() => {
                                setSelectedBildirim(bildirim);
                                setIsBildirimDetailModal(true);
                              }}
                              size="sm"
                              data-cy="entityDetailsButton"
                            >
                              <img width={15.2} height={11.2} src={`${SERVER_URL}/content/images/icon/faEye.svg`} />
                              <span className="d-none d-md-inline">
                                <Translate contentKey="entity.action.view">Detay</Translate>
                              </span>
                            </Button>
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>
              <CustomPagination
                currentPage={pageObjectBildirim.page + 1}
                currentPageSize={pageObjectBildirim.size}
                totalItems={krizKatilimciBildirimTotal}
                handlePageChange={handlePageBildirimChange}
              />
            </HasAuthComponent>
          </Box>
        ) : (
          !krizKatilimciBildirimLoading && (
            <div className="alert alert-warning mt-3">
              <Translate contentKey="tk24AdysApp.krizKatilimciBildirim.home.notFound">No found</Translate>
            </div>
          )
        )}
      </div>
    );
  };

  function ManuelBildirimTable({ manuelBildirim, isOpen, openTable }) {
    return (
      <>
        <TableRow className="accordion-content-row" sx={{ '& > *': { borderBottom: 'unset' } }} onClick={openTable}>
          <TableCell className="accordion-content-cell">
            <IconButton aria-label="expand row" size="small" className="accordion-icon">
              {isOpen ? <KeyboardArrowDownOutlined /> : <KeyboardArrowRight />}
            </IconButton>
          </TableCell>
          <TableCell className="accordion-content-cell" component="th" scope="row">
            {manuelBildirim?.bildirimTuru?.adi}
          </TableCell>
          <TableCell className="accordion-content-cell" component="th" scope="row">
            {manuelBildirim?.manuelIvrBildirimTipi?.adi}
          </TableCell>
          <TableCell className="accordion-content-cell" component="th" scope="row">
            {manuelBildirim?.aciklama}
          </TableCell>
          <TableCell className="accordion-content-cell" component="th" scope="row">
            <TextFormat type="date" value={manuelBildirim.cDate} format={APP_DATE_FORMAT} />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell className="accordion-content-area" colSpan={12}>
            <Collapse in={isOpen} unmountOnExit>
              {manuelBildirim?.bildirimTuru?.id === ReferansEnum.BildirimTuru.Crysis ? IvrAccordionDetails() : RaporAccordionDetails()}
            </Collapse>
          </TableCell>
        </TableRow>
      </>
    );
  }

  const getRaporDetayTable = (manuelBildirim: IManuelBildirim) => {
    if (openItemId === manuelBildirim?.id) {
      setOpenItemId(null);
      dispatch(resetBildirimEntities());
    } else {
      setSelectManuelBildirim(manuelBildirim);
      setOpenItemId(manuelBildirim?.id);
    }
  };

  const handleBildirimTuruChange = event => {
    if (!isNullOrEmpty(event.target.value)) {
      setPageObject(prevPageObject => ({
        ...prevPageObject,
        page: 0,
        query: prevPageObject.query + `&bildirimTuruId.equals=${event.target.value}`,
      }));
    } else {
      const keyToDelete = 'bildirimTuruId.equals';
      const updatedParams = pageObject.query
        .split('&')
        .filter(param => {
          const [key, _] = param.split('=');
          return key !== keyToDelete;
        })
        .join('&');

      setPageObject({ ...pageObject, page: 0, query: updatedParams });
    }
  };

  return (
    <div>
      <h2 id="rol-heading" data-cy="RolDokumanHeading" className="title-frame">
        <div className="excel-export-frame">
          <p className="sub-title-text">
            <Translate contentKey="entity.action.rapor" />
          </p>
        </div>
      </h2>
      {isBildirimDetailModal && BildirimDetailModal()}
      {(manuelBildirimListLoading || manuelBildirimListUpdating || krizKatilimciBildirimLoading || loadingManuelIvr) && <LoadingSpinner />}
      <div className="content-area">
        <div className="search-frame">
          <div className="seachiconposition">
            <img className="seachicon" width={24} height={24} src={`${SERVER_URL}/content/images/icon/faSearch.svg`} />
            <Input
              value={search}
              onChange={e => setSearch(e.target.value)}
              placeholder={translate('entity.action.search')}
              className="search-input"
            />
          </div>
          <div className="create-area">
            <Box sx={{ minWidth: 220, maxWidth: 220, marginRight: '15px' }}>
              <FormControl fullWidth>
                <InputLabel id="bildirim-sablon-label" className="select-input-label">
                  <Translate contentKey="tk24AdysApp.bildirimSablon.bildirimTuru" />
                </InputLabel>
                <Select
                  labelId="bildirim-sablon-label"
                  id="bildirim-sablon"
                  label={translate('tk24AdysApp.bildirimSablon.bildirimTuru')}
                  onChange={handleBildirimTuruChange}
                  className="select-input"
                >
                  <MenuItem value="">
                    <Translate contentKey="entity.action.all" />
                  </MenuItem>
                  {bildirimTuruList &&
                    bildirimTuruList.length > 0 &&
                    bildirimTuruList.map(item => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.adi}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Box>

            <ExcelDownloadButton
              excelSampleData={ExcelSampleData}
              grantedAuthorites={['manuel_bildirim_read']}
              fetchData={fetchTotalData}
              className={'download-button'}
              exportName={`${translate('global.menu.parameters.bildirimYonetimi')} ${translate('entity.action.rapor')}`}
            />
          </div>
        </div>
        <div className="table-responsive" style={{ marginTop: 5 }}>
          <TableContainer className="content-table">
            <Table aria-label="collapsible table">
              <TableHead>
                <TableRow className="accordion-title-row">
                  <TableCell className="accordion-title-cell hand" />

                  <TableCell
                    className="accordion-title-cell hand"
                    align="left"
                    onClick={sort(`bildirimTuru.adi${upperOnlyFirstLetter(localeLanguage)}`)}
                  >
                    <div className="tableHeaderContainer">
                      <Translate contentKey="tk24AdysApp.bildirimSablon.bildirimTuruTitle">Bildirim Turu</Translate>&nbsp;
                      <SortIcon column={`bildirimTuru.adi${upperOnlyFirstLetter(localeLanguage)}`} activeSortColumn={pageObject.sort} />
                    </div>
                  </TableCell>
                  <TableCell
                    className="accordion-title-cell hand"
                    align="left"
                    onClick={sort(`manuelIvrBildirimTipi.adi${upperOnlyFirstLetter(localeLanguage)}`)}
                  >
                    <div className="tableHeaderContainer">
                      <Translate contentKey="tk24AdysApp.manuelIvrBildirim.manuelIvrBildirimTipi" />
                      &nbsp;
                      <SortIcon
                        column={`manuelIvrBildirimTipi.adi${upperOnlyFirstLetter(localeLanguage)}`}
                        activeSortColumn={pageObject.sort}
                      />
                    </div>
                  </TableCell>

                  <TableCell className="accordion-title-cell hand" align="left" onClick={sort(`aciklama`)}>
                    <div className="tableHeaderContainer">
                      <Translate contentKey="global.languageGlobal.aciklamaTitle">aciklama</Translate>&nbsp;
                      <SortIcon column={`aciklama}`} activeSortColumn={pageObject.sort} />
                    </div>
                  </TableCell>

                  <TableCell className="accordion-title-cell hand" align="left" onClick={sort(`cDate`)}>
                    <div className="tableHeaderContainer">
                      <Translate contentKey="tk24AdysApp.bildirimYonetimi.cDateTitle">cDate</Translate>&nbsp;
                      <SortIcon column={`cDate}`} activeSortColumn={pageObject.sort} />
                    </div>
                  </TableCell>

                  <TableCell className="accordion-title-cell" />
                </TableRow>
              </TableHead>
              <TableBody>
                {manuelBildirimList?.map(manuelBildirim => (
                  <ManuelBildirimTable
                    key={manuelBildirim?.id}
                    manuelBildirim={manuelBildirim}
                    isOpen={openItemId === manuelBildirim?.id}
                    openTable={() => getRaporDetayTable(manuelBildirim)}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <CustomPagination
          currentPage={pageObject.page + 1}
          currentPageSize={pageObject.size}
          totalItems={totalItem}
          handlePageChange={handlePageChange}
        />
      </div>
    </div>
  );
};

export default BildirimYonetimiRapor;
