import { IReferans } from 'app/shared/model/referans.model';
import { IKrizKatilimci } from './kriz-katilimci.model';
import { IQueryParams } from '../reducers/reducer.utils';

export interface IKrizKatilimciIletisim {
  id?: number;
  guid?: string;
  krizKatilimci?: IKrizKatilimci;
  iletisimTipi?: IReferans;
  iletisimBilgisi?: string;
  entMiGeldi?: IReferans;
  cDate?: string;
  cUser?: number;
  mDate?: string;
  mUser?: number;
  durum?: IReferans;
  varsayilanMi?: any;
}

export interface IKrizKatilimciIletisimParams extends IQueryParams {
  entity?: IKrizKatilimciIletisim;
  katilimciId?: string | number;
}

export const defaultValue: Readonly<IKrizKatilimciIletisim> = {};
