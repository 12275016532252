import { IRefAdtForm } from './ref-adt-form.model';

export interface IRefAdtFormBolum {
  id?: number;
  guid?: string;
  adiTr?: string;
  adiEn?: string;
  cDate?: string;
  cUser?: number;
  mDate?: string;
  mUser?: number;
  sortIndex?: number;
  form?: IRefAdtForm;
  aciklamaTr?: string;
  aciklamaEn?: string;
  aciklama?: string;
}

export const defaultValue: Readonly<IRefAdtFormBolum> = {};
