import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  unauthorizedUser: false,
  loginConfirmation: false,
  confirmationText: false,
  krizAuthorityLoading: false,
};

export type krizAuthorityState = Readonly<typeof initialState>;

export const krizAuthority = createSlice({
  name: 'krizAuthority',
  initialState: initialState as krizAuthorityState,
  reducers: {
    setUnauthorizedUser(state, action) {
      state.unauthorizedUser = action.payload;
    },
    setLoginConfirmation(state, action) {
      state.loginConfirmation = action.payload;
    },
    setConfirmationText(state, action) {
      state.confirmationText = action.payload;
    },
    setKrizAuthorityLoading(state, action) {
      state.krizAuthorityLoading = action.payload;
    },
  },
});

export const { setUnauthorizedUser, setLoginConfirmation, setConfirmationText, setKrizAuthorityLoading } = krizAuthority.actions;

export default krizAuthority.reducer;
