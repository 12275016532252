import React from 'react';
import { Row, Col } from 'reactstrap';
import { Translate } from 'app/component/jhipster';
import { IRefSaglikDurumu } from 'app/shared/model/ref-saglik-durumu.model';

interface RefSaglikDurumuDetailProp {
  refSaglikDurumu: IRefSaglikDurumu;
}

export const RefSaglikDurumuDetail: React.FC<RefSaglikDurumuDetailProp> = ({ refSaglikDurumu }) => {
  return (
    <Row className="modal-body-row">
      <Col lg="6" style={{ marginTop: '10px' }}>
        <Row className="modal-content-title">
          <Translate contentKey="global.languageGlobal.adiTr">Adi Tr</Translate>
        </Row>
        <Row className="modal-content-text">{refSaglikDurumu.adiTr}</Row>
      </Col>
      <Col lg="6" style={{ marginTop: '10px' }}>
        <Row className="modal-content-title">
          <Translate contentKey="global.languageGlobal.adiEn">Adi En</Translate>
        </Row>
        <Row className="modal-content-text">{refSaglikDurumu.adiEn}</Row>
      </Col>
    </Row>
  );
};

export default RefSaglikDurumuDetail;
