import React, { useState, useEffect, useCallback } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Input, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { deleteEntity, getEntities, getTotalEntities } from './rol.reducer';
import { ASC, DESC } from 'app/shared/util/pagination.constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { CustomDeleteEntityConfirm } from 'app/shared/util/confirm-utils';
import RolUpdate from './rol-update';
import { LoadingSpinner } from 'app/component/LoadingSpinner';
import axios from 'axios';
import { modalStyle } from 'app/shared/util/style-utils';
import { toast } from 'react-toastify';
import TransferList from 'app/component/TransferList';
import { Translate, translate } from 'app/component/jhipster';
import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import MatModal from '@mui/material/Modal';
import { getTotalEntities as getTotalYetkiEntities } from '../yetki/yetki.reducer';
import CustomPagination from 'app/component/CustomPagination';
import GrantedButton from 'app/component/GrantedButton';
import { SERVER_URL } from 'app/config/constants';
import { KeyboardArrowRight, KeyboardArrowDownOutlined } from '@mui/icons-material';
import { IRol } from 'app/shared/model/rol.model';
import { IQueryParams } from 'app/shared/reducers/reducer.utils';
import ExcelDownloadButton from 'app/component/ExcelDownload';
import SortIcon from 'app/component/SortIcon';
import { localeLanguage, upperOnlyFirstLetter } from 'app/shared/util/util';
import { IYetki } from 'app/shared/model/yetki.model';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import classnames from 'classnames';
import { getEntities as getReferans } from '../referans/referans.reducer';
import { initialState } from 'app/shared/reducers/authentication';

export const Rol = (props: RouteComponentProps<{ url: string }>) => {
  const dispatch = useAppDispatch();

  const [rolLoading, setRolLoading] = useState(false);
  const [onlyForms, setOnlyForms] = useState(null);
  const [rolYetki, setRolYetki] = useState(null);

  const forms = useAppSelector(state => state.yetki.totalEntities);
  const formsLoading = useAppSelector(state => state.yetki.loading);
  const referansLoading = useAppSelector(state => state.referans.loading);

  const [rolYetkiList, setRolYetkiList] = useState(null);
  const [rolYetkiTotalList, setRolYetkiTotalList] = useState(null);
  const rolList = useAppSelector(state => state.rol.entities);
  const loading = useAppSelector(state => state.rol.loading);
  const updating = useAppSelector(state => state.rol.updating);
  const totalItem = useAppSelector(state => state.rol.totalItems);

  const [open, setOpen] = React.useState(false);
  const [openItemId, setOpenItemId] = useState<number | null>(null);

  const [isRolUpdateModal, setIsRolUpdateModal] = useState(false);
  const [selectedRol, setSelectedRol] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const [search, setSearch] = useState('');
  const [accordionSearch, setAccordionSearch] = useState('');

  const [pageObject, setPageObject] = useState<IQueryParams>(useAppSelector(state => state.paginate.pageObject));
  const [pageObjectYetki, setPageObjectYetki] = useState<IQueryParams>(useAppSelector(state => state.paginate.pageObject));
  const [pageObjectKullanici, setPageObjectKullanici] = useState<IQueryParams>(useAppSelector(state => state.paginate.pageObject));
  const [sortField, setSortField] = useState('yetki.adiTr');
  const [sortOrder, setSortOrder] = useState('asc');
  const [sortFieldKullanici, setSortFieldKullanici] = useState('kullanici.adi');
  const [sortOrderKullanici, setSortOrderKullanici] = useState('asc');
  const [totalYetki, setTotalYetki] = useState(0);
  const [activeSummaryTab, setActiveSummaryTab] = useState(1);
  const [kullaniciList, setKullaniciList] = useState([]);
  const [rolKullaniciTotalList, setRolKullaniciTotalList] = useState(null);
  const [kullaniciLoading, setKullaniciLoading] = useState(false);

  const ExcelSampleData = [
    { key: 'adiTr', titleContentKey: 'global.languageGlobal.adiTr' },
    { key: 'adiEn', titleContentKey: 'global.languageGlobal.adiEn' },
    { key: 'aciklamaTr', titleContentKey: 'global.languageGlobal.aciklamaTr' },
    { key: 'aciklamaEn', titleContentKey: 'global.languageGlobal.aciklamaEn' },
    { key: 'katilimciRolu.adi', titleContentKey: 'tk24AdysApp.refKatilimci.katilimciRolu.adi' },
  ];

  const AccordionExcelSampleData = [
    { key: 'yetki.code', titleContentKey: 'tk24AdysApp.yetki.code' },
    { key: 'yetki.adiTr', titleContentKey: 'tk24AdysApp.yetki.adiTr' },
    { key: 'yetki.adiEn', titleContentKey: 'tk24AdysApp.yetki.adiEn' },
    { key: 'yetki.nesneTr', titleContentKey: 'tk24AdysApp.yetki.nesneTr' },
    { key: 'yetki.nesneEn', titleContentKey: 'tk24AdysApp.yetki.nesneEn' },
  ];

  const AccordionExcelSampleDataKullanici = [
    { key: 'kullanici.adi', titleContentKey: 'tk24AdysApp.kullanici.adi' },
    { key: 'kullanici.soyadi', titleContentKey: 'tk24AdysApp.kullanici.soyadi' },
    { key: 'kullanici.unvanAdi', titleContentKey: 'tk24AdysApp.kullanici.unvanAdi' },
    { key: 'kullanici.kullaniciAdi', titleContentKey: 'tk24AdysApp.kullanici.kullaniciAdi' },
    { key: 'kullanici.eposta', titleContentKey: 'tk24AdysApp.kullanici.eposta' },
  ];

  const getAllEntities = () => {
    dispatch(getEntities(pageObject));
  };

  useEffect(() => {
    getAllEntities();
  }, [pageObject.page, pageObject.query, pageObject.size, pageObject.sort]);

  useEffect(() => {
    if (search.length > 2) {
      setPageObject({ ...pageObject, page: 0, query: `globalFilter=${search}` });
    } else if (search.length === 0) {
      setPageObject({ ...pageObject, page: 0, query: '' });
    }
  }, [search]);

  useEffect(() => {
    if (openItemId) {
      if (accordionSearch.length > 2) {
        getYetkiList(selectedRol.id, accordionSearch);
      } else if (accordionSearch.length === 0) {
        getYetkiList(selectedRol.id);
      }
    }
  }, [openItemId, sortField, sortOrder, accordionSearch, pageObjectYetki]);

  useEffect(() => {
    if (openItemId) {
      setAccordionSearch('');
      setPageObjectYetki({
        page: 0,
        size: 20,
        sort: `id,desc`,
        query: '',
      });
    }
  }, [openItemId]);

  useEffect(() => {
    dispatch(getTotalYetkiEntities({ size: 999, sort: `adi${upperOnlyFirstLetter(localeLanguage)},asc` }));
    dispatch(getReferans({ size: 999, sort: `adi${upperOnlyFirstLetter(localeLanguage)},asc`, page: 0 }));
  }, []);

  useEffect(() => {
    getKullanici();
  }, [sortFieldKullanici, sortOrderKullanici]);

  const sort = p => () => {
    const orderBy = pageObject.sort.split(',')[1];
    setPageObject({ ...pageObject, sort: `${p},${orderBy === ASC ? DESC : ASC}` });
  };

  const getKullanici = async () => {
    setKullaniciLoading(true);
    const krizChatUrl = `api/kullanici-rols?rolId.equals=${selectedRol?.id}&sort=${sortFieldKullanici},${sortOrderKullanici}&size=999`;
    await axios.get(krizChatUrl).then(response => {
      setKullaniciList(response.data);
      setKullaniciLoading(false);
    });
  };

  const handlePageChange = (pageNumber, newItemsPerPage = pageObject.size) => {
    if (newItemsPerPage !== pageObject.size) {
      setPageObject({ ...pageObject, page: pageNumber - 1, size: newItemsPerPage });
    } else {
      setPageObject({ ...pageObject, page: pageNumber - 1 });
    }
  };

  const handleCloseUpdateForm = () => {
    if (isRolUpdateModal) {
      setIsRolUpdateModal(false);
    }
    setSelectedRol(null);
  };

  const handleOpen = rol => {
    setRolLoading(true);
    setSelectedRol(rol);
    const dataForm = [];
    const ekipFormsUrl = `api/rol-yetkis?rolId.equals=${rol.id}&sort=yetki.adi${upperOnlyFirstLetter(localeLanguage)},asc&size=999`;
    axios.get(ekipFormsUrl).then(response => {
      response.data.map(item => dataForm.push(item.yetki));
      setOnlyForms(dataForm);
      setOpen(true);
      setRolLoading(false);
    });
    const rolDataYetki = rolList.filter(team => team.id === rol.id);
    setRolYetki(rolDataYetki);
  };

  const handleClose = () => {
    setOpen(false);
    // setSelectedRol(null);
  };

  const confirmRolDelete = rol => {
    setSelectedRol(rol);
    setIsDeleteModalOpen(true);
  };

  const handleDeleteConfirm = useCallback(async () => {
    if (selectedRol) {
      const entity = { ...pageObject, entity: selectedRol };

      if (rolList.length === 1) {
        entity.page = pageObject.page - 1;
        setPageObject({ ...pageObject, page: pageObject.page - 1 });
      }

      await dispatch(deleteEntity(entity));
      handleDeleteCancel();
    }
  }, [selectedRol, dispatch]);

  const handleDeleteCancel = () => {
    setSelectedRol(null);
    setIsDeleteModalOpen(false);
  };

  const fetchTotalRolData = async (): Promise<IRol[]> => {
    try {
      const response = (await dispatch(getTotalEntities({ ...pageObject, size: totalItem }))) as {
        payload: { data: IRol[] };
      };

      return response.payload.data || [];
    } catch (error) {
      console.error('Error fetching data:', error);
      return [];
    }
  };

  const fetchTotalYetkiData = (): IYetki[] => {
    setRolLoading(true);
    const totalYetkiUrl = `api/rol-yetkis?rolId.equals=${selectedRol?.id}&size=1000`;

    axios.get(totalYetkiUrl).then(response => {
      if (Array.isArray(response?.data)) {
        setRolYetkiTotalList(response?.data);
      }
    });
    setRolLoading(false);

    try {
      if (selectedRol) {
        return rolYetkiTotalList || [];
      } else {
        return [];
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      return [];
    }
  };

  const fetchTotalKullaniciData = (): IYetki[] => {
    setKullaniciLoading(true);
    const totalKullaniciUrl = `api/kullanici-rols?rolId.equals=${selectedRol?.id}&size=1000`;

    axios.get(totalKullaniciUrl).then(response => {
      if (Array.isArray(response?.data)) {
        setRolKullaniciTotalList(response?.data);
      }
    });
    setKullaniciLoading(false);

    try {
      if (selectedRol) {
        return rolKullaniciTotalList || [];
      } else {
        return [];
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      return [];
    }
  };

  const saveTransfer = async (right: any) => {
    const data = {
      rol: rolYetki[0],
      yetkiList: right,
    };

    setRolLoading(true);
    const refRolsFormUrl = `api/rol-yetkis/add`;
    await axios
      .post(refRolsFormUrl, data)
      .then(response => {
        toast.success(translate('tk24AdysApp.rolYetki.updated', { param: data.rol.adi }));
      })
      .catch(function (error) {
        toast.error(translate(`error.http.${error.response.status}`));
      });

    await getYetkiList(selectedRol?.id);
    setRolLoading(false);
    handleClose();
  };

  const getYetkiTable = (rol: IRol) => {
    if (openItemId === rol?.id) {
      setOpenItemId(null);
      setActiveSummaryTab(1);
      setRolYetkiList(null);
    } else {
      setOpenItemId(rol?.id);
      setActiveSummaryTab(1);
      setSelectedRol(rol);
      setPageObjectYetki({ ...pageObjectYetki, page: 0 });
    }
  };

  const handlePageChangeYetki = (pageNumber, newItemsPerPage = pageObjectYetki.size) => {
    if (newItemsPerPage !== pageObjectYetki.size) {
      setPageObjectYetki({ ...pageObjectYetki, page: pageNumber - 1, size: newItemsPerPage });
    } else {
      setPageObjectYetki({ ...pageObjectYetki, page: pageNumber - 1 });
    }
  };

  const sortAcordion = p => () => {
    const orderBy = pageObjectYetki.sort.split(',')[1];
    const newSortOrder = orderBy === ASC ? DESC : ASC;
    setPageObjectYetki({ ...pageObjectYetki, sort: `${p},${newSortOrder}` });
    setSortField(p);
    setSortOrder(newSortOrder);
  };

  const sortKullaniciAcordion = p => () => {
    const orderBy = pageObjectKullanici.sort.split(',')[1];
    const newSortOrder = orderBy === ASC ? DESC : ASC;
    setPageObjectKullanici({ ...pageObjectKullanici, sort: `${p},${newSortOrder}` });
    setSortFieldKullanici(p);
    setSortOrderKullanici(newSortOrder);
  };

  const getYetkiList = async (rolId: number, searchInput?: string) => {
    setRolLoading(true);
    setRolYetkiList(null);
    let kalitimciDdmsUrl = `api/rol-yetkis?rolId.equals=${rolId}&sort=${sortField},${sortOrder}&size=${pageObjectYetki.size}&page=${pageObjectYetki.page}`;

    if (searchInput) {
      kalitimciDdmsUrl += `&globalFilter=${searchInput}`;
    }

    await axios.get(kalitimciDdmsUrl).then(response => {
      if (Array.isArray(response?.data)) {
        setRolYetkiList(response?.data);
        const totalCountHeader = response.headers['x-total-count'];
        setTotalYetki(parseInt(totalCountHeader, 10) || 0);
      }
    });
    setRolLoading(false);
  };

  const RolUpdateModal = () => {
    return (
      <RolUpdate
        handleCloseForm={handleCloseUpdateForm}
        formValues={selectedRol}
        isOpen={isRolUpdateModal}
        pageObject={pageObject}
      ></RolUpdate>
    );
  };

  const RolYetkiUpdateModal = () => {
    return (
      <MatModal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={modalStyle} style={{ minWidth: 1200, height: 'auto' }} className="transferBox">
          {selectedRol && forms && forms.length > 0 && onlyForms && (
            <TransferList
              formData={forms}
              onlyForms={onlyForms}
              saveTransfer={saveTransfer}
              closeModal={handleClose}
              isSort={false}
              header={`${selectedRol.adi}`}
              title={translate('tk24AdysApp.rol.home.title')}
              transactionTitle={translate('tk24AdysApp.yetki.home.titles')}
            />
          )}
        </Box>
      </MatModal>
    );
  };

  return (
    <div>
      {(loading || updating || rolLoading || formsLoading || kullaniciLoading || referansLoading) && <LoadingSpinner />}
      {open && RolYetkiUpdateModal()}
      {isRolUpdateModal && RolUpdateModal()}
      {selectedRol && (
        <CustomDeleteEntityConfirm
          entity={selectedRol}
          entityName="rol"
          isOpen={isDeleteModalOpen}
          onConfirm={handleDeleteConfirm}
          onCancel={handleDeleteCancel}
        />
      )}

      <div style={{ display: 'flex', gap: 8, textAlign: 'center' }}>
        <p className="icon-text">
          <Link to={`/`} href={`/`} className="breadcrumb-link">
            <FontAwesomeIcon width={13} height={13} icon={faHome} />
          </Link>
        </p>
        <p className="separator"> / </p>
        <p className="link-text">
          {' '}
          <Translate contentKey="tk24AdysApp.rol.home.title" />{' '}
        </p>
      </div>

      <h2 id="rol-heading" data-cy="RolDokumanHeading" className="title-frame">
        <a className="link-a-text" onClick={() => props.history.goBack()}>
          <img width={27.5} height={22.5} src={`${SERVER_URL}/content/images/icon/faArrowLeft.svg`} />{' '}
        </a>
        <p className="title-text">
          <Translate contentKey="tk24AdysApp.rol.home.title" />
        </p>
      </h2>

      <div className="content-area">
        <div className="search-frame">
          <div className="seachiconposition">
            <img className="seachicon" width={24} height={24} src={`${SERVER_URL}/content/images/icon/faSearch.svg`} />
            <Input
              value={search}
              onChange={e => setSearch(e.target.value)}
              placeholder={translate('entity.action.search')}
              className="search-input"
            />
          </div>
          <div className="create-area">
            <ExcelDownloadButton
              excelSampleData={ExcelSampleData}
              grantedAuthorites={['rol_export']}
              fetchData={fetchTotalRolData}
              className={'download-button'}
              exportName={translate('tk24AdysApp.rol.home.title')}
            />
            <GrantedButton
              grantedAuthorites={['rol_edit']}
              onClick={() => {
                setIsRolUpdateModal(true);
              }}
              className="create-button"
              size="sm"
              data-cy="entityCreateButton"
            >
              <Tooltip title={<Translate contentKey="tk24AdysApp.rol.home.createLabel" />}>
                <img width={14} height={14} src={`${SERVER_URL}/content/images/icon/faPlus.svg`} />
              </Tooltip>
              <span className="show-on-lg">
                <Translate contentKey="tk24AdysApp.rol.home.createLabel" />
              </span>
            </GrantedButton>
          </div>
        </div>
        <div className="table-responsive" style={{ marginTop: 5 }}>
          <TableContainer className="content-table">
            <Table aria-label="collapsible table">
              <TableHead>
                <TableRow className="accordion-title-row">
                  <TableCell className="accordion-title-cell hand" style={{ width: 48 }} />
                  <TableCell className="accordion-title-cell hand" align="left" onClick={sort('adiTr')}>
                    <div className="tableHeaderContainer">
                      <Translate contentKey="global.languageGlobal.adiTrTitle">adiTr</Translate>&nbsp;
                      <SortIcon column="adiTr" activeSortColumn={pageObject.sort} />
                    </div>
                  </TableCell>
                  <TableCell className="accordion-title-cell hand" align="left" onClick={sort('adiEn')}>
                    <div className="tableHeaderContainer">
                      <Translate contentKey="global.languageGlobal.adiEnTitle">adiEn</Translate>&nbsp;
                      <SortIcon column="adiEn" activeSortColumn={pageObject.sort} />
                    </div>
                  </TableCell>
                  <TableCell className="accordion-title-cell hand" align="left" onClick={sort('aciklamaTr')}>
                    <div className="tableHeaderContainer">
                      <Translate contentKey="global.languageGlobal.aciklamaTrTitle">aciklamaTr</Translate>&nbsp;
                      <SortIcon column="aciklamaTr" activeSortColumn={pageObject.sort} />
                    </div>
                  </TableCell>
                  <TableCell className="accordion-title-cell hand" align="left" onClick={sort('aciklamaEn')}>
                    <div className="tableHeaderContainer">
                      <Translate contentKey="global.languageGlobal.aciklamaEnTitle">aciklamaEnTitle</Translate>&nbsp;
                      <SortIcon column="aciklamaEn" activeSortColumn={pageObject.sort} />
                    </div>
                  </TableCell>
                  <TableCell
                    className="accordion-title-cell hand"
                    align="left"
                    onClick={sort(`katilimciRolu.adi${upperOnlyFirstLetter(localeLanguage)}`)}
                  >
                    <div className="tableHeaderContainer">
                      <Translate contentKey="tk24AdysApp.refKatilimci.katilimciRolu.adiTitle">katilimciRoluAdi</Translate>&nbsp;
                      <SortIcon column={`katilimciRolu.adi${upperOnlyFirstLetter(localeLanguage)}`} activeSortColumn={pageObject.sort} />
                    </div>
                  </TableCell>
                  <TableCell className="sticky-right-header-accordion"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rolList?.map(rol => (
                  <>
                    <TableRow
                      className="accordion-content-row"
                      sx={{ '& > *': { borderBottom: 'unset' } }}
                      onClick={() => getYetkiTable(rol)}
                    >
                      <TableCell className="accordion-content-cell" style={{ width: 48 }}>
                        <IconButton aria-label="expand row" size="small" className="accordion-icon">
                          {openItemId === rol?.id ? <KeyboardArrowDownOutlined /> : <KeyboardArrowRight />}
                        </IconButton>
                      </TableCell>
                      <TableCell className="accordion-content-cell" component="th" scope="row">
                        {rol?.adiTr}
                      </TableCell>
                      <TableCell className="accordion-content-cell" component="th" scope="row">
                        {rol?.adiEn}
                      </TableCell>
                      <TableCell className="accordion-content-cell" component="th" scope="row">
                        {rol?.aciklamaTr}
                      </TableCell>
                      <TableCell className="accordion-content-cell" component="th" scope="row">
                        {rol?.aciklamaEn}
                      </TableCell>
                      <TableCell className="accordion-content-cell" component="th" scope="row">
                        {rol?.katilimciRolu?.adi}
                      </TableCell>
                      <TableCell align="right" className="sticky-right">
                        <div className="btn-group flex-btn-group-container">
                          <GrantedButton
                            grantedAuthorites={['rol_edit']}
                            className="edit-button"
                            onClick={event => {
                              event.stopPropagation();
                              setSelectedRol(rol);
                              setIsRolUpdateModal(true);
                            }}
                            size="sm"
                            data-cy="entityEditButton"
                          >
                            <Tooltip title={<Translate contentKey="entity.action.edit">Edit</Translate>}>
                              <img width={14} height={14} src={`${SERVER_URL}/content/images/icon/faPencil.svg`} />
                            </Tooltip>
                            <span className="show-on-lg">
                              <Translate contentKey="entity.action.edit">Edit</Translate>
                            </span>
                          </GrantedButton>
                          <GrantedButton
                            grantedAuthorites={['rol_delete']}
                            onClick={event => {
                              event.stopPropagation();
                              confirmRolDelete(rol);
                            }}
                            style={{ marginLeft: 10 }}
                            className="delete-button"
                            size="sm"
                            data-cy="entityDeleteButton"
                          >
                            <Tooltip title={<Translate contentKey="entity.action.delete">Delete</Translate>}>
                              <img width={11.2} height={12.8} src={`${SERVER_URL}/content/images/icon/faTrashCan.svg`} />
                            </Tooltip>
                            <span className="show-on-lg">
                              <Translate contentKey="entity.action.delete">Delete</Translate>
                            </span>
                          </GrantedButton>
                        </div>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className="accordion-content-area" colSpan={12}>
                        <Collapse in={openItemId === rol?.id} unmountOnExit>
                          <Nav className="custom-nav-tabs mt-3" tabs>
                            <NavItem>
                              <NavLink
                                className={classnames('custom-nav-link', { active: activeSummaryTab === 1 })}
                                onClick={() => {
                                  setActiveSummaryTab(1);
                                }}
                              >
                                <Translate contentKey="tk24AdysApp.yetki.rolYetkiTab" />
                              </NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink
                                className={classnames('custom-nav-link', { active: activeSummaryTab === 2 })}
                                onClick={() => {
                                  setActiveSummaryTab(2);
                                  getKullanici();
                                }}
                              >
                                <Translate contentKey="tk24AdysApp.kullanici.rolKullaniciTab" />
                              </NavLink>
                            </NavItem>
                          </Nav>
                          <TabContent activeTab={activeSummaryTab}>
                            <TabPane tabId={1}>
                              <Box className="accordion-box">
                                <Typography className="accordion-typography" gutterBottom component="div">
                                  <div className="search-frame">
                                    <div className="seachiconposition">
                                      <img
                                        className="seachicon"
                                        width={24}
                                        height={24}
                                        src={`${SERVER_URL}/content/images/icon/faSearch.svg`}
                                      />
                                      <Input
                                        value={accordionSearch}
                                        onChange={e => setAccordionSearch(e.target.value)}
                                        placeholder={translate('entity.action.search')}
                                        className="search-input"
                                      />
                                    </div>
                                    <div className="create-area">
                                      <ExcelDownloadButton
                                        excelSampleData={AccordionExcelSampleData}
                                        grantedAuthorites={['yetki_export']}
                                        fetchData={fetchTotalYetkiData}
                                        className={'download-button'}
                                        exportName={translate('tk24AdysApp.yetki.home.titles')}
                                      />
                                    </div>
                                  </div>
                                </Typography>
                                {rolYetkiList?.length > 0 ? (
                                  <>
                                    <Table className="accordion-table">
                                      <TableHead>
                                        <TableRow className="accordion-table-title-row">
                                          <TableCell
                                            className="accordion-table-title-cell"
                                            align="left"
                                            onClick={sortAcordion('yetki.code')}
                                          >
                                            <div className="tableHeaderContainer">
                                              <Translate contentKey="tk24AdysApp.yetki.code">code</Translate>&nbsp;
                                              <SortIcon column="yetki.code" activeSortColumn={pageObjectYetki.sort} />
                                            </div>
                                          </TableCell>
                                          <TableCell
                                            className="accordion-table-title-cell"
                                            align="left"
                                            onClick={sortAcordion('yetki.adiTr')}
                                          >
                                            <div className="tableHeaderContainer">
                                              <Translate contentKey="tk24AdysApp.yetki.adiTrTitle">adi</Translate>&nbsp;
                                              <SortIcon column="yetki.adiTr" activeSortColumn={pageObjectYetki.sort} />
                                            </div>
                                          </TableCell>
                                          <TableCell
                                            className="accordion-table-title-cell"
                                            align="left"
                                            onClick={sortAcordion('yetki.adiEn')}
                                          >
                                            <div className="tableHeaderContainer">
                                              <Translate contentKey="tk24AdysApp.yetki.adiEnTitle">adi</Translate>&nbsp;
                                              <SortIcon column="yetki.adiEn" activeSortColumn={pageObjectYetki.sort} />
                                            </div>
                                          </TableCell>
                                          <TableCell
                                            className="accordion-table-title-cell"
                                            align="left"
                                            onClick={sortAcordion('yetki.nesneTr')}
                                          >
                                            <div className="tableHeaderContainer">
                                              <Translate contentKey="tk24AdysApp.yetki.nesneTrTitle">nesne</Translate>&nbsp;
                                              <SortIcon column="yetki.nesneTr" activeSortColumn={pageObjectYetki.sort} />
                                            </div>
                                          </TableCell>
                                          <TableCell
                                            className="accordion-table-title-cell"
                                            align="left"
                                            onClick={sortAcordion('yetki.nesneEn')}
                                          >
                                            <div className="tableHeaderContainer">
                                              <Translate contentKey="tk24AdysApp.yetki.nesneEnTitle">nesne</Translate>&nbsp;
                                              <SortIcon column="yetki.nesneEn" activeSortColumn={pageObjectYetki.sort} />
                                            </div>
                                          </TableCell>
                                        </TableRow>
                                      </TableHead>
                                      <TableBody>
                                        {rolYetkiList
                                          ?.filter(item => item?.yetki)
                                          .map(item => (
                                            <TableRow key={item?.id} className="accordion-table-content-row">
                                              <TableCell className="accordion-table-content-cell" component="th" scope="row">
                                                {item?.yetki?.code}
                                              </TableCell>
                                              <TableCell className="accordion-table-content-cell" component="th" scope="row">
                                                {item?.yetki?.adiTr}
                                              </TableCell>
                                              <TableCell className="accordion-table-content-cell" component="th" scope="row">
                                                {item?.yetki?.adiEn}
                                              </TableCell>
                                              <TableCell className="accordion-table-content-cell" component="th" scope="row">
                                                {item?.yetki?.nesneTr}
                                              </TableCell>
                                              <TableCell className="accordion-table-content-cell" component="th" scope="row">
                                                {item?.yetki?.nesneEn}
                                              </TableCell>
                                            </TableRow>
                                          ))}
                                      </TableBody>
                                    </Table>
                                    <CustomPagination
                                      currentPage={pageObjectYetki.page + 1}
                                      currentPageSize={pageObjectYetki.size}
                                      totalItems={totalYetki}
                                      handlePageChange={handlePageChangeYetki}
                                    />
                                  </>
                                ) : (
                                  !rolLoading && (
                                    <div className="alert alert-warning mt-3">
                                      <Translate contentKey="tk24AdysApp.yetki.home.notFound">No RolYetki found</Translate>
                                    </div>
                                  )
                                )}
                                <div className="flex-start-frame">
                                  <GrantedButton
                                    grantedAuthorites={['yetki_read']}
                                    onClick={event => {
                                      event.stopPropagation();
                                      handleOpen(rol);
                                    }}
                                    className="accordion-create-button"
                                    size="sm"
                                    data-cy="entityCreateButton"
                                  >
                                    <FontAwesomeIcon icon="list" />
                                    <span className="d-none d-md-inline">
                                      {' '}
                                      <Translate contentKey="tk24AdysApp.rol.rolYetkiIslemleri" />
                                    </span>
                                  </GrantedButton>
                                </div>
                              </Box>
                            </TabPane>
                            <TabPane tabId={2}>
                              <Box className="accordion-box">
                                {kullaniciList?.length > 0 ? (
                                  <>
                                    <Typography className="item-total-count" gutterBottom component="div">
                                      <span className="totalListItemText">
                                        <Translate contentKey="global.item-total-count" interpolate={{ total: kullaniciList?.length }} />
                                      </span>
                                      <div className="create-area">
                                        <ExcelDownloadButton
                                          excelSampleData={AccordionExcelSampleDataKullanici}
                                          grantedAuthorites={['kullanici_export']}
                                          fetchData={fetchTotalKullaniciData}
                                          className={'download-button'}
                                          exportName={translate('tk24AdysApp.kullanici.home.titles')}
                                        />
                                      </div>
                                    </Typography>
                                    <Table className="accordion-table">
                                      <TableHead>
                                        <TableRow className="accordion-table-title-row">
                                          <TableCell
                                            className="accordion-table-title-cell"
                                            align="left"
                                            onClick={sortKullaniciAcordion('kullanici.adi')}
                                          >
                                            <div className="tableHeaderContainer">
                                              <Translate contentKey="tk24AdysApp.kullanici.adi">adi</Translate>&nbsp;
                                              <SortIcon column="kullanici.adi" activeSortColumn={pageObjectYetki.sort} />
                                            </div>
                                          </TableCell>
                                          <TableCell
                                            className="accordion-table-title-cell"
                                            align="left"
                                            onClick={sortKullaniciAcordion('kullanici.soyadi')}
                                          >
                                            <div className="tableHeaderContainer">
                                              <Translate contentKey="tk24AdysApp.kullanici.soyadi">adi</Translate>&nbsp;
                                              <SortIcon column="kullanici.soyadi" activeSortColumn={pageObjectYetki.sort} />
                                            </div>
                                          </TableCell>
                                          <TableCell
                                            className="accordion-table-title-cell"
                                            align="left"
                                            onClick={sortKullaniciAcordion('kullanici.unvanAdi')}
                                          >
                                            <div className="tableHeaderContainer">
                                              <Translate contentKey="tk24AdysApp.kullanici.unvanAdiTitle">adi</Translate>&nbsp;
                                              <SortIcon column="kullanici.unvanAdi" activeSortColumn={pageObjectYetki.sort} />
                                            </div>
                                          </TableCell>
                                          <TableCell
                                            className="accordion-table-title-cell"
                                            align="left"
                                            onClick={sortKullaniciAcordion('kullanici.kullaniciAdi')}
                                          >
                                            <div className="tableHeaderContainer">
                                              <Translate contentKey="tk24AdysApp.kullanici.kullaniciAdi">adi</Translate>&nbsp;
                                              <SortIcon column="kullanici.kullaniciAdi" activeSortColumn={pageObjectYetki.sort} />
                                            </div>
                                          </TableCell>
                                          <TableCell
                                            className="accordion-table-title-cell"
                                            align="left"
                                            onClick={sortKullaniciAcordion('kullanici.eposta')}
                                          >
                                            <div className="tableHeaderContainer">
                                              <Translate contentKey="tk24AdysApp.kullanici.epostaTitle">adi</Translate>&nbsp;
                                              <SortIcon column="kullanici.eposta" activeSortColumn={pageObjectYetki.sort} />
                                            </div>
                                          </TableCell>
                                        </TableRow>
                                      </TableHead>
                                      <TableBody>
                                        {kullaniciList
                                          ?.filter(item => item?.kullanici)
                                          .map(item => (
                                            <TableRow key={item?.id} className="accordion-table-content-row">
                                              <TableCell className="accordion-table-content-cell" component="th" scope="row">
                                                {item?.kullanici?.adi}
                                              </TableCell>
                                              <TableCell className="accordion-table-content-cell" component="th" scope="row">
                                                {item?.kullanici?.soyadi}
                                              </TableCell>
                                              <TableCell className="accordion-table-content-cell" component="th" scope="row">
                                                {item?.kullanici?.unvanAdi}
                                              </TableCell>
                                              <TableCell className="accordion-table-content-cell" component="th" scope="row">
                                                {item?.kullanici?.kullaniciAdi}
                                              </TableCell>
                                              <TableCell className="accordion-table-content-cell" component="th" scope="row">
                                                <a href={`mailto:${item?.kullanici?.eposta}`} className="epostaText">
                                                  {item?.kullanici?.eposta}
                                                </a>
                                              </TableCell>
                                            </TableRow>
                                          ))}
                                      </TableBody>
                                    </Table>
                                  </>
                                ) : (
                                  <div className="alert alert-warning">
                                    <Translate contentKey="tk24AdysApp.kullanici.home.notFound">No kullanici found</Translate>
                                  </div>
                                )}
                              </Box>
                            </TabPane>
                          </TabContent>
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  </>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <CustomPagination
          currentPage={pageObject.page + 1}
          currentPageSize={pageObject.size}
          totalItems={totalItem}
          handlePageChange={handlePageChange}
        />
      </div>
    </div>
  );
};

export default Rol;
