import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isDrawer: false,
  activeTabId: 1,
};

export type KrizChatState = Readonly<typeof initialState>;

export const KrizChatSlice = createSlice({
  name: 'krizChat',
  initialState: initialState as KrizChatState,
  reducers: {
    toggleDrawer(state) {
      state.isDrawer = !state.isDrawer;
    },
    closeDrawer(state) {
      state.isDrawer = false;
    },
    setActiveTab(state, action) {
      state.activeTabId = action.payload;
    },
  },
});

export default KrizChatSlice.reducer;
