import { IKullanici } from 'app/shared/model/kullanici.model';
import { IRol } from 'app/shared/model/rol.model';
import { IReferans } from 'app/shared/model/referans.model';
import { IPersonel } from './personel.model';

export interface IKullaniciRol {
  id?: number;
  guid?: string;
  cDate?: string;
  cUser?: number;
  mDate?: string;
  mUser?: number;
  kullanici?: IKullanici;
  rol?: IRol;
  durum?: IReferans;
}

export const defaultValue: Readonly<IKullaniciRol> = {};
