import React from 'react';
import { Row, Col } from 'reactstrap';
import { Translate, TextFormat } from 'app/component/jhipster';
import { APP_DATE_FORMAT } from 'app/config/constants';
import { IRefKatilimci } from 'app/shared/model/ref-katilimci.model';

interface RefKatilimciDetailProp {
  refKatilimci: IRefKatilimci;
}

export const RefKatilimciDetail: React.FC<RefKatilimciDetailProp> = ({ refKatilimci }) => {
  return (
    <>
      <Row className="modal-body-row">
        <Col lg="6" style={{ marginTop: '10px' }}>
          <Row className="modal-content-title">
            <Translate contentKey="tk24AdysApp.refKatilimci.sicil">sicil</Translate>
          </Row>
          <Row className="modal-content-text">{refKatilimci.sicil ? refKatilimci.sicil : '-'}</Row>
        </Col>
        <Col lg="6" style={{ marginTop: '10px' }}>
          <Row className="modal-content-title">
            <Translate contentKey="tk24AdysApp.refKatilimci.unvanAdi">unvanAdi</Translate>
          </Row>
          <Row className="modal-content-text">{refKatilimci.unvanAdi ? refKatilimci.unvanAdi : '-'}</Row>
        </Col>
      </Row>
      <Row className="modal-body-row">
        <Col lg="6" style={{ marginTop: '10px' }}>
          <Row className="modal-content-title">
            <Translate contentKey="tk24AdysApp.refKatilimci.adi">adi</Translate>
          </Row>
          <Row className="modal-content-text">{refKatilimci.adi ? refKatilimci.adi : '-'}</Row>
        </Col>
        <Col lg="6" style={{ marginTop: '10px' }}>
          <Row className="modal-content-title">
            <Translate contentKey="tk24AdysApp.refKatilimci.soyadi">soyadi</Translate>
          </Row>
          <Row className="modal-content-text">{refKatilimci.soyadi ? refKatilimci.soyadi : '-'}</Row>
        </Col>
      </Row>
      <Row className="modal-body-row">
        <Col lg="6" style={{ marginTop: '10px' }}>
          <Row className="modal-content-title">
            <Translate contentKey="tk24AdysApp.refKatilimci.orgAdiTr">orgAdiTr</Translate>
          </Row>
          <Row className="modal-content-text">{refKatilimci.org?.adiTr}</Row>
        </Col>
        <Col lg="6" style={{ marginTop: '10px' }}>
          <Row className="modal-content-title">
            <Translate contentKey="tk24AdysApp.refKatilimci.orgAdiEn">orgAdiEn</Translate>
          </Row>
          <Row className="modal-content-text">{refKatilimci.org?.adiEn}</Row>
        </Col>
      </Row>
      <Row className="modal-body-row">
        <Col lg="6" style={{ marginTop: '10px' }}>
          <Row className="modal-content-title">
            <Translate contentKey="tk24AdysApp.refKatilimci.bilgiSonGuncellenmeZamani">bilgiSonGuncellenmeZamani</Translate>
          </Row>
          <Row className="modal-content-text">
            {refKatilimci.bilgiSonGuncellenmeZamani ? (
              <TextFormat value={refKatilimci.bilgiSonGuncellenmeZamani} type="date" format={APP_DATE_FORMAT} />
            ) : (
              '-'
            )}
          </Row>
        </Col>
        <Col lg="6" style={{ marginTop: '10px' }}>
          <Row className="modal-content-title">
            <Translate contentKey="tk24AdysApp.refKatilimci.katilimciRolu.adi">katilimciRoluAdi</Translate>
          </Row>
          <Row className="modal-content-text">{refKatilimci.katilimciRolu ? refKatilimci.katilimciRolu?.adi : '-'}</Row>
        </Col>
      </Row>
      <Row className="modal-body-row">
        <Col lg="6" style={{ marginTop: '10px' }}>
          <Row className="modal-content-title">
            <Translate contentKey="tk24AdysApp.refKatilimci.sirket">sirket</Translate>
          </Row>
          <Row className="modal-content-text">{refKatilimci.org?.sirket?.adi}</Row>
        </Col>
      </Row>
    </>
  );
};

export default RefKatilimciDetail;
