import { IReferans } from 'app/shared/model/referans.model';
import { IRefKatilimci } from './ref-katilimci.model';
import { IRefDDMSDokuman } from './ref-ddms-dokuman.model';
import { IQueryParams } from '../reducers/reducer.utils';

export interface IRefKatilimciDdmsDokuman {
  id?: number;
  guid?: string;
  cDate?: string;
  cUser?: number;
  mDate?: string;
  mUser?: number;
  durum?: IReferans;
  katilimci?: IRefKatilimci;
  ddmsDokuman?: IRefDDMSDokuman;
}

export interface IRefKatilimciDdmsDokumanParams extends IQueryParams {
  entity?: IRefKatilimciDdmsDokuman;
}

export const defaultValue: Readonly<IRefKatilimciDdmsDokuman> = {};
