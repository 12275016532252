import { useAppDispatch, useAppSelector } from 'app/config/store';
import React, { useEffect } from 'react';
import { Translate } from 'app/component/jhipster';
import { Col, Container, Row } from 'reactstrap';
import { IRefMurettebat } from 'app/shared/model/ref-murettebat.model';
import { getEntity as getRefMurettebatEntity } from 'app/entities/etkilenen-kisi/reducer/ref-murettebat.reducer';

interface EtkilenenKisiMurettebatDetailTabProps {
  refMurettebat: IRefMurettebat;
  tab: number;
  loading?: boolean;
}

const EtkilenenKisiMurettebatDetailTab: React.FC<EtkilenenKisiMurettebatDetailTabProps> = ({ refMurettebat, tab, loading }) => {
  const dispatch = useAppDispatch();
  const refMurettebatDetail = useAppSelector(state => state.refMurettebat.entity);

  useEffect(() => {
    if (tab === 2) {
      dispatch(getRefMurettebatEntity(refMurettebat?.id));
    }
  }, [tab]);

  return (
    <>
      {!loading ? (
        <Container>
          <Row>
            <Col md={6}>
              <Row>
                <Col md={4}>
                  <p>
                    <strong>
                      <Translate contentKey="tk24AdysApp.refMurettebat.milliyet" />:
                    </strong>
                  </p>
                </Col>
                <Col md={8}>
                  <p> {refMurettebatDetail.milliyeti} </p>
                </Col>
              </Row>

              <Row>
                <Col md={4}>
                  <p>
                    <strong>
                      <Translate contentKey="tk24AdysApp.refMurettebat.ucusSinyorite" />:
                    </strong>
                  </p>
                </Col>
                <Col md={8}>
                  <p> {refMurettebatDetail.ucusSinyorite} </p>
                </Col>
              </Row>

              <Row>
                <Col md={4}>
                  <p>
                    <strong>
                      <Translate contentKey="tk24AdysApp.refMurettebat.sinyorite" />:
                    </strong>
                  </p>
                </Col>
                <Col md={8}>
                  <p> {refMurettebatDetail.sinyorite} </p>
                </Col>
              </Row>

              <Row>
                <Col md={4}>
                  <p>
                    <strong>
                      <Translate contentKey="tk24AdysApp.refMurettebat.murettebatTipi" />:
                    </strong>
                  </p>
                </Col>
                <Col md={8}>
                  <p> {refMurettebatDetail.murettebatTipi} </p>
                </Col>
              </Row>

              <Row>
                <Col md={4}>
                  <p>
                    <strong>
                      <Translate contentKey="tk24AdysApp.refMurettebat.bazGorev" />:
                    </strong>
                  </p>
                </Col>
                <Col md={8}>
                  <p> {refMurettebatDetail.bazGorev} </p>
                </Col>
              </Row>

              <Row>
                <Col md={4}>
                  <p>
                    <strong>
                      <Translate contentKey="tk24AdysApp.refMurettebat.sinif" />:
                    </strong>
                  </p>
                </Col>
                <Col md={8}>
                  <p> {refMurettebatDetail.sinif} </p>
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <p>
                    <strong>
                      <Translate contentKey="tk24AdysApp.refMurettebat.ucakTipiSertifikalari" />:
                    </strong>
                  </p>
                </Col>
                <Col md={8}>
                  <p> {refMurettebatDetail.ucakTipiSertifikalari} </p>
                </Col>
              </Row>
            </Col>
            <Col md={6}>
              {' '}
              <Row>
                <Col md={4}>
                  <p>
                    <strong>
                      <Translate contentKey="tk24AdysApp.refMurettebat.checkinDurumu" />:
                    </strong>
                  </p>
                </Col>
                <Col md={8}>
                  <p> {refMurettebatDetail.checkinDurumu?.adi} </p>
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <p>
                    <strong>
                      <Translate contentKey="tk24AdysApp.refMurettebat.passDurumu" />:
                    </strong>
                  </p>
                </Col>
                <Col md={8}>
                  <p> {refMurettebatDetail.passDurumu?.adi} </p>
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <p>
                    <strong>
                      <Translate contentKey="tk24AdysApp.refMurettebat.lifus" />:
                    </strong>
                  </p>
                </Col>
                <Col md={8}>
                  <p> {refMurettebatDetail.lifus?.adi} </p>
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <p>
                    <strong>
                      <Translate contentKey="tk24AdysApp.refMurettebat.rnav" />:
                    </strong>
                  </p>
                </Col>
                <Col md={8}>
                  <p> {refMurettebatDetail.rnav?.adi} </p>
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <p>
                    <strong>
                      <Translate contentKey="tk24AdysApp.refMurettebat.etops" />:
                    </strong>
                  </p>
                </Col>
                <Col md={8}>
                  <p> {refMurettebatDetail.etops?.adi} </p>
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <p>
                    <strong>
                      <Translate contentKey="tk24AdysApp.refMurettebat.ccategory" />:
                    </strong>
                  </p>
                </Col>
                <Col md={8}>
                  <p> {refMurettebatDetail.ccategory?.adi} </p>
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <p>
                    <strong>
                      <Translate contentKey="tk24AdysApp.refMurettebat.specialAirport" />:
                    </strong>
                  </p>
                </Col>
                <Col md={8}>
                  <p> {refMurettebatDetail.specialAirport?.adi} </p>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      ) : (
        <></>
      )}
    </>
  );
};

export default EtkilenenKisiMurettebatDetailTab;
