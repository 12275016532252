import { isNullOrEmpty } from 'app/shared/util/confirm-utils';
import React from 'react';

export const highlightMention = (text, krizKatilimciEntities, sicilNo) => {
  if (!text) {
    return '';
  } else {
    const mentionRegex = /\${(\d+?)}/g;
    return text.split(mentionRegex).map((part, index) => {
      if (index % 2 === 1) {
        const number = part.match(/\d+/);
        const color = number[0].toString() === sicilNo ? '#FF0000' : '#0078D4 ';
        const mentionKatilimci = krizKatilimciEntities && krizKatilimciEntities.find(item => item.sicil.toString() === number.toString());
        return (
          <span style={{ color, fontWeight: '500' }}>
            {mentionKatilimci
              ? `${mentionKatilimci.adi} 
              ${isNullOrEmpty(mentionKatilimci.soyadi) ? '' : mentionKatilimci.soyadi}`
              : '-'}
          </span>
        );
      } else {
        return <React.Fragment>{part}</React.Fragment>;
      }
    });
  }
};
