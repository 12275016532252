import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import Mesaj from './mesaj';
import MesajDetail from './mesaj-detail';
import MesajUpdate from './mesaj-update';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={MesajUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={MesajUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={MesajDetail} />
      <ErrorBoundaryRoute path={match.url} component={Mesaj} />
    </Switch>
  </>
);

export default Routes;
