import React, { useCallback, useEffect, useRef, useState } from 'react';
import { TextFormat, Translate, translate } from 'app/component/jhipster';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import classnames from 'classnames';
import KrizFormlarHazirForm from 'app/entities/kriz-medya/kriz-formlar-hazir-form';
import KrizFormlarDijitalForm from 'app/entities/kriz-medya/kriz-formlar-dijital-form';
import { RouteComponentProps } from 'react-router-dom';
import EtkilenenKisiDoldurulanFormlar from 'app/entities/etkilenen-kisi/etkilenen-kisi-doldurulan-formlar';
import KrizHazirFormDoldurulan from 'app/entities/kriz-medya/kriz-hazir-form-doldurulan';
import GrantedNavTab from 'app/component/GrantedNavTab';

interface FormComponentProp extends RouteComponentProps<{ id: string }> {
  activeTab?: string | number;
}

const KrizMedyaFormlar = (props: FormComponentProp) => {
  const [activeTab, setActiveTab] = useState(1);
  const kriz = useAppSelector(state => state.kriz.entity);

  const grantedTabConfig = {
    headerClassName: 'tabAdtGorev',
    tabs: [
      {
        authority: 'kriz_dokuman_hazir_form_read',
        label: translate('tk24AdysApp.krizMedya.hazirFormlar'),
        component: <KrizFormlarHazirForm activeTab={activeTab} />,
      },
      {
        authority: 'kriz_dokuman_doldurulan_hazir_form_read',
        label: translate('tk24AdysApp.krizMedya.hazirFormlarDoldurulan'),
        component: <KrizHazirFormDoldurulan />,
      },
      {
        authority: 'kriz_dokuman_dijital_form_read',
        label: translate('tk24AdysApp.krizMedya.dijitalFormlar'),
        component: <KrizFormlarDijitalForm activeTab={activeTab} />,
      },
      {
        authority: 'kriz_dokuman_doldurulan_dijital_form_read',
        label: translate('tk24AdysApp.krizMedya.dijitalFormlarDoldurulan'),
        component: <EtkilenenKisiDoldurulanFormlar kisi={null} kisiAltTipi={null} krizId={kriz.guid} onlyKriz={true} />,
      },
    ],
  };

  return (
    <div>
      <GrantedNavTab {...grantedTabConfig} />
    </div>
  );
};

export default KrizMedyaFormlar;
