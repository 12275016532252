import axios from 'axios';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AppThunk } from 'app/config/store';
import { modes } from 'app/config/constants';

import { serializeAxiosError } from 'app/shared/reducers/reducer.utils';

const initialState = {
  loading: false,
  errorMessage: null,
  fetchedLayers: [],
  activeLayerInLayerTree: null,
  showClickedLayer: null,
  layerBBOX: null,
  highLightObject: null,
  zoomLevel: null,
  layers: [],
  addresses: [],
  showLayerInfo: false,
  feature: null,
  mode: modes.VIEW,
  showSaveButton: false,
  jwtToken: null,
};

const apiUrl = 'api';

export type ViewerState = Readonly<typeof initialState>;

// Actions

export const retrieveLayers = createAsyncThunk('katman/retrieveLayers', async () => {
  const requestUrl = `${apiUrl}/retrieveLayers?cacheBuster=${new Date().getTime()}`;
  return axios.get<any>(requestUrl);
});

export const retrieveAddresses = createAsyncThunk('retrieveAddresses', async (q:string) => {
  const requestUrl = `${apiUrl}/query?q=${q}`;
  return axios.post<any>(requestUrl,null);
});


export const getLayerBBOX = createAsyncThunk('katman/getLayerBBOX', async (id: string | number) => {
  const requestUrl = `${apiUrl}/getBBOXofLayer/${id}`;
  return axios.get<any>(requestUrl);
});



export const saveLayer = createAsyncThunk('katman/save_layers', async (layerList: any) => {
  const requestUrl = `api/katman/saveLayer4User`;
  return axios.post<any>(requestUrl, layerList);
});

export const updateLayers: (layers) => AppThunk = layers => dispatch => {
  dispatch(updateLayersState(layers));
};

export const updateMode: (mode) => AppThunk = mode => dispatch => {
  dispatch(updateModeState(mode));
};

// slice

export const ViewerSlice = createSlice({
  name: 'viewer',
  initialState: initialState as ViewerState,
  reducers: {
    updateLayersState(state, action) {
      return {
        ...state,
        layers: action.payload.layers,
        showSaveButton: action.payload.show,
      };
    },

    updateModeState(state, action) {
      return {
        ...state,
        mode: action.payload,
      };
    },
  },
  extraReducers(builder) {
    builder

      .addCase(retrieveLayers.pending, state => {
        state.loading = true;
        state.errorMessage = null;
      })
      .addCase(getLayerBBOX.pending, state => {
        state.loading = true;
        state.errorMessage = null;
      })
      .addCase(saveLayer.pending, state => {
        state.loading = true;
        state.errorMessage = null;
      })
      .addCase(retrieveAddresses.pending, state => {
        state.loading = true;
        state.errorMessage = null;
      })
      .addCase(retrieveLayers.rejected, (state, action) => ({
        ...state,
        loading: false,
        errorMessage: action.error.message,
      }))
      .addCase(getLayerBBOX.rejected, (state, action) => ({
        ...state,
        loading: false,
        errorMessage: action.error.message,
      }))
      .addCase(saveLayer.rejected, (state, action) => ({
        ...state,
        loading: false,
        errorMessage: action.error.message,
      }))
      .addCase(retrieveAddresses.rejected, (state, action) => ({
        ...state,
        loading: false,
        errorMessage: action.error.message,
      }))
      .addCase(retrieveLayers.fulfilled, (state, action) => {
        return {
          ...state,
          loading: false,
          fetchedLayers: action.payload.data,
        };
      })

      .addCase(getLayerBBOX.fulfilled, (state, action) => {
        return {
          ...state,
          loading: false,
          layerBBOX: action.payload.data,
        };
      })
      .addCase(saveLayer.fulfilled, (state, action) => {
        return {
          ...state,
          loading: false,
        };
      })
      .addCase(retrieveAddresses.fulfilled, (state, action) => {
        return {
          ...state,
          addresses: action.payload.data,
          loading: false,
        };
      });
  },
});

export const {
  updateLayersState,
  updateModeState,
} = ViewerSlice.actions;

// Reducer
export default ViewerSlice.reducer;
