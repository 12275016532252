import axios from 'axios';
import { Storage } from 'app/component/jhipster';

import { SERVER_API_URL,CSRF_TOKEN_REGX } from 'app/config/constants';

const TIMEOUT = 1 * 60 * 1000;
axios.defaults.timeout = TIMEOUT;
axios.defaults.baseURL = SERVER_API_URL;

const setupAxiosInterceptors = onUnauthenticated => {
  const onRequestSuccess = config => {
    const token = Storage.local.get('jhi-authenticationToken') || Storage.session.get('jhi-authenticationToken');
    const selectedKriz = Storage.session.get('kriz');
    const reg = new RegExp(CSRF_TOKEN_REGX);
    const csrfToken = document.cookie.replace(reg.source, '$1');
    config.headers = { 'X-XSRF-TOKEN': csrfToken, 'Accept-Language': Storage.local.get('locale') || Storage.session.get('locale', 'tr') };
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    if (selectedKriz) {
      config.headers.kriz = `${selectedKriz}`;
    }
    config.headers.locale = Storage.session.get('locale', 'tr');

    return config;
  };
  const onResponseSuccess = response => response;
  const onResponseError = err => {
    const status = err.status || (err.response ? err.response.status : 0);
    if (status === 401) {
      onUnauthenticated();
    }
    return Promise.reject(err);
  };
  axios.interceptors.request.use(onRequestSuccess);
  axios.interceptors.response.use(onResponseSuccess, onResponseError);
};

export default setupAxiosInterceptors;
