import React, { useState } from 'react';
import { Button, UncontrolledTooltip, Form, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Translate, translate } from 'app/component/jhipster';

import { updateEntity, createEntity } from './sistem-parametre.reducer';
import { ISistemParametre } from 'app/shared/model/sistem-parametre.model';
import { useAppDispatch } from 'app/config/store';
import { CustomSaveEntityConfirm, getHeaderContentKey } from 'app/shared/util/confirm-utils';
import { FormItem } from 'app/component/FormItem';
import { IQueryParams } from 'app/shared/reducers/reducer.utils';

interface SistemParametreUpdateProp {
  handleCloseForm: () => void;
  formValues?: ISistemParametre;
  isOpen: boolean;
  pageObject?: IQueryParams;
  isViewing: boolean;
}

export const SistemParametreUpdate: React.FC<SistemParametreUpdateProp> = ({
  handleCloseForm,
  formValues,
  isOpen,
  pageObject,
  isViewing,
}) => {
  const dispatch = useAppDispatch();
  const [customIsOpen, setCustomIsOpen] = useState(isOpen);
  const [modalPromiseResolve, setModalPromiseResolve] = useState(null);
  const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);

  const showSaveConfirmModal = () => {
    setIsSaveModalOpen(true);
    return new Promise(resolve => {
      setModalPromiseResolve(() => resolve);
    });
  };

  const handleConfirm = () => {
    if (modalPromiseResolve) {
      modalPromiseResolve(true);
    }
    setIsSaveModalOpen(false);
  };

  const handleCancel = () => {
    if (modalPromiseResolve) {
      modalPromiseResolve(false);
    }
    setIsSaveModalOpen(false);
  };

  const saveEntity = async e => {
    e.preventDefault();
    setCustomIsOpen(false);
    const isSave = await showSaveConfirmModal();
    if (isSave) {
      const formDataObject = {};
      for (const field of e.target.elements) {
        if (field.name) {
          formDataObject[field.name] = field.value;
        }
      }

      const sistemParametreEntity = {
        ...formValues,
        ...formDataObject,
      };

      if (formValues !== null) {
        delete sistemParametreEntity.sonGuncelleyenKullanici;
        await dispatch(updateEntity({ ...pageObject, entity: sistemParametreEntity }));
      } else {
        await dispatch(createEntity({ ...pageObject, entity: sistemParametreEntity }));
      }

      handleCloseForm();
    } else {
      setCustomIsOpen(true);
    }
  };

  return (
    <>
      <CustomSaveEntityConfirm entity={formValues} isOpen={isSaveModalOpen} onConfirm={handleConfirm} onCancel={handleCancel} />

      <Modal className="modal-style-lg" isOpen={customIsOpen} toggle={handleCloseForm} centered>
        <ModalHeader data-cy="SistemParametreCreateUpdateHeading" toggle={handleCloseForm}>
          {formValues && (
            <p className="modal-title-info">
              {formValues?.adiTr} - {formValues?.adiEn}
            </p>
          )}
          <Translate contentKey={getHeaderContentKey(isViewing, formValues, 'sistemParametre')} />
        </ModalHeader>

        <Form onSubmit={saveEntity}>
          <ModalBody style={{ maxHeight: '500px', overflow: 'auto' }} className="sistem-parametre-update">
            <FormItem
              label={translate('global.languageGlobal.adiTr')}
              id="ref-adt-form-bolum-adiTr"
              name="adiTr"
              defaultValue={formValues && formValues['adiTr']}
              disabled={formValues || isViewing ? true : false}
              type={formValues && formValues['adiTr'] && formValues['adiTr'].length > 100 ? 'textarea' : 'text'}
              validation={{
                required: { value: true, message: translate('entity.validation.required') },
                maxLength: { value: 100, message: translate('entity.validation.maxlength', { max: 100 }) },
              }}
            />
            <UncontrolledTooltip target="adiTrLabel">
              <Translate contentKey="global.languageGlobal.adiTr" />
            </UncontrolledTooltip>

            <FormItem
              label={translate('global.languageGlobal.adiEn')}
              id="ref-adt-form-bolum-adiEn"
              name="adiEn"
              defaultValue={formValues && formValues['adiEn']}
              type={formValues && formValues['adiEn'] && formValues['adiEn'].length > 100 ? 'textarea' : 'text'}
              disabled={formValues || isViewing ? true : false}
              validation={{
                required: { value: true, message: translate('entity.validation.required') },
                maxLength: { value: 100, message: translate('entity.validation.maxlength', { max: 100 }) },
              }}
            />
            <UncontrolledTooltip target="adiEnLabel">
              <Translate contentKey="global.languageGlobal.adiEn" />
            </UncontrolledTooltip>

            <FormItem
              label={translate('tk24AdysApp.sistemParametre.aciklama')}
              id="aciklama"
              name="aciklama"
              defaultValue={formValues && formValues['aciklama']}
              disabled={isViewing}
              type="textarea"
              validation={{
                required: { value: true, message: translate('entity.validation.required') },
                maxLength: { value: 3000, message: translate('entity.validation.maxlength', { max: 3000 }) },
              }}
            />
            <FormItem
              label={translate('tk24AdysApp.sistemParametre.deger')}
              id="sistem-parametre-deger"
              name="deger"
              defaultValue={formValues && formValues['deger']}
              disabled={isViewing}
              type={formValues && formValues['deger'] && formValues['deger'].length > 100 ? 'textarea' : 'text'}
              validation={{
                required: { value: true, message: translate('entity.validation.required') },
              }}
            />
            <FormItem
              label={translate('global.languageGlobal.note')}
              id="note"
              name="note"
              defaultValue={formValues && formValues['note']}
              type="textarea"
              disabled={isViewing}
              validation={{
                maxLength: { value: 3000, message: translate('entity.validation.maxlength', { max: 3000 }) },
              }}
            />
            {isViewing && (
              <FormItem
                label={translate('global.languageGlobal.mDate')}
                id="mDate"
                name="mDate"
                defaultValue={formValues && formValues['mDate']}
                type="datetime"
                disabled={isViewing}
              />
            )}
          </ModalBody>
          {!isViewing && (
            <ModalFooter className="modal-footer">
              <Button className="cancel-button" onClick={handleCloseForm}>
                <Translate contentKey="entity.action.cancel">Cancel</Translate>
              </Button>
              &nbsp;
              <Button className="save-button" id="jhi-confirm-delete-refAdtForm" type="submit" data-cy="entityConfirmButton">
                <Translate contentKey="entity.action.save">Kaydet</Translate>
              </Button>
            </ModalFooter>
          )}
        </Form>
      </Modal>
    </>
  );
};

export default SistemParametreUpdate;
