import axios from 'axios';
import { IRefHavalimani } from '../model/ref-havalimani.model';
import { IRefAdtEkip } from '../model/ref-adt-ekip.model';
import { toast } from 'react-toastify';

export const validationKrizAdtEkip = async krizId => {
  try {
    const katilimciAdtEkip: IRefAdtEkip[] = (await axios.get(`/api/kriz-katilimci-adt-ekips?krizGuid=${krizId}`)).data;
    if (katilimciAdtEkip && katilimciAdtEkip.length > 0) {
      const krizAdtEkipResponse = await axios.get(
        `/api/kriz-adt-ekips?krizId.equals=${krizId}&adtEkipId.in=${katilimciAdtEkip.map(item => item.id)}`
      );
      return krizAdtEkipResponse.data;
    } else {
      return null;
    }
  } catch (error) {
    return null;
  }
};

export const validationKrizIstasyonEkip = async krizId => {
  try {
    const katilimciIstasyon: IRefHavalimani[] = (await axios.get(`/api/kriz-katilimci-istasyons?krizGuid=${krizId}`)).data;
    if (katilimciIstasyon && katilimciIstasyon.length > 0) {
      const istasyonResponse = await axios.get(
        `/api/kriz-istasyons?krizId.equals=${krizId}&havalimaniId.in=${katilimciIstasyon.map(item => item.id)}`
      );
      return istasyonResponse.data;
    } else {
      return null;
    }
  } catch (error) {
    return null;
  }
};

export const NearestList = async (konumX, konumY) => {
  const yakinHavalimaniUrl = `api/ref-havalimanis/nearest-list`;
  const nearList = await axios
    .post(yakinHavalimaniUrl, { lat: konumX, lon: konumY })
    .then(function (response) {
      const havalimanis = response.data;
      if (havalimanis.length > 0) {
        const lats = [];
        const lngs = [];
        lngs.push(konumX);
        lats.push(konumY);
        for (let i = 0; i < havalimanis.length; i++) {
          lngs.push(havalimanis[i].konumX);
          lats.push(havalimanis[i].konumY);
        }

        const minlat = Math.min.apply(null, lats),
          maxlat = Math.max.apply(null, lats);
        const minlng = Math.min.apply(null, lngs),
          maxlng = Math.max.apply(null, lngs);

        // create a bounding rectangle that can be used in leaflet
        const bbox = [
          [minlat, minlng],
          [maxlat, maxlng],
        ];

        return [havalimanis, bbox];
      }
    })
    .catch(function (error) {
      toast.error(error);
      return [[], []];
    });
  return nearList;
};
