import { IReferans } from 'app/shared/model/referans.model';
import { IKisi } from './kisi.model';

export interface IEtkilenenKisiHatirlatma {
  id?: number;
  guid?: string;
  cDate?: string;
  cUser?: number;
  mDate?: string;
  mUser?: number;
  durum?: IReferans;
  kisi?: IKisi;
  note?: string;
  hatirlatmaZamani?: string;
  hatirlatmaDurumu?: IReferans;
  aciklama?: string;
}

export const defaultValue: Readonly<IEtkilenenKisiHatirlatma> = {};
