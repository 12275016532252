import React, { useEffect } from 'react';
import { Switch, useLocation } from 'react-router-dom';
import './style.scss';
import './response.scss';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import PrivateRoute from 'app/shared/auth/private-route';

import AdymDuyuru from './adym-duyuru';
import AdtDuyuru from './adt-duyuru';
import EkDosya from './ek-dosya';
import KrizDetay from './kriz-detay';
import GecmisKriz from './gecmis-kriz';
import Kriz from './kriz';
import KrizCeride from './kriz-ceride';
import KrizKatilimci from './kriz-katilimci';
import KrizKatilimciBildirim from './kriz-katilimci-bildirim';
import KrizKatilimciGorev from './kriz-katilimci-gorev';
import KrizUcak from './kriz-ucak';
import KrizUcus from './kriz-ucus';
import Kullanici from './kullanici';
import KullaniciRol from './kullanici-rol';
import Mesaj from './mesaj';
import RefDuyuruTipi from './ref-duyuru-tipi';
import RefEkDosyaTipi from './ref-ek-dosya-tipi';
import RefGorev from './ref-gorev';
import RefGorevSorBirim from './ref-gorev-sor-birim';
import RefHavalimani from './ref-havalimani';
import RefHavalimaniIrtibat from './ref-havalimani-irtibat';
import RefKatilimci from './ref-katilimci';
import RefKatilimciVekil from './ref-katilimci-vekil';
import RefKrizSeviye from './ref-kriz-seviye';
import RefKrizTuru from './ref-kriz-turu';
import RefKrizTuruKatilimci from './ref-kriz-turu-katilimci';
import RefKrizTuruKatilimciGorev from './ref-kriz-turu-katilimci-gorev';
import RefUcak from './ref-ucak';
import Referans from './referans';
import Rol from './rol';
import RolYetki from './rol-yetki';
import SistemParametre from './sistem-parametre';
import UygulamaAyarlari from './uygulama-ayarlari';
import UcusKisiListesi from './ucus-kisi-listesi';
import Yetki from './yetki';
import RefAdtEkip from './ref-adt-ekip';
import RefAdtForm from './ref-adt-form';
import RefSaglikDurumu from './ref-saglik-durumu';
import RefYakinlik from './ref-yakinlik';
import RefKisiAltTipi from './ref-kisi-alt-tipi';
import EtkilenenKisi from './etkilenen-kisi';
import AdymGorevler from './kriz-adym-gorevler';
import AdymGorev from './kriz-adym-gorev';
import AdtGorevler from './kriz-adt-gorevler';
import AdtGorev from './kriz-adt-gorev';
import Irtibat from './irtibat';
import Vekalet from './vekalet';
import AdymKatilimci from './adym-katilimci';
import KrizHavalimani from './kriz-havalimani';
import KrizRapor from './kriz-rapor';
import KrizMedya from './kriz-medya';
import KrizTalimat from './kriz-talimat';
import KrizAcilDurumOzetRapor from './kriz-ozet-rapor';
import RefHavalimaniIrtibatTipi from './ref-havalimani-irtibat-tipi/ref-havalimani-irtibat-tipi';
import RefAdtFormBolum from './ref-adt-form-bolum';
import RefAdtFormBolumSoru from './ref-adt-form-bolum-soru';
import RefDdmsDokuman from './ref-ddms-dokuman';
import RefDdmsDokumanKategori from './ref-ddms-dokuman-kategori';
import BildirimSablonu from './bildirim-sablonu';
import BildirimYonetimi from './bildirim-yonetimi';
import RefDisDokuman from './ref-dis-dokuman';
import bildirimSablonuKisiTipi from './bildirim-sablon-kisi-tipi';
import KrizIvrBildirim from './kriz-ivr-bildirimleri/kriz-ivr-bildirim';
import KrizDoldurulanFormlar from './kriz-doldurulan-formlar/kriz-doldurulan-formlar';
import KrizAdtEkip from './kriz-adt-ekip';
import TalimatTakip from './talimat-takip';
import KrizIstasyonGorevCagir from './kriz-istasyon-ekip';
import KrizIstasyonGorev from './kriz-istasyon-gorev';
import RefIstasyonGorevGrubu from './ref-istasyon-gorev-grubu';
import RefIslemKodu from './ref-islem-kodu';
import RefSablonParametre from './ref-sablon-parametre';
import KrizEdit from './kriz-edit';
import KrizDuyurular from './kriz-duyurular';
import CrisisPrivateRouteComponent from 'app/shared/auth/crisis-private-route';
import KrizAkisListe from './kriz-akis-liste';

/* jhipster-needle-add-route-import - JHipster will add routes here */

const Routes = ({ match }) => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <div>
      <Switch>
        {/* prettier-ignore */}
        <CrisisPrivateRouteComponent path={`${match.url}kriz-duyurular`} component={KrizDuyurular} grantedAuthorities={['view_duyurular']} />
        <CrisisPrivateRouteComponent path={`${match.url}kym-duyuru`} component={AdymDuyuru} grantedAuthorities={['view_duyurular']} />
        <CrisisPrivateRouteComponent path={`${match.url}adt-duyuru`} component={AdtDuyuru} grantedAuthorities={['view_adt_duyurular']} />
        <CrisisPrivateRouteComponent
          path={`${match.url}kriz-akis-liste`}
          component={KrizAkisListe}
          grantedAuthorities={['view_kriz_akis_listesi']}
        />
        <PrivateRoute path={`${match.url}kriz`} component={Kriz} grantedAuthorities={['ref_duyuru_tipi_read']} />
        <PrivateRoute path={`${match.url}kriz-detay`} component={KrizDetay} grantedAuthorities={['ref_duyuru_tipi_read']} />
        <PrivateRoute path={`${match.url}gecmis-kriz`} component={GecmisKriz} grantedAuthorities={['gecmis_kriz_read']} />
        <PrivateRoute path={`${match.url}kriz-katilimci`} component={KrizKatilimci} grantedAuthorities={['ref_duyuru_tipi_read']} />
        <CrisisPrivateRouteComponent
          path={`${match.url}kriz-katilimci-bildirim`}
          component={KrizKatilimciBildirim}
          grantedAuthorities={['view_crisis']}
        />
        <PrivateRoute
          path={`${match.url}kriz-katilimci-gorev`}
          component={KrizKatilimciGorev}
          grantedAuthorities={['ref_duyuru_tipi_read']}
        />
        <CrisisPrivateRouteComponent path={`${match.url}kriz-ucak`} component={KrizUcak} grantedAuthorities={['view_ucak_bilgileri']} />
        <CrisisPrivateRouteComponent path={`${match.url}kriz-ucus`} component={KrizUcus} grantedAuthorities={['view_sefer_bilgileri']} />
        <PrivateRoute path={`${match.url}kullanici`} component={Kullanici} grantedAuthorities={['kullanici_read']} />
        <PrivateRoute path={`${match.url}kullanici-rol`} component={KullaniciRol} grantedAuthorities={['ref_duyuru_tipi_read']} />
        <PrivateRoute path={`${match.url}mesaj`} component={Mesaj} grantedAuthorities={['ref_duyuru_tipi_read']} />
        <PrivateRoute path={`${match.url}ref-duyuru-tipi`} component={RefDuyuruTipi} grantedAuthorities={['ref_duyuru_tipi_read']} />

        <PrivateRoute path={`${match.url}ref-ek-dosya-tipi`} component={RefEkDosyaTipi} grantedAuthorities={['ref_ek_dosya_tipi_read']} />
        <PrivateRoute path={`${match.url}ref-gorev`} component={RefGorev} grantedAuthorities={['ref_gorev_read']} />
        <PrivateRoute path={`${match.url}ref-gorev-sor-birim`} component={RefGorevSorBirim} grantedAuthorities={['ref_duyuru_tipi_read']} />
        <PrivateRoute path={`${match.url}ref-havalimani`} component={RefHavalimani} grantedAuthorities={['ref_havalimani_read']} />
        <PrivateRoute
          path={`${match.url}ref-havalimani-irtibat`}
          component={RefHavalimaniIrtibat}
          grantedAuthorities={['ref_duyuru_tipi_read']}
        />
        <PrivateRoute
          path={`${match.url}ref-havalimani-itribat-tipi`}
          component={RefHavalimaniIrtibatTipi}
          grantedAuthorities={['ref_havalimani_irtibat_tipi_read']}
        />
        <PrivateRoute path={`${match.url}ref-katilimci`} component={RefKatilimci} grantedAuthorities={['ref_katilimci_read']} />
        <PrivateRoute
          path={`${match.url}ref-katilimci-vekil`}
          component={RefKatilimciVekil}
          grantedAuthorities={['ref_katilimci_vekil_read']}
        />
        <PrivateRoute path={`${match.url}ref-kriz-seviye`} component={RefKrizSeviye} grantedAuthorities={['ref_kriz_seviye_read']} />
        <PrivateRoute path={`${match.url}ref-kriz-turu`} component={RefKrizTuru} grantedAuthorities={['ref_kriz_turu_read']} />
        <PrivateRoute
          path={`${match.url}ref-kriz-turu-katilimci`}
          component={RefKrizTuruKatilimci}
          grantedAuthorities={['ref_kriz_turu_katilimci_read']}
        />
        <PrivateRoute
          path={`${match.url}ref-kriz-turu-katilimci-gorev`}
          component={RefKrizTuruKatilimciGorev}
          grantedAuthorities={['ref_kriz_turu_katilimci_gorev_read']}
        />
        <PrivateRoute path={`${match.url}ref-ucak`} component={RefUcak} grantedAuthorities={['ref_ucak_read']} />
        <PrivateRoute path={`${match.url}referans`} component={Referans} grantedAuthorities={['referans_read']} />
        <PrivateRoute path={`${match.url}rol`} component={Rol} grantedAuthorities={['rol_read']} />
        <PrivateRoute path={`${match.url}rol-yetki`} component={RolYetki} grantedAuthorities={['ref_duyuru_tipi_read']} />
        <PrivateRoute path={`${match.url}sistem-parametre`} component={SistemParametre} grantedAuthorities={['sistem_parametre_read']} />
        <PrivateRoute path={`${match.url}uygulama-ayarlari`} component={UygulamaAyarlari} grantedAuthorities={['uygulama_ayarlari_read']} />
        <PrivateRoute path={`${match.url}ucus-kisi-listesi`} component={UcusKisiListesi} grantedAuthorities={['ref_duyuru_tipi_read']} />
        <PrivateRoute path={`${match.url}yetki`} component={Yetki} grantedAuthorities={['yetki_read']} />
        <PrivateRoute path={`${match.url}ref-adt-form-bolum`} component={RefAdtFormBolum} grantedAuthorities={['ref_duyuru_tipi_read']} />
        <PrivateRoute
          path={`${match.url}ref-adt-form-bolum-soru`}
          component={RefAdtFormBolumSoru}
          grantedAuthorities={['ref_duyuru_tipi_read']}
        />
        <PrivateRoute path={`${match.url}ref-adt-ekip`} component={RefAdtEkip} grantedAuthorities={['ref_adt_ekip_read']} />
        <PrivateRoute path={`${match.url}ref-adt-form`} component={RefAdtForm} grantedAuthorities={['ref_adt_form_read']} />
        <PrivateRoute path={`${match.url}ref-saglik-durumu`} component={RefSaglikDurumu} grantedAuthorities={['ref_saglik_durumu_read']} />
        <PrivateRoute path={`${match.url}ref-yakinlik`} component={RefYakinlik} grantedAuthorities={['ref_yakinlik_read']} />
        <PrivateRoute path={`${match.url}ref-kisi-alt-tipi`} component={RefKisiAltTipi} grantedAuthorities={['ref_kisi_alt_tipi_read']} />
        <CrisisPrivateRouteComponent
          path={`${match.url}etkilenen-kisi`}
          component={EtkilenenKisi}
          grantedAuthorities={['view_etkilenen_kisi']}
        />
        <CrisisPrivateRouteComponent
          path={`${match.url}kym-gorevler`}
          component={AdymGorevler}
          grantedAuthorities={['view_tum_gorevler']}
        />
        <CrisisPrivateRouteComponent path={`${match.url}kym-gorev`} component={AdymGorev} grantedAuthorities={['view_gorevlerim']} />
        <CrisisPrivateRouteComponent path={`${match.url}adt-gorevler`} component={AdtGorevler} grantedAuthorities={['view_adt_gorevler']} />
        <CrisisPrivateRouteComponent path={`${match.url}adt-gorev`} component={AdtGorev} grantedAuthorities={['view_adt_gorevlerim']} />
        <CrisisPrivateRouteComponent path={`${match.url}irtibat`} component={Irtibat} grantedAuthorities={['view_iletisim']} />
        <CrisisPrivateRouteComponent path={`${match.url}vekalet`} component={Vekalet} grantedAuthorities={['view_vekalet_yonetimi']} />
        <CrisisPrivateRouteComponent
          path={`${match.url}kym-katilimci`}
          component={AdymKatilimci}
          grantedAuthorities={['view_katilimci_yonetimi']}
        />
        <PrivateRoute path={`${match.url}havalimani`} component={KrizHavalimani} grantedAuthorities={['ref_duyuru_tipi_read']} />
        <CrisisPrivateRouteComponent path={`${match.url}kriz-rapor`} component={KrizRapor} grantedAuthorities={['view_raporlar']} />
        <CrisisPrivateRouteComponent path={`${match.url}kriz-medya`} component={KrizMedya} grantedAuthorities={['view_dokumanlar']} />
        <CrisisPrivateRouteComponent
          path={`${match.url}kriz-talimat`}
          component={KrizTalimat}
          grantedAuthorities={['view_talimat_yonetimi']}
        />
        <CrisisPrivateRouteComponent
          path={`${match.url}kriz-adt-ekip`}
          component={KrizAdtEkip}
          grantedAuthorities={['view_adt_gorev_cagir']}
        />
        <PrivateRoute
          path={`${match.url}ref-sablon-parametre`}
          component={RefSablonParametre}
          grantedAuthorities={['ref_sablon_parametre_read']}
        />
        <CrisisPrivateRouteComponent
          path={`${match.url}kriz-ozet-raporu`}
          component={KrizAcilDurumOzetRapor}
          grantedAuthorities={['view_kriz_ozet_raporu']}
        />
        <PrivateRoute path={`${match.url}ref-ddms-dokuman`} component={RefDdmsDokuman} grantedAuthorities={['ref_ddms_dokuman_read']} />
        <PrivateRoute
          path={`${match.url}ref-ddms-dokuman-kategori`}
          component={RefDdmsDokumanKategori}
          grantedAuthorities={['ref_ddms_dokuman_kategori_read']}
        />
        <PrivateRoute path={`${match.url}bildirim-sablonu`} component={BildirimSablonu} grantedAuthorities={['bildirim_sablonu_read']} />
        <PrivateRoute path={`${match.url}bildirim-yonetimi`} component={BildirimYonetimi} grantedAuthorities={['bildirim_yonetimi_read']} />
        <PrivateRoute path={`${match.url}ref-islem-kodu`} component={RefIslemKodu} grantedAuthorities={['ref_islem_kodu_read']} />
        <PrivateRoute path={`${match.url}ref-dis-dokuman`} component={RefDisDokuman} grantedAuthorities={['ref_dis_dokuman_read']} />
        <CrisisPrivateRouteComponent
          path={`${match.url}kriz-talimat-takip`}
          component={TalimatTakip}
          grantedAuthorities={['view_talimat_ekrani']}
        />
        <PrivateRoute
          path={`${match.url}bildirim-sablonu-kisi-tipi`}
          component={bildirimSablonuKisiTipi}
          grantedAuthorities={['bildirim_sablonu_kisi_tipi_read']}
        />
        <CrisisPrivateRouteComponent
          path={`${match.url}kriz-ivr-bildirim/:id`}
          component={KrizIvrBildirim}
          grantedAuthorities={['view_kriz_ivr_bildirim']}
        />
        <PrivateRoute
          path={`${match.url}kriz-doldurulan-formlar/:id`}
          component={KrizDoldurulanFormlar}
          grantedAuthorities={['ref_duyuru_tipi_read']}
        />
        <CrisisPrivateRouteComponent
          path={`${match.url}kriz-istasyon-personel-cagir`}
          component={KrizIstasyonGorevCagir}
          grantedAuthorities={['view_istasyon_gorev_cagirma']}
        />
        <CrisisPrivateRouteComponent
          path={`${match.url}kriz-istasyon-gorev`}
          component={KrizIstasyonGorev}
          grantedAuthorities={['view_istasyon_gorevleri']}
        />
        <CrisisPrivateRouteComponent path={`${match.url}kriz-ceride`} component={KrizCeride} grantedAuthorities={['view_ceride_liste']} />
        <PrivateRoute
          path={`${match.url}ref-istasyon-gorev-grubu`}
          component={RefIstasyonGorevGrubu}
          grantedAuthorities={['ref_istasyon_gorev_grubu_read']}
        />
        <CrisisPrivateRouteComponent path={`${match.url}kriz-edit`} component={KrizEdit} grantedAuthorities={['view_kriz_detay']} />
        {/* jhipster-needle-add-route-path - JHipster will add routes here */}
      </Switch>
    </div>
  );
};

export default Routes;
