import { IQueryParams } from '../reducers/reducer.utils';
import { IBaseModel } from './base.model';

export interface IRefSablonParametreGrubu extends IBaseModel {
  realId?: number;
  sinifAdi?: string;
}

export interface IRefSablonParametreGrubuParams extends IQueryParams {
  entity?: IRefSablonParametreGrubu;
}

export const defaultValue: Readonly<IRefSablonParametreGrubu> = {};
