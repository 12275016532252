import React from 'react';
import { NavLink } from 'react-router-dom';
import { DropdownItem } from 'reactstrap';
import { hasAnyAuthority } from 'app/config/store';

export interface IMenuItem {
  to: string;
  id?: string;
  'data-cy'?: string;
  grantedAuthorites?: string[];
  onClick?: () => void; // onClick prop ekleyin
}

export default class MenuItem extends React.Component<IMenuItem> {
  handleClick = () => {
    const { onClick } = this.props;
    if (onClick) {
      onClick(); // Eğer onClick prop'u varsa çağır
    }
  };

  render() {
    const { to, id, grantedAuthorites, children } = this.props;
    if (grantedAuthorites && hasAnyAuthority(grantedAuthorites))
      return (
        <div className="nav-link-container">
          <NavLink to={to} className="nav-link" activeClassName="active">
            <DropdownItem
              id={id}
              data-cy={this.props['data-cy']}
              className="dropdownItem"
              onClick={this.handleClick} // MenuItem'a tıklandığında handleClick fonksiyonunu çağır
            >
              <span className="dropdownItemText">{children}</span>
            </DropdownItem>
          </NavLink>
        </div>
      );
    else return null;
  }
}
