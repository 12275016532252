import { IQueryParams } from '../reducers/reducer.utils';

export interface IRefYakinlik {
  id?: number;
  guid?: string;
  adi?: string;
  adiTr?: string;
  adiEn?: string;
  cDate?: string;
  cUser?: number;
  mDate?: string;
  mUser?: number;
}

export interface IRefYakinlikParams extends IQueryParams {
  entity?: IRefYakinlik;
}

export const defaultValue: Readonly<IRefYakinlik> = {};
