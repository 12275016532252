import { IReferans } from 'app/shared/model/referans.model';
import { IQueryParams } from '../reducers/reducer.utils';
import { IRefSablonParametreGrubu } from './ref-sablon-parametre-grubu.model';

export interface IRefSablonParametre {
  aciklama?: string;
  aciklamaEn?: string;
  aciklamaTr?: string;
  adi?: string;
  adiEn?: string;
  adiTr?: string;
  guid?: string;
  id?: number;
  mDate?: string;
  parametreKodu?: string;
  realId?: number;
  sablonParametreGrubu?: IRefSablonParametreGrubu;
  statikIcerik?: string;
  statikMi?: IReferans;
}

export interface IRefSablonParametreParams extends IQueryParams {
  entity?: IRefSablonParametre;
}

export const defaultValue: Readonly<IRefSablonParametre> = {};
