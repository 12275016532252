import React from 'react';
import { Row, Col } from 'reactstrap';
import { translate } from 'app/component/jhipster';
import { ReferansEnum } from 'app/constants/ReferansEnum';
import { FormItem } from 'app/component/FormItem';
import { IRefKrizTuruKatilimciGorev } from 'app/shared/model/ref-kriz-turu-katilimci-gorev.model';
import { useAppSelector } from 'app/config/store';
import { ReferansTipiEnum } from 'app/constants/ReferansTipiEnum';

interface RefGorevDetailProp {
  refKrizTuruKatilimciGorev: IRefKrizTuruKatilimciGorev;
  isViewing: boolean;
}

export const RefKrizTuruKatilimciGorevDetail: React.FC<RefGorevDetailProp> = ({ refKrizTuruKatilimciGorev, isViewing }) => {
  const referans = useAppSelector(state => state.referans.entities);
  const refEvetHayir = referans.filter(item => item.tipi === ReferansTipiEnum.EvetHayir);

  return (
    <>
      <Row>
        <Col md={12}>
          <FormItem
            label={translate('global.languageGlobal.adiTr')}
            id="adiTr"
            name="adiTr"
            defaultValue={refKrizTuruKatilimciGorev && refKrizTuruKatilimciGorev?.gorev?.adiTr}
            disabled={isViewing}
            type="text"
          />
          <FormItem
            label={translate('global.languageGlobal.adiEn')}
            id="adiEn"
            name="adiEn"
            defaultValue={refKrizTuruKatilimciGorev && refKrizTuruKatilimciGorev?.gorev?.adiEn}
            disabled={isViewing}
            type="text"
          />
          <FormItem
            label={translate('global.languageGlobal.aciklamaTr')}
            id="aciklamaTr"
            name="aciklamaTr"
            defaultValue={refKrizTuruKatilimciGorev && refKrizTuruKatilimciGorev?.gorev?.aciklamaTr}
            disabled={isViewing}
            type="textarea"
          />
          <FormItem
            label={translate('global.languageGlobal.aciklamaEn')}
            id="aciklamaEn"
            name="aciklamaEn"
            defaultValue={refKrizTuruKatilimciGorev && refKrizTuruKatilimciGorev?.gorev?.aciklamaEn}
            disabled={isViewing}
            type="textarea"
          />
          {refKrizTuruKatilimciGorev?.gorev?.gorevSahibi?.id === ReferansEnum.GorevSahibi.ADYM && (
            <>
              <FormItem
                label={translate('tk24AdysApp.refGorev.sure')}
                id="sure"
                name="sure"
                defaultValue={
                  refKrizTuruKatilimciGorev && refKrizTuruKatilimciGorev.gorev?.sure
                    ? `${refKrizTuruKatilimciGorev?.gorev?.sure} ${translate('tk24AdysApp.refGorevSorBirim.dakika')}`
                    : '-'
                }
                disabled={isViewing}
                type="text"
              />

              <FormItem
                label={translate('tk24AdysApp.refGorev.krizAkisListGoreviMi')}
                id="krizAkisListGoreviMi"
                name="krizAkisListGoreviMi"
                defaultValue={refKrizTuruKatilimciGorev && refKrizTuruKatilimciGorev.krizAkisListGoreviMi?.id}
                type="select"
                disabled={isViewing}
              >
                <option value="" key="0" />
                {refEvetHayir
                  ? refEvetHayir.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.adi}
                      </option>
                    ))
                  : null}
              </FormItem>
            </>
          )}
          <FormItem
            label={translate('tk24AdysApp.refGorev.gorevSahibi')}
            id="gorevSahibi"
            name="gorevSahibi"
            defaultValue={
              refKrizTuruKatilimciGorev && refKrizTuruKatilimciGorev?.gorev?.gorevSahibi?.adi
                ? refKrizTuruKatilimciGorev?.gorev?.gorevSahibi?.adi
                : '-'
            }
            disabled={isViewing}
            type="text"
          />
        </Col>
      </Row>
    </>
  );
};

export default RefKrizTuruKatilimciGorevDetail;
