export const TableNameEnum = {
  Duyuru: 'DUYURU',
  KrizDuyuru: 'KRIZ_DUYURU',
  Kriz: 'KRIZ',
  KrizKatilimciGorev: 'KRIZ_KATILIMCI_GOREV',
  RefDisDokuman: 'REF_DIS_DOKUMAN',
  KrizChat: 'KRIZ_CHAT',
  KrizTalimat: 'KRIZ_TALIMAT',
  RefUcak: 'REF_UCAK',
  Kisi: 'KISI',
  RefMurettebat: 'REF_MURETTEBAT',
  RefDdmsDokuman: 'REF_DDMS_DOKUMAN',
  RefUcus: 'REF_UCUS',
  RefAdtForm: 'REF_ADT_FORM',
};

export default TableNameEnum;
