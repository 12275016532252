import { loadingBarReducer as loadingBar } from 'react-redux-loading-bar';

import locale, { LocaleState } from './locale';
import paginate, { PaginationState } from './pagination';
import authentication, { AuthenticationState } from './authentication';
import applicationProfile, { ApplicationProfileState } from './application-profile';

import administration, { AdministrationState } from 'app/modules/administration/administration.reducer';
import userManagement, { UserManagementState } from 'app/modules/administration/user-management/user-management.reducer';
import activate, { ActivateState } from 'app/modules/account/activate/activate.reducer';
import settings, { SettingsState } from 'app/modules/account/settings/settings.reducer';
// prettier-ignore
import adymDuyuru from 'app/entities/adym-duyuru/adym-duyuru.reducer';
// prettier-ignore
import adtDuyuru from 'app/entities/adt-duyuru/adt-duyuru.reducer';
// prettier-ignore
import krizDuyurular from 'app/entities/kriz-duyurular/kriz-duyurular.reducer';
// prettier-ignore
import ekDosya from 'app/entities/ek-dosya/ek-dosya.reducer';
// prettier-ignore
import kriz from 'app/entities/kriz/kriz.reducer';
// prettier-ignore
import krizCeride from 'app/entities/kriz-ceride/kriz-ceride.reducer';
// prettier-ignore
import krizKatilimci from 'app/entities/kriz-katilimci/kriz-katilimci.reducer';
// prettier-ignore
import krizKatilimciBildirim from 'app/entities/kriz-katilimci-bildirim/kriz-katilimci-bildirim.reducer';
// prettier-ignore
import krizKatilimciGorev from 'app/entities/kriz-katilimci-gorev/kriz-katilimci-gorev.reducer';
// prettier-ignore
import krizUcak from 'app/entities/kriz-ucak/kriz-ucak.reducer';
// prettier-ignore
import krizUcus from 'app/entities/kriz-ucus/kriz-ucus.reducer';
// prettier-ignore
import krizHavalimani from 'app/entities/kriz-havalimani/kriz-havalimani.reducer';
// prettier-ignore
import krizEtkilenenKisiFavori from 'app/entities/etkilenen-kisi/reducer/etkilenen-kisi-favoris.reducer';
// prettier-ignore
import krizEtkilenenKisiAdtAtama from 'app/entities/etkilenen-kisi/reducer/etkilenen-kisi-adt-atama.reducer';
// prettier-ignore
import krizTalimat from 'app/entities/kriz-talimat/kriz-talimat.reducer';
// prettier-ignore
import kullanici from 'app/entities/kullanici/kullanici.reducer';
// prettier-ignore
import kullaniciRol from 'app/entities/kullanici-rol/kullanici-rol.reducer';
// prettier-ignore
import mesaj from 'app/entities/mesaj/mesaj.reducer';
// prettier-ignore
import refDuyuruTipi from 'app/entities/ref-duyuru-tipi/ref-duyuru-tipi.reducer';
// prettier-ignore
import refDdmsDokuman from 'app/entities/ref-ddms-dokuman/ref-ddms-dokuman.reducer';
// prettier-ignore
import refDisDokuman from 'app/entities/ref-dis-dokuman/ref-dis-dokuman.reducer';
// prettier-ignore
import refEkDosyaTipi from 'app/entities/ref-ek-dosya-tipi/ref-ek-dosya-tipi.reducer';
// prettier-ignore
import refGorev from 'app/entities/ref-gorev/ref-gorev.reducer';
// prettier-ignore
import refGorevSorBirim from 'app/entities/ref-gorev-sor-birim/ref-gorev-sor-birim.reducer';
// prettier-ignore
import refHavalimani from 'app/entities/ref-havalimani/ref-havalimani.reducer';
// prettier-ignore
import refHavalimaniIrtibat from 'app/entities/ref-havalimani-irtibat/ref-havalimani-irtibat.reducer';
// prettier-ignore
import krizIrtibat from 'app/entities/irtibat/irtibat.reducer';
// prettier-ignore
import refKatilimci from 'app/entities/ref-katilimci/ref-katilimci.reducer';
// prettier-ignore
import refKatilimciIletisim from 'app/entities/ref-katilimci-iletisim/ref-katilimci-iletisim.reducer';
// prettier-ignore
import refKatilimciDdmsDokuman from 'app/entities/ref-katilimci-ddms-dokuman/ref-katilimci-ddms-dokuman.reducer';
// prettier-ignore
import refDdmsDokumanKategori from 'app/entities/ref-ddms-dokuman-kategori/ref-ddms-dokuman-kategori.reducer';
// prettier-ignore
import refKatilimciVekil from 'app/entities/ref-katilimci-vekil/ref-katilimci-vekil.reducer';
// prettier-ignore
import refKrizSeviye from 'app/entities/ref-kriz-seviye/ref-kriz-seviye.reducer';
// prettier-ignore
import refOrganizasyon from 'app/entities/ref-organizasyon/ref-organizasyon.reducer';
// prettier-ignore
import refKrizTuru from 'app/entities/ref-kriz-turu/ref-kriz-turu.reducer';
// prettier-ignore
import refKrizTuruKatilimci from 'app/entities/ref-kriz-turu-katilimci/ref-kriz-turu-katilimci.reducer';
// prettier-ignore
import refKrizTuruKatilimciGorev from 'app/entities/ref-kriz-turu-katilimci-gorev/ref-kriz-turu-katilimci-gorev.reducer';
// prettier-ignore
import refUcak from 'app/entities/ref-ucak/ref-ucak.reducer';
// prettier-ignore
import refUcus from 'app/entities/ref-ucus/ref-ucus.reducer';
// prettier-ignore
import referans from 'app/entities/referans/referans.reducer';
// prettier-ignore
import rol from 'app/entities/rol/rol.reducer';
// prettier-ignore
import rolYetki from 'app/entities/rol-yetki/rol-yetki.reducer';
// prettier-ignore
import sistemParametre from 'app/entities/sistem-parametre/sistem-parametre.reducer';
// prettier-ignore
import uygulamaAyarlari from 'app/entities/uygulama-ayarlari/uygulama-ayarlari.reducer';
// prettier-ignore
import ucusKisiListesi from 'app/entities/ucus-kisi-listesi/ucus-kisi-listesi.reducer';
// prettier-ignore
import yetki from 'app/entities/yetki/yetki.reducer';
// prettier-ignore
import refAdtAltEkip from 'app/entities/ref-adt-ekip/ref-adt-alt-ekip.reducer';
// prettier-ignore
import refAdtAltEkipUye from 'app/entities/ref-adt-ekip/ref-adt-alt-ekip-uye.reducer';
// prettier-ignore
import refAdtEkip from 'app/entities/ref-adt-ekip/ref-adt-ekip.reducer';
// prettier-ignore
import refAdtForm from 'app/entities/ref-adt-form/ref-adt-form.reducer';
// prettier-ignore
import refSaglikDurumu from 'app/entities/ref-saglik-durumu/ref-saglik-durumu.reducer';
// prettier-ignore
import refYakinlik from 'app/entities/ref-yakinlik/ref-yakinlik.reducer';
// prettier-ignore
import krizEkKisi from 'app/entities/etkilenen-kisi/reducer/etkilenen-kriz-ek-kisi.reducer';
// prettier-ignore
import krizEtkilenenKisiHatirlatma from 'app/entities/etkilenen-kisi/reducer/etkilenen-kisi-hatirlatma.reducer';
// prettier-ignore
import krizOzetRaporu from 'app/entities/kriz-ozet-rapor/kriz-ozet-rapor.reducer';
// prettier-ignore
import refAdtFormBolum from 'app/entities/ref-adt-form-bolum/ref-adt-form-bolum.reducer';
// prettier-ignore
import refAdtFormBolumSoru from 'app/entities/ref-adt-form-bolum-soru/ref-adt-form-bolum-soru.reducer';
// prettier-ignore
import refAdtFormBolumSoruSecenek from 'app/entities/ref-adt-form-bolum-soru-secenek/ref-adt-form-bolum-soru-secenek.reducer';
// prettier-ignore
import refYolcu from 'app/entities/etkilenen-kisi/reducer/ref-yolcu.reducer';
// prettier-ignore
import refMurettebat from 'app/entities/etkilenen-kisi/reducer/ref-murettebat.reducer';
// prettier-ignore
import refKisiAltTipi from 'app/entities/ref-kisi-alt-tipi/ref-kisi-alt-tipi.reducer';
import viewer from 'app/modules/viewer/viewer.reducer';
import refHavalimaniIrtibatTipi from 'app/entities/ref-havalimani-irtibat-tipi/ref-havalimani-irtibat-tipi.reducer';
import bildirimSablonu from 'app/entities/bildirim-sablonu/bildirim-sablonu.reducer';
import bildirimSablonuKisiTipi from 'app/entities/bildirim-sablon-kisi-tipi/bildirim-sablonu-kisi-tipi.reducer';
import krizIvrBildirim from 'app/entities/kriz-ivr-bildirimleri/kriz-ivr-bildirim.reducer';
import talimatTakip from 'app/entities/talimat-takip/talimat-takip.reducer';
import krizDoldurulanFormlar from 'app/entities/kriz-doldurulan-formlar/kriz-doldurulan-formlar.reducer';
import krizChat from 'app/entities/kriz-chat/kriz-chat.reducer';
import krizAdtEkip from 'app/entities/kriz-adt-ekip/kriz-adt-ekip.reducer';
import personel from 'app/entities/personel/personel.reducer';
import krizKatilimciGorevSorumluBirim from 'app/entities/kriz-katilimci-gorev-sorumlu-birim/kriz-katilimci-gorev-sorumlu-birim.reducer';
import refIslemKodu from 'app/entities/ref-islem-kodu/ref-islem-kodu.reducer';
import adtUye from 'app/entities/adt-uye/adt-uye.reducer';
import refIstasyonGorevGrubu from 'app/entities/ref-istasyon-gorev-grubu/ref-istasyon-gorev-grubu.reducer';
import refSablonParametreGrubu from 'app/entities/ref-sablon-parametre-grubu/ref-sablon-parametre-grubu.reducer';
import krizIstasyon from 'app/entities/kriz-isyasyon/kriz-istasyon.reducer';
import refIstasyonGorevGrubuPersonel from 'app/entities/ref-ist-gorev-grubu-personel/ref-ist-gorev-grubu-personel.reducer';
import krizKatilimciIletisim from 'app/entities/kriz-katilimci-iletisim/kriz-katilimci-iletisim.reducer';
import refSablonParametre from 'app/entities/ref-sablon-parametre/ref-sablon-parametre.reducer';
import krizKartList from 'app/entities/kriz/kriz-kart-list.reducer';
import krizAuthority from 'app/entities/kriz-dashboard/kriz-authority.reducer';
import krizChatSlice from 'app/entities/kriz-chat/kriz-chat-slice.reducer';
import occRehber from 'app/entities/occ-rehber/occ-rehber.reducer';
import refHavalimaniDestek from 'app/entities/ref-havalimani-destek/ref-havalimani-destek.reducer';
import manuelBildirim from 'app/entities/manuel-bildirim/manuel-bildirim.reducer';

/* jhipster-needle-add-reducer-import - JHipster will add reducer here */

const rootReducer = {
  authentication,
  locale,
  applicationProfile,
  administration,
  userManagement,
  activate,
  settings,
  adymDuyuru,
  adtDuyuru,
  krizDuyurular,
  ekDosya,
  kriz,
  krizCeride,
  krizKatilimci,
  krizKatilimciBildirim,
  krizKatilimciGorev,
  krizUcak,
  krizUcus,
  krizHavalimani,
  krizEtkilenenKisiFavori,
  krizEtkilenenKisiAdtAtama,
  krizTalimat,
  kullanici,
  kullaniciRol,
  mesaj,
  refDuyuruTipi,
  refDdmsDokuman,
  refDisDokuman,
  refEkDosyaTipi,
  refGorev,
  refGorevSorBirim,
  refHavalimani,
  refHavalimaniIrtibat,
  refHavalimaniIrtibatTipi,
  krizIrtibat,
  refKatilimci,
  refKatilimciDdmsDokuman,
  refDdmsDokumanKategori,
  refKatilimciIletisim,
  refKatilimciVekil,
  refKrizSeviye,
  refOrganizasyon,
  refKrizTuru,
  refKrizTuruKatilimci,
  refKrizTuruKatilimciGorev,
  refUcak,
  refUcus,
  referans,
  personel,
  krizKatilimciGorevSorumluBirim,
  refIslemKodu,
  adtUye,
  rol,
  rolYetki,
  refYolcu,
  refAdtFormBolum,
  refAdtFormBolumSoru,
  refAdtFormBolumSoruSecenek,
  refMurettebat,
  sistemParametre,
  uygulamaAyarlari,
  ucusKisiListesi,
  yetki,
  refAdtAltEkip,
  refAdtAltEkipUye,
  refAdtEkip,
  refAdtForm,
  refSaglikDurumu,
  refYakinlik,
  refKisiAltTipi,
  paginate,
  krizEkKisi,
  krizEtkilenenKisiHatirlatma,
  krizOzetRaporu,
  bildirimSablonu,
  bildirimSablonuKisiTipi,
  krizIvrBildirim,
  krizDoldurulanFormlar,
  krizChat,
  krizAdtEkip,
  talimatTakip,
  refIstasyonGorevGrubu,
  refSablonParametreGrubu,
  manuelBildirim,
  krizIstasyon,
  refIstasyonGorevGrubuPersonel,
  krizKatilimciIletisim,
  refSablonParametre,
  /* jhipster-needle-add-reducer-combine - JHipster will add reducer here */
  loadingBar,
  viewer,
  krizKartList,
  krizAuthority,
  krizChatSlice,
  occRehber,
  refHavalimaniDestek,
};

export default rootReducer;
