import { IQueryParams } from '../reducers/reducer.utils';
import { IBaseModel } from './base.model';

export interface IRefDDMSDokumanKategori extends IBaseModel {
  mDate?: string;
  kisaAdi?: string;
}

export interface IRefDDMSDokumanKategoriParams extends IQueryParams {
  entity?: IRefDDMSDokumanKategori;
}

export const defaultValue: Readonly<IRefDDMSDokumanKategori> = {};
