import { IReferans } from 'app/shared/model/referans.model';
import { IRefKrizTuru } from 'app/shared/model/ref-kriz-turu.model';
import { IRefKatilimci } from 'app/shared/model/ref-katilimci.model';
import { IRefGorev } from 'app/shared/model/ref-gorev.model';
import { IRefAdtEkip } from './ref-adt-ekip.model';
import { IQueryParams } from '../reducers/reducer.utils';
import { IBaseModel } from './base.model';

export interface IRefKrizTuruKatilimciGorev extends IBaseModel {
  acilDurumTuru?: IReferans;
  krizTuru?: IRefKrizTuru;
  adtEkip?: IRefAdtEkip;
  gorev?: IRefGorev;
  katilimci?: IRefKatilimci;
  krizAkisListGoreviMi?: IReferans;
  sortIndex?: string;
}

export interface IRefKrizTuruKatilimciGorevParams extends IQueryParams {
  entity?: IRefKrizTuruKatilimciGorev;
}

export const defaultValue: Readonly<IRefKrizTuruKatilimciGorev> = {};
