export interface IEtkilenenKisiYakini {
  id?: number;
  adi?: string;
  soyadi?: string;
  eposta?: string;
  gsmNo?: string;
  aciklama?: string;
  krizEtkilenenKisi?: any;
  yakinlik?: any;
}

export const defaultValue: Readonly<IEtkilenenKisiYakini> = {};
