export const RefCerideEkleyenEnum = {
  150: {
    KrizYonetimiKurulu: 1,
    KrizYonetimiKuruluBaskani: 2,
    KrizYonetimiKuruluSekreterya: 3,
    Diger: 4,
    Katilimci: 5,
  },
  151: {
    GoreveCagrilanTim: 6,
    Katilimci: 7,
    Diger: 8,
  },
  152: {
    GoreveCagrilanIstasyon: 9,
    Katilimci: 10,
    Diger: 11,
  },
};
