import { IQueryParams } from '../reducers/reducer.utils';

export interface IReferans {
  id?: number;
  guid?: string;
  mDate?: string;
  adiTr?: string;
  adiEn?: string;
  adi?: string;
  aciklamaTr?: string;
  aciklamaEn?: string;
  aciklama?: string;
  tipi?: string;
  sortIndex?: number;
  realId?: number;
}

export interface IReferansParams extends IQueryParams {
  entity?: IReferans;
}

export const defaultValue: Readonly<IReferans> = {};
