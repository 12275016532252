declare const L: any; // use this to declare an untyped leaflet namespace
import 'leaflet-contextmenu';

export const createMap = (mapMaxZoom, center, contextmenuItems) => {
  const newMap = L.map('map', {
    center,
    zoom: 7,
    attributionControl: false,
    zoomControl: false,
    minZoom: 1,
    maxZoom: mapMaxZoom,
     zoomAnimation: true,
    contextmenu: true,
    contextmenuWidth: 140,
    contextmenuItems,
    // zoomSnap: 0.25
  });

  return newMap;
}



