import { IReferans } from 'app/shared/model/referans.model';
import { IKullaniciRol } from 'app/shared/model/kullanici-rol.model';
import { IRolYetki } from 'app/shared/model/rol-yetki.model';
import { IQueryParams } from '../reducers/reducer.utils';

export interface IRol {
  id?: number;
  guid?: string;
  adi?: string;
  adiEn?: string;
  adiTr?: string;
  aciklamaEn?: string | null;
  aciklamaTr?: string | null;
  aciklama?: string | null;
  cDate?: string;
  cUser?: number;
  mDate?: string;
  mUser?: number;
  durum?: IReferans;
  kullaniciRols?: IKullaniciRol[] | null;
  rolYetkis?: IRolYetki[] | null;
  katilimciRolu?: IReferans;
  isAdmin?: IReferans;
}

export interface IRolParams extends IQueryParams {
  entity?: IRol;
}

export const defaultValue: Readonly<IRol> = {};
