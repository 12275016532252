import React, { FormEventHandler } from 'react';
import { Button, UncontrolledTooltip, Form, ModalFooter, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { Translate, translate } from 'app/component/jhipster';
import { FormItem } from 'app/component/FormItem';
import { IRefHavalimaniIrtibatTipi } from 'app/shared/model/ref-havalimani-irtibat-tipi.model';

interface RefHavalimaniIrtibatTipiUpdateProp {
  saveNewRefEntity: FormEventHandler;
  handleCloseForm: FormEventHandler;
  formValues: IRefHavalimaniIrtibatTipi;
  isOpen: boolean;
}

export const RefHavalimaniIrtibatTipiUpdate: React.FC<RefHavalimaniIrtibatTipiUpdateProp> = ({
  saveNewRefEntity,
  handleCloseForm,
  formValues,
  isOpen,
}) => {
  return (
    <Modal className="modal-style" isOpen={isOpen} toggle={handleCloseForm} centered>
      <ModalHeader data-cy="RefHavalimaniIrtibatTipiCreateUpdateHeading" toggle={handleCloseForm}>
        {formValues && (
          <p className="modal-title-info">
            {formValues?.adiTr}-{formValues?.adiEn}
          </p>
        )}
        {formValues ? (
          <Translate contentKey="tk24AdysApp.refHavalimaniIrtibatTipi.home.editLabel">Update a Ref Saglik Durumu</Translate>
        ) : (
          <Translate contentKey="tk24AdysApp.refHavalimaniIrtibatTipi.home.createLabel">Create a Ref Saglik Durumu</Translate>
        )}
      </ModalHeader>
      <Form onSubmit={saveNewRefEntity}>
        <ModalBody>
          <FormItem
            label={translate('global.languageGlobal.adiTr')}
            id="ref-yakinlik-adiTr"
            name="adiTr"
            defaultValue={formValues && formValues['adiTr']}
            type="text"
            validation={{
              required: { value: true, message: translate('entity.validation.required') },
              maxLength: { value: 100, message: translate('entity.validation.maxlength', { max: 100 }) },
            }}
          />
          <UncontrolledTooltip target="adiTrLabel">
            <Translate contentKey="global.languageGlobal.adiTr" />
          </UncontrolledTooltip>

          <FormItem
            label={translate('global.languageGlobal.adiEn')}
            id="ref-yakinlik-adiEn"
            name="adiEn"
            defaultValue={formValues && formValues['adiEn']}
            type="text"
            validation={{
              required: { value: true, message: translate('entity.validation.required') },
              maxLength: { value: 100, message: translate('entity.validation.maxlength', { max: 100 }) },
            }}
          />
          <UncontrolledTooltip target="adiEnLabel">
            <Translate contentKey="global.languageGlobal.adiEn" />
          </UncontrolledTooltip>
        </ModalBody>
        <ModalFooter className="modal-footer">
          <Button className="cancel-button" onClick={handleCloseForm}>
            <Translate contentKey="entity.action.cancel">Cancel</Translate>
          </Button>
          &nbsp;
          <Button className="save-button" id="jhi-confirm-delete-refAdtForm" type="submit" data-cy="entityConfirmButton">
            <Translate contentKey="entity.action.save">Kaydet</Translate>
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default RefHavalimaniIrtibatTipiUpdate;
