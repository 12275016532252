import React from 'react';
import { Row, Col } from 'reactstrap';
import { Translate } from 'app/component/jhipster';
import { IRefKrizTuru } from 'app/shared/model/ref-kriz-turu.model';

interface RefKrizTuruDetailProp {
  refKrizTuru: IRefKrizTuru;
}

export const RefKrizTuruDetail: React.FC<RefKrizTuruDetailProp> = ({ refKrizTuru }) => {
  return (
    <>
      <Row className="modal-body-row">
        <Col lg="6" style={{ marginTop: '10px' }}>
          <Row className="modal-content-title">
            <Translate contentKey="global.languageGlobal.adiTr">Adi Tr</Translate>
          </Row>
          <Row className="modal-content-text">{refKrizTuru.adiTr}</Row>
        </Col>
        <Col lg="6" style={{ marginTop: '10px' }}>
          <Row className="modal-content-title">
            <Translate contentKey="global.languageGlobal.adiEn">Adi En</Translate>
          </Row>
          <Row className="modal-content-text">{refKrizTuru.adiEn}</Row>
        </Col>
      </Row>
      <Row className="modal-body-row">
        <Col lg="6" style={{ marginTop: '10px' }}>
          <Row className="modal-content-title">
            <Translate contentKey="tk24AdysApp.refKrizTuru.defaultKrizSeviye"></Translate>
          </Row>
          <Row className="modal-content-text">{refKrizTuru.defaultKrizSeviye && refKrizTuru.defaultKrizSeviye.adiTr}</Row>
        </Col>
      </Row>
    </>
  );
};

export default RefKrizTuruDetail;
