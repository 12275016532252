import React, { useState, useEffect } from 'react';
import 'app/entities/style.scss';
import { RouteComponentProps } from 'react-router-dom';
import { TextFormat, Translate, translate } from 'app/component/jhipster';
import InfoTable from 'app/entities/infoTable';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntities, getTotalEntities } from './kriz-ivr-bildirim.reducer';
import { isNullOrEmpty } from 'app/shared/util/confirm-utils';
import { ASC, DESC } from 'app/shared/util/pagination.constants';
import { Input } from 'reactstrap';
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Table,
} from '@mui/material';
import { Search } from '@mui/icons-material';
import { getEntities as getReferans } from 'app/entities/referans/referans.reducer';
import { LoadingSpinner } from 'app/component/LoadingSpinner';
import { KrizMain } from 'app/component/KrizMain';
import { IQueryParams } from 'app/shared/reducers/reducer.utils';
import CustomPagination from 'app/component/CustomPagination';
import SortIcon from 'app/component/SortIcon';
import { IKrizIvrBildirim } from 'app/shared/model/kriz-ivr-bildirim.model';
import ExcelDownloadButton from 'app/component/ExcelDownload';
import { APP_DATE_FORMAT } from 'app/config/constants';
import { localeLanguage, upperOnlyFirstLetter } from 'app/shared/util/util';

const KrizIvrBildirim = (props: RouteComponentProps<{ id: string }>) => {
  const dispatch = useAppDispatch();
  const isDrawer = useAppSelector(state => state.krizChat.isDrawer);

  const { match } = props;

  const krizIvrBildirimList = useAppSelector(state => state.krizIvrBildirim.entities);
  const loading = useAppSelector(state => state.krizIvrBildirim.loading);
  const updating = useAppSelector(state => state.krizIvrBildirim.updating);
  const totalItem = useAppSelector(state => state.krizIvrBildirim.totalItems);

  const referans = useAppSelector(state => state.referans.entities);
  const aramaSebebiList = referans.filter(res => res.tipi === 'IVR_ARAMA_SEBEBI');
  const gonderimDurumuList = referans.filter(res => res.tipi === 'GONDERIM_DURUMU');

  const [search, setSearch] = useState('');

  const queryParams = useAppSelector(state => state.paginate.pageObject);

  const [pageObject, setPageObject] = useState<IQueryParams>(() => {
    const newPageObject = { ...queryParams };
    delete newPageObject.sort;
    return newPageObject;
  });

  const ExcelSampleData = [
    {
      key: 'krizKatilimci.vekilKatilimci.katilimci.adi',
      key2: 'krizKatilimci.vekilKatilimci.katilimci.soyadi',
      titleContentKey: 'tk24AdysApp.krizKatilimci.asil',
    },
    { key: 'krizKatilimci.adi', key2: 'krizKatilimci.soyadi', titleContentKey: 'tk24AdysApp.krizIvrBildirim.katilimciBilgisi' },
    { key: 'krizKatilimci.unvanAdi', titleContentKey: 'tk24AdysApp.krizKatilimci.unvanAdi' },
    { key: 'krizKatilimci.katilimciRolu.adi', titleContentKey: 'tk24AdysApp.krizKatilimci.katilimciRolu.adi' },
    { key: 'ivrAramaSebebi.adi', titleContentKey: 'tk24AdysApp.krizIvrBildirim.aramaSebebi' },
    { key: 'icerik', titleContentKey: 'tk24AdysApp.krizIvrBildirim.icerik' },
    { key: 'ivrTalep', titleContentKey: 'tk24AdysApp.krizIvrBildirim.ivrTalep' },
    { key: 'ivrCevapKacDkSonra', titleContentKey: 'tk24AdysApp.krizIvrBildirim.cevapSure' },
    { key: 'ivrCevapKatilimDurumu.adi', titleContentKey: 'tk24AdysApp.krizIvrBildirim.cevapKatilimDurumu' },
    { key: 'aramaZamani', format: 'date', titleContentKey: 'tk24AdysApp.krizIvrBildirim.gonderimZamani' },
    { key: 'denemeSayisi', titleContentKey: 'tk24AdysApp.krizIvrBildirim.denemeSayisi' },
    { key: 'gonderimDurumu.adi', titleContentKey: 'tk24AdysApp.krizIvrBildirim.gonderimDurumus' },
  ];

  const getAllEntities = () => {
    dispatch(
      getEntities({
        ...pageObject,
        krizId: `${match.params.id}`,
      })
    );
  };

  useEffect(() => {
    dispatch(getReferans({ size: 2000, page: 0, sort: 'asc' }));
  }, []);

  useEffect(() => {
    getAllEntities();
  }, [pageObject.page, pageObject.query, pageObject.size, pageObject.sort]);

  useEffect(() => {
    if (search.length > 2) {
      setPageObject(prevPageObject => ({
        ...prevPageObject,
        page: 0,
        query: prevPageObject.query + `&globalFilter=${search}`,
      }));
    } else if (search.length === 0 && !isNullOrEmpty(pageObject.query)) {
      const keyToDelete = 'globalFilter';
      const updatedParams = pageObject.query
        .split('&')
        .filter(param => {
          const [key, _] = param.split('=');
          return key !== keyToDelete;
        })
        .join('&');

      setPageObject({ ...pageObject, page: 0, query: updatedParams });
    }
  }, [search]);

  const sort = p => () => {
    if (pageObject.sort) {
      const orderBy = pageObject.sort.split(',')[1];
      setPageObject({ ...pageObject, sort: `${p},${orderBy === ASC ? DESC : ASC}` });
    } else {
      setPageObject({ ...pageObject, sort: `${p},desc` });
    }
  };

  const handlePageChange = (pageNumber, newItemsPerPage = pageObject.size) => {
    if (newItemsPerPage !== pageObject.size) {
      setPageObject({ ...pageObject, page: pageNumber - 1, size: newItemsPerPage });
    } else {
      setPageObject({ ...pageObject, page: pageNumber - 1 });
    }
  };

  const [ivrCevapKatilimDurumuData, setIvrCevapKatilimDurumuData] = useState(null);

  const deleteMultipleQueryParams = (queryParamsx, keysToDelete) => {
    let updatedParams = queryParamsx;
    keysToDelete.forEach(keyToDelete => {
      updatedParams = updatedParams
        .split('&')
        .filter(param => {
          const [key, _] = param.split('=');
          return key !== keyToDelete;
        })
        .join('&');
    });

    setIvrCevapKatilimDurumuData(null);
    return updatedParams;
  };

  const handleSearchChange = (elem, event) => {
    if (!isNullOrEmpty(event.target.value)) {
      setPageObject(prevPageObject => ({
        ...prevPageObject,
        page: 0,
        query: prevPageObject.query + `&${elem}.equals=${event.target.value}`,
      }));
    } else {
      const keyToDelete = `${elem}.equals`;
      const updatedParams = pageObject.query
        .split('&')
        .filter(param => {
          const [key, _] = param.split('=');
          return key !== keyToDelete;
        })
        .join('&');

      setPageObject({ ...pageObject, page: 0, query: updatedParams });
    }
  };

  const fetchTotalKrizIvrBildirimData = async (): Promise<IKrizIvrBildirim[]> => {
    try {
      const response = (await dispatch(
        getTotalEntities({
          ...pageObject,
          size: totalItem,
          krizId: match.params.id,
        })
      )) as {
        payload: { data: IKrizIvrBildirim[] };
      };

      return response.payload.data || [];
    } catch (error) {
      console.error('Error fetching data:', error);
      return [];
    }
  };

  return (
    <KrizMain isDrawer={isDrawer}>
      {(loading || updating) && <LoadingSpinner />}

      <div style={{ marginBottom: 5 }}>
        <InfoTable id={match.params.id} titleContentKey={'tk24AdysApp.krizIvrBildirim.home.title'} urlName="kriz-rapor" />
      </div>

      <div className="content-area">
        <div className="search-frame">
          <div className="seachiconposition">
            <Search className="seachicon" style={{ color: 'grey' }} />
            <Input
              value={search}
              onChange={e => setSearch(e.target.value)}
              placeholder={translate('entity.action.search')}
              className="search-input"
            />
          </div>
          <div className="d-flex justify-end">
            <Box sx={{ minWidth: 200, maxWidth: 200, marginLeft: 1 }}>
              <FormControl size={window.innerWidth < 1600 ? 'small' : 'medium'} fullWidth>
                <InputLabel id="ivrAramaSebebi" className="select-input">
                  <Translate contentKey="tk24AdysApp.refIvrCevapKatilimDurumu.aramaSebebi" />
                </InputLabel>
                <Select
                  className="select-input"
                  labelId="ivrAramaSebebi"
                  id="ivrAramaSebebi"
                  label={translate('tk24AdysApp.refIvrCevapKatilimDurumu.aramaSebebi')}
                  onChange={event => handleSearchChange('ivrAramaSebebiId', event)}
                >
                  <MenuItem value="">
                    <Translate contentKey="entity.action.all" />
                  </MenuItem>
                  {aramaSebebiList &&
                    aramaSebebiList.length > 0 &&
                    aramaSebebiList.map(item => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.adi}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Box>
            {ivrCevapKatilimDurumuData && (
              <Box sx={{ minWidth: 200, maxWidth: 200, marginLeft: 1 }}>
                <FormControl size={window.innerWidth < 1600 ? 'small' : 'medium'} fullWidth>
                  <InputLabel id="ivrCevapKatilimDurumuId" className="select-input">
                    <Translate contentKey="tk24AdysApp.krizIvrBildirim.cevapKatilimDurumu" />
                  </InputLabel>
                </FormControl>
              </Box>
            )}
            <Box sx={{ minWidth: 200, maxWidth: 200, marginLeft: 1, marginRight: 1 }}>
              <FormControl size={window.innerWidth < 1600 ? 'small' : 'medium'} fullWidth>
                <InputLabel id="ivrGonderimDurumu" className="select-input">
                  <Translate contentKey="tk24AdysApp.krizIvrBildirim.gonderimDurumu" />
                </InputLabel>
                <Select
                  className="select-input"
                  labelId="ivrGonderimDurumu"
                  id="ivrGonderimDurumu"
                  label={translate('tk24AdysApp.krizIvrBildirim.gonderimDurumu')}
                  onChange={event => handleSearchChange('gonderimDurumuId', event)}
                >
                  <MenuItem value="">
                    <Translate contentKey="entity.action.all" />
                  </MenuItem>
                  {gonderimDurumuList &&
                    gonderimDurumuList.length > 0 &&
                    gonderimDurumuList.map(item => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.adi}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Box>
            <ExcelDownloadButton
              excelSampleData={ExcelSampleData}
              grantedAuthorites={['kriz_ivr_bildirim_export']}
              fetchData={fetchTotalKrizIvrBildirimData}
              className={'download-button'}
              exportName={translate('tk24AdysApp.krizIvrBildirim.home.title')}
            />
          </div>
        </div>
        <TableContainer className="content-table">
          {krizIvrBildirimList && krizIvrBildirimList.length > 0 ? (
            <Table aria-label="a dense table">
              <TableHead className="content-table-title-area">
                <TableRow className="table-head-row">
                  <TableCell align="left" className="table-title-cell hand" onClick={sort(`krizKatilimci.vekilKatilimci.katilimci.adi`)}>
                    <div className="tableHeaderContainer">
                      <Translate contentKey="tk24AdysApp.krizKatilimci.asilTitle">ASIL</Translate>
                      <SortIcon column={`krizKatilimci.vekilKatilimci.katilimci.adi`} activeSortColumn={pageObject.sort} />
                    </div>
                  </TableCell>
                  <TableCell align="left" className="table-title-cell hand" onClick={sort('krizKatilimci.adi')}>
                    <div className="tableHeaderContainer">
                      <Translate contentKey="tk24AdysApp.krizIvrBildirim.katilimciBilgisiTitle">Katılımcı Bilgisi</Translate>&nbsp;
                      <SortIcon column="krizKatilimci.adi" activeSortColumn={pageObject.sort} />
                    </div>
                  </TableCell>
                  <TableCell
                    align="left"
                    className="table-title-cell hand"
                    onClick={sort(`krizKatilimci.katilimciRolu.adi${upperOnlyFirstLetter(localeLanguage)}`)}
                  >
                    <div className="tableHeaderContainer">
                      <Translate contentKey="tk24AdysApp.krizKatilimci.katilimciRoluTitle">Katılımcı Rolu</Translate>&nbsp;
                      <SortIcon
                        column={`krizKatilimci.katilimciRolu.adi${upperOnlyFirstLetter(localeLanguage)}`}
                        activeSortColumn={pageObject.sort}
                      />
                    </div>
                  </TableCell>

                  <TableCell align="left" className="table-title-cell hand" onClick={sort('ivrAramaSebebi')}>
                    <div className="tableHeaderContainer">
                      <Translate contentKey="tk24AdysApp.krizIvrBildirim.aramaSebebi">Arama Sebebi</Translate>&nbsp;
                      <SortIcon column="ivrAramaSebebi" activeSortColumn={pageObject.sort} />
                    </div>
                  </TableCell>
                  <TableCell align="left" className="table-title-cell hand" onClick={sort(`icerik${upperOnlyFirstLetter(localeLanguage)}`)}>
                    <div className="tableHeaderContainer">
                      <Translate contentKey="tk24AdysApp.krizIvrBildirim.icerik">İçerik</Translate>&nbsp;
                      <SortIcon column={`icerik${upperOnlyFirstLetter(localeLanguage)}`} activeSortColumn={pageObject.sort} />
                    </div>
                  </TableCell>
                  <TableCell align="left" className="table-title-cell hand" onClick={sort('ivrTalep')}>
                    <div className="tableHeaderContainer">
                      <Translate contentKey="tk24AdysApp.krizIvrBildirim.ivrTalep">ivrTalep</Translate>&nbsp;
                      <SortIcon column="ivrTalep" activeSortColumn={pageObject.sort} />
                    </div>
                  </TableCell>
                  <TableCell align="left" className="table-title-cell hand" onClick={sort('ivrCevapKacDkSonra')}>
                    <div className="tableHeaderContainer">
                      <Translate contentKey="tk24AdysApp.krizIvrBildirim.cevapSureTitle">Cevap Kaç Dakika Sonra Verildi</Translate>&nbsp;
                      <SortIcon column="ivrCevapKacDkSonra" activeSortColumn={pageObject.sort} />
                    </div>
                  </TableCell>
                  <TableCell align="left" className="table-title-cell hand" onClick={sort('ivrCevapKatilimDurumu')}>
                    <div className="tableHeaderContainer">
                      <Translate contentKey="tk24AdysApp.krizIvrBildirim.cevapKatilimDurumuTitle">IVR Cevap Katılım Durumu</Translate>&nbsp;
                      <SortIcon column="ivrCevapKatilimDurumu" activeSortColumn={pageObject.sort} />
                    </div>
                  </TableCell>
                  <TableCell align="left" className="table-title-cell hand" onClick={sort('aramaZamani')}>
                    <div className="tableHeaderContainer">
                      <Translate contentKey="tk24AdysApp.krizIvrBildirim.gonderimZamaniTitle">Arama Zamani</Translate>&nbsp;
                      <SortIcon column="aramaZamani" activeSortColumn={pageObject.sort} />
                    </div>
                  </TableCell>
                  <TableCell align="left" className="table-title-cell hand" onClick={sort('denemeSayisi')}>
                    <div className="tableHeaderContainer">
                      <Translate contentKey="tk24AdysApp.krizIvrBildirim.denemeSayisi">denemeSayisi</Translate>&nbsp;
                      <SortIcon column="denemeSayisi" activeSortColumn={pageObject.sort} />
                    </div>
                  </TableCell>
                  <TableCell align="left" className="table-title-cell hand" onClick={sort('gonderimDurumuId')}>
                    <div className="tableHeaderContainer">
                      <Translate contentKey="tk24AdysApp.krizIvrBildirim.gonderimDurumus">Gönderim Durumu</Translate>&nbsp;
                      <SortIcon column="gonderimDurumuId" activeSortColumn={pageObject.sort} />
                    </div>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {krizIvrBildirimList.map(krizIvrBildirimItem => (
                  <TableRow key={krizIvrBildirimItem.id} className="table-cell-row">
                    <TableCell component="th" scope="row" className="table-content-cell">
                      {krizIvrBildirimItem.krizKatilimci?.vekilKatilimci?.katilimci?.adi}{' '}
                      {krizIvrBildirimItem.krizKatilimci?.vekilKatilimci?.katilimci?.soyadi}
                    </TableCell>
                    <TableCell component="th" scope="row" className="table-content-cell">
                      {krizIvrBildirimItem.krizKatilimci && (
                        <>
                          {krizIvrBildirimItem.krizKatilimci.adi} {krizIvrBildirimItem.krizKatilimci.soyadi} <br />
                          {krizIvrBildirimItem.krizKatilimci.unvanAdi}
                        </>
                      )}
                    </TableCell>
                    <TableCell component="th" scope="row" className="table-content-cell">
                      {krizIvrBildirimItem.krizKatilimci?.katilimciRolu?.adi}
                    </TableCell>

                    <TableCell component="th" scope="row" className="table-content-cell">
                      {krizIvrBildirimItem.ivrAramaSebebi && krizIvrBildirimItem.ivrAramaSebebi.adi}
                    </TableCell>
                    <TableCell component="th" scope="row" className="table-content-cell">
                      {krizIvrBildirimItem.icerik}
                    </TableCell>
                    <TableCell component="th" scope="row" className="table-content-cell">
                      {krizIvrBildirimItem.ivrTalep}
                    </TableCell>
                    <TableCell component="th" scope="row" className="table-content-cell">
                      {krizIvrBildirimItem.ivrCevapKacDkSonra ? (
                        <>
                          {krizIvrBildirimItem.ivrCevapKacDkSonra}{' '}
                          <Translate contentKey="tk24AdysApp.krizIvrBildirim.dakika"> DK</Translate>
                        </>
                      ) : (
                        <></>
                      )}
                    </TableCell>
                    <TableCell component="th" scope="row" className="table-content-cell">
                      {krizIvrBildirimItem.ivrCevapKatilimDurumu && krizIvrBildirimItem.ivrCevapKatilimDurumu.adi}
                    </TableCell>
                    <TableCell component="th" scope="row" className="table-content-cell">
                      {krizIvrBildirimItem.aramaZamani ? (
                        <TextFormat type="date" value={krizIvrBildirimItem.aramaZamani} format={APP_DATE_FORMAT} />
                      ) : null}
                    </TableCell>
                    <TableCell component="th" scope="row" className="table-content-cell">
                      {krizIvrBildirimItem.denemeSayisi}
                    </TableCell>
                    <TableCell component="th" scope="row" className="table-content-cell">
                      {krizIvrBildirimItem.gonderimDurumu && krizIvrBildirimItem.gonderimDurumu.adi}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : (
            !loading &&
            !updating && (
              <div className="alert alert-warning">
                <Translate contentKey="tk24AdysApp.krizIvrBildirim.home.notFound">No Ref IVR Cevap Katilim Durumu found</Translate>
              </div>
            )
          )}
        </TableContainer>
        {totalItem ? (
          <div className={krizIvrBildirimList && krizIvrBildirimList.length > 0 ? '' : 'd-none'}>
            <CustomPagination
              currentPage={pageObject.page + 1}
              currentPageSize={pageObject.size}
              totalItems={totalItem}
              handlePageChange={handlePageChange}
            />
          </div>
        ) : (
          ''
        )}
      </div>
    </KrizMain>
  );
};

export default KrizIvrBildirim;
