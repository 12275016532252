import { translate, Translate } from 'app/component/jhipster';
import { SERVER_URL } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import React, { useState, useEffect, useCallback } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import {
  Alert,
  Button,
  Col,
  Form,
  Modal,
  ModalBody,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  Table,
  TabPane,
} from 'reactstrap';
import { getEntities as getSablonGrubuEntities } from 'app/entities/ref-sablon-parametre-grubu/ref-sablon-parametre-grubu.reducer';
import { getEntities as getReferansEntities } from 'app/entities/referans/referans.reducer';
import { localeLanguage, upperOnlyFirstLetter } from 'app/shared/util/util';
import { LoadingSpinner } from 'app/component/LoadingSpinner';
import axios from 'axios';
import { TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { FormItem } from 'app/component/FormItem';
import { CustomSaveEntityConfirm, isNullOrEmpty } from 'app/shared/util/confirm-utils';
import { ReferansEnum } from 'app/constants/ReferansEnum';
import RichTextEditor from 'app/component/RichTextEditor';
import classnames from 'classnames';
import Katilimci from './katilimci';
import Vekil from './vekil';
import MudurlukPersoneli from './mudurluk-personeli';
import Personel from './personel';
import AdtPersonel from './adt-personel';
import { toast } from 'react-toastify';

export const BildirimYonetimiUpdate = (props: RouteComponentProps<{ url: string }>) => {
  const dispatch = useAppDispatch();

  const refSablonParametreGrubuEntities = useAppSelector(state => state.refSablonParametreGrubu.entities);
  const refSablonParametreGrubuLoading = useAppSelector(state => state.refSablonParametreGrubu.loading);
  const [sablonParameters, setSablonParameters] = useState(null);
  const [bildirimTuruId, setBildirimTuruId] = useState(null);
  const referans = useAppSelector(state => state.referans.entities);
  const referansLoading = useAppSelector(state => state.referans.loading);
  const bildirimTuruList = referans.filter(
    res => res.id === ReferansEnum.BildirimTuru.EPosta || res.id === ReferansEnum.BildirimTuru.Sms
    // || res.id === ReferansEnum.BildirimTuru.Crysis
  );

  const [aliciList, setAliciList] = useState([]);
  const [isAliciListModal, setIsAliciListModal] = useState(false);
  const [valueTab, setValueTab] = React.useState(0);

  const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);
  const [modalPromiseResolve, setModalPromiseResolve] = useState(null);
  const [confirmText, setConfirmText] = useState(null);
  const [saveLoading, setSaveLoading] = useState(false);

  useEffect(() => {
    dispatch(
      getSablonGrubuEntities({
        size: 1,
        query: 'sinifAdi.equals=BildirimYonetimi',
        page: 0,
        sort: `adi${upperOnlyFirstLetter(localeLanguage)},asc`,
      })
    );
    dispatch(getReferansEntities({ size: 999, page: 0, sort: `adi${upperOnlyFirstLetter(localeLanguage)},asc` }));
  }, []);

  useEffect(() => {
    if (refSablonParametreGrubuEntities) {
      const ekipUrl = `api/ref-sablon-parametres?sablonParametreGrubuId.equals=${refSablonParametreGrubuEntities[0]?.id}`;
      axios.get(ekipUrl).then(response => {
        setSablonParameters(response.data);
      });
    }
  }, [refSablonParametreGrubuEntities]);

  const copyToClipboard = text => {
    navigator.clipboard.writeText(text).catch(err => {
      console.error('Panoya kopyalama hatası:', err);
    });
  };
  const [errorMessage, setErrorMessage] = useState({ visible: false, message: '' });

  const saveEntity = async values => {
    values.preventDefault();
    if (!aliciList || aliciList.length === 0) {
      setErrorMessage({ visible: true, message: translate('tk24AdysApp.bildirimYonetimi.confirm.aliciListe') });
    } else {
      const formDataObject = {};
      for (const field of values.target.elements) {
        formDataObject[field.name] = field.value;
      }

      const recieverDTOList = aliciList.map(item => ({
        iletisimBilgisi: bildirimTuruId.toString() === ReferansEnum.BildirimTuru.EPosta.toString() ? item.eposta : item.telefon,
        sicil: item.sicil,
      }));

      const emptyContactCount = recieverDTOList.filter(item => isNullOrEmpty(item.iletisimBilgisi)).length;

      if (emptyContactCount > 0) {
        setConfirmText(
          <Translate
            contentKey="tk24AdysApp.bildirimYonetimi.confirm.message"
            interpolate={{
              count: emptyContactCount,
              type: translate(
                `${
                  bildirimTuruId.toString() === ReferansEnum.BildirimTuru.EPosta.toString()
                    ? 'tk24AdysApp.bildirimYonetimi.eposta'
                    : 'tk24AdysApp.bildirimYonetimi.gsmNo'
                }`
              ),
            }}
          />
        );
      } else {
        setConfirmText(<Translate contentKey="entity.confirm.question" />);
      }

      const isSave = await showSaveConfirmModal();

      if (isSave && recieverDTOList.filter(item => !isNullOrEmpty(item.iletisimBilgisi)).length > 0) {
        setSaveLoading(true);

        const entity = {
          manuelBildirimDTO: {
            ...formDataObject,
            bildirimTuru: bildirimTuruList.find(item => item.id.toString() === bildirimTuruId.toString()),
          },
          recieverDTOList: [...recieverDTOList.filter(item => !isNullOrEmpty(item.iletisimBilgisi))],
        };

        await axios
          .post(`api/manuel-bildirims-with-list`, entity)
          .then(() => {
            toast.success(translate('tk24AdysApp.bildirimYonetimi.created'));
            resetFormField(values);
          })
          .catch(error => {
            toast.error(error.message);
          });

        setSaveLoading(false);
      }
    }
  };

  const resetFormField = values => {
    setBildirimTuruId('');

    for (const field of values.target.elements) {
      if (field.value) {
        field.value = null;
      }
    }
  };

  const showSaveConfirmModal = () => {
    setIsSaveModalOpen(true);
    return new Promise(resolve => {
      setModalPromiseResolve(() => resolve);
    });
  };

  const handleBildirimTuruChange = event => {
    if (event.target.value) {
      setBildirimTuruId(parseInt(event.target.value, 10));
    } else {
      setBildirimTuruId(null);
    }
  };

  const handleClose = () => {
    setIsAliciListModal(false);
  };

  const updateAliciList = items => {
    onAlertMessageDismiss();

    const newList = [...aliciList, ...items.filter(item => !aliciList.some(mevcutItem => mevcutItem.sicil === item.sicil))];

    setAliciList(newList);

    handleClose();
  };

  const removeAliciList = item => {
    const selectedIndex = aliciList.findIndex(alici => item.sicil === alici.sicil);
    let newSelected = [];

    if (selectedIndex === 0) {
      newSelected = newSelected.concat(aliciList.slice(1));
    } else if (selectedIndex === aliciList.length - 1) {
      newSelected = newSelected.concat(aliciList.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(aliciList.slice(0, selectedIndex), aliciList.slice(selectedIndex + 1));
    }

    setAliciList(newSelected);
  };

  const AliciListModal = () => {
    return (
      <Modal isOpen={isAliciListModal} toggle={handleClose} className="modal-style-lg" centered>
        <ModalHeader data-cy="refEntityDetailsHeading" toggle={handleClose}>
          <Translate contentKey="tk24AdysApp.bildirimYonetimi.aliciListe" />
        </ModalHeader>
        <ModalBody>
          <Nav className="custom-nav-tabs" tabs>
            <NavItem className="custom-nav-item">
              <NavLink
                className={classnames('custom-nav-link', { active: valueTab === 0 })}
                onClick={() => {
                  setValueTab(0);
                }}
              >
                <Translate contentKey="tk24AdysApp.bildirimYonetimi.katilimci" />
              </NavLink>
            </NavItem>
            <NavItem className="custom-nav-item">
              <NavLink
                className={classnames('custom-nav-link', { active: valueTab === 1 })}
                onClick={() => {
                  setValueTab(1);
                }}
              >
                <Translate contentKey="tk24AdysApp.bildirimYonetimi.vekil" />
              </NavLink>
            </NavItem>
            <NavItem className="custom-nav-item">
              <NavLink
                className={classnames('custom-nav-link', { active: valueTab === 2 })}
                onClick={() => {
                  setValueTab(2);
                }}
              >
                <Translate contentKey="tk24AdysApp.bildirimYonetimi.mudurlukPersoneli" />
              </NavLink>
            </NavItem>
            <NavItem className="custom-nav-item">
              <NavLink
                className={classnames('custom-nav-link', { active: valueTab === 3 })}
                onClick={() => {
                  setValueTab(3);
                }}
              >
                <Translate contentKey="tk24AdysApp.bildirimYonetimi.personel" />
              </NavLink>
            </NavItem>
            <NavItem className="custom-nav-item">
              <NavLink
                className={classnames('custom-nav-link', { active: valueTab === 4 })}
                onClick={() => {
                  setValueTab(4);
                }}
              >
                <Translate contentKey="tk24AdysApp.bildirimYonetimi.adt" />
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={valueTab}>
            <TabPane key={0} tabId={0}>
              {valueTab === 0 && <Katilimci dataToCompare={aliciList} updateAliciList={updateAliciList} />}
            </TabPane>
            <TabPane key={1} tabId={1}>
              {valueTab === 1 && <Vekil dataToCompare={aliciList} updateAliciList={updateAliciList} />}
            </TabPane>
            <TabPane key={2} tabId={2}>
              {valueTab === 2 && <MudurlukPersoneli dataToCompare={aliciList} updateAliciList={updateAliciList} />}
            </TabPane>
            <TabPane key={3} tabId={3}>
              {valueTab === 3 && <Personel dataToCompare={aliciList} updateAliciList={updateAliciList} />}
            </TabPane>
            <TabPane key={4} tabId={4}>
              {valueTab === 4 && <AdtPersonel dataToCompare={aliciList} updateAliciList={updateAliciList} />}
            </TabPane>
          </TabContent>
        </ModalBody>
      </Modal>
    );
  };

  const handleConfirm = () => {
    if (modalPromiseResolve) {
      modalPromiseResolve(true);
    }
    setIsSaveModalOpen(false);
  };

  const handleCancel = () => {
    if (modalPromiseResolve) {
      modalPromiseResolve(false);
    }
    setIsSaveModalOpen(false);
  };

  const onAlertMessageDismiss = () => {
    setErrorMessage({ visible: false, message: '' });
  };

  return (
    <div>
      {(refSablonParametreGrubuLoading || referansLoading || saveLoading) && <LoadingSpinner />}
      {isAliciListModal && AliciListModal()}
      <CustomSaveEntityConfirm
        entity={null}
        isOpen={isSaveModalOpen}
        onConfirm={handleConfirm}
        onCancel={handleCancel}
        confirmText={confirmText}
      />

      <h2 id="rol-heading" data-cy="RolDokumanHeading" className="title-frame">
        <div className="excel-export-frame">
          <p className="sub-title-text">
            <Translate contentKey="global.menu.parameters.bildirimYonetimi" />
          </p>
        </div>
      </h2>

      <div className="content-area">
        <Form onSubmit={saveEntity}>
          <FormItem
            label={translate('tk24AdysApp.bildirimSablon.bildirimTuru')}
            id="bildirim-turu"
            name="bildirimTuruId"
            type="select"
            value={bildirimTuruId}
            onChange={handleBildirimTuruChange}
            validation={{
              required: { value: true, message: translate('entity.validation.required') },
            }}
          >
            <option value="" key="0" />
            {bildirimTuruList
              ? bildirimTuruList.map(otherEntity => (
                  <option key={otherEntity.id} value={otherEntity.id}>
                    {otherEntity.adi}
                  </option>
                ))
              : null}
          </FormItem>

          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <p className="input-label">
              <Translate contentKey="tk24AdysApp.bildirimYonetimi.aliciListe" /> <span className="required">*</span>
            </p>

            <Button className="create-button" type="button" onClick={() => setIsAliciListModal(true)}>
              <img width={14} height={14} src={`${SERVER_URL}/content/images/icon/faPlus.svg`} />
              <span className="d-none d-md-inline">
                <Translate contentKey="tk24AdysApp.bildirimYonetimi.aliciEkle" />
              </span>
            </Button>
          </div>

          {errorMessage && (
            <Alert style={{ marginTop: '5px' }} color="danger" isOpen={errorMessage.visible} toggle={onAlertMessageDismiss}>
              {errorMessage.message}
            </Alert>
          )}

          {aliciList && aliciList.length > 0 ? (
            <div className="table-responsive">
              <span className="modal-clear" onClick={() => setAliciList([])}>
                <Translate contentKey="tk24AdysApp.bildirimYonetimi.aliciSil" />
              </span>
              <Typography className="item-total-count" gutterBottom component="div">
                <span className="totalListItemText">
                  <Translate contentKey="global.item-total-count" interpolate={{ total: aliciList?.length }} />
                </span>
              </Typography>
              <TableContainer className="content-table" style={{ maxHeight: '400px', overflowY: 'auto' }}>
                <Table aria-label="a dense table" stickyHeader>
                  <TableHead className="content-table-title-area">
                    <TableRow className="table-head-row">
                      <TableCell className="table-title-cell-kriz-create" align="left">
                        <div className="tableHeaderContainer">
                          <Translate contentKey="tk24AdysApp.bildirimYonetimi.aliciListeTitle" />
                          &nbsp;
                        </div>
                      </TableCell>

                      <TableCell className="table-title-cell-kriz-create" align="left">
                        <div className="tableHeaderContainer">
                          <Translate contentKey="tk24AdysApp.bildirimYonetimi.sicilTitle" />
                          &nbsp;
                        </div>
                      </TableCell>

                      <TableCell className="table-title-cell-kriz-create" align="left">
                        <div className="tableHeaderContainer">
                          <Translate contentKey="tk24AdysApp.bildirimYonetimi.adi" />
                          &nbsp;
                        </div>
                      </TableCell>

                      <TableCell className="table-title-cell-kriz-create" align="left">
                        <div className="tableHeaderContainer">
                          <Translate contentKey="tk24AdysApp.bildirimYonetimi.soyadi" />
                          &nbsp;
                        </div>
                      </TableCell>

                      <TableCell className="table-title-cell-kriz-create" align="left">
                        <div className="tableHeaderContainer">
                          <Translate contentKey="tk24AdysApp.bildirimYonetimi.gsmNo" />
                          &nbsp;
                        </div>
                      </TableCell>

                      <TableCell className="table-title-cell-kriz-create" align="left">
                        <div className="tableHeaderContainer">
                          <Translate contentKey="tk24AdysApp.bildirimYonetimi.epostaTitle" />
                          &nbsp;
                        </div>
                      </TableCell>

                      <TableCell className="table-title-cell-kriz-create" align="left" />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {aliciList.map(alici => {
                      return (
                        <TableRow key={alici?.scil} className={`table-cell-row`}>
                          <TableCell className="table-content-cell" component="th" scope="row">
                            {alici?.type}
                          </TableCell>

                          <TableCell className="table-content-cell" component="th" scope="row">
                            {alici?.sicil}
                          </TableCell>

                          <TableCell className="table-content-cell" component="th" scope="row">
                            {alici?.adi}
                          </TableCell>

                          <TableCell className="table-content-cell" component="th" scope="row">
                            {alici?.soyadi}
                          </TableCell>

                          <TableCell className="table-content-cell" component="th" scope="row">
                            {alici?.telefon}
                          </TableCell>

                          <TableCell className="table-content-cell" component="th" scope="row">
                            {alici?.eposta}
                          </TableCell>

                          <TableCell align="right" className="table-content-cell" component="th" scope="row">
                            <Button className="delete-button" size="sm" onClick={() => removeAliciList(alici)}>
                              <img width={11.2} height={12.8} src={`${SERVER_URL}/content/images/icon/faTrashCan.svg`} />
                              <span>
                                <Translate contentKey="entity.action.delete">Delete</Translate>
                              </span>{' '}
                            </Button>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          ) : (
            <div className="alert alert-warning" style={{ marginTop: '5px' }}>
              <Translate contentKey="tk24AdysApp.bildirimYonetimi.home.notFoundAlici" />
            </div>
          )}

          {!isNullOrEmpty(bildirimTuruId) && (
            <>
              {sablonParameters && (
                <TableContainer className="content-table mb-3" style={{ maxHeight: '300px' }}>
                  <Table aria-label="a dense table">
                    <TableHead className="content-table-title-area">
                      <TableRow className="table-head-row">
                        <TableCell className="table-title-cell hand" style={{ width: 570 }} align="left">
                          <Translate contentKey="global.languageGlobal.code">Kod</Translate>&nbsp;
                        </TableCell>
                        <TableCell className="table-title-cell hand" style={{ width: 570 }} align="left">
                          <Translate contentKey="global.languageGlobal.adi">Adi</Translate>&nbsp;
                        </TableCell>
                        <TableCell className="table-title-cell hand" style={{ width: 570 }} align="left">
                          <Translate contentKey="global.languageGlobal.aciklama">Aciklama</Translate>&nbsp;
                        </TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {sablonParameters.map(sablonParameter => (
                        <TableRow key={sablonParameter.id} className="table-cell-row">
                          <TableCell className="table-content-cell" component="th" scope="row">
                            {sablonParameter.parametreKodu}
                          </TableCell>
                          <TableCell className="table-content-cell" component="th" scope="row">
                            {sablonParameter.adiTr}
                          </TableCell>
                          <TableCell className="table-content-cell" component="th" scope="row">
                            {sablonParameter.aciklamaTr}
                          </TableCell>
                          <TableCell className="table-content-cell" align="right">
                            <div className="btn-group flex-btn-group-container">
                              <Button
                                style={{ marginLeft: 15 }}
                                onClick={() => {
                                  copyToClipboard(sablonParameter.parametreKodu);
                                }}
                                className="create-button clonla"
                                size="sm"
                                data-cy="entityCreateButton"
                              >
                                <img width={14} height={14} src={`${SERVER_URL}/content/images/icon/copy.svg`} />
                                <span className="d-none d-md-inline">
                                  <Translate contentKey="entity.action.klonla" />
                                </span>
                              </Button>
                            </div>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}

              {bildirimTuruId === ReferansEnum.BildirimTuru.EPosta && (
                <Row>
                  <Col md={6}>
                    <FormItem
                      label={translate('tk24AdysApp.bildirimSablon.baslikKonuTr')}
                      id="bildirim-sablonu-konu"
                      name="konuTr"
                      type="text"
                      validation={{
                        required: { value: true, message: translate('entity.validation.required') },
                        maxLength: { value: 100, message: translate('entity.validation.maxlength', { max: 100 }) },
                      }}
                    />
                  </Col>
                  <Col md={6}>
                    <FormItem
                      label={translate('tk24AdysApp.bildirimSablon.baslikKonuEn')}
                      id="bildirim-sablonu-konu"
                      name="konuEn"
                      type="text"
                    />
                  </Col>
                </Row>
              )}

              <Row>
                <Col md={6}>
                  {bildirimTuruId === ReferansEnum.BildirimTuru.EPosta ? (
                    <RichTextEditor
                      label={translate('tk24AdysApp.bildirimSablon.icerikTr')}
                      id="bildirim-sablonu-icerikTr"
                      name="icerikTr"
                      validation={{
                        required: { value: true, message: translate('entity.validation.required') },
                        maxLength: { value: 1500, message: translate('entity.validation.maxlength', { max: 1500 }) },
                      }}
                    />
                  ) : (
                    <FormItem
                      label={translate('tk24AdysApp.bildirimSablon.icerikTr')}
                      id="bildirim-sablonu-icerikTr"
                      name="icerikTr"
                      type="textarea"
                      validation={{
                        required: { value: true, message: translate('entity.validation.required') },
                        maxLength: { value: 1500, message: translate('entity.validation.maxlength', { max: 1500 }) },
                        rows: { value: 9 },
                      }}
                    />
                  )}
                </Col>
                <Col md={6}>
                  {' '}
                  {bildirimTuruId === ReferansEnum.BildirimTuru.EPosta ? (
                    <RichTextEditor
                      label={translate('tk24AdysApp.bildirimSablon.icerikEn')}
                      id="bildirim-sablonu-icerikEn"
                      name="icerikEn"
                      validation={{
                        maxLength: { value: 1500, message: translate('entity.validation.maxlength', { max: 1500 }) },
                      }}
                    />
                  ) : (
                    <FormItem
                      label={translate('tk24AdysApp.bildirimSablon.icerikEn')}
                      id="bildirim-sablonu-icerikEn"
                      name="icerikEn"
                      type="textarea"
                      validation={{
                        maxLength: { value: 1500, message: translate('entity.validation.maxlength', { max: 1500 }) },
                        rows: { value: 9 },
                      }}
                    />
                  )}{' '}
                </Col>
              </Row>
            </>
          )}

          <FormItem
            label={translate('global.languageGlobal.aciklama')}
            id="bildirim-sablonu-aciklama"
            name="aciklama"
            type="textarea"
            validation={{
              maxLength: { value: 1500, message: translate('entity.validation.maxlength', { max: 1500 }) },
              rows: { value: 4 },
            }}
          />

          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button className="save-button" id="jhi-confirm-delete-refAdtForm" type="submit" data-cy="entityConfirmButton">
              <Translate contentKey="entity.action.save">Kaydet</Translate>
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default BildirimYonetimiUpdate;
