import React, { useState } from 'react';
import { Row, Col, Nav, NavItem, NavLink, TabContent, TabPane, Container, Button } from 'reactstrap';
import { TextFormat, Translate } from 'app/component/jhipster';
import { IRefUcak } from 'app/shared/model/ref-ucak.model';
import { APP_DATE_FORMAT, SERVER_URL } from 'app/config/constants';
import { handleDownloadSavedFile } from 'app/shared/util/util';
import { DriveFolderUpload } from '@mui/icons-material';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getFilteredEntities as getFilterUcakEkDosyaEntities, reset as resetEkDosyaEntity } from 'app/entities/ek-dosya/ek-dosya.reducer';
import TableNameEnum from 'app/constants/TableNameEnum';
import { LoadingSpinner } from 'app/component/LoadingSpinner';

interface RefUcakDetailProp {
  refUcak: IRefUcak;
}

export const RefUcakDetail: React.FC<RefUcakDetailProp> = ({ refUcak }) => {
  const [activeTab, setActiveTab] = useState(1);
  const dispatch = useAppDispatch();
  const ucakEkDosyaList = useAppSelector(state => state.ekDosya.entities);
  const ucakEkDosyaLoading = useAppSelector(state => state.ekDosya.loading);

  const getEkDosya = () => {
    dispatch(
      getFilterUcakEkDosyaEntities({
        ilgiliTabloKayitId: refUcak?.realId,
        ilgiliTablo: TableNameEnum.RefUcak,
      })
    );
  };

  const Detay = () => {
    return (
      <Container className="modal-container-area">
        <Row>
          <Col sm={4}>
            <p className="detailKeyStyle">
              <Translate contentKey="tk24AdysApp.refUcak.bilgiSonGuncellenmeZamani" />
            </p>
          </Col>
          <Col sm={8}>
            <p className="detailValStyle">
              {refUcak.bilgiSonGuncellenmeZamani ? (
                <TextFormat type="date" value={refUcak.bilgiSonGuncellenmeZamani} format={APP_DATE_FORMAT} />
              ) : null}
            </p>
          </Col>
        </Row>
      </Container>
    );
  };

  return (
    <div>
      {ucakEkDosyaLoading && <LoadingSpinner />}
      <Nav tabs>
        <NavItem>
          <NavLink
            style={{ color: activeTab === 1 ? '#1175D2' : '#607083', borderBottom: activeTab === 1 && '2px solid #1175D2' }}
            onClick={() => {
              setActiveTab(1);
            }}
          >
            <Translate contentKey="tk24AdysApp.refUcak.detay"> Detay </Translate>
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane key={1} tabId={1}>
          <Detay />
        </TabPane>
      </TabContent>
    </div>
  );
};

export default RefUcakDetail;
