import React, { useEffect, useState } from 'react';
import 'app/entities/style.scss';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { Translate } from 'app/component/jhipster';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import InfoTable from 'app/entities/infoTable';
import GorevAdtKatilimci from '../kriz-adt-gorevler/gorev-adt-katlimci';
import axios from 'axios';
import { getEntities as getReferans } from 'app/entities/referans/referans.reducer';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { Row, Col } from 'reactstrap';
import { KrizMain } from 'app/component/KrizMain';
import { Box, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { MenuProps } from 'app/shared/util/filter.constants';
import { getEntityBySicilId } from '../kriz-katilimci/kriz-katilimci.reducer';
import classnames from 'classnames';
import GorevAdtTim from '../kriz-adt-gorevler/gorev-adt-tim';
import { IRefAdtEkip } from 'app/shared/model/ref-adt-ekip.model';
import { LoadingSpinner } from 'app/component/LoadingSpinner';
import { validationKrizAdtEkip } from 'app/shared/util/get-custom-service';
import { IKrizAdtEkip } from 'app/shared/model/kriz-adt-ekip.model';

interface GorevComponentProp extends RouteComponentProps<{ id: string }> {
  sicilId?: string;
}

const Gorevler = (props: GorevComponentProp) => {
  const { match } = props;
  const { sicilId } = props;
  const dispatch = useAppDispatch();
  const isDrawer = useAppSelector(state => state.krizChat.isDrawer);

  const [selectedAdtEkip, setSelectedAdtEkip] = useState(null);
  const [adtEkipList, setAdtEkipList] = useState<IKrizAdtEkip[]>(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const krizKatilimciEntity = useAppSelector(state => state.krizKatilimci.entity);
  const [activeTab, setActiveTab] = useState(1);
  const [adtEkipLoading, setAdtEkipLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch(getReferans({ page: 0, size: 10000, sort: 'asc' }));
        dispatch(getEntityBySicilId({ krizId: props.match.params.id, sicilId }));

        const krizEkip = await validationKrizAdtEkip(props.match.params.id);
        setAdtEkipList(krizEkip);
      } catch (error) {
        console.error('Error fetching data:', error);
        setAdtEkipLoading(false);
      }
    };
    fetchData();
  }, []);

  const titleTask = sicilId ? 'tk24AdysApp.krizDetay.adtGorevlerim' : 'tk24AdysApp.krizDetay.adtTumGorevler';
  const getSize = () => (window.innerWidth < 1600 ? 'small' : 'medium');
  return (
    <KrizMain isDrawer={isDrawer}>
      <div style={{ marginBottom: 5 }}>
        <InfoTable id={match.params.id} titleContentKey={titleTask} />
      </div>
      {adtEkipLoading && <LoadingSpinner />}
      <Row>
        <Col md={12}>
          <div className="d-flex align-items-center">
            <Box sx={{ minWidth: 640, backgroundColor: '#FFFFFF' }} className="mt-3 mb-3">
              <FormControl fullWidth size={getSize()}>
                <InputLabel id="default-krizKatilimci-label" className="select-input">
                  {' '}
                  <Translate contentKey="tk24AdysApp.refAdtEkip.home.title" />
                </InputLabel>
                <Select
                  labelId="krizAdtEkip-label"
                  id="krizAdtEkip"
                  label="krizAdtEkip Listesi"
                  MenuProps={MenuProps}
                  className="select-input"
                >
                  <MenuItem
                    key={'all'}
                    value={null}
                    onClick={() => {
                      setActiveTab(1);
                      setSelectedAdtEkip(null);
                    }}
                  >
                    <Translate contentKey="entity.action.all">All</Translate>
                  </MenuItem>
                  {adtEkipList &&
                    adtEkipList.map(ekip => (
                      <MenuItem key={ekip.adtEkip.id} value={ekip.adtEkip.id} onClick={() => setSelectedAdtEkip(ekip)}>
                        {ekip.adtEkip.adi}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Box>
          </div>

          <div>
            <Nav tabs className="tabAdtGorev">
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === 1 })}
                  onClick={() => {
                    setActiveTab(1);
                  }}
                >
                  <Translate contentKey="tk24AdysApp.krizAdtEkip.timGorevleri" />
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  disabled={selectedAdtEkip === null}
                  className={classnames({ active: activeTab === 2 })}
                  onClick={() => {
                    setActiveTab(2);
                  }}
                >
                  <Translate contentKey="tk24AdysApp.krizAdtEkip.benimGorevlerim" />
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={activeTab}>
              <TabPane key={1} tabId={1}>
                <GorevAdtTim selectedAdtEkip={selectedAdtEkip} activeTab={activeTab} {...props} />
              </TabPane>
              <TabPane key={2} tabId={2} disabled>
                <GorevAdtKatilimci selectedAdtEkip={selectedAdtEkip} activeTab={activeTab} {...props} />
              </TabPane>
            </TabContent>
          </div>
        </Col>
      </Row>
    </KrizMain>
  );
};

export default Gorevler;
