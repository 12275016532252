import React, { useState, useEffect } from 'react';
import { Translate } from 'app/component/jhipster';
import { createEntity, deleteEntity, getEntities } from './ref-gorev-sor-birim.reducer';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { IRefGorev } from 'app/shared/model/ref-gorev.model';
import { deleteEntityConfirm } from 'app/shared/util/confirm-utils';
import GrantedButton from 'app/component/GrantedButton';
import { SERVER_URL } from 'app/config/constants';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { LoadingSpinner } from 'app/component/LoadingSpinner';
import { IQueryParams } from 'app/shared/reducers/reducer.utils';

interface RefGorevSorBirimProp {
  refGorev: IRefGorev;
}

export const RefGorevSorBirim: React.FC<RefGorevSorBirimProp> = ({ refGorev }) => {
  const dispatch = useAppDispatch();
  const [isGorevSorBirimUpdateModal, setIsGorevSorBirimUpdateModal] = useState(false);

  const gorevSorBirimList = useAppSelector(state => state.refGorevSorBirim.entities);
  const loading = useAppSelector(state => state.refGorevSorBirim.loading);
  const updating = useAppSelector(state => state.refGorevSorBirim.updating);
  const pageObject: IQueryParams = {
    ...useAppSelector(state => state.paginate.pageObject),
    size: 2000,
    query: `gorevId.equals=${refGorev.id}`,
  };

  useEffect(() => {
    onLoadSorBirim();
  }, []);

  const onLoadSorBirim = () => {
    dispatch(getEntities(pageObject));
  };

  const confirmSorBirimDelete = async gorevSorBirim => {
    const isDel = await deleteEntityConfirm(gorevSorBirim.id, 'refGorevSorBirim');
    if (isDel) {
      const params = {
        ...pageObject,
        entity: { ...gorevSorBirim },
      };
      await dispatch(deleteEntity(params));
    }
  };

  const saveFormEntity = async unite => {
    const params = {
      ...pageObject,
      entity: {
        gorev: refGorev,
        org: { ...unite },
      },
    };

    await dispatch(createEntity(params));
    handleCloseForm();
  };

  const handleCloseForm = () => {
    setIsGorevSorBirimUpdateModal(false);
  };

  return (
    <div>
      {(loading || updating) && <LoadingSpinner />}
      <div className="table-responsive" style={{ maxHeight: '600px', minHeight: '600px', overflowY: 'auto', marginTop: 5 }}>
        <TableContainer className="content-table">
          {gorevSorBirimList && gorevSorBirimList.length > 0 ? (
            <Table aria-label="a dense table">
              <TableHead className="content-table-title-area">
                <TableRow className="table-head-row">
                  <TableCell className="table-title-cell hand" align="left">
                    <Translate contentKey="tk24AdysApp.refGorev.sorumluUniteAdiTitle">Sorumlu Unite Adi</Translate>
                  </TableCell>

                  <TableCell style={{ display: 'flex', justifyContent: 'end', alignContent: 'center' }}>
                    <div className="btn-group flex-btn-group-container">
                      <GrantedButton
                        grantedAuthorites={['ref_gorev_sor_birim_edit']}
                        onClick={() => setIsGorevSorBirimUpdateModal(true)}
                        className="create-button"
                        size="sm"
                        data-cy="entityCreateButton"
                      >
                        <img width={14} height={14} src={`${SERVER_URL}/content/images/icon/faPlus.svg`} />
                        <span className="d-none d-md-inline">
                          <Translate contentKey="tk24AdysApp.refGorev.sorumluUniteEkle" />
                        </span>
                      </GrantedButton>
                    </div>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {gorevSorBirimList.map(gorevSorBirim => (
                  <TableRow key={gorevSorBirim.id} className="table-cell-row">
                    <TableCell className="table-content-cell" component="th" scope="row">
                      {gorevSorBirim.org?.adi}
                    </TableCell>
                    <TableCell className="table-content-cell" align="right">
                      <div className="btn-group flex-btn-group-container">
                        <GrantedButton
                          grantedAuthorites={['ref_gorev_sor_birim_delete']}
                          onClick={() => confirmSorBirimDelete(gorevSorBirim)}
                          className="delete-button"
                          size="sm"
                          data-cy="entityDeleteButton"
                        >
                          <img width={11.2} height={12.8} src={`${SERVER_URL}/content/images/icon/faTrashCan.svg`} />
                          <span>
                            <Translate contentKey="entity.action.delete">Delete</Translate>
                          </span>
                        </GrantedButton>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : (
            !loading && (
              <div style={{ margin: '10px' }}>
                <div style={{ display: 'flex', justifyContent: 'end', alignItems: 'center', margin: '10px' }}>
                  <GrantedButton
                    grantedAuthorites={['ref_gorev_sor_birim_edit']}
                    onClick={() => setIsGorevSorBirimUpdateModal(true)}
                    className="create-button"
                    size="sm"
                    data-cy="entityCreateButton"
                  >
                    <img width={14} height={14} src={`${SERVER_URL}/content/images/icon/faPlus.svg`} />
                    <span className="d-none d-md-inline">
                      <Translate contentKey="tk24AdysApp.refGorevSorBirim.home.createLabel" />
                    </span>
                  </GrantedButton>
                </div>
                <div className="alert alert-warning">
                  <Translate contentKey="tk24AdysApp.refGorevSorBirim.home.notFound">No refGorevSorBirim found</Translate>
                </div>
              </div>
            )
          )}
        </TableContainer>
      </div>
    </div>
  );
};

export default RefGorevSorBirim;
