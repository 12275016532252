import L from 'leaflet';

const CustomControl = L.Control.extend({
    options: {
        position: 'bottomright',
    },

    onAdd(map) {
        this._div = L.DomUtil.create('div', 'map-info'); // create a div with a class "info"
        this.update();
        return this._div;
    },

    onRemove(map) {
        // Nothing to do here
    },

    update(props?,layer?) {
      /* function generateHtml4Tooltip(json) {

        let content = '<table><tbody>';

        const data = flattenObject(json);

        Object.keys(data).map((key, i) => (
            content  +='<tr><td>'+ key+'</td><td>'+data[key] + '</td></tr>' ))


        content += '</tbody></table>';

        return content;
      }

      function flattenObject(ob) {
        const toReturn = {};

        for (const i in ob) {
          if (!ob.hasOwnProperty(i)) continue;

          if ((typeof ob[i]) === 'object') {
            const flatObject = flattenObject(ob[i]);
            for (const x in flatObject) {
              if (!flatObject.hasOwnProperty(x)) continue;

              toReturn[i + '.' + x] = flatObject[x];
            }
          } else {
            toReturn[i] = ob[i];
          }
        }
        return toReturn;
      }
        this._div.innerHTML =
             '<h4>'+layer?.name+'</h4>' +
            generateHtml4Tooltip(props);
             //(props ? '<b>' + props.name + '</b><br />' + props.density + ' people / mi<sup>2</sup>' : 'Hover over a state');
      */
    },
});

export default CustomControl;
