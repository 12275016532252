import React, { useState, useEffect } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col, UncontrolledTooltip } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'app/component/jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { getEntities as getKrizKatilimcis } from 'app/entities/kriz-katilimci/kriz-katilimci.reducer';
import { getReferansByTip } from 'app/entities/referans/referans.reducer';
import { getEntities as getDuyurus } from 'app/entities/adym-duyuru/adym-duyuru.reducer';
import { getEntity, updateEntity, createEntity, reset } from './kriz-katilimci-bildirim.reducer';
import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

export const KrizKatilimciBildirimUpdate = (props: RouteComponentProps<{ id: string }>) => {
  const dispatch = useAppDispatch();

  const [isNew] = useState(!props.match.params || !props.match.params.id);

  const krizKatilimcis = useAppSelector(state => state.krizKatilimci.entities);
  const referans = useAppSelector(state => state.referans.entities);
  const duyurus = useAppSelector(state => state.adymDuyuru.entities);
  const krizKatilimciBildirimEntity = useAppSelector(state => state.krizKatilimciBildirim.entity);
  const loading = useAppSelector(state => state.krizKatilimciBildirim.loading);
  const updating = useAppSelector(state => state.krizKatilimciBildirim.updating);
  const updateSuccess = useAppSelector(state => state.krizKatilimciBildirim.updateSuccess);
  const bildirimTuru = useAppSelector(state => state.referans.entities);
  const gonderimDurumu = useAppSelector(state => state.referans.entities);

  const handleClose = () => {
    props.history.push('/kriz-katilimci-bildirim' + props.location.search);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(props.match.params.id));
    }

    dispatch(getKrizKatilimcis({}));
    // dispatch(getReferans({}));
    dispatch(getDuyurus({}));
    dispatch(getReferansByTip('BILDIRIM_TURU'));
    dispatch(getReferansByTip('GONDERIM_DURUMU'));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    values.planlananGonderimZamani = convertDateTimeToServer(values.planlananGonderimZamani);
    values.gonderimZamani = convertDateTimeToServer(values.gonderimZamani);
    values.cDate = convertDateTimeToServer(values.cDate);
    values.mDate = convertDateTimeToServer(values.mDate);

    const entity = {
      ...krizKatilimciBildirimEntity,
      ...values,
      krizKatilimci: krizKatilimcis.find(it => it.id.toString() === values.krizKatilimciId.toString()),
      bildirimTuru: referans.find(it => it.id.toString() === values.bildirimTuruId.toString()),
      gonderimDurumu: referans.find(it => it.id.toString() === values.gonderimDurumuId.toString()),
      durum: referans.find(it => it.id.toString() === values.durumId.toString()),
      duyuru: duyurus.find(it => it.id.toString() === values.duyuruId.toString()),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {
          planlananGonderimZamani: displayDefaultDateTime(),
          gonderimZamani: displayDefaultDateTime(),
          cDate: displayDefaultDateTime(),
          mDate: displayDefaultDateTime(),
        }
      : {
          ...krizKatilimciBildirimEntity,
          planlananGonderimZamani: convertDateTimeFromServer(krizKatilimciBildirimEntity.planlananGonderimZamani),
          gonderimZamani: convertDateTimeFromServer(krizKatilimciBildirimEntity.gonderimZamani),
          cDate: convertDateTimeFromServer(krizKatilimciBildirimEntity.cDate),
          mDate: convertDateTimeFromServer(krizKatilimciBildirimEntity.mDate),
          krizKatilimciAdi: krizKatilimciBildirimEntity?.krizKatilimci?.adi,
          krizKatilimciSoyadi: krizKatilimciBildirimEntity?.krizKatilimci?.soyadi,
          bildirimTuruId: krizKatilimciBildirimEntity?.bildirimTuru?.id,
          duyuruAdi: krizKatilimciBildirimEntity?.duyuru?.baslik,
          gonderimDurumuId: krizKatilimciBildirimEntity?.gonderimDurumu?.id,
          durumId: krizKatilimciBildirimEntity?.durum?.id,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="tk24AdysApp.krizKatilimciBildirim.home.createOrEditLabel" data-cy="KrizKatilimciBildirimCreateUpdateHeading">
            <Translate contentKey="tk24AdysApp.krizKatilimciBildirim.home.createOrEditLabel">
              Create or edit a KrizKatilimciBildirim
            </Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="kriz-katilimci-bildirim-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('tk24AdysApp.krizKatilimciBildirim.icerik')}
                id="kriz-katilimci-bildirim-icerik"
                name="icerik"
                data-cy="icerik"
                type="text"
                validate={{
                  maxLength: { value: 4000, message: translate('entity.validation.maxlength', { max: 4000 }) },
                }}
              />
              <UncontrolledTooltip target="icerikLabel">
                <Translate contentKey="tk24AdysApp.krizKatilimciBildirim.help.icerik" />
              </UncontrolledTooltip>
              <ValidatedField
                label={translate('tk24AdysApp.krizKatilimciBildirim.iletisimBilgisi')}
                id="kriz-katilimci-bildirim-iletisimBilgisi"
                name="iletisimBilgisi"
                data-cy="iletisimBilgisi"
                type="text"
                validate={{
                  maxLength: { value: 1000, message: translate('entity.validation.maxlength', { max: 1000 }) },
                }}
              />
              <UncontrolledTooltip target="iletisimBilgisiLabel">
                <Translate contentKey="tk24AdysApp.krizKatilimciBildirim.help.iletisimBilgisi" />
              </UncontrolledTooltip>
              <ValidatedField
                label={translate('tk24AdysApp.krizKatilimciBildirim.planlananGonderimZamani')}
                id="kriz-katilimci-bildirim-planlananGonderimZamani"
                name="planlananGonderimZamani"
                data-cy="planlananGonderimZamani"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <UncontrolledTooltip target="planlananGonderimZamaniLabel">
                <Translate contentKey="tk24AdysApp.krizKatilimciBildirim.help.planlananGonderimZamani" />
              </UncontrolledTooltip>
              <ValidatedField
                label={translate('tk24AdysApp.krizKatilimciBildirim.gonderimZamani')}
                id="kriz-katilimci-bildirim-gonderimZamani"
                name="gonderimZamani"
                data-cy="gonderimZamani"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <UncontrolledTooltip target="gonderimZamaniLabel">
                <Translate contentKey="tk24AdysApp.krizKatilimciBildirim.help.gonderimZamani" />
              </UncontrolledTooltip>
              <ValidatedField
                label={translate('tk24AdysApp.krizKatilimciBildirim.denemeSayisi')}
                id="kriz-katilimci-bildirim-denemeSayisi"
                name="denemeSayisi"
                data-cy="denemeSayisi"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  validate: v => isNumber(v) || translate('entity.validation.number'),
                }}
              />
              <UncontrolledTooltip target="denemeSayisiLabel">
                <Translate contentKey="tk24AdysApp.krizKatilimciBildirim.help.denemeSayisi" />
              </UncontrolledTooltip>
              <ValidatedField
                label={translate('tk24AdysApp.krizKatilimciBildirim.hataMesaji')}
                id="kriz-katilimci-bildirim-hataMesaji"
                name="hataMesaji"
                data-cy="hataMesaji"
                type="text"
                validate={{
                  maxLength: { value: 4000, message: translate('entity.validation.maxlength', { max: 4000 }) },
                }}
              />
              <UncontrolledTooltip target="hataMesajiLabel">
                <Translate contentKey="tk24AdysApp.krizKatilimciBildirim.help.hataMesaji" />
              </UncontrolledTooltip>
              <ValidatedField
                id="kriz-katilimci-bildirim-krizKatilimci"
                name="krizKatilimciAdi"
                data-cy="krizKatilimci"
                label={translate('tk24AdysApp.krizKatilimciBildirim.adi')}
                type="text"
                required
              ></ValidatedField>
              <ValidatedField
                id="kriz-katilimci-bildirim-krizKatilimci"
                name="krizKatilimciSoyadi"
                data-cy="krizKatilimci"
                label={translate('tk24AdysApp.krizKatilimciBildirim.soyadi')}
                type="text"
                required
              ></ValidatedField>
              <ValidatedField
                id="kriz-katilimci-bildirim-bildirimTuru"
                name="bildirimTuruId"
                data-cy="bildirimTuru"
                label={translate('tk24AdysApp.krizKatilimciBildirim.bildirimTuru')}
                type="select"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              >
                <option value="" key="0" />
                {bildirimTuru
                  ? bildirimTuru.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.adi}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="kriz-katilimci-bildirim-duyuru"
                name="duyuruAdi"
                data-cy="duyuru"
                label={translate('tk24AdysApp.krizKatilimciBildirim.duyuru')}
                type="text"
              ></ValidatedField>
              <ValidatedField
                id="kriz-katilimci-bildirim-gonderimDurumu"
                name="gonderimDurumuId"
                data-cy="gonderimDurumu"
                label={translate('tk24AdysApp.krizKatilimciBildirim.gonderimDurumu')}
                type="select"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              >
                <option value="" key="0" />
                {gonderimDurumu
                  ? gonderimDurumu.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.adi}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/kriz-katilimci-bildirim" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default KrizKatilimciBildirimUpdate;
