import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { TextFormat, translate, Translate } from 'app/component/jhipster';
import { ASC, DESC } from 'app/shared/util/pagination.constants';
import { APP_DATE_FORMAT, SERVER_URL } from 'app/config/constants';
import GrantedButton from 'app/component/GrantedButton';
import CustomPagination from 'app/component/CustomPagination';
import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntities, getTotalEntities } from 'app/entities/kriz-doldurulan-formlar/kriz-doldurulan-formlar.reducer';
import { IQueryParams } from 'app/shared/reducers/reducer.utils';
import { IKisi } from 'app/shared/model/kisi.model';
import { LoadingSpinner } from 'app/component/LoadingSpinner';
import { Input } from 'reactstrap';
import AdtFormPreview from 'app/component/AdtFormPreview';
import EtkilenenKisiFormKlon from './etkilenen-kisi-form-klon';
import { IReferans } from 'app/shared/model/referans.model';
import { IKrizDoldurulanFormlar } from 'app/shared/model/kriz-doldurulan-formlar.model';
import { ReferansEnum } from 'app/constants/ReferansEnum';
import { isNullOrEmpty, isObjectEmpty } from 'app/shared/util/confirm-utils';
import { MenuProps } from 'app/shared/util/filter.constants';
import { getEntities as getReferans } from 'app/entities/referans/referans.reducer';
import { getEntities as getFormEntities } from '../ref-adt-form/ref-adt-form.reducer';
import { getEntities as getKisiAltTipiEntities } from '../ref-kisi-alt-tipi/ref-kisi-alt-tipi.reducer';
import { localeLanguage, upperOnlyFirstLetter } from 'app/shared/util/util';
import ExcelDownloadButton from 'app/component/ExcelDownload';

interface EtkilenenKisiDoldurulanFormlarProps {
  kisi: IKisi;
  ucusId?: number;
  kisiAltTipi: IReferans;
  krizId?: string;
  onlyKriz?: boolean;
}

const EtkilenenKisiDoldurulanFormlar: React.FC<EtkilenenKisiDoldurulanFormlarProps> = ({ kisi, ucusId, kisiAltTipi, krizId, onlyKriz }) => {
  const dispatch = useAppDispatch();

  const krizDoldurulanFormlarList = useAppSelector(state => state.krizDoldurulanFormlar.entities);
  const loading = useAppSelector(state => state.krizDoldurulanFormlar.loading);
  const updating = useAppSelector(state => state.krizDoldurulanFormlar.updating);
  const totalItem = useAppSelector(state => state.krizDoldurulanFormlar.totalItems);
  const [isUpdatable, setIsUpdatable] = useState(null);
  const [search, setSearch] = useState(null);

  const [isKlonModal, setIsKlonModal] = useState(false);
  const [selectedForm, setSelectedForm] = useState(null);
  const [isFormModal, setIsFormModal] = useState(false);

  const [pageObject, setPageObject] = useState<IQueryParams>(useAppSelector(state => state.paginate.pageObject));
  const referans = useAppSelector(state => state.referans.entities);
  const kriz = useAppSelector(state => state.kriz.entity);
  const kisiTipiList = referans.filter(item => item.tipi === 'KISI_TIPI');
  const kisiAltTipiList = useAppSelector(state => state.refKisiAltTipi.entities);
  const formList = useAppSelector(state => state.refAdtForm.entities);
  const [kisiTipiId, setKisiTipiId] = useState(null);
  const [kisiAltTipiId, setKisiAltTipiId] = useState(null);
  const [formGuid, setFormGuid] = useState(null);
  const [kisiTipi, setKisiTipi] = useState(null);

  const ExcelSampleData = [
    { key: 'form.adiTr', titleContentKey: 'tk24AdysApp.krizDoldurulanForm.adiTr' },
    { key: 'dolduranKk.adi', titleContentKey: 'tk24AdysApp.krizDoldurulanForm.dolduranKullaniciAdi' },
    { key: 'dolduranKk.soyadi', titleContentKey: 'tk24AdysApp.krizDoldurulanForm.dolduranKullaniciSoyadi' },
    { key: 'doldurulmaTarihi', format: 'date', titleContentKey: 'tk24AdysApp.krizDoldurulanForm.doldurulmaTarihi' },
    { key: 'kisiDetay.adi', titleContentKey: 'tk24AdysApp.krizDoldurulanForm.doldurulanKullaniciAdi' },
    { key: 'kisiDetay.soyadi', titleContentKey: 'tk24AdysApp.krizDoldurulanForm.doldurulanKullaniciSoyadi' },
    { key: 'kisiDetay.kisiTipi.adi', titleContentKey: 'tk24AdysApp.ucusKisiListesi.kisiTip' },
    { key: 'kisiDetay.kisiAltTipi.adi', titleContentKey: 'tk24AdysApp.ucusKisiListesi.kisiAltTip' },
  ];

  const getAllEntities = () => {
    if (kisi !== null && kisi !== undefined) {
      dispatch(
        getEntities({
          ...pageObject,
          krizId: `${krizId}`,
          kisiGuid: `${kisi.id}`,
        })
      );
    } else {
      dispatch(
        getEntities({
          ...pageObject,
          krizId: `${krizId}`,
          formTipiId: onlyKriz ? 123 : null,
        })
      );
    }
  };

  useEffect(() => {
    getAllEntities();
  }, [pageObject.page, pageObject.query, pageObject.size, pageObject.sort]);

  useEffect(() => {
    if (!onlyKriz) {
      dispatch(getReferans({ page: 0, size: 999, sort: `adi${upperOnlyFirstLetter(localeLanguage)},asc` }));
      dispatch(getFormEntities({ page: 0, size: 999, sort: `adi${upperOnlyFirstLetter(localeLanguage)},asc` }));
    }
  }, []);

  const kisiAltTipiFunc = selectedKisiTipiId => {
    if (selectedKisiTipiId) {
      dispatch(
        getKisiAltTipiEntities({
          page: 0,
          size: 100,
          sort: `adi${upperOnlyFirstLetter(localeLanguage)},asc`,
          kisiTipiId: selectedKisiTipiId,
        })
      );
    } else {
      dispatch(
        getKisiAltTipiEntities({
          page: 0,
          size: 100,
          sort: `adi${upperOnlyFirstLetter(localeLanguage)},asc`,
        })
      );
    }
  };

  const sort = p => () => {
    const orderBy = pageObject.sort.split(',')[1];
    setPageObject({ ...pageObject, sort: `${p},${orderBy === ASC ? DESC : ASC}` });
  };

  const handlePageChange = (pageNumber, newItemsPerPage = pageObject.size) => {
    if (newItemsPerPage !== pageObject.size) {
      setPageObject({ ...pageObject, page: pageNumber - 1, size: newItemsPerPage });
    } else {
      setPageObject({ ...pageObject, page: pageNumber - 1 });
    }
  };

  const handleCloseFormModal = () => {
    setIsFormModal(false);
    setSelectedForm(null);
  };

  const handleCloseFormKlonModal = () => {
    setIsKlonModal(false);
    setSelectedForm(null);
  };

  const fetchTotalDoldurulanFormData = async (): Promise<IKrizDoldurulanFormlar[]> => {
    try {
      const response = (await dispatch(getTotalEntities({ ...pageObject, size: totalItem, krizId: kriz.id.toString() }))) as {
        payload: { data: IKrizDoldurulanFormlar[] };
      };

      return response.payload.data || [];
    } catch (error) {
      console.error('Error fetching data:', error);
      return [];
    }
  };

  const handleSearchFormChange = event => {
    const selectedFormGuid = !isNullOrEmpty(event.target.value) ? event.target.value : null;
    setFormGuid(selectedFormGuid);
    if (kisi !== null && kisi !== undefined) {
      dispatch(
        getEntities({
          ...pageObject,
          krizId: `${krizId}`,
          kisiGuid: `${kisi?.id}`,
          kisiTipiId,
          kisiAltTipiId,
          formGuid: selectedFormGuid,
          globalFilter: search,
        })
      );
    } else {
      dispatch(
        getEntities({
          ...pageObject,
          krizId: `${krizId}`,
          kisiTipiId,
          kisiAltTipiId,
          formGuid: selectedFormGuid,
          globalFilter: search,
        })
      );
    }
  };

  useEffect(() => {
    if (search === null) return;

    if (search.length > 2) {
      if (kisi !== null && kisi !== undefined) {
        dispatch(
          getEntities({
            ...pageObject,
            krizId: `${krizId}`,
            kisiGuid: `${kisi?.id}`,
            kisiTipiId,
            kisiAltTipiId,
            formGuid,
            globalFilter: search,
          })
        );
      } else {
        dispatch(
          getEntities({
            ...pageObject,
            krizId: `${krizId}`,
            kisiTipiId,
            kisiAltTipiId,
            formGuid,
            globalFilter: search,
          })
        );
      }
    } else if (search.length === 0) {
      if (kisi !== null && kisi !== undefined) {
        dispatch(
          getEntities({
            ...pageObject,
            krizId: `${krizId}`,
            kisiGuid: `${kisi?.id}`,
            kisiTipiId,
            kisiAltTipiId,
            formGuid,
            globalFilter: search,
          })
        );
      } else {
        dispatch(
          getEntities({
            ...pageObject,
            krizId: `${krizId}`,
            kisiTipiId,
            kisiAltTipiId,
            formGuid,
            globalFilter: search,
          })
        );
      }
    }
  }, [search]);

  const handleSearchChange = event => {
    const selectedKisiTipiGuid = !isNullOrEmpty(event.target.value) ? event.target.value : null;

    const selectedKisiTipiItem = kisiTipiList.find(item => item.guid === selectedKisiTipiGuid);

    const selectedKisiTipiId = selectedKisiTipiItem ? selectedKisiTipiItem.id : null;

    setKisiTipiId(selectedKisiTipiGuid);
    setKisiTipi(selectedKisiTipiId);
    setKisiAltTipiId(null);
    if (selectedKisiTipiId) {
      kisiAltTipiFunc(selectedKisiTipiId);
    } else {
      kisiAltTipiFunc(null);
    }
    if (kisi !== null && kisi !== undefined) {
      dispatch(
        getEntities({
          ...pageObject,
          krizId: `${krizId}`,
          kisiGuid: `${kisi?.id}`,
          kisiTipiId: selectedKisiTipiGuid,
          kisiAltTipiId: null,
          formGuid,
          globalFilter: search,
        })
      );
    } else {
      dispatch(
        getEntities({
          ...pageObject,
          krizId: `${krizId}`,
          kisiTipiId: selectedKisiTipiGuid,
          kisiAltTipiId: null,
          formGuid,
          globalFilter: search,
        })
      );
    }
  };

  const handleKisiAltTipiChange = event => {
    const selectedKisiAltTipiId = !isNullOrEmpty(event.target.value) ? event.target.value : null;
    setKisiAltTipiId(selectedKisiAltTipiId);
    if (kisi !== null && kisi !== undefined) {
      dispatch(
        getEntities({
          ...pageObject,
          krizId: `${krizId}`,
          kisiGuid: `${kisi?.id}`,
          kisiTipiId,
          kisiAltTipiId: selectedKisiAltTipiId,
          formGuid,
          globalFilter: search,
        })
      );
    } else {
      dispatch(
        getEntities({
          ...pageObject,
          krizId: `${krizId}`,
          kisiTipiId,
          kisiAltTipiId: selectedKisiAltTipiId,
          formGuid,
          globalFilter: search,
        })
      );
    }
  };

  return (
    !isObjectEmpty(kriz) && (
      <>
        {(loading || updating) && <LoadingSpinner />}
        {isKlonModal && (
          <EtkilenenKisiFormKlon
            isKlonModal={isKlonModal}
            handleCloseFormKlonModal={handleCloseFormKlonModal}
            selectedForm={selectedForm}
            kisiTipiId={selectedForm?.kisiDetay?.kisiTipi?.id}
            ucusId={ucusId}
          />
        )}

        {isFormModal && (
          <AdtFormPreview
            doldurulanForm={selectedForm}
            isFormModal={isFormModal}
            isUpdatable={isUpdatable}
            handleCloseFormModal={handleCloseFormModal}
          />
        )}
        <div className="content-area">
          {!onlyKriz && (
            <div className="search-frame">
              <div className="seachiconposition">
                <img className="seachicon" width={24} height={24} src={`${SERVER_URL}/content/images/icon/faSearch.svg`} />
                <Input
                  value={search}
                  onChange={e => setSearch(e.target.value)}
                  placeholder={translate('entity.action.search')}
                  className="search-input"
                />
                <Box sx={{ minWidth: 220, maxWidth: 220, marginLeft: 1 }}>
                  <FormControl fullWidth>
                    <InputLabel id="formGuid-label" className="select-input-label">
                      <Translate contentKey="tk24AdysApp.krizDoldurulanForm.form" />
                    </InputLabel>
                    <Select
                      className="select-input"
                      labelId="formGuid-label"
                      id="formGuid"
                      label={translate('tk24AdysApp.krizDoldurulanForm.form')}
                      MenuProps={MenuProps}
                      onChange={handleSearchFormChange}
                    >
                      <MenuItem value="">
                        <Translate contentKey="entity.action.all" />
                      </MenuItem>
                      {formList &&
                        formList.length > 0 &&
                        formList.map(item => (
                          <MenuItem key={item.id} value={item.id}>
                            {item.adi}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Box>
              </div>

              <div className="d-flex justify-end">
                <ExcelDownloadButton
                  excelSampleData={ExcelSampleData}
                  grantedAuthorites={['kriz_doldurulan_formlar_export']}
                  fetchData={fetchTotalDoldurulanFormData}
                  className={'download-button'}
                  exportName={translate('tk24AdysApp.krizDoldurulanForm.home.title')}
                />
              </div>
            </div>
          )}

          <div
            style={{
              overflowY: 'auto',
              maxHeight: '250px',
              position: 'relative',
            }}
          >
            <TableContainer className="content-table">
              {krizDoldurulanFormlarList && krizDoldurulanFormlarList.length > 0 ? (
                <Table aria-label="a dense table">
                  <TableHead className="content-table-title-area">
                    <TableRow className="table-head-row">
                      <TableCell className="table-title-cell hand" align="left">
                        <Translate contentKey="tk24AdysApp.krizDoldurulanForm.adiTr">Adi</Translate>&nbsp;
                      </TableCell>
                      <TableCell className="table-title-cell hand" align="left">
                        <Translate contentKey="tk24AdysApp.krizDoldurulanForm.dolduranKullaniciTitle">Dolduran Kullanıcı</Translate>&nbsp;
                      </TableCell>
                      <TableCell className="table-title-cell hand" align="left">
                        <Translate contentKey="tk24AdysApp.krizDoldurulanForm.doldurulmaTarihiTitle">Dolduran Kullanıcı</Translate>&nbsp;
                      </TableCell>
                      {!onlyKriz && (
                        <>
                          <TableCell className="table-title-cell hand" align="left">
                            <Translate contentKey="tk24AdysApp.krizDoldurulanForm.kisiTitle">Kişi</Translate>&nbsp;
                          </TableCell>
                          <TableCell className="table-title-cell hand" align="left">
                            <Translate contentKey="tk24AdysApp.ucusKisiListesi.kisiTip">Tip</Translate>&nbsp;
                          </TableCell>
                          <TableCell className="table-title-cell hand" align="left">
                            <Translate contentKey="tk24AdysApp.ucusKisiListesi.kisiAltTip">Alt Tip</Translate>&nbsp;
                          </TableCell>
                        </>
                      )}
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {krizDoldurulanFormlarList.map(krizDoldurulanForm => (
                      <TableRow key={krizDoldurulanForm.id} className="table-cell-row">
                        <TableCell className="table-content-cell" component="th" scope="row">
                          {krizDoldurulanForm.form.adiTr}
                        </TableCell>
                        <TableCell className="table-content-cell" component="th" scope="row">
                          {krizDoldurulanForm.dolduranKk?.adi} {krizDoldurulanForm.dolduranKk?.soyadi}
                        </TableCell>
                        <TableCell className="table-content-cell" component="th" scope="row">
                          <TextFormat type="date" value={krizDoldurulanForm.doldurulmaTarihi} format={APP_DATE_FORMAT} />{' '}
                        </TableCell>
                        {!onlyKriz && (
                          <>
                            <TableCell className="table-content-cell" component="th" scope="row">
                              {krizDoldurulanForm.kisiDetay?.adi} {krizDoldurulanForm.kisiDetay?.soyadi}
                            </TableCell>
                            <TableCell className="table-content-cell" component="th" scope="row">
                              {krizDoldurulanForm?.kisiDetay?.kisiTipi?.adi}
                            </TableCell>
                            <TableCell className="table-content-cell" component="th" scope="row">
                              {krizDoldurulanForm?.kisiDetay?.kisiAltTipi?.adi}
                            </TableCell>
                          </>
                        )}
                        <TableCell align="right" className="table-content-cell">
                          <div className="btn-group flex-btn-group-container etkilenenDoldurulanForm" style={{ gap: '5px' }}>
                            <GrantedButton
                              grantedAuthorites={['ref_adt_form_doldurulan_read']}
                              className="edit-button"
                              size="sm"
                              data-cy="entityEditButton"
                              onClick={() => {
                                setIsUpdatable(false);
                                setSelectedForm(krizDoldurulanForm);
                                setIsFormModal(true);
                              }}
                            >
                              <span className="d-none d-md-inline">
                                <Translate contentKey="tk24AdysApp.refAdtFormBolum.preview">preview</Translate>
                              </span>
                            </GrantedButton>
                            {onlyKriz && (
                              <GrantedButton
                                grantedAuthorites={['ref_adt_form_doldurulan_read']}
                                className="edit-button"
                                size="sm"
                                data-cy="entityEditButton"
                                onClick={() => {
                                  setIsUpdatable(true);
                                  setSelectedForm(krizDoldurulanForm);
                                  setIsFormModal(true);
                                }}
                              >
                                <span className="d-none d-md-inline">
                                  <Translate contentKey="tk24AdysApp.krizKatilimciGorev.duzenle">duzenle</Translate>
                                </span>
                              </GrantedButton>
                            )}
                            {kisi !== null && (
                              <>
                                <GrantedButton
                                  id={`update-button-${krizDoldurulanForm.id}`}
                                  grantedAuthorites={['ref_adt_form_doldurulan_edit']}
                                  className="edit-button"
                                  size="sm"
                                  data-cy="entityEditButton"
                                  style={{ marginLeft: 5 }}
                                  onClick={() => {
                                    setIsUpdatable(true);
                                    setSelectedForm(krizDoldurulanForm);
                                    setIsFormModal(true);
                                  }}
                                  comparison={[
                                    {
                                      value: kriz.krizDurumu?.id,
                                      checkValue: ReferansEnum.KrizDurumu.Aktif,
                                      contentKey: 'error.comparison.crisisIsPassive',
                                    },
                                  ]}
                                >
                                  <span className="d-none d-md-inline">
                                    <Translate contentKey="entity.action.edit">Edit</Translate>
                                  </span>
                                </GrantedButton>

                                <GrantedButton
                                  id={`update-button-${krizDoldurulanForm.id}`}
                                  grantedAuthorites={['ref_adt_form_doldurulan_edit']}
                                  className="edit-button"
                                  size="sm"
                                  data-cy="entityEditButton"
                                  style={{ marginLeft: 5 }}
                                  onClick={() => {
                                    setSelectedForm(krizDoldurulanForm);
                                    setIsKlonModal(true);
                                  }}
                                  comparison={[
                                    {
                                      value: kriz.krizDurumu?.id,
                                      checkValue: ReferansEnum.KrizDurumu.Aktif,
                                      contentKey: 'error.comparison.crisisIsPassive',
                                    },
                                  ]}
                                >
                                  <span className="d-none d-md-inline">
                                    <Translate contentKey="entity.action.klonla">Klonla</Translate>
                                  </span>
                                </GrantedButton>
                              </>
                            )}
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              ) : (
                !loading &&
                !updating && (
                  <div className="alert alert-warning">
                    <Translate contentKey="tk24AdysApp.krizDoldurulanForm.home.notFound">Doldurulan Form Bulunamadı</Translate>
                  </div>
                )
              )}
            </TableContainer>
          </div>

          {totalItem ? (
            <div className={krizDoldurulanFormlarList && krizDoldurulanFormlarList.length > 0 ? '' : 'd-none'}>
              <CustomPagination
                currentPage={pageObject.page + 1}
                currentPageSize={pageObject.size}
                totalItems={totalItem}
                handlePageChange={handlePageChange}
              />
            </div>
          ) : (
            ''
          )}
        </div>
      </>
    )
  );
};

export default EtkilenenKisiDoldurulanFormlar;
