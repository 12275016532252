import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending, createSlice } from '@reduxjs/toolkit';
import { EntityState, createEntitySlice, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { IFilteredDuyuruQueryParams, IKrizDuyurular, defaultValue } from 'app/shared/model/kriz-duyurular.model';

const initialState: EntityState<IKrizDuyurular> = {
  loading: false,
  errorMessage: null,
  entities: [],
  totalEntities: [],
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

const apiUrl = 'api/kriz-duyurus';

// Actions

export const getEntities = createAsyncThunk(
  'krizDuyurular/fetch_entity_list',
  async ({ id, page, size, sort }: IFilteredDuyuruQueryParams) => {
    const queryParams = new URLSearchParams();

    if (page) {
      queryParams.set('page', page.toString());
    }
    if (sort) {
      queryParams.set('sort', sort.toString());
    }
    if (size) {
      queryParams.set('size', size.toString());
    }

    queryParams.set('cacheBuster', new Date().getTime().toString());
    const queryString = queryParams.toString();
    const requestUrl = `${apiUrl}/sicil-duyuru${queryString ? `?${queryString}` : ''}&krizId.equals=${id}`;

    return axios.get<IKrizDuyurular[]>(requestUrl);
  }
);

// slice

export const krizDuyurularSlice = createEntitySlice({
  name: 'krizDuyurular',
  initialState,
  extraReducers(builder) {
    builder
      .addMatcher(isFulfilled(getEntities), (state, action) => {
        return {
          ...state,
          loading: false,
          entities: action.payload.data,
          totalItems: parseInt(action.payload.headers['x-total-count'], 10),
        };
      })
      .addMatcher(isPending(getEntities), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      });
  },
});

export const { reset } = krizDuyurularSlice.actions;

// Reducer
export default krizDuyurularSlice.reducer;
