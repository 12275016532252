import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import KrizCeride from './kriz-ceride';
import KrizCerideDetail from './kriz-ceride-detail';
import KrizCerideUpdate from './kriz-ceride-update';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={KrizCerideUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={KrizCerideUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={KrizCerideDetail} />
      <ErrorBoundaryRoute path={match.url} component={KrizCeride} />
    </Switch>
  </>
);

export default Routes;
