import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import MobileStepper from '@mui/material/MobileStepper';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { Card, Col, Row } from 'reactstrap';
import { CardContent } from '@mui/material';
import { SERVER_URL } from 'app/config/constants';
import { hasAnyAuthority, useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntities, reset } from '../kriz-ceride/kriz-ceride.reducer';
import { IQueryParams } from 'app/shared/reducers/reducer.utils';
import { ReferansEnum } from 'app/constants/ReferansEnum';
import { KartLoadingSpinner } from 'app/component/LoadingSpinner';
import { Link } from 'react-router-dom';
import { Translate, translate } from 'app/component/jhipster';
import GrantedButton from 'app/component/GrantedButton';

function capitalizeWords(sentence) {
  if (!sentence) {
    return '';
  }
  const words = sentence.split(' ');
  const capitalizedWords = words.map(word => {
    if (word.length > 1) {
      return word.charAt(0).toLocaleUpperCase('tr-TR') + word.slice(1).toLocaleLowerCase('tr-TR');
    } else {
      return word.toLocaleUpperCase('tr-TR');
    }
  });
  return capitalizedWords.join(' ');
}

const calculateStartIndex = (currentPage, totalItemCount, itemsPerPage) => {
  if (currentPage === 0) {
    return totalItemCount;
  } else {
    return totalItemCount - itemsPerPage * currentPage;
  }
};

const CerideSlide = ({ cerideList, totalItem, pageObject }) => (
  <div className="gorevlerim-dashboard">
    {cerideList.map((ceride, index) => {
      const islemZamani = ceride.islemZamani;
      const [tarihStr, saatTam] = islemZamani.split('T');
      const [yil, ay, gun] = tarihStr.split('-');
      const tarih = `${gun}.${ay}.${yil}`;
      const saat = saatTam.slice(0, 5);
      return (
        <Card className="cerideCardDashboard" key={index}>
          <CardContent>
            <Row className="align-items-center m-0">
              <Col md={12} className="p-0">
                <div className="cerideBefore">
                  <div className="d-flex align-items-center justify-content-between mb-2">
                    <div className="toplamCeride">
                      <span className="cerideVeri"> {calculateStartIndex(pageObject.page, totalItem, pageObject.size) - index}</span>
                      <span className="altLine"></span>
                      <span className="toplamCerideVeri"> {totalItem}</span>
                    </div>
                  </div>
                  <div style={{ height: '70px' }}>
                    {ceride.baslik && <h2 className="cerideBaslikDashboard">{ceride.baslik}</h2>}
                    <p className="cerideicerikDashboard" style={{ WebkitLineClamp: ceride.baslik ? 2 : 3 }}>
                      {ceride.icerik}
                    </p>
                  </div>
                  <div className="bottomLine"></div>

                  <div className="cerideislemler">
                    <div className="d-flex align-items-center">
                      <>
                        {(ceride.cerideEkleyen.realId === 1 || ceride.cerideEkleyen.realId === 2 || ceride.cerideEkleyen.realId === 3) && (
                          <div className="cerideKatilimciDashboard">
                            <p>{capitalizeWords(ceride?.cerideEkleyen?.adi)}</p>
                          </div>
                        )}
                        {ceride.cerideEkleyen.realId === 4 && (
                          <div className="cerideKatilimciDashboard">
                            <p>{capitalizeWords(ceride.cerideEkleyenDiger)}</p>
                          </div>
                        )}
                        {ceride.cerideEkleyen.realId === 5 && (
                          <>
                            <div className="cerideKatilimciDashboard">
                              <p>
                                {capitalizeWords(ceride?.krizKatilimci?.adi)} {capitalizeWords(ceride?.krizKatilimci?.soyadi)}
                              </p>
                              <span>{capitalizeWords(ceride?.krizKatilimci?.unvanAdi)}</span>
                            </div>
                          </>
                        )}

                        {ceride.cerideEkleyen.realId === 8 && ceride.cerideEkleyenDiger && (
                          <div className="cerideKatilimciDashboard">
                            {' '}
                            <p>{capitalizeWords(ceride.cerideEkleyenDiger)}</p>
                          </div>
                        )}
                        {ceride.cerideEkleyen.realId === 6 && ceride.cerideEkleyenKrizAdtEkip.adtEkip?.adi && (
                          <div className="cerideKatilimciDashboard">
                            <p>{capitalizeWords(ceride.cerideEkleyenKrizAdtEkip.adtEkip?.adi)}</p>
                          </div>
                        )}
                        {ceride.cerideEkleyen.realId === 7 && (
                          <>
                            {ceride.cerideEkleyenKrizAdtEkip?.adtEkip?.adi ? (
                              <>
                                <div>
                                  <div className="cerideKatilimciDashboard">
                                    <p>
                                      {capitalizeWords(ceride.krizKatilimci?.adi)} {capitalizeWords(ceride.krizKatilimci?.soyadi)}{' '}
                                    </p>{' '}
                                    <span>
                                      {' / '}
                                      {capitalizeWords(ceride.krizKatilimci?.unvanAdi)}
                                    </span>
                                  </div>
                                  <div className="cerideKatilimciDashboard">
                                    <span>{capitalizeWords(ceride.cerideEkleyenKrizAdtEkip?.adtEkip?.adi)}</span>
                                  </div>
                                </div>
                              </>
                            ) : (
                              <div className="cerideKatilimciDashboard">
                                <p>
                                  {capitalizeWords(ceride.krizKatilimci?.adi)} {capitalizeWords(ceride.krizKatilimci?.soyadi)}{' '}
                                </p>
                                <span>{capitalizeWords(ceride.krizKatilimci?.unvanAdi)}</span>
                              </div>
                            )}
                            {ceride.cerideEkleyen.realId === 11 && (
                              <div className="cerideKatilimci">
                                <p>{capitalizeWords(ceride.cerideEkleyenDiger)}</p>
                              </div>
                            )}
                            {ceride.cerideEkleyen.realId === 9 && ceride?.cerideEkleyenKrizIstasyon?.aciklama && (
                              <div className="cerideKatilimci">
                                <p>{capitalizeWords(ceride?.cerideEkleyenKrizIstasyon?.aciklama)}</p>
                              </div>
                            )}
                            {ceride.cerideEkleyen.realId === 10 && (
                              <>
                                {ceride.cerideEkleyenKrizIstasyon?.aciklama ? (
                                  <>
                                    <div>
                                      <div className="cerideKatilimciTim">
                                        <p>
                                          {capitalizeWords(ceride?.krizKatilimci?.adi)} {capitalizeWords(ceride.krizKatilimci?.soyadi)}{' '}
                                        </p>
                                        <span>
                                          {' | '}
                                          {capitalizeWords(ceride?.krizKatilimci?.unvanAdi)}
                                        </span>
                                      </div>
                                      <div className="cerideKatilimci">
                                        <span>{capitalizeWords(ceride.cerideEkleyenKrizIstasyon?.aciklama)}</span>
                                      </div>
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <div className="cerideKatilimci">
                                      <p>
                                        {capitalizeWords(ceride?.krizKatilimci?.adi)} {capitalizeWords(ceride?.krizKatilimci?.soyadi)}{' '}
                                      </p>
                                      <span>{capitalizeWords(ceride?.krizKatilimci?.unvanAdi)}</span>
                                    </div>
                                  </>
                                )}
                              </>
                            )}
                          </>
                        )}
                      </>
                    </div>
                    <div className="islemCerideZamani islemCerideDashboard">
                      <p>{tarih}</p>
                      <span>{saat}</span>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </CardContent>
        </Card>
      );
    })}
  </div>
);

const KrizCeride = () => {
  const dispatch = useAppDispatch();
  const krizEntity = useAppSelector(state => state.kriz.entity);
  const krizKatilimciEntity = useAppSelector(state => state.krizKatilimci.entity);
  const theme = useTheme();
  const krizCerideList = useAppSelector(state => state.krizCeride.entities);
  const loading = useAppSelector(state => state.krizCeride.loading);
  const totalItems = useAppSelector(state => state.krizCeride.totalItems);
  const [activeStep, setActiveStep] = useState(0);
  const maxSteps = Math.ceil(totalItems / 2);

  const getPageQuery = () => {
    const adtRoles = [
      ReferansEnum.KatilimciRolu.AdtEkipLideri,
      ReferansEnum.KatilimciRolu.AdtEkipUyesi,
      ReferansEnum.KatilimciRolu.AdtEkipLideriYardimcisi,
      ReferansEnum.KatilimciRolu.AdtEkKatilimci,
    ];
    const adymRoles = [
      ReferansEnum.KatilimciRolu.AdymBaskani,
      ReferansEnum.KatilimciRolu.AdymUyesi,
      ReferansEnum.KatilimciRolu.AdymEkKatilimci,
      ReferansEnum.KatilimciRolu.AdymTanimliVekil,
      ReferansEnum.KatilimciRolu.AdymEkVekil,
    ];
    const istasyonRoles = [
      ReferansEnum.KatilimciRolu.IstasyonMudur,
      ReferansEnum.KatilimciRolu.IstasyonSef,
      ReferansEnum.KatilimciRolu.IstasyonEkKatilimci,
      ReferansEnum.KatilimciRolu.IstasyonKatilimci,
    ];

    let kayitSahibiId = ReferansEnum.GorevSahibi.ADYM;

    if (krizKatilimciEntity && krizKatilimciEntity.katilimciRolu?.id) {
      if (adtRoles.includes(krizKatilimciEntity.katilimciRolu.id)) {
        kayitSahibiId = ReferansEnum.GorevSahibi.ADT;
      } else if (adymRoles.includes(krizKatilimciEntity.katilimciRolu.id)) {
        kayitSahibiId = ReferansEnum.GorevSahibi.ADYM;
      } else if (istasyonRoles.includes(krizKatilimciEntity.katilimciRolu.id)) {
        kayitSahibiId = ReferansEnum.GorevSahibi.Istasyon;
      }
    }

    if (kayitSahibiId && krizEntity?.id) {
      return `krizId.equals=${krizEntity.id}&kayitSahibiId.equals=${kayitSahibiId}`;
    }
  };

  const [pageObject, setPageObject] = useState<IQueryParams>({
    ...useAppSelector(state => state.paginate.pageObject),
    size: 2,
    sort: 'islemZamani,desc',
  });

  const totalPages = Math.ceil(totalItems / pageObject.size);

  useEffect(() => {
    const fetchKrizDetay = async () => {
      if (krizEntity?.id) {
        dispatch(reset());
        await dispatch(getEntities({ ...pageObject, query: getPageQuery() }));
      }
    };
    fetchKrizDetay();
  }, [krizEntity, pageObject.page]);

  const handleNext = () => {
    setActiveStep(prevActiveStep => (prevActiveStep + 1) % Math.min(maxSteps, 3));
    setPageObject(prevPageObject => ({
      ...prevPageObject,
      page: pageObject.page + 1,
    }));
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => (prevActiveStep - 1 + Math.min(maxSteps, 3)) % Math.min(maxSteps, 3));
    setPageObject(prevPageObject => ({
      ...prevPageObject,
      page: pageObject.page - 1,
    }));
  };

  return (
    <div className="dashboardSlider">
      <>
        <div className="chartTaskSlideHeader">
          <h3>
            <Translate
              contentKey={`tk24AdysApp.krizDetay.krizCeride`}
              interpolate={{ olayTuru: krizEntity?.acilDurumTuru?.adi }}
            ></Translate>
          </h3>
          <GrantedButton grantedAuthorites={['view_ceride_liste']} className="chart-button" tag={Link} to={`/kriz-ceride/${krizEntity.id}`}>
            {translate('dashboard.cerideGoruntule')}
          </GrantedButton>
        </div>

        {loading ? (
          <KartLoadingSpinner />
        ) : (
          <>
            {krizCerideList.length > 0 ? (
              <>
                <CerideSlide cerideList={krizCerideList} totalItem={totalItems} pageObject={pageObject} />
              </>
            ) : (
              <div className="noTasksMessage">
                <div>
                  <img src={`${SERVER_URL}/content/images/icon/cerideError.svg`} className="mb-3" style={{ width: '190px' }} />
                  <h3>{translate(`entity.confirm.aktifCerideYok`)}</h3>
                  <p>{translate(`entity.confirm.aktifCerideAciklama`)}</p>
                </div>
              </div>
            )}
          </>
        )}
        <MobileStepper
          variant="dots"
          steps={Math.min(maxSteps, 3)}
          position="static"
          sx={{ maxWidth: 400, flexGrow: 1 }}
          activeStep={activeStep}
          className="dotStepper"
          backButton={
            <Button size="small" className="chartSlideNav backButton" onClick={handleBack} disabled={pageObject.page === 0}>
              {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </Button>
          }
          nextButton={
            <Button size="small" className="chartSlideNav nextButton" onClick={handleNext} disabled={pageObject.page === totalPages - 1}>
              {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </Button>
          }
        />
      </>
    </div>
  );
};

export default KrizCeride;
