import { IReferans } from './referans.model';
import { IRefUcus } from './ref-ucus.model';
import { IKisi } from './kisi.model';
import { IRefKisiAltTipi } from './ref-kisi-alt-tipi.model';
import { IRefUlke } from './ref-ulke.model';

export interface IRefYolcu {
  id?: number;
  realId?: number;
  guid?: string;
  cDate?: string;
  cUser?: number;
  mDate?: string;
  mUser?: number;
  durum?: IReferans;
  refUcus?: IRefUcus;
  kisi?: IKisi;
  kisiAltTipi?: IRefKisiAltTipi;
  adiOneki?: string;
  adi?: string;
  soyadi?: string;
  milliyet?: string;
  koltukNumarasi?: string;
  yolcuRph?: number;
  adultRph?: string;
  aciklama?: string;
  entMiGeldi?: IReferans;
  sonGuncelleZamani?: string;
  ePosta?: string;
  gsmNo?: string;
  refUlke?: IRefUlke;
  sonGuncelleyenKullanici?: {
    adi?: string | null;
    id?: number;
    realId?: number;
    sicilNo?: string | null;
    soyadi?: string | null;
  };
  travelingWithInfant?: string;
  passengerTypeCode?: string;
  pnrCode?: string;
  bilgiSonGuncellenmeZamani?: Date;
  passengerFlight?: {
    flightRPH?: string;
    passengerRPH?: string;
    isStandBy?: string;
    cabinClassCode?: string;
    dateOfJoin?: string;
    passPriorityCode?: string;
    isPassRefreshed?: string;
    principalDestination?: string;
    isTravelsOnJumpseat?: string;
    isSeatDefectInformed?: string;
  };
  ticket?: {
    ticketNumber?: string;
    couponNumber?: string;
    ticketStatus?: string;
    ticketType?: string;
  };
  boardingState?: string;
  checkinState?: string;
  additionalComment?: string;
  contactInfoList?: ContactInfo[];
  baggageInfo?: {
    count?: number;
    weight?: number;
    bagTagIssueLocation?: string;
    bagTagList?: BagTagList[];
  };
  passPriorityCode?: string;
  dateOfJoin?: string;
  bookingInfoType?: string;
  hasHatirlatma?: boolean;
}

interface BagTagList {
  tagNumber: string;
  destination: string;
  feature: string;
  isActive: string;
}

interface ContactInfo {
  email?: string;
  telephone: {
    countryAccessCode?: string;
    areaCityCode?: string;
    phoneUseType?: string;
    phoneNumber?: string;
    language?: string;
  };
  telephoneAdditional: {
    phoneUseType?: string;
    phoneNumber?: string;
    remark?: string;
  };
}

export const defaultValue: Readonly<IRefYolcu> = {};
