import React, { useEffect, useState } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Label, Input } from 'reactstrap';
import InfoTable from 'app/entities/infoTable';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { KrizMain } from 'app/component/KrizMain';
import { translate, Translate } from 'app/component/jhipster';
import GrantedButton from 'app/component/GrantedButton';
import axios from 'axios';
import { LoadingSpinner } from 'app/component/LoadingSpinner';
import { toast } from 'react-toastify';

const KrizRapor = (props: RouteComponentProps<{ id: string }>) => {
  const dispatch = useAppDispatch();
  const { match } = props;
  const isDrawer = useAppSelector(state => state.krizChat.isDrawer);

  const [modal, setModal] = useState(false);
  const [selectedGorevSahipleri, setSelectedGorevSahipleri] = useState({
    kyk: false,
    adt: false,
    istasyon: false,
  });
  const [loading, setLoading] = useState(false);
  const [selectedReport, setSelectedReport] = useState({
    type: '',
    successKey: '',
    errorKey: '',
  });

  const toggleModal = () => {
    if (!modal) {
      setSelectedGorevSahipleri({
        kyk: false,
        adt: false,
        istasyon: false,
      });
    }
    setModal(!modal);
  };

  const handleCheckboxChange = (event, key) => {
    setSelectedGorevSahipleri(prevState => ({
      ...prevState,
      [key]: event.target.checked,
    }));
  };

  const handleSelectReportClick = async (reportType, successKey, errorKey) => {
    const { kyk, adt, istasyon } = selectedGorevSahipleri;
    if (!kyk && !adt && !istasyon) {
      toast.error('Lütfen en az bir görev sahibi seçin.');
      return;
    }
    setLoading(true);
    try {
      const reportData = {
        krizId: match.params.id,
        kyk,
        adt,
        istasyon,
      };

      const responseData = await axios.post(`api/report/${reportType}`, reportData);

      try {
        const response = await axios.get(`api/ek-dosyas/download/${responseData.data.id}`, {
          responseType: 'blob',
        });
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${responseData.data.dosyaAdi}`);
        document.body.appendChild(link);
        link.click();
      } catch (error) {
        console.error('Dosya indirme hatası:', error);
      }

      toast.success(<Translate contentKey="entity.rapor.generateReport" interpolate={{ title: translate(successKey) }} />);
    } catch (error) {
      console.error('Dosya indirme hatası:', error);
      toast.error(<Translate contentKey="entity.rapor.generateReport" interpolate={{ title: translate(errorKey) }} />);
    } finally {
      setLoading(false);
      toggleModal();
    }
  };

  const handleConfirmModal = () => {
    if (modal) {
      handleSelectReportClick(selectedReport.type, selectedReport.successKey, selectedReport.errorKey);
    }
  };

  const handleOpenModalForReport = (reportType, successKey, errorKey) => {
    setSelectedReport({ type: reportType, successKey, errorKey });
    toggleModal(); // Modalı aç
  };

  const handleReportClick = async (reportType, reportAction) => {
    setLoading(true);
    try {
      const responseData = await axios.post(`api/report/${reportType}/${match.params.id}`);
      try {
        const response = await axios.get(`api/ek-dosyas/download/${responseData.data.id}`, {
          responseType: 'blob',
        });
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${responseData.data.dosyaAdi}`);
        document.body.appendChild(link);
        link.click();
      } catch (error) {
        console.error('Dosya indirme hatası:', error);
      }
      toast.success(<Translate contentKey="entity.rapor.generateReport" interpolate={{ title: translate(reportAction) }} />);
    } catch (error) {
      console.error('Dosya indirme hatası:', error);
      toast.error(<Translate contentKey="entity.rapor.error.generateReport" interpolate={{ title: translate(reportAction) }} />);
    } finally {
      setLoading(false);
    }
  };

  const raporModal = () => {
    return (
      <Modal isOpen={modal} toggle={toggleModal} centered>
        <ModalHeader toggle={toggleModal}>
          <Translate contentKey={selectedReport.successKey} />
        </ModalHeader>
        <ModalBody>
          {Object.keys(selectedGorevSahipleri).map(key => (
            <FormGroup check key={key}>
              <Label check>
                <Input
                  type="checkbox"
                  value={key}
                  checked={selectedGorevSahipleri[key]}
                  onChange={event => handleCheckboxChange(event, key)}
                />
                <Translate contentKey={`entity.gorevSahipleri.${key}`} />
              </Label>
            </FormGroup>
          ))}
        </ModalBody>
        <ModalFooter>
          <Button className="cancel-button" onClick={toggleModal}>
            <Translate contentKey="entity.action.cancel">Cancel</Translate>
          </Button>
          <Button className="save-button" onClick={handleConfirmModal}>
            <Translate contentKey="entity.action.raporUret" />
          </Button>
        </ModalFooter>
      </Modal>
    );
  };

  return (
    <>
      {loading && <LoadingSpinner />}
      {modal && raporModal()}
      <KrizMain isDrawer={isDrawer}>
        <div style={{ marginBottom: 5 }}>
          <InfoTable id={match.params.id} titleContentKey={'tk24AdysApp.krizDetay.raporlar'} />
        </div>
        <div className="d-flex align-items-center flex-wrap  mt-3" style={{ gap: '15px' }}>
          <Button className="goBackBtn view-button" tag={Link} to={`/kriz-ivr-bildirim/${match.params.id}`}>
            <Translate contentKey="global.menu.entities.krizIvrBildirimleri" />
          </Button>
          <Button className="goBackBtn view-button" tag={Link} to={`/kriz-doldurulan-formlar/${match.params.id}`}>
            <Translate contentKey="global.menu.entities.doldurulanFormlar" />
          </Button>
        </div>
        <div className="d-flex align-items-center flex-wrap mt-3" style={{ gap: '15px' }}>
          <GrantedButton
            id="create-button"
            className="view-button"
            grantedAuthorites={['kriz_ceride_edit']}
            size="sm"
            data-cy="entityCreateButton"
            onClick={() => handleOpenModalForReport('kriz-ceride-raporu', 'entity.action.cerideRaporu', 'entity.action.cerideRaporu')}
          >
            <Translate contentKey="entity.action.cerideRaporu" />
          </GrantedButton>

          <GrantedButton
            id="create-button-talimat"
            grantedAuthorites={['kriz_talimat_edit']}
            className="view-button"
            size="sm"
            data-cy="entityCreateButton"
            onClick={() => handleReportClick('kriz-talimat-raporu', 'entity.action.talimatRaporu')}
          >
            <Translate contentKey="entity.action.talimatRaporu" />
          </GrantedButton>
          <GrantedButton
            id="create-button"
            grantedAuthorites={['kriz_ozet_raporu_edit']}
            className="view-button"
            size="sm"
            data-cy="entityCreateButton"
          >
            <Translate contentKey="entity.action.gorevlerRaporu"></Translate>
          </GrantedButton>
          <GrantedButton
            id="create-button"
            grantedAuthorites={['kriz_ozet_raporu_edit']}
            className="view-button"
            size="sm"
            data-cy="entityCreateButton"
          >
            <Translate contentKey="entity.action.krizdenEtkilenenKisilerRaporu"></Translate>
          </GrantedButton>
          <GrantedButton
            id="create-button-ivr"
            grantedAuthorites={['kriz_ivr_bildirim_edit']}
            className="view-button"
            size="sm"
            data-cy="entityCreateButton"
            onClick={() => handleReportClick('ivr-sonuc-raporu', 'entity.action.ivrCagriRaporu')}
          >
            <Translate contentKey="entity.action.ivrCagriRaporu" />
          </GrantedButton>

          <GrantedButton
            id="create-button"
            grantedAuthorites={['kriz_ozet_raporu_edit']}
            className="view-button"
            size="sm"
            data-cy="entityCreateButton"
            onClick={() => handleOpenModalForReport('kriz-katilim-raporu', 'entity.action.katilimRaporu', 'entity.action.katilimRaporu')}
          >
            <Translate contentKey="entity.action.katilimRaporu"></Translate>
          </GrantedButton>
          <GrantedButton
            id="create-button"
            grantedAuthorites={['kriz_ozet_raporu_edit']}
            className="view-button"
            size="sm"
            data-cy="entityCreateButton"
          >
            <Translate contentKey="entity.action.krizDokumanRaporu"></Translate>
          </GrantedButton>
        </div>
      </KrizMain>
    </>
  );
};

export default KrizRapor;
