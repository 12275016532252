import { useAppDispatch, useAppSelector } from 'app/config/store';
import { IRefYolcu } from 'app/shared/model/ref-yolcu.model';
import React, { useEffect } from 'react';
import { Translate } from 'app/component/jhipster';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { getEntity as getRefYolcuEntity } from 'app/entities/etkilenen-kisi/reducer/ref-yolcu.reducer';
import { isNullOrEmpty } from 'app/shared/util/confirm-utils';

interface EtkilenenKisiYolcuIletisimTabProps {
  refYolcu: IRefYolcu;
  tab: number;
  loading?: boolean;
}

const EtkilenenKisiYolcuIletisimTab: React.FC<EtkilenenKisiYolcuIletisimTabProps> = ({ refYolcu, tab, loading }) => {
  const dispatch = useAppDispatch();

  const refYolcuDetail = useAppSelector(state => state.refYolcu.entity);

  useEffect(() => {
    if (tab === 3) {
      dispatch(getRefYolcuEntity(refYolcu?.id));
    }
  }, [tab]);

  return (
    <>
      {!loading ? (
        <Container>
          {(refYolcuDetail?.contactInfoList && refYolcuDetail?.contactInfoList.length > 0) ||
          !isNullOrEmpty(refYolcu?.ePosta) ||
          !isNullOrEmpty(refYolcu?.gsmNo) ? (
            <Row>
              {refYolcuDetail.contactInfoList &&
                refYolcuDetail.contactInfoList.map((contact, contactIndex) => (
                  <Col sm={12} key={contactIndex}>
                    <Card key={contactIndex} style={{ borderRadius: '8px', marginBottom: '3px' }}>
                      <CardBody>
                        <Row>
                          <Col sm={3}>
                            <p>
                              <strong>
                                <Translate contentKey="tk24AdysApp.refYolcu.email" />:
                              </strong>
                            </p>
                          </Col>
                          <Col sm={9}>
                            <p> {contact.email}</p>
                          </Col>
                        </Row>
                        <hr style={{ marginBottom: 0, marginTop: 0 }} />
                        <Row>
                          <Col sm={7}>
                            <p>
                              <strong>
                                <Translate contentKey="tk24AdysApp.refYolcu.telephone" />
                              </strong>
                              <hr style={{ marginBottom: 0, marginTop: 0 }} />
                            </p>
                            {contact.telephone && (
                              <>
                                <Row>
                                  <Col sm={4}>
                                    <p>
                                      <strong>
                                        <Translate contentKey="tk24AdysApp.refYolcu.countryAccessCode" />:
                                      </strong>
                                    </p>
                                  </Col>
                                  <Col sm={8}>
                                    <p> {contact.telephone.countryAccessCode}</p>
                                  </Col>
                                </Row>

                                <Row>
                                  <Col sm={4}>
                                    <p>
                                      <strong>
                                        <Translate contentKey="tk24AdysApp.refYolcu.areaCityCode" />:
                                      </strong>
                                    </p>
                                  </Col>
                                  <Col sm={8}>
                                    <p> {contact.telephone.areaCityCode}</p>
                                  </Col>
                                </Row>

                                <Row>
                                  <Col sm={4}>
                                    <p>
                                      <strong>
                                        <Translate contentKey="tk24AdysApp.refYolcu.phoneUseType" />:
                                      </strong>
                                    </p>
                                  </Col>
                                  <Col sm={8}>
                                    <p> {contact.telephone.phoneUseType}</p>
                                  </Col>
                                </Row>

                                <Row>
                                  <Col sm={4}>
                                    <p>
                                      <strong>
                                        <Translate contentKey="tk24AdysApp.refYolcu.phoneNumber" />:
                                      </strong>
                                    </p>
                                  </Col>
                                  <Col sm={8}>
                                    <p> {contact.telephone.phoneNumber}</p>
                                  </Col>
                                </Row>

                                <Row>
                                  <Col sm={4}>
                                    <p>
                                      <strong>
                                        <Translate contentKey="tk24AdysApp.refYolcu.language" />:
                                      </strong>
                                    </p>
                                  </Col>
                                  <Col sm={8}>
                                    <p> {contact.telephone.language}</p>
                                  </Col>
                                </Row>
                              </>
                            )}
                          </Col>
                          <Col sm={5}>
                            <p>
                              <strong>
                                <Translate contentKey="tk24AdysApp.refYolcu.telephoneAdditional" />
                              </strong>
                              <hr style={{ marginBottom: 0, marginTop: 0 }} />
                            </p>
                            {contact.telephoneAdditional && (
                              <>
                                <Row style={{ fontSize: 12 }}>
                                  <Col sm={4}>
                                    <p>
                                      <strong>
                                        <Translate contentKey="tk24AdysApp.refYolcu.phoneUseType" />:
                                      </strong>
                                    </p>
                                  </Col>
                                  <Col sm={8}>
                                    <p> {contact.telephoneAdditional.phoneUseType}</p>
                                  </Col>
                                </Row>

                                <Row>
                                  <Col sm={4}>
                                    <p>
                                      <strong>
                                        <Translate contentKey="tk24AdysApp.refYolcu.phoneNumber" />:
                                      </strong>
                                    </p>
                                  </Col>
                                  <Col sm={8}>
                                    <p> {contact.telephoneAdditional.phoneNumber}</p>
                                  </Col>
                                </Row>

                                <Row>
                                  <Col sm={4}>
                                    <p>
                                      <strong>
                                        <Translate contentKey="tk24AdysApp.refYolcu.remark" />:
                                      </strong>
                                    </p>
                                  </Col>
                                  <Col sm={8}>
                                    <p> {contact.telephoneAdditional.remark}</p>
                                  </Col>
                                </Row>
                              </>
                            )}
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                    <hr style={{ marginBottom: 0, marginTop: 0 }} />
                  </Col>
                ))}

              <Col sm={12}>
                <Card style={{ borderRadius: '8px', marginBottom: '3px' }}>
                  <CardBody>
                    <Row>
                      <Col sm={3}>
                        <p>
                          <strong>
                            <Translate contentKey="tk24AdysApp.refYolcu.email" />:
                          </strong>
                        </p>
                      </Col>
                      <Col sm={9}>
                        <p> {refYolcu.ePosta}</p>
                      </Col>
                    </Row>

                    <Row>
                      <Col sm={3}>
                        <p>
                          <strong>
                            <Translate contentKey="tk24AdysApp.refYolcu.telephone" />:
                          </strong>
                        </p>
                      </Col>
                      <Col sm={9}>
                        <p> {refYolcu.gsmNo}</p>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          ) : (
            <div className="alert alert-warning">
              <Translate contentKey="tk24AdysApp.refYolcu.home.iletisimNotFound">İletisim Not Found</Translate>
            </div>
          )}
        </Container>
      ) : (
        <></>
      )}
    </>
  );
};

export default EtkilenenKisiYolcuIletisimTab;
