import { IKriz } from 'app/shared/model/kriz.model';
import { IRefDuyuruTipi } from 'app/shared/model/ref-duyuru-tipi.model';
import { IReferans } from 'app/shared/model/referans.model';
import { IKrizKatilimciBildirim } from 'app/shared/model/kriz-katilimci-bildirim.model';
import { IRefAdtEkip } from './ref-adt-ekip.model';
import { IKrizCerideEkleyen } from './ref-ceride-ekleyen.model';
import { IKrizKatilimci } from './kriz-katilimci.model';
import { IQueryParams } from '../reducers/reducer.utils';

export interface IKrizDuyurular {
  id?: number;
  realId?: number;
  guid?: string;
  adi?: string;
  baslik?: string;
  baslikTr?: string;
  baslikEn?: string;
  icerik?: string;
  icerikTr?: string;
  icerikEn?: string;
  adtEkip?: IRefAdtEkip;
  kriz?: IKriz;
  hedefKitle?: IReferans;
  duyuruTipi?: IRefDuyuruTipi;
  duyuruDurumu?: IReferans;
  durum?: IReferans;
  krizKatilimciBildirims?: IKrizKatilimciBildirim[] | null;
  yayinlanmaZamani?: string | null;
  bitisZamani?: string;
  duyuruEkleyen?: IKrizCerideEkleyen;
  duyuruEkleyenDiger?: string;
  duyuruEkleyenKrizKatilimci?: IKrizKatilimci;
  ekDosyaSayisi?: number;
}

export interface IFilteredDuyuruQueryParams extends IQueryParams {
  entity?: IKrizDuyurular;
  id?: string | number;
}

export const defaultValue: Readonly<IKrizDuyurular> = {};
