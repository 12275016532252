import { useAppDispatch, useAppSelector } from 'app/config/store';
import { IRefYolcu } from 'app/shared/model/ref-yolcu.model';
import React, { useEffect } from 'react';
import { Translate } from 'app/component/jhipster';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { getEntity as getRefYolcuEntity } from 'app/entities/etkilenen-kisi/reducer/ref-yolcu.reducer';

interface EtkilenenKisiYolcuBagajTabProps {
  refYolcu: IRefYolcu;
  tab: number;
  loading?: boolean;
}

const EtkilenenKisiYolcuBagajTab: React.FC<EtkilenenKisiYolcuBagajTabProps> = ({ refYolcu, tab, loading }) => {
  const dispatch = useAppDispatch();

  const refYolcuDetail = useAppSelector(state => state.refYolcu.entity);

  useEffect(() => {
    if (tab === 6) {
      dispatch(getRefYolcuEntity(refYolcu?.id));
    }
  }, [tab]);

  return (
    <>
      {!loading ? (
        <Container>
          {refYolcuDetail.baggageInfo ? (
            <>
              <Row>
                <Col sm={3}>
                  <p>
                    <strong>
                      <Translate contentKey="tk24AdysApp.refYolcu.count" />:
                    </strong>
                  </p>
                </Col>
                <Col sm={9}>
                  <p> {refYolcuDetail.baggageInfo?.count} </p>
                </Col>
              </Row>
              <Row>
                <Col sm={3}>
                  <p>
                    <strong>
                      <Translate contentKey="tk24AdysApp.refYolcu.weight" />:
                    </strong>
                  </p>
                </Col>
                <Col sm={9}>
                  <p> {refYolcuDetail.baggageInfo?.weight} </p>
                </Col>
              </Row>
              <Row>
                <Col sm={3}>
                  <p>
                    <strong>
                      <Translate contentKey="tk24AdysApp.refYolcu.bagTagIssueLocation" />:
                    </strong>
                  </p>
                </Col>
                <Col sm={9}>
                  <p> {refYolcuDetail.baggageInfo?.bagTagIssueLocation} </p>
                </Col>
              </Row>
              <Row>
                {refYolcuDetail.baggageInfo?.bagTagList &&
                  refYolcuDetail.baggageInfo?.bagTagList.map((bag, bagIndex) => (
                    <Col key={bagIndex} sm={12}>
                      <Card style={{ borderRadius: '8px', marginBottom: '3px' }}>
                        <CardBody>
                          <Row>
                            <Col sm={3}>
                              <p>
                                <strong>
                                  <Translate contentKey="tk24AdysApp.refYolcu.bagTagNumber" />:
                                </strong>
                              </p>
                            </Col>
                            <Col sm={9}>
                              <p> {bag.tagNumber}</p>
                            </Col>
                          </Row>
                          <Row>
                            <Col sm={3}>
                              <p>
                                <strong>
                                  <Translate contentKey="tk24AdysApp.refYolcu.bagDestination" />:
                                </strong>
                              </p>
                            </Col>
                            <Col sm={9}>
                              <p> {bag.destination}</p>
                            </Col>
                          </Row>
                          <Row>
                            <Col sm={3}>
                              <p>
                                <strong>
                                  <Translate contentKey="tk24AdysApp.refYolcu.feature" />:
                                </strong>
                              </p>
                            </Col>
                            <Col sm={9}>
                              <p> {bag.feature}</p>
                            </Col>
                          </Row>
                          <Row>
                            <Col sm={3}>
                              <p>
                                <strong>
                                  <Translate contentKey="tk24AdysApp.refYolcu.bagIsActive" />:
                                </strong>
                              </p>
                            </Col>
                            <Col sm={9}>
                              <p> {bag.isActive ? 'True' : 'False'}</p>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                  ))}
              </Row>
            </>
          ) : (
            <div className="alert alert-warning">
              <Translate contentKey="tk24AdysApp.refYolcu.home.baggageNotFound">Baggage Not Found</Translate>
            </div>
          )}
        </Container>
      ) : (
        <></>
      )}
    </>
  );
};

export default EtkilenenKisiYolcuBagajTab;
