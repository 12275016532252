import React, { useEffect } from 'react';
import 'app/entities/style.scss';
import { RouteComponentProps } from 'react-router-dom';
import { translate } from 'app/component/jhipster';
import InfoTable from 'app/entities/infoTable';
import { Box, Tab, Tabs } from '@mui/material';
import { CustomTabPanel } from 'app/component/CustomTabPanel';
import KrizMedyaAcilDurum from './kriz-medya-acil-durum';
import KrizMedyaDDMS from './kriz-medya-ddms';
import KrizMedyaDisDokuman from './kriz-medya-dis-dokuman';
import KrizMedyaFormlar from './kriz-medya-formlar';
import { KrizMain } from 'app/component/KrizMain';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntity as getKrizEntity } from '../kriz/kriz.reducer';
import { LoadingSpinner } from 'app/component/LoadingSpinner';
import { reset as resetEkDosya } from '../ek-dosya/ek-dosya.reducer';
import { getEntityBySicilId } from '../kriz-katilimci/kriz-katilimci.reducer';
import styled from 'styled-components';
import GrantedTab from 'app/component/GrantedTab';

const StyledTab = styled(Tab)`
  &:hover {
    box-shadow: 0px -2px 4px 0px #0000000f;
  }
`;

const KrizMedyaDetail = (props: RouteComponentProps<{ id: string }>) => {
  const { match } = props;
  const dispatch = useAppDispatch();
  const isDrawer = useAppSelector(state => state.krizChat.isDrawer);
  const krizLoading = useAppSelector(state => state.kriz.loading);
  const katilimciLoading = useAppSelector(state => state.krizKatilimci.loading);
  const sicilNo = useAppSelector(state => state.authentication.account.sicilNo);

  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    dispatch(resetEkDosya());
    setValue(newValue);
  };

  useEffect(() => {
    dispatch(getKrizEntity(match.params.id));
    dispatch(getEntityBySicilId({ krizId: match.params.id, sicilId: sicilNo }));
    // dispatch(
    //   getKatilimciEntities({
    //     page: 0,
    //     size: 5,
    //     sort: 'asc',
    //     query: `sicil.equals=${sicilNo}`,
    //   })
    // );
  }, []);

  const grantedTabConfig = {
    headerStyle: { borderBottom: 1, borderColor: 'divider' },
    headerClassName: 'krizAnaCerides',
    tabsClassName: 'tabKrizCerideAnaKategori',
    tabStyle: { width: '100%' },
    tabClassName: 'krizCeride',
    tabs: [
      {
        authority: 'view_medya_ddms',
        label: translate('tk24AdysApp.krizMedya.header.ddmsTitle'),
        className: 'tabKrizCerideTitle',
        component: <KrizMedyaDDMS {...props} krizId={props.match.params.id} />,
      },
      {
        authority: 'view_medya_dis_dokuman',
        label: translate('tk24AdysApp.krizMedya.header.disDokumanTitle'),
        className: 'tabKrizCerideTitle',
        component: <KrizMedyaDisDokuman {...props} krizId={props.match.params.id} />,
      },
      {
        authority: 'view_medya_kriz',
        label: translate('tk24AdysApp.krizMedya.header.krizTitle'),
        className: 'tabKrizCerideTitle',
        component: <KrizMedyaAcilDurum />,
      },
      {
        authority: 'view_medya_kriz',
        label: translate('tk24AdysApp.krizMedya.header.formlarTitle'),
        className: 'tabKrizCerideTitle',
        component: <KrizMedyaFormlar {...props} />,
      },
    ],
  };

  return (
    <KrizMain isDrawer={isDrawer}>
      <div style={{ marginBottom: 5 }}>
        <InfoTable id={match.params.id} titleContentKey={'tk24AdysApp.krizDetay.medya'} />
      </div>
      {krizLoading || katilimciLoading ? <LoadingSpinner /> : <GrantedTab {...grantedTabConfig} />}
    </KrizMain>
  );
};

export default KrizMedyaDetail;
