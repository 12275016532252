import { hasAnyAuthority } from 'app/config/store';
import React from 'react';
import { Translate } from './jhipster';

export const HasAuthComponent = ({ grantedAuthorites, children }) => {
  return (
    <>
      {!hasAnyAuthority(grantedAuthorites) ? (
        <div className="insufficient-authority">
          <div className="alert alert-danger">
            <Translate contentKey="error.http.403" />
          </div>
        </div>
      ) : (
        children
      )}
    </>
  );
};
