import { IKrizUcus } from 'app/shared/model/kriz-ucus.model';
import { IReferans } from 'app/shared/model/referans.model';

export interface IUcusKisiListesi {
  id?: number;
  guid?: string;
  adi?: string | null;
  soyadi?: string | null;
  eposta?: string | null;
  telefon?: string | null;
  murettebatGoreviVb?: string | null;
  murettabatSinifiVb?: string | null;
  cDate?: string;
  cUser?: number;
  mDate?: string;
  mUser?: number;
  ucus?: IKrizUcus | null;
  yolcuTipi?: IReferans | null;
  durum?: IReferans;
}

export const defaultValue: Readonly<IUcusKisiListesi> = {};
