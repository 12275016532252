import { IRefGorev } from 'app/shared/model/ref-gorev.model';
import { IBaseModel } from './base.model';
import { IRefOrganisazyon } from './ref-organizasyon.model';
import { IQueryParams } from '../reducers/reducer.utils';

export interface IRefGorevSorBirim extends IBaseModel {
  realId?: number;
  gorev?: IRefGorev;
  org?: IRefOrganisazyon;
}

export interface IRefGorevSorBirimParams extends IQueryParams {
  entity?: IRefGorevSorBirim;
}

export const defaultValue: Readonly<IRefGorevSorBirim> = {};
