import React, { useState, useEffect, useCallback } from 'react';
import { RouteComponentProps, Link } from 'react-router-dom';
import { Input } from 'reactstrap';
import { Translate, translate } from 'app/component/jhipster';
import { deleteEntity, getEntities, getTotalEntities } from './ref-adt-form.reducer';
import { ASC, DESC } from 'app/shared/util/pagination.constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import RefAdtFormUpdate from './ref-adt-form-update';
import { CustomDeleteEntityConfirm, isNullOrEmpty } from 'app/shared/util/confirm-utils';
import { getEntities as getReferans } from '../referans/referans.reducer';
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material';
import CustomPagination from 'app/component/CustomPagination';
import GrantedButton from 'app/component/GrantedButton';
import { SERVER_URL } from 'app/config/constants';
import { IQueryParams } from 'app/shared/reducers/reducer.utils';
import { IRefAdtForm } from 'app/shared/model/ref-adt-form.model';
import { LoadingSpinner } from 'app/component/LoadingSpinner';
import AdtForm from 'app/component/AdtForm';
import ExcelDownloadButton from 'app/component/ExcelDownload';
import SortIcon from 'app/component/SortIcon';
import { localeLanguage, upperOnlyFirstLetter } from 'app/shared/util/util';
import { ReferansEnum } from 'app/constants/ReferansEnum';
import { getEntities as getRefEkDosyaTipis } from 'app/entities/ref-ek-dosya-tipi/ref-ek-dosya-tipi.reducer';
import { getFilteredEntities as getFilterRefAdtFormEkDosyaEntities } from 'app/entities/ek-dosya/ek-dosya.reducer';
import TableNameEnum from 'app/constants/TableNameEnum';

export const RefAdtForm = (props: RouteComponentProps<{ url: string }>) => {
  const dispatch = useAppDispatch();

  const refAdtFormList = useAppSelector(state => state.refAdtForm.entities);
  const loading = useAppSelector(state => state.refAdtForm.loading);
  const updating = useAppSelector(state => state.refAdtForm.updating);
  const totalItem = useAppSelector(state => state.refAdtForm.totalItems);
  const [isViewing, setIsViewing] = useState(null);
  const refFormTipis = useAppSelector(state => state.referans.entities);

  const [selectedForm, setSelectedForm] = useState(null);
  const [isFormModal, setIsFormModal] = useState(false);

  const [isAdtFormUpdateModal, setIsAdtFormUpdateModal] = useState(false);
  const [isAdtFormDetailModal, setIsAdtFormDetailModal] = useState(false);
  const [selectedAdtForm, setSelectedAdtForm] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [adtFormLoading, setAdtFormLoading] = useState(false);

  const [search, setSearch] = useState('');

  const [pageObject, setPageObject] = useState<IQueryParams>(useAppSelector(state => state.paginate.pageObject));
  const ExcelSampleData = [
    { key: 'adiTr', titleContentKey: 'global.languageGlobal.adiTr' },
    { key: 'adiEn', titleContentKey: 'global.languageGlobal.adiEn' },
    { key: 'formSahibi.adi', titleContentKey: 'tk24AdysApp.refAdtForm.formSahibi' },
    { key: 'formTipi.adi', titleContentKey: 'tk24AdysApp.refAdtForm.formTipi' },
  ];

  useEffect(() => {
    dispatch(getReferans({ sort: `adi${upperOnlyFirstLetter(localeLanguage)},asc`, size: 999, page: 0 }));
    dispatch(getRefEkDosyaTipis({ sort: 'asc', size: 9999, page: 0 }));
  }, []);
  const getAllEntities = () => {
    dispatch(getEntities(pageObject));
  };

  useEffect(() => {
    getAllEntities();
  }, [pageObject.page, pageObject.query, pageObject.size, pageObject.sort]);

  const getRefAdtFormEkDosya = adtForm => {
    filteredRefAdtFormEkDosyaEntities(adtForm);
  };

  const filteredRefAdtFormEkDosyaEntities = adtForm => {
    dispatch(
      getFilterRefAdtFormEkDosyaEntities({
        ilgiliTabloKayitId: adtForm.realId,
        ilgiliTablo: TableNameEnum.RefAdtForm,
      })
    );
  };

  useEffect(() => {
    if (search.length > 2) {
      setPageObject(prevPageObject => ({
        ...prevPageObject,
        page: 0,
        query: prevPageObject.query + `&globalFilter=${search}`,
      }));
    } else if (search.length === 0 && !isNullOrEmpty(pageObject.query)) {
      const keyToDelete = 'globalFilter';
      const updatedParams = pageObject.query
        .split('&')
        .filter(param => {
          const [key, _] = param.split('=');
          return key !== keyToDelete;
        })
        .join('&');

      setPageObject({ ...pageObject, page: 0, query: updatedParams });
    }
  }, [search]);

  const sort = p => () => {
    const orderBy = pageObject.sort.split(',')[1];
    setPageObject({ ...pageObject, sort: `${p},${orderBy === ASC ? DESC : ASC}` });
  };

  const handlePageChange = (pageNumber, newItemsPerPage = pageObject.size) => {
    if (newItemsPerPage !== pageObject.size) {
      setPageObject({ ...pageObject, page: pageNumber - 1, size: newItemsPerPage });
    } else {
      setPageObject({ ...pageObject, page: pageNumber - 1 });
    }
  };

  const handleCloseUpdateForm = () => {
    if (isAdtFormUpdateModal) {
      setIsAdtFormUpdateModal(false);
    }
    setSelectedAdtForm(null);
  };

  const handleCloseFormModal = () => {
    setIsFormModal(false);
    setSelectedForm(null);
  };

  const confirmAdtFormDelete = form => {
    setSelectedAdtForm(form);
    setIsDeleteModalOpen(true);
  };

  const handleDeleteConfirm = useCallback(async () => {
    if (selectedAdtForm) {
      const entity = { ...pageObject, entity: selectedAdtForm };

      if (refAdtFormList.length === 1) {
        entity.page = pageObject.page - 1;
        setPageObject({ ...pageObject, page: pageObject.page - 1 });
      }

      await dispatch(deleteEntity(entity));
      handleDeleteCancel();
    }
  }, [selectedAdtForm, dispatch]);

  const handleDeleteCancel = () => {
    setIsDeleteModalOpen(false);
    setSelectedAdtForm(null);
  };

  const fetchTotalAdtFormData = async (): Promise<IRefAdtForm[]> => {
    try {
      const response = (await dispatch(getTotalEntities({ ...pageObject, size: totalItem }))) as { payload: { data: IRefAdtForm[] } };

      return response.payload.data || [];
    } catch (error) {
      console.error('Error fetching data:', error);
      return [];
    }
  };

  const handleAdtFormSahibiChange = event => {
    if (!isNullOrEmpty(event.target.value)) {
      setPageObject(prevPageObject => ({
        ...prevPageObject,
        page: 0,
        query: prevPageObject.query + `&formSahibiId.equals=${event.target.value}`,
      }));
    } else {
      const keyToDelete = 'formSahibiId.equals';
      const updatedParams = pageObject.query
        .split('&')
        .filter(param => {
          const [key, _] = param.split('=');
          return key !== keyToDelete;
        })
        .join('&');

      setPageObject({ ...pageObject, page: 0, query: updatedParams });
    }
  };

  const handleAdtFormTipiChange = event => {
    if (!isNullOrEmpty(event.target.value)) {
      setPageObject(prevPageObject => ({
        ...prevPageObject,
        page: 0,
        query: prevPageObject.query + `&formTipiId.equals=${event.target.value}`,
      }));
    } else {
      const keyToDelete = 'formTipiId.equals';
      const updatedParams = pageObject.query
        .split('&')
        .filter(param => {
          const [key, _] = param.split('=');
          return key !== keyToDelete;
        })
        .join('&');

      setPageObject({ ...pageObject, page: 0, query: updatedParams });
    }
  };

  const RefAdtFormUpdateModal = () => {
    return (
      <RefAdtFormUpdate
        handleCloseForm={handleCloseUpdateForm}
        formValues={selectedAdtForm}
        isOpen={isAdtFormUpdateModal}
        pageObject={pageObject}
        setIsLoading={setAdtFormLoading}
        isViewing={isViewing}
      ></RefAdtFormUpdate>
    );
  };

  return (
    <div>
      {(loading || updating || adtFormLoading) && <LoadingSpinner />}
      {isAdtFormUpdateModal && RefAdtFormUpdateModal()}
      {isFormModal && (
        <AdtForm
          form={selectedForm}
          isAnketFormModal={isFormModal}
          kullaniciAdi={''}
          etkilenenKisi={null}
          handleCloseFormModal={handleCloseFormModal}
          isPreview={true}
        />
      )}

      {selectedAdtForm && (
        <CustomDeleteEntityConfirm
          entity={selectedAdtForm}
          entityName="refAdtForm"
          isOpen={isDeleteModalOpen}
          onConfirm={handleDeleteConfirm}
          onCancel={handleDeleteCancel}
        />
      )}

      <div style={{ display: 'flex', gap: 8, textAlign: 'center' }}>
        <p className="icon-text">
          <a className="link-a-text" href={`${SERVER_URL}`}>
            <img width={13} height={13} src={`${SERVER_URL}/content/images/icon/faHouse.svg`} />
          </a>
        </p>
        <p className="separator"> / </p>
        <p className="link-text">
          {' '}
          <Translate contentKey="tk24AdysApp.refAdtForm.home.title" />{' '}
        </p>
      </div>

      <h2 id="ref-adt-form-heading" data-cy="RefAdtFormHeading" className="title-frame">
        <a className="link-a-text" onClick={() => props.history.goBack()}>
          <img width={27.5} height={22.5} src={`${SERVER_URL}/content/images/icon/faArrowLeft.svg`} />{' '}
        </a>
        <p className="title-text">
          <Translate contentKey="tk24AdysApp.refAdtForm.home.title" />
        </p>
      </h2>

      <div className="content-area">
        <div className="search-frame">
          <div className="seachiconposition">
            <img className="seachicon" width={24} height={24} src={`${SERVER_URL}/content/images/icon/faSearch.svg`} />
            <Input
              value={search}
              onChange={e => setSearch(e.target.value)}
              placeholder={translate('entity.action.search')}
              className="search-input"
            />
          </div>

          <div className="create-area">
            <Box sx={{ minWidth: 220, maxWidth: 220 }}>
              <FormControl fullWidth size={window.innerWidth < 1600 ? 'small' : 'medium'}>
                <InputLabel id="form-tipi-label" className="select-input">
                  <Translate contentKey="tk24AdysApp.refAdtForm.formTipi" />
                </InputLabel>
                <Select
                  labelId="form-tipi-label"
                  id="form-tipi"
                  label={translate('tk24AdysApp.refAdtForm.formTipi')}
                  onChange={handleAdtFormTipiChange}
                  className="select-input"
                >
                  <MenuItem value="">
                    <Translate contentKey="entity.action.all" />
                  </MenuItem>
                  {refFormTipis &&
                    refFormTipis.length > 0 &&
                    refFormTipis
                      .filter(item => item.tipi === 'FORM_TIPI')
                      .map(item => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.adi}
                        </MenuItem>
                      ))}
                </Select>
              </FormControl>
            </Box>
            <Box sx={{ minWidth: 220, maxWidth: 220 }}>
              <FormControl fullWidth size={window.innerWidth < 1600 ? 'small' : 'medium'}>
                <InputLabel id="form-sahibi-label" className="select-input">
                  <Translate contentKey="tk24AdysApp.refAdtForm.formSahibi" />
                </InputLabel>
                <Select
                  labelId="form-sahibi-label"
                  id="form-sahibi"
                  label={translate('tk24AdysApp.refAdtForm.formSahibi')}
                  onChange={handleAdtFormSahibiChange}
                  className="select-input"
                >
                  <MenuItem value="">
                    <Translate contentKey="entity.action.all" />
                  </MenuItem>
                  {refFormTipis &&
                    refFormTipis.length > 0 &&
                    refFormTipis
                      .filter(
                        item =>
                          (item.tipi === 'GOREV_SAHIBI' && item.id === ReferansEnum.GorevSahibi.ADT) ||
                          item.id === ReferansEnum.GorevSahibi.Istasyon
                      )
                      .map(item => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.adi}
                        </MenuItem>
                      ))}
                </Select>
              </FormControl>
            </Box>
            <ExcelDownloadButton
              excelSampleData={ExcelSampleData}
              fetchData={fetchTotalAdtFormData}
              className={'download-button'}
              exportName={translate('tk24AdysApp.refAdtForm.home.title')}
              grantedAuthorites={['ref_adt_form_export']}
            />
            <GrantedButton
              grantedAuthorites={['ref_adt_form_edit']}
              style={{ marginLeft: 15 }}
              onClick={() => {
                setIsAdtFormUpdateModal(true);
                setIsViewing(false);
              }}
              className="create-button"
              size="sm"
              data-cy="entityCreateButton"
            >
              <Tooltip title={<Translate contentKey="tk24AdysApp.refAdtForm.home.createLabel" />}>
                <img width={14} height={14} src={`${SERVER_URL}/content/images/icon/faPlus.svg`} />
              </Tooltip>
              <span className="show-on-lg">
                <Translate contentKey="tk24AdysApp.refAdtForm.home.createLabel" />
              </span>
            </GrantedButton>
          </div>
        </div>
        <div className="table-responsive" style={{ marginTop: 5 }}>
          <TableContainer className="content-table">
            {refAdtFormList && refAdtFormList.length > 0 ? (
              <Table aria-label="a dense table">
                <TableHead className="content-table-title-area">
                  <TableRow className="table-head-row">
                    <TableCell align="left" className="table-title-cell hand" onClick={sort('adiTr')}>
                      <div className="tableHeaderContainer">
                        <Translate contentKey="global.languageGlobal.adiTrTitle">Adi Tr</Translate>&nbsp;
                        <SortIcon column="adiTr" activeSortColumn={pageObject.sort} />
                      </div>
                    </TableCell>
                    <TableCell align="left" className="table-title-cell hand" onClick={sort('adiEn')}>
                      <div className="tableHeaderContainer">
                        <Translate contentKey="global.languageGlobal.adiEnTitle">Adi En</Translate>&nbsp;
                        <SortIcon column="adiEn" activeSortColumn={pageObject.sort} />
                      </div>
                    </TableCell>
                    <TableCell
                      align="left"
                      className="table-title-cell hand"
                      onClick={sort(`formSahibi.adi${upperOnlyFirstLetter(localeLanguage)}`)}
                    >
                      <div className="tableHeaderContainer">
                        <Translate contentKey="tk24AdysApp.refAdtForm.formSahibi">formSahibi</Translate>&nbsp;
                        <SortIcon column={`formSahibi.adi${upperOnlyFirstLetter(localeLanguage)}`} activeSortColumn={pageObject.sort} />
                      </div>
                    </TableCell>
                    <TableCell
                      align="left"
                      className="table-title-cell hand"
                      onClick={sort(`formTipi.adi${upperOnlyFirstLetter(localeLanguage)}`)}
                    >
                      <div className="tableHeaderContainer">
                        <Translate contentKey="tk24AdysApp.refAdtForm.formTipi">formTipi</Translate>&nbsp;
                        <SortIcon column={`formTipi.adi${upperOnlyFirstLetter(localeLanguage)}`} activeSortColumn={pageObject.sort} />
                      </div>
                    </TableCell>
                    <TableCell className="sticky-right-header"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {refAdtFormList.map(refAdtForm => (
                    <TableRow key={refAdtForm.id} className="table-cell-row">
                      <TableCell component="th" scope="row" className="table-content-cell">
                        {refAdtForm?.adiTr}
                      </TableCell>
                      <TableCell component="th" scope="row" className="table-content-cell">
                        {refAdtForm?.adiEn}
                      </TableCell>
                      <TableCell component="th" scope="row" className="table-content-cell">
                        {refAdtForm?.formSahibi?.adi}
                      </TableCell>
                      <TableCell component="th" scope="row" className="table-content-cell">
                        {refAdtForm?.formTipi?.adi}
                      </TableCell>

                      <TableCell align="right" className="sticky-right">
                        <div className="btn-group flex-btn-group-container">
                          {refAdtForm.formTipi?.id === 121 ? null : (
                            <>
                              <GrantedButton
                                grantedAuthorites={['ref_adt_form_doldurulan_read']}
                                className="edit-button"
                                onClick={() => {
                                  setSelectedForm(refAdtForm);
                                  setIsFormModal(true);
                                }}
                                size="sm"
                                data-cy="entityEditButton"
                              >
                                <Tooltip title={<Translate contentKey="tk24AdysApp.refAdtFormBolum.preview">preview</Translate>}>
                                  <img src={`${SERVER_URL}/content/images/icon/previewIcon.svg`} />
                                </Tooltip>
                                <span className="show-on-lg">
                                  <Translate contentKey="tk24AdysApp.refAdtFormBolum.preview">preview</Translate>
                                </span>
                              </GrantedButton>
                              <GrantedButton
                                grantedAuthorites={['ref_adt_form_bolum_read']}
                                className="edit-button"
                                style={{ marginLeft: 5 }}
                                tag={Link}
                                to={`/ref-adt-form-bolum/${refAdtForm.id}`}
                                size="sm"
                                data-cy="entityEditButton"
                              >
                                <Tooltip title={<Translate contentKey="tk24AdysApp.refAdtForm.units">Units</Translate>}>
                                  <img src={`${SERVER_URL}/content/images/icon/hamburgerIcon.svg`} />
                                </Tooltip>
                                <span className="show-on-lg">
                                  <Translate contentKey="tk24AdysApp.refAdtForm.units">Units</Translate>
                                </span>
                              </GrantedButton>
                            </>
                          )}
                          <GrantedButton
                            grantedAuthorites={['ref_adt_form_edit']}
                            className="edit-button"
                            style={{ marginLeft: 5 }}
                            onClick={() => {
                              setSelectedAdtForm(refAdtForm);
                              setIsViewing(false);
                              getRefAdtFormEkDosya(refAdtForm);
                              setIsAdtFormUpdateModal(true);
                            }}
                            size="sm"
                            data-cy="entityEditButton"
                          >
                            <Tooltip title={<Translate contentKey="entity.action.edit">Edit</Translate>}>
                              <img width={14} height={14} src={`${SERVER_URL}/content/images/icon/faPencil.svg`} />
                            </Tooltip>
                            <span className="show-on-lg">
                              <Translate contentKey="entity.action.edit">Edit</Translate>
                            </span>
                          </GrantedButton>
                          <GrantedButton
                            grantedAuthorites={['ref_adt_form_read']}
                            className="view-button"
                            onClick={() => {
                              setSelectedAdtForm(refAdtForm);
                              setIsViewing(true);
                              getRefAdtFormEkDosya(refAdtForm);
                              setIsAdtFormUpdateModal(true);
                            }}
                            style={{ marginLeft: 5 }}
                            size="sm"
                            data-cy="entityDetailsButton"
                          >
                            <Tooltip title={<Translate contentKey="entity.action.view">View</Translate>}>
                              <img width={15.2} height={11.2} src={`${SERVER_URL}/content/images/icon/faEye.svg`} />
                            </Tooltip>
                            <span className="show-on-lg">
                              <Translate contentKey="entity.action.view">View</Translate>
                            </span>
                          </GrantedButton>
                          <GrantedButton
                            grantedAuthorites={['ref_adt_form_delete']}
                            onClick={() => confirmAdtFormDelete(refAdtForm)}
                            style={{ marginLeft: 5 }}
                            className="delete-button"
                            size="sm"
                            data-cy="entityDeleteButton"
                          >
                            <Tooltip title={<Translate contentKey="entity.action.delete">Delete</Translate>}>
                              <img width={11.2} height={12.8} src={`${SERVER_URL}/content/images/icon/faTrashCan.svg`} />
                            </Tooltip>
                            <span className="show-on-lg">
                              <Translate contentKey="entity.action.delete">Delete</Translate>
                            </span>
                          </GrantedButton>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            ) : (
              !loading &&
              !updating && (
                <div className="alert alert-warning">
                  <Translate contentKey="tk24AdysApp.refAdtForm.home.notFound">No Ref Adt Form found</Translate>
                </div>
              )
            )}
          </TableContainer>
        </div>
        <CustomPagination
          currentPage={pageObject.page + 1}
          currentPageSize={pageObject.size}
          totalItems={totalItem}
          handlePageChange={handlePageChange}
        />
      </div>
    </div>
  );
};

export default RefAdtForm;
