import { IRefBildirimSablonu } from './bildirim-sablonu.model';
import { IReferans } from './referans.model';
import { IQueryParams } from '../reducers/reducer.utils';

export interface IRefBildirimSablonuKisiTipi {
  id?: number;
  guid?: string;
  cDate?: string;
  cUser?: number;
  mDate?: string;
  mUser?: number;
  bildirimSablon?: IRefBildirimSablonu;
  bildirimKisiTipi?: IReferans;
}

export interface IRefBildirimSablonuKisiTipiParams extends IQueryParams {
  entity?: IRefBildirimSablonuKisiTipi;
}

export const defaultValue: Readonly<IRefBildirimSablonuKisiTipi> = {};
