import React, { useEffect, useState } from 'react';
import { TextFormat, Translate } from 'app/component/jhipster';
import { Container } from 'reactstrap';
import { IRefMurettebat } from 'app/shared/model/ref-murettebat.model';
import axios from 'axios';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { filterBilgiFields } from 'app/constants/RefMurettebatDetayEnum';
import { APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { LoadingSpinner } from 'app/component/LoadingSpinner';

interface EtkilenenKisiMurettebatBossTabProps {
  refMurettebat: IRefMurettebat;
  tab: number;
}

const EtkilenenKisiMurettebatBossTab: React.FC<EtkilenenKisiMurettebatBossTabProps> = ({ refMurettebat, tab }) => {
  const [bossLoading, setBossLoading] = useState(false);
  const [murettebatDetayBilgileri, setMurettebatDetayBilgileri] = useState([]);

  useEffect(() => {
    if (tab === 3) {
      getDetayBilgileri(refMurettebat);
    }
  }, [tab]);

  const getDetayBilgileri = murettebat => {
    setBossLoading(true);
    const murettebatIdUrl = `api/ref-murettebat-detays?refMurettebat.equals=${murettebat?.id}&size=2000`;
    axios.get(murettebatIdUrl).then(response => {
      setMurettebatDetayBilgileri(response.data);
      setBossLoading(false);
    });
  };

  const parseBilgi = bilgi => {
    try {
      return JSON.parse(bilgi);
    } catch (error) {
      console.error('Failed to parse bilgi:', error);
      return {};
    }
  };

  const groupByBilgiTipi = data => {
    return data.reduce((acc, item) => {
      const key = item.bilgiTipi;
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(item);
      return acc;
    }, {});
  };

  const groupedData = groupByBilgiTipi(murettebatDetayBilgileri);

  return (
    <>
      {bossLoading && <LoadingSpinner />}
      {!bossLoading ? (
        <Container>
          {Object.keys(groupedData).map(bilgiTipi => (
            <>
              <Typography className="accordion-typography mt-3" gutterBottom component="div">
                <Translate contentKey={`tk24AdysApp.refMurettebat.detayTab.${bilgiTipi}`}></Translate>
              </Typography>
              <TableContainer key={bilgiTipi} className="content-table mb-4" style={{ backgroundColor: '#ffffff' }}>
                <Table className="accordion-table">
                  <TableHead>
                    <TableRow className="accordion-table-title-row">
                      {Object.keys(filterBilgiFields(parseBilgi(groupedData[bilgiTipi][0].bilgi), bilgiTipi)).map(key => (
                        <TableCell className="accordion-table-title-cell" align="left" key={key}>
                          <Translate contentKey={`tk24AdysApp.refMurettebat.detayTab.${key}`}>detayTab baslik</Translate>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {groupedData[bilgiTipi].map((murettebat, index) => {
                      const bilgiData = filterBilgiFields(parseBilgi(murettebat.bilgi), bilgiTipi);
                      return (
                        <TableRow key={index} className="accordion-table-content-row">
                          {Object.entries(bilgiData).map(([key, value], i) => (
                            <TableCell className="accordion-table-content-cell" component="th" scope="row" key={i}>
                              {value !== null ? (
                                key.toLowerCase().includes('date') ? (
                                  <TextFormat type="date" value={value?.toString()} format={APP_LOCAL_DATE_FORMAT} />
                                ) : (
                                  value?.toString()
                                )
                              ) : (
                                ''
                              )}
                            </TableCell>
                          ))}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          ))}
          {!bossLoading && murettebatDetayBilgileri.length === 0 && (
            <div className="alert alert-warning">
              <Translate contentKey="tk24AdysApp.refMurettebat.home.detailNotFound">No refMurettebat found</Translate>
            </div>
          )}
        </Container>
      ) : (
        <></>
      )}
    </>
  );
};

export default EtkilenenKisiMurettebatBossTab;
