import { IReferans } from 'app/shared/model/referans.model';
import { IKrizKatilimciGorev } from 'app/shared/model/kriz-katilimci-gorev.model';
import { IRefGorevSorBirim } from 'app/shared/model/ref-gorev-sor-birim.model';
import { IRefKrizTuruKatilimciGorev } from 'app/shared/model/ref-kriz-turu-katilimci-gorev.model';
import { IQueryParams } from '../reducers/reducer.utils';

export interface IRefGorev {
  id?: number;
  realId?: number;
  guid?: string;
  adi?: string;
  adiTr?: string;
  adiEn?: string;
  aciklamaTr?: string | null;
  aciklamaEn?: string | null;
  gorevSahibi?: IReferans;
  sure?: number | null;
  cDate?: string;
  cUser?: number;
  mDate?: string;
  mUser?: number;
  durum?: IReferans;
  krizKatilimciGorevs?: IKrizKatilimciGorev[] | null;
  refGorevSorBirims?: IRefGorevSorBirim[] | null;
  refKrizTuruKatilimciGorevs?: IRefKrizTuruKatilimciGorev[] | null;
  sorumluBirims?: IRefGorevSorBirim[] | null;
}

export interface IRefGorevParams extends IQueryParams {
  entity?: IRefGorev;
}

export const defaultValue: Readonly<IRefGorev> = {};
