import { IQueryParams } from '../reducers/reducer.utils';
import { IKisi } from './kisi.model';
import { IReferans } from './referans.model';

export interface IRefKisiAltTipi {
  id?: number;
  guid?: string;
  adi?: string;
  adiTr?: string;
  adiEn?: string;
  kisiTipi?: IReferans;
  cDate?: string;
  cUser?: number;
  mDate?: string;
  mUser?: number;
  kisaAdi?: string;
  realId?: any;
}

export interface IRefKisiAltTipiParams extends IQueryParams {
  entity?: IRefKisiAltTipi;
  kisiTipiId?: IKisi;
}

export const defaultValue: Readonly<IRefKisiAltTipi> = {};
