import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useRef, useState } from 'react';
import { Button, Card, CardText, Col, Container, Input, Row } from 'reactstrap';
import axios from 'axios';
import { TextFormat, Translate, translate } from 'app/component/jhipster';
import { APP_DATE_FORMAT, APP_DATETIME_FORMAT, APP_LOCAL_TIME_FORMAT, SERVER_URL } from 'app/config/constants';
import { ReferansEnum } from 'app/constants/ReferansEnum';
import { useAppSelector } from 'app/config/store';

import { Search } from '@mui/icons-material';
import KrizChatDetail from 'app/entities/kriz-chat/kriz-chat-adt/kriz-chat-detail';
import { DrawerLoadingSpinner } from 'app/component/LoadingSpinner';
import { isNullOrEmpty, messageReplace } from 'app/shared/util/confirm-utils';
import { ADTDmMessageComponent, KrizADTTimComponent } from '../kriz-chat-component';

const KrizChatAdt = ({ krizId }) => {
  const [messageList, setMessageList] = useState([]);
  const [loading, setLoading] = useState(false);

  const krizAdtEkipEntities = useAppSelector(state => state.krizAdtEkip.entities);
  const krizKatilimciEntities = useAppSelector(state => state.krizKatilimci.entities);

  const kriz = useAppSelector(state => state.kriz.entity);

  const [selectedMessageKullanici, setSelectedMessageKullanici] = useState(null);

  const [isOpenChatDetail, setIsOpenChatDetail] = useState(false);
  const [isOpenNewChat, setIsOpenNewChat] = useState(false);
  const [isOpenNewAllChat, setIsOpenNewAllChat] = useState(false);

  const [selectedAliciTipi, setSelectedAliciTipi] = useState(null);
  const [selectedGonderenSicil, setSelectedGonderenSicil] = useState(null);
  const [selectedGonderenTim, setSelectedGonderenTim] = useState(null);
  const [selectedMesajlasmaTipi, setSelectedMesajlasmaTipi] = useState(null);

  const [search, setSearch] = useState('');
  const [searchDmList, setSearchDmList] = useState([]);
  const [searchCustom, setSearchCustom] = useState([]);

  const checkForSearchCustom = (item, title) => {
    if (
      (item && item?.icerik?.toLocaleLowerCase('tr').includes(search.toLocaleLowerCase('tr'))) ||
      title.toLocaleLowerCase('tr').includes(search.toLocaleLowerCase('tr'))
    ) {
      setSearchCustom(prevMatches => [...prevMatches, item]);
    }
  };

  const checkForSearchDm = itemList => {
    const filteredItems = itemList.filter(
      item =>
        item.icerik?.toLocaleLowerCase('tr').includes(search.toLocaleLowerCase('tr')) ||
        item.adi?.toLocaleLowerCase('tr').includes(search.toLocaleLowerCase('tr')) ||
        item.soyadi?.toLocaleLowerCase('tr').includes(search.toLocaleLowerCase('tr')) ||
        item.unvan?.toLocaleLowerCase('tr').includes(search.toLocaleLowerCase('tr'))
    );

    setSearchDmList(filteredItems);
  };

  useEffect(() => {
    if (search.length > 2) {
      setSearchDmList([]);
      setSearchCustom([]);
      checkForSearchCustom(tumAdtMessage, translate('tk24AdysApp.krizChat.tumADT'));
      checkForSearchCustom(yonetimMessage, translate('tk24AdysApp.krizChat.yonetim'));
      checkForSearchDm(messageList);
    } else if (search.length === 0) {
      setSearchDmList([]);
      setSearchCustom([]);
    }
  }, [search]);

  useEffect(() => {
    getMessageList();
    getMessageCustomList();
  }, [kriz.realId]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (!isOpenChatDetail) {
        getMessageListReload();
        getMessageCustomListReload();
      }
    }, 5000);
    return () => clearInterval(interval);
  }, [isOpenChatDetail]);

  const [tumAdtMessage, setTumAdtMessage] = useState(null);
  const [yonetimMessage, setYonetimMessage] = useState(null);
  const [adtTimMessage, setAdtTimMessage] = useState([]);

  const getMessageList = async () => {
    try {
      setLoading(true);
      const krizChatUrl = `api/kriz-chats/get-messages-dm?krizGuid=${kriz.id}`;
      await axios.get(krizChatUrl).then(response => {
        setMessageList(response.data);
        setLoading(false);
      });
    } catch {
      setLoading(false);
    }
  };

  const getMessageCustomList = async () => {
    try {
      setLoading(true);
      const krizChatUrl = `api/kriz-chats/get-messages-custom?krizId.equals=${kriz.id}`;

      await axios
        .get(
          `${krizChatUrl}&mesajlasmaTipiId.equals=${ReferansEnum.MesajlasmaTipi.Adt}&mesajAliciTipiId.equals=${ReferansEnum.MesajAliciTipi.TumAdt}`
        )
        .then(response => {
          setTumAdtMessage(response.data);
        });

      for (const krizAdtEkip of krizAdtEkipEntities) {
        axios
          .get(
            `${krizChatUrl}&mesajlasmaTipiId.equals=${ReferansEnum.MesajlasmaTipi.Adt}&mesajAliciTipiId.equals=${ReferansEnum.MesajAliciTipi.BirAdtTimi}&aliciKrizAdtEkipId.equals=${krizAdtEkip.id}`
          )
          .then(response => {
            if (response.data) {
              const newData = { ...response.data, krizAdtEkipId: krizAdtEkip.id };
              setAdtTimMessage(prevData => {
                const mevcutIndex = prevData.findIndex(item => item.krizAdtEkipId === newData.krizAdtEkipId);

                if (mevcutIndex !== -1) {
                  const yeniArray = [...prevData];
                  yeniArray[mevcutIndex] = { ...yeniArray[mevcutIndex], ...newData };
                  return yeniArray;
                } else {
                  return [...prevData, newData];
                }
              });
            }
          });
      }

      await axios
        .get(
          `${krizChatUrl}&mesajlasmaTipiId.equals=${ReferansEnum.MesajlasmaTipi.Yonetim}&mesajAliciTipiId.equals=${ReferansEnum.MesajAliciTipi.TumAdt}`
        )
        .then(response => {
          setYonetimMessage(response.data);
        });
      setLoading(false);
    } catch {
      setLoading(false);
    }
  };

  const getMessageListReload = () => {
    const krizChatUrl = `api/kriz-chats/get-messages-dm?krizGuid=${kriz.id}`;
    axios.get(krizChatUrl).then(response => {
      setMessageList(response.data);
      setLoading(false);
    });
  };

  const getMessageCustomListReload = () => {
    const krizChatUrl = `api/kriz-chats/get-messages-custom?krizId.equals=${kriz.id}`;

    axios
      .get(
        `${krizChatUrl}&mesajlasmaTipiId.equals=${ReferansEnum.MesajlasmaTipi.Adt}&mesajAliciTipiId.equals=${ReferansEnum.MesajAliciTipi.TumAdt}`
      )
      .then(response => {
        setTumAdtMessage(response.data);
      });
    for (const krizAdtEkip of krizAdtEkipEntities) {
      axios
        .get(
          `${krizChatUrl}&mesajlasmaTipiId.equals=${ReferansEnum.MesajlasmaTipi.Adt}&mesajAliciTipiId.equals=${ReferansEnum.MesajAliciTipi.BirAdtTimi}&aliciKrizAdtEkipId.equals=${krizAdtEkip.id}`
        )
        .then(response => {
          if (response.data) {
            const newData = { ...response.data, krizAdtEkipId: krizAdtEkip.id };
            setAdtTimMessage(prevData => {
              const mevcutIndex = prevData.findIndex(item => item.krizAdtEkipId === newData.krizAdtEkipId);

              if (mevcutIndex !== -1) {
                const yeniArray = [...prevData];
                yeniArray[mevcutIndex] = { ...yeniArray[mevcutIndex], ...newData };
                return yeniArray;
              } else {
                return [...prevData, newData];
              }
            });
          }
        });
    }

    axios
      .get(
        `${krizChatUrl}&mesajlasmaTipiId.equals=${ReferansEnum.MesajlasmaTipi.Adt}&mesajAliciTipiId.equals=${ReferansEnum.MesajAliciTipi.Yonetim}`
      )
      .then(response => {
        setYonetimMessage(response.data);
      });
  };

  const chatDetail = (mesajlasmaTipi, messages, mesajAliciTipi, gonderenAdtTim, gonderenSicil) => {
    setSelectedMessageKullanici(messages);
    setSelectedAliciTipi(mesajAliciTipi);
    setSelectedGonderenSicil(gonderenSicil);
    setSelectedGonderenTim(gonderenAdtTim);
    setSelectedMesajlasmaTipi(mesajlasmaTipi);
    setIsOpenChatDetail(true);
    setIsOpenNewChat(false);
  };

  const chatDetailNew = () => {
    setIsOpenChatDetail(true);
    setIsOpenNewChat(true);
    setSelectedAliciTipi(ReferansEnum.MesajAliciTipi.Dm);
  };

  const closeDetailModal = () => {
    setIsOpenChatDetail(false);
  };

  const circleStyle = {
    width: '32px',
    height: '32px',
    borderRadius: '50%',
    backgroundColor: '#C1C8D1',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
  };

  const TumADT = () => {
    return (
      <Card
        body
        className="cardChat"
        onClick={() => chatDetail(ReferansEnum.MesajlasmaTipi.Adt, tumAdtMessage, ReferansEnum.MesajAliciTipi.TumAdt, null, null)}
        style={{
          backgroundColor: '#F2F3F580',
        }}
      >
        <div>
          <img width={32} height={32} src={`${SERVER_URL}/content/images/icon/faUserOvers.svg`} />
        </div>
        <CardText style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <span style={{ fontSize: '20px', color: '#34404F', fontWeight: '500' }}>
              <Translate contentKey="tk24AdysApp.krizChat.tumADT" />
            </span>
            <span style={{ fontSize: '14px', color: '#919DAC', fontWeight: '400' }}>
              {tumAdtMessage && <TextFormat value={tumAdtMessage.cDate} type="date" format={APP_DATETIME_FORMAT} />}
            </span>
          </div>

          <span style={{ color: '#919DAC', fontSize: '15px' }}>
            {tumAdtMessage && messageReplace(krizKatilimciEntities, tumAdtMessage.icerik)}
          </span>
        </CardText>
      </Card>
    );
  };

  const KrizADTTim = () => {
    return (
      <>
        {krizAdtEkipEntities &&
          krizAdtEkipEntities.map(item => {
            const krizAdtMessage = adtTimMessage.find(v => v.krizAdtEkipId === item.id);
            if (search.length > 2) {
              if (
                krizAdtMessage?.icerik?.toLocaleLowerCase('tr').includes(search.toLocaleLowerCase('tr')) ||
                item?.adtEkip?.adi.toLocaleLowerCase('tr').includes(search.toLocaleLowerCase('tr'))
              ) {
                return (
                  <KrizADTTimComponent
                    krizKatilimciEntities={krizKatilimciEntities}
                    krizAdtMessage={krizAdtMessage}
                    item={item}
                    chatDetail={chatDetail}
                  />
                );
              }
            } else {
              return (
                <KrizADTTimComponent
                  krizKatilimciEntities={krizKatilimciEntities}
                  krizAdtMessage={krizAdtMessage}
                  item={item}
                  chatDetail={chatDetail}
                />
              );
            }
          })}
      </>
    );
  };

  const YonetimChat = () => {
    return (
      <Card
        body
        className="cardChat"
        style={{
          backgroundColor: '#F2F3F580',
        }}
      >
        <div>
          <img width={32} height={32} src={`${SERVER_URL}/content/images/icon/faUserOvers.svg`} />
        </div>
        <CardText
          style={{ display: 'flex', flexDirection: 'column', width: '100%' }}
          onClick={() => chatDetail(ReferansEnum.MesajlasmaTipi.Yonetim, null, ReferansEnum.MesajAliciTipi.Yonetim, null, null)}
        >
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: '10px' }}>
            <span style={{ fontSize: '20px', color: '#34404F', fontWeight: '500' }}>
              <Translate contentKey="tk24AdysApp.krizChat.yonetim" />
            </span>
            <span style={{ fontSize: '14px', color: '#919DAC', fontWeight: '400' }}>
              {yonetimMessage && <TextFormat value={yonetimMessage.cDate} type="date" format={APP_DATETIME_FORMAT} />}
            </span>
          </div>

          <span style={{ color: '#919DAC', fontSize: '15px' }}>
            {yonetimMessage && messageReplace(krizKatilimciEntities, yonetimMessage.icerik)}
          </span>
        </CardText>
      </Card>
    );
  };

  const DMChat = () => {
    return (
      <>
        {!loading && (
          <>
            {search.length > 2
              ? searchDmList.length > 0 &&
                searchDmList.map((message, index) => (
                  <ADTDmMessageComponent
                    krizKatilimciEntities={krizKatilimciEntities}
                    key={index}
                    message={message}
                    chatDetail={chatDetail}
                  />
                ))
              : messageList.length > 0 &&
                messageList.map((message, index) => (
                  <ADTDmMessageComponent
                    krizKatilimciEntities={krizKatilimciEntities}
                    key={index}
                    message={message}
                    chatDetail={chatDetail}
                  />
                ))}
          </>
        )}
      </>
    );
  };
  return (
    <Container
      className="container-chat-area"
      style={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#FFFFFF',
        height: 'calc(100% - 70px)',
      }}
    >
      {loading ? (
        <DrawerLoadingSpinner />
      ) : (
        <>
          {!isOpenChatDetail ? (
            <>
              <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }} className="mb-4">
                <div className="seachiconposition" style={{ width: '100%' }}>
                  <Search className="seachicon" style={{ color: 'grey' }} />
                  <Input
                    value={search}
                    onChange={e => setSearch(e.target.value)}
                    placeholder={translate('entity.action.search')}
                    className="seachinput"
                    style={{ height: 48 }}
                  />
                </div>
                <div className="text-right chatNewButton">
                  <Button
                    style={{ marginLeft: 2 }}
                    className="create-button w-100"
                    size="sm"
                    data-cy="entityCreateButton"
                    onClick={chatDetailNew}
                  >
                    <FontAwesomeIcon icon="plus" />{' '}
                    <span className="d-none d-md-inline newChatAdd">
                      <Translate contentKey="tk24AdysApp.krizChat.home.createLabel" />
                    </span>
                  </Button>
                </div>
              </div>

              {search.length > 2 ? (
                <>
                  {searchCustom.some(item => item.realId === tumAdtMessage?.realId) && <TumADT />}
                  {searchCustom.some(item => item.realId === yonetimMessage?.realId) && <YonetimChat />}
                </>
              ) : (
                <>
                  <TumADT />
                  <YonetimChat />
                </>
              )}

              <KrizADTTim />
              <DMChat />
            </>
          ) : (
            <KrizChatDetail
              selectedMessageKullanici={selectedMessageKullanici}
              aliciTipi={selectedAliciTipi}
              isOpenNewChat={isOpenNewChat}
              onClose={closeDetailModal}
              krizAdtEkip={krizAdtEkipEntities}
              mesajlasmaSicil={selectedGonderenSicil}
              mesajlasmaTipi={selectedMesajlasmaTipi}
              selectedGonderenTim={selectedGonderenTim}
            />
          )}
        </>
      )}
    </Container>
  );
};

export default KrizChatAdt;
