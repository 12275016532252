import React, { useState, useEffect } from 'react';
import {
  Typography,
  Box,
  TableContainer,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  Table,
  IconButton,
  Collapse,
  MenuItem,
  Menu,
  FormControl,
  InputLabel,
  Select,
} from '@mui/material';
import axios from 'axios';
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from 'reactstrap';
import { TextFormat, Translate, translate } from 'app/component/jhipster';
import { KeyboardArrowRight, KeyboardArrowDownOutlined, MoreVert, Search } from '@mui/icons-material';

import { APP_DATE_FORMAT, SERVER_URL } from 'app/config/constants';
import EtkilenenKisiSaglikDialog from './etkilenen-kisi-saglik-dialog';
import { hasAnyAuthority, useAppDispatch, useAppSelector } from 'app/config/store';
import { deleteEntity as deleteEtkilenenKisiYakini } from './reducer/etkilenen-kisi-yakini.reducer';
import { CustomSaveEntityConfirm, deleteEntityConfirm, isNullOrEmpty, isObjectEmpty } from 'app/shared/util/confirm-utils';
import { ReferansEnum } from 'app/constants/ReferansEnum';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import StarIcon from '@mui/icons-material/Star';
import { createEntity as createFavori, deleteEntity as deleteFavori } from './reducer/etkilenen-kisi-favoris.reducer';
import {
  getKisiEntities as getKrizFilterEkKisiEntities,
  createEntity as createKrizEkKisiEntity,
  updateEntity as updateKrizEkKisiEntity,
  deleteEntity as deleteKrizEkKisiEntity,
  getTotalEntities,
} from './reducer/etkilenen-kriz-ek-kisi.reducer';
import EtkilenenKisiYakiniUpdate from './etkilenen-kisi-yakini-update';
import AdtAtama from 'app/component/etkilenenKisi/adt-atama';
import { IKrizEkKisi } from 'app/shared/model/kriz-ek-kisi.model';
import { LoadingSpinner } from 'app/component/LoadingSpinner';
import EtkilenenKisiHatirlatma from './etkilenen-kisi-hatirlatma';
import EtkilenenKisiForm from './etkilenen-kisi-form';
import { FormItem } from 'app/component/FormItem';
import { localeLanguage, upperOnlyFirstLetter } from 'app/shared/util/util';
import CustomPagination from 'app/component/CustomPagination';
import GrantedButton from 'app/component/GrantedButton';
import ExcelDownloadButton from 'app/component/ExcelDownload';
import EtkilenenKisiDoldurulanFormlar from './etkilenen-kisi-doldurulan-formlar';
import { IQueryParams } from 'app/shared/reducers/reducer.utils';
import GrantedMenuItem from 'app/component/GrantedMenuItem';
import SortIcon from 'app/component/SortIcon';
import { ASC, DESC } from 'app/shared/util/pagination.constants';
import { validationKrizAdtEkip } from 'app/shared/util/get-custom-service';
import { IKrizAdtEkip } from 'app/shared/model/kriz-adt-ekip.model';
import classnames from 'classnames';
import EtkilenenKisiNoteTab from './etkilenen-kisi-not-tab';
import EtkilenenKisiEkKisiEkDosyaTab from './etkilenen-kisi-ek-kisi-detail-tabs/etkilenen-kisi-ek-kisi-ek-dosya-tab';

interface EtkilenenKisiEkKisiProps {
  ucus: any;
  krizId: number | any;
}

const EtkilenenKisiEkKisi = (props: EtkilenenKisiEkKisiProps) => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);

  const ExcelSampleData = [
    { key: 'kisiAltTipi.adi', titleContentKey: 'tk24AdysApp.ucusKisiListesi.altTip' },
    { key: 'adi', titleContentKey: 'tk24AdysApp.ucusKisiListesi.adi' },
    { key: 'soyadi', titleContentKey: 'tk24AdysApp.ucusKisiListesi.soyadi' },
    { key: 'refUlke.adi', titleContentKey: 'tk24AdysApp.ucusKisiListesi.milliyet' },
    { key: 'eposta', titleContentKey: 'tk24AdysApp.ucusKisiListesi.eposta' },
    { key: 'gsmNo', titleContentKey: 'tk24AdysApp.ucusKisiListesi.telefon' },
    {
      key: 'kisi.saglikDurumu.adi',
      titleContentKey: 'tk24AdysApp.ucusKisiListesi.saglik',
      rowAuthorites: ['etkilenen_kisi_saglik_durumu_read'],
    },
    { key: 'aciklama', titleContentKey: 'global.languageGlobal.aciklama' },
    {
      key: 'sonGuncelleyenKullanici.adi',
      key2: 'sonGuncelleyenKullanici.soyadi',
      titleContentKey: 'tk24AdysApp.ucusKisiListesi.sonGuncelleyen',
    },
    { key: 'sonGuncelleZamani', format: 'date', titleContentKey: 'tk24AdysApp.ucusKisiListesi.sonGuncelleZamani' },
  ];

  const AccordionExcelSampleData = [
    { key: 'yakinlik.adi', titleContentKey: 'tk24AdysApp.ucusKisiListesi.yakinlik' },
    { key: 'adi', titleContentKey: 'tk24AdysApp.ucusKisiListesi.adi' },
    { key: 'soyadi', titleContentKey: 'tk24AdysApp.ucusKisiListesi.soyadi' },
    { key: 'eposta', titleContentKey: 'tk24AdysApp.ucusKisiListesi.eposta' },
    { key: 'gsmNo', titleContentKey: 'tk24AdysApp.ucusKisiListesi.telefon' },
    { key: 'aciklama', titleContentKey: 'global.languageGlobal.aciklama' },
  ];

  const getModalInfo = () => {
    const adi = selectedEkKisi?.adi || '';
    const soyadi = selectedEkKisi?.soyadi || '';
    const kisiAltTipi = selectedEkKisi?.kisiAltTipi?.adi || '';

    return `${adi} ${soyadi} ${kisiAltTipi ? '- ' + kisiAltTipi : ''}`;
  };

  const krizEkKisiEkDosyaLoading = useAppSelector(state => state.ekDosya.loading);
  const krizEkKisiEkDosyaUpdating = useAppSelector(state => state.ekDosya.updating);

  const krizEkKisis = useAppSelector(state => state.krizEkKisi.entities);
  const krizEkKisiLoading = useAppSelector(state => state.krizEkKisi.loading);
  const updating = useAppSelector(state => state.krizEkKisi.updating);

  const krizFavoriLoading = useAppSelector(state => state.krizEtkilenenKisiFavori.loading);
  const krizFavoriUpdating = useAppSelector(state => state.krizEtkilenenKisiFavori.updating);

  const totalItem = useAppSelector(state => state.krizEkKisi.totalItems);

  const [search, setSearch] = useState('');

  const [filterKisiIds, setFilterKisiIds] = useState(null);

  const [isSaglikDialog, setIsSaglikDialog] = useState(false);

  const [selectedEkKisi, setSelectedEkKisi] = useState(null);
  const [isEkKisiModal, setIsEkKisiModal] = useState(false);

  const [yolcuYakini, setYolcuYakini] = useState(null);
  const [isYakiniModal, setIsYakiniModal] = useState(false);
  const [selectedYakini, setSelectedYakini] = useState(null);

  const [expanded, setExpanded] = useState<string | false>();

  const refKisiAltTipiEntity = useAppSelector(state => state.refKisiAltTipi.entities);
  const krizEntity = useAppSelector(state => state.kriz.entity);
  const kisiTipEntity = useAppSelector(state => state.referans.entities);

  const [selectedFilter, setSelectedFilter] = useState('all');

  const krizFavori = useAppSelector(state => state.krizEtkilenenKisiFavori.entities);
  const krizAdtAtama = useAppSelector(state => state.krizEtkilenenKisiAdtAtama.entities);

  const krizKatilimci = useAppSelector(state => state.krizKatilimci.entity);

  const [isAssignModal, setIsAssignModal] = useState(false);
  const [isRemindingModal, setIsRemindingModal] = useState(false);
  const [isFormModal, setIsFormModal] = useState(false);

  const [krizAdtEkip, setKrizAdtEkip] = useState<IKrizAdtEkip[]>(null);

  const [selectedMoreKisi, setSelectedMoreKisi] = useState(null);
  const [openMoreBtn, setOpenMoreBtn] = useState(false);

  const [valueTab, setValueTab] = React.useState(0);
  const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);
  const [modalPromiseResolve, setModalPromiseResolve] = useState(null);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const ITEM_HEIGHT = 48;
  const [pageObject, setPageObject] = useState<IQueryParams>(useAppSelector(state => state.paginate.pageObject));

  const refSaglikDurums = useAppSelector(state => state.refSaglikDurumu.entities);
  const kisiAltTipiList = useAppSelector(state => state.refKisiAltTipi.entities);
  const [ulkeList, setUlkeList] = useState(null);

  const [pageObjectYakini, setPageObjectYakini] = useState<IQueryParams>(useAppSelector(state => state.paginate.pageObject));
  const [sortField, setSortField] = useState('id');
  const [sortOrder, setSortOrder] = useState('asc');
  const [activeTab, setActiveTab] = useState(1);

  useEffect(() => {
    sortEntities(selectedFilter);
  }, [pageObject.page, pageObject.query, pageObject.size, pageObject.sort]);

  const sortEntities = (filter: string) => {
    dispatch(
      getKrizFilterEkKisiEntities({
        ...pageObject,
        krizId: props.krizId,
        filterType: filter,
      })
    );
  };

  useEffect(() => {
    document.body.style.paddingRight = '0px';
  }, [openMoreBtn, anchorEl, open]);

  useEffect(() => {
    if (search.length > 2) {
      setPageObject(prevPageObject => ({
        ...prevPageObject,
        page: 0,
        query: prevPageObject.query + `&globalFilter=${search}`,
      }));
    } else if (search.length === 0 && !isNullOrEmpty(pageObject.query)) {
      const keyToDelete = 'globalFilter';
      const updatedParams = pageObject.query
        .split('&')
        .filter(param => {
          const [key, _] = param.split('=');
          return key !== keyToDelete;
        })
        .join('&');

      setPageObject({ ...pageObject, page: 0, query: updatedParams });
    }
  }, [search]);

  const handleSearchChange = (elem, event) => {
    if (!isNullOrEmpty(event.target.value)) {
      setPageObject(prevPageObject => ({
        ...prevPageObject,
        page: 0,
        query: prevPageObject.query + `&${elem}.equals=${event.target.value}`,
      }));
    } else {
      const keyToDelete = `${elem}.equals`;
      const updatedParams = pageObject.query
        .split('&')
        .filter(param => {
          const [key, _] = param.split('=');
          return key !== keyToDelete;
        })
        .join('&');
      setPageObject({ ...pageObject, page: 0, query: updatedParams });
    }
  };

  const sort = p => () => {
    const orderBy = pageObject.sort.split(',')[1];
    setPageObject({ ...pageObject, sort: `${p},${orderBy === ASC ? DESC : ASC}` });
  };

  const filterData = value => {
    if (krizEkKisis !== null) {
      setExpanded(false);
      handleCloseMoreBtn();
      switch (value) {
        case 'all':
          setFilterKisiIds(null);
          sortEntities(value);
          break;
        case 'fav': {
          sortEntities(value);
          break;
        }
        case 'tome': {
          sortEntities(value);
          break;
        }
        default:
          setFilterKisiIds(null);
          break;
      }
    }
  };

  useEffect(() => {
    if (selectedEkKisi) {
      getYakinlik(selectedEkKisi);
    }
  }, [pageObjectYakini, sortField, sortOrder]);

  const addFavorite = async kisi => {
    const entity = {
      krizKatilimci,
      kisi,
    };
    await dispatch(createFavori(entity));
  };

  const deleteFavorite = kisi => {
    const favorite = krizFavori.find(favori => favori.kisi.id.toString() === kisi.id.toString());
    if (favorite !== null) {
      const entity = {
        id: favorite.id,
        krizKatilimci,
        kisi,
      };
      dispatch(deleteFavori(entity));
    }
  };

  const handleRadioChange = event => {
    setSelectedFilter(event.target.value);
    filterData(event.target.value);
  };

  const handleCloseDialog = () => {
    setIsSaglikDialog(false);
    setSelectedEkKisi(null);
  };

  const saglikDurumu = event => {
    event.stopPropagation();
    setExpanded(false);
    setIsSaglikDialog(true);
  };

  const handleOpen = event => {
    event.stopPropagation();
    setExpanded(false);
    setIsEkKisiModal(true);
  };

  const handleOpenAssignModal = async event => {
    event.stopPropagation();
    handleCloseMoreBtn();
    setLoading(true);

    const krizEkip = await validationKrizAdtEkip(props.krizId);
    setKrizAdtEkip(krizEkip);

    setLoading(false);
    setIsAssignModal(true);
  };

  const handleOpenRemindingModal = event => {
    event.stopPropagation();
    handleCloseMoreBtn();
    setIsRemindingModal(true);
  };

  const handleOpenFormModal = event => {
    event.stopPropagation();
    handleCloseMoreBtn();
    setIsFormModal(true);
  };

  const handleClose = () => {
    setIsEkKisiModal(false);
    setIsAssignModal(false);
    setIsRemindingModal(false);
    setIsFormModal(false);
    setSelectedEkKisi(null);
    sortEntities(selectedFilter);
  };

  const handleYakiniOpen = event => {
    event.stopPropagation();
    setIsYakiniModal(true);
  };

  const handleYakiniClose = () => {
    setSelectedYakini(null);
    setIsYakiniModal(false);
    getYakinlik(selectedEkKisi);
  };

  const sortYakini = p => () => {
    const orderBy = pageObjectYakini.sort.split(',')[1];
    const newSortOrder = orderBy === ASC ? DESC : ASC;
    setPageObjectYakini({ ...pageObjectYakini, sort: `${p},${newSortOrder}` });
    setSortField(p);
    setSortOrder(newSortOrder);
  };

  const getYakinlik = async krizKisi => {
    setLoading(true);
    const yakinlikUrl = `api/kriz-etkilenen-kisi-yakinis?kisiId.equals=${krizKisi.kisi.id}&sort=${sortField},${sortOrder}&size=100`;
    await axios.get(yakinlikUrl).then(response => {
      setYolcuYakini(response.data);
    });
    setLoading(false);
  };
  const handleChangeAccordion =
    (panel: string, krizKisi: IKrizEkKisi) => (event: React.MouseEvent<HTMLTableRowElement>, newExpanded: boolean) => {
      handleCloseMoreBtn();
      setYolcuYakini(null);
      setActiveTab(1);
      setSelectedEkKisi(null);
      setExpanded(newExpanded ? panel : false);
      if (newExpanded) {
        setSelectedEkKisi(krizKisi);
        getYakinlik(krizKisi);
      }
    };

  const confirmYakiniDelete = async (krizEtkilenenKisiYakini: number | any) => {
    const isDel = await deleteEntityConfirm(krizEtkilenenKisiYakini, 'krizEtkilenenKisiYakini');
    if (isDel) {
      await dispatch(deleteEtkilenenKisiYakini(krizEtkilenenKisiYakini));
      getYakinlik(selectedEkKisi);
    }
  };

  const showSaveConfirmModal = () => {
    setIsSaveModalOpen(true);
    return new Promise(resolve => {
      setModalPromiseResolve(() => resolve);
    });
  };

  const handleConfirm = () => {
    if (modalPromiseResolve) {
      modalPromiseResolve(true);
    }
    setIsSaveModalOpen(false);
  };

  const handleCancel = () => {
    if (modalPromiseResolve) {
      modalPromiseResolve(false);
    }
    setIsSaveModalOpen(false);
  };

  const getFilterData = async () => {
    setLoading(true);
    const refUlkeUrl = `api/ref-ulkes?size=9999&sort=adi${upperOnlyFirstLetter(localeLanguage)},asc`;
    await axios.get(refUlkeUrl).then(response => {
      setUlkeList(response.data);
      setLoading(false);
    });
  };

  useEffect(() => {
    if (isEkKisiModal) {
      getFilterData();
    }
  }, [isEkKisiModal]);

  const saveEntity = async values => {
    values.preventDefault();
    const isSave = await showSaveConfirmModal();

    if (isSave) {
      const formDataObject = {};
      for (const field of values.target.elements) {
        if (field.name) {
          formDataObject[field.name] = field.value;
        }
      }

      const entity: IKrizEkKisi = {
        ...selectedEkKisi,
        ...formDataObject,
        kriz: krizEntity,
        kisiAltTipi: refKisiAltTipiEntity.find(it => it.id.toString() === formDataObject['tipiId'].toString()),
        kisiTipi: kisiTipEntity.find(it => it.id.toString() === ReferansEnum.KisiTipi.EkKisi.toString()),
        milliyet: formDataObject['milliyet'].toString(),
      };
      handleClose();
      if (!selectedEkKisi) {
        await dispatch(createKrizEkKisiEntity(entity));
      } else {
        await dispatch(updateKrizEkKisiEntity(entity));
      }

      sortEntities(selectedFilter);
    }
  };

  const deleteEntity = async values => {
    const isDelete = await deleteEntityConfirm(values.id, 'krizEkKisi');

    if (isDelete) {
      await dispatch(deleteKrizEkKisiEntity(values));
      sortEntities(selectedFilter);
    }
  };

  const remindingModal = () => {
    return (
      <Modal className="modal-style" isOpen={isRemindingModal} toggle={handleClose} centered>
        <ModalHeader data-cy="RefEtkilenenKisiMurettebatCreateUpdateHeading" toggle={handleClose}>
          {selectedEkKisi && <p className="modal-title-info">{getModalInfo()} </p>}
          <Translate contentKey="tk24AdysApp.krizEtkilenenKisiHatirlatma.home.editLabel" />
        </ModalHeader>
        <EtkilenenKisiHatirlatma etkilenenKisi={selectedEkKisi.kisi} updateList={() => {}} handleClose={handleClose} />
      </Modal>
    );
  };

  const formModal = () => {
    return (
      <Modal isOpen={isFormModal} toggle={handleClose} className="modal-style-lg" centered>
        <ModalHeader data-cy="refEntityDetailsHeading" toggle={handleClose}>
          <p className="modal-title-info">
            {selectedEkKisi?.adi +
              ' ' +
              (selectedEkKisi.soyadi ? ' ' + selectedEkKisi.soyadi : '') +
              ' - ' +
              selectedEkKisi?.kisiAltTipi?.adi}
          </p>
          <Translate contentKey="tk24AdysApp.etkilenenKisi.formlar" />
        </ModalHeader>
        <ModalBody>
          <Nav className="custom-nav-tabs" tabs>
            <NavItem className="custom-nav-item">
              <NavLink
                className={classnames('custom-nav-link', { active: valueTab === 0 })}
                onClick={() => {
                  setValueTab(0);
                }}
              >
                <Translate contentKey="tk24AdysApp.refAdtForm.doldurulacakFormlar"> Doldurulacak Formlar </Translate>
              </NavLink>
            </NavItem>
            <NavItem className="custom-nav-item">
              <NavLink
                className={classnames('custom-nav-link', { active: valueTab === 1 })}
                onClick={() => {
                  setValueTab(1);
                }}
              >
                <Translate contentKey="tk24AdysApp.refAdtForm.doldurulanFormlar"> Doldurulan Formlar </Translate>
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={valueTab}>
            <TabPane key={0} tabId={0}>
              {valueTab === 0 && (
                <EtkilenenKisiForm
                  etkilenenKisi={selectedEkKisi.kisi}
                  kullaniciAdi={`${selectedEkKisi.adi} ${selectedEkKisi.soyadi ? selectedEkKisi.soyadi : ''}`}
                  handleClose={handleClose}
                  krizKatilimci={krizKatilimci}
                ></EtkilenenKisiForm>
              )}
            </TabPane>
            <TabPane key={1} tabId={1}>
              {valueTab === 1 && (
                <EtkilenenKisiDoldurulanFormlar kisi={selectedEkKisi.kisi} kisiAltTipi={selectedEkKisi.kisiAltTipi} krizId={props.krizId} />
              )}
            </TabPane>
          </TabContent>
        </ModalBody>
      </Modal>
    );
  };

  const ekKisiModal = () => {
    return (
      <Modal className="modal-style" isOpen={isEkKisiModal} toggle={handleClose} centered>
        <ModalHeader data-cy="RefEtkilenenKisiYolcuCreateUpdateHeading" toggle={handleClose}>
          {selectedEkKisi && (
            <p className="modal-title-info">
              {selectedEkKisi?.adi + ' ' + (selectedEkKisi.soyadi ? selectedEkKisi.soyadi : '') + ' - ' + selectedEkKisi?.kisiAltTipi?.adi}
            </p>
          )}
          {selectedEkKisi ? (
            <Translate contentKey="tk24AdysApp.krizEkKisi.home.editLabel" />
          ) : (
            <Translate contentKey="tk24AdysApp.krizEkKisi.home.createLabel" />
          )}
        </ModalHeader>
        <Form onSubmit={saveEntity}>
          <ModalBody style={{ maxHeight: '500px', overflowY: 'auto' }}>
            <Row>
              <Col md="12">
                <FormItem
                  label={translate('global.languageGlobal.adi')}
                  id="adi"
                  name="adi"
                  type="text"
                  defaultValue={selectedEkKisi && selectedEkKisi.adi}
                  validation={{
                    required: { value: true, message: translate('entity.validation.required') },
                    maxLength: { value: 50, message: translate('entity.validation.maxlength', { max: 50 }) },
                  }}
                  tabIndex={1}
                />
                <FormItem
                  label={translate('tk24AdysApp.refYolcu.soyadi')}
                  id="soyadi"
                  name="soyadi"
                  type="text"
                  defaultValue={selectedEkKisi && selectedEkKisi.soyadi}
                  tabIndex={2}
                />
                <FormItem
                  id="tipi"
                  name="tipiId"
                  data-cy="tipi"
                  label={translate('tk24AdysApp.refYolcu.tipi')}
                  type="select"
                  defaultValue={selectedEkKisi && selectedEkKisi.kisiAltTipi.id}
                  validation={{
                    required: { value: true, message: translate('entity.validation.required') },
                  }}
                  tabIndex={3}
                >
                  {refKisiAltTipiEntity
                    ? refKisiAltTipiEntity.map(otherEntity =>
                        otherEntity.kisiTipi.id.toString() === ReferansEnum.KisiTipi.EkKisi.toString() ? (
                          <option value={otherEntity.id} key={otherEntity.id}>
                            {otherEntity.adi}
                          </option>
                        ) : null
                      )
                    : null}
                </FormItem>
                <FormItem
                  id="milliyet-id"
                  name="milliyet"
                  label={translate('tk24AdysApp.refYolcu.milliyet')}
                  type="select"
                  tabIndex={3}
                  defaultValue={selectedEkKisi && selectedEkKisi?.milliyet}
                >
                  <option value="" key="0" />
                  {ulkeList
                    ? ulkeList.map(otherEntity => (
                        <option value={otherEntity.kodu3Harf} key={otherEntity.kodu3Harf}>
                          {otherEntity.adi}
                        </option>
                      ))
                    : null}
                </FormItem>
                <FormItem
                  label={translate('tk24AdysApp.refYolcu.telephone')}
                  id="telefon"
                  name="gsmNo"
                  type="text"
                  defaultValue={selectedEkKisi && selectedEkKisi.gsmNo}
                  validation={{
                    maxLength: { value: 15, message: translate('entity.validation.maxlength', { max: 15 }) },
                  }}
                  tabIndex={4}
                />
                <FormItem
                  label={translate('tk24AdysApp.refYolcu.email')}
                  id="ePosta"
                  name="ePosta"
                  type="text"
                  defaultValue={selectedEkKisi && selectedEkKisi.ePosta}
                  validation={{
                    maxLength: { value: 50, message: translate('entity.validation.maxlength', { max: 50 }) },
                  }}
                  tabIndex={5}
                />
              </Col>
              <Col md="12">
                <FormItem
                  label={translate('tk24AdysApp.refYolcu.aciklama')}
                  id="aciklama"
                  name="aciklama"
                  type="textarea"
                  defaultValue={selectedEkKisi && selectedEkKisi.aciklama}
                  tabIndex={6}
                />
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter className="modal-footer">
            <Button className="cancel-button" onClick={handleClose} tabIndex={7}>
              <Translate contentKey="entity.action.cancel">Cancel</Translate>
            </Button>
            &nbsp;
            <Button className="save-button" id="jhi-confirm-delete-refAdtForm" type="submit" data-cy="entityConfirmButton" tabIndex={8}>
              <Translate contentKey="entity.action.save">Kaydet</Translate>
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    );
  };

  const yakiniModal = () => {
    return (
      <Modal className="modal-style" isOpen={isYakiniModal} toggle={handleYakiniClose} centered>
        <ModalHeader data-cy="RefEtkilenenKisiMurettebatCreateUpdateHeading" toggle={handleYakiniClose}>
          {selectedYakini && (
            <p className="modal-title-info">
              {selectedYakini?.adi} {selectedYakini?.soyadi}
            </p>
          )}
          {selectedYakini ? (
            <Translate contentKey="tk24AdysApp.krizEtkilenenKisiYakini.home.editLabel" />
          ) : (
            <Translate contentKey="tk24AdysApp.krizEtkilenenKisiYakini.home.createLabel" />
          )}
        </ModalHeader>
        <EtkilenenKisiYakiniUpdate
          krizId={props.krizId}
          krizEkKisi={selectedEkKisi}
          krizEkKisiYakini={selectedYakini}
          handleClose={handleYakiniClose}
        />
      </Modal>
    );
  };

  const Filter = () => {
    return (
      <div className="filter-etkilenen">
        <div style={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
          <FormGroup check className="inputArea">
            <Label check>
              <Input type="radio" name="filterAll" value="all" checked={selectedFilter === 'all'} onChange={handleRadioChange} />{' '}
              <Translate contentKey="tk24AdysApp.etkilenenKisi.filter.all" />
            </Label>
          </FormGroup>
        </div>

        {hasAnyAuthority(['kriz_etkilenen_kisi_adt_atama_read']) && (
          <div style={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
            <FormGroup check className="inputArea">
              <Label check>
                <Input
                  type="radio"
                  name="filterAppointees"
                  value="tome"
                  checked={selectedFilter === 'tome'}
                  onChange={handleRadioChange}
                  style={{ cursor: 'pointer' }}
                />{' '}
                <Translate contentKey="tk24AdysApp.etkilenenKisi.filter.appointees" />
              </Label>
            </FormGroup>
          </div>
        )}

        {hasAnyAuthority(['kriz_etkilenen_kisi_favori_read']) && (
          <>
            {!isObjectEmpty(krizKatilimci) && (
              <div style={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                <FormGroup check className="inputArea">
                  <Label check>
                    <Input
                      type="radio"
                      name="filterFavorites"
                      value="fav"
                      checked={selectedFilter === 'fav'}
                      onChange={handleRadioChange}
                      style={{ cursor: 'pointer' }}
                    />
                    <Translate contentKey="tk24AdysApp.etkilenenKisi.filter.favorites" />
                  </Label>
                </FormGroup>
              </div>
            )}
          </>
        )}
      </div>
    );
  };

  const assignModal = () => {
    return (
      selectedEkKisi && (
        <Modal isOpen={isAssignModal} toggle={handleClose} className="modal-style-lg" centered>
          <ModalHeader data-cy="refEntityDetailsHeading" toggle={handleClose}>
            <p className="modal-title-info">
              {selectedEkKisi?.adi +
                ' ' +
                (selectedEkKisi.soyadi ? ' ' + selectedEkKisi.soyadi : '') +
                ' - ' +
                selectedEkKisi?.kisiAltTipi?.adi}
            </p>
            <Translate contentKey="entity.action.assign" />
          </ModalHeader>
          <ModalBody style={{ maxHeight: '550px', overflowY: 'auto', marginTop: 5 }}>
            <AdtAtama etkilenenKisi={selectedEkKisi.kisi} krizId={props.krizId} krizAdtEkip={krizAdtEkip} setIsLoading={setLoading} />
          </ModalBody>
        </Modal>
      )
    );
  };

  const handlePageChange = (pageNumber, newItemsPerPage = pageObject.size) => {
    if (newItemsPerPage !== pageObject.size) {
      setPageObject({ ...pageObject, page: pageNumber - 1, size: newItemsPerPage });
    } else {
      setPageObject({ ...pageObject, page: pageNumber - 1 });
    }
  };

  const handleCloseMoreBtn = () => {
    setOpenMoreBtn(false);
    setSelectedMoreKisi(null);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const fetchTotalData = async (): Promise<IKrizEkKisi[]> => {
    try {
      const response = (await dispatch(getTotalEntities({ ...pageObject, size: totalItem, krizId: props.krizId }))) as {
        payload: { data: IKrizEkKisi[] };
      };

      return response.payload.data || [];
    } catch (error) {
      console.error('Error fetching data:', error);
      return [];
    }
  };

  const fetchTotalEkKisiYakiniData = (): any[] => {
    try {
      return yolcuYakini || [];
    } catch (error) {
      console.error('Error fetching data:', error);
      return [];
    }
  };
  const getSize = () => (window.innerWidth < 1600 ? 'small' : 'medium');

  return (
    !isObjectEmpty(krizEntity) && (
      <div>
        <div style={{ overflowY: 'hidden', overflowX: 'hidden' }}>
          {(krizEkKisiLoading ||
            loading ||
            updating ||
            krizFavoriLoading ||
            krizFavoriUpdating ||
            krizEkKisiEkDosyaLoading ||
            krizEkKisiEkDosyaUpdating) && <LoadingSpinner />}

          <CustomSaveEntityConfirm entity={selectedEkKisi} isOpen={isSaveModalOpen} onConfirm={handleConfirm} onCancel={handleCancel} />

          <Filter />
          <div>
            <div className="search-frame">
              <div className="seachiconposition">
                <Search className="seachicon" style={{ color: 'grey' }} />
                <Input
                  value={search}
                  onChange={e => setSearch(e.target.value)}
                  placeholder={translate('entity.action.search')}
                  className="search-input"
                  type="text"
                  name="categorySelect"
                  id="categorySelect"
                />
              </div>
              <div className="d-flex justify-end" style={{ gap: '5px' }}>
                <Box className="filter-box">
                  <FormControl fullWidth size={getSize()}>
                    <InputLabel id="default-kisi-alt-tipi-label" className="select-input-label">
                      <Translate contentKey="tk24AdysApp.ucusKisiListesi.altTip" />
                    </InputLabel>
                    <Select
                      labelId="categorySelect-label"
                      id="categorySelect"
                      name="categorySelect"
                      label={translate('tk24AdysApp.ucusKisiListesi.altTip')}
                      className="select-input"
                      onChange={event => handleSearchChange('kisiAltTipiId', event)}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 200,
                            overflowY: 'auto',
                          },
                        },
                      }}
                    >
                      <MenuItem value={null}>
                        <Translate contentKey="entity.action.all" />
                      </MenuItem>
                      {kisiAltTipiList &&
                        kisiAltTipiList.length > 0 &&
                        kisiAltTipiList
                          .filter(item => item.kisiTipi?.id === ReferansEnum.KisiTipi.EkKisi)
                          .map(item => (
                            <MenuItem key={item.id} value={item.id}>
                              {item.adi}
                            </MenuItem>
                          ))}
                    </Select>
                  </FormControl>
                </Box>
                {hasAnyAuthority(['etkilenen_kisi_saglik_durumu_read']) && (
                  <Box className="filter-box">
                    <FormControl fullWidth size={getSize()}>
                      <InputLabel id="saglik-durumu-label" className="select-input-label">
                        <Translate contentKey="tk24AdysApp.ucusKisiListesi.saglik" />
                      </InputLabel>
                      <Select
                        labelId="categorySelect-label"
                        id="categorySelect"
                        name="categorySelect"
                        className="select-input"
                        label={translate('tk24AdysApp.ucusKisiListesi.saglik')}
                        onChange={event => handleSearchChange('saglikDurumuId', event)}
                        MenuProps={{
                          PaperProps: {
                            style: {
                              maxHeight: 200,
                              overflowY: 'auto',
                            },
                          },
                        }}
                      >
                        <MenuItem value={null}>
                          <Translate contentKey="entity.action.all" />
                        </MenuItem>
                        {refSaglikDurums &&
                          refSaglikDurums.length > 0 &&
                          refSaglikDurums.map(item => (
                            <MenuItem key={item.id} value={item.id}>
                              {item.adi}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Box>
                )}
                <ExcelDownloadButton
                  style={{ marginLeft: 1 }}
                  excelSampleData={ExcelSampleData}
                  grantedAuthorites={['kriz_ek_kisi_export']}
                  fetchData={fetchTotalData}
                  exportName="Etkilenen Ek Kişi"
                  className={'download-button'}
                />
                <GrantedButton
                  id={`create-button`}
                  grantedAuthorites={['kriz_ek_kisi_edit']}
                  style={{ marginLeft: 1 }}
                  onClick={event => {
                    setSelectedEkKisi(null);
                    handleOpen(event);
                  }}
                  comparison={[
                    {
                      value: krizEntity.krizDurumu?.id,
                      checkValue: ReferansEnum.KrizDurumu.Aktif,
                      contentKey: 'error.comparison.crisisIsPassive',
                    },
                  ]}
                  className="create-button"
                  size="sm"
                  data-cy="entityCreateButton"
                >
                  <img width={14} height={14} src={`${SERVER_URL}/content/images/icon/faPlus.svg`} />
                  <span className="d-none d-md-inline">
                    <Translate contentKey="tk24AdysApp.etkilenenKisi.ekkisiEkle">Ek Kişi Ekle</Translate>
                  </span>
                </GrantedButton>
              </div>
            </div>
            {krizEkKisis.length > 0 ? (
              <>
                <div className="table-responsive" style={{ marginTop: 5 }}>
                  <TableContainer className="content-table mb-3">
                    <Table aria-label="collapsible table" className="tableList">
                      <TableHead>
                        <TableRow className="accordion-title-row">
                          <TableCell style={{ width: 48 }} />
                          <TableCell style={{ width: 40 }} />
                          <TableCell
                            className="accordion-title-cell"
                            style={{ width: '420px' }}
                            align="left"
                            onClick={sort(`kisi.kisiTipi.adi${upperOnlyFirstLetter(localeLanguage)}`)}
                          >
                            <div className="tableHeaderContainer">
                              <Translate contentKey="tk24AdysApp.ucusKisiListesi.altTip">Tip</Translate>&nbsp;
                              <SortIcon
                                column={`kisi.kisiTipi.adi${upperOnlyFirstLetter(localeLanguage)}`}
                                activeSortColumn={pageObject.sort}
                              />
                            </div>
                          </TableCell>
                          <TableCell className="accordion-title-cell" style={{ width: '420px' }} align="left" onClick={sort('adi')}>
                            <div className="tableHeaderContainer">
                              <Translate contentKey="tk24AdysApp.ucusKisiListesi.adi">Adı</Translate>&nbsp;
                              <SortIcon column="adi" activeSortColumn={pageObject.sort} />
                            </div>
                          </TableCell>
                          <TableCell className="accordion-title-cell" style={{ width: '420px' }} align="left" onClick={sort('soyadi')}>
                            <div className="tableHeaderContainer">
                              <Translate contentKey="tk24AdysApp.ucusKisiListesi.soyadi">Soyadı</Translate>
                              <SortIcon column="soyadi" activeSortColumn={pageObject.sort} />
                            </div>
                          </TableCell>
                          <TableCell className="accordion-title-cell" style={{ width: '420px' }} align="left" onClick={sort('milliyet')}>
                            <div className="tableHeaderContainer">
                              <Translate contentKey="tk24AdysApp.ucusKisiListesi.milliyetTitle">milliyet</Translate>
                              <SortIcon column="milliyet" activeSortColumn={pageObject.sort} />
                            </div>
                          </TableCell>
                          <TableCell className="accordion-title-cell" style={{ width: '420px' }} align="left" onClick={sort('gsmNo')}>
                            <div className="tableHeaderContainer">
                              <Translate contentKey="tk24AdysApp.ucusKisiListesi.telefon">Telefon</Translate>
                              <SortIcon column="gsmNo" activeSortColumn={pageObject.sort} />
                            </div>
                          </TableCell>
                          <TableCell className="accordion-title-cell" style={{ width: '420px' }} align="left" onClick={sort('ePosta')}>
                            <div className="tableHeaderContainer">
                              <Translate contentKey="tk24AdysApp.ucusKisiListesi.epostaTitle">Eposta</Translate>
                              <SortIcon column="ePosta" activeSortColumn={pageObject.sort} />
                            </div>
                          </TableCell>
                          <TableCell
                            className="accordion-title-cell"
                            style={{ width: '420px' }}
                            align="left"
                            onClick={sort(`kisi.saglikDurumu.adi${upperOnlyFirstLetter(localeLanguage)}`)}
                          >
                            <div className="tableHeaderContainer">
                              <Translate contentKey="tk24AdysApp.ucusKisiListesi.saglik">Sağlık Durumu</Translate>
                              <SortIcon
                                column={`kisi.saglikDurumu.adi${upperOnlyFirstLetter(localeLanguage)}`}
                                activeSortColumn={pageObject.sort}
                              />
                            </div>
                          </TableCell>
                          <TableCell className="accordion-title-cell" style={{ width: '420px' }} align="left" onClick={sort('aciklama')}>
                            <div className="tableHeaderContainer">
                              <Translate contentKey="global.languageGlobal.aciklamaTitle">Aciklama</Translate>
                              <SortIcon column="aciklama" activeSortColumn={pageObject.sort} />
                            </div>
                          </TableCell>
                          <TableCell
                            className="accordion-title-cell"
                            style={{ width: '420px' }}
                            align="left"
                            onClick={sort('sonGuncelleyenKullaniciId')}
                          >
                            <div className="tableHeaderContainer">
                              <Translate contentKey="tk24AdysApp.ucusKisiListesi.sonGuncelleyen">Son Güncelleyen</Translate>
                              <SortIcon column="sonGuncelleyenKullaniciId" activeSortColumn={pageObject.sort} />
                            </div>
                          </TableCell>
                          <TableCell
                            className="accordion-title-cell"
                            style={{ width: '420px' }}
                            align="left"
                            onClick={sort('sonGuncelleZamani')}
                          >
                            <div className="tableHeaderContainer">
                              <Translate contentKey="tk24AdysApp.ucusKisiListesi.sonGuncelleZamaniTitle">Son Güncelleme Zamanı</Translate>
                              <SortIcon column="sonGuncelleZamani" activeSortColumn={pageObject.sort} />
                            </div>
                          </TableCell>
                          <TableCell className="accordion-title-cell sticky-right-header-accordion" align="right"></TableCell>
                          <TableCell className="accordion-title-cell sticky-right-header-accordion" align="right"></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {krizEkKisis.map(krizKisi => (
                          <>
                            <TableRow
                              className="accordion-content-row"
                              sx={{ '& > *': { borderBottom: 'unset' } }}
                              onClick={(event: React.MouseEvent<HTMLTableRowElement>) =>
                                handleChangeAccordion('panel' + krizKisi.id, krizKisi)(
                                  event,
                                  !expanded || expanded !== 'panel' + krizKisi.id
                                )
                              }
                            >
                              <TableCell className="accordion-content-cell" style={{ width: 48 }}>
                                <IconButton aria-label="expand row" size="small" className="accordion-icon">
                                  {expanded !== false && expanded === 'panel' + krizKisi.id ? (
                                    <KeyboardArrowDownOutlined />
                                  ) : (
                                    <KeyboardArrowRight />
                                  )}
                                </IconButton>
                              </TableCell>
                              <TableCell className="accordion-content-cell" style={{ width: 40 }}>
                                {krizKatilimci !== null &&
                                  (krizFavori.some(favori => favori.kisi.id.toString() === krizKisi.kisi.id.toString()) ? (
                                    <div
                                      onClick={event => {
                                        event.stopPropagation();
                                        deleteFavorite(krizKisi.kisi);
                                      }}
                                    >
                                      <StarIcon sx={{ color: '#EBBA45' }} />
                                    </div>
                                  ) : (
                                    <div
                                      onClick={event => {
                                        event.stopPropagation();
                                        addFavorite(krizKisi.kisi);
                                      }}
                                    >
                                      <StarBorderIcon />
                                    </div>
                                  ))}
                              </TableCell>
                              <TableCell className="accordion-content-cell" component="th" scope="row">
                                <span style={{ borderRadius: '12px', backgroundColor: '#F3F4F6', padding: '2px 12px' }}>
                                  {' '}
                                  {krizKisi.kisiAltTipi.adi}
                                </span>
                              </TableCell>
                              <TableCell className="accordion-content-cell" component="th" scope="row">
                                {krizKisi.adi}
                              </TableCell>
                              <TableCell className="accordion-content-cell" component="th" scope="row">
                                {krizKisi.soyadi}
                              </TableCell>
                              <TableCell className="accordion-content-cell" component="th" scope="row">
                                {krizKisi.refUlke?.adi}
                              </TableCell>
                              <TableCell className="accordion-content-cell" component="th" scope="row">
                                {krizKisi.gsmNo}
                              </TableCell>
                              <TableCell className="accordion-content-cell" component="th" scope="row">
                                <a href={`mailto:${krizKisi.ePosta}`} className="yakinEpostaText">
                                  {' '}
                                  {krizKisi.ePosta}{' '}
                                </a>
                              </TableCell>
                              <TableCell className="accordion-content-cell" component="th" scope="row">
                                <GrantedButton
                                  id={`update-button-${krizKisi.id}`}
                                  grantedAuthorites={['etkilenen_kisi_saglik_durumu_read']}
                                  className="saglik-durumu-etkilenen"
                                  style={{
                                    color: krizKisi.kisi.saglikDurumu.cssBilgisiFontColor,
                                    backgroundColor: krizKisi.kisi.saglikDurumu.cssBilgisiBgColor,
                                    borderColor: krizKisi.kisi.saglikDurumu.cssBilgisiBorderColor,
                                  }}
                                  onClick={event => {
                                    setSelectedEkKisi(krizKisi);
                                    saglikDurumu(event);
                                  }}
                                  comparison={[
                                    {
                                      value: krizEntity.krizDurumu?.id,
                                      checkValue: ReferansEnum.KrizDurumu.Aktif,
                                      contentKey: 'error.comparison.crisisIsPassive',
                                    },
                                  ]}
                                >
                                  {krizKisi.kisi && krizKisi.kisi.saglikDurumu.adi}
                                </GrantedButton>
                              </TableCell>
                              <TableCell className="accordion-content-cell" component="th" scope="row">
                                {krizKisi.aciklama}
                              </TableCell>
                              <TableCell className="accordion-content-cell" component="th" scope="row">
                                {krizKisi?.sonGuncelleyenKullanici?.adi} {krizKisi?.sonGuncelleyenKullanici?.soyadi}
                              </TableCell>
                              <TableCell className="accordion-content-cell" component="th" scope="row">
                                {krizKisi.sonGuncelleZamani ? (
                                  <TextFormat type="date" value={krizKisi.sonGuncelleZamani} format={APP_DATE_FORMAT} />
                                ) : null}
                              </TableCell>
                              {krizKisi?.hasHatirlatma ? (
                                <TableCell align="right" className="table-content-cell sticky-right">
                                  <img width={32} height={32} src={`${SERVER_URL}/content/images/icon/faBellRed.svg`} />
                                </TableCell>
                              ) : (
                                <TableCell align="right" className="table-content-cell sticky-right"></TableCell>
                              )}
                              <TableCell align="right" className="table-content-cell sticky-right" style={{ position: 'relative' }}>
                                <IconButton
                                  aria-label="more"
                                  id="long-button"
                                  aria-controls={open ? 'long-menu' : undefined}
                                  aria-expanded={open ? 'true' : undefined}
                                  aria-haspopup="true"
                                  onClick={e => {
                                    e.stopPropagation();
                                    setOpenMoreBtn(!openMoreBtn);
                                    setSelectedMoreKisi(krizKisi);
                                    setSelectedEkKisi(krizKisi);
                                    setAnchorEl(e.currentTarget);
                                  }}
                                >
                                  <MoreVert />
                                </IconButton>
                                {openMoreBtn && selectedMoreKisi && selectedMoreKisi.id === krizKisi.id && (
                                  <Menu
                                    id="long-menu"
                                    MenuListProps={{
                                      'aria-labelledby': 'long-button',
                                    }}
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={handleCloseMenu}
                                    PaperProps={{
                                      style: {
                                        maxHeight: ITEM_HEIGHT * 4.5,
                                        width: '20ch',
                                      },
                                    }}
                                  >
                                    <GrantedMenuItem
                                      grantedAuthorites={['kriz_ek_kisi_edit']}
                                      id={`update-button-${krizKisi.id}`}
                                      onClick={event => {
                                        setSelectedEkKisi(krizKisi);
                                        setOpenMoreBtn(false);
                                        handleOpen(event);
                                      }}
                                      comparison={[
                                        {
                                          value: krizEntity.krizDurumu?.id,
                                          checkValue: ReferansEnum.KrizDurumu.Aktif,
                                          contentKey: 'error.comparison.crisisIsPassive',
                                        },
                                      ]}
                                    >
                                      <Translate contentKey="entity.action.update">Güncelle</Translate>
                                    </GrantedMenuItem>

                                    <GrantedMenuItem
                                      grantedAuthorites={['kriz_ek_kisi_delete']}
                                      id={`update-button-${krizKisi.id}`}
                                      onClick={event => {
                                        event.stopPropagation();
                                        setOpenMoreBtn(false);
                                        deleteEntity(krizKisi);
                                      }}
                                    >
                                      <Translate contentKey="entity.action.delete">Delete</Translate>
                                    </GrantedMenuItem>

                                    <GrantedMenuItem
                                      grantedAuthorites={['kriz_etkilenen_kisi_adt_atama_read']}
                                      id={`update-button-${krizKisi.id}`}
                                      onClick={event => {
                                        setSelectedEkKisi(krizKisi);
                                        handleOpenAssignModal(event);
                                      }}
                                      comparison={[
                                        {
                                          value: krizEntity.krizDurumu?.id,
                                          checkValue: ReferansEnum.KrizDurumu.Aktif,
                                          contentKey: 'error.comparison.crisisIsPassive',
                                        },
                                      ]}
                                    >
                                      <Translate contentKey="entity.action.assign" />
                                    </GrantedMenuItem>

                                    <GrantedMenuItem
                                      grantedAuthorites={['kriz_etkilenen_kisi_hatirlatma_read']}
                                      id={`update-button-${krizKisi.id}`}
                                      onClick={event => {
                                        event.stopPropagation();
                                        setSelectedEkKisi(krizKisi);
                                        handleOpenRemindingModal(event);
                                      }}
                                      comparison={[
                                        {
                                          value: krizEntity.krizDurumu?.id,
                                          checkValue: ReferansEnum.KrizDurumu.Aktif,
                                          contentKey: 'error.comparison.crisisIsPassive',
                                        },
                                      ]}
                                    >
                                      <Translate contentKey="entity.action.reminding" />
                                    </GrantedMenuItem>

                                    <MenuItem
                                      onClick={event => {
                                        event.stopPropagation();
                                        setSelectedEkKisi(krizKisi);
                                        handleOpenFormModal(event);
                                      }}
                                    >
                                      <Translate contentKey="entity.action.form" />
                                    </MenuItem>
                                  </Menu>
                                )}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell className="accordion-content-area" colSpan={16}>
                                <Collapse in={expanded === 'panel' + krizKisi.id} unmountOnExit>
                                  <div>
                                    <Nav className="custom-nav-tabs mt-3" tabs>
                                      <NavItem className="custom-nav-item">
                                        <NavLink
                                          className={classnames('custom-nav-link', { active: activeTab === 1 })}
                                          onClick={() => {
                                            setActiveTab(1);
                                          }}
                                        >
                                          <Translate contentKey="tk24AdysApp.ucusKisiListesi.yakinlari"> Yakınları </Translate>
                                        </NavLink>
                                      </NavItem>
                                      <NavItem className="custom-nav-item">
                                        <NavLink
                                          className={classnames('custom-nav-link', { active: activeTab === 2 })}
                                          onClick={() => {
                                            setActiveTab(2);
                                          }}
                                        >
                                          <Translate contentKey="tk24AdysApp.refMurettebat.detayTab.ekDosya"> Ek Dosya </Translate>
                                        </NavLink>
                                      </NavItem>
                                      <NavItem className="custom-nav-item">
                                        <NavLink
                                          className={classnames('custom-nav-link', { active: activeTab === 8 })}
                                          onClick={() => {
                                            setActiveTab(8);
                                          }}
                                        >
                                          <Translate contentKey="tk24AdysApp.krizEtkilenenKisiNote.notes"> Not </Translate>
                                        </NavLink>
                                      </NavItem>
                                    </Nav>
                                    <TabContent activeTab={activeTab}>
                                      <TabPane key={1} tabId={1}>
                                        {yolcuYakini?.length > 0 ? (
                                          <>
                                            <Typography className="accordion-typography mt-3" gutterBottom component="div">
                                              <Translate contentKey="tk24AdysApp.ucusKisiListesi.yakinlari">Yakınları</Translate>
                                            </Typography>
                                            <Typography className="item-total-count" gutterBottom component="div">
                                              <span className="totalListItemText">
                                                <Translate
                                                  contentKey="global.item-total-count"
                                                  interpolate={{ total: yolcuYakini?.length }}
                                                />
                                              </span>
                                              <ExcelDownloadButton
                                                excelSampleData={AccordionExcelSampleData}
                                                grantedAuthorites={['kriz_ek_kisi_export']}
                                                fetchData={fetchTotalEkKisiYakiniData}
                                                className={'download-button'}
                                                exportName={`${krizKisi.adi + ' ' + krizKisi.soyadi} ${translate(
                                                  'tk24AdysApp.ucusKisiListesi.yakinlari'
                                                )}`}
                                              />
                                            </Typography>
                                            <Table className="accordion-table tableList">
                                              <TableHead>
                                                <TableRow className="accordion-table-title-row">
                                                  <TableCell
                                                    className="accordion-title-cell"
                                                    style={{ width: '420px' }}
                                                    align="left"
                                                    onClick={sortYakini(`yakinlik.adi${upperOnlyFirstLetter(localeLanguage)}`)}
                                                  >
                                                    <div className="tableHeaderContainer">
                                                      <Translate contentKey="tk24AdysApp.ucusKisiListesi.yakinlikTitle">Yakınlık</Translate>
                                                      &nbsp;
                                                      <SortIcon
                                                        column={`yakinlik.adi${upperOnlyFirstLetter(localeLanguage)}`}
                                                        activeSortColumn={pageObjectYakini.sort}
                                                      />
                                                    </div>
                                                  </TableCell>
                                                  <TableCell
                                                    className="accordion-title-cell"
                                                    style={{ width: '420px' }}
                                                    align="left"
                                                    onClick={sortYakini('adi')}
                                                  >
                                                    <div className="tableHeaderContainer">
                                                      <Translate contentKey="tk24AdysApp.ucusKisiListesi.adi">Adı</Translate>&nbsp;
                                                      <SortIcon column="adi" activeSortColumn={pageObjectYakini.sort} />
                                                    </div>
                                                  </TableCell>
                                                  <TableCell
                                                    className="accordion-title-cell"
                                                    style={{ width: '420px' }}
                                                    align="left"
                                                    onClick={sortYakini('soyadi')}
                                                  >
                                                    <div className="tableHeaderContainer">
                                                      <Translate contentKey="tk24AdysApp.ucusKisiListesi.soyadi">Soyadı</Translate>
                                                      <SortIcon column="soyadi" activeSortColumn={pageObjectYakini.sort} />
                                                    </div>
                                                  </TableCell>
                                                  <TableCell
                                                    className="accordion-title-cell"
                                                    style={{ width: '420px' }}
                                                    align="left"
                                                    onClick={sortYakini('eposta')}
                                                  >
                                                    <div className="tableHeaderContainer">
                                                      <Translate contentKey="tk24AdysApp.ucusKisiListesi.epostaTitle">Eposta</Translate>
                                                      <SortIcon column="eposta" activeSortColumn={pageObjectYakini.sort} />
                                                    </div>
                                                  </TableCell>
                                                  <TableCell
                                                    className="accordion-title-cell"
                                                    style={{ width: '420px' }}
                                                    align="left"
                                                    onClick={sortYakini('gsmNo')}
                                                  >
                                                    <div className="tableHeaderContainer">
                                                      <Translate contentKey="tk24AdysApp.ucusKisiListesi.telefon">Telefon</Translate>
                                                      <SortIcon column="gsmNo" activeSortColumn={pageObjectYakini.sort} />
                                                    </div>
                                                  </TableCell>
                                                  <TableCell
                                                    className="accordion-title-cell"
                                                    style={{ width: '420px' }}
                                                    align="left"
                                                    onClick={sortYakini('aciklama')}
                                                  >
                                                    <div className="tableHeaderContainer">
                                                      <Translate contentKey="global.languageGlobal.aciklamaTitle">Aciklama</Translate>
                                                      <SortIcon column="aciklama" activeSortColumn={pageObjectYakini.sort} />
                                                    </div>
                                                  </TableCell>
                                                  <TableCell
                                                    className="accordion-title-cell"
                                                    style={{ width: '420px' }}
                                                    align="left"
                                                  ></TableCell>
                                                </TableRow>
                                              </TableHead>
                                              <TableBody>
                                                {yolcuYakini &&
                                                  yolcuYakini !== null &&
                                                  yolcuYakini.map(yakini => (
                                                    <TableRow key={yakini.id} className="tableYakini">
                                                      <TableCell className="accordion-table-content-cell" component="th" scope="row">
                                                        <span
                                                          style={{
                                                            borderRadius: '12px',
                                                            backgroundColor: '#F3F4F6',
                                                            padding: '2px 12px',
                                                          }}
                                                        >
                                                          {' '}
                                                          {yakini.yakinlik.adi}
                                                        </span>
                                                      </TableCell>
                                                      <TableCell className="accordion-table-content-cell" component="th" scope="row">
                                                        {yakini.adi}
                                                      </TableCell>
                                                      <TableCell className="accordion-table-content-cell" component="th" scope="row">
                                                        {yakini.soyadi}
                                                      </TableCell>
                                                      <TableCell className="accordion-table-content-cell" component="th" scope="row">
                                                        {yakini.eposta}
                                                      </TableCell>
                                                      <TableCell className="accordion-table-content-cell" component="th" scope="row">
                                                        {yakini.gsmNo}
                                                      </TableCell>
                                                      <TableCell className="accordion-table-content-cell" component="th" scope="row">
                                                        {yakini.aciklama}
                                                      </TableCell>
                                                      <TableCell style={{ display: 'flex', justifyContent: 'end' }}>
                                                        <div className="btn-group flex-btn-group-container">
                                                          <GrantedButton
                                                            id={`update-button-${yakini.id}`}
                                                            grantedAuthorites={['kriz_etkilenen_kisi_yakini_edit']}
                                                            onClick={event => {
                                                              setSelectedYakini(yakini);
                                                              handleYakiniOpen(event);
                                                            }}
                                                            comparison={[
                                                              {
                                                                value: krizEntity.krizDurumu?.id,
                                                                checkValue: ReferansEnum.KrizDurumu.Aktif,
                                                                contentKey: 'error.comparison.crisisIsPassive',
                                                              },
                                                            ]}
                                                            className="edit-button"
                                                            size="sm"
                                                            data-cy="entityEditButton"
                                                          >
                                                            <img
                                                              width={14}
                                                              height={14}
                                                              src={`${SERVER_URL}/content/images/icon/faPencil.svg`}
                                                            />
                                                            <span className="d-none d-md-inline">
                                                              <Translate contentKey="entity.action.edit">Edit</Translate>
                                                            </span>
                                                          </GrantedButton>

                                                          <GrantedButton
                                                            id={`delete-button-${yakini.id}`}
                                                            grantedAuthorites={['kriz_etkilenen_kisi_yakini_delete']}
                                                            onClick={() => confirmYakiniDelete(yakini.id)}
                                                            comparison={[
                                                              {
                                                                value: krizEntity.krizDurumu?.id,
                                                                checkValue: ReferansEnum.KrizDurumu.Aktif,
                                                                contentKey: 'error.comparison.crisisIsPassive',
                                                              },
                                                            ]}
                                                            style={{ marginLeft: 10 }}
                                                            className="delete-button"
                                                            size="sm"
                                                            data-cy="entityDeleteButton"
                                                          >
                                                            <img
                                                              width={14}
                                                              height={15}
                                                              src={`${SERVER_URL}/content/images/icon/faTrashCan.svg`}
                                                            />
                                                            <span className="d-none d-md-inline">
                                                              <Translate contentKey="entity.action.delete">Delete</Translate>
                                                            </span>
                                                          </GrantedButton>
                                                        </div>
                                                      </TableCell>
                                                    </TableRow>
                                                  ))}
                                              </TableBody>
                                            </Table>
                                          </>
                                        ) : (
                                          !loading &&
                                          !krizEkKisiLoading && (
                                            <div className="alert alert-warning mt-3">
                                              <Translate contentKey="tk24AdysApp.krizEtkilenenKisiYakini.home.notFound">
                                                No krizEtkilenenKisiYakini found
                                              </Translate>
                                            </div>
                                          )
                                        )}
                                        <div className="flex-start-frame">
                                          <GrantedButton
                                            id={`create-button`}
                                            grantedAuthorites={['kriz_etkilenen_kisi_yakini_edit']}
                                            comparison={[
                                              {
                                                value: krizEntity.krizDurumu?.id,
                                                checkValue: ReferansEnum.KrizDurumu.Aktif,
                                                contentKey: 'error.comparison.crisisIsPassive',
                                              },
                                            ]}
                                            onClick={handleYakiniOpen}
                                            className="accordion-create-button"
                                            size="sm"
                                            data-cy="entityCreateButton"
                                          >
                                            <img width={14} height={14} src={`${SERVER_URL}/content/images/icon/faPlusBlue.svg`} />
                                            <span className="d-none d-md-inline">
                                              <Translate contentKey="entity.action.yakinEkle">Yeni Kayıt</Translate>
                                            </span>
                                          </GrantedButton>
                                        </div>
                                      </TabPane>
                                      <TabPane key={2} tabId={2}>
                                        <EtkilenenKisiEkKisiEkDosyaTab ekKisi={selectedEkKisi} kriz={krizEntity} tab={activeTab} />
                                      </TabPane>
                                      <TabPane key={8} tabId={8}>
                                        <EtkilenenKisiNoteTab etkilenenKisi={selectedEkKisi?.kisi} tab={activeTab} />
                                      </TabPane>
                                    </TabContent>
                                  </div>
                                </Collapse>
                              </TableCell>
                            </TableRow>
                          </>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
                <CustomPagination
                  currentPage={pageObject.page + 1}
                  currentPageSize={pageObject.size}
                  totalItems={totalItem}
                  handlePageChange={handlePageChange}
                />
              </>
            ) : (
              <div className="alert alert-warning" style={{ marginTop: '1rem' }}>
                <Translate contentKey="tk24AdysApp.ucusKisiListesi.home.notFoundEkKisi">ucusKisiListesi not found.</Translate>
              </div>
            )}
          </div>
        </div>

        {ekKisiModal()}
        {selectedEkKisi && (
          <>
            {isYakiniModal && yakiniModal()}
            {isAssignModal && assignModal()}
            {isRemindingModal && remindingModal()}
            {isFormModal && formModal()}
            {isSaglikDialog && (
              <EtkilenenKisiSaglikDialog
                open={isSaglikDialog}
                headingName={selectedEkKisi?.adi + ' ' + selectedEkKisi?.soyadi + ' - ' + selectedEkKisi?.kisiAltTipi?.adi}
                selectedYolcu={selectedEkKisi.kisi}
                krizId={props.krizId}
                onClose={handleCloseDialog}
                saveOnClose={() => {
                  handleCloseDialog();
                  sortEntities(selectedFilter);
                }}
              />
            )}
          </>
        )}
      </div>
    )
  );
};

export default EtkilenenKisiEkKisi;
