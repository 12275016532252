import { IReferans } from 'app/shared/model/referans.model';
import { IQueryParams } from '../reducers/reducer.utils';
import { IKriz } from './kriz.model';
import { IRefAdtForm } from './ref-adt-form.model';
import { IKrizKatilimci } from './kriz-katilimci.model';
import { IKisi } from './kisi.model';

export interface IKrizDoldurulanFormlar {
  id?: number;
  guid?: string;
  realId?: number;
  cDate?: string;
  cUser?: number;
  mDate?: string;
  mUser?: number;
  durum?: IReferans;
  adiTr?: string;
  adiEn?: string;
  adi?: string;
  aciklamaTr?: string;
  aciklamaEn?: string;
  aciklama?: string;
  formTipi?: IReferans;
  uygulama?: IReferans;
  form?: IRefAdtForm;
  kriz?: IKriz;
  dolduranKk?: IKrizKatilimci;
  doldurulmaTarihi?: string;
  kisiDetay?: IKisi;
}
export interface IKrizDoldurulanFormlarParams extends IQueryParams {
  entity?: IKrizDoldurulanFormlar;
  krizId?: string;
  kisiId?: string;
  kisiGuid?: string;
  kisiTipiId?: string;
  kisiAltTipiId?: string;
  formGuid?: string;
  globalFilter?: string;
  formTipiId?:number;
}

export const defaultValue: Readonly<IKrizDoldurulanFormlar> = {};
