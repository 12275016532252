import React, { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { TextFormat, Translate, translate, Storage } from 'app/component/jhipster';
import { RouteComponentProps } from 'react-router-dom';
import { getKrizDurumEntities } from 'app/entities/kriz/kriz.reducer';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { ASC, DESC } from 'app/shared/util/pagination.constants';
import { ReferansEnum } from 'app/constants/ReferansEnum';
import { LoadingSpinner } from 'app/component/LoadingSpinner';
import CustomPagination from 'app/component/CustomPagination';
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Table,
} from '@mui/material';
import { getEntities as getReferans } from '../referans/referans.reducer';
import { getEntities as getKrizTuruEntities } from '../ref-kriz-turu/ref-kriz-turu.reducer';
import { getEntities as getKrizSeviyeEntities } from '../ref-kriz-seviye/ref-kriz-seviye.reducer';
import { isNullOrEmpty } from 'app/shared/util/confirm-utils';
import { MenuProps } from 'app/shared/util/filter.constants';
import { arrayUnique, localeLanguage, upperOnlyFirstLetter } from 'app/shared/util/util';
import GrantedButton from 'app/component/GrantedButton';
import { IQueryParams } from 'app/shared/reducers/reducer.utils';
import axios from 'axios';
import { setAuthenticationGrants } from 'app/shared/reducers/authentication';
import { toast } from 'react-toastify';
import SortIcon from 'app/component/SortIcon';
import { Search } from '@mui/icons-material';
import { Input } from 'reactstrap';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { convertDateTimeZoneAppDate } from 'app/shared/util/date-utils';

const GecmisKriz = (props: RouteComponentProps<{ url: string }>) => {
  const dispatch = useAppDispatch();
  const krizList = useAppSelector(state => state.kriz.entities);
  const loading = useAppSelector(state => state.kriz.loading);
  const [loadingKrizKatilimci, setLoadingKrizKatilimci] = useState(false);
  const account = useAppSelector(state => state.authentication.account);
  const totalItems = useAppSelector(state => state.kriz.totalItems);
  const refKrizTuruList = useAppSelector(state => state.refKrizTuru.entities);
  const refKrizSeviyeList = useAppSelector(state => state.refKrizSeviye.entities);
  const referans = useAppSelector(state => state.referans.entities);
  const refToplantiTipiList = referans.filter(item => item.tipi === 'TOPLANTI_TIPI');
  const acilDurumTuruList = referans.filter(res => res.tipi === 'ACIL_DURUM_TURU').sort((a, b) => a.sortIndex - b.sortIndex);
  const [pageObject, setPageObject] = useState<IQueryParams>(useAppSelector(state => state.paginate.pageObject));
  const [search, setSearch] = useState('');

  useEffect(() => {
    dispatch(getReferans({ page: 0, size: 2000, sort: `adi${upperOnlyFirstLetter(localeLanguage)},asc` }));
    dispatch(getKrizTuruEntities({ size: 1000, page: 0, sort: `adi${upperOnlyFirstLetter(localeLanguage)},asc` }));
    dispatch(getKrizSeviyeEntities({ size: 1000, page: 0, sort: `adi${upperOnlyFirstLetter(localeLanguage)},asc` }));
  }, []);

  const getAllEntities = () => {
    dispatch(
      getKrizDurumEntities({
        ...pageObject,
        krizDurumId: [ReferansEnum.KrizDurumu.Kapandi, ReferansEnum.KrizDurumu.IptalEdildi],
      })
    );
  };

  useEffect(() => {
    getAllEntities();
  }, [pageObject.page, pageObject.query, pageObject.size, pageObject.sort]);

  const sort = p => () => {
    const orderBy = pageObject.sort.split(',')[1];
    setPageObject({ ...pageObject, sort: `${p},${orderBy === ASC ? DESC : ASC}` });
  };

  const handlePageChange = (pageNumber, newItemsPerPage = pageObject.size) => {
    if (newItemsPerPage !== pageObject.size) {
      setPageObject({ ...pageObject, page: pageNumber - 1, size: newItemsPerPage });
    } else {
      setPageObject({ ...pageObject, page: pageNumber - 1 });
    }
  };

  useEffect(() => {
    if (search.length > 2) {
      setPageObject(prevPageObject => ({
        ...prevPageObject,
        page: 0,
        query: prevPageObject.query + `&globalFilter=${search}`,
      }));
    } else if (search.length === 0 && !isNullOrEmpty(pageObject.query)) {
      const keyToDelete = 'globalFilter';
      const updatedParams = pageObject.query
        .split('&')
        .filter(param => {
          const [key, _] = param.split('=');
          return key !== keyToDelete;
        })
        .join('&');

      setPageObject({ ...pageObject, page: 0, query: updatedParams });
    }
  }, [search]);

  const handleSearchChange = (elem, event) => {
    if (!isNullOrEmpty(event.target.value)) {
      setPageObject(prevPageObject => ({
        ...prevPageObject,
        page: 0,
        query: prevPageObject.query + `&${elem}.equals=${event.target.value}`,
      }));
    } else {
      const keyToDelete = `${elem}.equals`;
      const updatedParams = pageObject.query
        .split('&')
        .filter(param => {
          const [key, _] = param.split('=');
          return key !== keyToDelete;
        })
        .join('&');

      setPageObject({ ...pageObject, page: 0, query: updatedParams });
    }
  };

  const handleDateSearchChange = (queryString, event) => {
    if (!isNullOrEmpty(event)) {
      if (queryString === 'krizOlusmaZamani.lessThanOrEqual') {
        event = event.hour(23).minute(59);
      }
      setPageObject(prevPageObject => ({
        ...prevPageObject,
        page: 0,
        query: prevPageObject.query + `&${queryString}=${convertDateTimeZoneAppDate(event)}`,
      }));
    } else {
      const keyToDelete = `${queryString}`;
      const updatedParams = pageObject.query
        .split('&')
        .filter(param => {
          const [key, _] = param.split('=');
          return key !== keyToDelete;
        })
        .join('&');

      setPageObject({ ...pageObject, page: 0, query: updatedParams });
    }
  };

  const historyPushByKrizDetay = selectedKrizId => {
    Storage.session.set('kriz', selectedKrizId);
    props.history.push(`/kriz-detay/${selectedKrizId}`);
  };

  const control = async selectedKrizId => {
    setLoadingKrizKatilimci(true);

    try {
      const entryCheck = (await axios.get(`api/kriz-katilimcis/crisis-entry-check/${selectedKrizId}`)).data;
      Storage.session.set('isAdmin', entryCheck?.isAdmin);

      if (entryCheck.isAdmin) {
        historyPushByKrizDetay(selectedKrizId);
      } else if (entryCheck.isKrizKatilimci || entryCheck.canAccess) {
        dispatch(
          setAuthenticationGrants(
            arrayUnique(
              account.authorities
                .filter(e => !e.startsWith('kriz') || e === 'kriz_katilimci_read' || e === 'kriz_katilimci_edit')
                .concat(entryCheck.authorities)
            )
          )
        );
        historyPushByKrizDetay(selectedKrizId);
      } else if (!entryCheck.canAccess) {
        toast.error(translate('error.noAccessPermission'));
        setLoadingKrizKatilimci(false);
      }
    } catch (error) {
      toast.error(translate(`${error.response.data.message}`));
      setLoadingKrizKatilimci(false);
    }

    setLoadingKrizKatilimci(false);
  };

  return (
    <div>
      {(loading || loadingKrizKatilimci) && <LoadingSpinner />}

      <h3>
        {' '}
        <Translate contentKey="home.gecmisKriz" />
      </h3>
      <div className="content-area">
        <div className="search-frame">
          <div className="seachiconposition-text">
            <Search className="seachicon" style={{ color: 'grey' }} />
            <Input
              value={search}
              onChange={e => setSearch(e.target.value)}
              placeholder={translate('entity.action.search')}
              className="search-input"
              type="text"
              name="categorySelect"
              id="categorySelect"
            />
          </div>

          <div className="create-area">
            <Box sx={{ minWidth: 150, maxWidth: 150 }}>
              <FormControl fullWidth>
                <InputLabel id="default-acilDurumTuru-label" className="select-input-label">
                  <Translate contentKey="tk24AdysApp.kriz.olayTuru" />
                </InputLabel>
                <Select
                  labelId="acilDurumTuru-label"
                  id="acilDurumTuru"
                  className="select-input"
                  label={translate('tk24AdysApp.kriz.olayTuru')}
                  onChange={event => handleSearchChange('acilDurumTuruId', event)}
                  MenuProps={MenuProps}
                >
                  <MenuItem value="">
                    <Translate contentKey="entity.action.all" />
                  </MenuItem>
                  {acilDurumTuruList &&
                    acilDurumTuruList.length > 0 &&
                    acilDurumTuruList.map(item => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.adi}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Box>

            <Box sx={{ minWidth: 150, maxWidth: 150 }}>
              <FormControl fullWidth>
                <InputLabel id="default-krizTuru-label" className="select-input-label">
                  <Translate contentKey="tk24AdysApp.kriz.krizTuru" />
                </InputLabel>
                <Select
                  labelId="krizTuru-label"
                  id="krizTuru"
                  className="select-input"
                  label={translate('tk24AdysApp.kriz.krizTuru')}
                  onChange={event => handleSearchChange('krizTuruId', event)}
                  MenuProps={MenuProps}
                >
                  <MenuItem value="">
                    <Translate contentKey="entity.action.all" />
                  </MenuItem>
                  {refKrizTuruList &&
                    refKrizTuruList.length > 0 &&
                    refKrizTuruList.map(item => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.adi}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Box>

            <Box sx={{ minWidth: 150, maxWidth: 150 }}>
              <FormControl fullWidth>
                <InputLabel id="default-krizSeviye-label" className="select-input-label">
                  <Translate contentKey="tk24AdysApp.kriz.krizSeviye" />
                </InputLabel>
                <Select
                  labelId="krizSeviye-label"
                  id="krizSeviye"
                  className="select-input"
                  label={translate('tk24AdysApp.kriz.krizSeviye')}
                  onChange={event => handleSearchChange('krizSeviyeId', event)}
                  MenuProps={MenuProps}
                >
                  <MenuItem value="">
                    <Translate contentKey="entity.action.all" />
                  </MenuItem>
                  {refKrizSeviyeList &&
                    refKrizSeviyeList.length > 0 &&
                    refKrizSeviyeList.map(item => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.adi}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Box>

            <Box sx={{ minWidth: 150, maxWidth: 150 }}>
              <FormControl fullWidth>
                <InputLabel id="default-toplantiTipi-label" className="select-input-label">
                  <Translate contentKey="tk24AdysApp.kriz.toplantiTipi" />
                </InputLabel>
                <Select
                  labelId="toplantiTipi-label"
                  id="toplantiTipi"
                  className="select-input"
                  label={translate('tk24AdysApp.kriz.toplantiTipi')}
                  onChange={event => handleSearchChange('toplantiTipiId', event)}
                  MenuProps={MenuProps}
                >
                  <MenuItem value="">
                    <Translate contentKey="entity.action.all" />
                  </MenuItem>
                  {refToplantiTipiList &&
                    refToplantiTipiList.length > 0 &&
                    refToplantiTipiList.map(item => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.adi}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Box>

            <Box className="date-time-search" sx={{ minWidth: 150, maxWidth: 150 }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  slotProps={{
                    textField: {
                      id: 'krizOlusmaZamaniBaslangic',
                      name: 'krizOlusmaZamaniBaslangic',
                      size: window.innerWidth < 1600 ? 'small' : 'medium',
                    },
                    field: { clearable: true },
                  }}
                  label={translate('tk24AdysApp.kriz.krizOlusmaZamaniBaslangic')}
                  onChange={event => handleDateSearchChange('krizOlusmaZamani.greaterThanOrEqual', event)}
                  format={APP_LOCAL_DATE_FORMAT}
                  className="select-input"
                />
              </LocalizationProvider>
            </Box>

            <Box className="date-time-search" sx={{ minWidth: 150, maxWidth: 150 }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  slotProps={{
                    textField: {
                      id: 'krizOlusmaZamaniBitis',
                      name: 'krizOlusmaZamaniBitis',
                      size: window.innerWidth < 1600 ? 'small' : 'medium',
                    },
                    field: { clearable: true },
                  }}
                  label={translate('tk24AdysApp.kriz.krizOlusmaZamaniBitis')}
                  onChange={event => handleDateSearchChange('krizOlusmaZamani.lessThanOrEqual', event)}
                  format={APP_LOCAL_DATE_FORMAT}
                  className="select-input"
                />
              </LocalizationProvider>
            </Box>
          </div>
        </div>
        <div className="table-responsive" style={{ marginTop: 5 }}>
          <TableContainer className="content-table">
            {krizList && krizList.length > 0 ? (
              <Table aria-label="a dense table">
                <TableHead className="content-table-title-area">
                  <TableRow className="table-head-row">
                    <TableCell align="left" className="table-title-cell hand" onClick={sort('acilDurumTuru')}>
                      <div className="tableHeaderContainer">
                        <Translate contentKey="tk24AdysApp.kriz.olayTuruTitle">olayTuru</Translate>&nbsp;
                        <SortIcon column="adiTr" activeSortColumn={pageObject.sort} />
                      </div>
                    </TableCell>

                    <TableCell align="left" className="table-title-cell hand" onClick={sort('krizTuru')}>
                      <div className="tableHeaderContainer">
                        <Translate contentKey="tk24AdysApp.kriz.krizTuru">Kriz Turu</Translate>&nbsp;
                        <SortIcon column="adiTr" activeSortColumn={pageObject.sort} />
                      </div>
                    </TableCell>

                    <TableCell align="left" className="table-title-cell hand" onClick={sort('krizSeviye')}>
                      <div className="tableHeaderContainer">
                        <Translate contentKey="tk24AdysApp.kriz.krizSeviye">Kriz Seviye</Translate>&nbsp;
                        <SortIcon column="adiTr" activeSortColumn={pageObject.sort} />
                      </div>
                    </TableCell>

                    <TableCell align="left" className="table-title-cell hand" onClick={sort('toplantiTipi')}>
                      <div className="tableHeaderContainer">
                        <Translate contentKey="tk24AdysApp.kriz.toplantiTipi">toplantiTipi</Translate>&nbsp;
                        <SortIcon column="adiTr" activeSortColumn={pageObject.sort} />
                      </div>
                    </TableCell>

                    <TableCell align="left" className="table-title-cell hand" onClick={sort('aciklama')}>
                      <div className="tableHeaderContainer">
                        <Translate contentKey="tk24AdysApp.kriz.aciklama">aciklama</Translate>&nbsp;
                        <SortIcon column="adiTr" activeSortColumn={pageObject.sort} />
                      </div>
                    </TableCell>

                    <TableCell align="left" className="table-title-cell hand" onClick={sort('krizYeriAciklama')}>
                      <div className="tableHeaderContainer">
                        <Translate contentKey="tk24AdysApp.kriz.krizYeriAciklama">krizYeriAciklama</Translate>&nbsp;
                        <SortIcon column="adiTr" activeSortColumn={pageObject.sort} />
                      </div>
                    </TableCell>

                    <TableCell align="left" className="table-title-cell hand" onClick={sort('krizOlusmaZamani')}>
                      <div className="tableHeaderContainer">
                        <Translate contentKey="tk24AdysApp.kriz.krizOlusmaZamani">krizOlusmaZamani</Translate>&nbsp;
                        <SortIcon column="adiTr" activeSortColumn={pageObject.sort} />
                      </div>
                    </TableCell>

                    <TableCell align="left" className="table-title-cell hand" onClick={sort('krizDurumu')}>
                      <div className="tableHeaderContainer">
                        <Translate contentKey="tk24AdysApp.kriz.krizDurumu">krizDurumu</Translate>&nbsp;
                        <SortIcon column="adiTr" activeSortColumn={pageObject.sort} />
                      </div>
                    </TableCell>

                    <TableCell align="left" className="table-title-cell hand" onClick={sort('krizinBildirilmeZamani')}>
                      <div className="tableHeaderContainer">
                        <Translate contentKey="tk24AdysApp.kriz.krizBildirilmeZamani">krizBildirilmeZamani</Translate>&nbsp;
                        <SortIcon column="adiTr" activeSortColumn={pageObject.sort} />
                      </div>
                    </TableCell>

                    <TableCell align="left" className="table-title-cell hand" onClick={sort('kriziBildiren')}>
                      <div className="tableHeaderContainer">
                        <Translate contentKey="tk24AdysApp.kriz.krizBildiren">krizBildiren</Translate>&nbsp;
                        <SortIcon column="adiTr" activeSortColumn={pageObject.sort} />
                      </div>
                    </TableCell>

                    <TableCell align="left" className="table-title-cell hand" onClick={sort('krizKapanmaZamani')}>
                      <div className="tableHeaderContainer">
                        <Translate contentKey="tk24AdysApp.kriz.krizKapanmaZamani">krizKapanmaZamani</Translate>&nbsp;
                        <SortIcon column="adiTr" activeSortColumn={pageObject.sort} />
                      </div>
                    </TableCell>

                    <TableCell className="sticky-right-header"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {krizList.map(kriz => (
                    <TableRow key={kriz.id} className="table-cell-row">
                      <TableCell component="th" scope="row" className="table-content-cell">
                        {kriz.acilDurumTuru && kriz.acilDurumTuru.adi}
                      </TableCell>
                      <TableCell component="th" scope="row" className="table-content-cell">
                        {kriz.krizTuru && kriz.krizTuru.adi}{' '}
                      </TableCell>
                      <TableCell component="th" scope="row" className="table-content-cell">
                        {kriz.krizSeviye && kriz.krizSeviye.adi}{' '}
                      </TableCell>
                      <TableCell component="th" scope="row" className="table-content-cell">
                        {kriz.toplantiTipi && kriz.toplantiTipi.adi}{' '}
                      </TableCell>
                      <TableCell component="th" scope="row" className="table-content-cell">
                        {kriz.aciklama}{' '}
                      </TableCell>
                      <TableCell component="th" scope="row" className="table-content-cell">
                        {kriz.krizYeriAciklama}{' '}
                      </TableCell>
                      <TableCell component="th" scope="row" className="table-content-cell">
                        {kriz.krizOlusmaZamani && <TextFormat type="date" value={kriz.krizOlusmaZamani} format={APP_DATE_FORMAT} />}
                      </TableCell>
                      <TableCell component="th" scope="row" className="table-content-cell">
                        {kriz.krizDurumu.adi}{' '}
                      </TableCell>
                      <TableCell component="th" scope="row" className="table-content-cell">
                        {kriz.krizinBildirilmeZamani && (
                          <TextFormat type="date" value={kriz.krizinBildirilmeZamani} format={APP_DATE_FORMAT} />
                        )}
                      </TableCell>
                      <TableCell component="th" scope="row" className="table-content-cell">
                        {kriz.kriziBildiren}{' '}
                      </TableCell>
                      <TableCell component="th" scope="row" className="table-content-cell">
                        {kriz.krizKapanmaZamani && <TextFormat type="date" value={kriz.krizKapanmaZamani} format={APP_DATE_FORMAT} />}
                      </TableCell>
                      <TableCell component="th" scope="row" className="sticky-right">
                        <div className="btn-group flex-btn-group-container">
                          <GrantedButton
                            grantedAuthorites={['gecmis_kriz_detay']}
                            className="edit-button"
                            style={{ marginLeft: 2 }}
                            size="sm"
                            data-cy="entityEditButton"
                            onClick={() => control(kriz.id)}
                          >
                            <span className="d-md-inline">
                              <Translate contentKey="entity.action.giris">Giriş</Translate>
                            </span>
                          </GrantedButton>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            ) : (
              !loading && (
                <div className="alert alert-warning mt-3">
                  <Translate contentKey="tk24AdysApp.kriz.home.notFound">No Krizs found</Translate>
                </div>
              )
            )}
          </TableContainer>
        </div>
      </div>
      <CustomPagination
        currentPage={pageObject.page + 1}
        currentPageSize={pageObject.size}
        totalItems={totalItems}
        handlePageChange={handlePageChange}
      />
    </div>
  );
};

export default GecmisKriz;
