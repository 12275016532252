import React from 'react';
import MenuItem from 'app/shared/layout/menus/menu-item-account';
import { Translate } from 'app/component/jhipster';
import { NavDropdown } from './menu-components';
import { DropdownItem } from 'reactstrap';
import { useAppSelector } from 'app/config/store';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';

export const AccountMenu = ({ isAuthenticated = false }) => {
  const oidcUrl = useAppSelector(state => state.authentication.oidcUrl);
  const firstName = useAppSelector(state => state.authentication.account.firstName);
  const lastName = useAppSelector(state => state.authentication.account.lastName);
  const sicilNo = useAppSelector(state => state.authentication.account.sicilNo);
  const imageUser = useAppSelector(state => state.authentication.account.personel);

  const accountMenuItemsAuthenticated = (
    <>
      <MenuItem to="/account/settings" data-cy="settings">
        <SettingsOutlinedIcon style={{ color: '#607083', marginRight: 15 }} />
        <Translate contentKey="global.menu.account.settings">Settings</Translate>
      </MenuItem>
      <MenuItem to="/logout" data-cy="logout">
        <LogoutOutlinedIcon style={{ color: '#607083', marginRight: 15 }} />
        <Translate contentKey="global.menu.account.logout">Sign out</Translate>
      </MenuItem>
    </>
  );

  const accountMenuItems = oidcUrls => {
    return (
      <>
        <DropdownItem id="login-item">
          <LogoutOutlinedIcon style={{ color: '#607083', marginRight: 15 }} />
          <a href={oidcUrls} style={{ color: '#34404F' }}>
            <Translate contentKey="global.menu.account.login">Sign in</Translate>
          </a>
        </DropdownItem>
      </>
    );
  };

  return (
    <NavDropdown
      icon="user"
      firstName={firstName}
      lastName={lastName}
      className="accountMenu"
      id="account-menu"
      data-cy="accountMenu"
      isAuthenticated={isAuthenticated}
      imageUser={imageUser}
    >
      {isAuthenticated ? accountMenuItemsAuthenticated : accountMenuItems(oidcUrl)}
    </NavDropdown>
  );
};

export default AccountMenu;
