import React from 'react';
import { Button, UncontrolledTooltip } from 'reactstrap';
import { isObjectEmpty } from 'app/shared/util/confirm-utils';
import { hasAnyAuthority } from 'app/config/store';
import { IGrantedButtonProps, IGrantedButtonState } from 'app/shared/model/granted-button-model';
import { Translate } from 'app/component/jhipster';

export default class GrantedButton extends React.Component<IGrantedButtonProps, IGrantedButtonState> {
  constructor(props: IGrantedButtonProps) {
    super(props);

    this.state = {
      nonMatchingRecord: null,
    };
  }

  componentDidMount() {
    const { comparison } = this.props;
    if (comparison) {
      this.setState({ nonMatchingRecord: comparison.find(item => item.value !== item.checkValue) });
    }
  }

  render() {
    const { nonMatchingRecord } = this.state;
    const { grantedAuthorites, children } = this.props;

    return hasAnyAuthority(grantedAuthorites) ? (
      !isObjectEmpty(nonMatchingRecord) ? (
        <>
          <Button disabled={true} {...this.props}>
            {children}
          </Button>
          <UncontrolledTooltip placement="right" target={this.props?.id}>
            <Translate contentKey={nonMatchingRecord.contentKey} />
          </UncontrolledTooltip>
        </>
      ) : (
        <Button {...this.props}>{children}</Button>
      )
    ) : null;
  }
}
