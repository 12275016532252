import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Form, Modal, ModalHeader, ModalBody, ModalFooter, Col, Row, Alert } from 'reactstrap';
import { TextFormat, Translate, translate } from 'app/component/jhipster';
import { updateEntity, createEntity } from './ref-adt-form.reducer';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { IRefAdtForm } from 'app/shared/model/ref-adt-form.model';
import { CustomDeleteEntityConfirm, CustomSaveEntityConfirm, getHeaderContentKey, isNullOrEmpty } from 'app/shared/util/confirm-utils';
import { FormItem } from 'app/component/FormItem';
import { ReferansEnum } from 'app/constants/ReferansEnum';
import { IQueryParams } from 'app/shared/reducers/reducer.utils';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { APP_DATE_FORMAT, SERVER_URL } from 'app/config/constants';
import axios from 'axios';
import { IEkDosya } from 'app/shared/model/ek-dosya.model';
import {
  deleteEntity as deleteEkDosyaEntity,
  getFilteredEntities as getFilterAdtFormEntities,
  uploadEntity as uploadEkDosyaEntity,
} from 'app/entities/ek-dosya/ek-dosya.reducer';
import TableNameEnum from 'app/constants/TableNameEnum';
import { GetBase64PromiseString } from 'app/shared/util/util';
import { RefEkDosyaTipiCodeEnum } from 'app/constants/RefEkDosyaTipiCodeEnum';
import { convertDateTimeZoneAppDate } from 'app/shared/util/date-utils';

interface RefAdtFormUpdateProp {
  handleCloseForm: () => void;
  formValues?: IRefAdtForm;
  isOpen: boolean;
  pageObject?: IQueryParams;
  setIsLoading: (loading: boolean) => void;
  isViewing: boolean;
}

export const RefAdtFormUpdate: React.FC<RefAdtFormUpdateProp> = ({
  handleCloseForm,
  formValues,
  isOpen,
  pageObject,
  setIsLoading,
  isViewing,
}) => {
  const dispatch = useAppDispatch();
  const referans = useAppSelector(state => state.referans.entities);

  const [customIsOpen, setCustomIsOpen] = useState(isOpen);
  const [modalPromiseResolve, setModalPromiseResolve] = useState(null);
  const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);

  const fileInputRef = useRef<HTMLInputElement>(null);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const adtFormList = useAppSelector(state => state.ekDosya.entities);
  const refEkDosyaTipis = useAppSelector(state => state.refEkDosyaTipi.entities);
  const [deleteFile, setDeleteFile] = useState<IEkDosya | null>(null);
  const [isDeleteEkDosyaModalOpen, setIsDeleteEkDosyaModalOpen] = useState(false);
  const [selectedFormTipi, setSelectedFormTipi] = useState(formValues?.formTipi?.id || '');
  const [fileNames, setFileNames] = useState({});
  const [ekDosyaAciklama, setEkDosyaAciklama] = useState('');
  const [alertMessage, setAlertMessage] = useState(null);

  const handleFormTipiChange = e => {
    setSelectedFormTipi(e.target.value);
  };

  useEffect(() => {
    if (formValues?.formTipi?.id === 120) {
      setSelectedFormTipi('120');
    } else if (formValues?.formTipi?.id) {
      setSelectedFormTipi(formValues.formTipi.id.toString());
    }
  }, [formValues]);

  const handleCloseUpdateForm = () => {
    handleCloseForm();
    setSelectedFiles([]);
    setFileNames({});
    setEkDosyaAciklama('');
  };

  const handleDownloadSavedFile = async file => {
    setIsLoading(true);
    try {
      const response = await axios.get(`api/ek-dosyas/download/${file.id}`, {
        responseType: 'blob',
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${file.dosyaAdi}`);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error('Dosya indirme hatası:', error);
    }
    setIsLoading(false);
  };

  const confirmAdtFormDelete = ekDosya => {
    setDeleteFile(ekDosya);
    setCustomIsOpen(false);
    setIsDeleteEkDosyaModalOpen(true);
  };

  const handleRemoveFile = index => {
    const newFiles = [...selectedFiles];
    newFiles.splice(index, 1);
    setSelectedFiles(newFiles);
  };

  const handleDeleteConfirm = useCallback(async () => {
    if (deleteFile) {
      const ekDosyaId = deleteFile.ilgiliTabloKayitId;
      setIsLoading(true);
      await dispatch(deleteEkDosyaEntity(deleteFile.id));
      dispatch(
        getFilterAdtFormEntities({
          ilgiliTabloKayitId: ekDosyaId,
          ilgiliTablo: TableNameEnum.RefAdtForm,
        })
      );
      setIsLoading(false);
      setDeleteFile(null);
      setIsDeleteEkDosyaModalOpen(false);
      setCustomIsOpen(true);
    }
  }, [deleteFile, dispatch]);

  const handleDrop = event => {
    event.preventDefault();
    const files = Array.from(event.dataTransfer.files);
    setSelectedFiles([...selectedFiles, ...files]);
  };

  const getFileNameAndExtension = filename => {
    const dotIndex = filename.lastIndexOf('.');
    if (dotIndex === -1) {
      return { name: filename, extension: '' };
    }
    return { name: filename.substring(0, dotIndex), extension: filename.substring(dotIndex) };
  };

  const handleFileNameChange = (index, newName) => {
    setFileNames(prevNames => ({
      ...prevNames,
      [index]: newName,
    }));

    const updatedFiles = selectedFiles.map((file, fileIndex) => {
      if (fileIndex === index) {
        const { extension } = getFileNameAndExtension(file.name);
        return new File([file], `${newName}${extension}`, { type: file.type });
      }
      return file;
    });

    setSelectedFiles(updatedFiles);
  };

  const handleEkDosyaAciklamaChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEkDosyaAciklama(event.target.value);
  };

  const fileUpload = async (dosya: any) => {
    const fileInfoArray = await GetBase64PromiseString(selectedFiles);
    const entity = {
      ...dosya,
      fileList: fileInfoArray.map((file, index) => {
        const { extension } = getFileNameAndExtension(file.fileName);
        const updatedName = fileNames[index] || getFileNameAndExtension(file.fileName).name;
        return {
          ...file,
          fileName: `${updatedName}${extension}`,
          aciklama: ekDosyaAciklama,
        };
      }),
    };
    dispatch(uploadEkDosyaEntity(entity));
  };

  const handleDeleteCancel = () => {
    setIsDeleteEkDosyaModalOpen(false);
    setCustomIsOpen(true);
    setDeleteFile(null);
  };

  const showSaveConfirmModal = () => {
    setIsSaveModalOpen(true);
    return new Promise(resolve => {
      setModalPromiseResolve(() => resolve);
    });
  };

  const handleConfirm = () => {
    if (modalPromiseResolve) {
      modalPromiseResolve(true);
    }
    setIsSaveModalOpen(false);
  };

  const handleCancel = () => {
    if (modalPromiseResolve) {
      modalPromiseResolve(false);
    }
    setIsSaveModalOpen(false);
  };

  const handleFileChange = e => {
    const files = e.target.files;
    setSelectedFiles([...selectedFiles, ...files]);
  };

  function gecerlilikDatetimeKontrol(gecerlilikBaslangicTarihi, gecerlilikBitisTarihi) {
    if (gecerlilikBitisTarihi !== null) {
      if (gecerlilikBaslangicTarihi > gecerlilikBitisTarihi) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }

  const saveEntity = async e => {
    e.preventDefault();
    setCustomIsOpen(false);

    const formDataObject = {};
    for (const field of e.target.elements) {
      if (field.name) {
        switch (field.name) {
          case 'gecerlilikBaslangicTarihi':
          case 'gecerlilikBitisTarihi':
            formDataObject[field.name] = convertDateTimeZoneAppDate(field.value);
            break;
          default:
            formDataObject[field.name] = field.value;
            break;
        }
      }
    }

    const isDatetimeKontrol = gecerlilikDatetimeKontrol(
      formDataObject['gecerlilikBaslangicTarihi'],
      formDataObject['gecerlilikBitisTarihi']
    );
    if (!isDatetimeKontrol) {
      setCustomIsOpen(true);
      setAlertMessage(
        translate('entity.validation.lessThanField', { field: translate('tk24AdysApp.refAdtForm.gecerlilikBaslangicTarihi') })
      );
    } else {
      const isSave = await showSaveConfirmModal();

      if (isSave) {
        const adtFormEntity = {
          ...formValues,
          ...formDataObject,
          formTipi: referans.find(it => it.id.toString() === formDataObject['formTipiId'].toString()),
          formSahibi: referans.find(it => it.id.toString() === formDataObject['formSahibiId'].toString()),
          uygulama: referans.find(item => item.id.toString() === ReferansEnum.Uygulama.TK24.toString()),
        };

        if (formValues !== null) {
          await dispatch(updateEntity({ ...pageObject, entity: adtFormEntity }));

          if (selectedFiles !== null && selectedFiles.length > 0) {
            const ekDosyaSabit = {
              ilgiliTablo: TableNameEnum.RefAdtForm,
              ilgiliTabloKayitId: adtFormEntity['realId'],
              onaylandiMi: ReferansEnum.EvetHayir.Hayir,
              ekDosyaTipi: refEkDosyaTipis.find(res => res.code === RefEkDosyaTipiCodeEnum.HazirPdfDokuman),
              dil: referans.find(it => it.id.toString() === formDataObject['ekDosyaDil']),
              durum: referans.find(it => it.id === ReferansEnum.Durum.Aktif),
            };

            fileUpload(ekDosyaSabit);
          }
        } else {
          const createEntityAction: any = await dispatch(createEntity({ ...pageObject, entity: adtFormEntity }));

          if (selectedFiles !== null && selectedFiles.length > 0) {
            const createdEntity: { realId: number } = createEntityAction.payload.data;

            const ekDosyaSabit = {
              ilgiliTablo: TableNameEnum.RefAdtForm,
              ilgiliTabloKayitId: createdEntity.realId,
              onaylandiMi: ReferansEnum.EvetHayir.Hayir,
              ekDosyaTipi: refEkDosyaTipis.find(res => res.code === RefEkDosyaTipiCodeEnum.HazirPdfDokuman),
              dil: referans.find(it => it.id.toString() === formDataObject['ekDosyaDil']),
              durum: referans.find(it => it.id === ReferansEnum.Durum.Aktif),
            };

            fileUpload(ekDosyaSabit);
          }
        }

        handleCloseUpdateForm();
      } else {
        setCustomIsOpen(true);
      }
    }
  };

  return (
    <>
      {deleteFile && (
        <CustomDeleteEntityConfirm
          entity={deleteFile}
          entityName="ekDosya"
          isOpen={isDeleteEkDosyaModalOpen}
          onConfirm={handleDeleteConfirm}
          onCancel={handleDeleteCancel}
        />
      )}
      <CustomSaveEntityConfirm entity={formValues} isOpen={isSaveModalOpen} onConfirm={handleConfirm} onCancel={handleCancel} />

      <Modal className="modal-style-lg" isOpen={customIsOpen} toggle={handleCloseUpdateForm} centered>
        <ModalHeader data-cy="AdtFormCreateUpdateHeading" toggle={handleCloseUpdateForm}>
          {formValues && (
            <p className="modal-title-info">
              {formValues?.adiTr} - {formValues?.adiEn}
            </p>
          )}
          <Translate contentKey={getHeaderContentKey(isViewing, formValues, 'refAdtForm')} />
        </ModalHeader>

        <Form onSubmit={saveEntity}>
          <ModalBody style={{ maxHeight: '500px', overflow: 'auto' }}>
            <FormItem
              label={translate('global.languageGlobal.adiTr')}
              id="ref-adt-form-adiTr"
              name="adiTr"
              defaultValue={formValues && formValues['adiTr']}
              disabled={isViewing}
              type="text"
              validation={{
                required: { value: true, message: translate('entity.validation.required') },
                maxLength: { value: 100, message: translate('entity.validation.maxlength', { max: 100 }) },
              }}
            />
            <FormItem
              label={translate('global.languageGlobal.adiEn')}
              id="ref-adt-form-adiEn"
              name="adiEn"
              defaultValue={formValues && formValues['adiEn']}
              disabled={isViewing}
              type="text"
              validation={{
                required: { value: true, message: translate('entity.validation.required') },
                maxLength: { value: 100, message: translate('entity.validation.maxlength', { max: 100 }) },
              }}
            />

            <FormItem
              label={translate('tk24AdysApp.refAdtForm.formSahibi')}
              id="ref-adt-form-formSahibi"
              name="formSahibiId"
              defaultValue={formValues && formValues['formSahibi'] !== null && formValues.formSahibi.id}
              disabled={isViewing}
              type="select"
              validation={{
                required: { value: true, message: translate('entity.validation.required') },
              }}
            >
              <option value="" key="0" />
              {referans &&
                referans
                  .filter(item => item.tipi === 'GOREV_SAHIBI')
                  .map(otherEntity => (
                    <option value={otherEntity.id} key={otherEntity.id}>
                      {otherEntity.adi}
                    </option>
                  ))}
            </FormItem>

            <FormItem
              label={translate('tk24AdysApp.refAdtForm.formTipi')}
              id="ref-adt-form-id"
              name="formTipiId"
              defaultValue={formValues && formValues['formTipi'] !== null && formValues.formTipi.id}
              disabled={isViewing}
              type="select"
              validation={{
                required: { value: true, message: translate('entity.validation.required') },
              }}
              onChange={handleFormTipiChange}
            >
              <option value="" key="0" />
              {referans &&
                referans
                  .filter(item => item.tipi === 'FORM_TIPI')
                  .map(otherEntity => (
                    <option value={otherEntity.id} key={otherEntity.id}>
                      {otherEntity.adi}
                    </option>
                  ))}
            </FormItem>

            <FormItem
              id="gecerlilikBaslangicTarihi"
              name="gecerlilikBaslangicTarihi"
              label={translate('tk24AdysApp.refAdtForm.gecerlilikBaslangicTarihi')}
              type="datetime"
              defaultValue={formValues && formValues['gecerlilikBaslangicTarihi']}
              disabled={isViewing}
              disablePast={true}
            />

            <FormItem
              id="gecerlilikBitisTarihi"
              name="gecerlilikBitisTarihi"
              label={translate('tk24AdysApp.refAdtForm.gecerlilikBitisTarihi')}
              type="datetime"
              defaultValue={formValues && formValues['gecerlilikBitisTarihi']}
              disabled={isViewing}
              disablePast={true}
            />

            {!isNullOrEmpty(alertMessage) && (
              <div>
                <Alert color="danger" toggle={() => setAlertMessage(null)}>
                  {alertMessage}
                </Alert>
              </div>
            )}

            <FormItem
              label={translate('tk24AdysApp.refAdtForm.aciklamaTr')}
              id="aciklama-tr"
              name="aciklamaTr"
              defaultValue={formValues && formValues['aciklamaTr']}
              disabled={isViewing}
              type="textarea"
              validation={{
                required: { value: true, message: translate('entity.validation.required') },
                maxLength: { value: 1500, message: translate('entity.validation.maxlength', { max: 1500 }) },
              }}
            />

            <FormItem
              label={translate('tk24AdysApp.refAdtForm.aciklamaEn')}
              id="aciklama-en"
              name="aciklamaEn"
              defaultValue={formValues && formValues['aciklamaEn']}
              disabled={isViewing}
              type="textarea"
              validation={{
                required: { value: true, message: translate('entity.validation.required') },
                maxLength: { value: 1500, message: translate('entity.validation.maxlength', { max: 1500 }) },
              }}
            />

            {formValues ? (
              <Col sm="12" style={{ marginTop: '10px', padding: '0px' }}>
                <Row className="modal-content-title" style={{ margin: '10px 0px' }}>
                  <Translate contentKey="tk24AdysApp.ekDosya.savedFiles" />
                </Row>

                <TableContainer className="content-table">
                  {adtFormList && adtFormList.length > 0 ? (
                    <Table aria-label="a dense table">
                      <TableHead className="content-table-title-area">
                        <TableRow className="table-head-row">
                          <TableCell className="table-title-cell hand" align="left">
                            <Translate contentKey="tk24AdysApp.ekDosya.dosyaAdi">dosyaAdi</Translate>
                          </TableCell>
                          <TableCell className="table-title-cell hand" align="left">
                            <Translate contentKey="tk24AdysApp.ekDosya.aciklamaTitle">aciklama</Translate>
                          </TableCell>
                          <TableCell className="table-title-cell hand" align="left">
                            <Translate contentKey="tk24AdysApp.ekDosya.fileLanguageTitle">dil</Translate>
                          </TableCell>
                          <TableCell className="table-title-cell hand" align="left">
                            <Translate contentKey="tk24AdysApp.ekDosya.fileExtentionTitle">fileExtention</Translate>
                          </TableCell>
                          <TableCell className="table-title-cell hand" align="left">
                            <Translate contentKey="tk24AdysApp.ekDosya.ekleyenKullanici">ekleyenKullanici</Translate>
                          </TableCell>
                          <TableCell className="table-title-cell hand" align="left">
                            <Translate contentKey="tk24AdysApp.ekDosya.cDateTitle">CDate</Translate>
                          </TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {adtFormList.map(ekDosya => (
                          <TableRow key={ekDosya.id} className="table-cell-row">
                            <TableCell className="table-content-cell" component="th" scope="row">
                              {ekDosya?.dosyaAdi}
                            </TableCell>
                            <TableCell className="table-content-cell" component="th" scope="row">
                              {ekDosya?.aciklama}
                            </TableCell>
                            <TableCell className="table-content-cell" component="th" scope="row">
                              {ekDosya?.dil?.adi}
                            </TableCell>
                            <TableCell className="table-content-cell" component="th" scope="row">
                              {ekDosya?.fileExtention}
                            </TableCell>
                            <TableCell className="table-content-cell" component="th" scope="row">
                              {ekDosya?.ekleyenKullanici?.adi} {ekDosya?.ekleyenKullanici?.soyadi}
                            </TableCell>
                            <TableCell className="table-content-cell" component="th" scope="row">
                              <TextFormat type="date" value={ekDosya.cDate} format={APP_DATE_FORMAT} />
                            </TableCell>
                            <TableCell className="table-content-cell" align="right">
                              <div className="btn-group flex-btn-group-container">
                                <Button
                                  className="download-file-button"
                                  style={{ marginLeft: 5 }}
                                  onClick={() => handleDownloadSavedFile(ekDosya)}
                                >
                                  <img src={`${SERVER_URL}/content/images/icon/faArrowDownToLine.svg`} />
                                  <Translate contentKey="entity.action.download">İndir</Translate>
                                </Button>
                                {!isViewing && (
                                  <Button className="delete-button" style={{ marginLeft: 5 }} onClick={() => confirmAdtFormDelete(ekDosya)}>
                                    <img width={11.2} height={12.8} src={`${SERVER_URL}/content/images/icon/faTrashCan.svg`} />
                                    <span>
                                      <Translate contentKey="entity.action.delete">Sil</Translate>
                                    </span>
                                  </Button>
                                )}
                              </div>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  ) : (
                    <div className="alert alert-warning m-2">
                      <Translate contentKey="tk24AdysApp.ekDosya.home.notFound">No Ek Dosya found</Translate>
                    </div>
                  )}
                </TableContainer>
              </Col>
            ) : (
              <></>
            )}

            <hr style={{ marginTop: 20, marginBottom: 15 }} />

            <FormItem
              label={translate('tk24AdysApp.ekDosya.fileLanguage')}
              id="ref-ek-dosya-dil-id"
              name="ekDosyaDil"
              type="select"
              validation={selectedFiles.length > 0 ? { required: { value: true, message: translate('entity.validation.required') } } : {}}
            >
              <option value="" key="0" />
              {referans &&
                referans
                  .filter(item => item.tipi === 'DIL')
                  .map(otherEntity => (
                    <option value={otherEntity.id} key={otherEntity.id}>
                      {otherEntity.adi}
                    </option>
                  ))}
            </FormItem>

            <FormItem
              label={translate('tk24AdysApp.ekDosya.ekDosyaAciklama')}
              id="ek-dosya-aciklama"
              name="aciklama"
              defaultValue={ekDosyaAciklama}
              onChange={handleEkDosyaAciklamaChange}
              type="textarea"
              validation={{
                maxLength: { value: 1000, message: translate('entity.validation.maxlength', { max: 1000 }) },
              }}
            />

            <div className="modal-file-area">
              <div className="modal-dashed-file-area" onDrop={handleDrop} onDragOver={e => e.preventDefault()}>
                <img width={16} height={20} style={{ marginBottom: '20px' }} src={`${SERVER_URL}/content/images/icon/downloadIcon.svg`} />
                <span className="modal-file-text">
                  <span className="modal-upload-file-text" onClick={() => fileInputRef.current.click()}>
                    <Translate contentKey="tk24AdysApp.ekDosya.yukle" />
                  </span>
                  <span style={{ color: 'rgba(73, 88, 106, 1)' }}>
                    {' '}
                    <Translate contentKey="tk24AdysApp.ekDosya.surukle" />
                  </span>
                  <input type="file" ref={fileInputRef} style={{ display: 'none' }} onChange={handleFileChange} multiple />
                </span>
              </div>
              <div className="modal-file-list-area">
                {selectedFiles.length > 0 &&
                  selectedFiles.map((file, index) => {
                    const { name, extension } = getFileNameAndExtension(file.name);
                    return (
                      <div key={file.id} className="modal-file-list-row">
                        <div style={{ display: 'flex', justifyContent: 'start', alignItems: 'center', padding: '0px', width: '50px' }}>
                          <img width={16} height={20} src={`${SERVER_URL}/content/images/icon/downloadIcon.svg`} />
                        </div>
                        <div style={{ width: '100%' }}>
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'start',
                              justifyContent: 'center',
                              margin: 'auto',
                            }}
                          >
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              <input
                                type="text"
                                value={fileNames[index] || name}
                                onChange={e => handleFileNameChange(index, e.target.value)}
                                className="form-control"
                                style={{ width: '500px' }}
                                maxLength={950}
                              />
                              <span className="ml-2">{extension}</span>
                            </div>
                            <span className="modal-file-size">{`${(file.size / 1024).toFixed(2)}kb`}</span>
                          </div>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'end', alignItems: 'center', padding: '0px', width: '50px' }}>
                          <Button className="delete-file-button" onClick={() => handleRemoveFile(index)}>
                            <img width={12} height={15} src={`${SERVER_URL}/content/images/icon/deleteFileIcon.svg`} />
                          </Button>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </ModalBody>
          {!isViewing && (
            <ModalFooter className="modal-footer">
              <Button aria-label="cancel" className="cancel-button" onClick={handleCloseUpdateForm}>
                <Translate contentKey="entity.action.cancel">Cancel</Translate>
              </Button>

              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
                {selectedFormTipi === '121' && (
                  <Button className="dosya_btn mr-2" data-cy="entityDosyaButton" size="sm" onClick={() => fileInputRef.current.click()}>
                    <DriveFolderUploadIcon />
                    <span style={{ marginLeft: 5 }}>
                      <Translate contentKey="global.messages.ekDosyaEkle" />
                    </span>
                    <input type="file" ref={fileInputRef} style={{ display: 'none' }} onChange={handleFileChange} multiple />
                  </Button>
                )}

                <Button
                  aria-label="kaydet"
                  className="save-button"
                  id="jhi-confirm-delete-refAdtForm"
                  type="submit"
                  data-cy="entityConfirmButton"
                >
                  <Translate contentKey="entity.action.save">Kaydet</Translate>
                </Button>
              </div>
            </ModalFooter>
          )}
        </Form>
      </Modal>
    </>
  );
};

export default RefAdtFormUpdate;
