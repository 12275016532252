import React, { useEffect, useState } from 'react';

import { JhiPagination } from 'app/component/jhipster';
import { Button, Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import paginate, { setPageSize } from 'app/shared/reducers/pagination';

export interface IRDCPaginationProps {
  activePage: number;
  onSelect: (page: number) => void;
  maxButtons: number;
  totalItems: number;
  itemsPerPage: number;
}

export interface IRDCPaginationState {
  currentPage: number;
}

export const RDCPagination = props => {
  const currentPageSize = useAppSelector(state => state.paginate.currentPageSize);
  const dispatch = useAppDispatch();

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen(prevState => !prevState);

  useEffect(() => {
    props.syncPage && props.syncPage();
  }, [currentPageSize]);
  const changePageSize = pageSize => {
    props.onSelect(1);
    dispatch(setPageSize(pageSize));
  };

  return (
    <div style={{ flexDirection: 'row', justifyContent: 'space-between', display: 'flex' }}>
      <JhiPagination
        activePage={props.activePage}
        onSelect={props.onSelect}
        maxButtons={5}
        itemsPerPage={props.itemsPerPage}
        totalItems={props.totalItems}
      />

      <Dropdown isOpen={dropdownOpen} toggle={toggle} direction={'down'}>
        <DropdownToggle color="primary" caret>
          Kayıt Sayısı-{props.itemsPerPage}
        </DropdownToggle>
        <DropdownMenu color="primary">
          <DropdownItem
            onClick={() => {
              changePageSize(10);
            }}
          >
            10
          </DropdownItem>
          <DropdownItem
            onClick={() => {
              changePageSize(20);
            }}
          >
            20
          </DropdownItem>
          <DropdownItem
            onClick={() => {
              changePageSize(50);
            }}
          >
            50
          </DropdownItem>
          <DropdownItem
            onClick={() => {
              changePageSize(100);
            }}
          >
            100
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};
