import { SERVER_URL } from 'app/config/constants';
import { Storage, translate } from 'app/component/jhipster';
import React, { useEffect, useRef, useState } from 'react';
import { hasAnyAuthority, useAppDispatch, useAppSelector } from 'app/config/store';
import { useHistory } from 'react-router-dom';
import { Popover } from '@mui/material';
import Bildirimler from 'app/entities/kriz-dashboard/bildirimler';
import { toast } from 'react-toastify';
import axios from 'axios';
import { IQueryParams } from 'app/shared/reducers/reducer.utils';
import { getEntities, reset } from 'app/entities/kriz-katilimci-bildirim/kriz-katilimci-bildirim.reducer';
import { isObjectEmpty } from 'app/shared/util/confirm-utils';
import { ReferansEnum } from 'app/constants/ReferansEnum';
import { getEntities as getEntitiesDuyuru } from 'app/entities/kriz-duyurular/kriz-duyurular.reducer';

const ButtonHeader = () => {
  const history = useHistory();

  const handleDuyuruClick = () => {
    const krizId = Storage.session.get('kriz');
    history.push(`/kriz-duyurular/${krizId}`);
  };

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
    setNewNotification(false);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const krizKatilimciEntity = useAppSelector(state => state.krizKatilimci.entity);

  const dispatch = useAppDispatch();
  const [bildirimCount, setBildirimCount] = useState({ krizId: Storage.session.get('kriz'), count: 0 });
  const [pageObject, setPageObject] = useState<IQueryParams>({
    ...useAppSelector(state => state.paginate.pageObject),
    size: 999,
    page: 0,
    sort: 'cDate,desc',
    query: `gonderimDurumuId.in=${ReferansEnum.GonderimDurumu.Gonderildi},${ReferansEnum.GonderimDurumu.Gonderilecek}&bildirimTuruId.equals=64&krizKatilimciId.equals=${krizKatilimciEntity?.id}`,
  });

  const bildirimReadAuth = hasAnyAuthority(['kriz_katilimci_bildirim_read']);
  const [newNotification, setNewNotification] = useState(false);

  useEffect(() => {
    if (bildirimReadAuth && !isObjectEmpty(krizKatilimciEntity)) {
      const interval = setInterval(() => {
        const fetchData = async () => {
          const bildirimCountUrl = `api/kriz-katilimci-bildirims/count?gonderimDurumuId.in=21,22&bildirimTuruId.equals=64`;
          const count = (await axios.get(bildirimCountUrl)).data;

          if (Storage.session.get('kriz') !== bildirimCount.krizId) {
            setBildirimCount({ krizId: Storage.session.get('kriz'), count });
          } else if (count !== bildirimCount.count) {
            if (bildirimCount.count === 0) {
              setBildirimCount({ krizId: Storage.session.get('kriz'), count });
            } else {
              setBildirimCount({ krizId: Storage.session.get('kriz'), count });
              setNewNotification(true);
              toast.success(translate('tk24AdysApp.krizKatilimciBildirim.createdBildirim'), {
                position: toast.POSITION.TOP_RIGHT,
              });
            }

            if (open) {
              const previousAnchorEl = anchorEl;
              setAnchorEl(null);

              setAnchorEl(previousAnchorEl);
            }
          }
        };
        fetchData();
      }, 10000);

      return () => clearInterval(interval);
    }
  }, [krizKatilimciEntity, bildirimCount.count, bildirimCount.krizId]);

  const open = Boolean(anchorEl);
  const id = open ? 'notification-popover' : undefined;

  return (
    <div className="text-right hiddenInfo d-flex" style={{ gap: '16px' }}>
      {/*<div className="buttonsHeader">*/}
      {/*  <img src={`${SERVER_URL}/content/images/icon/searchHeader.svg`} />*/}
      {/*</div>*/}
      {hasAnyAuthority(['kriz_katilimci_bildirim_read']) && (
        <>
          <div className="buttonsHeader">
            <img
              src={`${SERVER_URL}/content/images/icon/bildirimHeader.svg`}
              onClick={handleClick}
              style={{ cursor: 'pointer' }}
              alt="Notification Icon"
            />
            {newNotification && <span className="notification-dot" />}
          </div>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <Bildirimler />
          </Popover>
        </>
      )}
      {hasAnyAuthority(['kriz_duyuru_read']) && (
        <div onClick={handleDuyuruClick} className="buttonsHeader">
          <img src={`${SERVER_URL}/content/images/icon/duyuruHeader.svg`} />
        </div>
      )}
    </div>
  );
};

export default ButtonHeader;
