import { SERVER_URL } from 'app/config/constants';
import { isNullOrEmpty } from 'app/shared/util/confirm-utils';
import React from 'react';

const SortIcon = ({ column, activeSortColumn }) => {
  if (!isNullOrEmpty(activeSortColumn)) {
    const activeColumn = activeSortColumn.split(',')[0];
    const sortActive = activeSortColumn.split(',')[1];
    const isActive = activeColumn === column;
    if (isActive) {
      return sortActive === 'asc' ? (
        <img src={`${SERVER_URL}/content/images/icon/sortBoldIcon.svg`} className="downIcon sortIcon" />
      ) : (
        <img src={`${SERVER_URL}/content/images/icon/sortBoldIcon.svg`} className="upIcon sortIcon" />
      );
    }
    return <img src={`${SERVER_URL}/content/images/icon/sortNormalIcon.svg`} className="sortIcon" />;
  } else {
    return <img src={`${SERVER_URL}/content/images/icon/sortNormalIcon.svg`} className="sortIcon" />;
  }
};

export default SortIcon;
