import React, { useEffect } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, UncontrolledTooltip, Row, Col } from 'reactstrap';
import { Translate, TextFormat } from 'app/component/jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getEntity } from './ref-havalimani-irtibat.reducer';
import { APP_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

export const RefHavalimaniIrtibatDetail = (props: RouteComponentProps<{ id: string }>) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getEntity(props.match.params.id));
  }, []);

  const refHavalimaniIrtibatEntity = useAppSelector(state => state.refHavalimaniIrtibat.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="refHavalimaniIrtibatDetailsHeading">
          <Translate contentKey="tk24AdysApp.refHavalimaniIrtibat.detail.title">RefHavalimaniIrtibat</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="adi">
              <Translate contentKey="tk24AdysApp.refHavalimaniIrtibat.adi">Adi</Translate>
            </span>
            <UncontrolledTooltip target="adi">
              <Translate contentKey="tk24AdysApp.refHavalimaniIrtibat.help.adi" />
            </UncontrolledTooltip>
          </dt>
          <dd>{refHavalimaniIrtibatEntity.adiSoyadiUnvani}</dd>
          <dt>
            <span id="eposta">
              <Translate contentKey="tk24AdysApp.refHavalimaniIrtibat.eposta">Eposta</Translate>
            </span>
          </dt>
          <dd>
            <a href={`mailto:${refHavalimaniIrtibatEntity.eposta}`} className="epostaText">
              {refHavalimaniIrtibatEntity.eposta}
            </a>
          </dd>
          <dt>
            <span id="telefon">
              <Translate contentKey="tk24AdysApp.refHavalimaniIrtibat.telefon">Telefon</Translate>
            </span>
          </dt>
          <dd>{refHavalimaniIrtibatEntity.telefonNo1}</dd>
          <dt>
            <Translate contentKey="tk24AdysApp.refHavalimaniIrtibat.havalimani">Havalimani</Translate>
          </dt>
          <dd>{refHavalimaniIrtibatEntity.havalimani ? refHavalimaniIrtibatEntity.havalimani.id : ''}</dd>
        </dl>
        <Button tag={Link} to="/ref-havalimani-irtibat" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/ref-havalimani-irtibat/${refHavalimaniIrtibatEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default RefHavalimaniIrtibatDetail;
