import React, { useEffect } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate } from 'app/component/jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { getEntity } from './kullanici-rol.reducer';
import { useAppDispatch, useAppSelector } from 'app/config/store';

export const KullaniciRolDetail = (props: RouteComponentProps<{ id: string }>) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getEntity(props.match.params.id));
  }, []);

  const kullaniciRolEntity = useAppSelector(state => state.kullaniciRol.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="kullaniciRolDetailsHeading">
          <Translate contentKey="tk24AdysApp.kullaniciRol.detail.title">KullaniciRol</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <Translate contentKey="tk24AdysApp.kullaniciRol.kullanici">Kullanici</Translate>
          </dt>
          <dd>{kullaniciRolEntity.kullanici ? kullaniciRolEntity.kullanici.id : ''}</dd>
          <dt>
            <Translate contentKey="tk24AdysApp.kullaniciRol.rol">Rol</Translate>
          </dt>
          <dd>{kullaniciRolEntity.rol ? kullaniciRolEntity.rol.id : ''}</dd>
        </dl>
        <Button tag={Link} to="/kullanici-rol" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/kullanici-rol/${kullaniciRolEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default KullaniciRolDetail;
