import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending } from '@reduxjs/toolkit';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { IQueryParams, createEntitySlice, EntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { IKriz, defaultValue, IKrizUpdate, IKrizKartDetail } from 'app/shared/model/kriz.model';
import { AppThunk } from 'app/config/store';

const initialState: EntityState<IKriz> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

const apiUrl = 'api/krizs';
interface KrizDurumEntitiesParams extends IQueryParams {
  krizDurumId: number[];
  startingKrizOlusmaZamani?: string;
  endingKrizOlusmaZamani?: string;
}

// Actions

export const getKartEntities = createAsyncThunk('kriz/fetch_entity_list', async ({ page, size, sort, query }: IQueryParams) => {
  const queryParams = new URLSearchParams();
  if (sort) {
    queryParams.set('page', page.toString());
    queryParams.set('size', size.toString());
    queryParams.set('sort', sort);
  }

  if (query) {
    const parsedQueryParams = new URLSearchParams(query);
    parsedQueryParams.forEach((value, key) => {
      queryParams.set(key, value);
    });
  }
  queryParams.set('cacheBuster', new Date().getTime().toString());
  const queryString = queryParams.toString();
  const requestUrl = `${apiUrl}/kart-list${queryString ? `?${queryString}` : ''}`;

  return axios.get<IKriz[]>(requestUrl);
});

export const getEntities = createAsyncThunk('kriz/fetch_entity_list', async ({ page, size, sort, query }: IQueryParams) => {
  const queryParams = new URLSearchParams();
  if (sort) {
    queryParams.set('page', page.toString());
    queryParams.set('size', size.toString());
    queryParams.set('sort', sort);
  }

  if (query) {
    const parsedQueryParams = new URLSearchParams(query);
    parsedQueryParams.forEach((value, key) => {
      queryParams.set(key, value);
    });
  }
  queryParams.set('cacheBuster', new Date().getTime().toString());
  const queryString = queryParams.toString();
  const requestUrl = `${apiUrl}${queryString ? `?${queryString}` : ''}`;

  return axios.get<IKriz[]>(requestUrl);
});

export const getKrizDurumEntities = createAsyncThunk(
  'kriz/fetch_durum_entity_list',
  async ({ krizDurumId, page, size, sort, query, startingKrizOlusmaZamani, endingKrizOlusmaZamani }: KrizDurumEntitiesParams) => {
    const queryParams = new URLSearchParams();
    queryParams.set('krizDurumuId.in', krizDurumId.toString());

    if (sort) {
      queryParams.set('page', page.toString());
      queryParams.set('size', size.toString());
      queryParams.set('sort', sort);
    }

    if (query) {
      const parsedQueryParams = new URLSearchParams(query);
      parsedQueryParams.forEach((value, key) => {
        queryParams.set(key, value);
      });
    }

    // if(startingKrizOlusmaZamani) {

    // }

    // if(endingKrizOlusmaZamani) {

    // }

    queryParams.set('cacheBuster', new Date().getTime().toString());
    const queryString = queryParams.toString();
    const requestUrl = `${apiUrl}${queryString ? `?${queryString}` : ''}`;
    return axios.get<IKriz[]>(requestUrl);
  }
);

export const getEntity = createAsyncThunk(
  'kriz/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<IKriz>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const createEntity = createAsyncThunk(
  'kriz/create_entity',
  async (entity: IKriz, thunkAPI) => {
    const result = await axios.post<IKriz>(apiUrl, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
  'kriz/update_entity',
  async (entity: IKriz, thunkAPI) => {
    const result = await axios.put<IKriz>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateThenGetEntity = createAsyncThunk(
  'kriz/update_then_get_entity',
  async (entity: IKrizUpdate, thunkAPI) => {
    const result = await axios.put<IKrizUpdate>(`${apiUrl}/${entity.kriz.id}`, cleanEntity(entity.kriz));
    thunkAPI.dispatch(getEntity(entity.kriz.id));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const krizAcThenGetEntity = createAsyncThunk(
  'kriz/kriz_ac_then_get_entity',
  async (entity: IKrizUpdate, thunkAPI) => {
    const result = await axios.put<IKrizUpdate>(`api/krizs-ac/${entity.kriz.id}`, cleanEntity(entity.kriz));
    thunkAPI.dispatch(getEntity(entity.kriz.id));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const krizKapatThenGetEntity = createAsyncThunk(
  'kriz/kriz_kapat_then_get_entity',
  async (entity: IKrizUpdate, thunkAPI) => {
    const result = await axios.put<IKrizUpdate>(`api/krizs-kapat/${entity.kriz.id}`, cleanEntity(entity.kriz));
    thunkAPI.dispatch(getEntity(entity.kriz.id));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const partialUpdateEntity = createAsyncThunk(
  'kriz/partial_update_entity',
  async (entity: IKriz, thunkAPI) => {
    const result = await axios.patch<IKriz>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const deleteEntity = createAsyncThunk(
  'kriz/delete_entity',
  async (id: string | number, thunkAPI) => {
    const requestUrl = `${apiUrl}/${id}`;
    const result = await axios.delete<IKriz>(requestUrl);
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const setKrizKart: (krizKart: IKrizKartDetail) => AppThunk = krizKart => dispatch => {
  dispatch(addKrizKart(krizKart));
};

// slice

export const KrizSlice = createEntitySlice({
  name: 'kriz',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(deleteEntity.fulfilled, state => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = {};
      })
      .addMatcher(isFulfilled(getEntities, getKartEntities, getKrizDurumEntities), (state, action) => {
        return {
          ...state,
          loading: false,
          entities: action.payload.data,
          totalItems: parseInt(action.payload.headers['x-total-count'], 10),
        };
      })
      .addMatcher(isFulfilled(createEntity, updateEntity, partialUpdateEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isFulfilled(updateThenGetEntity, krizAcThenGetEntity, krizKapatThenGetEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
      })
      .addMatcher(isPending(getEntities, getKrizDurumEntities, getKartEntities, getEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(
        isPending(
          createEntity,
          updateEntity,
          updateThenGetEntity,
          krizAcThenGetEntity,
          krizKapatThenGetEntity,
          partialUpdateEntity,
          deleteEntity
        ),
        state => {
          state.errorMessage = null;
          state.updateSuccess = false;
          state.updating = true;
        }
      );
  },
  reducers: {
    addKrizKart(state, action) {
      const krizKart = action.payload;
      const newState = Object.assign({}, state, { kriz: { id: krizKart.id, guid: krizKart.guid, realId: krizKart.realId } });
      window.console.log('neww', newState);
      return newState;
    },
  },
});

export const { reset, addKrizKart } = KrizSlice.actions;

// Reducer
export default KrizSlice.reducer;
