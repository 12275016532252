import { IQueryParams } from '../reducers/reducer.utils';
import { IReferans } from './referans.model';

export interface IRefAdtForm {
  id?: number;
  guid?: string;
  adi?: string;
  adiTr?: string;
  adiEn?: string;
  cDate?: string;
  cUser?: number;
  mDate?: string;
  mUser?: number;
  formTipi?: IReferans;
  uygulama?: IReferans;
  aciklamaTr?: string;
  aciklamaEn?: string;
  aciklama?: string;
  formSahibi?: IReferans;
  gecerlilikBaslangicTarihi?: string;
  gecerlilikBitisTarihi?: string;
}

export interface IRefAdtFormParams extends IQueryParams {
  entity?: IRefAdtForm;
  formTipiId?: number;
}

export const defaultValue: Readonly<IRefAdtForm> = {};
