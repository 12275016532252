import React, { useEffect, useState } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Label, Input, Col, Row } from 'reactstrap';
import InfoTable from 'app/entities/infoTable';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { KrizMain } from 'app/component/KrizMain';
import { translate, Translate } from 'app/component/jhipster';
import GrantedButton from 'app/component/GrantedButton';
import axios from 'axios';
import { LoadingSpinner } from 'app/component/LoadingSpinner';
import { toast } from 'react-toastify';
import { SERVER_URL } from 'app/config/constants';

const KrizRapor = (props: RouteComponentProps<{ id: string }>) => {
  const dispatch = useAppDispatch();
  const { match } = props;
  const isDrawer = useAppSelector(state => state.krizChat.isDrawer);

  const [modal, setModal] = useState(false);
  const [selectedGorevSahipleri, setSelectedGorevSahipleri] = useState({
    kyk: false,
    adt: false,
    istasyon: false,
  });
  const [loading, setLoading] = useState(false);
  const [selectedReport, setSelectedReport] = useState({
    type: '',
    successKey: '',
    errorKey: '',
  });

  const toggleModal = () => {
    if (!modal) {
      setSelectedGorevSahipleri({
        kyk: false,
        adt: false,
        istasyon: false,
      });
    }
    setModal(!modal);
  };

  const handleCheckboxChange = (event, key) => {
    setSelectedGorevSahipleri(prevState => ({
      ...prevState,
      [key]: event.target.checked,
    }));
  };

  const handleSelectReportClick = async (reportType, successKey, errorKey) => {
    const { kyk, adt, istasyon } = selectedGorevSahipleri;
    if (!kyk && !adt && !istasyon) {
      toast.error('Lütfen en az bir görev sahibi seçin.');
      return;
    }
    setLoading(true);
    try {
      const reportData = {
        krizId: match.params.id,
        kyk,
        adt,
        istasyon,
      };

      const responseData = await axios.post(`api/report/${reportType}`, reportData);

      try {
        const response = await axios.get(`api/ek-dosyas/download/${responseData.data.id}`, {
          responseType: 'blob',
        });
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${responseData.data.dosyaAdi}`);
        document.body.appendChild(link);
        link.click();
      } catch (error) {
        console.error('Dosya indirme hatası:', error);
      }

      toast.success(<Translate contentKey="entity.rapor.generateReport" interpolate={{ title: translate(successKey) }} />);
    } catch (error) {
      console.error('Dosya indirme hatası:', error);
      toast.error(<Translate contentKey="entity.rapor.generateReport" interpolate={{ title: translate(errorKey) }} />);
    } finally {
      setLoading(false);
      toggleModal();
    }
  };

  const handleConfirmModal = () => {
    if (modal) {
      handleSelectReportClick(selectedReport.type, selectedReport.successKey, selectedReport.errorKey);
    }
  };

  const handleOpenModalForReport = (reportType, successKey, errorKey) => {
    setSelectedReport({ type: reportType, successKey, errorKey });
    toggleModal(); // Modalı aç
  };

  const handleReportClick = async (reportType, reportAction) => {
    setLoading(true);
    try {
      const responseData = await axios.post(`api/report/${reportType}/${match.params.id}`);
      try {
        const response = await axios.get(`api/ek-dosyas/download/${responseData.data.id}`, {
          responseType: 'blob',
        });
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${responseData.data.dosyaAdi}`);
        document.body.appendChild(link);
        link.click();
      } catch (error) {
        console.error('Dosya indirme hatası:', error);
      }
      toast.success(<Translate contentKey="entity.rapor.generateReport" interpolate={{ title: translate(reportAction) }} />);
    } catch (error) {
      console.error('Dosya indirme hatası:', error);
      toast.error(<Translate contentKey="entity.rapor.error.generateReport" interpolate={{ title: translate(reportAction) }} />);
    } finally {
      setLoading(false);
    }
  };

  const raporModal = () => {
    return (
      <Modal isOpen={modal} toggle={toggleModal} centered>
        <ModalHeader toggle={toggleModal}>
          <Translate contentKey={selectedReport.successKey} />
        </ModalHeader>
        <ModalBody>
          {Object.keys(selectedGorevSahipleri).map(key => (
            <FormGroup check key={key}>
              <Label check>
                <Input
                  type="checkbox"
                  value={key}
                  checked={selectedGorevSahipleri[key]}
                  onChange={event => handleCheckboxChange(event, key)}
                />
                <Translate contentKey={`entity.gorevSahipleri.${key}`} />
              </Label>
            </FormGroup>
          ))}
        </ModalBody>
        <ModalFooter>
          <Button className="cancel-button" onClick={toggleModal}>
            <Translate contentKey="entity.action.cancel">Cancel</Translate>
          </Button>
          <Button className="save-button" onClick={handleConfirmModal}>
            <Translate contentKey="entity.action.raporUret" />
          </Button>
        </ModalFooter>
      </Modal>
    );
  };

  return (
    <>
      {loading && <LoadingSpinner />}
      {modal && raporModal()}
      <KrizMain isDrawer={isDrawer}>
        <div style={{ marginBottom: 5 }}>
          <InfoTable id={match.params.id} titleContentKey={'tk24AdysApp.krizDetay.raporlar'} />
        </div>
        <div className="d-flex align-items-center flex-wrap mt-3" style={{ gap: '15px' }}>
          <div className="mt-3 krizDetayBtn w-100">
            <Row>
              <Col className="conditional-col">
                <Button className=" krizDetayButtons" tag={Link} to={`/kriz-ivr-bildirim/${match.params.id}`}>
                  <div className="krizdetay-image">
                    <img
                      id={'krizAkisListe-icon'}
                      className="krizdetay-icon"
                      src={`${SERVER_URL}/content/images/kriz-detay/krizAkisListe.svg`}
                    />
                    <img
                      id={'krizAkisListe-icon'}
                      className="krizdetay-icon-hover"
                      src={`${SERVER_URL}/content/images/kriz-detay/kriz-detay-hover/AkisListesi.svg`}
                    />
                  </div>
                  <p>
                    <Translate contentKey="global.menu.entities.krizIvrBildirimleri" />
                  </p>
                </Button>
              </Col>
              <Col className="conditional-col">
                <Button className=" krizDetayButtons" tag={Link} to={`/kriz-doldurulan-formlar/${match.params.id}`}>
                  <div className="krizdetay-image">
                    <img id={'dokumanlar-icon'} className="krizdetay-icon" src={`${SERVER_URL}/content/images/kriz-detay/dokumanlar.svg`} />
                    <img
                      id={'dokumanlar-icon'}
                      className="krizdetay-icon-hover"
                      src={`${SERVER_URL}/content/images/kriz-detay/kriz-detay-hover/dokumanMedya.svg`}
                    />
                  </div>
                  <p>
                    <Translate contentKey="global.menu.entities.doldurulanFormlar" />
                  </p>
                </Button>
              </Col>
              <Col className="conditional-col">
                <GrantedButton
                  id="create-button"
                  className="krizDetayButtons"
                  grantedAuthorites={['kriz_ceride_edit']}
                  tag={Link}
                  onClick={() => handleOpenModalForReport('kriz-ceride-raporu', 'entity.action.cerideRaporu', 'entity.action.cerideRaporu')}
                >
                  <div className="krizdetay-image">
                    <img
                      id={'cerideListesi-icon'}
                      className="krizdetay-icon"
                      src={`${SERVER_URL}/content/images/kriz-detay/cerideListesi.svg`}
                    />
                    <img
                      id={'cerideListesi-icon'}
                      className="krizdetay-icon-hover"
                      src={`${SERVER_URL}/content/images/kriz-detay/kriz-detay-hover/ceride.svg`}
                    />
                  </div>
                  <p>
                    <Translate contentKey="entity.action.cerideRaporu" />
                  </p>
                </GrantedButton>
              </Col>
              <Col className="conditional-col">
                <GrantedButton
                  id="create-button-talimat"
                  grantedAuthorites={['kriz_talimat_edit']}
                  className="krizDetayButtons"
                  tag={Link}
                  data-cy="entityCreateButton"
                  onClick={() => handleReportClick('kriz-talimat-raporu', 'entity.action.talimatRaporu')}
                >
                  <div className="krizdetay-image">
                    <img
                      id={'talimatYonetimi-icon'}
                      className="krizdetay-icon"
                      src={`${SERVER_URL}/content/images/kriz-detay/talimatYonetimi.svg`}
                    />
                    <img
                      id={'talimatYonetimi-icon'}
                      className="krizdetay-icon-hover"
                      src={`${SERVER_URL}/content/images/kriz-detay/kriz-detay-hover/talimatlar.svg`}
                    />
                  </div>
                  <p>
                    <Translate contentKey="entity.action.talimatRaporu" />
                  </p>
                </GrantedButton>
              </Col>
              <Col className="conditional-col">
                <GrantedButton
                  id="create-button-ivr"
                  grantedAuthorites={['kriz_ivr_bildirim_edit']}
                  className="krizDetayButtons"
                  tag={Link}
                  data-cy="entityCreateButton"
                  onClick={() => handleReportClick('ivr-sonuc-raporu', 'entity.action.ivrCagriRaporu')}
                >
                  <div className="krizdetay-image">
                    <img
                      id={'krizAkisListe-icon'}
                      className="krizdetay-icon"
                      src={`${SERVER_URL}/content/images/kriz-detay/krizAkisListe.svg`}
                    />
                    <img
                      id={'krizAkisListe-icon'}
                      className="krizdetay-icon-hover"
                      src={`${SERVER_URL}/content/images/kriz-detay/kriz-detay-hover/AkisListesi.svg`}
                    />
                  </div>
                  <p>
                    <Translate contentKey="entity.action.ivrCagriRaporu" />
                  </p>
                </GrantedButton>
              </Col>
              <Col className="conditional-col">
                <GrantedButton
                  id="create-button"
                  grantedAuthorites={['kriz_ozet_raporu_edit']}
                  className="krizDetayButtons"
                  tag={Link}
                  data-cy="entityCreateButton"
                  onClick={() =>
                    handleOpenModalForReport('kriz-katilim-raporu', 'entity.action.katilimRaporu', 'entity.action.katilimRaporu')
                  }
                >
                  <div className="krizdetay-image">
                    <img
                      id={'tumGorevler-icon'}
                      className="krizdetay-icon"
                      src={`${SERVER_URL}/content/images/kriz-detay/tumGorevler.svg`}
                    />
                    <img
                      id={'tumGorevler-icon'}
                      className="krizdetay-icon-hover"
                      src={`${SERVER_URL}/content/images/kriz-detay/kriz-detay-hover/tumGorevler.svg`}
                    />
                  </div>
                  <p>
                    <Translate contentKey="entity.action.katilimRaporu"></Translate>
                  </p>
                </GrantedButton>
              </Col>
            </Row>
          </div>
        </div>
      </KrizMain>
    </>
  );
};

export default KrizRapor;
