import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import KrizKatilimci from './kriz-katilimci';
import KrizKatilimciDetail from './kriz-katilimci-detail';
import KrizKatilimciUpdate from './kriz-katilimci-update';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={KrizKatilimciUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={KrizKatilimciUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={KrizKatilimciDetail} />
      <ErrorBoundaryRoute path={match.url} component={KrizKatilimci} />
    </Switch>
  </>
);

export default Routes;
