import { IReferans } from 'app/shared/model/referans.model';
import { IRefHavalimani } from './ref-havalimani.model';
import { IRefOrganisazyon } from './ref-organizasyon.model';
import { IQueryParams } from '../reducers/reducer.utils';

export interface IPersonel {
  id?: number;
  guid?: string;
  unvanAdi?: string;
  unvanId?: string;
  sicil?: string | null;
  adi?: string | null;
  soyadi?: string | null;
  cDate?: string;
  cUser?: number;
  mDate?: string;
  mUser?: number;
  durum?: IReferans;
  eposta?: string;
  telefonNo?: string;
  havalimaniList?: IRefHavalimani[];
  acilDurumIrtibatKisi?: string;
  acilDurumIrtibatKisiIletisim?: string;
  org?: IRefOrganisazyon;
  thumbnail?: IThumbnailModel;
  istasyonPersoneliMi?: IReferans;
}

export interface IPersonelParams extends IQueryParams {
  entity?: IPersonel;
}

interface IThumbnailModel {
  base64?: string;
  contentType?: string;
}

export const defaultValue: Readonly<IPersonel> = {};
