import React, { useCallback, useRef, useState } from 'react';
import { Button, UncontrolledTooltip, Form, Row, Col, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { TextFormat, Translate, translate } from 'app/component/jhipster';
import { FormItem } from 'app/component/FormItem';
import { IRefDisDokuman } from 'app/shared/model/ref-dis-dokuman.model';
import axios from 'axios';
import { CustomDeleteEntityConfirm, CustomSaveEntityConfirm, getHeaderContentKey } from 'app/shared/util/confirm-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import {
  uploadEntity as uploadEkDosyaEntity,
  getFilteredEntities as getFilterDisDokumanDosyaEntities,
  deleteEntity as deleteEkDosyaEntity,
} from 'app/entities/ek-dosya/ek-dosya.reducer';
import TableNameEnum from 'app/constants/TableNameEnum';
import { createEntity as createDisDokumanEntity, updateEntity as updateDisDokumanEntity } from './ref-dis-dokuman.reducer';
import { ReferansEnum } from 'app/constants/ReferansEnum';
import { RefEkDosyaTipiCodeEnum } from 'app/constants/RefEkDosyaTipiCodeEnum';
import { GetBase64PromiseString } from 'app/shared/util/util';
import { APP_DATE_FORMAT, SERVER_URL } from 'app/config/constants';
import { IEkDosya } from 'app/shared/model/ek-dosya.model';
import { IQueryParams } from 'app/shared/reducers/reducer.utils';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

interface RefDisDokumanUpdateProp {
  handleCloseForm: () => void;
  formValues: IRefDisDokuman;
  isOpen: boolean;
  page: IQueryParams;
  setIsLoading: (loading: boolean) => void;
  isViewing: boolean;
}

export const RefDisDokumanUpdate: React.FC<RefDisDokumanUpdateProp> = ({
  handleCloseForm,
  formValues,
  isOpen,
  page,
  setIsLoading,
  isViewing,
}) => {
  const dispatch = useAppDispatch();

  const [customIsOpen, setCustomIsOpen] = useState(isOpen);

  const referans = useAppSelector(state => state.referans.entities.filter(res => res.tipi === 'DIL'));
  const refEkDosyaTipis = useAppSelector(state => state.refEkDosyaTipi.entities);
  const [deleteFile, setDeleteFile] = useState<IEkDosya | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const disDokumanDosyaList = useAppSelector(state => state.ekDosya.entities);
  const [isDeleteEkDosyaModalOpen, setIsDeleteEkDosyaModalOpen] = useState(false);

  const [modalPromiseResolve, setModalPromiseResolve] = useState(null);
  const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [fileNames, setFileNames] = useState({});
  const [ekDosyaAciklama, setEkDosyaAciklama] = useState('');

  const showSaveConfirmModal = () => {
    setIsSaveModalOpen(true);
    return new Promise(resolve => {
      setModalPromiseResolve(() => resolve);
    });
  };

  const handleConfirm = () => {
    if (modalPromiseResolve) {
      modalPromiseResolve(true);
    }
    setIsSaveModalOpen(false);
  };

  const handleCancel = () => {
    if (modalPromiseResolve) {
      modalPromiseResolve(false);
    }
    setIsSaveModalOpen(false);
  };

  const handleFileChange = e => {
    const files = e.target.files;
    setSelectedFiles([...selectedFiles, ...files]);
  };

  const handleDrop = event => {
    event.preventDefault();
    const files = Array.from(event.dataTransfer.files);
    setSelectedFiles([...selectedFiles, ...files]);
  };

  const getFileNameAndExtension = filename => {
    const dotIndex = filename.lastIndexOf('.');
    if (dotIndex === -1) {
      return { name: filename, extension: '' };
    }
    return { name: filename.substring(0, dotIndex), extension: filename.substring(dotIndex) };
  };

  const handleFileNameChange = (index, newName) => {
    setFileNames(prevNames => ({
      ...prevNames,
      [index]: newName,
    }));

    const updatedFiles = selectedFiles.map((file, fileIndex) => {
      if (fileIndex === index) {
        const { extension } = getFileNameAndExtension(file.name);
        return new File([file], `${newName}${extension}`, { type: file.type });
      }
      return file;
    });

    setSelectedFiles(updatedFiles);
  };

  const handleRemoveFile = index => {
    const newFiles = [...selectedFiles];
    newFiles.splice(index, 1);
    setSelectedFiles(newFiles);
  };

  const handleEkDosyaAciklamaChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEkDosyaAciklama(event.target.value);
  };

  const fileUpload = async (dosya: any) => {
    const fileInfoArray = await GetBase64PromiseString(selectedFiles);
    const entity = {
      ...dosya,
      fileList: fileInfoArray.map((file, index) => {
        const { extension } = getFileNameAndExtension(file.fileName);
        const updatedName = fileNames[index] || getFileNameAndExtension(file.fileName).name;
        return {
          ...file,
          fileName: `${updatedName}${extension}`,
          aciklama: ekDosyaAciklama,
        };
      }),
    };
    dispatch(uploadEkDosyaEntity(entity));
  };

  const saveNewDisDokuman = async e => {
    e.preventDefault();
    setCustomIsOpen(false);

    const isSave = await showSaveConfirmModal();

    if (isSave) {
      const formDataObject = {};
      for (const field of e.target.elements) {
        if (field.name) {
          formDataObject[field.name] = field.value;
        }
      }

      const refDisDokumanEntity = {
        ...formValues,
        ...formDataObject,
      };

      if (!formValues) {
        const createEntityAction: any = await dispatch(createDisDokumanEntity({ ...page, entity: refDisDokumanEntity }));

        if (selectedFiles !== null && selectedFiles.length > 0) {
          const createdEntity: { realId: number } = createEntityAction.payload;

          const ekDosyaSabit = {
            ilgiliTablo: TableNameEnum.RefDisDokuman,
            ilgiliTabloKayitId: createdEntity.realId,
            onaylandiMi: ReferansEnum.EvetHayir.Hayir,
            ekDosyaTipi: refEkDosyaTipis.find(res => res.code === RefEkDosyaTipiCodeEnum.DisDefaultDokuman),
            dil: referans.find(it => it.id.toString() === formDataObject['ekDosyaDil']),
          };

          fileUpload(ekDosyaSabit);
        }
      } else {
        await dispatch(updateDisDokumanEntity({ ...page, entity: refDisDokumanEntity }));

        if (selectedFiles !== null && selectedFiles.length > 0) {
          const ekDosyaSabit = {
            ilgiliTablo: TableNameEnum.RefDisDokuman,
            ilgiliTabloKayitId: refDisDokumanEntity['realId'],
            onaylandiMi: ReferansEnum.EvetHayir.Hayir,
            ekDosyaTipi: refEkDosyaTipis.find(res => res.code === RefEkDosyaTipiCodeEnum.DisDefaultDokuman),
            dil: referans.find(it => it.id.toString() === formDataObject['ekDosyaDil']),
          };

          fileUpload(ekDosyaSabit);
        }
      }

      handleCloseForm();
      setSelectedFiles([]);
      setFileNames({});
      setEkDosyaAciklama('');
    } else {
      setCustomIsOpen(true);
    }
  };

  const confirmDisDokumanDelete = ekDosya => {
    setDeleteFile(ekDosya);
    setCustomIsOpen(false);
    setIsDeleteEkDosyaModalOpen(true);
  };

  const handleDeleteConfirm = useCallback(async () => {
    if (deleteFile) {
      const disDokumanId = deleteFile.ilgiliTabloKayitId;
      setIsLoading(true);
      await dispatch(deleteEkDosyaEntity(deleteFile.id));
      dispatch(
        getFilterDisDokumanDosyaEntities({
          ilgiliTabloKayitId: disDokumanId,
          ilgiliTablo: TableNameEnum.RefDisDokuman,
        })
      );
      setIsLoading(false);
      setDeleteFile(null);
      setIsDeleteEkDosyaModalOpen(false);
      setCustomIsOpen(true);
    }
  }, [deleteFile, dispatch]);

  const handleDeleteCancel = () => {
    setIsDeleteEkDosyaModalOpen(false);
    setCustomIsOpen(true);
    setDeleteFile(null);
  };

  const handleDownloadSavedFile = async file => {
    setIsLoading(true);
    try {
      const response = await axios.get(`api/ek-dosyas/download/${file.id}`, {
        responseType: 'blob',
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${file.dosyaAdi}`);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error('Dosya indirme hatası:', error);
    }
    setIsLoading(false);
  };

  return (
    <>
      {deleteFile && (
        <CustomDeleteEntityConfirm
          entity={deleteFile}
          entityName="ekDosya"
          isOpen={isDeleteEkDosyaModalOpen}
          onConfirm={handleDeleteConfirm}
          onCancel={handleDeleteCancel}
        />
      )}
      <CustomSaveEntityConfirm entity={formValues} isOpen={isSaveModalOpen} onConfirm={handleConfirm} onCancel={handleCancel} />

      <Modal className="modal-style-lg" isOpen={customIsOpen} toggle={handleCloseForm} centered>
        <ModalHeader data-cy="RefDisDokumanCreateUpdateHeading" toggle={handleCloseForm}>
          {formValues && (
            <p className="modal-title-info">
              {formValues?.adiTr} -{formValues?.adiEn}
            </p>
          )}
          <Translate contentKey={getHeaderContentKey(isViewing, formValues, 'refDisDokuman')} />
        </ModalHeader>
        <Form onSubmit={saveNewDisDokuman}>
          <ModalBody style={{ maxHeight: '500px', overflowY: 'auto' }}>
            <FormItem
              label={translate('global.languageGlobal.adiTr')}
              id="ref-ddms-dokuman-adiTr"
              name="adiTr"
              defaultValue={formValues && formValues['adiTr']}
              disabled={isViewing}
              type="text"
              validation={{
                required: { value: true, message: translate('entity.validation.required') },
                maxLength: { value: 100, message: translate('entity.validation.maxlength', { max: 100 }) },
              }}
            />
            <UncontrolledTooltip target="adiTrLabel">
              <Translate contentKey="global.languageGlobal.adiTr" />
            </UncontrolledTooltip>

            <FormItem
              label={translate('global.languageGlobal.adiEn')}
              id="ref-ddms-dokuman-adiEn"
              name="adiEn"
              defaultValue={formValues && formValues['adiEn']}
              disabled={isViewing}
              type="text"
              validation={{
                required: { value: true, message: translate('entity.validation.required') },
                maxLength: { value: 100, message: translate('entity.validation.maxlength', { max: 100 }) },
              }}
            />
            <UncontrolledTooltip target="adiEnLabel">
              <Translate contentKey="global.languageGlobal.adiEn" />
            </UncontrolledTooltip>

            {formValues ? (
              <Col sm="12" style={{ marginTop: '10px', padding: '0px' }}>
                <Row className="modal-content-title" style={{ margin: '10px 0px' }}>
                  <Translate contentKey="tk24AdysApp.ekDosya.savedFiles" />
                </Row>

                <TableContainer className="content-table">
                  {disDokumanDosyaList && disDokumanDosyaList.length > 0 ? (
                    <Table aria-label="a dense table">
                      <TableHead className="content-table-title-area">
                        <TableRow className="table-head-row">
                          <TableCell className="table-title-cell hand" align="left">
                            <Translate contentKey="tk24AdysApp.ekDosya.dosyaAdi">dosyaAdi</Translate>
                          </TableCell>
                          <TableCell className="table-title-cell hand" align="left">
                            <Translate contentKey="tk24AdysApp.ekDosya.aciklamaTitle">aciklama</Translate>
                          </TableCell>
                          <TableCell className="table-title-cell hand" align="left">
                            <Translate contentKey="tk24AdysApp.ekDosya.fileLanguageTitle">dil</Translate>
                          </TableCell>
                          <TableCell className="table-title-cell hand" align="left">
                            <Translate contentKey="tk24AdysApp.ekDosya.fileExtentionTitle">fileExtention</Translate>
                          </TableCell>
                          <TableCell className="table-title-cell hand" align="left">
                            <Translate contentKey="tk24AdysApp.ekDosya.ekleyenKullanici">ekleyenKullanici</Translate>
                          </TableCell>
                          <TableCell className="table-title-cell hand" align="left">
                            <Translate contentKey="tk24AdysApp.ekDosya.cDateTitle">CDate</Translate>
                          </TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {disDokumanDosyaList.map(ekDosya => (
                          <TableRow key={ekDosya.id} className="table-cell-row">
                            <TableCell className="table-content-cell" component="th" scope="row">
                              {ekDosya?.dosyaAdi}
                            </TableCell>
                            <TableCell className="table-content-cell" component="th" scope="row">
                              {ekDosya?.aciklama}
                            </TableCell>
                            <TableCell className="table-content-cell" component="th" scope="row">
                              {ekDosya?.dil?.adi}
                            </TableCell>
                            <TableCell className="table-content-cell" component="th" scope="row">
                              {ekDosya?.fileExtention}
                            </TableCell>
                            <TableCell className="table-content-cell" component="th" scope="row">
                              {ekDosya?.ekleyenKullanici?.adi} {ekDosya?.ekleyenKullanici?.soyadi}
                            </TableCell>
                            <TableCell className="table-content-cell" component="th" scope="row">
                              <TextFormat type="date" value={ekDosya.cDate} format={APP_DATE_FORMAT} />
                            </TableCell>
                            <TableCell className="table-content-cell" align="right">
                              <div className="btn-group flex-btn-group-container">
                                <Button
                                  className="download-file-button"
                                  style={{ marginLeft: 5 }}
                                  onClick={() => handleDownloadSavedFile(ekDosya)}
                                >
                                  <img src={`${SERVER_URL}/content/images/icon/faArrowDownToLine.svg`} />
                                  <Translate contentKey="entity.action.download">İndir</Translate>
                                </Button>
                                {!isViewing && (
                                  <Button
                                    className="delete-button"
                                    style={{ marginLeft: 5 }}
                                    onClick={() => confirmDisDokumanDelete(ekDosya)}
                                  >
                                    <img width={11.2} height={12.8} src={`${SERVER_URL}/content/images/icon/faTrashCan.svg`} />
                                    <span>
                                      <Translate contentKey="entity.action.delete">Sil</Translate>
                                    </span>
                                  </Button>
                                )}
                              </div>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  ) : (
                    <div className="alert alert-warning m-2">
                      <Translate contentKey="tk24AdysApp.ekDosya.home.notFound">No Ek Dosya found</Translate>
                    </div>
                  )}
                </TableContainer>
              </Col>
            ) : (
              <></>
            )}

            {!isViewing && (
              <>
                <hr style={{ marginTop: 20, marginBottom: 15 }} />
                <FormItem
                  label={translate('tk24AdysApp.ekDosya.fileLanguage')}
                  id="ref-ek-dosya-dil-id"
                  name="ekDosyaDil"
                  type="select"
                  disabled={isViewing}
                  validation={{
                    required: { value: selectedFiles.length > 0, message: translate('entity.validation.required') },
                  }}
                >
                  <option value="" key="0" />
                  {referans.map(otherEntity => (
                    <option value={otherEntity.id} key={otherEntity.id}>
                      {otherEntity.adi}
                    </option>
                  ))}
                </FormItem>

                <FormItem
                  label={translate('tk24AdysApp.ekDosya.ekDosyaAciklama')}
                  id="ek-dosya-aciklama"
                  name="aciklama"
                  defaultValue={ekDosyaAciklama}
                  onChange={handleEkDosyaAciklamaChange}
                  type="textarea"
                  disabled={isViewing}
                  validation={{
                    maxLength: { value: 1000, message: translate('entity.validation.maxlength', { max: 1000 }) },
                  }}
                />
                <div className="modal-file-area">
                  <div className="modal-dashed-file-area" onDrop={handleDrop} onDragOver={e => e.preventDefault()}>
                    <img
                      width={16}
                      height={20}
                      style={{ marginBottom: '20px' }}
                      src={`${SERVER_URL}/content/images/icon/downloadIcon.svg`}
                    />
                    <span className="modal-file-text">
                      <span className="modal-upload-file-text" onClick={() => fileInputRef.current.click()}>
                        <Translate contentKey="tk24AdysApp.ekDosya.yukle" />
                      </span>
                      <span style={{ color: 'rgba(73, 88, 106, 1)' }}>
                        {' '}
                        <Translate contentKey="tk24AdysApp.ekDosya.surukle" />
                      </span>
                      <input type="file" ref={fileInputRef} style={{ display: 'none' }} onChange={handleFileChange} multiple />
                    </span>
                  </div>
                  <div className="modal-file-list-area">
                    {selectedFiles.length > 0 && (
                      <>
                        {selectedFiles.length > 0 &&
                          selectedFiles.map((file, index) => {
                            const { name, extension } = getFileNameAndExtension(file.name);
                            return (
                              <div key={file.id} className="modal-file-list-row">
                                <div
                                  style={{ display: 'flex', justifyContent: 'start', alignItems: 'center', padding: '0px', width: '50px' }}
                                >
                                  <img width={16} height={20} src={`${SERVER_URL}/content/images/icon/downloadIcon.svg`} />
                                </div>
                                <div style={{ width: '100%' }}>
                                  <div
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'column',
                                      alignItems: 'start',
                                      justifyContent: 'center',
                                      margin: 'auto',
                                    }}
                                  >
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                      <input
                                        type="text"
                                        value={fileNames[index] || name}
                                        onChange={e => handleFileNameChange(index, e.target.value)}
                                        className="form-control"
                                        style={{ width: '800px' }}
                                        maxLength={950}
                                      />
                                      <span className="ml-2">{extension}</span>
                                    </div>
                                    <span className="modal-file-size">{`${(file.size / 1024).toFixed(2)}kb`}</span>
                                  </div>
                                </div>
                                <div
                                  style={{ display: 'flex', justifyContent: 'end', alignItems: 'center', padding: '0px', width: '50px' }}
                                >
                                  <Button className="delete-file-button" onClick={() => handleRemoveFile(index)}>
                                    <img width={12} height={15} src={`${SERVER_URL}/content/images/icon/deleteFileIcon.svg`} />
                                  </Button>
                                </div>
                              </div>
                            );
                          })}
                      </>
                    )}
                  </div>
                </div>
              </>
            )}
          </ModalBody>
          {!isViewing && (
            <ModalFooter className="modal-footer">
              <Button className="cancel-button" onClick={handleCloseForm}>
                <Translate contentKey="entity.action.cancel">Cancel</Translate>
              </Button>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
                <Button className="dosya_btn mr-2" data-cy="entityDosyaButton" size="sm" onClick={() => fileInputRef.current.click()}>
                  <DriveFolderUploadIcon />
                  <span style={{ marginLeft: 5 }}>
                    <Translate contentKey="global.messages.ekDosyaEkle" />
                  </span>
                  <input type="file" ref={fileInputRef} style={{ display: 'none' }} onChange={handleFileChange} multiple />
                </Button>
                <Button className="save-button" id="jhi-confirm-delete-refAdtForm" type="submit" data-cy="entityConfirmButton">
                  <Translate contentKey="entity.action.save">Kaydet</Translate>
                </Button>
              </div>
            </ModalFooter>
          )}
        </Form>
      </Modal>
    </>
  );
};

export default RefDisDokumanUpdate;
