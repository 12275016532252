import { IRefKrizSeviye } from 'app/shared/model/ref-kriz-seviye.model';
import { IReferans } from 'app/shared/model/referans.model';
import { IKriz } from 'app/shared/model/kriz.model';
import { IRefKrizTuruKatilimci } from 'app/shared/model/ref-kriz-turu-katilimci.model';
import { IRefKrizTuruKatilimciGorev } from 'app/shared/model/ref-kriz-turu-katilimci-gorev.model';
import { IQueryParams } from '../reducers/reducer.utils';

export interface IRefKrizTuru {
  id?: number;
  guid?: string;
  adi?: string;
  adiTr?: string;
  adiEn?: string;
  cDate?: string;
  cUser?: number;
  mDate?: string;
  mUser?: number;
  defaultKrizSeviye?: IRefKrizSeviye;
  durum?: IReferans;
  krizs?: IKriz[] | null;
  refKrizTuruKatilimcis?: IRefKrizTuruKatilimci[] | null;
  refKrizTuruKatilimciGorevs?: IRefKrizTuruKatilimciGorev[] | null;
}

export interface IRefKrizTuruParams extends IQueryParams {
  entity?: IRefKrizTuru;
}

export const defaultValue: Readonly<IRefKrizTuru> = {};
