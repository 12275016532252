import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending } from '@reduxjs/toolkit';
import { cleanEntity } from 'app/shared/util/entity-utils';
import { IQueryParams, createEntitySlice, EntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { IKrizUcus, IKrizUcusParams, defaultValue } from 'app/shared/model/kriz-ucus.model';

const initialState: EntityState<IKrizUcus> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

const apiUrl = 'api/kriz-ucuses';

interface IUcusQueryParams extends IQueryParams {
  krizId: string;
}

// Actions

export const getTotalEntities = createAsyncThunk(
  'krizUcus/fetch_entity_total_list',
  async ({ size, sort, query, krizId }: IKrizUcusParams) => {
    const queryParams = new URLSearchParams();
    if (sort) {
      queryParams.set('size', size.toString());
      queryParams.set('sort', sort);
    }
    if (krizId) {
      queryParams.set('krizId.equals', krizId.toString());
    }
    if (query) {
      const parsedQueryParams = new URLSearchParams(query);
      parsedQueryParams.forEach((value, key) => {
        queryParams.set(key, value);
      });
    }
    queryParams.set('cacheBuster', new Date().getTime().toString());
    const queryString = queryParams.toString();
    const requestUrl = `${apiUrl}${queryString ? `?${queryString}` : ''}`;

    return axios.get<IKrizUcus[]>(requestUrl);
  }
);

export const getEntities = createAsyncThunk('krizUcus/fetch_entity_list', async ({ page, size, sort, query, krizId }: IKrizUcusParams) => {
  const queryParams = new URLSearchParams();
  if (sort) {
    queryParams.set('page', page.toString());
    queryParams.set('size', size.toString());
    queryParams.set('sort', sort);
  }

  if (krizId) {
    queryParams.set('krizId.equals', krizId.toString());
  }

  if (query) {
    const parsedQueryParams = new URLSearchParams(query);
    parsedQueryParams.forEach((value, key) => {
      queryParams.set(key, value);
    });
  }
  queryParams.set('cacheBuster', new Date().getTime().toString());
  const queryString = queryParams.toString();
  const requestUrl = `${apiUrl}${queryString ? `?${queryString}` : ''}`;
  return axios.get<IKrizUcus[]>(requestUrl);
});

export const getEntity = createAsyncThunk(
  'krizUcus/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<IKrizUcus>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const createEntity = createAsyncThunk(
  'krizUcus/create_entity',
  async (entity: IKrizUcus, thunkAPI) => {
    const result = await axios.post<IKrizUcus>(apiUrl, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
  'krizUcus/update_entity',
  async (params: IKrizUcusParams, thunkAPI) => {
    const result = await axios.put<IKrizUcus>(`${apiUrl}/${params.entity.id}`, cleanEntity(params.entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const partialUpdateEntity = createAsyncThunk(
  'krizUcus/partial_update_entity',
  async (entity: IKrizUcus, thunkAPI) => {
    const result = await axios.patch<IKrizUcus>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const deleteEntity = createAsyncThunk(
  'krizUcus/delete_entity',
  async (params: IKrizUcusParams, thunkAPI) => {
    const requestUrl = `${apiUrl}/${params.entity.id}`;
    const result = await axios.delete<IKrizUcus>(requestUrl);
    thunkAPI.dispatch(getEntities(params));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const deleteKrizEntity = createAsyncThunk(
  'krizUcus/delete_kriz_entity',
  async (id: string | number, thunkAPI) => {
    const requestUrl = `${apiUrl}/${id}`;
    const result = await axios.delete<IKrizUcus>(requestUrl);
    return result;
  },
  { serializeError: serializeAxiosError }
);

// slice

export const KrizUcusSlice = createEntitySlice({
  name: 'krizUcus',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(deleteEntity.fulfilled, state => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = {};
      })
      .addMatcher(isFulfilled(getTotalEntities), (state, action) => {
        return {
          ...state,
          loading: false,
          totalEntities: action.payload.data,
          totalItems: parseInt(action.payload.headers['x-total-count'], 10),
        };
      })
      .addMatcher(isFulfilled(getEntities), (state, action) => {
        return {
          ...state,
          loading: false,
          entities: action.payload.data,
          totalItems: parseInt(action.payload.headers['x-total-count'], 10),
        };
      })
      .addMatcher(isFulfilled(createEntity, updateEntity, partialUpdateEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isPending(getEntities, getTotalEntities, getEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(createEntity, updateEntity, partialUpdateEntity, deleteEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      });
  },
});

export const { reset } = KrizUcusSlice.actions;

// Reducer
export default KrizUcusSlice.reducer;
