import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import Kriz from './kriz';
import KrizDetail from './kriz-detail';
import KrizUpdate from './kriz-update';
import KrizDashboard from '../kriz-dashboard/kriz-dashboard';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={KrizUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={KrizDashboard} />
      <ErrorBoundaryRoute path={match.url} component={Kriz} />
    </Switch>
  </>
);

export default Routes;
