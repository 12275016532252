import axios, { AxiosResponse } from 'axios';
import { Storage } from 'app/component/jhipster';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { serializeAxiosError } from './reducer.utils';

import { AppThunk } from 'app/config/store';
import { setLocale } from 'app/shared/reducers/locale';

import { APP_ID } from 'app/config/constants';
import { IPersonel } from '../model/personel.model';

const AUTH_TOKEN_KEY = 'jhi-authenticationToken';

interface AccountModel {
  id: number;
  login: string;
  firstName: string;
  lastName: string;
  email: string;
  imageUrl: string;
  activated: boolean;
  langKey: string;
  cUser: number;
  cDate: string;
  mUser: number;
  mDate: string;
  authorities: string[];
  sicilNo: string;
  personel?: IPersonel;
}

export const initialState = {
  loading: false,
  isAuthenticated: false,
  isLoginPending: false,
  loginSuccess: false,
  loginError: false, // Errors returned from server side
  showModalLogin: false,
  account: {} as AccountModel,
  errorMessage: null as unknown as string, // Errors returned from server side
  redirectMessage: null as unknown as string,
  sessionHasBeenFetched: false,
  idToken: null as unknown as string,
  logoutUrl: null as unknown as string,
  oidcUrl: null as unknown as string,
  isLeftMenuOpen: false,
};

export type AuthenticationState = Readonly<typeof initialState>;

export const getSession = (): AppThunk => async (dispatch, getState) => {
  await dispatch(getAccount());

  const { account } = getState().authentication;
  if (account && account.langKey) {
    const langKey = Storage.session.get('locale', account.langKey);
    dispatch(setLocale(langKey));
  }

  Storage.session.set('isAuthenticated', getState().authentication.isAuthenticated);
  Storage.session.set('authState', getState().authentication);
};

export const getAccount = createAsyncThunk('authentication/get_account', async () => axios.get<any>('api/account'), {
  serializeError: serializeAxiosError,
});

interface IAuthParams {
  username?: string;
  password?: string;
  rememberMe?: boolean;
  code?: string;
  state?: string;
}

export const authenticate = createAsyncThunk(
  'authentication/login',
  async (auth: IAuthParams) => axios.post<any>(`api/authenticate/${APP_ID}`, auth),
  {
    serializeError: serializeAxiosError,
  }
);

export const login: (username: string, password: string, rememberMe?: boolean) => AppThunk =
  (username, password, rememberMe = false) =>
  async dispatch => {
    const result = await dispatch(authenticate({ username, password, rememberMe }));
    const response = result.payload as AxiosResponse;
    const bearerToken = response?.headers?.authorization;
    if (bearerToken && bearerToken.slice(0, 7) === 'Bearer ') {
      const jwt = bearerToken.slice(7, bearerToken.length);
      if (rememberMe) {
        Storage.local.set(AUTH_TOKEN_KEY, jwt);
      } else {
        Storage.session.set(AUTH_TOKEN_KEY, jwt);
      }
    }
    dispatch(getSession());
  };

export const oidcLogin: (code: string, oidc_state: string) => AppThunk = (code, oidc_state) => async (dispatch, getState) => {
  const result = await dispatch(authenticate({ code, state: oidc_state }));
  const response = result.payload as AxiosResponse;
  if (getState().authentication.loginSuccess) {
    const bearerToken = getState().authentication.idToken;
    if (bearerToken) {
      const jwt = bearerToken;
      Storage.session.set(AUTH_TOKEN_KEY, jwt);
    }
    dispatch(getSession());
  }
};

export const clearAuthToken = () => {
  if (Storage.local.get(AUTH_TOKEN_KEY)) {
    Storage.local.remove(AUTH_TOKEN_KEY);
  }
  if (Storage.session.get(AUTH_TOKEN_KEY)) {
    Storage.session.remove(AUTH_TOKEN_KEY);
  }

  Storage.session.remove('isAuthenticated');
  Storage.session.remove('authState');
  Storage.session.remove('isRedirectedOnce');
  Storage.session.remove('kriz');
  Storage.session.remove('isAdmin');
};

export const logout: () => AppThunk = () => dispatch => {
  clearAuthToken();
  dispatch(logoutSession());
};

export const getOIDCUrl = createAsyncThunk('account/get_oidc_url', async () => {
  const requestUrl = `api/getOidcUrl/${APP_ID}`;
  return axios.get<any>(requestUrl);
});

export const clearAuthentication = messageKey => dispatch => {
  clearAuthToken();
  dispatch(authError(messageKey));
  dispatch(clearAuth());
};

export const reloadAuthentication = createAsyncThunk('authentication/reloadAuthentication', async () => {}, {
  serializeError: serializeAxiosError,
});

export const setAuthenticationGrants = grants => async dispatch => {
  await dispatch(setGrants(grants));
};

export const setLeftSideMenu = isOpen => async dispatch => {
  await dispatch(setLeftMenu(isOpen));
};

/*
export const setAuthenticationGrants = createAsyncThunk(
  'authentication/setAuthenticationGrants',
  async (grants, thunkAPI) => {
    return thunkAPI.dispatch(setGrants(grants));
  },
  { serializeError: serializeAxiosError }
);
*/

export const AuthenticationSlice = createSlice({
  name: 'authentication',
  initialState: initialState as AuthenticationState,
  reducers: {
    logoutSession() {
      return {
        ...initialState,
        showModalLogin: true,
      };
    },
    authError(state, action) {
      return {
        ...state,
        showModalLogin: true,
        redirectMessage: action.payload,
      };
    },
    clearAuth(state) {
      return {
        ...state,
        loading: false,
        showModalLogin: true,
        isAuthenticated: false,
      };
    },
    setGrants(state, action) {
      return {
        ...state,
        account: { ...state.account, authorities: action.payload },
      };
    },
    setLeftMenu(state, action) {
      return {
        ...state,
        isLeftMenuOpen: action.payload,
      };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(authenticate.rejected, (state, action) => ({
        ...initialState,
        errorMessage: action.error.message,
        showModalLogin: true,
        loginError: true,
        isLoginPending: false,
      }))
      .addCase(getOIDCUrl.rejected, (state, action) => ({
        ...initialState,
        errorMessage: action.error.message,
        loginError: true,
      }))
      .addCase(authenticate.fulfilled, (state, action) => {
        window.console.log(action.payload.data);
        return {
          ...state,
          loading: false,
          loginError: false,
          showModalLogin: false,
          idToken: action.payload.data.id_token,
          errorMessage: action.payload.data.error_message,
          loginSuccess: action.payload.data.is_success,
          isLoginPending: false,
        };
      })
      .addCase(getOIDCUrl.fulfilled, (state, action) => ({
        ...state,
        loading: false,
        oidcUrl: action.payload.data.oidcUrl,
        logoutUrl: action.payload.data.logoutUrl,
      }))
      .addCase(getAccount.rejected, (state, action) => ({
        ...state,
        loading: false,
        isAuthenticated: false,
        sessionHasBeenFetched: true,
        showModalLogin: true,
        errorMessage: action.error.message,
      }))
      .addCase(getAccount.fulfilled, (state, action) => {
        const isAuthenticated = action.payload && action.payload.data && action.payload.data.activated;
        return {
          ...state,
          isAuthenticated,
          loading: false,
          sessionHasBeenFetched: true,
          account: action.payload.data,
        };
      })
      .addCase(authenticate.pending, state => {
        state.loading = true;
        state.isLoginPending = true;
      })
      .addCase(getOIDCUrl.pending, state => {
        return {
          ...state,
          loading: true,
          oidcUrl: null,
        };
      })
      .addCase(getAccount.pending, state => {
        state.loading = true;
      })
      .addCase(reloadAuthentication.pending, state => {
        state.loading = true;
      })
      .addCase(reloadAuthentication.fulfilled, state => {
        const ss = Storage.session.get('authState');
        return ss;
      });
  },
});

export const { logoutSession, authError, clearAuth, setGrants, setLeftMenu } = AuthenticationSlice.actions;

// Reducer
export default AuthenticationSlice.reducer;
