import { IReferans } from 'app/shared/model/referans.model';
import { IBaseModel } from './base.model';

export interface IManuelBildirim extends IBaseModel {
  aciklama?: string;
  konuTr?: string;
  konuEn?: string;
  icerikTr?: string;
  icerikEn?: string;
  ivrPrompTextTr?: string;
  ivrPrompTextEn?: string;
  ivrPrompText2Tr?: string;
  ivrPrompText2En?: string;
  bildirimTuru?: IReferans;
}

export const defaultValue: Readonly<IManuelBildirim> = {};
