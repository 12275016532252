import React from 'react';
import { Row, Col } from 'reactstrap';
import { Translate, TextFormat } from 'app/component/jhipster';
import { APP_DATE_FORMAT } from 'app/config/constants';
import { IKullanici } from 'app/shared/model/kullanici.model';

interface KullaniciDetailProp {
  kullanici: IKullanici;
}

export const KullaniciDetail: React.FC<KullaniciDetailProp> = ({ kullanici }) => {
  return (
    <Row className="modal-body-row">
      <Col lg="6" style={{ marginTop: '10px' }}>
        <Row className="modal-content-title">
          <Translate contentKey="tk24AdysApp.kullanici.adi">Adi</Translate>
        </Row>
        <Row className="modal-content-text">{kullanici.adi ? kullanici.adi : '-'}</Row>
      </Col>
      <Col lg="6" style={{ marginTop: '10px' }}>
        <Row className="modal-content-title">
          <Translate contentKey="tk24AdysApp.kullanici.soyadi">Soyadi</Translate>
        </Row>
        <Row className="modal-content-text">{kullanici.soyadi ? kullanici.soyadi : '-'}</Row>
      </Col>
      <Col lg="6" style={{ marginTop: '10px' }}>
        <Row className="modal-content-title">
          <Translate contentKey="tk24AdysApp.kullanici.unvanAdi">Unvan</Translate>
        </Row>
        <Row className="modal-content-text">{kullanici.unvanAdi ? kullanici.unvanAdi : '-'}</Row>
      </Col>
      <Col lg="6" style={{ marginTop: '10px' }}>
        <Row className="modal-content-title">
          <Translate contentKey="tk24AdysApp.kullanici.kullaniciAdi">kullaniciAdi</Translate>
        </Row>
        <Row className="modal-content-text">{kullanici.kullaniciAdi ? kullanici.kullaniciAdi : '-'}</Row>
      </Col>
      <Col lg="6" style={{ marginTop: '10px' }}>
        <Row className="modal-content-title">
          <Translate contentKey="tk24AdysApp.kullanici.eposta">eposta</Translate>
        </Row>
        <Row className="modal-content-text">{kullanici.eposta ? kullanici.eposta : '-'}</Row>
      </Col>

      <Col lg="6" style={{ marginTop: '10px' }}>
        <Row className="modal-content-title">
          <Translate contentKey="tk24AdysApp.kullanici.sonLoginZamani">sonLoginZamani</Translate>
        </Row>
        <Row className="modal-content-text">
          {kullanici.sonLoginZamani ? <TextFormat value={kullanici.sonLoginZamani} type="date" format={APP_DATE_FORMAT} /> : '-'}
        </Row>
      </Col>
    </Row>
  );
};

export default KullaniciDetail;
