import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending } from '@reduxjs/toolkit';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { IQueryParams, createEntitySlice, EntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { IFilteredAdtDuyuruQueryParams, IKrizDuyuru, defaultValue } from 'app/shared/model/kriz-duyuru.model';

const initialState: EntityState<IKrizDuyuru> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

const apiUrl = 'api/kriz-duyurus';

// Actions

export const getEntities = createAsyncThunk('duyuru/adt_fetch_entity_list', async ({ page, size, sort }: IQueryParams) => {
  const filter = `${sort ? `?page=${page}&size=${size}&sort=${sort}&` : '?'}cacheBuster=${new Date().getTime()}`;
  const requestUrl = `${apiUrl}${filter}`;
  return axios.get<IKrizDuyuru[]>(requestUrl);
});

export const getTotalEntities = createAsyncThunk(
  'duyuru/adt_fetch_filtered_entity_list',
  async ({ krizId, adtEkipId, size, sort }: IFilteredAdtDuyuruQueryParams) => {
    const queryParams = new URLSearchParams();

    if (sort) {
      queryParams.set('sort', sort.toString());
    }
    if (size) {
      queryParams.set('size', size.toString());
    }
    if (krizId) {
      queryParams.set('krizId.equals', krizId.toString());
    }
    if (adtEkipId) {
      queryParams.set('adtEkipId.equals', adtEkipId.toString());
    }

    queryParams.set('adtEkipId.specified', 'true');
    const queryString = queryParams.toString();
    const requestUrl = `${apiUrl}${queryString ? `?${queryString}` : ''}`;
    return axios.get<IKrizDuyuru[]>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const getFilteredEntities = createAsyncThunk(
  'duyuru/adt_fetch_filtered_entity_list',
  async ({ krizId, adtEkipId, page, size, sort, query }: IFilteredAdtDuyuruQueryParams) => {
    const queryParams = new URLSearchParams();
    if (sort) {
      queryParams.set('page', page.toString());
      queryParams.set('size', size.toString());
      queryParams.set('sort', sort);
    }

    if (krizId) {
      queryParams.set('krizId.equals', krizId.toString());
    }
    if (adtEkipId) {
      queryParams.set('adtEkipId.equals', adtEkipId.toString());
    }

    if (query) {
      const parsedQueryParams = new URLSearchParams(query);
      parsedQueryParams.forEach((value, key) => {
        queryParams.set(key, value);
      });
    }

    queryParams.set('adtEkipId.specified', 'true');
    const queryString = queryParams.toString();
    const requestUrl = `${apiUrl}${queryString ? `?${queryString}` : ''}`;
    return axios.get<IKrizDuyuru[]>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const getEntity = createAsyncThunk(
  'duyuru/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<IKrizDuyuru>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const createEntity = createAsyncThunk(
  'duyuru/create_entity',
  async (entity: IKrizDuyuru) => {
    const result = await axios.post<IKrizDuyuru>(apiUrl, cleanEntity(entity));

    const entityWithId = {
      ...result.data,
      realId: result.data.realId,
    };

    return entityWithId;
  },
  { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
  'duyuru/update_entity',
  async (entity: IKrizDuyuru) => {
    const result = await axios.put<IKrizDuyuru>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const partialUpdateEntity = createAsyncThunk(
  'duyuru/partial_update_entity',
  async (entity: IKrizDuyuru, thunkAPI) => {
    const result = await axios.patch<IKrizDuyuru>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const deleteEntity = createAsyncThunk(
  'duyuru/delete_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    const result = await axios.delete<IKrizDuyuru>(requestUrl);
    return result;
  },
  { serializeError: serializeAxiosError }
);

// slice

export const DuyuruSlice = createEntitySlice({
  name: 'duyuru',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(deleteEntity.fulfilled, state => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = {};
      })
      .addMatcher(isFulfilled(getEntities, getFilteredEntities), (state, action) => {
        return {
          ...state,
          loading: false,
          entities: action.payload.data,
          totalItems: parseInt(action.payload.headers['x-total-count'], 10),
        };
      })
      .addMatcher(isFulfilled(createEntity, updateEntity, partialUpdateEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;

        if ('data' in action.payload) {
          state.entity = action.payload.data;
        } else {
          state.entity = action.payload;
        }
      })
      .addMatcher(isPending(getEntities, getFilteredEntities, getEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(createEntity, updateEntity, partialUpdateEntity, deleteEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      });
  },
});

export const { reset } = DuyuruSlice.actions;

// Reducer
export default DuyuruSlice.reducer;
