import { IReferans } from './referans.model';
import { IRefAdtFormBolum } from './ref-adt-form-bolum.model';
import { IRefAdtFormBolumSoruSecenek } from './ref-adt-form-bolum-soru-secenek.model';

export interface IRefAdtFormBolumSoru {
  id?: string;
  realId?: number;
  guid?: string;
  soruTr?: string;
  soruEn?: string;
  soru?: string;
  cDate?: string;
  cUser?: number;
  mDate?: string;
  mUser?: number;
  sortIndex?: number;
  bolum?: IRefAdtFormBolum;
  soruTipi?: IReferans;
  soruSecenekList?: IRefAdtFormBolumSoruSecenek[];
  soruSecenekValue?: string;
  cevaplanmasiZorunluMu?: IReferans;
  digerSecenegiVarMi?: IReferans;
  bilgiAlaniVarMi?: IReferans;
  aciklamaTr?: string;
  aciklamaEn?: string;
  aciklama?: string;
}

export const defaultValue: Readonly<IRefAdtFormBolumSoru> = {};
